<template>

    <div class="part-text">

<h3 :class="{'activeClass': idClicked == I894 }" id="I894">INDEX V PLACES OUTSIDE OF ATHENS, ETHNIC GROUPS, LANGUAGES</h3>
<ul>
<li :class="{'activeClass': idClicked == I895 }" id="I895">ABDERA: Abderos; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP826'>Ath116*</a>, document relief </li>
<li :class="{'activeClass': idClicked == I896 }" id="I896">ACHAIA: 1) As an epithet: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1216'>Demeter</a> A.; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1245'>Demeter Kourotrophos</a> A. <br>2) Proconsuls of Achaia: Ampelius: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2422'>Kollytos</a>; Aquilius; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1179'>Claudius</a> Illyrius; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1266'>Demokrates</a>; Eusebius: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP625'>Asty</a> milestones; Panathenaios: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1986'>Herculius</a>; Theodoras. See also under Hellas</li>
<li :class="{'activeClass': idClicked == I897 }" id="I897">ACHARNAI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a>, temple; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP558'>Ask2*</a>; road to Acharnai: → Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM485">485</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM570">570</a>-572 </li>
<li :class="{'activeClass': idClicked == I898 }" id="I898">ACHARNIAN AQUEDUCT: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM618">618</a></li>
<li :class="{'activeClass': idClicked == I899 }" id="I899">ACHARNIAN GATE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3520'>Themistoklean City Wall</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3536'>Wal12*</a>; heading preceding Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM570">570</a> </li>
<li :class="{'activeClass': idClicked == I900 }" id="I900">AEZANI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3751'>Ulpius Apuleius Eurykles</a></li>
<li :class="{'activeClass': idClicked == I901 }" id="I901">AIGINA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP82'>Aiakeion</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem4*, Sem13*</li>
<li :class="{'activeClass': idClicked == I902 }" id="I902">ALEXANDRIA TROAS: Hadrian, statue base; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2561'>Lykon</a></li>
<li :class="{'activeClass': idClicked == I903 }" id="I903">AMAZONS: <a v-on:click.prevent="replaceLink"   href='GenIndex.html'>See Index I</a></li>
<li :class="{'activeClass': idClicked == I904 }" id="I904">AMBRAKIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP785'>Ath88*</a> (statue of <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a>)</li>
<li :class="{'activeClass': idClicked == I905 }" id="I905">AMPHIPOLIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem5*, Sem25*</li>
<li :class="{'activeClass': idClicked == I906 }" id="I906">ANAPHI: Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1136'>CM70*</a> (<a v-on:click.prevent="replaceLink"   href='NPP.Html#NP1136'>Hag. Symeon</a> built by Anaphiotes)</li>
<li :class="{'activeClass': idClicked == I907 }" id="I907">APHRODISIAS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP266'>Aph22**</a>; Aristotle, portrait herm possibly dd by Alexander of Aphrodisias</li>
<li :class="{'activeClass': idClicked == I908 }" id="I908">ARABIC: Islamic Monuments (Arabic inscr.); Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM9">9</a></li>
<li :class="{'activeClass': idClicked == I909 }" id="I909">ARAGON: Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1151'>CM80*</a> (Aragonese document)</li>
<li :class="{'activeClass': idClicked == I910 }" id="I910">ARGIVES, ARGOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP60'>Agora of the Argives</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem27*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2316'>Kalades</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2883'>Palladion</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3494'>Taurobolion</a></li>
<li :class="{'activeClass': idClicked == I911 }" id="I911">ARLES: Favorinus</li>
<li :class="{'activeClass': idClicked == I912 }" id="I912">ASIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem16*; Unknown Gods</li>
<li :class="{'activeClass': idClicked == I913 }" id="I913">ASKALON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP216'>Antiochos</a> of Askalon: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3268'>Ptolemies</a>, Ptolemy III, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3276'>Ptolemaion</a>; Antipatros</li>
<li :class="{'activeClass': idClicked == I914 }" id="I914">ATTICA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP15'>Acheloos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP356'>Apo38*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP377'>Apo53*</a>; Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1123'>CM60*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1910'>Hera Boulaia</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2351'>Kekropia</a>; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM87">87</a></li>
<li :class="{'activeClass': idClicked == I915 }" id="I915">AUGUSTA DIENSIUM, colony in Macedonia: Hadrian, statue bases</li>
<li :class="{'activeClass': idClicked == I916 }" id="I916">AULON (in Attica): <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1439'>Dio16*</a> (theater throne)</li>
<li :class="{'activeClass': idClicked == I917 }" id="I917">AVARS: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM301">301</a></li>
<li :class="{'activeClass': idClicked == I918 }" id="I918">BABYLON: Berossos</li>
<li :class="{'activeClass': idClicked == I919 }" id="I919">BAIAE: Harmodios</li>
<li :class="{'activeClass': idClicked == I920 }" id="I920">BARGOSA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3782'>Zarmaros</a></li>
<li :class="{'activeClass': idClicked == I921 }" id="I921">BITHYNIA: Antinoos</li>
<li :class="{'activeClass': idClicked == I922 }" id="I922">BOEOTIA, BOETIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a>, heading; Chariot and fetters, for victory over the Boeotians; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem10*</li>
<li :class="{'activeClass': idClicked == I923 }" id="I923">BOSPORAN KINGDOM: Pairisades; Spartokos II; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3446'>Spartokos III</a></li>
<li :class="{'activeClass': idClicked == I924 }" id="I924">BRAURON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP518'>Art27*</a>-30*</li>
<li :class="{'activeClass': idClicked == I925 }" id="I925">ΒΥΖΑΝΤΙΟΝ: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem9*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1821'>Hadrian Olympios</a></li>
<li :class="{'activeClass': idClicked == I926 }" id="I926">CAPPADOCIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP432'>Archelaos</a>, son of <a v-on:click.prevent="replaceLink"   href='NPP.html#NP431'>Archelaos Philopatris</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP431'>Archelaos Philopatris</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP462'>Ariarathes V</a>; Ariarathes (?); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP466'>Ariobarzanes II</a>; Ariobarzanes III; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1773'>Glaphyra</a>; Julian the Cappadocian sophist; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3244'>Prohairesios</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3300'>Pythodoris</a></li>
<li :class="{'activeClass': idClicked == I927 }" id="I927">CARIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP266'>Aph22**</a>; Asandros; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3750'>Tyr</a>  (Carian inscr.)</li>
<li :class="{'activeClass': idClicked == I928 }" id="I928">CARTHAGE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem31*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2883'>Palladion</a></li>
<li :class="{'activeClass': idClicked == I929 }" id="I929">CATALANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3388'>Setines</a></li>
<li :class="{'activeClass': idClicked == I930 }" id="I930">CHAIRONEIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem8*, Sem29*; Hippokrates' palaestra</li>
<li :class="{'activeClass': idClicked == I931 }" id="I931">CHALKIS, CHALKIDIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a> (Boeotians and Chalcidians); Chariot and fetters (re victory over the Chalkidians); Ismenias of Chalkis: → Eteoboutad priests</li>
<li :class="{'activeClass': idClicked == I932 }" id="I932">CHERSONESE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem9*</li>
<li :class="{'activeClass': idClicked == I933 }" id="I933">CHIOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem16*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3059'>Phanes</a></li>
<li :class="{'activeClass': idClicked == I934 }" id="I934">CILICIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP68'>Agrippa</a>, Odeion (sophists from Cilicia); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1153'>Chrysippos</a></li>
<li :class="{'activeClass': idClicked == I935 }" id="I935">COLCHIS: Jason</li>
<li :class="{'activeClass': idClicked == I936 }" id="I936">COMMAGENE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP216'>Antiochos</a> III; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3090'>Philopappos</a></li>
<li :class="{'activeClass': idClicked == I937 }" id="I937">CONSTANTINOPLE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP378'>Apo54*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP818'>Ath113*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1565'>Elephants</a> (re stelai taken to the Golden Gate)</li>
<li :class="{'activeClass': idClicked == I938 }" id="I938">CORINTH: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem10*, Sem11*, Sem40*; Klegora; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a>, Cn. Cornelius Pulcher</li>
<li :class="{'activeClass': idClicked == I939 }" id="I939">COSTOBOCI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3504'>Telesphoros</a>, son of Eukarpos</li>
<li :class="{'activeClass': idClicked == I940 }" id="I940">CRETE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem27* (Cretan archers); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a> (Cretan xoana)</li>
<li :class="{'activeClass': idClicked == I941 }" id="I941">CYPRUS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem12*, Sem13*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1744'>Evagoras</a></li>
<li :class="{'activeClass': idClicked == I942 }" id="I942">CYRENE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a> (<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3667'>Tiberius</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1179'>Claudius</a> Jason Magnus)</li>
<li :class="{'activeClass': idClicked == I943 }" id="I943">CYZICUS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1821'>Hadrian Olympios</a></li>
<li :class="{'activeClass': idClicked == I944 }" id="I944">DACIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3686'>Trajan</a></li>
<li :class="{'activeClass': idClicked == I945 }" id="I945">DAMASKOS: Aristotle, portrait herm (perhaps dd by Alexander of Damaskos); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2233'>Iakobos</a></li>
<li :class="{'activeClass': idClicked == I946 }" id="I946">DAPHNI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP625'>Asty</a> milestones</li>
<li :class="{'activeClass': idClicked == I947 }" id="I947">DAULIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP723'>Ath41*</a>(xoanon transported by <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3249'>Prokne</a>)</li>
<li :class="{'activeClass': idClicked == I948 }" id="I948">DEKELEIA: Dekeleians' meeting place; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3435'>Sophokles</a>' grave mon. (re road to Dekeleia)</li>
<li :class="{'activeClass': idClicked == I949 }" id="I949">DELION: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem14*</li>
<li :class="{'activeClass': idClicked == I950 }" id="I950">DELOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP346'>Apo29*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a> (xoanon brought by Erysichthon); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2043'>Hrm38*</a> (dedicated by a gymnasiarch); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2263'>Isis Dikaiosyne</a></li>
<li :class="{'activeClass': idClicked == I951 }" id="I951">DELPHI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP707'>Ath29*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1252'>Demetrios</a>, son of Aristarchos (re dedication by the Delphic Amphiktyons); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1769'>Gephyraioi</a> (re correspondence with the Delphians); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3332'>Sacred Way</a> of the <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3294'>Pythais</a> procession; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3497'>Technitai</a> (statue dedicated by the polis of the Delphians)</li>
<li :class="{'activeClass': idClicked == I952 }" id="I952">DODONA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3400'>Skiron</a> (seer from Dodona); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3874'>Zeu68*</a></li>
<li :class="{'activeClass': idClicked == I953 }" id="I953">DRABESKOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</li>
<li :class="{'activeClass': idClicked == I954 }" id="I954">EETIONEIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1535'>Eetione</a> Eet59*, theater seat</li>
<li :class="{'activeClass': idClicked == I955 }" id="I955">EGYPT: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP236'>Antonius</a> and Cleopatra; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP488'>Arsinoe II</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem13*; Hadrian, statues of Egyptian stone; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1991'>Hermaios</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2207'>Horus</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3268'>Ptolemies</a>; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM110">110</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM149">149</a> (hieroglyphics), 163 (hieroglyphics) See also Index I under Egyptian Gods</li>
<li :class="{'activeClass': idClicked == I956 }" id="I956">EION: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2054'>Her3*</a></li>
<li :class="{'activeClass': idClicked == I957 }" id="I957">ELEA: Zeno of Elea: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3301'>Pythodoros</a>' house</li>
<li :class="{'activeClass': idClicked == I958 }" id="I958">ELEUSIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1959'>Hrk30*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2287'>Isokrates</a>, bronze statue; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3015'>Pelargikon</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3332'>Sacred Way</a>; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM462">462</a> (re <a v-on:click.prevent="replaceLink"   href='NPP.html#NP38'>Agelastos Petra</a>)</li>
<li :class="{'activeClass': idClicked == I959 }" id="I959">ELEUTHERAI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1416'>Dio4*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1417'>Dio5*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1455'>Dio28*</a>; Pegasos</li>
<li :class="{'activeClass': idClicked == I960 }" id="I960">ELIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP240'>Antonius Oxylos</a></li>
<li :class="{'activeClass': idClicked == I961 }" id="I961">EPHESOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP517'>Art26*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li>
<li :class="{'activeClass': idClicked == I962 }" id="I962">EPIROS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3292'>Pyrrhos</a></li>
<li :class="{'activeClass': idClicked == I963 }" id="I963">EUBOIA: Chalkodon; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem16*</li>
<li :class="{'activeClass': idClicked == I964 }" id="I964">EUROPE: Unknown Gods</li>
<li :class="{'activeClass': idClicked == I965 }" id="I965">EURYMEDON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a> Nik80*, limestone naiskos; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem17*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1553'>Eirene</a></li>
<li :class="{'activeClass': idClicked == I966 }" id="I966">FRANKS: <a v-on:click.prevent="replaceLink"   href='LateInd.html'>See Index III</a> under Frankish Mon.</li>
<li :class="{'activeClass': idClicked == I967 }" id="I967">GADARA: Theodoras of Gadara</li>
<li :class="{'activeClass': idClicked == I968 }" id="I968">GALATIA,GALATIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP786'>Ath89*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and Attalos II, Attalid dedication; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1210'>Deiotaros</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2323'>Kallippos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3817'>Zeu23*</a></li>
<li :class="{'activeClass': idClicked == I969 }" id="I969">GAUL: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3694'>Trebellius Rufus</a></li>
<li :class="{'activeClass': idClicked == I970 }" id="I970">GENOESE: Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1101'>CM41*</a></li>
<li :class="{'activeClass': idClicked == I971 }" id="I971">GERMANIKOPOLIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3917'>Zeu104*</a></li>
<li :class="{'activeClass': idClicked == I972 }" id="I972">GOUDI: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM620">620</a></li>
<li :class="{'activeClass': idClicked == I973 }" id="I973">GRANIKOS: Alexander</li>
<li :class="{'activeClass': idClicked == I974 }" id="I974">GYTHEION: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP873'>Atticus</a> (Herodes <a v-on:click.prevent="replaceLink"   href='NPP.html#NP873'>Atticus</a>' father)</li>
<li :class="{'activeClass': idClicked == I975 }" id="I975">HALIEIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem13*</li>
<li :class="{'activeClass': idClicked == I976 }" id="I976">HALIKARNASSOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3060'>Phanostratos</a></li>
<li :class="{'activeClass': idClicked == I977 }" id="I977">HARMA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3800'>Zeu10*</a></li>
<li :class="{'activeClass': idClicked == I978 }" id="I978">HELIOPOLIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2313'>Jupiter Optimus Maximus</a></li>
<li :class="{'activeClass': idClicked == I979 }" id="I979">HELLAS, alternate name of Achaia used in the titles of proconsuls: Anatolios: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3243'>Probus</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1749'>Festus</a>; Severus Aetius: → <a v-on:click.prevent="replaceLink"   href='NPP.html#NP427'>Arcadius and Honorius</a></li>
<li :class="{'activeClass': idClicked == I980 }" id="I980">HELLESPONT: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem18*; Hellespont bridge cables; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM86">86</a></li>
<li :class="{'activeClass': idClicked == I981 }" id="I981">HERAKLEIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1962'>Hrk33*</a></li>
<li :class="{'activeClass': idClicked == I982 }" id="I982">HERULIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3213'>Post-Herulian Wall</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3520'>Themistoklean City Wall</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3561'>Wal34*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3563'>Wal35*</a>; Valerian fortifications; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM19">19</a> (<a v-on:click.prevent="replaceLink"   href='NNM.html#NNM19'>Beulé Gate</a>), no. 38 (Post-Herulian repair)</li>
<li :class="{'activeClass': idClicked == I983 }" id="I983">HIERAPOLIS: Sarapion Mon., Quintus Statius Sarapion</li>
<li :class="{'activeClass': idClicked == I984 }" id="I984">HYMETTOS: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM620">620</a></li>
<li :class="{'activeClass': idClicked == I985 }" id="I985">HYPATA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a>, Titus Flavius Cyllus</li>
<li :class="{'activeClass': idClicked == I986 }" id="I986">ILLYRICUM: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1986'>Herculius</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3243'>Probus</a></li>
<li :class="{'activeClass': idClicked == I987 }" id="I987">INDIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3782'>Zarmaros</a></li>
<li :class="{'activeClass': idClicked == I988 }" id="I988">IONIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem18*, Sem38* (Ionian allies)</li>
<li :class="{'activeClass': idClicked == I989 }" id="I989">ITALY: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem31*</li>
<li :class="{'activeClass': idClicked == I990 }" id="I990">JERUSALEM: Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1059'>CM17*</a></li>
<li :class="{'activeClass': idClicked == I991 }" id="I991">JEWS, JUDAEA: Herod; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2230'>Hyrkanos</a>; Jewish Mon.; Julia Berenike</li>
<li :class="{'activeClass': idClicked == I992 }" id="I992">KAISARIANI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a>, ram head spout</li>
<li :class="{'activeClass': idClicked == I993 }" id="I993">KARDIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</li>
<li :class="{'activeClass': idClicked == I994 }" id="I994">KARYSTOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP477'>Aristonikos</a></li>
<li :class="{'activeClass': idClicked == I995 }" id="I995">KAUNOS: Paralos, picture by Protogenes; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3634'>Thesmothetai</a>, picture by Protogenes</li>
<li :class="{'activeClass': idClicked == I996 }" id="I996">KEPHALARI: Hadrian, aqueduct</li>
<li :class="{'activeClass': idClicked == I997 }" id="I997">KEPHALE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP625'>Asty</a></li>
<li :class="{'activeClass': idClicked == I998 }" id="I998">KEPHISSIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3169'>Polydeukion</a></li>
<li :class="{'activeClass': idClicked == I999 }" id="I999">KERKYRA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP835'>Ath121*</a> (document relief); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3594'>Thersandros and Simylos</a></li>
<li :class="{'activeClass': idClicked == I1000 }" id="I1000">KITION: Zeno</li>
<li :class="{'activeClass': idClicked == I1001 }" id="I1001">KLEONAI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li>
<li :class="{'activeClass': idClicked == I1002 }" id="I1002">KNOSSOS: Epimenides</li>
<li :class="{'activeClass': idClicked == I1003 }" id="I1003">KOLIAS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP281'>Aph34*</a>, theater seat</li>
<li :class="{'activeClass': idClicked == I1004 }" id="I1004">KORONEIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem10*, Sem11*, Sem19*, Sem40*</li>
<li :class="{'activeClass': idClicked == I1005 }" id="I1005">KROTON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3062'>Phayllos</a></li>
<li :class="{'activeClass': idClicked == I1006 }" id="I1006">KYDONIA: Eumaridas</li>
<li :class="{'activeClass': idClicked == I1007 }" id="I1007">LACONIA: Part II no.105</li>
<li :class="{'activeClass': idClicked == I1008 }" id="I1008">LAKEDAIMONIANS: Alexander, inscription; Lakedaimonian shield; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2480'>Lakedaimonians</a>, statue base; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2480'>Lakedaimonians</a>, state burial mon.</li>
<li :class="{'activeClass': idClicked == I1009 }" id="I1009">LAMIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem20*</li>
<li :class="{'activeClass': idClicked == I1010 }" id="I1010">LAODIKEIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3089'>Philonides</a></li>
<li :class="{'activeClass': idClicked == I1011 }" id="I1011">LATIN INSCRIPTIONS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP625'>Asty</a> milestone; Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1031'>CM10*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1034'>CM11*</a> (2); Hadrian; Heraklios; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3000'>Paul</a></li>
<li :class="{'activeClass': idClicked == I1012 }" id="I1012">LEMNOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP767'>Ath72*</a></li>
<li :class="{'activeClass': idClicked == I1013 }" id="I1013">LESBOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a>, spear butt taken from the Lesbians</li>
<li :class="{'activeClass': idClicked == I1014 }" id="I1014">LIBYA: Unknown Gods</li>
<li :class="{'activeClass': idClicked == I1015 }" id="I1015">LIMNAI (Attic deme)</li>
<li :class="{'activeClass': idClicked == I1016 }" id="I1016">LOKRIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2342'>Kassandra</a>, Lokrian Aias etc., picture</li>
<li :class="{'activeClass': idClicked == I1017 }" id="I1017">LYCIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2619'>Melesandros</a></li>
<li :class="{'activeClass': idClicked == I1018 }" id="I1018">LYDIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2213'>Hydrophoros</a> dedicated by Lydians.See also under Philadelphia</li>
<li :class="{'activeClass': idClicked == I1019 }" id="I1019">MACEDON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP118'>Alexander the Great</a>; Antigonos; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1258'>Demetrios Poliorketes</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1389'>Diogenes Euergetes</a>, Macedonian phrourarch; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2515'>Licinius</a>, proconsul of Macedon; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2584'>Lysimachos</a>; Philip II; Philip V; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3268'>Ptolemies</a></li>
<li :class="{'activeClass': idClicked == I1020 }" id="I1020">MAGNESIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP537'>Art40*</a></li>
<li :class="{'activeClass': idClicked == I1021 }" id="I1021">MANTINEA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem21*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2592'>Mantinea</a>, picture</li>
<li :class="{'activeClass': idClicked == I1022 }" id="I1022">MARATHON: Eukleia; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and Attalos II; Herodes <a v-on:click.prevent="replaceLink"   href='NPP.html#NP873'>Atticus</a>, Marathonian hero; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2593'>Marathonomachia</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2657'>Miltiades</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2892'>Pan4*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3596'>Theseus</a> and Marathonian bull; see also Index I under <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3045'>Persians</a> and Persian Wars</li>
<li :class="{'activeClass': idClicked == I1023 }" id="I1023">MAURETANIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1773'>Glaphyra</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2304'>Juba II</a></li>
<li :class="{'activeClass': idClicked == I1024 }" id="I1024">MEDES: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP39'>Aglauros</a>; Chariot and fetters (re walls burnt by the Medes); see also Index I under <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3045'>Persians</a> and Persian Wars</li>
<li :class="{'activeClass': idClicked == I1025 }" id="I1025">MEGARA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem9*, Sem13*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2787'>Nisos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3297'>Pythion</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3503'>Telephanes</a></li>
<li :class="{'activeClass': idClicked == I1026 }" id="I1026">MENDE: Polykritos</li>
<li :class="{'activeClass': idClicked == I1027 }" id="I1027">MESOGAIA: Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM574">574</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM576">576</a></li>
<li :class="{'activeClass': idClicked == I1028 }" id="I1028">MILETOS: Athens and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2656'>Miletos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1012'>Charixenos</a> the Milesian; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM394">394</a> (Milesian kline)</li>
<li :class="{'activeClass': idClicked == I1029 }" id="I1029">MOLOSSIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP553'>Arybbas</a></li>
<li :class="{'activeClass': idClicked == I1030 }" id="I1030">MUNICHYIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP994'>Chairippos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem22*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2549'>Long Walls</a></li>
<li :class="{'activeClass': idClicked == I1031 }" id="I1031">MYSIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and Attalos II, Attalid dedication</li>
<li :class="{'activeClass': idClicked == I1032 }" id="I1032">NARBONESE GAUL: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3694'>Trebellius Rufus</a></li>
<li :class="{'activeClass': idClicked == I1033 }" id="I1033">NAUKRATIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1771'>Gerenos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3245'>Proklos</a></li>
<li :class="{'activeClass': idClicked == I1034 }" id="I1034">NAXOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP460'>Ariadne</a>; Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1088'>CM33*</a>. <a v-on:click.prevent="replaceLink"   href='NPP.Html#NP1088'>Nativity Relief</a></li>
<li :class="{'activeClass': idClicked == I1035 }" id="I1035">NEMEA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP130'>Alkibiades</a>, picture; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1948'>Hrk21*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1972'>Hrk41*</a></li>
<li :class="{'activeClass': idClicked == I1036 }" id="I1036">OCHRID: Aurelius Krates</li>
<li :class="{'activeClass': idClicked == I1037 }" id="I1037">OINOE</li>
<li :class="{'activeClass': idClicked == I1038 }" id="I1038">OLYMPIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2777'>Nike Olympia</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3879'>Zeu73*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3890'>Zeu83*</a> (<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3054'>Phaidyntes</a> at <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2838'>Olympia</a>)</li>
<li :class="{'activeClass': idClicked == I1039 }" id="I1039">OLYNTHOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem23*</li>
<li :class="{'activeClass': idClicked == I1040 }" id="I1040">OTTOMANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a></li>
<li :class="{'activeClass': idClicked == I1041 }" id="I1041">PAIONIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP885'>Audoleon</a></li>
<li :class="{'activeClass': idClicked == I1042 }" id="I1042">PALESTINE: Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1054'>CM16*</a>, stamped flasks; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1420'>Dio7*</a>, tripod base at Mt. Gerizim</li>
<li :class="{'activeClass': idClicked == I1043 }" id="I1043">PALLENE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a>, temple</li>
<li :class="{'activeClass': idClicked == I1044 }" id="I1044">PAPHOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1216'>Demeter</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a>, the Paphian kore</li>
<li :class="{'activeClass': idClicked == I1045 }" id="I1045">PARNES: Hadrian, aqueduct; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3896'>Zeu87*</a>; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM618">618</a></li>
<li :class="{'activeClass': idClicked == I1046 }" id="I1046">PAROS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2890'>Pan2*</a> with trophy of Parian marble</li>
<li :class="{'activeClass': idClicked == I1047 }" id="I1047">PARTHIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2596'>Marcus Aurelius</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2553'>Lucius Verus</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3767'>Victoria Romana</a></li>
<li :class="{'activeClass': idClicked == I1048 }" id="I1048">PATRAS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP625'>Asty</a> milestone (re Athens-Patras route)</li>
<li :class="{'activeClass': idClicked == I1049 }" id="I1049">PEIRAEUS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP508'>Art17*</a>, theater throne of Dadaphoros; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1977'>Hrk43*</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2549'>Long Walls</a>, Northern Wall; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2626'>Men</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2866'>Ouranios</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3002'>Peiraic Gate</a>; Propylon boundary stones; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM87">87</a></li>
<li :class="{'activeClass': idClicked == I1050 }" id="I1050">PEIRAEUS (continued), ROADS TO <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1733'>Euripides</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2630'>Menander</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3413'>Sokrates</a>; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM563">563</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM585">585</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM589">589</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM594">594</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM610">610</a></li>
<li :class="{'activeClass': idClicked == I1051 }" id="I1051">PELOPONNESOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP845'>Ath129*</a>, document relief</li>
<li :class="{'activeClass': idClicked == I1052 }" id="I1052">PERGAMON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and II; Eumenes II; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM113">113</a>-114</li>
<li :class="{'activeClass': idClicked == I1053 }" id="I1053">PERINTHOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP421'>Apollodoros</a></li>
<li :class="{'activeClass': idClicked == I1054 }" id="I1054">PERSIANS: <a v-on:click.prevent="replaceLink"   href='GenIndex.html'>See Index I</a> under <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3045'>Persians</a> and Persian Wars</li>
<li :class="{'activeClass': idClicked == I1055 }" id="I1055">PHALERON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1254'>Demetrios of Phaleron</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1905'>Hera</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2549'>Long Walls</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2716'>Mousaios</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2883'>Palladion</a>; Unknown Gods, altar</li>
<li :class="{'activeClass': idClicked == I1056 }" id="I1056">PHALERON (continued), ROADS TO: Antiope; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3057'>Phaleric Road</a>; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM580">580</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM582">582</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM584">584</a></li>
<li :class="{'activeClass': idClicked == I1057 }" id="I1057">PHASELIS: Theodektes</li>
<li :class="{'activeClass': idClicked == I1058 }" id="I1058">PHERAI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP117'>Alexander of Pherai</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP541'>Art43**</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2947'>Pandocheion</a></li>
<li :class="{'activeClass': idClicked == I1059 }" id="I1059">PHILADELPHIA (in Lydia): <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1821'>Hadrian Olympios</a></li>
<li :class="{'activeClass': idClicked == I1060 }" id="I1060">PHLIUS</li>
<li :class="{'activeClass': idClicked == I1061 }" id="I1061">PHOENICIA: Antipatros, Phoenician inscr.; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem13*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2593'>Marathonomachia</a>, Phoenician ships</li>
<li :class="{'activeClass': idClicked == I1062 }" id="I1062">PHRYGIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2650'>Metragyrtes</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3751'>Ulpius Apuleius Eurykles</a></li>
<li :class="{'activeClass': idClicked == I1063 }" id="I1063">PHYLE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3643'>Thrasyboulos</a></li>
<li :class="{'activeClass': idClicked == I1064 }" id="I1064">PISA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3890'>Zeu83*</a></li>
<li :class="{'activeClass': idClicked == I1065 }" id="I1065">PLATAIA, PLATAIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem16*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2593'>Marathonomachia</a>, Plataians; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a>, Eudemos of Plataia</li>
<li :class="{'activeClass': idClicked == I1066 }" id="I1066">PONTOS: Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM289">289</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM358">358</a> (Pharnakes I)</li>
<li :class="{'activeClass': idClicked == I1067 }" id="I1067">POTEIDAIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem24*, Sem25*</li>
<li :class="{'activeClass': idClicked == I1068 }" id="I1068">PYLOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li>
<li :class="{'activeClass': idClicked == I1069 }" id="I1069">RHAMNOUS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2746'>Nemesis</a>; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM336">336</a></li>
<li :class="{'activeClass': idClicked == I1070 }" id="I1070">RHEGION: Silenos</li>
<li :class="{'activeClass': idClicked == I1071 }" id="I1071">ROME: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem31*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2151'>Hestia</a> of the Romans; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2657'>Miltiades</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3466'>Stoa of the Roman</a>. For Romans active in Athens, <a v-on:click.prevent="replaceLink"   href='PersonIndex.html'>See Index VI</a></li>
<li :class="{'activeClass': idClicked == I1072 }" id="I1072">SALAMIS: Aias; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a>; Kerykos lithos; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3339'>Salamis</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3572'>Themistokles</a> on a galley; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM87">87</a></li>
<li :class="{'activeClass': idClicked == I1073 }" id="I1073">SALAMIS ON CYPRUS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1744'>Evagoras</a></li>
<li :class="{'activeClass': idClicked == I1074 }" id="I1074">SARDIS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2213'>Hydrophoros</a> dedicated at Sardis</li>
<li :class="{'activeClass': idClicked == I1075 }" id="I1075">SARMATIA: Sarmatian cuirass</li>
<li :class="{'activeClass': idClicked == I1076 }" id="I1076">SCYTHIA: Scythian archers; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3682'>Toxaris</a></li>
<li :class="{'activeClass': idClicked == I1077 }" id="I1077">SELYMBRIA: Pythagoras</li>
<li :class="{'activeClass': idClicked == I1078 }" id="I1078">SERDICA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3830'>Zeus Hypatos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3832'>Zeu35*</a></li>
<li :class="{'activeClass': idClicked == I1079 }" id="I1079">SERMYLIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li>
<li :class="{'activeClass': idClicked == I1080 }" id="I1080">SESTOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP834'>Ath120*</a>, document relief</li>
<li :class="{'activeClass': idClicked == I1081 }" id="I1081">SICILY: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem16*, Sem37*</li>
<li :class="{'activeClass': idClicked == I1082 }" id="I1082">SIDON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2961'>Pankrates</a> (altar dedicated by Sidonian); <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3086'>Philokles</a></li>
<li :class="{'activeClass': idClicked == I1083 }" id="I1083">SIGEION: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</li>
<li :class="{'activeClass': idClicked == I1084 }" id="I1084">SIKYON: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP830'>Ath117*</a>, document relief</li>
<li :class="{'activeClass': idClicked == I1085 }" id="I1085">SINGOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li>
<li :class="{'activeClass': idClicked == I1086 }" id="I1086">SINOPE: Diodoros</li>
<li :class="{'activeClass': idClicked == I1087 }" id="I1087">SKIONE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3399'>Skionians</a>, shields of</li>
<li :class="{'activeClass': idClicked == I1088 }" id="I1088">SKYROS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP17'>Achilles</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3596'>Theseus</a>' bones</li>
<li :class="{'activeClass': idClicked == I1089 }" id="I1089">SLAVS: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM301">301</a></li>
<li :class="{'activeClass': idClicked == I1090 }" id="I1090">SOLOI (in Cilicia): <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1153'>Chrysippos</a></li>
<li :class="{'activeClass': idClicked == I1091 }" id="I1091">SOUNION: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3213'>Post-Herulian Wall</a>, column capital; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM366">366</a> (<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> temple), 578 (road to Sounion)</li>
<li :class="{'activeClass': idClicked == I1092 }" id="I1092">SPARTA: Anchimolios</li>
<li :class="{'activeClass': idClicked == I1093 }" id="I1093">SPHAKTERIA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2479'>Lakedaimonian Shield</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a>, bronze statue</li>
<li :class="{'activeClass': idClicked == I1094 }" id="I1094">STAGEIRA: Aristotle</li>
<li :class="{'activeClass': idClicked == I1095 }" id="I1095">SYRACUSE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem26*</li>
<li :class="{'activeClass': idClicked == I1096 }" id="I1096">SYRIA,SYRIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3089'>Philonides</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3090'>Philopappos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> Olympios Oly74*</li>
<li :class="{'activeClass': idClicked == I1097 }" id="I1097">TANAGRA: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem27*; Melanopos and Makartatos</li>
<li :class="{'activeClass': idClicked == I1098 }" id="I1098">TARENTUM: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2781'>Nikokles</a></li>
<li :class="{'activeClass': idClicked == I1099 }" id="I1099">TARSOS (?): <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP409'>Apo79*</a></li>
<li :class="{'activeClass': idClicked == I1100 }" id="I1100">THASOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*; Hadrian, statues of Thasian marble</li>
<li :class="{'activeClass': idClicked == I1101 }" id="I1101">THEBES In Egypt: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1991'>Hermaios</a></li>
<li :class="{'activeClass': idClicked == I1102 }" id="I1102">THERMOPYLAI: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2323'>Kallippos</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2678'>Moirokles</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3817'>Zeu23*</a> (Kydias1 shield)</li>
<li :class="{'activeClass': idClicked == I1103 }" id="I1103">THESSALY, THESSALIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem20*, Sem28*, Sem39*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a> (Titus Flavius Cyllus); Peirithoos; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3085'>Philiskos</a>; Phoenix</li>
<li :class="{'activeClass': idClicked == I1104 }" id="I1104">THORIKOS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3642'>Thorikian Rock</a>; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM366">366</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM369">369</a> (Doric columns), 474 (<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3642'>Thorikian Rock</a>)</li>
<li :class="{'activeClass': idClicked == I1105 }" id="I1105">THRACE,THRACIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP849'>Ath132*</a>, document relief; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP917'>Bendis</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem9*, Sem25*; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2286'>Isodaites</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2440'>Kotys</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2442'>Kottyto</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2657'>Miltiades</a>; Rhaskopouris; Rhoimetalkes; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3832'>Zeu35*</a></li>
<li :class="{'activeClass': idClicked == I1106 }" id="I1106">TOULOUSE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3694'>Trebellius Rufus</a></li>
<li :class="{'activeClass': idClicked == I1107 }" id="I1107">TROIZEN: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3596'>Theseus</a> lifting the rock</li>
<li :class="{'activeClass': idClicked == I1108 }" id="I1108">TROJANS, TROY: Hadrian, statue dedicated by Trojans; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1396'>Diomedes</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2342'>Kassandra</a>; <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2883'>Palladion</a>, statue brought from Troy; Trojan Horse</li>
<li :class="{'activeClass': idClicked == I1109 }" id="I1109">TURKISH INSCRIPTION: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM30">30</a></li>
<li :class="{'activeClass': idClicked == I1110 }" id="I1110">TYANA: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM343">343</a></li>
<li :class="{'activeClass': idClicked == I1111 }" id="I1111">TYRE: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2637'>Menodoros</a></li>
<li :class="{'activeClass': idClicked == I1112 }" id="I1112">VENETIANS: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li :class="{'activeClass': idClicked == I1113 }" id="I1113">VOUNO, aqueduct to: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM619">619</a> </li>
<li :class="{'activeClass': idClicked == I1114 }" id="I1114">ZELEIA: Arthmios Decree </li>
<li :class="{'activeClass': idClicked == I1115 }" id="I1115">ZOSTER: <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP411'>Apo81*</a>, theater throne</li>
</ul>



</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    