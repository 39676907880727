
<template> 
<div class="part-text"> 
<h2>Judith Binder, Monuments and Sites of Athens</h2>
<h2 :class="{'activeClass': idClicked == NP1 }" id="NP1" >PART I: MONUMENTS, SITES, PLACE-NAMES AND CULTS WITH CLEAR-CUT NAMES IN ALPHABETICAL ORDER</h2>
<p :class="{'activeClass': idClicked == NP2 }" id="NP2" >ABECEDARIA: Agora XXI, 6-7</p>
<p :class="{'activeClass': idClicked == NP3 }" id="NP3" >First half of an abecedarium on a pyramidal loomweight with a horse-and-rider graffito on the bottom, ca. 725-625 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/mc%20907" target="_blank">Agora MC 907</a>; Agora XXI, 7 cat. no. A1, pl. 1; Jeffery, L. H. 1990, 431 no. 29</p>
<p :class="{'activeClass': idClicked == NP4 }" id="NP4" >Abecedarium on a lead plaque, probably numerals, Akr.: Threatte, L. 1980, 23</p>
<p :class="{'activeClass': idClicked == NP5 }" id="NP5" >Abecedarium on Pentelic marble, Roman pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203005" target="_blank">Agora I 3005</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 23 (1954), 283 no. 181, pl. 62; SEG 14 (1957) no. 295</p>
<p :class="{'activeClass': idClicked == NP6 }" id="NP6" >Abecedarium on Hymettian marble, Roman pd. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205529" target="_blank">Agora I 5529</a>; Meritt, B. D. 1961, 289 no. 183, pl. 61; SEG 21 (1965) no. 663</p>
<p :class="{'activeClass': idClicked == NP7 }" id="NP7" >ACADEMY: see Akademia</p>
<p :class="{'activeClass': idClicked == NP8 }" id="NP8" >ACADEMY EXCAVATIONS For monuments and sites without clear-cut names found in the Academy and vicinity, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM430">430</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM461">461</a></p>
<p :class="{'activeClass': idClicked == NP9 }" id="NP9" >ACANTHUS COLUMNS</p>
<p :class="{'activeClass': idClicked == NP10 }" id="NP10" >One found on the Akropolis: Inst. phot. Acr. 2007; E. G. Stikas, «Τρίπλευρα κιονόκρανα, κορυφώματα και μνημεία,» AE 1961, 166-167, fig. 15</p>
<p :class="{'activeClass': idClicked == NP11 }" id="NP11" >One found in northwest Athens, Hellenistic, Acharnon and Hag. Meletiou Sts. (Plan 3 gamma—5): P. Courbin, “Chronique des fouilles en 1953,” BCH 78 (1954) 108</p>
<p :class="{'activeClass': idClicked == NP12 }" id="NP12" >ACHAIA: see Demeter Achaia; Demeter Kourotrophos Achaia</p>
<p :class="{'activeClass': idClicked == NP13 }" id="NP13" >ACHARNIAN AQUEDUCT: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM618">618</a></p>
<p :class="{'activeClass': idClicked == NP14 }" id="NP14" >ACHARNIAN GATE: IG II² 2776 lines 70-71; Miller, S. G. 1972, 70, 76; SEG 43 (1993) no. 58; see Themistoklean City Wall Wal12*</p>
<p :class="{'activeClass': idClicked == NP15 }" id="NP15" >ACHELOOS: LIMC 1,1981, 23-24, nos. 169-172, 191, 201-2, 204, s.v. Acheloos, (H. P. Isler)</p>
<p :class="{'activeClass': idClicked == NP16 }" id="NP16" >Acheloos and The Nymphs, boundary stone?, 500-450 B.C. Athens or Attica: IG I³ 1061 = IG I² 773 See also: Dionysos Dio7*, Tripod Base; Herakles Hrk38*; Hermes Hrm33*, Hrm37*; Nymphs Nym7*, Nym9*, Nym11*; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM528B">528B</a></p>
<p :class="{'activeClass': idClicked == NP17 }" id="NP17" >ACHILLES</p>
<p :class="{'activeClass': idClicked == NP18 }" id="NP18" >Achilles at Skyros, picture by Polygnotos in the Propylaia northwest chamber: Paus. 1.22.6; LIMC I, 1981, 57, no. 95, s.v. Achilleus (A. Kossatz-Deissmann)</p>
<p :class="{'activeClass': idClicked == NP19 }" id="NP19" >Statue of the hero Achilles in a small shrine in front of the statue of Athena in the Parthenon, set up by a hierophant Nestorios ca. 375 A.D.: Zos. 4.18.2-3; Clinton, K. 1974, 43; T. M. Banchich, “Nestorius ιεροφαντειν τεταγμένος,” Historia XLVII (1998) 360-374 </p>
<p :class="{'activeClass': idClicked == NP20 }" id="NP20" >ACROPOLIS: see Akropolis</p>
<p :class="{'activeClass': idClicked == NP21 }" id="NP21" >ADONIS, cult: Plu. Ale. 18.2-3; LIMC I, 1981, 222-223, nos. 45-49, s.v. Adonis (B. Servais-Soyez); Simms, R. R. 1985, 209-263</p>
<p :class="{'activeClass': idClicked == NP22 }" id="NP22" >ADRASTOS: see Peirithoos</p>
<p :class="{'activeClass': idClicked == NP23 }" id="NP23" >AEGIS of gilded bronze with a head of Medusa the gorgon, on the Akropolis south wall above the theater, dedicated by Antiochos IV; Paus. 1.21.3, 5.12.4; represented on coins, O. Morkholm, Studies in The Coinage of Antiochus IV of Syria, Copenhagen, 1963, 14, 23; Chr. Habicht, “Athen und die Seleukiden,” Chiron 19 (1989) 11-12; Schenkungen hellenistischer Herrscher, Part I, 1995, 53-54; P. Themelis, “Macedonian Dedications on the Akropolis,” in Macedonians in Athens 2003, 168-172</p>
<p :class="{'activeClass': idClicked == NP24 }" id="NP24" >AELIUS CAESAR, L., father of Lucius Verus, statue <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20335" target="_blank">Agora S 335</a>; Vermeule, C. C. 1968, 263, 430-431, 515 note 24</p>
<p :class="{'activeClass': idClicked == NP25 }" id="NP25" >AESCULAPIUS: see Asklepios Ask9*</p>
<p :class="{'activeClass': idClicked == NP26 }" id="NP26" >AESOP, large statue set up by the Athenians (not necessarily in Athens): Phaedr. 2.9.1; Richter, G. M. A. 1984, 80</p>
<p :class="{'activeClass': idClicked == NP27 }" id="NP27" >AGATHE THEA, sanctuary attested by an honorary decree of a religious association, 3rd c. B.C., Iera Odos and Iakchou St., northeast of the Kerameikos Excavations (Plan 2—delta) — SEG 48 (1998) 130; Metro Excavations 1992-1997, 392 (I. Tsirigoti-Drakotou), no catalogue entry, no photo <JBcom>[pace I. T.-D. no reason to assign the decree to the sanctuary of Ariste and Kalliste]</JBcom></p>
<p :class="{'activeClass': idClicked == NP28 }" id="NP28" >AGATHE TYCHE: S. V. Tracy, “IG II² 1195 and Agathe Tyche in Attica,” Hesperia 63 (1994), 241-244; SEG 48 (1998) no. 275; I. Leventi. «Παρατηρήσεις στα αττικά αναθηματικά ανάγλυφα του υστέρου 4ου και προωίμου 3ου αι. π.Χ.,» in Macedonians in Athens 2003, 128-134</p>
<p :class="{'activeClass': idClicked == NP29 }" id="NP29" >Base, 1st-2nd c. A.C., Akr. west slope near the Areopagus - - - IG II² 4761</p>
<p :class="{'activeClass': idClicked == NP30 }" id="NP30" >Relief, 4th c. B.C., Akropolis South Slope at the Asklepieion - - - NM 1343; IG II² 4644; Svoronos, J. N. 1908, 261-263, no. 40, pl. 34 no. 6; an architectural relief deriving from an altar or statue base, I. Leventi, op. cit, 128-129, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP31 }" id="NP31" >Sanctuary attested by an inscription of 335/334 B.C. concerning the renewal of sanctuaries: IG II² 333 line 20; Tracy, S. V. 1994, 242-243; perhaps in Kollytos, ibid. 241 note 9</p>
<p :class="{'activeClass': idClicked == NP32 }" id="NP32" >Statue at the Prytaneion: Ael. VH 9.39 See also: Agathos Daimon; Herakles Hrk38*; Twelve Gods; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM341">341</a></p>
<p :class="{'activeClass': idClicked == NP33 }" id="NP33" >AGATHOS DAIMON: LIMC I, 1981, 277-282 s.v. Agathodaimon (F. Dunand)</p>
<p :class="{'activeClass': idClicked == NP34 }" id="NP34" >Base, Akropolis IG II² 4819</p>
<p :class="{'activeClass': idClicked == NP35 }" id="NP35" >Dedication by the prytaneis of Kekropis, round Eleusinian limestone base, 343/342 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202003" target="_blank">Agora I 2003</a>; Meritt, B. D. 1941, 40 no. 7, ph.</p>
<p :class="{'activeClass': idClicked == NP36 }" id="NP36" >Agathos Daimon, Agathe Tyche and Philia (?), inscribed relief base, found in 1839 east of the Parthenon - - - Acr. 4069; Walter, O. 1923, 184-186, no. 391, ph.; SEG 47(1997) no. 210</p>
<p :class="{'activeClass': idClicked == NP37 }" id="NP37" >AGELAA: see Athena Ath53*</p>
<p :class="{'activeClass': idClicked == NP38 }" id="NP38" >AGELASTOS PETRA: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM462">462</a></p>
<p :class="{'activeClass': idClicked == NP39 }" id="NP39" >AGLAUROS: LIMC I, 1981, 283-298, s.v. Aglauros (U. Kron); Kearns, E. 1989, 139-140</p>
<p :class="{'activeClass': idClicked == NP40 }" id="NP40" >Sanctuary of Aglauros near the place in front of the Akropolis, but behind the gates and the road up, whence the Persians got into the Akropolis: Hdt. 8.53.1; alongside of the Propylaia of the Akropolis, FGrHist III B 328 Philochoros 105; temenos of Aglauros above the sanctuary of the Dioskouroi, Paus. 1.18.2.; place where the ephebes swore their oath, Plu. Ale. XV,4; for a rich collection of testimonia in an article whose conclusions the author has retracted, see M. Ervin, “The Sanctuary of Aglauros on the south slope of the Akropolis . . .,” ΑΡΧΕΙΟΝ ΠΟΝΤΟΥ 22(1958) 133-136 and passim (an offprint of this article is in the Library of the American School of Classical Studies at Athens); although no ancient source attests a cave of Aglauros, the sanctuary is <JBcom>[mistakenly]</JBcom> located in the Akropolis North Slope cleft (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM198">198</a>), dubbed “Cave of Aglauros,” Travlos, J. 1971, 1,2,52,72, 228, 578, fig. 5 no. 11, fig. 91 no. 136, fig. 93, fig. 722 Q; <JBcom>[the temenos was probably on the Akropolis North Slope at Cave Gamma ]</JBcom>; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM188">188</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM197">197</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM198">198</a> (3)</p>
<p :class="{'activeClass': idClicked == NP41 }" id="NP41" >Decree honouring Timokrite, the priestess of Aglauros, to be set up in the sanctuary of Aglauros, 250/249 B.C., the stele found in situ <JBcom>[?]</JBcom> on the Akropolis East Slope below the East Cave (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM186">186</a>) - - - Acr. 13371; SEG 51 (2001) no. 93; Dontas, G. S. 1983, 48-63, figs. 1-3, pls. 13-15; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM188">188</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM189">189</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM197">197</a>-198. <JBcom>[There is no proof that the stele, encountered by chance as workmen were digging the trench for the new retaining wall of the Peripatos, was found in situ. Inexplicably no excavation was undertaken. The title of the editio princeps, “The True Aglaurion,” implies that all of the previously known literary, epigraphical, and archaeological evidence hitherto considered to refer to the topography of the monuments and sites of the Akropolis northern and western slopes may be reinterpreted to fit a site for the sanctuary of Aglauros on the Akropolis east slope. On the one hand, G. S. Dontas and those who follow after him by no means take account of all of the archaeological, epigraphical and literary evidence for those monuments and sites; on the other hand, bypassing the way in which the testimonia interlock and reinforce each other, they arbitrarily wrench each piece of evidence out of context in order to argue for a site on the Akropolis east slope.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP42 }" id="NP42" >Offering table: Acr. 13371 line 29; Dontas, G. S. 1983, 52</p>
<p :class="{'activeClass': idClicked == NP43 }" id="NP43" >Dedication on a round base by the priestess, Pheidostrate, sister of Chremonides, ca. 280 B.C., Akr.  IG II² 3459; Ferguson, W. S. 1938, 20-21 See also: Herse; Kourotrophos from Aglauros; for the unattested Cave of Aglauros, see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM198">198</a></p>
<p :class="{'activeClass': idClicked == NP44 }" id="NP44" >AGNOSTOS THEOS: see Unknown God</p>
<p :class="{'activeClass': idClicked == NP45 }" id="NP45" >AGORA, place name: Paus. 1.17.1 + 2; Agora III, passim</p>
<p :class="{'activeClass': idClicked == NP46 }" id="NP46" >Agora Boundary Stones: Agora XIV, 117-119; Ritchie Jr., C. E. 1985, 15-41, 646-652, pls. 2-6, figs. 1-3; Agora XIX, 10-11, 27-28; SEG 46 (1996) no. 56</p>
<p :class="{'activeClass': idClicked == NP47 }" id="NP47" >1) Poros limestone, mid-5th c. B.C. IG I³ 1090; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203226" target="_blank">Agora I 3226</a>; Agora XIX, 28 H28, pl. 2</p>
<p :class="{'activeClass': idClicked == NP48 }" id="NP48" >2) Island marble, ca. 500 B.C., found in situ at the road fork southeast of the Tholos - - - IG I³ 1087; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205510" target="_blank">Agora I 5510</a>; Agora XIV, 117-118, pls. 4, 64:a; Agora XIX, 27 H 25, pl. 2; the three early stones (nos. 2-4) plausibly downdated to 500 - 480/479, Papadopoulos, J. K. 2003, 289-291, figs. 5, 7-9</p>
<p :class="{'activeClass': idClicked == NP49 }" id="NP49" >3) Marble, ca. 500 B.C., reused in a Christian grave below the Hephaisteion floor — IG I³ 1089; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205675" target="_blank">Agora I 5675</a>; Shear, T. L 1940, 266 fig. 3; Agora XIX, 27 H27</p>
<p :class="{'activeClass': idClicked == NP50 }" id="NP50" >4) Parian marble, ca. 500 B.C., facing east at the roadside ca. 21 m. south of boundary stone <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205510" target="_blank">Agora I 5510</a> (no. 2 above) - - - IG I³ 1088; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207039" target="_blank">Agora I 7039</a>; Agora XIV, 117-118, pls. 4, 64:b; Agora XIX, 27 H26, pl. 2</p>
<p :class="{'activeClass': idClicked == NP51 }" id="NP51" >5) Uninscribed marble frag., probably an Agora marker stone, east of the Post-Herulian Wall <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203534" target="_blank">Agora A 3534</a>; Agora XIX, 27 under H25</p>
<p :class="{'activeClass': idClicked == NP52 }" id="NP52" >AGORA in the sense of market: X. Oec. 8.22; Agora III, 185-206</p>
<p :class="{'activeClass': idClicked == NP53 }" id="NP53" >AGORA, OLD (the Archaia Agora) near Aphrodite Pandemos: Harp. s.v. Πάνδημος Άφροδίτη; Wycherley, R. E. 1966, 285-293; <JBcom>[mistakenly]</JBcom> located on the Akr. northwest slope below the Peripatos near the Roman Agora, Travlos, J. 1971, 1,28 (“the Roman Agora ... is situated near the Old Agora founded by Theseus” ), 198 “the Eleusinion was founded in the Old Agora”), 210 (earlier Eponymous Hero Monument); <JBcom>[mistakenly]</JBcom> located east of the Akropolis, Dontas, G. S. 1983, 62-63; <JBcom>[mistakenly]</JBcom> located on the eastern part of the Akropolis North Slope, Miller, S. G. 1994, 202-203, 211-212, 214, 215, 219, fig. 1 AA; Schnurr, C. 1995a, 131-138, plans 1-2; Papadopoulos, J. K. 2003, 280-284, 288, fig. 5:15; <JBcom>[as Harpokration tells us the sanctuary of Aphrodite Pandemos is intimately associated with the Old Agora]</JBcom></p>
<p :class="{'activeClass': idClicked == NP54 }" id="NP54" >AGORA, ROMAN: see Roman Agora</p>
<p :class="{'activeClass': idClicked == NP55 }" id="NP55" >AGORA KEKROPIA adorned by Kimon: Melanthius cited by Plu. Cim. 4.7; Schnurr, C. 1995a, 132 note 1</p>
<p :class="{'activeClass': idClicked == NP56 }" id="NP56" >AGORA OF SOLON”: <JBcom>[invented modern]</JBcom> name for the Agora, Travlos, J. 1971,1, 2 lines 19-20, 4 (“Solon's Agora” ), 28 (Agora founded by Solon”), 158 ("great building programme begun by Solon”)</p>
<p :class="{'activeClass': idClicked == NP57 }" id="NP57" >AGORA OF THESEUS”: an <JBcom>[imaginary]</JBcom> earlier Agora, founded by Theseus after the synoikismos ... probably located below the Akropolis and the Peripatos on the northwest side of the hill,” Travlos, J. 1971, 1, 2, 28, 198</p>
<p :class="{'activeClass': idClicked == NP58 }" id="NP58" >AGORA IN KOILE: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203244" target="_blank">Agora I 3244</a> line 17; Ferguson, W. S. 1938, 55</p>
<p :class="{'activeClass': idClicked == NP59 }" id="NP59" >AGORA IN KOLLYTOS: see Kollytos</p>
<p :class="{'activeClass': idClicked == NP60 }" id="NP60" >AGORA OF THE ARGIVES: Hsch. s.v.; Agora III, 202 under no. 673</p>
<p :class="{'activeClass': idClicked == NP61 }" id="NP61" >AGORA OF THE KERKOPES where stolen goods were sold, near the Heliaia: Eust. Od. 2.7; Agora III, nos. 669-673</p>
<p :class="{'activeClass': idClicked == NP62 }" id="NP62" >AGORA OF THE SKAMBONIDAI: IG I³ 244 A lines 20-21 and C lines 9-10; SEG 25 (1971) no. 42</p>
<p :class="{'activeClass': idClicked == NP63 }" id="NP63" >AGORA EXCAVATIONS For monuments and sites without clear-cut names in the Agora Excavations and Agora Surroundings, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM266">266</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM387">387</a></p>
<p :class="{'activeClass': idClicked == NP64 }" id="NP64" >AGORAIOS: see Hermes Hrm15*, Hrm16*; Kolonos Agoraios; Zeus Zeu8*</p>
<p :class="{'activeClass': idClicked == NP65 }" id="NP65" >AGORANOMION, dedicated 138-161 A.D., at the Roman Agora - - - IG II² 3391; H. S. Robinson, “The Tower of the Winds and the Roman Market-Place,” AJA 47(1943) 303-305; probably at the ancient road intersection west of the Gate of Athena Archegetis, Hoff, M. C. 1994, 93 with notes 3-5; cf. Athena Ath55*</p>
<p :class="{'activeClass': idClicked == NP66 }" id="NP66" >Official charcoal measure of white marble, 1st c. A.C. 1st Ephor. Rho Alpha inv. 745; S. N. Koumanoudis, «Έπιγραφαι έξ Άθηνών,» Deltion 25 (1970) A, Meletai, 58 no. 5; SEG 36 (1986) no. 233</p>
<p :class="{'activeClass': idClicked == NP67 }" id="NP67" >AGRAI, district on the Ilissos left bank: Paus. 1.19.6; Judeich, W. 1931, 45 with note 2, 165, 176 note 6, 416 with note 6; Billot, M.-F. 1992, 121, 128-129, 131, 139-141; R. Simms, “Agra and Agrai,” GRBS 43 (2002/3) 219-229; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM536">536</a></p>
<p :class="{'activeClass': idClicked == NP68 }" id="NP68" >AGRIPPA = M. Vipsanius Agrippa. R. Hanslik, RE IX A1, 1961, Nachtrage, col. 1226-1275 (M. Vipsanius Agrippa)</p>
<p :class="{'activeClass': idClicked == NP69 }" id="NP69" >Odeion of Agrippa, constructed before 12 B.C., destroyed by fire and rebuilt as a lecture hall ca. 150 A.D: Thompson, H. A. 1950, 31-141, figs. 1-21, pls. 16-20; Travlos, J. 1971, 365-371, figs. 34 no. 65, 469-491; Agora Guide 1990, 28, 118 ff., 196 f., figs. 7, 19, 70-75</p>
<p :class="{'activeClass': idClicked == NP70 }" id="NP70" >Called the Odeion (Agrippa not mentioned): Paus. 1.8.6 + 14.1</p>
<p :class="{'activeClass': idClicked == NP71 }" id="NP71" >Called Agrippeion, theater in the Kerameikos where, among others, the sophists Herodes Atticus, Alexander and Philagros (both from Cilicia) held forth: Philostr. VS 2.5.3 (571) + 8.3-4 (580); Agora III, no. 522</p>
<p :class="{'activeClass': idClicked == NP72 }" id="NP72" >Epistyle fragment preserving one letter of the dedicatory inscription - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206243" target="_blank">Agora I 6243</a>; Meritt, B. D. 1961, 270-271 no.102, pl. 53</p>
<p :class="{'activeClass': idClicked == NP73 }" id="NP73" >Stage front with male and female herms <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201213" target="_blank">Agora S 1213</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM1391">1391</a> +; Agora XI, 139-140, 169-171 nos. 219-225, pl. 59</p>
<p :class="{'activeClass': idClicked == NP74 }" id="NP74" >Stamped rooftiles belonging to the repair, ca. 150 A.D., with the name of the contractor or fabricant ΑΡΧ<JBcom>[ΙΤΕΚΤΩΝ]</JBcom> ΔΙΟΝΥΣΙΟΣ: Camp, J. McK. 1989, 51, pl. 8, figs. 3-4; SEG 39 (1989) no. 225; originally assigned to an archon Dionysios, Thompson, H. A. 1950, 125-126, fig. 7, pl. 38; <JBcom>[As Homer Thompson conjectured, ibid. 133 note 3, Herodes Atticus may well have financed the rebuilding of the Odeion as a lecture hall]</JBcom></p>
<p :class="{'activeClass': idClicked == NP75 }" id="NP75" >Tritons and Giants  belonging to the repair ca. 150 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201214" target="_blank">Agora S 1214</a> +; ibid. 103-124, 137-139, figs. 16-20, pls. 60-72; Agora Guide 1990, 118-123, 197, figs. 70, 75; dated ca. 140 A.D. at the latest, Despinis, G. 2003, 114-116, 140, figs. 271-274; for the Tritons and Giants placed on new bases and reused in the Villa of the Giants, see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM373">373</a></p>
<p :class="{'activeClass': idClicked == NP76 }" id="NP76" >Statue base, ca. 23 B.C., west of the Propylaia - - - IG II² 4123; Geagan, D. 1967, 156</p>
<p :class="{'activeClass': idClicked == NP77 }" id="NP77" >Relief portrait - - - Boston MFA 99.347; Comstock, Μ. B. and C. C. Vermeule 1976, no. 330, ph.</p>
<p :class="{'activeClass': idClicked == NP78 }" id="NP78" >Agrippa Monument: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM114">114</a> </p>
<p :class="{'activeClass': idClicked == NP79 }" id="NP79" >AGROTERA: see Artemis Art18*-20*</p>
<p :class="{'activeClass': idClicked == NP80 }" id="NP80" >AGRYLE, demes of Upper and Lower Agryle: Harp. s.v. Άρδηττός; southwest of Ardettos, Traill, J. S. 1986, 125 with Map</p>
<p :class="{'activeClass': idClicked == NP81 }" id="NP81" >AGYIEUS: see Apollo Apo22*-24*, Apo65*</p>
<p :class="{'activeClass': idClicked == NP82 }" id="NP82" >AIAKEION, ca. 506 B.C., temenos of the Aiginetan hero Aiakos in the Agora built in accordance with a Delphic oracle: Hdt. 5.89; POxy 2087 lines 16-18; Kearns, E. 1989, 47; the Aiakeion made watertight and provided with a door to serve as a public granary, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207557" target="_blank">Agora I 7557</a>, lines 14-16; Stroud, R. S. 1998, 51-53, 54, 85-108, 111, figs. 6-7; SEG 48 (1998) nos. 69, 96; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM266">266</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM345">345</a></p>
<p :class="{'activeClass': idClicked == NP83 }" id="NP83" >AIAS OF SALAMIS, son of Telamon</p>
<p :class="{'activeClass': idClicked == NP84 }" id="NP84" >Aianteion: IG II² 1008 line 87; Agora III, 90-92, 225; probably at the Eurysakeion, Kron, U. 1976, 172-174; Kearns, E. 1989, 82, 141-142; probably on Salamis, Stroud, R. S. 1998, 89-90, 103</p>
<p :class="{'activeClass': idClicked == NP85 }" id="NP85" >Eponymous Hero statue: Paus. 1.5.2; Kron, U. 1976, 30, 171-176; LIMC I, 1981, 315 no. 2, s.v. Aias I (O. Touchefeu)</p>
<p :class="{'activeClass': idClicked == NP86 }" id="NP86" >AIDOS: LIMC I, 1981, 351-352, s.v. Aidos (F. Eckstein)</p>
<p :class="{'activeClass': idClicked == NP87 }" id="NP87" >Altar: seen “in the Agora,” Paus. 1.17.1; <JBcom>[probably in the Roman Agora]</JBcom></p>
<p :class="{'activeClass': idClicked == NP88 }" id="NP88" >Theater seat for a sacred official - - - IG II² 5147</p>
<p :class="{'activeClass': idClicked == NP89 }" id="NP89" >AIDOS AND APHELIA, altars on the Akr.: F GR HIST III B, 176, Istros 334 F 25, with suppl. vols. I, 642-3 and II, 516</p>
<p :class="{'activeClass': idClicked == NP90 }" id="NP90" >AIGEUS: LIMC 1,1981, 359-360 (literary sources), s.v. Aigeis (U. Kron); Kearns, E. 1989, 142</p>
<p :class="{'activeClass': idClicked == NP91 }" id="NP91" >Aigeus' gate, east of the Delphinion: Plu. Thes. 12.3; Kron, U. 1976, 125-126 note 574; cf. Themistoklean City Wall Wal20*</p>
<p :class="{'activeClass': idClicked == NP92 }" id="NP92" >Heroon: Paus. 1.22.5; Kron, U. 1976, 124-127; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM141">141</a></p>
<p :class="{'activeClass': idClicked == NP93 }" id="NP93" >Aigeus' house at the Delphinion site: see Apollo Apo33*; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM549">549</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM550">550</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM554">554</a></p>
<p :class="{'activeClass': idClicked == NP94 }" id="NP94" >Eponymous Hero statue: Paus. 1.5.2</p>
<p :class="{'activeClass': idClicked == NP95 }" id="NP95" >Aigeus' observation post at the Athena Nike temple site: Paus. 1.22.4-5; G. S. Dontas, «Άνασκαφή είς τους  νοτίους πρόποδας τής Άκροπόλεως,» Praktika, 1960,6</p>
<p :class="{'activeClass': idClicked == NP96 }" id="NP96" >Tripod base relief: see Dionysos Dio15**</p>
<p :class="{'activeClass': idClicked == NP97 }" id="NP97" >AISCHINES the orator, house in Kollytos: Aeschin. Ep. 5.6; Davies, J. K. 1971, 547</p>
<p :class="{'activeClass': idClicked == NP98 }" id="NP98" >AISCHRAIOS, son of Polykles, choregic monument, silvered-over tripod above the theater with an inscription on the rock face - - - IG II² 3043; F GR HIST III B, 116, Philochoros 328 F 58, with suppl. vol. I, 334; Amandry, P. 1976, 87 note 102; PAA vol. 1, 1994, no. 115285; cf. SEG 47 (1997) no.208</p>
<p :class="{'activeClass': idClicked == NP99 }" id="NP99" >AISCHYLOS, SEG 49 (1999) no. 162;</p>
<p :class="{'activeClass': idClicked == NP100 }" id="NP100" >Bronze statue in the theater: Paus. 1.21.2; Gauer W. 1968b,132-134</p>
<p :class="{'activeClass': idClicked == NP101 }" id="NP101" >Statue base, Eleusinian marble, 2nd c. A.C., theater - - - IG II² 4265; Richter, G. M. A. 1965, 121-122, fig. 610 See also: Marathonomachia, picture in the Stoa Poikile</p>
<p :class="{'activeClass': idClicked == NP102 }" id="NP102" >AKADEMIA, HEKADEMEIA: Travlos, J. 1971, 42-51 with plans, figs. 53, 62, 417 <JBcom>[arbitrarily drawn, unsubstantiated, highly improbable boundary line]</JBcom>; Billot, M.-F. 1989, 693-789, frontispiece (mosaic picturing Plato's Academy) and figs. 5-6</p>
<p :class="{'activeClass': idClicked == NP103 }" id="NP103" >Hekademeia, the original place-name, after the hero Hekademos: D.L. 3.7-8; Kron, U. 1979, 55-58; Threatte, L. 1980, 128</p>
<p :class="{'activeClass': idClicked == NP104 }" id="NP104" >Hekademeia boundary stone, ca. 500 B.C., south side of Aimonos St., 11.60 m. south of Tripoleos St. (Plan 3 beta—6) — IG I³ 1091; 3rd Ephor. M 1111; stone found in situ, the inscription facing those coming from Athens on an ancient road in use in the late 6th c. B.C., O. Alexandri, «Τοπογραφικά Άθηνών,» AAA 1 (1968), 101-102, 107, plan 1, fig. 1 and photo on the front cover of fascicule 2; SEG 24 (1969) no. 54; the stone <JBcom>[allegedly]</JBcom> “fixes the northeast corner of the site,” Travlos, J. 1971, 42, figs. 56-57, fig. 417 no. 180</p>
<p :class="{'activeClass': idClicked == NP105 }" id="NP105" >Akademia: Pl. Ly. 203 A (Sokrates' walk from the Academy directly to the Lykeion on the ring road circling the City Wall); Paus. 1.29.2 + 30.1-3 (large district containing gymnasium and sanctuaries); Plu. Moralia 603 B (small privately owned property where Plato, Xenokrates and Polemon lived and taught); properties privately owned in the Academy in the 2nd c. A.C., IG II² 2776 lines 89, 149, 186; for a republication of this inscription with commentary, see Miller, S. G. 1972, 67-95</p>
<p :class="{'activeClass': idClicked == NP106 }" id="NP106" >For monuments and sites without clear-cut names in the Academy and in the Academy Area, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM430">430</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM461">461</a></p>
<p :class="{'activeClass': idClicked == NP107 }" id="NP107" >AKADEMOS, HEKADEMOS: Threatte, L. 1980, 128; LIMC I, 1981, 433-455 s.v. Akademos, (U. Kron); Billot, M.-F. 1989, 723, 731, 733-735</p>
<p :class="{'activeClass': idClicked == NP108 }" id="NP108" >Akademos, heroon at the Academy: schol. D. 24.114; cf. Ploughman relief; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM447">447</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM457">457</a></p>
<p :class="{'activeClass': idClicked == NP109 }" id="NP109" >AKAMAS, son of Theseus; Kron, U. 1976, 141-170; Kearns, E. 1989, 123, 143-144</p>
<p :class="{'activeClass': idClicked == NP110 }" id="NP110" >Sanctuary mentioned in the decree of Akamantis, 361/360 B.C., found at the northeast corner of Plateia Kallitheas, formerly Plateia Syntagmatarchou Davaki (Plan 3 beta—11): EM 13354; M. Mitsos, “Έκ τού Έπιγραφικού Μουσείου,” ΑΕ 1965, 131-136 no. 1, pls. 43-44, SEG 23 (1968) no. 78 lines 10-11; Kron, U. 1976, 146 -147</p>
<p :class="{'activeClass': idClicked == NP111 }" id="NP111" >Eponymous Hero statue: Paus. 1.5.2 See also: Trojan Horse; Zeus Zeu33*</p>
<p :class="{'activeClass': idClicked == NP112 }" id="NP112" >AKESO: see Asklepios Ask22*</p>
<p :class="{'activeClass': idClicked == NP113 }" id="NP113" >AKESTION, daughter of Xenokles and descendant of Themistokles, grave mon., 1st c. B.C., on the Sacred Way: Paus. 1.37.1; Clinton, K. 1974, 54-55 nos. 13-14, 58 Table 1</p>
<p :class="{'activeClass': idClicked == NP114 }" id="NP114" >AKRATOS, mask built into a wall in the precinct of Dionysos Melpomenos: Paus. 1.2.5; Kearns, E. 1989, 144</p>
<p :class="{'activeClass': idClicked == NP115 }" id="NP115" >AKROPOLIS: Paus. 1.5.4, 1.18.2, 1.21.3, 1.21.4, 1.22.1, 1.22.4-1.28.3; 4.36.6; 5.11.10, 5.21.1; 7.25.3, 7.27.2; 8.10.4, 8.23.5; 9.4.1, 9.35.3, 9.35.7; earliest epigraphical evidence for the place-name, Dinsmoor, W. B. 1932, 157-170; idem 1947, 134-135; representations on Athenian imperial coins: Price, J., M. and B. L. Trell 1977, 68, 75-78, figs. 130-133; J. M. Hurwit, The Athenian Acropolis, Cambridge, 1999</p>
<p :class="{'activeClass': idClicked == NP116 }" id="NP116" >For Akropolis monuments and sites without clear-cut names, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM1">1</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM129">129</a></p>
<p :class="{'activeClass': idClicked == NP117 }" id="NP117" >ALEXANDER OF PHERAI, bronze statue: Plu. Pel. 31.4</p>
<p :class="{'activeClass': idClicked == NP118 }" id="NP118" >ALEXANDER THE GREAT: Kotsidu, H. 2000, 27-31</p>
<p :class="{'activeClass': idClicked == NP119 }" id="NP119" >Alexander cult in Athens: A. D. Nock, “Notes on Ruler-cult, l-IV: I Alexander and Dionysus,” JHS 48, (1928) 21-30</p>
<p :class="{'activeClass': idClicked == NP120 }" id="NP120" >Golden throne and peplos bequeathed to Athens: Incerti auctoris epitoma rerum gestarum Alexandri Magni cum libro de morte testamentoque Alexandri, Teubner, 1960, 120</p>
<p :class="{'activeClass': idClicked == NP121 }" id="NP121" >Three hundred Persian panoplies or 300 shields dedicated to Athena on the Akropolis after the battle of Granikos, 334 B.C., with an inscription proclaiming the dedicators as Alexander, the son of Philip, and the Hellenes excepting the Lakedaimonians: Arr. An. 1.16.7 (panoplies); Plu. Alex. 16.17 (shields)); Schenkungen hellenistischer Herrscher, Part I, 1995, 17-18 and Part II, vol. 1, 2000, 57-58 and vol. 2, 2000, 86-87, 89-90 with figs. 26-27, 232</p>
<p :class="{'activeClass': idClicked == NP122 }" id="NP122" >Portrait head, Erechtheion - - - Acr. 1331; T. Holscher, Ideal und Wirklichkeit in den Bildnissen Alexanders des Grossen, Heidelberg, 1971, 10 note 11 and passim, pl. 1</p>
<p :class="{'activeClass': idClicked == NP123 }" id="NP123" >Head <JBcom>[mistakenly]</JBcom> identified as Alexander, Pankrates sanct. on the Ilissos - - - 1st Ephor. Pankrates inv. 66 A; M. Bieber, Alexander The Great in Greek and Roman Art, Chicago, 1964, 85, fig. 38 on pl. 20; for a <JBcom>[plausible]</JBcom> identification as Pankrates, Vikela, E. 1994, 60 with note 20</p>
<p :class="{'activeClass': idClicked == NP124 }" id="NP124" >Portrait head with AO scratched four times on the face, Kerameikos Exc. - - - NM 366; perhaps portrait of Mithridates VI Eupator, A. F. Stewart, Rev. of N. Yalouris et ah, The Search for Alexander in Art Bulletin 64 (1982) 324 note 18; more likely Herakles, LIMC IV, 1988, 790 no. 1311, s.v. Herakles (O. Palagia); Kaltsas, N. 2002, 273 no. 572, ph.</p>
<p :class="{'activeClass': idClicked == NP125 }" id="NP125" >Statue in front of the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.9.4</p>
<p :class="{'activeClass': idClicked == NP126 }" id="NP126" >Alexander and Hephaistion, cult: Hyp. Epit. 21; cf. Habicht, Chr. 1970, 28-36, 216-221, 246-250 See also: Helios; Herakles Hrk46*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM522">522</a></p>
<p :class="{'activeClass': idClicked == NP127 }" id="NP127" >ALEXIKAKOS: see Apollo Apo23*, Apo25*; Athena Ath119*; Herakles Hrk42*</p>
<p :class="{'activeClass': idClicked == NP128 }" id="NP128" >ALKIA, Herodes Atticus' mother, Vibullia Alkia Agrippina: Ameling, W. 1983, I, 18 note 22, 24, 31 and II, 91 no. 63, 132 no.123; Byrne, S. G. 2003, 479-480, no. 3</p>
<p :class="{'activeClass': idClicked == NP129 }" id="NP129" >Theater seat IG II² 5122; Geagan, D. 1967, 42 note 3, 148 See also: Atticus and Vibullia Alkia</p>
<p :class="{'activeClass': idClicked == NP130 }" id="NP130" >ALKIBIADES, son of Kleinias</p>
<p :class="{'activeClass': idClicked == NP131 }" id="NP131" >House with wall paintings by Agatharchos: Plu. Ale. 16.4 + 22.3</p>
<p :class="{'activeClass': idClicked == NP132 }" id="NP132" >Confiscated property sold at auction (Alkibiades' property was in Athens and in Attica), stelai set up in the Eleusinion: IG I³ 421 lines 12 ff.; Stele I lines 12-13 and 158 ff., Stele IV lines 27-28 ff.; W. K. Pritchett, “The Attic Stelai, Part I,” Hesperia 22 (1953) 241, 243, 247-248, 263-264</p>
<p :class="{'activeClass': idClicked == NP133 }" id="NP133" >Statue relabelled Ahenobarbus: D. Chr. 37.40; Richter, G. M. A. 1984, 2, 82</p>
<p :class="{'activeClass': idClicked == NP134 }" id="NP134" >Pictures of Alkibiades dedicated by himself: Holscher, T. 1975, 191-192</p>
<p :class="{'activeClass': idClicked == NP135 }" id="NP135" >Alkibiades celebrating his chariot race victory at Nemea, Propylaia: Paus. 1.22.6-7; Pollitt, J. J. 1990, 147 with note 27</p>
<p :class="{'activeClass': idClicked == NP136 }" id="NP136" >Alkibiades crowned by Olympias and Pythias painted by Aglaophon: Ath. XII. 534 D; Svoronos, J. N. 1911, 467-469 See also: Herms Her4*; Hipparete; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM395">395</a></p>
<p :class="{'activeClass': idClicked == NP137 }" id="NP137" >ALKMENE, altar in the sanct. of Herakles at Kynosarges: Paus. 1.19.3; Kearns, E. 1989, 145</p>
<p :class="{'activeClass': idClicked == NP138 }" id="NP138" >ALKMEONIDAI: see Hipparete</p>
<p :class="{'activeClass': idClicked == NP139 }" id="NP139" >ALL THE GODS: K. Ziegler, RE XVIII, 1949, col. 718 (Pantheion); Willers, D. 1990, 16, 18-19, 22-24, 61-62</p>
<p :class="{'activeClass': idClicked == NP140 }" id="NP140" >Sanctuary founded by Hadrian, the so-called Pantheon: Paus. 1.5.5 + 1.18.9; cf. 8.37.10; J. Beaujeu, La religion romaine a l'apogee de l'Empire, I: La politique religieuse des Antonins (96-102), Paris, 1955, Index s.v. Pantheon d'Athenes; <JBcom>[mistakenly]</JBcom> located in the Library of Hadrian, Karivieri, A. 1994a, 90-93, 112; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM207">207</a></p>
<p :class="{'activeClass': idClicked == NP141 }" id="NP141" >Dedication on a small columnar base, ca. 2nd c. A.C., Akropolis South Slope on Dion. Areopagitou St., <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204348" target="_blank">Agora I 4348</a>; J. H. Oliver, “Greek and Latin Inscriptions,” Hesperia 10 (1941) 255 no. 60, ph. See also: Athena Ath111*; Nymphs Nym11*</p>
<p :class="{'activeClass': idClicked == NP142 }" id="NP142" >ALOPEKE</p>
<p :class="{'activeClass': idClicked == NP143 }" id="NP143" >Deme south of the City Wall: Hdt. 5.63; Traill, J. S. 1986, 139 with Map; Billot, M.-F. 1992, 120, 128 with note 30, 133, 143, 144, 152-153</p>
<p :class="{'activeClass': idClicked == NP144 }" id="NP144" >Trittys name: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206078" target="_blank">Agora I 6078</a> lines 4-5; SEG 21 (1965) no. 111</p>
<p :class="{'activeClass': idClicked == NP145 }" id="NP145" >Aphrodite Alopekesin: see Aphrodite Aph22*</p>
<p :class="{'activeClass': idClicked == NP146 }" id="NP146" >ALPHITOPOLIS STOA: see Stoa Alphitopolis</p>
<p :class="{'activeClass': idClicked == NP147 }" id="NP147" >AMAZON, Mattei type, 2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201655" target="_blank">Agora S 1655</a>; M. Weber,” Die Amazonen von Ephesos II,” Jahrbuch 99 (1984) 111-112 with fig. 43, 120-121, 123</p>
<p :class="{'activeClass': idClicked == NP148 }" id="NP148" >AMAZON'S GRAVE STELE near the Itonian Gate: Pl. Ax. 364 D-365 A; Frazer, J. G. 1913, II, 37; Billot, M.-F. 1992, 121; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM172">172</a></p>
<p :class="{'activeClass': idClicked == NP149 }" id="NP149" >AMAZONEION, sanctuary established by the Amazons on the Areopagus at the site of their encampment: A. Eu. 685-690; F GR HIST III B, 56, Kleidemos 323 F 18 with suppl. vol. I, 77; Judeich, W. 1931, 62 note 1, 300 note 6; Kearns, E. 1989, 146 s.v. Amazons; Conwell, D. H. 1993, 222-224, 523</p>
<p :class="{'activeClass': idClicked == NP150 }" id="NP150" >AMAZONOMACHIA: Kearns, E. 1989, 122, 146</p>
<p :class="{'activeClass': idClicked == NP151 }" id="NP151" >Graves of those fallen in battle lining the road to the Peiraeus Gate: Plu. Thes. 27.3-4; F GR HIST III B, suppl. vol. I, 77-78</p>
<p :class="{'activeClass': idClicked == NP152 }" id="NP152" >Architectural relief frags., probably metopes, late archaic-severe style, Akr. (?) - - - Akr. 13530 +; G. Despinis, «Θραύσματα αναγλύφων από τη Νότια πλευρά της Ακροπόλης,» in Archaische und Klassische Griechische Plastik 1986, 177-180, 210, 224, pls. 75-76</p>
<p :class="{'activeClass': idClicked == NP153 }" id="NP153" >Picture by Mikon in the Stoa Poikile, Athenians and Theseus fighting Amazons: Paus. 1.15.2; Agora III, nos. 55-6, 59,80; T. Holscher, Griechische Historienbilder des 5. und 4. Jahrhunderts v. Chr., Wurzburg, 1973, 50, 68, 71-73</p>
<p :class="{'activeClass': idClicked == NP154 }" id="NP154" >Picture in the Theseion of the Athenians battling Amazons: Paus. 1.17.2, cf. 5.11.2</p>
<p :class="{'activeClass': idClicked == NP155 }" id="NP155" >Large base ? with reliefs copying Parthenos shield reliefs, mid-2nd c. A.C.: B. Schlorb, “Beitrage zur Schildamazonomachie der Athena Parthenos,” A/W 78 (1963) 156-172, Beilagen 75-79; Despinis, G. 2003, 51-52, 127</p>
<p :class="{'activeClass': idClicked == NP156 }" id="NP156" >Fragments found in 5th c. A.C. foundations overlying the Pompeion propylon Kerameikos P 674-677</p>
<p :class="{'activeClass': idClicked == NP157 }" id="NP157" >Fragments, Antonine pd. Agora S1357 +; cautiously weighing attribution to the Kerameikos reliefs, E. B. Harrison, “The Composition of the Amazonomachy on the Shield of Athena Parthenos,” Hesperia 35 (1966) 132-133</p>
<p :class="{'activeClass': idClicked == NP158 }" id="NP158" >AMMON, cult with sacrifices by the strategoi: IG II² 1496 lines 96-97; H. Schwabl , RE Suppl. XV, 1978, col. 1065 (Ammon); SEG 47 (1997) no.29</p>
<p :class="{'activeClass': idClicked == NP159 }" id="NP159" >AMMONIAS: see Paralos</p>
<p :class="{'activeClass': idClicked == NP160 }" id="NP160" >AMPHIARAOS: Kearns, E. 1989,147</p>
<p :class="{'activeClass': idClicked == NP161 }" id="NP161" >Statue next to the Eponymous Heroes: Paus. 1.8.2; LIMC I, 1981, 701 no. 57, s.v. Amphiaraos (I. Krauskopf); probably shown on New Style silver tetradrachms, 135/4 B.C., Herzog, H. 1996, 22-26</p>
<p :class="{'activeClass': idClicked == NP162 }" id="NP162" >Amphiaraos and Hygieia, arula dedicated by Timokles, 2nd c. B.C., Tower of the Winds - - - IG II² 4441; SEG 3 (1929) no. 148 and 12 (1955) no. 162</p>
<p :class="{'activeClass': idClicked == NP163 }" id="NP163" >Rededicated to Asklepios and Amphiaraos, 1st c. A.C.: SEG 3 (1929) no. 149</p>
<p :class="{'activeClass': idClicked == NP164 }" id="NP164" >Amphiaraos, Artikleides at an altar being crowned by Hygieia, document relief, ca. 320 B.C., found in the railroad trench north of the Hephaisteion NM 1396, IG II² 171; Lawton, C. L. 1995, 16 with note 54, 35, 49-50, 147-148 no. 153, pl. 81; Kaltsas, N. 2002, 236 no. 496, ph.</p>
<p :class="{'activeClass': idClicked == NP165 }" id="NP165" >AMPHIKTYON, king of the Athenians, feasting Dionysos and The Gods, terracotta sculpture in an oikema next to the precinct of Dionysos Melpomenos: Paus. 1.2.5; cf. Paus. 10.8.1; F GR HIST III B, suppl. vol. I, 164 under frag. 58; Kearns, E. 1989, 147</p>
<p :class="{'activeClass': idClicked == NP166 }" id="NP166" >AMPHIKTYONIC DECREE renewing special privileges granted to the Technitai, 130/129 B.C., northwest corner of the Agora Exc. to the north of the railway  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205679" target="_blank">Agora I 5679</a>, IG II² 1133; S. V. Tracy, “Greek Inscriptions,” Hesperia 39 (1970) 309-311, pl. 76 no. 3; probably set up in the sanctuary of Dionysos Melpomenos q.v.</p>
<p :class="{'activeClass': idClicked == NP167 }" id="NP167" >AMPHILOCHOS, son of Amphiaraos, altar: Paus. 1.34.3; Kearns E. 1989, 148 </p>
<p :class="{'activeClass': idClicked == NP168 }" id="NP168" >AMYETOS: see Hermes Hrm17*</p>
<p :class="{'activeClass': idClicked == NP169 }" id="NP169" >AMYNOS AND ASKLEPIOS, sanctuary in the valley between the Areopagus and the Pnyx: IG II² 1252 line 17; SEG 49 (1999) no. 159; Korte, A. 1893, 231-256; idem 1896, 287-313, plan pl. 11; Amynos considered a hero of foreign origin in the time of Solon, heroized after his death under the name of Amynos, Pantazopoulos, N. I. 1947/48, 106-107; Travlos, J. 1971, 76-78, figs. 97-101; hundreds of finds, sculpture, inscriptions, pottery etc., from the Amyneion stored in the National Museum, N. Yalouris, «8. Ευρήματα Άμυνείου,» Deltion 29 (1973/74) B1 Chr., 2-3, pls. 7-8; <JBcom>[no evidence seen so far attests a date for the founding of the sanctuary earlier than the 4th c. B.C.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP170 }" id="NP170" >Precinct wall and propylon: Koerte, A. 1896, 287-288, pl. 11</p>
<p :class="{'activeClass': idClicked == NP171 }" id="NP171" >Perirrhanterion dedicated to Amynos, 4th c. B.C., probably at the entrance to the sanctuary - - - IG II² 4424; Pimpl, H. 1997, 49, 97, 189 no. 114</p>
<p :class="{'activeClass': idClicked == NP172 }" id="NP172" >Cult table with a relief of two snakes between the feet: Koerte, A. 1896, 289, F on pl. 11</p>
<p :class="{'activeClass': idClicked == NP173 }" id="NP173" >Amynos and Asklepios and Hygieia, arula, 50-20 B.C. - - - IG II² 4457; Aleshire, S. B. 1991, 223-234</p>
<p :class="{'activeClass': idClicked == NP174 }" id="NP174" >Amynos and Asklepios and Dexion, decree of orgeones, ca. 350 - 300 B.C. IG II² 1252 +; SEG 49 (1999) no. 159; Aleshire, S. B. 1991, 236 A5, 237 A10 See also: Halon; Lysimachides</p>
<p :class="{'activeClass': idClicked == NP175 }" id="NP175" >ANAIDEIA, sanctuary: F GR HIST III B, 172, Istros 334 F 11 with suppl. vols. I, 635-6 and II, 512-3</p>
<p :class="{'activeClass': idClicked == NP176 }" id="NP176" >Anaideias Lithos ("Rock of Unforgiveness, where the prosecutor stood”) on the Areopagus: Paus. 1.28.5; FGR HIST III B, suppl. vols. I 635-6 and II, 512-3; Agora XXVIII, 46</p>
<p :class="{'activeClass': idClicked == NP177 }" id="NP177" >ANAKEION and ANAKES: see Dioskouroi</p>
<p :class="{'activeClass': idClicked == NP178 }" id="NP178" >ANAKO, Isokrates' aunt, statue on the Akr.: Plu. Moralia 839 D; Blanck, H. 1969, 15</p>
<p :class="{'activeClass': idClicked == NP179 }" id="NP179" >ANAKREON: Richter, G. M. A. 1972, I, 83-86</p>
<p :class="{'activeClass': idClicked == NP180 }" id="NP180" >Statue of Anakreon singing while intoxicated, on the Akr.: Paus. 1.25.1; Gauer, W. 1968b,141-142</p>
<p :class="{'activeClass': idClicked == NP181 }" id="NP181" >Headless herm, 2nd c. A.C., Fauvel Coll., later reused as a threshold <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202061" target="_blank">Agora I 2061</a>; SEG 16 (1959) no. 167; Richter, G. M. A. 1965, I, 77 no. 11, fig. 273</p>
<p :class="{'activeClass': idClicked == NP182 }" id="NP182" >ANCHESMIOS: see Zeus Zeu9*</p>
<p :class="{'activeClass': idClicked == NP183 }" id="NP183" >ANCHESMOS, name of a hill: Paus. 1.32.2; probably present-day Tourkovouni (g.v.)about 1 1/2 km. north of Lykabettos: Langdon, Μ. K. 1976, 101-102</p>
<p :class="{'activeClass': idClicked == NP184 }" id="NP184" >ANCHIMOLIOS of Sparta, grave stele, 512 B.C., in Alopeke near the Herakleion at Kynosarges: Hdt. 5.63; Pritchett, W. K. 1985, 163-164; Billot, M.-F. 1992, 120, 125, 154</p>
<p :class="{'activeClass': idClicked == NP185 }" id="NP185" >ANDOKIDES the orator <br>House on the Akropolis north slope with the “Andokides' herm” in front: Plu. Nic. 13.2; Agora XI, 119-120; see Herms Her8*; Symbolon</p>
<p :class="{'activeClass': idClicked == NP187 }" id="NP187" >Choregic victory tripod: Plu. Moralia 835 B; Amandry, P. 1977,175 note 17</p>
<p :class="{'activeClass': idClicked == NP188 }" id="NP188" >ANDREAS, hydraulic engineer, gravestone, 5th-6th c. A.C., west of the Dipylon Gate EM 2221; A. Avramea, “Ύδραγωγός και άρχιτεκτων των ύδάτων” από άνέκδοτη Άθηναϊκή έπιγραφή,» Βυζαντινά 13 (1985) 1095-1099, ph. (not seen); D. Feissel, “Inscriptions chretiennes et byzantines,” REG 100 (1987) 356 no. 421; Sironen, E. 1997, 153-154 no. 80</p>
<p :class="{'activeClass': idClicked == NP189 }" id="NP189" >ANDRONIKOS KYRRHESTES, Horologion, the Tower of the Winds, late 2nd c. B.C., Akr. Lower North Slope (Plan 1 no. 70): IG II² 1035 lines 54-55; Freeden, J. von 1983; Travlos, J. 1971, 281-288, figs. 49, 362-378</p>
<p :class="{'activeClass': idClicked == NP190 }" id="NP190" >Triton weather vane pointing to labelled frieze reliefs of winds marking the eight points of the compass - - - CIG I 518; SEG 47 (1997) no. 234; Vitr. 1.6.4; Neuser, K. 1982, 172-175, 231, figs. 42-43; Freeden, J. von 1983, Index s.v. Triton, Windgotter, Windrose</p>
<p :class="{'activeClass': idClicked == NP191 }" id="NP191" >Water-clock: ibid., 42-47 with pl. 19, 192-196</p>
<p :class="{'activeClass': idClicked == NP192 }" id="NP192" >Nine sundials: Gibbs, S. L. 1976, 342-345 no. 5001</p>
<p :class="{'activeClass': idClicked == NP193 }" id="NP193" >ANDRONIKOS KYRRHESTES, Horologion (continued), later names for the monument: <br>Church of the Greeks, 15th c.: Milan Anon., Codex Ambrosianus C 61 cited by Freeden, J. von 1983, 17 note 78, 21 note 94 <br>Plato's Pavilion, mentioned by Evliya Çelebi in 1668: MacKay, P. A. 1969, 468 <br>Socratis templum, mentioned by J. Transfeldt in 1675: A. Michaelis, “J. G. Transfeldts Examen reliquarum antiquitatum Atheniensium,” AM 1 (1876) 115; Freeden J. von 1983, 21-22 <br>Tekes tou Braimi: EMME II, 1929, 121-122 <br>Tower of the Winds, name adopted by Stuart and Revett in 1762 <br>See also: Christian Mon. nos. CM4*, CM35*</p>
<p :class="{'activeClass': idClicked == NP199 }" id="NP199" >ANIKETOS: see Mithras, relief dedicated to Helios Mithras Aniketos</p>
<p :class="{'activeClass': idClicked == NP200 }" id="NP200" >ANKYLE, demes of Upper and Lower Ankyle: IG II² 2362 lines l8-19; SEG 15 (1958) no. 131; northeast of Ardettos, Traill, J. S. 1986, 127 with Map</p>
<p :class="{'activeClass': idClicked == NP201 }" id="NP201" >ANTEROS, altar in the city dedicated by metics: Paus. 1.30.1, cf. Vl.23.3; Frazer, J. G. 1913, II, 391; cf. A. Wlosok, “Amor and Cupid”, HSCP 79 (1975)166, 176 note 1</p>
<p :class="{'activeClass': idClicked == NP202 }" id="NP202" >ANTHEMION, son of Diphilos, standing beside a mare, sculpture on the Akropolis: Arist. Ath. 7,4; Rhodes, P. J. 1981, 143-145; Further Greek Epigrams 1981, 415-417</p>
<p :class="{'activeClass': idClicked == NP203 }" id="NP203" >ANTHEMOKRITOS the herald: Grave monument, 431 B.C., first grave mon. on the Sacred Way: Paus. 1.36.3 +4; state-financed burial monument, Plu. Per. 30.3; Knigge, U. 1980, 72-76; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM400">400</a></p>
<p :class="{'activeClass': idClicked == NP205 }" id="NP205" >Statue voted by decree of the people: D. 12.4; Knigge, U. 1960, 74</p>
<p :class="{'activeClass': idClicked == NP206 }" id="NP206" >ANTIGENES, palaestra: IG II² 958 line 61; Delorme, J. 1960, 147, 261 note 1 ("Epigenes” by mistake for "Antigenes”)</p>
<p :class="{'activeClass': idClicked == NP207 }" id="NP207" >ANTIGONOS I (Monophthalmos) and DEMETRIOS POLIORKETES: Kotsidu, H. 2000, 33-46</p>
<p :class="{'activeClass': idClicked == NP208 }" id="NP208" >As Soteres, cult with altars instituted in 307 B.C.: Plu. Demetr. 10.4, 12.3, 46.1; Habicht, Chr. 1970, 44-48, 53, 54, 132, 134-135, 138, 142, 143, 144, 146</p>
<p :class="{'activeClass': idClicked == NP209 }" id="NP209" >As Eponymous Heroes, statues: T. Leslie Shear, Jr., “The Monument of the Eponymous Heroes in the Athenian Agora,” Hesperia 39 (1970) 171-176, 196-198; as Eponymous Gods, Habicht, Chr. 1970, 45 with note 7</p>
<p :class="{'activeClass': idClicked == NP210 }" id="NP210" >Gilded bronze statues in a chariot, near Harmodios and Aristogeiton: D.S. 20.46.2; Habicht, Chr. 1970, 45 note 8, 46-47</p>
<p :class="{'activeClass': idClicked == NP211 }" id="NP211" >Depictions of, on the Panathenaic peplos: Plu. Demetr. 10.5, 12.3; Mansfield, J. 1989, 63-64 with notes 45-46</p>
<p :class="{'activeClass': idClicked == NP212 }" id="NP212" >ANTINOEIS, deme created after Antinoos' death in 130 A.D.: Zahrnt, M. 1979, 397-398</p>
<p :class="{'activeClass': idClicked == NP213 }" id="NP213" >ANTINOOS the Bithynian: H. Meyer, Antinoos (Munich, 1991),165 with note 16, 205-207, 253</p>
<p :class="{'activeClass': idClicked == NP214 }" id="NP214" >Statuette, 31 Ermou St., near the intersection with Phokionos St. (Plan 1 beta— 8/9) - - - 3rd Ephor. no. 495; Daux, G. 1961, 613, fig. 17; Dontas, G. S. 1969, 73; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM510B">510B</a> (Sculpture Collection)</p>
<p :class="{'activeClass': idClicked == NP215 }" id="NP215" >Antinoos Choreios, Technitai priest's theater throne - - - IG II² 5062; D. J. Geagan, “Hadrian and the Athenian Dionysiac Technitai,” ΤΑΡΑ 103 (1972) 145-149</p>
<p :class="{'activeClass': idClicked == NP216 }" id="NP216" >ANTIOCHOS, son of Herakles and Meda, Eponymous Hero: Kron, U. 1976, 190-193; LIMC I, 1981, 852-854, s.v. Antiochos (E. B. Harrison)</p>
<p :class="{'activeClass': idClicked == NP217 }" id="NP217" >Decree of the hippeis of Antiochis, ca. 330 B.C., to be set up in the temenos of Antiochos, found on the Ilissos left bank south of the former Fix Brewery, approximately at the intersection of Kallirrois and Ambrosiou Frantzi Sts. (Plan 3 delta—10): SEG 3 (1929) no. 115 lines 21-23; probably at Kynosarges, Kron, U. 1976, 191-192; Billot, M.-F. 1992, 143-154</p>
<p :class="{'activeClass': idClicked == NP218 }" id="NP218" >Statue as Eponymous Hero: Paus. 1.5.2; LIMC I, 1981, 853 no. 9, s.v. Antiochos (E. B. Harrison) See also: Herakles Hrk39*</p>
<p :class="{'activeClass': idClicked == NP219 }" id="NP219" >ANTIOCHOS, theater seat - - - IG II² 5144; Merkel, W. R. 1947, 77</p>
<p :class="{'activeClass': idClicked == NP220 }" id="NP220" >ANTIOCHOS III of Commagene, d. 17 A.D., statue base, Akr. - - - IG II² 3444; SEG 28 (1978) no. 214</p>
<p :class="{'activeClass': idClicked == NP221 }" id="NP221" >ANTIOCHOS IV EPIPHANES, statues in Athens: OGI248 lines 53-54; Habicht, Chr. 1989, 19 note 62, 22 note 82; Kotsidu, H. 2002, 75-77</p>
<p :class="{'activeClass': idClicked == NP222 }" id="NP222" >ANTIOCHOS VII Sidetes, statue in the Agora, ca. 135 B.C.: IG II² 937 +; SEG 38 (1988) no. 112 lines 46; Habicht, Chr. 1989, 22-24</p>
<p :class="{'activeClass': idClicked == NP223 }" id="NP223" >ANTIOPE the Amazon, mother of Hippolytos in some, not all sources, slain by Molpadia, grave mon. on the road from Phaleron just inside the City Wall: Paus. 1.2.1; Frazer, J. G. 1913, 37-38; Kearns, E. 1989, 54, 146; Conwell, D. H. 1993, 222-226</p>
<p :class="{'activeClass': idClicked == NP224 }" id="NP224" >ANTIPATROS from Askalon, grave relief with lion attacking Antipatros and sacred (?) ship, Phoenician and Greek inscr., ca. 300 B.C., Kerameikos - - - IG II² 8388; NM 1488; C. W. Clairmont, Gravestone and Epigram, Mainz, 1970, 114-117, pl. 19; SEG 42 (1992) no. 203; Kaltsas N. 2002, 190 no. 376, ph.</p>
<p :class="{'activeClass': idClicked == NP225 }" id="NP225" >ANTIPHON, son of Pyrilampes, Plato's half-brother, house in Melite near the Agora: Pl. Prm. 126C</p>
<p :class="{'activeClass': idClicked == NP226 }" id="NP226" >ANTIPHON, son of Sophilos, and ARCHEPTOLEMOS, inscribed property boundary stones branding them as traitors: Plu. Moralia 834A; W. R. Connor, “The Razing of the House in Greek Society,” ΤΑΡΑ 115 (1985) 82-83 T 10, 85 note 19</p>
<p :class="{'activeClass': idClicked == NP227 }" id="NP227" >ANTISTHENES' SCHOOL in the Kynosarges Gymnasium: D.L. 6.13; F GR HIST CONTINUED, Part IV A, fascicle 1, 94 (J. Engels)</p>
<p :class="{'activeClass': idClicked == NP228 }" id="NP228" >ANTONIA, theater seat - - - after IG II² 5126</p>
<p :class="{'activeClass': idClicked == NP229 }" id="NP229" >ANTONIA (Minor?), priestess' theater seat - - - IG II² 5095; Graindor, A. 1927, 152, 157-158</p>
<p :class="{'activeClass': idClicked == NP230 }" id="NP230" >ANTONIA MINOR = Antonia Augusta (PIR, 2nd ed., 885), portrait head, Claudian? - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20220" target="_blank">Agora S 220</a>; K. P. Erhart, “A Portrait of Antonia Minor in the Fogg Art Museum and Its Iconographical Tradition,VUA 82 (1978) 202, 210 Group Two no. 3</p>
<p :class="{'activeClass': idClicked == NP231 }" id="NP231" >ANTONINUS PIUS = T. Aurelius Fulvius Boionius Arrius Antoninus (PIR, 2nd ed. 1513)</p>
<p :class="{'activeClass': idClicked == NP232 }" id="NP232" >Portrait head (?), at Apollo Patroos NM 3563; Heckler, A. 1935, 404-407 no. 4, figs. 7-8; cf. A. Datsouli-Stavridi, «Εικονιστικά Β',» Deltion 30 (1975) A, Meletai, 187 with note 11; “probably . . . Septimius Severus,” Kaltsas, N. 2002, 355 no. 751, ph.</p>
<p :class="{'activeClass': idClicked == NP233 }" id="NP233" >Portrait bust <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202436" target="_blank">Agora S 2436</a>; Shear Jr., T. L. 1973a, 161, 170-171, pl. 37:A</p>
<p :class="{'activeClass': idClicked == NP234 }" id="NP234" >Statue base fragment - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204147" target="_blank">Agora I 4147</a>; Raubitschek, A. E. 1966, 248 no. 9, pl. 65; SEG 23 (1968) no. 107</p>
<p :class="{'activeClass': idClicked == NP235 }" id="NP235" >Building inscription on a small epistyle, dedicated to Antoninus Pius and family members, 145/6 A.D., National Garden, north of the Zappeion entrance (Plan 2 eta—6) - - - IG II² 5210; Third Ephoria inv. no. 13; the inscription refers to Faustina the Younger according to E. Sironen, “New Readings on Four Athenian Inscriptions of the Imperial Period,” Arctos 23 (1989) 179-181, fig. 1; SEG 39 (1989) no. 245 See also: Hadrian, Aqueduct </p>
<p :class="{'activeClass': idClicked == NP236 }" id="NP236" >ANTONIUS = M. Antonius M.f.M.n. (RE Antonius 30) <br>Mark Antony as Neos Dionysos, cult with festival: IG II² 1043 lines 22-23; SEG 49 (1999) no. 130; see Attalid dedication, Dionysos; cf. A. D. Nock, “Notes on Ruler-cult, l-IV: II Neos Dionysos,” JHS 48 (1928) 33-34</p>
<p :class="{'activeClass': idClicked == NP238 }" id="NP238" >Statues of Mark Antony and Cleopatra as gods set up on the Akropolis by the Athenians, 32 B.C., thrown down into the theater by thunderbolts in 31 B.C.: D.C. 50.15.2; T. Holscher, “Die Geschlagenen und Ausgelieferten in der Kunst des Hellenismus,” Antike Kunst 28 (1985) 126-127; Schenkungen hellenistischer Herrscher, Part I, 1995, 48-49; <JBcom>[Cleopatra may have been represented as Selene, cf. D.C. 50.5.3]</JBcom></p>
<p :class="{'activeClass': idClicked == NP239 }" id="NP239" >Mark Antony and Octavia, altar - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203071" target="_blank">Agora I 3071</a>; “Antony and Octavia were celebrated in Athens as Dionysos and Athena” according to A. E. Raubitschek, “Octavia's Deification at Athens,” ΤΑΡΑ 77 (1946) 149-150, pl. I</p>
<p :class="{'activeClass': idClicked == NP240 }" id="NP240" >ANTONIUS OXYLOS of Elis, base of statue set up on Akr. by decree, 117/118 A.D. - - - IG II² 1072</p>
<p :class="{'activeClass': idClicked == NP241 }" id="NP241" >ANUBIS: see Isis (2); Sarapis (2)</p>
<p :class="{'activeClass': idClicked == NP242 }" id="NP242" >APHELIA: see Aidos </p>
<p :class="{'activeClass': idClicked == NP243 }" id="NP243" >APHRODITE</p>
<p :class="{'activeClass': idClicked == NP244 }" id="NP244" >1* Dedication, ca. 475 B.C., built into the fortification wall connecting the Nike</p>
<p :class="{'activeClass': idClicked == NP245 }" id="NP245" >Bastion with the Beule Gate south tower, Aphrodite Pandemos site IG I³ 832 = IG I² 700; Raubitschek, A. E. 1949, no. 296 with drawing</p>
<p :class="{'activeClass': idClicked == NP246 }" id="NP246" >2* Dedication on a small plaque <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202526" target="_blank">Agora I 2526</a>; Meritt, B. D. 1941, 60 no. 24, ph. 3* Dedication, 4th-2nd c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206952" target="_blank">Agora I 6952</a>; probably from an offering table, B. D. Meritt, “Greek Inscriptions,” Hesperia 32 (1963) 46 no. 63, pl. 15; SEG 21 (1965) no. 787</p>
<p :class="{'activeClass': idClicked == NP247 }" id="NP247" >4* Statue, late 5th c. B.C. - - - Acr. 2861; Delivorrias, A. et al. 1984, 32 no. 203, ph.</p>
<p :class="{'activeClass': idClicked == NP248 }" id="NP248" >5* Statue by Kalamis dedicated by Kallias on the Akropolis, ca. 450 B.C.?: - - - IG I³ 876; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205128" target="_blank">Agora I 5128</a>; Paus. 1.23.2; Delivorrias, A. et al. 1984, 23 no. 146</p>
<p :class="{'activeClass': idClicked == NP249 }" id="NP249" >6* Statue dedicated ca. 127 A.D. in the temple of Isis on the Akropolis South Slope: IG II² 4771 lines 2-3; Walker, S. 1979a, 247 no. 6, 248, 252; see Isis, naiskos</p>
<p :class="{'activeClass': idClicked == NP250 }" id="NP250" >7* Statue by Praxias, ca. 360-350 B.C., Akr. northwest slope IG II² 2820; SEG 47 (1997) no. 211</p>
<p :class="{'activeClass': idClicked == NP251 }" id="NP251" >8* Statue, ca. 420 B.C., built into the Post-Herulian Wall - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201882" target="_blank">Agora S 1882</a>; E. B. Harrison, “New Sculpture from the Athenian Agora, 1959,” Hesperia 29 (1960) 373-376, pl. 82; Delivorrias, A. et al. 1984, 26 no. 162, ph. </p>
<p :class="{'activeClass': idClicked == NP252 }" id="NP252" >9* Statue holding hydria, Louvre-Naples type, mid-2nd c. A.C., near the Nymphaeum in the Agora Excavations (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM296">296</a>) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201654" target="_blank">Agora S 1654</a>; P. Karanastassis, “Untersuchungen zur kaiserzeitlichen Plastik in Griechenland. I. Kopien, Varianten und Umbildungen nach Aphrodite-Typen des 5. Jhs. v. Chr.” AM 101 (1986) 228, 282-287 no. Al 16, pl. 56 nos. 1-2 and catalogue entries Al 11, 13, 23, 26, 29 for five other examples of the type also found in the Agora Excavations; Agora Guide 1990, 162, 206</p>
<p :class="{'activeClass': idClicked == NP253 }" id="NP253" >10* Two statues at the Ares temple: Paus. 1.8.4; E. B. Harrison, op. cit. (no. Aph8* above), 374; Delivorrias, A. et al. 1984, 35-36 no. 242, ph. (= <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%2037" target="_blank">Agora S 37</a>) and 42 no. 293, ph. (= <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20378" target="_blank">Agora S 378</a>)</p>
<p :class="{'activeClass': idClicked == NP254 }" id="NP254" >11* Statue, ca. 430 B.C., Olympieion area - - - NM 4762; Delivorrias, A. et al. 1984, 25 no. 152; <JBcom>[ from the sanctuary of Aphrodite in the Gardens?]</JBcom></p>
<p :class="{'activeClass': idClicked == NP255 }" id="NP255" >12* Head frag., Arles head type, rough crosses on forehead and on chin, 1st c. A.C., Roman <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/nm%201762" target="_blank">Agora NM 1762</a>; B. S. Ridgway, “The Aphrodite of Arles,” AJA 80 (1976) 153-154, pl. 23, figs. 5-6; Delivorrias, A. 1991, 113-114, pl. 54d; Kaltsas, N. 2002, 244 no. 510, ph.</p>
<p :class="{'activeClass': idClicked == NP256 }" id="NP256" >13* Torso frag., Arles type, at the Lysikrates Mon. NM 227; B. S. Ridgway, op. cit. (no. 12*) 149, pl. 23, figs. 2-4; Delivorrias, A. et al. 1984, 63-64 no. 528, ph.; Kaltsas, N. 2002, 241 no. 507, ph.</p>
<p :class="{'activeClass': idClicked == NP257 }" id="NP257" >14* Statuettes, at least 40 in number, Akropolis lower west slope near Dörpfeld's Enneakrounos: C. Smith, “Archaeology in Greece, 1895-6,” BSA 2 (1895-96) 49; Delivorrias, A. et al. 1984, 80 nos. 712, ph., and 713</p>
<p :class="{'activeClass': idClicked == NP258 }" id="NP258" >15* Aphrodite statuette, from the Amyneion - - - NM no number (storeroom number ΣΑ 218); N. Yalouris, «8. Εύρηματα Άμυνείου,» Deltion 29 (1973/74) B1 Chr., 3 no. 8 epsilon, pl. 8</p>
<p :class="{'activeClass': idClicked == NP259 }" id="NP259" >16* Statuette of Aphrodite with a dolphin ridden by Eros, owned by Byron BM cat. no. 1579; Smith, A. H. 1904, no. 31</p>
<p :class="{'activeClass': idClicked == NP260 }" id="NP260" >17* Statuette, Aphrodite of Cnidus type, owned by Byron - - - BM cat. no. 1575; Smith, A. H. 1904, 29; L. Closuit, L'Aphrodite de Cnide, Pillet-Martigny, 1978, 42-43 no. 3, ph.</p>
<p :class="{'activeClass': idClicked == NP261 }" id="NP261" >18* Statuette, 1st c. B.C., found in 1904 near the Hephaisteion NM 2585; Karouzou, S. 1968, 184; Kaltsas, N. 2002, 293 no. 614, ph.</p>
<p :class="{'activeClass': idClicked == NP262 }" id="NP262" >19* Statuettes, more than 250 in number, of at least three different types, found in the Agora Excavations, awaiting publication</p>
<p :class="{'activeClass': idClicked == NP263 }" id="NP263" >20* Aphrodite with dolphin, 2nd - 3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20346" target="_blank">Agora S 346</a>; Shear, T. L. 1935, 394-395, figs. 19-21; T. L. Shear, “Archaeological Notes: The Conclusion of the 1936 Campaign in the Athenian Agora,” AJA 40 (1936) 410-411, fig. 7; Delivorrias, A. et al. 1984, 80 nos. 712, ph., and 713</p>
<p :class="{'activeClass': idClicked == NP264 }" id="NP264" >21* Architectural relief, Aphrodite on flying swan with 2nd swan and goat, 1st c. B.C., built into the southwest corner of Panagia Gorgoepekoos (Chr. Mon. CM75*): Delivorrias, A. et al. 1984, 98 no. 936, ph. See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM323">323</a>, sanctuary inventory 22* </p>
<p :class="{'activeClass': idClicked == NP265 }" id="NP265" >APHRODITE ALOPEKESIN, cult: IG II² 3683 line 6</p>
<p :class="{'activeClass': idClicked == NP266 }" id="NP266" >22** APHRODITE (APHRODISIAS), statuette, copy of the cult statue of Aphrodite in Aphrodisias, Caria, 2nd c. A.C., northeast corner of the Royal Gardens (Plan 2 zeta—7)- - - NM 2147 + 1795; C. Fredrich, “Die Aphrodite von Aphrodisias in Karien,” AM 22 (1897) 361, 362 with drawing, 363 A, pl. 11 A; LIMC 11,1984, 152 no.11, ph., s.v. Aphrodite Aphrodisias (R. Foerster)</p>
<p :class="{'activeClass': idClicked == NP267 }" id="NP267" >23* APHRODITE ENAGONIOS, base found in the theater, now south of the theater - - - IG II² 5013</p>
<p :class="{'activeClass': idClicked == NP268 }" id="NP268" >APHRODITE EPITRAGIA: <JBcom>[since Aphrodite Epitragia has two theater seats, whereas Aphrodite Ourania has neither a throne nor a seat in the theater one of the theater seats may be for the sacred official of the sanctuary of Aphrodite Ourania founded by Aigeus]</JBcom></p>
<p :class="{'activeClass': idClicked == NP269 }" id="NP269" >24* Theater seat - - - IG II² 5115</p>
<p :class="{'activeClass': idClicked == NP270 }" id="NP270" >25* Theater seat IG II² 5148 cf. Aphrodite Ourania Aph39*-40*, Aph41*- Aph43*, reliefs of Aphrodite on a goat; cf. A. Schober, “Zu den elischen Bildwerken der Aphrodite,” JOAI 21-22 (1922-24) 225-228</p>
<p :class="{'activeClass': idClicked == NP271 }" id="NP271" >26* APHRODITE έφ' Ίππολύτω, sanctuary on the Akropolis South Slope west of the Asklepieion, mentioned in an inventory of the treasurers of the Other Gods, 429/428 B.C.: IG I³ 383 lines 233-4; Kearns, E. 1989, 173</p>
<p :class="{'activeClass': idClicked == NP272 }" id="NP272" >27* Temple founded by Phaidra: E. Hipp. 30-34 with schol.; W. S. Barrett, ed., Euripides, Hippolytos, Oxford, 1978, 1-10, 13-15, 27-28, 32-34, 44-45, 159-162; cf. Isis, naiskos facing south, Akropolis South Slope, west of the Asklepieion.</p>
<p :class="{'activeClass': idClicked == NP273 }" id="NP273" >28* Burial mound of Hippolytos in front of the temple of Themis: Paus. 1.22.1; La Rocca, E. 1975, 447 See also: Aphrodite Aph6*, statue in Isis temple; Hermes Hrm35*</p>
<p :class="{'activeClass': idClicked == NP274 }" id="NP274" >APHRODITE HEGEMONE AND CHARITES<br> 29* Altar with two roses in relief dedicated by the Boule to Aphrodite Hegemone of the Demos and to the Charites, 197 B.C., lower north slope of Kolonos Agoraios - - - NM 1495; IG II² 2798; SEG 21 (1965) no. 672; Travlos, J. 1971, 79, fig. 102 B, figs. 103-104; Monaco, M. C. 2001, 114-119, fig. 7; Kaltsas, N. 2002, 306 no. 643; The inscription faced north, A. Lolling, «Βόμος και έπιγραφαι,» Deltion Z' (1891),126-123</p>
<p :class="{'activeClass': idClicked == NP276 }" id="NP276" >30* Relief frag, with Three Charites - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20477" target="_blank">Agora S 477</a>; the composition was recognized by W. Fuchs in 1954</p>
<p :class="{'activeClass': idClicked == NP277 }" id="NP277" >31* APHRODITE HETAIRA, sanct.: Hsch. s.v. Ἑταίρας ἱερόν, O. Jessen, RE VIII, 1913, col. 1331 (Hetaira)</p>
<p :class="{'activeClass': idClicked == NP278 }" id="NP278" >APHRODITE IN THE GARDENS, sanctuary on the Ilissos: IG I³ 369 line 80, 423/422 B.C., is the earliest testimonium. The site has not been found: cf. Aphrodite Aph11*; Arrhephoroi; Eros and Aphrodite; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM557">557</a></p>
<p :class="{'activeClass': idClicked == NP279 }" id="NP279" >32* Temple seen by Pausanias after Apollo Delphinios and before he saw Kynosarges: Paus. 1.19.2 + 1.27.3</p>
<p :class="{'activeClass': idClicked == NP280 }" id="NP280" >33* Temple statue by Alkamenes: Paus. 1.19.2; Delivorrias, A. 1984, 31 no. 193; Pollitt, J. J. 1990, 65, 252</p>
<p :class="{'activeClass': idClicked == NP281 }" id="NP281" >34* APHRODITE KOLIAS, theater seat IG II² 5119</p>
<p :class="{'activeClass': idClicked == NP282 }" id="NP282" >APHRODITE LAMIA: see Demetrios Poliorketes, disgraceful cults </p>
<p :class="{'activeClass': idClicked == NP283 }" id="NP283" >APHRODITE LEAINA: see Demetrios Poliorketes, disgraceful cults </p>
<p :class="{'activeClass': idClicked == NP284 }" id="NP284" >APHRODITE, NEA THEA APHRODITE: see Drusilla</p>
<p :class="{'activeClass': idClicked == NP285 }" id="NP285" >35* APHRODITE OURANIA, sanctuary founded by Aigeus, near the Hephaisteion: Paus. 1.14.7; Shear Jr., T. L. 1984, 24-33, figs.1-4, 10, 13-16, pls. 1, 5:b, 6-7, 8:a, 9; C. M. Edwards, “Aphrodite on a Ladder,” Hesperia 53 (1984) 59-72; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM347">347</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM365">365</a></p>
<p :class="{'activeClass': idClicked == NP286 }" id="NP286" >36* Altar, ca. 500 B.C., in the northeastern section of the Agora Excavations in the city block bounded by Adrianou, Thissiou, Astingos and Hag. Philippou Sts. (Plan 1 alpha—2): Shear Jr., T. L. 1984, 24-33, 37-38, figs. 1-4, 10. 13-16, pls.1, 6-9; 3200 burnt bone fragments, mainly sheep and goat, found in the altar, D. S. Reece, “Faunal Remains from the Altar of Aphrodite Ourania, Athens,” Hesperia 58 (1989) 63-70, pls. 15-16; growing doubts about the identification are indicated in a plan by Richard Anderson, Hesperia 68 (1999) 264 fig. 13, where the altar is labelled “Archaic Altar” </p>
<p :class="{'activeClass': idClicked == NP287 }" id="NP287" >37* Statue of Parian marble by Pheidias: Paus. 1.14.7; Delivorrias et al. 1984, 27 no. 175; cf. Herms Her19*</p>
<p :class="{'activeClass': idClicked == NP288 }" id="NP288" >38* Aphrodite on a ladder, relief, late 5th c. B.C., 10 m. east of the altar of Aphrodite (no. 36 above) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%203344" target="_blank">Agora S 3344</a>; Shear Jr., T. L. 1984, 38; C. M. Edwards, op. cit., 59-72, pl. 17:a</p>
<p :class="{'activeClass': idClicked == NP289 }" id="NP289" >39* Aphrodite on a goat, relief, 3rd c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201158" target="_blank">Agora S 1158</a>; C. M. Edwards, op. cit., 69-70, pl. 18:a</p>
<p :class="{'activeClass': idClicked == NP290 }" id="NP290" >40* Aphrodite on a goat, relief, 3rd c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201944" target="_blank">Agora S 1944</a>; C. M. Edwards, op. cit., 70-71, pl. 18:c cf. Aphrodite Epitragia Epi24*, Epi25*; Herms Her19*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM347">347</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM365">365</a></p>
<p :class="{'activeClass': idClicked == NP292 }" id="NP292" >40** Aphrodite Ourania, inscribed marble treasury-box, early 4th c. B.C., Akropolis northeast slope at 19 Epicharmou St. (Plan 1 epsilon—8) - - - 1st Ephoria (number not known); assigned to the Eros and Aphrodite sanctuary on the Akropolis North Slope, K. Tsakos and Κ. N. Kazamiakis, «θησαυροί Αφροδίτης Ουρανίας - η κατασκευή,» Horos 8-9 (1990/1991) 17-28, 29-44, figs. 1-9, pls. 6-7; SEG 49 (1999) no. 222</p>
<p :class="{'activeClass': idClicked == NP293 }" id="NP293" >41* Aphrodite on a goat, relief, Akropolis South Slope at the Asklepieion - - - NM 2422; Svoronos, J. N. 1937, 646 no. 382, pl. 157</p>
<p :class="{'activeClass': idClicked == NP294 }" id="NP294" >42* Aphrodite on a goat, Hellenistic relief disk Louvre MA 2701; Delivorrias et al. 1984, 99 no. 957, ph.</p>
<p :class="{'activeClass': idClicked == NP295 }" id="NP295" >43* Aphrodite riding a goat amidst moon and stars, silver relief disk, 4th c. B.C., Bldg. Z by the Sacred Gate - - - Kerameikos M 374; <JBcom>[questionably]</JBcom> identified as Aphrodite Panolemos, U. Knigge, «Ό αστήρ τής Άφροδίτης,» ΑΜ 97 (1982) 153-170, pl. 31</p>
<p :class="{'activeClass': idClicked == NP296 }" id="NP296" >44* Herm of Aphrodite Ourania, oldest of the Moirai, at Aphrodite in the Gardens: Paus. 1.19.2; Agora XI, 138-9,168; Delivorrias et al. 1984, 10-11 no. 10 and 31 no. 193</p>
<p :class="{'activeClass': idClicked == NP297 }" id="NP297" >45* APHRODITE PANDEMOS, sanctuary south and southwest of the Nike Bastion: cult established by Theseus, Paus. 1.22.3; IG II² 659 lines 11-12; Daux, G., 1961, 607, figs. 10-11; Johrens, G. 1981, 126-127; for a reconstructed drawing of the Akropolis by M. Korres showing the sanctuary below the Nike Bastion, see Parthenon 1994, 350-351 no. 18; <JBcom>[imaginary]</JBcom> site in the saddle between the Areopagus and the Akropolis, Travlos, J. 1971, 1, 2, fig. 5 no. 6</p>
<p :class="{'activeClass': idClicked == NP298 }" id="NP298" >Regulations concerning the sanctuary of Aphrodite Pandemos, 283/2 B.C. found at the Beule Gate - - - IG II² 659; SEG 45 (1955) no. 105</p>
<p :class="{'activeClass': idClicked == NP299 }" id="NP299" >46* Altars: IG II² 659 line 25</p>
<p :class="{'activeClass': idClicked == NP300 }" id="NP300" >47* Cult statues: IG II² 659 line 26; the original cult statue and the replacement: Paus. 1.22.3; G. S. Dontas, “Ein verkanntes Meisterwerk im Nationalmuseum von Athen,” in Festschrift fur N. Himmelmann 1989, 144-145; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM150">150</a></p>
<p :class="{'activeClass': idClicked == NP301 }" id="NP301" >48* Dedication made by the priestess Menekrateia, daughter of Dexikrates, and others, family group in naiskos with frieze of doves and fillets, now set up south of the Beule Gate - - - IG II² 4596; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.” Jahrbuch 88 (1973) 106 note 319; the naiskos probably stood on the bedding south of the Nike Bastion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM141">141</a>)</p>
<p :class="{'activeClass': idClicked == NP302 }" id="NP302" >49* Aphrodite Pandemos, Nymphe, priestess' theater seat - - - IG II² 5149; La Rocca, E. 1975, 446 See also: Aphrodite Aph1*; Peitho; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM141">141</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM142">142</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM146">146</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM150">150</a>; cf. Aphrodite Aph43*, silver disk</p>
<p :class="{'activeClass': idClicked == NP303 }" id="NP303" >50* APHRODITE PSITHYROS and EROS PSITHYROS, sanctuary: Eust.Od. 20.8; F GR HIST III B, 188, Zopyros 336 F 2 with commentary</p>
<p :class="{'activeClass': idClicked == NP304 }" id="NP304" >51* APHRODITE AND EROS <br>Statue of Aphrodite with Eros on her shoulder, Hellenistic pd. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20473" target="_blank">Agora S 473</a>; Delivorrias, A. 1984, 43 no. 307, ph.</p>
<p :class="{'activeClass': idClicked == NP306 }" id="NP306" >52* Aphrodite with Eros behind her shoulder, statuette, Hellenistic pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201192" target="_blank">Agora S 1192</a>; Delivorrias, A. 1984, 42 no. 300, ph.</p>
<p :class="{'activeClass': idClicked == NP307 }" id="NP307" >53* Statuette dedicated to Athena - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201787" target="_blank">Agora I 1787</a>; Meritt, B. D. 1941, 63-64 no. 29, ph.; (the inscription is in one piece with the statuette)</p>
<p :class="{'activeClass': idClicked == NP308 }" id="NP308" >54* Statuette, south of the Hephaisteion <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201806" target="_blank">Agora S 1806</a>; unpublished See also: Eros and Aphrodite</p>
<p :class="{'activeClass': idClicked == NP309 }" id="NP309" >55* APHRODITE, (PAN and EROS), bronze statuette, Akropolis East Slope - - - NM 7406; Delivorras, D. et al. 1984, 62 no. 515</p>
<p :class="{'activeClass': idClicked == NP310 }" id="NP310" >APHRODITE and OTHERS: see Demeter and A.; Hermes Hrm34*, Hrm35*</p>
<p :class="{'activeClass': idClicked == NP311 }" id="NP311" >APOLLO</p>
<p :class="{'activeClass': idClicked == NP312 }" id="NP312" >1* Altar in the Agora gilded by Neoptolemos, son of Antikles, in accordance with an oracle, ca. 330 B.C.: Plu. Moralia 843 F; Agora III, 52 no. 113; <JBcom>[altar of Apollo Patroos?]</JBcom></p>
<p :class="{'activeClass': idClicked == NP313 }" id="NP313" >2* Cave sanctuary of Apollo Hypoakraios with rock-cut niches for votive plaques dedicated by archons to Apollo with various epithets (Apollo Apo5*-7* below), Augustan pd. - 3rd c. A.C., Akropolis North Slope, Cave Beta IG II² 2891-2930 +; Paus. 1.28.4; Kavvadias, P. 1897, 1-27, pls.1-4; <JBcom>[mistakenly]</JBcom> identified as the Pythion, Travlos, J. 1971, 91, 422, figs. 116, 540 no. 134; cf. E. Meyer, RE XXIV, 1963, col. 554-558 (Pythion no. 2); SEG 50 (2000) no. 190; Nulton, P. E. 2000, 71-74 cat. no. 4, ph.; see Thesmothetai</p>
<p :class="{'activeClass': idClicked == NP314 }" id="NP314" >3* Rock-cut bedding for an altar (?) in front of the cave: Kavvadias, P. 1897, 15, pl. 1 beta; Keramopoullos, A. D. 1929, 87-88; Nulton, P. E. 2000, 10 note 13, 13-14 note 20, 39 note 90; <JBcom>[according to those who have seen it, the rock-cutting does not resemble an altar bedding]</JBcom></p>
<p :class="{'activeClass': idClicked == NP315 }" id="NP315" >4* Dedication to Apollo, 480-470? B.C., Akropolis North Slope, reused for the third or fourth time in the Klepsydra spring house parapet IG I² 950 = <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205517" target="_blank">Agora I 5517</a>; Parsons, A. W. 1943, 239-240 with note 129; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 79 no. 24, pls. 16,25; SEG 16 (1959) no. 14; this dedication has no bearing on the founding date of the cult of Apollo Hypoakraios and the site of the Pythion, Nulton, P. E. 2000, 135-137, fig. 3</p>
<p :class="{'activeClass': idClicked == NP316 }" id="NP316" >5* Apollo Hypoakraios, relief plaque on which a naiskos with elaborate Corinthian columns, pairs of warriors in rasura, a palm tree on the right, dedication by a polemarch, 100/101 A.D., Akropolis North Slope IG II² 2914; SEG 46 (1996) no. 246; Nulton, P. E. 2000, 79-82, ph.</p>
<p :class="{'activeClass': idClicked == NP317 }" id="NP317" >6* Apollo ύπ' ακραίς, dedication by Trebellius Rufus and two others, 85-95 A.D., found at the base of the ledge in front of Cave Beta — II² 2893; Travlos, J. 1971, fig. 120; Nulton, P. E. 2000, 71-74 cat. no. 4, ph.</p>
<p :class="{'activeClass': idClicked == NP318 }" id="NP318" >7* Apollo υπό Μακραις, dedication, 40-54 A.D. IG II² 2891; Travlos, J. 1971, fig.121; Nulton, P. E. 2000, 64-66, ph.</p>
<p :class="{'activeClass': idClicked == NP319 }" id="NP319" >8* Dedication reused in a Byzantine grave at the Basilica on the Ilissos (Chr. Mon. CM15*): Peek, W. 1942, 41-42 no. 51; <JBcom>[Apollo's name plausibly restored]</JBcom></p>
<p :class="{'activeClass': idClicked == NP320 }" id="NP320" >9* Dedication by Dionysios, son of Dionysodoros, Lykeion epimelete, after 50 B.C., found in 1833 at the door of the Russian Church on Philhellenon St. (Plan 1 epsilon—-11, no. 85)— - - IG. II² 2875; E. Vanderpool, “The Museum and Garden of the Peripatetics,” AE 1953/54 II, 126; Travlos, J. 1971, 345, fig. 449; Lynch, J. P. 1972, 17-21; Ritchie Jr., C. E., 1989, 256; SEG 39 (1989) no. 203</p>
<p :class="{'activeClass': idClicked == NP321 }" id="NP321" >10* Dedication to Apollo by a gymnasiarch on an epistyle, Roman pd., north of Hag. Ioannis Kolonna between Menandrou St. and Plateia Theatrou (Plan 2 delta—4) — IG II² 3002; A. A. Papagiannopoulos-Palaios, «To Διογένειον Γυμνάσιον,» Polemo III (1947-48) 22-24</p>
<p :class="{'activeClass': idClicked == NP322 }" id="NP322" >11* Oracles of Apollo prophesying the coming of Christ: Fragmente griechischer Theosophien, H. Erbse ed. = Hamburger Arbeiten zur Altertumswissenschaft vol. 4), Hansischer Gildenverlag, Hamburg, 1941</p>
<p :class="{'activeClass': idClicked == NP323 }" id="NP323" >In a temple in Athens: ibid. 180, par. 53-54 (Theosophia Tubingensis, compiled in last q. of 5th c.)</p>
<p :class="{'activeClass': idClicked == NP324 }" id="NP324" >In the temple of Apollo in Athens, deposited by Diogenes, found by Constantine the Great: ibid. 222 (the text is presumably Justinianic or later)</p>
<p :class="{'activeClass': idClicked == NP325 }" id="NP325" >APOLLO (continued): SCULPTURE</p>
<p :class="{'activeClass': idClicked == NP326 }" id="NP326" >12* Statue made and dedicated by Euboulides in the precinct of Dionysos Melpomenos: Paus. 1.2.5</p>
<p :class="{'activeClass': idClicked == NP327 }" id="NP327" >13* Base with Euboulides' signature but without a dedication, ca. 130 B.C., Ermou St. west of Hag. Asomaton church (Plan 2 epsilon—3)- - - IG II² 4298; perhaps belonging to Euboulides' dedication, Frazer, J. G., 51-53; Travlos, J. 1971, fig. 219 no. 243, fig. 540 no. 243, fig. 722 B; Schenkungen hellenistischer Herrscher, Part I, 1995, 443-444; Despinis, G. 1995, 321-338, ph.; SEG 45 (1995) no. 183</p>
<p :class="{'activeClass': idClicked == NP328 }" id="NP328" >14* Statue by Leochares in front of the temple of Apollo Patroos: Paus. 1.3.4; usually linked to Apollo Belvedere, LIMC II, 1984, 198 no. 179, s.v. Apollon (O. Palagia) and 381-382, s.v. Apollon-Apollo (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP329 }" id="NP329" >15* Statue by Peisias in the Bouleuterion: Paus. 1.3.5</p>
<p :class="{'activeClass': idClicked == NP330 }" id="NP330" >16* Statuette - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202058" target="_blank">Agora S 2058</a>; LIMC II, 1984, 387 under no. 71, s.v. Apollon-Apollo (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP331 }" id="NP331" >16** Statue known as the Omphalos Apollo, 2nd c. A.C., Theater of Dionysos - - - NM 45; copy of a bronze Apollo statue, ca. 460 B.C., appearing on New Style silver tetradrachms, 144/3 B.C., and on Athenian imperial bronzes, Herzog, H. 1996, 13-15; Kaltsas, N. 2002, 90-91 no. 155, ph.</p>
<p :class="{'activeClass': idClicked == NP332 }" id="NP332" >17* Base of a bronze statue dedicated to Apollo by the Athenians, ca. 365/4 B.C., between Lembesi and Vourvachi Sts. in the area of the Apollo Pythios sanct. (Plan 2 theia—5) - - - IG II² 2789; Travlos, J. 1971, 100 C with fig. 130 C; LIMC II, 1984, 259 no. 619c, s.v. Apollon (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP333 }" id="NP333" >18* Torso, lower Akropolis South Slope on Kallisperi St. (Plan 2 eta—4): Alexandri, O. 1968, 63 no. 42, fig. 1 no. 44, fig. 33 gamma</p>
<p :class="{'activeClass': idClicked == NP334 }" id="NP334" >19* Torso, Cassel Apollo type, first half of 2nd c. A.C., east of the Olympieion (probable findspot) NM 1612; E. Schmidt, Der Kasseler Apollon und seine Repliker,” Antike Plastik, 1966, 16-17, pls. 14-15; “found near Votanikos, Athens,” Kaltsas, N. 2002, 102 no. 183</p>
<p :class="{'activeClass': idClicked == NP335 }" id="NP335" >20* Head, Cassel Apollo type, 2nd c. A.C., east of the Olympieion not far from no. Apo19* - - - NM 47; E. Schmidt, op. cit, 20-21, pls. 20-22; Kaltsas, N. 2002, 103 no. 185, ph.</p>
<p :class="{'activeClass': idClicked == NP336 }" id="NP336" >20** Statue of Apollo holding bow and laurel branch on silver tetradrachms of Athens, 175/4 B.C.: linked to the Cassel Apollo, LIMC II, 1984, 217-218 no. 281, ph., s.v. Apollon (W. Lambrinudakis); Herzog, H. 1996, 11-13</p>
<p :class="{'activeClass': idClicked == NP337 }" id="NP337" >21* Statue of Apollo set up by Plato's disciples (?): Academicorum philosophorum index Herculanensis col. 2, lines 18-31; K. Gaiser, "Philochoros über zwei Statuen in Athen,” in Festschrift fur U. Hausmann 1982, 97-100 See also: Augustus as Neos Apollon, statue base; Nero; Panathenaic Stadium; Pyloroi, incised Apollo statue</p>
<p :class="{'activeClass': idClicked == NP338 }" id="NP338" >APOLLO AGYIEUS: LIMC II, 1984, 327-332, s.v. Apollon Agyieus, 327-332 (E. di Filippo Balestrazzi)</p>
<p :class="{'activeClass': idClicked == NP339 }" id="NP339" >22* Altar dedicated by the pyloroi, mid-1st c. A.C., Akr. at the Agrippa Mon. - - - IG II² 4719; Agora XIV, 169 with note 270; cf. Pyloroi</p>
<p :class="{'activeClass': idClicked == NP340 }" id="NP340" >23* Apollo Agyieus Alexikakos, base, City Wall Gate at Sophokleous and Aiolou Sts. (Plan 2 delta—5) - - - IG II² 4850</p>
<p :class="{'activeClass': idClicked == NP341 }" id="NP341" >24* Apollo Agyieus Prostaterios Patroos Pythios Klarios Panionios, altar or relief base, Apollo with lyre, 1st c. A.C., Panagia Kristaliotissa Church (Plan 1 no. 110) on Diogenous St. - - - NM 1730, IG II² 4995; Bull. 1977 no. 162; LIMC 11,1984, 211 no. 219 with drawing, ph., s.v. Apollon (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP342 }" id="NP342" >25* APOLLO ALEXIKAKOS, statue by Kalamis in front of the temple of Apollo Patroos: Paus. 1.3.4, 8.41.8; Hedrick Jr., C. W. 1988, 195-198</p>
<p :class="{'activeClass': idClicked == NP343 }" id="NP343" >26* APOLLO ANADOUMENOS, statue in Ares temple precinct: Paus. 1.8.4; LIMC II, 1984, 242 nos. 467-468, s.v. Apollon (W. Lambrinudakis)</p>
<p :class="{'activeClass': idClicked == NP344 }" id="NP344" >27* APOLLO BELVEDERE: see Apollo Apo14*</p>
<p :class="{'activeClass': idClicked == NP345 }" id="NP345" >28* APOLLO DAPHNEPHOROS, priest's theater throne, Hadrianic or later IG II² 5079; Maass, M. 1972, 138, pl. 21</p>
<p :class="{'activeClass': idClicked == NP346 }" id="NP346" >29* APOLLO DELIOS, priest's theater throne, perhaps 1st c. B.C. - - - IG II² 5052; Maass, M. 1972, 123-124, pl. 14</p>
<p :class="{'activeClass': idClicked == NP347 }" id="NP347" >30* APOLLO DELPHINIOS, sanctuary between the sanctuary of Apollo Pythios and the Gardens: Paus. 1.19.1; often called the Delphinion, Plu. Thes. 18 (twice); called sanct. of Artemis and Apollo Delphinios founded by Aigeus, schol. D. 23.74; F. Graf, “Apollo Delphinios,” MH 36 (1979) 12-19</p>
<p :class="{'activeClass': idClicked == NP348 }" id="NP348" >31* Temple of Apollo Delphinios built in Theseus' lifetime: Paus. 1.19.1 (thrice); cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM549">549</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM550">550</a></p>
<p :class="{'activeClass': idClicked == NP349 }" id="NP349" >32* Apollo Delphinios and Artemis Delphinia, dedication IG II² 3725</p>
<p :class="{'activeClass': idClicked == NP350 }" id="NP350" >33* Enclosure in the Delphinion on the site of Aigeus' house, where Medea's poison for Theseus spilled; Plu. Thes. 12.3; Kron, U. 1976, 115, 125, 128-134</p>
<p :class="{'activeClass': idClicked == NP351 }" id="NP351" >34* Lawcourt in the Delphinion where Theseus was put on trial: Paus. 1.28.10; Agora XXVIII, 48-49, 91, 135-139 and Index s.v. Delphinion; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM554">554</a></p>
<p :class="{'activeClass': idClicked == NP352 }" id="NP352" >35* APOLLO ERITHASEOS, statue base (?), Academy area - - -IG II² 5010; Guarducci, M. 1967-1978, IV, 18-19; since the priest's decree concerning the sanctuary of Apollo Erithaseos, IG II² 1362, was found at Kamatero at the northwest edge of present-day Athens not far from Menidi (Acharnai), the sanctuary was probably not in ancient Athens</p>
<p :class="{'activeClass': idClicked == NP353 }" id="NP353" >36* APOLLO GEPHYRAIOS, arula, north of Vasilissis Olgas Blvd. at the Zappeion Bldg. (Plan 2 eta—6) - - - IG II² 4813; Davies, J. K. 1971, 479</p>
<p :class="{'activeClass': idClicked == NP354 }" id="NP354" >37* APOLLO HEKATOMBAIOS, cult: EM s.v. Έκατομβαιών; J. D. Mikalson, The Sacred and Civil Calendar of the Athenian Year, Princeton, 1975, 26</p>
<p :class="{'activeClass': idClicked == NP355 }" id="NP355" >APOLLO HYPOAKRAIOS: see Apollo Apo2*-7*</p>
<p :class="{'activeClass': idClicked == NP356 }" id="NP356" >38* APOLLO KERKYONEUS, cult in Athens or Attica.; IG II² 1972 line 7 </p>
<p :class="{'activeClass': idClicked == NP357 }" id="NP357" >APOLLO KITHAROIDOS See Apollo Patroos Pat60*, temple statue</p>
<p :class="{'activeClass': idClicked == NP358 }" id="NP358" >39* Relief, Akropolis South Slope, Asklepieion - - - NM 1359; Svoronos, J. N. 1908, 279, no. 56, pl. 46; Palagia, O. 1980, 15-17, 19 no. 2</p>
<p :class="{'activeClass': idClicked == NP359 }" id="NP359" >40* APOLLO LYKEIOS, sanctuary with a gymnasium in northeast Athens, the Lykeion: place named for Lykos son of Pandion, Paus. 1.19.3 + 4; Wycherley, R. E. 1978, 226-229; Ritchie Jr., C. E. 1989, 251-260; Kearns, E. 1989, 182 s.v. Lykos; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM497">497</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM500">500</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM504">504</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM505">505</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM505">505</a> BIS <br>Sanctuary of Apollo Lykeios: Paus. 1.19.3; Lynch, J. P. 1972, 1-2, 9-32, 45-46, 209-212, fig. 1; the Lykeion is once attested as the meeting-place of the Assembly of the People: IG I³ 105 line 34; Ryan, F. X. 1994, 126-128; SEG 44 (1994) no. 9</p>
<p :class="{'activeClass': idClicked == NP361 }" id="NP361" >41* Statue of Apollo Lykeios leaning on a column supporting a tripod, 350-325 B.C.: Lucian Anach. 7; E. J. Milleker, The Statue of Apollo Lykeios in Athens, University Microfilms, 1988, 1-351; the statue may have stood in the palaestra, op. cit., 7.</p>
<p :class="{'activeClass': idClicked == NP362 }" id="NP362" >Representations on New Style Tetradrachms: LIMC II, 1984, 193 no. 39 b, ph., s.v. Apollon (O. Palagia); S. F. Schroder,” Der Apollon Lykeios and die attische Ephebie des 4. Jhs.,” AM 101 (1986), 167-184, fig.1; E. J. Milleker, op. cit., 10-14, 122-137.</p>
<p :class="{'activeClass': idClicked == NP363 }" id="NP363" >Representations on Athenian imperial bronze coins of mid-3rd c. A.C.: E. J. Milleker, op. cit., 14-17, 138-144</p>
<p :class="{'activeClass': idClicked == NP364 }" id="NP364" >42* Priest's theater throne IG II² 5073; Maass, M. 1972, 135-136, pl. 20</p>
<p :class="{'activeClass': idClicked == NP365 }" id="NP365" >43* Wall-paintings by Kleagoras: X. An. 7.8.1; Delorme, J. 1960, 56 with note 3</p>
<p :class="{'activeClass': idClicked == NP366 }" id="NP366" >44* Gymnasium of Apollo Lykeios: Paus. 1.29.16; Frazer; J. G. 1913, 196-198; Delorme, J. 1960, 523 s.v. Athenes (Lycee); Kyle, D. J. 1987, 77-84</p>
<p :class="{'activeClass': idClicked == NP367 }" id="NP367" >45* Bath used by Sokrates: Pl. Smp. 223 D</p>
<p :class="{'activeClass': idClicked == NP368 }" id="NP368" >46* Palaestra built by Lykourgos: Plu. Moralia 841 D, 843 F; Delorme, D. J. 1960, 56-57. A large complex, late 4th c. B.C. - late 4th c. A.C. on the Ilissos right bank east of Rigillis St.and north of Vas. Georgiou B' St. (Plan 2 zeta — 7) has been <JBcom>[doubtably]</JBcom> identified as the palaestra in the Lykeion gymnasium, E. Lygouri-Tolia, “Excavating an ancient palaestra in Athens,” in Stamatopoulou and Yerolanou 2002, 203-212, figs. 1-2, pls. 45-48. The theater tickets, cited as “particularly important” for the identification because they were issued to ephebes [ibid. 206, pl. 48c) are in fact dicasts tokens; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM497">497</a></p>
<p :class="{'activeClass': idClicked == NP369 }" id="NP369" >47* Peripatos: D.L. 5.2; Lynch, J. P. 1972, 68-69, 73-75</p>
<p :class="{'activeClass': idClicked == NP370 }" id="NP370" >48* Plane tree by a water channel: Thphr. HP 1.7.1; Delorme, J. P. 1960, 56</p>
<p :class="{'activeClass': idClicked == NP371 }" id="NP371" >49* List of those associated with the gymnasium in 45/46 A.D., Royal Gardens (Plan 2 epsilon/zeta—6/7) - - - IG II² 1945; Geagan, D. J. 1967, 160-161; Ritchie Jr., C. E., 1989, 255-256 See also: Apollo Apo9*, dedication by Lykeion epimelete; Asklepios Ask37*; Dromos; Gymnasium sculpture inventory; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM496">496</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM497">497</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM500">500</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM543">543</a>-544</p>
<p :class="{'activeClass': idClicked == NP373 }" id="NP373" >50* Ivory statuette, 2nd-3rd c. A.C., found in a well on Kolonos Agoraios Agora Bl 236; T. L. Shear, “The Campaign of 1936,” Hesperia 6 (1937) 349-351, figs. 13-14; Agora Guide 1990, 266, fig. 156; LIMC II, 1984, 194 no. 39r, ph., s.v. Apollon-Apollo (O. Palagia) and 379-380 no. 54, s.v. Apollon-Apollo (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP374 }" id="NP374" >51* Dedication, Roman pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204205" target="_blank">Agora I 4205</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 29 (1960) 60 no. 97, pl. 12; SEG 19 (1963) no. 227</p>
<p :class="{'activeClass': idClicked == NP375 }" id="NP375" >52* APOLLO METAGEITNION, cult: Harp. s.v. μεταγειτνιών; B. gr. Kruse, REXV, 1932, col. 1318-1319 (Metageitnios)</p>
<p :class="{'activeClass': idClicked == NP376 }" id="NP376" >52** APOLLO OULIOS, cult: SEG 51 (2005) no. 13</p>
<p :class="{'activeClass': idClicked == NP377 }" id="NP377" >53* APOLLO PAION, cult, Athens or Attica: IG I² 383 lines 163-164; Threatte, L. 1980, 234-235</p>
<p :class="{'activeClass': idClicked == NP378 }" id="NP378" >54* APOLLO PARNOPIOS, bronze statue by Pheidias, east of the Parthenon: Paus. 1.24.8; located south of the Parthenon, Stevens, G. P. 1946a, 15-16; possibly transfered to Constantinople, Agora XXIV, 75-76</p>
<p :class="{'activeClass': idClicked == NP379 }" id="NP379" >APOLLO PATROOS: Agora III, 50-53; Hedrick Jr., C. W.,1988, 185-210</p>
<p :class="{'activeClass': idClicked == NP380 }" id="NP380" >55* Altar, 3rd c. B.C. northwest Athens at the Varvakeion, bounded by Athenas, Evripidou and Sokratou Sts. (Plan 2 delta—4) IG II² 4984; assigned to the temple of Apollo in the Agora (Apollo Apo59* below), Thompson, H. A. 1937, 110 figs. 57-58; location in the Agora rejected, C. W. Hedrick, Jr., op. cit., 194; SEG 38 (1988) no. 195</p>
<p :class="{'activeClass': idClicked == NP381 }" id="NP381" >56* Boundary stone, early 4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205569" target="_blank">Agora I 5569</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 91 no. 38, pl. 22; C. W. Hedrick Jr., AJA 92 (1988) 194; SEG 38 (1988) no. 195; Ritchie Jr., C. E., 1985, 57-61, pl. 10, fig. 7</p>
<p :class="{'activeClass': idClicked == NP382 }" id="NP382" >57* Boundary stone (?) of sanct. of Apollo Patroos of the phratry Therrikleidai, 4th c. B.C. - - - IG II² 4973; Hedrick Jr., C. W. 1984, 267-273; see Therrikles</p>
<p :class="{'activeClass': idClicked == NP383 }" id="NP383" >58* Temple and cult statue of the 6th c. B.C. beneath the temple no. Apo59* below; see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM314">314</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM344">344</a></p>
<p :class="{'activeClass': idClicked == NP384 }" id="NP384" >59* Temple, 350-325 B.C., south of the Stoa of Zeus Eleutherios: Paus. 1.3.4; Hedrick Jr., C. W. 1988, 185-210, figs. 1-3; Agora Guide 1990, 74-77, 274 no. 74, figs. 5-7, 18, 19 no. 42, 38</p>
<p :class="{'activeClass': idClicked == NP385 }" id="NP385" >60* Temple statue by Euphranor, colossal statue of Apollo Kitharoidos, ca. 330 B.C., found ca. 20 m. south of the temple of Apollo Patroos in the north room of the Metroon - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202154" target="_blank">Agora S 2154</a>, ex-NM 3573; Paus. 1.3.4; Palagia, O. 1980, 3-4 no. 18, 13-19, 65, figs. 6-17. The five kithara fragments (Agora A 41) found along the north side of the temple do not belong to this statue.</p>
<p :class="{'activeClass': idClicked == NP386 }" id="NP386" >61* Priest's theater throne IG II² 5061; Maass, M. 1972, 128, pl. 16</p>
<p :class="{'activeClass': idClicked == NP387 }" id="NP387" >62* Apollo Patroos and Artemis, dedication, 1st c. A.C. ?. Hag. Thomas (Chr. Mon. CM7*), Kladou St. east of the Stoa of Attalos (Plan 2 zeta—4) - - - IG II² 4726; Agora III, 53; SEG 48 (1998) no. 248</p>
<p :class="{'activeClass': idClicked == NP388 }" id="NP388" >63* Apollo Patroos of the Gephyraioi, honours for a priest, Akropolis South Slope: IG II² 3630 lines 4-6; D. Peppa-Delmouzou, «Έπιγραφαι έκ τού Επιγραφικού Μουσείου,» Deltion 25 (1970) A, Meletai, 197-199 no. 3, pl. 68 See also: Apollo Apo24*; Claudius</p>
<p :class="{'activeClass': idClicked == NP389 }" id="NP389" >APOLLO PROSTATERIOS: Agora III, 53-54 nos. 114-115; 56-57 nos.120-121</p>
<p :class="{'activeClass': idClicked == NP390 }" id="NP390" >64* Dedication, Areopagus northwest Slope IG II² 4727; cf. Agora XIV, 45</p>
<p :class="{'activeClass': idClicked == NP391 }" id="NP391" >65* Apollo Prostaterios Apotropaios Agyieus, dedication - - - IG II² 4852</p>
<p :class="{'activeClass': idClicked == NP392 }" id="NP392" >APOLLO PYTHIOS: ORACLES</p>
<p :class="{'activeClass': idClicked == NP393 }" id="NP393" >66* Concerning the revival of the cult of Apollo Pythios, 129/8 B.C. - - - EM 6001; Hedrick Jr., C. W., 1988, 201-202; SEG 38 (1988) no. 113</p>
<p :class="{'activeClass': idClicked == NP394 }" id="NP394" >67* On dedicating Demon's house and garden to Asklepios, ca. 350-330 B.C.- - - IG II² 4969; SEG 21 (1965) no. 812; Aleshire, S. B. 1989, 163-164</p>
<p :class="{'activeClass': idClicked == NP395 }" id="NP395" >68* Concerning Demeter Chloe and Kore, southwest of the Nike Bastion - - - IG II² 5006: Wycherley, R. E. 1966, 290-291</p>
<p :class="{'activeClass': idClicked == NP396 }" id="NP396" >69* Oracles kept on the Akropolis: Hdt. 5.90 See also: Gephyraioi; Palladia; cf. Apollo Apo11*</p>
<p :class="{'activeClass': idClicked == NP397 }" id="NP397" >70* SANCTUARY OF APOLLO PYTHIOS, southwest of the Olympieion, the Pythion, on the Ilissos right bank in the area bounded by Kallirrois Blvd., Vourvachi St., Syngrou Boulevard and Athan. Diakou St. (Plan 2 eta/theta—5): Th. 2.15.4; Travlos, J. 1971, 100-103, fig. 130</p>
<p :class="{'activeClass': idClicked == NP398 }" id="NP398" >71* Altar, dedicated by Peisistratos, son of Hippias, ca. 522/1 B.C., found on the Ilissos right bank in the city block bounded by Kallirrois Blvd., Iosiph ton Rogon St. and Lembesi St. (Plan 2 theta—5) - - - IG I² 948 = IG I² 761; SEG 50 (2000) no. 80; Davies, J. K. 1971, 450-451; Travlos, J. 1971, 100, figs. 130, 132-134; supporting the 522/1 B.C. date, Keesling, C. M. 1995, 30-32</p>
<p :class="{'activeClass': idClicked == NP399 }" id="NP399" >72* Sherd with dipinto inscription naming Apollo and two sherds with letters ΠΥ.found at the southwest edge of the archaeological zone south of the Olympieion near Athan. Diakou St. and the findspot of the altar of Apollo Pythios ( Plan 2 eta—5): Μ. T. Mitsos, “Inscriptions from Athens,” Hesperia 16 (1947) 262 nos. 1-3; O. Walter, “Archaologische Funde in Griechenland von Fruhjahr 1940 bis Herbst 1941,” AA 1942, 106; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM549">549</a></p>
<p :class="{'activeClass': idClicked == NP400 }" id="NP400" >73* Choregic monuments for victories in the Thargelia set up in the Pythion: useful survey of the findspots, Al. N. Oikonomides, “Attic Choregic-Inscriptions: II. The Walls south of the Acropolis and Pythion,” The Ancient World 3 (1980) 20-21</p>
<p :class="{'activeClass': idClicked == NP401 }" id="NP401" >Three tripod bases found on the Ilissos right bank west of the Olympieion “ubi Pythium fuisse constat” - - - IG II² 3065-3067</p>
<p :class="{'activeClass': idClicked == NP402 }" id="NP402" >Eight tripod bases, 362-348 B.C., reused in a structure of the 3rd c. B.C. at 17 Iosiph ton Rogon St. between Lembesi and Vourvachi Sts. (Plan 2 theta—5)- - - SEG 27 (1977) nos. 12-19; SEG 30 (1980) no. 127; Koumanoudes, S. N. 1979, 143-149, nos. 1-8, pls. 41-46</p>
<p :class="{'activeClass': idClicked == NP403 }" id="NP403" >74* Statue near the temple of Zeus Olympios: Paus. 1.19.1; C. Gasparri, “Osservazione sul tipo di Apollo detto ‘Ariadne',” Studi Miscellanei 22 (1974-75) 96 -98. The context of Paus. 1.19.1 clearly indicates that Pausanias saw the statue in a sanctuary of Apollo Pythios</p>
<p :class="{'activeClass': idClicked == NP404 }" id="NP404" >75* Temple built by Peisistratos, disliked by the Athenians: Hsch. s.v. ἐν Πυθίῳ χέσαι; J. P. Lynch, “Hipparchos' Wall in the Academy at Athens,” in Studies Sterling Dow 1984, 175-176, 177-179 (testimonia); M. F. Arnush, “The Career of Peisistratos Son of Hippias,” Hesperia 64 (1995) 144-145</p>
<p :class="{'activeClass': idClicked == NP405 }" id="NP405" >76* Priest's theater throne, 2nd c. B.C. - - - IG II² 5029; Maass, M. 1972, 107-108, pl. 7 See also: Apollo Apo17*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM549">549</a>; cf. Apollo Apo2* and Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM200">200</a> for a <JBcom>[controversial]</JBcom> second sanctuary of Apollo Pythios on the Akropolis upper north slope.</p>
<p :class="{'activeClass': idClicked == NP407 }" id="NP407" >77* Apollo, Delphic omphalos between eagles and Artemis, document relief above Philoxenos' decree concerning the cult of Apollo Pythios, ca. 422-416 B.C., near the Gate of Athena Archegetis (Plan 1 gamma—5, no. 68) - - - IG I³ 137 with addenda; IG I² 78; SEG 25 (1971) no. 25; Lawton, C. L. 1995, 47-48, 114-115 no. 67, pl. 35</p>
<p :class="{'activeClass': idClicked == NP408 }" id="NP408" >78* APOLLO SMINTHIOS AND ARTEMIS, dedication, Akropolis North Slope at Hag. Ιoannis Prodromos tou Mangoutis ( Chr. Mon. CM29*) - - - IG II² 4854</p>
<p :class="{'activeClass': idClicked == NP409 }" id="NP409" >79* APOLLO TARSIOS, dedication by sailors IG II² 3003; B. gr. Kruse, RE IV A2, 1932, 2411-2412, (Tarseus)</p>
<p :class="{'activeClass': idClicked == NP410 }" id="NP410" >80* APOLLO XANTHOS, sanctuary boundary stone, ca. 400 B.C. - - - IG I 3 1053 = <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201454" target="_blank">Agora I 1454</a>; Meritt, B. D. 1941, 38 no. 4, ph.; Ritchie Jr., C. E. 1985, 52-56, pl. 9, fig. 6; Agora XIX, 23-24 H 10</p>
<p :class="{'activeClass': idClicked == NP411 }" id="NP411" >81* APOLLO ZOSTER, priest's theater throne - - - IG II² 5081; Maass, M. 1972, 15-16, 140, pl. 22</p>
<p :class="{'activeClass': idClicked == NP412 }" id="NP412" >82* APOLLO, ARTEMIS and OTHERS: see Dionysos Dio7*; Hermes Hrm37*; Thrasyllos Monument</p>
<p :class="{'activeClass': idClicked == NP413 }" id="NP413" >83* APOLLO and ASKLEPIOS, paean by Makedonikos with traces of a relief in the pediment, 1st c. A.C., Akropolis South Slope - - - EM 13345 +, IG II² 4473; SEG 34 (1984) no. 192</p>
<p :class="{'activeClass': idClicked == NP414 }" id="NP414" >84* APOLLO AND ATHENA, altar relief, 5th c. B.C. (?)--- Mus. Barracco, Rome: LIMC II, 1984, 281 no. 786, ph., s.v. Apollon (G. Kokkorou-Alewras)</p>
<p :class="{'activeClass': idClicked == NP415 }" id="NP415" >85* APOLLO seated on the tripod, LETO and ARTEMIS, relief, late 5th c. B.C., seen by Pittakis in a house in Plaka - - - NM 1389; IG II² 4558; Kaltsas, N. 2002, 136-137 no. 262, ph.</p>
<p :class="{'activeClass': idClicked == NP416 }" id="NP416" >86* APOLLO and HERMES listening to PAN playing syrinx to NYMPHS dancing around a cylindrical altar, relief, late 2nd c. B.C., Akropolis South Slope south of the theater precinct - - - NM 1966; Kaltsas, N. 2002, 306 no. 645, ph.</p>
<p :class="{'activeClass': idClicked == NP417 }" id="NP417" >87* APOLLO, HERMES, (HERAKLES): see Hermes Hrm38*</p>
<p :class="{'activeClass': idClicked == NP418 }" id="NP418" >APOLLO and HESTIA and OTHERS: see Hestia</p>
<p :class="{'activeClass': idClicked == NP419 }" id="NP419" >88* APOLLO and THE MUSES (?), cult: IG II² 1341 frag. B, line 8</p>
<p :class="{'activeClass': idClicked == NP420 }" id="NP420" >89* and APOLLO, theater throne frag. - - - IG II² 5082; Maass, M. 1972, 16, 140, pl.2</p>
<p :class="{'activeClass': idClicked == NP421 }" id="NP421" >APOLLODOROS, grave monument, after 340 B.C., on the Road to the Academy: “Apollodoros, a captain of the mercenaries, but a native Athenian, who being sent by Arsites, satrap of Hellespontine Phrygia, successfully defended the city of Perinthus when Philip invaded its territory,” Paus. 1.29.10 (transl. by Frazer); Frazer J. G. 1913, 382-383; <JBcom>[a state-financed monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP422 }" id="NP422" >APOLLODOROS, son of Thrasyllos, choregic victory tripod monument, ca. 375 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207002" target="_blank">Agora I 7002</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 36 (1967) 93-94 no. 21, pl. 26; SEG 24 (1969) no. 211</p>
<p :class="{'activeClass': idClicked == NP423 }" id="NP423" >APOLLONIOS, sophist and hierophant, late 2nd-early 3rd c. A.C., grave at Hiera Syke: Philostr. VS 2.20.3; Clinton, K. 1974, 40-42 no. 29</p>
<p :class="{'activeClass': idClicked == NP424 }" id="NP424" >APOTROPAIOS: see Apollo Apo65*</p>
<p :class="{'activeClass': idClicked == NP425 }" id="NP425" >AQUILIUS FLORUS TURCIANUS GALLUS, L., proconsul of Achaia in the reign of Claudius, statue base, Propylaia IG II² 4126 + CIL 551; R. Hanslik, KP 2, 1964, 480 (s.v. Aquilius no. 2); IL in Greciae Repertae, 1979, 61 under no. 139</p>
<p :class="{'activeClass': idClicked == NP426 }" id="NP426" >ARA, sanctuary: Hsch.</p>
<p :class="{'activeClass': idClicked == NP427 }" id="NP427" >ARCADIUS AND HONORIUS</p>
<p :class="{'activeClass': idClicked == NP428 }" id="NP428" >Large epistyle recording the dedication of a building to the emperors by the proconsul of Hellas, Severus Aetius, 396-401 A.D., south of Panagia Gorgoepekoos (Plan 1 gamma—8, no. 94) - - - IG II² 5205; conjecturally <JBcom>[implausibly]</JBcom> assigned to an <JBcom>[undocumented]</JBcom> gymnasium in the Diogeneion, A. Frantz, “A Public Building of Late Antiquity (IG II² 5205),” Hesperia 48 (1979) 194-203, figs. 1-3, pls. 62-64; also <JBcom>[implausibly]</JBcom> favoring the Diogeneion site, Sironen, E. 1997, 112-114 no. 38; SEG 46 (1996) no. 271 See also: Asty, Herm milestones</p>
<p :class="{'activeClass': idClicked == NP429 }" id="NP429" >ARCHEGETES, ARCHEGETIS: see Athena Ath54*, Ath55*; Hegemon A.</p>
<p :class="{'activeClass': idClicked == NP430 }" id="NP430" >ARCHEIA, term applied both to government offices and to the place around the Tholos, where most of them were: Phot. s.v. θολος; Agora III, 126 and nos. 487, 573, 579, 594, 598; Agora XIV, General Index</p>
<p :class="{'activeClass': idClicked == NP431 }" id="NP431" >ARCHELAOS PHILOPATRIS, the last king of Cappadocia, statue bases, after 20 B.C., Akr., - - - IG II² 3430-3432; Geagan, D. 1996, 157-159; cf. SEG 51 (2001) no. 1005</p>
<p :class="{'activeClass': idClicked == NP432 }" id="NP432" >ARCHELAOS, the son of the last king of Cappadocia, statue bases, after 17 A.D., Akr. - - IG II² 3434-3435; Geagan, D. 1996, 157-158; SEG 51 (2001) no. 1005</p>
<p :class="{'activeClass': idClicked == NP433 }" id="NP433" >ARCHON</p>
<p :class="{'activeClass': idClicked == NP434 }" id="NP434" >Theater throne - - - IG II² 5036; Maass, M. 1972, 15,16, 117-119, pl. 10 Archons' building (oikema): D. 21.85; Agora III, 4, 177, no. 583</p>
<p :class="{'activeClass': idClicked == NP435 }" id="NP435" >Archons' stone: see Lithos See also: Basileus, theater throne; Court of the Archon; Pyrphoros and Archon </p>
<p :class="{'activeClass': idClicked == NP436 }" id="NP436" >ARDETTOS</p>
<p :class="{'activeClass': idClicked == NP437 }" id="NP437" >Place where Athenians swore the heliastic oath, named after the hero Ardettes who first administered the oath to the Athenians, on the Ilissos above the Panathenaic stadium near the deme of Lower Agryle: Harp, s.v.; Judeich, W. 1931, 45 note 2, 170, 419; Kearns, E. 1989, 150; Billot, M.-F. 1992, 121; Ryan, F. X. 1994, 128</p>
<p :class="{'activeClass': idClicked == NP438 }" id="NP438" >Lawcourt where dikasts were sworn in according to Pollux 8.122; called lawcourt by mistake, Agora XXVIII, 91,186-187 nos. 171-175</p>
<p :class="{'activeClass': idClicked == NP439 }" id="NP439" >Present-day name for the hill at the southwest side of the stadium site (Plan 2 eta/theta—6/7)</p>
<p :class="{'activeClass': idClicked == NP440 }" id="NP440" >AREIA: see Athena Ath34*, Ath56*</p>
<p :class="{'activeClass': idClicked == NP441 }" id="NP441" >AREOPAGUS: Frazer, J. G. 1913, 362-367</p>
<p :class="{'activeClass': idClicked == NP442 }" id="NP442" >Hill named after Ares: Paus. 1.28.5 + 1.29.1; 7.25.1 + 2; cf. 1.21.4; on present-day plans the name Areopagus refers only to the peak of this large sprawling hill which in fact slopes down all the way to the Eridanos River on the north and down to the Areopagus-Pnyx Valley on the west; the Areopagus south slope has been overlooked because it merges with the Akropolis west slope</p>
<p :class="{'activeClass': idClicked == NP443 }" id="NP443" >The huge fallen boulders just below the highest point of the hill, east of the Church of Dionysios Areopagites (Chr. Mon. CM60*), probably broke off from the main mass of the peak in 1601 when the church was destroyed. For the site of the huge,boulders, see two plans by J. Travlos published by E. Vanderpool, “The Apostle Paul in Athens,” Archaeology 3 (1950) 36-37. Certain evocative passages in the Eumenides of Aeschylus and the Electra of Euripides mentioning an underground abode or chasm of the Erinyes have been interpreted as referring to this site which loses its ancient awesome glamour if the rock slide dates to 1601</p>
<p :class="{'activeClass': idClicked == NP444 }" id="NP444" >Meeting-place of the Council of the Areopagus acting as a homicide court, near the walled sanctuary of The Semnai: A. Eu. lines 684, 704 (called Bouleuterion); Paus. 1.28.5 + 6 (Ares tried for the murder of Halirrhothios), 8.34.4; Agora XXVIII, testimonia nos. 1-3, 8-28, 34, and Index s.v. Areopagus, Areopagus Council; formerly <JBcom>[mistakenly]</JBcom> located at the site of the church of Dionysios Areopagites (Chr. Mon. CM60*), E. Vanderpool, op. cit., 34-37; perhaps under the fallen boulders below the peak, Agora XXVIII, 44; <JBcom>[probably east of the fallen boulders]</JBcom>; see Demos and Demokratia, Law against Tyranny; Kornelius Onomarchos; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM228">228</a></p>
<p :class="{'activeClass': idClicked == NP445 }" id="NP445" >Boundary stone of the Council of the Areopagus, early 4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205054" target="_blank">Agora I 5054</a>; <JBcom>[mistakenly]</JBcom> published as a dedication, “the earliest epigraphic mention of the Council of the Areopagus,” A. G. Woodhead, “Greek Inscriptions,” Hesperia 26 (1957) 233-234 no. 89, pl. 5; SEG 17 (1960) no. 63; in the spring of 2004 G. V. Lalonde and R. S. Stroud confirmed that the stele is a boundary stone and R. S. Stroud noticed traces of an earlier inscription See also: Hestia, Apollo etc.; Keryx of the Areopagus Council; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM276">276</a></p>
<p :class="{'activeClass': idClicked == NP446 }" id="NP446" >ARES, sanctuary with a temple, south of the Altar of The Twelve Gods: Paus. 1.8.4</p>
<p :class="{'activeClass': idClicked == NP447 }" id="NP447" >Temple of Ares, 430s B.C., originally dedicated to Athena Pallenis at Pallene, moved to the Agora in the early 1st c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%2064" target="_blank">Agora A 64</a>+; W. B. Dinsmoor, “The Temple of Ares at Athens,” Hesperia 9 (1940) 1-52, figs. 1-18; Μ. H. McAllister, “The Temple of Ares at Athens,” Hesperia 28 (1959) 1-64, figs. 1-24, pls. A, 1-17; original site <JBcom>[mistakenly]</JBcom> located at Acharnai, Travlos, J. 1971, 104 with figs. 34 no. 67, 138-145; Agora Guide 1990, 114-115, 276 no. 114, figs. 7, 67; probably repaired after 267 A.D., Agora XXIV, 97 note 9, 109; M. Platonos-Yota, “The Sanctuary of Athena Pallenis,” Archaiologia 65 (Oct.-Dec. 1997) 92-97 with photos and drawings; M. Korres, “Από τον Σταυρό στην Αγορά,” Horos 10-12 (1992-1998) 83-104, pls. 15-20; the temple of Ares linked to the building of the temple of Mars Ultor in Rome, F. E. Romer, “A Numismatic Date for the Departure of C. Caesar?,” ΤΑΡΑ 108 (1978) 201-202 note 35</p>
<p :class="{'activeClass': idClicked == NP448 }" id="NP448" >Central acroterion: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM334">334</a>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM336">336</a></p>
<p :class="{'activeClass': idClicked == NP449 }" id="NP449" >Masons' marks, 5th c. B.C. ?: Μ. H. McAllister, op. cit, 39-41, pl. 5:e</p>
<p :class="{'activeClass': idClicked == NP450 }" id="NP450" >Masons' marks, 1st c. A.C.: ibid., 47-54, figs. 3, 9-11, 17</p>
<p :class="{'activeClass': idClicked == NP451 }" id="NP451" >Column drum and triglyph block of the temple reused as olive press weights - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202257" target="_blank">Agora A 2257</a>, A 2277; H. Forbes and L. Foxhall, “The Queen of all Trees: Preliminary Notes on the Archaeology of the Olive,” Expedition 21 (1978) 43, figs. 10-11</p>
<p :class="{'activeClass': idClicked == NP452 }" id="NP452" >Temple statue by Alkamenes: Paus. 1.8.4; usually associated with the Ares Borghese, LIMC II, 1984, 480-481 no. 23, s.v. Ares (P. Bruneau) and 512-513 no. 21, Ares-Mars (E. Simon); Ares Borghese type representing Mars Ultor, an Augustan creation, mistakenly attributed to Alkamenes by Pausanias, Hartswick, K. J. 1990, 227-283, figs. 1-13; <JBcom>[perhaps together with Athena Ath34*]</JBcom></p>
<p :class="{'activeClass': idClicked == NP453 }" id="NP453" >Frieze in high relief assigned to the Ares temple - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20676" target="_blank">Agora S 676</a> +; Harrison, E. B. 1977, 164-173 with figs. 20-25, 34-35, 38-39; ibid. 265-280 passim with figs. 2, 12, 18-21; eadem, “The classical High-Relief Frieze from the Athenian Agora,” in Archaische und klassische griechische Plastik 1986, vol. II, 109-117, pls. 117-122</p>
<p :class="{'activeClass': idClicked == NP454 }" id="NP454" >Altar, 4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201651" target="_blank">Agora A 1651</a> +; Μ. H. McAllister, op. cit., 3-6, fig. 1, pls. A, 1, 2:c,e; Agora XIV,164, pl. 81 :d</p>
<p :class="{'activeClass': idClicked == NP455 }" id="NP455" >Inscribed orthostates with shields in relief <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206634" target="_blank">Agora I 6634</a> “somehow associated with the altar on its earlier site,” Μ. H. McAllister, op. cit., 6, fig. 2</p>
<p :class="{'activeClass': idClicked == NP456 }" id="NP456" >ARES (continued): see Caesar, Gaius; Drusus</p>
<p :class="{'activeClass': idClicked == NP457 }" id="NP457" >ARES ENYALIOS and ENYO, cult: IG II² 1072 line 5; Agora III, 55; LIMC III, 1986, 746, s.v. Enyo I (R. M. Gais); Hartswick, K. J. 1990, 258-259</p>
<p :class="{'activeClass': idClicked == NP458 }" id="NP458" >ARGONAUTS: see Jason</p>
<p :class="{'activeClass': idClicked == NP459 }" id="NP459" >ARGYROKOPEION, the mint for silver coinage: IG II² 1013, line 30; Agora III, 160-161; the mint for silver coinage has not yet been found, J. McK. Camp II and J. H. Kroll,” The Agora Mint and Athenian Bronze Coinage,” Hesperia 70 (2001) 144; cf. Mint for bronze coinage</p>
<p :class="{'activeClass': idClicked == NP460 }" id="NP460" >ARIADNE</p>
<p :class="{'activeClass': idClicked == NP461 }" id="NP461" >Sculpture of Ariadne reclining on a base which has a crude relief of Theseus abandoning Ariadne on Naxos as he is about to sail to Athens on a boat, Roman pd., found near the Gate of Athena Archegetis at Areos and Poikilis Sts. (Plan 1 gamma—4/5), present whereabouts unknown: published as a grave relief with the Theseus relief misunderstood as Charon in his boat, A. Philadelpheus, «Έπιτύμβιον άνάγλυφον μετά Χαρωνείου παραστάσεως,» ΑΕ 1896, 131-136, ρΐ. 5; Inst. phot. Athen Varia 172, the mounted photograph in the DAI Photoabteilung has a precious note identifying the iconography in Dörpfeld's hand; <JBcom>[perhaps this sculpture was in the Theseion in which case the findspot has something to say]</JBcom> See also: Dionysos Dio21*, picture</p>
<p :class="{'activeClass': idClicked == NP462 }" id="NP462" >ARIARATHES V of Cappadocia: honorary statues awarded by decree of the Synodos of the Technitai of Dionysos, 130 B.C.: IG II² 1330 lines 24-25; SEG 45 (1995) no. 131; Aneziri, S. 2000, 263; Kotsidu, H. 2000, 95-98</p>
<p :class="{'activeClass': idClicked == NP463 }" id="NP463" >Statue (agalma) set up beside the cult statue of Dionysos <JBcom>[Melpomenos]</JBcom> by the Technitai of Dionysos: IG II² 1330 line 24 (cf. lines 36-37); D. M. Lewis and R. S. Stroud, “Athens Honors King Euagoras of Salamis,” Hesperia 48 (1979) 193 note 24</p>
<p :class="{'activeClass': idClicked == NP464 }" id="NP464" >Bronze statue (eikon) set up by the Technitai of Dionysos in the propylaion of the temenos <JBcom>[of Dionysos Melpomenos]</JBcom>: IG II² 1330 lines 24-25; Pickard-Cambridge, A. 1968, 288, 311-312 no. 7</p>
<p :class="{'activeClass': idClicked == NP465 }" id="NP465" >ARIARATHES (?) V (?) or IX (?) of Cappadocia, head, Akropolis west slope near Dörpfeld's Enneakrounos - - - NM 3556; Richter, G. M. A. 1965, vol. Ill, 276 col. 2, figs. 1942-1943; Kaltsas, N. 2002, 287 no. 605, ph.; no hard evidence for an identification, Kotsidu, H. 2000, 424-426</p>
<p :class="{'activeClass': idClicked == NP466 }" id="NP466" >ARIOBARZANES II PHILOPATOR of Cappadocia</p>
<p :class="{'activeClass': idClicked == NP467 }" id="NP467" >Inscribed column shaft, ca. 57 B.C., King Ariobarzanes Philopator honoured as a benefactor by the brothers Gaius and Marcus Stallius and Menalippos, Odeion architects (?) — IG II² 3426; Schenkungen hellenistischer Herrscher, Part I, 1995, 84-86 and Part II, vol. 1, 2000, 13-14, 164 note 126</p>
<p :class="{'activeClass': idClicked == NP468 }" id="NP468" >Dedication on a column shaft to King Ariobarzanes Philopator and Queen Athenais Philostorgos by the people of Athens, 63/2-52/1 B.C., Akropolis South Slope at the theater - - - IG II² 3427; P. Kastriotis, “'Ωδεχον τού Περχκλέου5,” Deltion 1919, parart. 5-6, fig. 11; Geagan, D. J. 1967, 155; Schenkungen hellenistischer Herrscher, Part I, 1995, 84 (in the commentary), fig. 29; Kotsidu, H. 2000, 99-100</p>
<p :class="{'activeClass': idClicked == NP469 }" id="NP469" >ARIOBARZANES III of Cappadocia, statue, 52-42 B.C. IG II² 3428; Geagan, D. J. 1967,155; Kotsidu, H. 2000, 100</p>
<p :class="{'activeClass': idClicked == NP470 }" id="NP470" >ARISTE: see Kalliste</p>
<p :class="{'activeClass': idClicked == NP471 }" id="NP471" >ARISTEIDES, son of Lysimachos</p>
<p :class="{'activeClass': idClicked == NP472 }" id="NP472" >Choregic tripods, anciently attributed to Aristeides, son of Lysimachos: Plu. Arist. 1; Al. N. Oikonomides, “Attic Choregic-lnscriptions I: Aristeides and Cyriacus of Ancona,” Ancient World 3 (1980) 17-19 concerning <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203398" target="_blank">Agora I 3398</a> and IG II² 3027; SEG 30 (1980) nos. 124-125; Wilson, P. 200U, 205</p>
<p :class="{'activeClass': idClicked == NP473 }" id="NP473" >House: D. 3.26</p>
<p :class="{'activeClass': idClicked == NP474 }" id="NP474" >ARISTOBOULE: see Artemis Art22*</p>
<p :class="{'activeClass': idClicked == NP475 }" id="NP475" >ARISTODEMOS' house with a boundary stone recording legal encumbrance, at the site of Dörpfeld's Enneakrounos on the ancient “Areopagus-Pnyx Valley Street” (Plan 1 zeta/eta—1/2) IG II² 2729; Agora XIV,183 note 60</p>
<p :class="{'activeClass': idClicked == NP476 }" id="NP476" >ARISTOKRATES, son of Skellias, choregic tripod base, ca. 440-430? B.C., found in 1832 west of the Athena Archegetis Gate (Plan 1 gamma-5 no. 68) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207352" target="_blank">Agora I 7352</a>, IG I³ 964 = IG I² 772; SEG 32 (1982) no. 24; Wilson, P. 2000, 203-204</p>
<p :class="{'activeClass': idClicked == NP477 }" id="NP477" >ARISTONIKOS of Karystos, statue: Ath. 1.19A; S. Dow, “The Athenian Honors for Aristonikos of Karystos, “Alexander's ΣΦΑΙΡΙΣΤΗΣ,” HSCP 67 (1963) 78-92</p>
<p :class="{'activeClass': idClicked == NP478 }" id="NP478" >ARISTOTLE OF STAGEIRA: Richter, G. M. A. 1972, 95-99</p>
<p :class="{'activeClass': idClicked == NP479 }" id="NP479" >Statue in Theophrastos' Garden: D.L. 5.51; Frischer, B. 1982, 94 with note 20</p>
<p :class="{'activeClass': idClicked == NP480 }" id="NP480" >Portrait herm (headless), 2nd c. A.C., erected by Alexander, found at Kapnikarea Church on Kapnikareas St. just off Ermou St. (Plan 1 alpha—7, no. 95) - - - IG II² 4261; Richter, G. M. A. 1965, 171 no. 1, 173 no. 14, fig. 1014; the dedicator was probably either Alexander of Damaskos or Alexander of Aphrodisias, Voutiras, E. 1987, 179-185; SEG 37 (1987) no. 155; Schenkungen hellenistischer Herrscher, Part I, 1995, 441</p>
<p :class="{'activeClass': idClicked == NP481 }" id="NP481" >Head, found in Athens (?)--- NM 3291; Richter, G. M. A. 1965, II, 173 no. 15, figs. 1012-1013</p>
<p :class="{'activeClass': idClicked == NP482 }" id="NP482" >Double herm, unfinished, area of Dörpfeld's Enneakrounos on the “Areopagus-Pnyx Valley Street” (Plan 1 zeta/eta—1/2) - - - NM 3772; G. M. A. Richter, op. cit., 173-174 nos. 16-17, figs. 1004-1005, 1009-1010; Kaltsas, N. 2002, 252 no. 526, ph.</p>
<p :class="{'activeClass': idClicked == NP483 }" id="NP483" >Studium Aristotelis, Akropolis South Slope (?): Niccolo da Martoni, Liber peregrinationis ad loca sancta; Van der Vin, J. P. A. 1980, vol. I, 42-43 and vol II, 609, 615-616</p>
<p :class="{'activeClass': idClicked == NP484 }" id="NP484" >ARRHEPHOROI: Threatte, L. 1980, 127-128, 536; Mansfield, J. M. 1989, 260-326</p>
<p :class="{'activeClass': idClicked == NP485 }" id="NP485" >Sphairistra (open air court for ball games) on the Akropolis, used by the Arrhephoroi: Plu. Moralia 839 C; G. Roux, “Les Gymnases de Delphes et de Delos,” 8CH 104 (1980) 138</p>
<p :class="{'activeClass': idClicked == NP486 }" id="NP486" >Dwelling on the Akropolis not far from the Polias temple: Paus. 1.27.3; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM60">60</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM61">61</a></p>
<p :class="{'activeClass': idClicked == NP487 }" id="NP487" >Route taken by the Arrhephoroi from the Akropolis to a nameless enclosure (peribolos) not far from Aphrodite in the Gardens, via an underground passage: Paus. 1.27.3; Frazer, J. G. 1913, II, 191 on Paus. 1.19.2; K. Jeppesen, “Where Was the So-called Erechtheion?,” AJA 83 (1979) 392; cf. Eros and Aphrodite; Part II no.198</p>
<p :class="{'activeClass': idClicked == NP488 }" id="NP488" >ARSINOE II of Egypt, queen of Ptolemy II Philadelphos, statue in front of the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6; Richter, G. M. A. 1972, II, 232</p>
<p :class="{'activeClass': idClicked == NP489 }" id="NP489" >ARTEMIS</p>
<p :class="{'activeClass': idClicked == NP490 }" id="NP490" >Altar: see Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP491 }" id="NP491" >1* Arula or thymiaterion, ca. 100 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202843" target="_blank">Agora I 2843</a>; Meritt, B. D. 1941, 64 no. 30, ph.</p>
<p :class="{'activeClass': idClicked == NP492 }" id="NP492" >2* Arula or thymiaterion, 3rd c. A.C., Roman Agora (uncertain provenance) - - - SEG 32 (1982) no. 263; S. N. Koumanoudes, «Έπιγραφαι έξ Άθηνών,» Deltion 25 (1970) A Meletai, 67-68 no. 22, pl. 26:b</p>
<p :class="{'activeClass': idClicked == NP493 }" id="NP493" >3* Arula, ca. 200 B.C., Pompeion area IG II² 4689; SEG 48 (1988) no. 249; perhaps for Artemis Soteira, see Artemis Art46*-48*</p>
<p :class="{'activeClass': idClicked == NP494 }" id="NP494" >4* Dedication, lower Akropolis South Slope at 8 Propylaion St. (Plan 2 eta—4): <JBcom>[thought to be]</JBcom> from a sanctuary of Artemis in the immediate vicinity, O. Alexandri, Deltion 28 (1973) B1 Chr., 41 no. 26; SEG 28 (1978) no. 227</p>
<p :class="{'activeClass': idClicked == NP495 }" id="NP495" >5* not used</p>
<p :class="{'activeClass': idClicked == NP496 }" id="NP496" >Precinct on the Road from the Dipylon Gate to the Academy Gymnasium; see Kalliste</p>
<p :class="{'activeClass': idClicked == NP497 }" id="NP497" >6* Sanctuary of Artemis: IG II² 1298 lines 11-12</p>
<p :class="{'activeClass': idClicked == NP498 }" id="NP498" >7* Thiasos decree set up in the sanctuary, ca. 244 B.C., built into a late wall south of the Sacred Gate - - - IG II² 1298; SEG 38 (1988) no. 129</p>
<p :class="{'activeClass': idClicked == NP499 }" id="NP499" >8* Two statues listed in a gymnasium sculpture inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 46 and line 55; Clay, D. 1977, 261, 263</p>
<p :class="{'activeClass': idClicked == NP500 }" id="NP500" >9* Small statue, southeast of the Tholos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20912" target="_blank">Agora S 912</a>; Thompson, H. A. 1940, 139, fig. 101</p>
<p :class="{'activeClass': idClicked == NP501 }" id="NP501" >10* Statuette Acr. 6656; LIMC II, 1984, 652 no. 365, s.v. Artemis (L. Kahil)</p>
<p :class="{'activeClass': idClicked == NP502 }" id="NP502" >11* Artemis with deer and hound, statuette, Versailles type, in a small chamber north of the Bakcheion apse - - - NM 3567; Schrader, H. 1896, 272-274 no. 2 with drawing; Kaltsas, N. 2002, 250 no. 520, ph.</p>
<p :class="{'activeClass': idClicked == NP503 }" id="NP503" >12* Statuette, Akropolis South Slope, Odeion of Perikles - - - NM 3714; Theophanides, B. D. 1948, 10 no. 35</p>
<p :class="{'activeClass': idClicked == NP504 }" id="NP504" >13* Terracotta statuette with fawn, early 5th c. B.C. Acr. 12367; L. Kahil, op. cit. (no. Art10* above), 671 no. 665, ph.</p>
<p :class="{'activeClass': idClicked == NP505 }" id="NP505" >14* Artemis and stag, relief, late 5th c. B.C. - - - Cassel SK 774; ibid. 653 no. 397, ph.</p>
<p :class="{'activeClass': idClicked == NP506 }" id="NP506" >15* Artemis and stag, relief, found on the Ilissos left bank near the Stadium Brocklesby Park no. 101; Vermeule, C. C. and D. von Bothmer 1956, 325</p>
<p :class="{'activeClass': idClicked == NP507 }" id="NP507" >16* Artemis with hound, relief, 2nd-3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202361" target="_blank">Agora S 2361</a>; L. Kahil, op. cit. (no. Art10* above) 655 no. 414, ph.</p>
<p :class="{'activeClass': idClicked == NP508 }" id="NP508" >17* ARTEMIS IN A, Dadaphoros in Peiraeus, theater seat - - - IG II² 5146</p>
<p :class="{'activeClass': idClicked == NP509 }" id="NP509" >18* ARTEMIS AGROTERA, Ionic temple on the Ilissos left bank, ca. 435 B.C., southern edge of Ardittou Blvd. west of Thomopoulou St. (Plan 2 eta—6): Paus. 1.19.6; Travlos, J. 1971, 112-120, figs. 154 no. 151, 156-163; E. Lygouri-Tolia, «Ανασκαφικη έρευνα στο χώρο του ναου' της Αρτέμιδος Αγροτέρας (οδοί Αρδηττού-Κούτουλα-Κεφάλου-Θωμοπούλου στο Μετζ),» Deltion 49 (1994) B1 Chr., 36-38; Childs, W. A. P. 1985, 207-251 with figs. 2, 5 and pls. 43-45; Mark, I. S. 1993, 82-83 with note 64, 84, 119-120; see Chr. Mon. CM34*; cf. Mother of The Gods Met10*</p>
<p :class="{'activeClass': idClicked == NP510 }" id="NP510" >19* Temple statue with bow: Paus. 1.19.6</p>
<p :class="{'activeClass': idClicked == NP511 }" id="NP511" >20* ARTEMIS AGROTERA and ENYALIOS, altar or altars at which the polemarch offers sacrifices: Arist. Ath. 58.1; Rhodes, P. J. 1981, 650</p>
<p :class="{'activeClass': idClicked == NP512 }" id="NP512" >21* <JBcom>[not used]</JBcom></p>
<p :class="{'activeClass': idClicked == NP513 }" id="NP513" >22* ARTEMIS ARISTOBOULE in Melite, sanctuary founded by Themistokles, west of Plateia Thissiou at 1 Irakleidon St. (Plan 2 zeta—3): Plu. Them. 22.1-2; Threpsiades, J. and E. Vanderpool 1964, 26-36, plans 1-2, pls. 16-21; SEG 22 (1967) no. 116</p>
<p :class="{'activeClass': idClicked == NP514 }" id="NP514" >23* Temple facing west, ca. 330 B.C.: Travlos, J. 1971, 121, figs. 164-167</p>
<p :class="{'activeClass': idClicked == NP515 }" id="NP515" >24* Altar of poros limestone - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203373" target="_blank">Agora A 3373</a>; J. Threpsiades and E. Vanderpool, op. cit., 28, 33, pl. 20:a</p>
<p :class="{'activeClass': idClicked == NP516 }" id="NP516" >25* Dedication to Artemis by Neoptolemos of Melite on a votive pillar, ca. 330 B.C., reused ca. 3rd c. A.C. as a threshold block in the temple - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206969" target="_blank">Agora I 6969</a>; J. Threpsiades and E. Vanderpool, op. cit., 31-33, pls. 19, 20:a; SEG 22 (1967) no. 116</p>
<p :class="{'activeClass': idClicked == NP517 }" id="NP517" >26*ARTEMIS BOULAIA, altar in the Agora: mentioned in a decree of 224/3-222/1 B.C., honouring an embassy from the city of Ephesos, to be set up at the altar, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202361" target="_blank">Agora I 2361</a> lines 19-20; SEG 25 (1971) no. 108; Agora III, 55-57; Agora XVI, no. 225, 324-327; see Livia</p>
<p :class="{'activeClass': idClicked == NP518 }" id="NP518" >27* ARTEMIS BRAURONIA, sanctuary on the Akropolis, southeast of the Propylaia: Paus. 1.23.7; Travlos, J. 1971, 124-125, figs. 168-169; R. F. Rhodes and J. J. Dobbins, “The Sanctuary of Artemis Brauronia on the Athenian Akropolis,” Hesperia 1948 (1979) 325-341, pls. 81-86; L. Kahil, “Le ‘craterisque' d'Artemis et le Brauronion de l'Acropole,” Hesperia 50 (1981) 253-263, figs. 1-4, pl. 62; Despinis, G. 1997, 209-217, figs. 1-6</p>
<p :class="{'activeClass': idClicked == NP519 }" id="NP519" >27** Inventories of dedications to Artemis Brauronia at Brauron, 355/4-334/3 B.C., found on the Akropolis - - - IG II² 1514-1531; SEG 49 (1999) no. 164; T. Linders, Studies in The Treasure Records of Artemis Brauronia found in Athens, Stockholm, 1972</p>
<p :class="{'activeClass': idClicked == NP520 }" id="NP520" >28* Statue by Praxiteles: Paus. 1.23.7; LIMC II, 1984, 620, 640-641 no. 190, s.v. Artemis (L. Kahil) and 801-802 no. 16, s.v. Artemis-Diana (E. Simon); identified as Acr. 1352 (= Acr. 13601), a head badly mutilated by Christians, G. Despinis, “Neues zu einem alten Fund,” AM 109 (1994) 173-198, pls. 31-45; Despinis, G. 1997, 209-217, figs. 1-6</p>
<p :class="{'activeClass': idClicked == NP521 }" id="NP521" >29* Stoa: R. F. Rhodes and J. J. Dobbins, op. cit., 325-341, fig. 1, pls. 81-86; perhaps built in the 4th c. B.C.</p>
<p :class="{'activeClass': idClicked == NP522 }" id="NP522" >30* Rock-cut steps leading up onto the north side of the sanctuary: ibid. 331, 340-341, pls. 81, 86:c-d</p>
<p :class="{'activeClass': idClicked == NP523 }" id="NP523" >ARTEMIS DELPHINIA: see Apollo Apo30*, Apo32*</p>
<p :class="{'activeClass': idClicked == NP524 }" id="NP524" >31* ARTEMIS DIKTYNNA, dedication on base, 2nd c. B.C., Akropolis South Slope at the theater - - - IG II² 4688</p>
<p :class="{'activeClass': idClicked == NP525 }" id="NP525" >32* ARTEMIS ELAPHEBOLOS, cult: Anecdota Graeca, 1914, 249 lines 7-9, s.v. Έλαφηβολιών</p>
<p :class="{'activeClass': idClicked == NP526 }" id="NP526" >ARTEMIS EPIPYRGIDIA: see Charites and Artemis Epipyrgidia; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM89">89</a>. <JBcom>[Although Artemis Epipyrgidia has been arbitrarily equated with Hekate Epipyrgidia whose statue was seen by Pausanias on the Nike Bastion, the cult is more likely to have been situated in the Propylaia northwest wing where Artemis Kolainis is attested and which fits the epithet epipyrgidia equally well (see Artemis Art35*)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP527 }" id="NP527" >33* ARTEMIS ERITHOS, small altar, Hadrianic date, in the small chamber north of the Bakcheion apse (Plan 1 zeta—2) NM 3566, IG II² 5005; SEG 51 (2001) no. 234</p>
<p :class="{'activeClass': idClicked == NP528 }" id="NP528" >ARTEMIS IN LIMNAI: see Limnai </p>
<p :class="{'activeClass': idClicked == NP529 }" id="NP529" >ARTEMIS KALLISTE: see Kalliste and Ariste</p>
<p :class="{'activeClass': idClicked == NP530 }" id="NP530" >34* ARTEMIS KELKAIA, cult statue (edos) taken by Xerxes, returned to Athens by Alexander the Great: Arr. An. 7.19.2, cf. 3.16.8; A. Adler, RE IX, 1921, 147 (Kelkaia); Schenkungen hellenistischer Herrscher, Part I, 1995, 395</p>
<p :class="{'activeClass': idClicked == NP531 }" id="NP531" >ARTEMIS KOLAINIS, THEA KOLAINIS: F GR HIST III B, suppl. vol. I. 177-178; Kearns, E, 1989, 178 s.v. Kolainos</p>
<p :class="{'activeClass': idClicked == NP532 }" id="NP532" >35* Inscription, 2nd c. A.C., inside the Propylaia northwest wing on the south wall - - - IG II² 4791; SEG 42 (1992) no.190</p>
<p :class="{'activeClass': idClicked == NP533 }" id="NP533" >36* Priest's theater throne IG II² 5057; Maass, M. 1972, 126, pl. 15</p>
<p :class="{'activeClass': idClicked == NP534 }" id="NP534" >37* Artemis Kolainis (restored), theater seat, 2nd c. A.C. or later IG II² 5140; Maass, M. 1972, 126</p>
<p :class="{'activeClass': idClicked == NP535 }" id="NP535" >38* Artemis Kolainis Epekoos, relief of breasts IG II² 4860</p>
<p :class="{'activeClass': idClicked == NP536 }" id="NP536" >39* Thea Kolainis, arula with Artemis relief, Olympieion - - - IG II² 4731; F. T. van Straten, “Daikrates' Dream. A Votive Relief from Kos and some other kat' onar Dedications,” BABesch 51 (1976) 10 note 127</p>
<p :class="{'activeClass': idClicked == NP537 }" id="NP537" >40* ARTEMIS LEUKOPHRYENE, held in honour by the Magnesians, bronze statue dedicated on the Akropolis by Themistokles' sons, near the statue of Olympiodoros: Paus. 1.26.4, cf. Ill 18.9; F. Fleischer, Artemis von Ephesos und verwandte Kultstatuen aus Anatolien und Syrien, Leiden, 1973, 140-146; Keesling, C. M. 2003, 139 with note 46</p>
<p :class="{'activeClass': idClicked == NP538 }" id="NP538" >41* ARTEMIS LYSIZONOS, sanctuary: schol. A.R. l.288</p>
<p :class="{'activeClass': idClicked == NP539 }" id="NP539" >42* ARTEMIS OINAIA, theater seat - - - IG II² 5116; SEG 51 (2001) no. 235</p>
<p :class="{'activeClass': idClicked == NP540 }" id="NP540" >43* ARTEMIS ORTHOSIA, sanctuary in the Kerameikos: schol. Pindari. Οl. III.54; B. gr. Kruse, RE XVIII 2, 1942, col 1435 (Orthia)</p>
<p :class="{'activeClass': idClicked == NP541 }" id="NP541" >43** ARTEMIS PHERAIA, cult in Athens with the statue brought from Pherai: Paus. 2.23.5</p>
<p :class="{'activeClass': idClicked == NP542 }" id="NP542" >ARTEMIS PHOSPHOROS: Agora III, 56-58</p>
<p :class="{'activeClass': idClicked == NP543 }" id="NP543" >44* Cult at the Tholos: IG II² 902 lines 7-8; Agora XIV, 45 with note 119</p>
<p :class="{'activeClass': idClicked == NP544 }" id="NP544" >45* Dedication, lower Akropolis North Slope IG II² 4659</p>
<p :class="{'activeClass': idClicked == NP545 }" id="NP545" >ARTEMIS SOTEIRA, SOTEIRA</p>
<p :class="{'activeClass': idClicked == NP546 }" id="NP546" >46* Dedication to Artemis Soteira - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201349" target="_blank">Agora I 1349</a>; Meritt, B. D. 1941, 62-63 no. 28, ph.</p>
<p :class="{'activeClass': idClicked == NP547 }" id="NP547" >47* Dedication to Artemis Soteira, 2nd-1st c. B.C. Kerameikos I 304, IG II² 4695; G. Siebert, “Artemis Soteira a Delos”, BCH 90 (1966) 457; for the findspot see under Art48*</p>
<p :class="{'activeClass': idClicked == NP548 }" id="NP548" >48* Honorary inscr. to be set up in the temenos of the Soteira, ca. 37-36 B.C., found with Art47* in the Kerameikos Excavations in the so-called Temenos of Hekate, southwest of Lysimachides' grave plot IG II² 1343; Brueckner, A. 1909, 53, no. 32 on the General Plan; Hoepfner, W. 1970, Beilage 5, the oblong structure between "Temenos der Hekate” and "Altar” See also: Artemis Art3*</p>
<p :class="{'activeClass': idClicked == NP549 }" id="NP549" >ARTEMIS and OTHERS: see Apollo Apo62*, Apo77*, Apo78*, Apo85*; Dionysos Dio7*; Hermes Hrm37*; Leto and A.; Mother of The Gods Met21*</p>
<p :class="{'activeClass': idClicked == NP550 }" id="NP550" >ARTEMON PERIPHORETOS, statue by Polykleitos; Plin. Nat. XXXIV.56; T. Lorenz, Polyklet, Wiesbaden, 1972., 54 with note 245; Pollitt, J. J. 1990, 76 note 42</p>
<p :class="{'activeClass': idClicked == NP551 }" id="NP551" >ARTHMIOS DECREE outlawing Arthmios of Zeleia as a Persian agent, bronze stele beside Athena Promachos on the Akropolis: F GR HIST III B, 203, Krateros 342 F14 with commentary and notes; Devlin, R. 1989, 59</p>
<p :class="{'activeClass': idClicked == NP552 }" id="NP552" >ARTORIUS ASCLEPIADES, M., one of Augustus' physicians, statue base — IG II² 4116; V. Nutton, “Archiatri and the Medical Profession in Antiquity,” BSR 45 (1977) 195 note 22</p>
<p :class="{'activeClass': idClicked == NP553 }" id="NP553" >ARYBBAS, king of Molossia, honorary decree with reliefs commemorating victories in the chariot race and the horse race, ca. 342 B.C., Akropolis- - - IG II² 226; NM 2948; SEG 42 (1992) no, 90; Kaltsas, N. 2002, 239 no. 504, ph.; C. L. Lawton, “Athenian anti-Macedonian sentiment and democratic ideology in Attic document reliefs in the second half of the 4th c. B.C.,” in Macedonians in Athens 2003, 121-122, figs. 6-7</p>
<p :class="{'activeClass': idClicked == NP554 }" id="NP554" >ASANDER, satrap of Caria, bronze equestrian statue in the Agora awarded by decree 314/3 B.C.: IG II² 450 lines 7-15; L. O'Sullivan, “Asander, Athens and IG II² 450: A New Interpretation,”  ZPE 119 (1997) 114, 116; SEG 49 (1999) no. 106</p>
<p :class="{'activeClass': idClicked == NP555 }" id="NP555" >ASKLEPARION and HESYCHIA, MYSTERIOS and THEODOULE, grave stone, near the Theater of Dionysos - - - EM 9946; C. Bayet, de Titulis Atticae Christianis Antiquissimis, Paris, 1878, 65 no. 1, pl. I no. 1; T. E. Gregory, “The Survival of Paganism in Christian Greece: A Critical Essay,” AJP 107 (1986) 239-240; Sironen, E. 1997, 124-125 no. 43</p>
<p :class="{'activeClass': idClicked == NP556 }" id="NP556" >First use: gravestone of Mysterios and Theodoule, 4th-5th c. A.C. <JBcom>[5th c. A.C.]</JBcom> Second use: gravestone of Askleparion and Hesychia, with crosses chiseled away, 5th-6th c. A.C. <JBcom>[6th c. A.C.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP557 }" id="NP557" >1* ASKLEPIOS, sanctuary on the Akropolis South Slope: Paus. 1.21.4-5, 1.22.1 and 2.26.8; Aleshire, S. B. 1989, 1-385; Forsen, B. 1996, 2-25 passim, 31-54, nos. 1.1-1.49, figs. 3-39; Riethmüller, J. W. 1999, 123-143, figs. 1-4; Beschi, L. 2002, 19-29, 38-41 (bibliography)</p>
<p :class="{'activeClass': idClicked == NP558 }" id="NP558" >2* Telemachos Monument, ca. 400 B.C., duplicate records of the founding of the sanct. in 420/19 B.C. and its early history: L. Beschi, “II relievo di Telemachos ricompletato,” AAA 15 (1982) 31-43, figs. 1-9; Aleshire, S. B. 1989, 7-10; Κ. M. Eliakis, «Πρότασι για τη μορφή του αναθήματος του Τελεμάχου Άχαρνέως,» Horos 10-12 (1992-1998) 73-76, pls. 9-12; Beschi, L. 2002, 19-25, figs. 6-7; SEG 51 (2001) no. 233 Inscription - - - IG II² 4960/4961, 4963; SEG 49 (1999) no. 231</p>
<p :class="{'activeClass': idClicked == NP559 }" id="NP559" >Reliefs some of which represent Telemachos <JBcom>[of Acharnai]</JBcom>, Asklepios and Hygieia at trapeza, the Asklepieion propylon with a cock on the roof and snakes in the pediment, a stork on a tree within the temenos wall <JBcom>[doubtably]</JBcom> interpreted as the Pelargikon wall, banqueting hero with lyre - - - Acr. 2530; NM 2477, 2490, 2491 +; Padua Museo Civico no. 14; BM 1920.6-16.1, BM 1971. 1-25.1; Verona, Museo Maffeiano</p>
<p :class="{'activeClass': idClicked == NP560 }" id="NP560" >3* Altar founded by Telemachos: IG II² 4961 line 2</p>
<p :class="{'activeClass': idClicked == NP561 }" id="NP561" >4* Altar east of but not on axis with the temple of Asklepios: Judeich, W. 1931, 321, 324; Aleshire, S. B. 1989, 21 note 4; <JBcom>[the so-called altar belongs to the floor of the 6th c. basilica (Chr. Mon. CM5*)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP562 }" id="NP562" >5* Altar (or altar-like object) of Asklepios Soter, ca. 200 A.D., near the Odeion of Perikles — IG II² 4516; S. Dow, “Healing Deities on Pentelikon,” Phoenix 36 (1982) 326-327</p>
<p :class="{'activeClass': idClicked == NP563 }" id="NP563" >6* Cult table: IG II² 4963 line 4; Gill, D. 1991, 8-9</p>
<p :class="{'activeClass': idClicked == NP564 }" id="NP564" >7* Boundary stone of Asklepios Soter, 2nd c. A.C., Asklepieion - - - IG II² 5180; Ritchie Jr., C. E., 1985, 68-72, pl. 12, fig. 9</p>
<p :class="{'activeClass': idClicked == NP565 }" id="NP565" >8* Asklepios, Hygieia, Hypnos, dedication, Asklepieion - - - IG II² 4467; cf. R. Egger, “Die hilfreiche Kleine im Kapuzenmantel,” JOAI 37 (1948) 98-99</p>
<p :class="{'activeClass': idClicked == NP566 }" id="NP566" >8** Dedication on an epistyle to Asklepios and Hygieia for the welfare and health of Tiberius, Asklepieion - - - IG II² 3181; Vermeule, C. C. 1968, 430</p>
<p :class="{'activeClass': idClicked == NP567 }" id="NP567" >9* Dedication to Aesculapius and Valetudo, 1st c. A.C., Akr. South Slope - - - IG II² 4478 = CIL III 7279</p>
<p :class="{'activeClass': idClicked == NP568 }" id="NP568" >10* List of dedications including one from C. Iulius Antiochos Epiphanes Philopappos, ca. A.D. 93 - - - IG II² 4511; SEG 33 (1983) no. 196; Byrne, S. G. 2003, 309 (iv)</p>
<p :class="{'activeClass': idClicked == NP569 }" id="NP569" >11* Old Propylon: IG II² 1046 lines 11-13 + 16-18 + 20-21 + 25-26; Aleshire, S. B. 1989, 32-34; SEG 38 (1988) no. 118; <JBcom>[the Old Propylon was probably southwest of the temple at the place where Travlos, J. 1971, fig. 171 shows an entrance in the precinct wall]</JBcom>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM138">138</a></p>
<p :class="{'activeClass': idClicked == NP570 }" id="NP570" >12* Inventory of votive offerings in the temple of Asklepios, 274/273 B.C. IG II² 1534 A; SEG 39 (1989) no. 165 and 49 (1999) no. 165; Aleshire, S. B. 1989, 67 (dedications mistakenly attributed to Epicurean hetairai), 166-248; Aleshire, S. B. 1991, 5-11 (with co-author A. P. Matthaiou) and 41-46.</p>
<p :class="{'activeClass': idClicked == NP571 }" id="NP571" >Paeans</p>
<p :class="{'activeClass': idClicked == NP572 }" id="NP572" >13* Paean, ca. 200-250 A.D. found near the Asklepieion IG II² 4509; the paean was probably revised in Athens in the mid-4th c. B.C., J. H. Oliver, “The Sarapion Monument and the Paean of Sophocles,” Hesperia 5 (1936) 115-118; SEG 46 (1996) no. 269; K. Hallof, “Ein neues Fragment des Asklepios-Paans IG II² 4509,” Horos 10-12 (1992-1998) 71; SEG 49 (1999) no. 219</p>
<p :class="{'activeClass': idClicked == NP573 }" id="NP573" >14* Diophantos' paean, ca. 150 A.D., Asklepieion - - - IG II² 4514; Aleshire, S. B. 1989, 58; SEG 48 (1998) no. 253 See also: Apollo Apo83*, paean; Sarapion Mon.</p>
<p :class="{'activeClass': idClicked == NP574 }" id="NP574" >15* Pictures: Paus. 1.21.4</p>
<p :class="{'activeClass': idClicked == NP575 }" id="NP575" >16* Precinct wall: Boersma, J. S. 1970, 161 no. 18; Townsend, R. F. 1982, 86; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM152">152</a></p>
<p :class="{'activeClass': idClicked == NP576 }" id="NP576" >Sculpture and reliefs</p>
<p :class="{'activeClass': idClicked == NP577 }" id="NP577" >17* Temple statue holding seal-stone dedications in his hand: IG II² 1534 A lines 125-127; Aleshire, S. B. 1989, 186, 203-204; SEG 39 (1989) 164; Beschi, L. 1969/1970, 101-117; the original of type Giustini, Holtzmann B. 1984, 891 at the end of the left column and, for copies of the type found in Athens, 880-881 nos. 167-169, 200, 201, 203, 205, 207-210; M. Meyer, “Erfindung und Wirkung. Zum Asklepios Giustini,” AM 103 (1988) 158; for the temple statue prescribing a cure for Plutarch, the Neo-Platonist, see Athanssiadi, P. 1999, 225, fragment 89A</p>
<p :class="{'activeClass': idClicked == NP578 }" id="NP578" >18* Asklepios and his children, statues: Paus. 1.21.4; Beschi, L. 1969/1970, 117</p>
<p :class="{'activeClass': idClicked == NP579 }" id="NP579" >19* Asklepios curing Theopompos the comic poet, inscribed representation made of Parian marble: Ael. frag. 99 H; FAC I, 1957, 848; Aleshire, S. B. 1989, 13-14</p>
<p :class="{'activeClass': idClicked == NP580 }" id="NP580" >19** Asklepios, Hygieia, dedicator at an altar, relief, all three faces mutilated and red crosses painted on the altar and the background - - - NM 1338; Delivorrias, A. 1991, 118, pl. 58 beta; Kaltsas, N. 2002, 141 no. 268, ph.</p>
<p :class="{'activeClass': idClicked == NP581 }" id="NP581" >20* Asklepios, Hygieia, Epione, donor and cart, relief, early 4th c. B.C. - - - NM 1341 + Acr. 7988, IG II² 4356; Beschi, L. 1969/1970, 86-93, fig. 1; Kaltsas, N. 2002, 140 no. 267, ph.</p>
<p :class="{'activeClass': idClicked == NP582 }" id="NP582" >21* New-born Asklepios ( not on the preserved portion of the relief) found by Aresthanas (?) on a mountain with palm tree, dogs and snake, and, above, god and goddess, relief, ca. 350 B.C., Asklepieion - - - NM 1351, cf. Paus. 2.26.4-5; LIMC II, 1984, 721 no. 1279, ph., s.v. Artemis (L. Kahil); Holtzmann, B. 1984, 868 no. 5; Apollo is shown above at the right, Kaltsas, N. 2002, 214 no. 440</p>
<p :class="{'activeClass': idClicked == NP583 }" id="NP583" >22* Asklepios, Epione, Akeso, Iaso, and Panakeia, relief, mid-4th c. B.C., Akr. South Slope - - - NM 1352, IG II² 4388 (the inscr. names Akeso, Iaso, Panakeia, E<JBcom>[pione]</JBcom>; LIMC III, 1986, 808 no. 1, s.v. Epione (F. Croissant); <JBcom>[adventurously]</JBcom> suggesting that the naked boy “is possibly Asklepios' son Ianiskos,” Kaltsas, N. 2002, 216 no. 444</p>
<p :class="{'activeClass': idClicked == NP584 }" id="NP584" >23* Asklepios and Hygieia, relief, late 4th-3rd c. B.C. NM 1354, IG II² 4357; Epione rather than Hygieia, LIMC III,1986, 808 no. 15, 809 (Commentaire), s.v. Epione (F. Croissant)</p>
<p :class="{'activeClass': idClicked == NP585 }" id="NP585" >24* Asklepios and Machaon, relief, Akr. South Slope - - - NM 1364, IG II² 4416; Holtzmann, B. 1984, 875 no. 95, ph.</p>
<p :class="{'activeClass': idClicked == NP586 }" id="NP586" >25* Epione with goose, Asklepios and Hygieia in a temple and, at an angle to the temple, a procession of worshippers in a stoa; on the sides relief of a herm and relief of a goddess with two torches, mid-4th c. (?) B.C. NM 1377; Aleshire, S. B. 1989, 25-26; the goddess with torches identified as Hekate, Kaltsas, N. 2002, 215 no. 442, ph.; <JBcom>[does this remarkable work carved from one block of marble celebrate the inauguration of the temple?]</JBcom></p>
<p :class="{'activeClass': idClicked == NP587 }" id="NP587" >26* Asklepios and Hygieia (?), relief within circular frame on a small pediment, 150-200 A.D. IG II² 3197 + NM 2450; D. Peppa-Delmouzou, «Έπιγραφικό Μουσείο Άθηνών,» Deltion 30 (1975) B1 Chronika, 7-8, pl. 7 B</p>
<p :class="{'activeClass': idClicked == NP588 }" id="NP588" >27* Spring house in a round cave, mostly natural, partly rock-cut: IG II² 4464; R. Martin and H. Metzger, “Recherches d'architecture et de topographie a l'Asclepieon d'Athenes,” BCH 73 (1949) 321-323, pl. 16; the place where Halirrhothios the son of Poseidon attacked Alkippe the daughter of Ares, Paus. 1.21.4; Kearns, E. 1989, 144-145 s.v. Halirrothios and 145 s.v. Alkippe; <JBcom>[probably a cave of Pan and the Nymphs incorporated into the Asklepieion]</JBcom>; see Chr. Mon. CM5*, CM38**; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM153">153</a></p>
<p :class="{'activeClass': idClicked == NP589 }" id="NP589" >Stoas</p>
<p :class="{'activeClass': idClicked == NP590 }" id="NP590" >28* Doric Stoa, 300/299 B.C., Asklepieion eastern terrace: Coulton, J. J. 1976, 223-225; Townsend, R. F. 1982, 42-76; specifications and contracts for this stoa recorded in IG II² 1685, SEG 39 (1989) no. 175; Aleshire, S. B. 1991,13-32; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM109">109</a></p>
<p :class="{'activeClass': idClicked == NP591 }" id="NP591" >29* Sacred Pit enclosed in the northwest end of the Doric Stoa: Townsend, R. F. 1982, 61-68; Aleshire, S. B. 1989, 21, 26, 27, 34; for Doric architecture <JBcom>[mistakenly]</JBcom> assigned to a baldachino over the Sacred Pit, see Travlos, J. 1971, 127, figs. 176-177, 179-180; Riethmuller, J. W. 1999, 131-143, figs. 1-11</p>
<p :class="{'activeClass': idClicked == NP592 }" id="NP592" >30* Ionic Stoa, 4th c. B.C. west of the Doric Stoa: R. A. Tomlinson, “Two Buildings in Sanctuaries of Asklepios,VHS 89 (1969) 112-117, figs. 2-3, pl. 7; not necessarily in, probably outside of the Asklepieion, Aleshire, S. B. 1989, 24, 25, 28, 30, 31 note 2; <JBcom>[the Ionic Stoa did not belong to the Asklepieion]</JBcom></p>
<p :class="{'activeClass': idClicked == NP593 }" id="NP593" >Four rooms restored as dining-rooms on the evidence of a bedding for an off-center threshold block (not preserved) on the toichobate at the front of Room 2: R. A. Tomlinson, op. cit., 114-115, pl. VII:2; Travlos, J. 1971, 127, fig. 171; restored with klinai for incubation, Beschi, L. 2002, 44-45</p>
<p :class="{'activeClass': idClicked == NP594 }" id="NP594" >31* Roman Stoa, Augustan pd., with inscribed epistyle IG II² 3120; Walker, S. 1979a, 243-244, 247 (iv); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM138">138</a></p>
<p :class="{'activeClass': idClicked == NP595 }" id="NP595" >Dedication of the stoa to (Asklepios), Hygieia, Augustus: IG II² 3120 lines 6-8</p>
<p :class="{'activeClass': idClicked == NP596 }" id="NP596" >Dexikles' dedication of a tripod to Asklepios, 190-200 A.D.: IG II² 3120 lines 1-5; Aleshire, S. B. 1989, 17 cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM138">138</a></p>
<p :class="{'activeClass': idClicked == NP597 }" id="NP597" >Temple(s): Aleshire, S. B. 1989, 26, 27, 32, 33, 34; idem, 1991, 5-11, 41-46, pl. 11.</p>
<p :class="{'activeClass': idClicked == NP598 }" id="NP598" >32* Temple foundations on the eastern terrace: Boersma, J. S. 1970, 175 no. 43</p>
<p :class="{'activeClass': idClicked == NP599 }" id="NP599" >33* Old Temple of Asklepios and Hygieia, ca. mid-4th c. B.C.: IG II² 1046 lines 13-14; Aleshire, S. B. 1989, 32-34; SEG 38 (1988) no. 118</p>
<p :class="{'activeClass': idClicked == NP600 }" id="NP600" >34* Later temple (?): IG II² 1046 lines 17-18; Judeich, W. 1931, 321, 323; it is not at all certain that IG II² 1046 mentions two temples of Asklepios, Aleshire, S. B. 1989, 32-33; Sara Aleshire's typically keen observation was confirmed on May 16, 2002 when P. Christodoulopoulou reported in a lecture at the Polytechneion that a study of disiecta membra has enabled her and her colleagues to determine that there was one temple with two phases, the first phase being marble, Ionic, distyle in antis</p>
<p :class="{'activeClass': idClicked == NP601 }" id="NP601" >35* Asklepios, Demeter, Kore, relief dedicated by men <JBcom>[mistakenly]</JBcom> thought to be physicians, mid-4th c. B.C., - - - NM 1332; IG II² 4359; "all the faces hacked out by a <JBcom>[Christian]</JBcom> fanatic,” Svoronos, J. N. 1908, 247-252 no. 29, pl. 36 no. 2; Aleshire, S. B. 1989, 8 with note 7, 94-95; SEG 39 (1989) no. 230; Kaltsas, N. 2002, 224-225 no. 472, ph.</p>
<p :class="{'activeClass': idClicked == NP602 }" id="NP602" >ASKLEPIOS (continued) FINDSPOTS OTHER THAN THE AKROPOLIS SOUTH SLOPE</p>
<p :class="{'activeClass': idClicked == NP603 }" id="NP603" >36* Base of a statue of Asklepios dedicated ca. 120 A.D. by an official of the Isis cult, Athenas St. — IG II² 4772; Vidman, L. 1969, 12 no. 17; Aleshire, S. B. 1989, 22 note 3</p>
<p :class="{'activeClass': idClicked == NP604 }" id="NP604" >36** Asklepios and Hygieia, temenos boundary stone, 2nd c. B.C., 3 Polygnotou St., Akropolis lower north slope (Plan 1 delta—4) - - - 1st Ephor. Inv. no. Pi Lamda 751; A. Choremi-Spetsieri, «Οδός Πολυγνώτου 3,» Deltion 45 (1990) B1 Chr., 21, pl. 14:a; SEG 47 (1997) no. 194 also recording that 18 reliefs of Asklepios and Hygieia were found in the Agora Excavations with Kevin Clinton's statement that “the most likely source of these finds and of the boundary stone is the sanctuary of Amynos on the lower western slope of the Areopagus;” the more likely source is a sanctuary in or adjoining the City Eleusinion, SEG 48 (1998) no. 169; C. Lawton, “Votive Reliefs and Popular Religion in the Athenian Agora; The case of Asklepios and Hygieia,” in ICCA XV, Amsterdam, July 12-17, 1998, Docter, R. F. and E, M. Moorman, eds., Amsterdam, 1999, 232-234, fig. 90</p>
<p :class="{'activeClass': idClicked == NP605 }" id="NP605" >37* Statues of Asklepios and Hygieia, mid-2nd c. A.C., found in the baths in front of Zappeion Hall (Plan 2 epsilon/zeta—6/7) - - - NM 702, NM 701; for NM 702, see Holtzmann, B. 1984, 880 no. 167; for NM 701, see Kaltsas, N. 2002, 128-129 no. 246, ph.</p>
<p :class="{'activeClass': idClicked == NP606 }" id="NP606" >38* ASKLEPIOS XENIOS, dedication on a base, in the Olympieion precinct — IG II² 4492-4493</p>
<p :class="{'activeClass': idClicked == NP607 }" id="NP607" >39* ASKLEPIOS AND HYGIEIA, relief, southwest of the Pompeion - - - IG II² 4417</p>
<p :class="{'activeClass': idClicked == NP608 }" id="NP608" >40* ASKLEPIOS AND TELESPHOROS, statue group, Roman pd., Kerameikos Exc., reused in a drain in the 5th c. A.C. - - - Kerameikos P 1250; B. von Freytag gen. Loringhoff, “Kerameikos 1983-1985: Kanal au und Werkstattbereich,” AA 1987, 485 with note 9, figs. 9 (findspot of “Asklepiosstatue” marked on the general plan,11 a-b</p>
<p :class="{'activeClass': idClicked == NP609 }" id="NP609" >ASKLEPIOS (continued), FINDSPOTS OR LOCATIONS NOT KNOWN</p>
<p :class="{'activeClass': idClicked == NP610 }" id="NP610" >41* Altar set up by Sophokles: EM s.v. Δέξιον; Ferguson, W. S. 1944, 90-91; Sophokles "evidently" set up the altar in the Amyneion, according to Travlos, J. 1971, 76, <JBcom>[but no evidence indicates that the Amyneion was founded as early as the 5th c. B.C., the altar was probably in Sophokles' house]</JBcom>; cf. Riethmüller, J. W. 1999, 135-136</p>
<p :class="{'activeClass': idClicked == NP611 }" id="NP611" >42* Asklepios and Hygieia, dedication on an epistyle recording an oikos and exedra dedicated by Diokles, 51/50 B.C. - - - IG II² 3174; SEG 39 (1989) no. 212; Aleshire, S. B. 1991, 106</p>
<p :class="{'activeClass': idClicked == NP612 }" id="NP612" >43* Asklepios, Hygieia, Telesphoros, hymns to secure relief from the plague, 3rd c. A.C. - - - IG II² 4533; J. H. Oliver, op. cit. (Asklepios Ask13*), 119-120</p>
<p :class="{'activeClass': idClicked == NP613 }" id="NP613" >44* Asklepios and Hygieia, oracle inscribed on a cult table with a snake relief, 1 st-2nd c. A.C. - - - IG II² 4997; SEG 21 (1965) no. 815; Gill, D. G. 1991, 62-64, pl. 16, fig. 8</p>
<p :class="{'activeClass': idClicked == NP614 }" id="NP614" >45* Two statues of Asklepios listed in a gymnasium sculpture inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 19 and line 24; Clay, D. 1977, 261, 262-263</p>
<p :class="{'activeClass': idClicked == NP615 }" id="NP615" >46* Statues of Asklepios and Hygieia listed in a gymnasium sculpture inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 20; loc. cit.] cf. Asklepios Ask37*</p>
<p :class="{'activeClass': idClicked == NP616 }" id="NP616" >47* Asklepios and Hygieia, relief, 1st c. B.C. Fitzwilliam Mus. GR 14.1865; Holtzmann, B. 1984, 881 no. 207, ph.; both heads have been defaced <JBcom>[by Christians in the 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP617 }" id="NP617" >48* Asklepios and family of worshippers, relief frag., 4th c. B.C. - - - Kassel Sk 44; ibid., 875 no. 89, ph.</p>
<p :class="{'activeClass': idClicked == NP618 }" id="NP618" >49* Asklepios and Hygieia, relief fragment Verona, Museo Archeologico; Holtzmann B, 1984, 888 no. 360, ph.</p>
<p :class="{'activeClass': idClicked == NP619 }" id="NP619" >ASKLEPIOS (continued) PRIESTS' THEATER THRONES assignable to cults in Athens, Peiraeus or Eleusis</p>
<p :class="{'activeClass': idClicked == NP620 }" id="NP620" >50* ASKLEPIOS PAION (?), priest's theater throne IG II² 5045; Maass, M. 1972, 120, pl. 12</p>
<p :class="{'activeClass': idClicked == NP621 }" id="NP621" >51* Priest's theater throne - - - IG II² 5068; Maass, M. 1972, 133, pl. 18 </p>
<p :class="{'activeClass': idClicked == NP622 }" id="NP622" >ASKLEPIOS (continued): see Amphiaraos; Amynos; Apollo Apo67*; Athena Ath69* </p>
<p :class="{'activeClass': idClicked == NP623 }" id="NP623" >ASPHALEIOS: see Poseidon Asphaleios </p>
<p :class="{'activeClass': idClicked == NP624 }" id="NP624" >ASTRAPAIOS: see Zeus Zeu10*</p>
<p :class="{'activeClass': idClicked == NP625 }" id="NP625" >ASTY, the town of Athens: IG I² 837; SEG 50 (2000) no. 42; Paus.l.20.5, 1.25.8 Milestones marking distances from the Asty</p>
<p :class="{'activeClass': idClicked == NP626 }" id="NP626" >Hipparchos' herm, before 514 B.C., marking the half-way point between the Asty and Kephale - - - IG I³ 1023 = IG I² 837; Pl., Hipparch. 228D-229B; Guarducci, M. 1967-1978, II, 440-441, fig. 97; Agora XI, 112-113,114; SEG 50 (2000) no. 82; Billot, M.-F. 1989, 722</p>
<p :class="{'activeClass': idClicked == NP627 }" id="NP627" >Two milestones found on the Sacred Way near Daphni, 1st-2nd c. A.C. - - - IG II² 5181-5182</p>
<p :class="{'activeClass': idClicked == NP628 }" id="NP628" >Milestone dated by Diocletian and Maximianus, 286-305 A.D, found near Aspropyrgos (i.e. near the Sacred Way) - - - IG II² 5202; Sironen, E. 1997, 102-103 no. 32</p>
<p :class="{'activeClass': idClicked == NP629 }" id="NP629" >Milestone dated by Jovian, 363/4 A.D., Daphni SEG 51 (2001) no. 160</p>
<p :class="{'activeClass': idClicked == NP630 }" id="NP630" >Milestone on the Sacred Way, found in the stairs of the monastery at Daphni - - - IG II² 5204; CIL III 572; SEG 26 (1976-77) no. 273; Sironen, E. 1997, 108-110 no. 35</p>
<p :class="{'activeClass': idClicked == NP631 }" id="NP631" >Text 1: Greek inscr., dated by an emperor of the early 3rd to the mid-4th c. A.C.</p>
<p :class="{'activeClass': idClicked == NP632 }" id="NP632" >Text 2: Greek inscr., dated by Valentinian and Valens</p>
<p :class="{'activeClass': idClicked == NP633 }" id="NP633" >Text 3: Latin inscr., set up in 396/7 A.D. by the proconsul Eusebius during the reign of Arcadius and Honorius with a cross added below in later times</p>
<p :class="{'activeClass': idClicked == NP634 }" id="NP634" >ASTYDAMAS, son of Astydamas, base of an honorary bronze statue in the Theater - - - IG II² 3775 (= IG II² 5168); D.L. 2.43; RE Suppl. X, 1965, 46, s.v. Astydamas (F. Stoessel); the literary testimonia for the statue presented and its date reviewed by H. R. Gotte, “Die Basis des Astydamas im sogenannten lykurgischen Dionysos-Theater zu Athen,”, Antike Kunst 42 (1999) 21-25, fig. 1; pl. 5:1-2</p>
<p :class="{'activeClass': idClicked == NP635 }" id="NP635" >ASTYNOMOS, son of Lysiades, grave trapeza, 350 - 200 B.C.? IG II² 3831; SEG 39 (1989) no. 249; reused in a statue base in the Theater of Dionysos west parodos, M. Korres,” Διονυσιακό Θέατρο: VI, μεγάλο βάθρο,» Deltion 37 (1982) B1 Chr. 17 no. 1, pl. 14 gamma</p>
<p :class="{'activeClass': idClicked == NP636 }" id="NP636" >ATHENA</p>
<p :class="{'activeClass': idClicked == NP637 }" id="NP637" >Altars</p>
<p :class="{'activeClass': idClicked == NP638 }" id="NP638" >1* Altar in the Academy: Paus. 1.30.2; in the precinct of Athena in the Academy, schol. S. OC 56; Billot, M.- F. 1989, 735-736; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM448">448</a></p>
<p :class="{'activeClass': idClicked == NP639 }" id="NP639" >2* Great altar on the Akropolis: mentioned in the law concerning the Panathenaic Festival, IG II² 334 lines 19-20 and 29; Bancroft, S. 1980, 58-61; SEG 42 (1992) no. 92; the great altar of Athena <JBcom>[capriciously]</JBcom> located on the Pnyx, D. G. Romano, “Lykourgos, the Panathenaia and the Great Altar of Athena: Thoughts concerning the Pnyx Hill,” in Pnyx 1996, 81-85; SEG 48 (1998) no. 103; cf. Olive tree, owl and snake; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM97">97</a></p>
<p :class="{'activeClass': idClicked == NP640 }" id="NP640" >3* Poros limestone cult table dedicated by Chairion, a tamias, on the Akr. ca. 600-575 B.C.- - - IG I³ 590 = IG I² 467; SEG 47 (1997) no. 87; APF, 1971, 13</p>
<p :class="{'activeClass': idClicked == NP641 }" id="NP641" >Boundary stones</p>
<p :class="{'activeClass': idClicked == NP642 }" id="NP642" >4* <JBcom>[Hypothetical]</JBcom> boundary stone, ca. 475 B.C. - - - IG 12 243, 1050; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206656" target="_blank">Agora I 6656</a>; not a boundary stone and not necessarily naming Athena, G. V. Lalonde, “Agora I 6656: A Fragment of IG I³ 243,” Horos 13 (1999) 9-16, pl. 1; SEG 49 (1999) no. 55</p>
<p :class="{'activeClass': idClicked == NP643 }" id="NP643" >5* Sanctuary boundary stone, 4th c. B.C., near the Dipylon Gate northeast tower Kerameikos I 110, IG II² 2607-8; Ritchie Jr., C. E. 1985, 94-98, pl. 17, fig. 14 See also: Athena Ath15* below Dedications</p>
<p :class="{'activeClass': idClicked == NP644 }" id="NP644" >6* Bronze boat-lamp, inscribed, 4th-3rd c. B.C., found in the central chamber of the Erechtheion in or close to the west transverse wall IG I² 549 bis; NM X. 7038; A. S. Rousopoulos, «Δ' Ποίκιλα,» AE 1862 <JBcom>[1863]</JBcom>, 39 no. 9; K. S. Pittakis, «To έν τω Έρεχθείω εύρεθέν πλοΐον,» ibid., 91-94, drawing; Caskey, L. D. et al. 1927, 572, fig. 229</p>
<p :class="{'activeClass': idClicked == NP645 }" id="NP645" >7* Kore by Antenor, dedicated by Nearchos, ca. 520 B.C.?, Akr. - - - IG I² 628 = IG I² 485; Acr. 681; SEG 50 (2000) no. 78; Jacob-Felsen, N. 1969, 118-119 no. 16; commending T. B. L. Webster's suggestion that Nearchos was a homonymous grandson of Nearchos the potter, Keesling, C. M. 1995, 33-37, 78-79, 131-134, 226, 354-357; eadem 2003, 7, 43-44, 56-59, 71-72, 88, 213, figs. 11-12</p>
<p :class="{'activeClass': idClicked == NP646 }" id="NP646" >8* Statue dedicated by Kallimachos, probably Nike or Iris, ca. 490-485 B.C., north of the Parthenon at the highest point of the way IG I 2 784 = IG I² 609; W. A. P. Childs, “Herodotos, Archaic Chronology and the Temple of Apollo at Delphi "Jahrbuch 108 (1993) 403-405; M. Korres, “Recent Discoveries on the Acropolis,” in Acropolis Restoration 1994, 174 (drawings), 178 (text); a single public monument combining a private dedication with a public dedication set up by the Athenians, Keesling, C. M. 1995, 28, 130, 437-475; SEG 47 (1997) no. 90; Keesling, C. M. 2003, 53-54 with notes 62-63, 55; cf. Pan Pan3*</p>
<p :class="{'activeClass': idClicked == NP647 }" id="NP647" >Ionic capital associated with the inscribed column shaft - - - Acr. 3776; E. P. McGowan, “The Origins of the Athenian Ionic Capital,” Hesperia 66 (1997) 210-213, pls. 55:a-c, 66:a-b</p>
<p :class="{'activeClass': idClicked == NP648 }" id="NP648" >Frags, of two statues neither of which necessarily belongs to Kallimachos' dedication - - - Acr. 690; Gulaki, A. 1981, 18-27, figs. 1-4</p>
<p :class="{'activeClass': idClicked == NP649 }" id="NP649" >8** Seven perirrhanteria dedicated by Onesimos, son of Smithykos, perhaps the vase-painter (but see under IG I³ 699), ca. 485-480?, Acropolis - - - IG I³ 926-932; Pimpl, H. 1997, 31, 83 and cat. nos. 65-67, 71, 75-76, 82; <JBcom>[more likely to have been set up following rather than preceding the Persian sack]</JBcom></p>
<p :class="{'activeClass': idClicked == NP650 }" id="NP650" >9* Base of a statue of the Athena Promachos type?, set up to honour Ekphantos, signed by Kritios and Nesiotes, ca. 465 B.C., built into the brick reservoir west of the Parthenon (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM9">9</a>) - - - IG 12 850 = IG I² 530; Acr. 13206; Raubitschek, A. E. 1949, no. 121, ph. <br>Rededicated in honour of Lucius Cassius Longinus — IG II² 4168</p>
<p :class="{'activeClass': idClicked == NP652 }" id="NP652" >10* Statue base signed by Kresilas, ca. 450 B.C., base reused in the Parthenon basilica west doorway IG I³ 885 = IG I² 529; Acr. 6978; Raubitschek, A. E. 1949, no. 133 with drawing</p>
<p :class="{'activeClass': idClicked == NP654 }" id="NP654" >Inscription copied in antiquity: AP XIII.13</p>
<p :class="{'activeClass': idClicked == NP655 }" id="NP655" >For other dedications to Athena, see Alexander; Aphrodite Aph53*; Chairion</p>
<p :class="{'activeClass': idClicked == NP657 }" id="NP657" >11* Hymn to Athena, Propylaia - - - IG II² 3970,4347; D. Peppas-Delmousou and R. S. Stroud, “A Hymn to Athena,” AAA 6 (1973) 293-295, fig. 1</p>
<p :class="{'activeClass': idClicked == NP658 }" id="NP658" >12* Naiskos north of the Dipylon Gate: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM411">411</a></p>
<p :class="{'activeClass': idClicked == NP659 }" id="NP659" >Pictured in the Stoa Poikile: see Marathonomachia</p>
<p :class="{'activeClass': idClicked == NP660 }" id="NP660" >13* Sanctuary of Athena: referring to the Akropolis where Sulla committed sacrilege: Paus. 1.20.7</p>
<p :class="{'activeClass': idClicked == NP661 }" id="NP661" >14* Telma of Athena, defensive ditch around parts of the City Wall: Ritchie Jr., C. E. 1985, 88-93, 725-730, fig. 13, pl. 16</p>
<p :class="{'activeClass': idClicked == NP662 }" id="NP662" >15* Telma boundary stone, dark blue-grey marble, 400-375 B.C., found in situ at the 5th c. B.C. moat north northwest of the Dipylon Gate - - - Kerameikos I 405; SEG 21 (1965) no. 651; Wycherley, R. E. 1978, 21, fig. 59:1; a cement copy is now on the site, the original is in the Kerameikos Museum</p>
<p :class="{'activeClass': idClicked == NP663 }" id="NP663" >16* Telma near the <JBcom>[gates]</JBcom> beside Diochares' bath: IG II² 2495 lines 6-8; Μ. B. Walbank, “Leases of Sacred Properties in Attica, Part II,” Hesperia 52 (1983) 191 note 70, 193, 197, 198; Ritchie Jr., C. E. 1989, 253-254</p>
<p :class="{'activeClass': idClicked == NP664 }" id="NP664" >ATHENA, TEMPLES ON THE AKROPOLIS</p>
<p :class="{'activeClass': idClicked == NP665 }" id="NP665" >17* Temple, 8th c. or later: Iliad II. 549; Preisshofen, F. 1977, 80-81; location in relation to the plan of the Old Temple of Athena, Korres, M. 1997, 226 no. 2; for the famous poros limestone column bases, see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM81">81</a></p>
<p :class="{'activeClass': idClicked == NP666 }" id="NP666" >18* Adyton approached by Kleomenes: Hdt. 5.72; J. Pouilloux, “A propos d'une etude sur l'Erechtheion (1 )” BCH 74 (1950) 269-270</p>
<p :class="{'activeClass': idClicked == NP667 }" id="NP667" >19* MID-6TH CENTURY TEMPLE of Athena, ca. 550 B.C., variously called Hekatompedon, the H-architecture, Ur-Parthenon, first peripteral Parthenon, Archaic Parthenon, Old Temple of Athena: Wiegand, Th. 1904, 1-114; Bancroft, S. 1980, 27-45; <JBcom>[convincingly]</JBcom> dated to 550-540 B.C., D. Williams, “Aegina, Aphaia-Tempel” AA 1982, 61-63; located on the western part of the Parthenon site, Korres, M. 1997, 219-241, frontispiece, figs. 1,2 nos. 6 and 11, 4-10; Klein, N. L. 1998, 7-16, 184-187</p>
<p :class="{'activeClass': idClicked == NP668 }" id="NP668" >Temple statue: E. B. Harrison, rev. of C. J. Herington, Athena Parthenos and Athena Polias, in AJA 61 (1957) 208-209</p>
<p :class="{'activeClass': idClicked == NP669 }" id="NP669" >Pediment sculpture of poros limestone, - - - Acr. 3-4, 35-37, 40 +; Brouskari, M. 1974, 28 no. 4, 30 no. 4572, 33 no. 41, 34 no. 56, 36 nos. 37-40, 39 nos. 35-36, 46 no. 3, figs. 14, 44, 46, 54-56, 80-81; Agora XI, 31-33</p>
<p :class="{'activeClass': idClicked == NP670 }" id="NP670" >Pediment One: Lioness-and-bull group flanked by snakes</p>
<p :class="{'activeClass': idClicked == NP671 }" id="NP671" >Pediment Two: Lions-and-bull group flanked by Herakles wrestling Triton on left, Typhon defeated by Zeus on right; U. Hockmann, “Zeus besiegt Typhon,” AA 1991, 11-23, figs. 1-10</p>
<p :class="{'activeClass': idClicked == NP672 }" id="NP672" >Marble simas - - - Acr. 3902-3940, 3945-3988 +; W.-H. Schuchhardt, “Archaische Bauten auf der Akropolis von Athen,” AA 1963, cols. 816-821, fig. 18</p>
<p :class="{'activeClass': idClicked == NP673 }" id="NP673" >Labelled sima IG I³ 1388 (ca. 550 - 525? B.C.), Acr. 3918; SEG 10 (1949) no. 466</p>
<p :class="{'activeClass': idClicked == NP674 }" id="NP674" >Metopes</p>
<p :class="{'activeClass': idClicked == NP675 }" id="NP675" >Metope slabs reused in 485/4 B.C.(?) for the Law concerning the Acropolis, the “Hekatompedon Inscription” - - - IG I³ 4; Dinsmoor Jr., W. B. 1980, 24-26, 28-30; SEG 51 (2005) no. 26</p>
<p :class="{'activeClass': idClicked == NP676 }" id="NP676" >Metope reused for Lysias kalos dipinto, ca. 450 B.C. - - - IG I³ 1406 = IG I² 925; Wiegand, Th. 1904, 12, 112, fig. 115; SEG 50 (2000) no. 100</p>
<p :class="{'activeClass': idClicked == NP677 }" id="NP677" >Metopes reused in the forecourt of the Old Propylon: ibid., 110, fig. 113; Dinsmoor Jr., W. B. 1980, 67 s.v. Hekatompedon</p>
<p :class="{'activeClass': idClicked == NP678 }" id="NP678" >The following marble reliefs, separately attached, have been <JBcom>[adventurously]</JBcom> assigned to the temple metopes - - - Acr. 701 (Gorgon), Acr. 122* (panthers and lions), Acr. 577 (four-horse chariot); M. Korres, «Κλασσική αθηναϊκή άρχετεκτονική,» in ΑΘΗΝΑΙ 2000, 7, fig. 4</p>
<p :class="{'activeClass': idClicked == NP679 }" id="NP679" >Raking geisa with painted soffits: Wiegand, Th. 1904, 23-27, 64-65, figs. 28-54, pls. I-III; Klein, N. L. 1998, 9-11, 186-187</p>
<p :class="{'activeClass': idClicked == NP680 }" id="NP680" >Soffits with painted lotus - - - Acr. 66 +; <JBcom>[heavy restorations in plaster have produced an unlovely lotos design unparalleled in ancient times]</JBcom></p>
<p :class="{'activeClass': idClicked == NP681 }" id="NP681" >Soffits with painted storks and sea eagles flying overhead, seen from below - - - Acr. 13 + Cf. Gorgon, archaic relief fragments; Sphinx</p>
<p :class="{'activeClass': idClicked == NP682 }" id="NP682" >20* THE OLD TEMPLE OF ATHENA, ca. 500 B.C., south of the Erechtheion, the late archaic temple variously called the Old Temple of Athena, Hekatompedon, the Peisistratid temple, the Dörpfeld temple: H. Riemann, “Der peisistratische Athenatempel auf der Akropolis zu Athen,” MDAI 3 (1950) 7-39, figs. 1-5; "sculptural decoration should date around 500 B.C.”, W. A. P. Childs, “The Date of the Old Temple of Athena on the Acropolis,” in Coulson et al. 1994, 1-6, figs. 1-3; [built to celebrate the victory over the Boeotians and Chalcidians in 506 B.C.)</p>
<p :class="{'activeClass': idClicked == NP683 }" id="NP683" >Foundations: W. Dörpfeld, “Der alte Athenatempel auf der Akropolis,” A/W 11 (1886) 337-351, Beilage A; <JBcom>[mistakenly]</JBcom> dated to 7th c. B.C., Beyer, I. 1977, 44-54, figs. 1-4; <JBcom>[the entire foundation dates after 506 B.C.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP684 }" id="NP684" >Sima with mason's mark from the construction period and with graffiti made after the Persian destruction - - - EM 5949 +; D. Peppa-Delmouzou, «Τεκτονικά Σημεία και Έπιγραφαί,» in Orlandos Charisterion 1965-1968, IV, 370-375, 379-380, fig. 1, pl. 105</p>
<p :class="{'activeClass': idClicked == NP685 }" id="NP685" >Rooftiles with masons' marks from the construction pd. and with graffiti made after the Persian destruction EM 5401 +; D. Peppa-Delmouzou, op. cit., 375-381, fig. 2, pl. 106; earliest known use of acrophonic numeral system, Guarducci, M. 1967-1968, ill, 384-5, fig. 138; cf. Threatte, L. 1980, 112; idem, 1996, 695; SEG 50 (2000) no. 96</p>
<p :class="{'activeClass': idClicked == NP686 }" id="NP686" >Cult statue: see Athena Ath103* (Polias)</p>
<p :class="{'activeClass': idClicked == NP687 }" id="NP687" >Frieze reliefs - - - Acr. 1342-1343 +; Schrader, H., 1939, 387-399, figs. 500-503, pls. 198-200. (Acr. 1340 does not belong.)</p>
<p :class="{'activeClass': idClicked == NP688 }" id="NP688" >Pediment groups, Gigantomachia and Lions attacking bull - - - Acr. 631+; Schrader, H. 1939, 345-387, figs. 406-454, 457, 465, 469-495, pls. 185-197</p>
<p :class="{'activeClass': idClicked == NP689 }" id="NP689" >Lionhead spouts Acr. 69 +; ibid., nos. 439-440, fig. 367, pls. 180-181</p>
<p :class="{'activeClass': idClicked == NP690 }" id="NP690" >Ramshead spouts</p>
<p :class="{'activeClass': idClicked == NP691 }" id="NP691" >Two frags, of one spout - - - Acr. 3722; ibid., no. 438, figs. 365-366</p>
<p :class="{'activeClass': idClicked == NP692 }" id="NP692" >Spout in use as a water spout at the Kaisariani Monastery until 1985 when it was taken to the Akropolis, replaced at Kaisariani by an excellent marble replica - - - Acr. (number not known); assigned to a fountain house at Kaisariani, Wiegand, Th. 1904, 125 fig. 122; in 1969 William Bell Dinsmoor told me that he had known for a long time that the Kaisariani ramshead water spout came from the Old Temple of Athena</p>
<p :class="{'activeClass': idClicked == NP693 }" id="NP693" >Shield taken down from the temple by Kimon before the battle of Salamis: Plu. Cim. 5.3; Preisshofen, F. 1977, 83; <JBcom>[perhaps a shield captured in 506 B.C.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP694 }" id="NP694" >For other temples of Athena on the Akropolis, see also Athena Ath80* (limestone naiskos of Athena Nike); Athena Ath84* (marble temple of Athena Nike); Erechtheion; Parthenon, unfinished marble temple; Parthenon; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM65B">65B</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM81">81</a></p>
<p :class="{'activeClass': idClicked == NP695 }" id="NP695" >ATHENA (continued) THRONES AND THEATER SEATS</p>
<p :class="{'activeClass': idClicked == NP696 }" id="NP696" >21* Throne of the priestess who spoke to Kleomenes on the Akropolis: Hdt. 5.72</p>
<p :class="{'activeClass': idClicked == NP697 }" id="NP697" >22* Athenion, priestess of Athena, theater throne with snake and gorgoneion reliefs, 2nd c. A.C. - - - IG II² 5063 A; Maass, M. 1972, 20-21,130-131, pls. 3c,17</p>
<p :class="{'activeClass': idClicked == NP698 }" id="NP698" >23* Theater seat - - - IG II² 5163 See also: Ladamea; Megiste </p>
<p :class="{'activeClass': idClicked == NP699 }" id="NP699" >ATHENA, (continued) SCULPTURE FROM THE AKROPOLIS Xoanon set up by Erichthonios: see Athena Ath103*</p>
<p :class="{'activeClass': idClicked == NP700 }" id="NP700" >Seventh c. B.C. terracotta statue: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM117">117</a></p>
<p :class="{'activeClass': idClicked == NP701 }" id="NP701" >24* Ancient statues blackened by Persian fire: Paus. 1.27.6; Keesling, C. M. 2003, 128; <JBcom>[perhaps a memorial display at the Akropolis north wall]</JBcom></p>
<p :class="{'activeClass': idClicked == NP702 }" id="NP702" >25* Archaic head of Athena from a poros limestone pediment - - - Acr. 50; I. Beyer, “Die Reliefgiebel des alten Athena-Tempels der Akropolis,” AA 1974, 647-8, fig. 7 no. 50</p>
<p :class="{'activeClass': idClicked == NP703 }" id="NP703" >26* Statue by Pythis, dedicated by Epiteles, 510-500? B.C. - - - Acr. 136 + 6506, IG I³ 680 = G I2 507; Jacob-Felsch, M. 1969, 122-3 no. 22; SEG 29 (1979) no. 25; LIMC II, 1984, 971 no. 130, s.v. Athena (P. Demargne)</p>
<p :class="{'activeClass': idClicked == NP704 }" id="NP704" >27* Statue by Evenor, dedicated by Angelitos, ca. 470 B.C., found in the southeast corner of the Akr. - - - Acr. 140, IG I² 788 = IG I² 495; SEG 42 (1982) no. 40; Tolle-Kastenbein, R. 1980, 54-56 no. 9:e with pls. 42-44; P. Demargne, op. cit. (26* above) 972 no. 144, ph. and 1009 no. 566</p>
<p :class="{'activeClass': idClicked == NP705 }" id="NP705" >28* Seated statue by Endoios, dedicated by Kallias, ca. 525 B.C., in the vicinity of the Erechtheion: Paus. 1.26.4, probably = Acr. 625; P. A. Marx, “Acropolis 625 (Endoios Athena) and the Rediscovery of Its Findspot,” Hesperia 70 (2001), 221-254, figs. 1-16</p>
<p :class="{'activeClass': idClicked == NP706 }" id="NP706" >28** Athena with diagonal aegis, early 1st c. A.C. - - - Acr. 1337; dated ca. 420 B.C., Palagia, O. 1987, 84, figs. 3-4</p>
<p :class="{'activeClass': idClicked == NP707 }" id="NP707" >29* Head - - - Acr. 2338; grouped with the bearded head, Akr. 2344, regarded as copies of the statues of Athena and Miltiades in the Delphi group by Pheidias, G. Despinis, «Η Κεφάλη του Μουσείου της Ακροπολης 2344,» in Μνείας Χάριν: Topos στη Μνήμη Μαίρης Σιγανίδου, edd. Μ. Lilibaki-Akamati et al., Ministry of Culture, Thessaloniki, 1998, 47-59, pls. 1:1, 2:2, 3:2, 4-5</p>
<p :class="{'activeClass': idClicked == NP708 }" id="NP708" >30* Statuette holding cornucopia in the left hand, late 2nd c. A.C. - - - Acr. 2803; C. Praschniker, “Aus dem Depot des Akropolismuseums I. Athene-Gestalten,” JOAI 37 (1948) Beibl. 29-30 no. 17, fig. 17; Karanastassis, P. 1987, 421-422 B III 4, pl. 48:3-6</p>
<p :class="{'activeClass': idClicked == NP709 }" id="NP709" >31* Statuette, Ince Blundell type - - - Acr. 2805 + 3209; C. Praschniker, op. cit, (Athena Ath30*), Beibl. 11-18 no. 7, fig. 7</p>
<p :class="{'activeClass': idClicked == NP710 }" id="NP710" >32* Torso frag., Hope type - - - Acr. no number; ibid., Beibl. 9-11 no. 6, fig. 6</p>
<p :class="{'activeClass': idClicked == NP711 }" id="NP711" >33* Statue mentioned in an inscr. of the mid-2nd c. A.C., Akr. : IG II² 3194 lines 11-12; J. H. Oliver, “Philosophers and Procurators, Relatives of the Aemilius Juncus of Vita Commodi 4, 11,” Hesperia 36 (1967) 52; Byrne, S. G. 2003, 44 no. 1</p>
<p :class="{'activeClass': idClicked == NP712 }" id="NP712" >ATHENA (continued), SCULPTURE IN THE AGORA EXCAVATIONS AREA</p>
<p :class="{'activeClass': idClicked == NP713 }" id="NP713" >34* Statue by Lokros in the temple of Ares: Paus. 1.8.4; Agora XIV, 164; Hartswick, K. J. 1990, 258 note 99</p>
<p :class="{'activeClass': idClicked == NP714 }" id="NP714" >35* Head, Giustiniani type, mid-2nd c. B.C. ?, Stoa of Attalos - - - NM 3004; Karanastassis, P. 1987, 427 B VI 3, pl. 53:1-3; unfinished, Despinis, G. 1999, 173, 176, 179-180 (downdated to the Augustan pd.), figs. 6-7; Kaltsas, N. 2002, 126 no. 238, ph.</p>
<p :class="{'activeClass': idClicked == NP715 }" id="NP715" >36* Statue, ca. 420-410 B.C., west of the Odeion <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20654" target="_blank">Agora S 654</a>; Dinsmoor Jr., W. B. 1982a, 437 with note 51, fig. 1 for the findspot; P. Demargne, op. cit. (no. Ath26* above) 900 no. 244, ph.</p>
<p :class="{'activeClass': idClicked == NP716 }" id="NP716" >37* Statue fragments, Pallas Herculaneum type, 2nd half of 1st c. B.C.: M. D. Fullerton, “The Date of the Herculaneum-Pallas Type,” AA 1989, 57-67</p>
<p :class="{'activeClass': idClicked == NP717 }" id="NP717" >Head frag., Akr. North Slope <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201064" target="_blank">Agora S 1064</a>; Agora XI, no. 124, pl. 26</p>
<p :class="{'activeClass': idClicked == NP718 }" id="NP718" >Fragments - - - Acr. 3189 +; <JBcom>[mistakenly]</JBcom> dated ca. 430 B.C., M. S. Brouskari, «H Αθηνά του τύπου Herculaneum στην Ακρόπολη,» in Archaische und klassische griechische Plastik 1986, II, 77-83, 230, 244-245, fig. 1, pls. 107-108</p>
<p :class="{'activeClass': idClicked == NP719 }" id="NP719" >38* Statue, 1st c. A.C., Areopagus west slope in a late 3rd c. A.C. context- - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201232" target="_blank">Agora S 1232</a>; <JBcom>[mistakenly]</JBcom> assigned to the Hephaisteion west pediment, H. A. Thompson, “The pedimental Sculpture of the Hephaisteion,” Hesperia 18 (1949) 234 C (noting a “large rudely incised delta on the left upper arm and another on the left breast”), 239-240, 246-247, fig. 1 (findspot), pls. 51:1-3, 52:1; Karanastassis, P. 1987, 422-423 B IV 2, pl. 49:4</p>
<p :class="{'activeClass': idClicked == NP720 }" id="NP720" >39* Statuette frag., Athena Velletri type <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202054" target="_blank">Agora S 2054</a>; Harrison, E. B. 1977, 150 note 64, 177 no. 18, figs. 13-14</p>
<p :class="{'activeClass': idClicked == NP721 }" id="NP721" >40* Statue, mid-2nd c. A.C., decapitated and reused as a doorstep by the Christian owners of the Late Roman Mansion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM291">291</a>) on the Areopagus northeast slope - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202337" target="_blank">Agora S 2337</a>; Shear Jr., T. L. 1971, 274-5, pl. 59:b; idem 1973a, 161, 163 note 94; L. J. Roccos, “Athena from a House on the Areopagus,” Hesperia 60 (1991) 397-410, pls. 107-108</p>
<p :class="{'activeClass': idClicked == NP722 }" id="NP722" >ATHENA (continued), SCULPTURE, VARIOUS</p>
<p :class="{'activeClass': idClicked == NP723 }" id="NP723" >41* Xoanon brought to Daulis from Athens by Prokne: Paus. 10.4.9; J. McK. Camp, “Before Democracy: Alkmaionidai and Peisistratidai,” in Coulson et al. 1994, 8</p>
<p :class="{'activeClass': idClicked == NP724 }" id="NP724" >42* Pnyx Athena head, Medici type, 2nd c. A.C., probably Antonine, ca. 100 m. north of the Assembly Place at 19 Iouliou Smith St. (Plan 2 zeta—3) - - - NM 3718; B. D. Theophanides, «Ή Άθηνά τής Πνύκος,» Deltion13 (1930-31) 171-176, pls. 1-3; Karanastassis, P. 1987, 413 B II 2, pl. 43:1-2; unfinished, from a sculptor's workshop, A. Ajootian, “Athena Medici in Athens,” in ICCA XV, Amsterdam, July 12-17 1998, Doctor, R. F. and E. M. Moorman eds., Amsterdam, 1999, 54-55, pl. 3:c; Kaltsas, N. 2002, 111 no. 200, ph.</p>
<p :class="{'activeClass': idClicked == NP725 }" id="NP725" >43* Athena with owl, bronze statuette. ex-Elgin Coll. - - - Metropolitan Museum of Art 50.11.1; Tolle-Kastenbein, R. 1980, 49-50 no. 8:c, 312, pl. 36</p>
<p :class="{'activeClass': idClicked == NP726 }" id="NP726" >44* Statue set up as a starting post: IG II² 4350; Gasparri, C. 1974/75, Annuario 52-53, 366 note 2</p>
<p :class="{'activeClass': idClicked == NP727 }" id="NP727" >45* Statuette - - - NM 2266 + 2268; <JBcom>[mistakenly]</JBcom> linked to the Gigantomachia of the Attalid dedication, Svoronos, J. N. 1937, 650 no. 393, pl. 168 See also: Palladia; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM117">117</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM515">515</a></p>
<p :class="{'activeClass': idClicked == NP728 }" id="NP728" >ATHENA (continued), RELIEFS</p>
<p :class="{'activeClass': idClicked == NP729 }" id="NP729" >46* Two-sided relief, frontal archaizing Athena in high relief and winged Athena in profile in low relief, incorporated in the foundations of a late part of the Akropolis circuit wall above the cave of Apollo Hypoakraios - - - Acr. 4915; A. N. Skias, «Άνασκαφαι έν Άκρόπολει,» Praktika 1910, 58-59, 144; A. von Netoliczka, “Ein doppelseitiges Relief von der Akropolis,” JOA117 (1914) 121-132, figs.10-11; P. Demargne, op. cit (no. Ath26* above), 965 no. 66</p>
<p :class="{'activeClass': idClicked == NP730 }" id="NP730" >47* Athena leaning on her spear, the so-called Mourning Athena, 460-450 B.C., found at the Akropolis south wall in the ergasterion foundations (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM53">53</a>) - - - Acr. 695; Tolle-Kastenbein, R. 1980, 56-57 no. 9f, pl. 45; P. Demargne, op. cit (no. Ath26* above) 1015 no. 625, ph.; M. Meyer, “Zur ‘sinnenden' Athena,” in Festschrift fur N. Himmelmann 1989, 161-168, pl. 30:3</p>
<p :class="{'activeClass': idClicked == NP731 }" id="NP731" >47** Athena seated by a naiskos containing a xoanon of goddess standing behind a trapeza, 4th c. B.C. - - - Acr. 4734 +; the xoanon thought to be Athena Polias, I. S. Mark, “The ancient Image and Naiskos of Athena Polias: The Ritual Setting on a late Fifth-century Acropolis Relief,” Abstracts: 88th General Meeting, Archaological Institute of America, 11 (1986) 18</p>
<p :class="{'activeClass': idClicked == NP732 }" id="NP732" >48* Athena standing with owl, shield and herm, 1st c. B.C. Virginia Museum of Fine Arts no. 60-5; C. Vermeule, Roman Art: Early Republic to Late Empire, Boston, 1979, 43-44,189 no. 45, fig. 45; <JBcom>[corrigendum: the relief, originally acquired in Naples, is not from Athens]</JBcom></p>
<p :class="{'activeClass': idClicked == NP733 }" id="NP733" >49* Athena standing by an altar, 410-400? B.C. - - - NM 1398, IG I 3 960 = IG I² 762; Raubitschek, A. E. 1949, no. 300</p>
<p :class="{'activeClass': idClicked == NP734 }" id="NP734" >50* Athena standing beside the olive tree with her owl, late 2nd c. A.C. - - - Acr. 2426; S. Pelekides, «Ο τύπος τής Άθηνάς των Μεδίκων,» Deltion 9 (1924-25) 129 under beta; Karanastassis, P. 1987, 416 B II 9, pl. 46:4</p>
<p :class="{'activeClass': idClicked == NP735 }" id="NP735" >51* Athena standing between owl and snake, Ambelokipi: drawing by Sir William Gell, Sketchbook 8, LB 17, "at the church of Angelokipos"; P. Wolters, “Deux bas-reliefs attiques disparus,” BCH 18 (1894) 487-489 with drawing; the church is Haghioi Pantes Omologetes (Chr. Mon. CM53**)</p>
<p :class="{'activeClass': idClicked == NP736 }" id="NP736" >52* Athena standing by a flaming altar, holding a Medusa head, with a snake below - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202220" target="_blank">Agora S 2220</a>; unpublished</p>
<p :class="{'activeClass': idClicked == NP737 }" id="NP737" >52** Athena holding Nike, and Herakles, 4th c B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20433" target="_blank">Agora S 433</a>; LIMC IV, 1988, 747 no. 326, ph., s.v. Herakles (O. Palagia) See also: Boule; Demos and Charites, dedication; Hadrian, statue; cf. Kodros, Neleus, Basile; Snake Goddess</p>
<p :class="{'activeClass': idClicked == NP738 }" id="NP738" >53* ATHENA AGELAA, cult possibly in Athens: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203244" target="_blank">Agora I 3244</a> line 90; Ferguson, W. S. 1938, 29</p>
<p :class="{'activeClass': idClicked == NP739 }" id="NP739" >ATHENA ARCHEGETIS = Athena Polias: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207295" target="_blank">Agora I 7295</a> line 65; Shear Jr., T. Leslie, 1978, 3, 6, 36 note 88</p>
<p :class="{'activeClass': idClicked == NP740 }" id="NP740" >Gate of Athena Archegetis: see Roman Agora</p>
<p :class="{'activeClass': idClicked == NP741 }" id="NP741" >54* Statue of Athena Archegetis: schol. Ar. Av. 516; identical with the old image of Athena Polias, Kroll, J. H. 1982, 69-70</p>
<p :class="{'activeClass': idClicked == NP742 }" id="NP742" >55* Aqueduct (ex-Agoranomion), often called the arcuated building, supplying the Tower of The Winds reservoir, dedicated to Athena Archegetis and The Theoi Sebastoi (Divi Augusti), mid-1st c. A.C., east of the Roman Agora (Plan 1 delta - 6, no. 69) IG II² 3183 (3180 = 3183c); SEG 47 (1997) no. 212; seen to be an aqueduct, K. Pittakis, L'ancienne Athenes, 1835, 126; although J. Travlos clearly stated in his text that this building is not the Agoranomion, his illustrations thereof were mislabelled “Agoranomeion”, Travlos, J. 1971, 37-40, 579 (“perhaps served as a propylon” ), figs. 39 no. 81, 46-50, 362 B, 722 S; G. Dontas, «Οικόπεδον Ν. Τσιμβίλη - Δ. Καραμβόζα έπι τών όδών Κυρρηστου - Λυσίου - Μάρκου Αυρηλίου,» Deltion 27 (1972) B1 Chr.,17-21, figs. 2-4, pl. 30 no. 2, pl. 31; M. C, Hoff, “The so-called Agoranomion and the imperial Cult in Julio-Claudian Athens,VL4 1994, 93-109, figs. 1-18; “a kind of ornamental entrance,” M. Korres, “The Parthenon from Antiquity to the 19th Century,” in Parthenon 1994, 144 col. 2 with fig. 8; the aqueduct was constructed in connection with a massive alteration of the Tower of The Winds water supply system, information contributed by H. J. Kienast in October 1996; see Chr. Mon. CM18*</p>
<p :class="{'activeClass': idClicked == NP743 }" id="NP743" >56* ATHENA AREIA, altar dedicated by Orestes on the Areopagus: Paus. 1.28.5; Johrens, G. 1981, 153-154 with note 828</p>
<p :class="{'activeClass': idClicked == NP744 }" id="NP744" >57* ATHENA AT THE PALLADION, cult mentioned in the accounts of the logistai for 426/5-423/2 B.C: IG I³ 369 lines 73, 90</p>
<p :class="{'activeClass': idClicked == NP745 }" id="NP745" >Kanephoroi, theater seat IG II² 5118 See also: Palladia; Palladion; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM164">164</a> </p>
<p :class="{'activeClass': idClicked == NP746 }" id="NP746" >ATHENA BOULAIA: see Zeus Zeu15* (Boulaios)</p>
<p :class="{'activeClass': idClicked == NP747 }" id="NP747" >58* ATHENA DEMOKRATIA, altar, Augustan (?), Akr. - - - IG II² 4992; SEG 21 (1965) no. 814; Ameling, W. 1983, II, 227-228 no. 214</p>
<p :class="{'activeClass': idClicked == NP748 }" id="NP748" >59* ATHENA EETIONE, theater seat - - - IG II² 5120 (uncertain restoration)</p>
<p :class="{'activeClass': idClicked == NP749 }" id="NP749" >60*ATHENA ERGANE, THEA ERGANE: precinct (?) on the Akropolis: Paus. 1.24.3, 3.17.4, 5.14.5; Johrens, G. 1981, 176-178 esp. notes 934-935</p>
<p :class="{'activeClass': idClicked == NP750 }" id="NP750" >Dedications made by brothers, Teisikles and Euktemon, mid-4th c. B.C.: B. D. Meritt, “Greek Inscriptions,” Hesperia 9 (1940) 58-59 no. 7, ph.</p>
<p :class="{'activeClass': idClicked == NP751 }" id="NP751" >61* Teisikles' dedication - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201732" target="_blank">Agora I 1732</a></p>
<p :class="{'activeClass': idClicked == NP752 }" id="NP752" >62* Euktemon's dedication, Akr. IG II² 4329</p>
<p :class="{'activeClass': idClicked == NP753 }" id="NP753" >63* Dedication, mid-4th c. B.C. IG I² 561; Raubitschek, A. E. 1949, 89</p>
<p :class="{'activeClass': idClicked == NP754 }" id="NP754" >64* Melinna's dedication to Thea Ergane, found in 1835, Akr. IG II² 4334; SEG 16 (1959) no. 168</p>
<p :class="{'activeClass': idClicked == NP755 }" id="NP755" >65* Statue dedicated to (Athena Ergane) Polias, Thea Ergane, 4th c. B.C. - - - IG II² 4318; SEG 16 (1959) nos. 24, 168 See also: Parthenon, naiskos in north colonnade; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM92">92</a></p>
<p :class="{'activeClass': idClicked == NP756 }" id="NP756" >ATHENA HEPHAISTEIA: see Hephaistos at Kolonos Agoraios</p>
<p :class="{'activeClass': idClicked == NP757 }" id="NP757" >ATHENA HIPPIA: see Poseidon Hippios</p>
<p :class="{'activeClass': idClicked == NP758 }" id="NP758" >ATHENA HYGIEIA ON THE AKROPOLIS: Frazer, J. G. 1913, II, 277-282</p>
<p :class="{'activeClass': idClicked == NP759 }" id="NP759" >65** Dedication by the potter Euphronios, ca. 475 B.C. - - - IG I³ 824 = IG I² 516; SEG 51 (2001) no. 43</p>
<p :class="{'activeClass': idClicked == NP760 }" id="NP760" >66* Graffito dedication to Athena Hygieia by the potter Kallis on a vase Acr. 1367; "early classic,” ARI/2,1556</p>
<p :class="{'activeClass': idClicked == NP761 }" id="NP761" >66** Altar: Plu. Per. 13.8; Kavvadias-Kawerau, 1906, 146, pl. eta at altitude 145.70 m.</p>
<p :class="{'activeClass': idClicked == NP762 }" id="NP762" >67* Cult table: Gill, D. 1991, 9, 36 no. 6, pl. 1; Travlos, J. 1971, fig. 170</p>
<p :class="{'activeClass': idClicked == NP763 }" id="NP763" >68* Base of statue by Pyrrhos, ca. 432 B.C., in situ east of the southernmost column of the Propylaia east porch - - - IG I³ 506 = IG I² 395; Paus. 1.23.4; Raubitschek, A. E. 1949, no. 166, 2 ph.; SEG 49 (1999) no. 62</p>
<p :class="{'activeClass': idClicked == NP764 }" id="NP764" >69* ATHENA (HYGIEIA?) with helmet, aegis and shield, ASKLEPIOS, worshipper and altar, relief, ca. mid-4th c. B.C., Asklepieion - - - NM 1369; Svoronos, J. N. 1911, 288-289 no. 66, pl. 37.5; Kaltsas, N. 2002, 216 no. 445</p>
<p :class="{'activeClass': idClicked == NP765 }" id="NP765" >70* ATHENA ITONIA, sanctuary in Athens : IG I³ 383 lines 151-152; P. Roesch, Etudes boeotiennes, Paris,1982, 220</p>
<p :class="{'activeClass': idClicked == NP766 }" id="NP766" >71* Marble plaque, boundary marker, ca. 475-450 B.C. - - - IG I³ 1049; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207047" target="_blank">Agora I 7047</a>; Ritchie Jr., C. E. 1985, 78-82, pl. 14, fig. 11; Agora XIX, 1991, 22 H1, pl. 1</p>
<p :class="{'activeClass': idClicked == NP767 }" id="NP767" >72* ATHENA KLEIDOUCHOS, statue by Pheidias, Pliny NH XXXIV.54 (location not specified); Schattner, T. G. 1990, 206; <JBcom>[probably in Athens]</JBcom></p>
<p :class="{'activeClass': idClicked == NP768 }" id="NP768" >72** ATHENA LEMNIA, statue by Pheidias on the Akr.: Paus. 1.28.2; perhaps east of the northernmost column of the Propylaia east porch, G. P. Stevens, “The Periclean Entrance Court of the Acropolis of Athens,” Hesperia 5 (1936) 454-455, 514-515, figs. 7, 63; Pollitt, J. J. 1990, 63, 250-251; K. J. Hartswick, “The Athena Lemnia: A Response,” in ΣΤΕΦΑΝΟΣ 1998, 105-114; cf. commentary to IG I³ 514</p>
<p :class="{'activeClass': idClicked == NP769 }" id="NP769" >73* ATHENA NIKE, sanctuary on the Akropolis: IG I³ 35 with Addendum (Decree on the temple and priestess of Athena Nike, ca. 450 B.C.?) and IG I³ 36 with Addendum (Decree concerning the salary of the priestess of Athena Nike, 424/3 B.C.); Mark, I. S. 1993, xiii-xix (bibliography),1-155, figs. 1-17, pls. 1-22, Plan A; SEG 50 (2000) no.36</p>
<p :class="{'activeClass': idClicked == NP770 }" id="NP770" >74* First altar set up by Patrok<JBcom>[l]</JBcom>edes, ca. 550-530 B.C., north of the 2nd altar — IG I³ 596; Raubitschek, A. E. 1949, no. 329 with drawing; SEG 44 (1994) no. 15; Mark, I. S. 1993, 157 s.v. Altar, inscribed, fig. 4, pl. 6:a; <JBcom>[date given above proposed by G. Nemeth per ep., January 17, 1986, based on a study of the letter forms, thus rejecting the dogmatically set date of 566 B.C. generally accepted, e.g. Travlos, J. 1971, 482]</JBcom></p>
<p :class="{'activeClass': idClicked == NP771 }" id="NP771" >75* Second altar, mid-5th c. B.C., in situ east of the naiskos (80* below): Walter, O. 1940, 152 fig. 23; Mark, I. S. 1993, 53-54, 67-68 with figs. 11-14, pl. 5, C on Plan A</p>
<p :class="{'activeClass': idClicked == NP772 }" id="NP772" >76* Altar? or base of Aiginetan poros, northeast of the naiskos: <JBcom>[mistakenly]</JBcom> interpreted as an altar, ibid., 55-56, 121, fig. 12, pls. 6:b, 7, B on Plan A</p>
<p :class="{'activeClass': idClicked == NP773 }" id="NP773" >77* Third altar, for the marble temple, above the 2nd altar: IG II² 334 line 20; SEG 42 (1992) no. 92 and 48 (1998) no. 103; Kavvadias-Kawerau, 1906, 138, pl. Eta; Mark, I. S. 1993, 7, 75-76, 119, fig. 17</p>
<p :class="{'activeClass': idClicked == NP774 }" id="NP774" >78* Xoanon of Athena Nike (sometimes called Athena Apteros) holding a pomegranate and a helmet: IG I³ 1330 lines 11-13; Paus. 3.15.7, (made of wood) 5.26.6; Gulaki, A. 1981, 128-9; Romano, I. B. 1981, 58-69; Mark, I. S. 1993, 3-4, 24-29. 93-98 (testimonia), 108-110, 123-129, fig.3</p>
<p :class="{'activeClass': idClicked == NP775 }" id="NP775" >79* Archaic sanctuary: ibid., 31-35; cf. Bundgaard, J. A. 1974b, 43-49, figs. I-IV; nos. Ath81*-82* below belong to the pre-Persian sanctuary</p>
<p :class="{'activeClass': idClicked == NP776 }" id="NP776" >80* Limestone naiskos, mid-5th c. B.C.: ibid., 43-49, figs.l-IV; naiskos with the second altar (no. 75* above) dated after Eurymedon, Childs, W. A. P. 1985, 239 note 158; Mark, I. S. 1993, 1-3, 5, 10 with note 39, 42-52, figs. 5-10, 12, pls. 1,3,7,16, Plan A</p>
<p :class="{'activeClass': idClicked == NP777 }" id="NP777" >81* Limestone eschara under the floor of the the limestone naiskos: first identified as an eschara, Iakovides, S. 1962, 186-187; an eschara according to Mazarakis Ainian, A. 1997, 244 line 3; identified as the cult statue base, Mark, I. S. 1993, 1,4, 20-31, 52-53, figs. 2-3, Pis. 1-3:a, 7,16, Plan A; for the position under the floor, ibid., pl. 1 showing the eschara in relation to the euthynteria of the naiskos with its roughly worked inside surface not meant to be seen, and Appendix A, 143-147, Page 10 and Page 13 of Balanos' notes transcribed and translated, and also, more explicitly, I. S. Mark, “Levels taken on the Nike Bastion,” Hesperia 64 (1995) 385-386 with note 19, 389</p>
<p :class="{'activeClass': idClicked == NP778 }" id="NP778" >82* Handmade female terracotta figurines, 6th c. B.C., found (along with bones and sherds) in and around the limestone eschara, last seen in 1939: Walter, O. 1940,146, figs. 21-22; Romano, I. B. 1981, 60-61; <JBcom>[doubtably]</JBcom> dated to 7th c. B.C., Travlos, J. 1971, 148, fig. 201; Mark, I. S. 1993, 1, 3-4, 22, 30, 31-32 with note 7, 66-67, pl. 2:b; Mazarakis Ainian, A. 1997, 244 with note 1949; <JBcom>[in 1939 A. E. Raubitschek saw handmade terracotta bulls with the terracotta female figurines in the eschara deposit, personal communication]</JBcom></p>
<p :class="{'activeClass': idClicked == NP779 }" id="NP779" >83* Retaining wall along the north edge of the sanctuary, 430s B.C.: earliest example of irregular trapezoidal masonry in Athens, Mark, I. S. 1993, 158 s.v. Irregular trapezoidal masonry, pl. 6:c, D on Plan A</p>
<p :class="{'activeClass': idClicked == NP780 }" id="NP780" >84* Temple of Wingless Victory, the marble temple: IG I³ 64 with addenda; SEG 44 (1994) no. 4; Paus. 1.22.4, 2.30.2; B. Wesenberg, “Zur Baugeschichte des Niketempels, Jahrbuch 96 (1981) 28-54, figs. 1-3, 8-9; Mark, I. S. 1993, 72-75, 82-92 (frieze dated ca. 420-418 B.C.), fig. 17, pls. 11, 14-21</p>
<p :class="{'activeClass': idClicked == NP781 }" id="NP781" >85* Gilded bronze acroteria: IG II² 1425 lines 101-106; Schultz, P. 2001, 1-47, figs. 1-25, proposing three hypothetical restorations of the central acroterion: a tripod, a trophy flanked by Nikai, and a Nike <JBcom>[ignoring the possibility of an Athena suggested by J. Binder in the spring of 2000]</JBcom>; cf. Bellerophon</p>
<p :class="{'activeClass': idClicked == NP782 }" id="NP782" >86* Athena head frag. Acr. 4303: attributed to Athena Nike in a Gigantomachia restored on the east pediment, G. Despinis, «Τά γλυπτά των αετωμάτων τού ναού τής Άθηνάς Νίκης,» Deltion 29 (1974) A, 12-13 no. 4, 19-20, 274, pls. 16-17</p>
<p :class="{'activeClass': idClicked == NP783 }" id="NP783" >Temple terrace: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM15">15</a></p>
<p :class="{'activeClass': idClicked == NP784 }" id="NP784" >87* Parapet frieze - - - Acr. 973 +; Despinis, G. 1971, 170-174; M. S. Brouskari, To θωράκιο τού ναού τής Άθνάς Νίκης, ΑΕ 137 (1998), Athens, 1999, 1-268, pls. 1-86, plans and drawings 1-19</p>
<p :class="{'activeClass': idClicked == NP785 }" id="NP785" >88* Statue of Athena Nike, commemorating victory over the Ambrakiots 426/425 B.C., repaired ca. 338-326 B.C: IG II² 403; SEG 48 (1998) no. 99; <JBcom>[mistakenly]</JBcom> equated with the temple statue, Travlos, J. 1971, 149; Mark, I. S. 1993, 113, 123</p>
<p :class="{'activeClass': idClicked == NP786 }" id="NP786" >89* Stelai commemorating Antigonos' II victory over barbarians <JBcom>[Galatians]</JBcom> dedicated to Athena Nike by Herakleitos of Athmonon, recorded in an honorary decree ca. 250 B.C.: IG II² 677 lines 3-6; Bull. 1965 no. 142; SEG 47 (1997) no. 135; Schenkungen hellenistischer Herrscher, Part II, vol. 2, 2000, 310-311; S. V. Tracy, “Antigonos Gonatas, King of Athens,” in Macedonians in Athens 2003, 59</p>
<p :class="{'activeClass': idClicked == NP787 }" id="NP787" >ATHENA PAIONIA</p>
<p :class="{'activeClass': idClicked == NP788 }" id="NP788" >90* Sanctuary or statue in Melanthios' Garden, on the Road to the Academy opposite the grave mon. of Lykourgos and his descendants: Plu: Moralia 842 E</p>
<p :class="{'activeClass': idClicked == NP789 }" id="NP789" >91* Statue in the precinct of Dionysos Melpomenos: Paus. 1.2.5; sometimes identified with NM 234, the so-called Euboulides Athena found on Ermou St. west of the church of Hag. Asomaton (Plan 2 epsilon—3), Frazer, J. G. 1913, II, 51-53; Travlos, J. 1971, fig. 541; dated to the Augustan period, Karanastassis, P. 1987, 416-420 B III 1, pl. 47:1-2; cf. Despinis, G. 1995, 322-323, 326, pl. 66; Kaltsas, N. 2002, 299 no. 625, ph.</p>
<p :class="{'activeClass': idClicked == NP790 }" id="NP790" >ATHENA PALLENIS: see Ares, temple</p>
<p :class="{'activeClass': idClicked == NP791 }" id="NP791" >92* ATHENA PARTHENOS, temple statue in the Parthenon: J. Mansfield, “The Chronology of Anaxagoras' Athenian Period and the Date of his Trial. Part II: The Plot against Pericles and his Associates,'"Mnemosyne 33 (1980) 1, 52-70; LIMC II, 1984, 977-978, 1031, section A 11, s.v. Athena (P. Demargne); G. Nick, Die Athena Parthenos: Studien zum griechischen Kultbild und seiner Rezeption (AM. 19 Beiheft) Mainz, 2002, 1-289, pls. 1-24</p>
<p :class="{'activeClass': idClicked == NP792 }" id="NP792" >First chryselephantine statue by Pheidias originally called The Golden Statue, 438/437 B.C.: IG I³ 453-460; Paus. 1.17.2 + 1.25.7, 5.11.10, 7.27.2; 10.34.8; Pollitt, J. J. 1990, 53-58, 249-250; Harris, D. 1995, 263-265 (testimonia and select bibliography); represented on New Style silver tetradrachms of 55/54 B.C, Herzog, H. 1996, 145-147; <JBcom>[destroyed by fire during Hadrian's reign, before Pausanias' visit]</JBcom>; cf. A. Frantz, “Did Julian the Apostate Rebuild the Parthenon?” AJA 83 (1979) 395-6, 401 note 54</p>
<p :class="{'activeClass': idClicked == NP793 }" id="NP793" >Birth of Pandora represented on the base: Paus. 1.24.7</p>
<p :class="{'activeClass': idClicked == NP794 }" id="NP794" >Six marble base blocks replaced in the Parthenon cella ca. 1925: G. P. Stevens, “Remarks upon the Colossal Chryselephantine Statue of Athena in the Parthenon "Hesperia 24 (1955) 244-259, fig. 2</p>
<p :class="{'activeClass': idClicked == NP795 }" id="NP795" >Crown moulding of Eleusinian limestone - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203001" target="_blank">Agora A 3001</a>; tentatively assigned to the Athena Parthenos base, Gadbery, L. M. 1988, 125-188</p>
<p :class="{'activeClass': idClicked == NP796 }" id="NP796" >Second chryselephantine statue: Paus. 1.24.5-7; represented on Athenian imperial bronze coins, 2nd c. A.C., Agora XXVI, 128 nos. 169-171, pl. 16; G. P. Stevens, op. cit., 270-276; idem, “How the Parthenos was Made,” Hesperia 26 (1957) 361 (suggesting that “the well finished statue, which had served as a model for the colossal statue” had been preserved and used for the new Parthenos); idem, “Concerning the Parthenos,” Hesperia 30 (1961) 1-7, fig. 1, pl. 1; cf. Athena Ath99*; <JBcom>[Athena Parthenos II was dedicated during the reign of Antoninus Pius]</JBcom></p>
<p :class="{'activeClass': idClicked == NP797 }" id="NP797" >93* Lenormant copy, unfinished, 3rd c. A.C., Pnyx west slope in an area with potteries and sculptors' workshops (Plan 2 zeta—3) NM 128; W.-H. Schuchhardt, “Athena Parthenos,” Antike Plastik II (1963) 46-53, pls. 33-37; Karanastassis, P. 1987, 410-411 Bl 13, pl. 42:2; Kaltsas, N. 2002, 106 no. 190, ph.</p>
<p :class="{'activeClass': idClicked == NP798 }" id="NP798" >94* Varvakeion copy, mid-2nd c. A.C., found protected by a brick vault in a house of the Roman period in north Athens, north of the Varvakeion which was in the city block bounded by Sokratous, Sophokleous, Athinas and Evripidou Sts. (Plan 2 delta-4 ) - - - NM 129; findspot, Judeich, W. 1931, plan I F2; W.-H. Schuchhardt, op. cit. (Athena Ath93*), 31-46, fig. 2, pls. 20-32; Karanastassis, P. 1987, 327, 328-329, 408-410 Bl 12, pl. 35:1; Kaltsas, N. 2002, 104-105 no. 187, ph.</p>
<p :class="{'activeClass': idClicked == NP799 }" id="NP799" >95* Overlifesize copy, 150-200 A.D. Acr. 1362; Karanastassis, P. 1987, 327, 333, 407-408 Bl 10, pl. 41</p>
<p :class="{'activeClass': idClicked == NP800 }" id="NP800" >96* Frag, of snake and shield from a copy Acr. 4742; C. Praschniker, op. cit. (Athena Ath30*), Beiblatt 8-9 no. 5, fig. 5</p>
<p :class="{'activeClass': idClicked == NP801 }" id="NP801" >97* Strangford shield - - - BM cat. no. 302 (Smith, A. N. 1892, 99-100); K. W. Arafat, “A Note on the Athena Parthenos,” BSA 81 (1986) 3-6, fig. 2</p>
<p :class="{'activeClass': idClicked == NP802 }" id="NP802" >98* Relief copying the shield, probably from the Post-Herulian Wall <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202113" target="_blank">Agora S 2113</a>; Stephanidou-Tiberiou, T. 1979, Index, 191 under <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202113" target="_blank">Agora S 2113</a>, pl. 44b See also: Amazonomachia frieze</p>
<p :class="{'activeClass': idClicked == NP803 }" id="NP803" >99* Small statue of Athena Parthenos made by Pheidias before he made the large Parthenos: Him. Or. XXVIII.8; Giovanni Becatti, Problemi fidiaci, Milan,1951, 81-82</p>
<p :class="{'activeClass': idClicked == NP804 }" id="NP804" >100* <JBcom>[Athena]</JBcom> Parthenos by Timarchides, dedicated in a gymnasium, ca. 150 B.C., attested in a fragmentary inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> lines 56-57; Chr. Habicht, “Eine Liste von Hieropoioi”, AM 97 (1982) 178-180 with note 32</p>
<p :class="{'activeClass': idClicked == NP805 }" id="NP805" >101* Athena Nike crowning a priestess of Athena Polias who holds a temple key, document relief, ca. 350-300 B.C. - - - Berlin inv. 881 = K 104; Blümel, C. 1966, 79 no. 92, fig. 126; Lawton, C. L. 1995, 151-152 no. 164, pl. 86</p>
<p :class="{'activeClass': idClicked == NP806 }" id="NP806" >102* Athena Parthenos and a man being crowned by a Nike, relief frags. Acr. 2437 + 3001; E. Raftopoulou, “Une statue argienne d'Athena,”, BCH 90 (1966) 61 note 1, fig. 14</p>
<p :class="{'activeClass': idClicked == NP807 }" id="NP807" >ATHENA PHRATRIA: see Zeus Zeu93*- Zeu95*</p>
<p :class="{'activeClass': idClicked == NP808 }" id="NP808" >ATHENA POLIAS: for the epithet “Polias” see Nagy, B. 1991, 294-296 Temples: see Athena Ath20* (Old Temple); Erechtheion</p>
<p :class="{'activeClass': idClicked == NP809 }" id="NP809" >103* Cult statue with golden owl, golden aegis, golden gorgoneion and golden phiale: IG II² 1424 A lines 362-326; Paus. 1.26.6 (indirectly alluding to the epithet Polias); xoanon set up by Erichthonios, Apoll. 3.14.6; Frazer, J. G. 1913, II, 340-341, 570-580; Kroll, J. H. 1982, 65-76, pl. 11; early 8th c. B.C., Romano, I. B. 1981, 42-57; cf. Athena Ath47**, 54</p>
<p :class="{'activeClass': idClicked == NP810 }" id="NP810" >104* Conjectural temporary housing for the cult statue after 479 B.C. on the site later occupied by the Erechtheion eastern foundations: Boersma, J. S. 1970, 181 no. 49</p>
<p :class="{'activeClass': idClicked == NP811 }" id="NP811" >105* Golden peritrachelidia (crescent-shaped pectorals) and a golden rhyton dedicated by Roxane, wife of King Alexander, 319/318 B.C.?: IG II² 1492 lines 46-57; Schenkungen hellenistischer Herrscher; Part I, 1995, 19-20 and Part II, vol. 1, 2000, 39-40, 55, and Part II, vol. 2, 2000, 93,151; Harris, D. 1995, 140 no. 141, 179 no. 358, 229, 234-235; P. Themelis, “Macedonian dedications on the Akropolis,” in Macedonians in Athens 2003, 164-168</p>
<p :class="{'activeClass': idClicked == NP812 }" id="NP812" >106* Base of a statue dedicated by the Athenians to Athena Polias to thank a donor for beautifying the Akropolis, mid-2nd c. A.C. IG II² 3193</p>
<p :class="{'activeClass': idClicked == NP813 }" id="NP813" >107* -108* Boundary stones “to mark measured tracts of land sacred to Athena Polias and belonging to her priestly clan, the Eteoboutadai,” 125-175 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204090" target="_blank">Agora I 4090</a>, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204090b" target="_blank">Agora I 4090b</a>; Meritt, B. D. 1968, 292-294 nos. 35-36, pl. 84; Ritchie Jr., C. E., 1985, 99-108, 673-675, figs. 15-16; Agora XIX, 26-27 H23-H24, pl. 1; SEG 51 (2001) no. 156; S. B. Aleshire provided the information about the date in 1993</p>
<p :class="{'activeClass': idClicked == NP814 }" id="NP814" >109* Poros limestone boundary stone of Athena Polias' Sacred House (oikia), 5th or early 4th c. B.C. - - - IG I³ 1051; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202221" target="_blank">Agora I 2221</a>; SEG 10 (1949) no. 364; Ritchie Jr., C. E., 1985, 83-87, pl. 15, fig. 12; Agora XIX, 26 H21, pl. 1</p>
<p :class="{'activeClass': idClicked == NP815 }" id="NP815" >110* Precinct by the Long Walls mentioned in the “Restoration Decree” : IG II² 1035 line 48</p>
<p :class="{'activeClass': idClicked == NP816 }" id="NP816" >111* Athena Polias and All The Gods, dedication on a base - - - IG II² 2802</p>
<p :class="{'activeClass': idClicked == NP817 }" id="NP817" >112* Athena Polias and Erechtheus, cult: Hdt. 5.82; Kron, U. 1976, 40-42; probably = Ath118* below See also: Athena Ath47**, Ath54*, Ath65*, Ath101*; Erechtheion; Habryllis; Julia Domna; Lysimache (2); Megiste (3); Pantainos; Penteteris; Philippe; Philotera; Spartokos II; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM69">69</a></p>
<p :class="{'activeClass': idClicked == NP818 }" id="NP818" >113* ATHENA PROMACHOS on the Akropolis, ca. 450 B.C.?: IG I³ 435 with addendum; SEG 51 (2005) no. 39; Paus. 1.28.2, 9.4.1; Pollitt, J. J. 1990, 62-63, 250; represented on Athenian imperial bronze coins, Price, J. M. and B. L. Trell 1977, 68 fig. 130, 76 figs. 130-131, 77; Gauer, W. 1968, 38-9, 103-5; Tolle-Kastenbein, R. 1980, 58; removal to Constantinople perhaps in 465-470 A.D., Agora XXIV, 76-77</p>
<p :class="{'activeClass': idClicked == NP819 }" id="NP819" >Foundations and base: W. B. Dinsmoor, “Two Monuments on the Athenian Acropolis,” in Orlandos Charisterion, IV, 145-151, pls. 48-49; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM86">86</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM108">108</a></p>
<p :class="{'activeClass': idClicked == NP820 }" id="NP820" >Ancient names for the statue:</p>
<p :class="{'activeClass': idClicked == NP821 }" id="NP821" >Called the great bronze Athena in the earliest mention: D. 19.272 Called the bronze statue of Athena by Pheidias: Paus. 1.28.2</p>
<p :class="{'activeClass': idClicked == NP822 }" id="NP822" >Called Promachos, 5th c. A.C.: IG II² 4225 line 4; SEG 29 (1979) no. 183 </p>
<p :class="{'activeClass': idClicked == NP823 }" id="NP823" >ATHENA SOTEIRA: see Zeus Zeu103*</p>
<p :class="{'activeClass': idClicked == NP824 }" id="NP824" >114* ATHENA THEMIS, Olephoros' theater seat - - - IG II² 5103</p>
<p :class="{'activeClass': idClicked == NP825 }" id="NP825" >115* ATHENA  (epithet not preserved), dedication (?), before 350 B.C., basilica on the Ilissos (Plan 2 eta—6) — IG II² 4323 A; E. Preuner, “Aus alten Papieren,” AM 46 (1921) 1-2; Threatte, L. 1980, 274 no. 30</p>
<p :class="{'activeClass': idClicked == NP826 }" id="NP826" >116* ATHENA and ABDEROS, the eponymous hero of Abdera, honorary decree for</p>
<p :class="{'activeClass': idClicked == NP827 }" id="NP827" >Dioskourides of Abdera, document relief, 346/5 B.C., Akr. IG II² 218; SEG 31 (1981) no. 74</p>
<p :class="{'activeClass': idClicked == NP828 }" id="NP828" >ATHENA and APOLLO: see Apollo Apo84*</p>
<p :class="{'activeClass': idClicked == NP829 }" id="NP829" >ATHENA and ASKLEPIOS: see Athena Ath69*</p>
<p :class="{'activeClass': idClicked == NP830 }" id="NP830" >117* ATHENA, DEMOS, EUPHRON of SIKYON, HORSE and GROOM, document relief with honorary decrees, 323/2 and 318/7 B.C., one copy to be set up beside Zeus Soter, found 1891 in the railroad trench north of the Stoa of Attalos - - - NM 1482, IG II² 448; SEG 44 (1994) no. 43; Lawton, C. L. 1995, 107/108 no. 54, pl. 28 and General Index s.v. Euphron; Kaltsas, N. 2002, 236 no. 495, ph.; C. L. Lawton, op. cit (under Arybbas), 126-127, fig. 2</p>
<p :class="{'activeClass': idClicked == NP831 }" id="NP831" >118* ATHENA and ERECHTHEUS, cult: Iliad II 546-551; Kron, U. 1976, 32-37, 40-42; probably = Athena Polias and Erechtheus (Athena Ath112* above)</p>
<p :class="{'activeClass': idClicked == NP832 }" id="NP832" >ATHENA and HEPHAISTOS: see Hephaistos at Kolonos Agoraios</p>
<p :class="{'activeClass': idClicked == NP833 }" id="NP833" >119* ATHENA, HERAKLES, DEMOS, document relief, 350-325 B.C., between Philopappos and Pnyx hills at Hag.Dimitrios Loumbardiaris (Plan 1 theta -1, no. 112) - - - NM 2407, IG II² 4630; Kron, U. 1979, 49-63, pl. 7; Lawton, C. L. 1995, 139 no. 133, pl. 71; Herakles Alexikakos crowning Athena, Tagalidou, E. 1993, 51-54, 213-215 no. 20, pl. 11</p>
<p :class="{'activeClass': idClicked == NP834 }" id="NP834" >120* ATHENA, HERO ON HORSEBACK and PHILISKOS of Sestos, document relief, 355/4 B.C., near the Lysikrates Mon. (Plan 1 eta—8) - - - NM 1474, IG II² 133; SEG 22 (1967) no. 91; Lawton, C. L. 1995, 96-97 no. 30, pl. 16 and General Index s.v. Philiskos; Kaltsas, N. 2002, 236-237 no. 497, ph.</p>
<p :class="{'activeClass': idClicked == NP835 }" id="NP835" >121* ATHENA, KERKYRA (?), DEMOS (?), document relief, 375/4 B.C. (?), Akropolis</p>
<p :class="{'activeClass': idClicked == NP836 }" id="NP836" >South Slope, west of the theater NM 1467, IG II² 97; SEG 31 (1981) no. 65; the figures are more reasonably identified as Athena, Hera and Zeus, Lawton, C. L. 1995, 126-127 no. 96, pl. 50; Kaltsas, N. 2002, 238-239 no. 503, ph.</p>
<p :class="{'activeClass': idClicked == NP837 }" id="NP837" >ATHENA AND MARSYAS: H. A. Weis, “The ‘Marsyas' of Myron: Old Problems and New Evidence,” AJA 83 (1979) 214-219, pls. 30-31</p>
<p :class="{'activeClass': idClicked == NP838 }" id="NP838" >122* Sculpture by Myron, mid-5th c. B.C., on the Akropolis: Paus. 1.24.1; represented on Athenian imperial bronze coins, G. Daltrop, Gruppo mironiano di Atena e Marsia nei Musei Vaticani, Vatican City, 1980, 13-14, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP839 }" id="NP839" >123* Finlay krater, unfinished marble krater relief NM 127; Kaltsas, N. 2001, 91 no. 157, ph.</p>
<p :class="{'activeClass': idClicked == NP840 }" id="NP840" >124* Two-sided fragmentary relief, helmeted Athena with lance in front, Marsyas and youth on reverse, 2nd c. A.C., Academy area 38 m. southeast of the prehistoric apsidal house (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM457">457</a> below) - - - 3rd Ephoria M 3112: Daux, G. 1959, 582, figs. 15-16; Froning, H. 1981, 52-53; a friendly centaur represented, not Marsyas, E. Mathiopoulos, “Das fragment eines attischen Doppelreliefs,” in Festschrift Wr N. Himmelmann 1989, 151-159, fig. 1, pl. 29</p>
<p :class="{'activeClass': idClicked == NP841 }" id="NP841" >125* ATHENA, NIKE crowning a CHARIOTEER in a four-horse chariot, relief, ca. 400 B.C., from Consul Logothetes' house BM cat. no. 814 (Smith, A. H. 1892, 373) + British School of Athens inv. no. S.24; E. G. Pemberton, “Dedications by Alkibiades and Thrasyboulos,” BSA 76 (1981) 310-313, pl. 54 a</p>
<p :class="{'activeClass': idClicked == NP842 }" id="NP842" >126* ATHENA, NIKE and 3 OTHERS, architectural relief, 400-350 B.C., cistern in the Ilissos river bed near the Kallirrhoe spring below the church of Hag. Photini (Plan 2 eta - 6)- - - NM 2668; H. Mobius, “Das Metroon in Agrai und sein Fries,” AM 60/61 (1935-1936) 250-253, pl. 91; A. Peschlow-Bindokat, “Demeter und Persephone in der attischen Kunst des 6. bis 4. Jahrhunderts v. Chr. "Jahrbuch 87 (1972) 122-123, 152 R 37; Kaltsas, N. 2002, 209 no. 422</p>
<p :class="{'activeClass': idClicked == NP843 }" id="NP843" >127* ATHENA, OLIVE TREE and HERO, document relief with accounts of the Treasurers of Athena for the year 409/8 B.C. - - - Louvre Ma 831, IG I³ 375 with addenda; LIMC IV, 1988, 941 no. 75, s.v. Erechtheus (U. Kron); Lawton, C. L. 1995, 86-87 no. 8, pl. 5; SEG 50 (2000) no. 15. <JBcom>[Heads and hands deliberately mutilated by Christians (?)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP844 }" id="NP844" >128* ATHENA and PANDROSOS, dedication on the statue base of Phila, the Arrhephoros, 2nd c. B.C., behind the Propylaia — IG II² 3472; F GR HIST III B, 101, Philochoros 328 F 10 with suppl. Vol. I., 276-277</p>
<p :class="{'activeClass': idClicked == NP845 }" id="NP845" >129* ATHENA and so-called PELOPONNESOS and ZEUS, document relief, 362/361 B.C., Akropolis - - - IG II² 112, NM 1481; H. Jung, “Zur Eirene des Kephisodot,” Jahrbuch 91 (1976) 123 -126, fig. 6; the figure is Hera not Peloponnesos, Lawton, C. L. 1995, 94 no. 24, pl. 13; Kaltsas, N. 2002, 235 no. 493, ph.</p>
<p :class="{'activeClass': idClicked == NP846 }" id="NP846" >ATHENA and POSEIDON</p>
<p :class="{'activeClass': idClicked == NP847 }" id="NP847" >130* Cult in the sanct. of Demeter and Kore on the Sacred Way: Paus. 1.37.2; S. Dow and R. F. Healey, S. J. 1965, 39-40</p>
<p :class="{'activeClass': idClicked == NP848 }" id="NP848" >131* Athena and Poseidon peacefully displaying the “tokens”, sculpture group on the Akr.: Paus. 1.24.3; near the altar of Zeus Polieus, Frazer, J. G. 1913, II, 301-302; perhaps opposite to the 2nd column from the east on the Parthenon north side, Stevens, G. P. 1946a, 11-12, fig. 3 no. 9, fig. 15; versions of the group represented on bronze imperial coins, Thompson, M. 1982, 166, 167, pl. 28 nos. 11, 13</p>
<p :class="{'activeClass': idClicked == NP849 }" id="NP849" >132* ATHENA and RHEBOULAS the Thracian, brother of Kotys, with two horses, document relief, 331/330 B.C., Akropolis South Slope - - - IG II² 349, NM 1476; Palagia, O. 1982,104 note 33, pl. 33:b; Lawton, C. L. 1995, 6, 60, 103-104, 135, pl. 24; SEG 51 (2005) no. 81</p>
<p :class="{'activeClass': idClicked == NP850 }" id="NP850" >ATHENA and ZEUS</p>
<p :class="{'activeClass': idClicked == NP851 }" id="NP851" >133* Athena arising from Zeus' head, sculpture, Akr.: Paus. 1.24.2; R. Thomas, “Die ‘schwebende Athena' in Basel. Bemerkungen zur Darstellung der Athenageburt im 5. Jahrhundert v. Chr.,” Jahrbuch 97 (1982) 62-63 See also: Zeus Zeu66* (Meilichios)</p>
<p :class="{'activeClass': idClicked == NP852 }" id="NP852" >ATHENA and OTHERS</p>
<p :class="{'activeClass': idClicked == NP853 }" id="NP853" >134* Athena and five women (Nymphs?) involved in a building project, document relief (?), ca. 325-300 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202495" target="_blank">Agora S 2495</a>; C. Lawton, “Four Document Reliefs from the Athenian Agora,” Hesperia 64 (1995) 123-125, pl. 35:a</p>
<p :class="{'activeClass': idClicked == NP854 }" id="NP854" >135* Athena standing with a shield (other figures not preserved), ephebic document relief, 334/333 B.C. - - - IG II² 2970; SEG 22 (1967) no. 148; Lawton, C. L. 1995, 101 no. 40, pl. 21. The restorations in lines 1-2 suggest that the ephebes' dedication is to a hero.</p>
<p :class="{'activeClass': idClicked == NP855 }" id="NP855" >136* (name missing) and Athena, dedication, 3rd-2nd c. B.C., Dipylon area -  - - IG II² 4339 b See also: Boule, Athena, man; Demos and Charites, IG II² 4775; Diogenes Euergetes, temenos; Herodoros</p>
<p :class="{'activeClass': idClicked == NP856 }" id="NP856" >ATHENAI, place-name: Iliad II 546, 549; Od. Ill 278, 307; VII 80; XI 323; Paus. 1.1.5 et passim; see Neai Athenai; Novae Athenae; Setines</p>
<p :class="{'activeClass': idClicked == NP857 }" id="NP857" >ATHENAIS, daughter of Herodes Atticus, Marcia Claudia Alkia Athenais Gavidia Latiaria: Tobin, J. 1997, Index s.v. Athenais (Panathenais)</p>
<p :class="{'activeClass': idClicked == NP858 }" id="NP858" >State burial monument inside the walls, before 157 A.D.: Philostr. VS 11.1.10; Ameling, W. 1983, I, 97 and II, 18-19, 141 no. 137</p>
<p :class="{'activeClass': idClicked == NP859 }" id="NP859" >Statue base, ca. A.D. 156, built into church foundations in the Asklepieion - - - IG II² 4073; Byrne, S. G. 2003, 125 (v)</p>
<p :class="{'activeClass': idClicked == NP860 }" id="NP860" >ATHENIAN TRIBUTE - QUOTA LISTS - - - IG I³ 259-290; displayed at the Propylaia, W. K. Pritchett, “The Location of the Lapis Primus,” GRBS 8 (1967) 113-119; SEG 42 (1992) 25</p>
<p :class="{'activeClass': idClicked == NP861 }" id="NP861" >ATHENS and MILETOS, decree concerning Miletos with document relief, 450/449 B.C. (?) or ca. 426/5 B.C., Akropolis South Slope - - - IG3 21 with addendum p. 937; Lawton, C. L. 1995, 112-113 no. 63, pl. 33; SEG 47 (1997) no. 56 (Mattingly argues <JBcom>[convincingly]</JBcom> for 426/5 B.C.)</p>
<p :class="{'activeClass': idClicked == NP862 }" id="NP862" >ATTALOS I and ATTALOS II OF PERGAMON: Kotsidu, H. 2002, 78-87, figs. 1-3, 5-7</p>
<p :class="{'activeClass': idClicked == NP863 }" id="NP863" >Attalid dedication at the Akropolis south wall, sculpture groups representing the Gigantomachia, the battle of the Amazons and Athenians, the battle with the Medes at Marathon, the destruction of the Galatians in Mysia: Paus. 1.25.2; IG II² 1035 lines 25-26; “doubtless dedicated by Attalos I,” Frazer, J. G. 1913, II, 322; Chr. Habicht, “Athens and the Attalids in the Second Century B.C.,” Hesperia 59 (1990) 562-563 with notes 8-9; Schenkungen hellenistischer Herrscher, Part 1, 1995, 66-68 and Part II, vol. 2, 2000, 232-244 with figs. 73-84, 258; A. Stewart, Attalos, Athens and the Akropolis: The Pergamene Little Barbarians,” and their Roman and Renaissance Legacy,” with an Essay on the Pedestals and the Akropolis South Wall by Manolis Korres, Cambridge, 2004</p>
<p :class="{'activeClass': idClicked == NP864 }" id="NP864" >Dionysos in the Gigantomachia group linked to Mark Antony as New Dionysos: Plu. Ant. 60.2; T. Holscher, “Die Geschlagenen und Ausgelieferten in der Kunst des Hellenismus,” Antike Kunst 28 (1985)123-128 cf. Athena Ath45*</p>
<p :class="{'activeClass': idClicked == NP865 }" id="NP865" >Attalos I, statue as Eponymous Hero, 200 B.C.: Paus. 1.5.5 + 8.1; Habicht, Chr. 1982, 109-110, 145</p>
<p :class="{'activeClass': idClicked == NP866 }" id="NP866" >Priest of Attalos I Eponymous, double theater throne (together with Diogenes Euergetes) - - - IG II² 5080; Maass, M. 1972, 19, 53-54, 92, 139, pl. 22</p>
<p :class="{'activeClass': idClicked == NP867 }" id="NP867" >Attalos I or II, headless herm - - - 1st Ephor. Rho Alpha no. 643: perhaps Attalos II at the beginning of his reign (the alpha cross bar is straight), S. N. Koumanoudes, «Έπιγράφαι έξ Αθηνών,» Deltion 25 (1970) A Meletai, 63-64 no. 13; SEG 30 (1980) no. 138</p>
<p :class="{'activeClass': idClicked == NP868 }" id="NP868" >Stoa of Attalos II with donor's inscription, ca. 144 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206135" target="_blank">Agora I 6135</a>, IG II² 3171; Agora III, 46-47 nos. 99-102; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 83-88, pls. 18-21, 26-27; Travlos, J. 1971, 505-515, figs. 636-656; Agora XIV, General Index s.v. stoas: of Attalos, pls. 54-57; compared with the Stoa of Eumenes, Korres, M. 1983, 201-205, fig. 1; Agora Guide 1990, 35. 130ff., 191, 193-194, 276 no. 130, figs. 6-9, 80-84, 130; Schenkungen hellenistischer Herrscher, Part I, 1995, 63-66, figs. 9-14; for the construction date ca. 144 B.C., see Lavall, M. L. 2002, 319</p>
<p :class="{'activeClass': idClicked == NP869 }" id="NP869" >Herm relief on the door jamb of Shop III: Agora XI, no. 234, pl. 61</p>
<p :class="{'activeClass': idClicked == NP870 }" id="NP870" >Attalos II, bronze chariot group, donor's monument in front of the Stoa of Attalos, 115 blocks of which were used in a tower of the Post-Herulian Wall, Agora XXIV,134; Schenkungen hellenistischer Herrscher, Part I, 1995, 446, figs. 169-170</p>
<p :class="{'activeClass': idClicked == NP871 }" id="NP871" >Originally dedicated to Attalos II; Agora XIV, 107; Korres, M. 2000, 320 no. 4</p>
<p :class="{'activeClass': idClicked == NP872 }" id="NP872" >Rededicated to Tiberius <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206120" target="_blank">Agora I 6120</a>; IG II² 4209; Tiberius accorded divine honors during his lifetime, E. Vanderpool, “Athens honors the Emperor Tiberius,” Hesperia 28 (1959) 86-90, pls. 10-12; SEG 17 (1960) no. 68 See also: Eumenes II; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM113">113</a></p>
<p :class="{'activeClass': idClicked == NP873 }" id="NP873" >ATTICUS, Tiberius Claudius Atticus Herodes, father of Herodes Atticus: Ameling, W. 1983, I, 18-19, 21-35 and II, 65-93 nos. 33-68; Byrne, S. G. 2003, 110-114, no. 7</p>
<p :class="{'activeClass': idClicked == NP874 }" id="NP874" >House near the theater: Philostr. VS 11.1.2, cf. 1.21.7: W. Ameling, op. cit., I, 18-19, 21-22; Trianti, I 2002, 166</p>
<p :class="{'activeClass': idClicked == NP875 }" id="NP875" >Theater seat, ca. 120 -130 A.D. - - - IG II² 5090</p>
<p :class="{'activeClass': idClicked == NP876 }" id="NP876" >Base of a statue set up by the polis of Gytheion ca. 134 A.D., near the Propylaia - - - IG II² 3596; W. Ameling, op. cit., I, 23-30 and II, 83-84 no. 54</p>
<p :class="{'activeClass': idClicked == NP877 }" id="NP877" >Three statue bases built into the church of Panagia Pyrgiotissa in a tower of the Post-Herulian Wall in the Stoa of Attalos — IG II² 3597 a-c; D. J. Geagan, “Roman Athens I,” in ANRW 11.7.1, 393</p>
<p :class="{'activeClass': idClicked == NP878 }" id="NP878" >Atticus and Vibullia Alkia, bronze statues decreed ca. 128 A.D.: IG II² 1073-4; W. Ameling, op. cit., I, 32 and II, 85-88 no. 56 lines 15-19 + 25</p>
<p :class="{'activeClass': idClicked == NP879 }" id="NP879" >ATTICUS, Titus Pomponius Atticus</p>
<p :class="{'activeClass': idClicked == NP880 }" id="NP880" >House not far from the place at the Ilissos where Boreas seized Oreithyia: Cic. Leg. 1.1.3</p>
<p :class="{'activeClass': idClicked == NP881 }" id="NP881" >Statues: Nep. Att. 3.2</p>
<p :class="{'activeClass': idClicked == NP882 }" id="NP882" >ATTIS: LIMC III, 1986, 22-23 and nos. 113, 338, 376, s.v. Attis (M. J. Vermaseren and Μ. B. de Boer)</p>
<p :class="{'activeClass': idClicked == NP883 }" id="NP883" >Head fragment - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201320" target="_blank">Agora S 1320</a>; Vermaseren, M. J. 1982, II, no. 157</p>
<p :class="{'activeClass': idClicked == NP884 }" id="NP884" >Table support statuette, 3rd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20344" target="_blank">Agora S 344</a>; ibid. no. 135, pl. 22 See also: Taurobolion altars</p>
<p :class="{'activeClass': idClicked == NP885 }" id="NP885" >AUDOLEON, king of the Paionians, bronze equestrian statue in the Agora, 284 B.C.: IG II² 654 lines 57-58; Schenkungen hellenistischer Herrscher, Part I, 1995, 70-72 and Part II, vol. 1, 2000, 145</p>
<p :class="{'activeClass': idClicked == NP886 }" id="NP886" >AUGUSTUS = C. Julius Caesar, antea C. Octavius, postea Imp. Caesar, Imp. Caesar Augustus (PIR 2nd ed., 215); T. Mavrojannis, “Apollo Delio, Atene e Augusto,” Ostraka IV (1995), 85-102</p>
<p :class="{'activeClass': idClicked == NP887 }" id="NP887" >Altars: A. S. Benjamin and A. E. Raubitschek, “Arae Augusti,” Hesperia 28 (1959) 65-85, pls. 8-9; SEG 18 (1962) nos. 73-80; SEG 22 (1967) no. 151</p>
<p :class="{'activeClass': idClicked == NP888 }" id="NP888" >Altar, found “bei den Schlangenfusslern”, i.e. at the Odeion of Agrippa façade - - - IG II² 3228 1st use after 27 B.C.: altar for Augustus 2nd use after 14 A.D.: altar for Tiberius; 3rd use ca. 132 A.D.: altar for Hadrian Olympios, SEG 18 (1962) no. 80 (d)</p>
<p :class="{'activeClass': idClicked == NP889 }" id="NP889" >Altar - - - IG II² 3229, 3281-3282; SEG 18 (1962) no. 80 (e) 1st use after 27 B.C.: Augustus - - - IG II² 3229 A 2nd use after 54 A.D.: Nero, with rasura IG II² 3229 A; 3rd use after 69 A.D.: Vespasian IG II² 3229 A = 3281 4th use after 79 A.D.: Titus IG II² 3229 B = 3282</p>
<p :class="{'activeClass': idClicked == NP890 }" id="NP890" >Graffito, first nine alphabetic numerals - - - IG II² 3229 C; Threatte, L. 1980, 59 See also: Telekles, grave mon.</p>
<p :class="{'activeClass': idClicked == NP891 }" id="NP891" >Portrait head, Roman <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/nm%203758" target="_blank">Agora NM 3758</a>; Heckler, A. 1935, 399-403 no. 2, figs. 3-4; Kaltsas, N, 2001, 316-317 no. 661, ph.</p>
<p :class="{'activeClass': idClicked == NP892 }" id="NP892" >Portrait head Acr. Museum (inv. no. not known); Vermeule, C. C. 1968, 380 no. 5</p>
<p :class="{'activeClass': idClicked == NP893 }" id="NP893" >Statue base, Augustus as the Neos Apollo - - - IG II² 3262 + 4725; SEG 42 (1992) no. 219 and 45 (1995) nos. 223,1029; D. Peppa-Delmouzou, “A Statue Base for Augustus,” AJP 100 (1979) 126-142, figs, 1-2; M. C. Hoff, “Augustus, Apollo, and Athens,” MH 49 (1992) 223 - 232; see Pandaites</p>
<p :class="{'activeClass': idClicked == NP894 }" id="NP894" >Priest's theater throne IG II² 5034; Maass, M. 1972, 116, pl. 9; SEG 47 (1997) no. 233 See also: Asklepios Ask31*; Roma and Augustus </p>
<p :class="{'activeClass': idClicked == NP895 }" id="NP895" >AULONEUS: see Dionysos Dio16*</p>
<p :class="{'activeClass': idClicked == NP896 }" id="NP896" >AURELIOS KRATES, son of Ptolemaios, statue set up by the Athenians on the Akropolis in the Asklepieion, attested by an inscribed altar, 3rd c. A.C., found at Lake Ochrid: V. Bistrakova-Grozdanova, “Trois monuments epigraphiques d'Ohrid,” Ziva Antika 20 (1970) 159-161, 165 fig. 1</p>
<p :class="{'activeClass': idClicked == NP897 }" id="NP897" >AUTOLYKOS the pancratiast, statue in the Prytaneion: Paus. 1.18.3, 9.32.8; Claude Vatin, " Un athlete athenien a Delphes: Autolycus,” in Hommages a Lucien Lerat, Paris, 1984, 842-843</p>
<p :class="{'activeClass': idClicked == NP898 }" id="NP898" >AXIOCHOS, Alkibiades' uncle, house near the Itonian Gate by the stele of the Amazon: Pl. Ax. 364-5; Delorme, J. 1960, 47</p>
<p :class="{'activeClass': idClicked == NP899 }" id="NP899" >AZESIA: see Demeter Azesia</p>
<p :class="{'activeClass': idClicked == NP900 }" id="NP900" >BAKCHEION, meeting hall of the Society: W. Dörpfeld, “Die Ausgrabungen am Westabhange der Akropolis: II B. Die Ruinen des Bakcheion,” AM 20 (1895)176-180, pl.IV</p>
<p :class="{'activeClass': idClicked == NP901 }" id="NP901" >Statutes of the Iobakchoi with reliefs of krater, boukranion, panthers, grape clusters, ante 178 A.D., inscribed on a column of the Bakcheion - - - IG II² 1368; SEG 42 (1992) no. 124</p>
<p :class="{'activeClass': idClicked == NP902 }" id="NP902" >Dining-hall: IG II² 1368 line 141</p>
<p :class="{'activeClass': idClicked == NP903 }" id="NP903" >Entrance with gateway: IG II² 1368 lines 142-143</p>
<p :class="{'activeClass': idClicked == NP904 }" id="NP904" >Inventory of the Society's possessions: IG II² 1368 line 148</p>
<p :class="{'activeClass': idClicked == NP905 }" id="NP905" >Altar with boukrania and garlands, inscr. erased, in the center of the eastern room: Schrader, H. 1896, 265-266</p>
<p :class="{'activeClass': idClicked == NP906 }" id="NP906" >BAKCHIOS, Panathenaic amphora potter, gravestone ca. 330 B.C. - - - IG II² 6320; M. A. Tiverios, «Παναθηναϊκά,» Deltion 29 (1974) A, 144-145; SEG 46 (1996) no. 283 and 49 (1999) no. 76; P. Siewert, “Literarische und Epigraphische Testimonien über ‘Kerameikos' und ‘Kerameis',” AM 114 (1999)2</p>
<p :class="{'activeClass': idClicked == NP907 }" id="NP907" >BARATHRON, place-name (from the generic term), cleft on the Hill of the Nymphs west slope into which criminals were thrown: Hdt. 7.133; Milchhöfer, A. 1891, 1-11 s.v. Barathron; Judeich, W. 1931, 126 note 3, 140 notes 2-3, 168 note 5, 390, 425 notes 2-5, Plan I B/C-5; according to Judeich's plan the barathron was approximately at the junction of Kymaion and Demophontos Sts. (Plan 2 zeta—2)</p>
<p :class="{'activeClass': idClicked == NP908 }" id="NP908" >BASILE: see Kodros</p>
<p :class="{'activeClass': idClicked == NP909 }" id="NP909" >BASILEIA: see Zeuxippos</p>
<p :class="{'activeClass': idClicked == NP910 }" id="NP910" >BASILEION, meeting-place of the Phylobasileis, beside the Boukoleion: Poll. 8.111; Judeich, W. 1931, 63, 296, 298-299</p>
<p :class="{'activeClass': idClicked == NP911 }" id="NP911" >BASILEIOS: see Stoa Basileios; Zeus Zeu13*</p>
<p :class="{'activeClass': idClicked == NP912 }" id="NP912" >BASILEUS, theater throne - - - IG II² 5037; Maass, M. 1972, 15, 16, 117 pl. 10</p>
<p :class="{'activeClass': idClicked == NP913 }" id="NP913" >BATE, deme: IG II² 2362 line 23; perhaps at Ambelokipi, Traill, J. S. 1986, 127 with Map</p>
<p :class="{'activeClass': idClicked == NP914 }" id="NP914" >BATRACHIOUN, lawcourt: F GR HIST III B, suppl. vol. I, 165 and II, 146, frg. 59 note 1; Paus. 1.28.8; possibly one of the structures under the Square Peristyle (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM346">346</a>), Agora XXVIII, 92 and Index s.v. Batrachioun (Green Court)</p>
<p :class="{'activeClass': idClicked == NP915 }" id="NP915" >BEAR, sculpture of a female bear, 4th c. B.C., Akr. Acr. 3737; “une ravissante petite ourse”, L. Kahil, “L'Artemis de Brauron: rites et mystere,” Antike Kunst 20 (1977) 94, pl. 21 nos. 6-7</p>
<p :class="{'activeClass': idClicked == NP916 }" id="NP916" >(BELLEROPHON), PEGASOS, CHIMAIRA, acroterion group and <JBcom>[possibly]</JBcom> a Nike acroterion, 425-415? B.C., Akr.: IG I³ 482 lines 2-4 under the rubric Rationes Incertae; <JBcom>[doubtably]</JBcom> assigned to the Athena Nike temple, P. N. Boulter, “The Akroteria of the Nike Temple,” Hesperia 38 (1969) 133-140, figs. 1-3, pls. 35, 36:b, 37:b; disassociated from the Athena Nike temple, Schultz, P. 2001, 18-24, fig. 10; SEG 51 (2005) no. 40</p>
<p :class="{'activeClass': idClicked == NP917 }" id="NP917" >BENDIS, sanctuary established by an association of Thracians in 261 B.C. - - - IG II² 1283; Pelekidis, C. 1979, 88-90; SEG 49 (1999) no. 162 and 51 (2001)no.11</p>
<p :class="{'activeClass': idClicked == NP918 }" id="NP918" >BEROSSOS OF BABYLON, priest-historian, 4th-3rd c. B.C., statue in a gymnasium: Plin. Nat. VII.123; cf. Paus. 10.12.9; P. Schnabel, Berossos, Berlin, 1923, 250 (testimonia)</p>
<p :class="{'activeClass': idClicked == NP919 }" id="NP919" >BLAUTE, place-name: Hsch. s.v.; Beschi, L. 1967/1968b, 519-520; see Heroes Hro12*</p>
<p :class="{'activeClass': idClicked == NP920 }" id="NP920" >BLAUTE AND KOUROTROPHOS, precinct at the entrance to the Akropolis: IG II² 5183 lines 2-3; L. Beschi, op. cit, 518-520; Ritchie Jr., C. E. 1985, 109-119, pls. 20-21, figs. 17-18; conjecturally <JBcom>[doubtably]</JBcom> located between the Akropolis and the Areopagus, Travlos, J. 1971, 2, fig. 5 no. 5; SEG 38 (1988) no. 190</p>
<p :class="{'activeClass': idClicked == NP921 }" id="NP921" >Entrance marker, 3rd c. A.C., west of the Panathenaic Way on the Akropolis northwest slope - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204572" target="_blank">Agora I 4572</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 91-92 no. 40, pl. 23</p>
<p :class="{'activeClass': idClicked == NP922 }" id="NP922" >Entrance marker, 2nd-3rd c. A.C., built into the Turkish bastion below the Nike bastion - - - IG II² 5183</p>
<p :class="{'activeClass': idClicked == NP923 }" id="NP923" >BOREAS: LIMC III, 1986,133-135,140-142, s.v. Boreas (S. Kaempf-Dimitriadou)</p>
<p :class="{'activeClass': idClicked == NP924 }" id="NP924" >Sanctuary on the Ilissos <JBcom>[right bank]</JBcom> with an altar at the crossing to Agra mentioned by Sokrates: Pl. Phdr. 229 B-C; A. Jacquemin, «ΒΟΡΕΑΣ Ο ΘΟΥΡΙΟΣ,» BCH 103 (1979) 190; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM547">547</a></p>
<p :class="{'activeClass': idClicked == NP925 }" id="NP925" >Place at the Ilissos where Boreas seized Oreithyia: Paus. 1.19.5; Neuser, K. 1982, 32 note 71, testimonia</p>
<p :class="{'activeClass': idClicked == NP926 }" id="NP926" >Sanctuary of Boreas <JBcom>[supposedly located]</JBcom> on the Areopagus: Judeich, W. 1931, 300 note 4; for the source of Judeich's error, see K. Wernicke, RE 111,1899, col. 724 lines 20-24 (Boreas)</p>
<p :class="{'activeClass': idClicked == NP927 }" id="NP927" >BOTHYNOS, place on the Sacred Way: Harp. s.v.</p>
<p :class="{'activeClass': idClicked == NP928 }" id="NP928" >BOUKOLEION, early headquarters of the Basileus, near the Prytaneion: Arist. Ath. 3.5; Agora III, nos. 582,588; Judeich, W. 1931, 63, 286, 296, 297 note 1, 298; conjecturally located on the Akropolis North Slope, Travlos, J. 1971, 2, fig. 5 no. 16; cf. Bull, architectural relief</p>
<p :class="{'activeClass': idClicked == NP929 }" id="NP929" >BOULAIOS, BOULAIA: see Artemis Art26*; Hera B.; Hestia B.; Livia; Zeus14*-17*</p>
<p :class="{'activeClass': idClicked == NP930 }" id="NP930" >BOULE, ATHENA AND MAN, document relief, ca. 330 - 320 B.C.? - - - NM 1473; LIMC III, 1986, 146 no. 1, ph., s.v. Boule (V. Komninos); SEG 41 (1991) no. 134; Lawton, C. L. 1995,142-143 no. 142, pl. 75</p>
<p :class="{'activeClass': idClicked == NP931 }" id="NP931" >BOULEUTERION: Agora III, 1957, 128-137</p>
<p :class="{'activeClass': idClicked == NP932 }" id="NP932" >1) “First Bouleuterion” founded by Theseus in the asty: Plu. Thes. 24.3; probably on the Eleusinion site, Travlos, J. 1971, 198. Testimonia for meetings of the Council in the Eleusinion, Agora III, nos. 191, 211-213</p>
<p :class="{'activeClass': idClicked == NP933 }" id="NP933" >2) “Primitive Bouleuterion,” conjectural archaic predecessor of the Old Bouleuterion,” located on the terrace south of the Tholos: Wycherley, R. E. 1978, 28-30, fig. 1; Agora Guide 1990, 66, fig. 53</p>
<p :class="{'activeClass': idClicked == NP934 }" id="NP934" >3) “Old Bouleuterion”, built after 479 B.C., in which the state cult of the Mother of The Gods was incorporated ca. 410-400 B.C.: Agora III, 128-137; Athenian Agora 1986, 52-53, figs. 32-33, 27-31; T. L. Shear, Jr., “The Persian Destruction of Athens: Evidence from Agora Deposits,” Hesperia 62 (1993) 418-427, figs. 1-4; Shear Jr., T. L., 1995, 15-171, figs. 1-5; <JBcom>[implausibly]</JBcom> restored as an early octastyle temple of the Mother of The Gods, Miller, S. G. 1995, 133-156, figs. 1-6; <JBcom>[the Old Bouleuterion” continued functioning as the Council House (see Arist. Ath. 53.4) until ca. 140 B.C. when it was torn down to make way for the Metroon]</JBcom></p>
<p :class="{'activeClass': idClicked == NP935 }" id="NP935" >Marble basin, early 5th c. B.C. IG I³ 1385 = <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204869" target="_blank">Agora I 4869</a>; SEG 46 (1996) no. 71; Agora XIV, 30, pl. 30:d; Pimpl, H. 1997, 117, 179 no.48, fig. 9:2</p>
<p :class="{'activeClass': idClicked == NP936 }" id="NP936" >Bema: Antiphon, Choreutes, 40; Agora 111,129 <JBcom>[a strange scene with two men having a private conversation on the bema]</JBcom></p>
<p :class="{'activeClass': idClicked == NP937 }" id="NP937" >4) “New Bouleuterion”, built 410-400 B.C.: Paus. 1.3.5, 1.5.1, 10.19.5; Thompson, H. A. 1937,140-160, figs. 77-87,89-94, pls. 6-8; Shear Jr., T. L. 1995, 178-189, fig. 5; <JBcom>[the “New Bouleuterion” functioned as an annex for storing archives until ca. 140 B.C. when, the Old Bouleuterion” having been torn down to make way for the Metroon, it was fitted out with benches to serve as the Bouleuterion]</JBcom></p>
<p :class="{'activeClass': idClicked == NP938 }" id="NP938" >BOULEUTERION OF THE AREOPAGUS COUNCIL: see Areopagus</p>
<p :class="{'activeClass': idClicked == NP939 }" id="NP939" >BOULEUTERION OF THE TECHNITAI: see Technitai</p>
<p :class="{'activeClass': idClicked == NP940 }" id="NP940" >BOULEUTERION on Mouseion Hill: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM248">248</a></p>
<p :class="{'activeClass': idClicked == NP941 }" id="NP941" >BOUTADAI, deme name: Plu. Moralia 841 B; along the Sacred Way near Lakiadai, Traill, J. S. 1986, 133 with Map; Siewert, P. 1982, 40 note 22, 41; see Eteoboutad priests</p>
<p :class="{'activeClass': idClicked == NP942 }" id="NP942" >BOUTES: Kron U. 1976,48,51,104,165-166; LIMC III, 1986, 152-153, s.v. Boutes (E. Simon); Kearns, E. 1989, 152-153</p>
<p :class="{'activeClass': idClicked == NP943 }" id="NP943" >Altar for the hero Boutes in the Erechtheion: Paus. 1.26.5; H. von Geisau, KP 1, 1964, 974-975 ( s.v. Butes 1); conjecturally <JBcom>[rightly]</JBcom> located in the Erechtheion east chamber <JBcom>[but the cylindrical form restored in fig. 281 is highly improbable]</JBcom>, Travlos, J. 1971, 213, fig. 281</p>
<p :class="{'activeClass': idClicked == NP944 }" id="NP944" >Boutes pictured in a battle scene in the Stoa Poikile: Zenobius IV, 28; probably in the Marathonomachia in the Stoa Poikile, Agora III, 44-45 no. 96; Boutes more likely in the Amazonomachia, Kron, U. 1976, 165-166</p>
<p :class="{'activeClass': idClicked == NP945 }" id="NP945" >Boutes and Hephaistos, double throne, 4th c. B.C., Erechtheion IG II² 5166 + 4982; Stuart, J. and N. Revett 1787, 22 (vignette), 45; Caskey, L. D. et al. 1927, 484-485, fig. 206</p>
<p :class="{'activeClass': idClicked == NP946 }" id="NP946" >BOUZYGES AND BOUZYGION: H. von Geisau, KP 1, 1964, 977 (Buzyges 1); LIMC III, 1986, 153-155, s.v. Bouzyges (C. Borker); Kearns, E. 1989, 152 s.v. Bouzyges</p>
<p :class="{'activeClass': idClicked == NP947 }" id="NP947" >Bouzyges' plough dedicated on the Akropolis: schol. Aeschin. II.78; Johrens, G. 1981, 113</p>
<p :class="{'activeClass': idClicked == NP948 }" id="NP948" >Theater thrones: see Zeus Zeu12*, Zeu109*</p>
<p :class="{'activeClass': idClicked == NP949 }" id="NP949" >Bouzygion, precinct on the Akropolis slopes: Plu. Moralia 144 B; Agora III, 177-178; conjecturally located on the Akropolis upper west slope, Travlos, J. 1971, 2, fig. 5 no. 10</p>
<p :class="{'activeClass': idClicked == NP950 }" id="NP950" >BOXMAKERS or chest makers, name of a street or district through which Sokrates walked when his daimonion saved him from colliding with a herd of pigs: Plu. Moralia 580 E; <JBcom>[the context indicates that Sokrates was walking down the western side of the Akropolis lower north slope]</JBcom></p>
<p :class="{'activeClass': idClicked == NP951 }" id="NP951" >BRAURONIA: see Artemis Art27*-30*</p>
<p :class="{'activeClass': idClicked == NP952 }" id="NP952" >BRAZEN THRESHOLD, descent to the underworld: S. OC 57 with schol. to 1590 and 1592; F GR HIST III B, suppl. vol. I, no. 325, 195, lines 6-11; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM463">463</a></p>
<p :class="{'activeClass': idClicked == NP953 }" id="NP953" >BROMIOS: see Dionysos Dio15*</p>
<p :class="{'activeClass': idClicked == NP954 }" id="NP954" >BRUTUS AND CASSIUS = M. Iunius Brutus (RE Iunius 53) and C. Cassius Longinus, bronze statues in the Agora flanking Harmodios and Aristogeiton: D.C. 47.20.4; Holscher, T. 1975, 188</p>
<p :class="{'activeClass': idClicked == NP955 }" id="NP955" >Base for a statue of Q. Caepio Brutus, 44/43 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203366" target="_blank">Agora I 3366</a>; SEG 17 (1960) no. 75; Bull. 1970 no. 242</p>
<p :class="{'activeClass': idClicked == NP956 }" id="NP956" >BRYAXIS BASE, tripod base with reliefs of horsemen and prize tripods by Bryaxis, dedicated by phylarchs for victories in the anthippasia, mid-4th c. B.C., found in situ north of the north wall of the Zeus Eleutherios stoa in the middle of an ancient thoroughfare - - - NM 1733, IG II² 3130; Thompson, H. 1937, 7, 70; Travlos, J. 1971, figs. 24-25, 102A (findspot); SEG 32 (1982) no. 250; Kaltsas, N. 2002, 254 no. 530, ph.</p>
<p :class="{'activeClass': idClicked == NP957 }" id="NP957" >BRYGOS' WORKSHOP: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM482">482</a></p>
<p :class="{'activeClass': idClicked == NP958 }" id="NP958" >BULLS, sculpture in bronze and marble:</p>
<p :class="{'activeClass': idClicked == NP959 }" id="NP959" >Bull dedicated by the Council of the Areopagus on the Akropolis: Paus. 1.24.2; Judeich, W. 1931, 241 note 1; Stevens, G. P. 1940, 19-24, figs. 1, 15Q, 16, 17, 20 Q; <JBcom>[ if Pausanias hadn't told us, who would ever have guessed that they had dedicated a bull?]</JBcom></p>
<p :class="{'activeClass': idClicked == NP960 }" id="NP960" >Bronze bull led to sacrifice, in front of the temple of Triptolemos: Paus. 1.14.4; H. A. Shapiro,, “The Marathonian Bull on the Athenian Akropolis,” AJA 92 (1988) 376 no. 1; Agora XXXI, 52</p>
<p :class="{'activeClass': idClicked == NP961 }" id="NP961" >Fragments of a marble bull, early 5th c. B.C.?: <JBcom>[doubtably]</JBcom> considered to be the Marathonian bull seen by Pausanias on the Akropolis, H. A. Shapiro, op. cit., 373/382, figs. 1-4; Neils, J. 1984, 939 no. 218; cf. Theseus and the Marathonian bull</p>
<p :class="{'activeClass': idClicked == NP962 }" id="NP962" >Torso frag., Acr. 15243; found on the Akr. north slope, formerly lying inside the Beule Gate, moved to the Acropolis Museum courtyard in 1986; moved into the Acropolis Museum Gallery V and placed in back of the Old Temple of Athena Gigantomachia pediment sculpture in 1987 <JBcom>[the placement intended to hint that the bull might belong on the other pediment of the temple, but the bull's body shows no signs of contact with a lion]</JBcom></p>
<p :class="{'activeClass': idClicked == NP963 }" id="NP963" >Muzzle and plinth frag. - - - NM 5656; Despinis, G. 2003,69</p>
<p :class="{'activeClass': idClicked == NP964 }" id="NP964" >Large architectural relief, Roman pd., reused as paving in the former church of Haghios Konstantinos Saita on the south side of Lysiou St. between Erechtheos and Mnesikleous Sts., Akropolis Lower North Slope (Plan 1 delta—7): Miller, S. G. 1970, Hesperia 39, 227-231, fig. 1B, pl. 60; tentatively connected with the Boukoleion, ibid. 230-231; kept in the outdoor storage area around the Tower of The Winds</p>
<p :class="{'activeClass': idClicked == NP965 }" id="NP965" >Bull with phallos on his back, architectural (?) relief - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201881" target="_blank">Agora S 1881</a> (unpublished) See also: Index IV under Bull, Ox, Cow and Calf</p>
<p :class="{'activeClass': idClicked == NP966 }" id="NP966" >BUTTERFLY perched on a rose, relief on a 3-sided grave mon. of the Roman pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205868" target="_blank">Agora I 5868</a>, unpublished</p>
<p :class="{'activeClass': idClicked == NP967 }" id="NP967" >CAECILIUS METELLUS, Q., statue base, after 69 B.C., Stoa of Attalos - - - IG II² 4107; Geagan, D. J. 1967, 155</p>
<p :class="{'activeClass': idClicked == NP968 }" id="NP968" >CAESAR = C. Julius C. f. Caesar: A. E. Raubitschek, “Epigraphical Notes on Julius Caesar,” JRS 44 (1954) 65-75</p>
<p :class="{'activeClass': idClicked == NP969 }" id="NP969" >Statue base, 48 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202818" target="_blank">Agora I 2818</a>; A. E. Raubitschek, op. cit., 65-66 (F), pl. Ill; SEG 14 (1957) no. 121</p>
<p :class="{'activeClass': idClicked == NP970 }" id="NP970" >Colossal statue base, 47 B.C., Stoa of Attalos - - - IG II² 3222; A. E. Raubitschek, op. cit., 68-69 (P); D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 71;SEG 34 (1984) no.177</p>
<p :class="{'activeClass': idClicked == NP971 }" id="NP971" >Statue base <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203042" target="_blank">Agora I 3042</a>; D. J. Geagan, op. cit., 71 note 7</p>
<p :class="{'activeClass': idClicked == NP972 }" id="NP972" >CAESAR, GAIUS</p>
<p :class="{'activeClass': idClicked == NP973 }" id="NP973" >Portrait head, Royal Gardens (Plan 2 zeta—6/7) - - - NM 3606; Pollini, J. 1987, 43-45, 95 no. 3, pl. 5</p>
<p :class="{'activeClass': idClicked == NP974 }" id="NP974" >Portrait head from a relief, with Christian cross graffito on forehead, northwest Athens near the intersection of Lenormant and Iphigeneias Sts. (Plan 3 alpha—6)- - - NM 3665; Vermeule, C. C. 1968, 383 no.1; Lucius rather than Gaius Caesar, A. Datsouli-Stavridi, «Παιδικά και νεανικά πορτραΐτα τής Ρομαιοκρατΐας; στο Έθηνικό Άρχαιολογικό Μουσείο Αθηνών,» ΑΑΑ 13 (1980) 319-322, fig. 3</p>
<p :class="{'activeClass': idClicked == NP975 }" id="NP975" >C. Caesar as the Neos Ares, statue base, 2 B.C., near the church of H. Dimitrios tou Katiphori (Chr.Mon. CM59*) - - - IG II² 3250 with add. page 349; SEG 47 (1997) no. 219; K. Moller, Gotterattribute in ihrer Aufwendung auf Augustus, Idstein, 1985, 4-5, 114-121, 168-169; Pollini, J. 1987, 72-73 with note 145; located in the temple of Ares, Hartswick, K. J. 1990, 267-269, 270; M. C. Hoff, “An Equestrian Statue of Lucius Caesar in Athens,” AA 2001, 596-597, fig. 15</p>
<p :class="{'activeClass': idClicked == NP976 }" id="NP976" >CAESAR, L. = L. Julius Caesar (PIR, 2nd ed., 222)</p>
<p :class="{'activeClass': idClicked == NP977 }" id="NP977" >Statue base, Stoa of Attalos - - - IG II² 3252; Hanson, C. and F. P. Johnson 1946, 397 See also: Roman Agora</p>
<p :class="{'activeClass': idClicked == NP978 }" id="NP978" >CALENDAR (parapegma), 4th c. B.C., Pompeion - - - IG II² 2782; W. K. Pritchett and B. L. Van der Waerden, “Thucydidean Time-Reckoning and Euctemon's Seasonal Calendar,” BCH 85 (1961) 40; B. L. van der Waerden, “Greek Astronomical Calendars, I: The Parapegma of Euctemon,” Archive for the History of Exact Sciences 29 (1984) 103-106</p>
<p :class="{'activeClass': idClicked == NP979 }" id="NP979" >CALYDONIAN (?) BOAR, sculpture on the Akr.: Paus. 1.27.6</p>
<p :class="{'activeClass': idClicked == NP980 }" id="NP980" >CARACALLA = M. Aurelius Antoninus</p>
<p :class="{'activeClass': idClicked == NP981 }" id="NP981" >Head - - - Acr. ( inv. no. ?); Vermeule, C. C. 1968, 299, 400 no. 1; the head belongs to a group of 6 acrolithic statues dedicated by Caracalla, Catling, H. W. C. 1984/1985, 6</p>
<p :class="{'activeClass': idClicked == NP982 }" id="NP982" >Small portrait bust, 31 Ermou St. near the intersection with Phokionos St. (Plan 1 beta—8/9): Dontas, G. S. 1969, 73-74 fig. 4; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM510B">510B</a></p>
<p :class="{'activeClass': idClicked == NP983 }" id="NP983" >CARACALLA ( name erased), GETA (name erased) and JULIA DOMNA, dedication, 211/212 A.D. IG II² 3416; A. N. Oikonomides, “Five Athenian</p>
<p :class="{'activeClass': idClicked == NP984 }" id="NP984" >Inscriptions,” BASP 21 (1984) 183-184 no. 4; SEG 34 (1984) 186-187 See also: Septimius Severus</p>
<p :class="{'activeClass': idClicked == NP985 }" id="NP985" >CENTAURS</p>
<p :class="{'activeClass': idClicked == NP986 }" id="NP986" >Centaur family, copy of a picture by Zeuxis: Lucian, Zeux. 3-7, 12; Geschwantler, K. 1978, 1482-1483 no. 6; LIMC VIII, 1997, 698-699 no. 326, s.v. Kentauroi et Kentaurides (L. Palaiokrassa)</p>
<p :class="{'activeClass': idClicked == NP987 }" id="NP987" >Centauromachy, picture of the fight between the centaurs and the Lapiths in the Theseion: "Theseus has already killed a centaur,” Paus. 1.17.2; Brommer, F. 1982, 109</p>
<p :class="{'activeClass': idClicked == NP988 }" id="NP988" >Centauromachy, relief frag., Akr. northwest slope south of the Eleusinion - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20866" target="_blank">Agora S 866</a>, unpublished; <JBcom>[perhaps an architectural relief]</JBcom></p>
<p :class="{'activeClass': idClicked == NP989 }" id="NP989" >CHABRIAS, son of Ktesippos: Develin, R. 1989, 448 no. 556</p>
<p :class="{'activeClass': idClicked == NP990 }" id="NP990" >State-financed burial monument costing a thousand drachmas, 357/6 B.C., on the Road to the Academy: Ath. IV.165 F; Paus. 1.29.3; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM397">397</a></p>
<p :class="{'activeClass': idClicked == NP991 }" id="NP991" >House: Hyp. FR. 137</p>
<p :class="{'activeClass': idClicked == NP992 }" id="NP992" >Base of a bronze honorary statue, ca. 375 B.C., to be set up in the Agora - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20994" target="_blank">Agora I 994</a>+; SEG 44 (1994) no. 162; Agora III, 209; A. P. Burnett and Colin N. Edmonson, “The Chabrias Monument in the Athenian Agora "Hesperia 30 (1961) 74-91, figs. 1-3, pls. 11:a,b and 12</p>
<p :class="{'activeClass': idClicked == NP993 }" id="NP993" >CHAIRION, base of a statue dedicated to Athena by his son Alkimachos, 520-510 B.C., Akr. - - - IG I³ 618 = IG I² 663; SEG 47 (1997) no. 87 <JBcom>[Mistakenly]</JBcom> associated with the "scribe" - - - Acr. 629; Schrader, H. 1939, no. 309, fig. 204, pl. 132; Raubitschek, A .E. 1949, no. 6; disassociated by W. Peek in 1953, SEG 14 (1957) no. 12 D6, and, independently, in 1992 by I. Trianti, «Παρατηρήσει σς δυο ομάδες γλυπτών του τέλος του 6ου αιώνα από την Ακρόπολη,» in Coulson et al. 1994, 83-86, figs. 1-8; the connection between the statue base and Acr. 629 defended, Keesling, C. M. 2003, 67, 182-185, 210-212, fig. 59</p>
<p :class="{'activeClass': idClicked == NP994 }" id="NP994" >CHAIRIPPOS, statue and state-financed burial monument for valour at Munichyia, 285 B.C., Dipylou St. near Leokoriou St. (Plan 2 epsilon—3)— IG II² 5227 A; N. Kyparissis and W. Peek, “Zwei attische Kriegergraber,” AM 57 (1932) 146-150, Beilage XXXV,2; SEG 25 (1971) no. 236; Shear Jr., T. L., 1978, 83</p>
<p :class="{'activeClass': idClicked == NP995 }" id="NP995" >CHALKO, place where bronze was sold at the Hephaisteion: Anecdota Graeca, 1914, 316 lines 23-24, s.v χαλκώ; Agora III, 99 no. 283</p>
<p :class="{'activeClass': idClicked == NP996 }" id="NP996" >CHALKODON of Euboia, father of Elephenor, heroon at the Peiraic Gate: Plu. Thes. 27.3 + 35.3; cf. Paus. 8.15.6, 9.19.3; LIMC III, 1986, 187-188, Chalkodon (E. Simon); Kearns, E. 1989, 206</p>
<p :class="{'activeClass': idClicked == NP997 }" id="NP997" >CHALKOSTHENES' WORKSHOP for terracotta statuary, in the Kerameikos: Plin. Nat. 35.155; F GR HIST III B, suppl. vol. 1, 269 with note 9</p>
<p :class="{'activeClass': idClicked == NP998 }" id="NP998" >CHALKOTHEKE, storeroom (arsenal) on the Akropolis for military and cult equipment listed in inventories dating from 371/0 to 321 B.C.: IG II² 1424A (Add.) +; L. La Folette,“The Chalkotheke on the Athenian Akropolis,” Hesperia 55 (1986) 75-87, figs. 1-4, pls. 20-24; SEG 36 (1986) no. 208; the building west of the Parthenon is usually <JBcom>[deeply doubtably]</JBcom> identified as the Chalkotheke; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM54">54</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM64">64</a></p>
<p :class="{'activeClass': idClicked == NP999 }" id="NP999" >CHAMAIZELOS: see Poseidon Chamaizelos </p>
<p :class="{'activeClass': idClicked == NP1000 }" id="NP1000" >CHARIDOTES: see Hermes Hrm29*</p>
<p :class="{'activeClass': idClicked == NP1001 }" id="NP1001" >CHARIOT dedication of Pronapes, bronze, 450-440 B.C., on a base built into the Parthenon west door - - - IG I 2 880 = IG II² 3123; Acr. 13172; SEG 40 (1990) no. 26; Korres, M. 2000, 296-312, figs. 2-27, 19-25; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM98">98</a></p>
<p :class="{'activeClass': idClicked == NP1002 }" id="NP1002" >CHARIOT AND FETTERS, memorials of victory over Boeotians and Chalkidians in 507/506 B.C., Akr.: Judeich, W. 1931, 3-4, 68, 216 with fig. 22, 234, 236-239; Meiggs, R. and D. M. Lewis 1969, 28-9 no. 15; SEG 40 (1990) no. 24</p>
<p :class="{'activeClass': idClicked == NP1003 }" id="NP1003" >The bronze chariot: Korres, M. 2000, 312,314</p>
<p :class="{'activeClass': idClicked == NP1004 }" id="NP1004" >First set up ca. 505 B.C. with an inscribed Eleusinian limestone base — IG I³ 501 = IG I² 394 II</p>
<p :class="{'activeClass': idClicked == NP1005 }" id="NP1005" >Renewed ca. 455 B.C. with an inscribed Pentelic marble base — IG I³ 501 = IG I² 394 I; seen on the left as you enter the Propylaia, Hdt. 5.77; seen near Athena Promachos, Paus. 1.28.2; no reason to date the replacement as late as 455 B.C., Keesling, C. M. 1995, 27-28 with note 62; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM116">116</a></p>
<p :class="{'activeClass': idClicked == NP1006 }" id="NP1006" >The fetters, suspended from walls burnt by the Medes, opposite the megaron facing west.: Hdt. 5.77; Bancroft, S. 1980, 5-7, 15 with note 30; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM58">58</a></p>
<p :class="{'activeClass': idClicked == NP1007 }" id="NP1007" >CHARITES: Habicht, Chr. 1982, 87-93; LIMC ill, 1986, 191-203, Charites (E. B. Harrison)</p>
<p :class="{'activeClass': idClicked == NP1008 }" id="NP1008" >Sanctuary at the Akropolis entrance: schol. Ar. Nu. 773; <JBcom>[doubtably]</JBcom> associated with Athena Nike, Beschi, L. 1967/1968b, 535-536; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM91">91</a></p>
<p :class="{'activeClass': idClicked == NP1009 }" id="NP1009" >Three draped Charites by Sokrates son of Sophroniskos seen by Pausanias after he saw the Propylaia northwest wing: Paus. 1.22.8, 9.35.2-3 + 7; Agora XI, 122-3; Pollitt, J. J. 1990, 74-75; possibly on New Style silver tetradrachms, 122/1 B.C., Herzog, H. 1996, 36-39</p>
<p :class="{'activeClass': idClicked == NP1010 }" id="NP1010" >Statues in the sanct. of the Muses at the Academy: D.L. 4.1; F GR HIST III B, 159, Philochoros 328 F 224 with suppl. vol. I., 589-591; Billot. M.-F. 1989, 742-744</p>
<p :class="{'activeClass': idClicked == NP1011 }" id="NP1011" >Charites and Artemis Epipyrgidia, theater throne of the priest who is also Pyrphoros - - - IG II² 5050; cf. Maass, M. 1972, 122, pl. 13; Clinton, K. 1974, 94-95 See also; Aphrodite Aph29*-30*; Demokratia; Demos; Hekataia: Hekate herm with Charites (2); Hermes Hrm29*, Hrm40*; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM89">89</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM91">91</a></p>
<p :class="{'activeClass': idClicked == NP1012 }" id="NP1012" >CHARIXENOS the Milesian, grave relief, after 86 B.C., Kerameikos — NM 1236; made soon after the Athenians resumed grave monument production in the 1st c. B.C., A. Rugler, “Das Grabmal des Philetos,” AM 104 (1989) 222-223 with notes 12-16, esp. note 21, pl. 39:2</p>
<p :class="{'activeClass': idClicked == NP1013 }" id="NP1013" >CHARMIDES, house beside the Olympieion where Alkibiades profaned the Mysteries: And. 1.16; W. K. Pritchett, “The Attic Stelai, Part I,” Hesperia 22 (1953) 230</p>
<p :class="{'activeClass': idClicked == NP1014 }" id="NP1014" >CHARON: see Ariadne; Lysimachides, grave relief </p>
<p :class="{'activeClass': idClicked == NP1015 }" id="NP1015" >CHLOE: see Demeter Chloe; Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP1016 }" id="NP1016" >CHLOE THEMIS, ERSEPHOROI, theater seat - - - IG II² 5098; Threatte, L. 1980, 127-128, 326</p>
<p :class="{'activeClass': idClicked == NP1017 }" id="NP1017" >CHOLARGOS, trittys name: IG I² 900; Siewert, P. 1982, 14 with note 69 </p>
<p :class="{'activeClass': idClicked == NP1018 }" id="NP1018" >CHOREIOS: see Antinoos</p>
<p :class="{'activeClass': idClicked == NP1019 }" id="NP1019" >CHREMONIDES, statue base, ca. 265 B.C., built into the Akropolis north wall — SEG 25 (1971) no. 207; Η. I. Immerwahr, “Five Dedicatory Inscriptions from the North Wall of the Acropolis,” Hesperia 11 (1942) 344-347 no. 3, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1020 }" id="NP1020" >CHRISTIAN MONUMENTS TO THE YEAR 1000 ( Chr. Mon. CM1*-35*)</p>
<p :class="{'activeClass': idClicked == NP1021 }" id="NP1021" >1* ARCHITECTURAL BLOCKS and RELIEFS from Early Christian and Byzantine churches, area of Hadrian's Library and Tower of the Winds - - - Byzantine Museum inv. nos. 3145-3179; M. Hadzidakis, «Βυζαντινόν Μουσεΐον (1961),» Deltion 17 (1961-62) B Chr., 8 no. 1, fig. 4:a; <JBcom>[I do not know which of these blocks predate the year 1000]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1022 }" id="NP1022" >2* ARCHITECTURAL BLOCKS with carved ornament from Byzantine churches of Athens, collected from the Akropolis archaeological zone - - - Byzantine Museum inv. nos. 4143-4173; M. Hadzidakis, «Βυζαντινόν Μουσεΐον,» Deltion 19 (1964) B1 Chr., 19 no. 1; <JBcom>[I have not seen and do not know which, if any, of these blocks predate the year 1000]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1023 }" id="NP1023" >3* ARCHITECTURAL FRAGMENTS on the Akropolis, 7th-8th c.: G. A. Sotiriou, «Ή Βυζαντινή γλυπτική τής Έλλάδος κατά τον 7ον και 8ον αιώνα,» ΑΕ 1937, I, 176 with fig. 1, 179 with fig. 10, 181-182 with fig. 15</p>
<p :class="{'activeClass': idClicked == NP1024 }" id="NP1024" >4* BAPTISTERY in the Tower of the Winds (Plan 1 delta—6, no. 70): Freeden, J. von 1983, 16-18</p>
<p :class="{'activeClass': idClicked == NP1025 }" id="NP1025" >5* BASILICA AT THE ASKLEPIEION with a baptistery in the cave spring house (Asklepios Ask27*): I. N. Travlos, “Ή Παλαιοχριστιανική Βασιλική τού Άσκληπιείου των Άθηνών,” ΑΕ 1939-1941, 34-68, figs. 1-21; <JBcom>[doubtably]</JBcom> assigned to the Hag. Anargyroi, Travlos, J. 1971, 128; perhaps dedicated to Hag. Andreas, Creaghan, J. S., S. J. and A. E. Raubitschek 1947, 4, 29 no. XI, pl. Ill; Aleshire, S. B. 1989, 19-20; dated after 530 A.D., Tanoulas, T. 1997, Vol. I, 270, 273; Sironen, E. 1997, 327-328, no. 33 (Hag. Andreas); see Asklepios Ask4*, Chr. Mon. CM38**</p>
<p :class="{'activeClass': idClicked == NP1026 }" id="NP1026" >6* BASILICA AT THE OLYMPIEION PROPYLON: <JBcom>[doubtably]</JBcom> dated to the 5th c., I. N. Travlos, «Άνασκαφικαί 'Έρευναι παρά τό Όλυμπιεΐον,» Praktika 1949, 26-28, 36-43, figs. 2, 10-13. <JBcom>[No earlier than the 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1027 }" id="NP1027" >7* BASILICA AT THE SITE OF HAG. THOMAS, east of the Stoa of Attalos on Vrysakiou St. north of Kladou St.(Plan 1 gamma-4): M. Hadzidakis, «Άνασκαφή 'Αγίου Θωμά,» Deltion 29 (1973-1974) B1 Chr.,184-192, figs. 2-6, pls. 127-129 alpha-beta; H. W. Catling, “Archaeology in Greece 1979-1980,” in AR 1979/1980, 12 <br> 1) Remains of a large Late Roman or Early Christian building with figured mosaic floor <br> 2) The first church, a three-aisled basilica, 6th-7th c., with wall paintings  <br> 3) The second church probably 9th-10th c. <br> 4) the third church, Hag. Thomas, 17th c., demolished ca. 1834 by Prince Kantakouzenos: Touloupa, E. 1992, 593 with note 1, 598, 599</p>
<p :class="{'activeClass': idClicked == NP1028 }" id="NP1028" >8* BASILICA AT THE THEATRE OF DIONYSOS, using the eastern parados retaining wall for its north wall and the orchestra for the aithrion, 6th c.: I Travlos, «Η παλαιοχριστιανική βασιλική τού Διονυσιακού θεάτρου,» ΑΕ 1953-54, II, 301-310, figs. 1-5; Sironen, Ε. 1997, 124 with notes 38-39</p>
<p :class="{'activeClass': idClicked == NP1029 }" id="NP1029" >9* BASILICA IN THE ERECHTHEION: Caskey, L. D. et al. 1927, 492-523; established at the beginning of the 7th c. at the earliest, Tanoulas, T. 1997, I, 270 with note 3</p>
<p :class="{'activeClass': idClicked == NP1030 }" id="NP1030" >An inscribed block reused for a carved slab of the iconostasis, 7th c., set up approximately in place - - - IG II² 1930; Caskey, L. D. et al. 1927, 508-511, fig. 213; A. Frantz, “From Paganism to Christianity in the Temples of Athens,” DOP 19 (1965) 202, 204, fig. 14</p>
<p :class="{'activeClass': idClicked == NP1031 }" id="NP1031" >10* BASILICA IN THE HEPHAISTEION, Haghios Giorgios ho Akamantis, 7th c.: ibid. 202-205</p>
<p :class="{'activeClass': idClicked == NP1032 }" id="NP1032" >Fragments of wall-paintings from the latest Christian phase, removed and inserted in the walls of the Church of The Holy Apostles: Agora XX, 41</p>
<p :class="{'activeClass': idClicked == NP1033 }" id="NP1033" >John Twedell's gravestone, 1799, with a Latin epitaph by Lord Elgin - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20406" target="_blank">Agora I 406</a> +; Dinsmoor, W. B. 1941,17-24, figs. 8-9; Meritt, B. D. 1946, 240-244 no. 75, ph.; between 1799 and 1933 the gravestone, itself part of an ancient block, was broken up into small pieces</p>
<p :class="{'activeClass': idClicked == NP1034 }" id="NP1034" >11* BASILICA IN THE PARTHENON dedicated to the Virgin Mary, second half of the 6th c., rededicated as a Roman Catholic church in 1206: Setton, Κ. M. 1975, III, 201 note 2; Orlandos, A. K. 1973, Introduction *31; M. Korres, “The Parthenon from Antiquity to the 19th Century,” in Parthenon 1994, 146-151, figs. 10-16, 18; Sironen, E. 1997, 167 note 131; Tanoulas, T. 1997, I, 270, 278 note 7 (favouring a date toward the end of the 6th c.)</p>
<p :class="{'activeClass': idClicked == NP1035 }" id="NP1035" >Altar with reused material - - - IG I³ 71, IG I³ 460; incorporated in an altar demolished by Botticher, K. S. Pittakis, «A' Έπιγραφαι έλληνικαι,» AE 1862, 64-73 nos. 68-72</p>
<p :class="{'activeClass': idClicked == NP1036 }" id="NP1036" >Ambo with much reused material including a block from the Konon and Timotheus Monument: M. Korres, op. cit. 148 with notes 76-77, figs. 10, 14</p>
<p :class="{'activeClass': idClicked == NP1037 }" id="NP1037" >Graffiti including ΔΕΣΠΟΙΝΑ ΑΘΗΝΩΝ, west side, on the end column from the north: Orlandos, A. K. 1973, nos. 16-17</p>
<p :class="{'activeClass': idClicked == NP1038 }" id="NP1038" >Earliest inscription dating by the era of creation, 693 A.D., west side, 4th column from the north: Orlandos, A. K. 1973, no. 34; Bull. 1979, no. 41</p>
<p :class="{'activeClass': idClicked == NP1039 }" id="NP1039" >Haghia Sophia graffito, dubitandum, west side, 6th column from the north, 3rd drum: Orlandos, A. K. 1973, no. 53 and especially the Introduction, *31</p>
<p :class="{'activeClass': idClicked == NP1040 }" id="NP1040" >Inscription recording the dedication of the Church of Haghia Sophia in the year 630 <JBcom>[allegedly]</JBcom> seen by Pittakis in the Parthenon south wall before 1821: J. Strzygowski, “Die Akropolis in altbyzantinischer Zeit,” AM 14 (1889) 274-276</p>
<p :class="{'activeClass': idClicked == NP1041 }" id="NP1041" >Fresco of two angels flanking enthroned Virgin and child, 12th c.?, south of the west doorway on the outer face of the west wall: Korres, M. 1994, 40 col. 1, fig. 15</p>
<p :class="{'activeClass': idClicked == NP1042 }" id="NP1042" >St Dionysius (St Denis) linked to the Parthenon as a pagan temple rededicated as a Catholic church in the 13th c., associations reported by Niccolo da Martoni, Liber peregrinationis ad loca sancta, 1395: W. Judeich, “Athen im Jahre 1395 nach der Beschreibung des Niccolo da Martini,” AM 22 (1897) 428-429 (sections 6,7,10), 437; Van der Vin, J. P. A. 1980, I, 45-46 and II, 616-617</p>
<p :class="{'activeClass': idClicked == NP1043 }" id="NP1043" >1) Sign of the cross made on a column by St Dionysius during the Passion of Christ</p>
<p :class="{'activeClass': idClicked == NP1044 }" id="NP1044" >2) First altar of the world made by St Dionysius after his conversion to the holy Catholic faith</p>
<p :class="{'activeClass': idClicked == NP1045 }" id="NP1045" >3) Relic of "Sancti Dyonisii de Francia" shown to Niccolo da Martoni in the Catholic church in the Parthenon</p>
<p :class="{'activeClass': idClicked == NP1046 }" id="NP1046" >Frankish bell-tower, 13th c., at the south side of the opisthodomos: Korres, M. and C. Bouras, 1983, I, 151; mostly made of blocks from the Philopappos Mon., Korres, M. 1994, 40 col 2 no. 1, 123 col 2 no. 1, fig. 17, pl. 7</p>
<p :class="{'activeClass': idClicked == NP1047 }" id="NP1047" >Church bell fragments with Latin inscr. found under the floor of the opisthodomos: P. Kavvadias, «Έπιγραφαι έκ τής Άκροπόλεως,» Deltion 5 (1889) 34</p>
<p :class="{'activeClass': idClicked == NP1048 }" id="NP1048" >Fresco "sketchy painting of the Lamb of God with a gothic black-letter inscription,” west wall outer face, north side: P. Hetherington, Byzantine and Mediaeval Greece, London, 1991, 67 See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM122">122</a></p>
<p :class="{'activeClass': idClicked == NP1049 }" id="NP1049" >12* BASILICA, 7th c., in the Roman Agora, most of which is below the northeast corner of the Fetichie Tzami mosque ( Plan 1 gamma—6, no. 113): Ionic column capitals reused (perhaps in modern times) in the mosque porch, N. Platon, Deltion 20 (1965) B1 Chr., 22 (first par.), pl. 22 alpha and gamma', dated to the 7th c., Agora XXIV, 71, 73; Choremi-Spetsieri, A. 2003, 11 fig. 15</p>
<p :class="{'activeClass': idClicked == NP1050 }" id="NP1050" >13* BASILICA ON LYKABETTOS SOUTH SLOPE, 26 Tsakaloff St. (Plan 2 epsilon-7), with sarcophagus of Bishop Klematios, 5th c. ? - - - Byzantine Museum 142; Sironen, E. 1997, 156-157 no. 83</p>
<p :class="{'activeClass': idClicked == NP1051 }" id="NP1051" >14* BASILICA, Hag. Andreou St. (Plan 1 delta—8): EMME 1,1927, 56-57, fig. 44</p>
<p :class="{'activeClass': idClicked == NP1052 }" id="NP1052" >15* BASILICA with mosaics, 5th c.?, on the Ilissos island, outside the eastward extension of the Themistoklean City Wall (Plan 2 eta—6): <JBcom>[mistakenly]</JBcom> called the Basilica of Leonides, Travlos, J. 1971, fig. 154 no. 149; tacitly accepting a late 4th-early 5th c. date, J.-P. Sodini, “Mosaiques paleochretiennes de Grece,”, BCH 94 (1970) 702 no. 2, fig. 4:d; Spiro, M. 1978, 26-36; mosaic floors, now in the Byzantine Museum, including a stork attacking a snake, M. Kazanaki-Lappa, «Η Αθήνα απο την ύστερη αρχαιότητα ως την Τουρκική κατακτηση,» in ΑΘΗΝΑΙ 2000, figs. 6-7 (superb colour photography by S. Mavrommatis and N. Panagiotopoulos); <JBcom>[the basilica is no earlier than the 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1053 }" id="NP1053" >15** "BYZANTINISCHE KIRCHE" the scanty remains detected in a building west of the Parthenon (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM54">54</a>): W. Dörpfeld, “Chalkothek und Ergane-Tempel,” AM 14 (1889) 308</p>
<p :class="{'activeClass': idClicked == NP1054 }" id="NP1054" >16* CHRISTIAN SANCTUARY in the southern area of the Agora Exc., attested by finds as follows: Christian lamps: Agora VII, General Index s.v. Christian; <JBcom>[in this volume I dated the Christian lamps much too early; Christian lamps made in Athens are no earlier than the second quarter of the 5th c., although some imports may possibly be earlier]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1055 }" id="NP1055" >Stamped Christian unguent flasks from Palestine (?), ca. 550 - ca. 650 A.D. - - - Agora Deposit O 13:4; J. W. Hayes, “A New type of Early Christian Ampulla,”, BSA 66 (1971) 245, 247, fig. 1, pl. 36:b; concentrated in a possible monastic complex, Agora XXIV, 91, pls. 72:b, 73:b</p>
<p :class="{'activeClass': idClicked == NP1056 }" id="NP1056" >Nineteen Christian dipinti and graffiti: Agora XXI, 87-88, pls. 53-54</p>
<p :class="{'activeClass': idClicked == NP1057 }" id="NP1057" >Christian monogram with Parthenos graffito on a small jug - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2025133" target="_blank">Agora P 25133</a>; ibid., 87, J4, pl. 53</p>
<p :class="{'activeClass': idClicked == NP1058 }" id="NP1058" >Sigma table fragments - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202266" target="_blank">Agora A 2266</a>, A 2293; Agora XXIV, 91</p>
<p :class="{'activeClass': idClicked == NP1059 }" id="NP1059" >17* CHRISTIAN SANCTUARY, 7th c., Areopagus north slope west and northwest of the church of Hag. Dionysios Areopagites</p>
<p :class="{'activeClass': idClicked == NP1060 }" id="NP1060" >Graveyard 7th c., at the site of the 16th c. church of Hag. Dionysios Areopagites: Travlos, J. and A. Frantz 1965, 166-168, figs. 1-2, pis 42 c-d, 43</p>
<p :class="{'activeClass': idClicked == NP1061 }" id="NP1061" >Inscribed bronze reliquary cross probably made in Jerusalem, early 7th c., east of the 7th c. graveyard - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201270" target="_blank">Agora B 1270</a>; ibid. 168 no. 14, pl. 43 b</p>
<p :class="{'activeClass': idClicked == NP1062 }" id="NP1062" >Architectural marbles, 7th-9th c., reused in the Archbishop's Palace (Chr. Mon. CM36* below) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20409" target="_blank">Agora A 409</a> +; tentatively assigned to a 7th c. church of Hag. Dionysios Areopagites, ibid. 169, pl. 44</p>
<p :class="{'activeClass': idClicked == NP1063 }" id="NP1063" >Lead seal of Theophylaktos, Bishop of Athens, 7th c., found on the Areopagus north slope, ca. 100 m. northwest of the site of the later church of Dionysios the Areopagite - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/il%20529" target="_blank">Agora IL 529</a>; V. Laurent, Le corpus des sceaux de l'empire byzantin, Paris, 1963, vol. 5, 437-8 no. 585 See also: Kornelios Onomarchos</p>
<p :class="{'activeClass': idClicked == NP1064 }" id="NP1064" >17** Church in the Propylaia southwest wing, 6th c.: Tanoulas, T. 1994a, 56, 57 (plan of the Propylaia ca. 700 A.D.)</p>
<p :class="{'activeClass': idClicked == NP1065 }" id="NP1065" >18* CHURCH (?) in the aqueduct (ex-Agoranomeion) dedicated to Athena Archegetis and Divi Augusti (Athena Ath55*) east of the Roman Agora: I. E. Volonakis, «Τα Παλαιοχριστιανικά Βαπτιστηρια της Ἐλλάδος,» Athens, 1976, 76</p>
<p :class="{'activeClass': idClicked == NP1066 }" id="NP1066" >19* CHURCH south of the Olympieion precinct on a Roman temple site (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM548">548</a>): Travlos, J. 1971, 335</p>
<p :class="{'activeClass': idClicked == NP1067 }" id="NP1067" >20* CHURCHES IN THE LIBRARY OF HADRIAN (Plan 1 beta—5/6, no. 74): J. Travlos, «Άνασκαφαί έν τη Βιβλιοθήκη του 'Αδριανού,» Praktika 1950, 41-60, figs. 1-14</p>
<p :class="{'activeClass': idClicked == NP1068 }" id="NP1068" >1) Quatrefoil Church with mosaic floors: at first <JBcom>[mistakenly]</JBcom> thought to be a pagan bldg. built at the beginning of the 5th c. A.C., Travlos, J. 1971, 244, figs. 321-323; construction <JBcom>[mistakenly]</JBcom> dated to the mid-5th c., destroyed at the end of the 6th c., idem, «To Τετράκογχο οίκοδομημα τής βιβλιοθήκης του 'Αδριανού» in Studies Mylonas, I, 1986, 343-347, fig. 1, pls. 54-57; <JBcom>[mistakenly]</JBcom> attributed to Eudokia by J. Burman, “The Athenian Empress Eudocia,” in Castren, P., ed., 1994, 83 and by A. Karivieri, “The ‘Library of Hadrian' and the Tetraconch church,” ibid., 105-113 and “The ‘House of Proclus',” ibid., 126-127; <JBcom>[late 5th - 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1069 }" id="NP1069" >2) Basilica on the ruins of the Quatrefoil Church, built after the end of the 6th c.: J. Travlos, op. cit., 347</p>
<p :class="{'activeClass': idClicked == NP1070 }" id="NP1070" >3) for Megale Panagia, see Chr. Mon. CM73*</p>
<p :class="{'activeClass': idClicked == NP1071 }" id="NP1071" >4) for the church north of the Quatrefoil Church, see Chr. Mon. CM40*</p>
<p :class="{'activeClass': idClicked == NP1072 }" id="NP1072" >DIONYSIOS THE AREOPAGITE: see Chr. Mon. CM60*</p>
<p :class="{'activeClass': idClicked == NP1073 }" id="NP1073" >21* GRAVE ON THE AKROPOLIS with two gold coins of Tiberius Constantinus (578-582): L. Ross, Kunst-Blatt no. 24, 24. Marz 1836; <JBcom>[the presence of this grave suggests that by the last quarter of the 6th c. a church existed on the Akropolis.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1074 }" id="NP1074" >22* GRAVESTONE OF ATHENODORA, wife of Thaumasios, 6th c. ?, in the Panathenaic Stadium - - - EM 9940; "perhaps 3rd c.", Guarducci, M. 1967-1978, IV, 317-319, fig. 87; for the findspot, see Carl Curtius, “Ausgrabungen im Panathenaischen Stadion,” AZ 27 (1869) 118; Sironen, E. 1997, 236 no. 195</p>
<p :class="{'activeClass': idClicked == NP1075 }" id="NP1075" >23* GRAVE MONUMENT OF HERAKLIOS with cross monogram, 5th c. A.C., female herm reused for a gravestone, northwest Athens at 23 Sphakterias St. (Plan 2 delta -2): O. Alexandri, «Σφακτηρίας 23,» Deltion 23 (1968) B1 Chr., 95 no. 81, pl. 53gamma; SEG 32 (1982) no. 317</p>
<p :class="{'activeClass': idClicked == NP1076 }" id="NP1076" >24* GRAVES with Christian lamps found in 1832 at the Old Palace site at Plateia Klafthmonos (Plan 2 epsilon—5), and elsewhere in northeast Athens: Ross, L. 1855, 34, 35</p>
<p :class="{'activeClass': idClicked == NP1077 }" id="NP1077" >25* GRAVES with Christian lamps, mid-5th c. or later, graveyard in southeast Athens, Vourvachi and Theophilopoulou Sts. (Plan 2 theta—5): O. Alexandri, «Βούρβαχη 30 και Θεοφιλοπούλου 10,» Deltion 27 (1972) B1 Chr., 97-99 no. 11, fig. 1 no. 11, fig. 9, pl. 65</p>
<p :class="{'activeClass': idClicked == NP1078 }" id="NP1078" >26* GRAVESTONE dated to the year 918, Akr. South Slope, east of the later temple of Dionysos: for the findspot, see Levensohn, M. and E. 1947, 66 no. 7, fig. 1, pl. 12</p>
<p :class="{'activeClass': idClicked == NP1079 }" id="NP1079" >27* HAGHIA AGATHOKLEIA, church, 6th c.?, near Monastiraki?: attested by two inscriptions. For Byzantine Museum 139, see Sironen, E. 1997, 178-179 no. 110. For <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204088" target="_blank">Agora I 4088</a> lines 4-6, see Creaghan, J. S., S. J. and A. E. Raubitschek 1947, 4-5, 33, 39-40 no. 5, pl. VI and Sironen, E. 1997, 202-203, no. 148; according to Maltezos, G. T. 1960, 79 no. 56, the church of H. Thekla on H. Thekias St. (Plan 2 epsilon—4) also went by the name of H. Agathokleia</p>
<p :class="{'activeClass': idClicked == NP1080 }" id="NP1080" >HAGHIOI APOSTOLOI TOU SOLAKI: see Chr. Mon. CM30* below</p>
<p :class="{'activeClass': idClicked == NP1081 }" id="NP1081" >28* HAGHIOI THEODOROI (?), church built out of blocks from the proteichisma, underneath a second church of the 15th-16th c., northeast Athens, 27 Nikis St. near Nikodemou St.( Plan 2 zeta—5): O. Alexandri, «Νίκης 27,» Deltion 25 (1970) B1 Chr., 78 no. 38; P. E. Lazarides, «Ερείπια ναού των 'Αγίων Θεοδώρων εις την οδόν Νίκης,» ibid., 138-142, figs. 1-2, pls. 108-109</p>
<p :class="{'activeClass': idClicked == NP1082 }" id="NP1082" >HAGHIOS ANDREAS: see Chr. Mon. CM5* above</p>
<p :class="{'activeClass': idClicked == NP1083 }" id="NP1083" >29* HAGHIOS IOANNIS PRODROMOS TOU MANGOUTI, church on the Akropolis North Slope on the south side of Erotokritou St. near Mnesikleous St. (Plan 1 delta -7) built in 871, rebuilt in the Frankish period, demolished in 1835: Travlos, J. and A. Frantz 1965,175; Biris, K. 1966, 44-45 no. 33</p>
<p :class="{'activeClass': idClicked == NP1084 }" id="NP1084" >30* HOLY APOSTLES (tou Solaki), late 10th-early 11th c., church on the site of a 2nd c. A.C. nymphaeum in the Agora Exc. (Plan 1 delta—3/4 no. 92): Agora XX, 1-44, figs. 1-12. pls. 1-11,14-40</p>
<p :class="{'activeClass': idClicked == NP1085 }" id="NP1085" >Doric column capital, 5th c. B.C., installed as the altar table in the 19th c. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204202" target="_blank">Agora A 4202</a>; Agora XX, 39, pl. 18:c</p>
<p :class="{'activeClass': idClicked == NP1086 }" id="NP1086" >31* MARTYRION (?) with mosaic floor, northeast Athens, Hag. Markou St. off Kolokotroni St. (Plan 2 epsilon—5), on the site of a later chapel, Haghios Soter, demolished in 1923: O. Alexandri, «Όδός Άγ. Μάρκου 11-13,» Deltion 27 (1972) B1 Chr., 86 no. 2, fig. 1 no.2, figs. 2-3, pl. 60 A; <JBcom>[probably no earlier than the 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1087 }" id="NP1087" >32* MONI PETRAKI, (Μονή Ασωμάτων Ταξιαρχών Πετράκι), 10th c., Gennadiou St. in northeast Athens (Plan 2 epsilon—9, no. 56): M. Sotiriou, «To καθολικόν τής Μονή Πετράκι Άθηνών,» Delt. Christ. Arch. Et. 1960-61, 101-129, pls. 47-50; P. E. Lazarides, «Μονή Πετράκι,» Deltion 27 (1972) B1 Chr., 185-186, fig. 1, pl. 125; Pappas, A. A. 1991, 102-124, figs. 1, 11-13</p>
<p :class="{'activeClass': idClicked == NP1088 }" id="NP1088" >33* NATIVITY RELIEF supposedly found on the Akropolis, 6th-7th c. Byzantine Museum T 95; the relief is from Naxos, K. Damiralis, «Άνάγλυφον τής γεννησεως τού Χριστού,» ΑΕ 1890, 21-22, pl. 3</p>
<p :class="{'activeClass': idClicked == NP1089 }" id="NP1089" >34* PANAGIA STIN PETRA, churches on and at the site of the temple of Artemis Agrotera on the Ilissos, on the south edge of Ardittou Blvd., west of Thomopoulou St. (Plan 2 eta—6)</p>
<p :class="{'activeClass': idClicked == NP1090 }" id="NP1090" >Early church, 5th c. (?), with graves: Travlos, J. 1971, 113, figs. 154,156</p>
<p :class="{'activeClass': idClicked == NP1091 }" id="NP1091" >Church built in the 17th c. by remodelling the temple, demolished by order of the Greek Orthodox Archbishop of Athens before August 20, 1768: Baron de Riedesel, Remarques d'un voyageur moderne au Levant, Amsterdam, 1773, 134,141; Μ. M. Miles, “The Date of the Temple on the Ilissos River” Hesperia 49 (1980) 311; for <JBcom>[alleged]</JBcom> demolition by Turks in 1778, see Travlos, J. 1971, 113; cf. Kokkou, A. 1977, 25 note 5</p>
<p :class="{'activeClass': idClicked == NP1092 }" id="NP1092" >35* PHILIP (the Apostle?), tomb in the Tower of the Winds mentioned by Evliya Çelebi in 1668: MacKay, P. A. 1969, 468</p>
<p :class="{'activeClass': idClicked == NP1093 }" id="NP1093" >CHRISTIAN MONUMENTS AFTER THE YEAR 1000 (Chr. Mon. CM36*-80*)</p>
<p :class="{'activeClass': idClicked == NP1094 }" id="NP1094" >36* ARCHBISHOP'S PALACE on the Areopagus, mid-16th c., built on top of the foundations of Dionysios the Areopagite's house: J. P. Babin's account in 1672 published by J. Spon, Relation de l'etat present de le ville d'Athenes ..., Lyon, 1674, 2, 6; Travlos, J. and A. Frantz 1965,158, 183-191, 196-201 (testimonia 4-13,18) with figs. 1-2, 5, 7-9, pls. 52-55</p>
<p :class="{'activeClass': idClicked == NP1095 }" id="NP1095" >37* ASOMATOS STA SKALIA, 11th or 12th c. church formerly in the west front of Hadrian's Library: M. Hadzidakis, «1. Άθήναι. Άσώματος ατά Σκαλιά,» Deltion 22 (1967) B1 Chr., 21-22 no. 1; Touloupa, E. 1992, 593-600</p>
<p :class="{'activeClass': idClicked == NP1096 }" id="NP1096" >BASILICA IN THE PARTHENON: see Chr Mon. CM11*</p>
<p :class="{'activeClass': idClicked == NP1097 }" id="NP1097" >38* CHAPEL north of the Parthenon: Kavvadias-Kawerau, 1906, 15 note 1</p>
<p :class="{'activeClass': idClicked == NP1098 }" id="NP1098" >38** CHAPEL of the Theotokos - Zoodochos Pighi (Mother of God - The Life-giving Source) in the Asklepieion spring house (Asklepios Ask27*), founded after 1876: E. Schmidt, “Halon,” AM 38 (1913) 74; <JBcom>[sorry folks, no continuity with the 6th c. baptistery in the spring house (Chr. Mon. CM5*) which will have disappeared from view centuries before the excavators cleared it in 1876]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1099 }" id="NP1099" >39* CHURCH in the Propylaia corner between the north wall of the gate bldg. and the east wall of the northern wing: Tanoulas, T. 1987, 428-429, figs. 17-19, 20</p>
<p :class="{'activeClass': idClicked == NP1100 }" id="NP1100" >40* CHURCH north of the Quatrefoil Church in Hadrian's Library: late Byzantine or Frankish period, destroyed in the Ottoman period, G. S. Dontas, «βιβλιοθήκη Άδριανού,» Deltion 25 (1970) B1 Chr. 28-30, fig. 2, pl. 41b</p>
<p :class="{'activeClass': idClicked == NP1101 }" id="NP1101" >41* CHURCH OF THE GENOESE colony in Athens, 15th c., near the church of Haghion Asomaton (Plan 2 epsilon—3): L. J. A. Lowenthal, “A note on the so-called Panaghia of the Catalans,” AAA 4 (1971) 90</p>
<p :class="{'activeClass': idClicked == NP1102 }" id="NP1102" >42* HAGHIA AIKATERINI TIS MONIS SINA (named Haghioi Theodoroi before 1767), Lysikratous St. near Lysikrates Mon.(Plan 1 eta -9, no.87): Bin's, K. 1966, 44-45 no. 7; EMME II, 1929, 94 fig. 108; Lippolis, E. 1995, 60-65, figs. 16-26</p>
<p :class="{'activeClass': idClicked == NP1103 }" id="NP1103" >43* HAGHIA ANNA, private chapel, Akropolis northwest slope on Dioskouron St. (Plan 1 delta/epsilon—5, no. 99): perhaps the "ruined chapel of Joanna" mentioned by J. G. Frazer as being perhaps in the neighbourhood of the Theseum, Frazer, J. G. 1913, 11,147</p>
<p :class="{'activeClass': idClicked == NP1104 }" id="NP1104" >44* HAGHIA DYNAMIS (Metochion M. Pentelis), Ottoman period, at the southeast corner of Mitropoleos and Pentelis Sts. (Plan 1 gamma—10, no. 111): drawing in black crayon, National Historical Museum no. 4431; Biris, K. 1966, 44-45 no. 53; scattered remains (unfluted columns, Ionic epistyles, geison fragments) of an Early Christian church nearby, perhaps the predecessor of Haghia Dynamis, Threpsiades, I. 1960, «Άνασκαφή οίκόπεδον Ο.Δ.Ε.Π. (όδός Βουλής και Μητροπόλεως),» 22-23 (unnumbered plan of the 1959 excavations)</p>
<p :class="{'activeClass': idClicked == NP1105 }" id="NP1105" >45* HAGHIA ELEOUSA, northwest side of Kolonos Hippios (Plan 3 beta—6): EMME 111,1933, 139 no. 16, fig. 180; see Kathimia; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM467">467</a></p>
<p :class="{'activeClass': idClicked == NP1106 }" id="NP1106" >46* HAGHIA KYRA, Akropolis Lower North Slope, Polygnotou St. (Plan 1 delta—4): Biris, K. 1966, 44-45 no. 115; EMME II, 1929, 112</p>
<p :class="{'activeClass': idClicked == NP1107 }" id="NP1107" >47* HAGHIA MARINA, in a cave on the northeastern spur of the Hill of the Nymphs, now the baptistery of the big church built in 1931 (Plan 2 zeta—3, no. 62): EMME II, 1929, 105, figs. 130-132; no earlier than the 13th c., no continuity with any pagan cult, G. V. Lalonde, “Pagan Cult to Christian Ritual: The Case of Agia Marina Theseiou,” GRBS 45 (2005) 91-125, figs. 1-8</p>
<p :class="{'activeClass': idClicked == NP1108 }" id="NP1108" >48* HAGHIA PARASKEVI, southeast of the Dionysos Eleuthereus precinct, little chapel ca. 1860, demolished in the 1960s, preceded by a 17th c. church, preceded by a late Byzantine one-aisled basilica: P. G. Kalligas, «Αγία Παρασκευή,» Deltion 18 (1963) B1 Chr., 17-18, fig. 1 no. 12, pl. 11 delta (wall-painting with pattern of nesting triangles with wavy lines); Choremi-Spetsieri, A. 2003, 8</p>
<p :class="{'activeClass': idClicked == NP1109 }" id="NP1109" >49* HAGHIA PHOTINI, modern church on the Ilissos left bank overlooking the site of Kallirrhoe spring (Plan 2 eta -6, no. 76); Biris, K. 1966, 44-45 no. 134; A. N. Skias, «Άνασκαφαί παρά τον Ιλισόν,» Praktika 1897, 84-85; Wickens, J. M. 1986, 317</p>
<p :class="{'activeClass': idClicked == NP1110 }" id="NP1110" >Earlier chapel in a little cave right beside the Ilissos: this predecessor of Haghia Photeini is now concealed by the retaining wall for the front terrace of the present church, A. N. Skias, «Περι της έν τή κοίτη τού Ιλισού,» Praktika 1893, 125</p>
<p :class="{'activeClass': idClicked == NP1111 }" id="NP1111" >50* HAGHIA TRIADA, west of the Tritopatreion in the Kerameikos Exc.; after the War of Independence the ruinous church was rebuilt with entirely different architecture, so that the photographs bear no resemblance to the drawings of 17th-18th c.; annexes were added in 1878 or later, and it was demolished in 1931: Brueckner, A. 1909, 4-5, figs. 1-2, 18-19, 67; K. Kubler, “Ausgrabungen im Kerameikos,” AA 1932, 183-184, Beilage 1 and fig. 1; <JBcom>[the old church of Haghia Triada, a landmark in the history of the Kerameikos Excavations, should not be confused with its replacement, the huge new church of Haghia Triada on Peiraios St. on a site chosen by Brueckner himself as being barren of finds]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1112 }" id="NP1112" >51* HAGHIOI APOSTOLOI STA MARMARA, Klepsydra well house consecrated as a chapel in 11th c. or, perhaps, earlier, Akr. northwest slope: Parsons, A. W. 1943, 193, 198, 201, 246-8, 250-251, figs. 1-2, 19-21; Agora XX, 3 note 1</p>
<p :class="{'activeClass': idClicked == NP1113 }" id="NP1113" >52* HAGHIOI ANARGYROI tou KOLOKYNTHI, Metochion of The Holy Sepulchre, ca. 1600, Akropolis North Slope, Prytaneiou St., west of Erechtheos St. (Plan 1 epsilon—7, no. 104): A. Tanoulas, «Οί 'Αγιοι Άνάργυροι Κολοκύνθη στην Άθήνα,» in Έκκλησίες στην Ελλάδα μετά την Ἅλωσι, National Technical University Athens, 1982, vol. 2, 179-190, figs. 1-12</p>
<p :class="{'activeClass': idClicked == NP1114 }" id="NP1114" >53* HAGHIOI ASOMATOI, Ermou and Asomaton Sts. (Plan 2 epsilon -3, no. 45): Biris, K. 1966, 44-45 no. 88; G. Daux, “Eglise des Saints-Asomates, restauration,” BCH 85 (1961) 614-616, 618, figs. 19-21</p>
<p :class="{'activeClass': idClicked == NP1115 }" id="NP1115" >53** HAGHIOI PANTES OMOLOGETES in Ampelokipi, 11th-12th c., Pan. Kyriakou and An. Tsocha Sts. (Plan 2 gamma—10, no. 59), with much ancient material including a boundary stone and architectural members of a 5th c. B.C. Ionic temple: Welters, P. 1894, 483-490; for the location, see the plan in Deltion 20 ( 1965) B1 Chr., 102 fig. 35; investigations at this site were presented in a lecture in the Polytechneion by M. Korres on December 8, 1994; see Athena Ath51*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM503B">503B</a></p>
<p :class="{'activeClass': idClicked == NP1116 }" id="NP1116" >54* HAGHIOI THEODOROI, Plateia Klafthmonos (Plan 2 epsilon—5, no. 50): Byzantine Monuments 1970, pls. 23—29</p>
<p :class="{'activeClass': idClicked == NP1117 }" id="NP1117" >HAGHIOI ASOMATOI STA SKALIA: see Chr. Mon. CM37* above</p>
<p :class="{'activeClass': idClicked == NP1118 }" id="NP1118" >55* HAGHIOS AIMILIANOS, chapel on Skouze Hill north of Hippios Kolonos (Plan 3 beta—5): Svoronos, J. N. 1911, 389, 408 note 1, pl. 125 (for "Hag. Meletios" read "Hag. Aimilianos")</p>
<p :class="{'activeClass': idClicked == NP1119 }" id="NP1119" >56* HAGHIOS ATHANASIOS, also called Hag. Ioannis tou Alaniari (Asketariou), chapel in the Pan cave on the Akropolis North Slope (Pan Pan4*): EMME 11,1929, 103, figs. 127-129; Iakovides S. 1962, 101, fig. 15 no. 8; reporting serious deterioration of the wall-painting, N. Platon, «Δ. Έργασίαι Βορείας κλιτυος,» Deltion 21 (1966) Bl. Chr.,44</p>
<p :class="{'activeClass': idClicked == NP1120 }" id="NP1120" >57* HAGHIOS ATHANASIOS TOU BILIANAKI, Areopagus north slope: Biris, K. 1966, 44-45 no. 121</p>
<p :class="{'activeClass': idClicked == NP1121 }" id="NP1121" >58* HAGHIOS DIMITRIOS LOUMBARDIARIS on the saddle between the Pnyx and Philopappos hills (Plan 1 theta -1, no. 112): Biris, K. 1966, 44-45 no. 127; Kron, U. 1979, 49, 61-62 with note 65</p>
<p :class="{'activeClass': idClicked == NP1122 }" id="NP1122" >59* HAGHIOS DIMITRIOS TOU KATIPHORI, demolished ca. 1857, Akropolis lower north slope at the intersection of Kyrrhestou and Erechtheos Sts. (Plan 1 delta - 7): Biris, K. 1966, 44-45 no. 37; EMME II, 1929, 112-113, fig. 143; cf. Post-Herulian Wall, east flank</p>
<p :class="{'activeClass': idClicked == NP1123 }" id="NP1123" >60* HAGHIOS DIONYSIOS AREOPAGITES, church on the Areopagus upper north slope built in the mid-16th c., destroyed by falling boulders in 1601 (Plan 1 epsilon—3, no. 98): Travlos, J. and A. Frantz 1965, 157-202, figs. 1-9, pls. 41-46, 49-50. <JBcom>[This church has no predecessor at the site.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1124 }" id="NP1124" >A church of Dionysios the Areopagite somewhere in Athens or Attica first mentioned by name in a Papal Bull of Innocent III in 1208, location unspecified: PL vol. 215, cols. 1559-1561, CCLVI; the testimonium presented as <JBcom>[disputably]</JBcom> indisputable evidence for the existence of a predecessor of the mid-16th c. church of H. Dionysios Areopagites on the Areopagus, J. Travlos and A. Frantz, op. cit., 164, 194 no. 1; cf. Chr. Mon. CM17*</p>
<p :class="{'activeClass': idClicked == NP1125 }" id="NP1125" >61* HAGHIOS IOANNIS STIN KOLONNA, Evripidou and Menandrou Sts. (Plan 2 delta-4, no. 48): Biris, K. 1966, 44-45 no. 71; EMME II, 1929, 101; A. A. Papagiannopoulos-Palaios, «To Διογένειον Γυμνάσιον,» Polemo 3 (1947) 22-24, fig. 1</p>
<p :class="{'activeClass': idClicked == NP1126 }" id="NP1126" >62* HAGHIOS IOANNIS STIS KOLONNES, in the Olympieion: EMME II, 1929, 114-115 no. 28 and III, 1933, 150 no.7, fig. 197</p>
<p :class="{'activeClass': idClicked == NP1127 }" id="NP1127" >63* HAGHIOS IOANNIS THEOLOGOS tis Monis Patmou, 12th c., Akropolis northeast slope, Erotokritou St. east of Erechtheos St. (Plan 1 epsilon—7, no. 90); Biris, K, 1966, 44-45 no. 24; 13th c. wall-paintings uncovered, E. Kounoupiotou-Manolessou, «Άθηναι: Άγιος Ίωάννης Θεολόγος. Έργασίαι στερεώσις,» ΑΑΑ 8 (1975) 140-151, figs, 1, 3-11</p>
<p :class="{'activeClass': idClicked == NP1128 }" id="NP1128" >HAGHIOS NIKODEMOS: see Chr. Mon. CM79*</p>
<p :class="{'activeClass': idClicked == NP1129 }" id="NP1129" >64* HAGHIOS NIKOLAOS, Antiphanous and Xanthippis Sts., northwest of Kolonos Hippios (Plan 3 beta—6): Svoronos, J. N. 1911, 399, 406, fig. 205, pls. 123 B, 125; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM447">447</a></p>
<p :class="{'activeClass': idClicked == NP1130 }" id="NP1130" >65* HAGHIOS NIKOLAOS (or Seraphim), Akropolis North Slope (Plan 1 epsilon — 5, no. 105): Biris, K. 1966, 44-45 no. 111; EMME II, 1929, 105-106, figs. 133-134; Choremi-Spetsieri, A. 2003, 7 fig. 5; see Hypapanti Wall</p>
<p :class="{'activeClass': idClicked == NP1131 }" id="NP1131" >66* HAGHIOS NIKOLAOS ΚΤΕΝΑ, demolished between 1833 and 1866, Plateia Karamanou, Voreou and Vissis Sts. (Plan 2 epsilon—4): Biris, K. 1966, 44-45 no. 78; Kyparissis, N. and H. A. Thompson 1938, 612, 619 with fig. 9</p>
<p :class="{'activeClass': idClicked == NP1132 }" id="NP1132" >67* HAGHIOS NIKOLAOS RANGAVAS, Akropolis northeast slope, Rangava St. merging with Prytaneiou St. (Plan 1 epsilon—7, no. 89) : Biris, K. 1966, 44-45 no. 21; EMME II, 1929, 94, figs. 105-6; Lippolis, E. 1995, 57-58, figs. 4 no. 4, 13-15</p>
<p :class="{'activeClass': idClicked == NP1133 }" id="NP1133" >68* HAGHIOS PETROS STAVROMENOS with an ancient mosaic pavement, northeast of the stadium, perhaps at Plateia Hag. Spyridonos (Plan 2 eta—7): Stuart, J. and N. Revett 1762, 10-11, Plate between pages IX and X, A-6; idem 1794, plan preceding page II and text on page V; Judeich, W. 1931, 416, Plan I L-6</p>
<p :class="{'activeClass': idClicked == NP1134 }" id="NP1134" >69* HAGHIOS PHILIPPOS, 16th c., Adrianou and Hag. Philippou Sts. (Plan 1 beta—3, no. 97): Biris, K. 1966, 44-45 no. 94; EMME I, 1927, 10; II, 1929, 63-65 fig. 50; Agora XXIV, 73-74</p>
<p :class="{'activeClass': idClicked == NP1135 }" id="NP1135" >69** HAGHIOS SPYRIDON, private chapel with frescoes, built 1613 in the ruins of an earlier church, demolished 1939, south of the Stoa of Attalos on the site of the Library of Pantainos: A. Frantz, “The Church of Saint Spiridon,” Hesperia 4 (1935) 448-469, figs. 5-14, 17-20, pls. VI-VII; Shear, T. L. 1940, 293-294; the wall-paintings were transferred to the narthex walls of the Church of The Holy Apostles (Chr. Mon. CM30*), H. A. Thompson, “Activities in the Athenian Agora: 1955,” Hesperia 25 (1956) 66</p>
<p :class="{'activeClass': idClicked == NP1136 }" id="NP1136" >70* HAGHIOS SYMEON, chapel built in 1847 by the settlers from Anaphi, Akropolis North Slope, Anaphiotika (Plan 1 epsilon—6, no.106): recording the inscription on the façade, P. E. Lazarides, «Άγιος Συμεών,» Deltion 25 (1970) B1 Chr., 138</p>
<p :class="{'activeClass': idClicked == NP1137 }" id="NP1137" >HAGHIOS THOMAS: see Chr. Mon. CM7*</p>
<p :class="{'activeClass': idClicked == NP1138 }" id="NP1138" >71* HYPAPANTI, 17th c., demolished in 1938, Akropolis northwest slope on the site of the “Hypapanti Gate” in the Post-Herulian Wall south of the Eleusinion: Shear, T. L. 1940, pl. I, north of circled number 25; Biris, K. 1966, 44-45 no. 113; Setton, K. M. 1975, I, 240, 242-243; Agora XXIV, 8,126, 138-139; Agora XXXI, 3, Plan 3</p>
<p :class="{'activeClass': idClicked == NP1139 }" id="NP1139" >Holy table support, a reused columnar grave mon., transferred in 1933 from Hypapanti to the private chapel of Hag. Spyridon (Chr. Mon. CM69**), now at the Church of The Holy Apostles (Chr. Mon. CM30*) IG II² 10813</p>
<p :class="{'activeClass': idClicked == NP1140 }" id="NP1140" >72* KAPNIKAREA, (Church of the Presentation of the Virgin), 11th c., Ermou and Kapnikareas Sts.( Plan 1 alpha—7, no. 95): Maltezos, G. T. 1960, 59-60 no. 2; Biris, K. 1966, 44-45 no. 57; Byzantine Monuments 1970, pls. 12-22</p>
<p :class="{'activeClass': idClicked == NP1141 }" id="NP1141" >73* MEGALE PANAGIA in the Library of Hadrian, 12th c.: J. Travlos, «Άνασκαφαι έν τή Βιβλιοθήκη τού Άδριανού III. Ή Βυζαντινή εκκλησία,» Praktika 1950, 60, 63, figs. 15-16; Maltezos, G. T. 1960, 72-73 no. 3; Biris, K. 1966, 44-45 no. 100</p>
<p :class="{'activeClass': idClicked == NP1142 }" id="NP1142" >74* METAMORPHOSIS, 13th-14th c., Akropolis North Slope ( Plan 1 epsilon—5/6, no. 91): Biris, K. 1966, 44-45 no. 110; A. Xyngopoulos, «Άττικής Βυζαντινοί ναοί Β'. Ναός Μεταμορφώσεως,» ΑΕ 1913, 137-143, figs. 8-12; Byzantine Monuments 1970, pls. 30-32; in front of the church was the grave of Odysseus Androutsos murdered by his enemies in the night of June 4/5, 1825, Maltezos, G. T. 1960, 74-75 no. 38</p>
<p :class="{'activeClass': idClicked == NP1143 }" id="NP1143" >PANAGIA CHRYSOPYRGIOTISSA: see Chr. Mon. CM77*</p>
<p :class="{'activeClass': idClicked == NP1144 }" id="NP1144" >75* PANAGIA GORGOEPEKOOS, The Little Metropolis, 12th or 13th c., renamed Hag. Eleutherios in 1862 when King Otho was forced to resign, (Plan 1 gamma — 8, no. 94): Maltezos, G. T. 1960, 56-59 no. 1; Biris, K, 1966, 44-45 no. 51; Byzantine Monuments 1970, pls. 1-11; K. Michel and A. Strunk, “Die mittelbyzantinischen Kirchen Athens: I. Die Kirche der Panagia Gorgoepekoos,” AM 31 (1906) 281-324, figs. 1-30, pls. 20-21; P. Steiner, “Antike Skulpturen an der Panagia Gorgoepekoos zu Athen,” ibid., 325-341, figs. 1-2; <JBcom>[construction in the 13th c. during the Frankish period is more likely]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1145 }" id="NP1145" >Grave relief of two standing women, 240-2C0 A.D., with added Christian cross and inscription, built into the north wall of the church: Conze, A. 1893-1922, IV, 50 no. 1936, pl. 417; Walters, E. J. 1988, 41 note 69; St. Anna and the Virgin suggested as the interpretatio Christiana of the relief, Delivorrias, A. 1991, 116-117, pl. 56 See also Index II under Churches, Panagia Gorgoepekoos</p>
<p :class="{'activeClass': idClicked == NP1146 }" id="NP1146" >76* PANAGIA PANTANASSA at Monastiraki (Plan 1 alpha—5, no. 96): Biris, K. 1966, 44-45 no. 103; EMME II, 1929, 65, figs. 51-53</p>
<p :class="{'activeClass': idClicked == NP1147 }" id="NP1147" >77* PANAGIA PYRGIOTISSA (or Chrysopyrgiotissa), installed between 1456-1687 in a tower of the Post-Herulian Wall at the southwest corner of the Stoa of Attalos, demolished in 1859: Biris, K. 1966, 44-45 no. 117; Miller, S. G. 1972, 58 with note 3 and fig. 6; Agora XXIV, 7-8, 126, 133, 138</p>
<p :class="{'activeClass': idClicked == NP1148 }" id="NP1148" >78* PANAGIA SPILIAIOTISSA or Chrysospiliotissa, above the theater in the Thrasyllos Mon. Cave (Plan 1 no. 30): Biris, K. 1966, 44-45 no. 1; EMME I, 1927, 47-48 with fig. 34; II, 1929, 103, fig. 130</p>
<p :class="{'activeClass': idClicked == NP1149 }" id="NP1149" >PANAGIA STIN PETRA: see Christian Mon. no. CM34*</p>
<p :class="{'activeClass': idClicked == NP1150 }" id="NP1150" >79* PANAGIA TOU LYKODEMOU variously called Hag. Nikodemos <JBcom>[the original name]</JBcom> and Hag. Lykodemos (now the Russian Orthodox Church), 11th c., Philellenon St. (Plan 1 delta/epsilon—11, no. 85): Maltezos, G. T. 1960, 61-62 no.6; Biris, K. 1966, 44-45 no. 16; EMME 11,1929, 80-83, 115, figs. 76-83; Lynch, J. P. 1972, 17-22; Ritchie Jr., C. E. 1989, 256-257</p>
<p :class="{'activeClass': idClicked == NP1151 }" id="NP1151" >80* SAINT BARTHOLOMEW, Catholic chapel in the Propylaia, mentioned in an Aragonese document of 1380: Caskey, L. D. et al. 1927, 520 note l See also: Index II, Churches</p>
<p :class="{'activeClass': idClicked == NP1152 }" id="NP1152" >CHRYSA, sanctuary (?) on Pnyx Hill: Plu. Thes. 27.3; FGRHIST III B, 56-7, Kleidemos 323 F 18, with suppl. vol. 1.77; LIMC III, 1986, 279-281, s.v. Chryse I (H. Froning)</p>
<p :class="{'activeClass': idClicked == NP1153 }" id="NP1153" >CHRYSIPPOS of Soloi (in Cilicia), Stoic philosopher</p>
<p :class="{'activeClass': idClicked == NP1154 }" id="NP1154" >Grave on the Road to the Academy: Paus. 1.29.15; probably a state-financed burial, Stupperich, R. 1977, 21 note 4, 263 note 4</p>
<p :class="{'activeClass': idClicked == NP1155 }" id="NP1155" >Inscribed portrait herm, 1st c. A.C., found in excavations for an underground garage at the Parliament Building on Plateia Syntagmatos SEG 47 (1997) 227B; Athens News, 18 April, 1997, 3</p>
<p :class="{'activeClass': idClicked == NP1156 }" id="NP1156" >Statues known from literary sources: Richter, G. M. A. 1972, I, 103</p>
<p :class="{'activeClass': idClicked == NP1157 }" id="NP1157" >Statue in the Kerameikos, almost hidden by an equestrian statue: Cic. Fin. 1.11.39</p>
<p :class="{'activeClass': idClicked == NP1158 }" id="NP1158" >Statue in the Ptolemaion: Paus. 1.17.2</p>
<p :class="{'activeClass': idClicked == NP1159 }" id="NP1159" >Statue with epigram set up by Chrysippos' nephew Aristokreon: Plu. Moralia 1033 E; Habicht, Chr. 1989, 13-14</p>
<p :class="{'activeClass': idClicked == NP1160 }" id="NP1160" >Portrait bust: see Mithras</p>
<p :class="{'activeClass': idClicked == NP1161 }" id="NP1161" >CHRYSOUS STENOPOS (Χρυσους στενοπόυς), name of an alley: Alciphr. 3.5.1, 4.14.7</p>
<p :class="{'activeClass': idClicked == NP1162 }" id="NP1162" >CHTHONIC DAIMONES, altar, 2nd-3rd c. A.C., in a church near Lykabettos IG II² 4820</p>
<p :class="{'activeClass': idClicked == NP1163 }" id="NP1163" >CHTHONIC GODS, altar - - - IG II² 5020 </p>
<p :class="{'activeClass': idClicked == NP1164 }" id="NP1164" >CHTHONIOS: see Hermes Hrm18*</p>
<p :class="{'activeClass': idClicked == NP1165 }" id="NP1165" >CINDERHILL: see Stachtothiki</p>
<p :class="{'activeClass': idClicked == NP1166 }" id="NP1166" >CITY WALL, PRE-PERSIAN: Hdt. 9.13, cf. Paus. 7.25.2; R. G. A. Weir, “The lost Archaic Wall around Athens,” Phoenix 49 (1995) 247-258; conjectural version of the line of the wall, Travlos, J. 1971, 158, 261, fig. 5 no. 20</p>
<p :class="{'activeClass': idClicked == NP1167 }" id="NP1167" >CITY WALL, 479 B.C.: see Themistoklean City Wall</p>
<p :class="{'activeClass': idClicked == NP1168 }" id="NP1168" >CITY WALL IN 1687: engraving drawn by Verneda in Atene Attica ... nel 1687 by Francesco Fanelli; T. Bowie and J. Thimme, eds., The Carrey Drawings of the Parthenon, Bloomington, Indiana, 1971, 36 fig. 22</p>
<p :class="{'activeClass': idClicked == NP1169 }" id="NP1169" >CITY WALL IN 1778, the Haseki Wall with seven gates: Dodwell, E. 1819, vol. I, 381-384; Biris, K, 1966, colour plate facing 9, 10-14, 44-45 (plan of Turkish Athens) nos. 230, 224, 225, 226, 227, 201, 229</p>
<p :class="{'activeClass': idClicked == NP1170 }" id="NP1170" >Porta tou Kastrou, Akropolis northwest slope west of the Odysseus Bastion: water colour by James Skene, 1838, National Historical Museum no. 29</p>
<p :class="{'activeClass': idClicked == NP1171 }" id="NP1171" >Porta tou Mandravili (or tou Drakou), Plateia Thissiou (Plan 2 zeta—3): H. A. Thompson, “Activities in the Athenian Agora: 1956 "Hesperia 26 (1957) 101</p>
<p :class="{'activeClass': idClicked == NP1172 }" id="NP1172" >Porta tou Moria or Gyphtiki, Leokoriou St. (Plan 2 epsilon—3)</p>
<p :class="{'activeClass': idClicked == NP1173 }" id="NP1173" >Porta Menidiatiki or Holy Apostles, Aiolou and Sophokleous Sts. (Plan 2 delta—5): EMME II, 1929, 115 no. 29</p>
<p :class="{'activeClass': idClicked == NP1174 }" id="NP1174" >Porta tis Boubounistras, southeast corner of Plateia Syntagmatos (Plan 2 zeta -6)</p>
<p :class="{'activeClass': idClicked == NP1175 }" id="NP1175" >Porta tis Vasilopoulas, Hadrian's Arch (Plan 1 eta/theta—10, no. 80)</p>
<p :class="{'activeClass': idClicked == NP1176 }" id="NP1176" >Porta Arvanitiki, Vyronos and Dion. Areopagitou Sts. (Plan 1 theta—8)</p>
<p :class="{'activeClass': idClicked == NP1177 }" id="NP1177" >CITY WALLS: see Index I under Fortifications</p>
<p :class="{'activeClass': idClicked == NP1178 }" id="NP1178" >CIVICA BARBARUS, (M. Ceionius Civica Barbarus), base of a statue set up by his friend Herodes Atticus by consent of a decree of the polis, Post-Herulian Wall  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204922" target="_blank">Agora I 4922</a>; SEG 15 (1959) no. 166; T. L. Shear, “The Campaign of 1937,” Hesperia 7 (1938) 328, figs. 10 (the stele base built into the wall),11; Ameling, W. 1983, I, 81,159 and 11,180-182 no. 188; Byrne, S. G. 2003, 118 (xxvii)</p>
<p :class="{'activeClass': idClicked == NP1179 }" id="NP1179" >CLAUDIUS = Tib. Claudius Nero (PIR 2nd ed., 941): Hoff, M. C. 1994, 116</p>
<p :class="{'activeClass': idClicked == NP1180 }" id="NP1180" >Herm base reused for bronze statue of Claudius as Apollo Patroos, Panagia Pyrgiotissa (in the Stoa of Attalos southwest corner) - - - IG II² 3274; Shear Jr., T. L. 1981, 363 with note 33</p>
<p :class="{'activeClass': idClicked == NP1181 }" id="NP1181" >Statue base, 41 A.D., Akropolis North Slope - - - IG II² 3270; SEG 29 (1979) no. 169; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Acta 8th Congress, 70 note 2, 71 note 8</p>
<p :class="{'activeClass': idClicked == NP1182 }" id="NP1182" >Base for one of the statues restored to Athens by Claudius benefactor of the polis - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20578" target="_blank">Agora I 578</a>; IG II² 5176; SEG 19 (1963) no. 235; T. L. Shear, Jr., op. cit., 367 with note 51</p>
<p :class="{'activeClass': idClicked == NP1183 }" id="NP1183" >CLAUDIUS (LEONTICUS) ILLYRIUS, proconsul of Achaia, bases of two statues erected by the City under the supervision of Marcus Junius Minucianus the sophist, ca. 270 - 280 A.D., Akr. - - - IG II² 3689/3690; Sironen, E. 1997, 59-62, nos. 5-6 (cf. no. 30); SEG 51 (2001) no. 218; Byrne, S. G. 2003, 336 no. 38 (ii.1) and (ii.2)</p>
<p :class="{'activeClass': idClicked == NP1184 }" id="NP1184" >CLAUDIUS MARCELLUS, M.</p>
<p :class="{'activeClass': idClicked == NP1185 }" id="NP1185" >Grave monument commissioned by Cicero in nobilissima orbis terrarum gymnasio Academiae, 45 B.C.: Servius Sulpicius Rufus in Cic. Fam. 4.12.3; Billot, M.-F. 1989, 729</p>
<p :class="{'activeClass': idClicked == NP1186 }" id="NP1186" >Base for statues of Claudius Marcelius and his wife, ca. 45 B.C., Akr., north of the Agrippa Mon. IG II² 4111; Geagan, D. J. 1967, 80, 140; SEG 40 (1990) no. 194</p>
<p :class="{'activeClass': idClicked == NP1187 }" id="NP1187" >CLAUDIUS PULCHER, APPIUS (RE Claudius 297)</p>
<p :class="{'activeClass': idClicked == NP1188 }" id="NP1188" >Imago clipeata, not far from the church of Dim. Loumbardiaris (Plan 1 theta—1, no. 112)- - - 1st Ephor., Acropolis Stray Finds inv. no. 372; G. S. Dontas, “Appius Claudius Pulcher,” Orlandos Charisterion, II, 1966, 121-137, pls. 12-13</p>
<p :class="{'activeClass': idClicked == NP1189 }" id="NP1189" >Statue base, ca. 50 B.C., Panagia Pyrgiotissa (in the Stoa of Attalos southwest corner) - - - IG II² 4109; Geagan, D. J. 1967, 144</p>
<p :class="{'activeClass': idClicked == NP1190 }" id="NP1190" >CLEMENTIA: see Eleos</p>
<p :class="{'activeClass': idClicked == NP1191 }" id="NP1191" >COMIC MASK, overlifesize, marble with clamp cuttings on either side and back for attachment to a background, 2nd c. B.C., on the left side of the Dipylon-Academy Road 60 m. distant from the Dipylon Gate - - - NM 3373; <JBcom>[plausibly]</JBcom> suggesting a link with the Technitai of Dionysos, A. Brueckner, “Maske aus dem Kerameikos,”, Winckelmannsprogramme Berlin 75 (1915) 32-36, figs. 1-2, pls. 4-6; “probably part of the decoration of the council-house of the Dionysiac Artists,” Kaltsas, N. 2002, 283 no. 595, ph.; Hallof, K. - J. Stroszeck 2002, 127 note 40, pl. 21:2; <JBcom>[probably displayed on a wall in the Pompeion which also served as the bouleuterion of the Technitai of Dionysos]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1192 }" id="NP1192" >COMIC POETS, picture or pictures in the Pompeion: Plin. Nat. XXXV.140</p>
<p :class="{'activeClass': idClicked == NP1193 }" id="NP1193" >CONSTANTINE, statue as strategos with epigram set up by the Athenians: Jul. Or. I.8.CD; Agora XXIV,16</p>
<p :class="{'activeClass': idClicked == NP1194 }" id="NP1194" >CORNELIUS DOLABELLA, P. (RECornelius 141), columnar statue base, ca. 7 A.D., Stoa of Attalos - - - IG II² 4155; Geagan, D. J. 1967, 158</p>
<p :class="{'activeClass': idClicked == NP1195 }" id="NP1195" >CORNELIUS SCIPIO, P., (RE Cornelius 333), statue by Kephisodoros — IG II² 4120; A. N. Oikonomides, “Inscriptions from Oropos and the Amphiareion: 1. The free city of Oropos under Augustus” Horos 3 (1985) 20 note 2</p>
<p :class="{'activeClass': idClicked == NP1196 }" id="NP1196" >COSSUTIUS, Olympieion architect, statue base, Olympieion - - - IG II² 4099; E. Rawson, “Architecture and Sculpture: the Activities of the Cossutii,” BSR 43 (1975) 36-37; Tolle-Kastenbein, R. 1994, 144-145 with notes 779-785</p>
<p :class="{'activeClass': idClicked == NP1197 }" id="NP1197" >COURT BESIDE THE WALLS, lawcourt: Ar. V. 1107 ff.; a topic eliciting <JBcom>[confusing]</JBcom> conjectures, Agora XXVIII, Index s.v Walls, Court by the</p>
<p :class="{'activeClass': idClicked == NP1198 }" id="NP1198" >COURT NEAR LYKOS, lawcourt: Poll. 8.121; suggested location at the poros limestone benches (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM176">176</a>) set into the east slope of Kolonos Agoraios, Agora 1995, 14, 188-191 and Index s.v. Lykos; cf. Heroes Hro20*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM276">276</a>; <JBcom>[this lawcourt was probably on the east side of the Agora with the other dikasteria]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1199 }" id="NP1199" >COURT OF METIOCHOS, lawcourt: Pollux 8.121; F GR HIST III B, 74, Androtion 324 F 59 with suppl. vols. I, 164,7 and 11,148, 150, 152-3; Davies, J. K. 1971, 308; Agora XXV111,177-178 and Index s.v. Metiocheion, Metiochos</p>
<p :class="{'activeClass': idClicked == NP1200 }" id="NP1200" >COURT OF THE ARCHON, lawcourt mentioned in a decree of 421/420 B.C.: IG I³ 82 line 28; SEG 26 (1976-77) no. 20; Agora XXVIII, 91 ("possibly an alternative designation of the Odeion"), testimonia nos. 139-142, and Index s.v Archon's Court</p>
<p :class="{'activeClass': idClicked == NP1201 }" id="NP1201" >COW, bronze sculpture by Myron on the Akropolis: Tz. H. VIII.370-374; G. Lippold, RE XVI, 1933, col. 1126-1127 (Myron); Pollitt, J. J. 1990, 50-51</p>
<p :class="{'activeClass': idClicked == NP1202 }" id="NP1202" >DADOUCHOS, theater throne - - - IG II² 5028; Maass, M. 1972,106-107, pl. 7</p>
<p :class="{'activeClass': idClicked == NP1203 }" id="NP1203" >DAIDALOS, DAIDALIDAI, DAIDALEION</p>
<p :class="{'activeClass': idClicked == NP1204 }" id="NP1204" >Folding stool made by Daidalos in the Temple of the Polias: Paus. 1.27.1; Ole Wanscher, Sella Curulis, Copenhagen, 1980, 86-87; Kearns, E. 1989, 184, Daidalos</p>
<p :class="{'activeClass': idClicked == NP1205 }" id="NP1205" >Daidalidai, deme name: gravestones of men of this deme, IG II² 5962-5964, were found at Eleusis, “infra Philopappum” and “in Ceramico”; probably north of Alopeke, Traill, J. S. 1986, 135 with Map</p>
<p :class="{'activeClass': idClicked == NP1206 }" id="NP1206" >Daidaleion and the road to the Daidaleion attested solely in a poletai record of 367/6 B.C.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205509" target="_blank">Agora I 5509</a>, lines 11-12, 21-22; M. Crosby, “Greek Inscriptions: A Poletai Record of the Year 367/6 B.C.,” Hesperia 10 (1941) 14-18, 20-21; SEG 46 (1996) no. 202</p>
<p :class="{'activeClass': idClicked == NP1207 }" id="NP1207" >DAIMON: see Agathos Daimon; Chthonic Daimones; Spoudaios Daimon;</p>
<p :class="{'activeClass': idClicked == NP1208 }" id="NP1208" >Unknown Gods</p>
<p :class="{'activeClass': idClicked == NP1209 }" id="NP1209" >DAPHNEPHOROS: see Apollo Apo28*</p>
<p :class="{'activeClass': idClicked == NP1210 }" id="NP1210" >DEIOTAROS, king of the Galatian Tolistobogii, statue base, after 63/62 B.C. - - - IG II² 3429; Maass, M. 1972, 54 note 100, pl. 23; Kotsidu, H. 2000, 102-103, figs. 10-11</p>
<p :class="{'activeClass': idClicked == NP1211 }" id="NP1211" >DEIPNOPHOROI, theater seat — IG II² 5151; M. A. Tiverios, «Ιερόν Δορποφόρων,» Deltion 30 (1975) A, 145 with note 30</p>
<p :class="{'activeClass': idClicked == NP1212 }" id="NP1212" >DEKELEIANS' meeting-place at the barbershop near the Herms: Lys. XXIII.2-3; Agora III, no. 306; Hedrick Jr., C. W., 1990, 46-47, 54-55</p>
<p :class="{'activeClass': idClicked == NP1213 }" id="NP1213" >DELIOS: see Apollo Apo29*</p>
<p :class="{'activeClass': idClicked == NP1214 }" id="NP1214" >DELPHINIA, DELPHINION, DELPHINIOS: see Apollo Apo30*-34*</p>
<p :class="{'activeClass': idClicked == NP1215 }" id="NP1215" >DEMADES, son of Demeas, bronze statue in the Agora, ca. 335 B.C.: Din. 1.101; Wolfgang Will, Athen und Alexander, München, 1983, 59</p>
<p :class="{'activeClass': idClicked == NP1216 }" id="NP1216" >DEMETER</p>
<p :class="{'activeClass': idClicked == NP1217 }" id="NP1217" >Boundary stones: Ritchie Jr., C. E. 1985,120-128, pls. 22-23, figs. 19-20</p>
<p :class="{'activeClass': idClicked == NP1218 }" id="NP1218" >Boundary stone, ca. 490-460 B.C., beneath the Stoa of Attalos floor - - - IG I² 1054 = <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206311" target="_blank">Agora I 6311</a>; Agora XIX, 22 H4 See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM322">322</a></p>
<p :class="{'activeClass': idClicked == NP1219 }" id="NP1219" >Statue base, Akr. northwest slope at the reservoir adjoining the Klepsydra - - - IG II² 4662</p>
<p :class="{'activeClass': idClicked == NP1220 }" id="NP1220" >Statuette of Demeter holding a poppy and wheat, 2nd c. A.C., east of the Hephaisteion - - - NM 3989; Palagia, O. 1980, 36 no. 2; Kaltsas, N. 2002, 253 no. 259</p>
<p :class="{'activeClass': idClicked == NP1221 }" id="NP1221" >Temple near the Pompeion: Paus. 1.2.4 (twice); Kerameikos X, 171 note 369 See also: Eleusinion; Kourotrophos from Aglauros; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM368">368</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM464">464</a></p>
<p :class="{'activeClass': idClicked == NP1222 }" id="NP1222" >DEMETER and APHRODITE (Deo and the Paphian Kore), dedication on a columnar base, Roman pd., northern section of the Library of Pantainos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20562" target="_blank">Agora I 562</a>; Oliver, J. H. 1935, 61-63, fig. 25; Agora III, 50, 85</p>
<p :class="{'activeClass': idClicked == NP1223 }" id="NP1223" >DEMETER and KORE</p>
<p :class="{'activeClass': idClicked == NP1224 }" id="NP1224" >Dedication on an epistyle, from a late wall between the Beule Gate south tower and the Nike Bastion - - - IG II² 4640; Beschi, L. 1967/68, 526</p>
<p :class="{'activeClass': idClicked == NP1225 }" id="NP1225" >Kleokrateia's dedication signed by Praxiteles, mid-4th c. B.C., north foot of Kolonos Agoraios - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204165" target="_blank">Agora I 4165</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 200-203 no. 50, fig. 1, pl. 50; Agora XIV, 154-155, fig. 38, pl. 76:c; Agora Guide 1990, 93, 193, 278 no. 193</p>
<p :class="{'activeClass': idClicked == NP1226 }" id="NP1226" >Arula, dedication of 2nd-1st c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206909" target="_blank">Agora I 6909</a>; Meritt, B. D. 1963, 42-43 no. 48, pl. 12; SEG 21 (1965) no. 795</p>
<p :class="{'activeClass': idClicked == NP1227 }" id="NP1227" >Dedication on a base, Pompeion IG II² 4642</p>
<p :class="{'activeClass': idClicked == NP1228 }" id="NP1228" >Sanctuary on the Sacred Way to Eleusis: Paus. 1.37.2; S. Dow and R. F. Healey, S. J. 1965, 39-41; see Athena Ath130*</p>
<p :class="{'activeClass': idClicked == NP1229 }" id="NP1229" >Sculpture group, mid-2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201429" target="_blank">Agora S 1429</a>; Agora XXXI, 220 no. 14, pl. 40</p>
<p :class="{'activeClass': idClicked == NP1230 }" id="NP1230" >Temple of Demeter and Kore in the Eleusinion: “above the Enneakrounos”, Paus. 1.14.1; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205165" target="_blank">Agora I 5165</a> line 12; Agora XXXI, 43, 44, 49 with note 35, 51, 198 no. 35 (= <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205165" target="_blank">Agora I 5165</a>); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM366">366</a></p>
<p :class="{'activeClass': idClicked == NP1231 }" id="NP1231" >Demeter and Kore and Iakchos, statues by Praxiteles according to the inscription on the wall in a temple near the Pompeion: Paus. 1.2.4; LIMC IV, 1988, 879 no. 415, s.v. Demeter (L. Beschi); for the inscr., see H. Whittaker, “Pausanias and his Use of Inscriptions” Symbolae Osloensis 66 (1991) 183-186</p>
<p :class="{'activeClass': idClicked == NP1232 }" id="NP1232" >Relief of Demeter and Kore, Eubouleus with the infant Ploutos, late 4th c. B.C.?, west foot of the Areopagus - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201251" target="_blank">Agora S 1251</a>; LIMC IV, 1988, 878-879 no. 413, ph., s.v. Demeter (L. Beschi); LIMC VII, 1994, 417-418, no. 14, s.v. Ploutos (K. Clinton)</p>
<p :class="{'activeClass': idClicked == NP1233 }" id="NP1233" >Relief of Demeter and Kore, Eubouleus with the infant Ploutos, kneeling worshipper, late 4th c. B.C.? - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201646" target="_blank">Agora S 1646</a>; LIMC IV,1988, 879 no. 414, ph., s.v. Demeter (L. Beschi); LIMC VII, 1994, 418 no. 15, s.v. Ploutos (K. Clinton) See also: Asklepios Ask35*; Demeter and Pherephatte; Eagle; Habryllis; Nymphs Nym11*; Phaidros the Epicurean; Thesmophoroi; Triptolemos</p>
<p :class="{'activeClass': idClicked == NP1234 }" id="NP1234" >DEMETER and PHEREPHATTE, priest's theater throne — IG II² 5074; Maass, M. 1972, 136, pl. 20; Threatte, L. 1980, 450-451</p>
<p :class="{'activeClass': idClicked == NP1235 }" id="NP1235" >DEMETER and OTHERS: see Hermes Hrm37*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM216">216</a></p>
<p :class="{'activeClass': idClicked == NP1236 }" id="NP1236" >DEMETER ACHAIA: V. Suys, “Le culte de Demeter Achaia en Beotie: Etat actuel des connaissances,” Antiquite Classique 63 (1994) 1-20</p>
<p :class="{'activeClass': idClicked == NP1237 }" id="NP1237" >Sanctuary of the Gephyraioi: Hdt. 5.61; F GR HIST III B, suppl. vol. II, 500 note 16</p>
<p :class="{'activeClass': idClicked == NP1238 }" id="NP1238" >Theater seat - - - IG II² 5117 See also: Demeter Kourotrophos Achaia</p>
<p :class="{'activeClass': idClicked == NP1239 }" id="NP1239" >DEMETER AZESIA, sanctuary boundary stone, 4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20513" target="_blank">Agora I 513</a>; Oliver, J. H. 1935, 52-53 no. 14, ph.</p>
<p :class="{'activeClass': idClicked == NP1240 }" id="NP1240" >DEMETER CHLOE, sanctuary at the entrance to the Akropolis: Paus. 1.22.3; Milchhöfer, A. 1891, 25; Wycherley, R. E. 1966, 290-291; conjecturally located on the Akropolis upper west slope, Travlos, J. 1971, 2, fig. 5 no. 8</p>
<p :class="{'activeClass': idClicked == NP1241 }" id="NP1241" >Dedication found at the Beule Gate - - - IG II² 4777 = 4750; Beschi, L. 1967/68, 526</p>
<p :class="{'activeClass': idClicked == NP1242 }" id="NP1242" >Theater seat of priestess (and) Diophantes - - - IG II² 5129; Oikonomides, A. N. 1964, 9 no. IX See also: Apollo Apo68*; Chloe Themis; Demeter Euchloos; Kourotrophos, base</p>
<p :class="{'activeClass': idClicked == NP1243 }" id="NP1243" >DEMETER EUCHLOOS <JBcom>[= Demeter Chloe]</JBcom>: S. OC 1600 with schol.; FGR HIST III B, suppl. vol. I, 335 frag. 61; cf. Part II no.465</p>
<p :class="{'activeClass': idClicked == NP1244 }" id="NP1244" >DEMETER KARPOPHOROS: Milchhöfer, A. 1891, 26 s.v.; Judeich, W. 1931, 304 note 2 (for "Karpophoros" read "Kourotrophos")</p>
<p :class="{'activeClass': idClicked == NP1245 }" id="NP1245" >DEMETER KOUROTROPHOS</p>
<p :class="{'activeClass': idClicked == NP1246 }" id="NP1246" >Priestess' theater seat, Peitho (separately inscribed) - - - IG II² 5131; Oikonomides, A. N. 1964, 8-9 no. VII; SEG 28 (1978) no. 237</p>
<p :class="{'activeClass': idClicked == NP1247 }" id="NP1247" >Demeter Kourotrophos Achaia, theater seat - - - IG II² 5153</p>
<p :class="{'activeClass': idClicked == NP1248 }" id="NP1248" >DEMETER PHREAROOS, theater seat — IG II² 5155; R. M. Simms, “The Phrearrian Lex Sacra: An Interpretation,” Hesperia 67 (1998) 92; SEG (1998) no. 138</p>
<p :class="{'activeClass': idClicked == NP1249 }" id="NP1249" >DEMETER PROPYLAIA, dedication on a base, after 350 B.C., north of Plateia</p>
<p :class="{'activeClass': idClicked == NP1250 }" id="NP1250" >Syntagmatos - - - IG II² 4597; Threatte, L. 1980, 279</p>
<p :class="{'activeClass': idClicked == NP1251 }" id="NP1251" >DEMETER THESMOPHOROS: see Thesmophorion</p>
<p :class="{'activeClass': idClicked == NP1252 }" id="NP1252" >DEMETRIOS, son of Aristarchos, bronze statue dedicated by the Delphic Amphiktyons at Apollo Patroos, 1st c. A. D.: Fouilles de Delphes III 2, 188 no. 161 lines 18-27</p>
<p :class="{'activeClass': idClicked == NP1253 }" id="NP1253" >DEMETRIOS KATAIBATES: see Demetrios Poliorketes</p>
<p :class="{'activeClass': idClicked == NP1254 }" id="NP1254" >DEMETRIOS OF PHALERON</p>
<p :class="{'activeClass': idClicked == NP1255 }" id="NP1255" >Bronze statue on the Akropolis, (317-307 B.C.), the only statue of Demetrios not destroyed: D.L. 5.77; R. von den Hoff, “Tradition and innovation: portraits and dedications on the early Hellenistic Akropolis,” in Macedonians in Athens 2003, 175</p>
<p :class="{'activeClass': idClicked == NP1256 }" id="NP1256" >More than 360 bronze statues, equestrian or driving horses, destroyed in his lifetime: D.L. 5.75 + 77 + 82; Demetrius of Phaleron: Text, Translation and Discussion (Rutgers University Studies in Classical Humanities, vol. IX), W. W. Fortenbaugh and E. Schütrumpf eds., New Brunswick and London, 2000, frg. nos. 1.75, 1.77, 1.82, 19, 24B, 2 A-C</p>
<p :class="{'activeClass': idClicked == NP1257 }" id="NP1257" >Lead curse tablet naming Pleistarchos (Kassander's brother), Eupolemos, Kassander, Demeterios of Phaieron, as well as a fifth person whose name has not yet been deciphered, 304? B.C., well in the Dipylon courtyard Kerameikos IB 2 (replacing the old inv. no. I 460); D. Jordan, “Two inscribed lead Tablets from a Well in the Athenian Kerameikos,” AM 95 (1980) 225, 229-236, fig. 2; Habicht, Chr. 1985, 77-82, fig. 28</p>
<p :class="{'activeClass': idClicked == NP1258 }" id="NP1258" >DEMETRIOS POLIORKETES: I. Kertesz, “Bemerkungen zum Kult des Demetrios Poliorketes,” Oikumene 2 (1.978) 164-175</p>
<p :class="{'activeClass': idClicked == NP1259 }" id="NP1259" >Demetrios Poliorketes riding on the world, theater stage picture: Ath. XII 536 A</p>
<p :class="{'activeClass': idClicked == NP1260 }" id="NP1260" >Equestrian statue in the Agora, beside the statue of Demokratia, 302 or 294 B.C.: EM 12749 lines 13-14; the text printed in SEG 25 (1971) no. 149; SEG 32 (1982) no. 151; Kotsidu, H. 2002, 47-49; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM338">338</a></p>
<p :class="{'activeClass': idClicked == NP1261 }" id="NP1261" >Demetrios Poliorketes as Kataibates, altar set up in 304 B.C.: Plu. Demetr. 10.4; Habicht, Chr. 1970, 48-50, 140, 156; the date contributed by Anne Stewart in April, 1987</p>
<p :class="{'activeClass': idClicked == NP1262 }" id="NP1262" >Disgraceful cults founded by Demetrios Poliorketes: sanctuaries and heroa for Lamia Aphrodite, Leaina Aphrodite, Bourichos, Adeimantos, Oxythemis: Ath. VI 253 A; Habicht, Chr. 1970, 49, 55-58, 132, 138, 213-216, 255-256 See also: Antigonos I of Macedon and Demetrios Poliorketes; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM440">440</a></p>
<p :class="{'activeClass': idClicked == NP1263 }" id="NP1263" >DEMIAN GATE in the Themistoklean City Wall: Hsch. s.v.; Stroud, R. S. 1998, 105 note 44, fig. 7; probably on the Hill of the Nymphs northwest slope, Threpsiades, J. and E. Vanderpool 1964, 36; Travlos, J. 1971, 121, 159, fig. 219 Gate I; see Themistoklean City Wall Wal31*</p>
<p :class="{'activeClass': idClicked == NP1264 }" id="NP1264" >DEMOCHARES, son of Laches, bronze statue set up in 271 B.C., transferred from the Agora to the Prytaneion: Plu. Moralia 847 D-E; Agora III, no. 559; Schenkungen hellenistischer Herrscher, Part I, 1995, 26-28</p>
<p :class="{'activeClass': idClicked == NP1265 }" id="NP1265" >DEMOKLES, son of Philistion, bronze statue awarded in an honorary decree to be set up in the Eurysakeion, early 2nd c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206827" target="_blank">Agora I 6827</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 32 (1963)14-15 no. 13, pl. 4</p>
<p :class="{'activeClass': idClicked == NP1266 }" id="NP1266" >DEMOKRATES, proconsul of Achaia, grave stele with 5 epigrams, 3rd c. A.C. IG II² 11120; A. Wilhelm, “Bemerkungen zu den attischen Grabinschriften IG II²,” ZPE 29 (1978) 75-76; SEG 28 (1978) no. 319</p>
<p :class="{'activeClass': idClicked == NP1267 }" id="NP1267" >DEMOKRATIA: A. E. Raubitschek, “Demokratia”, Hesperia 31 (1962) 238-243, pl. 86; Palagia, O. 1980, 57-61; LIMC III, 1986, 372-374, nos. 1,6,7,8, s.v. Demokratia (O. Palagia); Monaco M. C. 2001, 132-133</p>
<p :class="{'activeClass': idClicked == NP1268 }" id="NP1268" >Statue in the Agora: EM 12749 line 14; SEG 25 (1971) no. 149; Palagia, O. 1982,112 with note 82</p>
<p :class="{'activeClass': idClicked == NP1269 }" id="NP1269" >Base of a statue dedicated by the Boule, 333/332 B.C., Adrianou St. west of the church of Hag. Philippou, from the demolished church of Hag. Nikolaos Vlassarou (Plan 1 beta—3) - - - EM 3913 + IG II² 2791; Bull. 1963, no. 68; O. Palagia, op. cit., 111-113, pl. 36:1; SEG 46 (1996) no. 245</p>
<p :class="{'activeClass': idClicked == NP1270 }" id="NP1270" >Demokratia, Demos and Charites, Ptolemy III and Berenike, priest's theater bench - - - IG II² 4676, 5029 A; Maass, M. 1972, 108-113, pls. 7, 8, 23; Monaco, M. C. 2001, 133-134 See also: Athena Ath58*; Demos and D.; Theseus, picture; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM341">341</a></p>
<p :class="{'activeClass': idClicked == NP1271 }" id="NP1271" >DEMOPHANTOS the money-lender, house behind the Stoa Poikile: Lucian, DMeretr. 8.2; Agora III, no. 73</p>
<p :class="{'activeClass': idClicked == NP1272 }" id="NP1272" >DEMOS: Kron, U. 1979, 49-75, fig. 1-3, pl. 7-12; LIMC III, 1986, 375-382, nos. 4, 9, 32, 39, 43-48, 53-61, 65, 69-71, s.v. Demos (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP1273 }" id="NP1273" >Picture by Parrhasios: Plin. Nat. XXXV.69; perhaps together with Theseus, Palagia, 0. 1980, 58, 61</p>
<p :class="{'activeClass': idClicked == NP1274 }" id="NP1274" >Statue by Lyson in the Bouleuterion: Paus. 1.3.5; LIMC III, 1986, 377 no. 9, s.v. Demos (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP1275 }" id="NP1275" >DEMOS and CHARITES: Agora III, 59-61 nos. 125-132; Monaco, M. C. 2001, 103-150</p>
<p :class="{'activeClass': idClicked == NP1276 }" id="NP1276" >Dedication to Demos and Charites or to Roma and Demos and Charites, banqueting hero relief with relief busts of Hadrian (?) and Sokrates as pediment acroteria and an Athena relief in the pediment, Akr. - - - IG II² 4775; Peek, W. 1942, 57-58 no. 96, pl. 21 no. 1</p>
<p :class="{'activeClass': idClicked == NP1277 }" id="NP1277" >Temenos founded just after 229 B.C., on the lower north slope of Kolonos Agoraios: IG II² 844 lines 39-41; Travlos, J. 1971, 79-81, fig. 80B (marking the findspot of the altar of Aphrodite Hegemone of the Demos and Charites); Habicht, Chr. 1982, 84-93</p>
<p :class="{'activeClass': idClicked == NP1278 }" id="NP1278" >Demos and Charites and Roma, priest's theater throne IG II² 5047; Maass, M. 1972, 121, pl. 12 See also: Aphrodite Hegemone of the Demos and Charites; Demokratia, Demos and Charites</p>
<p :class="{'activeClass': idClicked == NP1279 }" id="NP1279" >DEMOS and DEMOKRATIA, document relief with the Law of Eukrates against tyranny, one copy to be set up by the entrance to the Areopagus Bouleuterion, the other in the Ekklesia, 337/336 B.C., below the north end of the Stoa of Attalos  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206524" target="_blank">Agora I 6524</a>; SEG 45 (1995) no. 65; Palagia, O. 1980, 23, 58-61, 63 no. 3, fig. 43; LIMC III, 1986, 373 no. 7, ph., s.v. Demokratia, and 379 no. 54, s.v. Demos (O. Alexandri); Lawton, C. L. 1995, 99-100 no. 38, pl. 20 and General Index s.v. Demokratia; Agora XVI, no. 73, 111-113; C. L. Lawton, op. cit. (under Arybbas), 123-124, fig. 8; <JBcom>[this stele was most probably set up west of the Panathenaic Way and to the east of the great fallen boulders at the entrance to the meeting-place of the Council of the Areopagus; the copy set up in the Ekklesia, i.e. the Pnyx Assembly Place, has not been found]</JBcom> See also: Demokratia, Demos and Charites</p>
<p :class="{'activeClass': idClicked == NP1280 }" id="NP1280" >DEMOS AND OTHERS: see also Athena Ath117*, Ath119*, Ath121*; Eutaxia; Hestia, Apollo etc.; Nymphs Nym10*; Theseus, pictures</p>
<p :class="{'activeClass': idClicked == NP1281 }" id="NP1281" >DEMOS OF THE LAKEDAIMONIANS: see Lakedaimonians</p>
<p :class="{'activeClass': idClicked == NP1282 }" id="NP1282" >DEMOSIA BALANEIA, public baths, 4th c. B.C., never used by Phryne: Ath. XIII, 590 f.</p>
<p :class="{'activeClass': idClicked == NP1283 }" id="NP1283" >DEMOSIA ΟΙΚΙΑ: see Oikia Demosia</p>
<p :class="{'activeClass': idClicked == NP1284 }" id="NP1284" >DEMOSION</p>
<p :class="{'activeClass': idClicked == NP1285 }" id="NP1285" >1) the public treasury: Anon. Argent. L. 6; perhaps in the lower town, Η. T. Wade-Gery and B. D. Meritt, “Athenian Resources in 449 and 431 B. C.”, Hesperia 26 (1957) 186-187</p>
<p :class="{'activeClass': idClicked == NP1286 }" id="NP1286" >2) the public archives in the Bouleuterion: IG I² 127 lines 28-30; Shear Jr., T. L. 1995, 186-188</p>
<p :class="{'activeClass': idClicked == NP1287 }" id="NP1287" >DEMOSION SEMA</p>
<p :class="{'activeClass': idClicked == NP1288 }" id="NP1288" >1* State Burial Ground for those fallen in battle by sea or land, on the Road to the Academy: Paus. 1.29.4-16; SEG 25 (1971) no. 56; Stupperich, R. 1977, 26-33; Pritchett, W. K. 1985, 95, 103-104, 105-124, 139-140, 145-151, 153-241 passim; Ritchie Jr., C. E. 1985, 770-786; Pritchett W. K. 1998, 1-60; SEG 51 (2005) nos. 49-50. For photographs of the Demosion Sema inscriptions, see C. W. Clairmont, Patria Nomos, 1983</p>
<p :class="{'activeClass': idClicked == NP1289 }" id="NP1289" ><JBcom>[The Demosion Sema was between two roads to the Academy, the Dipylon-Academy Road (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM565">565</a>) and the road through the Leokoriou St. Gate (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM566">566</a>). Most of the stelai were illegally excavated from ca. 1850 onwards and broken up to be reused as building material. The records of the Archaeological Society and the Agora Excavation field notebooks show that no fragment of a Demosion Sema inscription was ever found in the Post-Herulian Wall pace S. Alipheri, «Τά διεσπαρμένα μνημεία ως πηγές για την καταστροφή τού Δημοσίου Σήματος,» Horos 10-12 (1992-1998) 183-203, pl. 35 = SEG 46 (1996) no. 73]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1290 }" id="NP1290" >2* Bema for the epitaphios logos: Th. 2.34.8; Stupperich, R. 1977, 33-53; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM565">565</a></p>
<p :class="{'activeClass': idClicked == NP1291 }" id="NP1291" >3* Inscribed stelai in the Demosion Sema represented on a white ground sherd in the Allard Pierson Museum, Amsterdam: D. W. Bradeen, “The Athenian Casualty List of 464 B.C.”, Hesperia 36 (1967) 324-5, pl. 70:d</p>
<p :class="{'activeClass': idClicked == NP1292 }" id="NP1292" >DEMOSION SEMA (continued) Monuments for which the campaign sites are known</p>
<p :class="{'activeClass': idClicked == NP1293 }" id="NP1293" >4* AIGINETAN WAR, 491/490 B.C.: Paus. 1.29.7; Pritchett, W. K. 1985, 123, 148,165-166 no. 12</p>
<p :class="{'activeClass': idClicked == NP1294 }" id="NP1294" >5* AMPHIPOLIS, Kleon's expedition in 422 B.C.: Paus. 1.29.13; Clairmont, C. 1983, 188-189 no. 50; SEG 33 (1983) no. 39; Pritchett, W. K. 1985, 148</p>
<p :class="{'activeClass': idClicked == NP1295 }" id="NP1295" >6* ATHENS, Athenians fighting against Lachares, ca. 296 B.C.: Paus. 1.29.10; Habicht, Chr. 1979, 9, 11; Pritchett, W. K. 1985, 148 note 163</p>
<p :class="{'activeClass': idClicked == NP1296 }" id="NP1296" >7* ATHENS, Mouseion Fort, Olympiodoros' men, 288 B.C.: Paus. 1.29.13; Pritchett, W. K. 1985,148</p>
<p :class="{'activeClass': idClicked == NP1297 }" id="NP1297" >8* CHAIRONEIA, 338 B.C.: Paus. 1.29.13; Pritchett, W. K. 1985, 148, 222-226 no. 89</p>
<p :class="{'activeClass': idClicked == NP1298 }" id="NP1298" >Epigram on the base, near the Olympieion - - - IG II² 5226; SEG 37 (1987) no. 158</p>
<p :class="{'activeClass': idClicked == NP1299 }" id="NP1299" >Fragment, tentatively attributed - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206953" target="_blank">Agora I 6953</a>; Agora XVII, no. 25</p>
<p :class="{'activeClass': idClicked == NP1300 }" id="NP1300" >Fragment built into a late Roman vaulted tomb on the Road to the Academy Gymnasium, 23 Sphakterias St. (Plan 2 delta—2): O. Alexandri, «Σφακτηρίας 23,» Deltion 23 (1968) B1 Chr., 94-95 no. 81, pl. 53:a and fig. 42 showing the site of Tomb VIII; tentatively connected with the battle of Chaironeia, SEG 30 (1980) no. 191</p>
<p :class="{'activeClass': idClicked == NP1301 }" id="NP1301" >Fragment of a very large relief, rider and opponent(s) on foot, built into Roman foundations on the Road to the Academy Gymnasium, Plataion St. and 93 Kerameikou St. (Plan 2 delta—2)- - - 3rd Ephor. M 2347; tentatively <JBcom>[doubtably]</JBcom> assigned to Chaironeia, S. Kaempf-Dimitriadou, “Ein attisches Staatsgrabmal des 4. Jahrhunderts v. Chr.,” Antike Kunst 29 (1986) 22-35, fig. 1, pls. 2-3; for the findspot see Stichel, R. H. W. 1998, 158 with note 99, fig. 3,g; <JBcom>[the relief may come from a cenotaph set up by the cavalry]</JBcom> See also: Demosion Sema Sem29*</p>
<p :class="{'activeClass': idClicked == NP1302 }" id="NP1302" >9* CHERSONESE, ΒΥΖΑΝΤΙΟΝ, and OTHER WARS, 447 B.C.: Paus. 1.29.13 (Thrace and Megara); Clairmont, C. 1983, 164-169 no. 32, pls. 3:c and 45-46; Pritchett, W. K. 1985, 147, 148</p>
<p :class="{'activeClass': idClicked == NP1303 }" id="NP1303" >Epigram and list found in in house foundations in Elaiotriveion St. - - - IG I² 943 = IG l31162; Meiggs, R. and D. M. Lewis, 1969, 125-128 no. 48; CEG 1983, no.6; SEG 51 (2005) no. 51</p>
<p :class="{'activeClass': idClicked == NP1304 }" id="NP1304" >10* CORINTH and BOEOTIA (at Koroneia) 394/393 B.C.: inscription with relief of the battle, ca. 200 m. northeast of the Dipylon Gate at the intersection of Psaromilingou and Kalog. Samouil Sts. (Plan 2 delta—3) NM 2744, IG II² 5221; SEG 44 (1994) no. 180; Paus. 1.29.11 (Corinth only); M. N. Tod, A Selection of Greek Historical Inscriptions A II, 1948, 18-20 no. 104; S. Kaempf-Dimitriadou, op. cit. (Demosion Sema Sem8*), 31-32, pl. 4 no. 3; Pritchett, W. K. 1985, 212-214 no. 71; Kaltsas, N. 2002, 115 no. 313, ph.</p>
<p :class="{'activeClass': idClicked == NP1305 }" id="NP1305" >11* CORINTH and KORONEIA, 394/393 B.C., anthemion acroterion and inscription preserved, probably the cenotaph for twelve horsemen including Dexileos set up by the tribe Akamantis, found in 1861 by the tileworks 250 paces northeast of Haghia Triada at Kerameikou St., east of Plataion St. - - - NM 754, IG II² 5222; C. Curtius, “Der attische Friedhof vor dem Dipylon,”, AZ29 (1872) 34, pl. 42, inset lower left: Ziegelbrennerei; cf. Clairmont, C. 1983, 212-214 no. 68:b, pl. 3:a; SEG 48 (1998) no. 284; Pritchett, W. K. 1985, 212-214, no. 71; Stichel, R. H. W. 1998, 157, fig. 3(h); Kaltsas, N. 2002, 158-159 no. 312, ph.; <JBcom>[not a Demosion Sema monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1306 }" id="NP1306" >12* CYPRUS, Kimon's expedition, 449 B.C.: Paus. 1.29.13; Further Greek Epigrams 1981, 266-268 no.45; Pritchett, W. K. 1985, 148, 182-183 no. 26</p>
<p :class="{'activeClass': idClicked == NP1307 }" id="NP1307" >13* CYPRUS, EGYPT, PHOENICIA, HALIEIS, AIGINA, MEGARA, 460/459 B.C., found in 1674 in the church of the Stavromenou ( Chr. Mon. CM68*) — - IG I³ 1147 = IG I² 929; Louvre MA 863; Bradeen, D. W. and D. M. Lewis 1979, 240; SEG 34 (1984) no. 45; Pritchett, W. K. 1985, 179-180 no. 24</p>
<p :class="{'activeClass': idClicked == NP1308 }" id="NP1308" >14* DELION, 424 B.C.: Paus. 1.29.13; Bradeen, D. W. 1969, 145, 151, 153-154; SEG 33 (1983) no.43; Pritchett, W. K. 1985, 148</p>
<p :class="{'activeClass': idClicked == NP1309 }" id="NP1309" >15* DRABESKOS, THASOS, SIGEION, EION, KARDIA, 464 B.C., Athenians, allies and slaves - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207009" target="_blank">Agora I 7009</a>, IG I² 928 = IG I³ 1144; SEG 33 (1983) no.33; Agora XVII, 3-6 no. 1; Pritchett, W. K. 1985, 112-113, 123, 148, 178-179 no. 23</p>
<p :class="{'activeClass': idClicked == NP1310 }" id="NP1310" >Drabeskos: Paus. 1.29.4; Meiggs, R. 1972, 84-5, 416 (the Drabeskos casualties had a separate monument); G. Grimm, “Der schone Leagros . . .,” Antike Welt 32 (2001) 191-192, fig. 2; Pritchett, W. K. 1998, 38-40</p>
<p :class="{'activeClass': idClicked == NP1311 }" id="NP1311" >Leagros, son of Glaukon, and Sophanes, son of Eutychides, generals at Drabeskos: Paus. 1.29.5</p>
<p :class="{'activeClass': idClicked == NP1312 }" id="NP1312" >16* EUBOIA, CHIOS and THE BORDERS OF ASIA AND SICILY, 412 B.C.: Paus. 1.29.11; Clairmont, C. 1983, 191-2 no. 54, 259-60 note 3; SEG 33 (1983) no. 45; Pritchett, W. K. 1998, 44-53</p>
<p :class="{'activeClass': idClicked == NP1313 }" id="NP1313" >Athenian and Plataian casualties in Sicily: Paus. 1.29.12; Bradeen, D. W. 1969, 156-159; Pritchett, W. K. 1985, 149</p>
<p :class="{'activeClass': idClicked == NP1314 }" id="NP1314" >Stele, 412 B.C. <JBcom>[?]</JBcom>, intersection of Asomaton and Dipylou Sts. - - - Third Ephoria Λ 7066; dated to the year 412 B.C. and associated with IG I³ 1163 (19* below) found a few meters to the east of the stele, I. Tsirigoti-Drakotou, «Νέα στήλη πεσόντων από το Δημόσιον Σήμα. Μια πρώτη παρουσίαση,” Deltion 55 (2000) A' Meletes, 87-112, figs. 1-14; cf. Demosion Sema Sem19*</p>
<p :class="{'activeClass': idClicked == NP1315 }" id="NP1315" >17* EURYMEDON, 468 B.C.?: Paus. 1.29.14; cf. Agora XVII,11-12 no. 7; Further Greek Epigrams 1981, 268-272 no. 46; Pritchett, W. K. 1985, 123, 148, 177-178 no. 22</p>
<p :class="{'activeClass': idClicked == NP1316 }" id="NP1316" >18* HELLESPONT and IONIA, 409 B.C. IG l31191 = IG I² 954 + 957 + 964; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201008" target="_blank">Agora I 1008</a>+; 1st Ephor. Rho Alpha 1965 no. 20; Paus. 1.29.13; Agora XVII, 27-33 no. 23; SEG 33 (1983) no. 46; Pritchett, W. K. 1985, 148, 202-204 no. 59 (Battle of Ephesos); idem 1998, 8-10</p>
<p :class="{'activeClass': idClicked == NP1317 }" id="NP1317" >19* KORONEIA, 446 B.C., Tolmides, son of Tolmaios, and his men - - - IG I³ 1163 = IG I² 942; Paus. 1.29.14, cf. 1.27.5; D. W. Bradeen, “Athenian Casualty Lists,” Hesperia 33 (1964) 21-29 no. 5, figs. 1-2, pls. 2-3; SEG 40 (1990) no. 37; Pritchett, W. K. 1985, 148; cf. no. 16* above</p>
<p :class="{'activeClass': idClicked == NP1318 }" id="NP1318" >Base, three blocks with epigram, Dipylou and Leokoriou Sts. at a gate in the City Wall, Themistoklean City Wall Wal9* below (Plan 2 epsilon—3): Skilardi, D. 1968, 36 with note 7; Clairmont, C. 1983, 159-164 no. 31:b, pls. 42-44; CEG 1983, no. 5</p>
<p :class="{'activeClass': idClicked == NP1319 }" id="NP1319" >Three stelai fragments, two first seen in the Roman Agora - - - EM 12883+</p>
<p :class="{'activeClass': idClicked == NP1320 }" id="NP1320" >20* LAMIA, the men led to Thessaly by Leosthenes, 323/322 B.C.: Paus. 1.29.13, 7.10.5; cf. 1.25.5; Pritchett, W. K. 1985, 148, 227-228 no. 94</p>
<p :class="{'activeClass': idClicked == NP1321 }" id="NP1321" >Leosthenes buried in heroic fashion: D.S. 18.13.5; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM477">477</a></p>
<p :class="{'activeClass': idClicked == NP1322 }" id="NP1322" >21* MANTINEA, 418 B.C: Paus. 1.29.13; SEG 33 (1983) no. 209; Pritchett, W. K. 1985, 147, 148</p>
<p :class="{'activeClass': idClicked == NP1323 }" id="NP1323" >22* MUNYCHIA, 285 B.C.: Paus. 1.29.10; Habicht, Chr. 1979, 98</p>
<p :class="{'activeClass': idClicked == NP1324 }" id="NP1324" >23* OLYNTHOS campaign, 349 B.C.: Paus. 1.29.7; Pritchett, W. K. 1985, 23</p>
<p :class="{'activeClass': idClicked == NP1325 }" id="NP1325" >24* POTEIDAIA, stele base, 432 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202277" target="_blank">Agora I 2277</a>, IG I² 945 = IG l31179; London, BM GR 1816.6 - 10.348; CEG, 1983, no. 10; SEG 33 (1983) no. 41; F. T. Miller, “Philosophical Themes in early Greek Grave Inscriptions,” in Preliminary Publication 11th Congress 1997, 45-46</p>
<p :class="{'activeClass': idClicked == NP1326 }" id="NP1326" >25* POTEIDAIA, AMPHIPOLIS, THRACE, PYLOS, SERMYLIA, SINGOS, 423 B.C., Athenian and other casualties - - - IG l31184 = IG I² 949, SEG 25 (1971) no. 57; Bradeen, D. W. and D. M. Lewis 1979, 244</p>
<p :class="{'activeClass': idClicked == NP1327 }" id="NP1327" >26* SYRACUSE, 414 B.C.: Paus. 1.29.13; Clairmont, C. 1983, 189-190 no. 52; Pritchett, W. K. 1985, 147, 148</p>
<p :class="{'activeClass': idClicked == NP1328 }" id="NP1328" >27* TANAGRA, 458/457 B.C.: Pritchett, W. K. 1985, 115-116, 180-182 no. 25</p>
<p :class="{'activeClass': idClicked == NP1329 }" id="NP1329" >Argive allies, 13 fragments found on the Akropolis and in the Agora Exc. and 1 fragment found in the Kerameikos Exc. reused in the 19th c. renovation of chapel of Haghia Triada (Chr. Mon. CM50*): - - - IG I³ 1149 = IG I² 931-932; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202006" target="_blank">Agora I 2006</a>, EM 13305-13; Paus. 1.29.8 + 9; Pritchett, W. K. 1985, 149, 150, 181-182 no. 2; SEG 46 (1996) no. 2341</p>
<p :class="{'activeClass': idClicked == NP1330 }" id="NP1330" >Kleonaians who came to Attica with Argives: Paus. 1.29.7; Pritchett, W. K. 1985, 149, 182 no. 3</p>
<p :class="{'activeClass': idClicked == NP1331 }" id="NP1331" >Epigram perhaps commemorating Athenian losses at Tanagra, built into a wall on Mousaion St. (now called Karori St.) northwest of Hag. Irini (Plan 2 epsilon—4)- - - IG I³ 1181 = IG I² 946; SEG 46 (1996) no. 72; CEG, 1983, no. 4; Bugh, G. R. 1988, 44-45; Pritchett, W. K. 1985, 180-181 no. 1</p>
<p :class="{'activeClass': idClicked == NP1332 }" id="NP1332" >DEMOSION SEMA (continued), campaign sites not known, selected monuments</p>
<p :class="{'activeClass': idClicked == NP1333 }" id="NP1333" >28* ATHENIAN HORSEMEN who fell with the Thessalians, beginning of the Archidamian War, 431 B.C.: Paus. 1.29.6; Pritchett, W. K. 1985,148</p>
<p :class="{'activeClass': idClicked == NP1334 }" id="NP1334" >29* ATHENIAN SLAVES faithful to their masters in war: Paus. 1.29.7; probably for those fallen at Chaironeia, Pritchett, W. K. 1985, 146</p>
<p :class="{'activeClass': idClicked == NP1335 }" id="NP1335" >30* ATHENIANS fighting against Cassander, 304 B.C.?: Paus. 1.29.8; Pritchett, W. K. 1985, 148 note 162</p>
<p :class="{'activeClass': idClicked == NP1336 }" id="NP1336" >31* ATHENIANS helping Romans in Italy and against Carthaginians, ca. 200 B.C.: Paus. 1.29.14; Pritchett, W. K. 1985,148-149 note 164</p>
<p :class="{'activeClass': idClicked == NP1337 }" id="NP1337" >32* ATHENIANS and BARBARIAN ARCHERS, 435 B.C.? - - - Kerameikos I 66; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203181" target="_blank">Agora I 3181</a>, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206523" target="_blank">Agora I 6523</a>, EM 2492, IG I² 944 = IG l31180; Agora XVII, 20-21 no. 17; Clairmont, C. 1983, 178-180 no. 43, pls. 56-57; Pritchett, W. K. 1985, 155-158, 186-187 no. 32</p>
<p :class="{'activeClass': idClicked == NP1338 }" id="NP1338" >33* ATHENIANS and BARBARIAN ARCHERS - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204031" target="_blank">Agora I 4031</a> +, IG I³ 1192 = IG I² 951-2; Agora XVII, 25-27 no. 22</p>
<p :class="{'activeClass': idClicked == NP1339 }" id="NP1339" >33** ATHENIAN CASUALTIES, 460-450 B.C., five fragments from at least three stelai, three fragments found just northwest of Plateia Monastirakiou at 9 Miaouli St. - - - IG I³ 1150 = IG I² 933+; 3rd Ephoria Μ 1213A. P. Matthaiou, «Κ(χτάλογο5 θανάντων έν πολέμωι,» Horos 5 (1987) 25-29; SEG 37 (1987) no. 49</p>
<p :class="{'activeClass': idClicked == NP1340 }" id="NP1340" >34* ATHENIAN CASUALTIES in the Archidamian War - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204168" target="_blank">Agora I 4168</a> +; “430 B.C. seems the most likely”, Agora XVII, 21-22 no. 18; SEG 33 (1983) no. 42</p>
<p :class="{'activeClass': idClicked == NP1341 }" id="NP1341" >35* ATHENIAN CASUALTIES ca. 411 B.C. - - - IG I³ 1186 = IG I² 955 +; SEG 48 (1998) no. 84</p>
<p :class="{'activeClass': idClicked == NP1342 }" id="NP1342" >36* ALLIES, mid-5th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201207" target="_blank">Agora I 1207</a>, IG I³ 1158a = IG I² 851; Agora XVII,12 no. 8</p>
<p :class="{'activeClass': idClicked == NP1343 }" id="NP1343" >37* CRETAN ARCHERS, casualties in the Sicilian expedition, 414 B.C.: Paus. 1.29.6; Clairmont, C. 1983, 191 no. 53</p>
<p :class="{'activeClass': idClicked == NP1344 }" id="NP1344" >38* IONIAN ALLIES, mid-5th c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20895" target="_blank">Agora I 895</a> + I 6992, EM 5164; Agora XVII, 12-14 no. 9; Pritchett, W. K. 1985, 149, 150-151</p>
<p :class="{'activeClass': idClicked == NP1345 }" id="NP1345" >39* THESSALIAN HORSEMEN, casualties in the Archidamian War, 431 B.C.: Paus. 1.29.6; Pritchett, W. K. 1985, 149</p>
<p :class="{'activeClass': idClicked == NP1346 }" id="NP1346" >40* DUBITANDUM, the Albani Relief, found in Rome, horse and dismounting rider, widely believed to be from the Demosion Sema - - - Villa Albani no. 985; Ridgway, B. S. 1981, 144-145, 157, figs. 104-105; <JBcom>[flimsily]</JBcom> linked to the Corinth and Koroneia monument (Demosion Sema Sem11* above, IG II² 5222), Clairmont, C. 1983, 177-178 no. 42b and 212-214 no. 68b</p>
<p :class="{'activeClass': idClicked == NP1347 }" id="NP1347" >41* DUBITANDUM, casualty lists of cavalrymen with a relief of a battle on rocky terrain, 420-400 B.C.?, Palaiologou Ventilation Shaft, east of the Larissa railroad station - - - 3rd Ephor. M. 4551; Metro Excavations 1992-1997, 396-399 no. 452, ph. (L. M. Parlama); <JBcom>[given that one list names only 19 hippeis plus one hippotoxotes and the other only 12 hippeis with no foot soldiers listed, this stele could hardly have been set up in the Demosion Sema and is most likely a cenotaph]</JBcom> See also: Melanopos and Makartatos; Melesandros; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM476T">476T</a></p>
<p :class="{'activeClass': idClicked == NP1348 }" id="NP1348" >DEMOSTHENES</p>
<p :class="{'activeClass': idClicked == NP1349 }" id="NP1349" >House: Him. Or. LXIV.3</p>
<p :class="{'activeClass': idClicked == NP1350 }" id="NP1350" >Subterranean study existing in Plutarch's time: Plu. Dem. 7.3</p>
<p :class="{'activeClass': idClicked == NP1351 }" id="NP1351" >Statue by Polyeuktos set up by Demochares, 280-279 B.C., near the Ares sanct.: Paus. 1.8.2 + 4; bronze, with epigram, Plu. Dem. 30.5-31.1; Agora III, 210-211; E. Diez, “Eine neue Kopfreplik des Demosthenes,” JOAI 50 (1972-1975) 5-6</p>
<p :class="{'activeClass': idClicked == NP1352 }" id="NP1352" >Statue base, 2nd c. B.C., Akropolis South Slope — IG II² 4263; Richter, G. M. A. 1965, II, 220 no. 44, fig. 1498</p>
<p :class="{'activeClass': idClicked == NP1353 }" id="NP1353" >Head, found when the palace (now the Parliament Bldg.) foundations were laid (Plan 2 epsilon/zeta—6) - - - NM 327; Richter, G. M. A. 1965, II, 220 no. 41, figs. 1489-1490; Kaltsas, N. 2002, 273 no. 571, ph.</p>
<p :class="{'activeClass': idClicked == NP1354 }" id="NP1354" >Portrait bust, small, 31 Ermou St. near intersection with Phokionis St. (Plan 1 beta—8/9): Dontas, G. S. 1969, 73-74, fig. 3; Trianti, I. 2002, 160-162, figs. 14, 16-17; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM510B">510B</a></p>
<p :class="{'activeClass': idClicked == NP1355 }" id="NP1355" >Statue base: see Eueteria</p>
<p :class="{'activeClass': idClicked == NP1356 }" id="NP1356" >Demosthenes' Lantern: see Lysikrates Monument</p>
<p :class="{'activeClass': idClicked == NP1357 }" id="NP1357" >DESMOTERION, the state prison, also called oikema: Pl. Phd. 59 D; Agora III, 149-150; <JBcom>[mistakenly]</JBcom> located in the Poros Building (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM308">308</a>), Vanderpool, E. 1980, 17-31; <JBcom>[plausibly located]</JBcom> near or at the Theseion, S. N. Koumanoudes, “Perhaps → usually → certainly,” Horos 2 (1984) 71-79; Rhodes, P. J. 1981, 580</p>
<p :class="{'activeClass': idClicked == NP1358 }" id="NP1358" >DEUKALION, grave not far from the present temple of Zeus Olympios: Paus.1.18.8; F GR HIST III B, suppl. vol. 1, 400, no. 328 F 95; Kearns, E. 1989,155</p>
<p :class="{'activeClass': idClicked == NP1359 }" id="NP1359" >DEXILEOS, son of Lysanias, cenotaph with conquering rider relief, 394 B.C. - - - Kerameikos I 220; Kerameikos P 1130, IG II² 6217; SEG 37 (1987) no. 165 and 49 (1999) no. 239; E. Vermeule, “Five Vases from the Grave Precinct of Dexileos,” Jahrbuch 85 (1970) 94 -111, figs. 1-11; C. N. Edmonson, “IG II², 6217: A Footnote to the Restoration of the Athenian Democracy,” AIA Summaries, 1975, 37-38; Knigge, U. 1991, 41, 111-113, figs. 3:a-b (color photos in back), fig. 107, fig. 165 no. 18; cf. Demosion Sema Sem11*</p>
<p :class="{'activeClass': idClicked == NP1360 }" id="NP1360" >DEXION, the Hero Sophokles: see Sophokles, Sanctuary</p>
<p :class="{'activeClass': idClicked == NP1361 }" id="NP1361" >DEXIPPOS I, son of Ptolemaios = P. Herennius Dexippos Hermeios (PIR, 2nd ed., 104); F. Millar, “P. Herennius Dexippos: the Greek World and the Third-Century Invasions,” JRS 59 (1969) 12-29; Kapetanopoulos, E. 1972, 133-155; Byrne, S. G. 2003, 295 no. 19</p>
<p :class="{'activeClass': idClicked == NP1362 }" id="NP1362" >Archon Herennius Dexippos, rock-cut inscr., 276-282 A.D., at the cave sanctuary of Apollo Hypoakraios (Apollo Apo2*) on the Akr. northwest slope - - - IG II² 2931; Travlos, J. 1971, fig. 119; Nulton, P. E. 2000, 47-48, 94-95 no. 14 with drawing</p>
<p :class="{'activeClass': idClicked == NP1363 }" id="NP1363" >Inscr, recording that Dexippos set up the edos of the goddess, Propylaia southwest wing, on the west face of the central pier - - - IG II² 3198; for an improved reading, see Mansfield, J. M. 1989, 101 note 61</p>
<p :class="{'activeClass': idClicked == NP1364 }" id="NP1364" >Base of a statue set up by Dexippos1 children: E. Kapetanoupoulos, op. cit, 135 note 3</p>
<p :class="{'activeClass': idClicked == NP1365 }" id="NP1365" >1st use: statue of an Epiktetos, 150-200 A.D. IG II² 3625</p>
<p :class="{'activeClass': idClicked == NP1366 }" id="NP1366" >Reused for Dexippos, 270 A.D. or later IG II² 3669; SEG 33 (1983) no. 186; cf. E. Voutiras, “Ein wiedergewonnenes attisches Portrat der Spatantike,” AM 96 (1981) 201-208 (especially the last sentence), pis 63-68; Sironen, E. 1997, 55-59 no. 4</p>
<p :class="{'activeClass': idClicked == NP1367 }" id="NP1367" >Herm set up by Dexippos' son and daughter, Herrenius Dexippos II and Herennia</p>
<p :class="{'activeClass': idClicked == NP1368 }" id="NP1368" >Hermonakteia, with Christian cross and graffito on the chest IG II² 3670;</p>
<p :class="{'activeClass': idClicked == NP1369 }" id="NP1369" >Kapetanopoulos, E. 1972, 135 note 2, 143-44 under Stemma C</p>
<p :class="{'activeClass': idClicked == NP1370 }" id="NP1370" >DEXIPPOS, son of Lysikles, statue base with epigrams, late 4th - early 5th c. A.C. or later ?, Library of Hadrian - - - IG II² 4008; Sironen, E. 1997, 78-81 no. 21; SEG 51 (2001) no. 221</p>
<p :class="{'activeClass': idClicked == NP1371 }" id="NP1371" >DIATEICHISMA</p>
<p :class="{'activeClass': idClicked == NP1372 }" id="NP1372" >Kleon's diateichisma: schol. Ar. Eq. 817-818; Thompson H. A. and R. L. Scranton 1943, 334-336</p>
<p :class="{'activeClass': idClicked == NP1373 }" id="NP1373" >Diateichisma from the Mouseion Fort to the Hill of the Nymphs, the Compartment Wall, <JBcom>[allegedly]</JBcom> built between 307 and 294 B.C. <JBcom>[?]</JBcom>(Plan 2 zeta/theta—3): IG II² 463 line 53; Thompson H. A. and R. L. Scranton 1943, 301-340, figs. 18-20, 22-39, 42, pls. 14-16; Conwell, D. H. 1993, 153-154; L. Karlsson, The City Walls on the Pnyx Put into Context,” in Pnyx 1996, 87-92, figs. 58-60, 61 a-b; D. H. Conwell, The White Poros Wall on the Athenian Pnyx: Character and Context,” in Pnyx 1996, 93-101, Plan 1; according to D. H. Conwell's recent research, communicated in the summer of 2003, this diateichisma was constructed in the second or third decades of the 3rd c. B.C. and is not attested by IG II² 463 line 53 which refers to an entirely different, earlier diateichisma that must have been constructed some time before 307/6 B.C., the date of the inscription. <JBcom>[A section of the Themistoklean City Wall became a diateichisma when the Long Walls were built.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1374 }" id="NP1374" >Rooftiles stamped ΔΗΜΟΣΙΑ: Thompson, H. A. 1936, 194-195, 199, fig. 27</p>
<p :class="{'activeClass': idClicked == NP1375 }" id="NP1375" >Gate at Hag. Dimitrios Loumbardiaris in the saddle between the Pnyx Hill and the Mouseion Hill (Plan 2 eta—3): <JBcom>[mistakenly]</JBcom> equated with the Dipylon-above-the-Gates mentioned in IG II² 463 line 53, Thompson, H. A. and R. L. Scranton 1943, 312-324, 352-356, 367-372, 376-378, figs. 28-34, pl. 14; <JBcom>[mistakenly]</JBcom> identified as the Dipylon-above-the-Gates, Travlos, J. 1971, 160-161, Gate XIV, figs. 28, 219; cf. Dipylon Gate</p>
<p :class="{'activeClass': idClicked == NP1376 }" id="NP1376" >Gate between the Pnyx and the Hill of the Nymphs destroyed by quarrying in the saddle: Judeich, W. 1931, 161, Plan I at C-5; <JBcom>[mistakenly]</JBcom> identified as the Melitides Gate (which was in the Themistoklean city Wall), Travlos, J. 1971, 161, figs. 28, 219, Gate XV; see Melitides Gate</p>
<p :class="{'activeClass': idClicked == NP1377 }" id="NP1377" >DIEITREPHES, bronze statue of D. pierced with arrows, Akropolis: Paus. 1. 23.3-4; probably identical with the vulneratus by Kresilas, Pliny N.H. 34.74-75; Pollitt, J. J. 1990, 69</p>
<p :class="{'activeClass': idClicked == NP1378 }" id="NP1378" >Statue base signed by Kresilas, dedicated by Hermolykos, son of Dieitrephes, ca. 410-400 B.C., found April 1839 west of the Parthenon - - - IG I³ 883 = IG I² 527; Acr. 13201; C. M. Keesling, “The Hermolykos/Kresilas Base and the Date of Kresilas of Kydonia,” ZPE 147 (2004) 79-91, fig. 1</p>
<p :class="{'activeClass': idClicked == NP1379 }" id="NP1379" >DIKAIOSYNE: see Isis Dikaiosyne</p>
<p :class="{'activeClass': idClicked == NP1380 }" id="NP1380" >DIKTYNNA: see Artemis Art31*</p>
<p :class="{'activeClass': idClicked == NP1381 }" id="NP1381" >DIKTYS AND KLYMENE, altar: Paus. 11.18.1</p>
<p :class="{'activeClass': idClicked == NP1382 }" id="NP1382" >DIOCHARES: PAA 367960</p>
<p :class="{'activeClass': idClicked == NP1383 }" id="NP1383" >Diochares' baths near Diochares' Gate: IG II² 2495 lines 7-8; see Athena Ath16*; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM502">502</a></p>
<p :class="{'activeClass': idClicked == NP1384 }" id="NP1384" >Diochares' gate in the Themistoklean City Wall by Diochares' Baths and near the Lykeion: Str. IX.3S7; IG II² 2495 lines 6-7; Judeich, W. 1931, 49, 142-143, 164, 184 note 3, 415, Plan I H-4, Plan IV; Ritchie Jr., C. E., 1989, 251-254, 257; cf. Themistoklean City Wall Wal15*</p>
<p :class="{'activeClass': idClicked == NP1385 }" id="NP1385" >DIODOROS of Sinope, comic poet, bronze statue in the theater, 294 B.C.: IG II² 648 line 4; Habicht, Chr. 1979, 13-15</p>
<p :class="{'activeClass': idClicked == NP1386 }" id="NP1386" >DIOGENES THE CYNIC</p>
<p :class="{'activeClass': idClicked == NP1387 }" id="NP1387" >Diogenes' pithos in the Metroon: D.L. 6.23</p>
<p :class="{'activeClass': idClicked == NP1388 }" id="NP1388" >Diogenes' lantern, to; φανάρι τού Διογένη, nickname for a bldg. near the Lysikrates Mon.: Laborde, L. E. S. J., Comte de 1854, facing page 228, no. 16 on the plan drawn by Guillet in 1672; Miller, S. 1970, 227</p>
<p :class="{'activeClass': idClicked == NP1389 }" id="NP1389" >DIOGENES EUERGETES: Gauthier, Ph. 1985, 64-65</p>
<p :class="{'activeClass': idClicked == NP1390 }" id="NP1390" >Temenos, the Diogeneion, surrounded by a wall repaired before 105 B.C.: IG II² 1011 line 41; ephebic headquarters, east of the Tower of the Winds, S. Dow, «ΟΙ ΠΕΡΙ ΤΟ ΔΙΟΓΕΝΕΙΟΝ,» HSCP 63 (1958) 423-436; Delorme, J. 1960, 523 s.v. Athenes (Diogeneion); cf. Arcadius and Honorius; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM210">210</a></p>
<p :class="{'activeClass': idClicked == NP1391 }" id="NP1391" >Diogenes' heroon in the Diogeneion: <JBcom>[plausibly]</JBcom> conjectured by Louis Robert, “Une epigramme satirique d'Automedon et Athenes au debut de l'Empire,”, REG 94 (1981) 359 note 101 ( “je crois pouvolr ajouter que Diogenes avait une tombe heroique de fondateur . . <JBcom>[perhaps a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1392 }" id="NP1392" >Ephebic document with reliefs, from the Diogeneion, 254/5 A.D., Hag. Dimitrios tou Katiphori (Plan 1 delta—7) IG II² 2245</p>
<p :class="{'activeClass': idClicked == NP1393 }" id="NP1393" >Relief above: head of Athena and two amphoras</p>
<p :class="{'activeClass': idClicked == NP1394 }" id="NP1394" >Relief below: Dexippos II (= Herennius Dexippos, son of Dexippos I) in a boat; Kapetanopoulos, E. 1972, 135, 143-144 Stemma C (A 22), 148-151 (H 10)</p>
<p :class="{'activeClass': idClicked == NP1395 }" id="NP1395" >Theater throne (double seat together with the priest of eponymous Attalos) IG II² 5080; Maass, M. 1972, 92, 139-140, pl. 22</p>
<p :class="{'activeClass': idClicked == NP1396 }" id="NP1396" >DIOMEDES, son of Tydeus, carrying off the Palladion from Troy</p>
<p :class="{'activeClass': idClicked == NP1397 }" id="NP1397" >Picture in the Propylaia northwest chamber: Paus. 1.22.6; Frazer, J. G. 1913, II, 263-265. Although in his description of the pictures of Diomedes and Odysseus Pausanias does not clearly specify who stole the Palladion and who the bow of Philoktetes, Pausanias I. 28. 10 implies that Diomedes stole the Palladion</p>
<p :class="{'activeClass': idClicked == NP1398 }" id="NP1398" >Diomedes carrying off the Palladion, unfinished sculpture, 2nd-1st c. B.C., found built into the wall of a late Roman house just inside the City Wall between Aristeidou St. and Plateia Klafthmonos: E. Vanderpool, “News Letter from Greece,”, AJA 61 (1957) 282, pl. 83 fig. 2; G. Despinis, “Studien zur hellenistischen Plastik II: Statuengruppen. Diomedes und Odysseus,” AM 111 (1996) 299-323 (esp. 301-304), fig. 53:2</p>
<p :class="{'activeClass': idClicked == NP1399 }" id="NP1399" >DIOMEDES, son of Athenodoros, comic poet, 2nd c. B.C. statue base in the theater - - - IG II² 4257; SEG 33 (1983) no. 195</p>
<p :class="{'activeClass': idClicked == NP1400 }" id="NP1400" >DIOMEIA, DIOMOS</p>
<p :class="{'activeClass': idClicked == NP1401 }" id="NP1401" >Diomeia, deme named after the hero Diomos, a son of Kollytos: Harp. s.v. εν Διομείοις Ηράκλειον; southeast of the City Wall, Traill, J. S. 1986,128 with Map; G. Wissowa, RE V.1905, col. 831-832 (Diomos); Billot, M.-F. 1992, 121,124-125,128-129,142,144,154</p>
<p :class="{'activeClass': idClicked == NP1402 }" id="NP1402" >Diomeian Gate in the Themistoklean City Wall: Hsch. s.v. Δημίασι πυλαίς; Judeich, W. 1931, 141 note 2, 142, 143 note 1, 170, 179 note 3, Plan I G-7, Plan IV; a hitherto unidentified gate southwest of the Olympieion on a road leading to the Ilissos and Kynosarges, Billot, M.-F. 1992, 123, 143-145; cf. Themistoklean City Wall Wal20*</p>
<p :class="{'activeClass': idClicked == NP1403 }" id="NP1403" >Diomos, eponymous hero of the deme Diomeia, cult: IG II² 1247 lines l8-19; Whitehead, D. 1986, 210; Kearns, E. 1989, 97-98,156; Billot, M.-F. 1992, 124-125,</p>
<p :class="{'activeClass': idClicked == NP1404 }" id="NP1404" >DIONE</p>
<p :class="{'activeClass': idClicked == NP1405 }" id="NP1405" >Altar at the Erechtheion east porch: IG I³ 476 lines 75, 93-94, 102-103, 194-195, 223-224, 306-307, 322; perhaps set up during the Peloponnesian War, LIMC 111, 1986, 411, s.v. Dione (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP1406 }" id="NP1406" >Throne, dedication, Akropolis Acr. 4047; IG II² 4643; dedicated by Phile, daughter of Niketes, possibly the eponymous archon of 332/1 B.C., Palagia, O. 2002, 176-178, figs. 10-11</p>
<p :class="{'activeClass': idClicked == NP1407 }" id="NP1407" >Theatre seat - - - IG II² 5113 See also: Zeus Zeu68*</p>
<p :class="{'activeClass': idClicked == NP1408 }" id="NP1408" >DIONYSION, a precinct named in the decree concerning the sanctuary of Kodros and Neleus and Basile: IG I³ 84 line 35; <JBcom>[correctly]</JBcom> identified as the precinct of Dionysos Eleuthereus, Wheeler, J. R. 1887, 48; possibly the sanct. of Dionysos in Limnai, Pickard-Cambridge, A. 1968, 24-25; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM559">559</a></p>
<p :class="{'activeClass': idClicked == NP1409 }" id="NP1409" >DIONYSIOS, tragedy writer, statue base in the theater - - - IG II² 4268; Ghiron-Bastagne, P. 1976, 100, fig. 41</p>
<p :class="{'activeClass': idClicked == NP1410 }" id="NP1410" >DIONYSIOS' school where Plato was educated: D.L. 3.4</p>
<p :class="{'activeClass': idClicked == NP1411 }" id="NP1411" >DIONYSIOS THE AREOPAGITE (St Denis)</p>
<p :class="{'activeClass': idClicked == NP1412 }" id="NP1412" >Legendary house on the Areopagus north Slope, first mentioned in 1602: François Arnaud, Voiage en Levant et en Jerusalem, 1602; cited in testimonium no. 2, Travlos, J. and Alison Frantz 1965,187,190, 194-195 testimonia nos. 2, 3 See also: Areopagus Meeting Place of the Council; Chr. Mon. CM11*, CM17*, CM36*, CM60*; Kornelios Onomarchos</p>
<p :class="{'activeClass': idClicked == NP1413 }" id="NP1413" >DIONYSOS: LIMC III, 1986, 499-512, s.v. Dionysos (C. Gasparri)</p>
<p :class="{'activeClass': idClicked == NP1414 }" id="NP1414" >1* Arula with Medusa head, dedicated by Zosimos, son of Eireneos, 2nd c. A.C., Pompeion area IG II² 4789; S. A. Koumanoudes, «Αρχαιολογικοί είδησεις και έπιγρφαι Άττικής ανέκδοτοι,» Athenaion 1 (1872) 403; idem, «Μια εικασία,» Athenaion 2 (1873) 135; SEG 48 (1998) no. 261</p>
<p :class="{'activeClass': idClicked == NP1415 }" id="NP1415" >Altars: see Nymphs Nym1*; Kourotrophos, reused altar Nym2* Priest's theater seat - - - IG II² 5094 Nym3* Priest's theater seat - - - IG II² 5126</p>
<p :class="{'activeClass': idClicked == NP1416 }" id="NP1416" >4* Temple <JBcom>[probably but not necessarily of Dionysos]</JBcom>, used for the procession of the cult statue of D. Eleuthereus: Paus. 1.29.2; at the Academy, Philostr. VS 11.1.3; Pickard-Cambridge, A. 1968, 60-61, 62, 65</p>
<p :class="{'activeClass': idClicked == NP1417 }" id="NP1417" >5* Eschara whence the ephebes escorted Dionysos Eleuthereus to the theater, recorded in an ephebic decree, 122/1 B.C.: IG II² 1006 lines 12-13; Romano, I. B. 1981, 75</p>
<p :class="{'activeClass': idClicked == NP1418 }" id="NP1418" >6* Temple of Dionysos on the ancient Tripodes street: Paus. 1.20.2, <JBcom>[surely referring to a choregic monument]</JBcom>; cf. Lysikrates Mon.</p>
<p :class="{'activeClass': idClicked == NP1419 }" id="NP1419" >Theater of Dionysos in the Agora: see Orchestra</p>
<p :class="{'activeClass': idClicked == NP1420 }" id="NP1420" >7* Tripod base with six reliefs, dedicated to Dionysos, originally in Athens, later dedicated at the sanctuary of Zeus Olympios founded by Hadrian at Mt. Gerizim, Nablus, Palestine — Istanbul, Archaeological Museum M 638; Froning, H. 1981, 46-47, figs. 2-4, pl. 7; information about the sanctuary contributed by Jennifer Tobin on June 9, 2001</p>
<p :class="{'activeClass': idClicked == NP1421 }" id="NP1421" >Side 1: Goddess mounting a snake chariot; Herakles and Acheloos</p>
<p :class="{'activeClass': idClicked == NP1422 }" id="NP1422" >Side 2: Herakles strangling snakes; Theseus lifting the rock</p>
<p :class="{'activeClass': idClicked == NP1423 }" id="NP1423" >Side 3: Artemis, Apollo, Leto, Python: LIMC II, 1984, 303 no. 997, ph., s.v. Apollon (W. Lambrinudakis); Theseus and the Minotaur</p>
<p :class="{'activeClass': idClicked == NP1424 }" id="NP1424" >DIONYSOS (CONTINUED) SCULPTURE AND RELIEFS</p>
<p :class="{'activeClass': idClicked == NP1425 }" id="NP1425" >8* Statue, Sardanapalos type, early 1st c. B.C., near the theater NM 1656; E. Pochmarski,” Neue Beobachtungen zum Typus Sardanapal,” JOAI 50 (1972-75) 41-67, especially 41 A1, 44-46, 61-67, figs. 1-2; Kaltsas, N. 2002, 245 no. 512, ph.</p>
<p :class="{'activeClass': idClicked == NP1426 }" id="NP1426" >9* Statue in the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.14.1; Agora XIV, 129 note 71</p>
<p :class="{'activeClass': idClicked == NP1427 }" id="NP1427" >9** Unfinished statuette, youthful Dionysos with a huge grape vine in a rural landscape, 3rd or 4th c. A.C., reused in a drain along the Sacred Way Kerameikos P 1540; F. Duthoy, “Die unvollendeten Marmorskulpturen des Kerameikos,” AM 115 (2000) 120-121 no. 2, pl. 10</p>
<p :class="{'activeClass': idClicked == NP1428 }" id="NP1428" >9*** Unfinished sculpture of Dionysos and satyr, end of 2nd c. A.C. or later, found at the north enclosure wall of the Olympieion — NM 245; E. Pochmarski, Dionysische Gruppen, Vienna, 1990, 319 (P 20), 396 (Museumsregister) s.v. National Museum P 20, pl. 67:1</p>
<p :class="{'activeClass': idClicked == NP1429 }" id="NP1429" >10* Mask of Dionysos said to be a portrait of Peisistratos: Ath. XII 533 C; F. Kolb, “Bau-, Religions- und Kulturpolitik der Peisistratiden,” Jahrbuch 92 (1977) 129-130 note 180</p>
<p :class="{'activeClass': idClicked == NP1430 }" id="NP1430" >11* Dionysos mask, ca. 470 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202485" target="_blank">Agora S 2485</a>; Shear Jr., T. L. 1973b, 402-404, pl. 74:d</p>
<p :class="{'activeClass': idClicked == NP1431 }" id="NP1431" >12* Dionysos and Maenad with satyrs, architectural relief, Odeion of Herodes Atticus - - - BM inv. GR 1816.6-10.193 (Smith, A. H. 1904, 225-226 no. 2154); W. R. Lethaby, “An Attic Cistern Front at the British Museum,” JHS 48 (1928) 7-8, fig. 1</p>
<p :class="{'activeClass': idClicked == NP1432 }" id="NP1432" >13* Reliefs from a base (?), 2nd-1st c. B.C., Regillis and Lykeiou Sts. in northeast Athens (Plan 2 zeta-1)- - - NM 3496-8; Blegen, E. P. 1937,138, pl. 7; Froning, H. 1981, 35 note 17; “probably the revetment of an altar,” Kaltsas, N. 2002, 288-289 no. 609, ph.</p>
<p :class="{'activeClass': idClicked == NP1433 }" id="NP1433" >Side 1:Dionysos and youth with oinochoe, Nikai leading bulls (NM 3496)</p>
<p :class="{'activeClass': idClicked == NP1434 }" id="NP1434" >Side 2: Satyrs supporting krater, Silens trampling grapes (NM 3498)</p>
<p :class="{'activeClass': idClicked == NP1435 }" id="NP1435" >Side 3: Pouncing panther (NM 3497)</p>
<p :class="{'activeClass': idClicked == NP1436 }" id="NP1436" >14* Dionysos and Nikai reliefs, tripod base, ca. 340 B.C., Akropolis east slope a bit above the Lysikrates Mon. - - - NM 1463; Kaltsas, N. 2002, 244-245 no. 511, ph.; SEG 51 (2001) no. 195</p>
<p :class="{'activeClass': idClicked == NP1437 }" id="NP1437" >15* Bromios and Nike, statues: attested by IG II² 3089, dedication of an agonothetes on a stele base, found in the Theater of Dionysos, carrying an epigram, ca. 200-150 B.C., referring to dedications to Hermes Enagonios, to the Technitai of Dionysos, and to statues of Nike and Dionysos, perhaps tripod supports made by Praxiteles; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.: Formanalytische Untersuchungen zur Kunst der Nachklassik,” Jahrbuch 88 (1973) 204-205; SEG 47 (1997) nos.206, 518; <JBcom>[implausibly]</JBcom> associated with the tripod base, Dionysos Dio14* above, SEG 51 (2001) no. 195</p>
<p :class="{'activeClass': idClicked == NP1438 }" id="NP1438" >15** Dionysos, Herakles and Maenad, tripod base reliefs, late 2nd c. B.C., northwest corner of the Middle Stoa <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20370" target="_blank">Agora S 370</a>; T. L. Shear, “The Sculpture found in 1933,”, Hesperia 4 (1935) 324 with fig. 11 (the base standing in situ) and 387-393 with figs. 15-17; LIMC III, 1986, 432 no. 91, ph., s.v. Dionysos (C. Gasparri); <JBcom>[mistakenly]</JBcom> identified as Aigeus, Theseus and Medea, “certainly a base for a tripod won in the Theseia,” Travlos, J. 1971, 234, fig. 311 “Theseus”  See also: Amphiktyon and D.; Dionysos Dio38*; Eros and D.; Hermes Hrm36*-37*; Kourotrophos; Lysikrates Mon. frieze; Thrasyllos Mon.; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM516">516</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM517">517</a></p>
<p :class="{'activeClass': idClicked == NP1439 }" id="NP1439" >16* DIONYSOS AULONEUS, priest's theater throne — IG II² 5078; Maass, M. 1972,138, pl. 21</p>
<p :class="{'activeClass': idClicked == NP1440 }" id="NP1440" >DIONYSOS BAKCHEIOS: see Bakcheion</p>
<p :class="{'activeClass': idClicked == NP1441 }" id="NP1441" >17* DIONYSOS ELEUTHEREUS, sanctuary on the Akropolis South Slope: Paus. 1.20.3 + 4; cult established after 508 B.C., Connor, W. R. 1990, 7-32; for the epithet Eleuthereus, ibid., 9-12, 17-18; cf. Dionysion</p>
<p :class="{'activeClass': idClicked == NP1442 }" id="NP1442" >18* Precinct wall and propylon: And. 1.38; Paus. 1.20.3; Boersma, J. S. 1970, 164 no. 23; restored plan, Travlos, J. 1971, fig. 678</p>
<p :class="{'activeClass': idClicked == NP1443 }" id="NP1443" >Altars: P. G. Kalligas, «Βωμοί,» Deltion 18 (1963) B1 Chr., 15-16, fig. 1 nos. 4-5 and 9, pl. 9 gamma</p>
<p :class="{'activeClass': idClicked == NP1444 }" id="NP1444" >19* Conglomerate foundations for a large altar or statue base, southeast of the later temple: Dörpfeld, W. and E. Reisch, Das griechische Theater, Athen, 1896 (Neudruck, Scientia Verlag Aalen, 1966), 23 pl. 1</p>
<p :class="{'activeClass': idClicked == NP1445 }" id="NP1445" >20* Cylindrical altar with satyr masks supporting a garland of grape clusters and ivy, dedicated by the brothers, Pistokrates and Apollodoros, archons of the genos Bakchiadai, 123-120 B.C., still in the Dionysos precinct but not in situ — IG II² 2949 (the inscr. does not name the god); Travlos, J. 1971, fig, 690; J. v. Freeden, «ΠΙΣΤΟΚΡΑΤΗΣ ΚΑΙ ΑΠΟΛΛΟΔΩΡΟΣ ΣΑΤΥΡΟΥ ΑΥΡΙΔΑΙ (IG II² 2949),» ZPE 61 (1985) 215-218, pl. VIII C</p>
<p :class="{'activeClass': idClicked == NP1446 }" id="NP1446" >Pictures</p>
<p :class="{'activeClass': idClicked == NP1447 }" id="NP1447" >21* Dionysos, Ariadne, Theseus: Paus. 1.20.3; Brommer, F. 1982, 90; LIMC III, 1986, 487-488 nos. 777-778, s.v. Dionysos (C. Gasparri)</p>
<p :class="{'activeClass': idClicked == NP1448 }" id="NP1448" >22* Dionysos leading Hephaistos back to Olympos: Paus. 1.20.3; Brommer, F. 1978, 35-36, 118-9, 199-205, 235</p>
<p :class="{'activeClass': idClicked == NP1449 }" id="NP1449" >23* Dionysos punishing Pentheus: Paus. i.20.3</p>
<p :class="{'activeClass': idClicked == NP1450 }" id="NP1450" >24* Dionysos punishing Lykourgos: Paus. 1.20.3</p>
<p :class="{'activeClass': idClicked == NP1451 }" id="NP1451" >25* Stoa, 350-300 B.C.: Vitr. 5.9.1; Townsend, R. F. 1982, 98-105, 125-6, 128-132; for an <JBcom>[imaginary]</JBcom> second storey, see Travlos, J. 1971, 537</p>
<p :class="{'activeClass': idClicked == NP1452 }" id="NP1452" >Graffito reading Eros Eros on a stoa orthostate block: Korres, M. 1980, 14; SEG 38 (1988) no. 259</p>
<p :class="{'activeClass': idClicked == NP1453 }" id="NP1453" >26* Archaic poros limestone pediment with satyrs and maenad, ca. 540 B.C., found in 1876 in the courtyard of a house near the south side of the Theatre - - - NM 3131; Heberdey, R. 1919, 75-77, 163, fig. 53; Connor, W. 1990, 24-25; disassociated from the earlier temple (no. 27* below), Despinis, G. 2000, 196-198, 587, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1454 }" id="NP1454" >27* Earlier temple: Paus. 1.20.3; Connor, W. C. 1990, 24-26; Despinis, G. 2000, 193-214, figs. 1-27 <JBcom>[the temple was built after 508 B.C.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1455 }" id="NP1455" >28* Cult statue of Dionysos Eleuthereus: Paus. 1.20.3 + 1.29.2 + 1.38.8;</p>
<p :class="{'activeClass': idClicked == NP1456 }" id="NP1456" >Romano, I. B. 1981, 74-78; Connor, W. C. 1990, 9-12; cf. Dionysos Dio4*-5*</p>
<p :class="{'activeClass': idClicked == NP1457 }" id="NP1457" >29* Later temple, 350-300 B.C.: Townsend, R. F. 1982, 92-98, 125, 126</p>
<p :class="{'activeClass': idClicked == NP1458 }" id="NP1458" >30* Chryselephantine statue by Alkamenes: Paus 1.20.3; Romano, I. B. 1981, 75-76; <JBcom>[idle reconstruction of unattested]</JBcom> statue base reliefs, Edwards, C. M. 1985, 78-155</p>
<p :class="{'activeClass': idClicked == NP1459 }" id="NP1459" >31* DIONYSOS ELEUTHEREUS (continued), theater belonging to the sanctuary on the Akropolis South Slope: Paus. 1.20.3 + 4, 1.21.1 + 3 (twice) + 4, 5.12.4; Wurster, W. W. 1979, 58-76, figs. 1-16</p>
<p :class="{'activeClass': idClicked == NP1460 }" id="NP1460" >Theatre before Lykourgos: Paus. 1.29.16; E. Pohlmann, “Die Proedrie des Dionysostheaters im 5. Jahrhundert und das Buhnenspiel der Klassik,” MH 38 (1981) 129-137, 140-145, figs. 1, 4-6</p>
<p :class="{'activeClass': idClicked == NP1461 }" id="NP1461" >32* Dörpfeld's <JBcom>[imaginary]</JBcom> orchestra circle: E. Gebhard, “The Form of the Orchestra in the Early Greek Theater,” Hesperia 43 (1974) 432-434, pl. 89</p>
<p :class="{'activeClass': idClicked == NP1462 }" id="NP1462" >33* Two seat blocks for Boule staff members, ca. 425-403 B.C., reused in the retaining wall of the Lykourgan theater and in the drain - - - IG I³ 1390 = IG I² 879; SEG 31 (1981) no. 50; E. Pohlmann, op. cit, 142-145, figs. 5-6</p>
<p :class="{'activeClass': idClicked == NP1463 }" id="NP1463" >34* Seat block for a priest IG I³ 1389; SEG 31 (1981) no. 50; E. Pohlmann, op. cit, 140-141, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1464 }" id="NP1464" >Theatre in the time of Lykourgos: Paus. 1.29.16; Townsend, R. F. 1982, 106-132; construction started ca. 360 B.C., i.e. pre-Lykourgan, H. R. Gotte, “Die Basis des Astydamas im sogenannten lykurgischen Dionysos-Theater zu Athen,” Antike Kunst 42 (1999) 21-25, fig. 1; pl. 5:1-2</p>
<p :class="{'activeClass': idClicked == NP1465 }" id="NP1465" >35* Dionysos Eleuthereus, priest's theater throne - - - IG II² 5022; Maass, M. 1972, 60-76, 102-103, pls. 1,2,3,d; Μ. E. Micheli, “Su di un gruppo di troni con decorazione vegetale,” Boreas 10 (1987) 68 no. B 1, 69, figs. 2-3</p>
<p :class="{'activeClass': idClicked == NP1466 }" id="NP1466" >35** Propylon in the east parodos: Korres, M. 1980, 11-14, figs. 1-2, pl. 9; <JBcom>[mistakenly]</JBcom> called a choregic monument, Travlos, J. 1971, fig. 678 B</p>
<p :class="{'activeClass': idClicked == NP1467 }" id="NP1467" >Theatre after the time of Lykourgos: represented on Athenian imperial bronze coins, 3rd c. A.C., Price, J. M. and B. L. Trell, 1977, 68 fig. 133, 78 fig. 133</p>
<p :class="{'activeClass': idClicked == NP1468 }" id="NP1468" >36* Stage building (?) with the Didaskaliai, 3rd-2nd c. B.C. - - - IG II² 2319-23 +, 2325; SEG 34 (1984) no. 83 and 47 (1997) no. 186</p>
<p :class="{'activeClass': idClicked == NP1469 }" id="NP1469" >37* Nero's stage building, 54-61/2 A.D. IG II² 3182; A. von Gerkan, “Die neronische scaenae frons des Dionysostheaters in Athen,” Jahrbuch 56 (1941) 163-177, figs. 1-5; Carroll, Κ. K. 1982, 65</p>
<p :class="{'activeClass': idClicked == NP1470 }" id="NP1470" >38* Stage front with reliefs of Dionysos scenes and Silenoi, mid-2nd c. A.C.: M. C. Sturgeon, “The Reliefs on the Theater of Dionysos in Athens,” AJA 81 (1977) 31-53, figs. 1-7; the four Dionysos reliefs disassociated from the stage front, probably originally adorned an altar, then reused for Phaidros' bema, Despinis, G. 2003, 129, 141-144</p>
<p :class="{'activeClass': idClicked == NP1471 }" id="NP1471" >39* Phaidros' bema, latest 4th-early 5th c. A.C. - - - 1st Ephor. NK 201; IG II² 5021; SEG 32 (1982) no. 271; E. Sironen, “Life and Administration of Late Roman Attica,” in Castren, ed. 1994, 43-45 no. 27; Sironen, E. 1997, 116-118 no. 42</p>
<p :class="{'activeClass': idClicked == NP1472 }" id="NP1472" >Stage front reused: Wurster, W. W. 1979, 66-67</p>
<p :class="{'activeClass': idClicked == NP1473 }" id="NP1473" >Geisa from the Stoa of Eumenes reused: W. B. Dinsmoor, “The Choragic Monument of Nicias,” AJA 14 (1910) 482-483 See also: Chr. Mon. CM8* (basilica in the parodos)</p>
<p :class="{'activeClass': idClicked == NP1474 }" id="NP1474" >40* DIONYSOS IN LIMNAI, sanctuary in southeast Athens: Th. 2.15.4; Pickard-Cambridge, A.1968, 1-7 (testimonia nos. 1, 3, 8, 9,11, 19, 20), 9-10, 11, 16 no. 8 (sanctuary <JBcom>[mistakenly]</JBcom> located at the site of the Iobakcheion), 18-25</p>
<p :class="{'activeClass': idClicked == NP1475 }" id="NP1475" >41* Oikos and temple: schol. Ar. Ra. 216; Pickard-Cambridge, A. 1968, 4, 11 with note 7, 23</p>
<p :class="{'activeClass': idClicked == NP1476 }" id="NP1476" >42* Cult statue: not directly stated but implied by Philostr. VA 111.14; Pickard-Cambridge, A. 1968, 8 no. 36; Romano, I. B. 1981, 70-73</p>
<p :class="{'activeClass': idClicked == NP1477 }" id="NP1477" >43* Altar: D. 59.76 + 78; Pickard-Cambridge, A. 1968, 4 no. 11, 23-24</p>
<p :class="{'activeClass': idClicked == NP1478 }" id="NP1478" >44* Inscr. recording the law concerning Basileus' wife, beside the altar: D. 59.76; Kolb, F. 1981, 37 note 125 cf. Dionysion; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM173">173</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM230">230</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM239">239</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM559">559</a></p>
<p :class="{'activeClass': idClicked == NP1479 }" id="NP1479" >DIONYSOS LENAIOS: see Lenaion</p>
<p :class="{'activeClass': idClicked == NP1480 }" id="NP1480" >45* DIONYSOS MELANAIGIS, cult: Ar. Ach. 146, F GR HIST III B, suppl. vol. I, 594 L. 17 and suppl. vol. II, 51 note 10 and 507 frag. 3, note 10; F. Kolb, op. cit (Dionysos Dio10*), 125-130; cult probably established after 508 B.C., Connor, W. R. 1990, 14 note 24</p>
<p :class="{'activeClass': idClicked == NP1481 }" id="NP1481" >46* DIONYSOS MELPOMENOS, precinct on the Panathenaic Way in the area of the intersection of Ermou and Asomaton Sts. (Plan 2 epsilon—3): Paus. 1.2.5; owned by the guild of the Technitai, F. Poland, RE V A2,1934, col. 2522 lines 28-32 and col. 2540 line 64 - col. 2541 line 23 (Nachtrag, Technitai); Aneziri, S. 2000, 259-279</p>
<p :class="{'activeClass': idClicked == NP1482 }" id="NP1482" >47* Temenos with gateway: IG II² 1330 line 25</p>
<p :class="{'activeClass': idClicked == NP1483 }" id="NP1483" >48* Cult statue of Dionysos: IG II² 1330 line 24</p>
<p :class="{'activeClass': idClicked == NP1484 }" id="NP1484" >49* Euneidai priest's theater throne - - - IG II² 5056; Maass, M. 1972, 125-126, pl. 15; Aneziri, S. 2000, 260, 262-263, 267-274</p>
<p :class="{'activeClass': idClicked == NP1485 }" id="NP1485" >50* Technitai priest's theater throne - - - IG II² 5060; Maass, M. 1972, 128, pl. 16; Aneziri, S. 2000, 261-262 See also: Poulytion's house; Technitai of Dionysos; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM323">323</a></p>
<p :class="{'activeClass': idClicked == NP1486 }" id="NP1486" >51* DIONYSOS MORYCHOS, statue by Sikon, son of Eupalamos: Clem. Al. Protr. IV.42; LIMC III, 1986, 447 no. 227, s.v. Dionysos (C. Gasparri)</p>
<p :class="{'activeClass': idClicked == NP1487 }" id="NP1487" >DIONYSOS NEOS: see Antonius Marcus, Mark Antony as Neos Dionysos; Attalid dedication, Dionysos in Gigantomachia group; Hadrian as Neos Dionysos; Hadrian Olympios as Neos Dionysos</p>
<p :class="{'activeClass': idClicked == NP1488 }" id="NP1488" >52* DIONYSOS ORTHOS, altar in the sanct. of the Horai: F GR HIST III B, 99, Philochoros 328 F 5 with suppl. vol. I, 186, 268-271 and 348 (addendum)</p>
<p :class="{'activeClass': idClicked == NP1489 }" id="NP1489" >53* DIONYSOS PAIDEIOS, tripod base, 3rd c. B.C., theater - - - IG II² 2979; S. A. Koumanoudes, «Έπιγραφαι θεσπιών και Άθηνών,» Athenaion 7 (1878) 291 no. 1</p>
<p :class="{'activeClass': idClicked == NP1490 }" id="NP1490" >54* (name missing) and DIONYSOS, altar, 2nd c. A.C., Alexandrou and Mullerou Sts. (Plan 2 delta—3) — IG II² 3323; Benjamin, A. S. 1963, 71-72, pl. 30; SEG 21 (1965) no. 802</p>
<p :class="{'activeClass': idClicked == NP1491 }" id="NP1491" >DIONYSOS and HELIOS: see Helios and Dionysos </p>
<p :class="{'activeClass': idClicked == NP1492 }" id="NP1492" >DIONYSOS and HERMES: see Hermes Hrm36*-37*</p>
<p :class="{'activeClass': idClicked == NP1493 }" id="NP1493" >DIOSKOUROI, ANAKES, ANAKEION: LIMC III, 1986, 590-592 with nos. 2-4, 22, 52, 87 (silver tetradrachm), 95,111-115,165-171,176, 179-186,195-201,219-223, 236, 251-252, s.v. Dioskouroi (A. Hermary); Kearns, E. 1989,148</p>
<p :class="{'activeClass': idClicked == NP1494 }" id="NP1494" >Anakeion, sanctuary of the Anakes (Dioskouroi), Akropolis northwest slope: the ancient sanctuary of the Dioskouroi described by Pausanias (1.18.1-2) after he saw the sanctuary of Theseus and before he saw the precinct of Aglauros; Agora III, 61-65; conjectural <JBcom>[doubtable]</JBcom> location on the Akropolis North Slope, Travlos, J. 1971, 2,fig. 5 no. 12, fig. 722 P</p>
<p :class="{'activeClass': idClicked == NP1495 }" id="NP1495" >Boundary stone, poros limestone, ca. 450 B.C. - - - IG I² 1052; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202080" target="_blank">Agora I 2080</a>; SEG 25 (1971) no. 54; Ritchie Jr., C. E., 1985, 47-51, 652-657, pl. 8, fig. 5; Agora XIX, 22-23 H5, pl. 1</p>
<p :class="{'activeClass': idClicked == NP1496 }" id="NP1496" >Inscription in the Anakeion concerning sacrificial oxen: Ath. 235b</p>
<p :class="{'activeClass': idClicked == NP1497 }" id="NP1497" >Dioskouroi and the daughters of Leukippos, picture by Polygnotos, 475-450 B.C. in the Anakeion: Paus. 1.18.1; A. Hermary, op. cit., 583 no. 192, 590</p>
<p :class="{'activeClass': idClicked == NP1498 }" id="NP1498" >Dioskouroi standing and their sons on horseback, sculpture in the Anakeion: Paus. 1.18.1</p>
<p :class="{'activeClass': idClicked == NP1499 }" id="NP1499" >Anakes Dioskouroi, altar, 2nd c. A.C., found near the Stoa of Attalos - - - IG II² 4796; Dodwell, E. 1819, 371</p>
<p :class="{'activeClass': idClicked == NP1500 }" id="NP1500" >Dioskouroi, dedication of a spear butt taken from the Lesbians, 427 B.C., northwest corner of the Agora Excavations - - - IG I² 521 bis; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201373" target="_blank">Agora B 1373</a>; SEG 28 (1978) no. 24; J. McK. Camp II, “A Spear Butt taken from the Lesbians,” Hesperia 47 (1978) 192-195, fig. 1, pl. 50</p>
<p :class="{'activeClass': idClicked == NP1501 }" id="NP1501" >Dioskouroi, dedication, 2nd-3rd c. A.C., Akr. northwest slope southeast of the Hypapanti Church (Chr. Mon. CM71*, Plan 1 delta—4) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205231" target="_blank">Agora I 5231</a>; A. G. Woodhead, “Greek Inscriptions,” Hesperia 28 (1959) 286-287 no. 14, pl. 56</p>
<p :class="{'activeClass': idClicked == NP1502 }" id="NP1502" >Anakes?, dedication on a statue base found in the Eleusinion area <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206960" target="_blank">Agora I 6960</a>; restored as a dedication to the Anakes, B. Meritt, “Greek Inscriptions,” Hesperia 32 (1963), 45 no. 61, pl. 13; A. E. Raubitschek, Hesperia 32 (1963) 438 (corrigendum to the preceding item)</p>
<p :class="{'activeClass': idClicked == NP1503 }" id="NP1503" >Cult tables for the Dioskouroi in the Prytaneion: Ath. 4.137e; Gill, D. 1991, 9</p>
<p :class="{'activeClass': idClicked == NP1504 }" id="NP1504" >Dioskouroi with horses flanking flaming altar, unfinished relief, late Hellenistic - early Roman Berlin K 103; A. Hermary, op. cit371 no. 33, ph.</p>
<p :class="{'activeClass': idClicked == NP1505 }" id="NP1505" >Dioskouros with horse protome, unfinished statuette, Roman pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201342" target="_blank">Agora S 1342</a>; Thompson, H. A. 1949, 222, pl. 44; A. Hermary, op. cit., 572 no. 52, ph.</p>
<p :class="{'activeClass': idClicked == NP1506 }" id="NP1506" >Dioskouros with horse and spear, gilded ivory plaque, 5th-6th c. A.C., Akropolis Lower South Slope, Akropolis Station - - - Metro Exc. inv no. M 2516; Metro Excavations 1992-1997, 71 no 43, ph.</p>
<p :class="{'activeClass': idClicked == NP1507 }" id="NP1507" >Anakes and Heros Epitegios, priest's theater throne, Roman pd. - - - IG II² 5071; Maass, M. 1972, 134-135, pl. 19; Kearns, E. 1989, 159; N. Robertson, «"Hpoos Έπιτέγχος,» ZPE 127 (1999) 179-181; SEG 49 (1999) no. 232</p>
<p :class="{'activeClass': idClicked == NP1508 }" id="NP1508" >DIPHILOS, son of Diopeithes (?), ally of Demosthenes, statue in the Agora: Din. Ι.43; Gauthier, Ph. 1985, 95 note 51</p>
<p :class="{'activeClass': idClicked == NP1509 }" id="NP1509" >DIPYLON GATE: IG II² 463 line 53, specifying how the top of the wall directly over the Dipylon Gate itself is to be constructed (Dipylon-above-the-gates), in distinction to the other walls in the Dipylon Gate complex; G. HObner, “Dachterrakotten aus dem Kerameikos von Athen: Das Dach des hellenistischen Dipylon,” AM 88 (1973) 101-109 (esp. 107-108 note 135), pl. 65; see Kerameikai Gate; Thriasian Gate; Themistoklean City Wall Wal6*; cf. Diateichisma, Gate at Hag. Dimitrios Loumbardiaris</p>
<p :class="{'activeClass': idClicked == NP1510 }" id="NP1510" >DIPYLON GATE FOUNTAIN HOUSE: Knigge, U. 1991, 74-75, figs. 60, 62 - 63, 65-66, 165 no. 4</p>
<p :class="{'activeClass': idClicked == NP1511 }" id="NP1511" >DIPYLON GRAVES (Dipylon Graber), large group of graves ca. 350 m. northeast of the Dipylon Gate, once considered part of a huge graveyard extending northeast from the Dipylon Gate, now known to flank the road leading out of the Leokoriou St. Gate (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM567">567</a>). Hence the terms: Dipylon Cemetery, Dipylon grave inventory, Dipylon Master, Dipylon Workshop. See Brueckner, A. and E. Pernice, 1893, 75-76; Skilardi, D. 1968, 34-44, figs. 4-7; J. N. Coldstream, Greek Geometric Pottery, London, 1968, 349</p>
<p :class="{'activeClass': idClicked == NP1512 }" id="NP1512" >The Dipylon Amphora by the Dipylon Master, grave marker, mid-8th c. B.C., found in a lot bounded by Psaramilingou, Kalogirou Samouil (formerly B. Herakleidou St.) and Peiraios Sts. (Plan 2 delta—3) NM 804; A. Brueckner, and E. Pernice, op. cit., 103-104 with pl. 6 fig. 1</p>
<p :class="{'activeClass': idClicked == NP1513 }" id="NP1513" >The Dipylon Oinochoe with graffito, the earliest Greek inscription, ca. 740 B.C., northeast corner of the city block bounded by Peiraios, Kriezi, Psaromilingou and Kalogiro Samouil Sts. (Plan 2 delta—3) - - - NM 192; IG I² 919; SEG 46 (1996) no. 104 and 48 (1998) no. 89; for the findspot, S. A Koumanoudes, «Άρχαϊκή έπιγραφή Άττικού αγγείου,» Athenaion 9(1880), first page of an Addendum in a separately paginated section inserted between pages 50 and 51</p>
<p :class="{'activeClass': idClicked == NP1514 }" id="NP1514" >DIVIAUGUSTI = THEOISEBASTOI</p>
<p :class="{'activeClass': idClicked == NP1515 }" id="NP1515" >Altar of the Sebastoi on the Akropolis beside which the honorary decree for Julia Domna was to be set up: IG II² 1076 lines 38-39; J. H. Oliver, “Julia Domna as Athena Polias,” HSCP Suppl. Vol. I, 1940, 528, 529 See also: Athena Ath55*; Hestia, Apollo etc.</p>
<p :class="{'activeClass': idClicked == NP1516 }" id="NP1516" >DOMITIA LONGINA = Domitia Longina Augusta (PIR, 2nd ed., 181), portrait, Plaka area - - - NM inv. Theseion 307; A. Datsouli-Stavridi, «Εικονιστικά Α,» Deltion 29 (1974) A, 187-188, 282, pls. 106-109</p>
<p :class="{'activeClass': idClicked == NP1517 }" id="NP1517" >DOMITIAN = Titus Flavius Domitianus (PIR, 2nd ed., 259), relabelled statue base, Asklepieion — IG II² 3283</p>
<p :class="{'activeClass': idClicked == NP1518 }" id="NP1518" >1st use, 41-54 A.D. - - - IG II² 3283a</p>
<p :class="{'activeClass': idClicked == NP1519 }" id="NP1519" >2nd use, dedication to Domitian, 69/79? A.D. - - - IG II² 3283 b; Geagan, D. J. 1967,141</p>
<p :class="{'activeClass': idClicked == NP1520 }" id="NP1520" >3rd use, Christian inscr. - - - IG II² 3283c</p>
<p :class="{'activeClass': idClicked == NP1521 }" id="NP1521" >DOMITIUS AHENOBARBUS, L., probably Nero's grandfather, relabelled statue base, Akr. - - - IG II² 4144; Blanck, H. 1969, 81</p>
<p :class="{'activeClass': idClicked == NP1522 }" id="NP1522" >1st use: base for statue of Phanomachos, son of Phanias from Eleusis</p>
<p :class="{'activeClass': idClicked == NP1523 }" id="NP1523" >DRAKON'S LAWS, ca. 621 B.C., inscribed on axones, on kyrbeis, on marble</p>
<p :class="{'activeClass': idClicked == NP1524 }" id="NP1524" >On axones: IG I² 104 lines 10, 56; Stroud, R. S. 1979, passim especially 19-20, 41-44</p>
<p :class="{'activeClass': idClicked == NP1525 }" id="NP1525" >On kyrbeis: Plu. Sol. 25.1; Stroud, R. S. 1979, passim especially 19 -20, 41-44, 47-60</p>
<p :class="{'activeClass': idClicked == NP1526 }" id="NP1526" >Republication of Drakon's law on homicide, 409/408 B.C., on marble stelai in front of the Stoa Basileos - - - IG I³ 104; Stroud, R. S. 1979, 6-10, 16, 20, 44; SEG 51 (2001) no. 26</p>
<p :class="{'activeClass': idClicked == NP1527 }" id="NP1527" >DROMOS</p>
<p :class="{'activeClass': idClicked == NP1528 }" id="NP1528" >Name for the Panathenaic Way: Him. Or. XLV.12; Agora 111, 20 no. 1; Travlos, J. 1971, 2-3, fig. 5 no. 32, figs. 18-19; not a name for the Panathenaic Way, Miller, S. G. 1994, 216-218; SEG 46 (1996) no. 62</p>
<p :class="{'activeClass': idClicked == NP1529 }" id="NP1529" >Dromos from the Lykeion: X. HG 2.4.27; Ritchie Jr., C. E. 1989, 252, 253; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM505">505</a> cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM536">536</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM565">565</a></p>
<p :class="{'activeClass': idClicked == NP1530 }" id="NP1530" >DRUSILLA, Caligula's sister, base for statue of D. as Nea Thea Aphrodite, 37/38 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204313" target="_blank">Agora I 4313</a>; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 76; SEG 34 (1984) no. 180</p>
<p :class="{'activeClass': idClicked == NP1531 }" id="NP1531" >DRUSUS the Younger, son of Tiberius, as the new Ares, statue base,17-20 A.D., found in the city block bounded by Sophokleous, Aiolou and Georgiou Stavrou Sts. (Plan 2 delta—5) - - - IG II² 3257: Hartswick, K. J. 1990, 270-271; SEG 47 (1997) no. 219</p>
<p :class="{'activeClass': idClicked == NP1532 }" id="NP1532" >EAGLE, sculpture dedicated to The Goddesses, 2nd-3rd c. A.C., filling of the Post-Herulian Wall - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205436" target="_blank">Agora I 5436</a>; dedication assigned to the Two Goddesses <JBcom>[although the dual form not used]</JBcom>, Agora XXXI, 88, 193 no. 24, pl. 28</p>
<p :class="{'activeClass': idClicked == NP1533 }" id="NP1533" >ECHELOS: see Heroes Hro10*</p>
<p :class="{'activeClass': idClicked == NP1534 }" id="NP1534" >ECHETLOS: see Marathonomachia</p>
<p :class="{'activeClass': idClicked == NP1535 }" id="NP1535" >EETIONE: see Athena Ath59*</p>
<p :class="{'activeClass': idClicked == NP1536 }" id="NP1536" >EGRETES HEROS, sanctuary with buildings and trees leased to Diognetes of Melite recorded in an orgeones decree to be set up in the sanctuary, 306/5 B.C., found on the slope north of the Hill of The Nymphs near the southeast angle of the modern</p>
<p :class="{'activeClass': idClicked == NP1537 }" id="NP1537" >Theseion <JBcom>[Hephaisteion]</JBcom> Garden: IG II² 2499; G. D. Lord, “An Attic Lease Inscription,” AJA 3 (1899) 4453, pl. I; Ferguson, W. S. 1944, 80-81; SEG 25 (1971) no. 197; Kearns, E. 1989,157</p>
<p :class="{'activeClass': idClicked == NP1538 }" id="NP1538" >EILEITHYIA: Pingiatoglu, S. 1981, 42-44, 46, 81, 90, 92, 149 (L56), 157-158 (E 33-35)</p>
<p :class="{'activeClass': idClicked == NP1539 }" id="NP1539" >Dedication on a base, Metropolis church on Mitropoleos St. - - - IG II² 4669; Peek, W. 1942,167 no. 346</p>
<p :class="{'activeClass': idClicked == NP1540 }" id="NP1540" >Dedication on a base, mid-4th c. B.C. - - - 1st Ephor. Rho Alpha inv. 637; S. N. Koumanoudes, «Έπιγαφαι έξ Άθηνών,» Deltion 25 (1970) A, 68 no. 23, pl. 27a; SEG 35 (1985) no. 141</p>
<p :class="{'activeClass': idClicked == NP1541 }" id="NP1541" >Dedication on a base, 229/8 - ca. 203 B.C., west of the Post-Herulian Wall  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205016" target="_blank">Agora I 5016</a>; A. G. Woodhead, “Greek Inscriptions,” Hesperia 28 (1959) 274-275 no. 2, pl. 54; SEG 18 (1962) no. 88; for the revised date, see Tracy, S. V. 1990, 49</p>
<p :class="{'activeClass': idClicked == NP1542 }" id="NP1542" >Dedication on a base, 1st c. A.C., near the Lysikrates Mon. IG II² 4048;</p>
<p :class="{'activeClass': idClicked == NP1543 }" id="NP1543" >Pingiatoglu, S. 1981, 43, 158 E 34, pl. 15 no. 1; SEG 42 (1992) no. 173</p>
<p :class="{'activeClass': idClicked == NP1544 }" id="NP1544" >Temple near Theseus' and Peirithoos1 Covenant Place: Paus.l.18.5; probably in the area around the Roman Agora, Travlos, J. 1971, 28</p>
<p :class="{'activeClass': idClicked == NP1545 }" id="NP1545" >Two Cretan xoana dedicated by Phaedra: Paus. 1.18.5; J. Papadopoulos, Xoana e Sphyrelata, Rome, 1980, 56</p>
<p :class="{'activeClass': idClicked == NP1546 }" id="NP1546" >Oldest xoanon brought from Delos by Erysichthon: Paus. 1.18.5; LIMC IV, 1988,18, s.v. Erysichthon II (U. Kron)</p>
<p :class="{'activeClass': idClicked == NP1547 }" id="NP1547" >EILEITHYIA IN AGRAI: F GR HIST III B, 51, Kleidemos 323 F1, with suppl. vol. I; Pingiatoglu, S. 1981, 42-44, 45, 61-65, 142 no. 1, 146 (L 27), 158-9 (E 38-E 39), 160 (E 44)</p>
<p :class="{'activeClass': idClicked == NP1548 }" id="NP1548" >Statuette of Eukoline (a personification) dedicated to Eileithyia, 3rd c. B.C., by the Ilissos east of Kallirrhoe (Plan 2 eta—6) — IG II² 4682; Raubitschek, A. E. 1966, 242 no. 2, pl. 66; SEG 23 (1968) no. 128</p>
<p :class="{'activeClass': idClicked == NP1549 }" id="NP1549" >Statues of children, late 4th-early 3rd c. B.C., east of Kallirrhoe spring (Plan 2 eta—6) NM 693-696; S. Papaspyridi-Karouzou, «Ή τυφλή 'Άρκτος,» AE 1957, 77-80, fig. 8, pls. 20-21; Kaltsas, N. 2002, 268 no. 560, ph. (NM 693) and 268 no. 559, ph. (NM 694) and 271 no. 566, ph. (NM 695) and 270-271 no. 565 (NM 696)</p>
<p :class="{'activeClass': idClicked == NP1550 }" id="NP1550" >Base of a statuette of a boy dedicated to The Eileithyiai, late 1st c. B.C. or early 1st c. A.C., built into the Pasteur Institute garage, 127 Vasil. Sophias Blvd. near An. Tsocha St. (Plan 3 eta—7) - - - EM 13353; SEG 24 (1969) no. 226; M.-T. Couilloud, “Nouvelle dedicace attique aux Ilithyes,” BCH 92 (1968) 72-75, fig. 1</p>
<p :class="{'activeClass': idClicked == NP1551 }" id="NP1551" >Statuette of a girl, Julia, dedicated to The Eileithyiai, Pankrati - - - Private Collection; Peek, W. 1942, 56-57 no. 94</p>
<p :class="{'activeClass': idClicked == NP1552 }" id="NP1552" >Ersephoroi, theater throne IG II² 5099; Pingiatoglu, S. 1981, 43, 158 (E 36), pl. 17 no. 2</p>
<p :class="{'activeClass': idClicked == NP1553 }" id="NP1553" >EIRENE: Agora III, 65-67, nos. 152-162; UMC III, 1986, 700-701, 704-705 and nos. 2 <JBcom>[beware]</JBcom>, 3-4, 6-9, 11-12, s.v. Eirene (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP1554 }" id="NP1554" >Altar commemorating the Peace of Kallias after the victory at Eurymedon: Plu. Cim. 13.6; J. Walsh, “The Authenticity and the Dates of the Peace of Callias and the Congress Decree,” Chiron 11 (1981) 31-63, especially 34-35</p>
<p :class="{'activeClass': idClicked == NP1555 }" id="NP1555" >Altar, 375 B.C.: Did. in D. 10.34; J. Buckler, “Dating the Peace of 375/4 B.C.,” GRBS 12 (1971) 359-361; SEG 29 (1979) no. 88</p>
<p :class="{'activeClass': idClicked == NP1556 }" id="NP1556" >Statue in the Prytaneion: Paus. 1.18.3</p>
<p :class="{'activeClass': idClicked == NP1557 }" id="NP1557" >Eirene and Ploutos, statue group by Kephisodotos, 374 - ca. 360 B.C., in the Agora near the Eponymous Heroes: Paus. 1.8.2, 9.16.2; arguing for ca. 360 as the more likely date, H. Jung, “Zur Eirene des Kephisodot,” Jahrbuch 91 (1976) 97-134</p>
<p :class="{'activeClass': idClicked == NP1558 }" id="NP1558" >Group represented on Panathenaic amphoras dated to 360/359 B.C.: Eschbach, N. 1986, 58-70, figs. 36-50, pls. 16-19</p>
<p :class="{'activeClass': idClicked == NP1559 }" id="NP1559" >Group represented on bronze coins, 2nd c. A.C., LIMC III, 1986, 702 no. 4, ph., s.v. Eirene (E. Simon)</p>
<p :class="{'activeClass': idClicked == NP1560 }" id="NP1560" >EIRESIDAI, deme between Kolonos Hippios and the Kephisos: D.L. 3.42; Siewert, P. 1982, 41-2 with note 30, 95; Traill, J. S. 1986, 132</p>
<p :class="{'activeClass': idClicked == NP1561 }" id="NP1561" >ELAPHEBOLOS: see Artemis Art32*</p>
<p :class="{'activeClass': idClicked == NP1562 }" id="NP1562" >ELATES: see Poseidon Elates</p>
<p :class="{'activeClass': idClicked == NP1563 }" id="NP1563" >ELEOS (Philanthropia, “Pity” ), altar mentioned by Pausanias, after he described the Stoa Poikile and before he saw the Gymnasium of Ptolemy: Paus. 1.17.1; Agora III, 67-74, nos. 163-190; an <JBcom>[imaginary]</JBcom> Altar of Pity <JBcom>[supposedly]</JBcom> seen by Pausanias by the Altar of the Twelve Gods, Travlos, J. 1971, 458; on the Akropolis Lower North Slope east of the Agora, E. Vanderpool, “The ‘Agora' of Pausanias 1.17.1-2,” Hesperia 43 (1974) 308-310 Called altar of Misericordia: Sen. Con. 10.5.10</p>
<p :class="{'activeClass': idClicked == NP1564 }" id="NP1564" >Called altar of Clementia dedicated to none of the powerful gods, set in a laurel and olive tree grove: Stat. Th. 481-482, 491-492; cf. Agora XIV, 134-136 See also: Zeus Zeu35*</p>
<p :class="{'activeClass': idClicked == NP1565 }" id="NP1565" >ELEPHANTS carved on stelai at the temple of Ares, removed to the Golden Gate in the early 5th c. A.C.: George Kodinos, περι αγαλμάτων... 47,14; Agora III, no. 116; probably set up by Augustus himself, B. A. Kellum, “Pro Miraculo: Augustus' Dedication of four obsidian Elephants in the Temple of Concord,” AJA 91 (1987) 280-281; Agora XXIV, 75</p>
<p :class="{'activeClass': idClicked == NP1566 }" id="NP1566" >ELEUSINIAN MYSTERIES, the site of the Lesser Mysteries at Agrai on the Ilissos: IG II² 1231 lines 11-12; SEG 42 (1992) no. 119; O. Kern, RE XVI, 1935, 1223-1225 (Mysterien); for the heroes who received preliminary sacrifices at the Lesser Mysteries, see Kearns, E. 1989, 159 s.v. Eleusis Heros Archegetis, 153 s.v. Eumolpos, 170 s.v. Threptos, 184 s.v. Melichos, 194-195 s.v. Polyxenos; Vikela, E. 1994, 151-153, 157-158; the Lesser Mysteries were <JBcom>[most certainly]</JBcom> celebrated in the sanctuary of the Mother of The Gods in Agrai, Shear Jr., T. L., 1995, 172; <JBcom>[the site must have, in any case, been close to the Kallirrhoe spring]</JBcom>; see Herakles Hrk24*, Hrk38*; Kallirrhoe; Mother of The Gods Met10*</p>
<p :class="{'activeClass': idClicked == NP1567 }" id="NP1567" >ELEUSINION, Akropolis northwest slope on the Panathenaic Way: Paus. 1.14.3; Agora III, 1 74-85; Agora Guide 1990, 152-153, 194, 277 no. 152, figs. 8, 19, 98; Agora XXXI, The City Eleusinion, 1998</p>
<p :class="{'activeClass': idClicked == NP1568 }" id="NP1568" >Precinct wall, 5th c. B.C.: Th. 2.17.1</p>
<p :class="{'activeClass': idClicked == NP1569 }" id="NP1569" >Altars inscribed with Sacred Laws, ca. 510-480 B.C. IG I³ 231-232; L. H. Jeffery, “The Boustrophedon Sacral Inscriptions from the Agora,” Hesperia 17 (1948) 86-109, figs. 1-3, pls. 29-32; Agora XXXI, 8, 63, 64, 200-201 nos. 39-40 (listing the findspots of the 30 fragments no one of which was found in the Post-Herulian Wall)</p>
<p :class="{'activeClass': idClicked == NP1570 }" id="NP1570" >Altar I, ca. 510-500 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202470" target="_blank">Agora I 2470</a> +, IG I³ 231</p>
<p :class="{'activeClass': idClicked == NP1571 }" id="NP1571" >Altar II, ca. 510-480 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204721" target="_blank">Agora I 4721</a> +, IG I³ 232; SEG 39 (1989) no. 17</p>
<p :class="{'activeClass': idClicked == NP1572 }" id="NP1572" >Altar: And. 1.110</p>
<p :class="{'activeClass': idClicked == NP1573 }" id="NP1573" >Inner Propylon: Agora XXXI, 51, 66, 89-91; this admittedly conjectural propylon with as yet no foundations to stand on has been postulated in order to provide a home for the following:</p>
<p :class="{'activeClass': idClicked == NP1574 }" id="NP1574" >Triglyph metope frieze with Eleusinian reliefs of poppy-heads, crossed torches and plemochoe, Roman pd., built into the Little Metropolis (Chr. Mon. CM75*) : F. Brommer, “Plemochoe,” AA 1980, 548 note 19, fig. 2; assigned to a conjectural Inner Propylon, Agora XXXI, 89-91, pls. 22-23</p>
<p :class="{'activeClass': idClicked == NP1575 }" id="NP1575" >Caryatids assigned to a conjectural Inner Propylon: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM217">217</a></p>
<p :class="{'activeClass': idClicked == NP1576 }" id="NP1576" ><JBcom>[Imaginary]</JBcom> early temple: vanished without a trace, Travlos, J. 1971, 198; debunked by M. M. Miles, Agora XXXI, 28-29 note 13</p>
<p :class="{'activeClass': idClicked == NP1577 }" id="NP1577" >Temple facing north: see Triptolemos</p>
<p :class="{'activeClass': idClicked == NP1578 }" id="NP1578" >Inventory of possessions belonging to Demeter and Kore, stored in the Eleusinion: IG I³ 386 lines 14-38; Cavanaugh, Μ. B. 1986, 105, 130-144, 213</p>
<p :class="{'activeClass': idClicked == NP1579 }" id="NP1579" >Accounts of the poletai recording the sale of the confiscated property of the profaners of the Mysteries and the mutilators of the Herms, 415-413 B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20236" target="_blank">Agora I 236</a>, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207307" target="_blank">Agora I 7307</a> +, IG I³ 420-430; SEG 51 (2001) no. 38; located on a base east of the temple, Travlos, J. 1971, 198, fig. 260 D; Agora XXXI, 8,9, 63,65-66, 203-205 nos. 46-57</p>
<p :class="{'activeClass': idClicked == NP1580 }" id="NP1580" >Bronze horse with reliefs on the base made by Demetrios of Alopeke, 5th-4th c. B.C., set up at the Eleusinion by Simon, author of a work on horsemanship: X. Eq. 1.1; Plin. Nat. XXXIV.76; Agora III, no. 204; H. R. Breitenbach, KP 5, 1975, 202 (s.v. Simon no. 1); Pollitt, J. J. 1990, 89-90; most probably Simon the hipparch of 425/4 B.C., Bugh, G. R. 1988, 91 with note 35, 227 no. 447; <JBcom>[mistakenly]</JBcom> described as an equestrian statue, T. Schafer, “Ein Schlachtfries von der Akropolis,” AM 115 (2000) 312-313 with notes 56-58</p>
<p :class="{'activeClass': idClicked == NP1581 }" id="NP1581" >ELEUSIS, personification: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM216">216</a></p>
<p :class="{'activeClass': idClicked == NP1582 }" id="NP1582" >ELEUSIS, road to: see Sacred Way</p>
<p :class="{'activeClass': idClicked == NP1583 }" id="NP1583" >ELEUTHEREUS: see Dionysos Dio4*, Dio5*, Dio17*-39*; Hadrian Eleutheraios </p>
<p :class="{'activeClass': idClicked == NP1584 }" id="NP1584" >ELEUTHERIOS: see Zeus Zeu18*-24*</p>
<p :class="{'activeClass': idClicked == NP1585 }" id="NP1585" >ELLIMENIA: see Hera Ellimenia</p>
<p :class="{'activeClass': idClicked == NP1586 }" id="NP1586" >ELPIAS' SCHOOL where Aischines' father taught letters, beside the Theseion: D. 18.129; Agora III, no. 347</p>
<p :class="{'activeClass': idClicked == NP1587 }" id="NP1587" >ELPINIKE, Kimon's sister, grave next to Thucydides' grave in Koile not far from the Melitides Gate: Plu. Cim. 4</p>
<p :class="{'activeClass': idClicked == NP1588 }" id="NP1588" >EMPEDO, earlier name for the Klepsydra spring: schol. Ar. Lys. 913; Parsons, A. W. 1943, 201-207, 265-266; an <JBcom>[unattested imaginary]</JBcom> “shrine of Empedo” at the Klepsydra site, Travlos, J. 1971, 323; cf. Nymphaion, boundary stone</p>
<p :class="{'activeClass': idClicked == NP1589 }" id="NP1589" >ENAGONIOS: see Aphrodite Aph23*; Hermes Hrm19*-20*</p>
<p :class="{'activeClass': idClicked == NP1590 }" id="NP1590" >ENCHORIOS: see Zeus Zeu25*</p>
<p :class="{'activeClass': idClicked == NP1591 }" id="NP1591" >ENNEAKROUNOS: Agora III, 137-142; Travlos, J. 1971, 204; Camp, J. McK, 1980, 63 and testimonia nos. 3, 16, 20-25, 27-28, 30-31, 33-34, 36, 38, 40, 41</p>
<p :class="{'activeClass': idClicked == NP1592 }" id="NP1592" >Fountain house at Kallirrhoe spring: Th. 2.15.4-5; at the Ilissos northeast of Haghia Photini, Judeich, W. 1931, 49, 55 note 4, 59, 65, 141 note 1,193-201, 420, Plan I at H-7; Tolle-Kastenbein, R. 1986, 55-73</p>
<p :class="{'activeClass': idClicked == NP1593 }" id="NP1593" >The Enneakrounos reported by Pausanias: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM298">298</a></p>
<p :class="{'activeClass': idClicked == NP1594 }" id="NP1594" >Dörpfeld's Enneakrounos: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM231">231</a></p>
<p :class="{'activeClass': idClicked == NP1595 }" id="NP1595" >ENNEAPYLON: F GR HIST III B, 55, Kleidemos 323 F 16 with suppl. vol. I, 72-74; Iakovides, S. 1962, 184-185, 187-188, 236 nos. 33-34 (testimonia), 261 εννέα πύλαι, έννεάπυλον; wall with nine gates guarding the Akropolis approach and water supply (also called Pelargikon), Travlos, J. 1971, 2,52,54, figs. 67 and 71 no. 105 (Outer Pelargikon)</p>
<p :class="{'activeClass': idClicked == NP1596 }" id="NP1596" >ENODIA: see Pan Pan18*</p>
<p :class="{'activeClass': idClicked == NP1597 }" id="NP1597" >ENYALIOS: see Ares Enyalios; Artemis Art20*</p>
<p :class="{'activeClass': idClicked == NP1598 }" id="NP1598" >ENYO, statue in the temple of Ares, by the sons of Praxiteles: Paus. 1.8.4; LIMC III, 1986, 746-747, no. 1, s.v. Enyo I (R. M. Gais); Hartswick, K. J. 1990, 258-259; see Ares Enyalios</p>
<p :class="{'activeClass': idClicked == NP1599 }" id="NP1599" >EOS and KEPHALOS: see Stoa Basileios, terracotta acroteria: Hemera and Kephalos</p>
<p :class="{'activeClass': idClicked == NP1600 }" id="NP1600" >EPAKRIOS: see Zeus Zeu26*</p>
<p :class="{'activeClass': idClicked == NP1601 }" id="NP1601" >EPALXIS (or EPALXEIS), lawcourt beside the Prytaneion: Anecdota Graeca 1914, 243 lines 16-18, s.v. Έπαλξεις; Agora XXVIII, Index Epalxeis</p>
<p :class="{'activeClass': idClicked == NP1602 }" id="NP1602" >EPEKOOS: see Artemis Art38*; Chr. Mon. CM75* (Panagia Gorgoepekoos); Hermes Hrm21*; Heroes Hrm11*; Nemesis Thea Epekoos; Thea Epekoos</p>
<p :class="{'activeClass': idClicked == NP1603 }" id="NP1603" >EPHEBES, gymnasium (?) in Athens (?): IG II² 478 line 30; SEG 15 (1958) no. 98; Delorme, J. 1960, 58 note 3; dubitandum</p>
<p :class="{'activeClass': idClicked == NP1604 }" id="NP1604" >EPHEDRISMOS: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM333">333</a></p>
<p :class="{'activeClass': idClicked == NP1605 }" id="NP1605" >EPHIALTES, state-financed grave mon. on the Road to the Academy: Paus. 1.29.15; Stupperich, R. 1977, 249-250; Taylor, M. W. 1991,12 note 21, 96</p>
<p :class="{'activeClass': idClicked == NP1606 }" id="NP1606" >EPHOROS: see Zeus Zeu27*</p>
<p :class="{'activeClass': idClicked == NP1607 }" id="NP1607" >EPICHARINOS the hoplite runner, inscribed base of statue by Kritios and Nesiotes, ca. 470 B.C., between the Propylaia and the Parthenon - - - IG I³ 847 = IG I² 531; Acr. 13248; Paus. 1.23.9 (naming Kritias as the sculptor, seen after he saw the Trojan horse); Raubitschek, A. E. 1949, no. 20, ph.; SEG 33 (1983) no. 25; Keesling, C. M. 2000, 15, 29, 66, 170, 171-172, 196</p>
<p :class="{'activeClass': idClicked == NP1608 }" id="NP1608" >EPICURUS</p>
<p :class="{'activeClass': idClicked == NP1609 }" id="NP1609" >Garden between the Dipylon Gate and the Academy: Cic. Fin. 5.1.3; G. S. Dontas, «Εικονιστικά Β',» Deltion 26 (1971) A, 18-33, 319; <JBcom>[although all of the diagnostic finds turned up at or near 61 Marathonos St. along an ancient street (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM608">608</a>) connecting the two roads to the Academy, the excavator did not accept the site as Epicurus' Garden]</JBcom>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM480">480</a></p>
<p :class="{'activeClass': idClicked == NP1610 }" id="NP1610" >Inscription at the entrance to the Garden: Sen. Ep. 21.10</p>
<p :class="{'activeClass': idClicked == NP1611 }" id="NP1611" >Seated statue, 2nd c. A.C., northwest Athens, 61 Marathonos St. near Achilleos St. (Plan 2 gamma—2) 3rd Ephor. M 887; G. S. Dontas, op. cit., 19-20, pl. 5</p>
<p :class="{'activeClass': idClicked == NP1612 }" id="NP1612" >Seated statue, 2nd-3rd c. A.C., 61 Marathonos St. near Achilleos St. (Plan 2 gamma—2) - - - 3rd Ephor. M 888; G. S. Dontas, op. cit., 19-20, pl. 6</p>
<p :class="{'activeClass': idClicked == NP1613 }" id="NP1613" >House in Melite: Cic. Att. 5.19.3, Fam. 13.1.3; D.L. 10.17; G. S. Dontas, op. cit., 22-23</p>
<p :class="{'activeClass': idClicked == NP1614 }" id="NP1614" >Portrait head, Metropolis church site (Plan 1 beta/gamma—8) NM 540; Richter, G. M. A. 1965, II, 197 no. 26, figs. 1224-1225</p>
<p :class="{'activeClass': idClicked == NP1615 }" id="NP1615" >Portrait head Acr. 2186; ibid., 197 no. 27</p>
<p :class="{'activeClass': idClicked == NP1616 }" id="NP1616" >Seated statue, ca. A.D. 150 in the Odeion of Agrippa - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20826" target="_blank">Agora S 826</a>; Thomson, H. A. 1950, 124-125 no. 2, 132, pl. 78; the statue was set up in the lecture hall built on the site of the Odeion</p>
<p :class="{'activeClass': idClicked == NP1617 }" id="NP1617" >EPILYKEION, the Polemarcheion renamed after Epilykos the polemarch who rebuilt it: Arist. Ath. 3.5; Agora III, 177-178 no. 582; Develin, R. 1989, 34; conjectural location on the Akropolis North Slope, Travlos, J. 1971, 2, fig. 5 no. 17</p>
<p :class="{'activeClass': idClicked == NP1618 }" id="NP1618" >EPIMENIDES OF KNOSSOS, seated statue in the Eleusinion: Paus. 1.14.4; Agora XXXI, 3, 52 note 48, 53; cf. F GR HIST III B, no. 457, Epimenides von Kreta, 388 lines 7-12 with commentary, 319 (d)</p>
<p :class="{'activeClass': idClicked == NP1619 }" id="NP1619" >EPIONE: see Asklepios Ask20*, Ask22*, Ask23*, Ask25*</p>
<p :class="{'activeClass': idClicked == NP1620 }" id="NP1620" >EPIPYRGIDIA: see Charites and Artemis Epipyrgidia; Hekate Epipyrgidia</p>
<p :class="{'activeClass': idClicked == NP1621 }" id="NP1621" >EPISTASION, office of the epistatai, mentioned in an inventory of the Treasures of Athena: IG II² 1651 line 10; the epistasion probably had dining-rooms according to information from Kevin Clinton.</p>
<p :class="{'activeClass': idClicked == NP1622 }" id="NP1622" >EPITEGIOS: see Dioskouroi: Anakes and Heros Epitegios </p>
<p :class="{'activeClass': idClicked == NP1623 }" id="NP1623" >EPITRAGIA: see Aphrodite Aph24*, Aph25*</p>
<p :class="{'activeClass': idClicked == NP1624 }" id="NP1624" >EPONYMOI, the heroes after whom the tribes were named: Agora III, 85-90, nos. 229-245; Kron, U, 1976,13-31; Kearns, E. 1989, 80-92; N. F. Jones, “The Athenian Phylai as Associations: Disposition, Function, and Purpose”, Hesperia 64 (1995) 503-542</p>
<p :class="{'activeClass': idClicked == NP1625 }" id="NP1625" >1st monument, 5th c. B .C,: Ar. Pax 1183-4; conjectural location beneath the western end of the Middle Stoa, T. L. Shear, Jr., “The Monument of the Eponymous Heroes in the Athenian Agora,” Hesperia 39 (1970) 203-222, figs. 16-17; conjectural location near the Prytaneion, Travlos, J. 1971, 210; location beneath the Middle Stoa western end rejected, Kron, U. 1976, 229-232; cf. the 1998 plan of the remains under the west end of the Middle Stoa, J. McK. Camp, Hesperia 68 (1999) 256 fig. 1</p>
<p :class="{'activeClass': idClicked == NP1626 }" id="NP1626" >2nd monument, originally ten statues with a tripod at either end, enclosed by a fence, 350-325 B.C., east of the Metroon: Paus. 1.5.1-5 + 1.8.2, cf. 1.29.6; Agora Guide 1990, 69-72, 274 no. 79, figs. 7, 19 no. 30, 20a, 33-34; C. Vatin, “La base des Heros eponymes a Athenes au temps de Pausanias,” Ostraka IV (1995) 33-41, figs. 1-8; <JBcom>[in view of the Samos horos (Agora III, 90), the tripods and the fence, this must be a temenos]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1627 }" id="NP1627" >For the original ten Eponymoi, see Aias; Aigeus; Akamas; Antiochos; Erechtheus; Hippothoon; Kekrops; Leos; Oineus; and Pandion; for those added later, see Antigonos I of Macedon and Demetrios Poliorketes; Attalos I; Hadrian, Statues; Ptolemy II, Ptolemy III</p>
<p :class="{'activeClass': idClicked == NP1628 }" id="NP1628" >ERECHTHEION, 421 B.C. (construction may have started as early as ca. 431 B.C.) - 406/405 B.C., modern name for the building comprising the classical temple of Athena Polias and the Erechtheion (in ancient times the name for part of the building, never the whole building): IG I³ 474-479 (building accounts 409/8-405 B.C.); SEG 51 (2005) 39bis; IG II² 1654; Paus. 1.26.5 - 1.27.2; Caskey, L. D. et al. 1927; Hurwit, J. M. 1999, 380 Erechtheum; shown on Athenian imperial bronzes, Price, J. M. and B. L. Trell 1977, 68 fig. 130, 76 figs. 130-131, 77; <JBcom>[extensively rebuilt in the 2nd c. A.C.]</JBcom>; cf. A. Frantz, “Did Julian the Apostate Rebuild the Parthenon,” AJA 83 (1979) 396 note 9; see Chr. Mon. CM9*</p>
<p :class="{'activeClass': idClicked == NP1629 }" id="NP1629" >The classical temple of Athena Polias, ancient names: <br>Temple on the Akropolis in which is the Old Statue: IG I³ 474 line 1; Caskey, L. D. et al. 1927, 298-299 <br>Old Temple of The Polias: Str. IX.16, C 396; Caskey, L. D. et al. 1927, 475-476 Temple of Athena: Paus. 1.27.2 + 4 <br>Temple of The Polias: Paus. 1.27.1 + 3; according to J. H. Oliver's reading of IG II² 1076 lines 21-22, the temple was probably rededicated to Julia Domna as Athena Polias: J. H. Oliver, “Julia Domna as Athena Polias,” HSCP Suppl. Vol. I, 1940, 525-6 <br>Temple of Pallas Minerva: Vitr. 4.8.4; Caskey, L. D. et al. 1927, 476-477 <br>The Erechtheion: Paus. 1.26.5, referring to a two-level unit, twice called oikema, in the eastern part of the building; cf. Euphiletos' house (oikidion diploun), Erechtheus cuius Athenis et delubrum vidimus et sacerdotem: Cic. N.D. 111.49; cf. Caskey, L. D. et al. 1927, 480-492; Part II no.60</p>
<p :class="{'activeClass': idClicked == NP1630 }" id="NP1630" >Shrine north of the Old Temple (Athena Ath20*), a predecessor on the site of the Erechtheion east cella, end of 6th or start of 5th c. B.C., which continued to function during the construction of the Erechtheion east cella in 438-431 B.C., Korres, M. 1997, 229 with note 73, 242 with note 92, 243, fig. 2 no. 4</p>
<p :class="{'activeClass': idClicked == NP1631 }" id="NP1631" >ERECHTHEION GOLDEN LAMP with bronze palm tree chimney made for Athena by Kallimachos: Paus. 1.26.6-7; O. Palagia, “A Niche for Kallimachos' Lamp?”, AJA 88 (1984) 516-521, figs. 1-3, pls. 66-67; W. Fuchs, “Paralipomena: II. Der Chrysos Lychnos im Erechtheion,” in Kotinos 1992, 201-203</p>
<p :class="{'activeClass': idClicked == NP1632 }" id="NP1632" >ERECHTHEION NORTH COURT AND STAIRWAY: Caskey et al. 1927, General Index s.v. Pavement, east of North Portico</p>
<p :class="{'activeClass': idClicked == NP1633 }" id="NP1633" >Theatral area conjectured in the Erechtheion north court with stepped seats on the north and east sides: Stevens, G. P. 1946b, 97-102, figs. 15-18; traces on the eastern end of the Erechtheion north wall attest the existence of a stairway leading from the lower courtyard to the upper level <JBcom>[but there is no evidence for restoring steps right across the east and north sides of the court]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1634 }" id="NP1634" >ERECHTHEIS: see Poseidon, Spring</p>
<p :class="{'activeClass': idClicked == NP1635 }" id="NP1635" >ERECHTHEUS: Mikalson, J. D. 19976, 141-153; LIMC IV, 1988, 923-951, s.v. Erechtheus (U. Kron); Kearns, E. 1989, 113-115, 133, 160</p>
<p :class="{'activeClass': idClicked == NP1636 }" id="NP1636" >Altar implied by mention of bulls and rams sacrificed to Erechtheus: Iliad II. 546-550; Kron; U. 1976, 32-37</p>
<p :class="{'activeClass': idClicked == NP1637 }" id="NP1637" >Grave: E. Ion 281; ibid., 43-48</p>
<p :class="{'activeClass': idClicked == NP1638 }" id="NP1638" >House on the Akropolis: Od. VII.81; Kron, U. 32-33; Mazarakis Ainian, A. 1997, 368, 392</p>
<p :class="{'activeClass': idClicked == NP1639 }" id="NP1639" >Picture in the Erechtheion, E. driving a chariot: schol. Aristid. XIII.107; LIMC IV, 1988, 936 no. 52, s.v. Erechtheus (U. Kron)</p>
<p :class="{'activeClass': idClicked == NP1640 }" id="NP1640" >Eponymous Hero statue: Paus. 1.5.2; ibid., 942-943 no. 81</p>
<p :class="{'activeClass': idClicked == NP1641 }" id="NP1641" >Statue by Myron in Athens: Paus. 9.30.1, possibly = Erechtheus fighting Eumolpos q.v.</p>
<p :class="{'activeClass': idClicked == NP1642 }" id="NP1642" >Temple (or sanctuary) on the Akropolis in which were the olive tree and the thalassa: Hdt. 8.55; Kron, U. 1976, 40-41</p>
<p :class="{'activeClass': idClicked == NP1643 }" id="NP1643" >Erechtheus fighting Eumolpos (or Immarados), large bronze sculpture group on the Akr. near the temple of Athena: Paus. 1.27.4, cf. 1.5.2; Kron, U. 1976, 50, 77; LIMC III, 1986, 269, s.v. Chione 1 (U. Kron); LIMC IV, 1988, 940 no. 69, s.v. Erechtheus (U. Kron); <JBcom>[doubtably]</JBcom> thought to be part of a 4-figure group with Tolmides and his seer, C. Ioakimidou, Die Statuenreihen griechischer Poleis und Bunde aus spatarchaischer und klassischer Zeit, Munchen, 1997, 99-100, 262-273 See also: Athena Ath112*, Ath118*, Ath127*; Erechtheion; Poseidon in the Erechtheion </p>
<p :class="{'activeClass': idClicked == NP1644 }" id="NP1644" >ERECHTHEUS' DAUGHTERS: see Hyakinthides</p>
<p :class="{'activeClass': idClicked == NP1645 }" id="NP1645" >ERETRIA, earlier name for what was the Agora in Strabo's time: Str. 10.1.10; thought to be the area of the Roman Agora, Travlos, J. 1971, 28</p>
<p :class="{'activeClass': idClicked == NP1646 }" id="NP1646" >ERGANE: see Athena Ath60*-65*</p>
<p :class="{'activeClass': idClicked == NP1647 }" id="NP1647" >ERIAI GATE: no ancient source refers to a specific gate named Ήρίαι πύλαι, Alabe, F. 1987,133-135; Billot, M.-F. 1989, 702 no. 5; cf. Themistoklean City Wall Wal9*, Gate at Leokoriou and Dipylou Sts.</p>
<p :class="{'activeClass': idClicked == NP1648 }" id="NP1648" >ERICHTHONIOS, grave in the temple of The Polias: Clem. Al. Protr. III.39; Agora III, nos. 194-195; Kearns, E. 1989, 160 -161; “The appearance and definition of Erichthonius appeared in the 5th c. B.C.,” Mikalson, J. D. 1976, 141-142</p>
<p :class="{'activeClass': idClicked == NP1649 }" id="NP1649" >ERIDANOS RIVER: Paus. 1.19.5; Camp, J. McK. 1980, 20, 289, 290, 293-294</p>
<p :class="{'activeClass': idClicked == NP1650 }" id="NP1650" >Terracotta pipeline, ca. 500-450 B.C., in the Eridanos river bed at Plateia Syntagmatos: Metro Excavations 1992-1997, 149, 154-155, figs. 7-8 (O. Zacharidou)</p>
<p :class="{'activeClass': idClicked == NP1651 }" id="NP1651" >Eridanos channel with good masonry floor, walls, cover-slabs, 475-450 B.C., along the north side of the Agora Excavations: T. L. Shear, Jr., “The Athenian Agora: Excavations of 1989-1993,” Hesperia 66 (1997) 514-521, figs. 1-2, pls. 99:b, 100-102::a; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM387">387</a></p>
<p :class="{'activeClass': idClicked == NP1652 }" id="NP1652" >Eridanos retaining walls and passages at the Sacred Gate: Noack, F. 1907,150-152, 153, 159-160, 477-480, 496-497, 499 with figs. 14-15, 17 and pls. 10-11, 13</p>
<p :class="{'activeClass': idClicked == NP1653 }" id="NP1653" >Themistoklean channel walls: Kerameikos X, 14</p>
<p :class="{'activeClass': idClicked == NP1654 }" id="NP1654" >Passage with corbelled vault, early 4th c. B.C.?: Young, R. S. 1951, 153, pl. 56b</p>
<p :class="{'activeClass': idClicked == NP1655 }" id="NP1655" >Passage with true vault: loc. cit., 153; Kerameikos X,166, pl. 15</p>
<p :class="{'activeClass': idClicked == NP1656 }" id="NP1656" >Brick tunnel preserved behind the Sacred Gate and near the Tritopatreion: Kyparisses, N. and H. A. Thompson 1938, 615 with note 2</p>
<p :class="{'activeClass': idClicked == NP1657 }" id="NP1657" >Portcullis gate with reused material, 6th c. A.C.: H. R. Goette, “Ein dorischer Architrav im Kerameikos von Athen,” AM 104 (1989) 83-84 with note 5, fig. 1, pls. 12-13</p>
<p :class="{'activeClass': idClicked == NP1658 }" id="NP1658" >Monolithic marble arch roughly hacked out of a huge block, set up after the roof of the brick tunnel had collapsed: Alten, G. von 1878, 45; H. R. Goette, op. cit., 83 fig. 1</p>
<p :class="{'activeClass': idClicked == NP1659 }" id="NP1659" >Doric architrave recording 17 honours for Nausikles, son of Klearchos, including a bronze statue set up by the hippeis, ca. 325 B.C., reused as a pier on the north side of the portcullis gate - - - IG II² 3206; SEG 39 (1989) no. 211; H. R. Goette, op. cit., 83-103, figs. 1-3, pls. 12-16</p>
<p :class="{'activeClass': idClicked == NP1660 }" id="NP1660" >Marble pier, reused grave stele of Euthykrates and Theophron, sons of Euthyphron of Kerameis, 4th c. B.C. - - - IG II² 6323; <JBcom>[the deme Kerameis turns up remarkably rarely on grave inscriptions found in the Kerameikos Excavations]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1661 }" id="NP1661" >Channel walls with reused material of 2nd-3rd c. A.C., demolished in 1874; S. A. Koumanoudes, «Έπιγραφαι έκ των άνασκαφών Διπύλου,» Ath. 3 (1874) 267; <JBcom>[a post-Herulian repair]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1662 }" id="NP1662" >Sarcophagi with mythological reliefs: A. Brueckner, “Mitteilungen aus dem Kerameikos V,”, AM 56 (1931) 30</p>
<p :class="{'activeClass': idClicked == NP1663 }" id="NP1663" >Naiskos, grave mon. for Aurelius Rufus ca. 150-200 A.D. - - - IG II² 13211; SEG 35 (1985) no. 208</p>
<p :class="{'activeClass': idClicked == NP1664 }" id="NP1664" >Bridges over the Eridanos in the area of the Sacred Gate</p>
<p :class="{'activeClass': idClicked == NP1665 }" id="NP1665" >Bridge near the Tritopatreion, late 4th c. B.C.: Knigge, U. 1991, 150-151, fig. 165 no. 50; Bougia, P. 1996, 156-157, pl. 13 no. 50, pl. 14 (Eridanos Bridge II)</p>
<p :class="{'activeClass': idClicked == NP1666 }" id="NP1666" >Bridge across from the Thersandros and Simylos Mon., 350-300 B.C.: U. Knigge, “Kerameikos: Tatigkeitsbericht 1975/76,” AA 1978, 60-61 and figs. 12, 28; Bougia, P. 1996, 155-156, pl. 13 no. 51 (Eridanos Bridge I)</p>
<p :class="{'activeClass': idClicked == NP1667 }" id="NP1667" >Bridge behind the Sacred Gate near Melidoni St.: U. Knigge, “Tatigkeitsbericht Kerameikos 1977,”, AA 1979,182-184, figs. 2, 3, 8; 4th c. A.C.; Bougia, P.1996, 158-159, pls. 13,15 (Eridanos Bridge III)</p>
<p :class="{'activeClass': idClicked == NP1668 }" id="NP1668" >ERINYES: see Areopagus; Semnai; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM466">466</a></p>
<p :class="{'activeClass': idClicked == NP1669 }" id="NP1669" >ERITHASEOS: see Apollo Apo35*</p>
<p :class="{'activeClass': idClicked == NP1670 }" id="NP1670" >ERITHOS: see Artemis Art33*</p>
<p :class="{'activeClass': idClicked == NP1671 }" id="NP1671" >EROS AT THE ACADEMY: Billot, M.-F. 1989,. 706-709, 773-776</p>
<p :class="{'activeClass': idClicked == NP1672 }" id="NP1672" >Altar with epigram stating that Charmos was the first Athenian to dedicate an altar to Eros, at the Academy Gymnasium: Paus. 1.30.1; in Sokrates' dream, Apul. Pl. 1.1; Riginos, A. S. 1976, 21-24</p>
<p :class="{'activeClass': idClicked == NP1673 }" id="NP1673" >Statue dedicated by Peisistratos in the Academy where those in the sacred torch race light their torches: Plu. Sol. I.4; F GR HIST III B, suppl. vol. I, 72; Frazer, J. G. 1913, II, 390-391</p>
<p :class="{'activeClass': idClicked == NP1674 }" id="NP1674" >EROS AT OTHER SITES</p>
<p :class="{'activeClass': idClicked == NP1675 }" id="NP1675" >Eros wearing a wreath of roses, picture by Zeuxis in the temple of Aphrodite: schol. Ar. Ach. 992; Geschwantler, K. 1978, col. 1482 no. 2</p>
<p :class="{'activeClass': idClicked == NP1676 }" id="NP1676" >Statue by Praxiteles with an epigram by Praxiteles on the base, in the Theatre of Dionysos: Ath. 591 a-b; cf. Paus. 1.20.1-3 (the story about Phryne); H. Lauter, “Zur wirtschaftlichen Position der Praxiteles-Familie im spatklassischen Athen,”, AA 1980, 529-530; SEG 47 (1997) nos. 206, 518 See also: Dionysos Dio25*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM519">519</a></p>
<p :class="{'activeClass': idClicked == NP1677 }" id="NP1677" >EROS AND APHRODITE, sanctuary on the Akropolis North Slope: <JBcom>[mistakenly]</JBcom> identified as Aphrodite in the Gardens, Broneer, O. 1932, 31-55, figs. 1-17; idem, 1935, 140-148, figs. 30-31, 33, 35, pl. I; <JBcom>[mistakenly]</JBcom> identified as a second shrine of Aphrodite in the Gardens in order to support an <JBcom>[imaginary]</JBcom> route taken by the Arrhephoroi (q.v.) on their way to a precinct not far from</p>
<p :class="{'activeClass': idClicked == NP1678 }" id="NP1678" >Aphrodite in the Gardens, Travlos, J. 1971, 72, 228</p>
<p :class="{'activeClass': idClicked == NP1679 }" id="NP1679" >Eros, lex sacra, rock-cut inscr., 450-430? B.C. - - - IG I³ 1382a; SEG 10 (1949) no. 27; Travlos, J. 1971, figs. 295, 297</p>
<p :class="{'activeClass': idClicked == NP1680 }" id="NP1680" >Aphrodite, rock-cut inscr., 5th c. B.C. - - - IG I³ 1382b; SEG 10 (1949) no. 324; Travlos, J. 1971, figs. 296-297</p>
<p :class="{'activeClass': idClicked == NP1681 }" id="NP1681" >Frieze of Erotes, second half of 2nd c. B.C., interpreted as angels (interpretatio Christiana) and built into the church of Hag. Dimitrios tou</p>
<p :class="{'activeClass': idClicked == NP1682 }" id="NP1682" >Katiphori (Chr. Mon. CM59*, Plan 1 delta—7) NM 1451-1452; S. Papaspyridi-Karouzou, «Ελληνιστικά αντίγραφα και έπαναλήψες αρχαίων,» ΑΕ 1956, 165-173, figs. 3-6, pls. 4-5; “probably from the enclosure wall of the sanctuary,” Kaltsas, N. 2002, 289-280 no. 610, ph.</p>
<p :class="{'activeClass': idClicked == NP1683 }" id="NP1683" >Twenty-two rock-cut niches: Broneer, O. 1932, 41 note 1, figs. 1,6-9 See also: Aphrodite Aph40**</p>
<p :class="{'activeClass': idClicked == NP1684 }" id="NP1684" >EROS AND APHRODITE (continued): see Aphrodite and Eros Ero51*-55*</p>
<p :class="{'activeClass': idClicked == NP1685 }" id="NP1685" >EROS AND DIONYSOS, sculpture by Thymilos: Paus. 1.20.2; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.,” Jahrbuch 88 (1973) 196-197</p>
<p :class="{'activeClass': idClicked == NP1686 }" id="NP1686" >EROS PSITHYROS: see Aphrodite Aph50*</p>
<p :class="{'activeClass': idClicked == NP1687 }" id="NP1687" >ETEOBOUTAD PRIESTS OF POSEIDON, pictures in the Erechtheion: Holscher, T. 1975,189,190 with note 32</p>
<p :class="{'activeClass': idClicked == NP1688 }" id="NP1688" >Wall paintings of the genos of the Boutadai: Paus. 1.26.5</p>
<p :class="{'activeClass': idClicked == NP1689 }" id="NP1689" >Picture showing members of Lykourgos' family who were priests of Poseidon, including Habron handing the trident to his brother Lykophron, painted on wood by Ismenias of Chalkis, dedicated by Habron, son of Lykourgos <JBcom>[II]</JBcom> in the Erechtheion: Plu. Moralia 843 F</p>
<p :class="{'activeClass': idClicked == NP1690 }" id="NP1690" >ETEOKLES, son of Chremonides, father of the famous Chremonides, statue base, theater IG II² 3845; for the findspot, see Levensohn, M. and E. 1947, 71 no. 51 in fig. 1</p>
<p :class="{'activeClass': idClicked == NP1691 }" id="NP1691" >EUBOULE: see Nymphe, theater seat </p>
<p :class="{'activeClass': idClicked == NP1692 }" id="NP1692" >EUBOULEUS: see Demeter and Kore</p>
<p :class="{'activeClass': idClicked == NP1693 }" id="NP1693" >EUBOULEUS, the so-called Eubouleus sculpture type: perhaps Triptolemos, LIMC IV, 1988, 43-46, esp. 44 no. 3, s.v. Eubouleus (G. Schwarz)</p>
<p :class="{'activeClass': idClicked == NP1694 }" id="NP1694" >Head, Augustan pd., Akropolis Lower South Slope in the area of the Military Hospital (now the Center for Akropolis Studies) on Makriyanni St. (Plan 2 eta - 5) NM 2650; G. Schwarz, “Zum sogenannten Eubouleus,” GettyMJ II (1975) 73, figs. 3, 6; Kaltsas, N, 2002, 265 no. 554, ph.</p>
<p :class="{'activeClass': idClicked == NP1695 }" id="NP1695" >Head, Kallisperi St. between Erechtheiou and Parthenonos St. (Plan 2 eta - 4): O. Alexandri,«Καλλισπέρι, εργα Δήμου,» Deltion 23 (1968) B1 Chr., 63 no. 42, fig. 1 no. 44, fig. 33 b</p>
<p :class="{'activeClass': idClicked == NP1696 }" id="NP1696" >Head frag. - - - Acr. 2394; G Schwarz, loc. cit.</p>
<p :class="{'activeClass': idClicked == NP1697 }" id="NP1697" >Head Acr. 7285; G. Schwarz, loc. cit.</p>
<p :class="{'activeClass': idClicked == NP1698 }" id="NP1698" >Unfinished bust <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202089" target="_blank">Agora S 2089</a>; G. Schwarz, loc. cit. with fig. 7:a-b</p>
<p :class="{'activeClass': idClicked == NP1699 }" id="NP1699" >“EUBOULIDES MONUMENT”, statue or statue group in the precinct of Dionysos Melpomenos: Paus. 1.2.4-5; Despinis, G. 1995, 321-337, pls. 62-68; Aneziri, S. 2000, 266-267, 276-279; see Apollo Apo12*-13*</p>
<p :class="{'activeClass': idClicked == NP1700 }" id="NP1700" >EUBOULOS, son of Spintharos, died before 330 B.C.</p>
<p :class="{'activeClass': idClicked == NP1701 }" id="NP1701" >Grave <JBcom>[at public expense]</JBcom> on the Road to the Academy: Paus. 1.29.10; Habicht, Chr. 1979,9</p>
<p :class="{'activeClass': idClicked == NP1702 }" id="NP1702" >Private grave monument, lost marble relief lekythos IG II² 11370; Conze, A. 1893-1922, no. 751, pl. 143 (drawing) and vol IV, 117 (photo from plaster cast); PAA no. 268285; <JBcom>[Euboulos standing, clasping right hands with his seated father Spintharos who receives his son in the Underworld (dexiosis)', the woman Boulete may be Euboulos' mother]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1703 }" id="NP1703" >EUCHLOOS: see Demeter Euchloos</p>
<p :class="{'activeClass': idClicked == NP1704 }" id="NP1704" >EUDANEMOI, altar, opposite to the Metroon and not far from Harmodios and Aristogeiton in the Agora: Arr. An. 3.16.8; Agora XIV, 157-158; Kearns, E. 1989, 163; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM268">268</a></p>
<p :class="{'activeClass': idClicked == NP1705 }" id="NP1705" >EUDOKIA (originally Athenais), base of a statue put up by her husband Theodosius II soon after A.D. 421 ? <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203558" target="_blank">Agora I 3558</a>; E. Sironen, “An Honorary Epigram for the Empress Eudocia in the Athenian Agora,” Hesperia 59 (1990) 371-374, pl. 69; SEG 40 (1991) no. 184; J. Burman, “The Athenan Empress Eudocia,” in Castren, P. ed. 1994, 81-83; Sironen, E. 1997, 84-85</p>
<p :class="{'activeClass': idClicked == NP1706 }" id="NP1706" >EUEGOROS, son of Philoinos, ISTHMONIKE, daughter of Lysis, and OTHERS (Davies, J. K. 1971, 360-361), grave stele built into the central pier of the Late Hellenistic arched gate closing off the Dipylon courtyard (Themistoklean City Wall Wal7*) - - - IG II² 7045; G. P. Oikonomos, “Aus dem Dipylon,” AM 37 (1912) 226-229, figs. 1, 3:A; Gruben, G. 1964, 390; R. S. Stroud, “The Gravestone of Sokrates' Friend, Lysis,” Hesperia 53 (1984) 356-357</p>
<p :class="{'activeClass': idClicked == NP1707 }" id="NP1707" >EUERGETES: see Diogenes Euergetes</p>
<p :class="{'activeClass': idClicked == NP1708 }" id="NP1708" >EUETERIA, personification of bounty, statue base EM 1927; SEG 23 (1968) no. 110</p>
<p :class="{'activeClass': idClicked == NP1709 }" id="NP1709" >1st use, statue of Eueteria, 299 B.C.: this statue of Eueteria (“the Greek model of the Roman Annona” ) is linked to a large donation of grain made to the Athenians by Lysimachos, Raubitschek, A. E. 1966, 242-243 no. 2</p>
<p :class="{'activeClass': idClicked == NP1710 }" id="NP1710" >2nd use, polemarch's dedication, 2nd c. B.C.: SEG 21 (1965) no. 675</p>
<p :class="{'activeClass': idClicked == NP1711 }" id="NP1711" >3rd use, statue of Demosthenes, 1st c. B.C. or A.C.: ibid., no. 772</p>
<p :class="{'activeClass': idClicked == NP1712 }" id="NP1712" >EUKLEIA and EUNOMIA, sanctuary: IG II² 1035 line 53; LIMC IV, 1988, 48-49, 51, s.v. Eukleia and 62-65, s.v. Eunomia (A. Kossatz-Deissmann)</p>
<p :class="{'activeClass': idClicked == NP1713 }" id="NP1713" >Eukleia, temple in honour of the victory at Marathon, still farther off from the temple of Triptolemos: Paus. 1.14.5; Agora III, 58-59; Freeden, J. von 1983, 12 with note 56; <JBcom>[the temple is on top of the Areopagus]</JBcom>; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM226">226</a></p>
<p :class="{'activeClass': idClicked == NP1714 }" id="NP1714" >Eukleia and Eunomia, priest's theater throne - - - IG II² 5059; Maass, M. 1972, 127 pl. 15</p>
<p :class="{'activeClass': idClicked == NP1715 }" id="NP1715" >EUKOLOS, cult: IG II² 1950 line 12; Vidman, L. 1969, no. 30</p>
<p :class="{'activeClass': idClicked == NP1716 }" id="NP1716" >EUKTEMON'S lodging house in the Kerameikos by the postern gate where wine is sold: Isaeus VI.20; Judeich, W. 1931, 143 note 5; H. Lind, “Ein Hetarenhaus am Heiligen Tor? Der Athener Bau Z und die bei Isaios erwahnte Synoikia Euktemons,” MH 45 (1988) 158-169; <JBcom>[the postern gate in question has been incorrectly identified with the one beside the Sacred Gate (Themistoklean City Wall Wal4*) which along with the Sacred Gate itself was not in the Kerameikos]</JBcom>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM389">389</a>.</p>
<p :class="{'activeClass': idClicked == NP1717 }" id="NP1717" >EUMARIDAS of Kydonia, statue to be set up in the temenos of Demos and the Charites (revising an earlier permission to set it on the Akropolis), 193/2 B.C.: IG II² 844 lines 39-41; Agora III, 60 no. 127; Habicht, Chr. 1982, 84-85, 99; Monaco, M. C. 2001, 114, 120, fig. 9</p>
<p :class="{'activeClass': idClicked == NP1718 }" id="NP1718" >EUMENES II OF PERGAMON</p>
<p :class="{'activeClass': idClicked == NP1719 }" id="NP1719" >Colossal statues of Eumenes and Attalos relabelled Antonius: Plu. Ant. 60.3; Chr. Habicht, “Athens and the Attalids in the Second Century B.C.,” Hesperia 59 (1990) 572 with notes 62-63</p>
<p :class="{'activeClass': idClicked == NP1720 }" id="NP1720" >Stoa of Eumenes, 197-160/59 B.C., Akropolis South Slope: Vitr. 5.9.1; Travlos, J. 1971, 523-526, figs. 660-664; Korres, M. 1983, 201-207, figs. 1-3; Chr. Habicht, op. cit., 563 with note 10; Schenkungen hellenistischer Herrscher, Part I, 1995, 62-63; see Dionysos Dio39* (reused geisa); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM162">162</a></p>
<p :class="{'activeClass': idClicked == NP1721 }" id="NP1721" >Pergamene masons' marks on column bases and drums, geison blocks - - - SEG 38 (1988) no. 261; Korres, M. 1983, 204 fig. 3</p>
<p :class="{'activeClass': idClicked == NP1722 }" id="NP1722" >Fountain set in the back wall: Camp, J. McK. 1980, 164-166</p>
<p :class="{'activeClass': idClicked == NP1723 }" id="NP1723" >Aphrodisios graffito on the fountain: U. Kohler, “Hallenanlage am SOdfusse der Akropolis zu Athen "AM 3 (1878) 149 note 2</p>
<p :class="{'activeClass': idClicked == NP1724 }" id="NP1724" >Eumenes II, Donor's Monument, in front of the Stoa of Eumenes: the fragments of a Pergamene pillar base for a chariot group recognized and tentatively located on the Akropolis, Korres, M. 2000, 325-328, figs. 32-34; attributed to a Donor's Monument in front of the Stoa of Eumenes by Martin Kreeb in 2001 <JBcom>[communicated by word of mouth in April 2002]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1725 }" id="NP1725" >EUMENIDES at the Areopagus: see Semnai</p>
<p :class="{'activeClass': idClicked == NP1726 }" id="NP1726" >EUMENIDES at Kolonos Hippios, precinct: S. OC. 38-43, 84-101, 125-137, 155-165, 466-506; Apollod. 3.5.9; Svoronos, J. N. 1911, 389-395; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM467">467</a></p>
<p :class="{'activeClass': idClicked == NP1727 }" id="NP1727" >EUNEIDAI: see Dionysos Dio49*</p>
<p :class="{'activeClass': idClicked == NP1728 }" id="NP1728" >EUNOMIA: see Eukleia</p>
<p :class="{'activeClass': idClicked == NP1729 }" id="NP1729" >EUPATRID EXPOUNDER of Sacred Law, theater throne — IG II² 5049; Maass, M. 1972, 122, pl. 13</p>
<p :class="{'activeClass': idClicked == NP1730 }" id="NP1730" >EUPHILETOS' house: a little two-storey house (oikidion diploun), G. Morgan, “Euphiletos' House: Lysias I,” ΤΑΡΑ 112 (1982) 115-123</p>
<p :class="{'activeClass': idClicked == NP1731 }" id="NP1731" >EUPOLIS, comic poet, inscribed portrait herm, early Roman, found in excavations for an underground garage at the Parliament Building, Plateia Syntagmatos (Plan 2 eta/zeta—6) — SEG 48 (1998) no. 234; Athens News, Feb. 13, 1997, 3 with ph.</p>
<p :class="{'activeClass': idClicked == NP1732 }" id="NP1732" >EUPORIA, altar (?) with large birds in relief, ca. 200 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203328" target="_blank">Agora I 3328</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 29 (1960) 64 no, 109, pl. 20; SEG 19 (1963) no. 224</p>
<p :class="{'activeClass': idClicked == NP1733 }" id="NP1733" >EURIPIDES</p>
<p :class="{'activeClass': idClicked == NP1734 }" id="NP1734" >Cenotaph on the Road from Peiraeus: Paus. 1.2.2; M. R. Lefkowitz, “The Euripides Vita”, GRBS 20 (1979) 190, 203; Further Greek Epigrams 1981, 307-308</p>
<p :class="{'activeClass': idClicked == NP1735 }" id="NP1735" >Statue in the theater: Paus. 1.21.1; Gauer, W. 1968b,134-135</p>
<p :class="{'activeClass': idClicked == NP1736 }" id="NP1736" >Terracotta emblemata portraits <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2013581" target="_blank">Agora P 13581</a>+; Richter, G. M. A. 1965, I, 137, fig. 762</p>
<p :class="{'activeClass': idClicked == NP1737 }" id="NP1737" >EURYGYES (who is Androgeos, son of Minos, according to Amelesagoras), grave or heroon in the Kerameikos: Hsch. s.v. επ' Εύρυγύη άγων; F GR HIST III B, 164, Amelesagoras 330 F 2 with suppl. vols. I, 603-6 and II, 492-495; Kearns, E. 1989, 122, 149 s.v. Androgeos, 164 s.v. Eurygyes</p>
<p :class="{'activeClass': idClicked == NP1738 }" id="NP1738" >EURYKLEIDES, ventriloquist seer, statue in the theater: Ath. 1.19 the passage in Athenaeus refers to Eurykles of Athens, E. H. Musirillo, S. J., “The Problem of Lying and Deceit and the Two Voices of Euripides' Hippolytus 925-31,” TAPA 104 (1974) 237-238</p>
<p :class="{'activeClass': idClicked == NP1739 }" id="NP1739" >EURYMEDON of Myrrhinos, one of Plato's executors, property or properties in Eiresidai, adjoining Plato's property, mentioned in Plato's will: D.L. 3.42; Davies, J. K. 1971, 334-5; Eurymedon's property perhaps adjoined Plato's Garden, Lynch, J. P. 1972, 126</p>
<p :class="{'activeClass': idClicked == NP1740 }" id="NP1740" >EURYSAKES, son of Aias, temenos on Kolonos Agoraios in Melite: Harp. s.v. Μελίτη and Κολωνέτας; Agora III, 90-93, 225; Ferguson, W. S. 1938, 15-16; Kron, U. 1976, 138, 172-174; Kearns, E. 1989, 70-71, 82, 107-108, 164</p>
<p :class="{'activeClass': idClicked == NP1741 }" id="NP1741" >Altar: Paus. 1.35.3; Agora III, no. 248</p>
<p :class="{'activeClass': idClicked == NP1742 }" id="NP1742" >Sacrificial calendar of the genos Salaminioi to be set up in the Eurysakeion, 363/2 B.C., found in a cistern southwest of the Hephaisteion <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203244" target="_blank">Agora I 3244</a>; W. S. Ferguson, op. cit., 1-76, fig. 1; SEG 25 (1971) no. 148</p>
<p :class="{'activeClass': idClicked == NP1743 }" id="NP1743" >EUTAXIA, DEMOS (?), TRIPOD ON COLUMN, HOPLITE, document relief, ca. 325 B.C., Akr. - - - NM 2958, IG II² 417; Lawton, C. L. 1996, 57, 59, 146 no. 150, pl. 79; SEG 48 (1998) no. 106; concerning the Panathenaia or the Amphiareia of 329/8 B.C., Palagia, O. 2002, 171-172, fig. 6</p>
<p :class="{'activeClass': idClicked == NP1744 }" id="NP1744" >EVAGORAS, king of Salamis in Cyprus, statue in front of the Stoa of Zeus, decreed in 393 B.C.: Paus. 1.3.2; D. M. Lewis and R. S. Stroud, “Athens Honors King Euagoras of Salamis,” Hesperia 48 (1979) 187, 192-193; Lawton, C. L. 1995, 122 no. 184</p>
<p :class="{'activeClass': idClicked == NP1745 }" id="NP1745" >EXEGETES: see Eupatrid Expounder; Pythochrestes</p>
<p :class="{'activeClass': idClicked == NP1746 }" id="NP1746" >FAUSTINA THE ELDER = Annia Galeria Faustina = Faustina Augusta (PIR, 2nd ed.,715), portrait Acr. 2360; A. Stavridi, «Ή εικονογραφία τής Φαυστίνας τής πρεσβύτερης στον έλληνικό χώρο,» ΑΕ 1976, 139</p>
<p :class="{'activeClass': idClicked == NP1747 }" id="NP1747" >FAUSTINA THE YOUNGER = Annia Galeria Faustina (II) = Faustina Augusta (PIR, 2nd ed., 716), portrait <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20336" target="_blank">Agora S 336</a>; Agora I, no.33, pl. 21</p>
<p :class="{'activeClass': idClicked == NP1748 }" id="NP1748" >FAVORINUS OF ARLES, bronze statue: Philostr. VS 1.8.3; Ameling, W. 1983,1,45-47</p>
<p :class="{'activeClass': idClicked == NP1749 }" id="NP1749" >FESTUS, Postumius Rufius Festus signo Avienus, proconsul of Hellas (PLRE I, Festus 12, 336-337), reused statue base, after 372 A.D., seen by Cyriacus of Ancona in the Parthenon in April 1436 — IG II² 4222; E. W. Bodnar, S. J., “Athens in April 1436, Part I,” Archaeology 23 (1970) 103, ph.; SEG 42 (1992) no. 177; Sironen, E. 1997, 66-69 no. 12; Achaia alternatively called Hellas, T. Corsten, “‘Proconsul Graeciae',” ZPE 117 (1997)117-122</p>
<p :class="{'activeClass': idClicked == NP1750 }" id="NP1750" >Base first used for L. Egnatius Victor Lollianus (PIR, 2nd ed., Egnatius 36) - - - IG II² 4217</p>
<p :class="{'activeClass': idClicked == NP1751 }" id="NP1751" >FIDES, temple: Plautus Aul. 583; E. Bernet, RE XX,1950, col. 1812 (Pistis)</p>
<p :class="{'activeClass': idClicked == NP1752 }" id="NP1752" >FOUNTAIN HOUSE “in the osiers” where Phrynichos was murdered: Lycurg. Leocr. 112; Agora III, 132 under no. 405, 142 under no. 455; H. Thompson, H. A. 1937, 213 note 4; Agora XIV, 1972, 201 note 48</p>
<p :class="{'activeClass': idClicked == NP1753 }" id="NP1753" >FRANKISH PAVILION on the Ilissos at Kallirrhoe: EMME 1,1927, 53</p>
<p :class="{'activeClass': idClicked == NP1754 }" id="NP1754" >FULVIUS PLAUTIANUS, C. (?), colossal statue to be set up beside a statue of an emperor (Caracalla ?), 203 A.D. (?): Agora 4853 lines 5-7; IG II² 1081/5, 1116 +; Oliver, J. H. 1941a, 85-90 no. 37 lines 5-7, ph.; highly debatable identifications, Agora XVI, no. 342, 485-487</p>
<p :class="{'activeClass': idClicked == NP1755 }" id="NP1755" >GAIEOCHOS: see Poseidon in the Erechtheion</p>
<p :class="{'activeClass': idClicked == NP1756 }" id="NP1756" >GARDENS, place-name, seen by Pausanias between Apollo Delphinios and Kynosarges: Paus. 1.19.2; see Aphrodite Aph32*</p>
<p :class="{'activeClass': idClicked == NP1757 }" id="NP1757" >GE, statue in the sanctuary of the Semnai: Paus. 1.28.6</p>
<p :class="{'activeClass': idClicked == NP1758 }" id="NP1758" >GE HEMEROS and POTHOS, cult or cults: IG II² 3664 lines 4-5</p>
<p :class="{'activeClass': idClicked == NP1759 }" id="NP1759" >GE KARPOPHOROS, statue of Ge praying to Zeus to rain on her at a time of drought, with a rock-cut inscription which must predate Pausanias' visit ca. 150 A.D., Akr. — IG II² 4758; Paus. 1.24.3: Stevens, G. P. 1946a, 1-4, fig. 3 no. 1, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1760 }" id="NP1760" >GE KOUROTROPHOS</p>
<p :class="{'activeClass': idClicked == NP1761 }" id="NP1761" >Altar established by Erichthonios: Suid. s.v. Κουρότροφος; W. Ferguson, W. S. 1938, 21; Mikalson, J. D. 1976, 144 note 12</p>
<p :class="{'activeClass': idClicked == NP1762 }" id="NP1762" >Sanctuary between Aphrodite Pandemos and the Propylaia: Paus. 1.22.3; Beschi, L. 1967/1968b, 517-528; <JBcom>[mistaken]</JBcom> conjectural location on the Akropolis upper west slope, Travlos, J. 1971, 2, fig. 5 no. 7 See also: Blaute and Kourotrophos; Kourotrophion; Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP1763 }" id="NP1763" >GE OLYMPIA, temenos within the Olympieion precinct: Paus. 1.18.7; Thuc. 11.15.4 (sanct. of Ge without the epithet); Judeich, W. 1931, 58-59, 385-6; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM555">555</a></p>
<p :class="{'activeClass': idClicked == NP1764 }" id="NP1764" >Cleft inside the temenos where Deukalion's flood drained away: Paus. 1.18.7; F GR HIST 111 B, suppl. vol. I, 400 with note 3; Wickens, J. M. 1986, 317-318; <JBcom>[implausibly]</JBcom> located in the temple of Zeus Olympios, Tolle-Kastenbein, R. 1994, 91,122,123,129-130,159,167</p>
<p :class="{'activeClass': idClicked == NP1765 }" id="NP1765" >GE THEMIS, hersephoroi, theatre seat IG II² 5130</p>
<p :class="{'activeClass': idClicked == NP1766 }" id="NP1766" >GE and OTHERS: see Moirai; Zeus Zeu66*</p>
<p :class="{'activeClass': idClicked == NP1767 }" id="NP1767" >GELEON: see Zeus Zeu29*</p>
<p :class="{'activeClass': idClicked == NP1768 }" id="NP1768" >GEORGOS: see Zeus Zeu30*</p>
<p :class="{'activeClass': idClicked == NP1769 }" id="NP1769" >GEPHYRAIOI, letter of the genos to the Delphians on behalf of the priests of Zeus in the Palladion and a letter from the Delphians to the genos with an authenticated copy of an oracle from Apollo, ca. 37/36 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202044" target="_blank">Agora I 2044</a>, IG II² 1096; J. H. Oliver, “From Gennetai to Curiales,” Hesperia 49 (1980) 38-43; SEG 30 (1980) no. 85 See also: Apollo Apo36*, Apo63*; Demeter Achaia</p>
<p :class="{'activeClass': idClicked == NP1770 }" id="NP1770" >GERAISTOS the Kyklops, grave where Hyakinthos' daughters were sacrificed in obedience to an oracle: Apollod. III. 15.8; Mikalson, J. D. 1976, 145; Petterson, M. 1992, 35; cf. Hyakinthos</p>
<p :class="{'activeClass': idClicked == NP1771 }" id="NP1771" >GERENOS, wrestler from Naukratis, grave mon. to the right of the road to Eleusis: Philostr. Gym. 54</p>
<p :class="{'activeClass': idClicked == NP1772 }" id="NP1772" >GETA = L. Septimius Geta, statue base, ca. 209-212 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20808" target="_blank">Agora I 808</a> +; IG II² 3416a; D. J. Geagan, “Greek Inscriptions from the Athenian Agora,” Hesperia 52 (1983)168-169 no. 6, pl. 44:6; SEG 33 (1983) no. 180</p>
<p :class="{'activeClass': idClicked == NP1773 }" id="NP1773" >GLAPHYRA, daughter of king Archelaos of Cappadocia, wife of Juba II, king of Mauretania, statue base, ca. 1 A.D., Akr. - - - IG II² 3437/3438; fragment (d) of Eleusinian marble is probably a reused Erechtheion south architrave block, N. Kokkinos, “Re-Assembling the Inscription of Glaphyra from Athens,” ZPE 68 (1987) 288-290, drawing in text, pl. 14:c; SEG 37 (1987) no. 148; Geagan, D. J. 1996, 157/158,161; <JBcom>[the Eleusinian marble architrave blocks may be modern repairs in which case the date of the ancient repair of the Erechtheion is not affected by the date of Glaphyra's statue base]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1774 }" id="NP1774" >GLAUKON, Chremonides' brother, choregic mon., 282/1 B.C., theatre - - - IG II² 3079; SEG 27 (1977) no. 11</p>
<p :class="{'activeClass': idClicked == NP1775 }" id="NP1775" >GLAUKOPION, old name for the Akropolis or for Athena's sanctuary on the Akropolis: schol. E. Hipp. 33; Milchhöfer, A. 1891., 4 s.v.</p>
<p :class="{'activeClass': idClicked == NP1776 }" id="NP1776" >GLYKON, bronze snake, 3rd c. A.C., cistern of a house on Kolonos Agoraios  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20253" target="_blank">Agora B 253</a>; L. Robert, “Le serpent Glycon d'Abonouteuchos a Athenes,” CRAI 1981, 513-516, fig. 3a; SEG 32 (1982) no. 1686</p>
<p :class="{'activeClass': idClicked == NP1777 }" id="NP1777" >GORGON</p>
<p :class="{'activeClass': idClicked == NP1778 }" id="NP1778" >Architectural bronze, acroterion (?), ca. 650 - 625 B.C., Akropolis - - - NM 13050; E. Touloupa, “line Gorgone en bronze de l'Acropole,” BCH 93 (1969) 862-884, figs. 1-6, pl. 20; for the date, LIMC IV, 1988, 310 no. 279, ph., s.v. Gorgo (I. Krauskopf)</p>
<p :class="{'activeClass': idClicked == NP1779 }" id="NP1779" >Archaic relief fragments, <JBcom>[wrongly]</JBcom> assigned to an acroterion of the Mid-6th c. Temple of Athena Acr. 701 +; Schrader, H. 1939, 319-320 no. 441, pl. 184; Danner, P. 1989, 16 no. 100, 90 A8, pl. 1</p>
<p :class="{'activeClass': idClicked == NP1780 }" id="NP1780" >Terracotta antefix, mid-6th c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202296" target="_blank">Agora A 2296</a>; LIMC IV, 1988, 291 no. 31, ph., s.v. Gorgo (I. Krauskopf) See also: Aegis</p>
<p :class="{'activeClass': idClicked == NP1781 }" id="NP1781" >GRACES: see Charites</p>
<p :class="{'activeClass': idClicked == NP1782 }" id="NP1782" >GREAT GODS: see Megaloi Theoi</p>
<p :class="{'activeClass': idClicked == NP1783 }" id="NP1783" >GYMNASIUM BOUNDARY STONE, probably 3rd c. B.C., last seen at the Odeion of Herodes Atticus, present whereabouts unknown: Agora III, no. 463; perhaps the gymnasium boundary stone seen by K. Rhomaios at Veikou and Drakou Sts. (Plan 2 theta—4), J. Threpsiades, «Άνασκαφαι νοτίως τής Άκροπόλεως,» Praktika 1950, 65, last paragraph of note 2</p>
<p :class="{'activeClass': idClicked == NP1784 }" id="NP1784" >GYMNASIUM BOUNDARY STONE, 5th c. C.E., reused columnar grave mon., with</p>
<p :class="{'activeClass': idClicked == NP1785 }" id="NP1785" >Christian cross found at the "Giants" in the Agora - - - IG II² 11030; this stone was <JBcom>[mistakenly]</JBcom> thought to identify the function of a large sprawling 5th c. complex called the "Gymnasium of The Giants" (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM373">373</a>), Thompson, H. A. 1950, 136 note 2; Ritchie Jr. C. E. 1985, 140-144, pl. 26, fig. 22</p>
<p :class="{'activeClass': idClicked == NP1786 }" id="NP1786" >GYMNASIUM SCULPTURE INVENTORY, 169/8-135/4 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> +; tentatively associated with the Ptolemaion, Clay, D. 1977, 259-267, 397, pls. 61-62; SEG 26 (1976-77) no. 139; inscribed by the Cutter of <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206006" target="_blank">Agora I 6006</a>, Tracy, S. V. 1990, 150,159; <JBcom>[perhaps from the Lykeion]</JBcom>; see Artemis Art8*; Asklepios Ask45*-46*; Athena Ath100*; Hermaphroditos; Hermes Hrm14*; Muses; Tragoidia and Komoidia</p>
<p :class="{'activeClass': idClicked == NP1787 }" id="NP1787" >Exedra where the sculpture was displayed: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a>+ lines 45, 50; D. Clay, op. cit, 263</p>
<p :class="{'activeClass': idClicked == NP1788 }" id="NP1788" >HABRYLLIS, daughter of Mikion, priestess of Athena Polias, gravestone with temple key in relief, after 138 B.C., north of the Akr. NM 1727, IG II² 6398; priestess of Athena Polias, Lewis, D. M. 1955, 9 no. 8; Turner, J. A. 1983, 245, 261-265; Aleshire, S. A. 1994, 336 no. 10, 337; for Habryllis as priestess of Demeter and Kore, see Agora XXXI, 208 no. 75 <JBcom>[doubtably]</JBcom> implying that Habryllis could not have been the priestess of Athena Polias</p>
<p :class="{'activeClass': idClicked == NP1789 }" id="NP1789" >HADES, descent to: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM468">468</a></p>
<p :class="{'activeClass': idClicked == NP1790 }" id="NP1790" >HADRIAN = P. Aelius Hadrian = Imp. Caesar Traianus Hadrianus Aug. (PIR, 2nd ed., 184); Willers, D. 1990, 1-107</p>
<p :class="{'activeClass': idClicked == NP1791 }" id="NP1791" >Altar dedicated by Hadrian to himself: Spartianus V. Hadr. 16.6; Benjamin, A. S. 1963, 59 note 19; <JBcom>[doubtably]</JBcom> located in the Olympieion precinct, Tolle-Kastenbein, R. 1994, 160</p>
<p :class="{'activeClass': idClicked == NP1792 }" id="NP1792" >Aqueduct with water sources on the slopes of Mt. Parnes and Mt. Penteli, ending on Lykabettos south slope, completed by Antoninus Pius: Travlos, J. 1971, 242-243, figs. 312-313; Leigh, S. 1998, 1-231; cf. Ge Karpophoros, the inscription attesting a drought</p>
<p :class="{'activeClass': idClicked == NP1793 }" id="NP1793" >Propylon at the so-called reservoir, Lykabettos south slope at Plateia</p>
<p :class="{'activeClass': idClicked == NP1794 }" id="NP1794" >Dexameni (Plan 2 epsilon—7, no. 20) CIL III 549; Stuart, J. and N. Revett, 1794, Chapter IV with pls. 1-4, Vermeule, C. C. 1968, 267-8, 431; the so-called reservoir is a nymphaeum, S. Leigh, “The ‘reservoir' of Hadrian in Athens,”, JRA 10 (1997) 279-290, figs. 1-12; Leigh, S. 1998, 106-146</p>
<p :class="{'activeClass': idClicked == NP1795 }" id="NP1795" >Arch near the northwest corner of the Olympieion precinct, ca. 128 A.D. (Plan 1 eta/theta—10, no.80) IG II² 5185; SEG 48 (1948) no. 238; Willers, D. 1990, 67-69, 71-93, figs. 16-27; pls. 2:3, 7, 19-27; Leigh, S. 1998, 224-227; see City Wall, 1778 A.D., Porta tis Vasilopoulou; cf. SEG 50 (2000) no. 155 line 35</p>
<p :class="{'activeClass': idClicked == NP1796 }" id="NP1796" >Statues of Hadrian and Theseus <JBcom>[imagined]</JBcom> in the upper central niches: <JBcom>[idly]</JBcom> suggested, Travlos, J. 1971, 253; no traces of statues observed by Nicholas Revett, Stuart, J. and N. Revett 1794, 65, addendum to page 23</p>
<p :class="{'activeClass': idClicked == NP1797 }" id="NP1797" >Gymnasium: Paus. 1.18.9; Kokkou, A. 1970, 165-167, fig. 5, pl. 53b; Billot, M.-F. 1992, 134-143; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM537">537</a></p>
<p :class="{'activeClass': idClicked == NP1798 }" id="NP1798" >Library, a peristyle court with rooms (oikemata) for books, bounded by Dexippou, Areos, Pandrosou, Aiolou and Adrianou Sts.( Plan 1 beta/gamma—5/6, no. 73) Paus. 1.18.9; Eusebius, Hieronymi Chronicon, ed. Heim, 1956, 282,16; Travlos, J. 1971, 244-252, 579, figs. 314-324; housing the cadastral archives of the province of Achaia, P. Castren, “General Aspects of Life in Post-Herulian Athens,” in Castren, P., ed. 1994, 2-4,12; [misinterpreted as the “Forum of Hadrian,” A. Karivieri, “The Library of Hadrian and the Tetraconch Church,” in Castren, op. cit., 89-113, figs. 1-4, 10; Tinginanka, I. 2003, 285-326</p>
<p :class="{'activeClass': idClicked == NP1799 }" id="NP1799" >Northeast and southeast chambers, “two small amphitheaters” for lectures and readings, I. Tinginanka, op. cit., 295, fig. 1</p>
<p :class="{'activeClass': idClicked == NP1800 }" id="NP1800" >Res Gestae, inscription recording Hadrian's good deeds, in the sanctuary of All The Gods: Paus. 1.5.5; Benjamin, A. S. 1963, 59 note 19</p>
<p :class="{'activeClass': idClicked == NP1801 }" id="NP1801" >Statue bases: A. S. Benjamin, op. cit., 83; SEG 39 (1989) no. 323</p>
<p :class="{'activeClass': idClicked == NP1802 }" id="NP1802" >Hadrian as Neos Dionysos, statue base, 129/30 A.D.?, 8-10 Tziraion St., west of the Olympieion (Plan 1 theta—9) SEG 47 (1997) no. 222; V. Orphanou, «5. Οδός Τζιραίων 8-10,» Deltion 47 (1992) B1 Chr., 26, Plan A no. 5</p>
<p :class="{'activeClass': idClicked == NP1803 }" id="NP1803" >Statues in the theater - - - IG II² 3286 + CIL III 550, IG II² 3287; Maass, M. 1972, 14, 19 note 53, 85 note 48, 117</p>
<p :class="{'activeClass': idClicked == NP1804 }" id="NP1804" >Statues set up by colony-cities at the Olympieion IG II² 3289-3307+; Paus. 1.18.6; A. S. Benjamin, op. cit., 58-59; SEG 21(1965) no. 704</p>
<p :class="{'activeClass': idClicked == NP1805 }" id="NP1805" >Bilingual dedication to Hadrian by the colonia Augusta Diensium, 132 A.D. - -  - IG II² 3289; Fanoula Papazoglou, Les villes Macedoine a l'epoque romaine (BCH Suppl. 16), Athens, 1988,110; SEG 38 (1988) no. 180</p>
<p :class="{'activeClass': idClicked == NP1806 }" id="NP1806" >Bases with Latin inscriptions probably from the Olympieion - - - CIL III 7281, 7283, 7285; IL in Graecia repertae 1979, nos. 133-135</p>
<p :class="{'activeClass': idClicked == NP1807 }" id="NP1807" >Base with Latin dedication to Hadrian by the Trojans, 132 A.D., reused as a column base in the Library of Hadrian, south peristyle - - - CIL III 7282, G. Knithakis and G. Tinginanka, “Βιβλιοθήκη Αδριανού,» Deltion, B1 Chr., 5, pl. 10:a; SEG 38 (1988) no. 181</p>
<p :class="{'activeClass': idClicked == NP1808 }" id="NP1808" >Bilingual base, dedication from Alexandria Troas, Hadrian's Library: Vermeule, C. C. 1968, 430</p>
<p :class="{'activeClass': idClicked == NP1809 }" id="NP1809" >Statue set up by Herodes Atticus ca. 132 A.D. IG II² 3384; M. Th. Mitsos, «Els IG II² 3384,» Deltion 22 (1967) B1 Chr., 15 no. 5, pl. 11 gamma', SEG 24 (1969) no. 213</p>
<p :class="{'activeClass': idClicked == NP1810 }" id="NP1810" >Statues of Hadrian: Willers, D. 1990, 48-53</p>
<p :class="{'activeClass': idClicked == NP1811 }" id="NP1811" >Statue in the Parthenon: Paus. 1.24.7; <JBcom>[inside the Parthenon Pausanias reports only the Athena Parthenos and the portrait statue of Hadrian in contrast to the many antiquities he saw inside the Erechtheion]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1812 }" id="NP1812" >Cuirassed statues, Akr.: Vermeule, C. C. 1968, 391 no. 2</p>
<p :class="{'activeClass': idClicked == NP1813 }" id="NP1813" >Four statues, two of Thasian and two of Egyptian stone, at the approach to the Olympieion: Paus. 1.18.6</p>
<p :class="{'activeClass': idClicked == NP1814 }" id="NP1814" >Colossal statue behind the temple of Zeus Olympios dedicated by the Athenians: Paus. 1.18.6; cf. fragment of a colossal male statue, A. S. Rousopoulos, «Έπιγραφαι έλληνικαι και δσα άλλα αρχαία εύρέθησαν έν ταΐς περι τό Όλύμπιον άνασκαφαις,» ΑΕ 1862, 50 no. 8; Willers, D. 1990, 46, pl. 6:1-2</p>
<p :class="{'activeClass': idClicked == NP1815 }" id="NP1815" >Portrait bust, Olympieion NM 249; Agora I, 39, pl. 45; Kaltsas, N. 2002, 339 no. 718, ph.</p>
<p :class="{'activeClass': idClicked == NP1816 }" id="NP1816" >Statue as Eponymous Hero, 124/125 A.D.: Paus. 1.5.5; T. L. Shear, Jr., “The Monument of the Eponymous Heroes in the Athenian Agora,” Hesperia 39 (1970)181-186, 201-203, pl. 45; Willers, D. 1990, 68-69</p>
<p :class="{'activeClass': idClicked == NP1817 }" id="NP1817" >Statue with cuirass reliefs of Athena standing on the wolf suckling Romulus and Remus, found as a cover slab of the Great Drain near its original position in front of the Stoa of Zeus <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20166" target="_blank">Agora S 166</a>; Paus. 1.3.2; T. L. Shear, “The Sculpture,”, Hesperia 2 (1933) 178-183, figs. 8-10, pl. VI; Agora I, 71-74, pls. 36-37; Agora Guide 1990, 66-67, 274 no. 66, fig. 31</p>
<p :class="{'activeClass': idClicked == NP1818 }" id="NP1818" >Portrait head from a cuirassed statue, Thasian marble, found in 1885 in the Agora Excavation area NM 632; Datsouli-Stavridi, A. 1974, 188-189 no. 2, 282, fig. 111</p>
<p :class="{'activeClass': idClicked == NP1819 }" id="NP1819" >Head of a colossal statue in armour, Syngrou Blvd. NM 3729; P. Lemerle, “Chronique des fouilles et decouvertes archeologiques dans l'Orient hellenique en 1934,” BCH 59 (1935) 238; Kaltsas, N. 2002, 340 no. 720, ph. See also: Demos and Charites, IG II² 4775; Neai Athenai; Roman Agora, Hadrian's Oil Law</p>
<p :class="{'activeClass': idClicked == NP1820 }" id="NP1820" >HADRIAN ELEUTHERAIOS, priest's theater throne — IG II² 5035; Maass, M. 1972, 116-117, pl. 9</p>
<p :class="{'activeClass': idClicked == NP1821 }" id="NP1821" >HADRIAN OLYMPIOS</p>
<p :class="{'activeClass': idClicked == NP1822 }" id="NP1822" >Ninety-four altars and votive plaques: Benjamin, A. S. 1963, 57-72, pls. 22-29; SEG 21 (1965) nos. 705-730, 732-733; <JBcom>[of these 94 the findspots of 7 are not known, 86 were found north of the Akropolis, 1 was found south of the Akropolis, all of them were dedicated in the Panhellenion, not set up along the Panathenaic Way as suggested by D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 77]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1823 }" id="NP1823" >Altar dedicated by the people of Cyzicus, Akropolis lower north slope, 11 Erechtheos St. between Prytaneiou and Erotokritou Sts. (Plan 1 epsilon—7) — First Ephoria inv. no. Pi 225; stored at 20 Thrasyllou St.; A. Choremi, «Οδός Ερεχθέως 11 - Ερωτοκρίτου και Πρυτανείου,» Deltion 44 (1989) B1 Chr., 19-20</p>
<p :class="{'activeClass': idClicked == NP1824 }" id="NP1824" >Altar perhaps seen by Cyriacus of Ancona - - - IG II² 3361; SEG 21 (1965) nos. 732-733 See also: Augustus, altars; Homonia, altar</p>
<p :class="{'activeClass': idClicked == NP1825 }" id="NP1825" >Hadrian Olympios and, altar, ca. 132 A.D., Dipylon Gate IG II² 3331; SEG 21 (1965) no. 732</p>
<p :class="{'activeClass': idClicked == NP1826 }" id="NP1826" >Base of a statue dedicated to Hadrian Olympios, perhaps as Neos Dionysos, by the town of Philadelphia in Lydia IG II² 3966a +; H. Karapa-Molisani, “A</p>
<p :class="{'activeClass': idClicked == NP1827 }" id="NP1827" >Dedication to the Emperor Hadrian,” in Praktika 8th Congress 1984, vol. 2, 308-311, pl. 30; SEG 42 (1992) no. 172</p>
<p :class="{'activeClass': idClicked == NP1828 }" id="NP1828" >Base of a bronze statue dedicated by Byzantion?, ca. 9 m. north of the northwest corner of the Odeion - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204188" target="_blank">Agora I 4188</a>; T. L. Shear, “The Campaign of 1936,”, Hesperia 6 (1937) 352-354, figs. 16-17; <JBcom>[mistakenly]</JBcom> assigned to Zeus Olympios, Thompson, H. A. 1950, 82 note 2</p>
<p :class="{'activeClass': idClicked == NP1829 }" id="NP1829" >Corrigendum: most of the statue bases listed under Hadrian without an epithet are also dedications to Hadrian Olympios</p>
<p :class="{'activeClass': idClicked == NP1830 }" id="NP1830" >HADRIAN PANHELLENIOS, cult: OGI no. 507 LL. 1-2; Oliver, J. H. 1970, 115-116 no. 30; see Panhellenion</p>
<p :class="{'activeClass': idClicked == NP1831 }" id="NP1831" >HADRIANIC (?) BASILICAS: see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM207">207</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM274">274</a></p>
<p :class="{'activeClass': idClicked == NP1832 }" id="NP1832" >HADRIANOPOLIS, name for part of Athens: Spartianus, V. Hadr. 20.4; Zahrnt, M. 1979, 393-398; Leigh, S. 1998, 224-227</p>
<p :class="{'activeClass': idClicked == NP1833 }" id="NP1833" >“HALADE” GATE, modern name for the ancient gate through which the Mystai go on their way to the sea (halade ) mentioned in IG I³ 84 line 35; see Mystai Gate; cf. Themistoklean City Wall Wal25*, Wal28*</p>
<p :class="{'activeClass': idClicked == NP1834 }" id="NP1834" >HALON, hero cult: Vita Soph.11; Halon emended to read Amynos, Aleshire, S. B. 1989, 9 note 2, 28</p>
<p :class="{'activeClass': idClicked == NP1835 }" id="NP1835" >HARMODIOS and ARISTOGEITON, the Tyrant-slayers; Agora III, 93-98; Michael W. Taylor, The Tyrant Slayers, 2nd ed., Salem, New Hampshire, 1991; SEG 42 (1992) no. 33</p>
<p :class="{'activeClass': idClicked == NP1836 }" id="NP1836" >Grave on the Road to the Academy: Paus. 1.29.15; state burial monument, site of their hero cult, Michael W. Taylor, op. cit., 5-12,92 note 1, 96; cf. Heroes Hro9*; <JBcom>[probably a cenotaph, perhaps established by Perikles]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1837 }" id="NP1837" >Statue group by Antenor, between the temple of Ares and the Odeion of Agrippa: Paus. 1.8.5, cf. 8.46.4; S. Brunnsaker, The Tyrant-slayers of Kritios and Nesiotes, Stockholm, 1971, 39-40 (testimonia),40-45, 90-98, 110-112, 115-6, 121, 125 note 2, 131, 132, 135; the group removed by Xerxes was returned to Athens by Alexander the Great according to Arrian and Pliny, by Seleukos according to Valerius Maximus, by Antiochos <JBcom>[IV?]</JBcom> according to Pausanias {Agora III, nos. 260,270, 272,277); Habicht, Chr. 1989, 7-8</p>
<p :class="{'activeClass': idClicked == NP1838 }" id="NP1838" >Statue group by Kritios and Nesiotes, 477/6 B.C.: Marmor Parium 70; Paus. 1.8.5; (as in the case of Epicharinos Pausanias names Kritias as the sole sculptor)</p>
<p :class="{'activeClass': idClicked == NP1839 }" id="NP1839" >Plaster cast fragments found in Baiae: Christa Landwehr, Die antiken Gipsabgusse aus Baiae, Berlin, 1985, 27-47, fig. 3, pls. 2-11</p>
<p :class="{'activeClass': idClicked == NP1840 }" id="NP1840" >Representations on Athenian silver tetradrachms: S. Brunnsaker, op. cit 100-101 no. 2, pl. 23; Herzog, H. 1996, 81-84</p>
<p :class="{'activeClass': idClicked == NP1841 }" id="NP1841" >Fragment of base with epigram, 477/6 B.C., between the temple of Ares and the Odeion of Agrippa - - - IG I³ 502; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203872" target="_blank">Agora I 3872</a>; Agora XIV, 155-158, pl. 79:a; Gadbery, L. M. 1988, 89-124; SEG 50 (2000) no. 72</p>
<p :class="{'activeClass': idClicked == NP1842 }" id="NP1842" >Broomhall throne with relief of the Tyrant-slayers and relief of a Greek, doubtless Theseus, vanquishing an Amazon, early 3rd c. B.C. - - - Getty Museum no. 74.AA.12; IG II² 5167; SEG 32 (1982) no.273 </p>
<p :class="{'activeClass': idClicked == NP1843 }" id="NP1843" >HARPOKRATES: see Isis; Sarapis; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM192">192</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM521">521</a> </p>
<p :class="{'activeClass': idClicked == NP1844 }" id="NP1844" >HEBE</p>
<p :class="{'activeClass': idClicked == NP1845 }" id="NP1845" >Altar in the sanct. of Herakles called Kynosarges: Paus. 1.19.3</p>
<p :class="{'activeClass': idClicked == NP1846 }" id="NP1846" >Theater seat IG II² 5150; of the two theater seats for Hebe one is from her cult in Kynosarges, the other from a cult in the countryside</p>
<p :class="{'activeClass': idClicked == NP1847 }" id="NP1847" >Theater seat IG II² 5154 cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM334">334</a></p>
<p :class="{'activeClass': idClicked == NP1848 }" id="NP1848" >HECTOR'S RANSOM, sarcophagus relief frag., Akropolis South Slope: L. von Sybel, “Έκτορος λύτρα, Relieffragment zu Athen,” A/W 9 (1884) 54-57</p>
<p :class="{'activeClass': idClicked == NP1849 }" id="NP1849" >HEDYLOS, son of Straton, bronze portrait of the kosmetes Hedylos set up by ephebes in the Agora, decreed 79/78 B.C.: IG II² 1039 lines 38-39</p>
<p :class="{'activeClass': idClicked == NP1850 }" id="NP1850" >HEGEMON ARCHEGETES, banqueting hero relief, 3rd c. B.C., near the Odeion of Herodes Atticus - - - IG II² 4686; SEG 25 (1971) no. 222; Dentzer, J.-M. 1982, 591 no. R 203, fig. 461</p>
<p :class="{'activeClass': idClicked == NP1851 }" id="NP1851" >HEGEMONE: see Aphrodite Aph29*</p>
<p :class="{'activeClass': idClicked == NP1852 }" id="NP1852" >HEGEMONIOS: see Hermes Hrm22*</p>
<p :class="{'activeClass': idClicked == NP1853 }" id="NP1853" >HEKADEMEIA, HEKADEMOS: see Akademeia, Akademos</p>
<p :class="{'activeClass': idClicked == NP1854 }" id="NP1854" >HEKATAIA, HEKATE</p>
<p :class="{'activeClass': idClicked == NP1855 }" id="NP1855" >Finds from the Agora Excavations: Agora XI, 86-107 nos. 134-155, pls. 32-39</p>
<p :class="{'activeClass': idClicked == NP1856 }" id="NP1856" >Largest Hekataion known from Athens, Augustan pd. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201277" target="_blank">Agora S 1277</a>; Agora XI, no. 134, pl. 32</p>
<p :class="{'activeClass': idClicked == NP1857 }" id="NP1857" >Hekataion with two dogs and altar, poros limestone, 2nd-3rd c. A.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201943" target="_blank">Agora S 1943</a>; Agora XI, no. 155, pl. 39</p>
<p :class="{'activeClass': idClicked == NP1858 }" id="NP1858" >Hekate herm with Charites, 2nd c. B.C. (?)--- <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20862" target="_blank">Agora S 862</a>; Agora XI, no. 152, pl. 38</p>
<p :class="{'activeClass': idClicked == NP1859 }" id="NP1859" >Finds from the Kerameikos Excavations:</p>
<p :class="{'activeClass': idClicked == NP1860 }" id="NP1860" >Hekataion, late Hellenistic, south of the Sacred Gate - - - Kerameikos P 1441; E. Simon, “Hekate in Athen,” AM 100 (1985) 272, 280-281 pl. 54</p>
<p :class="{'activeClass': idClicked == NP1861 }" id="NP1861" >Hekataion base; Brueckner, A. 1909, 45, figs. 19-20; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM408">408</a></p>
<p :class="{'activeClass': idClicked == NP1862 }" id="NP1862" >Hekate herm surrounded by three dancers, Charites<JBcom>[?]</JBcom>, and a dog, 1st c. B.C., north of Ermou St. not far from the Sacred Gate - - - Kerameikos P 1488; E. Simon, op. cit. 281-282, pl. 55</p>
<p :class="{'activeClass': idClicked == NP1863 }" id="NP1863" >Hekataia in various places:</p>
<p :class="{'activeClass': idClicked == NP1864 }" id="NP1864" >Hekataion, archaizing, 5 Aristeidou St. in northeast Athens (Plan 1 delta—5): H. W. Catling, “Archaeology in Greece 1984-85,” in AR for 1984-85, 8</p>
<p :class="{'activeClass': idClicked == NP1865 }" id="NP1865" >Hekate Epipyrgidia, statue beside the Athena Nike temple: Paus. 2.30.2; Agora XI, Index s.v. Alkamenes; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM89">89</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM90">90</a></p>
<p :class="{'activeClass': idClicked == NP1866 }" id="NP1866" >Hekate Triglanthine, statue at a place called Trigla; Ath. VII.325 D; FAC II, 1959, 370, 634 See also: Asklepios Ask25*, Kalliste; Pan Pan19*-20*; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM90">90</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM315">315</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM408">408</a> </p>
<p :class="{'activeClass': idClicked == NP1867 }" id="NP1867" >HEKATOMBAIOS: see Apollo Apo37*</p>
<p :class="{'activeClass': idClicked == NP1868 }" id="NP1868" >HEKATOMPEDON, temple on the Acropolis named in the Law concerning the Acropolis (the so-called Hekatompedon Inscription): IG I³ 4 B lines l0-11, 18; SEG 48 (1998) no. 43; the name Hekatompedon variously applied to four temples on the Akropolis, Dinsmoor, W. B. 1947,118-124; for the terms Hekatompedon and Hekatompedos Neos, Harris, D. 1995, 2, 4-5, 17-18 and Index s.v. Hekatompedon. <JBcom>[n.b. not all scholars think that the term Hekatompedon refers to a temple; the writer thinks the Hekatompedon inscription refers to the Old Temple of Athena (Athena Ath20*) as the Hekatompedon]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1869 }" id="NP1869" >HELEN, picture by Zeuxis in the Stoa Alphitopolis: Eust. Iliad XI.630; Geschwantler, K. 1978, col. 1484 lines 33-43</p>
<p :class="{'activeClass': idClicked == NP1870 }" id="NP1870" >HELIAIA, lawcourt for trials heard by very large juries: Paus.1.28.8; Agora III, 145-146, 147-148, 201 no. 670, 202 no. 672; Μ. H. Hansen, “The Athenian Heliaia from Solon to Aristotle,” Cl et Med 33 (1981-82) 9-10, 115-127; Agora XXVIII, Index s.v. Heliaia; <JBcom>[mistaken]</JBcom> conjectural location north of the Eleusinion, Travlos, J. 1971, fig. 5 no. 19, fig. 28 (not labelled), fig. 219 (not labelled); cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM345">345</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM346">346</a></p>
<p :class="{'activeClass': idClicked == NP1871 }" id="NP1871" >HELIKON, place on the Ilissos left bank later called Agra: F GR HIST III B, 51-2, Kleidemos 323 F 1 with suppl. vol. 2, 62-63</p>
<p :class="{'activeClass': idClicked == NP1872 }" id="NP1872" >HELIKONIOS: see Poseidon Helikonios</p>
<p :class="{'activeClass': idClicked == NP1873 }" id="NP1873" >HELIODOROS Halis (Halaieus?), picture in the great temple of Athena <JBcom>[the Parthenon]</JBcom> and grave monument on the Sacred Way: Paus. 1.37.1; PAA, vol. 8, 1999, 186 no. 483200; <JBcom>[identified as the Epicurean philosopher close to Hadrian]</JBcom>; cf. SEG 3 (1929) no. 226 and 43 (1993) no. 24; cf. A. R. Birley, Hadrian, London and New York, 1997, 375 s.v. Avidius Heliodorus, C.</p>
<p :class="{'activeClass': idClicked == NP1874 }" id="NP1874" >HELIOS: O. Jessen, RE VIII, 1913, col. 65-66 (Helios); P. Matern, Helios und Sol: Kulte und Ikonographie des griechischen und romischen Sonnengottes, Istanbul, 2002, 13-14</p>
<p :class="{'activeClass': idClicked == NP1875 }" id="NP1875" >Head, 2nd c. A.C., Areopagus north slope <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202356" target="_blank">Agora S 2356</a>; perhaps from a</p>
<p :class="{'activeClass': idClicked == NP1876 }" id="NP1876" >Mithraeum, Agora XXIV, 37 note 145, 41 note 161; called Alexander the Great, Shear Jr., T. L 1971, 273-274, pl. 58:b</p>
<p :class="{'activeClass': idClicked == NP1877 }" id="NP1877" >Priestess' theater seat - - - IG II² 5093; Wurcter, W. W. 1979, 65, fig. 64</p>
<p :class="{'activeClass': idClicked == NP1878 }" id="NP1878" >Helios and Dionysos, relief arula, 1st c. B.C., Akr. West Slope - - - IG II² 4993</p>
<p :class="{'activeClass': idClicked == NP1879 }" id="NP1879" >Helios and The Horai, cult: Porph. Abst. 2.7; L. Deubner, Attische Feste, Berlin 1932, 190-192, 201; Helios and the Horai are listed in lines 12-13 of Acr. 13371, the honorary decree for the priestess of Aglauros, Dontas, G. S. 1983, 52</p>
<p :class="{'activeClass': idClicked == NP1880 }" id="NP1880" >Helios and Men, double relief with two inscr. Boston MFA 1972.78; Lane, E. 1971/1978, I, 1971,1-2 no. 2, PI. II; II, 1975, 151; 111,1,2, 3,13, 92,101</p>
<p :class="{'activeClass': idClicked == NP1881 }" id="NP1881" >Helios and Zeus Meilichios, dedication, north of the Hill of the Nymphs IG II² 4678; A. Koumanoudes, «Έπιγραφαι 'Arrncrjs ανέκδοτοι,» Athenaion 8 (1879) 288; P. Matern, loc. cit. See also: Mithras</p>
<p :class="{'activeClass': idClicked == NP1882 }" id="NP1882" >HELLESPONT BRIDGE CABLES, dedicated in the sanctuaries: Hdt. 9.121; Gauer, G.1968, 37, 73; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM86">86</a></p>
<p :class="{'activeClass': idClicked == NP1883 }" id="NP1883" >HEMEROS: see Ge Hemeros</p>
<p :class="{'activeClass': idClicked == NP1884 }" id="NP1884" >HEPHAISTEIA: see Hephaistos at Kolonos Agoraios, dedication to Hephaistos and Athena Hephaisteia</p>
<p :class="{'activeClass': idClicked == NP1885 }" id="NP1885" >HEPHAISTOS: Brommer, F. 1978, 157-158</p>
<p :class="{'activeClass': idClicked == NP1886 }" id="NP1886" >Hephaistos on the Akropolis</p>
<p :class="{'activeClass': idClicked == NP1887 }" id="NP1887" >Altar in the Erechtheion: Paus. 1.26.5; conjecturally <JBcom>[rightly]</JBcom> located in the eastern chamber <JBcom>[but the cylindrical form is highly improbable]</JBcom>, Travlos, J. 1971, 213, fig. 281 D</p>
<p :class="{'activeClass': idClicked == NP1888 }" id="NP1888" >Priest's throne: see Boutes and Hephaistos</p>
<p :class="{'activeClass': idClicked == NP1889 }" id="NP1889" >Hephaistos at the Academy: see Prometheus and Hephaistos; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM471">471</a></p>
<p :class="{'activeClass': idClicked == NP1890 }" id="NP1890" >Hephaistos at Kolonos Agoraios: Agora III, 1957, 98-102</p>
<p :class="{'activeClass': idClicked == NP1891 }" id="NP1891" >Temple of Hephaistos: Paus. 1.14.6; Agora Guide 1990, 39-45, 273 no. 39, with front and back covers and figs. 4-9, 12-19, 37; for a tentative chronology of the construction, see W. F. Wyatt, Jr. and C. N. Edmonson, “The Ceiling of the Hephaisteion,” AJA 88 (1984) 167; see Chr. Mon. CM10*</p>
<p :class="{'activeClass': idClicked == NP1892 }" id="NP1892" >300+ masons' marks on ceiling coffers in two series, ca. 460-450 B.C. (?) and 421-415 B.C. - - - SEG 34 (1984) no. 44; W. F. Wyatt, Jr. and C. N. Edmonson, op. cit, 135-167, ills. 1-24, pl. 28</p>
<p :class="{'activeClass': idClicked == NP1893 }" id="NP1893" >Temple statues of Hephaistos and Athena, 421/0-416/5 B.C.: IG I³ 472 = IG I² 370/1; SEG 44 (1994) no. 12; Paus. 1.14.6; Pollitt, J. J. 1990, 66, 251-252; Brommer, F. 1978, 75-90, 238, 243; UMC IV, 1988) 634 no. 67, 651 (A. Jacquemin); LIMC II, 1984, 979 no. 241 (restored drawing), 980 nos. 247, 251, s.v. Athena (P. Demargne); photo of part of the expense accounts, Agora XIV, pl. 71 :c</p>
<p :class="{'activeClass': idClicked == NP1894 }" id="NP1894" >Base of the temple statues with separately attached reliefs, not extant; Dinsmoor, W. B. 1941, 105-110, figs. 38-40; LIMC I 1981, 287-288 no. 10, s.v. Aglauros, Herse, Pandrosos (U. Kron); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM367">367</a></p>
<p :class="{'activeClass': idClicked == NP1895 }" id="NP1895" >Altar of Hephaistos: IG I³ 82 line 36</p>
<p :class="{'activeClass': idClicked == NP1896 }" id="NP1896" >Decree concerning the cult of Hephaistos and Athena Hephaisteia, 421/0 B.C. - - - IG l382 = IG l284; SEG 48 (1998) no. 52</p>
<p :class="{'activeClass': idClicked == NP1897 }" id="NP1897" >Cult table for Hephaistos and Athena: IG I³ 472 line 152; SEG 44 (1994) no. 12; Gill, D. 1991, 8</p>
<p :class="{'activeClass': idClicked == NP1898 }" id="NP1898" >Precinct wall: D. B. Thompson, “The Garden of Hephaistos,” Hesperia 6 (1937) 396-400, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP1899 }" id="NP1899" >Temple garden with rows of flowerpots - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%206978" target="_blank">Agora P 6978</a> and others; D. B. Thompson, op. cit, 396-425, figs. 1-10, 12-18</p>
<p :class="{'activeClass': idClicked == NP1900 }" id="NP1900" >Base for a statue of Hephaistos (?), dedicated to Hephaistos and Athena Hephaisteia, 343/342 B.C., lower Akropolis north slope at the site of H. Dimitrios Katiphori (Chr. Mon. CM59*), Plan 1 delta—7)- - - IG II² 223 lines 1-2 on Face A and lines 3-4 on face B; Brommer, F. 1978, 94-95, 238, fig. 40, pl. 41</p>
<p :class="{'activeClass': idClicked == NP1901 }" id="NP1901" >Architectural relief frag, with Hephaistos, Roman pd., east of the Post-Herulian Wall - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202180" target="_blank">Agora S 2180</a>; Harrison, E. B. 1977,148-149, fig. 6</p>
<p :class="{'activeClass': idClicked == NP1902 }" id="NP1902" >Terracotta sima frag, with dipinto dedication to Hephaistos, late Hellenistic - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20891" target="_blank">Agora A 891</a>; Shear, T. L. 1939, 214-215, fig. 12; SEG 32 (1982) no. 336</p>
<p :class="{'activeClass': idClicked == NP1903 }" id="NP1903" >Priest's theater throne IG II² 5069; Brommer, F. 1978, 109, 251, pl. 40.2</p>
<p :class="{'activeClass': idClicked == NP1904 }" id="NP1904" >HEPTACHALKON at the Themistoklean City Wall between the Peiraic and Sacred Gates: Plu. Sull. 14.1, Moralia 505 B</p>
<p :class="{'activeClass': idClicked == NP1905 }" id="NP1905" >HERA</p>
<p :class="{'activeClass': idClicked == NP1906 }" id="NP1906" >Temple burnt by Mardonios on the Road to Phaleron: Paus. 1.1.5, 10.35.2</p>
<p :class="{'activeClass': idClicked == NP1907 }" id="NP1907" >Earlier temple statue implied by Pausanias: 1.1.5</p>
<p :class="{'activeClass': idClicked == NP1908 }" id="NP1908" >Temple statue by Alkamenes: Paus. 1.1.5; Frazer, J. G. 1913, II.37</p>
<p :class="{'activeClass': idClicked == NP1909 }" id="NP1909" >Archestrate's dedication to Hera on herm shaft, last seen in the storeroom in the Roman Agora: Peek, W. 1942, 51-52 no. 79 See also: Athena Ath121*, Ath129*</p>
<p :class="{'activeClass': idClicked == NP1910 }" id="NP1910" >HERA BOULAIA, dedication, Athens or Attica IG II² 4675</p>
<p :class="{'activeClass': idClicked == NP1911 }" id="NP1911" >HERA ELLIMENIA, theater seat - - - IG II² 5148 </p>
<p :class="{'activeClass': idClicked == NP1912 }" id="NP1912" >HERA LEIRIOESSA: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM161">161</a></p>
<p :class="{'activeClass': idClicked == NP1913 }" id="NP1913" >HERA THELXINIA: Hsch. s.v. θελξινία; SEG 50 (2000) nos. 19, 1071 sub(2)</p>
<p :class="{'activeClass': idClicked == NP1914 }" id="NP1914" >HERA AND ZEUS: see Athena Ath121*; Panhellenion; Zeus Zeu111*</p>
<p :class="{'activeClass': idClicked == NP1915 }" id="NP1915" >HERAIOS: see Zeus Zeu31*</p>
<p :class="{'activeClass': idClicked == NP1916 }" id="NP1916" >HERAKLEIDAI</p>
<p :class="{'activeClass': idClicked == NP1917 }" id="NP1917" >Sanctuary in Kydathenaion?: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205212" target="_blank">Agora I 5212</a> lines 3-4 on Face A and lines 4-5 on Face B; Agora XVI, no. 68,104-105, pl. 8</p>
<p :class="{'activeClass': idClicked == NP1918 }" id="NP1918" >Picture of the Herakleidai supplicating the Athenians, by Pamphilos or Apollodoros: schol. Ar.Pl. 385; Agora III, no. 58; LIMC IV, 1988, 725 no. 1, s.v. Herakleidai (M, Schmidt)</p>
<p :class="{'activeClass': idClicked == NP1919 }" id="NP1919" >HERAKLES</p>
<p :class="{'activeClass': idClicked == NP1920 }" id="NP1920" >1* Altar dedicated by Theseus after Herakles rescued him: Ael. VH IV.5</p>
<p :class="{'activeClass': idClicked == NP1921 }" id="NP1921" >2* Altar, 3rd-2nd c. B.C., built into the foundations of the church in the Asklepieion - - - IG II² 4986; SEG 25 (1971) no. 234</p>
<p :class="{'activeClass': idClicked == NP1922 }" id="NP1922" >3* Altar at the Academy: Paus. 1.30.2; Kron, U. 1979, 57 note 41; Billot, M.-F. 1989, 740-741; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM454">454</a></p>
<p :class="{'activeClass': idClicked == NP1923 }" id="NP1923" >4* Altar set up by a group of the Praxiergidai and a second group, ca. 350 B.C., south of the altar of the Twelve Gods - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201052" target="_blank">Agora I 1052</a>; SEG 51 (2001) no. 224; Μ. H. Jameson, “An Altar for Herakles,” in Polis and Politics: Studies in Ancient Greek History Presented to Mogens Herman Hansen on his Sixtieth Birthday, August 20, 2000, edd. P. Flensted-Jensen et. al., University of Copenhagen, 2000, 217-227, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP1924 }" id="NP1924" >5* Cult table owned by the priest Simon of Kydathenaion and members of a thiasos, early 4th c. B.C., Kydathenaion IG II² 2343; SEG 42 (1992) no. 143; Gill, D. 1991, 42-43 no. 12, fig. 20, pl. 4</p>
<p :class="{'activeClass': idClicked == NP1925 }" id="NP1925" >6* Dedication, Akropolis South Slope - - - IG II² 4571</p>
<p :class="{'activeClass': idClicked == NP1926 }" id="NP1926" >7* Dedication, church of Haghia Triada in the Kerameikos Exc. (Chr. Mon. CM50*) - - - IG II² 4612; SEG 39 (1989) no. 236</p>
<p :class="{'activeClass': idClicked == NP1927 }" id="NP1927" >Sanctuaries: for <JBcom>[imaginary]</JBcom> sanctuaries of Herakles in the Academy area and on the Ilissos left bank, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM454">454</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM535">535</a></p>
<p :class="{'activeClass': idClicked == NP1928 }" id="NP1928" >8* Sanctuaries of Theseus given to Herakles by Theseus (Herakleia): Plu. Thes. 35.2; F GR HIST III B, suppl. vol. I, 307-309</p>
<p :class="{'activeClass': idClicked == NP1929 }" id="NP1929" >Sanctuary boundary stones: Ritchie Jr., C. E., 1985, 145-154, 731-743, pls. 27-28, figs. 23-24 and 731-743</p>
<p :class="{'activeClass': idClicked == NP1930 }" id="NP1930" >9* Sanctuary boundary stone, reused marble rooftile, 5th/4th c. B.C., 5 Hag. Philippou St. (Plan 1 alpha -3)- - - IG I³ 1059; 3rd Ephor. M 1129; SEG 35 (1985) no. 137; Stavropoulos, Ph. D. 1965, “4. 'Αγίου Φιλίππου άριθ. 5,» 54 with fig. 7 and pl. 41 :beta; Travlos, J. 1971, 274, fig. 219 no. 242, fig. 355; for an <JBcom>[imaginary]</JBcom> site dubbed “Shrine of Herakles” at or near Ermou St., well north of the boundary stone findspot, see Travlos, J. 1971, fig. 18 no. 242 and Travlos, J. 1988, fig. 36</p>
<p :class="{'activeClass': idClicked == NP1931 }" id="NP1931" >10* Sanctuary boundary stone, ca. 450 B.C., modern house wall over the western end of the Middle Stoa - - - IG I³ 1058; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20298" target="_blank">Agora I 298</a>; SEG 17 (1960) no. 13; tentatively <JBcom>[and plausibly]</JBcom> assigned to the sanct. of Herakles Alexikakos, Meritt, B. D. 1934, 64-65 no. 56, ph.; conjecturally <JBcom>[implausibly]</JBcom> assigned to the sanctuary of Herakles at 5 Hag. Philippou St. together with Herakles Hrk9*, Travlos, J. 1971, 274; Agora XIX, 23 H6; see Herakles Hrk42*</p>
<p :class="{'activeClass': idClicked == NP1932 }" id="NP1932" >10** Boundary stone of a sanctuary of Her<JBcom>[akles]</JBcom> and another hero or god <JBcom>[name missing]</JBcom> belonging to the Thymaitian phratry, Areopagus north slope - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204468" target="_blank">Agora I 4468</a>; C. W. Hedrick Jr., “The Thymaitian Phratry,” Hesperia 57 (1988) 81-82; SEG 38 (1988) no. 172; Lambert, S. D. 1993, 330-331 T 14</p>
<p :class="{'activeClass': idClicked == NP1933 }" id="NP1933" >11* Temples and altars of Herakles established by the city of Athens after Herakles died: Aristid. Panath. 174 D; J. H. Oliver, The Civilizing Power, Philadelphia, 1968, 106 no. 48 (commentary),157 (text)</p>
<p :class="{'activeClass': idClicked == NP1934 }" id="NP1934" >12* Herakles fighting a centaur, the earliest signed mosaic, early 3rd c. B.C., found near Hadrian's Arch: M Robertson, “Greek Mosaics: A Postscript,”, JHS 87 (1967) 134-5 no. 3, pl. 23 no. 1-4; Salzmann, D. 1982, 86-87 no. 20 See also: Marathonmachia, picture in the Stoa Poikile</p>
<p :class="{'activeClass': idClicked == NP1935 }" id="NP1935" >HERAKLES (continued), ARCHITECTURAL SCULPTURE:</p>
<p :class="{'activeClass': idClicked == NP1936 }" id="NP1936" >13* Herakles and the Hydra, archaic pediment relief, poros limestone, ca. 560-550 B.C. found in 1882 in a terrace filling in the southeast corner of the Akropolis, north of the museum, - - - Acr. 1; for the findspot, see P. J. Meier, “Ueber das archaische Giebelrelief von der Akropolis,”, AM 10 (1885) 237 note 1; Heberdey, R. 1919, 4, 10-12, figs. 3-4</p>
<p :class="{'activeClass': idClicked == NP1937 }" id="NP1937" >14* Herakles and Triton</p>
<p :class="{'activeClass': idClicked == NP1938 }" id="NP1938" >Archaic pediment, poros limestone, found southeast and east of the Parthenon - - - Acr. 2; Heberdey, R. 1919, 4, 13-15, figs. 3, 5-6, pl. 5 See also: Athena Ath19*, Pediment Two</p>
<p :class="{'activeClass': idClicked == NP1939 }" id="NP1939" >15* Herakles1 Introduction to Olympos, archaic pediment, poros limestone, fragments found east and south of the Parthenon Acr. 9; R. Heberdey, 1919, 4-5, 29-46, figs. 3,14-19, pl.1; Bancroft, S. 1980, 72-74</p>
<p :class="{'activeClass': idClicked == NP1940 }" id="NP1940" >HERAKLES (continued), SCULPTURE IN THE ROUND</p>
<p :class="{'activeClass': idClicked == NP1941 }" id="NP1941" >16* Xoanon "said to have been chopped up by the atheist Diagoras to cook his turnips": Athenagoras leg. pro Christ. 4,1; LIMC IV, 1988, 738 no. 85, s.v. Herakles (J. Boardman)</p>
<p :class="{'activeClass': idClicked == NP1942 }" id="NP1942" >16** Herakles fighting Kyknos, Akr.: Paus. 1.27.2</p>
<p :class="{'activeClass': idClicked == NP1943 }" id="NP1943" >17* Herakles strangling snakes, Akr.: Paus. 1.24.2</p>
<p :class="{'activeClass': idClicked == NP1944 }" id="NP1944" >18* Statue in the Ares precinct: Paus. 1.8.4</p>
<p :class="{'activeClass': idClicked == NP1945 }" id="NP1945" >19*-20* Herakles holding the apples of the Hesperides: C. C. Vermeule, “The Weary Herakles of Lysippos,” AJA 79 (1975) 323-332</p>
<p :class="{'activeClass': idClicked == NP1946 }" id="NP1946" >19* Frag, of an over-lifesize hand with three apples, Akropolis west slope: Watzinger, C. 1901, 314 no. 4</p>
<p :class="{'activeClass': idClicked == NP1947 }" id="NP1947" >20* Statuette, 200-250 A.D.?, northwest foot of the Areopagus <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201241" target="_blank">Agora S 1241</a>; Thompson, H. A. 1948, 180, pl. 59 no. 2</p>
<p :class="{'activeClass': idClicked == NP1948 }" id="NP1948" >21* Herakles and the Nemean Lion, rock crystal statuette, Roman pd., Akr. west slope: F. Brommer, “Antiken des Athener Instituts.”, AM 87 (1972) 289-91, pl. 100</p>
<p :class="{'activeClass': idClicked == NP1949 }" id="NP1949" >22* Statuette, Areopagus-Pnyx Vallex south of the Bakcheion in the area of the Roman houses: C. Watzinger, “Herakles ΜΗΝΤΤΗΣ,” AM 29 (1904) 237-243 with 2 photographs; see also Herakles Hrk47*</p>
<p :class="{'activeClass': idClicked == NP1950 }" id="NP1950" >23* Statue, lower Akropolis southeast slope, found in General Makryianni's house, opposite the Military Hospital at Makryianni (now the Center for Akropolis Studies, Plan 2 eta—5) Acr. 5906; Chr. I. Karouzos, «Άπό τό Ηράκλειον τού Κυνοσάργους,» Deltion 8 (1923) 95-96, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1951 }" id="NP1951" >24* Head of an over-lifesize statue, in cistern B in the Ilissos river bed at Kallirrhoe (Plan 2 eta—6): Skias, A. N. 1894, 135; <JBcom>[found at the site where Herakles was initiated into the Lesser Mysteries, cf. no. 38* below]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1952 }" id="NP1952" >25* Fragments of a colossal club, now south of the Nike Bastion - - - inv. no.?; perhaps the one seen in 1929, Papagiannopoulos-Palaios, A. A. 1939, 74-5; Billot, M.-F. 1992, 151 section 3</p>
<p :class="{'activeClass': idClicked == NP1954 }" id="NP1954" >26* Statue, 2nd c. A.C., Late Roman Mansion on the Areopagus north slope (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM291">291</a>) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202438" target="_blank">Agora S 2438</a>; Shear Jr., T. L. 1973a, 161, 172-173 and pl. 38b</p>
<p :class="{'activeClass': idClicked == NP1955 }" id="NP1955" >27* Herakles reclining, 1st c. B.C., Hill of the Nymphs northeast slope <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201318" target="_blank">Agora S 1318</a>; Thompson, H. A. 1949, 221, pl. 44 no. 1</p>
<p :class="{'activeClass': idClicked == NP1956 }" id="NP1956" >28* Statuette, perhaps Herakles strangling snakes, built into a late wall on the Areopagus west slope: Thompson, H. A. 1947, 195</p>
<p :class="{'activeClass': idClicked == NP1957 }" id="NP1957" >29* Head, 3rd c. A.C., northwest Athens, Hag. Thekla St. off Ermou St.(Plan 2 epsilon—4) : O. Alexandri, «Άγίας θέκλας 10-12,» Deltion 22 (1967) B1 Chr., 43 no. 4, pl. 68a See also: Alexander the Great, NM 366; Pankrates and Palaimon</p>
<p :class="{'activeClass': idClicked == NP1958 }" id="NP1958" >HERAKLES (continued), RELIEFS</p>
<p :class="{'activeClass': idClicked == NP1959 }" id="NP1959" >30* Herakles reclining, dedication for an ephebic victory at Eleusis, 159 A.D. - - - Oxford, Ashmolean, IG II² 3012; Scharmer, H. 1971, 34 no. 43</p>
<p :class="{'activeClass': idClicked == NP1960 }" id="NP1960" >31* Relief, west of the Propylaia - - - IG II² 4611; SEG 39 (1989) no. 236</p>
<p :class="{'activeClass': idClicked == NP1961 }" id="NP1961" >32* Relief, Herakles at an altar, family of worshippers, boy with pig, dedicated by Lysistrate, late 4th c. B.C., Akropolis South Slope - - - IG II² 4613; Kearns, E. 1989, 35-36, pl. IB; SEG 39 (1989) no. 236; Tagalidou, E. 1993, 203-205 no. 15, pl. 8</p>
<p :class="{'activeClass': idClicked == NP1962 }" id="NP1962" >33* Herakles reclining, dedicated by Timaios of Herakleia Brocklesby Park no. 28; IG II² 4952; LIMC IV 1988, 778 no. 1050, ph., s.v. Herakles (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP1963 }" id="NP1963" >34* Herakles seated under a baldachin Acr. 2586; Walter, O. 1923, 73-74 no. 123 with ph.</p>
<p :class="{'activeClass': idClicked == NP1964 }" id="NP1964" >35* Herakles, kneeling worshipper and a female body in four sections, late 4th c. B.C., Asklepieion (?)--- Acr. 7232 = Acr. 4901; LIMC IV, 1988, 803 no. 1386, ph., s.v. Herakles (O. Palagia); Tagalidou, E. 1993, 191-192, pl. 3</p>
<p :class="{'activeClass': idClicked == NP1965 }" id="NP1965" >36* Herakles with the Erymanthian boar, 5th c. B.C., near the Hephaisteion NM 43; Svoronos, J. N. 1908, 88-89, pl. 22; Froning, H. 1981, 51-52; <JBcom>[implausibly]</JBcom> assigned to a sanctuary at 5 Hag. Philippou St. (cf. Herakles Hrk9*), Travlos, J. 1971, 274; Tagalidou, E. 1993, 7-9, 205-208 no. 17, pl. 10; Kaltsas, N. 2002, 76 no. 114; <JBcom>[may be from the Herakles Alexikakos sanctuary]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1966 }" id="NP1966" >37* Herakles reclining under a plane tree, 2nd c. A.C., railroad trench at</p>
<p :class="{'activeClass': idClicked == NP1967 }" id="NP1967" >Monastiraki (Plan 1 beta—5) NM 1454; Scharmer, H. 1971, 11 no. 6, fig. 3;</p>
<p :class="{'activeClass': idClicked == NP1968 }" id="NP1968" >Kaltsas, N. 2002, 305 no. 642, ph.</p>
<p :class="{'activeClass': idClicked == NP1969 }" id="NP1969" >38* Herakles, Zeus Meilichios (?), goddess, Hermes, head of Acheloos (with inscr.), 250-200 B.C., in the Ilissos river bed at the site of Kallirrhoe-Enneakrounos (Plan 2 eta -6)- - - NM 1773; Skias, A. N. 1894, 134, 136-139, pl. 7; Isler, H. P. 1970, 128 no. 35; Travlos, J. 1971, fig. 388; the relief represents Herakles' initiation in the Lesser Mysteries at Agrai, Tagalidou, E. 1993, 64-70, 211-213 no. 9, pl. 9; Kaltsas, N. 2002, 278 no. 583, ph.; the goddess <JBcom>[convincingly]</JBcom> identified as Agathe Tyche, I. Leventi, op. cit. (under Agathe Tyche), 134-135, fig. 8</p>
<p :class="{'activeClass': idClicked == NP1970 }" id="NP1970" >39* Herakles and Antiochos, document relief, the phyle Antiochis honors the taxiarch Prokleides, ca. 325-300 B.C., Ilissos left bank south of the former Fix Brewery site (Kallirrois Blvd. and Ambrosiou Frantzi St., Plan 3 delta—10) - - - NM 3491; SEG 3 (1929) no. 116; LIMC I, 1981, 852, entry no. 6, ph., s.v. Antiochos (E. B. Harrison); Billot, M.-F. 1992, 146-148, 153, fig. 5; Lawton, C. L. 1995, 150 no. 157, pl. 83, General Index s.v. Antiochis, Antiochos; Kaltsas, N. 2002, 238 no. 501, ph.</p>
<p :class="{'activeClass': idClicked == NP1971 }" id="NP1971" >40* Herakles holding an apple, family of the dedicator with sacrifical bull, allegedly found south of the Ilissos - - - formerly <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201249" target="_blank">Agora S 1249</a>, now NM 3942; <JBcom>[doubtably]</JBcom> dated to 400-350 B.C., D. M. Robinson, “A New Heracles Relief,”, Hesperia 17 (1948) 137-140, pl. 34; Billot, M.-F. 1992, 137-138, 142, 144, 145, 153, fig. 4</p>
<p :class="{'activeClass': idClicked == NP1972 }" id="NP1972" >41* Herakles wrestling the Nemean lion, terracotta votive plaque, ca. 500 B.C. - - - Acr. 1323; LIMC V, 1990, 24 no. 1878, ph., s.v. Herakles (W. Felten); <JBcom>[evidence for a Herakles cult on the Akr. before 480 B.C. ]</JBcom></p>
<p :class="{'activeClass': idClicked == NP1973 }" id="NP1973" >41** Herakles riding an ithyphallic donkey, terracotta plaque, early 1st c. B.C., east foot of the Hill of the Nymphs - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%202466" target="_blank">Agora T 2466</a>; Thompson, D. B. 1966, 14-15, pi.6 See also: Athena Ath52**; Ath119*; Dionysos Dio7*, tripod base (2); Dionysos Dio15**; Orpheus Mon.</p>
<p :class="{'activeClass': idClicked == NP1974 }" id="NP1974" >HERAKLES - ALEXANDER: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM522">522</a></p>
<p :class="{'activeClass': idClicked == NP1975 }" id="NP1975" >HERAKLES ALEXIKAKOS</p>
<p :class="{'activeClass': idClicked == NP1976 }" id="NP1976" >42* Sanctuary in Melite with statue by Ageladas of Argos: schol. Ar. Ra. 501; <JBcom>[mistaken]</JBcom> location on the Areopagus southwest slope at the Bakcheion site, Travlos, J. 1971, 274-275, fig. 351; <JBcom>[Travlos' reconstruction is based on mutually supporting misinterpretations of three pieces of evidence: 1) an inscribed relief (Herakles Hrk43*), muddled up with 2) a cult table base (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM230">230</a>), and 3) an inscription mentioning a road from the sanctuary of Herakles Alexikakos to the Agora (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM600">600</a>)]</JBcom>; Kron, U. 62-63; Pollitt, J. J. 1990,33; LIMC IV, 1988, 746-747, 789 no. 1282, 791, s.v. Herakles (O. Palagia); Tagalidou, E. 1993, 7-41, 44-45; <JBcom>[doubtably]</JBcom> located “at the upper tier of the shrine of Zeus” on the east slope of the Hill of The Nymphs, Laionae, G. V. 2002, 297</p>
<p :class="{'activeClass': idClicked == NP1977 }" id="NP1977" >43* Inscr. relief of Herakles Alexikakos pointing to a Viersaulenbau with a krater on the epistyle and, at left, Hermes, 4th c. B.C., found in the Peiraieus (?)--- MFA Boston 96.696; SEG 28 (1978) no. 232; LIMC IV, 1988) 802 no. 1378, ph., s.v. Herakles (O. Palagia); dedication of a phratry for the Oinisteria, Tagalidou, E. 1993, 32-37, 230-233 no. 32, pl. 12; <JBcom>[the inscription looks modern in which case the representation has nothing whatsoever to do with Herakles Alexikakos]</JBcom> See also: Athena Ath119*; Herakles Hrk10*, Hrk36*; cf. Philokrates; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM230">230</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM600">600</a></p>
<p :class="{'activeClass': idClicked == NP1978 }" id="NP1978" >HERAKLES IN KYNOSARGES: IG I³ 369 line 87; Polemon in Ath. 234d (Herakleion in Kynosarges); Judeich, W. 1931, 422-424; Billot, M.-F. 1992, 120, 124, 129-142</p>
<p :class="{'activeClass': idClicked == NP1979 }" id="NP1979" >44* Sanctuary of Herakles called Kynosarges with altar of Herakles: Paus. 1.19.3; the altar of Herakles is paired with that of Hebe q.v.</p>
<p :class="{'activeClass': idClicked == NP1980 }" id="NP1980" >45* Sacred law forbidding tanners to work upstream from the temenos of Herakles on the Ilissos, 440-420 B.C., found at the corner of Vyronos and Bakchou Sts. (Plan 1 eta—8) - - - IG I³ 257; SEG 37 (1987) no. 23; the tanners worked not far from Kallirrhoe Spring, Travlos, J. 1970, 8; idem, 1971, 340, fig. 442; the inscription does not necessarily refer to the Herakleion in Kynosarges, Billot, M.-F. 1992, 155-156 See also: Antiochos, son of Herakles; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM535">535</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM539">539</a></p>
<p :class="{'activeClass': idClicked == NP1981 }" id="NP1981" >46* HERAKLES LEONTAS (rest.) and HERAKLES ALEXANDER, dedication inscribed on the right side of the herm portrait of kosmetes Onasos, Akropolis Lower North Slope, at the site of Hag. Dimitrios tou Katephori (Chr. Mon. CM59*, Plan 1 delta—7) - - - NM 387, IG II² 3744; Kaltsas, N. 2002, 326 no. 685, ph.</p>
<p :class="{'activeClass': idClicked == NP1982 }" id="NP1982" >47* HERAKLES MENYTES, sanctuary: Vita Soph.12; C. Watzinger, “Herakles ΜΗΝΥΤΗΣ,” AM 29 (1904) 237-243; B. gr. Kruse, RE XV, 1931, 970-971 (Menytes); LIMC IV, 1988, 803, s.v. Herakles, 803 (O. Palagia)</p>
<p :class="{'activeClass': idClicked == NP1983 }" id="NP1983" >HERAKLES PANKRATES: see Pankrates</p>
<p :class="{'activeClass': idClicked == NP1984 }" id="NP1984" >HERAKLES AND OTHERS: see Hermes Hrm38*</p>
<p :class="{'activeClass': idClicked == NP1985 }" id="NP1985" >HERAKLIOS the philosopher, columnar gravestone with an inscription containing two Latin words, 3rd c. A.C. (?), Phalirou St. in southeast Athens (Plan 2 theta—4/5) - - - IG II² 11606 A; J. H. Oliver, “Marcus Aurelius and the Philosophical Schools at Athens: II. Epitaph of a Philosopher at Athens,” AJP 102 (1981) 217-221; SEG 31 (1981) no. 238</p>
<p :class="{'activeClass': idClicked == NP1986 }" id="NP1986" >HERCULIUS, praetorian prefect of Illyricum 408-410 A.D. (PLRE II, 1980, Herculius 2); Agora XXIV, Index I s.v. Apronianos, Herculius, Ploutarchos, pl. 47: b and f (IG II² 4224 and 4225); P. Castren, “General Aspects of Life in Post-Herulian Athens,” in Castren, P. ed. 1994, 12</p>
<p :class="{'activeClass': idClicked == NP1987 }" id="NP1987" >Statue dedicated by Apronianos beside the statue of Athena Promachos,408-410 IG II² 4225; Agora XXIV, 64, pl. 47:f; Sironen, E. 1997, 82-84, no. 23</p>
<p :class="{'activeClass': idClicked == NP1988 }" id="NP1988" >Epigram recording a statue dedicated by Ploutarchos the sophist, 408-410 A.D., inscribed on a block in the west façade of Hadrian's Library - - - IG II² 4224; A. Frantz, “Honors to a Librarian,” Hesperia 3* (1966) 380, pl. 91 :d <JBcom>[a beautiful photo]</JBcom>; SEG 38 (1988) no. 185; Sironen, E. 1997, 81-82 no. 22; the epigram was carved in the same workshop as IG II² 5201 recording the repair of a tower by Panathenaios, perhaps the proconsul of Achaia for whom Himerios wrote an epithalamion, M. Heil, “Panathenius, Statthalter von Griechenland,” Klio 79 (1997) vol. 2, 469 note 10; SEG 47 (1997) no. 236</p>
<p :class="{'activeClass': idClicked == NP1989 }" id="NP1989" >HERKEIOS: see Zeus Zeu32*-33*</p>
<p :class="{'activeClass': idClicked == NP1990 }" id="NP1990" >HERM: see Herms</p>
<p :class="{'activeClass': idClicked == NP1991 }" id="NP1991" >HERMAIOS, an Egyptian wool-weaver from Thebes, gravestone, 425-400? B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20356" target="_blank">Agora I 356</a>, IG I³ 1341 bis = IG II² 7967; Meritt, B. D. 1934, 87 no. 105 with drawing</p>
<p :class="{'activeClass': idClicked == NP1992 }" id="NP1992" >HERMAPHRODITOS</p>
<p :class="{'activeClass': idClicked == NP1993 }" id="NP1993" >Cult in Alopeke: Alciphr. II.35; M. Delcourt, Hermaphrodite, London, 1961, 49-50; LIMC V, 1990, 269, s.v. Hermaphroditos (A. Ajootian)</p>
<p :class="{'activeClass': idClicked == NP1994 }" id="NP1994" >Statue listed in a gymnasium inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 54; Clay, D. 1977, 46, 261, 263, 265</p>
<p :class="{'activeClass': idClicked == NP1995 }" id="NP1995" >Miniature herm of an ithyphallic Hermaphrodite, ca. 1st c. B.C., northwest foot of the Areopagus - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201234" target="_blank">Agora S 1234</a>; Thompson, H. A. 1948, 180 note 59; Agora XI, 173 no. 231, pl. 60</p>
<p :class="{'activeClass': idClicked == NP1996 }" id="NP1996" >HERMCARVERS, name of a street or district: Plu. Moralia 580 E; Young, R. S. 1951, 139, 151 note 36</p>
<p :class="{'activeClass': idClicked == NP1997 }" id="NP1997" >HERMES</p>
<p :class="{'activeClass': idClicked == NP1998 }" id="NP1998" >Altars</p>
<p :class="{'activeClass': idClicked == NP1999 }" id="NP1999" >1* Altar at the Academy: Paus. 1.30.2; Billot, M.-F. 1989, 742 See also: Hermes Hrm15*, Hrm34*; Zeus Zeu33*</p>
<p :class="{'activeClass': idClicked == NP2000 }" id="NP2000" >2* Dedication on herm or base by Oinobios (?) the Keryx, ca. 500-480 B.C., Propylaia - - - IG I³ 776 = IG I² 631; SEG 16 (1959) no. 15</p>
<p :class="{'activeClass': idClicked == NP2001 }" id="NP2001" >3* Dedication on a base by a victor in the Epitaphia torch race, ca. 8/7 B.C., Akropolis lower north slope at the site of Hag. Dimitrios tou Katephori (Chr. Mon. CM51*, Plan 1 delta—7) - - - IG II² 2997; SEG 23 (1968) no. 100</p>
<p :class="{'activeClass': idClicked == NP2002 }" id="NP2002" >4* Dedication by a victor in the Heroa torch race, herm (?) base, mid-2nd c. B.C. Kerameikos I 328; Willemsen, F. 1970, 107-111 no. 10, fig. 2, pl. 49 no. 1</p>
<p :class="{'activeClass': idClicked == NP2003 }" id="NP2003" >5* Torch dedicated by a torch race victor, ca. 50 A.D., 10-12 Hag. Theklas St. northwest of Monastiraki (Plan 1 alpha—4) - - - 3rd Ephor. M. 743; P. A. Pantos, «Έπιγραφαι παρά την Αγοράν Άθηνών,» ΑΕ 1973, 176-180, figs. 1-2; Bull. 1976, no. 177: 2; SEG 38 (1988) no. 176</p>
<p :class="{'activeClass': idClicked == NP2004 }" id="NP2004" >6* Dedication by Thebaios the phylarch, 3rd c. B.C., in the foundations of the so-called Peripatos or Square Peristyle in the Academy (Part II no.438) - - - 3rd Ephor. M 3118; Papagiannopoulos-Palaios, A. A. 1939, 105-107, no. 32; Billot, M.- F. 1992, 742; SEG 47 (1997) no. 197</p>
<p :class="{'activeClass': idClicked == NP2005 }" id="NP2005" >7* Ephebes' dedication on columnar base, 59/8 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205738" target="_blank">Agora I 5738</a>; Meritt, B. D. 1961, 270 no. 100, pl. 52</p>
<p :class="{'activeClass': idClicked == NP2006 }" id="NP2006" >8* Herm dedicated to Hermes companion of the Naiads, 1st c. A.C., at the Giants (?)--- IG II² 4728; Camp, J. McK. 1980, 326 no. 68</p>
<p :class="{'activeClass': idClicked == NP2007 }" id="NP2007" >9* Gymnasium of Hermes near Dionysos Melpomenos: Paus. 1.2.5; Delorme, J. 1960, 523 s.v. Athenes (g.d'Hermes)</p>
<p :class="{'activeClass': idClicked == NP2008 }" id="NP2008" >10* Wooden statue in the temple of the Polias, concealed in myrtle branches, dedicated by Kekrops: Paus. 1.27.1; U. Kron, “Heilige Steine,” in Kotinos 1992, 57 with note 9</p>
<p :class="{'activeClass': idClicked == NP2009 }" id="NP2009" >11* Statue in the sanctuary of the Semnai: Paus. 1.28.6</p>
<p :class="{'activeClass': idClicked == NP2010 }" id="NP2010" >12* Hermes with syrinx, high relief, not far from the Parthenon southeast corner - - - Acr. 622; Schrader, H. 1939, no. 428, pl. 177</p>
<p :class="{'activeClass': idClicked == NP2011 }" id="NP2011" >13* Seated Hermes, 5th c. B.C. (?), from a spring house, Acr. 1346; Ridgway, B. S. 1981, 242</p>
<p :class="{'activeClass': idClicked == NP2012 }" id="NP2012" >14* Statue listed in a gymnasium inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 35; Clay, D. 1977, 261, 263 See also: Herms Her14*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM523">523</a></p>
<p :class="{'activeClass': idClicked == NP2013 }" id="NP2013" >HERMES AGORAIOS by the Stoa Poikile: Agora III, 1957, 102-103 nos. 296-300</p>
<p :class="{'activeClass': idClicked == NP2014 }" id="NP2014" >15* Altar set up by the hipparch Kallistratos, 413 B.C. (?): Plu. Moralia 844 B; Agora III no. 300; Develin, R. 1989, 156</p>
<p :class="{'activeClass': idClicked == NP2015 }" id="NP2015" >16* Bronze statue seen by Pausanias on his way from the sanctuary of Aphrodite Ourania to the Stoa Poikile: Paus. 1.15.1; Agora XI.112</p>
<p :class="{'activeClass': idClicked == NP2016 }" id="NP2016" >17* HERMES AMYETOS, statue on the Akr.: Hsch. s.v.; Judeich, W. 1931, 225 note 1</p>
<p :class="{'activeClass': idClicked == NP2017 }" id="NP2017" >18* HERMES CHTHONIOS, cult: schol. Ar. Ach. 1076-7, schol. Ar. Pax 650; Pickard-Cambridge, A. 1968, 3 no. 5, 13-14</p>
<p :class="{'activeClass': idClicked == NP2018 }" id="NP2018" >HERMES ENAGONIOS</p>
<p :class="{'activeClass': idClicked == NP2019 }" id="NP2019" >19* Dedication on a statue base for a victory in the torch race at the Greater Panathenaia, 338 B.C., near the theater - - - IG II² 3023</p>
<p :class="{'activeClass': idClicked == NP2020 }" id="NP2020" >20* Dedication on a base, mid-4th c. B.C., theater IG II² 4572; Davies, J. K. 1971, 345 See also: Dionysos Dio15*</p>
<p :class="{'activeClass': idClicked == NP2021 }" id="NP2021" >21* HERMES EPEKOOS, dedication on a columella, Akropolis North Slope —IG II² 4742; Threatte, L. 1980, 9</p>
<p :class="{'activeClass': idClicked == NP2022 }" id="NP2022" >22* HERMES HEGEMONIOS, cult: IG II² 1496 lines 84-85; C. A. Powell, “Religion and the Sicilian Expedition,” Historia 28(1979) 22</p>
<p :class="{'activeClass': idClicked == NP2023 }" id="NP2023" >23* HERMES IN THE LYKEION, cult: IG II² 1357b line 4 </p>
<p :class="{'activeClass': idClicked == NP2024 }" id="NP2024" >24* HERMES KATAIBATES, cult: schol. Ar. Pax 650</p>
<p :class="{'activeClass': idClicked == NP2025 }" id="NP2025" >25* HERMES KRIOPHOROS, late archaic relief, northwest Athens on Aimonos St. north of Alexandreias St. (Plan 2 alpha—2): O. Alexandri, «Αΐμονος 1, (χάνδαξ ΟΑΠ),» Deltion 27 (1972) B1Chr., 88 no. 3, fig. 1 no. 3</p>
<p :class="{'activeClass': idClicked == NP2026 }" id="NP2026" >26* HERMES PHALANTHEIOS, dedication on a round base, Athena Archegetis Gate — IG II² 4855; A. N. Oikonomides and S. N. Koumanoudes, «Εις Άττικής έπιγραφάς,» Polemo 5 (1952/53) 25 no. 1;</p>
<p :class="{'activeClass': idClicked == NP2027 }" id="NP2027" >SEG 12 (1955) no. 168</p>
<p :class="{'activeClass': idClicked == NP2028 }" id="NP2028" >27* HERMES PROPYLAIOS, sculpture at the entrance to the Akr.: Paus.l.22.8; A. Hermary, “A propos de l'Hermes Propylaios de Delos,” BCH 103 (1979) 145-9; J. E. Francis, “Rewriting Attributions: Alkamenes and the Hermes Propylaos,” in ΣΤΕΦΑΝΟΣ 1988, 61-68, fig. 1,1-7; SEG 46 (1996) no. 2376</p>
<p :class="{'activeClass': idClicked == NP2029 }" id="NP2029" >28* HERMES PSITHYRISTES, statue: D. 59.39; F GR HIST III B, Zopyros 336 F 2 with commentary</p>
<p :class="{'activeClass': idClicked == NP2030 }" id="NP2030" >HERMES PSYCHOPOMPOS: see Myrrhine</p>
<p :class="{'activeClass': idClicked == NP2031 }" id="NP2031" >29* HERMES PYLETES AND CHARIDOTES, cult: IG II² 3664 lines 3-4; Clinton, K. 1974, 94, 96</p>
<p :class="{'activeClass': idClicked == NP2032 }" id="NP2032" >30* HERMES TETRAKEPHALOS by Telesarchides in the Kerameikos: Eust. Iliad XXIV, 336; Agora III, nos. 314-316</p>
<p :class="{'activeClass': idClicked == NP2033 }" id="NP2033" >31* HERMES TRIKEPHALOS at a road fork in Ankyle: Harp. s.v. Τρικέφαλος; FGR HIST III B, 105, Philochoros 328 F 22 with suppl. vol. 1.313; I. Z. Tzifopoulos, “Hekate? Street: A Horos from Ηλιόκαστρo of Hermione,” Horos 10-12 (1992-1998) 257-258</p>
<p :class="{'activeClass': idClicked == NP2034 }" id="NP2034" >32* HERMES TYCHON, statue; Clem. Al. Protr. X. 81; H. Herter, RE VII A2, 1948, col. 1699 lines 16-27 (Tychon)</p>
<p :class="{'activeClass': idClicked == NP2035 }" id="NP2035" >33* HERMES, ACHELOOS, (NYMPHS), relief fragment, ca. 330 B.C. — Acr. 2743; Edwards, C. M. 1985, 496-498</p>
<p :class="{'activeClass': idClicked == NP2036 }" id="NP2036" >34* HERMES AND APHRODITE (?), altar, Augustan pd., built into the wall of a house on Mousaiou St., south of the Roman Agora (Plan 1 delta—5) NM 54; F. Harl-Schaller, “Archaistischer Reliefpfeiler im Nationalmuseum zu Athen,” AM 87 (1972) 241-253, pls. 84, 86-89 no. 1; Kaltsas, N. 2002, 94 no. 162, ph.</p>
<p :class="{'activeClass': idClicked == NP2037 }" id="NP2037" >35* HERMES, APHRODITE, PAN, NYMPHS, ISIS, stele base (?), 49-30 B.C., Akropolis South Slope, built into the foundations of the church in the Asklepieion IG II² 4994; Walker, S. 1979a, 246-248, 256, fig. 1, pl. 30 b; Archeion 1,1992, no. 171; a bronze plaque (Kanellopoulos Museum XA 1469) imitating the base exposed as a forgery, Beschi, L. 2002, 25-28</p>
<p :class="{'activeClass': idClicked == NP2038 }" id="NP2038" >HERMES AND APOLLO: see Apollo Apo86*; Panathenaic Stadium, Double herms</p>
<p :class="{'activeClass': idClicked == NP2039 }" id="NP2039" >HERMES DIONYSOPHOROS</p>
<p :class="{'activeClass': idClicked == NP2040 }" id="NP2040" >36* Statue with herm support, 2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%2033" target="_blank">Agora S 33</a>; Agora XI, 162 no. 210, pl 56</p>
<p :class="{'activeClass': idClicked == NP2041 }" id="NP2041" >37* Central figure in a grotto relief with Zeus, Demeter, Apollo, Artemis, Nymphs, Pan, Acheloos, dedicated by Neoptolemos, son of Antikles, ca. 330 B.C., Late Roman Mansion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM121">121</a>) on the Areopagus northeast slope - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207154" target="_blank">Agora I 7154</a>;</p>
<p :class="{'activeClass': idClicked == NP2042 }" id="NP2042" >LIMC II, 1984, 721 no. 1280, ph., s.v. Artemis (L. Kahil); Edwards, C. M. 1985, 419-437 no. 14; Christians obliterated the heads of the figures <JBcom>[in the 6th c.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2043 }" id="NP2043" >38* HERMES, (HERAKLES), APOLLO, herm dedicated by the gymnasiarch of the ephebes in Delos, 95 B.C., Pompeion — Kerameikos P 1, IG II² 2990; D. Willers, “Zum Hermes Propylaios des Alkamenes,” Jahrbuch 82 (1967) 91 no. 7</p>
<p :class="{'activeClass': idClicked == NP2044 }" id="NP2044" >39* HERMES and NYMPH(S), relief, 4th c. B.C., Akropolis South Slope - - - NM 1349; Edwards, C. M. 1985, 397-400; <JBcom>[perhaps from the Round Cave Spring House in the Asklepieion (Asklepios Ask27*)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2045 }" id="NP2045" >40* HERMES AND NYMPHS, archaizing relief, late 1st c. B.C. - early 1st c. A.C., northwest Athens, northwest of the Academy area: Ph. D. Stavropoulos, «Άνασκαφαι Άρχαίας Άκαδημειας,» Praktika 1963, 20, fig. 7, pl. 16: a; Travlos, J. 1971, fig. 63 Upper zone: Hermes and three nymphs or Charites Lower zone: (missing figure) and three Nymphs in a cave (?)</p>
<p :class="{'activeClass': idClicked == NP2046 }" id="NP2046" >HERMES and OTHERS: see Apollo Apo86*; Herakles Hrk38*, 42+; Nymphs Nym3*, Nym8*, Nym11*; Orpheus Mon.; Pan Pan10*; Zeus Zeu33*</p>
<p :class="{'activeClass': idClicked == NP2047 }" id="NP2047" >HERMOLYKOS the pankratiast, son of Euthoinos, statue, Akr.: seen on the way from the Propylaia to the Parthenon, Paus. 1.23.10; see Oinobios</p>
<p :class="{'activeClass': idClicked == NP2048 }" id="NP2048" >HERMS: Paus. 1.19.2, 1.24.3, 4.33.3; Agora III, 102-108; Agora XI, 108-176, pls. 40-61; Henning Wrede, Die antike Herme, Mainz, 1985, 5-12</p>
<p :class="{'activeClass': idClicked == NP2049 }" id="NP2049" >1* The Herms, series on the Panathenaic Way starting from the Stoa Basileios and the Stoa Poikile: Agora III, nos. 203, 302-303, 305-306, 308, 310-312; Shear Jr., T. L. 1984, 40-43; Di Cesare, R. 2001, 21-23</p>
<p :class="{'activeClass': idClicked == NP2050 }" id="NP2050" >1** Onesippos' herm, choregic monument for a victory in the Lenaia, ca. 400 B.C., the base in situ on the step of the Stoa Basileios - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207168" target="_blank">Agora I 7168</a>; SEG 32 (1982) no. 239; Wilson, P. 2000, 29-31, 236, 320 notes 95-100</p>
<p :class="{'activeClass': idClicked == NP2051 }" id="NP2051" >2* Stoa of the Herms, built ca. 476/5 B.C., named in two honorary decrees for hipparchs and phylarchs to be set up in the Stoa of the Herms; for other testimonia, see Agora III, nos. 301, 305, 313; Di Cesare, R. 2001, 17-36</p>
<p :class="{'activeClass': idClicked == NP2052 }" id="NP2052" >Decree of 281/280 B.C., built into the foundations of the Augustan podium temple (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM365">365</a>) in the city block bounded by Adrianou, Hag. Philippou, Astingos and Thissiou Sts. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207587" target="_blank">Agora I 7587</a>; SEG 46 (1996) no. 167; Camp, J. McK. 1996, 252-258, fig. 1 (which shows how close the findspot is to the so-called Stoa Poikile, Part II no 351)</p>
<p :class="{'activeClass': idClicked == NP2053 }" id="NP2053" >Decree of 282/281 B.C., reused in a foundation of early Roman times at 7-9 Thissiou St. in the same city block as the decree above 3rd Ephor. no. 767; SEG 21 (1965) no. 525 line 44; Threpsiades, J. and E. Vanderpool 1963, 99, 102, 104-109, figs. 1-2, pl. 38a; Threpsiades and Vanderpool op. cit., 108-109 with fig. 1, note that this decree was found only about 50 metres west of the hypothetical position of the Stoa of the Herms as shown on current plans of the Agora <JBcom>[i.e. the site of the so-called Stoa Poikile (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM351">351</a>)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2054 }" id="NP2054" >3* Herms set up in the Stoa of the Herms after the capture of Eion, ca. 475 B.C.: Aischin. III.183-5; Agora III, nos. 301,309; Agora XIV, 94, 96 note 73; Further Greek Epigrams 1981, 255-259; Eion herms represented on an RF pelike by the Pan Painter, ca. 470 B.C., Agora XI, 111, pl. 65 A; Di Cesare, R. 2001, 24-32, 36</p>
<p :class="{'activeClass': idClicked == NP2055 }" id="NP2055" >4* Herms in the likeness of Alkibiades: Clem. Al. Protr. IV.47; G. Hafner, “Alkibiades und Nikias,”, Madrider Mitteilungen 25 (1984) 13 note 52</p>
<p :class="{'activeClass': idClicked == NP2056 }" id="NP2056" >5* Herms in the Ptolemaion: Paus. 1.17.2; Agora XI, 109-110</p>
<p :class="{'activeClass': idClicked == NP2057 }" id="NP2057" >6* Herm with hexameter on Agamemnon: Harp. s.v. Έρμαι; Agora III, no. 305</p>
<p :class="{'activeClass': idClicked == NP2058 }" id="NP2058" >7* Herm at Aigeus' Gate, east of the Delphinion: Plu. Thes. 12.3; Kron, U. 1976, 125 with note 574</p>
<p :class="{'activeClass': idClicked == NP2059 }" id="NP2059" >8* Herm dedicated by the Aigeis tribe, known as the herm of Andokides, by the Phorbanteion and by Andokides' ancestral house: And. 1.62; Agora III, nos. 367, 382; <JBcom>[correctly]</JBcom> located southeast of the Agora near the Theseion <JBcom>[i.e. on the Akropolis North Slope]</JBcom>, Agora XI, 117-120</p>
<p :class="{'activeClass': idClicked == NP2060 }" id="NP2060" >9* Archaic head frag. - - - Acr. 642; Schrader H. 1939, no. 461, pl. 163</p>
<p :class="{'activeClass': idClicked == NP2061 }" id="NP2061" >10* Herm Acr. 2881; D. Willers, op. cit. (Hermes Hrm38*),102 no. 60, fig. 82</p>
<p :class="{'activeClass': idClicked == NP2062 }" id="NP2062" >11* Herm, head fragment, formerly called Dionysos mask, ca. 470 B.C., Akropolis South Slope - - - NM 96; Agora XI, 1965, 143, pl. 65:b; Kaltsas, N. 2002, 86 no. 147</p>
<p :class="{'activeClass': idClicked == NP2063 }" id="NP2063" >12* Double herm, frag, with winged kerykeion and hydria reliefs, Odeion of Perikles: G. Karo, “Archaologische Funde im Jahre 1914,” AA 1915, 180 fig. 3</p>
<p :class="{'activeClass': idClicked == NP2064 }" id="NP2064" >13* Herm with beard hacked off, Akr. West Slope - - - NM inv. no. ?; D. Willers, op. cit. (Hermes Hrm38*), 97 no. 22, fig. 76</p>
<p :class="{'activeClass': idClicked == NP2065 }" id="NP2065" >14* Herm dedicated to Hermes (?) by ephebes, Akropolis North Slope at Haghios Nikolaos (or Seraphim), (Plan 1 epsilon—5, no. 105) - - - IG II² 2988</p>
<p :class="{'activeClass': idClicked == NP2066 }" id="NP2066" >15* Herm with broken nose, ca. 500 B.C., just above the western steps of the so-called Stoa Poikile (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM351">351</a>) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%203347" target="_blank">Agora S 3347</a>; Shear Jr., T. L. 1984, 42-43, pl. 10</p>
<p :class="{'activeClass': idClicked == NP2067 }" id="NP2067" >16* Herm, 480-470 B.C., the nose broken off and repaired in antiquity - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20211" target="_blank">Agora S 211</a>; Agora XI, no. 156, pl. 40</p>
<p :class="{'activeClass': idClicked == NP2068 }" id="NP2068" >17* Herm mutilated in 415 B.C., found in late 5th c. B.C. deposit on the Sacred Rock in the Northwest Orthostate Enclosure (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM318">318</a>) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202452" target="_blank">Agora S 2452</a>; Shear Jr., T. L. 1973a, 129, 164-5, pls. 26 b, 35 a and b </p>
<p :class="{'activeClass': idClicked == NP2069 }" id="NP2069" >18* Herm base, dedicated by the prytaneis of Antiochis, 393/2 B.C., reused as a bench in the Panagia Vlassarou church - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204226" target="_blank">Agora I 4226</a>; Meritt, B. D. 1960, 36-37 no. 44, pl. 9; SEG 19 (1963) no. 150</p>
<p :class="{'activeClass': idClicked == NP2070 }" id="NP2070" >19* Female herm, 2nd c. B.C., found in a well that was closed up ca. 150 B.C., northeast of the Hephaisteion, with the bones of over 450 newborn babes and about 150 dogs, pottery of the 2nd quarter of the 2nd c. B.C., and a sword - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201086" target="_blank">Agora S 1086</a>; Agora XI, 1965, 139, 167-169 no. 218, pl. 58; <JBcom>[mistakenly]</JBcom> identified as a copy of the cult statue of Aphrodite Ourania, Travlos, J. 1971, 79, fig. 105; For the magnificent ivory sword hilt crossbar (Agora Bl 457), see Shear, T. L. 1939, 239, fig. 38; for the deposit, see L. M. Little, “Babies in Well G 5:3: Preliminary Results and future Analysis,” and L. M. Snyder, “The Animal Bones from Well G 5:3,” and S. I. Rotroff, “The Artifacts from Well G 5:3 and Some Conclusions concerning the Deposit,” AJA 103 (1999) 284-285 See also Index I s.v. Sculpture, Herms. Some of the entries under the heading “Hermes” may well be herms, e.g. Hermes Hrm30*-31*</p>
<p :class="{'activeClass': idClicked == NP2071 }" id="NP2071" >HEROA, festival at the Asklepieion: IG II² 974 line 12; Beschi, L. 1967/68, 422-423, 428</p>
<p :class="{'activeClass': idClicked == NP2072 }" id="NP2072" >HEROD THE GREAT, king of Judaea 37-44 A.D.: W. Otto, RESuppl. 2, 1913, 74-75 (Herodes)</p>
<p :class="{'activeClass': idClicked == NP2073 }" id="NP2073" >Herod's dedications in Athens: Josephus, BJ I.425; for a survey of Herod's donations outside of Judaea, see W. Otto, op. cit., 73-75</p>
<p :class="{'activeClass': idClicked == NP2074 }" id="NP2074" >Statue base, Akr. IG II² 3440; Geagan, D. 1996, 158-159</p>
<p :class="{'activeClass': idClicked == NP2075 }" id="NP2075" >Statue base, Akr. IG II² 3441</p>
<p :class="{'activeClass': idClicked == NP2076 }" id="NP2076" >Statue base - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202658" target="_blank">Agora I 2658</a> (restored); Meritt, B. D. 1952, 370 no. 14, pl. 93; SEG 12 (1955) no. 150</p>
<p :class="{'activeClass': idClicked == NP2077 }" id="NP2077" >HERODES ATTICUS. L. Vibullius Hipparchos Tiberius Claudius Atticus Herodes: Ameling W. 1983; SEG 48 (1998) no. 12; Byrne, S. G. 2003, 114-122 no. 8</p>
<p :class="{'activeClass': idClicked == NP2078 }" id="NP2078" >Grave in the Panathenaic Stadium with an epitaph, state burial monument, 179 A.D.: Philostr. VS 11.1.15; Tobin, J. 1997, 181-185, figs. 34-35; cf. Welch, K. 1998, 136-141</p>
<p :class="{'activeClass': idClicked == NP2079 }" id="NP2079" >Unfinished sarcophagus controversially <JBcom>[mistakenly]</JBcom> assigned to Herodes Atticus, A. D. 177/8 ? A.C., on the hill east of the Panathenaic Stadium: the sarcophagus contained the half-burnt remains of a man with a gold coin of Trajan Decius (249-251) in his mouth, perhaps from a second burial, H. R. Goette, “Attische-Klinen-Relief Sarkophage,” AM 106 (1991) 321-322 no. 2, pl. 95; Tobin, J. 1993, 82-85, fig. 2; eadem 1997, 181-184</p>
<p :class="{'activeClass': idClicked == NP2080 }" id="NP2080" >Base or altar, ca. 179 A.D., dedicated to The Marathonian Hero, reused in the wall enclosing the sarcophagus (above) - - - IG II² 6791; Tobin, J. 1993, 84-85; eadem 1997, 177-185, Welch, K. 1998, 139-140; SEG 48 (1998) no. 292</p>
<p :class="{'activeClass': idClicked == NP2081 }" id="NP2081" >Odeion, memorial to Regilla, ca. 160s A.C., Akropolis South Slope: Paus. 7.20.6; Travlos, J. 1971, 378-386, figs. 492-500; Meinel, R. 1980, 80-117, 417-420, figs. 20-31</p>
<p :class="{'activeClass': idClicked == NP2082 }" id="NP2082" >Rooftiles stamped with a monogram composed of theta, eta, rho: Meinel, R. 1980,110</p>
<p :class="{'activeClass': idClicked == NP2083 }" id="NP2083" >Headless statue of an author thought to be Herodes Atticus, found in a niche in the Odeion: Richter, G. M. A. 1965, III, 286, fig. 2043</p>
<p :class="{'activeClass': idClicked == NP2084 }" id="NP2084" >Inscr. about a pyrgos carved on an Odeion seat - - - IG II² 5214; Levensohn, M. and E. 1947, 73 note 18, fig. 1 no. 148</p>
<p :class="{'activeClass': idClicked == NP2085 }" id="NP2085" >Statue base, before 127 A.D., built into the Hypapanti Church, Akropolis northwest slope (Chr. Mon. CM71*, Plan 1 epsilon—4) IG II² 3600; Ameling, W. 1983, I, 48-49 note 8 and II, 105 no. 75</p>
<p :class="{'activeClass': idClicked == NP2086 }" id="NP2086" >Statue base, Asklepieion - - - IG II² 3599 + 4523; D. Peppa-Delmouzou, «Έπιγραφαι έκ τού Έπιγραφικού Μουσείου,» Deltion 25 (1970) A, 195-197 no. 2, pl. 67b</p>
<p :class="{'activeClass': idClicked == NP2087 }" id="NP2087" >HERODOROS, emissary of Demetrios Poliorketes, honorary decree with relief of Herodoros crowned by Athena, 295/4 B.C. - - - Acr. 2307 + 4603, IG II² 646; SEG 29 (1979) no. 95 and 45 (1995) no. 100; Lawton, C. L. 1995, 109-110 no. 59, pl. 31 and General Index s.v. Herodoros</p>
<p :class="{'activeClass': idClicked == NP2088 }" id="NP2088" >Bronze statue, awarded by decree, to be set up anywhere in the Agora except at Harmodios and Aristogeiton and the Soteres (Antigonos I and Demetrios Poliorketes): IG II² 646 line 38; Habicht, Chr. 1979, 4-7 </p>
<p :class="{'activeClass': idClicked == NP2089 }" id="NP2089" >HERODOTOS: Grave together with Thucydides' grave: Marcellin. Vit. Thuc. 17; dubitandum, O. Luschat, RE Suppl. XII, 1970, 1105-1106 (Thukydides)</p>
<p :class="{'activeClass': idClicked == NP2090 }" id="NP2090" >Head (Herodotos ?) 2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20270" target="_blank">Agora S 270</a>; Agora I, 9-10, pl. I</p>
<p :class="{'activeClass': idClicked == NP2091 }" id="NP2091" >HEROES NAMED AS HEROES IN ANCIENT SOURCES</p>
<p :class="{'activeClass': idClicked == NP2092 }" id="NP2092" >1* Dedication to The Hero, Akropolis South Slope - - - IG II² 4599</p>
<p :class="{'activeClass': idClicked == NP2093 }" id="NP2093" >2* Dedication to The Hero on a statue base, 350-300 B.C. (?), built into Haghion Asomaton Church off Ermou St. ( Plan 2 epsilon—3, no. 45) - - - SEG 21 (1965) no. 754</p>
<p :class="{'activeClass': idClicked == NP2094 }" id="NP2094" >3* Dedication to The Hero by the prytaneis of Akamantis, 227/6 B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201559" target="_blank">Agora I 1559</a>; Meritt, B. D. 1947, 160 no. 55, pl. 28</p>
<p :class="{'activeClass': idClicked == NP2095 }" id="NP2095" >4* Banqueting hero relief, dedicated to The Hero by Chrysis, Areopagus north slope  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204707" target="_blank">Agora I 4707</a>; SEG 22 (1967) no. 166; Dentzer, J.-M. 1982, 584 no. R 143, fig. 409</p>
<p :class="{'activeClass': idClicked == NP2096 }" id="NP2096" >5* Base for a dedication to The Hero by 10 eranistai, early 2nd c. B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206595" target="_blank">Agora I 6595</a>; SEG 21 (1965) no. 633; B. D. Meritt, “Greek Inscriptions,” Hesperia 32 (1963), 46 no. 64, pl. 15</p>
<p :class="{'activeClass': idClicked == NP2097 }" id="NP2097" >6* Dedication to The Hero, after the mid-4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207015" target="_blank">Agora I 7015</a>; Thompson, H. A. 1968, 53, fig. 7, pl. 9:b; Meritt, B. D. 1968, 288 no. 27; SEG 25 (1971) no. 219</p>
<p :class="{'activeClass': idClicked == NP2098 }" id="NP2098" >7* Hero to whom the Boule dedicated the 10 silver kylikes of the Eponymous Heroes recorded in an inventory, ca. 325 B.C., both fragments of which were found in the northwest section of the Agora Exc.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201078" target="_blank">Agora I 1078</a> + 7475 lines 13-16; D. M. Lewis, “An Inventory in the Agora,” ZPE 36 (1979) 131-134; SEG 29 (1979) no. 146</p>
<p :class="{'activeClass': idClicked == NP2099 }" id="NP2099" >8* The Heroes, dedication, ca. 100 B.C., “from the foundations of a late Roman house southeast of the Tholos” <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204791" target="_blank">Agora I 4791</a>; Meritt, B. D. 1961, 269 no. 98, pl. 51; SEG 21 (1965) no. 794; Tracy, S. V. 1990, 253</p>
<p :class="{'activeClass': idClicked == NP2100 }" id="NP2100" >9* The Heroes, pit at the Academy where the polemarchs sacrifice to The Heroes: Hid. 1.17.5; Billot, M.-F. 1989, 777-778; perhaps Harmodios and Aristogeiton, pace Kearns, E. 1989, 52 note 36, 143 s.v. Akademia</p>
<p :class="{'activeClass': idClicked == NP2101 }" id="NP2101" >10* The Heroines and Echelos, stele recording a decree of the orgeones to be set up in the sanctuary, early 3rd c. B.C., Areopagus north slope <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201906" target="_blank">Agora I 1906</a>; SEG 49 (1999) no. 160; Ferguson, W. S. 1944, 73-79, 94-95, 127-128; Agora XVI, 1997, no. 161, 229-231</p>
<p :class="{'activeClass': idClicked == NP2102 }" id="NP2102" >Heros Echetlos: see Marathonomachia</p>
<p :class="{'activeClass': idClicked == NP2103 }" id="NP2103" >11* Heros Epekoos, dedication, ca. 280 B.C. Kerameikos I 444; Willemsen, F. 1970, 105-107 no. 9, fig. 1, pl. 48:3; SEG 37 (1987) no. 143</p>
<p :class="{'activeClass': idClicked == NP2104 }" id="NP2104" >12* Heros έπι Βλαυτη: Poll. 7.87; Beschi, L. 1967/68, 519-520; cf. Snake with dedicator Silon</p>
<p :class="{'activeClass': idClicked == NP2105 }" id="NP2105" >Heros Epitegios: see Dioskouroi, Anakes and Heros E.</p>
<p :class="{'activeClass': idClicked == NP2106 }" id="NP2106" >13* Heros Ιatros, sanctuary in asty: IG II² 840 line 3; Agora III, nos. 340, 347; S. Dow, “The Cult of the Hero Doctor,” BASP 22 (1985) 33-47; cf. 17* below; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM475">475</a></p>
<p :class="{'activeClass': idClicked == NP2107 }" id="NP2107" >Two decrees concerning dedications in the sanct., found at the junction of Athinas and Voreou Sts. not far from the chapel of Haghia Mavra (Plan 2 epsilon -4): Judeich, W. 1931, Plan I square F-2</p>
<p :class="{'activeClass': idClicked == NP2108 }" id="NP2108" >14* Decree specifying elaborate arrangements for melting down silver dedications to make a new silver oinochoe to be suitably inscribed, 221/20 B.C. - - - IG II² 839; S. Dow, op. cit, 34-47; SEG 35 (1985) no. 94; for the 55 inscriptions attributed to the Broken-Alpha mason, see S. Dow, op. cit., 36</p>
<p :class="{'activeClass': idClicked == NP2109 }" id="NP2109" >15* Decree, ca. 150-125 B.C. - - - IG II² 840; SEG 25 (1971) no. 127</p>
<p :class="{'activeClass': idClicked == NP2110 }" id="NP2110" >16* Dedication with relief eye - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205968" target="_blank">Agora I 5968</a>; Meritt, B. D. 1948, 39 no. 26, pl. 12; <JBcom>[although B.D. Meritt takes it for granted that this dedication goes with nos. Hro14*-15* above, it may come from the heroon of The Iatros near the Theseion: Apollon. Vit. Aischin. 1-2]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2111 }" id="NP2111" >17* Contract for leasing the garden of an unnamed heroon, 333/332 B.C., 82 Evripidou St. in north Athens (Plan 2 delta—4/5) EM 13051; <JBcom>[implausibly]</JBcom> assigned to Heros Iatros, SEG 24 (1969) no. 203</p>
<p :class="{'activeClass': idClicked == NP2112 }" id="NP2112" >18* Heros Kalamites, sanctuary: D. 18.129; R. E. Wycherley, “Lenaion”, Hesperia 34 (1965) 73-76; LIMC VIII, 1997, 663, s.v. Kalamites (K. Clinton)</p>
<p :class="{'activeClass': idClicked == NP2113 }" id="NP2113" >19* Heros Kallistephanos, cobbler's dedication with a relief of the cobbler's workshop, 400-350 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207396" target="_blank">Agora I 7396</a>; SEG 39 (1989) no, 235; Kearns, E. 1989, 11; Agora Guide 1990, 211-213, 281 no. 211, fig. 134</p>
<p :class="{'activeClass': idClicked == NP2114 }" id="NP2114" >20* Heros Lykos, sanctuary at the lawcourts: Ar. V. 387-394; Agora XXVIII, 95, 188-191, testimonia nos. 176-185; see Court near Lykos</p>
<p :class="{'activeClass': idClicked == NP2115 }" id="NP2115" >21* Heros Mounichos (one of the 42 eponymous heroes of the ephebes), dedication by ephebes for victory in the torch race, 333/2 B.C., found in dumped fill south of the Pompeion propylon - - - Kerameikos I 64; Chr. Habicht, “Neue Inschriften aus dem Kerameikos,”, AM 76 (1961) 143-146, Beilage 75 = SEG 21 (1965) no. 680; Mounichos was perhaps the son of Akamas and Laodike, Kron, U. 1976,143 note 649, 164</p>
<p :class="{'activeClass': idClicked == NP2116 }" id="NP2116" >22* Heros par' Hagne?, cult: IG I³ 234 lines 11?, 20? = IG I² 840 lines 11 and 20; SEG 25 (1971) no. 7. Corrigendum: the inscription is too fragmentary to be of service; this entry should be disregarded</p>
<p :class="{'activeClass': idClicked == NP2117 }" id="NP2117" >23* Heros Strategos, relief dedication, ca. 200 B.C.?, east of the Metroon in a late Roman level - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20147" target="_blank">Agora I 147</a>; Meritt, B. D. 1946, 221 no. 48 ph.; Agora III, 176 no. 579; “could come from almost any period,” Tracy, S. V. 1990, 249; <JBcom>[the missing relief is, perhaps, a banqueting hero]</JBcom>. Cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM317">317</a></p>
<p :class="{'activeClass': idClicked == NP2118 }" id="NP2118" >24* Heros Theodoros, hero on horseback relief, Asklepieion - - - NM 1401, IG II² 4712; Schuchhardt, W.-H. 1978, 93, fig. 14 See also: Index I under Heroes and Heroines</p>
<p :class="{'activeClass': idClicked == NP2119 }" id="NP2119" >HEROES, NAMES UNKNOWN: BANQUETING HERO RELIEFS</p>
<p :class="{'activeClass': idClicked == NP2120 }" id="NP2120" >25* Double relief Acr. 3013; Dentzer, J.-M. 1982, 327, 328, 358, 581 no. R 103, figs. 369-370</p>
<p :class="{'activeClass': idClicked == NP2121 }" id="NP2121" >26* Double relief - - - Acr. 2451; ibid., 358, 580 no. R 99, fig. 365</p>
<p :class="{'activeClass': idClicked == NP2122 }" id="NP2122" >27* Relief Acr. Museum, inv. number ?; Thonges-Stringaris, R. N. 1965, 81 no. 89</p>
<p :class="{'activeClass': idClicked == NP2123 }" id="NP2123" >28* Relief <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202638" target="_blank">Agora I 2638</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 29 (1960) 68 no. 119, pl. 20; Agora XVII, 1974, 185 no. 1046</p>
<p :class="{'activeClass': idClicked == NP2124 }" id="NP2124" >29* Relief - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20396" target="_blank">Agora S 396</a>; Dentzer, J.-M. 1982, 314, 582 no. R 119, fig. 383</p>
<p :class="{'activeClass': idClicked == NP2125 }" id="NP2125" >30* Relief - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%2071" target="_blank">Agora S 71</a>.3; ibid., 583 no. R 124, fig. 388</p>
<p :class="{'activeClass': idClicked == NP2126 }" id="NP2126" >31* Relief - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201630" target="_blank">Agora S 1630</a>; ibid. 1982, 584 no. R 139, fig. 405</p>
<p :class="{'activeClass': idClicked == NP2127 }" id="NP2127" >32* Relief NM 1510; Thonges-Stringaris, R. N. 1965, 78 no. 66, Beil. 10 no. 2</p>
<p :class="{'activeClass': idClicked == NP2128 }" id="NP2128" >33* Relief, Asklepieion NM 1512; Dentzer, J.-M. 1982, 585 no. R 146, fig. 412</p>
<p :class="{'activeClass': idClicked == NP2129 }" id="NP2129" >34* Relief, Asklepieion - - - NM 1517, IG II² 4924; ibid., 585 no. R 147, fig. 413. The fragmentary inscription mentions Tychai</p>
<p :class="{'activeClass': idClicked == NP2130 }" id="NP2130" >35* Relief of a banqueting hero heavily mutilated except for the inscription and horse protome left untouched with three crosses added in red, 1st c. B.C., reused as the cover slab of a Christian grave in the Asklepieion NM 2413, IG II² 4482; S. Koumanoudes, «Επί ιερέως Διοφάνους τού Άπολλωνίου Άζηνιέως,» Athenaion 5 (1876) 328 no. 9; Dentzer, J.-M. 1982, 586 no. R 159, fig. 425</p>
<p :class="{'activeClass': idClicked == NP2131 }" id="NP2131" >36* Relief, site of the Odeion of Herodes — Berlin inv. 818 = K 98; Blumel, C. 1966, 83 no. 99, fig. 132</p>
<p :class="{'activeClass': idClicked == NP2132 }" id="NP2132" >37* Relief, Akropolis west slope - - - NM 3527; Dentzer, J.-M. 1982, 590 no. R 192, fig. 450; Kaltsas, N. 2002, 231 no. 486</p>
<p :class="{'activeClass': idClicked == NP2133 }" id="NP2133" >38* Relief, northeast Athens, Vasil. Sophias Blvd. at the Old Age Home (Plan 2 epsilon—9?)- - - NM 3715; Dentzer, J.-M. 1982, 590 no. R 193, fig. 451</p>
<p :class="{'activeClass': idClicked == NP2134 }" id="NP2134" >39* Relief, northwest Athens, Leokoriou St. - - - Storeroom of the 3rd Ephor. (inv. no. not known); ibid., 590 no. R 197, fig. 455</p>
<p :class="{'activeClass': idClicked == NP2135 }" id="NP2135" >40* Relief, Aiolou St. opposite the church of Hag. Irini (Plan 2 epsilon—4, no. 88) - - - NM 3937; ibid., 590 no. R 196, fig. 454</p>
<p :class="{'activeClass': idClicked == NP2136 }" id="NP2136" >41* Relief, northwest Athens, west of Academy area - - - Storeroom of the 3rd Ephor., inv. number ?; ibid., 591 no. R 200, fig. 458</p>
<p :class="{'activeClass': idClicked == NP2137 }" id="NP2137" >42* Relief with remains of inscription below - - - Fitzwilliam inv. GR 16.1865; Budde L. and R. Nicholls 1964, 16-17 no. 36, pl. 8</p>
<p :class="{'activeClass': idClicked == NP2138 }" id="NP2138" >43* Relief with two heroes, from a house in the Plaka - - - Acr. 9716; J.-P. Michaud, “Chronique des fouilles en 1970,” BCH 95 (1971) 810, fig. 9; Dentzer, J.- M. 1982, 590 no. R 198:b</p>
<p :class="{'activeClass': idClicked == NP2139 }" id="NP2139" >44* Relief with two heroes, ca. 380 B.C., Tower of the Winds - - - Roman Agora inv. 117; ibid., 590 no. R 198:a, fig. 456 See also: Demos and Charites, dedication</p>
<p :class="{'activeClass': idClicked == NP2140 }" id="NP2140" >HERO ON HORSEBACK</p>
<p :class="{'activeClass': idClicked == NP2141 }" id="NP2141" >45* Relief, 3rd c. B.C.; Areopagus northwest slope NM 3531; Watzinger, C. 1901, 323-324 no. 4, fig. 16; LIMC VI 1992, 1040 no. 286, ph., s.v. Heros Equitans (V. Machaira)</p>
<p :class="{'activeClass': idClicked == NP2142 }" id="NP2142" >46* Relief with added Christian crosses, Areopagus north slope <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201197" target="_blank">Agora S 1197</a>; unpublished</p>
<p :class="{'activeClass': idClicked == NP2143 }" id="NP2143" >47* Libation relief with rider, heroine and tree with coiled snake, Akr. lower south slope, 10 Erechtheiou St. (Plan 2 eta—4)- - - 1st Ephoria, Rho Alpha 1244; V. Machaira, op. cit (45* above), 1042 no. 325, ph.</p>
<p :class="{'activeClass': idClicked == NP2144 }" id="NP2144" >HERO STANDING BY HORSE</p>
<p :class="{'activeClass': idClicked == NP2145 }" id="NP2145" >48* Libation relief, northeast of Akr., 7-9 Kekropos St. (Plan 1 eta—9)- - - 3rd Ephor. M 915; J.-P. Michaud, “Chronique des fouilles en 1970,” BCH 95 (1971) 821, fig. 31; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM192">192</a></p>
<p :class="{'activeClass': idClicked == NP2146 }" id="NP2146" >49* Relief with goddess or heroine - - - Berlin inv. 808 = K 113; Blümel, C. 1966, 80 no. 93, fig. 127</p>
<p :class="{'activeClass': idClicked == NP2147 }" id="NP2147" >HEROES, NAMES UNKNOWN (continued)</p>
<p :class="{'activeClass': idClicked == NP2148 }" id="NP2148" >50* Heroon, dedication, built into the Church of the Holy Apostles (Chr.Mon. CM30*) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206690" target="_blank">Agora I 6690</a>; unpublished See also: Index I under Heroes and Heroines</p>
<p :class="{'activeClass': idClicked == NP2149 }" id="NP2149" >HERSE, PANDROSOS, AGLAUROS, cult: Anecdota Graeca, 1914, 239 lines 8-9, s.v. Δειπνοφορία; Ferguson, W. S. 1944, 21; Kearns, E. 1989, 161-162</p>
<p :class="{'activeClass': idClicked == NP2150 }" id="NP2150" >HESPERIDES: see Orpheus and Eurydice Monument</p>
<p :class="{'activeClass': idClicked == NP2151 }" id="NP2151" >HESTIA</p>
<p :class="{'activeClass': idClicked == NP2152 }" id="NP2152" >Hearth in the Prytaneion: IG II² 1283 lines 6-7 (procession at the festival of Bendis allowed to start from the hearth in the Prytaneion); Agora III, nos. 569-570</p>
<p :class="{'activeClass': idClicked == NP2153 }" id="NP2153" >Statue at the Prytaneion: Paus. 1.18.3; M Gianaiulio, “Edifici pubblici e culti nelle nuove iscrizhione da Entella,” ASNP Xll,3 (1982) 948-949, 959, 960; SEG 32 (1982) no. 148</p>
<p :class="{'activeClass': idClicked == NP2154 }" id="NP2154" >Name of a street in Ankyle: Harp. s.v. τρικέφαλος; F GR HIST III B, 105, Philochoros 328 F 22 with suppl. vol. I, 313; I. Z. Tzifopoulos, “Hekate? Street: A Horos from Ήλιόκαστρο of Hermione,”, Horos 10-12 (1992-1998) 257-258; SEG 46 (1996) no. 368</p>
<p :class="{'activeClass': idClicked == NP2155 }" id="NP2155" >Hestia Bouiaia, hearth in the bouleuterion: Aeschin. II.45 with schol.; Agora III, 128-129 nos. 387-389, 391,397-8, 407; see Zeus Zeu17*</p>
<p :class="{'activeClass': idClicked == NP2156 }" id="NP2156" >Hestia of the Romans, priestess' theater seat IG II² 5102</p>
<p :class="{'activeClass': idClicked == NP2157 }" id="NP2157" >Hestia of the Romans, priestess' theater seat - - - IG II² 5145</p>
<p :class="{'activeClass': idClicked == NP2158 }" id="NP2158" >Hestia on the Akropolis and Livia and Julia, priestess' theater seat - - - IG ll25096</p>
<p :class="{'activeClass': idClicked == NP2159 }" id="NP2159" >Hestia, Apollo, Divi Augusti, Areopagus Council, Council of 600 and Demos, reused statue base dedicated by Philoxenos, son of the sculptor Agathokles, 1st c. A.C., built into a wall of the church of Kyra tou Kandili just northeast of the Lysikrates Mon. - - - IG II² 3185; SEG 48 (1998) no. 195 First use, base for statue of Epikleides, son of Archestratos - - - IG II² 3833</p>
<p :class="{'activeClass': idClicked == NP2160 }" id="NP2160" >HESYCHOS, hero of the genos Hesychidai who received a preliminary sacrifice of a ram at sacrifices for the Semnai, sanctuary outside of the Enneapylon: schol. S. OC 489; Iakovides, S. 1962, 236 nos. 33-34 (the testimonia); P. Friedlander, RE VIII, 1913, col. 1330-1331 (Hesychos); E. Wust, RE Suppl. VIII, 1956, col. 129 (Erinys)</p>
<p :class="{'activeClass': idClicked == NP2161 }" id="NP2161" >HETAIRA: see Aphrodite Aph31*</p>
<p :class="{'activeClass': idClicked == NP2162 }" id="NP2162" >HEUDANEMOI: see Eudanemoi</p>
<p :class="{'activeClass': idClicked == NP2163 }" id="NP2163" >HIERA ODOS: see Sacred Way</p>
<p :class="{'activeClass': idClicked == NP2164 }" id="NP2164" >HIERA SYKE, the sacred fig tree given to Phytalos by Demeter The tree itself: IG I³ 386 line 163; Cavanaugh, Μ. B. 1996, 194, 215</p>
<p :class="{'activeClass': idClicked == NP2165 }" id="NP2165" >Suburb named for the tree, along the Sacred Way: Philostr. VS 2.20.3; Judeich, W. 1931, 177 note 3, 411 See also: Phytalos </p>
<p :class="{'activeClass': idClicked == NP2166 }" id="NP2166" >HIEROKERYX, theater throne - - - IG II² 5043; Maass, M. 1972, 119, pl. 11 </p>
<p :class="{'activeClass': idClicked == NP2167 }" id="NP2167" >HIEROMNEMON, theater throne - - - IG II² 5033; Maass, M. 1972,115, pl. 9</p>
<p :class="{'activeClass': idClicked == NP2168 }" id="NP2168" >HIEROPHANT, theater throne IG II² 5053; Maass, M. 1972, 124, pl. 14 See also: Thesmophoroi</p>
<p :class="{'activeClass': idClicked == NP2169 }" id="NP2169" >HIEROPHANTID, theater seat - - - IG II² 5111; Clinton, K. 1974, 87</p>
<p :class="{'activeClass': idClicked == NP2170 }" id="NP2170" >HIKESIOS (= Zeus Hikesios) in the sanct. of Athena on the Akr.: Paus. 1.20.7; cf. graffiti on vases from summit of Mt. Parnes, SEG 33 (1983) no. 244 d-e</p>
<p :class="{'activeClass': idClicked == NP2171 }" id="NP2171" >HILL OF THE NYMPHS, in the Pnyx range, northwest of the Pnyx, present-day place-name: Kron, U. 1979, 63 with note 75, pls. 9-10</p>
<p :class="{'activeClass': idClicked == NP2172 }" id="NP2172" >For monuments and sites without clear-cut names on the Hill of The Nymphs, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM260">260</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM265">265</a></p>
<p :class="{'activeClass': idClicked == NP2173 }" id="NP2173" >HIPPADES GATE in the Themistoklean City Wall: IG II² 463 line 128; the gate east of the Dipylon Gate was <JBcom>[correctly]</JBcom> identified in Barbie du Bocage's plan of 1784, Travlos, J. 1971, fig. 53 = Gate at Leokoriou and Dipylou Sts.; <JBcom>[securely]</JBcom> identified as the Hippades Gate, F. Alabe, “Porte Equestre et Porte des Tertres, Note de Toponymie athenienne,” REG 100 (1987) 133-140 with testimonia 135-136; SEG 37 (1987) no. 85; Billot, M.-F. 1989, 702 no. 5; cf. Themistoklean City Wall Wal9*, Wal18*</p>
<p :class="{'activeClass': idClicked == NP2174 }" id="NP2174" >HIPPALEKTRYON WITH RIDER, sculpture, ca. 510-500 B.C., southeast corner of the Akr. - - -Acr. 597; Schrader, H. 1939, no. 319, pl. 145; LIMC V, 1990, 430 no. 79, ph., 431-432, s.v. Hippalektryon (D. Williams)</p>
<p :class="{'activeClass': idClicked == NP2175 }" id="NP2175" >HIPPARCHEION: IG II² 895 line 6; Chr. Habicht, “Neue Inschriften aus dem Kerameikos: I. Ehrendekrete für einen Hipparchen des Jahres 188/187,” AM 76 (1961) 127-141; SEG 21 (1965) nos. 435-436; "close to the Stoa of the Herms in the northwest corner of the Athenian Agora,” Bugh, G. R. 1988, 219-220; J. McK. Camp, “Excavations in the Athenian Agora: 1994 and 1995,” Hesperia 65 (1996) 252-259</p>
<p :class="{'activeClass': idClicked == NP2176 }" id="NP2176" >HIPPARCHOS, son of Charmos (or Timarchos), bronze statue on the Akropolis, melted down in 487/6 B.C. to make a stele listing names of aliterioi and traitors including Hipparchos himself: Lycurg. Leocr. 117-118; A. E. Raubitschek, “Leagros,” Hesperia 8 (1939) 155; W. R. Connor, “The Razing of the House in Greek Society,” ΤΑΡΑ 115 (1985) 92 with note 39</p>
<p :class="{'activeClass': idClicked == NP2177 }" id="NP2177" >HIPPARCHOS, son of Peisistratos</p>
<p :class="{'activeClass': idClicked == NP2178 }" id="NP2178" >Hipparchos' Wall built either "around the Academy" or "along the Pythia" according to the proverb writers: Hipparchos' Wall disassociated from the Academy, J. P. Lynch, “Hipparchos' Wall in the Academy at Athens: A Closer Look at the Tradition,” in Studies Sterling Dow 1984, 173-179 with testimonia; Billot, M.-F. 1989, 709 -716; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM449">449</a> See also: Asty milestones</p>
<p :class="{'activeClass': idClicked == NP2179 }" id="NP2179" >HIPPARCHS' DEDICATION on the Akropolis, original inscription and two copies, one copy on the original base, the second copy on a later base: Raubitschek, A. E. 1949, 146-152, 461, 518-519, ph.; Bugh, G. R. 1988, 45-51</p>
<p :class="{'activeClass': idClicked == NP2180 }" id="NP2180" >Original base: 1st use, bronze group of a man leading a horse by Lykios son of Myron, dedicated by Pronapes, Xenophon and Lakedaimonios, ca. 457-430 B.C. (?) -  - - IG I³ 511 = IG I² 400 la; Beschi, L. 1967/68, 528-531, fig. 13; Bugh, G. R. 1988, 45-52; SEG 38 (1988) no. 18</p>
<p :class="{'activeClass': idClicked == NP2181 }" id="NP2181" >2nd use, early 1st c. A.C., with the inscription copied upside down on the back of the base, on the anta by the Athena Nike terrace stair - - - IG I² 4001b; Paus. 1.22.4</p>
<p :class="{'activeClass': idClicked == NP2182 }" id="NP2182" >Rededication to Germanicus Caesar carved on the north face of the anta by the Athena Nike terrace stair - - - IG II² 3260; Blanck, H. 1969, 82-3; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 72; SEG 34 (1984) no. 179</p>
<p :class="{'activeClass': idClicked == NP2183 }" id="NP2183" >Later base, 1st c. A.C., with the second copy of the hipparchs' dedication on the opposite anta IG I² 400 II; statue of Drusus, son of Tiberius, Vermeule, C. C. 1968, 430</p>
<p :class="{'activeClass': idClicked == NP2184 }" id="NP2184" >HIPPARETE, daughter of Alkibiades of Skambonidai, granddaughter of the famous Alkibiades, trapeza, end of 4th c. B.C., on top of a family grave mound, perhaps of the Alkmeonidai, west of the Tritopatreion IG II² 7400; Knigge, U. 1991, 109 and fig. 165 (where the little rectangle slightly above and to the right of no. 17 marks the site of Hipparete's trapeza ); Davies, J. K. 1971, 21-22, Table I; Kerameikos IX, 10-11 note 26</p>
<p :class="{'activeClass': idClicked == NP2185 }" id="NP2185" >HIPPIA, HIPPIOS: see Kolonos Hippios; Poseidon Hippios and Athena Hippia</p>
<p :class="{'activeClass': idClicked == NP2186 }" id="NP2186" >HIPPOKRATES' PALAESTRA where Isokrates heard about the battle of Chaironeia: Plu. Moralia 837 E; Delorme, J. 1960, 60-61, 261 note 1</p>
<p :class="{'activeClass': idClicked == NP2187 }" id="NP2187" >HIPPOLYTA the Amazon, grave stele by the sanctuary of Ge (Olympia): Plu. Thes. 27.4</p>
<p :class="{'activeClass': idClicked == NP2188 }" id="NP2188" >HIPPOLYTOS: see Antiope; Aphrodite Aph26*, Aph28*</p>
<p :class="{'activeClass': idClicked == NP2189 }" id="NP2189" >HIPPOMACHIA GATE: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM377">377</a></p>
<p :class="{'activeClass': idClicked == NP2190 }" id="NP2190" >HIPPONIKOS, son of Strouthon, choregic victory tripod, 5th c. B.C.: AP XII 1.28; Further Greek Epigrams 1981, 11-15</p>
<p :class="{'activeClass': idClicked == NP2191 }" id="NP2191" >HIPPOTHOON, son of Poseidon and Alope, Eponymous Hero statue: Paus. 1.5.2; Kron, U. 1976, 177-187</p>
<p :class="{'activeClass': idClicked == NP2192 }" id="NP2192" >HOMER</p>
<p :class="{'activeClass': idClicked == NP2193 }" id="NP2193" >Portrait at the temple of the Ptolemies: Lucian, Dem. Enc. 2</p>
<p :class="{'activeClass': idClicked == NP2194 }" id="NP2194" >Portrait in the theater, early 4th c. A.C. - - - NM 626; Richter, G. M. A. 1965, I, 49 no. 13; Kaltsas, N. 2002, 372 no. 793, ph. See also: Iliad and Odyssey; Nicanor; Theodektes</p>
<p :class="{'activeClass': idClicked == NP2195 }" id="NP2195" >HOMONIA</p>
<p :class="{'activeClass': idClicked == NP2196 }" id="NP2196" >Cult: Alexis frag. 244, in The Fragments of Attic Comedy, ed. J. M. Edmonds, Leiden, 1959, II, 490 = Ath. XI, 502b; Theriault, G. 1996, 118-122; SEG 46 (1996) no. 2292</p>
<p :class="{'activeClass': idClicked == NP2197 }" id="NP2197" >Altar of Homonia: Benjamin, A. S. 1963, 70 no. 73, pl. 28</p>
<p :class="{'activeClass': idClicked == NP2198 }" id="NP2198" >First use: altar of Homonia, 3rd c. B.C. - - - IG II² 4985; G. Theriault, 1996, 118</p>
<p :class="{'activeClass': idClicked == NP2199 }" id="NP2199" >Second use: altar of Hadrian Olympios IG II² 3358</p>
<p :class="{'activeClass': idClicked == NP2200 }" id="NP2200" >HORAI, sanctuary: Ath. II.38 C, XIV.656 A; F GR HIST III B,99, 148, Philochoros 328 F 5 and F 173 with suppl. vol. I, 268-271, 546; cf. Paus. 9.35.2; see Dionysos Dio52*; Helios and Horai</p>
<p :class="{'activeClass': idClicked == NP2201 }" id="NP2201" >HORDEONIUS LOLLIANUS, P. (PIR, 2nd ed., 203), sophist; Byrne, S. G. 2003, 300</p>
<p :class="{'activeClass': idClicked == NP2202 }" id="NP2202" >Statue base, ca. 140 A.D., west of the Propylaia - - - IG II² 4211; SEG 13 (1956) no. 63</p>
<p :class="{'activeClass': idClicked == NP2203 }" id="NP2203" >Statue in the Agora and another statue in a grove which he planted: Philostr. VS 1.23.2; Agora III, 703</p>
<p :class="{'activeClass': idClicked == NP2204 }" id="NP2204" >HORKOMOSION, where Theseus and the Amazons made a pact, beside the Theseion: Plu. Thes. 27.5; F GR HIST III B, suppl. vol. I, 78 LL. 30-31; <JBcom>[on the Akropolis North Slope]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2205 }" id="NP2205" >HORME, personification of zeal or effort, altar: Paus. 1.17.1, possibly = IG II² 4734, small round altar</p>
<p :class="{'activeClass': idClicked == NP2206 }" id="NP2206" >HORSE-AND-KORE, name of the site where Hippomenes' house once stood: Aeschin. 1.182; A. N. Oikonomides, “The Site of the Horse and the Girl,” Ancient World 3 (1980) 47-48</p>
<p :class="{'activeClass': idClicked == NP2207 }" id="NP2207" >HORUS</p>
<p :class="{'activeClass': idClicked == NP2208 }" id="NP2208" >Bronze statuette from Egypt, 6th c. B.C., Akropolis - - - NM 6591; J. D. S. Pendlebury, Aegyptiaca, Cambridge, 1930, 78 no. 159, pl. IV</p>
<p :class="{'activeClass': idClicked == NP2209 }" id="NP2209" >Cult, mentioned in a dedication to Osiris, ca. 220 A.D., found on Ermou St.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204347" target="_blank">Agora I 4347</a> line 6; Vidman, L. 1969, 19 no. 33B See also: Isis, arula</p>
<p :class="{'activeClass': idClicked == NP2210 }" id="NP2210" >HYAKINTHIDES, daughters of Erechtheus, sanctuary: Mikalson, J. D. 1976, 144-153; Kearns, E. 1989, 51, 59, 60-63, 201-202; Petterson, M. 1992, 35-36 Called abaton temenos: E. Erech. col. VII line 87; C. Austin, Nova Fragmenta Euripidea, Berlin, 1968, 38 Called Hyakinthion: IG II² 1035 line 52</p>
<p :class="{'activeClass': idClicked == NP2211 }" id="NP2211" >HYAKINTHOS, hill where Erechtheus' daughters were sacrificed: F GR HIST III B, 79-80, Phanodemos 325 F 4 with suppl. vol.I 178-180; H. von Geisau, KP, 2 1967, 1254, (s.v. Hyakinthides); Petterson, M. 1992, 35; SEG 42 (1992) no. 109</p>
<p :class="{'activeClass': idClicked == NP2212 }" id="NP2212" >HYBRIS, stone of, ("Rock of Outrage . . . where the defendant stood") on the Areopagus: Paus.l.28.5; H. von Geisau, RE Suppl. IX, 1962, col. 1898-1899, (Hybris); Agora XXVIII, 46</p>
<p :class="{'activeClass': idClicked == NP2213 }" id="NP2213" >HYDROPHOROS, bronze kore dedicated in Athens by Themistokles as epistates of the water supply in Athens before 480 B.C. and rededicated by the Lydians in the Metroon at Sardis: Plu. Them. 31.1; Η. T. Wade-Gery, “Themistokles' Archonship,” BSA 37 (1936-7) 264; Keesling, C. M. 2003, 113</p>
<p :class="{'activeClass': idClicked == NP2214 }" id="NP2214" >HYGIEIA: J. Tambornino, PE IX,1914, col. 93-97 (Hygieia)</p>
<p :class="{'activeClass': idClicked == NP2215 }" id="NP2215" >Altar, Roman pd., Church of Haghia Kyra (Chr. Mon. CM46*) IG II² 4539; S. Dow, “Healing Deities on Pentelikon,” Phoenix 36 (1982) 321</p>
<p :class="{'activeClass': idClicked == NP2216 }" id="NP2216" >Statue of Hygieia, daughter of Asklepios, on the Akr.: Paus. 1.23.4, seen by Pausanias together with Athena Hygieia (Athena Ath68*)</p>
<p :class="{'activeClass': idClicked == NP2217 }" id="NP2217" >Dedication of a phiale at the statue of Hygieia, ca. 333 B.C.?, by Olympias, the mother of Alexander the Great: Hyp. Eux. 19; perhaps dedicated in 333 B.C. after Alexander's recovery in Cilicia, Schenkungen hellenistischer Herrscher, Part 1, 1995, 17, and Part II, vol. I, 2000, 46 note 101, 56</p>
<p :class="{'activeClass': idClicked == NP2218 }" id="NP2218" >Dedication on a cylindrical base, before 350 B.C., Akropolis South Slope - - - 1st Ephor. NK 105; G. Daux, “Chronique des fouilles 1965,” BCH 90 (1966) 722, 725, fig. 3; SEG 41 (1991) no. 166 See also: Amphiaraos (2); Amynos; Asklepios Ask2*, Ask8*, Ask8**, Ask19**, Ask20*, Ask23*, Ask25*, Ask26*, Ask31*, Ask33*, Ask36**, Ask37*, Ask39*, Ask42*-44*, Ask46*, Ask47*; Athena Ath65**-69*; Livia</p>
<p :class="{'activeClass': idClicked == NP2219 }" id="NP2219" >HYMNETRIAI PHILIDIOU, theater seat - - - IG II² 5128 </p>
<p :class="{'activeClass': idClicked == NP2220 }" id="NP2220" >HYMNETRIAI: see Nymphe (?), theater seat</p>
<p :class="{'activeClass': idClicked == NP2221 }" id="NP2221" >HYPAPANTI WALL, Akropolis outwork, built between 1699 and 1738, protecting the Akropolis upper northwest slope and the western approach: G. S. Dontas, “Συντηρησις αρχαίων,» Deltion 25 (1970) B1 Chr., 26-27, fig. 1, pl. 38:a-b; Tanoulas, T. 1997, Vol. I, 70, 253 and Vol. II, drawings 43, 44 no. 3, 45 no. 5; Marx, P. M. 2001, 232-250, figs. 5,8, 9, 11-13</p>
<p :class="{'activeClass': idClicked == NP2222 }" id="NP2222" >The Liondari Tower built on the remains of the church of Hag. Nikolaos or Seraphim (Plan 1 epsilon—5, no. 105): Biris, K, 1971, 62; Tanoulas, T. 1997, Vol. II figs. 37, 67</p>
<p :class="{'activeClass': idClicked == NP2223 }" id="NP2223" >HYPATOS: see Zeus Zeu34*, Zeu35*</p>
<p :class="{'activeClass': idClicked == NP2224 }" id="NP2224" >HYPEREIDES: Grave in which H. was secretly buried in the family plot outside of the Hippades Gate: Plu. Moralia 849 C-D</p>
<p :class="{'activeClass': idClicked == NP2225 }" id="NP2225" >Statues: POxy no. 1800, frag. 8 lines 30-33; Richter, G. M. A. 1972, I, 150 </p>
<p :class="{'activeClass': idClicked == NP2226 }" id="NP2226" >HYPNOS: see Asklepios Ask8*</p>
<p :class="{'activeClass': idClicked == NP2227 }" id="NP2227" >HYPOAKRAIOS: see Apollo Apo5*</p>
<p :class="{'activeClass': idClicked == NP2228 }" id="NP2228" >HYPODEKTES, sanctuary recorded in a decree of the orgeones to be set up in the sanctuary beside the statue of the god, late 4th c. B.C., western Athens IG II²  2501; Ferguson, W. S. 1944, 81-82; Kearns, E. 1989, 75, 125, 202; <JBcom>[commentators understand Hypodektes as a hero, although in the inscription he is a god]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2229 }" id="NP2229" >HYPSISTOS: see Zeus Zeu36*-48*</p>
<p :class="{'activeClass': idClicked == NP2230 }" id="NP2230" >HYRKANOS, high priest of the Jews, bronze statue in the precinct of Demos and Charites, 1st c. B.C.: Josephus, AJ XIV.153; Agora III, no. 125; cf. IG II² 4700 restored as a dedication to Hyrkanos by A. N. Oikonomides, SEG 21 (1965) no. 799; Monaco, M. C. 2001, 127</p>
<p :class="{'activeClass': idClicked == NP2231 }" id="NP2231" >IAKCHOS: E. Kearns, 1989, 170-171 Iakchagogos priest, theatre throne IG II² 5044; Maass, M. 1972, 119, pl. 12; Walker, S. 1974a, 254</p>
<p :class="{'activeClass': idClicked == NP2232 }" id="NP2232" >Iakcheion: Plu. Arist. 27.3; F. Graf, Eleusis und die orphische Dichtung Athens ..., Berlin, 1974, 49 note 43, 55 note 22 See also: Demeter and Kore and Iakchos</p>
<p :class="{'activeClass': idClicked == NP2233 }" id="NP2233" >IAKOBOS, doctor from Damaskos who advised Proklos on diet, statue in Athens seen by Damaskios: Athanassiadi, P. 1999, 212 Fragment 84 I</p>
<p :class="{'activeClass': idClicked == NP2234 }" id="NP2234" >IAMBLICHOS, son of Himerios, philosopher, reused herm with epigrams praising him for fortifying Athens, after 391 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203542" target="_blank">Agora I 3542</a>; Agora XXIV, 51, pl. 45:a; SEG 38 (1988) no. 184; Sironen, E. 1997, 72-74 no. 15</p>
<p :class="{'activeClass': idClicked == NP2235 }" id="NP2235" >IANISKOS: see Asklepios Ask22*</p>
<p :class="{'activeClass': idClicked == NP2236 }" id="NP2236" >IASO: see Asklepios Ask22*</p>
<p :class="{'activeClass': idClicked == NP2237 }" id="NP2237" >IATROS: see Heroes Hro13*-16*; Toxaris</p>
<p :class="{'activeClass': idClicked == NP2238 }" id="NP2238" >IKRIA: Grandstands in the Agora: Agora III, nos. 524-5, 528, 721, 723, 726-727; Kolb, F. 1981, 122 s.v. ikria Ikria mentioned in IG I³ 84 line 28: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM559">559</a></p>
<p :class="{'activeClass': idClicked == NP2239 }" id="NP2239" >Grandstands for spectators at torch races: see Part II no 565</p>
<p :class="{'activeClass': idClicked == NP2240 }" id="NP2240" >ILIAD, (HOMER), ODYSSEY, statue group, early 2nd c. A.C., southwest corner of the Stoa of Attalos - - - formerly NM 311-312, now <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202038" target="_blank">Agora S 2038-2039</a>, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206628" target="_blank">Agora I 6628</a>, IG II² 4313; Travlos, J. 1971, 233, 234, figs. 308-310; Agora XIV,115, 232, pl. 62:d (Iliad inscribed base), pl. 63; SEG 37 (1987) no. 153, reporting that according to E. Kapetanopoulos in Prometheus 13 (1987) 1-10 <JBcom>[not seen]</JBcom> the Iliad epigram refers to C. Julius Nicanor, the New Homer; <JBcom>[on the basis of a keen comparison convincingly argued that]</JBcom> the so-called Iliad statue does not belong to the group, Kirsten Seaman, “Personifications of the Iliad and the Odyssey in Hellenistic and Roman Art,” in Personification in the Greek World, E. Stafford and J. Herrin, eds, Aldershot: Ashgate. in press</p>
<p :class="{'activeClass': idClicked == NP2241 }" id="NP2241" >ILISSIAN MUSES: see Muses, Altar</p>
<p :class="{'activeClass': idClicked == NP2242 }" id="NP2242" >ILISSOS RIVER: Paus. 1.19.5-6 (6 mentions); Judeich, W. 1931, 48-49, 127 note 2, 205; Camp, J. McK. 1980, testimonia nos. 4, 18, 26, 29, 32, 37; Conwell, D. H. 1993, 209-213</p>
<p :class="{'activeClass': idClicked == NP2243 }" id="NP2243" >Cult of Ilissos: IG I³ 383 line 141</p>
<p :class="{'activeClass': idClicked == NP2244 }" id="NP2244" >Bridge at the Stadium, 2nd c. A.C.: Gasparri, C. 1974/75, 315 note 8, 317-320 passim, 324, 329, 331, figs. 2-6, 10-12, 14 (C.G.); Bougia, P. 1966,159-171, pls.16-30</p>
<p :class="{'activeClass': idClicked == NP2245 }" id="NP2245" >Bridge, 19th c. or earlier, above Kallirrhoe: water colour, 1842, by Pauline Lady Trevelyan, BM Prints and Drawings Department</p>
<p :class="{'activeClass': idClicked == NP2246 }" id="NP2246" >Place where Pausanias crossed the Ilissos into Agrai: Paus. 1.19.6; <JBcom>[since Pausanias did not mention Kallirrhoe spring and placed the Enneakrounos in the Agora, he probably crossed the Ilissos some distance upstream from Kallirrhoe which, possibly, had temporarily ceased to deliver water during a drought; cf. Ge Karpophoros]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2247 }" id="NP2247" >Ilissos crossing just above Kallirrhoe: Travlos, J. 1971,112, fig.154 no. 156, fig. 155, fig. 219 (just southeast of Gate X), fig. 379 no. 156, fig. 381</p>
<p :class="{'activeClass': idClicked == NP2248 }" id="NP2248" >Dam: Judeich, W. 1931, 205</p>
<p :class="{'activeClass': idClicked == NP2249 }" id="NP2249" >IMMAROS (= Immarados), son of Eumolpos, grave in the Eleusinion: Clem. Al. Protr. III.39; Agora III, nos. 194-5; Kearns, E. 1989,114-115, 172; Agora XXXI, 3, 51-52; see also: Erechtheus and Eumolpos</p>
<p :class="{'activeClass': idClicked == NP2250 }" id="NP2250" >IO, the daughter of Inachos, statue by Deinomenes on the Akropolis: Paus. 1.25.1; LIMC V, 1990, 671 no. 83, s.v. Ιο I (N. Yalouris)</p>
<p :class="{'activeClass': idClicked == NP2251 }" id="NP2251" >IOBAKCHOI: see Bakcheion</p>
<p :class="{'activeClass': idClicked == NP2252 }" id="NP2252" >IOLAOS, altar in Herakles' sanct. called Kynosarges: Paus. 1.19.3; Kearns, E. 1989, 172-173</p>
<p :class="{'activeClass': idClicked == NP2253 }" id="NP2253" >IPHIKRATES, son of Timotheus</p>
<p :class="{'activeClass': idClicked == NP2254 }" id="NP2254" >Statue at the Parthenon entrance, ca. 389 B.C.: Paus. 1.24.7; Gauthier, Ph. 1985, 26, 97-98, 125, 177-180; cf. Agora III, nos. 693,702</p>
<p :class="{'activeClass': idClicked == NP2255 }" id="NP2255" >Gilded shield in the Erechtheion, dedicated by Iphikrates: IG II² 1487 line 40; Harris, D. 1995, 207 no. 6, 220-221, 227, 233, 238, 247-248 no. 57</p>
<p :class="{'activeClass': idClicked == NP2256 }" id="NP2256" >IRIS: see Athena Ath8*</p>
<p :class="{'activeClass': idClicked == NP2257 }" id="NP2257" >ISIS: Iseion, mentioned thrice in a dedication to Osiris, ca. 220 A.D., found on Ermou St.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204347" target="_blank">Agora I 4347</a>; Vidman, L. 1969, 19 no. 33:b <br>Naiskos facing south, Akr. South Slope west of the Asklepieion: IG II² 4771; Walker, S. 1979a, 243-257, figs. 1-3, pls. 30-32 <br>Originally built for Aphrodite at Hippolytos: see Aphrodite Aph6*, Aph27* Given over to Isis, perhaps when Antony and Cleopatra visited Athens: Aleshire, S. B. 1989, 22-23 note 4; see Hermes Hrm35* <br>Rebuilt and rededicated to Isis ca. 127 A.D.: IG II² 4771 <br>Cult statue: IG II² 4771 lines 4-6; Walker, S. 1979a, 247 no. 6, 252-253</p>
<p :class="{'activeClass': idClicked == NP2258 }" id="NP2258" >Fragment of colossal statue of Isis made of dark Eleusinian limestone, over 3 m. tall, ca. 40-50 A.D., found in the so-called House of Proklos on the Akr. S. Slope (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM165">165</a>) - - - 1st Ephor. NAM 40; Walters, E. J. 1988, 63; <JBcom>[almost certainly from the cult statue of the Akr. South Slope Iseion]</JBcom> cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM149">149</a></p>
<p :class="{'activeClass': idClicked == NP2259 }" id="NP2259" >Arula, dedication, with relief of a hawk (Horus), 3rd c. A.C. Roman Agora inv. 765; S. N. Koumanoudes, «Έπιγραφαι έξ Άθηνών,» Deltion 25 (1970) A, 68-69 no. 24; SEG 35 (1985) no. 145</p>
<p :class="{'activeClass': idClicked == NP2260 }" id="NP2260" >Dedication found near the Metropolis (Plan 1 beta/gamma—8) — IG II² 4732; Vidman, L. 1969, 10 no. 12</p>
<p :class="{'activeClass': idClicked == NP2261 }" id="NP2261" >Portrait of a priest of Isis, mid-1st c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20333" target="_blank">Agora S 333</a>; Agora I, no. 3, pl. 3</p>
<p :class="{'activeClass': idClicked == NP2262 }" id="NP2262" >Grave of an initiate of Isis with a silver sistrum and coin of Constantine II (337-340), Parliament Bldg. site at the south end (Plan 2 epsilon/zeta—6): L. Ross, Kunst-Blatt no. 56, 14 Juli 1836; Walters, E. J. 1988, 57 note 216 See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM192">192</a>; see SEG 50 (2000) no. 211 for grave reliefs of women in the dress of Isis</p>
<p :class="{'activeClass': idClicked == NP2263 }" id="NP2263" >ISIS DIKAIOSYNE, relief, 1st c. B.C., near Tower of the Winds — IG II² 4702; Vidman, L. 1969, 8 no. 6; possibly from Delos, Oliver, J. H. 1965, 294 note 10; Walters, E. J. 1988, 31 note 189, 32, pl. 1</p>
<p :class="{'activeClass': idClicked == NP2264 }" id="NP2264" >ISIS (PHARIA), inscr. stone mould frag, for a circular plaque, 150-200 A.D.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20527" target="_blank">Agora ST 527</a>; E. R. Williams, “Isis Pelagia and a Roman Marble Matrix from the Athenian Agora,” Hesperia 54 (1985) 109-119, pl. 21 = SEG 35 (1985) no. 234</p>
<p :class="{'activeClass': idClicked == NP2265 }" id="NP2265" >ISIS TAPOSEIRIAS: IG II² 1950 line 13; Vidman, L. 1969, 16-17 no. 30; SEG 42 (1992) no. 1811</p>
<p :class="{'activeClass': idClicked == NP2266 }" id="NP2266" >ISIS AND ANUBIS, dedication on cylindrical base, 133/132 B.C., 4 Xenophontos St. near Amalias Ave. (Plan 1 delta—12) — SEG 24 (1969) no. 225; Stavropoullos, Ph.D. 1965, «29. Ξενοφώντος άριθ. 4,» 97, pl. 55:b; Guarducci, M. 1967-1978, IV, 205-6, fig. 59</p>
<p :class="{'activeClass': idClicked == NP2267 }" id="NP2267" >ISIS and THE EGYPTIAN GODS, dedication of torches — IG II² 4733; Vidman, L. 1969, 11 no. 15</p>
<p :class="{'activeClass': idClicked == NP2268 }" id="NP2268" >ISIS, SARAPIS, ANUBIS, HARPOKRATES, dedication, 116-94 B.C., 11 Philotheis St. near the Metropolis (Plan 1 gamma—8) EM 649; Vidman, L. 1969, 7-8 no. 5; SEG 42 (1992) no. 157</p>
<p :class="{'activeClass': idClicked == NP2269 }" id="NP2269" >ISIS AND SARAPIS: see Sarapis (3)</p>
<p :class="{'activeClass': idClicked == NP2270 }" id="NP2270" >ISIS AND OTHERS: see Hermes Hrm35*</p>
<p :class="{'activeClass': idClicked == NP2271 }" id="NP2271" >ISLAMIC AND OTTOMAN MONUMENTS</p>
<p :class="{'activeClass': idClicked == NP2272 }" id="NP2272" >Mendreses, Theological Seminary, 1721 to 1914, north of the Tower of the Winds at the intersection of Aiolou and Pelopida Sts. (Plan 1 gamma—6, no. 115): EMME. II, 1929, 119, figs. 149-152</p>
<p :class="{'activeClass': idClicked == NP2273 }" id="NP2273" >Mosque with Kufic Arabic inscr., 10th-11th c. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203837" target="_blank">Agora I 3837</a>+; G. C. Miles, “The Arab Mosque in Athens,” Hesperia 25 (1956) 329-344, figs. 1-2, pl. 49</p>
<p :class="{'activeClass': idClicked == NP2274 }" id="NP2274" >First mosque in the Parthenon, 1460-1687, no construction except for the minaret added on top of the Frankish Tower: M. Korres, “The History of the Acropolis Monuments,” in Acropolis Restoration 1994, 49</p>
<p :class="{'activeClass': idClicked == NP2275 }" id="NP2275" >Second mosque in the Parthenon cella, built in 1699 or later, collapsed in 1842: drawings by Sir William Gell, Sketchbook 8 page 5 and Sketchbook 13 LB 63, LB 64, LB 78, particularly LB 79, in the Prints and Drawings Dept., BM London; for a view by Christian Hansen in 1836, see Mallouchou-Tufano, “The History of Interventions on the Acropolis,” in Acropolis Restoration 1994, 68; K. S. Pittakis, AE 1842, 533 no. 895; EMME I, 1927, 35; II, 1929, 121; Travlos, J. 1971, 445, figs. 577-578; Tanoulas, T. 1987, 442 note 57; from 1835-1842 the mosque served as a kind of storeroom and, in a way, as the first Acropolis Museum</p>
<p :class="{'activeClass': idClicked == NP2276 }" id="NP2276" >Mosque at Monastiraki, Tzami tou Kato Syntrivianou, built in 1759 (Plan 1 beta—5, no. 114): EMME I, 1927, 20; II, 1929, 117, figs. 147-148</p>
<p :class="{'activeClass': idClicked == NP2277 }" id="NP2277" >Mosque in the Roman Agora, Fetichie Tzami (Tzami tou Staropazarou), 17th-18th c. (Plan 1 gamma—5/6, no. 113): EMME II, 1929, 117, figs. 145-146; this mosque, formerly dated to the late 15th c., is now thought to be considerably later</p>
<p :class="{'activeClass': idClicked == NP2278 }" id="NP2278" >Mosque (originally a church) below the southwest corner of the Beule Gate: EMME II, 1929, 91; Tanoulas, T. 1987, 433</p>
<p :class="{'activeClass': idClicked == NP2279 }" id="NP2279" >Mosque southwest of Plateia Syntagmatos, the Yeni Mosque, in the block bounded by Voulis, Nikodimou, Thoukydidou, and Apollonos Sts (Plan 1 gamma/delta—9/10): watercolour by L. Dupre, 1819, Benaki Museum no. 26463</p>
<p :class="{'activeClass': idClicked == NP2280 }" id="NP2280" >Turkish Bath, south of the Parthenon: Bundgaard, J. A. 1974a, 10, pl. 58 no. 1</p>
<p :class="{'activeClass': idClicked == NP2281 }" id="NP2281" >Turkish Fountain House 50 m. northeast of the Klepsydra, built in the first half of the 18th c., in use until 1822; Shear, T. L. 1940, 296-297</p>
<p :class="{'activeClass': idClicked == NP2282 }" id="NP2282" >Turkish graveyard, Akr. West Slope from the southern foot of the Areopagus to the Odeion of Herodes Atticus: E.Dodwell, Tour, 1819, vol. I, 305; until recently some of the gravestones were stored on the Mnesiklean terrace at the base of the Propylaia north wing</p>
<p :class="{'activeClass': idClicked == NP2283 }" id="NP2283" >Turkish gravestone <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206095" target="_blank">Agora I 6095</a>; unpublished</p>
<p :class="{'activeClass': idClicked == NP2284 }" id="NP2284" >Turkish Spring House in northeast Athens <JBcom>[perhaps on the Ilissos right bank]</JBcom>: watercolour by Carl Rottman, München, Graphische Sammlung inv. no. 21360 Z; Erika Bierhaus-Rodiger, Carl Rottman 1797-1850, München, 1978, 321 no. 424 with photo</p>
<p :class="{'activeClass': idClicked == NP2285 }" id="NP2285" >Voivodalike, residence and headquarters of the Voivode, governor of Athens, in the western part of Hadrian's Library, demolished in 1835: Y. Knithakis, F. Malouchou, Y. Tinginaka, "The Voevodaliki of Athens,” Selected specimens of Greek Domestic Architecture during the Ottoman Period, National Technical Univ., Athens, 1986, 107-124, figs. 1-23 (in Greek with a summary in English)</p>
<p :class="{'activeClass': idClicked == NP2286 }" id="NP2286" >ISODAITES, god originating in Thrace, cult introduced into Athens by Phryne after 371 B.C.: Harpocration s.v.; Simms, R. R. 1985, 159-165</p>
<p :class="{'activeClass': idClicked == NP2287 }" id="NP2287" >ISOKRATES: School near the Lykeion Gymnasium: A. Westermann, ed., Vitae Oratorum, IV. Isocratis lines 108-109; Lynch, J. P. 1972, 53-54</p>
<p :class="{'activeClass': idClicked == NP2288 }" id="NP2288" >Grave precinct of Isokrates and his family outside the Diomeian Gate near Kynosarges on the hill to the left: Plu. Moralia 838 B-D; Scholl, A. 1994, 240-252; Billot, M.-F. 1992, 120, 129, 131 <br>Isokrates' grave marked by a siren on a column ca. 9 m. high, and a trapeza with reliefs of Isokrates, Gorgias, poets and teachers <br>Six trapezai for members of Isokrates' family</p>
<p :class="{'activeClass': idClicked == NP2289 }" id="NP2289" >Painted portrait in the Pompeion: Plu. Moralia 839 C</p>
<p :class="{'activeClass': idClicked == NP2290 }" id="NP2290" >Statue in the Olympieion precinct: Paus. 1.18.8; dedicated to Zeus by Isokrates' adopted son, the tragedian Aphareus, Plu. Moralia 839 B; Further Greek Epigrams 1981,16</p>
<p :class="{'activeClass': idClicked == NP2291 }" id="NP2291" >Bronze equestrian statue on the Akr.: Plu. Moralia 839 C</p>
<p :class="{'activeClass': idClicked == NP2292 }" id="NP2292" >Bronze statue by Leochares dedicated by Timotheos either in the Eleusinion at Athens or in Eleusis: Plu. Moralia 838 D; Further Greek Epigrams 1981,429 no.123</p>
<p :class="{'activeClass': idClicked == NP2293 }" id="NP2293" >Statue by Boutes: Academicorum philosophorum index Herculanenesis, Philodemos, col. 2 lines 4-31; Konrad Gaiser, "Philochoros über zwei Statuen in Athen,” in Festschrift fur U. Hausmann 1982, 91-100; cf. Sokrates, statue by Boutes</p>
<p :class="{'activeClass': idClicked == NP2294 }" id="NP2294" >ISOKRATES' MOTHER, Hedyto, statue later relabelled, on the Akr. near Hygieia: Plu. Moralia 839 D; Blanck, H. 1969,15, 24</p>
<p :class="{'activeClass': idClicked == NP2295 }" id="NP2295" >ISTHMONIKOS' BATH, predating 418 B.C., outside the City Wall: IG I³ 84 line 37. An irrigation channel providing water for the olive trees in the temenos of Neleus and Basile passed through a gate in the Themistoklean City Wall at this bath; see Itonian Gate; Kodros; cf. Themistoklean City Wail Wai21*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM558">558</a></p>
<p :class="{'activeClass': idClicked == NP2296 }" id="NP2296" >ITONIA: see Athena Ath70*-71*</p>
<p :class="{'activeClass': idClicked == NP2297 }" id="NP2297" >ITONIAN GATE in the Themistoklean City Wall, not far from Kallirrhoe: Pl. Ax. 364 D; Judeich, W. 1931, 126, 141-2, 386, 422, Plan I G-7, Plan IV; Gate (?) at 8 Iosiph ton Rogon St., east of Lembesi St., identified as both the Itonian Gate and the gate through which the road to Isthmonikos' Bath passed, Travlos, J. 1971, 160 Gate XI, figs, 219, 379, 380, 435; Billot, M.-F. 1992, 121, 123, 143, 145; cf. Themistoklean City Wall Wal21*, Wal24*; Conwell, D. H. 1993, 222, 248, 523-524</p>
<p :class="{'activeClass': idClicked == NP2298 }" id="NP2298" >JASON and those sailing with him to Colchis including Akastos with his horses, picture by Mikon in the sanctuary of the Dioskouroi: Paus. 1.18.1; LIMC II, 1984, 596 no. 25, s.v. Argonautai (R. Blatter)</p>
<p :class="{'activeClass': idClicked == NP2299 }" id="NP2299" >JEWISH MONUMENTS: SEG 41 (1991) no. 1839 (containing no specific references to Jewish inscribed monuments in Athens, simply providing invaluable insights)</p>
<p :class="{'activeClass': idClicked == NP2300 }" id="NP2300" >Anna, gravestone, 4th c. B.C., Kerameikos Exc. - - - IG II² 10678; SEG 25 (1971) no. 317; sometimes advertised as the earliest Jewish monument in Greece <JBcom>[but Anna is not exclusively a Jewish name]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2301 }" id="NP2301" >Theodoula and Moses, gravestone with incised menorah, trumpet and palm branch, Akr. - - - IG III 3546; Sironen, E. 1997, 284 no. 255; cf. SEG 47 (1997) nos. 259, 2297</p>
<p :class="{'activeClass': idClicked == NP2302 }" id="NP2302" >Marble plaque fragment with incised menorah and palm branch, 5th c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204546" target="_blank">Agora A 4546</a>; tentatively <JBcom>[and wrongly]</JBcom> identified as the revetment from a synagogue <JBcom>[implausibly]</JBcom> located in the north room of the Metroon, A. T. Kraabel, “The Diaspora Synagogue: XI. Addendum: A Synagogue in the Athenian Agora?,” in ANRW II. 19.1, 505-507, pl. I; Agora XXIV, 49, 59 with notes 16-17, 61 no. 3, 96; <JBcom>[the fragment is from a gravestone such as that of Theodoula and Moses, above]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2303 }" id="NP2303" >Synagogue visited by St. Paul: Acts 17.17; L. B. Urdahl, “Jews in Attica,” Symbolae Osloensis 43 (1968) 39-40, 53-55, 56; cf. the preceding entry</p>
<p :class="{'activeClass': idClicked == NP2304 }" id="NP2304" >JUBA II, king of Mauretania, statue in the Ptolemaion: Paus. 1.17.2</p>
<p :class="{'activeClass': idClicked == NP2305 }" id="NP2305" >JULIA and Olbia the priestess' theatre seat dedicated by a hypomnematismos (of the Areopagus) and by a psephisma - - - IG II² 5101; Julia Livilla (?), Merkel, W. R. 1947, 76-77</p>
<p :class="{'activeClass': idClicked == NP2306 }" id="NP2306" >JULIA BERENIKE = Iulia Berenike (PIR, 2nd ed., 651), daughter of Julius Agrippa, king of the Jews, statue base, after 48 A.D., Ermou St. - - - IG II² 3449; Geagan, D. J. 1967, 33 note 9, 117, 118, 140</p>
<p :class="{'activeClass': idClicked == NP2307 }" id="NP2307" >JULIA DOMNA: Temple statue synthronos with Athena Polias: IG II² 1076 lines 16-20; SEG 37 (1987) no. 97; Agora XVI, no. 341, 482-485</p>
<p :class="{'activeClass': idClicked == NP2308 }" id="NP2308" >Golden statue in the Parthenon: IG II² 1076 lines 27-28; A. D. Nock, «ΣΥΝΝΑΟΣ ΘΕΟΣ,» HSCP 41 (1930) 35; SEG 37 (1987) no. 97</p>
<p :class="{'activeClass': idClicked == NP2309 }" id="NP2309" >Head - - - Acr. _ (inv. number not known); tentative identification, Vermeule, C. C. 1968, 400 no. 2; belonging to a group of six acrolithic statues dedicated by Caracalla, Catling, H. W. C. 1984-1985, 6</p>
<p :class="{'activeClass': idClicked == NP2310 }" id="NP2310" >Miniature head - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201977" target="_blank">Agora S 1977</a>; H. A. Thompson, “Activities in the Athenian Agora: 1957,” Hesperia 27 (1958) 155, pl. 43 B</p>
<p :class="{'activeClass': idClicked == NP2311 }" id="NP2311" >JULIAN THE APOSTATE = F. Claudius Iulianus (PLRE 29), portrait head, built into a wall above the door of a house beside the Monastiraki Station, opposite the mosque (Plan 1 alpha—5) NM 2006; P. Kastriotis, «Ίουλιανού τού Άποστάτου κεφαλή,» ΑΕ 1923, 118-123, figs. 1,1a; P. Leveque, Observations sur l'iconographie de Julien dit l'Apostat,” MonPiot 51 (1960) 112-113 no. 3, fig. 9; <JBcom>[arbitrarily]</JBcom> conjecturally placed in a conjectural Kaisersaal in the Library of Hadrian, Karivieri, A. 1994a, 103-104; “probably a priest or an emperor - possibly Julian the Apostate,” Kaltsas, N. 2002, 373 no. 800, ph.</p>
<p :class="{'activeClass': idClicked == NP2312 }" id="NP2312" >JULIAN THE CAPPODOCIAN SOPHIST (PLRE Iulianus 5), 4th c. A.C.: see Prohairesios</p>
<p :class="{'activeClass': idClicked == NP2313 }" id="NP2313" >JUPITER OPTIMUS MAXIMUS, VENUS AND MERCURY, gods of Heliopolis, small altar, 2nd c. A.C., near the Gate of the Roman Agora CIL 7280; Y. Hajjar, La Triade d'Heliopolis-Baalbek I, Leiden, 1977, 318 no. 268</p>
<p :class="{'activeClass': idClicked == NP2314 }" id="NP2314" >KAINON, place-name and lawcourt: schol. Ar. V. 120; F GR HIST III B, suppl. vol. I, 164-167; Agora XXVIII, Index s.v. Kainon</p>
<p :class="{'activeClass': idClicked == NP2315 }" id="NP2315" >KAIROS, relief fragment - - - Acr. 2799; LIMCV, 1990, 922 no. 3, ph., s.v. Kairos (P. Moreno)</p>
<p :class="{'activeClass': idClicked == NP2316 }" id="NP2316" >KALADES, statue at the temple of Ares: Paus. 1.8.4; O. Walter, RE Suppl. VII, 940, col. 317 (Kalades); Pausanias refers to the musician Sakadas of Argos, Pritchett, W. K. 1998, 56-57</p>
<p :class="{'activeClass': idClicked == NP2317 }" id="NP2317" >KALAMITES: see Heroes Hro18*</p>
<p :class="{'activeClass': idClicked == NP2318 }" id="NP2318" >KALLIAS, son of Hipponikos</p>
<p :class="{'activeClass': idClicked == NP2319 }" id="NP2319" >Bronze statue dedicated by Kallias ca. 480-470 B.C. (?) on the Akr. IG I² 835 = IG I² 607; Acr. 7898; Raubitschek, A. E. 1949, no. 111</p>
<p :class="{'activeClass': idClicked == NP2320 }" id="NP2320" >Statue in the Agora near the statue of Eirene: Paus. 1.8.2; Holscher, T. 1975, 192</p>
<p :class="{'activeClass': idClicked == NP2321 }" id="NP2321" >KALLIAS of Sphettos, son of Thymochares, bronze statue to be set up in the Agora, awarded in an honorary decree to be inscribed on a stele set up beside the statue, 270/269 B.C., reused as a cover slab on the Great Drain east of the Stoa Basileios: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207295" target="_blank">Agora I 7295</a> lines 96, 100-101,107; Shear Jr., T. L. 1978, 4,6, 55-56; SEG 49 (1999) no. 113</p>
<p :class="{'activeClass': idClicked == NP2322 }" id="NP2322" >KALLION, lawcourt: Poll. 8.121; perhaps built by Kallias, son of Hipponikos, F GR HIST III B, suppl., vol. I, 164-167; Agora XXVIII, Index s.v. Kallion</p>
<p :class="{'activeClass': idClicked == NP2323 }" id="NP2323" >KALLIPPOS, son of Moirokles, commander at Thermopylai against the Galatians, 279 B.C., picture by Olbiades in the Bouleuterion: Paus. 1.3.5; Habicht, Chr. 1985, 84-85</p>
<p :class="{'activeClass': idClicked == NP2324 }" id="NP2324" >KALLIRRHOE, spring in the Ilissos river bed near the church of Haghia Photini (Plan 2 eta—6): Th. 2.15.5-6; Agora III, nos. 435, 438-440, 442, 444, 448-450, 452-455; Judeich, W. 1931, 194-196, 419-420; Travlos, J. 1971, 204-205, 340, 361, figs. 130, 154, 267-268, 379, 441; LIMC V, 1990, 937, s.v. Kallirrhoe II (A. Spetsieri-Choremi); see also Eleusinian Mysteries; Enneakrounos</p>
<p :class="{'activeClass': idClicked == NP2325 }" id="NP2325" >KALLISTE, sanctuary, on the Road to the Academy Gymnasium, at 11 Plataion St. opposite Granikou St. (Plan 2 delta—2): walled precinct of Artemis with xoana of Ariste and Kalliste on the way to the Academy, Paus. 1.29.2; precinct of Hekate, whom some call Artemis, in the Kerameikos according to Hsch. s.v.; IG II² 788 line 28; A. Philadelpheus, “Le sanctuaire d'Artemis Kalliste et l'ancienne rue de l' Academie,” BCH 51 (1927) 155-163, figs. 2-4, pl. 8; Travlos, J. 1971, 301, figs. 417 no. 178, 423-424, Forsen, B. 1996, 57-58, figs. 47-49</p>
<p :class="{'activeClass': idClicked == NP2326 }" id="NP2326" >Decree to be set up in the sanctuary, ca. 190 B.C., found 1890 in the Kerameikos Exc. at the so-called Hekataion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM408">408</a>) IG II² 789; A. Philadelpheus, op. cit, 161-162 note 2; for the date, Tracy, S. V. 1990, 71, 73, 261</p>
<p :class="{'activeClass': idClicked == NP2327 }" id="NP2327" >Decree of 235/234 B.C. to be set up in the sanctuary, found in 1896 between Plataion and Salaminos Sts. (Plan 2 delta—2) — IG II² 788; Papagiannopoulos-Palaios, A. A. 1939, 89-92 no. 27</p>
<p :class="{'activeClass': idClicked == NP2328 }" id="NP2328" >Eukoline's dedication on a base for a statue or relief, found on Plataion St., now in the Kerameikos Museum - - - Kerameikos I 39, IG II² 4668; ibid., 89 no. 26</p>
<p :class="{'activeClass': idClicked == NP2329 }" id="NP2329" >Kalliste and Ariste, fragment of a herm (?) dedicated by the priest Antibios, son of Prokles, ca. 246 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205290" target="_blank">Agora I 5290</a>; A. G. Woodhead, “Greek Inscriptions,” Hesperia 28 (1959) 278-279 no. 7, pl. 56; SEG 18 (1962) no. 87</p>
<p :class="{'activeClass': idClicked == NP2330 }" id="NP2330" >KALLISTEPHANOS: see Heroes Hro19*</p>
<p :class="{'activeClass': idClicked == NP2331 }" id="NP2331" >KALLISTO, daughter of Lykaon, statue by Deinomenes on the Akr.: Paus. 1.25.1, cf. 10.9.5; LIMCV, 1990, 941 no. 2, s.v. Kallisto (I. McPhee)</p>
<p :class="{'activeClass': idClicked == NP2332 }" id="NP2332" >KALOS, Daidalos' nephew, grave on the Akr. South Slope between the theater and the Asklepieion: Paus. 1.21.4, 1.26.4; suggested location at the site of the "cisterna paleocristiana", Beschi, L. 1967/68a, 393-396, 512, fig. 1 monument B. Other ancient authors call Daidalos1 nephew Talos or Perdix, LIMC VII, 1994, 837, s.v. Talos II, 837 (I. Leventi); Beschi, L. 2002, 21, 25</p>
<p :class="{'activeClass': idClicked == NP2333 }" id="NP2333" >KARIOS: see Zeus Zeu49*</p>
<p :class="{'activeClass': idClicked == NP2334 }" id="NP2334" >KARNEADES, founder of the New Academy</p>
<p :class="{'activeClass': idClicked == NP2335 }" id="NP2335" >Exedra in the Academy: Cic. Fin. 5.2.4</p>
<p :class="{'activeClass': idClicked == NP2336 }" id="NP2336" >Base of a statue dedicated by private Athenian citizens Attalos and Ariarathes of Sypalettos, ca. 150 B.C., near the Stoa of Attalos - - - IG II² 3781; SEG 41 (1991) no. 152; A. Stahli, “Die Datierung des Karneades-Bildnisses,”, AA 1991, 219-232, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP2337 }" id="NP2337" >KARPOPHOROS: see Demeter Karpophoros; Ge Karpophoros</p>
<p :class="{'activeClass': idClicked == NP2338 }" id="NP2338" >KASIANOS ANTIOCHOS called Synesios: PAA vol.1 (1994) no. 135705; Oliver, J. H. 1970, 105-107 no. 20, 131, 133</p>
<p :class="{'activeClass': idClicked == NP2339 }" id="NP2339" >Statue base for K. as Archon of the Panhellenion, 3rd c. A.C., Roman Agora - - - IG II² 3712</p>
<p :class="{'activeClass': idClicked == NP2340 }" id="NP2340" >Grave monument - - - IG II² 13209; L. Robert, “Maledictions funeraires grecques," CRAI, 1978, 244-245</p>
<p :class="{'activeClass': idClicked == NP2341 }" id="NP2341" >KASSANDER, bronze statue destroyed by the Athenians: Plu. Moralia 559 D</p>
<p :class="{'activeClass': idClicked == NP2342 }" id="NP2342" >KASSANDRA, LOKRIAN AIAS, LAODIKE (portrait of Elpinike) and other captive Trojan women in the presence of the Greek kings, picture by Polygnotos ca. 460 B.C. in the Stoa Poikile: Plu. Cim. 4, 5-6; Paus. 1.15.2, cf. 10.26.1; Agora III, 31, nos. 80,86; Kron, U. 1976, 157-158. The account of Polygnotos' painting of the same scene in Delphi (Paus. 10.26.1,3,7) provides clues to the content of the picture in the Stoa Poikile <JBcom>[which will also have pictured Kassandra holding the desecrated cult statue of Athena and Laodike as a philhellene; Menestheus was surely pictured among the Greek kings]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2343 }" id="NP2343" >KASTRO, name for the Akropolis: earliest testimonium in the 16th c.: Paris Anonymous line 1; R. Foerster, “Zum Pariser und Wiener Anonymous über Athen,”, AM 8 (1883) 30-32</p>
<p :class="{'activeClass': idClicked == NP2344 }" id="NP2344" >KATAIBATES: see Demetrios Poliorketes; Hermes Hrm24*; Zeus Zeu50*-52*, Zeu67*</p>
<p :class="{'activeClass': idClicked == NP2345 }" id="NP2345" >KATATOME, vertically hewn rock face above the theater: Harp, s.v.; Judeich, W. 1931, 315 note 1, cf. 394 note 4; Welter, G. 1938, 32, figs. 1-4</p>
<p :class="{'activeClass': idClicked == NP2346 }" id="NP2346" >KATHARSIOS, cult: Poll. 8.142; Cook, A. B. 1914-1940, II, 1093 note 1, 1100 note 1</p>
<p :class="{'activeClass': idClicked == NP2347 }" id="NP2347" >KATHIMIA, current name for a district in northwest Athens, derived from the ancient name Akademeia: Biris, K. 1971, 16, 43</p>
<p :class="{'activeClass': idClicked == NP2348 }" id="NP2348" >Kathimia of Haghia Eleousa (Chr.Mon. CM45*), name for Kolonos Hippios (Plan 3 beta—6): Svoronos, J. M. 1911, 389, pl. 123 A</p>
<p :class="{'activeClass': idClicked == NP2349 }" id="NP2349" >KEIONIAN GARDEN adjoining the Acharnian Gate: IG II² 2776 lines 70-72; Miller, S. G. 1972,70</p>
<p :class="{'activeClass': idClicked == NP2350 }" id="NP2350" >KEIRIADAI, deme west of the Pnyx outside the city walls: Anecdota Graeca, 1914, 219 lines 12-13, s.v. βάραθρον; Traill, J. S. 1986, 136 with Map</p>
<p :class="{'activeClass': idClicked == NP2351 }" id="NP2351" >KEKROPIA, name for Attica and Athens: Str. 9.1.20; F GR HIST III B, 127, Philochoros 328 F. 94, suppl. vols. I, 392 and II, 291-292, 295; cf. Agora Kekropia</p>
<p :class="{'activeClass': idClicked == NP2352 }" id="NP2352" >KEKROPS: Kron U. 1976, 84-103; Kearns, E. 1989, 175-176</p>
<p :class="{'activeClass': idClicked == NP2353 }" id="NP2353" >Heroon, the Kekropeion at the southwest corner of the Erechtheion: IG I² 474 lines 9, 59, 63, 84 and IG I² 476 lines 126-127; Caskey, L. D. et al. 1927, 127-137, 301; Stevens, G. P. 1946b, 93-97, figs. 12-14</p>
<p :class="{'activeClass': idClicked == NP2354 }" id="NP2354" >Large Ionic poros limestone column capital, early 5th c. B.C., one fragment east of the Erechtheion, one fragment built into the Akropolis North Wall Acr. 13302; E. P. McGowan, “The Origins of the Athenian Ionic Capital,” Hesperia 66 (1997) 213-214, pls. 56: c,d and 57:a; assigned to a marker erected on a square base in the Kekropeion, M. Korres, “An Early Attic Ionic capital and the Kekropion on the Athenian Akropolis,” in Greek Offerings, Essays on Greek Art in honour of John Boardman, ed. O. Palagia, Oxford, 1997, 95-107, figs. 1-3.</p>
<p :class="{'activeClass': idClicked == NP2355 }" id="NP2355" >Grave: F GR HIST III B, 166-7, Antiochos-Pherekydes 333 F 1, with suppl. vols. I, 614-5 and II, 498; Iakovides, S. 1962, 53, 89-91, 92, 93, 236 nos. 40-42 (testimonia), fig. 12 no. 9</p>
<p :class="{'activeClass': idClicked == NP2356 }" id="NP2356" >Statue as Eponymous Hero: Paus. 1.5.3 See also: Kronion, altar of Kronos and Rhea founded by Kekrops </p>
<p :class="{'activeClass': idClicked == NP2357 }" id="NP2357" >KELKAIA: see Artemis Art34*</p>
<p :class="{'activeClass': idClicked == NP2358 }" id="NP2358" >KEPHISODOROS, Athenian statesman: Habicht, Chr. 1985, 91-92</p>
<p :class="{'activeClass': idClicked == NP2359 }" id="NP2359" >Grave monument, 195 B.C., on the Sacred Way: Paus. 1.36.5 + 1.37.1; J. P. V. D. Baldson, “Rome and Macedon, 205-200 B.C., JRS 44 (1954) 36; <JBcom>[probably a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2360 }" id="NP2360" >Bronze statue in the Agora, decreed 196/5 B.C.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20605" target="_blank">Agora I 605</a> lines 33+ 52; Gauthier, Ph. 1985, 30 with note 72, 68, 79, 80, 85, 87, 100; SEG 41 (1991) no. 59; Schenkungen hellenistischer Herrscher, Part I, 1995, 86-89; SEG 49 (1999) no. 117</p>
<p :class="{'activeClass': idClicked == NP2361 }" id="NP2361" >KEPHISOS RIVER: Paus. 1.37.3+ 4; F. Bolte, REX I, 1921, 244-247 (Kephisos) Siewert, P. 1982, 40 with note 20</p>
<p :class="{'activeClass': idClicked == NP2362 }" id="NP2362" >Precinct implied by the mention of a dedication to Kephisos: Paus. 1.37.3</p>
<p :class="{'activeClass': idClicked == NP2363 }" id="NP2363" >Boundary stone of (the sanctuary of the river-god) Kephisos belonging to the phratry Gleontis, ca. 400 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205910" target="_blank">Agora I 5910</a>; Meritt, B. D. 1948, 35 no. 18, pl. 9; Hedrick Jr., C. W. 1984, 248-251; SEG 49 (1999) no. 180</p>
<p :class="{'activeClass': idClicked == NP2364 }" id="NP2364" >KERAMEIKAI GATE in the Themistoklean City Wall: Philostr. VS 2.8.2; another name for the Dipylon Gate, Judeich, W. 1931, 136 note 2; see Themistoklean City Wall Wal6*</p>
<p :class="{'activeClass': idClicked == NP2365 }" id="NP2365" >KERAMEIKOS, name of a district: Paus. 1.2.4, 1.3.1, 1.14.6, 1.20.6, 8.9.8; Agora III, 221-224; Siewert, P. 1982, 29 note 140, 31 note 150, 42; J. W. Flick - G. C. Mandl - P. Siewert - I. Steffelbauer, “Aristophanes' Nachrichten liber den Athenischen Kerameikos,” AM 115 (2000) 217-235; Papadopoulos, J. K. 2003, 271-197; Kerameikos as a street name is not attested in the ancient sources; cf. Kerameis; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM565">565</a></p>
<p :class="{'activeClass': idClicked == NP2366 }" id="NP2366" >KERAMEIKOS BOUNDARY STONES: Ritchie Jr., C. E. 1985, 199-232, 664-667, 756-767, pls. 38-44, figs. 32-33, suppl. figs. 3-9; Agora XIX, 11-13, 28; SEG 41 (1991) no. 122; Knigge, U. 1991, 157, fig. 1 (boundary stones labelled H), figs. 2, 61-63, 156-157, 165 (the Master Plan); Stroszeck, J. 2003, 53-83, figs. 1-20; <JBcom>[pace J. Stroszeck, the Kerameikos boundary stones, like the Agora and Academy boundary stones, define public domain]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2367 }" id="NP2367" >Stele, early 4th c. B.C., in situ facing north beside the Panathenaic Way, south side of Adrianou St. and west of Thissiou St. (Plan 1 alpha—2) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205770" target="_blank">Agora I 5770</a>; Agora XIX, 28 H30, pl.2; Agora XIV, plan pl. 6; SEG 50 (2000) no. 183; Papadopoulos, J. K. 2003, 292, fig. 5.12-13</p>
<p :class="{'activeClass': idClicked == NP2368 }" id="NP2368" >Opisthographic stele fragment, mid-4th c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206835" target="_blank">Agora I 6835</a>; Agora XIX, 28 H 31, pl.2</p>
<p :class="{'activeClass': idClicked == NP2369 }" id="NP2369" >Kerameikos Horos 1, stele in situ at at the Dipylon Gate on the west side of the Road to the Academy Gymnasium - - - Kerameikos I 238, IG II² 261</p>
<p :class="{'activeClass': idClicked == NP2370 }" id="NP2370" >Kerameikos boundary stone ? A stele base with the lowest part of the stele <JBcom>[now missing]</JBcom>, found in situ at the Dipylon Gate at the east side of the Road to the Academy Gymnasium, is thought to be a base for a Kerameikos boundary stone, a pendant to Kerameikos Horos 1: S. Koumanoudes, Praktika 1873-74 <JBcom>[1874]</JBcom>, 15-16 with plan following 66; mentioned under IG II² 2617. In March 1978 a cement cast of Kerameikos boundary stone H1 was set up on this stele base, concealing the stele bedding into which it does not fit, being too large; Clairmont, C. 1983, 35 with note 40.</p>
<p :class="{'activeClass': idClicked == NP2371 }" id="NP2371" >Shortly before the stele bedding was lost to sight under the cement cast, Fordyce Wood Mitchel and Judith Binder measured it, establishing that it did not belong to the set of three Kerameikos boundary stones in situ on the west side of the Road to the Academy Gymnasium. Although the stele base could have held a Kerameikos boundary stone, it was not made at the same time as Kerameikos horoi 1, 2 and 3 which share distinctive characteristics, especially the bevelled corners, and measurements, forming a set. S. Koumanoudes who saw the lowest part of the stele, now missing, would surely have noted unusual bevelling at the four corners had it been present.</p>
<p :class="{'activeClass': idClicked == NP2372 }" id="NP2372" >Kerameikos Horos 2, stele in situ on the west side of the Road to the Academy Gymnasium at the tomb of the Lakedaimonians - - - Kerameikos I 240; IG II² 2618; Willemsen, F. 1977, 117 note 1, fig. 1 H3, pl. 59, Beilage 4 H3; <JBcom>[the stele, inscribed on both sides like H1 and H3, is strangely enough jammed up so tightly against the tomb of the Lakedaimonians that the inscription in back cannot be seen; its existence went unsuspected until one day in the 1950s Eugene Vanderpool inserted the tip of his slender forefinger between the stele and the façade of the tomb and felt the top of the omicron]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2373 }" id="NP2373" >Kerameikos Horos 3, stele in situ on the west side of the Road to the Academy Gymnasium Kerameikos I 239, IG II² 2619</p>
<p :class="{'activeClass': idClicked == NP2374 }" id="NP2374" >Stele found on Alexandreias St. between Kolonos Hippios and the Academy, 1.5 km. northwest of the Kerameikos Exc., exact findspot not recorded (Plan 2 alpha/beta—1/2) Kerameikos I 322; E. Vanderpool, “News Letter from Greece,” AJA 60 (1956) 267; Travlos, J. 1971, 300</p>
<p :class="{'activeClass': idClicked == NP2375 }" id="NP2375" >KERAMEIKOS EXCAVATIONS: For monuments and sites without clear-cut names in the Kerameikos Excavations, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM388">388</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM429">429</a></p>
<p :class="{'activeClass': idClicked == NP2376 }" id="NP2376" >KERAMEIS</p>
<p :class="{'activeClass': idClicked == NP2377 }" id="NP2377" >Deme name: Harp, s.v.; Siewert, P. 1982, 29 note 140, 31 note 150, 41-42, 95; <JBcom>[at various times Kerameis was coextensive or overlapping or contiguous with the Kerameikos district]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2378 }" id="NP2378" >Grave mon. for Andropeithes, Satyra and Pytheas of Kerameis, 4th c. B.C., found on Vasilikon St. between Phaonos and Marathonomachon Sts. (Plan 3 approximately at alpha—7) - - - SEG 25 (1971) no. 248</p>
<p :class="{'activeClass': idClicked == NP2379 }" id="NP2379" >Trittys name: IG I³ 1118 = IG I² 883; D. M. Lewis, “Cleisthenes and Attica,”, Historia 12 (1963) 28; Siewert, P. 1982, 14 with note 69; Traill, J. S. 1986, 97-98 no. 3, pl. 11; SEG 37 (1987) no. 41; see Trittys signposts no. 7</p>
<p :class="{'activeClass': idClicked == NP2380 }" id="NP2380" >KERAMO, daughter of Enialos, grave stele, 650-600 B.C., found on the Ilissos in the area called Agrai in ancient times <JBcom>[i.e. on the Ilissos left bank, not at Hadrian's Arch as sometimes reported]</JBcom> IG I² 1194 = IG I² 997; SEG 29 (1979) no. 62; the earliest inscribed Attic gravestone, Jeffery, L. H. 1990, 71, 76 no. 8, 401, 421, pl. 2 no. 8</p>
<p :class="{'activeClass': idClicked == NP2381 }" id="NP2381" >KERAMOS, potter-hero: Kearns, E. 1989, 94,176; cf. Potter Hero</p>
<p :class="{'activeClass': idClicked == NP2382 }" id="NP2382" >Hero of the deme Kerameis: Harp, s.v.; F GR HIST III B, 106, Philochoros 328 F 25.</p>
<p :class="{'activeClass': idClicked == NP2383 }" id="NP2383" >Son of Dionysos and Ariadne, hero of the Kerameikos district: Paus. 1.3.1 </p>
<p :class="{'activeClass': idClicked == NP2384 }" id="NP2384" >KERKOPES: see Agora of the Kerkopes </p>
<p :class="{'activeClass': idClicked == NP2385 }" id="NP2385" >KERKYONEUS: see Apollo Apo38*</p>
<p :class="{'activeClass': idClicked == NP2386 }" id="NP2386" >KERKYRA: see Athena Ath121*</p>
<p :class="{'activeClass': idClicked == NP2387 }" id="NP2387" >KERYKES, base, 5th c.? B.C., Akr. south slope, Serpentze Wall IG I² 1392 = IG I² 880; Levensohn, M. and E. 1947, 71 no. 101 in fig. 1; dated to the 4th c. B.C. by Kevin Clinton; not a boundary stone, pace Ritchie Jr., C. E. 1985, 233-238, pl. 45, fig. 34</p>
<p :class="{'activeClass': idClicked == NP2388 }" id="NP2388" >KERYKES, catalogue of the genos, early 2nd c. A. C., Asklepieion (?)--- IG II² 2340; SEG 42 (1992) no. 139</p>
<p :class="{'activeClass': idClicked == NP2389 }" id="NP2389" >KERYKOS LITHOS, stone on which Solon stood when he recited his poem on Salamis: Plu. Sol. 8.2; Agora XIV, 48</p>
<p :class="{'activeClass': idClicked == NP2390 }" id="NP2390" >KERYX OF THE AREOPAGUS COUNCIL, theater throne - - - IG II² 5032; Maass, M. 1972, 114-115, pl. 9</p>
<p :class="{'activeClass': idClicked == NP2391 }" id="NP2391" >KERYX PANAGES, theater throne - - - IG II² 5048; Maass, M. 1972, 121-122, pl. 13; SEG 44 (1994) no. 172</p>
<p :class="{'activeClass': idClicked == NP2392 }" id="NP2392" >KHNUM, Egyptian god with donkey head, furniture support figure <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202353" target="_blank">Agora S 2353</a> + 2432; unpublished</p>
<p :class="{'activeClass': idClicked == NP2393 }" id="NP2393" >KIMON, son of Miltiades</p>
<p :class="{'activeClass': idClicked == NP2394 }" id="NP2394" >Grave in the family burial ground, later known as the "Kimonian Remains" outside the Melitides Gate in Koile: Plu. Cim. 4.2; Cratinus frg. 151 in The Fragments of Attic Comedy, ed. J. M. Edmonds, I, Leiden, 1957, 70; O. Luschnat, RE Suppl. XII, 1970, col. 1090-1091 (Thukydides); Davies, J. K. 1971, 310; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM243">243</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM244">244</a></p>
<p :class="{'activeClass': idClicked == NP2395 }" id="NP2395" >Well-watered grove planted in the Academy: Plu. Cim. 13.8; Billot, M.-F. 1989, 717, 722-723, 727-728</p>
<p :class="{'activeClass': idClicked == NP2396 }" id="NP2396" >Aqueduct, 2nd q. of 5th c. B.C., from central Athens to the Academy: <JBcom>[logically]</JBcom> assigned to Kimon; Shear Jr., T. L. 1984, 48-50, figs. 3-4, pl. 14</p>
<p :class="{'activeClass': idClicked == NP2397 }" id="NP2397" >Plane trees planted in the Agora: Plu. Cim. 13.8; Agora III, nos. 717-718</p>
<p :class="{'activeClass': idClicked == NP2398 }" id="NP2398" >KIMON, son of Stesagoras, grave in the family plot in Koile outside the Melitides Gate in Koile: Hdt. 6.103; Judeich, W. 1931, 140 note 4, 400, 409-10</p>
<p :class="{'activeClass': idClicked == NP2399 }" id="NP2399" >KLARIOS: see Apollo Apo24*</p>
<p :class="{'activeClass': idClicked == NP2400 }" id="NP2400" >KLEARISTE, theater seat - - - IG II² 5125</p>
<p :class="{'activeClass': idClicked == NP2401 }" id="NP2401" >KLEGORA of Corinth, grave stele, 4th c. B.C., built into the central pier of the late Hellenistic vaulted gateway closing off the Dipylon courtyard IG II² 9069; G. P. Oikonomos, “Aus dem Dipylon,” AM 37 (1912) 229, fig. 3:B; cf. Themistoklean City Wall Wal7*</p>
<p :class="{'activeClass': idClicked == NP2402 }" id="NP2402" >KLEIDOUCHOS: see Athena Ath72*</p>
<p :class="{'activeClass': idClicked == NP2403 }" id="NP2403" >KLEISTHENES, grave mon. on the Road to the Academy: Paus. 1.29.6; a state burial monument, Stupperich, R. 1977, 1, 223-224; R. D. Cromey, “Kleisthenes' Fate,” Historia 28 (1979) 130-131, 133</p>
<p :class="{'activeClass': idClicked == NP2404 }" id="NP2404" >KLEON, son of Kleainetos, tannery in Kydathenaion: schol. Ar. Eq. 44; U. Kahrstedt, RE XI, 1921, 714 lines 28-30, testimonia (Kleon)</p>
<p :class="{'activeClass': idClicked == NP2405 }" id="NP2405" >KLEPSYDRA</p>
<p :class="{'activeClass': idClicked == NP2406 }" id="NP2406" >1) Spring house, 475-450 B.C., partly rock-cut, set deep in a cave on the Akropolis North Slope: Paus. 1.28.4 (unnamed spring below the Propylaia); tentatively <JBcom>[and convincingly]</JBcom> assigned to Kimon, Parsons, A. W. 1943, 191-267, testimonia 264-267, figs. 1-42; Travlos, J. 1971, 323-331, figs. 116G, 426-434; for the Late Mycenean predecessor, see Smithson, E. L. 1982, 141-154, fig. 1; see also: Empedo</p>
<p :class="{'activeClass': idClicked == NP2407 }" id="NP2407" >2) Vaulted Well House, after 267 A.D., with vaulted stairway leading into the Mnesiklean Bastion: Parsons, A. W. 1943, 193, 195, 201, 246-249, 261, figs. 1-2, 4-6, 19-21, 31-34, 38-40; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM23">23</a></p>
<p :class="{'activeClass': idClicked == NP2408 }" id="NP2408" >KLYMENE: see Diktys and Klymene</p>
<p :class="{'activeClass': idClicked == NP2409 }" id="NP2409" >KODROS, Athenian king: N. Robertson, “Melanthus, Codrus, Neleus, Caucon,”, GRBS 29 (1988) 201-205, 224-230, 255-261; Kearns, E. 1989, 56-57, 81,107, 178</p>
<p :class="{'activeClass': idClicked == NP2410 }" id="NP2410" >Statue with inscribed base, Augustan pd., set up at the spot on the Ilissos, seen by Pausanias, where Kodros fell IG II² 4258; Pausanias 1.19.5, cf. 7.25.2. Perhaps within the sanctuary of Kodros, Neleus and Basile, N. Robertson, op. cit., 225-226</p>
<p :class="{'activeClass': idClicked == NP2411 }" id="NP2411" >Grave below the Akropolis: IG II² 4258 lines 3-4; R.D. Cromey, The Alkmeonid Inheritance ..., Univ. Microfilms, 1973, 45-47, 50-51; <JBcom>[the epigram clearly distinguishes between the place where Kodros fell and the place where he was buried with heroic honours inside the town]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2412 }" id="NP2412" >KODROS and NELEUS and BASILE, sanctuary: IG I³ 84 lines 4, 13-14, 30-31; south or southeast of the precinct of Dionysos Eleuthereus, Wheeler, J. R. 1887, 38-49; for an <JBcom>[imaginary]</JBcom> site entrenched in a network of <JBcom>[imaginary]</JBcom> sites and <JBcom>[mistaken]</JBcom> identifications mutually supporting each other, see Travlos, J. 1971, 332-333, figs. 379 no. 182, 435; N. Robertson, op. cit. (under Kodros), 201-205, 224-239, 255-261; SEG 38 (1988) no. 5; Kearns, E. 1989, 151 s.v. Basile, 178 s.v. Kodros, 188 s.v. Neleus; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM171">171</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM559">559</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM560">560</a></p>
<p :class="{'activeClass': idClicked == NP2413 }" id="NP2413" >Boundary stones: IG I³ 84 line 7; SEG 38 (1988) no. 5; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM560">560</a>; <JBcom>[the mention of horistai in line 7 of the decree suggests but does not prove the existence of boundary stones]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2414 }" id="NP2414" >Sanctuary decree with a badly disfigured relief of a hero on horseback and a seated figure, 418/417 B.C., found "some distance southeast of the Acropolis., to the left of the steam-tramway which leads to Phaleron" IG I³ 84 with Addenda (= IG I² 94); for the findspot see J. R. Wheeler, op. cit., 40; the decree <JBcom>[erroneously]</JBcom> assumed to have been found in situ at an <JBcom>[incorrectly]</JBcom> reported findspot, Travlos, J. 1971, 332; the figures <JBcom>[doubtably]</JBcom> identified as Kodros and Athena, Lawton, C. L. 1995, 83-84 no. 4, pl. 2 and General Index s.v. Kodros; LIMC VIII, 1997, 539 no. 3, s.v. Basile (P. Muller)</p>
<p :class="{'activeClass': idClicked == NP2415 }" id="NP2415" >Temenos of Neleus and Basile to be planted with 200 or more olive trees: IG I³ 84 lines 12, 29, 32-34; the Neleion: IG I³ 84 lines 27-28</p>
<p :class="{'activeClass': idClicked == NP2416 }" id="NP2416" >Sanctuary of Basile: Pl. Chrm. 153A; assuming that Plato does not refer to a separate sanctuary of Basile, Wheeler, J. R. 1887, 45 note 19, 46-47;</p>
<p :class="{'activeClass': idClicked == NP2417 }" id="NP2417" >KOILE</p>
<p :class="{'activeClass': idClicked == NP2418 }" id="NP2418" >Deme southwest of the Pnyx: Marcellin. Vit. Thuc. 17.55; Traill, J. S. 1986, 136 with Map</p>
<p :class="{'activeClass': idClicked == NP2419 }" id="NP2419" >Road through Koile: Hdt. 6.103; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM585">585</a> See also: Agora in Koile </p>
<p :class="{'activeClass': idClicked == NP2420 }" id="NP2420" >KOLAINIS: see Artemis Art35*-39*</p>
<p :class="{'activeClass': idClicked == NP2421 }" id="NP2421" >KOLIAS: see Aphrodite Aph34*</p>
<p :class="{'activeClass': idClicked == NP2422 }" id="NP2422" >KOLLYTOS</p>
<p :class="{'activeClass': idClicked == NP2423 }" id="NP2423" >Deme named after Kollytos father of Diomos, southwest of the Agora, south of the Areopagus: Str. 1.4.7; E. Honigmann, REX I, 1921, 1106-1107 (Kollytos); Traill, J. S. 1986, 126, 128 note 18, Map; Billot, M.-F. 1992, 125, 128; Stroud, R. S. 1998, 17, 88-89; see Agathe Tyche, sanctuary; Zeus Zeu1*</p>
<p :class="{'activeClass': idClicked == NP2424 }" id="NP2424" >Στενοπός Κολλυτός, a narrow street in the heart of the city that served as an agora in 359/360 A.D. when the district was restored by Ampelius, proconsul of Achaia: Phot. Bibl. 375 B; probably the ancient northwest-southeast street running through the Areopagus-Pnyx Valley, Ritchie Jr., C. E. 1985, 737; Agora XXIV, 21, 35-36 with notes 135-137; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM602">602</a></p>
<p :class="{'activeClass': idClicked == NP2425 }" id="NP2425" >KOLONOS</p>
<p :class="{'activeClass': idClicked == NP2426 }" id="NP2426" >Deme at Kolonos Hippios: Harp. s.v. Κολωνέτας; Traill, J. S. 1986, 126, Map</p>
<p :class="{'activeClass': idClicked == NP2427 }" id="NP2427" >Eponymous hero (archegos) of Kolonos, equestrian (?) statue: S. OC 58-65; Whitehead, D. 1986, 211; Kearns, E. 1989, 95, 125, 178</p>
<p :class="{'activeClass': idClicked == NP2428 }" id="NP2428" >KOLONOS AGORAIOS, name of the low hill west of the Agora: Harp. s.v. κολωνέτας; Agora III, nos. 246-248, 250-251, 286, 290; summit of the hill levelled down in 1862, Travlos, J. 1971, 261</p>
<p :class="{'activeClass': idClicked == NP2429 }" id="NP2429" >KOLONOS HIPPIOS, hill near the Academy (Plan 3 beta—6): Paus. 1.30.4; Svoronos, J. N. 1911, 387-414, figs. 202-203, pls. 123-125; E. Meyer, KP 3, 1969, 276 (s.v. Kolonos 2) For imaginary monuments and sites in the Kolonos Hippios area, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM462">462</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM474">474</a></p>
<p :class="{'activeClass': idClicked == NP2430 }" id="NP2430" >KOMOIDIA: see Tragoidia</p>
<p :class="{'activeClass': idClicked == NP2431 }" id="NP2431" >KONNIDAS, Theseus' paidagogos, honoured as a hero by the Athenians: Plu. Thes. 4.1; Kearns, E. 1989, 178-179</p>
<p :class="{'activeClass': idClicked == NP2432 }" id="NP2432" >KONON (II) (d. ca. 389 B.C.) and TIMOTHEUS (II) (d. 354 B.C.)</p>
<p :class="{'activeClass': idClicked == NP2433 }" id="NP2433" >Konon's house with a 20-kline dining room taken over by the peripatetic Lykon for his receptions: Ath. XII.548 A-B; the house more likely belonged to Konon (III), the grandson of Konon (II), Davies, J. K. 1971, 509, 512</p>
<p :class="{'activeClass': idClicked == NP2434 }" id="NP2434" >Grave monuments on the Road to the Academy: Paus. 1.29.15; <JBcom>[state burial monuments ]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2435 }" id="NP2435" >Timotheus' mon. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205975" target="_blank">Agora I 5975</a>; this large naiskos with its restored inscription has been categorically <JBcom>[doubtably]</JBcom> identified as the grave monument Pausanias saw on the Road to the Academy; Meritt, B. D. 1961, 267 no. 89, pl. 50; Agora XVII, 42 no. 71; <JBcom>[a naiskos, hence a private grave monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2436 }" id="NP2436" >Statues in front of the Stoa of Zeus: Paus. 1.3.2; Agora HI, nos. 16, 29, 261, 702, 712; Gauer, W. 1968b, 118-24</p>
<p :class="{'activeClass': idClicked == NP2437 }" id="NP2437" >Statues opposite the 10th column from the east on the Parthenon north side - - - IG II² 3774; Paus. 1.24.3; Stevens, G. P. 1946a, 4-10, fig. 3 no. 2, figs. 4-11; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.,” Jahrbuch 88 (1973) 66 with note 102; SEG 36 (1986) no. 246; see Chr. Mon. CM11*, Ambo</p>
<p :class="{'activeClass': idClicked == NP2438 }" id="NP2438" >KORE: see Apollo Apo68*; Demeter and Κ.; Kourotrophos; Plouton</p>
<p :class="{'activeClass': idClicked == NP2439 }" id="NP2439" >KORNELIOS ONOMARCHOS, base of a statue voted by the Areopagus Council, early 3rd c. A.C., Areopagus north slope, west of the narthex of the church of Hag. Dionysios Areopagites <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201936" target="_blank">Agora I 1936</a>, IG II² 4004; the base reworked as a Christian altar with a cross carved out at the front, B. D. Meritt, “Greek Inscriptions,” Hesperia 33 (1964) 226 no. 74, pl. 37; SEG 21 (1965) no. 765</p>
<p :class="{'activeClass': idClicked == NP2440 }" id="NP2440" >KOTYS, Thracian king, ca. 42-31 B.C., base of statue by Antignotos, Akr. - - - IG II² 3443; Oliver, J. H. 1965, 55; Kotsidou, H. 2000, 88-90</p>
<p :class="{'activeClass': idClicked == NP2441 }" id="NP2441" >Reused for Paullus Fabius Maximus (REFabius no. 102) consul in 11 B.C. — IG II² 4129; cf. Raubitschek, A. E, 1966, 246 under no. 6</p>
<p :class="{'activeClass': idClicked == NP2442 }" id="NP2442" >KOTTYTO, Thracian goddess, cult (?): Simms, R. R. 1985, 144-158</p>
<p :class="{'activeClass': idClicked == NP2443 }" id="NP2443" >KOUROTROPHION</p>
<p :class="{'activeClass': idClicked == NP2444 }" id="NP2444" >Boundary marker, ca. 500-450 B.C., below the Propylaia - - - IG I³ 1060 = IG I² 859; Ritchie Jr., C. E., 1985, 239-244, pl. 46, figs. 35-36</p>
<p :class="{'activeClass': idClicked == NP2445 }" id="NP2445" >Bases built into the Serpentze Wall, Akropolis South Slope IG II² 4756 - 4757; S. A. Koumanoudes, “Έπιγραφαι έκ των περι τό Άσκληπιειον τόπων,» Athenaion 6 (1877) 147-148 no. 28</p>
<p :class="{'activeClass': idClicked == NP2446 }" id="NP2446" >KOUROTROPHOS</p>
<p :class="{'activeClass': idClicked == NP2447 }" id="NP2447" >Altar in the Bakcheion eastern room: Schrader, H. 1896, 266-269, pl. 9</p>
<p :class="{'activeClass': idClicked == NP2448 }" id="NP2448" >1st use: Kourotrophos at Artemis, Hadrianic - - - IG II² 5004; Threatte, L. 1980, 9, 219, 259</p>
<p :class="{'activeClass': idClicked == NP2449 }" id="NP2449" >2nd use: inscr. erased, reliefs added on three sides, ca. 200-250 A.C.: Inst, phot. Athen Varia 125-7, 216-8</p>
<p :class="{'activeClass': idClicked == NP2450 }" id="NP2450" >Side 1: Bull tethered to an altar and the sacrifice of a boar Side 2: Satyrs, maenad, goat</p>
<p :class="{'activeClass': idClicked == NP2451 }" id="NP2451" >Side 3: Dionysos, Satyr, Pan; E. Pochmarski, Dionysische Gruppen, Vienna, 1990, 11, 125, 304-305, 376-377</p>
<p :class="{'activeClass': idClicked == NP2452 }" id="NP2452" >Kioniskos base for a statuette of Kourotrophos dedicated to Demeter Chloe and Kore, found when the wall connecting the south tower of the Beule Gate to the Nike Bastion was demolished- - IG II² 4778; A. Lolling, «Έπιγραφαι έκ των περι την Άκροπολιν,» Deltion, 1889, 130 no. 5; A. D. Keramopoullos, «Υπό τά προπύλαια τής Άκροπόλεως,» Deltion 12 (1929) 76-77; another dedication for Demeter Chloe q.v. was found at the Beule Gate</p>
<p :class="{'activeClass': idClicked == NP2453 }" id="NP2453" >Kourotrophos from Aglauros, Demeter, theater seat - - - IG II² 5152; SEG 28 (1978) no. 238; R. S. J. Garland, “Religious Authority in Archaic and Classical Athens,” BSA 79 (1984) 86-87 See also: Blaute and Kourotrophos; Demeter Kourotrophos; Ge Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP2454 }" id="NP2454" >KRANAA POLIS, old name for the Akropolis: Ar. Lys. 480-481; Bull. 1965 no. 155; M. S. Jensen, “A Note on Homer's Use of the Word κραναός,” Cl et Med 33 (1981-82) 5-8</p>
<p :class="{'activeClass': idClicked == NP2455 }" id="NP2455" >KRONION, temenos at the Olympieion precinct as far as the Metroon in Agra: Anecdota Graeca, 1914, 273 lines 20-21, s.v. Κρονιον τέμενος; Agora III, no. 472</p>
<p :class="{'activeClass': idClicked == NP2456 }" id="NP2456" >Altar of Kronos and Rhea founded by Kekrops: Philochoros in Macr. 10.22; F GR HIST III B, 127-8, Philochoros 328 F 97 with suppl. vol. I, 401-404</p>
<p :class="{'activeClass': idClicked == NP2457 }" id="NP2457" >Temple in the precinct of Zeus Olympios: Paus. 1.18.7; <JBcom>[probably the classical Doric temple south of the Olympieion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM549">549</a>)]</JBcom>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM548">548</a></p>
<p :class="{'activeClass': idClicked == NP2458 }" id="NP2458" >KRONOS AND RHEA: see Kronion</p>
<p :class="{'activeClass': idClicked == NP2459 }" id="NP2459" >KTESIOS: see Zeus Zeu53*</p>
<p :class="{'activeClass': idClicked == NP2460 }" id="NP2460" >KYBELE: in Athens there was no cult of Kybele per se; for an illuminating account of how “the Atticized Phrygian Kybele” amalgamated with the Mother of The Gods, see Shear Jr., T. L. 1995, 172-178, 189; see Metragyrtes; Mother of The Gods Met8*</p>
<p :class="{'activeClass': idClicked == NP2461 }" id="NP2461" >KYDATHENAION</p>
<p :class="{'activeClass': idClicked == NP2462 }" id="NP2462" >Deme north of the Akropolis: IG II² 1590 line 4; E. Meyer, KP 3, 1969, 390 (s.v. Kydathenaion); Traill, J. S. 1986, 129 with Map</p>
<p :class="{'activeClass': idClicked == NP2463 }" id="NP2463" >Trittys name (rest.): IG II² 1748 line 14; Siewert, P. 1982, 14 with note 68 See also: Herakleidai, sanctuary </p>
<p :class="{'activeClass': idClicked == NP2464 }" id="NP2464" >KYDONION: see Kyloneion</p>
<p :class="{'activeClass': idClicked == NP2465 }" id="NP2465" >KYKLOBOROS, brook: schol. Ar. Eq. 137; Judeich, W. 1931, 48-49; according to Pappas, A. A. 1999, 26, sections iii-iv, Kykloboros started northwest of the church of Hag. Stylianos in northeast Athens (Plan 3 zeta—6), ran along Evelpidon → Kodringtonos Sts., and along the north side of the OTE Bldg. (Plan 3 alpha—6); Bousgou St. (Plan 3 epsilon—6) was formerly called Kykloborou St.</p>
<p :class="{'activeClass': idClicked == NP2466 }" id="NP2466" >KYKNOS: see Herakles Hrk16**</p>
<p :class="{'activeClass': idClicked == NP2467 }" id="NP2467" >KYLON, bronze statue on the Akropolis: preceding the description of Athena Promachos, Paus. 1.28.1; M. Jameson, “Notes on the Sacrificial Calendar from Erchia,” BCH 89 (1965) 170-171</p>
<p :class="{'activeClass': idClicked == NP2468 }" id="NP2468" >KYLONEION (or KYDONION, unemended) outside the Enneapylon: schol. S. OC 489; cf. F GR HIST III B, 385-7, Epimenides T 1 lines 12-13, T 2 lines 24-25, T 4 lines 16 ff. with commentary; M. Jameson, op. cit. 171 note 1; conjectural <JBcom>[arbitrarily chosen]</JBcom> location on the Akr. West Slope, Travlos, J. 1971, 2, fig. 5 no. 9</p>
<p :class="{'activeClass': idClicked == NP2469 }" id="NP2469" >KYNOSARGES: Kyle, D. G. 1987, 84-92; Billot, M.-F. 1992, 119-156, figs. 1-6; SEG 42 (1992) no. 236; Privitera, S. 2002, 51-55 (testimonia), 63-65 (bibliography)</p>
<p :class="{'activeClass': idClicked == NP2470 }" id="NP2470" >Place-name: Plu. Moralia 838 B; Billot, M.-F. 1992, 120</p>
<p :class="{'activeClass': idClicked == NP2471 }" id="NP2471" >Gymnasium: Liv. XXXI.24; Delorme, J. 1960, 523 s.v. Athenes (Cynosarges); Billot, M.-F. 1992, 119 note 1, 120, 124, 129-144; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM536">536</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM539">539</a></p>
<p :class="{'activeClass': idClicked == NP2472 }" id="NP2472" >Inscription concerning bases for tripods at Kynosarges, 4th c. B.C., found on the Ilissos left bank south of Hag. Panteleimon (Plan 2 theta—5) - - - IG II² 1665; for the findspot, D. M. Robinson, “Inscriptions in Athens,”, AJP 28 (1907) 425, 428; SEG 18 (1962) no. 51; Billot, M.-F. 1992, 136-137, 138, 139, 142, 144, 145; S. Privitera, op. cit., 51-62 See also: Herakles Hrk44*-45*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM535">535</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM539">539</a></p>
<p :class="{'activeClass': idClicked == NP2473 }" id="NP2473" >LACHARES, pagan sophist, active 450-475 A.C.</p>
<p :class="{'activeClass': idClicked == NP2474 }" id="NP2474" >Portrait sculpture: Athanassiadi, P. 1999, 171, frag. 62A</p>
<p :class="{'activeClass': idClicked == NP2475 }" id="NP2475" >Grave epigrams: Thoukydidou St. - - - IG II² 11952; Sironen, E. 1997, 212-213 no. 163</p>
<p :class="{'activeClass': idClicked == NP2476 }" id="NP2476" >LADAMEA (Laodameia), theater seat IG II² 5092</p>
<p :class="{'activeClass': idClicked == NP2477 }" id="NP2477" >LADAMEA (Laodameia), daughter of Me(deios), priestess of Athena, theater seat -- - IG II² 5123; cf. Lewis, D. M. 1955, 11 no. 20</p>
<p :class="{'activeClass': idClicked == NP2478 }" id="NP2478" >LAETUS, honorary epigram for Ofellius Laetus, Platonic philosopher,1st c. A.C., built into the Parthenon west door jamb IG II² 3816; epigram attributed to T. Flavius Glaukos, Oliver, J. H. 1949, 257-258; V. Kontorini, “Ο Αύλος Οφέλλιος Μάιωρ Μακεδών (Dessau 8849),» Dodone 15, Part 1 (1986) 188, 201, pl. 197:a (dim photo); SEG 36 (1986) no. 248; Korres, M. 1994, 73 no. MB3, fig. 33; Byrne, S. G. 2003, 388 no. 7</p>
<p :class="{'activeClass': idClicked == NP2479 }" id="NP2479" >LAKEDAIMONIAN SHIELD, one of those captured at Sphakteria, 425/4 B.C.  IG I³ 522, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20262" target="_blank">Agora B 262</a>; SEG 10 (1949) no. 325; Paus.l.15.4; Agora Guide, 1990,105-106, 254-255, 284 no. 254, figs. 60-61</p>
<p :class="{'activeClass': idClicked == NP2480 }" id="NP2480" >LAKEDAIMONIANS, base for a statue of the Demos of the Lakedaimonians by Androboulos, ca. 30-20 B.C., Akr. IG II² 3448; D. Peppa-Delmouzou, «Ό Άνδριαντοποίος Άνδρόβουλος,» in Στηλη, Τόμος εις Μνημη Νικόλαου Κοντολέοντος, Athens, 1980, 434-439, fig. 1 and pls. 202 beta - 204</p>
<p :class="{'activeClass': idClicked == NP2481 }" id="NP2481" >LAKEDAIMONIANS, burial monument at state expense, 403 B.C., in back of the 2nd Kerameikos horos, on the southwest edge of the Road to the Academy IG II²  11678; SEG 37 (1987) no. 157; X. HG 2.4.33; Knigge, U. 1991,11,12, 38, 42, 161-161,162, fig. 156-157, 165 no. 63; <JBcom>[this tomb was not in the Demosion Sema]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2482 }" id="NP2482" >LAKIADAI, LAKIOS</p>
<p :class="{'activeClass': idClicked == NP2483 }" id="NP2483" >Lakiadai, deme on the Sacred Way east of the Kephisos: Paus. 1.37.2; Traill, J. S.1986, 133 with Map</p>
<p :class="{'activeClass': idClicked == NP2484 }" id="NP2484" >Lakiadai, trittys name: IG I³ 1120 = IG I² 884; Siewert, P. 1982,14 with note 70; see Trittys signposts no. 8</p>
<p :class="{'activeClass': idClicked == NP2485 }" id="NP2485" >Lakios the Hero, precinct on the Sacred Way in the deme of Lakiadai: Paus. 1.37.2</p>
<p :class="{'activeClass': idClicked == NP2486 }" id="NP2486" >LAKYDEION, garden in the Academy where Lakydes taught, a gift from Attalos I: D.L. 4.60; Chr. Habicht, “Athens and the Attalids in the Second Century B.C.,” Hesperia 59 (1990) 562</p>
<p :class="{'activeClass': idClicked == NP2487 }" id="NP2487" >LAMIDION, theater seat - - - IG II² 5124</p>
<p :class="{'activeClass': idClicked == NP2488 }" id="NP2488" >LAPHYSTIOS: see Phrixos sacrificing to Zeus Laphystios</p>
<p :class="{'activeClass': idClicked == NP2489 }" id="NP2489" >LATRINE, 1st c. A.C., outside the northeast corner of the Roman Agora: Travlos, J. 1971, 342-344, figs. 39 no. 82, 362 E, 443-445</p>
<p :class="{'activeClass': idClicked == NP2490 }" id="NP2490" >LEAGROS THE YOUNGER, choregic mon., ca. 440 B.C. — <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201740" target="_blank">Agora I 1740</a>, IG I³ 958</p>
<p :class="{'activeClass': idClicked == NP2491 }" id="NP2491" >LEAINA monument, bronze lioness on the Akr. beside a statue of Aphrodite: Paus. 1.23.2; G. P. Stevens, “The Periclean Entrance Court of the Acropolis of Athens,”, Hesperia 5 (1936) 451-454, 456</p>
<p :class="{'activeClass': idClicked == NP2492 }" id="NP2492" >LEIMON (λειμών), boundary stone, Akr. lower north slope 1st Ephor. BA 3; A. Choremi, «Βιβλιοθήκη Αδριανου,» Deltion 46 (1991) B1 Chr. 20 no. γ, ph.; SEG 44 (1994) no. 75; <JBcom>[perhaps marked the Limou Pedion (Field of Famine) q.v.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2493 }" id="NP2493" >LEMNIA: see Athena Ath72**</p>
<p :class="{'activeClass': idClicked == NP2494 }" id="NP2494" >LENAION, enclosure with with sanctuary of Dionysos Lenaios: Pickard-Cambridge, A. 1968, 25-42 with testimonia nos. 1-31; Despinis, G. 2003, 128-135, 142-143. Sites assigned to the Lenaion:</p>
<p :class="{'activeClass': idClicked == NP2495 }" id="NP2495" >In the Agora Square: Travlos, J. 1971, 566</p>
<p :class="{'activeClass': idClicked == NP2496 }" id="NP2496" >South of the Altar of the Twelve Gods: Kolb, F. 1981, 26-58; cf. Kuhn, G. 1985, 225 note 372</p>
<p :class="{'activeClass': idClicked == NP2497 }" id="NP2497" >In the Areopagus-Pnyx Valley: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM239">239</a> Conjecturally located on the Ilissos right bank: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM559">559</a> Conjectural <JBcom>[doubtable]</JBcom> location northeast/north of the Akropolis, Despinis, G. 2003, 130-132</p>
<p :class="{'activeClass': idClicked == NP2498 }" id="NP2498" >LEOKORION, heroon of Leos' daughters, on the Panathenaic Way inside a gate in the archaic City Wall: Th. 1.20.2, 6.57.1-3; Agora III, 1957, 108-113, nos. 317-338, Kron, U. 1976, 195-200; located to the south of a conjectural site of the archaic City Wall gate in question, Travlos, J. 1971, fig. 5 no. 20; SEG 48 (1998) no. 272; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM297">297</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM318">318</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM382">382</a></p>
<p :class="{'activeClass': idClicked == NP2499 }" id="NP2499" >LEOKRITOS</p>
<p :class="{'activeClass': idClicked == NP2500 }" id="NP2500" >State burial monument implied by Paus. 1.26.2, honours paid to him after he fell in battle See also: Zeus Zeu24*, Leokritos' shield</p>
<p :class="{'activeClass': idClicked == NP2501 }" id="NP2501" >LEON THE METROPOLITAN, tower built by him in 11th c., perhaps attested by an inscr. found on the Areopagus: G. A. Sotiriou, “Τά έρείπια τού παρά τον 'Άρειον Πάγον Βυζαντινού Ναού,” Deltion 2 (1916) 139-142, fig.14; Tanoulas, Τ. 1994a, 58; idem 1997, 287-288</p>
<p :class="{'activeClass': idClicked == NP2502 }" id="NP2502" >LEONTAS: see Herakles Hrk46*</p>
<p :class="{'activeClass': idClicked == NP2503 }" id="NP2503" >LEOS: Kron, U. 1976, 194-201; Kearns, E. 1989, 80 note 2, 86, 87, 88, 181 s.v. Leos (the Herald), Leos (the eponymous), and Daughters of Leos</p>
<p :class="{'activeClass': idClicked == NP2504 }" id="NP2504" >Sanctuary of The Hero, mentioned in an honorary ephebic decree of Leontis to be set up in the sanctuary, 333/2 B.C.: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203068" target="_blank">Agora I 3068</a> lines 32-33; B. D. Meritt, “Greek Inscriptions, Hesperia 9 (1940) 59-66 no. 8, ph.; S. I. Rotroff, “An Anonymous Hero in the Athenian Agora,” Hesperia 47 (1978) 206-207; perhaps <JBcom>[probably]</JBcom> the sanctuary was in Skambonidai, Kron, U, 1976, 201</p>
<p :class="{'activeClass': idClicked == NP2505 }" id="NP2505" >Eponymous Hero statue: Paus. 1.5.2; Kron, U. 1976, 200-201, 280 line 3</p>
<p :class="{'activeClass': idClicked == NP2506 }" id="NP2506" >Statue base with dedication by the prytaneis of Leontis and prytany catalogue, 400-350 B.C., built into the mediaeval tower in the Propylaia southwest wing IG II² 1742; Agora XV, 33-34 no. 13; SEG 29 (1979) no. 148</p>
<p :class="{'activeClass': idClicked == NP2507 }" id="NP2507" >LESCHAI, 360 in Athens: schol. Hes. Op. 493-495; SEG 46 (1996) no. 2388</p>
<p :class="{'activeClass': idClicked == NP2508 }" id="NP2508" >Lesche with 2 boundary stones, 4th c. B.C., built above the remains of a naiskos (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM234">234</a>), southwest of the Bakcheion on the west side of an ancient road IG II² 2620; W. Dörpfeld, “Die Ausgrabungen am Westabhange der Akropolis. I.,” AM 19 (1894) 503, pl. 14 E (plan showing the lesche extending under Apostolou Pavlou St. all the way to the Pnyx “Felsabhang” ); H. A. Thompson, “Activities in the Athenian Agora: 1958,” Hesperia 28 (1959) 98-99 with note 17; Ritchie Jr., C. E. 1985, 245-250, pl. 47</p>
<p :class="{'activeClass': idClicked == NP2509 }" id="NP2509" >LETHE, altar in the Erechtheion: Plu. Moralia 74i B </p>
<p :class="{'activeClass': idClicked == NP2510 }" id="NP2510" >LETO See Apollo Apo85*; Dionysos Dio7* </p>
<p :class="{'activeClass': idClicked == NP2511 }" id="NP2511" >Leto and Artemis, priestess' theater seat - - - IG II² 5156; Hedrick Jr., C. W. 1990, 60</p>
<p :class="{'activeClass': idClicked == NP2512 }" id="NP2512" >LEUKIPPIDAI: see Dioskouroi</p>
<p :class="{'activeClass': idClicked == NP2513 }" id="NP2513" >LEUKOPHRYENE: see Artemis Art40*</p>
<p :class="{'activeClass': idClicked == NP2514 }" id="NP2514" >LEUKOTHEA: see Pankrates and Palaimon</p>
<p :class="{'activeClass': idClicked == NP2515 }" id="NP2515" >LICINIUS: M. Licinius Crassus (PIR2, 186), proconsul of Macedonia, statue base, ca. 27 B.C., still on the Akr. IG II² 4118; Oliver, J. H. 1965, 51</p>
<p :class="{'activeClass': idClicked == NP2516 }" id="NP2516" >LIMNAI</p>
<p :class="{'activeClass': idClicked == NP2517 }" id="NP2517" >1) Marshy place with sanctuary of Dionysos: Th. 2.15.4; F GR HIST III B, 160, 328. Philochoros F 229, 744 lines 26-29 and suppl. vols. I, 594-595 and II, 486</p>
<p :class="{'activeClass': idClicked == NP2518 }" id="NP2518" >2) Deme in Attica, where Artemis is revered: Call. Dian. 172; Callimachus, ed. R Pfeiffer, Oxford, 1953, II, 63; hence Dörpfeld's sanctuary of Artemis in Limnai preceding a sanctuary of Artemis in the Bakcheion, W. Dörpfeld, “Die Ausgrabungen an der Enneakrounos. II.,” AM 19 (1894) 150</p>
<p :class="{'activeClass': idClicked == NP2519 }" id="NP2519" >LIMOU PEDION (Field of Famine), field behind the prytaneion given to Famine in accordance with an oracle: Zen. IV.93; J. McK. Camp II, “A Drought in the Late Eighth Century B.C.,” Hesperia 48 (1979) 402-403 with note 24; cf. Leimon</p>
<p :class="{'activeClass': idClicked == NP2520 }" id="NP2520" >LIONS</p>
<p :class="{'activeClass': idClicked == NP2521 }" id="NP2521" >Krenophylax, bronze fountain house lion: Poll. 8.113; Camp, J. McK. II 1980, 296-298</p>
<p :class="{'activeClass': idClicked == NP2522 }" id="NP2522" >Sculpture on the Akr. taken by the Venetians: Judeich, W. 1931, 110 note 4, 284 note 17</p>
<p :class="{'activeClass': idClicked == NP2523 }" id="NP2523" >Sculpture northwest of the Hephaisteion: plan of Athens, ca. 1687, pencil drawing in Bibl. Nationale, Paris; Omont, H. 1898, pl. 39</p>
<p :class="{'activeClass': idClicked == NP2524 }" id="NP2524" >Lion acroteria (?), 525-500 B.C., three lions perhaps from two matching pairs: Schrader, H., 1939, 269-272, nos. 382-384; Agora XI, 1965, 28-29 under no. 91; Danner, P. 1989, 91 A 19</p>
<p :class="{'activeClass': idClicked == NP2525 }" id="NP2525" >Lion, right, fragment of head and body, found in the Nike Bastion (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM15">15</a>) Acr. no number; Schrader, H. 1939, no. 382, fig. 306, pl. 166</p>
<p :class="{'activeClass': idClicked == NP2526 }" id="NP2526" >Lion, left, frags, of head, body, foreleg Acr. 3832-33; ibid., no. 383, fig. 307, pls. 167-168</p>
<p :class="{'activeClass': idClicked == NP2527 }" id="NP2527" >Lion, right, frag, of body and hindquarters Acr. 3834; ibid., no. 384, fig. 308</p>
<p :class="{'activeClass': idClicked == NP2528 }" id="NP2528" >Forepaws with plinth from lion right - - - Acr. 545; ibid., no. 384, fig. 309, belonging to one of the two lions moving to right</p>
<p :class="{'activeClass': idClicked == NP2529 }" id="NP2529" >Lion and boar, poros limestone pediment sculpture - - - Acr. 4550 +; Heberdey, R. 1919, 113-115, figs. 109-113</p>
<p :class="{'activeClass': idClicked == NP2530 }" id="NP2530" >Lion and bull groups, pediment sculpture: Group, early 5th c. B.C., found in the Olympieion area NM 1673 + Metropolitan Museum of Art 42.11.35; Travlos, J. 1971, fig. 385; tentatively assigned to Apollo Pythios, Karouzou, S. 1968, 15; Kaltsas, N. 2002, 69-70 no. 98, ph.</p>
<p :class="{'activeClass': idClicked == NP2531 }" id="NP2531" >Poros limestone group, early 5th c. B.C., found in the walls of a Byzantine vaulted reservoir - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201972" target="_blank">Agora S 1972</a>; Agora XI, 1965, 33-36 no. 95, pls. 15-16 See also: Athena Ath19*, Ath20*</p>
<p :class="{'activeClass': idClicked == NP2532 }" id="NP2532" >Painted Lioness pediment - - - Acr. 21, 63; Wiegand, Th. 1904, 230-231 no. 12, pl. VI no. 3; Heberdey, R. 1919, 125-127, figs. 137-138; Boersma, J. S. 1970, 232 no. 121, 237 no. 131; Bancroft, S. 1980, 56-57; mistakenly assigned to Building E by Schuchhardt, Klein, N. L. 1988, 27-28, 33</p>
<p :class="{'activeClass': idClicked == NP2533 }" id="NP2533" >Painted Lioness on a marble lintel, ca. 450-425 B.C., in the filling of the Post-Herulian Wall on the Akr. northwest slope near the Eleusinion - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20818" target="_blank">Agora A 818</a> +; G. P. Stevens, “Lintel with the Painted Lioness,” Hesperia 23 (1954) 169-184 with extra colour plate A and pls. 39-48</p>
<p :class="{'activeClass': idClicked == NP2534 }" id="NP2534" >LITHOKOPOl, theater seat - - - IG II² 5087</p>
<p :class="{'activeClass': idClicked == NP2535 }" id="NP2535" >LITHOPHOROS priest, theater throne - - - IG II² 5077; Clinton, K. 1974, 98</p>
<p :class="{'activeClass': idClicked == NP2536 }" id="NP2536" >LITHOS (Stones of special significance)</p>
<p :class="{'activeClass': idClicked == NP2537 }" id="NP2537" >The Lithophoros' Sacred Stone: SEG 30 (1980) no. 83 lines 15-16; Clinton, K. 1974, 98 nos. 1-2</p>
<p :class="{'activeClass': idClicked == NP2538 }" id="NP2538" >Unworked stone inscribed lithos, Roman pd., Akr. northwest slope in the Eleusinion <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206876" target="_blank">Agora I 6876</a>; SEG 42 (1992) no. 215; Agora XXXI, 1998, 20 with note 28, 205 no. 59, pl. 31; Papadopoulos, J. K. 2003, 291-292, figs. 5.10-11</p>
<p :class="{'activeClass': idClicked == NP2539 }" id="NP2539" >Archon's stone in front of the Stoa Basileos: Arist. Ath. VII.1, LV.5; Shear Jr., T. L. 1971, 259-260, fig. 1, pls. 47, 50:d; as an altar, Kuhn, G. 1985, 222-226, figs. 7, 12</p>
<p :class="{'activeClass': idClicked == NP2540 }" id="NP2540" >Stone on the Akropolis where the silen sat when Dionysos first came: Paus. 1.23.5; cf. E. Pfuhl, Malerei und Zeichnung der Griechen (München, 1923) vol. Ill, pl. 282 no. 665 See also: Index I under Stones and Rocks </p>
<p :class="{'activeClass': idClicked == NP2541 }" id="NP2541" >LIVIA, postea Iulia Augusta (PIR, 2nd ed., 301): Hoff, M. C. 1994, 111</p>
<p :class="{'activeClass': idClicked == NP2542 }" id="NP2542" >Hygieia Sebaste, dedication, Propylaia - - - IG II² 3240</p>
<p :class="{'activeClass': idClicked == NP2543 }" id="NP2543" >Julia Augusta Artemis Boulaia, statue base, 14-37 A.D, east of the Metroon and 11 m. north of the Southwest Temple (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM369">369</a>) - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204012" target="_blank">Agora I 4012</a>; SEG 22 (1967) no. 152; M. Crosby, “Greek Inscriptions,” Hesperia 6 (1937) 464-465 no. 12, ph.; Dinsmoor Jr., W. B. 1982a, 438 fig. 1 for the findspot</p>
<p :class="{'activeClass': idClicked == NP2544 }" id="NP2544" >Julia Augusta Pronaia, base of a statue dedicated by the agoranomoi, near the Gate of Athena Archegetis IG II² 3238; Geagan, D. J. 1967, 33 notes 9-10,124, 140</p>
<p :class="{'activeClass': idClicked == NP2545 }" id="NP2545" >Theater seat - - - IG II² 5161 See also: Hestia on the Akr.</p>
<p :class="{'activeClass': idClicked == NP2546 }" id="NP2546" >LIVY the historian, statue base, uncertain attribution, near the Propylaia IG II²  4141</p>
<p :class="{'activeClass': idClicked == NP2547 }" id="NP2547" >LOGISTERIA, offices of the logistai: Harp. s.v. λογισταί; Agora III, 1957, 126</p>
<p :class="{'activeClass': idClicked == NP2548 }" id="NP2548" >LOLLIUS, M. (PIR, 2nd ed., 311), statue base, west of the Parthenon IG II²  4139</p>
<p :class="{'activeClass': idClicked == NP2549 }" id="NP2549" >LONG WALLS: Paus. 1.2.2, 1.25.5; Maier, F. G. 1959,16-17, 21-67 nos. 1-11 and II, 1961, 117-118 no. 9:a; Judeich, W. 1931, 2, 75-76 note 1, 81, 83, 93 note 1, 111, 126, 155 note 1 testimonial 55-160, 162; Conwell, D. H. 1993, 1-701 (with testimonia 447-491 and bibliography 548-608), figs. 1-60</p>
<p :class="{'activeClass': idClicked == NP2550 }" id="NP2550" >Northern Wall, from Athens to the Peiraeus, built ca. 461-456 B.C.: Harp. s.v. Διά μέσου τείχους </p>
<p :class="{'activeClass': idClicked == NP2551 }" id="NP2551" >Phaleric Wall, from Athens to Phaleron, built with the Northern Wall: Th. 2.13.7</p>
<p :class="{'activeClass': idClicked == NP2552 }" id="NP2552" >Middle Wall, from Athens to Munichyia, begun after ca. 445 B.C., finished before 431 B:C. First called Middle Wall: Pl. Grg. 445 E with schol. Called Southern Wall after the Phaleric Wall went out of use: Harp. s.v. Διά μέσου τείχους</p>
<p :class="{'activeClass': idClicked == NP2553 }" id="NP2553" >LUCIUS VERUS</p>
<p :class="{'activeClass': idClicked == NP2554 }" id="NP2554" >Colossal head, theater - - - NM 350; Vermeule, C. C. 1968, 286, 397 no. 1; Albertson, F. C. 1981, 337-338 no. 2</p>
<p :class="{'activeClass': idClicked == NP2555 }" id="NP2555" >Deliberately mutilated head found at the Dipylon <JBcom>[i.e. Sacred Gate]</JBcom> in 1872 NM 3296; A. Datsouli-Stavridi, «Πορτραίτα τής έποχής των Άντωνίνων,» ΑΕ 1982, 221, pl. 53 delta</p>
<p :class="{'activeClass': idClicked == NP2556 }" id="NP2556" >Head, Kolokotroni St. Plan 2 epsilon—5) NM 3740; Albertson, F. C. 1981, 340-341 no. 4; Kaltsas, N. 2002, 345 no. 732, ph. See also: Marcus Aurelius</p>
<p :class="{'activeClass': idClicked == NP2557 }" id="NP2557" >LUCULLUS = L. Licinius Lucullus, statue base, Propylaia — IG II² 4104; cf. D. Peppa-Deimouzou, «Έπιγραφαι έκ τού Έπιγραφικού Μουσείου,» Deltion 25 (1970) A, 200 under no. 4; Maass, M. 1972, 54 note 101, pl. 23</p>
<p :class="{'activeClass': idClicked == NP2558 }" id="NP2558" >LYKABETTOS, hill in northeast Athens (Plan 2 gamma/delta—7/9): Antig. Mir. 12; Milchhöfer, A. 1891, 6; Judeich, W. 1931, 44, 45 note 1, 49</p>
<p :class="{'activeClass': idClicked == NP2559 }" id="NP2559" >LYKEION, LYKEIOS: see Apollo Apo40*-51*; Dromos; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM497">497</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM500">500</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM504">504</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM505">505</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM505B">505B</a></p>
<p :class="{'activeClass': idClicked == NP2560 }" id="NP2560" >LYKOMEDES, greatgrandfather of Lykourgos <JBcom>[II]</JBcom> the statesman and first priest of Poseidon on the Akropolis, ca. 450 B.C., state burial monument in the Kerameikos: Plu. Moralia 843 E, 852 A; Matthaiou, A. P. 1987, 38-39</p>
<p :class="{'activeClass': idClicked == NP2561 }" id="NP2561" >LYKON from Alexandria Troas, head of the Peripatetic School from ca. 270-226 B.C., statue specified in Lykon's will: D.L. 5.71; Frischer, B. 1982, 94</p>
<p :class="{'activeClass': idClicked == NP2562 }" id="NP2562" >LYKOPHRON <JBcom>[I]</JBcom>, son of Lykourgos <JBcom>[I]</JBcom> and father of Lykourgos <JBcom>[II]</JBcom> the statesman, and OTHERS, grave stele, ca. 375-350 B.C., near the Academy at the intersection of Vasilikon and Kratylou Sts. (Plan 3 beta—7) 3rd Ephoria no. 2191; Matthaiou, A. P. 1987, 31-43, pl. 8; SEG 37 (1987) no. 161</p>
<p :class="{'activeClass': idClicked == NP2563 }" id="NP2563" >LYKOPHRON <JBcom>[III]</JBcom>, youngest son of Lykourgos <JBcom>[II]</JBcom>, grave lekythos, after 306/5 B.C., at the intersection of Vasilikon and Kratylou Sts. - - - 3rd Ephoria no. 2290; Matthaiou, A. P. 1987, 35-38, pl. 10; <JBcom>[ on the side visible in the photo no relief representation]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2564 }" id="NP2564" >LYKOS: see Apollo Lykeios; Court near Lykos; Heroes Hro20*</p>
<p :class="{'activeClass': idClicked == NP2565 }" id="NP2565" >LYKOURGOS <JBcom>[I]</JBcom>, son of Lykomedes and grandfather of Lykourgos <JBcom>[II]</JBcom> the statesman, state burial monument in the Kerameikos: Plu. Moralia 843 E; Matthaiou, A. P. 1987, 38-40</p>
<p :class="{'activeClass': idClicked == NP2566 }" id="NP2566" >LYKOURGOS <JBcom>[II]</JBcom>, son of Lykophron</p>
<p :class="{'activeClass': idClicked == NP2567 }" id="NP2567" >Armamentarium: Rut. Lup. 1.7</p>
<p :class="{'activeClass': idClicked == NP2568 }" id="NP2568" >Base for a bronze statue decreed in 307/6 B.C., near the Eponymous Heroes - - - IG II² 3776; Paus. 1.8.2; Agora III, 1957, 213-4; Richter, G. M. A. 1965, II, 1965, 212, fig. 1365</p>
<p :class="{'activeClass': idClicked == NP2569 }" id="NP2569" >Early Roman statue base, found in the chapel of Panagia Pyrgiotissa in the Stoa of Attalos IG II² 4259; ibid., 1965, 212, fig. 1366</p>
<p :class="{'activeClass': idClicked == NP2570 }" id="NP2570" >Stele recording Lykourgos' deeds in the palaestra built by Lykourgos in the Lykeion: Plu. Moralia 843 F; Delorme, J. 1960, 56</p>
<p :class="{'activeClass': idClicked == NP2571 }" id="NP2571" >State burial monuments for Lykourgos and his sons (Habron, Lykourgos and Lykophron), inscribed trapezai in the garden of the philosopher Melanthios opposite Athena Paionia: Plu. Moralia 842 E and 852 A; Paus. 1.29.15 mentions only Lykourgos' grave mon. on the Road to the Academy; Matthaiou, A. P. 1987, 31-43; Billot, M.-F. 1989, 788-789</p>
<p :class="{'activeClass': idClicked == NP2572 }" id="NP2572" >Wooden statues of Lykourgos and his sons, Habron, Lykourgos and Lykophron, by Timarchos and Kephisodotos, sons of Praxiteles, in the Erechtheion, probably set up after 307 B.C: Plu. Moralia 843 F; Caskey, L. D. et al. 1927, 478 with note 3; R. von den Hoff, op. cit. (under Demetrios of Phaleron), 179-180</p>
<p :class="{'activeClass': idClicked == NP2573 }" id="NP2573" >Picture in the Erechtheion: see Eteoboutad priests</p>
<p :class="{'activeClass': idClicked == NP2574 }" id="NP2574" >LYSIKRATES, choregic monument with frieze representing Dionysos and the pirates, 335/334 B.C., on the ancient Street of The Tripods (present-day Plateia Lysikratous, Plan 1 eta—8, no. 77) IG II² 3042; Travlos, J. 1971, 348-352, figs. 450-452, 709-710; H. Bauer, “Lysikratesdenkmal, Baubestand und Rekonstruktion,” AM 92 (1977) 197-227, figs. 1-7, pls. 91-6, Beilagen 5-10; Wilson, P. 2000, 219-226, figs. 11-14; <JBcom>[possibly the temple of Dionysos mentioned by Pausanias 1.20.2]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2575 }" id="NP2575" >Demosthenes' Lantern, old name for the Lysikrates Mon.: Akominatos Michael Choniates, ed. Sp. Lambros, 1879, Athens, Vol. I, 98; H. Riemann, RE Suppl. VIII, 1956, 268 (Tripodes)</p>
<p :class="{'activeClass': idClicked == NP2576 }" id="NP2576" >Lamp on a hundred-foot pillar made by Plato mentioned by Lambert le Tort in a 12th c. Alexander romance: F. Gregorovius, Geschichte der Stadt Athen im Mittelalter, Stuttgart, 1889, I, 242 note 2</p>
<p :class="{'activeClass': idClicked == NP2577 }" id="NP2577" >LYSIMACHE, daughter of Drakontides, priestess of Athena Polias, statue base, ca. 360 B.C., Akr. - - - IG II² 3453; : Plin. Nat. XXXIV.76; Davies, J. K. 1971, 170; SEG 30 (1980) no. 140</p>
<p :class="{'activeClass': idClicked == NP2578 }" id="NP2578" >LYSIMACHE, daughter of Lysistratos, priestess of Athena Polias, base of statue by Kephisodotos and Timarchos, ca. 300-290 B.C., at the Erechtheion - - - IG II² 3455; Davies, J. K. 1971, 171-172; Aleshire S. B. 1994, 336</p>
<p :class="{'activeClass': idClicked == NP2579 }" id="NP2579" >LYSIMACHIDES, son of Lysimachos, archon 339/8 B.C.</p>
<p :class="{'activeClass': idClicked == NP2580 }" id="NP2580" >Lysimachides' dedication, relief representing either Amynos or, more probably, Lysimachides with a giant sculpture of an afflicted leg and two small votive feet in back, ca. 340 B.C., Amyneion at the north precinct wall between the gate and Base A NM 3526, IG II² 4387; A. Koerte, “Bezirk eines Heilgottes,” AM 18 (1893) 233 with fig. 1 (the findspot) and 235-238 with pl. 11; Peek, W. 1980, 29 no. 25; Kaltsas, N. 2002, 227 no. 477, ph.</p>
<p :class="{'activeClass': idClicked == NP2581 }" id="NP2581" >Lysimachides' family grave plot on the Street of the Tombs, Kerameikos Excavations: assigned to a descendant of the archon, Lysimachides; Knigge, U. 1991, 126 no. 24, fig. 165 no. 24</p>
<p :class="{'activeClass': idClicked == NP2582 }" id="NP2582" >Kioniskos of Lysimachides, son of Lysimachos IG II² 5813; Davies, J. K. 1971, 357 f. no. 9480</p>
<p :class="{'activeClass': idClicked == NP2583 }" id="NP2583" >So-called Charon Relief, ca. 330-320 B.C. ?, with two reclining heroes each with a seated heroine, tables with food, and, in the lower left, an old man in a boat — Kerameikos P 692; A. Scholl, “Das ‘Charonrelief im Kerameikos,” Jahrbuch 108 (1993) 353-373, fig. 1, pls. 1-3. <JBcom>[The man in the boat may be Charon or Lysimachides or someone else; a connexion with Lysimachides is not proven]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2584 }" id="NP2584" >LYSIMACHOS of Macedon, successor of Alexander, statue in front of the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.9.4; Kotsidu, H. 2000, 31-32</p>
<p :class="{'activeClass': idClicked == NP2585 }" id="NP2585" >LYSIZONOS: see Artemis Art41*</p>
<p :class="{'activeClass': idClicked == NP2586 }" id="NP2586" >MACHAON and PODALEIRIOS, base of a statue group, ca. mid-4th c. B.C., Akr. South Slope - - - IG II² 4353; Beschi, L. 1969/1970, 106-107, fig. 12; SEG 42 (1992) no. 180 See also: Asklepios Ask24*</p>
<p :class="{'activeClass': idClicked == NP2587 }" id="NP2587" >MAECENAS, statue base, Propylaia — IG II² 4133 </p>
<p :class="{'activeClass': idClicked == NP2588 }" id="NP2588" >MAIMAKTES: see Zeus Zeu54*</p>
<p :class="{'activeClass': idClicked == NP2589 }" id="NP2589" >MAKRA STOA, adjoining Kolonos Agoraios: schol. Ar. Av. 997; Agora III, 21 No. 3; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM386">386</a></p>
<p :class="{'activeClass': idClicked == NP2590 }" id="NP2590" >MAKRAI, name for the Akropolis northwest slope cliffs: E. Ion 11-13; Judeich, W. 1931, 301 note 6</p>
<p :class="{'activeClass': idClicked == NP2591 }" id="NP2591" >MAN IN ARMOUR fighting, picture by Antidotos: Plin. Nat. XXXIV.130</p>
<p :class="{'activeClass': idClicked == NP2592 }" id="NP2592" >MANTINEA, picture by Euphranor of Epaminondas killed by Gryllos, son of Xenophon, in the cavalry engagement at Mantinea, in the Stoa of Zeus: Paus. 1.3.4, 8.9.8, 8.11.6, 9. 15.5; Palagia, O. 1980, 1-2 no. 7, 2-3 no. 14, 3-4 no. 18, 50-54</p>
<p :class="{'activeClass': idClicked == NP2593 }" id="NP2593" >MARATHONOMACHIA, picture by Panainos in the Stoa Poikile: Paus. 1.15.3, 1.21.2, 5.11.6; the picture did not have inscribed names, Gauer, W. 1968b, 136-138; E. B. Harrison, “The South Frieze of the Nike Temple and the Marathon Painting in the Painted Stoa,” AJA 76 (1972) 370-378, testimonia; the picture had inscribed names, Palagia, O. 1980, 54 note 301; Kearns, E. 1989, 45-46; Di Cesare, R. 2002, 43-47 Noticed by Pausanias in the picture: Aischylos, Athena, Barbarians (3), Greeks, Herakles, Heros Echetlos, Heros Marathon, Kallimachos, men of Attica, Miltiades, Phoenician ships, Plataians, Theseus rising out of the ground. Not mentioned by Pausanias: Boutes; Datis and Artaphernes, Plin. Nat. XXXV.57; an Athenian and his brave dog, Ael. NA 7.38; Epizelos, Ael. NA 7.38; Kynegeiros, Ael. NA 7.38; Medes, Pers. Ili.53; the ten archons with Miltiades foremost “numero prima,” Nepos, Milt. 6,3; cf. Agora III, no. 96 <br>see Attalid dedication on the Akropolis; Persian War Memorials</p>
<p :class="{'activeClass': idClicked == NP2595 }" id="NP2595" >MARCELLINUS' GATE TOWERS: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM24">24</a></p>
<p :class="{'activeClass': idClicked == NP2596 }" id="NP2596" >MARCUS AURELIUS = M. Annius Verus (PIR, 2nd ed., 697); Geagan, D. 1979, 402-404</p>
<p :class="{'activeClass': idClicked == NP2597 }" id="NP2597" >Head with three crosses carved on the forehead - - - Acr. 7248; Delivorrias, A. 1991, 112 with note 20</p>
<p :class="{'activeClass': idClicked == NP2598 }" id="NP2598" >Statue base, 176 A.D., theater - - - IG II² 3410; Maass, M. 1972, 56, pl. 23</p>
<p :class="{'activeClass': idClicked == NP2599 }" id="NP2599" >Head - - - NM 514; Vermeule, C. C. 1968, 280, 394 no. 1; Albertson, F. C. 1981, 153-154 no. 2</p>
<p :class="{'activeClass': idClicked == NP2600 }" id="NP2600" >MARCUS AURELIUS and LUCIUS VERUS, altars: Raubitschek, A. E. 1966, 250-251, pl. 67; SEG 23 (1968) nos. 108-109; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 74</p>
<p :class="{'activeClass': idClicked == NP2601 }" id="NP2601" >Cylindrical altar, 163/164 A.C., with prayer for victory in the Parthian War - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204039" target="_blank">Agora I 4039</a></p>
<p :class="{'activeClass': idClicked == NP2602 }" id="NP2602" >Altar, between the Stoa of Attalos and Monastiraki IG II² 3403</p>
<p :class="{'activeClass': idClicked == NP2603 }" id="NP2603" >Altar, Aiolou St. - - - IG II² 3405</p>
<p :class="{'activeClass': idClicked == NP2604 }" id="NP2604" >Altar, Church of Agathokleia (Chr.Mon. CM27*) on Ermou St, not far from and west of Monastiraki IG II² 3406</p>
<p :class="{'activeClass': idClicked == NP2605 }" id="NP2605" >MARDONIOS' dagger in the temple of the Polias: Paus. 1.27.1; Harris, D. 1995, 217</p>
<p :class="{'activeClass': idClicked == NP2606 }" id="NP2606" >MARS ULTOR: see Ares, temple statue</p>
<p :class="{'activeClass': idClicked == NP2607 }" id="NP2607" >MARSYAS: see Athena 122M24*</p>
<p :class="{'activeClass': idClicked == NP2608 }" id="NP2608" >MASISTIOS' cuirass in the temple of the Polias: Paus. 1.27.1; D. Harris, 1995, 204-205</p>
<p :class="{'activeClass': idClicked == NP2609 }" id="NP2609" >MATIDIA, sister of Sabina Augusta, statue base, ca. 120 A.D. - - - IG II² 3388; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 72; SEG 34 (1984) no. 183</p>
<p :class="{'activeClass': idClicked == NP2610 }" id="NP2610" >MEDONTIDAI, marker of property or meeting-place, after 450 B.C., below the Akropolis entrance - - - IG I³ 1062 = IG I² 871; SEG 48 (1998) no. 79; a phratry inscr., Hedrick Jr., C. W. 1984, 281-292; idem, “Phratry Shrines of Attica and Athens "Hesperia 60 (1991) 245; Lambert, S. D. 1993, 310 T 7, 316-318</p>
<p :class="{'activeClass': idClicked == NP2611 }" id="NP2611" >MEGALOI THEOI, cult association (oikos) probably of metics: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206108" target="_blank">Agora I 6108</a> lines 7-8; SEG 21 (1965) no. 536; L. Robert, “Deux decrets d'une association a Athenes,” AE 1969, 7-14; Agora XVI, 1997, 452-453 no. 324 and 454-455 no. 325; the mystery cult of the Megaloi Theoi located in the Metroon, Torelli, M, 1995, 16; cf. B. Hemberg, Die Kabiren, Uppsala, 1950, 373 ff., Index IV s.v. Anakes, Dioskuren</p>
<p :class="{'activeClass': idClicked == NP2612 }" id="NP2612" >MEGISTE, three theater seats - - - IG II² 5105, 5107, 5138; probably all three were priestesses of Athena Polias, Lewis, D. M. 1955, 9 no. 13, 10 no. 16, 12 paragraph 5; Aleshire, S. B. 1994, 336 no. 16</p>
<p :class="{'activeClass': idClicked == NP2613 }" id="NP2613" >MEILICHIOS: see Zeus Zeu1*, Zeu55*-66*</p>
<p :class="{'activeClass': idClicked == NP2614 }" id="NP2614" >MEIZON, lawcourt: Poll. 8.121; F GR HIST III B, suppl. vol. I, 165-6; Agora XXVIII, 1995, Index s.v. Meizon</p>
<p :class="{'activeClass': idClicked == NP2615 }" id="NP2615" >MELANAIGIS: see Dionysos Dio45*</p>
<p :class="{'activeClass': idClicked == NP2616 }" id="NP2616" >MELANIPPOS, son of Theseus, heroon in Melite: Harp. s.v. Μελανιππειον; F GR HIST III B, 52, Kleidemos 323 F 2 with suppl. vol. I, 63, 314; Kearns, E. 1989, 123, 184</p>
<p :class="{'activeClass': idClicked == NP2617 }" id="NP2617" >MELANOPOS and MAKARTATOS, state burial monument with horsemen relief, 458/7 B.C., battle of Tanagra; Paus. 1.29.6; <JBcom>[mistakenly]</JBcom> linked to <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201898" target="_blank">Agora I 1898</a> = IG I³ 1288 (ca. 410? B.C.); Agora XVII, 89 no. 375; Bugh, G. R. 1988, 43-44; Pritchett, W. K. 1985, 148 note 161; SEG 38 (1988) no. 30</p>
<p :class="{'activeClass': idClicked == NP2618 }" id="NP2618" >MELANTHIOS, philosopher of the Academy, garden on the Road to the Academy; Plu. Moralia 842 E</p>
<p :class="{'activeClass': idClicked == NP2619 }" id="NP2619" >MELESANDROS, Athenian strategos killed in Lycia, 430/429 B.C., state burial mon. on the Road to the Academy: Paus. 1.29.7; Develin, R. 1989, 119; Pritchett, W. K. 1985, 148, Table 3 s.v. Coast of Caria</p>
<p :class="{'activeClass': idClicked == NP2620 }" id="NP2620" >MELITE, daughter of Myrmex, eponymous heroine of the deme: E. Kearns, 1989, 94, 99,184; LIMC VI, 1992, 446-447, s.v. Melite II (A. Spetsieri-Choremi)</p>
<p :class="{'activeClass': idClicked == NP2621 }" id="NP2621" >Deme, west of the Agora: Str. 1.4.7; Kron, U. 1979, 62 with note 68; Traill, J. S. 1986, 134 with Map; Stroud, R. S. 1998, 87, 89-90, 102-104, 107; see Zeus Zeu1*</p>
<p :class="{'activeClass': idClicked == NP2622 }" id="NP2622" >House (oikos) of the people of Melite, used for rehearsals of tragedy: Hsch. s.v. Μελιτέων οίκος, FAC I, 1957, 558; A. D. Keramopoullos, «To Βουλευτηριον των τεχνιτών έν Άθηναίς,» Deltion 11 (1927-28)<JBcom>[1930]</JBcom> 121-122; Hedrick Jr., C. W. 1990, 50-51</p>
<p :class="{'activeClass': idClicked == NP2623 }" id="NP2623" >Melitides Gate: Paus. 1.23.9; gate in the Themistoklean City Wall, west of the peak of Philopappos, north of the intersection of Miniak and Arakynthou Sts., Judeich, W. 1931, 140, Plan I C-7, Plan IV; <JBcom>[mistakenly]</JBcom> identified with the gate in the Diateichisma in the saddle between the Pnyx and the Hill of the Nymphs, Travlos, J., 1971, 161, Gate XV Melitides Gate, figs. 28, 219 which shows a road heading south connecting Travlos' Melitides Gate with Judeich's Melitides Gate in the Themistoklean City Wall; Conwell, D. H. 1993, 220-221 cf. Diateichisma; Themistoklean City Wall Wal29*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM586">586</a></p>
<p :class="{'activeClass': idClicked == NP2624 }" id="NP2624" >MELPOMENOS: see Dionysos Dio46*-50*</p>
<p :class="{'activeClass': idClicked == NP2625 }" id="NP2625" >MEMMIUS, C. M., L.f.: house with sacrarium rented by the sculptor C. Avianus Evander, 51 B.C.: Cic. Fam.XIII.2; F. Marx, “Der Bildhauer C. Evianus Euander und Ciceros Briefe,” in Festschrift fur Otto Benndorf zu seinem 60. Geburtstage, K. Masner ed., Vienna, 1898, 37-45</p>
<p :class="{'activeClass': idClicked == NP2626 }" id="NP2626" >MEN (Μην), reliefs</p>
<p :class="{'activeClass': idClicked == NP2627 }" id="NP2627" >Μην on a ram with a crescent moon behind and an offering table below, valley between the Areopagus and the Pnyx: Lane, E. 1971/1978, I, 1971, 1 no. 1, pl. I; III, 1976, 1, 2, 3, 13, 101, 104</p>
<p :class="{'activeClass': idClicked == NP2628 }" id="NP2628" >Μην on a ram with a crescent moon behind and an offering table below, Kolonos Agoraios - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20726" target="_blank">Agora S 726</a>; ibid., I, 1971, 3 no. 4, pl. Ill; III, 1976, 1, 2, 3, 13, 101-102</p>
<p :class="{'activeClass': idClicked == NP2629 }" id="NP2629" >Μην Ouranios, star and crescent moon, dedication, Athens or Peiraeus - - - IG II² 4818; ibid.,I, 1971, 4-5 no. 7 with drawing in text; III, 1976, 1, 5, 7, 76 See also: Helios and M.; Pan Pan18*</p>
<p :class="{'activeClass': idClicked == NP2630 }" id="NP2630" >MENANDER</p>
<p :class="{'activeClass': idClicked == NP2631 }" id="NP2631" >Grave on the road from Peiraeus: Paus. 1.2.2; Conwell, D. H. 1993, 228</p>
<p :class="{'activeClass': idClicked == NP2632 }" id="NP2632" >Portrait head Acr. 3647; Inst. phot. 83-123; K. Fittschen, “Zur Rekonstruktion griechischer Dichterstatuen. 1. Teil: Die Statue des Menander,” AM 106 (1991) 251 no. 59a; SEG 41 (1991) no. 1862</p>
<p :class="{'activeClass': idClicked == NP2633 }" id="NP2633" >Statue in the theater - - - IG II² 3777; Paus. 1.21.1; set up in the east parodos of the theater of Dionysos soon after 293/292 B.C., K. Fittschen, op. cit., 268 - 275, fig. 1, pls. 76-77</p>
<p :class="{'activeClass': idClicked == NP2634 }" id="NP2634" >Menander's name inscribed on the inside of the Pompeion front wall, on the string course above the orthostates, picture label (?), 2nd c. B.C. IG II² 4256; Kerameikos X, 120,124, figs. 91-92,149; SEG 35 (1985) no. 160</p>
<p :class="{'activeClass': idClicked == NP2635 }" id="NP2635" >Head - - - NM 3292; Richter, G. M. A. 1965, II, 233 no. 44, figs. 1630-1632; K. Fittschen, op. cit, 251 no. 59; Kaltsas, N. 2002, 282-283 no. 594, ph.</p>
<p :class="{'activeClass': idClicked == NP2636 }" id="NP2636" >MENESTHEUS; E. Kearns, 1992,185 Epitaph: Arist. Pepl. no. 34 See also: Kassandra; Trojan Horse</p>
<p :class="{'activeClass': idClicked == NP2637 }" id="NP2637" >MENODOROS (or Zenodoros), son of Eumenes, bronze statue in the Agora beside the statue of King Antiochos VII, decreed ca. 135 B.C.: IG II² 937 +; SEG 38 (1988) no. 112 lines 45-46; Habicht, Chr. 1989, 22-24; Agora XVI, no. 310, 437-441; Schenkungen hellenistischer Herrscher, Part I, 1995, 57-59</p>
<p :class="{'activeClass': idClicked == NP2638 }" id="NP2638" >MENODOROS, son of Gnaios, monument signed by Tyrian sculptors commemorating 32 athletic victories, ca. 150-130 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20382" target="_blank">Agora I 382</a> +, IG II² 3147+; I. E. Stephanis, «Ό Μηνόδωρος από την Άθήνα: περίπου 135-130 π.Χ.,» Ἐλληνικά 39 (1988) 281-286; SEG 38 (1988) ηο.178</p>
<p :class="{'activeClass': idClicked == NP2639 }" id="NP2639" >MENYTES: see Herakles Hrk47*</p>
<p :class="{'activeClass': idClicked == NP2640 }" id="NP2640" >MERCURY: see Jupiter Optimus Maximus</p>
<p :class="{'activeClass': idClicked == NP2641 }" id="NP2641" >MESON, lawcourt: Poll. 8.121; F GR HIST III B, 74, Androtion 324 F 59 with suppl. vol. I, 164-7; Agora XXVIII, Index s.v. Meson</p>
<p :class="{'activeClass': idClicked == NP2642 }" id="NP2642" >MESSALINA, Statilia, Nero's third wife, statue base, 66-68 A.D., Akr. IG II²  3280; Carroll, K. K. 1982, 32</p>
<p :class="{'activeClass': idClicked == NP2643 }" id="NP2643" >METAGEITNION: see Apollo Apo52*</p>
<p :class="{'activeClass': idClicked == NP2644 }" id="NP2644" >METER, rock-cut sanctuary marker, ca. 350 B.C., on the lower north slope of Mouseion Hill 10 m. above the rock-cut chamber formerly called Kimon's grave: Peek, W. 1942, 149-150 no. 323; Ritchie Jr., C. E. 1985, 268-271, pl. 51, suppl. fig. 10; SEG 44 (1994) no. 74 cf. Mother of The Gods</p>
<p :class="{'activeClass': idClicked == NP2645 }" id="NP2645" >METIOCHEION: see Court of Metiochos</p>
<p :class="{'activeClass': idClicked == NP2646 }" id="NP2646" >METON the astronomer</p>
<p :class="{'activeClass': idClicked == NP2647 }" id="NP2647" >House, adjacent to the Stoa Poikile: Ael. VH XIII. 12; Agora III, no. 48</p>
<p :class="{'activeClass': idClicked == NP2648 }" id="NP2648" >Astronomical device run by water (?), on Kolonos Agoraios: schol. Ar. Av. 997; F GR HIST III B, 135, Philochoros 328 F 122 with suppl. vol. I, 496-8 and vol. II, 401 note 2; Camp, J. McK. II 1980, 324-325; W. Kendrick Pritchett, Athenian Calendars and Ekklesias, Amsterdam, 2001, 98-99</p>
<p :class="{'activeClass': idClicked == NP2649 }" id="NP2649" >Sundial on the Pnyx by the wall at the Assembly Place: schol. Ar. Av. 997; rock-cut beddings tentatively assigned, Kourouniotis, K. and H. A. Thompson, 1932, 207-11, figs. 66-67, pl. 1; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM254">254</a></p>
<p :class="{'activeClass': idClicked == NP2650 }" id="NP2650" >METRAGYRTES, Phrygian mendicant priest, statue and grave in a Bouleuterion consecrated to the Mother of The Gods which the plague-stricken Athenians built to atone for his murder: Phot. s.v. μητραγύρτης; Agora III, nos. 487, 492; Shear Jr., T. L. 1995, 173-174, 175-176, 177-178</p>
<p :class="{'activeClass': idClicked == NP2651 }" id="NP2651" >METROBIOS AND OTHERS, rock-cut commemorative (?) inscr., Akr. South Slope above the theater - - - IG II² 13242; Μ. K. Langdon, “The Attic Tituli Memoriales,” GRBS 24 (1983) 70</p>
<p :class="{'activeClass': idClicked == NP2652 }" id="NP2652" >METRODOROS, Epicurus' friend and follower, portrait bust, 2nd c. A.C. NM 368; Richter, G. M. A. 1965, II, 202 no. 16, figs. 1255-1257; Kaltsas, N. 2002, 342 no. 724, ph.</p>
<p :class="{'activeClass': idClicked == NP2653 }" id="NP2653" >METRONOMOI, inscription concerning weights and measures set up by the Metronomoi, 222/221 B.C., found in situ in South Stoa Room 3 - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207030" target="_blank">Agora I 7030</a>; E. Vanderpool, “Metronomoi,” Hesperia 37 (1968) 73-76, pl. 9:a; SEG 24 (1969) no. 157</p>
<p :class="{'activeClass': idClicked == NP2654 }" id="NP2654" >METROON: see Mother of The Gods</p>
<p :class="{'activeClass': idClicked == NP2655 }" id="NP2655" >MIDDLE WALL: see Long Walls</p>
<p :class="{'activeClass': idClicked == NP2656 }" id="NP2656" >MILETOS: see Athens and Miletos, document relief</p>
<p :class="{'activeClass': idClicked == NP2657 }" id="NP2657" >MILTIADES, son of Kimon See Athena Ath29*; Marathonomachia</p>
<p :class="{'activeClass': idClicked == NP2658 }" id="NP2658" >House still existing in Demosthenes' time: D. 3.26</p>
<p :class="{'activeClass': idClicked == NP2659 }" id="NP2659" >Statues of Miltiades and Themistokles in the Prytaneion relabelled as a Roman and a Thracian: Paus. 1.18.3; Gauer, W. 1968b, 128-132, 144; accepting Louis Robert's suggestion that they were relabelled as the Thracian king Rhoimetalkes and Gaius Julius Nicanor, H. Whittaker, “Pausanias and his Use of Inscriptions,” Symbolae Osloensis 66 (1991) 178 with note 9</p>
<p :class="{'activeClass': idClicked == NP2660 }" id="NP2660" >Miltiades with a Persian captive and Themistokles with a Persian captive, a pair of statue groups in the theater: Aristid. XLVI.161 with schol.; Richter, G. M. A. 1965, I, 95 no. 4, 96 with figs. 393-401 (the coins), 98b; cf. Agora XXVI, 123 note 52</p>
<p :class="{'activeClass': idClicked == NP2661 }" id="NP2661" >MILTIADES, son of Zoilos, base of a statue by Eucheir and Euboulides, mid-2nd c. B.C., Akr. North Slope at Hag. Dimitrios tou Katephori (Chr. Mon. CM59*) IG II²  3867; Gauthier, Ph. 1985, 79, 105</p>
<p :class="{'activeClass': idClicked == NP2662 }" id="NP2662" >MINERVA: see Erechtheion</p>
<p :class="{'activeClass': idClicked == NP2663 }" id="NP2663" >MINT for bronze coinage: J. McK. Camp and J. H. Kroll, “The Agora Mint and Athenian Bronze Coinage,” Hesperia 70 (2001) 129-162, figs. 1-27; cf. Argyrokopeion</p>
<p :class="{'activeClass': idClicked == NP2664 }" id="NP2664" >1) Mint for bronze coinage of the 4th-1st c. B.C., structure west of the Panathenaic Way and east of the Southeast Fountain House (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM298">298</a>)</p>
<p :class="{'activeClass': idClicked == NP2665 }" id="NP2665" >2) The mint for Hadrianic and Antonine coinage has not been found: J. McK. Camp and J. H. Kroll, op. cit., 160</p>
<p :class="{'activeClass': idClicked == NP2666 }" id="NP2666" >3) In the mid-3rd c. A.C. the Southwest Enclosure (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM345">345</a>) served as the mint: loc. cit.</p>
<p :class="{'activeClass': idClicked == NP2667 }" id="NP2667" >MISERICORDIA: see Eleos</p>
<p :class="{'activeClass': idClicked == NP2668 }" id="NP2668" >MITHRAS</p>
<p :class="{'activeClass': idClicked == NP2669 }" id="NP2669" >Dedication, portrait bust of Chrysippos, 1st/2nd c. C.E., above the theater IG II² 3794; NM 4542; Richter, G. M. A. 1972, 1,104 fig. 68; Trianti, I. 2002, 161-162, fig. 15</p>
<p :class="{'activeClass': idClicked == NP2670 }" id="NP2670" >Mithras slaying a bull, relief dedicated to Helios Mithras Aniketos, late 2nd c. C.E., marble pile at the Tower of the Winds: A. N. Oikonomides, Mithraic Art, Chicago, 1975, 80-81 no. 3 with sketch See also: Helios, head</p>
<p :class="{'activeClass': idClicked == NP2671 }" id="NP2671" >MNEMOSYNE</p>
<p :class="{'activeClass': idClicked == NP2672 }" id="NP2672" >Dedication on a base or an altar, ca. 300 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206578" target="_blank">Agora I 6578</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 32 (1963) 45-46 no. 62, pl. 12; SEG 25 (1971) no. 231</p>
<p :class="{'activeClass': idClicked == NP2673 }" id="NP2673" >Statue in the precinct of Dionysos Melpomenos: Paus. 1.2.5; according to a suggestion of Giorgos Despinis, the head wrongly associated with the statue NM 233 could belong to Mnemosyne in the group associated with the Euboulides Monument, Despinis, G. 1995, 331-334, pls. 62-64; Kaltsas, N. 2002, 282 no. 593, ph.</p>
<p :class="{'activeClass': idClicked == NP2674 }" id="NP2674" >MNESIMACHE and her son, statues at the Kephisos: Paus. 1.37.3</p>
<p :class="{'activeClass': idClicked == NP2675 }" id="NP2675" >MOIRAGETES: see Moirai</p>
<p :class="{'activeClass': idClicked == NP2676 }" id="NP2676" >MOIRAI Theater seat IG II² 5137</p>
<p :class="{'activeClass': idClicked == NP2677 }" id="NP2677" >Moirai, Zeus Moiragetes, Ge, to whom the Praxiergidai, in accordance with an oracle from Apollo, sacrified before enrobing Athena Polias: IG I³ 7 line 12; SEG 50 (2000) no. 32; H. Schwabl, RE Suppl. XV, 1978, col.1071 (Zeus Moiragetes) See also: Aphrodite Aph44*</p>
<p :class="{'activeClass': idClicked == NP2678 }" id="NP2678" >MOIROKLES, father of the famous Kallippos (general at Thermopylai in 279/8 B.C.), grave naiskos, ca. 350/325 B.C., over 1400 m. distant from the Dipylon Gate on the Road to the Academy Gymnasium IG II² 6043; G. Karo, “Archaologische Funde aus dem Jahre 1929 und der ersten Halite von 1930,” AA 1930, 92-94; neither A. P. Matthaiou (Horos 5 (1987) 21 s.v. Μοιροκλής Έλευσίνιος) nor S. B. Aleshire and J. Binder who searched high and low in 1990 were able to locate this stone</p>
<p :class="{'activeClass': idClicked == NP2679 }" id="NP2679" >MOLOTTOS (Molossos) the strategos, died in 374 B.C., stele and grave on the Sacred Way after Anthemokritos: Paus. 1.36.4; <JBcom>[probably a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2680 }" id="NP2680" >MOLPADIA, Amazon slain by Theseus, grave mon.: Paus. 1.2.1</p>
<p :class="{'activeClass': idClicked == NP2681 }" id="NP2681" >MORIOS: see Zeus Zeu67*</p>
<p :class="{'activeClass': idClicked == NP2682 }" id="NP2682" >MORYCHOS: see Dionysos Dio51*</p>
<p :class="{'activeClass': idClicked == NP2683 }" id="NP2683" >MORYCHOS, tragic poet, house near the Olympieion: Pl. Phdr. 227 B; Travlos, J. 1971, 289</p>
<p :class="{'activeClass': idClicked == NP2684 }" id="NP2684" >“MOSCHOPHOROS”, statue of a man carrying a bull calf dedicated by <JBcom>[Rh]</JBcom>onbos, ca. 560 B.C. - - - Acr. 624, IG I³ 593 = IG I² 469; SEG 21 (1965) no. 86; Jacob-Felsch, M. 1969, 110-111 no. 6; the statue “may represent its dedicator Rhombos as a votary . . Keesling, C. M. 2003, 117 with note 87</p>
<p :class="{'activeClass': idClicked == NP2685 }" id="NP2685" >MOTHER OF THE GODS: Vermaseren, M. J. 1982, II, 3-68, 97-109, 231-232 s.v. Athens, pls. 1-59, 85-112</p>
<p :class="{'activeClass': idClicked == NP2686 }" id="NP2686" >Metroon in the Agora: Paus. 1.3.5; Agora III, 150-160; Agora XIV, General Index s.v. Metroon; Shear Jr., T. L. 1995, 157-190 <br>For an early temple below the north room of the Hellenistic Metroon <JBcom>[doubtably]</JBcom> assigned to the Mother of The Gods, see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM370">370</a> <br>The classical Metroon, the sanctuary of Rhea, was a part of the Bouleuterion; schol. Aischin. 3.187 (Ktesiphon); Agora III, no. 467; see Bouleuterion 3) Old Bouleuterion </p>
<p :class="{'activeClass': idClicked == NP2689 }" id="NP2689" >1* Hellenistic Metroon, ca. 140 B.C., between the Tholos and Apollo Patroos: Paus. 1.3.5; Agora XIV, General Index s.v. Metroon, Hellenistic; P. Valavanis, “Thoughts on the Public Archive in the Hellenistic Metroon of the Athenian Agora,” AM 117 (2002) 221-255, figs. 1-3</p>
<p :class="{'activeClass': idClicked == NP2690 }" id="NP2690" >Stamped rooftiles dedicated to the Mother of the Gods by Dionysios and Ammonios <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20304" target="_blank">Agora A 304</a>+, IG II² 4870+; Agora XIV, 37, pl. 30:C; Chr. Habicht, “Athens and the Attalids in the Second Century B.C.,” Hesperia 59 (1990) 574-575; SEG 40 (1990) no. 275; <JBcom>[the mintmasters Dionysios and Ammonios may be the donors rather than the fabricants]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2691 }" id="NP2691" >2* Boundary stone, 400-350 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202472" target="_blank">Agora I 2472</a>; Meritt, B. D. 1941, 39 no. 6, ph.; Agora XIX, 8, 24 H14</p>
<p :class="{'activeClass': idClicked == NP2692 }" id="NP2692" >3* Altar: Aeschin. 1.60-61; Agora III, no. 465; perhaps the altar in front of the Metroon colonnade east of the 2nd room from the south, Agora XIV, 38 with note 79, fig. 10, pls. 3,7</p>
<p :class="{'activeClass': idClicked == NP2693 }" id="NP2693" >4* Statue of the Mother of The Gods by Pheidias or Agorakritos: Paus. 1.3.5, 8.37.3; Agora III, nos. 402, 489; Naumann, F.1983, 159 -180; Shear Jr., T. L. 1995, 168, 189; <JBcom>[the statue originally stood in the “Old Bouleuterion” ]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2694 }" id="NP2694" >5* Base with dedication by the priest of The Mother of the Gods, Armenos, son of Antiphates, 328/7 B.C. IG II² 4595; Agora III, under no. 495; Vermaseren, M. J. 1982, II, no. 5</p>
<p :class="{'activeClass': idClicked == NP2695 }" id="NP2695" >Naiskos reliefs: ibid., nos. 3, 37-42, 44-69, 71-134, 136-139, 141-56, 160-170</p>
<p :class="{'activeClass': idClicked == NP2696 }" id="NP2696" >6* Kriton's dedication, 4th-3rd c. B.C., reused in the foundations of the Hellenistic Metroon <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202669" target="_blank">Agora I 2669</a>; ibid., no. 3, pl. 3</p>
<p :class="{'activeClass': idClicked == NP2697 }" id="NP2697" >7* Naiskos relief - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20731" target="_blank">Agora S 731</a>; Agora XIV, pl. 31 :A</p>
<p :class="{'activeClass': idClicked == NP2698 }" id="NP2698" >8* Terracotta statuette, Mother of The Gods riding a lion with gilded mane, 2nd-1st c. B.C., southeast foot of Kolonos Agoraios - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%20915" target="_blank">Agora T 915</a>; <JBcom>[doubtably]</JBcom> called Kybele, Thompson, D. B. 1966, 3-6, 16, 17 no. 1, pl. 1; see Kybele</p>
<p :class="{'activeClass': idClicked == NP2699 }" id="NP2699" >9* Theater seat - - - IG II² 5134; Vermaseren, M. J. 1982, II, no. 17</p>
<p :class="{'activeClass': idClicked == NP2700 }" id="NP2700" >10* Metroon in Agra: IG I³ 369 line 91; <JBcom>[correctly]</JBcom> located at the site of Hag. Photini on the Ilissos, H. Mobius, “Zu Ilissosfries und Nikeballustrade,”, AM 53 (1928) 4-5; <JBcom>[mistakenly]</JBcom> equated with the Ionic temple on Ilissos, H. Mobius, “Das Metroon in Agrai und sein Fries,” AM 60-61 (1935-36) 243-261; Vermaseren, M. J. 1982, II, 113-114 nos. 381-385; <JBcom>[the Metroon in Agra was at or near Kallirrhoe most probably at the site of Pan Pan16* near Hag. Photini (Chr. Mon. CM49*) where two imposing adjacent rock-cut walls preserve two sides of a structure or temenos]</JBcom>; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM533">533</a></p>
<p :class="{'activeClass': idClicked == NP2701 }" id="NP2701" >MOTHER OF THE GODS (continued,), finds from the Akropolis West Slope Exc., including the "Enneakrounos" Excavations and south of the Areopagus: Naumann, F. 1983, naiskos reliefs, 314 ff., cat. nos. 146, 148, 150, 154-8, 194-8, 219-220, 223-236, 270, 279, 302-303, 314, 325-330, 339-341, 359-364, 382-3, 386-7</p>
<p :class="{'activeClass': idClicked == NP2702 }" id="NP2702" >11* Naiskos relief with low reliefs of Pan and a girl on the left anta and of a youth on the right anta NM 3538; Naumann, F. 1983, 186, 327, cat. no. 270, pl. 27 no. 2; Kaltsas, N. 2002, 233 no. 491, ph.</p>
<p :class="{'activeClass': idClicked == NP2703 }" id="NP2703" >12* Thymiaterion with Panes relief, dedicated to the Mother of The Gods, 2nd c. B.C., Bakcheion IG II² 4773; Schrader, H. 1896, 275-276 with drawing in text; F. T. van Straten, “Daikrates' Dream. A votive relief from Kos and some other kat'onar dedications,” BABesch 51 (1976) 9 with notes 108 -113, fig. 18; Vermaseren, M. J. 1982, II, no. 180, pl. 35</p>
<p :class="{'activeClass': idClicked == NP2704 }" id="NP2704" >MOTHER OF THE GODS (continued), finds from the Kerameikos Exc.</p>
<p :class="{'activeClass': idClicked == NP2705 }" id="NP2705" >13* Mother of The Gods, sculpture in the round placed in a separately made naiskos, Bldg. Z by the Sacred Gate: G. Touchais, “Chronique des fouilles en 1978,” BCH 103 (1979) 533, fig. 24</p>
<p :class="{'activeClass': idClicked == NP2706 }" id="NP2706" >14* Naiskos reliefs: Naumann, F. 1983, 315 ff., cat. nos. 161-163, 237-8, 267, 271, 323, 365</p>
<p :class="{'activeClass': idClicked == NP2707 }" id="NP2707" >MOTHER OF THE GODS (continued), naiskos reliefs from various places:</p>
<p :class="{'activeClass': idClicked == NP2708 }" id="NP2708" >15* Double naiskos relief, built into the mediaeval tower in the Propylaia southwest wing - - - formerly Propylaia inv. 1977; Naumann, F. 1983, cat. no. 343</p>
<p :class="{'activeClass': idClicked == NP2709 }" id="NP2709" >16* Naiskos relief built into the east side of Panagia Gorgoepekoos (Chr. Mon. CM75*) at top centre (namely back to back with the representation of the Mother of God in the apse): P. Steiner, “Antike Skulpturen an der Panagia Gorgoepekoos zu Athen,” AM 31 (1906) 328 no. 3, fig. 1; Delivorrias, A. 1991, 117 with note 55, pl. 57; cf. Malalas, Chronographia L.IV.O 94-95 (Mother of The Gods as Mother of God)</p>
<p :class="{'activeClass': idClicked == NP2710 }" id="NP2710" >17* Naiskos relief, northwest Athens, 60 Aischylou St. (Plan 2 delta/epsilon—4) - - - NM 3726; B. D. Theophanides, «Νέα προσκτήματα Έθν. Άρχ. Μουσείου κατά τά έτη 1930, 1931, 1932,» ΑΕ 1939-41 parart. 11 no. 41, fig. 17; Kaltsas, N. 2002, 232 no. 489, ph.</p>
<p :class="{'activeClass': idClicked == NP2711 }" id="NP2711" >18* Naiskos relief, area northwest of the Academy: Ph. D. Stavropoullos, “Άνασκαφαι Άρχαίας Άκαδημείας,» Praktika 1963, 20 f. with fig. 17:a</p>
<p :class="{'activeClass': idClicked == NP2712 }" id="NP2712" >19* Naiskos reliefs found in an apsidal sanctuary in a large building complex, 3rd-4th c. A.C. at the northeast corner of the National Gardens at the intersection of Vasilissis Sophias and Irodou Attikou Sts. (Plan 2 epsilon/zeta—7): H. W. Catling, “Archaeology in Greece, 1983-1984,” AR 1983-1984, 9; E. Spathari and M. Hatzioti, «7. Λεωφόρος Βασ. Σοφίας και Ηρώδου Αττικού',» Deltion 38 (1983) B1 Chr.,25, fig. 4</p>
<p :class="{'activeClass': idClicked == NP2713 }" id="NP2713" >20* Naiskos relief with horos inscr., 1st c. B.C. - - - NM 1552; Vermaseren, M. J. 1982, II, 100-101, no. 336, pl. 91; Ritchie Jr., C. E. 1985, 174-178 with pl. 33, fig. 37, 743-745 See also: Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM167">167</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM192">192</a></p>
<p :class="{'activeClass': idClicked == NP2714 }" id="NP2714" >21* MOTHER OF THE GODS and ARTEMIS, altar (?), Akr. northwest slope IG II²  2603 = IG II² 4670 = IG II² 5015; Vermaseren, M. J. 1982, II, no. 243</p>
<p :class="{'activeClass': idClicked == NP2715 }" id="NP2715" >MOUNICHOS: see Heroes Hro21*, Heros Mounichos</p>
<p :class="{'activeClass': idClicked == NP2716 }" id="NP2716" >MOUSAIOS: F GR HIST III B, suppl. vol. I, 574-577; Kearns, E. 1989, 187 Picture in the Propylaia northwest chamber: Paus. 1.22.7</p>
<p :class="{'activeClass': idClicked == NP2717 }" id="NP2717" >Tomb: on Mouseion Hill where Mousaios sang according to Pausanias 1.25.8; in Phaleron according to D.L. 1.3</p>
<p :class="{'activeClass': idClicked == NP2718 }" id="NP2718" >MOUSEION HILL: inside the ancient circuit wall across from the Akropolis, Paus. 1.25.8; now called Philopappos Hill or Hill of the Muses</p>
<p :class="{'activeClass': idClicked == NP2719 }" id="NP2719" >Mouseion Fort built between 307 and 294 B.C. on Mouseion Hill (Plan 2 eta/theta—3): Paus. 1.25.8 + 1.26.1-2, 3.6.6; Thompson, H. A. and R. L. Scranton 1943, 331, 337-338, 357-358, fig. 40, pl. 14; Travlos, J. 1971, 462, figs. 219 no. 220, 232-233; for D. H. Conwell's view that, pace R. L. Scranton, the Diateichisma was later than the Mouseion Fort, see Diateichisma</p>
<p :class="{'activeClass': idClicked == NP2720 }" id="NP2720" >For monuments and sites without clear-cut names on Mouseion Hill, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM240">240</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM249">249</a></p>
<p :class="{'activeClass': idClicked == NP2721 }" id="NP2721" >MOUSEION in the Academy, founded by Plato: D.L. 4.1; Riginos, A. S. 1976, 119-121; Billot, M.-F. 1989, 743-744, 781-782, 785-786</p>
<p :class="{'activeClass': idClicked == NP2722 }" id="NP2722" >Altar of the Muses: Paus. 1.30.2; Delorme, J. 1960, 53 note 1</p>
<p :class="{'activeClass': idClicked == NP2723 }" id="NP2723" >MOUSEION rebuilt by Theophrastos with statues of the goddesses: D.L. 5.51; Lynch, J. P. 1972, 114-116</p>
<p :class="{'activeClass': idClicked == NP2724 }" id="NP2724" >Small stoa: D.L. 5.51</p>
<p :class="{'activeClass': idClicked == NP2725 }" id="NP2725" >Lower stoa containing maps: D.L. 5.51-52</p>
<p :class="{'activeClass': idClicked == NP2726 }" id="NP2726" >Altar: D.L. 5.52 See also: Theophrastos' Garden</p>
<p :class="{'activeClass': idClicked == NP2727 }" id="NP2727" >MOUSEION, name for the philosophical schools of Athens, 3rd c. A.C.: IG II² 3712 line 4; Oliver, J. H. 1970, 105-106 no. 20</p>
<p :class="{'activeClass': idClicked == NP2728 }" id="NP2728" >MUSES</p>
<p :class="{'activeClass': idClicked == NP2729 }" id="NP2729" >Altar of the Ιlissian Muses: Paus. 1.19.5; Ritchie Jr., C. E. 1989, 259-260; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM552">552</a></p>
<p :class="{'activeClass': idClicked == NP2730 }" id="NP2730" >Muses' garden boundary stones: Lynch, J. P. 1972, 209-211; Ritchie Jr., C. E. 1985, 272-284, pls. 52-53, figs. 40-42 and 793-814; disassociated from Theophrastos' Garden, Ritchie Jr., C. E. 1989, 250, 258-260; SEG 39 (1989) no. 197</p>
<p :class="{'activeClass': idClicked == NP2731 }" id="NP2731" >One found in the foundations of a house on the north side of Plateia Syntagmatos IG II² 2613; S. I. Charitonides, «Άνασκαφή κλασσικών τάφων παρά την πλατείαν Σψντάγματος,» ΑΕ 1958, 126-128; Travlos, J. 1971, 345, figs. 447-448</p>
<p :class="{'activeClass': idClicked == NP2732 }" id="NP2732" >One found on the Akr. South Slope built into the Serpentze Wall IG II²  2614; Peek, W. 1942, 34</p>
<p :class="{'activeClass': idClicked == NP2733 }" id="NP2733" >Priest's theater throne IG II² 5067; Maass, M. 1972, 132-133, pl. 18</p>
<p :class="{'activeClass': idClicked == NP2734 }" id="NP2734" >Throne (?) on the Akropolis IG II² 5195</p>
<p :class="{'activeClass': idClicked == NP2735 }" id="NP2735" >Statues in the precinct of Dionysos Melpomenos: Paus. 1.2.5; according to Giorgos I. Despinis, NM 233 formerly identified as a Nike may be one of these Muses; see Nike, statue found with the Euboulides base</p>
<p :class="{'activeClass': idClicked == NP2736 }" id="NP2736" >Statues listed in a gymnasium inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 51; Clay, D. 1977, 261, 263, 265 See also: Apollo Apo88*; Mouseion in the Academy; Mouseion rebuilt by Theophrastos; Plato, statue</p>
<p :class="{'activeClass': idClicked == NP2737 }" id="NP2737" >MYRMEX LANE in Skambonidai, named after the Hero Myrmex, son of Melite: Hsch. s.v. Μύρμηκος Άτραπούς; Judeich, W. 1931, 168 note 3, 183 note 8; Miller, S. G. 1972, 83 with note 32, commentary on IG II² 2776 line 62; Siewert, P. 1982, 29 note 140</p>
<p :class="{'activeClass': idClicked == NP2738 }" id="NP2738" >MYRRHINE led to the Underworld by Hermes Psychopompos, grave relief on a marble lekythos ca. 400 B.C. or later, found in 1873 in Plateia Syntagmatos - - - NM 4485; IG l31285 = IG II² 12196; SEG 44 (1994) no. 22; not identical with the Myrrhine of IG I³ 1330, epitaph for the first priestess of Athena Nike, Mark, I. S. 1993, 111-112 note 80; Kaltsas, N. 2002, 148 no. 289, ph.</p>
<p :class="{'activeClass': idClicked == NP2739 }" id="NP2739" >“MYSTAI GATE”, modern name for an ancient gate in the City Wall through which the mystai went down to the sea: IG I³ 84 lines 35-36; Travlos, J. 1971, 332; cf. “Halade Gate” (another modern name for the same ancient gate); see Themistoklean City Wall Wal25*, Wal28*</p>
<p :class="{'activeClass': idClicked == NP2740 }" id="NP2740" >NAIADS: see Hermes Hrm8*, companion of the Naiads </p>
<p :class="{'activeClass': idClicked == NP2741 }" id="NP2741" >NAIOS: see Zeus Zeu68*-69*</p>
<p :class="{'activeClass': idClicked == NP2742 }" id="NP2742" >NEA THEA APHRODITE: see Drusilla</p>
<p :class="{'activeClass': idClicked == NP2743 }" id="NP2743" >NEAI ATHENAI HADRIANAI: Stephanus Byz. in F GR HIST II B, 1166, Phlegon of Tralles 257 F 19; Zahrnt, M. 1979, 393-398</p>
<p :class="{'activeClass': idClicked == NP2744 }" id="NP2744" >NECYOMANTEA HOMERI, picture by Nikias: see Odysseus in the Underworld</p>
<p :class="{'activeClass': idClicked == NP2745 }" id="NP2745" >NELEUS AND BASILE: see Kodros</p>
<p :class="{'activeClass': idClicked == NP2746 }" id="NP2746" >NEMESIS</p>
<p :class="{'activeClass': idClicked == NP2747 }" id="NP2747" >Altar dedicated to Thea Nemesis, ca. 240 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204790" target="_blank">Agora I 4790</a> + IG II² 4817a; A. E. Raubitschek,” Greek Inscriptions”, Hesperia 12 (1943) 87-8 no. 26, ph.</p>
<p :class="{'activeClass': idClicked == NP2748 }" id="NP2748" >Arula, 1st-2nd c. A.C., at the theater - - - IG II² 4747; SEG 42 (1992) no. 188;</p>
<p :class="{'activeClass': idClicked == NP2749 }" id="NP2749" >Archeion I (1992) 184 no. 9.3</p>
<p :class="{'activeClass': idClicked == NP2750 }" id="NP2750" >Arula dedicated to Nemesis Thea Epekoos, Kerameikos Exc. IG II² 4865; S. A. Koumanoudes, «Έπιγραφαι ανέκδοτοι εκ Δήλου και Άθηνών,» Athenaion II (1873) 135-136</p>
<p :class="{'activeClass': idClicked == NP2751 }" id="NP2751" >Nemesis at Rhamnous, priestess' theater throne, Roman pd. - - - IG II² 5143; B. Petrakos, «Το Νεμέσιον τού Ραμνούντος,» in Studies Mylonas, II, 325; SEG 36 (1986) no. 276</p>
<p :class="{'activeClass': idClicked == NP2752 }" id="NP2752" >Nemesis at Rhamnous, copy of the temple statue, 29 Praxitelous St. (Plan 2 epsilon—5) - - - NM 3949; Despinis, G. 1971, 25, 28-29, 33-34, 40, pls. 41-42; Kaltsas, N. 2002, 120 no. 219, ph.</p>
<p :class="{'activeClass': idClicked == NP2753 }" id="NP2753" >Nemesis at Rhamnous, copy of the head of the temple statue <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201055" target="_blank">Agora S 1055</a>; Despinis, G. 1971, 78 note 153, pl. 58</p>
<p :class="{'activeClass': idClicked == NP2754 }" id="NP2754" >Nemesis Ourania, priest's theater throne - - - IG II² 5070; Maass, M. 1972, 134, pl. 19</p>
<p :class="{'activeClass': idClicked == NP2755 }" id="NP2755" >NEOKLES, father of Epicurus, herm (?) frag., 2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20708" target="_blank">Agora I 708</a>; Meritt, B. D. 1946, 235 no. 67, ph.</p>
<p :class="{'activeClass': idClicked == NP2756 }" id="NP2756" >NEOPTOLEMOS, son of Antikles, statue: Plu. Moralia 843 F; Further Greek Epigrams, 1981, 280-281 no. 55</p>
<p :class="{'activeClass': idClicked == NP2757 }" id="NP2757" >NEOS APOLLON: see Augustus, statue base; Nero </p>
<p :class="{'activeClass': idClicked == NP2758 }" id="NP2758" >NEOS ARES: see Caesar, Gaius</p>
<p :class="{'activeClass': idClicked == NP2759 }" id="NP2759" >NEOS DIONYSOS: see Antonius; Hadrian, statue bases (2)</p>
<p :class="{'activeClass': idClicked == NP2760 }" id="NP2760" >NEPHTHYS and OSIRIS, cult: IG II² 1367 line 4; SEG 51 (2001) no. 138 </p>
<p :class="{'activeClass': idClicked == NP2761 }" id="NP2761" >NERO: Altar (?) for Nero as the Neos Apollon with damnatio memoriae erasure, intersection of Lekka and Perikleous Sts (Plan 2 epsilon—5).: J.-P. Michaud, “Chronique des fouilles en 1970,” BCH 95 (1971) 821, fig. 41; cf. D. J. Geagan, “Imperial Visits to Athens: the Epigraphical Evidence,” in Praktika 8th Congress 1984, 74 with note 22, 76 with note 35; SEG 36 (1986) no. 244; cf. SEG 44 (1994) no. 165 See also: Augustus, altar; Dionysos Dio37*; Parthenon, inscr. honoring Nero</p>
<p :class="{'activeClass': idClicked == NP2762 }" id="NP2762" >NICANOR = C. Julius Nicanor (PIR, 2nd ed., 440), two statue bases from which the honorary titles of The New Homer and The New Themistokles have been erased, 1st c. A.C. - - - IG II² 3786 (found north of the Parthenon) and 3787 (found near the Beule Gate); SEG 31 (1981) no. 108; Chr. Habicht, “Salamis in der Zeit nach Sulla,” ZPE 111 (1996) 79-87; Byrne, S. G. 2003, 321 no. 94</p>
<p :class="{'activeClass': idClicked == NP2763 }" id="NP2763" >NIKE</p>
<p :class="{'activeClass': idClicked == NP2764 }" id="NP2764" >Nike cult recorded in the Themistokles decree: EM 13330 line 39; Meiggs, R. and D. M. Lewis, 1969, no. 23 with page 51</p>
<p :class="{'activeClass': idClicked == NP2765 }" id="NP2765" >The Golden Nikai: IG I³ 467-471; SEG 50 (2000) no. 68; Paus. 1.29.16; D. B. Thompson, “The Golden Nikai Reconsidered,” Hesperia 13 (1944) 173-209, testimonia 173-177; Mark, I. S. 1988, 134-156, 216-221; Harris, D. 1995, 272-275</p>
<p :class="{'activeClass': idClicked == NP2766 }" id="NP2766" >Bronze statue commemorating the capture of 292 Spartans on Sphakteria, 425 B.C., Akr.: Paus. 4.36.6; Mark, I. S. 1988, 187-188</p>
<p :class="{'activeClass': idClicked == NP2767 }" id="NP2767" >Gilded bronze head, ca. 420-415 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%2030" target="_blank">Agora B 30</a>; T. L. Shear, “The Sculpture,” Hesperia 2 (1933) 519-527, figs. 5-9, pl. XV; Agora XIV, 190-191, frontispiece, pl. 98</p>
<p :class="{'activeClass': idClicked == NP2768 }" id="NP2768" >Acroterion (?), early 5th c. B.C., Northwest Orthostate Enclosure - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202476" target="_blank">Agora S 2476</a>; thought to be a kore head, Shear Jr., T. L. 1973b, 400-401, pl. 66:a-c</p>
<p :class="{'activeClass': idClicked == NP2769 }" id="NP2769" >Statue, ca. 400 B.C., in front of the Stoa of Zeus - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20312" target="_blank">Agora S 312</a> +; <JBcom>[mistakenly]</JBcom> presented as one of a pair of acroteria on the Stoa of Zeus, Agora XIV, 99, 100 with note 94, pl. 51 :c (the female head <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20373" target="_blank">Agora S 373</a> does not belong), pl. 52; <JBcom>[wrongly]</JBcom> identified as an acroterion and taken as a basis for four <JBcom>[imaginary]</JBcom> corner Nike acroteria on the plaster model of the Stoa of Zeus, Travlos, J. 1971, figs. 671-672; Gulaki, A. 1981, 63-68, figs. 23-25; Danner, P. 1989, 17 no. 105, pl.</p>
<p :class="{'activeClass': idClicked == NP2770 }" id="NP2770" >Statue found with the Euboulides base, Ermou and Asomaton Sts. (Plan 2 epsilon—3) NM 233; Gulaki, A. 1981, 254-258, figs. 241, 247-248; Travlos, J. 1971, fig. 542; not Nike, diagnosed as a Muse, Despinis, G. 1995, 322, 325-326, 328-333, pls. 62-65</p>
<p :class="{'activeClass': idClicked == NP2771 }" id="NP2771" >Statue, theater Brussels, Mus. du Cinq. A 1937; Gulaki, A. 1981, 252-253, figs. 237-238, 240</p>
<p :class="{'activeClass': idClicked == NP2772 }" id="NP2772" >Tripod ? base with reliefs, late 5th c. B.C. ?, built into Panagia Gorgoepekoos (Chr. Mon. CM75*): J. Dorig, “Une nouvelle base commemorant une victoire de la fin du cinquieme siecle avant J.-C.,”, in Zur Griechischen Kunst, Hansjorg Bloesch zum sechzigsten Geburtstag am 5. Juli 1972, (Antike Kunst Suppl. 9), Isler, H. P. and G. Seiterle edd., Bern, 1973, 14-19, pls. 3:1-2, 5:1-2</p>
<p :class="{'activeClass': idClicked == NP2773 }" id="NP2773" >Side 1) Nike presents a tripod to a young woman</p>
<p :class="{'activeClass': idClicked == NP2774 }" id="NP2774" >Side 2) Nike presents a cuirass to a young woman</p>
<p :class="{'activeClass': idClicked == NP2775 }" id="NP2775" >Side 3) a rider See also: Athena Ath8*, Ath52**, Ath73*-89*, Ath92* (Athena Parthenos = Athena Nikephoros), Ath101*, Ath125*, Ath126*; Dionysos Dio13*, Dio14*, Dio15*; Victoria Romana; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM15">15</a> (Nike Bastion)</p>
<p :class="{'activeClass': idClicked == NP2776 }" id="NP2776" >NIKE APTEROS: see Athena Ath78*, cult statue</p>
<p :class="{'activeClass': idClicked == NP2777 }" id="NP2777" >NIKE OLYMPIA, priest's theater throne, Hadrianic IG II² 5027; a simple seat of Hymettian marble in the 2nd row, Maass, M. 1972, 106, pl. 7</p>
<p :class="{'activeClass': idClicked == NP2778 }" id="NP2778" >NIKIAS, son of Nikeratos, and his brothers, choregic tripods in the Dionysion: Pl. Grg. 472 A; Davies, J. K. 1971, 403-404; Wilson, P. 2000, 203, 228-229</p>
<p :class="{'activeClass': idClicked == NP2779 }" id="NP2779" >NIKIAS, son of Nikodemos, choregic mon. for a victorious dithyramb, Timotheos' Elpenor, 320/319 B.C., originally on the Akr. South Slope (Plan 1 eta—6, no. 24), after the Herulian destruction of 267 A.D. the inscription and part of the entablature were incorporated in the Beule Gate (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM19">19</a>) as a victory display - - - IG II² 3055; Travlos, J. 1971, 357-360, figs. 91 no. 143, 460-463; Townsend, R. F. 1982, 198-216; Wilson, P. 2000, 226-229, figs. 15-16; M. Korres,” Κλασσική αθηναϊκή αρχιτεκτονική,» in ΑΘΗΝΑΙ 2000, 36 fig. 36; the Beule Gate towers incorporate plain wall blocks of the Nikias Monument; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM135">135</a></p>
<p :class="{'activeClass': idClicked == NP2780 }" id="NP2780" >NIKIAS, son of Nikomedes, artist, grave on the Road to the Academy: Paus. 1.29.15; O. Gengler, “Le peintre Nikias chez Pausanias et IG II² 3055,” ZPE 130 (2000) 143-146; SEG 50 (2000) no. 193; <JBcom>[may be a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2781 }" id="NP2781" >NIKOKLES, kitharode from Tarentum</p>
<p :class="{'activeClass': idClicked == NP2782 }" id="NP2782" >Grave on the Sacred Way: Paus. 1.37.2</p>
<p :class="{'activeClass': idClicked == NP2783 }" id="NP2783" >Statue base in the theater - - - IG II² 3779; Ghiron-Bastagne, P. 1976, 101</p>
<p :class="{'activeClass': idClicked == NP2784 }" id="NP2784" >NIKOKRATES' WORKSHOP for gold and silver cult vessels listed in Akropolis inventories, last 3rd of the 4th c. B.C.: D. Harris, “Nikokrates of Kolonos, Metalworker to the Parthenon Treasurers,” Hesperia 57 (1988) 329-337, pl.88; Harris, D. 1995, General Index s.v. artists' signatures</p>
<p :class="{'activeClass': idClicked == NP2785 }" id="NP2785" >Gold phialai ordered by the Treasurers of Athena in 321/320 B.C. on each of which is inscribed "Nikokrates of Kolonos made me": IG II² 1471 lines 10-15; Harris, D. 1995, 171-172 no. 320; SEG 46 (1996) no. 176</p>
<p :class="{'activeClass': idClicked == NP2786 }" id="NP2786" >NIKOMACHOS, Aristotle's son, statue provided for in Theophrastos' will: D.L. 5.52</p>
<p :class="{'activeClass': idClicked == NP2787 }" id="NP2787" >NISOS, king of Megara, brother of Lykos, grave mon. behind the Lykeion: Paus. 1.19.4; Delorme, J. 1960, 44; Kearns, E. 1989, 115-116, 188; cf. Part II no.506</p>
<p :class="{'activeClass': idClicked == NP2788 }" id="NP2788" >NOMOPHYLAKES: Nomophylakion, office of the nomophylakes: Poll. 8.102; H.-J. Gehrke, “Politik und Philosophie bei Demetrios von Phaleron,” Chiron 8 (1978) 188-189</p>
<p :class="{'activeClass': idClicked == NP2790 }" id="NP2790" >Theater thrones: F GR HIST III B, 117, Philochoros 328 F 64 b; H.-J. Gehrke, op. cit., 152 note 14</p>
<p :class="{'activeClass': idClicked == NP2791 }" id="NP2791" >NOVAE ATHENAE, place-name: CIL III 549, named in the inscription on the propylon of the Hadrianic - Antonine aqueduct on Lykabettos south slope; Willers, D. 1990, 68-72; Leigh, S. 1998, 224-227</p>
<p :class="{'activeClass': idClicked == NP2792 }" id="NP2792" >NYMPHAION, sanctuary boundary stone, poros limestone, 500-450 B.C., northeast of the Temple of Ares IG I³ 1063 = <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204773" target="_blank">Agora I 4773</a>; assigned to the Klepsydra spring cave on the Akr. North Slope, Parsons, A. W. 1943, 232-233; SEG 25 (1971) no. 54; <JBcom>[doubtably]</JBcom> assigned to an <JBcom>[undocumented]</JBcom> sanctuary of Empedo, Travlos, J. 1971, 323, fig. 425; Ritchie Jr., C. E. 1985, 290-294, pl. 55, fig. 44 and 815-816; Agora XIX, 22 H3</p>
<p :class="{'activeClass': idClicked == NP2793 }" id="NP2793" >NYMPHE: Sanctuary, Akr. South Slope, 7th - 2nd C. B.C., south of the Odeion of Herodes Atticus (Plan 1 eta—4, south southwest of no.25): Travlos, J. 1971, 361-363, figs. 91 no. 148, 464-467; La Rocca, E. 1975, 441-448; LIMC VIII, 1997, 902, s.v. Nymphe I (M. Kyrkou)</p>
<p :class="{'activeClass': idClicked == NP2794 }" id="NP2794" >Altar made of small unworked stones, 650-625 B.C.: Maro Kyrkou, VH Πρωτοαττικη πρόκληση . . .,” in Athenian Potters and Painters, edd. J. H. Oakley et al., Oxbow Monographs 67, 1997, 423</p>
<p :class="{'activeClass': idClicked == NP2795 }" id="NP2795" >Boundary stone, late 5th c. B.C. IG I³ 1064 = 1st Ephor. ΝΑΓ 90-1957; SEG 17 (1960) no. 10; Travlos, J. 1971, fig. 465: Ritchie, C. E. Jr. 1985, 285-289, pl. 54, fig. 43</p>
<p :class="{'activeClass': idClicked == NP2796 }" id="NP2796" >Decree honouring the priestess of Nymphe - - - SEG 29 (1979) no. 135; E. Vanderpool, “The Genos Theonidai Honors a Priestess of Nymphe,” AJP 100 (1979) 213-216</p>
<p :class="{'activeClass': idClicked == NP2797 }" id="NP2797" >Theater seat, Hymnetriai ... Euboule Nymphe (?)--- IG II² 5100; La Rocca, E. 1975, 445; SEG 33 (1983) no. 208 See also: Aphrodite Aph49*</p>
<p :class="{'activeClass': idClicked == NP2798 }" id="NP2798" >NYMPHS</p>
<p :class="{'activeClass': idClicked == NP2799 }" id="NP2799" >1* Nymphs who reared Dionysos, altar founded by Amphiktyon: Ath. II.38 C-D; F GR HIST III B, Philochoros 328 F 5 with suppl. vol. I, 270; cf. Amphiktyon, terracotta sculpture; Hermes Hrm37* (cave relief)</p>
<p :class="{'activeClass': idClicked == NP2800 }" id="NP2800" >2* Relief dedicated to The Nymphs, 400-350 B.C., Areopagus northwest slope - - - NM 3529; IG II² 4565</p>
<p :class="{'activeClass': idClicked == NP2801 }" id="NP2801" >3* Relief dedicated to The Nymphs by Sokrates son of Habron, with Hermes and a man standing at an altar, 4th c. B.C., Akr. South Slope NM 2351, IG II²  4592; Edwards, C. M. 1985, 599-601</p>
<p :class="{'activeClass': idClicked == NP2802 }" id="NP2802" >4* Relief, dated by the priesthood of a priest of Asklepios, 325/324 B.C., Akr. South Slope - - - NM 2439, IG II² 4371; SEG 17 (1960) no. 80; Edwards, C. M. 1985, 642-648; see Asklepios Ask27*</p>
<p :class="{'activeClass': idClicked == NP2803 }" id="NP2803" >5* Sanctuary mentioned in a grave epigram: IG II² 12516; C. Borker, “Zwei vergessene Giebel in Athen,” AA 1976, 274 note 43</p>
<p :class="{'activeClass': idClicked == NP2804 }" id="NP2804" >6* Nymphai Ompniai, grotto relief with seated divinity, three nymphs and Pan with syrinx, 380-370 B.C., Hill of the Nymphs - - - Avignon, Musee Calvert 220, IG ΙΙ2 4647; SEG 16 (1959) no. 176; Kron, U. 1979, 68-9, pl. 12 no. 1</p>
<p :class="{'activeClass': idClicked == NP2805 }" id="NP2805" >7* Nymphs, Acheloos, Pan, grotto relief, near the Pompeion propylon Kerameikos P 928; Kerameikos X, 177 note 383, 218-9, fig. 254</p>
<p :class="{'activeClass': idClicked == NP2806 }" id="NP2806" >8* Nymphs, Acheloos, Pan, Hermes, and worshippers bringing a ram to an altar, relief, found not far from the Akr. - - - IG II² 4886; Peek, W. 1942, 54-55 no. 87</p>
<p :class="{'activeClass': idClicked == NP2807 }" id="NP2807" >9* Nymphs, Acheloos, Pan and The Other Gods, sanctuary with a spring by the Ilissos, visited by Sokrates: Pl. Phdr. 229 B, 230 B, 279 B; Str. 9.1.24; <JBcom>[mistakenly]</JBcom> located southwest of the Panathenaic Stadium at the findspot of the Washermen Relief (Nymphs Nym11*), Travlos, J. 1971, 289, fig. 379 no. 196; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM528B">528B</a></p>
<p :class="{'activeClass': idClicked == NP2808 }" id="NP2808" >10* Nymphs and Demos, sanctuary on the Hill of the Nymphs Rock-cut sanctuary marker, mid-5th c. B.C., at the summit of the Hill of the Nymphs southwest of the Observatory IG I² 1065 = IG I² 854; K. S. Pittakis, AE 1852, 669-670 no. 1111; SEG 47 (1997) no. 85; Ritchie, Jr., C. E. 1985, 295-300, pl. 56, fig. 45 and 816-819; SEG 50 (2000) no. 87</p>
<p :class="{'activeClass': idClicked == NP2809 }" id="NP2809" >Rectangular rock-cut bedding for an altar in front of the inscribed rock face: K. S. Pittakis, loc. cit.</p>
<p :class="{'activeClass': idClicked == NP2810 }" id="NP2810" >Terracotta female figurines, 7th-4th c. B.C., very large deposit reported in lectures given in Athens in 2003; <JBcom>[the impressive deposit attesting the extraordinary importance of this cult of the nymphs long before the cult of Demos joined it, leads one to think that there must have been of a copious source of water, a spring, in the vicinity, a conjecture reinforced by news of the discovery of a huge rock-cut aqueduct running from the top of the hill just inside the line of the Long Wall]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2811 }" id="NP2811" >11* Nymphs and All The Gods, relief dedicated by washermen, late 4th c. B.C., found in 1759 by the Ilissos at the Panathenaic Stadium - - - Berlin inv. 709 = K 87, IG II² 2934; Gasparri, C. 1974/75, 367 note 1, fig. 64; LIMC I, 1981, 24 no. 202, ph., s.v. Acheloos (H. P. Isler); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM528">528</a>; <JBcom>[suggestion: the relief was originally set up at the Metroon in Agra by the spring of Kallirrhoe; cf. Eleusinian Mysteries; Mother of the Gods Met10*]</JBcom> Upper zone: Acheloos, Hermes, Nymphs, Pan: Isler, H. P. 1970, 125 no. 16 Lower zone: Hero standing beside a horse, Demeter and Kore, altar: Borgeaud, P. 1979, 206</p>
<p :class="{'activeClass': idClicked == NP2812 }" id="NP2812" >12* Nymphs and Pan, relief with the dedicator, Archandros, standing at an altar below Pan in a cave, early 4th c. B.C., earliest known Nymph relief in Athens, Akr. South Slope at the Asklepieion NM 1329, IG I³ 955 = IG \ 4545; SEG 17 (1960) no. 81; Travlos, J. 1971, fig. 192; <JBcom>[implausibly]</JBcom> associated with the Pan cave on the Akr. North Slope, Edwards, C. M. 1985 2, 41-42, 293-303 esp. 297; Kaltsas, N. 2002, 135 no. 260, ph. See also: Acheloos; Apollo Apo86*; Athena Ath134*; Hermes Hrm33*, Hrm35*, Hrm37*, Hrm39*, Hrm40*; Pan Pan5*, Pan10*, Pan11*, Pan12*, Pan15**, Pan18*, Pan19*, Pan20*</p>
<p :class="{'activeClass': idClicked == NP2813 }" id="NP2813" >ODYSSEUS, pictures: O. Touchefeu-Meynier, Themes Odyseens dans l' art antique, Paris,1968</p>
<p :class="{'activeClass': idClicked == NP2814 }" id="NP2814" >Odysseus stealing Philoktetes' bow, Propylaia northwest chamber: Paus. 1.22.6; Brommer, F. 1983, 16, 49, 112, 123; cf. Diomedes</p>
<p :class="{'activeClass': idClicked == NP2815 }" id="NP2815" >Odysseus meeting Nausikaa, Propylaia northwest chamber: Paus. 1.22.6; Brommer, F. 1983, 127 s.v. Nausikaa</p>
<p :class="{'activeClass': idClicked == NP2816 }" id="NP2816" >Odysseus in the underworld, the Necyomantea Homeri by Nikias: Plin. Nat. XXXV.132; perhaps displayed in the Nikias Mon., Townsend, R. F. 1982, 216 with note 115; Brommer, F. 1983, 113</p>
<p :class="{'activeClass': idClicked == NP2817 }" id="NP2817" >ODYSSEUS BASTION around the Klepsydra spring, named after Odysseus Androutsos, constructed in 1822, torn down in 1888, Akr. northwest slope: Parsons, A. W. 1943, 195, 199, 201, 261-264, figs. 4 and 42; Tanoulas, T. 1997, vol. I, 192, 253; vol. II figs. 361-363</p>
<p :class="{'activeClass': idClicked == NP2818 }" id="NP2818" >ODYSSEY: see Iliad</p>
<p :class="{'activeClass': idClicked == NP2819 }" id="NP2819" >OIDIPOUS: Altar between the Areopagus and the Akropolis: V. Max. V.3, ext. 3</p>
<p :class="{'activeClass': idClicked == NP2821 }" id="NP2821" >Sacred grave not far from the Covenant Place of Theseus and Peirithoos: S. OC 1545, 1593-1594, 1756-1763; two sites are attested: <br>1) the grave was at Kolonos Hippios according to Aristid. XLVI.172; R. C. Jebb, The Oedipus Coloneus, 1885, XXIX; cf. Kearns, E. 1989, 50-52, 106, 129, 189, 208-209; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM469">469</a> <br>2) the grave was on the Areopagus within the precinct wall of the sanctuary of the Semnai according to Paus. 1.28.7; cf. L. W. Daly, RE Suppl. VII, 1940, col. 781 line 62 - col. 784 (Oidipous); <JBcom>[in the context of Oedipus Coloneus the grave must have been within the early City Walls]</JBcom> <br>See also: Peirithoos</p>
<p :class="{'activeClass': idClicked == NP2824 }" id="NP2824" >OIKIA DEMOSIA: mentioned in connexion with a water channel irrigating the temenos of Neleus and Basile: IG I³ 84 line 36; referring to a brothel, A. J. Graham, “The woman at the window: observations on the ‘stele from the harbour' of Thasos,” JHS 118 (1998) 33-40; SEG 48 (1998) no. 54; <JBcom>[the oikia demosia, (which I mistakenly translated as “official house,” Travlos, J. 1971, 332), was probably a confiscated private house that became public property as was the case with another oikia demosia mentioned in a poletai record, SEG 12 (1955) no. 100 linesl 0-11 ]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2825 }" id="NP2825" >OIKIA HIERA: Sacred House mentioned in the Propylaia accounts: IG I³ 462. line 24; Μ. B. Walbank, “Two Notes on Leases of Sacred Property in Attica,” ZPE 116 (1997) 39; SEG 48 (1998) no. 72; <JBcom>[probably the Sacred House of Athena Polias (Athena Ath109*)]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2826 }" id="NP2826" >OINAIA: see Artemis Art42*</p>
<p :class="{'activeClass': idClicked == NP2827 }" id="NP2827" >OINANTHE: (rest.) theater seat - - - IG II² 5133; uncertain restoration of a priestess' name, K. Scherling, EE XVII, 1937, col. 2188 (Oinanthe no. 5)</p>
<p :class="{'activeClass': idClicked == NP2828 }" id="NP2828" >OINEUS, bastard son of Pandion, Eponymous Hero statue: Paus. 1.5.2; Kron, U. 1976, 188-9; Kearns, E. 1989, 89, 190; LIMC VIII, 1997, 919-920 s.v. Oineus II (E. Stasinopoulou-Kakarouga)</p>
<p :class="{'activeClass': idClicked == NP2829 }" id="NP2829" >OINOBIOS, proposer of the decree recalling Thucydides, statue (?) on the Akropolis: Paus. 1.23,9; “Pausanias implies, though he does not expressly state, that there were statues of Oenobius, Hermolycus and Phormio on the Acropolis,” Frazer, J. G. 1913, II, 287</p>
<p :class="{'activeClass': idClicked == NP2830 }" id="NP2830" >OINOE, picture of the battle, in the Stoa Poikile: Paus. 1.15.1, cf. 10.10.3-4; Meiggs, R. 1972, 96-97, 469-472; B. Develin, “The Battle of Oinoe Meets Ockham's Razor?”, ZPE 99 (1993) 235-240</p>
<p :class="{'activeClass': idClicked == NP2831 }" id="NP2831" >OLDER PARTHENON: see Parthenon</p>
<p :class="{'activeClass': idClicked == NP2832 }" id="NP2832" >OLIVE TREE: Tree in the Pandroseion by the altar of Zeus Herkeios, evidence of Athena's victory: D.H. Din. 3; Paus. 1.27.2 (martyrion, evidence that the contest took place) and 1.30.2 (implied), 8.23.5; Caskey, L. D. et al. 1927, General Index s.v. Olive</p>
<p :class="{'activeClass': idClicked == NP2834 }" id="NP2834" >Moriai, sacred olive trees at the Academy: schol. S. OC 701; the second olive tree to appear, at the Academy, Paus. 1.30.2; Billot, M.-F. 1989, 736-739, 740; J. L. Shear, “Prizes from Athens: The List of Panathenaic Prizes and the Sacred Oil,” ZPE 142 (2003) 98-100</p>
<p :class="{'activeClass': idClicked == NP2835 }" id="NP2835" >Olive tree, owl and snake, architectural relief, Roman  Acr. 2444; found east of the Beule Gate, R. Bohn, “Ausgrabungen auf der Akropolis,” AM 5 (1880) 314-315; <JBcom>[doubtably]</JBcom> attributed to the Great Altar of Athena (Athena Ath2*), Mobius, H. 1927, 181-189, figs. 6-7, Beilage XII nos. 5-8</p>
<p :class="{'activeClass': idClicked == NP2836 }" id="NP2836" >Olive tree pediment, archaic poros limestone sculpture, Akr. Acr. 52; Heberdey, R. 1919, 16-28 See also: Index IV under Olive</p>
<p :class="{'activeClass': idClicked == NP2837 }" id="NP2837" >OLOROS, father of Thucydides, grave in Kimon's family plot: Marcellin. Vit. Thuc. 17; O. Luschnat, RE Suppl. XII, 1970, col. 1105-1106 (Thukydides)</p>
<p :class="{'activeClass': idClicked == NP2838 }" id="NP2838" >OLYMPIA, OLYMPIOS: see Ge Olympia; Hadrian Olympios; Nike Olympia; Zeus Zeu70*-83*</p>
<p :class="{'activeClass': idClicked == NP2839 }" id="NP2839" >OLYMPIAS (personification): see Alkibiades</p>
<p :class="{'activeClass': idClicked == NP2840 }" id="NP2840" >OLYMPICUM CHRONICON, chronicle of the Olympic Games, ca. 264 B.C., Lykabettos south slope - - - IG II² 2326; SEG 34 (1984) no.173</p>
<p :class="{'activeClass': idClicked == NP2841 }" id="NP2841" >OLYMPIODOROS, strategos who stormed the Mouseion in 287 B.C.: Habicht, Chr. 1985, 89-91; J. J. Gabbert, “The Career of Olympiodorus of Athens (ca. 340-270 B.C.),” Ancient World 27 (1996) 59-66</p>
<p :class="{'activeClass': idClicked == NP2842 }" id="NP2842" >Statue on the Akropolis: Paus. 1.25.2 + 1.26.3 + 1.26.4; Richter, G. M. A. 1972, II, 170; R. von den Hoff, op. cit. (under Demetrios of Phaleron), 176-178</p>
<p :class="{'activeClass': idClicked == NP2843 }" id="NP2843" >Statue (?) in the Prytaneion: Paus. 1.26.3</p>
<p :class="{'activeClass': idClicked == NP2844 }" id="NP2844" >OMBRIOS: see Zeus Zeu84*-85*</p>
<p :class="{'activeClass': idClicked == NP2845 }" id="NP2845" >OMPHALOI: Omphaloi (?), at the northeast corner of the Metroon: Thompson, H. A. 1937, 110, 112, figs. 59, 101; S. I. Rotroff, “Three Cistern Systems on the Kolonos Agoraios,” Hesperia 52 (1983) 270 with note 44; Hedrick Jr., C. W. 1988, 194-195; identified as “reproduzioni dei caraterristici berretti terminati a punti attributi dei Cabiri-Dioscuri,” Torelli, M. 1995, 16-18, figs. 11-14; cf. marble piloi with separately attached stars found on Delos, LIMC III, 1986, 589 no. 248, ph., s.v. Dioskouroi (A. Hermary); the two omphaloi testify “inequivocabilmente la connessione con Apollo Pythios,” A. Latini, “L'attivita di Eufranore nell'Atene di Licurgo,” Annuario LXXIX (2001) 89; according to J. McK. Camp, March 4, 2003, these objects are not omphaloi, have lewis holes at the top and had some practical function</p>
<p :class="{'activeClass': idClicked == NP2847 }" id="NP2847" >Omphalos with laurel wreath and fillets <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201507" target="_blank">Agora S 1507</a> (unpublished)</p>
<p :class="{'activeClass': idClicked == NP2848 }" id="NP2848" >Omphalos altar with fillet network painted on stucco coating, west side of Perikles' Odeion: G. Karo, “Archaologische Funde vom Sommer 1931 bis Mai 1932,”, AA 1932, 123-124</p>
<p :class="{'activeClass': idClicked == NP2849 }" id="NP2849" >An omphalos as a statue base, theater - - - NM 46; Karouzou, S. 1968, 44 </p>
<p :class="{'activeClass': idClicked == NP2850 }" id="NP2850" >OMPNIAI: see Nymphs Nym6*</p>
<p :class="{'activeClass': idClicked == NP2851 }" id="NP2851" >OPISTHODOMOS, treasury on the Akropolis: IG I³ 52 A linesl 5-16, 17 and B line 24; SEG 42 (1992) no. 12 and 50 (2000) no. 38; Harris, D. 1995, 2, 4-5, 40-63, 204, 258; Μ. B. Hollinshead, “‘Adyton,' Opisthodomos,' and the Inner Room of the Greek Temple,” Hesperia 68 (1999) 210-213</p>
<p :class="{'activeClass': idClicked == NP2852 }" id="NP2852" >ORCHESTRA in the Agora, the central part of the theater, where the statues of Harmodios and Aristogeiton stood: Tim. Lex. s.v.; Agora III, 97 no. 276, 162-163 nos. 524-528, 220-221 nos. 721-728; <JBcom>[reasonable]</JBcom> conjectural location and <JBcom>[doubtable]</JBcom> circular plan presented as facts, Travlos, J. 1971, 3, 365, fig. 5 no. 33, fig. 29 no. 33; Kolb, F. 1981, 3, 26-46, 53-57, figs. 3-4. The testimonia indicate that The Orchestra belonged to an early theater of Dionysos in the Agora, preceding the theater of Dionysos Eleuthereus and that The Orchestra continued to exist for some time after the performances were transferred to the Akr. South Slope; see Agora XIV, General Index s.v. Orchestra: in the Agora, and pls. 4-6</p>
<p :class="{'activeClass': idClicked == NP2853 }" id="NP2853" >ORESTES killing Aigisthos and Pylades killing the sons of Nauplios who had come to rescue Aigisthos, picture in the Propylaia: Paus. 1.22.6; S. Woodford, “Palamedes seeks revenge,” JHS 114 (1994) 168</p>
<p :class="{'activeClass': idClicked == NP2854 }" id="NP2854" >ORPHEUS AND EURYDICE MONUMENT: four three-figure reliefs, late 5th c. B.C.: H. Gotze, “Die attischen Dreifigurenreliefs,” RM 53 (1938) 189-280, pls. 32-38; Ridgway, B. S. 1981, 206-10, 220-21, figs. 129-131; <JBcom>[the lost original by an Athenian sculptor was probably not set up in Athens, since none of the many copies was found in Athens]</JBcom>; cf. Twelve Gods: Sanctuary Enclosure Parapet</p>
<p :class="{'activeClass': idClicked == NP2855 }" id="NP2855" >Orpheus, Hermes, Eurydice: L.-A. Touchette, “A New Interpretation of the Orpheus-Relief, "AA 1990, 77-90, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP2856 }" id="NP2856" >Frag, of a head, 1st c. A.C., Academy Exc. of 1933 - - - NM 3775; hesitantly <JBcom>[implausibly]</JBcom> attributed, Karouzou, S. 1968, 45</p>
<p :class="{'activeClass': idClicked == NP2857 }" id="NP2857" >Herakles and the Hesperides: J. Raeder, “Neues Fragment eines Dreifigurenreliefs in englischem Besitz,” AA 1994, 383-391, figs. 1-5</p>
<p :class="{'activeClass': idClicked == NP2858 }" id="NP2858" >Herakles, Theseus, Peirithoos: Brommer, F. 1982, 102</p>
<p :class="{'activeClass': idClicked == NP2859 }" id="NP2859" >Medea and Pelias' daughters: Meyer, H. 1980, 38-50, 131-134, 137-139 with pls. 14, 29</p>
<p :class="{'activeClass': idClicked == NP2860 }" id="NP2860" >ORTHOS: see Dionysos Dio52*</p>
<p :class="{'activeClass': idClicked == NP2861 }" id="NP2861" >ORTHOSIA: see Artemis Art43*</p>
<p :class="{'activeClass': idClicked == NP2862 }" id="NP2862" >OSIRIS: Dedication, ca. 220 A.D., Ermou St. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204347" target="_blank">Agora I 4347</a>; Vidman, L. 1969, 19 no. 33 b</p>
<p :class="{'activeClass': idClicked == NP2864 }" id="NP2864" >Dedication, Monastiraki (Plan 1 alpha—5)- - - IG II² 4873; Vidman, L. 1969, 18 no. 33 See also: Nephthys and Osiris </p>
<p :class="{'activeClass': idClicked == NP2865 }" id="NP2865" >OULIOS: see Apollo Apo52**</p>
<p :class="{'activeClass': idClicked == NP2866 }" id="NP2866" >OURANIOS, OURANIA: see Aphrodite Aph35*-44*; Men O.; Nemesis O.</p>
<p :class="{'activeClass': idClicked == NP2867 }" id="NP2867" >OWL: Glaux en polei: D. Chr. XII.6; Judeich, W. 1931, 284 note 18</p>
<p :class="{'activeClass': idClicked == NP2869 }" id="NP2869" >Limestone owl from an archaic pediment Acr. 56; Heberdey, R. 1919, 72-73, figs. 50-52</p>
<p :class="{'activeClass': idClicked == NP2870 }" id="NP2870" >Limestone owl Berlin inv. 1722 = A 10; BIGmel, C. 1963, 19 no. 10, figs. 27-28</p>
<p :class="{'activeClass': idClicked == NP2871 }" id="NP2871" >Marble owl, early 5th c. B.C. Acr. 1347; Schrader, H. 1939, no. 380, figs. 301-302</p>
<p :class="{'activeClass': idClicked == NP2872 }" id="NP2872" >Marble owl fragments, 490-480 B.C. Acr. 340 +; Schrader, H. 1939, no. 381, figs. 301-305</p>
<p :class="{'activeClass': idClicked == NP2873 }" id="NP2873" >Silver owl on a boxwood column in the Erechtheion, recorded in 307/6 B.C.: IG II² 1489 lines 33-35; Harris, D. 1995, 209 no. 18b</p>
<p :class="{'activeClass': idClicked == NP2874 }" id="NP2874" >PAIDEIOS: see Dionysos Dio53*</p>
<p :class="{'activeClass': idClicked == NP2875 }" id="NP2875" >PAION: see Apollo Apo53*; Asklepios Ask50*</p>
<p :class="{'activeClass': idClicked == NP2876 }" id="NP2876" >PAIONIA: see Athena Ath90*-91*</p>
<p :class="{'activeClass': idClicked == NP2877 }" id="NP2877" >PAIRISADES I, Bosporan ruler, and two of his sons, SATYROS and GORGIPPOS, bronze statues set up in the Agora ca. 330-324 B.C.: IG II² 653 lines 14-15, 41; Din. I.43; S. M. Burstein, “I.G.II2 653, Demosthenes and Athenian Relations with Bosporus in the Fourth Century B.C.,” Historia 27 (1978) 428-436; see Spartokos II and Pairisades I</p>
<p :class="{'activeClass': idClicked == NP2878 }" id="NP2878" >PALAIMON, dedication with relief of Palaimon seated holding cornucopia and phiale, and kneeling figure, 325-300 B.C., sanctuary of Pankrates on the Ilissos (Plan 2 zeta—8) First Ephoria Pankrates no. 3A; SEG 16 (1959) no. 184; Vikela, E. 1994, 19-20 no. A 10, pl. 8; see Pankrates and Palaimon</p>
<p :class="{'activeClass': idClicked == NP2879 }" id="NP2879" >PALLADIA, images of the Palladion Statue brought from Troy, the Palladion seized by Demophon, son of Theseus: Paus. J.28.9, cf. I. 22.6; Kron, U. 1976, 149-151; Kearns, E. 1989, 155-156; SEG 42 (1992) 108; cf. Diomedes</p>
<p :class="{'activeClass': idClicked == NP2880 }" id="NP2880" >Pallas: a second statue (hedos) of Pallas dedicated to the gods and the city by the priest of Zeus at the Palladion in obedience to an oracle of Pythian Apollo: IG II² 3177; L. Ziehen, RE XVIII, 1949, col. 176 (Palladion); Nagy, B. 1991, 296-299 Palladion on the Akropolis dedicated by Nikias: Plu. Nic. 3.3</p>
<p :class="{'activeClass': idClicked == NP2881 }" id="NP2881" >Gilded ivory Palladion dedicated in the Parthenon: IG II² 1388 lines 67-69; Harris, D. 1995, 134 no. 97</p>
<p :class="{'activeClass': idClicked == NP2882 }" id="NP2882" >Palladia listed in an inventory of bronze statues compiled by the Treasurers of Athena, ca. 340-330 B.C., Akropolis: IG II² 1498 B lines 60, 67, 69-70 (rest.); SEG 42 (1992) no. 128 See also: Diomedes; Odysseus, pictures</p>
<p :class="{'activeClass': idClicked == NP2883 }" id="NP2883" >PALLADION, law court where cases of involuntary homicide were tried: Paus. 1. 28.8-9; founded at the site where the slain Argives were buried, Pollux 8.118; the testimonia indicate that the Palladion lawcourt was in Phaleron, L. Ziehen, RE XVIII, 1949, col. 177 (Palladion); Agora XXVIII, testimonia nos. 1-8, 40-53 and Index s.v. Palladion; "two quite separate sanctuaries called Palladion" <JBcom>[implausibly]</JBcom> conjectured, ibid., 47-48; <JBcom>[since the slain Argives were likely buried where they fell, the Palladion was probably at Phaleron]</JBcom>; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM164">164</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM182">182</a></p>
<p :class="{'activeClass': idClicked == NP2884 }" id="NP2884" >Athena at the Palladion: see Athena Ath57*; Palladia</p>
<p :class="{'activeClass': idClicked == NP2885 }" id="NP2885" >Zeus at the Palladion: see Zeus Zeu11*-12*</p>
<p :class="{'activeClass': idClicked == NP2886 }" id="NP2886" >Philosophic school at the Palladion, founded 140 B.C. by Kleitomachos of Carthage: Academicorum Philosophorum Index Herculanensis, col. 25.8-9; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM182">182</a></p>
<p :class="{'activeClass': idClicked == NP2887 }" id="NP2887" >PALLAS: see Erechtheion; Palladia; Parthenon: Pallados graffito; for the epithet, see Nagy, B. 1991, 295-300</p>
<p :class="{'activeClass': idClicked == NP2888 }" id="NP2888" >PAN: Bourgeaud, P. 1979, 195-237, 259-261</p>
<p :class="{'activeClass': idClicked == NP2889 }" id="NP2889" >1* Pan statue dedicated by Miltiades: API 232; Further Greek Epigrams 1981, 194-195 no. V by 'Simonides'; cf. Pan Pan9*</p>
<p :class="{'activeClass': idClicked == NP2890 }" id="NP2890" >2* Pan with trophy, Parian marble, dedicated by the Athenians, Akr.: API no. 259; F. Brommer, RE Suppl. VIII, 1956, col. 967 no. 2 (Pan)</p>
<p :class="{'activeClass': idClicked == NP2891 }" id="NP2891" >3* Bronze kerykeion with Pan heads, Akr. - - - NM 7146; F. Brommer, op. cit, col. 965, 967; disassociated from Kallimachos' dedication (Athena Ath8*), E. B. Harrison, “The Victory of Kallimachos,” GRBS 12 (1971) 6 note 4</p>
<p :class="{'activeClass': idClicked == NP2892 }" id="NP2892" >4* CAVE of PAN on the Akr. North Slope (Cave complex Delta), cult inaugurated after the Battle of Marathon: Paus. 1.28.4; Travlos, J. 1971, 417-421; Bourgeaud, P. 1979, 195-239, 259, 261; represented on Athenian imperial bronze coins, 2nd c. A.C., Agora XXVI, 1993, 145 no. 280a, pl. 19</p>
<p :class="{'activeClass': idClicked == NP2893 }" id="NP2893" >5* Rock-cut niche, roughly semicircular, at Cave Delta: E. Vanderpool's observation that the niche was cut to hold a grotto relief of Pan and The Nymphs was published by Travlos, J. 1971, 417, figs. 536-537; the niche was probably carved by the builders of the chapel of Hag. Athanasios (Chr. Mon. CM56*) according to Edwards, C. M. 1985, 25-26; Nulton, P. E. 2000, 11, 32 note 78</p>
<p :class="{'activeClass': idClicked == NP2894 }" id="NP2894" >6* Altars: E. Ion 938</p>
<p :class="{'activeClass': idClicked == NP2895 }" id="NP2895" >7* Pan in mantle with syrinx, statuette found below the cave Fitzwilliam inv. GR 4.1865; Budde, L. and R. Nicholls 1964, 26-27 no. 49, pl. 11</p>
<p :class="{'activeClass': idClicked == NP2896 }" id="NP2896" >8* Colossal fragment, mid-2nd c. A.C., Akr. north slope <JBcom>[?]</JBcom> - - - Copenhagen, Danish Nat. Mus. no. ABb160; P. J. Riis, “A colossal Athenian Pan,”, Acta Arch. 45 (1974) 124-133, fig. 1; in fact the head of a Silen from a figure in the Theater of Dionysos stage front, Despinis, G. 2003, 140-141, figs. 286-287</p>
<p :class="{'activeClass': idClicked == NP2897 }" id="NP2897" >9* Goat head frag., limestone, findspot according to the dealer “near the west end of the north slope of the Acropolis” - - - Cleveland Mus. of Art 26.538; tentatively attributed to Miltiades' dedication (Pan Pan1*), H. S. Robinson, “A Greek Head - Animal or Hybrid?,”, Bulletin of the Cleveland Museum of Art 64 (1977) 230-241, cover photo, figs. 1-5; authenticity doubtable, A. P. Grimanis et al., Transactions of The American Nuclear Society, June 1992, 170-171 with note 8</p>
<p :class="{'activeClass': idClicked == NP2898 }" id="NP2898" >10* Pan, Hermes, (Nymphs), relief, 330-320 B.C., found in 1829 below the cave of Pan, Akr. North Slope - - - NM 1443; Svoronos, J. N. 1911, 442-3 no. 142, pl. 53; Edwards, C. M. 1985, 499-502 no. 25</p>
<p :class="{'activeClass': idClicked == NP2899 }" id="NP2899" >11* Pan (and Nymphs), relief, Akr. northwest slope at the Odysseus Bastion - - - Acr. 4064, IG II² 4672; SEG 17 (1960) no. 88</p>
<p :class="{'activeClass': idClicked == NP2900 }" id="NP2900" >12* Pan and Nymphs, relief, 100-50 B.C., Akr. northwest slope - - - Acr. 1345; Brouskari, M. 1974, 167, figs. 354-355; Edwards, C. M. 1985, 715-719</p>
<p :class="{'activeClass': idClicked == NP2901 }" id="NP2901" >Cave of Pan on the Akropolis South Slope: see Asklepios Ask27*; Nymphs Nym12*</p>
<p :class="{'activeClass': idClicked == NP2902 }" id="NP2902" >13* Head frag. - - - Nasjonalgalleriet Oslo; A. Seeberg, “Den evige Pan,” in Streiftog I Antiken: Til H. P. Oranges 70-Arstag (Kunst og Kulturs Serie) Oslo, 1973, 3-12, figs. 1-2</p>
<p :class="{'activeClass': idClicked == NP2903 }" id="NP2903" >14* Statuette, Bakcheion area: K. A. Neugebauer, “Bruchstücke eines Relieffrieses vom Esquilin mit unerklarter Darstellung,” in Brunn-Bruckmann's Denkmaler griechischer und romischer Kunst, München, 1932, 21-22 under no. 725, fig. 14</p>
<p :class="{'activeClass': idClicked == NP2904 }" id="NP2904" >15* Seated piping Pan statue, 2nd c. B. C., Olympieion NM 683; Karouzou, S. 1968, 93; Kaltsas, N. 2002, 264 no. 552, ph.</p>
<p :class="{'activeClass': idClicked == NP2905 }" id="NP2905" >15** Rock-cut relief of Pan grabbing a beautiful naked nymph, Pitys, who pushes him away as she turns into a pine tree, a noble seated dog with upraised paw at the far left, at the entrance to a cave with a spring house (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM231">231</a>) on the lower northwest slope of the Pnyx, at the intersection of Apostolou Pavlou and Aiginetou Sts.: Choremi-Spetsieri, A. 20C3, 8</p>
<p :class="{'activeClass': idClicked == NP2906 }" id="NP2906" >16* Pan playing syrinx outlined on a rock-cut wall, part of a structure with two rock-cut walls meeting at a right angle, one partly demolished by dynamiting <JBcom>[halted by Eleftheria Serbeti in 1968]</JBcom>, beside Haghia Photini (Chr.Mon. CM49*) at the Ilissos (Plan 2 eta—6): Travlos, J. 1971, 289, fig. 154 no. 154, fig. 379 no. 154, fig. 380 lower right corner, figs 386-387; Wycherley, R. E. 1978, 171-172, fig. 50. <JBcom>[the rock-cut walls belong to the Metroon in Agra where the Lesser Mysteries were celebrated]</JBcom>; cf. Mother of The Gods Met10*</p>
<p :class="{'activeClass': idClicked == NP2907 }" id="NP2907" >17* Terracotta bust, southeast of the Panathenaic Stadium: A. Furtwangler, “Buste Pans in Terracotta,” AM 3 (1878) 155-60, pl. 8</p>
<p :class="{'activeClass': idClicked == NP2908 }" id="NP2908" >18* Pan, Men (Μην), Nymphs and, possibly, Enodia, graffiti on a well head, west of the grave terrace of Lysimachides, Kerameikos Exc. - - - IG II² 4876; Lane, E. 1971/1978, I, 3-4 no. 5; III, 1976, 1, 5, 6, 7, 88, 107; IV, 1978, 62; Bourgeaud, P. 1979, 206-208</p>
<p :class="{'activeClass': idClicked == NP2909 }" id="NP2909" >19*-20* Panes, Nymphs and Hekataion, grotto reliefs:</p>
<p :class="{'activeClass': idClicked == NP2910 }" id="NP2910" >19* Relief found at the Lysikrates Mon.: Schrader, H. 1896, 276-278 with drawing in text</p>
<p :class="{'activeClass': idClicked == NP2911 }" id="NP2911" >20* Relief - - - München, Glyptothek 456; Schrader, H. 1896, 276 no. 1, pl. 8; F. Eckstein, “Das Hekataion in der Britisch School zu Athen,” Antike Plastik IV, 1965, 35 note 58, fig. 13 See also: Aphrodite Aph55*; Apollo Apo86*; Hermes Hrm35*, Hrm37*; Kourotrophos, altar; Mother of The Gods Met11*-12*; Nymphs Nym6*-9*, Nym11*, Nym12*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM196">196</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM528B">528B</a></p>
<p :class="{'activeClass': idClicked == NP2912 }" id="NP2912" >PANAKEIA: see Asklepios Ask22*</p>
<p :class="{'activeClass': idClicked == NP2913 }" id="NP2913" >PANATHENAIC PEPLOS: see Antigonos I and Demetrios Poliorketes; Peplotheke; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM390">390</a> (ergasterion)</p>
<p :class="{'activeClass': idClicked == NP2914 }" id="NP2914" >PANATHENAIC SHIP: Paus. 1.29.1 (seen near the Areopagus); Shear Jr., Leslie T. 1978, 39-44, testimonia in note 101; Mansfield, J. M. 1989, 2-6, 12-18, 46-50, 68-78; cf. Part II no 408B</p>
<p :class="{'activeClass': idClicked == NP2915 }" id="NP2915" >Relief of the Panathenaic Ship, mostly obliterated by a Christian cross, on the State Festival Calendar Frieze q.v.: N. J. Norman, “The Panathenaic Ship”, Archaeological News 12 (1983) 41-46, fig. 3</p>
<p :class="{'activeClass': idClicked == NP2916 }" id="NP2916" >PANATHENAIC SHIPHOUSE: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM528">528</a></p>
<p :class="{'activeClass': idClicked == NP2917 }" id="NP2917" >PANATHENAIC STADIUM, Ilissos left bank (Plan 2 eta/theta -7, no. 75): Paus. 1.19.6; founded by Lykourgos, Plu. Moralia 841 D; Travlos, J. 1971, 498-503, figs. 379, 628-633; Gasparri, C. 1974/75, 313-392; <JBcom>[in the teeth of the evidence]</JBcom> located on the Pnyx, D. G. Romano, “Lykourgos, the Panathenaia and the Great Altar of Athena,” in Pnyx 1996, 71-85, fig. 57 = SEG 44 (1994) no. 41; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM259">259</a></p>
<p :class="{'activeClass': idClicked == NP2918 }" id="NP2918" >Called Panathenaic Theater in an honorary decree for Eudemos of Plataia, 330/329 B.C.: IG II² 351 lines 16-18</p>
<p :class="{'activeClass': idClicked == NP2919 }" id="NP2919" >Rebuilt in marble by Herodes Atticus 139/140 - 143/44 A.D.: Paus. 1. 19.6; Welch, K. 1998, 133-138, figs. 16-20</p>
<p :class="{'activeClass': idClicked == NP2920 }" id="NP2920" >Thrones and benches with owl reliefs: Gasparri, C. 1974/75, 340-61, figs. 25-54</p>
<p :class="{'activeClass': idClicked == NP2921 }" id="NP2921" >Biel throne with relief of agonistic table and olive tree Salysbury, Teffont Manor; ibid. 349-351 T1, figs. 36-40</p>
<p :class="{'activeClass': idClicked == NP2922 }" id="NP2922" >Twin of Biel throne: ibid. 352 T2, fig. 41</p>
<p :class="{'activeClass': idClicked == NP2923 }" id="NP2923" >Owl benches - - - Acr. 3665-7 +, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20181" target="_blank">Agora S 181</a>, S 1590, NM 3215 +; ibid. 340-348, figs. 25-33</p>
<p :class="{'activeClass': idClicked == NP2924 }" id="NP2924" >Throne with palmettes on armrests, now east of the Parthenon: ibid. 352-353 T3, figs. 42-43</p>
<p :class="{'activeClass': idClicked == NP2925 }" id="NP2925" >Double herms with Hermes and Apollo: D. Willers, “Zum Hermes Propylaios des Alkamenes,” Jahrbuch 82 (1967) 56-65, figs. 18-30</p>
<p :class="{'activeClass': idClicked == NP2926 }" id="NP2926" >Double herm A now in the stadium: ibid., 56-65, figs. 18-20, 25</p>
<p :class="{'activeClass': idClicked == NP2927 }" id="NP2927" >Double herm B now in the stadium: ibid. figs. 21-24</p>
<p :class="{'activeClass': idClicked == NP2928 }" id="NP2928" >Double herm C - - - NM 1693; ibid. figs. 26-28</p>
<p :class="{'activeClass': idClicked == NP2929 }" id="NP2929" >Double herm D NM 1801; ibid. figs. 29-30</p>
<p :class="{'activeClass': idClicked == NP2930 }" id="NP2930" >Tunnel at the southeast end of the Stadium taken to be a cave, Spilia ton Moiron, in recent times: Wickens, J. M. 1986, 318 under no. 58</p>
<p :class="{'activeClass': idClicked == NP2931 }" id="NP2931" >PANATHENAIC VOTIVE DEPOSIT, RF oinochoai depicting Athena driving a chariot over the sea, ca. 410 B.C., Akr. North Slope ca. 7 m. east of the Klepsydra - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2015840" target="_blank">Agora P 15840</a> +; R. Green, “A New Oinochoe Series from the Acropolis North Slope,” Hesperia 31 (1962) 82-94, figs. 1-3, pls. 28-32</p>
<p :class="{'activeClass': idClicked == NP2932 }" id="NP2932" >PANATHENAIC WAY from the Pompeion to the Akropolis: Agora III, 222, nos. 1, 193, 199, 201, 203, 216, 302, 321, 336, 338, 729-730; Travlos, J. 1971, 2-3, 422-8, figs. 102, 260, 265, 540, 543-6; Agora XIV, 1972, General Index s.v. Panathenaic Way, pl. 99; see Dromos; Sacred Way; for the last section approaching the Propylaia see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM6">6</a> (earlier ramp), no. 13 (later ramp) and no 18 ( Roman Monumental Stairway)</p>
<p :class="{'activeClass': idClicked == NP2933 }" id="NP2933" >Inscription naming the Panathenaic Way, ca. 350-300 B.C., carved on the north face of the Mnesiklean Bastion <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204963" target="_blank">Agora I 4963</a>; E. Vanderpool, “The Route of Pausanias in the Athenian Agora,” Hesperia 18 (1949) 134-135 note 26, pl. 9; Travlos, J. 1971, 422, fig. 544; SEG 51 (2001) no. 168</p>
<p :class="{'activeClass': idClicked == NP2934 }" id="NP2934" >PANDAITES' and PASIKLES' DEDICATION, base for a statue group of Pasikles of Potamos and his family, 325-287 B.C., signed by Sthennis and Leochares, west of the Parthenon - - - IG II² 3829 a-e; Davies, J. K. 1971, 23-24; Chr. Habicht, “Sthennis,” HOROS 10-12 (1992-1998) 21-26; for information on Sthennis, see SEG 46 (1996) no. 179</p>
<p :class="{'activeClass': idClicked == NP2935 }" id="NP2935" >Four blocks reused for Augustus' family group "celebrating the dynastic arrangement of A.D. 4": Geagan, D. 1996, 157, 161</p>
<p :class="{'activeClass': idClicked == NP2936 }" id="NP2936" >Augustus - - - IG II² 3253 (= IG II² 3829 c); Guarducci, M. 1967-1978, II, 149, fig. 33</p>
<p :class="{'activeClass': idClicked == NP2937 }" id="NP2937" >Tiberius - - - IG II² 3254 (= IG II² 3829 d)</p>
<p :class="{'activeClass': idClicked == NP2938 }" id="NP2938" >Germanicus - - - IG II² 3255 (= IG II² 3829 b)</p>
<p :class="{'activeClass': idClicked == NP2939 }" id="NP2939" >Drusus, son of Tiberius IG II² 3256 (= IG II² 3829 e)</p>
<p :class="{'activeClass': idClicked == NP2940 }" id="NP2940" >One block reused for a statue base of Trajan - - - IG II² 3284 (= IG II² 3829 a); D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 77</p>
<p :class="{'activeClass': idClicked == NP2941 }" id="NP2941" >PANDEMOS: see Aphrodite Aph45*</p>
<p :class="{'activeClass': idClicked == NP2942 }" id="NP2942" >PANDIA, cult: IG II² 1172 line 9; A. Heinrichs, “Zur Genealogie des Musaios,” ZPE 58 (1985) 4-5</p>
<p :class="{'activeClass': idClicked == NP2943 }" id="NP2943" >PANDION: Kron, U. 1976, 104-119; Kearns, E. 1989, 191-192</p>
<p :class="{'activeClass': idClicked == NP2944 }" id="NP2944" >Heroon on the Akropolis: IG II² 1144 line 9; <JBcom>[mistaken]</JBcom> identification as the Southeast Building, Kron, U. 1976, 109-111; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM64">64</a></p>
<p :class="{'activeClass': idClicked == NP2945 }" id="NP2945" >Statue on the Akr.: Paus. 1.5.4; U. Kron, “Eine Pandion-Statue in Rom, Jahrbuch 92 (1977) 162-164</p>
<p :class="{'activeClass': idClicked == NP2946 }" id="NP2946" >Eponymous Hero statue: Paus. 1.5.3</p>
<p :class="{'activeClass': idClicked == NP2947 }" id="NP2947" >PANDOCHEION, at Pherai: erroneously located in Athens by Theodoretos, Bishop of Kyrrhos, Therapeutika VIII: P. Chrysostomos, «Το Πανδοχείο και το Διοσκούρειο των Φερών,» ΑΑΑ 16 (1983) 96, 105</p>
<p :class="{'activeClass': idClicked == NP2948 }" id="NP2948" >PANDROSOS, precinct west of the Erechtheion, the Pandroseion: IG I³ 474 lines 45, 170, 177 and IG I³ 475 lines 117, 131, 258; Caskey, L. D. et al. 1927, General Index s.v. Pandroseum; Kearns, E. 1989, 127,192-193</p>
<p :class="{'activeClass': idClicked == NP2949 }" id="NP2949" >Temple of Pandrosos adjoining the temple of Athena: Paus. 1.22.7; Travlos, J. 1971, fig. 281 P, presents a conjectural restored plan of the temple with a statue base, both <JBcom>[deceptively]</JBcom> shown in solid black <JBcom>[as if guaranteed by the remains]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2950 }" id="NP2950" >L-shaped stoa on the north and west sides of the precinct: Hurwit, J. M. 1999, 145, figs. 3 no. 110, 115</p>
<p :class="{'activeClass': idClicked == NP2951 }" id="NP2951" >Eaves tile with painted olive branch, 480-460 B.C. - - - Acr. K 156; Christina Vlassopoulou, “Decorated Architectural Terracottas from the Athenian Acropolis: Catalogue of Exhibition,” Hesperia 59 (1990) XXIV - XXV (the Roman numerals are page numbers following page 323), no. 44, ph. See also: Athena Ath128*; Herse</p>
<p :class="{'activeClass': idClicked == NP2952 }" id="NP2952" >PANHELLENION, sanctuary of Hadrian Panhellenios, the meeting-place of the Panhellenes, <JBcom>[announced but not yet underway at the dedication of the temple of Zeus Olympios]</JBcom> 131/132 A.D.: Oliver, J. H. 1970 92-138; A. J. Spawforth and S. Walker, “The World of the Panhellenion. I. Athens and Eleusis,”, JRS 75 (1985) 94-98, fig. 2 no. 9 and pl. II no. 3; SEG 35 (1985) no. 1767; Willers, D. 1990, 54-60, 97-103; the basilica east of the Roman Agora (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM207">207</a>) is almost certainly the Panhellenion; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM553">553</a> The ancient name Panhellenion applies to the sanctuary for Hadrian Panhellenios; D.C. 69.16. <JBcom>[Inscriptions set up in the Panhellenion refer to Hadrian Olympios (q.v.); inscriptions concerning the Panhellenion set up elsewhere refer to Hadrian Panhellenios]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2953 }" id="NP2953" >Temple of (Hera? and) Zeus Panhellenios: Paus. 1.18.9</p>
<p :class="{'activeClass': idClicked == NP2954 }" id="NP2954" >Cult image(s): A. D. Nock, «ΣΥΝΝΑΟΣ ΘΕΟΣ,» HSCP 41 (1930)32</p>
<p :class="{'activeClass': idClicked == NP2955 }" id="NP2955" >Round base for Cn. Cornelius Pulcher of Corinth, perhaps the first archon of the Panhellenion, 133 A.D. — 1st Ephor. Rho Alpha 636; S. N. Koumanoudes, «Έπιγραφαι έξ Άθηνών,» Deltion 25 (1970) A, 54-55 no.1, pl. 24 beta; Follet, S. 1976, 126, 129</p>
<p :class="{'activeClass': idClicked == NP2956 }" id="NP2956" >Bases for statues of archons of the Panhellenion built into the foundations of the Post-Herulian Wall, gate-tower south of the Stoa of Attalos: Robert, L. 1969, 30-34</p>
<p :class="{'activeClass': idClicked == NP2957 }" id="NP2957" >Titus Flavius Cyllus of Hypata in Thessaly, after 156 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206738" target="_blank">Agora I 6738</a>; A S. Benjamin, “Two Dedications in Athens to Archons of the Panhellenion,” Hesperia 37 (1938) 340-344, fig. 1, pl. 101; SEG 25 (1971) no. 211; N. V. Sekunda, “The Kylloi and Eubiotoi of Hypata During the Imperial Period,” ZPE 118 (1997) 218-220</p>
<p :class="{'activeClass': idClicked == NP2958 }" id="NP2958" >Tiberius Claudius Jason Magnus of Cyrene, after 157 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206737" target="_blank">Agora I 6737</a>; A. S. Benjamin, op. cit, 338-340 no. 47, 342-344, fig. 1, pl. 101; SEG 25 (1971) no. 212 See also: Hadrian Olympios; Kasianos Antiochos</p>
<p :class="{'activeClass': idClicked == NP2959 }" id="NP2959" >PANHELLENIOS: see Hadrian Panhellenios; Zeus Panhellenios: → Panhellenion</p>
<p :class="{'activeClass': idClicked == NP2960 }" id="NP2960" >PANIONIOS: see Apollo Apo24*</p>
<p :class="{'activeClass': idClicked == NP2961 }" id="NP2961" >PANKRATES: see Zeus Zeu86*</p>
<p :class="{'activeClass': idClicked == NP2962 }" id="NP2962" >PANKRATES AND PALAIMON, sanctuary on the Ilissos with a cleft and rock-cut seats, Vasileos Konstantinou Blvd. and Vasileos Georgiou B' (formerly Diocharous) St. (Plan 2 zeta—8): Travlos, J. 1971, 278-280; E. Vikela, Die Weihreliefs aus dem Athener Pankrates-Heiligtum am Ilissos (AM 16. Beiheft), Berlin, 1994 <JBcom>[a work which would have benefitted greatly had the author been allowed to see the texts of the inscriptions from this sanctuary]</JBcom>; SEG 41 (1991) nos. 10, 82-84, 171-172, 247; SEG 44 (1994) nos. 139-159</p>
<p :class="{'activeClass': idClicked == NP2963 }" id="NP2963" >Herakles Pankrates reclining, sculpture in the round - - - 1st Ephor. Pankrates inventory number not known; <JBcom>[wrongly]</JBcom> identified as pediment sculpture from an <JBcom>[imaginary]</JBcom> temple, Travlos, J. 1971, 278, figs. 360-361</p>
<p :class="{'activeClass': idClicked == NP2964 }" id="NP2964" >Relief dedicated to Herakles Pankrates, by Philon and the runners, with relief of Herakles holding club and kantharos and Philon holding a torch (?), ca. 330 B.C. <JBcom>[?]</JBcom> - - - 1st Ephor. Pankrates 58 B; SEG 44 (1994) no. 139; Vikela, E. 1994, 35 no. B8, pl. 21; <JBcom>[the assigned date seems too early]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2965 }" id="NP2965" >Relief dedicated to Pankrates shown with lionskin and club, 325-300 B.C. 1st Ephor. Pankrates17 A; Travlos, J. 1971, fig. 356; Vikela, E. 1994, 38-39 no. B 12, pl. 23:2</p>
<p :class="{'activeClass': idClicked == NP2966 }" id="NP2966" >Relief of a leg dedicated to Pankrates, 2nd c. A.C. 1st Ephor. Pankrates 62 A; SEG 16 (1959) no. 183; Travlos, J. 1971, fig. 357; Vikela, E. 1994, 56 no. S 4, pl. 33:3</p>
<p :class="{'activeClass': idClicked == NP2967 }" id="NP2967" >Dedication to Pankrates with a relief of Herakles and a large bearded head emerging from below, 300-250 B.C. 1st Ephor. Pankrates 42 B; SEG 22 (1967) no. 164; Vikela, E. 1994, 36-37, pl. 22:2</p>
<p :class="{'activeClass': idClicked == NP2968 }" id="NP2968" >Pankrates, base of a votive dedicated by Nikarete - - - SEG 16 (1959) no. 181</p>
<p :class="{'activeClass': idClicked == NP2969 }" id="NP2969" >Pankrates, altar dedicated by Straton from Sidon, 4th c. B.C. SEG 16 (1959) no. 182</p>
<p :class="{'activeClass': idClicked == NP2970 }" id="NP2970" >Relief with god holding cornucopia and phiale, goddess and kneeling figure, 340-330 B.C. <JBcom>[?]</JBcom> 1st Ephor. Pankrates 10 A; Travlos, J. 1971, fig. 358; <JBcom>[wrongly]</JBcom> interpreted as Palaimon and Leukothea by E. Mitropoulou, Kneeling Worshippers in Greek and Oriental Literature and Art, Athens, 1975, 26-27 no. 2; Vikela, E. 1994, 12-13 no. 3, pl. 3</p>
<p :class="{'activeClass': idClicked == NP2971 }" id="NP2971" >Pankrates, dedication, with relief of Pankrates seated, holding cornucopia and phiale, altar, worshippers bringing pig, 330-325 B.C. <JBcom>[?]</JBcom> - - - 1st Ephor. Pankrates 56 B; SEG 44 (1994) no. 141; Vikela, E. 1994, 13-14 no. A4, pl. 4 See also: Alexander, head from Pankrates sanct.; Palaimon; Plouton </p>
<p :class="{'activeClass': idClicked == NP2972 }" id="NP2972" >PANOPS: Spring house at a postern gate in the City Wall, on Sokrates' way from the Academy to the Lykeion: Pl. Ly. 203 A; Camp, J. McK. 1980, 294, 320-321 (testimonia); Billot, M.-F. 1989, 702 no. 6; Ritchie Jr., C. E. 1980, 251-252</p>
<p :class="{'activeClass': idClicked == NP2974 }" id="NP2974" >Statue and temple of the hero Panops: Hsch s.v.; Kearns, E 1989, 193</p>
<p :class="{'activeClass': idClicked == NP2975 }" id="NP2975" >PANTAINOS, T. Flavius: Portrait herm, ca. 100 A.D. - - - EM 3849 + 3616; M. Mitsos, «Άπό τους καταλόγους Αθηναίων έφήβων,» Deltion 25 (1970) A Meletai, 187 no. 6, pl. 63:b; SEG 29 (1979) no. 180; Clinton, K. 1974, 30 no. 19</p>
<p :class="{'activeClass': idClicked == NP2977 }" id="NP2977" >Library dedicated ca. 100 A.D. to Athena Polias, Trajan and to the Polis of the Athenians by T. Flavius Pantainos, priest of the philosophic Muses, on the Panathenaic Way south of the Stoa of Attalos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20848" target="_blank">Agora I 848</a>; Agora III, 150 no. 464; SEG 39 (1989) no. 214; Agora Guide 1990, 140-142, 276 no. 140, figs. 7, 19 no. 17, 90-91, no. 48 on the Actual State Plan</p>
<p :class="{'activeClass': idClicked == NP2978 }" id="NP2978" >Library rules, ca. 100 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202729" target="_blank">Agora I 2729</a>; Agora Guide 1990, 140-141, 238-239, fig. 91</p>
<p :class="{'activeClass': idClicked == NP2979 }" id="NP2979" >PANTHEON: see All The Gods; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM207">207</a></p>
<p :class="{'activeClass': idClicked == NP2980 }" id="NP2980" >PANTHERS AND LIONS, archaic architectural cut-out reliefs, Akr. - - - Acr. 122, 551-5; Schrader, H. 1939, nos. 462-3, figs. 400-404, pls. 182-183; <JBcom>[doubtably]</JBcom> placed on the metopes of the mid-6th c. B.C. temple of Athena (Athena Ath19*), M. Korres, «Κλασσική αθηναϊκή άρχετεκτονική,» in ΑΘΗΝΑΙ 2000, 7, fig. 4</p>
<p :class="{'activeClass': idClicked == NP2981 }" id="NP2981" >PAPHIAN KORE: see Demeter and Aphrodite</p>
<p :class="{'activeClass': idClicked == NP2982 }" id="NP2982" >PARABYSTON, lawcourt where the Eleven supervised trials : F GR HIST III B, 74, Androtion 324 F 59 with suppl. vol. I, 164-166; Paus. 1.28.8; Agora XXVIII Index s.v. Parabyston</p>
<p :class="{'activeClass': idClicked == NP2983 }" id="NP2983" >PARALOS and AMMONIAS, Sacred Ships, picture by Protogenes of Kaunos in the Propylaia: Plin. Nat. XXXV.101; Beschi, L. 1969/1970, 130-131</p>
<p :class="{'activeClass': idClicked == NP2984 }" id="NP2984" >PARASITION, office of the parasitai: Poll. 6.35</p>
<p :class="{'activeClass': idClicked == NP2985 }" id="NP2985" >PARNESSIOS: see Zeus Zeu87*</p>
<p :class="{'activeClass': idClicked == NP2986 }" id="NP2986" >PARNOPIOS: see Apollo Apo54*</p>
<p :class="{'activeClass': idClicked == NP2987 }" id="NP2987" >PARTHENON, the “Kleisthenic Parthenon” project, ca. 508 - ca. 490 B.C., designed as a poros limestone temple for which only the foundations were built, M. Korres, “The Architecture of the Parthenon,” in Parthenon 1994, 56, fig. 3; blocks quarried in Peiraeus for the abandoned project of the Peisistratid Olympieion used for the foundations, Korres M. 1997, 236-240, 242 “Plan (nach Einstellung der Arbeiten am Olympieion)”; M. Korres, «Όλυμπιείον,» Anthemio, January 1999, 27</p>
<p :class="{'activeClass': idClicked == NP2988 }" id="NP2988" >The unfinished marble temple, the Older Parthenon, ca. 490-485 B.C.: Tschira, A. 1972, 158-231, pls. 1-2, figs. 1-50; H. Drerup, “Parthenon und Vorparthenon. Zum Stand der Kontroverse,” Antike Kunst 24 (1981) 21-37; Korres, M. and C. Bourras 1983, 131, 132; M. Korres, “The Architecture of the Parthenon,” in Parthenon 1994, 56-57, fig. 1 (reconstruction of the plan), figs 3-4, 24; Korres, M. 1997, 218-220, 225, 236-241, fig. 2 nos. 8-9,11</p>
<p :class="{'activeClass': idClicked == NP2989 }" id="NP2989" >Temple completed in 432 B.C.: IG I³ 436-451; SEG 40 (1990) no. 19; Paus. 1.1.2, 1.24.5, 1.24.8, 1.37.1, 5.11.10, 8.41.9; represented on imperial bronze coins, 3rd c. A.C., Price, J. M. and B L. Trell 1977, 68 fig. 133, 78 with fig. 133; Orlandos, A. K. 1977, 3 vols.; Korres, M. and C. Bouras 1983, 171-197 (bibliography); The Parthenon and Its Impact in Modern Times, P. Tournikiotis ed., Athens, 1994, 1-365; see Chr. Mon. CM11*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM357">357</a> (original cella colonnade fragments found in foundations of the 5th c. A.C. in the Agora Excavations)</p>
<p :class="{'activeClass': idClicked == NP2990 }" id="NP2990" >Small marble naiskos <JBcom>[?]</JBcom> facing east and cylindrical altar <JBcom>[?]</JBcom> in the north colonnade: M. Korres, “The History of Acropolis Monuments,” in Acropolis Restoration 1994, 45 (plan), 46 text and drawing); naiskos and altar <JBcom>[doubtably]</JBcom> dated to the mid-6th c. B.C. and <JBcom>[doubtably]</JBcom> assigned to Athena Ergane, Korres, M. 1997, 227-229, 242, fig. 2 nos. 7 and 7a, fig. 3; <JBcom>[since the evidence merely attests an enclosure, a naiskos with statue, although not excluded, is admittedly conjectural; since cylindrical altars are not attested in Attica in the 6th-5th c. B.C., the so-called altar may be a statue base]</JBcom></p>
<p :class="{'activeClass': idClicked == NP2991 }" id="NP2991" >Shields on the architraves: Orlandos, A. K. 1977, II. 211-215, figs. 135-138; Carroll, Κ. K. 1982, 11, 73 note 23, pl. 2, figs. 4-5; M. Korres, “The Parthenon from Antiquity to the 19th Century,” in Parthenon 1994, 138 with note 5, 139 with note 8, fig. 2; perhaps some of the 300 shields sent to Athens by Alexander the Great</p>
<p :class="{'activeClass': idClicked == NP2992 }" id="NP2992" >Inscription honoring Nero, 61/62 A.D., on the east architrave - - - IG II² 3277; Carroll, Κ. K. 1982, 1-74; SEG 45 (1995) no. 178</p>
<p :class="{'activeClass': idClicked == NP2993 }" id="NP2993" >Pallados graffito, 4th-5th c. A.C., on the east porch stylobate at the southeast corner Orlandos, A. K. 1977, I, fig. 83 See also: Unknown God(s); Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM209">209</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM357">357</a></p>
<p :class="{'activeClass': idClicked == NP2994 }" id="NP2994" >East Terrace: Stevens, G. P. 1940, 44-49, figs. 1, 34-35, 61</p>
<p :class="{'activeClass': idClicked == NP2995 }" id="NP2995" >North Terrace retaining wall: with <JBcom>[imaginary]</JBcom> stairs leading down from the North Terrace, Stevens, G. P. 1946a, 4, 5, 8, figs. 2, 3</p>
<p :class="{'activeClass': idClicked == NP2996 }" id="NP2996" >West Terrace with stepped terrace wall partly rock-cut, partly built of poros limestone blocks: Stevens, G. P. 1940, 6, 11-13, 24-40, frontispiece, figs. 1, 3-4, 6-9, 11, 14, 19-31, 34</p>
<p :class="{'activeClass': idClicked == NP2997 }" id="NP2997" >West court and propylon: G. P. Stevens, “The Periclean Entrance Court,” Hesperia 5 (1936) 472-482, figs. 1 no. 7, 25, 29-34</p>
<p :class="{'activeClass': idClicked == NP2998 }" id="NP2998" >PARTHENOS: see Athena Ath92*</p>
<p :class="{'activeClass': idClicked == NP2999 }" id="NP2999" >PATROOS: see Apollo Apo1*, Apo24*, Apo55*-63*</p>
<p :class="{'activeClass': idClicked == NP3000 }" id="NP3000" >PAUL: relief of St Paul with a Latin inscription on an arch, Acropolis, Frankish period, 13th c. (?)--- Byzantine Museum, no. _; A. Xyngopoulos,” Φρανγκοβυζαντινά γλυπτά έν Άθηναις,» ΑΕ 1931, 85 no. II with fig. 22, 91, 93-95</p>
<p :class="{'activeClass': idClicked == NP3001 }" id="NP3001" >PEGASOS OF ELEUTHERAI who brought Dionysos to Athens in accordance with the Delphic oracle, statue in a building near the sanct. of Dionysos Melpomenos: Paus. 1.2.5; Connor, W. R. 1990, 8-9</p>
<p :class="{'activeClass': idClicked == NP3002 }" id="NP3002" >PEIRAIC GATE in the Themistoklean City Wall: Plu. Thes. 27.4, Sull. 14.3; Judeich, W. 1931, 139-140, Plan I C-4; Stroud, R S. 1998, 105 note 45; see Themistoklean City Wall Wal32*</p>
<p :class="{'activeClass': idClicked == NP3003 }" id="NP3003" >PEIRITHOOS the Thessalian Lapith chieftain, eponymous hero of the deme Perithoidai: Kearns, E. 1989, 193-194</p>
<p :class="{'activeClass': idClicked == NP3004 }" id="NP3004" >Peirithoos, Theseus, Oidipous and Adrastos, heroon at Kolonos Hippios: Paus. 1.30.4, cf. 1.39.2; for the story of Adrastos, F GR HIST III B, suppl. vol. I, 442-448; Kearns, E. 1989, 140-141 s.v. Adrastos and 193-194 s.v. Peirithoos</p>
<p :class="{'activeClass': idClicked == NP3005 }" id="NP3005" >Lamplight picture of Peirithoos' wedding by Hippys: Ath. XI. 474 C-D See also: Orpheus Mon.; Theseus and Peirithoos</p>
<p :class="{'activeClass': idClicked == NP3006 }" id="NP3006" >PEISANAKTEIOS STOA: see Stoa Poikile</p>
<p :class="{'activeClass': idClicked == NP3007 }" id="NP3007" >PEISISTRATOS, son of Hippokrates</p>
<p :class="{'activeClass': idClicked == NP3008 }" id="NP3008" >Library: Gel. Noctes Atticae VII, XVII 1-2; Platthy, J. 1968, 97-110; Schenkungen hellenistischer Herrscher, Part II, vol. 1, 2000, 37-38</p>
<p :class="{'activeClass': idClicked == NP3009 }" id="NP3009" >Statue with an epigram on the Peisistratean recension of Homer: Vitae Homeri, ed. T. W. Allen, Homeri Opera, Oxford vol. 5, 248-249; Further Greek Epigrams 1981, 338-339 See also: Dionysos Dio10*; Part II no 620 (“Peisistratid Aqueduct” )</p>
<p :class="{'activeClass': idClicked == NP3010 }" id="NP3010" >PEISONIANOS' AND GRIPOS' dedication, rock-cut inscr. on the Akr. South Slope above the theater - - - IG II² 3167 with add. p. 349; Levensohn, M. and E., 1947, 68, 71 no. 71 in fig. 1</p>
<p :class="{'activeClass': idClicked == NP3011 }" id="NP3011" >PEITHO: F. Voigt, RE, XIX, 1937, col. 195 no. 4 (Peitho)</p>
<p :class="{'activeClass': idClicked == NP3012 }" id="NP3012" >Sacrifices made to Peitho by the prytaneis: D. Prooem. 54; Agora III, no. 474</p>
<p :class="{'activeClass': idClicked == NP3013 }" id="NP3013" >Priestess' theater seat - - - IG II² 5131; SEG 28 (1978) no. 237; see Demeter Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP3014 }" id="NP3014" >Earlier and later statues in sanctuary of Aphrodite Pandemos: Paus. 1. 22.3 See also: Demeter Kourotrophos</p>
<p :class="{'activeClass': idClicked == NP3015 }" id="NP3015" >PELARGIKON (or Pelasgikon): Iakovides, S. 1962, 231-235 (the testimonia), 265 (Index) s.v.; J. McK. Camp II, “Water and the Pelargikon,” in Studies Sterling Dow 1984, 37-41, fig. 1. The name Pelargikon is applied to three features according to the testimonia</p>
<p :class="{'activeClass': idClicked == NP3016 }" id="NP3016" >1) Earliest Akropolis circuit wall: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM36">36</a></p>
<p :class="{'activeClass': idClicked == NP3017 }" id="NP3017" >2) Fortification wall on the Akropolis slopes often called the Outer Pelargikon: Iakovides, S. 1962, 28, 177-199; J. McK. Camp, loc. cit., <JBcom>[doubtably]</JBcom> called the Enneapylon, Travlos, J. 1971, 52; <JBcom>[this wall must have enclosed a much more extensive area than has hitherto been supposed as Angelos P. Matthaiou observed in 1988]</JBcom>; cf. Nulton, P. E. 2000, 14 note 20; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM152">152</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM203">203</a></p>
<p :class="{'activeClass': idClicked == NP3018 }" id="NP3018" >3) Area of the Akropolis slopes protected by the terms of Lampon's proposed amendment to the decree concerning the Eleusinian First-fruits syngraphe: IG I³ 78 lines 54-57; Cavanaugh, Μ. B. 1980, 236, Index s.v. Pelargikon; SEG 51 (2001) no. 30</p>
<p :class="{'activeClass': idClicked == NP3019 }" id="NP3019" >Boundary stones for sanctuaries in the Pelargikon: IG I³ 78 lines 54-55; Ritchie Jr., C. E. 1985, 613-614; SEG 42 (1992) no. 17</p>
<p :class="{'activeClass': idClicked == NP3020 }" id="NP3020" >The names “Pelargikon” and “Upper Pelargikon” have also been applied to a <JBcom>[not entirely convincing]</JBcom> Mycenaean wall enclosing the terrace with the caves on the Akropolis northwest slope. According to Iakovides this wall is “an extension of the fortification preserving the name Pelargikon”; see Iakovides, S. E. 1962, 188-189, 203, figs. 39-42, plans 36, 38:2. According to Travlos this wall is <JBcom>[implausibly]</JBcom> named “Upper Pelargikon” and functioned down into Roman times as a retaining wall within which the <JBcom>[imaginary]</JBcom> eschara of Zeus Astrapaios was located, thus providing a wall between an <JBcom>[imaginary]</JBcom> Olympion and an <JBcom>[imaginary]</JBcom> Pythion, neatly fitting Strabo's account; see Travlos, J. 1971, 91, 100 with fig. 116 Wall E; cf. Zeus Zeu10*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM203">203</a></p>
<p :class="{'activeClass': idClicked == NP3021 }" id="NP3021" >PELASGIAN settlement at the foot of the Akropolis: Paus. 1.28.3; J. H. Oliver, “Horoi as Reserved Areas”, GRBS 4 (1963) 143</p>
<p :class="{'activeClass': idClicked == NP3022 }" id="NP3022" >PELOPONNESOS, personification: see Athena Ath129*</p>
<p :class="{'activeClass': idClicked == NP3023 }" id="NP3023" >PENTETERIS, priestess of Athena Polias, theater seat IG II² 5159; Davies, J. K. 1971, 170; Aleshire, S. B. 1994, 336</p>
<p :class="{'activeClass': idClicked == NP3024 }" id="NP3024" >PEPLOTHEKE, storage place for Panathenaic peploi on the Akr.: IG II² 1462 line 12; B. Nagy, “The Peplotheke: What Was It?,” in Studies Sterling Dow 1984, 227-232; SEG 34 (1984) no. 118; Mansfield, J. M. 1989, 55-56 with notes 16-17</p>
<p :class="{'activeClass': idClicked == NP3025 }" id="NP3025" >PERDIX, Daidalos' sister, sanctuary, Akr. South Slope, Suid. s.v. Πέρδικος ιερόν; Beschi, L. 1967/68a, 395; Kearns, E. 1989, 194</p>
<p :class="{'activeClass': idClicked == NP3026 }" id="NP3026" >PERIKLEAN FOUNTAIN HOUSE (?), elusive but definite possibility: IG I³ 49 lines 4-5 with addendum p. 940; SEG 29 (1979) no. 10; Bull. 1977 no. 135; Camp, J. McK. II 1980, 322-324</p>
<p :class="{'activeClass': idClicked == NP3027 }" id="NP3027" >PERIKLES: Grave on the Road to the Academy: Paus. 1.29.3; Cic. Fin. 5.2.5; Stupperich, R. 1977, 25 with note 3; <JBcom>[as may be inferred from Ath. XII. 595a, probably a state burial monument which Pausanias saw after he saw the grave of Thrasyboulos and before he saw the graves of Chabrias and Phormio]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3029 }" id="NP3029" >Odeion of Perikles, next to the theater (Plan 1 no. 31): structure imitating Xerxes' tent, rebuilt after Sullan destruction, Paus. 1.20.4 (Perikles not mentioned); Travlos, J. 1971, 387-391, figs. 91 no. 139, 501-504; Meinel, R. 1980, 135-154, figs. 35-38; testimonia on the Odeion functioning as a law court, Agora XXVIII 185-186 nos. 168-170; Thanos G. Papathanasopoulos, To Τρόπαιό, Athens, 2003, 1-167, the revised version of a dissertation, «To Ωδείο του Περικλή: νέα θεώρησι του μνημείου,» (University of Rethymno, 1999) reviews and analyzes the testimonia at length, concluding <JBcom>[convincingly]</JBcom> that Themistokles was the builder</p>
<p :class="{'activeClass': idClicked == NP3030 }" id="NP3030" >Post-Sullan wall painting of a Panathenaic amphora on the north wall: Karo, G. 1932, 123</p>
<p :class="{'activeClass': idClicked == NP3031 }" id="NP3031" >Picture of Perikles by Aristolaus, son of Pausias: Plin. Nat. XXXV.137</p>
<p :class="{'activeClass': idClicked == NP3032 }" id="NP3032" >Portrait by Kresilas on the Akr.: the sculptor but not the site recorded, Plin. Nat. 34.74; the site but not the sculptor recorded, Paus. 1.25.1 (twice), 1.28.2; Holscher, T. 1975, 187-199</p>
<p :class="{'activeClass': idClicked == NP3033 }" id="NP3033" >Signed base belonging to the statue Pausanias saw IG I³ 884 = IG I² 528; G. M. Keesling, op. cit. (under Dieitrephes) 79, 81, 89 with note 50</p>
<p :class="{'activeClass': idClicked == NP3034 }" id="NP3034" >PERIPATOS: Walk around the Akropolis slopes with a 4th c. B.C. inscription cut “on the face of a rock which had become detached and fallen from the north slope” - - - IG II² 2639; cf. Paus. 1.22.1; Broneer, O. 1933, 347-349, fig. 19, pl. XI; Travlos, J. 1971, 54, 228, 523, 562, figs. 67, 71, 91, 293-294; Dontas, G. S. 1983, 48; Threatte, L. 1980, 108; “may be the earliest appearance of a lunate sigma in an Athenian inscription,” Ritchie Jr., C. E. 1985, 430-435; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM152">152</a> See also: Apollo Apo47*, peripatos in the Lykeion; Theophrastos' Garden; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM443">443</a>, peripatos in the Academy</p>
<p :class="{'activeClass': idClicked == NP3036 }" id="NP3036" >PERISCHOINISMA, roped off place in the Agora: Plu. Moralia 837 A; Agora III, nos. 529-536; at the orchestra site, Kolb, F. 1981, 53-54</p>
<p :class="{'activeClass': idClicked == NP3037 }" id="NP3037" >PERSEUS: Perseus with Medusa's head, picture in the Propylaia northwest chamber: Paus. 1.22.7</p>
<p :class="{'activeClass': idClicked == NP3039 }" id="NP3039" >Statue by Myron on the Akr.: Paus. 1.23.7</p>
<p :class="{'activeClass': idClicked == NP3040 }" id="NP3040" >Temenos in Athens (?): Paus. 11.18.1; J. H. Croon, “The Mask of the Underworld Daemon - Some Remarks on the Perseus-Gorgon Story,” JHS 75 (1955) 10 note 15; Kearns, E. 1989, 194 s.v. Perrheus See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM103">103</a></p>
<p :class="{'activeClass': idClicked == NP3041 }" id="NP3041" >PERSIAN GOD (?) or ruler (?) between two horned gryphons in the upper zone and lion devouring stag in the lower zone, throne (?) reliefs, early 4th c. B.C., found at “Sphageia,” where the 19th century slaughter-houses were on the banks of the Ilissos at the foot of Mouseion Hill NM 1487; A. D. H. Bivar, “Religious Subjects on Achaemenid Seals,” in Mithraic Studies: Proceedings of the First International Congress of Mithraic Studies, J. R. Hinnels ed., Manchester University Press, 1975, I, 95-97 and II, 519 no. 46, pl. 4b; Kaltsas, N. 2002, 266 no. 557, ph.</p>
<p :class="{'activeClass': idClicked == NP3042 }" id="NP3042" >PERSIAN WAR EPIGRAMS: The "Marathon Epigrams,” 490-479 B.C. and a copy? 210/9 -171/0 B.C. - - - IG I³ 503-504 = IG I² 763; 3rd Ephoria M 3258 found at 51 Plataion St. in a late context (Plan 2 delta—2); <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204256" target="_blank">Agora I 4256</a> (a copy?); SEG 51 (2001) no. 44</p>
<p :class="{'activeClass': idClicked == NP3044 }" id="NP3044" >Epigram formerly thought to have Darius' name, 490-470 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20555" target="_blank">Agora I 555</a>, IG I³ 1142; SEG 10 (1949) no. 403; CEG 1983, no. 1; Pritchett, W. K. 1985, 154 note 1 75</p>
<p :class="{'activeClass': idClicked == NP3045 }" id="NP3045" >PERSIANS supporting a bronze tripod, sculpture in the Olympieion precinct: Paus. 1.18.8; victory monument perhaps dedicated by Augustus in 19 B.C., Rolf Michael Schneider, Bunte Barbaren, Worms,1986, 82-91</p>
<p :class="{'activeClass': idClicked == NP3046 }" id="NP3046" >PHAIDROS, son of Lysiades, Epicurean philosopher: A. E. Raubitschek, “Phaidros and his Roman Pupils,” Hesperia 18 (1949) 96-103, fig. 1, pl.3</p>
<p :class="{'activeClass': idClicked == NP3047 }" id="NP3047" >Base of a statue, before 70 B.C., Akr. South Slope - - - IG II² 3899; possibly dedicated by Atticus, A. E. Raubitschek, op. cit., 98-99, pl. 3</p>
<p :class="{'activeClass': idClicked == NP3048 }" id="NP3048" >Base of a herm of Phaidros as kathegetes, dedicated to the Two Goddesses by Appius Saufeius, before 70 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205485" target="_blank">Agora I 5485</a>; Agora XI, 127-128; for the findspot close to the Eleusinion, Agora XXXI, 192 no. 20; SEG 48 (1998) no. 233</p>
<p :class="{'activeClass': idClicked == NP3049 }" id="NP3049" >Statue base for Phaidros as kathegetes and for Lucius Saufeius and Appius Saufeius, before 70 B.C., on the Akr. - - - IG II² 3897 + EM 4916; A. E. Raubitschek, op. cit, 99-101, pl. 3; SEG 29 (1979) no. 176</p>
<p :class="{'activeClass': idClicked == NP3050 }" id="NP3050" >PHAIDROS, son of Thymochares of Sphettos, bronze statue to be set up in the Agora, decreed ca. 255/4 B.C.: IG II² 682 lines 80-81; Shear Jr., T. L. 1978, 56, 59, 88-89; SEG 42 (1992) no. 101; for the findspot of the decree at the church of H. Dimitrios Katiphoris (Chr. Mon. CM59*), see Archeion I 1992, 191 no. 82</p>
<p :class="{'activeClass': idClicked == NP3051 }" id="NP3051" >PHAIDROS, son of Zoilos<br>Bema: see Dionysos Dio39* <br>Sundial with four deviating vertical dials, latest 4th-early 5th c. A.C., Panagia Gorgoepekoos (Chr.Mon. CM75*, Plan 1 gamma-8) BM cat. no. 2544 (Smith, A. H. 1904, 412-413); IG II² 5208; Gibbs, S. L. 1976, 346-347 no. 5002 G; Sironen, E. 1997, 116-118 no. 41</p>
<p :class="{'activeClass': idClicked == NP3054 }" id="NP3054" >PHAIDYNTES: see Zeus Zeu81*, Zeu83*</p>
<p :class="{'activeClass': idClicked == NP3055 }" id="NP3055" >PHAINOCHARIS' (?) tomb of sun-dried brick with terracotta metopes painted by Exekias, ca. 530 B.C., found in the block bounded by Peiraios, Thermopylon, Agesilaou and Mullerou Sts. (Plan 2 delta—3) Berlin 1811-1826; Brueckner, A. and E. Pernice 1893, 75 with pl. 6 no. 1, gives the findspot “hinter dem Waisenhaus”; W. Konigs in Kerameikos XII, 47-48, fig. 28; the church of the Hadzikosta Orphanage still stands (Plan 2 delta—3, no. 111)</p>
<p :class="{'activeClass': idClicked == NP3056 }" id="NP3056" >PHALANTHEIOS: see Hermes Hrm26*</p>
<p :class="{'activeClass': idClicked == NP3057 }" id="NP3057" >PHALERIC ROAD: Paus. 1.1.5, 10.35.2; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM580">580</a> PHALERIC WALL: see Long Walls</p>
<p :class="{'activeClass': idClicked == NP3058 }" id="NP3058" >PHALLOS, island marble, Akr. North Slope: Broneer, O. 1933, 346, fig. 18</p>
<p :class="{'activeClass': idClicked == NP3059 }" id="NP3059" >PHANES of Chios, statue base? or grave trapeza, 3rd c. B.C., theater - - - IG II² 3778; reused in a huge base for a colossal female figure, 4.50 m. high, set up in the parodos of the theater, Korres, M. 1982, 17 no. 7 in Base VI, pl. 15:a, drawing Gamma; SEG 39 (1989) no. 217</p>
<p :class="{'activeClass': idClicked == NP3060 }" id="NP3060" >PHANOSTRATOS, tragedian from Halikarnassos, statue base, ca. 307/6 B.C IG II² 2794; H. Riemann, RE Suppl. 8, 1956, col. 468-469 (Phanostratos)</p>
<p :class="{'activeClass': idClicked == NP3061 }" id="NP3061" >PHARIA: see Isis Pharia</p>
<p :class="{'activeClass': idClicked == NP3062 }" id="NP3062" >PHAYLLOS of Kroton, statue on the Akr., 480-470 B.C. - - - IG I³ 823 = IG I² 655; SEG 46 (1996) no. 2269 and 48 (1998) no.80; Schenkungen hellenistlscher Herrscher, Part I, 1995, 382 and Part II, vol. 1, 2000, 59</p>
<p :class="{'activeClass': idClicked == NP3063 }" id="NP3063" >PHEIDIAS the politician, statue: Nep. Att. 3.2</p>
<p :class="{'activeClass': idClicked == NP3064 }" id="NP3064" >PHEIDOSTRATOS, school (didaskalelon): Pl. Hp. Ma. 286 B</p>
<p :class="{'activeClass': idClicked == NP3065 }" id="NP3065" >PHEME, altar: Paus. 1.17.1; Frazer, J. G. 1913, II, 144; F. Voigt, RE XIX, 1938, col. 1955 (Pheme)</p>
<p :class="{'activeClass': idClicked == NP3066 }" id="NP3066" >PHERAIA: see Artemis Art43**</p>
<p :class="{'activeClass': idClicked == NP3067 }" id="NP3067" >PHEREPHATTE: see Demeter and Pherephatte</p>
<p :class="{'activeClass': idClicked == NP3068 }" id="NP3068" >PHEREPHATTION, sanctuary in the Agora: D. 54.8; Agora III, 85 and no. 323 </p>
<p :class="{'activeClass': idClicked == NP3069 }" id="NP3069" >PHILANTHROPE: see Eleos</p>
<p :class="{'activeClass': idClicked == NP3070 }" id="NP3070" >PHILEMON, comic poet, statue base, east of the Propylaia - - - IG II² 4266</p>
<p :class="{'activeClass': idClicked == NP3071 }" id="NP3071" >PHILEMON, theater seat - - - IG II² 5089; Merkel, W. R. 1947, 76 </p>
<p :class="{'activeClass': idClicked == NP3072 }" id="NP3072" >PHILIA: Altar on the Akropolis: Hsch. s.v. Αίδούς Βωμός</p>
<p :class="{'activeClass': idClicked == NP3074 }" id="NP3074" >Altar founded in honour of Plato: Arist. Fr. no. 673 (ed. V. Rose, Teubner, 1886); I. During, Aristotle in the Ancient Biographical Tradition, Goteborg, 1957, 315-318 See also: Agathos Daimon </p>
<p :class="{'activeClass': idClicked == NP3075 }" id="NP3075" >PHILIDIOU HYMNETRIAI: see Hymnetriai</p>
<p :class="{'activeClass': idClicked == NP3076 }" id="NP3076" >PHILINOS, theater seat IG II² 5097; Maass, M. 1972, 83 note 36</p>
<p :class="{'activeClass': idClicked == NP3077 }" id="NP3077" >PHILIOS: see Zeus Zeu88*-92*</p>
<p :class="{'activeClass': idClicked == NP3078 }" id="NP3078" >PHILIP II OF MACEDON: Kotsidu, H. 2000, 23-26</p>
<p :class="{'activeClass': idClicked == NP3079 }" id="NP3079" >Cult at Kynosarges: Clem. Al. Protr. IV.48; E. A. Fredrichsmeyer, “Divine Honors for Philip II,”, ΤΑΡΑ 109 (1979) 49-50</p>
<p :class="{'activeClass': idClicked == NP3080 }" id="NP3080" >Statue in front of the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.9.4</p>
<p :class="{'activeClass': idClicked == NP3081 }" id="NP3081" >PHILIP V OF MACEDON, damnatio memoriae decreed by the Athenians, statues and representations of Philip together with his name on inscriptions to be destroyed: Liv. XXXI.44; H. A. Thompson, “Athens faces Adversity,” Hesperia 50 (1981) 354; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM338">338</a></p>
<p :class="{'activeClass': idClicked == NP3082 }" id="NP3082" >PHILIPPE, daughter of Medeios, priestess of Athena Polias, theater seat, after 106/5 B.C. - - - IG II² 5104; Lewis, D. M. 1955, 9 no. 11; Turner, J. A. 1983, 245, 275-277; Aleshire, S. B. 1994, 336 no. 14</p>
<p :class="{'activeClass': idClicked == NP3083 }" id="NP3083" >PHILIPPIDES, son of Philokles, comic poet, bronze statue in the theater, decreed 283/282 B.C.: IG II² 657 lines 63-64; Shear Jr., T. L. 1978, 115 s.v. Philippides, son of Philokles; SEG 46 (1996) no. 132</p>
<p :class="{'activeClass': idClicked == NP3084 }" id="NP3084" >PHILIPPIDES, son of Philomelos, bronze statue in the Agora decreed 293/2 B.C.: IG II² 649; SEG 45 (1995) no. 101 lines 44-45,50; Kotsidu, H. 2000, 52-54</p>
<p :class="{'activeClass': idClicked == NP3085 }" id="NP3085" >PHILISKOS of Thessaly, sophist, grave in the Academy where the Polemarch institutes the games for the war dead: Philostr. VS II.30; Pritchett, W. K. 1998,19-20; <JBcom>[state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3086 }" id="NP3086" >PHILOKLES, king of the Sidonians, statue base, ca. 280 B.C, Hag. Dimitrios tou Katephori (Chr.Mon. CM59*,Plan 1 delta-7)- - - IG II² 3425; Shear Jr., T. L. 1978, 33-34</p>
<p :class="{'activeClass': idClicked == NP3087 }" id="NP3087" >PHILOKRATES of Hagnous, two houses, and HIEROKLEIDES of Hermos, confiscated properties mentioned in poletai accounts of 342/1 B.C., “two workshops in <JBcom>[Melite, bounded on]</JBcom> the east by <JBcom>[a house]</JBcom> of Philokrates <JBcom>[of Hagnous, on the]</JBcom> west by a workshop (ergasterion) of Hierokleides of Hermos, <JBcom>[on the north]</JBcom> by a house of Philokrates of Hagnous, and on the south by the road <JBcom>[leading from the sanctuary]</JBcom> of Herakles Alexikakos to the Agora,” : <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201749" target="_blank">Agora I 1749</a> lines 105-109; B. D. Meritt, Greek Inscriptions,” Hesperia 5 (1936) 400 (the Greek text), 405 (translation reproduced above), 410 (commentary). Although the ancient street running through the Areopagus-Pnyx Valley (Areopagus-Pnyx Valley Street no. 602 below) in no way fits the specifications given in the inscription, nevertheless J. Travlos identified it as the road from the sanctuary of Herakles Alexikakos to the Agora in order to support a mistaken location for the sanctuary of Herakles Alexikakos at the Bakcheion site, Travlos, J. 1971, 274, fig. 351 <JBcom>[sketching in impossible locations for Philokrates' houses and Hierokleides' workshop as anyone standing at the designated sites can see]</JBcom>; Travlos' trumped up claims confuted, Ritchie Jr., C. E. 1985, 737-740; SEG 44 (1994) no. 69; cf. Herakles Hrk42*, Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM230">230</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM586">586</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM600">600</a>; cf. Agora XVII, 50 no. 125, pl. 13 (gravestone of Hierokleides' son)</p>
<p :class="{'activeClass': idClicked == NP3088 }" id="NP3088" >PHILON, son of Aristokles, grave mon. in a family plot, marble loutrophoros with a relief of a spirited cavalry engagement, 360-350 B.C., southwest of Plateia Syntagmatos, 27 Nikis St. (Plan 1 beta-11)- - - SEG 25 (1971) no.257; O. Alexandri, «Νίκης 27,» Deltion 25 (1970) B1 77-79 no. 38, pl. 64b; S. Kaempf-Dimitriadou, “Die Marmorlutrophoros des Philon in Athen,” Antike Kunst 4 (2000) 70-85, pls. 12-14; SEG 50 (2000) no. 268</p>
<p :class="{'activeClass': idClicked == NP3089 }" id="NP3089" >PHILONIDES of Laodikeia in Syria and his sons, Philonides the Epicurean philosopher and Dikaiarchos, statues in the precinct of Demos and Charites mentioned in a decree of the Kerykes and the Eumolpidai, ca. 209-187 B.C.: IG il2 1236 line 5; Habicht, Chr. 1989, 18; SEG 46 (1996) no. 161; D. Gera, “Philonides the Epicurean at Court: Early Connections,” ZPE 125 (1999) 77-83; for the date see Tracy, S. V. 1990, 93, 95, 262; Monaco, M. C. 2001, 127, fig. 14</p>
<p :class="{'activeClass': idClicked == NP3090 }" id="NP3090" >PHILOPAPPOS, Gaius Julius Antiochus Epiphanes Philopappos of Commagene (PIR, 2nd ed., 151); Byrne, S. G. 2003, 308-310 no. 37</p>
<p :class="{'activeClass': idClicked == NP3091 }" id="NP3091" >Grave mon.,114-116 A.D., on the summit of Mouseion Hill (Plan 2 eta—3, no. 22): — CIL III 552, IG II² 3451; a Syrian man, Paus. 1.25.8; Travlos, J. 1971, 462-465, figs. 233, 585-587; Diana E. E. Kleiner, The Monument of Philopappos in Athens, Rome, 1983; SEG 48 (1998) nos. 236, 2030; <JBcom>[probably paid for privately but a state burial monument in the sense that permission for burial inside the City Wall must have been granted by the State]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3092 }" id="NP3092" >Graffiti carved on the grave mon., names of Choiseul-Gouffier, Foucherot and Fauvel, 1776 and 1781: W. St. Clair, Lord Elgin and the Marbles, London, 1967, 58 See also: Asklepios Ask10*; Chr. Mon. CM11* Frankish bell-tower</p>
<p :class="{'activeClass': idClicked == NP3093 }" id="NP3093" >PHILOTERA daughter of AMYMONE, the two names in letters made of gold set in two gold armbands, found in a kline sarcophagus, ca. 180 A.D., in front of the Dipylon and proteichisma - - - SEG 51 (2001) no. 305; H. R. Goette, “Attische Klinen-Riefel Sarkophage,” AM 106 (1991) 317-321, pls. 89-92; the jewelry found in the sarcophagus was made in Syria according to I. Touratsoglou, «Φιλοτέρα Άμυμώνης: Από την κοσμηματοθήκη του Εθνικού Μουσείου,» ΤΟ ΜΟΥΣΕΙΟΝ: Περιοδική έκδοση του Εθνικού Αρχαιολογικού Μουσείου 2 (2001) 93-102, figs. 1-2; according to J. Stroszeck, personal communication in April 2003, the jewellry bears no resemblance to jewellry of Syrian manufacture; <JBcom>[Philotera was perhaps a priestess of Athena Polias; see Aleshire, S. B. 1994, 336, nos.9,11]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3094 }" id="NP3094" >PHILTATIOS, grammarian, public statue awarded ca. 416 A.D.: Olymp. Hist. frg. 32; A Frantz, “Honors to a Librarian,” Hesperia 35 (1966) 377-380</p>
<p :class="{'activeClass': idClicked == NP3095 }" id="NP3095" >PHLIUS, battle-scene, picture by Pamphilos: Plin. Nat. XXXV.76; Palagia, O. 1980, 52 no. 3</p>
<p :class="{'activeClass': idClicked == NP3096 }" id="NP3096" >PHOBOS, recipient of sacrifices from Theseus: Plu. Thes. 27.2</p>
<p :class="{'activeClass': idClicked == NP3097 }" id="NP3097" >PHOINIKIOUN, lawcourt: F GR HIST III B, suppl. vol. I, 165; Paus. 1.28.8; Agora XXVIII, Index s.v. Phoinikioun</p>
<p :class="{'activeClass': idClicked == NP3098 }" id="NP3098" >PHOINIX OF THESSALY, sophist, grave mon. on the right side of the Road to the Academy near the graves of the war dead: Philostr. VS II.22; Billot, M.-F. 1989, 777-778; Pritchett, W. K. 1998, 19-20; <JBcom>[probably a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3099 }" id="NP3099" >PHOKION, son of Phokos: Grave monument at state expense after a secret private burial: Plu. Phoc. 37.3-38.1</p>
<p :class="{'activeClass': idClicked == NP3101 }" id="NP3101" >House in Melite where Phokion's wife secretly buried the cremated remains of Phokion by the hearth: Plu. Phoc. 18.5, 37.2-3</p>
<p :class="{'activeClass': idClicked == NP3102 }" id="NP3102" >Bronze statue set up by people of Athens after Phokion's execution: Plu. Phoc. 38.1; Richter, G. M. A. 1965, II, 158</p>
<p :class="{'activeClass': idClicked == NP3103 }" id="NP3103" >PHORBANTEION, heroon of Phorbas, Theseus' tutor and charioteer, beside Andokides' herm (Herms Her8*): Harp, s.v.; Milchhöfer, A. 1891, 53; Agora XI, 117, 119-120; Kearns, E. 1989, 204; LIMC VIII, 1997, 990, s.v. Phorbas I (A. Kauffmann-Samaras)</p>
<p :class="{'activeClass': idClicked == NP3104 }" id="NP3104" >PHORMION, son of Asopios <br>Grave monument, ca. 428 B.C., on the Road to the Academy: Paus. 1.29.3; <JBcom>[probably a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3106 }" id="NP3106" >Statue on the Akropolis: Paus. 1.23.10</p>
<p :class="{'activeClass': idClicked == NP3107 }" id="NP3107" >PHOSPHOROI, dedication, ca. 200 A.D., southeast of the Tholos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204745" target="_blank">Agora I 4745</a>; Agora III, 58 no. 124; Agora XIV, 45, pl. 36:a</p>
<p :class="{'activeClass': idClicked == NP3108 }" id="NP3108" >PHOSPHOROS: see Artemis Art44*-45*</p>
<p :class="{'activeClass': idClicked == NP3109 }" id="NP3109" >PHRASIKLEIA, grave relief, ca. 400 B.C. <JBcom>[or later]</JBcom> in front of the Acharnian Gate IG II² 13013; NM 831; reused as an icon in the church of the Megali Panagia (Chr. Mon. CM73*), G. Despinis, «Ένα επιτύμβιο ανάγλυπφο από τη Μακεδονία στην Αθήνα,» Egnatia 3 (1991/1992) 62-67, fig. 2; SEG 47 (1997) no. 258</p>
<p :class="{'activeClass': idClicked == NP3110 }" id="NP3110" >PHRATRIOS, PHRATRIA: see Zeus Zeu93*-95*</p>
<p :class="{'activeClass': idClicked == NP3111 }" id="NP3111" >PHREAROOS: see Demeter Phrearoos</p>
<p :class="{'activeClass': idClicked == NP3112 }" id="NP3112" >PHRIXOS sacrificing the ram (with the golden fleece) to Zeus Laphystios, sculpture on the Akr.: Paus. 1.24.2; IG II² 4272, a base with Naukydes' signature possibly belongs to this group, G. Despinis, “Zum Hermes von Troizen,” AM 96 (1981) 242-243</p>
<p :class="{'activeClass': idClicked == NP3113 }" id="NP3113" >PHYTALMIOS: see Poseidon Phytalmios</p>
<p :class="{'activeClass': idClicked == NP3114 }" id="NP3114" >PHYTALOS, hero of the genos Phytalidai: K. Ziegler, RE XX, 1941, col. 1176-1177 (Phytalos); Kearns, E. 1989, 79, 121, 205</p>
<p :class="{'activeClass': idClicked == NP3115 }" id="NP3115" >Grave with epigram naming Phytalos as a hero at the sanct. of Demeter and Kore on the Sacred Way: Paus. 1.37.2</p>
<p :class="{'activeClass': idClicked == NP3116 }" id="NP3116" >House (oikos) at the sanct. of Demeter and Kore on the Sacred Way: Paus. 1.37.2 See also: Hiera Syke</p>
<p :class="{'activeClass': idClicked == NP3117 }" id="NP3117" >PINDAR, statue at Ares temple: Paus. 1.8.4; Agora III, 215; Richter, G. M. A. 1972, II, 176-180</p>
<p :class="{'activeClass': idClicked == NP3118 }" id="NP3118" >PITY, altar: see Eleos</p>
<p :class="{'activeClass': idClicked == NP3119 }" id="NP3119" >PLATEIA (i.e. The Avenue or Broadway): Robert, L. 1980, 127, 128, 154-156; for the term applied to a colonnaded street, see SEG 49 (1999) no. 2408 <br>Avenue leading to the Peiraic Gate: Plu. Thes. 27.3</p>
<p :class="{'activeClass': idClicked == NP3121 }" id="NP3121" >Marble-paved avenue, named Plateia and dedicated by the Demos, with a marble archway south of the Stoa of Attalos, connecting the Panathenaic Way with the Roman Agora: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207349" target="_blank">Agora I 7349</a> line 2; Shear Jr., T. L. 1973b, 385, 389-390 no. 3, fig. 6; Agora Guide 1990, 135, 276, with figs. 86, 88, 90; the marble-paved Plateia was dedicated to Trajan, suggestion contributed by John McK. Camp in 1998</p>
<p :class="{'activeClass': idClicked == NP3122 }" id="NP3122" >PLATO<br>Garden: adjoining the Academy, Apul. Pl. I.4; beside Kolonos Hippios, D.L. 3.5; Lynch, J. P: 1972, 61; Billot, M.-F. 1989, 780-786; see Eurymedon of Myrrhinos</p>
<p :class="{'activeClass': idClicked == NP3124 }" id="NP3124" >Grave: not far from the Academy, Paus. 1.30.3; in the Academy, D.L. 3.41; Billot, M.-F. 1989, 786-787</p>
<p :class="{'activeClass': idClicked == NP3125 }" id="NP3125" >Statue by Silanion in the Academy, dedicated by the Persian Mithradates, son of Orontobates, to the Muses: D.L. 3.25; Richter, G. M. A. 1965, II, 165, 169-70; Billot, M.-F. 1989, 781-782; Els Theys in F GR HIST CONTINUED, Part IV A, Fascicle 1, 260-262; Trianti, I. 2002, 163-164</p>
<p :class="{'activeClass': idClicked == NP3126 }" id="NP3126" >Portrait head, 2nd c. A.C. - - - NM 3735; G. M. A. Richter, op. cit, 167 no. 21, figs. 957-959; I. Trianti, op. cit, 164-165, 168 note 58; Kaltsas, N. 2002, 248 no. 516</p>
<p :class="{'activeClass': idClicked == NP3127 }" id="NP3127" >Portrait head, formerly stored in the Tower of the Winds, found in nearby excavations Acr. 7275; G. M. A. Richter, op. cit., 167 no. 20, figs. 938, 941; I. Trianti, op. cit., 164-165, 168 note 55</p>
<p :class="{'activeClass': idClicked == NP3128 }" id="NP3128" >Portrait head, 1st c. A.C., Akr. lower south slope, south of the Theater of Dionysos and Dionysiou Areopagitou St. in the Metro Excavations for the Akropolis Station Metro Excavations inv. no. M 163; copy of a seated statue by Silanion, Metro Excavations 1992-1997, 35 (P. Kalligas); Baumer, L. E. 2001, 60 note 39, 62 note 50, 65 note 66; I. Trianti, op. cit. 157-169, figs. 1-6; <JBcom>[although not found in situ, nevertheless this head is the sole solid piece of archaeological evidence for the site of Proklos' house that is in accord with the written testimonia; see Ploutarchos, son of Nestorios; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM165">165</a>]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3129 }" id="NP3129" >Small portrait bust, 31 Ermou St. near intersection with Phokionos St. (Plan 1 beta—8/9) - - - 3rd Ephor. inv. no. 490; Dontas, G. S. 1969, 73-74 fig. 2; I. Trianti, op. cit. 160-162, figs. 8-10, 16-17; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM510B">510B</a></p>
<p :class="{'activeClass': idClicked == NP3130 }" id="NP3130" >PLOUGHMAN AND OLIVE TREE: relief base, northwest of Kolonos Hippios at Hag. Nikolaos (Plan 3 beta—6) NM 3748; ploughman dubbed Akademos, Svoronos, J. N. 1911, 1911, 408-410, fig. 207</p>
<p :class="{'activeClass': idClicked == NP3131 }" id="NP3131" >PLOUTARCHOS, son of Nestorius, head of the Neo-Platonist school, ca. 350-431/2 A.D. (PLRE I, 1971, Ploutarchos 5); Agora XXIV, 148 s.v. Plutarch (Neoplatonist)</p>
<p :class="{'activeClass': idClicked == NP3132 }" id="NP3132" >Statue base (?) with 2 epigrams, before 435 A.D., National Garden (Plan 2 epsilon/zeta -6/7)- - - IG II² 12767a; SEG 38 (1988) no. 185; Sironen, E. 1997, 85-87 no. 25</p>
<p :class="{'activeClass': idClicked == NP3133 }" id="NP3133" >House handed down to Syrianos and then to Proklos, Akr. South Slope near the Asklepieion and the Dionysion at the theater: Marin. Procl. 29; P. Castren, “General Aspects of Life in Post-Herulian Athens,” in Castren, P., ed. 1994, 6-7; see above: Plato, portrait head found on the Akropolis lower south slope; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM165">165</a></p>
<p :class="{'activeClass': idClicked == NP3134 }" id="NP3134" >Portrait bust, 400-450 A.D., Akr. Acr. 1313; the identification as Ploutarchos, head of the Neo-Platonist school, was presented as a conjecture by G. Dontas, “Kopf eines Neoplatonikers,” AM 69/70 (1954/55) 147-152, pl. 14, Beilagen 54-55; for a photo of the head on display in the Propylaia ca. 1860, see ATHINA 1839-1900, 93 no. 40; <JBcom>[although the head received an Acropolis inventory number and was seen in the Propylaia at an early date, it managed to sashay down to the Akropolis south slope where it gallantly supports the identification of a house (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM165">165</a>) as the “House of Proklos” ]</JBcom>; cf. Baumer, L. E. 2001, 61 with note 45, 68 with note 86</p>
<p :class="{'activeClass': idClicked == NP3135 }" id="NP3135" >PLOUTARCHOS, the sophist (PLRE II, 1980, Ploutarchos 2), reused statue base <br>1st use: honorary decree for M. Ulpius Flavius Teisamenos, the son of M. Ulpius Eubiotus, ca. 230 A.D. - - - IG II² 3701; Byrne, S. G. 2003, 459 (iii) <br>2nd use: honorary decree for the archon Hegias, 300-350 A.D. - - - IG II² 3692; Sironen, E. 1997, 65-66 no. 12; cf. Athanassiadi, P. 1999, 253 note 277 <br>3rd use: statue base for Ploutarchos honored by Athens for covering the expenses of the Panathenaic Ship thrice, late 4th - early 5th c. A.C.  IG II²  3818; Agora XXIV, 63-64, pl. 47:d, SEG 38 (1988) no. 185; Sironen, E. 1997, 77-78 no. 20 <br>4th use: built into a wall of the church of Kyra tou Kandili, just northeast of the Lysikrates Mon.</p>
<p :class="{'activeClass': idClicked == NP3140 }" id="NP3140" >PLOUTON<br>Sanctuary near the Eleusinion: IG II² 1672 lines 168-169; Agora III, 80, 81, 84, nos. 215, 224; Agora XXXI, 3, 51-52, 62 note 8, 83, 84, 101-102, 205 no. 60; SEG 42 (1992) nos. 135, 138 and 50 (2000) no. 21</p>
<p :class="{'activeClass': idClicked == NP3142 }" id="NP3142" >Cult table, 170-150 B.C.: IG II² 1934 line 4; Clinton, K. 1974, 20 with notes 65-66, 22 no. 9; for the date, see Tracy, S. V. 1990, 149, 155-156, 262 = SEG 40 (1990) no. 157</p>
<p :class="{'activeClass': idClicked == NP3143 }" id="NP3143" >Statue at the sanct. of the Semnai: Paus. 1.28.6; Clinton, K. 1974, 20-21 with note 67; Eschbach, N. 1986, 18-26, esp. 26</p>
<p :class="{'activeClass': idClicked == NP3144 }" id="NP3144" >Plouton and Kore, relief dedication IG II² 4751</p>
<p :class="{'activeClass': idClicked == NP3145 }" id="NP3145" >Plouton holding cornucopia and phiale, inscribed votive relief, ca. 340 B.C. <JBcom>[?]</JBcom>, sanct. of Pankrates on the Ilissos ( Plan 2 zeta—7) 1st Ephor. Pankrates 18 A; SEG 44 (1994) no. 144; Vikela, E. 1994, 11-12 no. A 2, pl. 2; <JBcom>[the relief looks distinctly later than the ca. 340 B.C. date proposed by E. Vikella]</JBcom> cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM470">470</a></p>
<p :class="{'activeClass': idClicked == NP3146 }" id="NP3146" >PLOUTOS: see Demeter and Kore; Eirene and Ploutos</p>
<p :class="{'activeClass': idClicked == NP3147 }" id="NP3147" >PNYX: Milchhöfer, A. 1891, 6-7 <br>Place-name: Plu. Thes. 27.1 and 3; E. Meyer, RE XXI, 1951, col 1106-1110 (Pnyx) Assembly Place (Plan 2 zeta/eta—3, no., 21): the bibliography, Thompson, H. A. 1982, 133 note 1; The Pnyx in the History of Athens. Proceedings of an International Colloquium Organized by the Finnish Institute at Athens, 7-9, October, 1994 (Papers and Monographs of the Finnish Institute at Athens II), B. Forsen and G. R. Stanton edd., Helsinki, 1996 (abbreviated: Pnyx 1996)</p>
<p :class="{'activeClass': idClicked == NP3150 }" id="NP3150" >First Period of the Assembly Place, 5th c. B.C.: Ar. Ach. 19-33; Kourouniotis K. and H. A. Thompson 1932, 96-113, 215-216, figs. 3-6, pls. 2-4</p>
<p :class="{'activeClass': idClicked == NP3151 }" id="NP3151" >Boundary stone, ca. 450 B.C., perhaps 20 m. south of the bema IG I³ 1092 = IG I² 882; SEG 44 (1994) no. 13 = D. Peppas-Delmousou, “The Pnyx and the Mouseion Hill Inscriptions,” in Pnyx 1996, 103-104; Thompson, H. A. 1982, 137 with notes 15-16; Ritchie Jr., C. E., 1985, 301-306, pl. 57, fig. 46</p>
<p :class="{'activeClass': idClicked == NP3152 }" id="NP3152" >Second Period of the Assembly Place, 404/3 B.C.: Plu. Them. 19.4; Kourouniotis, K. and H. A. Thompson 1932, 113-138, 178-180, 216-7, figs. 8-20, pls. 2-4; G. R. Stanton, “The Shape and Size of the Athenian Assembly Place in its Second Phase,” in Pnyx 1996, 7-21, figs. 1-5</p>
<p :class="{'activeClass': idClicked == NP3153 }" id="NP3153" >Third Period of the Assembly Place, 2nd c. A.C., left unfinished: dated to the 2nd c. A.C. <JBcom>[on the basis of the evidence]</JBcom>, Kourouniotis, K. and H. A. Thompson 1932, 139-200, 217, figs. 1-2, 21-57, pls. 1-4; dated to the 4th c. B.C. <JBcom>[contradicting the evidence]</JBcom>, Thompson, H. A. and R. L. Scranton 1943, 297-299, 300-301; Thompson, H. A. 1982,143-145; for the coins, see Agora XXVI, 299-300; John McK. Camp II, Bjorn Forsen, Mogens Herman Hansen, David Gilman Romano,and Susan I. Rotroff insisted on a 4th c. B.C. date <JBcom>[by dint of ignoring crucial evidence]</JBcom> in Pnyx 1996, 23-25, 35-40, 41-46, 47-55, 78; 4th c. B.C. date, S. I. Rotroff and J. McK. Camp, “The Date of the Third Period of the Pnyx,” Hesperia 65 (1996) 263-294, figs. 1-21, pls. 77-80; <JBcom>[probably an unfinished Hadrianic project as H. A. Thompson originally surmised]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3154 }" id="NP3154" >Great curved retaining wall: Kourouniotis, K. and H. A. Thompson 1932, 91-95, 140-155, 172, 174-192, figs. 1-57 passim</p>
<p :class="{'activeClass': idClicked == NP3155 }" id="NP3155" >Rock-cut bema, the uppermost surface left unfinished in a very rough state, with two flights of rock-cut steps to the upper level: ibid., 91-2, 139, 141, 158-163, figs. 37-39, 41-42, 51, 58, pls. 1-3; <JBcom>[this form of stepped bema is Roman, not occurring in Greek structures]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3156 }" id="NP3156" >Two sets of rock-cut benches above and flanking the bema: ibid., 165-6 with figs. 38, 41-2, 51, 58 and pls. 1-3; G. R. Stanton, “The Shape and Size of the Athenian Assembly Place in its Second Phase,” in Pnyx 1996, 15-16</p>
<p :class="{'activeClass': idClicked == NP3157 }" id="NP3157" >Retaining wall for the terrace west of and above the auditorium: Kourouniotis, K. and H. A. Thompson 1932, 91, 139, 166-173 with figs. 1, 42-45, 51 and pls. 1-2</p>
<p :class="{'activeClass': idClicked == NP3158 }" id="NP3158" >PNYX HILL. For monuments and sites without clear-cut names on Pnyx Hill, see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM250">250</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM259">259</a></p>
<p :class="{'activeClass': idClicked == NP3159 }" id="NP3159" >PODALEIRIOS: see Machaon and Podaleirios</p>
<p :class="{'activeClass': idClicked == NP3160 }" id="NP3160" >POLEMARCH, theater throne - - - IG II² 5038; Maass, M. 1972, 16,117, pl. 10 </p>
<p :class="{'activeClass': idClicked == NP3161 }" id="NP3161" >POLEMARCHEION: see Epilykeion</p>
<p :class="{'activeClass': idClicked == NP3162 }" id="NP3162" >POLEMON the philosopher, place where he customarily sat (sessio) in the Academy: Cic. Fin. 5.1.2; Billot, M.-F. 1989, 787</p>
<p :class="{'activeClass': idClicked == NP3163 }" id="NP3163" >POLEMON = Antonius Polemo (PIR, 2nd ed., 862), 2nd c. A.C. sophist, portrait head Olympieion NM inv. no. —?; portrait head <JBcom>[doubtably]</JBcom> assigned to Polemon because of the findspot, A. Heckler, “Philosophen- und Gelehrtenbildnisse der mittleren Kaiserzeit,” Die Antike 16 (1940) 125, figs. 6-7</p>
<p :class="{'activeClass': idClicked == NP3164 }" id="NP3164" >POLETERION, meeting place of the poletai: Harp. s.v. πωληταί; Agora III, 165 nos. 537-540; perhaps south of the Tholos, Μ. K. Langdon in Agora XIX, 66-67</p>
<p :class="{'activeClass': idClicked == NP3165 }" id="NP3165" >POLIAS: see Athena Ath65*, Ath101*, Ath103*-112*; Erechtheion; Julia Domna</p>
<p :class="{'activeClass': idClicked == NP3166 }" id="NP3166" >POLIEUS: see Zeus Zeu96*-100*</p>
<p :class="{'activeClass': idClicked == NP3167 }" id="NP3167" >POLIS: name for Athens as a city and a political community: Hdt. 8.61; old name of the Acropolis: Paus. 1.26.6; David Russell Cole, “Asty and Polis : ‘City' in Early Greek,” Univ. Microfilms, 1976, 255-344; see Pantainos, Library; Tyche of the Polis</p>
<p :class="{'activeClass': idClicked == NP3168 }" id="NP3168" >POLYBOTES: see Poseidon and P.</p>
<p :class="{'activeClass': idClicked == NP3169 }" id="NP3169" >POLYDEUKION: H. Meyer, “Vibullius Polydeukion: ein archaologisch-epigraphischer Problemfall,” AM 100 (1985) 393-404, especially 398 nos. 3-5, 7, 9-11; Tobin, J. 1997, Index s.v. Polydeukion, esp. 195, 198, 199; Byrne, S. G. 2003, 485-486 no. 28</p>
<p :class="{'activeClass': idClicked == NP3170 }" id="NP3170" >Herm, ca. 150 A.D., near the Tower of the Winds IG II² 13194; SEG 42 (1992) no. 206; the herm is from Kephissia according to Tobin, J. 1997, 121</p>
<p :class="{'activeClass': idClicked == NP3171 }" id="NP3171" >Head frag, from a relief, Akr. west slope - - - NM 2144; A. Datsouli-Stavridi, «Συμβουλή στην εικονογραφία τού Πολυδεύκη,» AAA 10 (1977) 126, 133-4, fig. 7</p>
<p :class="{'activeClass': idClicked == NP3172 }" id="NP3172" >POLYKRITOS OF MENDE, physician, statue in the Asklepieion, 4th c. B.C.: IG II² 1533 lines 27-28, 63; Aleshire, S. B. 1989, 156, 160</p>
<p :class="{'activeClass': idClicked == NP3173 }" id="NP3173" >POLYXENA “about to be slaughtered near the grave of Achilles”, picture in the Propylaia western chamber: Paus. 1.22.6, 10.25.10; <JBcom>[the comment that “Homer did well to omit so savage a deed” is one more indication that Pausanias knew his Homer by heart]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3174 }" id="NP3174" >POMPEION between the the Sacred Gate and the Dipylon Gate: Kerameikos X, 1-248, figs. 1-261, pls. 1-30; cf. Technitai of Dionysos, bouleuterion <JBcom>[Imaginary]</JBcom> early Pompeion after 566 B.C.: Travlos, J. 1971, 477</p>
<p :class="{'activeClass': idClicked == NP3175 }" id="NP3175" >Pompeion (I) early 4th c. - 87 B.C.: D. 34.39</p>
<p :class="{'activeClass': idClicked == NP3176 }" id="NP3176" >Mosaic floor with gryphons and lions in a dining-room, 4th c. B.C.: Salzmann, D. 1982, 86 no. 19, pl. 24 nos. 2-3</p>
<p :class="{'activeClass': idClicked == NP3177 }" id="NP3177" >Graffiti written on the inside front wall by ephebes after ca. 150 B.C. IG II²  1960 A; <JBcom>[mistakenly]</JBcom> assumed to demonstrate that the Pompeion functioned as a gymnasium, Kerameikos X, 120-122, 132, fig. 91, pl. 28; SEG 26 (1976-7) no. 199; <JBcom>[the ephebes were not stationed in Athens, they were at the Pompeion as escorts in processions]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3178 }" id="NP3178" >Pompeion (II) ca. 144 -267 A.D.: Paus. 1.2.4; perhaps donated by Herodes Atticus, Kerameikos X, 169</p>
<p :class="{'activeClass': idClicked == NP3179 }" id="NP3179" >Theater mask, fragment of a wall painting, 2nd-3rd c. A.C., found in the Pompeion courtyard in debris of the 3rd c. A.C. Kerameikos WM 3; Hallof, K.- J. Stroszeck 2002, 130, p. 20:3</p>
<p :class="{'activeClass': idClicked == NP3180 }" id="NP3180" ><JBcom>[Imaqinary]</JBcom> "Pompeion built in the 4th c., probably in the reign of Julian,” Travlos, J. 1971, fig. 607</p>
<p :class="{'activeClass': idClicked == NP3181 }" id="NP3181" >Pompeion (III): for the so-called Pompeion III, 5th c. A.C., see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM422">422</a></p>
<p :class="{'activeClass': idClicked == NP3182 }" id="NP3182" >POMPEIUS CAPITO = Q. Pompeius Colleina Capito, poet, base of bronze statue beside Menander's statue in theater - - - IG II² 3800; R. Hanslik, RE XXI, 1952, col. 2268-2269 (Pompeius no. 71); L. Robert, “Une epigramme satirique d'Automedon et Athenes au debut de l'empire,” REG 94 (1981) 348 note 45; Byrne, S. G. 2003, 408 no. 20</p>
<p :class="{'activeClass': idClicked == NP3183 }" id="NP3183" >POMPEIUS MAGNUS, Cn. (RE Pompeius 31), inscriptions inside and outside the city gate addressed to Pompey by the people of Athens: Plu. Pomp. 27; A. D. Nock, “Notes on Ruler-Cult l-IV,” JHS 48 (1928) 37</p>
<p :class="{'activeClass': idClicked == NP3184 }" id="NP3184" >POMPEIUS SEXTUS, grandfather of the triumvir, statue base, Propylaia south wing - - - IG II² 4100; R. Hanslik, RE XXI, 1952, col. 2059 (Pompeius no.17)</p>
<p :class="{'activeClass': idClicked == NP3185 }" id="NP3185" >POMPEIUS STRABO, Cn. father of the triumvir, statue base, Akr. North Slope near the Klepsydra — IG II² 4101; F. Miltner, RE XXI, 1952, col. 2254 (Pompeius no. 45)</p>
<p :class="{'activeClass': idClicked == NP3186 }" id="NP3186" >POSEIDIPPEION dedicated by Poseidippos, New Comedy writer, in the temenos of the Technitai of Dionysos: IG II² 1320 lines 14-15; F. Poland, REV, A2 1934, col. 2542 line 62 - col. 2543 line 2, col. 2551 lines 52-54, col. 2554 lines 21-24 (Nachtrage, Technitai)</p>
<p :class="{'activeClass': idClicked == NP3187 }" id="NP3187" >POSEIDON ON THE AKROPOLIS</p>
<p :class="{'activeClass': idClicked == NP3188 }" id="NP3188" >Altar of Poseidon on which sacrifices to Erechtheus are made in obedience to an oracle, in the Erechtheion: Paus. 1.26.5; conjecturally <JBcom>[rightly]</JBcom> located in the Erechtheion eastern chamber, Travlos, J. 1971, 213, fig. 281 B; Kron, U. 1976, 50-52</p>
<p :class="{'activeClass': idClicked == NP3189 }" id="NP3189" >Spring in the Erechtheion, Poseidon's martyrion <JBcom>[i.e. evidence of Poseidon's participation in the contest]</JBcom>: Paus. 1.26.5, 4.35.11, 8.10.4; called Erechtheis, Apoll. 3.14.1; Kron, U. 1976, 43-44, 49</p>
<p :class="{'activeClass': idClicked == NP3190 }" id="NP3190" >Marks of the trident in the rock at the Erechtheion, Poseidon's martyrion: Paus. 1.26,5; Kron, U. 1976, 43-44</p>
<p :class="{'activeClass': idClicked == NP3191 }" id="NP3191" >Poseidon Erechtheus, cult: E. Erech. col. VII lines 92-4, C. Austin, Nova Fragmenta Euripidea, Berlin, 1968, 39; Mikalson, J. D. 1976, 143</p>
<p :class="{'activeClass': idClicked == NP3192 }" id="NP3192" >Poseidon Erechtheus Gaieochos, dedication made by the priest in honor of Gaius Iulius Spartiatikos, 54-68 A.D.: IG II² 3538 lines 8-9; Kron, U. 1976, 48 note 193; Petterson, M. 1992, 35; SEG 42 (1992) no. 163</p>
<p :class="{'activeClass': idClicked == NP3193 }" id="NP3193" >Poseidon Erechtheus, perirrhanterion dedication by two men of Erechtheis, ca. 450? B.C., near Erechtheion - - - IG I³ 873 = IG I² 580; SEG 42 (1992) no. 42; Kron, U. 1976, 48-49, 53 with note 217; L. H. Jeffery, “Poseidon on the Acropolis,” in ICCA XII, Athens, 1983, 125</p>
<p :class="{'activeClass': idClicked == NP3194 }" id="NP3194" >Poseidon Gaieochos and Erechtheus, Hadrianic period, priest's theater throne IG II² 5058; Maass, M. 1972, 127, pl. 15; Kearns, E. 1989, 210; SEG 42 (1992) no. 111 See also: Eteoboutad priests of Poseidon</p>
<p :class="{'activeClass': idClicked == NP3196 }" id="NP3196" >Poseidon with trident, fig (?) tree, enthroned goddess with cornucopia, relief disk of poros limestone, Hellenistic pd. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201194" target="_blank">Agora S 1194</a>; interpreted as Poseidon Phytalmios and Demeter, 7. L. Shear, “The Campaign of 1940,” Hesperia 10 (1941) 3-5, fig. 4; identified more cautiously as Poseidon and perhaps Demeter, LIMC IV, 1988, 882 no. 460, ph., s.v. Demeter (L. Beschi)</p>
<p :class="{'activeClass': idClicked == NP3197 }" id="NP3197" >Theater seat, Poseidon (?) IG II² 5157 See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM524">524</a></p>
<p :class="{'activeClass': idClicked == NP3198 }" id="NP3198" >POSEIDON ASPHALEIOS, cult: EM 13330 lines 39-40; D. M. Lewis, “Notes on the Decree of Themistokles,” CQ 11 (1961) 64-65</p>
<p :class="{'activeClass': idClicked == NP3199 }" id="NP3199" >POSEIDON CHAMAIZELOS, cult: IG II² 1 367 line 18; SEG 51 (2001) no. 138</p>
<p :class="{'activeClass': idClicked == NP3200 }" id="NP3200" >POSEIDON ELATES, cult: Hsch. s.v. Έλάτη; E. Wust, RE XXII, 1953, col. 496 lines 63-67 (Poseidon)</p>
<p :class="{'activeClass': idClicked == NP3201 }" id="NP3201" >POSEIDON ERECHTHEUS: see Poseidon on the Akropolis, above</p>
<p :class="{'activeClass': idClicked == NP3202 }" id="NP3202" >POSEIDON GAIEOCHOS: see Poseidon on the Akropolis, above</p>
<p :class="{'activeClass': idClicked == NP3203 }" id="NP3203" >POSEIDON HELIKONIOS, eschara on top of Helikon hill: Anecdota graeca, 1914, 326-327 s.v. Αγραι; F GR HIST III B, 51-2, Kleidemos F 1 with suppl. vols. I, 63 and II, 35 under F 11; probably founded in 478 B.C.; cf. J. P. Barron, “The fifth-century horoi of Aiginia,” JHS 103 (1983) 11; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM531">531</a></p>
<p :class="{'activeClass': idClicked == NP3204 }" id="NP3204" >POSEIDON HIPPIOS, sanctuary at Kolonos Hippios, the Poseidonion: P. Siewert, “Poseidon Hippios am Kolonos und die athenischen Hippeis,” in Hellenic Studies presented to Bernard M. W. Knox on the occasion of his 65th birthday, G. W. Bowersock et al. eds., Berlin, 1979, 280-289</p>
<p :class="{'activeClass': idClicked == NP3205 }" id="NP3205" >Temple and grove: Paus. 1.30.4; Svoronos, J. N. 1911, 395-397</p>
<p :class="{'activeClass': idClicked == NP3206 }" id="NP3206" >Inventory of sanctuary furnishings, 413-405 B.C. IG I³ 405</p>
<p :class="{'activeClass': idClicked == NP3207 }" id="NP3207" >Altar of Poseidon where Theseus sacrificed and Polyneikes was a suppliant: S. OC lines 888-889, 1156-1159; Alabe, F. 1987, 137-139</p>
<p :class="{'activeClass': idClicked == NP3208 }" id="NP3208" >Poseidon Hippios and Athena Hippia, altar: Paus. 1.30.4; Johrens, G. 1981,140-143</p>
<p :class="{'activeClass': idClicked == NP3209 }" id="NP3209" >Poseidon Hippios and Prometheus, sanctuary at Kolonos Hippios: Sallustios, 4th hypothesis to S. OC; Svoronos, J. N. 1911, 397-399</p>
<p :class="{'activeClass': idClicked == NP3210 }" id="NP3210" >POSEIDON PHYTALMIOS, priest's theater throne IG II² 5051; Maass, M. 1972, 123, pl. 13; for a possible representation of Poseidon Phytalmios, see Poseidon with trident, poros limestone relief disk, above</p>
<p :class="{'activeClass': idClicked == NP3211 }" id="NP3211" >POSEIDON AND ATHENA: see Athena Ath130*-131*</p>
<p :class="{'activeClass': idClicked == NP3212 }" id="NP3212" >POSEIDON AND POLYBOTES, Poseidon on horseback attacking the giant Polybotes with later inscr. renaming the sculpture: Paus. 1.2.4; Frazer, J. G. 1913, II, 48-50</p>
<p :class="{'activeClass': idClicked == NP3213 }" id="NP3213" >POST-HERULIAN WALL, formerly called the Valerian Wall or the Late Roman Fortification Wall, constructed all the way around the Akropolis slopes, completed during or after the reign of Maximianus (286-305 A.D.): Agora XXIV, 5-11 (see esp. note 40 for the coin of Maximianus), 125-141, pls. 3-15; the general assumption that the wall enclosed and protected only the Akropolis North Slope was shown to be mistaken in the 1990s, Tanoulas, T. 1997, Vol. I, 265, 267; <JBcom>[since in A.D. 267 the Athenians had no way of knowing when or if the Herulians would return, next week, next month, or next year, it is well-nigh incredible that they would put off strengthening their defences for some twenty or more years. The Athenians would have repaired the old Themistoklean circuit before constructing an inner line of defence, the Post-Herulian Wall; see Themistoklean City Wall Wal34*]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3214 }" id="NP3214" >Inscription praising Claudius Illyrius (name restored) for building the City Wall, 270s/280s A.D., found in 1861 at the Church of Hag. Dimitrios tou Katiphori (Chr. Mon. CM59*, Plan 1 delta—7) - - - IG II² 5199; SEG 46 (1996) no. 272; the entire Post-Herulian Wall probably built by Illyrius, Agora XXIV, 9; Sironen, E. 1997, 98-100 no. 30; cf. Claudius Illyrius</p>
<p :class="{'activeClass': idClicked == NP3215 }" id="NP3215" >Hundreds of pieces of architecture, sculpture and inscriptions were built into the Post-Herulian Wall. One example follows. <br>Column capital - - - NM 4478; A. Kokkou, “To κιονοκρανον τού ναού τής Σουνιάδης Άθηνάς Ε. Μ. 4478 και ή Συλλογή τού Καντακουζενού,» ΑΕ, 1974, 102-112, pls. 34, 36, fig. 1 <br>1st location: the temple of Athena at Sounion, 5th c. B.C.<br>2nd location: the Southeast Temple in the Agora, 2nd c. A.C. (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM366">366</a>) <br>3rd location: Post-Herulian Fortification Wall, ca. 280 A.C.<br>4th location: Church of Hag. Kyra ( Chr. Mon. CM46*), Polygnotou St. (Plan 1 delta—4), 16th c. A.C.<br>5th location: Collection Georgios Kantakouzenos, 1834<br>6th location: Hephaisteion, then called Theseion, functioning as the Central Public Museum for antiquities<br>7th location: National Museum, transferred thither between 1874 and 1889</p>
<p :class="{'activeClass': idClicked == NP3224 }" id="NP3224" >Post-Herulian Wall west flank on the Akropolis northwest slope, from the Mnesiklean Bastion to the north end of the Stoa of Attalos: Agora XXIV, 6,7-8, 127-136, 139</p>
<p :class="{'activeClass': idClicked == NP3225 }" id="NP3225" >Tower between the Mnesiklean Bastion and the Hypapanti Gate: Shear, T. L. 1940, 297, pl. I; Tower W1, Agora XXIV, 7, 128, pl. 5</p>
<p :class="{'activeClass': idClicked == NP3226 }" id="NP3226" >Gate at the site of the Hypapanti church (Chr.Mon. CM71*), Akr. northwest slope south of the Eleusinion: Shear T. L. 1939, 204 fig. 3 (above and left of the letter B), 220-221; “Hypapanti Gate,” Agora XXIV.138-139</p>
<p :class="{'activeClass': idClicked == NP3227 }" id="NP3227" >Central gate with the tower to the south: H. A. Thompson, “Activities in the Athenian Agora: 1958,” Hesperia 28 (1959) 95-6, pls. 14-15; “Christ Church Gate” protected by Tower W2, Agora XXIV, 7, 129, pls. 5, 14:a</p>
<p :class="{'activeClass': idClicked == NP3228 }" id="NP3228" >Tower at the southwest corner of the Library of Pantainos: H. A. Thompson, “Activities in the Athenian Agora: 1959,” Hesperia 29 (1960) 350-359, fig. 1, pl. 75:b; Tower W3, Agora XXIV, 7, 130, pl. 5</p>
<p :class="{'activeClass': idClicked == NP3229 }" id="NP3229" >Gate flanked by towers south of the Stoa of Attalos: Shear, Jr., T. L. 1973b, Hesperia 42, 391, 392 fig. 7; “Pyrgiotissa Gate” flanked by Towers 4 and 5, Agora XXIV, 7-8, 131, 133, 139, pls. 5, 10:b</p>
<p :class="{'activeClass': idClicked == NP3230 }" id="NP3230" >The Church of Panagia Pyrgiotissa or Chrysopyrgiotissa (Chr. Mon. CM77*), demolished in 1859, had been built into Tower 5 which was demolished in 1869: W. Gurlitt, “I. Scavi. a. Scavi di Atene,” BdI 1869, 161-162; Miller, S. G. 1972, 58-59, fig. 6</p>
<p :class="{'activeClass': idClicked == NP3231 }" id="NP3231" >Tower in the middle of the Stoa of Attalos, torn down in 1900: Sketch dated June 1785, E. Vanderpool, “Athens Honors the Emperor Tiberius,” Hesperia 28 (1959) 87, pls. 11-12; Travlos, J. 1971, fig. 234; Tower W6, Agora XXIV, 8, 132, pls. 5,12</p>
<p :class="{'activeClass': idClicked == NP3232 }" id="NP3232" >Post-Herulian Wall north flank on the Akropolis lower north slope: Agora XXIV, 1988, 6,7,8, 136-137, 139-140</p>
<p :class="{'activeClass': idClicked == NP3233 }" id="NP3233" >Postern gate at 94 Adrianou St.: Dontas, G. S. 1972, 16-17, fig. 1, pl. 29</p>
<p :class="{'activeClass': idClicked == NP3234 }" id="NP3234" >Gate repaired in the time of Justinian at the site of the Panagia Krystalliotissa, Adrianou St. west of Mnesikleous St. (Plan 1 gamma—7, no. 110): EMME I, 1927, 28-30, figs. 10-11; II, 106, fig. 135</p>
<p :class="{'activeClass': idClicked == NP3235 }" id="NP3235" >Post-Herulian Wall, east flank on the Akropolis northeast slope: P. G. Kalligas, «Ακίνητα περιοχής Πλάκας,» Deltion 46 (1991) B1 Chr., 22 fig. 3 (plan showing what actually exists of the east flank); the line of the east flank, shown in solid lines but in fact <JBcom>[arbitrarily]</JBcom> restored, ending high up on the Akropolis northeast slope, J. Travlos, “Athens after the Liberation,” Hesperia 50 (1981) 405 fig. 7; <JBcom>[mistakenly]</JBcom> restored as “undoubtedly” ending near the northeast corner of the Akropolis, Agora XXIV, 6,7, 137-138, 140; <JBcom>[south of Lissiou St. the so-called east flank has no foundations, no beddings and no sign of contact where the restored line of the wall is shown stopping at a rock face; J. Travlos was prevented from running the line of the east flank right up to the Akropolis fortification wall, as would be logical, because the absence of any sign of contact is strikingly visible; he contented himself with a fairly inaccessible, not easily seen, never photographed, unworked rock face for the end of his east flank]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3236 }" id="NP3236" >Inscriptions and sculpture removed from the east flank of the Post-Herulian wall near H. Dimitrios tou Katiphori (Chr. Mon. CM59*) including finds to be associated with the Gymnasium of Ptolemy: Lippolis, E. 1995, Ostraka 4, 51-54, esp. n. 29, fig. 4 no. 2, figs. 6-10; SEG 45 (1995) no. 222</p>
<p :class="{'activeClass': idClicked == NP3237 }" id="NP3237" >Post-Herulian Wall, Akropolis South Slope: K. Tsakos, «Διονυσιακό Θέατρο,» Deltion 40 (1985) Chr. 10-11 with note 17; Tanoulas, T. 1997, Vol. I, 255, 267, 273 (north wall of the Stoa of Eumenes)</p>
<p :class="{'activeClass': idClicked == NP3238 }" id="NP3238" >POTHOS: see Ge Hemeros</p>
<p :class="{'activeClass': idClicked == NP3239 }" id="NP3239" >POTTER HERO with kylikes, early 5th c. B.C. relief, Akr. - - - IG I³ 764 = IG I² 718 + <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204571" target="_blank">Agora I 4571</a>, Acr. 1332, 13250; SEG 42 (1992) no. 39; Raubitschek, A. E. 1949, no. 70; called Keramos, Svoronos, J. N. 1911, 492; attributed to Endoios, P. A. Marx, “The Introduction of the Gorgoneion to the Shield and Aegis of Athena and the Question of Endoios,” RA 1993, 247-248, fig. 16; “the Potter relief might actually represent a maker of metal vessels,” Keesling, C. M. 2003, 69, 73, fig. 19. <JBcom>[It is difficult to believe that such a large seated figure is the dedicator, as is generally taken for granted, but if it is the dedicator he could not have been alive at the time of the dedication]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3240 }" id="NP3240" >POULYTION'S HOUSE where the Eleusinian mysteries were parodied, later dedicated to Dionysos Melpomenos, in a stoa on the Panathenaic Way: Paus. 1.2.5; Agora III, 20-21; Maass, M. 1972, 126; A. H. Sommerstein, “The Decree of Syrakosios,” CQ 36 (1986) 105-106; Aneziri, S. 2000, 259-279</p>
<p :class="{'activeClass': idClicked == NP3241 }" id="NP3241" >PRATER LITHOS, the Selling Stone: Poll. 3.126; Agora III, no.</p>
<p :class="{'activeClass': idClicked == NP3242 }" id="NP3242" >PRAXITELES' atelier (oikema) containing his works including the Satyr and the Eros: Paus. 1.20.1-2</p>
<p :class="{'activeClass': idClicked == NP3243 }" id="NP3243" >PROBUS, Sex. Claudius Petronius, praetorian prefect of Illyricum ca. 383 A.D. (PLRE I, 1971, Probus 5), base of a bronze statue dedicated by Anatolios proconsul of Hellas, north of the Tower of the Winds - - - IG II² 4226; Sironen, E. 1997, 69-70 no. 13</p>
<p :class="{'activeClass': idClicked == NP3244 }" id="NP3244" >PROHAIRESIOS, Christian sophist, one of Julian the Apostate's teachers, house with theater inherited from the sophist, Julian the Cappodocian: Eun. VS 483; Agora XXIV, 45</p>
<p :class="{'activeClass': idClicked == NP3245 }" id="NP3245" >PROKLOS of Naukratis, sophist, house with private library, late 2nd-early 3rd A.C.: Philostr. VS 11.21 (604); Agora XXIV, 46</p>
<p :class="{'activeClass': idClicked == NP3246 }" id="NP3246" >PROKLOS, Neo-Platonist philosopher (PLRE II, 1980, Proclus 4)</p>
<p :class="{'activeClass': idClicked == NP3247 }" id="NP3247" >House near the Asklepieion: see Ploutarchos' House; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM165">165</a></p>
<p :class="{'activeClass': idClicked == NP3248 }" id="NP3248" >Proklos and Syrianos, grave monument with epigram by Proklos, after 485 A.D., near Lykabettos: Marin. Procl. 36; PLRE II, 1980, Syrianos 3: Sironen, E. 1997, 156 no. 82; cf. SEG 51 (2001) no. 298</p>
<p :class="{'activeClass': idClicked == NP3249 }" id="NP3249" >PROKNE, daughter of Pandion, contemplating the murder of ITYS, sculpture dedicated by Alkamenes, 430-420 B.C., found 1836 in the rampart west of the Propylaia Acr. 1358; Paus. 1.24.3, cf. 1.5.4; the head does not belong, H. Knell, “Die Gruppe von Prokne und Itys” Antike Plastik XVII, 1978, 9-19, figs. 1-3, pls. 1-9; perhaps stood at the Parthenon north side, opposite to the 3rd column from the east, Stevens, G. P. 1946a, 10-11, figs. 1, 3 no. 3, 12</p>
<p :class="{'activeClass': idClicked == NP3250 }" id="NP3250" >PROMACHOS: see Athena Ath113*</p>
<p :class="{'activeClass': idClicked == NP3251 }" id="NP3251" >PROMETHEUS: Billot, M.-F. 1989, 744-768</p>
<p :class="{'activeClass': idClicked == NP3252 }" id="NP3252" >Pyrphoros Theos Titan Prometheus in the precinct of Poseidon Hippios: S. OC lines 55-56; Svoronos, J. M. 1911, 398-399, 400; cf. Poseidon Hippios and Prometheus</p>
<p :class="{'activeClass': idClicked == NP3253 }" id="NP3253" >Prometheus and Hephaistos, ancient base with a relief of an old Prometheus and youthful Hephaistos with a joint altar, at the entrance to the Academy: schol. S. OC line 56; altar of Prometheus where the torch races start, Paus. 1.30.2; Billot, M.-F. 1989, 745-746, 764-766, 767, 768-773; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM471">471</a></p>
<p :class="{'activeClass': idClicked == NP3254 }" id="NP3254" >Picture of Prometheus by Parrhasios: Sen. Con. 10.5.3-4</p>
<p :class="{'activeClass': idClicked == NP3255 }" id="NP3255" >PRONAIA: see Livia Julia Augusta Pronaia</p>
<p :class="{'activeClass': idClicked == NP3256 }" id="NP3256" >PROPYLAIA: see Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM8">8</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM12">12</a></p>
<p :class="{'activeClass': idClicked == NP3257 }" id="NP3257" >PROPYLAIOS, PROPYLAIA: see Demeter Propylaia; Hermes Hrm27*</p>
<p :class="{'activeClass': idClicked == NP3258 }" id="NP3258" >PROPYLON boundary stone, poros limestone, before the mid-5th c. B.C., built into the Church of Vlassarou west of the Odeion of Agrippa IG l31097; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203624" target="_blank">Agora I 3624</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 23 (1954) 259 no. 47, pl. 54; SEG 14 (1957) no. 27; accepting the <JBcom>[doubtable]</JBcom> view that this public propylon was in the Peiraeus, Agora XIX, 28 H29</p>
<p :class="{'activeClass': idClicked == NP3259 }" id="NP3259" >PROSTATERIOS: see Apollo Apo24*, Apo64*, Apo65*</p>
<p :class="{'activeClass': idClicked == NP3260 }" id="NP3260" >PROTEICHISMA: see Themistoklean City Wall Wal1*, Wal10*, Wal14*, Wal16*, Wal27*</p>
<p :class="{'activeClass': idClicked == NP3261 }" id="NP3261" >PRYTANEION, Akr. north slope below the Aglaurion and at the start of the street of the Tripodes: Paus. 1.18.3 + 1.20.1 + 1.26.3, 9.32.8; Agora III, 166-174; conjectural location southeast of the Eleusinion, Travlos, J. 1971, 1,2, fig. 5 no. 15, fig. 540 no. 15, fig. 722 O; S. G. Miller, The Prytaneion, Berkeley, 1978, 38-66, 136-183; for a <JBcom>[mistaken]</JBcom> location on the Akr. East Slope, see Aglaurion and Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM188">188</a>,189; the prytaneion site may be at the fork of the ancient street of the Tripods, i.e. Tripodes q.v.</p>
<p :class="{'activeClass': idClicked == NP3262 }" id="NP3262" >Lawcourt at the Prytaneion: Paus. 1.28.10; categorically <JBcom>[mistakenly]</JBcom> located on the eastern side of the Akropolis, Agora XXVIII, 50 and Index s.v. Prytaneion</p>
<p :class="{'activeClass': idClicked == NP3263 }" id="NP3263" >PRYTANIKON: Th. 2.15; name of the Tholos precinct, E. Vanderpool, “Tholos and Prytanikon,” Hesperia 4 (1935) 470-475; Shear Jr., T. L. “Ίσονομους . . The Agora and the Democracy,” in Coulson et al. 1994, 241, 247 note 70; SEG 46 (1996) no. 22; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM278">278</a></p>
<p :class="{'activeClass': idClicked == NP3264 }" id="NP3264" >PSITHYRISTES: see Hermes Hrm28*</p>
<p :class="{'activeClass': idClicked == NP3265 }" id="NP3265" >PSITHYROS, hero cult: Hsch. s.v. ψιθύρα; G. Radke, RE XXIII,1959, col. 1415-1417 (Psithyros); see Aphrodite Aph50*</p>
<p :class="{'activeClass': idClicked == NP3266 }" id="NP3266" >PTOLEMAIOS, son of Juba II, statue base — IG II² 3445; found in the ruins of the Stoa of Attalos formerly thought to be the Gymnasium of Ptolemy, Stuart, J. & N. Revett 1794, 1 note b, with plan of Athens preceding page II</p>
<p :class="{'activeClass': idClicked == NP3267 }" id="NP3267" >PTOLEMAIOS son of Ptolemaios, bronze equestrian statue beside the Old Temple of Athena, awarded by decree between 169 and 134 B.C.: IG II² 983 lines 4-6; identification with Ptolemy VI Philometor ruled out, Habicht, Chr. 1992, 81-82 = SEG 42 (1992) no. 106 and 49 (1999) no. 119</p>
<p :class="{'activeClass': idClicked == NP3268 }" id="NP3268" >PTOLEMIES, the Macedonian dynasty: Richter, G. M. A. 1972, II, 230-237; Habicht, Chr. 1992, 68-90; SEG 42 (1992) no. 237; Kotsidu, H. 2000, 64-71</p>
<p :class="{'activeClass': idClicked == NP3269 }" id="NP3269" >Statues of the Ptolemies in front of the entrance to the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6, 1.9.4 (the Egyptians)</p>
<p :class="{'activeClass': idClicked == NP3270 }" id="NP3270" >Ptolemy I Soter, statue in front of the entrance to the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6</p>
<p :class="{'activeClass': idClicked == NP3271 }" id="NP3271" >Ptolemy II Philadelphos</p>
<p :class="{'activeClass': idClicked == NP3272 }" id="NP3272" >Statue in front of the entrance to the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6</p>
<p :class="{'activeClass': idClicked == NP3273 }" id="NP3273" >Eponymous Hero statue: Paus. 1.5.5 + 1.8.6, cf. 1.6.8; Pausanias erred, Ptolemy III is the eponymous of Ptolemais, Habicht, Chr. 1985, 96-97 with note 12</p>
<p :class="{'activeClass': idClicked == NP3274 }" id="NP3274" >Ptolemy III Euergetes</p>
<p :class="{'activeClass': idClicked == NP3275 }" id="NP3275" >Eponymous Hero statue, 224/223 B.C.: Agora XXI1,11-13</p>
<p :class="{'activeClass': idClicked == NP3276 }" id="NP3276" >Ptolemaion, gymnasium founded by Ptolemy III in 224/223 B.C.: not far from the Agora and near the sanctuary of Theseus according to Paus. 1.17.2 (Pausanias does not specify which Ptolemy); Agora III, 142-144; Habicht, Chr. 1982, 112-117; Wycherley, R. E. 1978, 232, 233-234; <JBcom>[mistaken]</JBcom> location in the Agora South Square, Travlos, J. 1971, 233-241, figs. 301-311; <JBcom>[unsubstantiated]</JBcom> location on the Akropolis north slope east of the Roman Agora, Travlos, J. 1971, 578-579 with fig. 722 T; <JBcom>[unsubstantiated]</JBcom> location on the Akr. north slope east of the Roman Agora with the xystos located on Kyrrhestou St., Miller, S. G. 1994, 202-209, fig. 1 GP; SEG 46 (1996) nos. 62, 311; cf. Post-Herulian Wall, east flank; Ptolemaios, son of Juba II; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM207">207</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM209">209</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM209">209</a> BIS</p>
<p :class="{'activeClass': idClicked == NP3277 }" id="NP3277" >Library in the Ptolemaion to which the ephebes donated 100 books each year starting in 116/115 B.C.: IG II² 1029 lines 25-26; IG II² 1009+ line 10; Platthy, J. 1968, 110-111, testimonia nos. 28-35; SEG 38 (1988) no. 116 (re the 100 books); Schenkungen hellenistisher Herrscher, Part I, 1995, 47 (the testimonia)</p>
<p :class="{'activeClass': idClicked == NP3278 }" id="NP3278" >Lecture hall in the Ptolemaion where Cicero heard the head of the Academy, Antiochos of Askalon, in 79 B.C.: Cic. Fin. V.1; Agora III, no. 457</p>
<p :class="{'activeClass': idClicked == NP3279 }" id="NP3279" >Bronze statue of Ptolemy in the Ptolemaion: Paus. 1.17.2; Schenkungen hellenistischer Herrscher, Part I, 1995, 45-46 and Part II, vol. 1, 145-146</p>
<p :class="{'activeClass': idClicked == NP3280 }" id="NP3280" >Ptolemy III Euergetes and Berenike: see Demokratia, Demos and Charites</p>
<p :class="{'activeClass': idClicked == NP3281 }" id="NP3281" >Ptolemy VI Philometor and his daughter (Kleopatra Berenike III, called Berenike by Pausanias), bronze statues set up by the Athenians grateful for his many benefactions, in front of the entrance to the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6 + 1.9.3; Pausanias 1.9.3 in fact refers to Ptolemy IX, Habicht, Chr. 1992, 85-86 with note 108; cf. Ptolemaios son of Ptolemaios; Ptolemy IX</p>
<p :class="{'activeClass': idClicked == NP3282 }" id="NP3282" >Ptolemy IX Philometor Soter II and his daughter Kleopatra Berenike, bronze statues set up by the Athenians grateful for his many benefactions, in front of the entrance to the Odeion <JBcom>[of Agrippa]</JBcom>: Paus. 1.8.6 and 1.9.3; Habicht, Chr. 1992, 85-86 with note 108</p>
<p :class="{'activeClass': idClicked == NP3283 }" id="NP3283" >PYLETES: see Hermes Hrm29*</p>
<p :class="{'activeClass': idClicked == NP3284 }" id="NP3284" >PYLOROI, the Akropolis gate guards: Geagan, D. J. 1967, 125-127</p>
<p :class="{'activeClass': idClicked == NP3285 }" id="NP3285" >List of the pyloroi with incised representation of an an Apollo statue and votive offerings, an amphora and phialai IG II² 2304; A. E. Raubitschek, “The Pyloroi of the Akropolis,” ΤΑΡΑ 76 (1945) 104-105, pl. 1; cf. Apollo Apo22* (Agyieus)</p>
<p :class="{'activeClass': idClicked == NP3286 }" id="NP3286" >Pylor<JBcom>[ion]</JBcom>, inscr. on a marble rooftile, 1 st c. A.C. - - - EM 3522; D. Peppa-Delmouzou, «Τεκτονικά Σημεία και Έπιγραφαι,» in Orlandos Charisterion 1965-1968, IV, 384-385, fig. 3, pl. 107 gamma; SEG 25 (1971) no. 318</p>
<p :class="{'activeClass': idClicked == NP3287 }" id="NP3287" >PYRPHOROS in Eleusinian cult: see Charites and Artemis Epipyrgidia</p>
<p :class="{'activeClass': idClicked == NP3288 }" id="NP3288" >PYRPHOROS from the Akropolis</p>
<p :class="{'activeClass': idClicked == NP3289 }" id="NP3289" >Theater throne IG II² 5046; Maass, M. 1972, 121, pl. 12</p>
<p :class="{'activeClass': idClicked == NP3290 }" id="NP3290" >Pyrphoros and Archon, double throne on the Akropolis, 4th c. B.C. IG II²  5170; Maass. M. 1972, 17, 33 note 2</p>
<p :class="{'activeClass': idClicked == NP3291 }" id="NP3291" >PYRPHOROS, epithet of Prometheus: see Prometheus</p>
<p :class="{'activeClass': idClicked == NP3292 }" id="NP3292" >PYRRHOS, king of Epiros, statue at the Odeion <JBcom>[of Agrippa]</JBcom>, 276-272 B.C.: Paus. 1.11.1; Kotsidu, H. 2000, 32-33</p>
<p :class="{'activeClass': idClicked == NP3293 }" id="NP3293" >PYTHAGORAS of Selymbria, state burial monument, 460-450 B.C., in situ on the Sacred Way near the Sacred Gate IG l3 1154 = IG I² 1034; W. Hoepfner, “Das Grabmonument des Pythagoras aus Selymbria,” AM 88 (1973) 145-63, figs. 1-9, pls. 77-78; CEG 1983, no. 11; SEG 33 (1983) no. 51</p>
<p :class="{'activeClass': idClicked == NP3294 }" id="NP3294" >PYTHAIS SACRED WAY TO DELPHI: see Sacred Way</p>
<p :class="{'activeClass': idClicked == NP3295 }" id="NP3295" >PYTHIAS: see Alkibiades</p>
<p :class="{'activeClass': idClicked == NP3296 }" id="NP3296" >PYTHION OF MEGARA, gravestone, ca. 445 - 425? B.C., erected by three Athenian tribes (Pandionis, Kekropis, Antiochis), found on the road to Acharnai outside the Acharnian Gate - - - IG I² 1085 = IG I³ 1353; Meiggs, R. and D. M. Lewis 1969, no. 51; Clairmont, C. 1983, 180-181, pl. 59; CEG 1983, no. 83; “the first epitaph to distinguish explicitly between a person and his body,” F. D. Miller, Jr.,” Philosophical Themes in Early Greek Grave Inscriptions,” Acta 11th Congress, I, 193</p>
<p :class="{'activeClass': idClicked == NP3297 }" id="NP3297" >PYTHION, sanctuary of Apollo Pythios: see Apollo Apo2*, Apo70*-73*; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM200">200</a></p>
<p :class="{'activeClass': idClicked == NP3298 }" id="NP3298" >PYTHIOS: see Apollo Apo24*, Apo66*-77*</p>
<p :class="{'activeClass': idClicked == NP3299 }" id="NP3299" >PYTHOCHRESTOS EXEGETES, theater throne - - - IG II² 5023; Maass, M. 1972, 103-104, pl. 6; R. S. J. Garland, “Religious Authority in Archaic and Classical Athens,” BSA 79 (1984) 116</p>
<p :class="{'activeClass': idClicked == NP3300 }" id="NP3300" >PYTHODORIS, queen of Archelaos Philopatris of Cappadocia, statue base, Akropolis - - - IG II² 3433; Geagan, D. 1996, 158</p>
<p :class="{'activeClass': idClicked == NP3301 }" id="NP3301" >PYTHODOROS, son of Epizelos, choregic tripod, 415 B.C. IG I³ 960 (= IG I² 770A); Davies, J. K. 1971, 481</p>
<p :class="{'activeClass': idClicked == NP3302 }" id="NP3302" >PYTHODOROS, son of Isolochos, house in the Kerameikos outside the walls where Sokrates met Zeno and Parmenides: Pl. Prm. 127 b-c</p>
<p :class="{'activeClass': idClicked == NP3303 }" id="NP3303" >RAM, bronze sculpture on the Akropolis, mid-5th c. B.C. or earlier: Hsch. s.v. κριός ἀσελγόκερων; FAC I, 1957, 556</p>
<p :class="{'activeClass': idClicked == NP3304 }" id="NP3304" >REGILLA, wife of Herodes Atticus, Appia Annia Regilla Atilia Caucidia Tertulla (PIR, 2nd ed., 720), base of a statue for R. as first priestess of Tyche of The Polis, west of the Panathenaic Stadium - - - IG II² 3607; Tobin, J. 1997, 77, 175-176; Byrne, S. G. 2003, 62 no. 28 (xii)</p>
<p :class="{'activeClass': idClicked == NP3305 }" id="NP3305" >RHASKOPOURIS I, king of Thrace, 48-42 B.C., statue by Antignotos, Akr. — IG II² 3442; Oliver, J. H. 1965, 55; Kotsidu, H. 2000, 87-88</p>
<p :class="{'activeClass': idClicked == NP3306 }" id="NP3306" >RHEA: see Kronion; Mother of The Gods; Taurobolion</p>
<p :class="{'activeClass': idClicked == NP3307 }" id="NP3307" >RHOIMETALKES III, king of Thrace, archon in Athens 37/38 A.D., dedication on reused kioniskos</p>
<p :class="{'activeClass': idClicked == NP3308 }" id="NP3308" >1st use: grave mon. of Lyso, 1st c. B.C. - - - IG II² 6041</p>
<p :class="{'activeClass': idClicked == NP3309 }" id="NP3309" >2nd use: Sarapion's prize in the taurokathapsia contest dedicated to Rhoimetalkes, 37/38 A.D., Acharnian Gate - - - IG II² 3156 with drawing of palm branch and crown; SEG 32 (1982) no. 259</p>
<p :class="{'activeClass': idClicked == NP3310 }" id="NP3310" >RIZOKASTRON, name for the settled area high up on the Akropolis upper north slope before the War of Independence: G. Kairophylas, «Τοπωνυμία της Αθηνας, του Πειραιά και των περιχώρων,» Athens, 1995, 186</p>
<p :class="{'activeClass': idClicked == NP3311 }" id="NP3311" >RIZOKASTRON WALL, A. D. 1225-1250, mostly demolished in 1877, wall with at least six gates right round the Akropolis slopes: <JBcom>[mistakenly]</JBcom> dated to the 11th c., J. Travlos, 1971, 387, 538, figs. 502, 686 IX; basically a refortification of the Post-Herulian Wall, K. Tsakos, «Διονυσιακό θέατρο» Deltion 40 (1985) Chr., 9, 10-11; Tanoulas, T. 1997, Vol. I, 301, 304, 305 and Vol. II, dr. 62; <JBcom>[in archaeologists' argot nowadays this wall is simply called the Rizokastron (I have not found out who first applied the old place-name to this wall), but a distinction should always be made between the Rizokastron Wall which goes right around the Akropolis slopes and the Rizokastron which is limited to the Akropolis north slope]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3312 }" id="NP3312" >ROMA AND AUGUSTUS<br>Altar, Evangelistrias St. at Panagia Rombi, east of Kapnikarea church (Plan 2 epsilon—5, no. 52) IG II² 3179; P. Baldassarri, “Augusto Soter: ipotesi sul monopteros dell'Acropoli ateniense,” Ostraca 4 (1995) 74 with note 28</p>
<p :class="{'activeClass': idClicked == NP3314 }" id="NP3314" >Monopteros, 19 B.C., east of the Erechtheion: the round building represented at the Akropolis north wall east of the Erechtheion on an Athenian bronze coin, 3rd c. A.C., BM 1922.3-17.82, M. J. Price, “Architecture on ancient coins,” The British Museum Yearbook 1 (1976) 42, fig. 80; P. Baldassarri, op. cit., 69-84, figs. 1-8; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM82">82</a></p>
<p :class="{'activeClass': idClicked == NP3315 }" id="NP3315" >Dedication on the epistyle - - - IG II² 3173; SEG 47 (1997) no. 209</p>
<p :class="{'activeClass': idClicked == NP3316 }" id="NP3316" >Priest's theater seat IG II² 5114; SEG 47 (1997) no. 233</p>
<p :class="{'activeClass': idClicked == NP3317 }" id="NP3317" >ROMA and DEMOS and CHARITES: see Demos and Charites</p>
<p :class="{'activeClass': idClicked == NP3318 }" id="NP3318" >ROMAN AGORA, peristyle court, ca. 10 B.C., lower Akr. north slope (Plan 1 no. 67): Travlos, J. 1971, 28-36, figs. 38/45, 362 no. 79; Ameling, W. 1983, I, 7-8, 10-11; M.C. Hoff, The Roman Agora at Athens, Univ. Microfilms, 1988; see Chr. Mon. CM12*</p>
<p :class="{'activeClass': idClicked == NP3319 }" id="NP3319" >Dedication to Athena Archegetis on the west propylon, 11/10 or 10/9 B.C. (Plan 1 no. 68) - - - IG II² 3175; SEG 39 (1989) no. 213; Ameling, W. 1983, II, 43-44 no. 10</p>
<p :class="{'activeClass': idClicked == NP3320 }" id="NP3320" >Statue of Lucius Caesar, acroterion on the west propylon IG II² 3251; Hanson, C. and F. P. Johnson 1946, 397; M. C. Hoff, “An Equestrian Statue of Lucius Caesar in Athens Reconsidered,” AA 2001, 583-599, figs. 1-14</p>
<p :class="{'activeClass': idClicked == NP3321 }" id="NP3321" >Hadrian's Oil Law, 124/125 A.D., on a west propylon pier IG II² 1100; SEG 51 (2001) no. 98</p>
<p :class="{'activeClass': idClicked == NP3322 }" id="NP3322" >Spring house in the southern colonnade, late 1st c. B.C.: Walker, S. 1979b, 127-130</p>
<p :class="{'activeClass': idClicked == NP3323 }" id="NP3323" >Metope-triglyph frieze, reused in the bldg.: dated to the 5th c. B.C., H. S. Robinson, “The Tower of the Winds and the Roman Market-Place,” AJA 47 (1943) 302 with note 26; W. B. Dinsmoor, “The Temple of Ares and the Roman Agora,” AJA 47 (1943) 383-384; dated to the early 3rd c. B.C., Townsend, R. F. 1982, 23</p>
<p :class="{'activeClass': idClicked == NP3324 }" id="NP3324" >Christian graffiti, fish on the east propylon, cross monogram and dove on a column on the east side: A. K. Orlandos, «'Έκθεσις περί των άνασκαφών Βιβλιοθηκης Άδριανού και Ρωμαικής Άγοράς,» ΑΕ 1964, parart. 58, figs. 110-111</p>
<p :class="{'activeClass': idClicked == NP3325 }" id="NP3325" >ROMAN, Stoa of: see Stoa of the Roman</p>
<p :class="{'activeClass': idClicked == NP3326 }" id="NP3326" >ROMANUS POPULUS, statue base, Asklepieion — IG II² 3447; Levensohn, M. and E. 1947, 71 no. 74 in fig. 1 cf. J. R. Fears, “Ο ΔΗΜΟΣ Ο ΡΩΜΑΙΩΝ - Genius Populi Romani,” Mnemosyne 31 (1978) 274-286</p>
<p :class="{'activeClass': idClicked == NP3327 }" id="NP3327" >ROYAL STOA: see Stoa Basileios</p>
<p :class="{'activeClass': idClicked == NP3328 }" id="NP3328" >SABAZIOS, cult: Cic. Leg. 11.15.37; H. Schaefer, RE I A2, 1920, col 1545 (Sabazios no. 12); Shear Jr., T. L. 1995, 175; see Zeus Zeu101*</p>
<p :class="{'activeClass': idClicked == NP3329 }" id="NP3329" >SABINA AUGUSTA, Hadrian's wife, statue base, Panagia Pyrgiotissa (Chr.Mon. CM77*) in the Stoa of Attalos IG II² 3387; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical evidence,” in Praktika 8th Congress 1984, 72; SEG 34 (1984) no. 183</p>
<p :class="{'activeClass': idClicked == NP3330 }" id="NP3330" >SACRED GATE: see Eridanos; Themistoklean City Wall Wal5*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM562">562</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM564">564</a></p>
<p :class="{'activeClass': idClicked == NP3331 }" id="NP3331" >SACRED HOLLOWS, ιεροί αύλώνες: Ath. V.189 C</p>
<p :class="{'activeClass': idClicked == NP3332 }" id="NP3332" >SACRED WAY from the Eleusinion on the Akr. northwest slope to Eleusis: Paus. 1.36.3. This road had different names depending on the function; three other names for the same route are listed below. Inside the City Wall the Sacred Way is wholly or partly coterminous with the Panathenaic Way q.v.; Travlos, J. 1971,159, 299, 302 (bibliography), 439, figs. 213 no. 237, 219 no. 237, 391 no. 175, 417 no. 175. No monograph on the Sacred Way has been published presenting the testimonia, the travellers' accounts and the excavations, although the Greek Archaeological Service has excavated the Sacred Way outside the City Wall at many different places and published highly informative reports, a representative one of which is cited here, O. Alexandri, «10. Ἱερὰ ὁδός,» Deltion 28 (1973) B1 Chr., 31 no. 10; Siewert, P. 1982, 39-41.</p>
<p :class="{'activeClass': idClicked == NP3333 }" id="NP3333" >Boundary stones for the Road to Eleusis; Ritchie Jr., C. E. 1985, 389-392 with fig. 63 and 409-418, pls. 75-76, figs. 68-69</p>
<p :class="{'activeClass': idClicked == NP3334 }" id="NP3334" >Pentelic marble, ca. 420 B.C., found in the Eridanos river bed - - - IG l31096; Knigge, U. 1991, 150, fig. 146</p>
<p :class="{'activeClass': idClicked == NP3335 }" id="NP3335" >Poros limestone, mid-5th c. B.C. ?, on the south side of the Sacred Way near the chapel of Hag. Triada (Chr. Mon. CM50*)- - -IG I³ 1095 = IG I² 881</p>
<p :class="{'activeClass': idClicked == NP3336 }" id="NP3336" >Hymettian marble, found 230 m. west of the Dipylon Gate IG II² 2624; Curtius, E. and J. A. Kaupert 1878, 14 col. 2 no. 2 with Blatt II</p>
<p :class="{'activeClass': idClicked == NP3337 }" id="NP3337" >Boundary stone of the Ancestral Highway, 4th c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203361" target="_blank">Agora I 3361</a>; Meritt, B. D. 1941, 40-41 no. 8, ph.; Nulton, P. E. 2000, 26-28</p>
<p :class="{'activeClass': idClicked == NP3338 }" id="NP3338" >Sacred Way for the Pythais procession to Delphi, boundary stone, 4th c. B.C., reused as a threshold block west of the north end of the Stoa of Attalos  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205476" target="_blank">Agora I 5476</a>; defining a section of the Sacred Way, Agora XIV, 118 note 5, pl. 64:d; Agora XIX, 29 H34, pl. 3; cf. Paus. 10.4.3, road from Athens to Delphi; J. McK. Camp et al., “An Athenian Dedication to Herakles at Panopeus,” Hesperia 66 (1997) 265-266 See also: Asty, herm milestones; Panathenaic Way; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM562">562</a></p>
<p :class="{'activeClass': idClicked == NP3339 }" id="NP3339" >SALAMIS, picture of the battle <JBcom>[supposedly]</JBcom> in the Stoa Poikile: schol. Gregory of Nazianos, Contra Iulianum I; Agora XIV, 92 with note 49</p>
<p :class="{'activeClass': idClicked == NP3340 }" id="NP3340" >SARAPION MONUMENT, tripod base reused for the statue of Quintus Statius Sarapion of Cholleidai, doctor, poet and Stoic philosopher originally from Hierapolis in Syria, dedicated by this grandson, ca. A.D. 145-165, in the Asklepieion - - - IG II² 1948+ <JBcom>[3704]</JBcom>; Aleshire, S. B. 1991, 49-74, fig. 1; Byrne, S. G. 2003, 441-442 no. 4 (the grandfather) and 442-443 no. 6 (the grandson); SEG 51 (2001) no. 204</p>
<p :class="{'activeClass': idClicked == NP3341 }" id="NP3341" >Front of the tripod base:</p>
<p :class="{'activeClass': idClicked == NP3342 }" id="NP3342" >1) First dedication, the original dedication ca. 87/88 - 91/92 A.D. by an unknown person in celebration of a choregic victory; for the revised date, see SEG 51 (2001) no. 204 third paragraph</p>
<p :class="{'activeClass': idClicked == NP3343 }" id="NP3343" >2) Second dedication, ca. A.D. 145-165, by Q. Statius Sarapion Cholleides in honour of his grandfather a) dedication of a statue of Sarapion Cholleides written above the original dedication b) paian of Sarapion written below the original dedication, reflecting “a religious ceremony at the time of the Plague of Antoninus,” J. H. Oliver, “The Sarapion Monument and the Paean of Sophocles,” Hesperia 5 (1936) 110</p>
<p :class="{'activeClass': idClicked == NP3344 }" id="NP3344" >Right side of the tripod base</p>
<p :class="{'activeClass': idClicked == NP3345 }" id="NP3345" >3) Third dedication, ca. 200-210 A.D., catalogue of the chorus that performed the paean inscribed on the left side of the tripod base</p>
<p :class="{'activeClass': idClicked == NP3346 }" id="NP3346" >Left side of the tripod base</p>
<p :class="{'activeClass': idClicked == NP3347 }" id="NP3347" >3) Third dedication continued: paean of Sophokles for Asklepios and Koronis, ca. A. D. 200-210: the earliest evidence for Sophokles' association with the Asklepieion, Aleshire, S. B. 1989, 9-10, 17</p>
<p :class="{'activeClass': idClicked == NP3348 }" id="NP3348" >SARAPIS: G. J. F. Kater-Sibbes, Preliminary Catalogue of Sarapis Monuments, Leiden, 1973, 85-87</p>
<p :class="{'activeClass': idClicked == NP3349 }" id="NP3349" >Base for bronze votive, Asklepieion - - - IG II² 4815; Walker, S. 1979a, 257 with note 72</p>
<p :class="{'activeClass': idClicked == NP3350 }" id="NP3350" >Altar or base, 1st c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206627" target="_blank">Agora I 6627</a>; Vidman, L. 1969, 10 no. 10</p>
<p :class="{'activeClass': idClicked == NP3351 }" id="NP3351" >Sanctuary: Paus. 1.18.4 (Pausanias has just seen the Prytaneion on the Akropolis north slope and descended to lower Athens); no evidence that the state cult of Sarapis in Athens existed before the 2nd c. B.C., Chr. Habicht, “Bemerkungen zum P. Haun.6,” ZPE 39 (1980) 4 with note 17; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM194">194</a></p>
<p :class="{'activeClass': idClicked == NP3352 }" id="NP3352" >Sculpture; </p>
<p :class="{'activeClass': idClicked == NP3353 }" id="NP3353" >Bust, mid-2nd C. A.C., northwest Athens, Sokratous and Armodiou Sts. (Plan 2 delta—4) - - - NM 3951; traces of yellow colour indicate an attempt to imitate gilding, P. Amandry, “Chronique des fouilles en 1948,” BCH 73 (1949) 517 no. 2</p>
<p :class="{'activeClass': idClicked == NP3354 }" id="NP3354" >Gilded bust - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20355" target="_blank">Agora S 355</a>; Shear, T. L. 1935, 397-398, fig. 24</p>
<p :class="{'activeClass': idClicked == NP3355 }" id="NP3355" >Head of Sarapis with cornucopia, Late Hellenistic(?), Syntagma Metro Station - - - Inv. no. M 4305; Metro Excavations 1992-1997, 186 no. 172 (P. Zoridis)</p>
<p :class="{'activeClass': idClicked == NP3356 }" id="NP3356" >Foot of Sarapis with reliefs of Anubis, Harpokrates, Kerberos and crocodile, 2nd c. A.C.? NM _; S. Dow and F. S. Upson, “The Foot of Sarapis,,” Hesperia 13 (1944) 65-70 no. 2, figs. 5-7. Not necessarily from Athens, place of discovery not recorded See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM525">525</a></p>
<p :class="{'activeClass': idClicked == NP3357 }" id="NP3357" >Sarapis and The Egyptian Gods, dedication IG II² 4872; Vidman, L. 1969, 18 no. 32</p>
<p :class="{'activeClass': idClicked == NP3358 }" id="NP3358" >Sarapis and Isis at their temple, terracotta lamp disk relief, 3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/l%204274" target="_blank">Agora L 4274</a>, Agora VII, 121-122 no. 805, pl. 18</p>
<p :class="{'activeClass': idClicked == NP3359 }" id="NP3359" >Sarapis and Isis, herm dedication, portrait of a kanephoros of Sarapis and Isis, 1st-2nd c. A.C., found 1843 in digging foundations of the Metropolis (Plan 1 beta/gamma—8 no. 121) IG II² 3565; Vidman, L. 1969, 13 no. 20</p>
<p :class="{'activeClass': idClicked == NP3360 }" id="NP3360" >Sarapis, Isis, (Anubis), dedication, after 200 B.C., reused in the floor of Hag. Konstantinos Saita, Akr. north slope on the north side of Lysiou St. between Mnesikleous and Erechtheos Sts. (Plan 1 delta—7) — IG II² 4692; J.-C. Grenier, Anubis alexandrin et romain, Leiden, 1977, 109 no. 80 See also: Isis, Sarapis, Anubis etc.</p>
<p :class="{'activeClass': idClicked == NP3361 }" id="NP3361" >SARMATIAN cuirass made of mares' hooves, Asklepieion: Paus. 1.21.5-6</p>
<p :class="{'activeClass': idClicked == NP3362 }" id="NP3362" >SATYRS<br>Statue by Lysippos: Plin. Nat. 34.64</p>
<p :class="{'activeClass': idClicked == NP3364 }" id="NP3364" >Statue by Praxiteles on ancient Tripodes Street: Paus. 1.20.1-2; Ath. 13 591b; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.,” Jahrbuch 88 (1973) 194-197, 199</p>
<p :class="{'activeClass': idClicked == NP3365 }" id="NP3365" >Statue of a satyr boy in the temple of Dionysos on ancient Tripodes Street: Paus. 1.20.2; A. H. Borbein, op. cit, 196-197, 199 note 674</p>
<p :class="{'activeClass': idClicked == NP3366 }" id="NP3366" >Statue of a satyr boy holding a syrinx and a goat, ca. 230-240 A.D., smashed in 73 pieces and thrown down a well <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20221" target="_blank">Agora S 221</a>; T. L. Shear, “The Sculpture,” Hesperia 2 (1933) 536-541, figs. 20-24; the revised date was contributed by Elizabeth Walters</p>
<p :class="{'activeClass': idClicked == NP3367 }" id="NP3367" >SCYTHIAN (?) ARCHER-POLICE, places where they lived in the Agora and on the Areopagus: schol. Ar. Ach. 54; cf. L. Robert, Hellenica XI-XII, 1960, 271 note 2</p>
<p :class="{'activeClass': idClicked == NP3368 }" id="NP3368" >SEBASTE: see Livia, Hygieia Sebaste</p>
<p :class="{'activeClass': idClicked == NP3369 }" id="NP3369" >SELENE: Cult: schol. S. OC 100; F GR HIST III B, 101, Philochoros 328 F 12</p>
<p :class="{'activeClass': idClicked == NP3371 }" id="NP3371" >Unfinished relief, Areopagus north slope - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20857" target="_blank">Agora S 857</a>; Agora XXIV, 37 note 146, pl. 25:c See also: Antonius, statues of Mark Antony and Cleopatra</p>
<p :class="{'activeClass': idClicked == NP3372 }" id="NP3372" >SELEUKOS I NIKATOR, bronze statue near the Stoa Poikile, 281 B.C. (or earlier): Paus. 1.16.1; Habicht, Chr. 1989, 9; Kotsidu, H. 2000, 73</p>
<p :class="{'activeClass': idClicked == NP3373 }" id="NP3373" >SEMNAI: Milchhöfer, A. 1891, 29 s.v. Erinyen</p>
<p :class="{'activeClass': idClicked == NP3374 }" id="NP3374" >Walled sanctuary on the Areopagus: Paus. 1.28.6-7; E. Wust, RE Suppl. VIII, 1956, col. 128-129 (Erinys)</p>
<p :class="{'activeClass': idClicked == NP3375 }" id="NP3375" >Altars (escharai): Paus. 1.28.6, 7.25.1 + 2</p>
<p :class="{'activeClass': idClicked == NP3376 }" id="NP3376" >Statues, two by Skopas, the one in the middle by Kalamis: Paus. 1.28.6 (sculptors not mentioned); A. F. Stewart, Skopas of Paros, Park Ridge, 1977, 111, 129-130 testimonia nos. 22-25; LIMC III, 1986, 827 no. 2, 839-840 commentary 1b, s.v. Erinys (H. Sarian)</p>
<p :class="{'activeClass': idClicked == NP3377 }" id="NP3377" >Thrones: A. Eu. 806</p>
<p :class="{'activeClass': idClicked == NP3378 }" id="NP3378" >Semnai at Kolonos Hippios, temple: Hypothesis to S. OC; Svoronos, J. N. 1911, 389 note 4; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM472">472</a></p>
<p :class="{'activeClass': idClicked == NP3379 }" id="NP3379" >SEMNAI THEAI, rooftile bearing a stamped inscription on the side reading Σεμνών Θεών, 3rd c. B.C., Lenormant St. near the intersection with Konstantinoupoleos Blvd. (Plan 2 gamma—3): “there is no doubt that it comes from the temenos of the Semnai at Kolonos Hippios,” according to T. Karagiorga-Stathakopouiou, «Δημοσία έργα και άνασκαφές στην Άθήνα τά τελευταία πέντε χρονιά,» HOROS 6 (1988) 98; SEG 47 (1997) no. 279</p>
<p :class="{'activeClass': idClicked == NP3380 }" id="NP3380" >SEPTIMIUS SEVERUS, statue base, no earlier than 209 A.D., Akr. — IG II² 3417; D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress, 70</p>
<p :class="{'activeClass': idClicked == NP3381 }" id="NP3381" >SEPTIMIUS SEVERUS and CARACALLA, dedication, ca. 211 A.D. — IG II² 3416+, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205047" target="_blank">Agora I 5047</a> +; J. H. Oliver, “Greek and Latin Inscriptions,” Hesperia 10 (1941) 251 no. 53, ph.; new discoveries by Chr. Habicht awaiting publication by D. J. Geaqan, SEG 35 (1985) no. 151</p>
<p :class="{'activeClass': idClicked == NP3382 }" id="NP3382" >SEPTIMIUS SEVERUS and GETA (in rasura), dedication before 209 A.D., Akr. — IG II² 3414; A. N. Oikonomides, “Five Athenian Inscriptions of the Emperor L. Septimius Severus,” 21 (1984) 181 no. 2; SEG 34 (1984) no. 185</p>
<p :class="{'activeClass': idClicked == NP3383 }" id="NP3383" >SERPENTZE WALL, Akropolis outwork on the south slope incorporating part of the Rizokastron Wall, built between 1670 and 1687, destroyed 1877: Tanoulas, T. 1987, 432-433, 450, figs. 8-9, 12, 23, 31-32, 59</p>
<p :class="{'activeClass': idClicked == NP3384 }" id="NP3384" >West flank from the Western Battery 1 (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM30">30</a>) to the southwest corner of the Odeion of Herodes Atticus</p>
<p :class="{'activeClass': idClicked == NP3385 }" id="NP3385" >South side incorporating the Odeion façade and the Stoa of Eumenes</p>
<p :class="{'activeClass': idClicked == NP3386 }" id="NP3386" >Tower or guardhouse at the east end of the Stoa of Eumenes with an entrance to the area enclosed by the Serpentze Wall</p>
<p :class="{'activeClass': idClicked == NP3387 }" id="NP3387" >East flank up to the Akropolis south wall below the Parthenon southeast corner with an entrance for a path along the upper part of the south slope</p>
<p :class="{'activeClass': idClicked == NP3388 }" id="NP3388" >SETINES, mediaeval name for Athens: earliest known use in a Catalan document of 1278, Van der Vin, J. P. A. 1980, 1,176 and II, 405 note 32</p>
<p :class="{'activeClass': idClicked == NP3389 }" id="NP3389" >SHIP WITH CARGO OF GRAIN, document relief, ca. 330 B.C. - - - Acr. 3703; IG II² 312; Lawton, C. L. 1995, 133 no. 118, pl. 62</p>
<p :class="{'activeClass': idClicked == NP3390 }" id="NP3390" >SIBYRTIOS' palaestra, visited by Alkibiades: Plu. Alc. 3; Delorme, J. 1960, 59, 261 note 1</p>
<p :class="{'activeClass': idClicked == NP3391 }" id="NP3391" >SIKELIA HILL: Paus. 8.11.12; F GR HIST III B, 230-231, Anonymer Perieget 369 F 1; very probably the present-day Sikelia Hill, south of the Ilissos (Plan 3 gamma—11), Judeich, W. 1931, 45 note 3 testimonia, 159 with note 3, 458 addendum to 158, Plan IV on the restored line of the Phaleric Wall south of the Hill of the Nymphs = site of the present-day Kallithea stadium; Conwell, D. A. 1933, 107, 202, 238-239, 247-269 passim; for traces of fortifications on present-day Sikelia Hill, see Curtius E. and J. A. Kaupert, 11 with plans I-II</p>
<p :class="{'activeClass': idClicked == NP3392 }" id="NP3392" >SILEN, limestone statue opposite Andokides' choregic tripod: Plu. Moralia 835 B</p>
<p :class="{'activeClass': idClicked == NP3393 }" id="NP3393" >SILENOS OF RHEGION, state burial monument, 433/32 B.C., found during the demolition of the church of Haghia Triada (Chr.Mon. CM50*) in the Kerameikos Exc. - -  - IG II² 5220 = IG I³ 1178; SEG 22 (1967) no. 65; Knigge, U. 1972, 589-591, figs. 7-9, 33-34; CEG, 1983, no. 12; Pritchett, W. K. 1998, 10</p>
<p :class="{'activeClass': idClicked == NP3394 }" id="NP3394" >SIMON THE COBBLER'S SHOP, ca. 450-400 B.C., southeast of the Tholos: D.L. 2.122; D. B. Thompson, “The House of Simon the Shoemaker,” Archaeology 13 (1960) 234-240 with plan and photos</p>
<p :class="{'activeClass': idClicked == NP3395 }" id="NP3395" >Fragment of kylix foot with Simon's name, graffito - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2022998" target="_blank">Agora P 22998</a>; Agora XIV, 173-174, pl. S8:d</p>
<p :class="{'activeClass': idClicked == NP3396 }" id="NP3396" >SKAMBONIDAI: Deme in the northern part of the city: IG I³ 244, a Sacred Law of the Skambonidai found in a house at the Hephaisteion; SEG 25 (1971) no. 42; Traill, J. S. 1986, 130 with Map; see Agora of the Skambonidai</p>
<p :class="{'activeClass': idClicked == NP3398 }" id="NP3398" >Trittys name: IG I³ 1117; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206699" target="_blank">Agora I 6699</a>; Siewert, P. 1982, 14; see Trittys Signposts no. 10</p>
<p :class="{'activeClass': idClicked == NP3399 }" id="NP3399" >SKIONIANS and their allies, captured shields in the Stoa Poikile: Paus. 1.15.4</p>
<p :class="{'activeClass': idClicked == NP3400 }" id="NP3400" >SKIRON and SKIROS: Frazer, J. G. 1913, II, 488-489; Kearns, E. 197-198</p>
<p :class="{'activeClass': idClicked == NP3401 }" id="NP3401" >Skiron, place along the Sacred Way named after Skiros: Paus. 1.36.4; Agora III, 222</p>
<p :class="{'activeClass': idClicked == NP3402 }" id="NP3402" >Skiron, brook named after Skiros: Paus. 1.36.4</p>
<p :class="{'activeClass': idClicked == NP3403 }" id="NP3403" >Skiros, seer-hero from Dodona, grave on the Sacred Way: Paus. 1.36.4; F GR HIST III B, suppl. vol. I, 285-305 especially 289-291</p>
<p :class="{'activeClass': idClicked == NP3404 }" id="NP3404" >SKOUZE HILL, current name for the hill north northeast of Kolonos Hippios between Prevezis and Athamanias Sts. (Plan 3 beta—5): Svoronos, J. N. 1911, 389, 390, pls. 124-125; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM465">465</a> (Demeter Euchloos)</p>
<p :class="{'activeClass': idClicked == NP3405 }" id="NP3405" >SMINTHIOS: see Apollo Apo78*</p>
<p :class="{'activeClass': idClicked == NP3406 }" id="NP3406" >SNAKE: Snake cult in the Erechtheion, celebrated with the Epimenia on the Noumenia: Hdt. 8.41; J. D. Mikalson, “The Noumena and Epimenia in Athens,” HThR 65 (1972) 291-296</p>
<p :class="{'activeClass': idClicked == NP3408 }" id="NP3408" >Snake goddess, terracotta plaque, mid-7th c. B.C., found in a deposit of partly burned offerings, animal bones, pottery, terracotta shields, chariot groups, loomweights, spindle whorls, above the ruins of the Geometric Oval Enclosure (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM316">316</a>), at the foot of the Areopagus - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%20175" target="_blank">Agora T 175</a>; D. Burr, “A Geometric House and a Proto-Attic Votive Deposit,” Hesperia 2 (1933) 604-605 no. 277, figs. 72-73 (the plaque) and 568-621, figs. 27-87 (the deposit); Agora VIII, 87 no. 43, pl. 30; perhaps Athena, LIMC II, 1984, 960 no. 27, ph. s.v. Athena (P. Demargne)</p>
<p :class="{'activeClass': idClicked == NP3409 }" id="NP3409" >Snake coiling around a tree trunk, Roman pd., found at the Olympieion, placed in front of the Akropolis entrance in 1862 - - - Acr. 6510; A. S. Rousopoulos, «Ελληνικάι έπιγραφαι,» AE 1862, 50 no. 6; <JBcom>[mistakenly]</JBcom> attributed to the Parthenon west pediment, F. Brommer, Die Skulpturen der Parthenon-Giebel, Mainz, 1963, 41, pl. 102</p>
<p :class="{'activeClass': idClicked == NP3410 }" id="NP3410" >Snake and dedicator Silon on sandal, relief, south of the Theater, south of the Military Hospital (= Center for Acropolis Studies, Plan 2 eta—5) - - - NM 2565, IG II² 4423; SEG 31 (1981) no. 176; Kaltsas, N. 2002, 216 no. 443, ph.</p>
<p :class="{'activeClass': idClicked == NP3412 }" id="NP3412" >Snake coiled around an altar, relief, Roman pd., lower Akr. south slope, at the intersection of Rovertou Galli and Propylaion Sts. (Plan 2 eta—4): O. Alexandri, «71. Προπυλαίων - Ροβ. Γκάλλι,» Deltion 23 (1968) B1 Chr., 89 no. 71, fig. 1 no. 74, pl. 50</p>
<p :class="{'activeClass': idClicked == NP3413 }" id="NP3413" >SOKRATES, son of Sophroniskos: 	House: Him. Or. 64.3</p>
<p :class="{'activeClass': idClicked == NP3415 }" id="NP3415" >Altar for Sokrates' sacrifices at home: X. Mem. 1.1.2</p>
<p :class="{'activeClass': idClicked == NP3416 }" id="NP3416" >Sokrateion, Sokrates' grave stele near a spring on the road from Peiraeus to Athens: Marin. Procl. 10; K. Giannoulidou, «Πού εκειτο το Σωκρατειον;» Platon 31 (1979) 123-127; Camp, J. McK. II 1980, 326-327</p>
<p :class="{'activeClass': idClicked == NP3417 }" id="NP3417" >Socratis templum: see Andronikos Kyrrhestes, horologion</p>
<p :class="{'activeClass': idClicked == NP3418 }" id="NP3418" >Sokrates' prison: see Desmoterion; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM242">242</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM308">308</a>-309</p>
<p :class="{'activeClass': idClicked == NP3419 }" id="NP3419" >Bronze statue by Boutes reported by Philochoros: Academicorum philosophorum Index Herculanensis, col. 2, lines 4-35; earliest portrait of Sokrates, (not Isokrates q.v., pace K. Gaiser) erected by Plato and his followers ca. 380-370 B.C. in the sanctuary of the Muses in the Academy, E. Voutiras, “Sokrates in der Akademie: Die früheste bezeugte Philosophenstatue,” AM 109 (1994) 146-161; SEG 46 (1996) no. 2378</p>
<p :class="{'activeClass': idClicked == NP3420 }" id="NP3420" >Bronze statue by Lysippos in the Pompeion: D.L. 2.43; E. Voutiras, op. cit, 137-146</p>
<p :class="{'activeClass': idClicked == NP3421 }" id="NP3421" >Portrait head - - - Acr. 2285; Richter, G. M. A. 1965, I, 111 no. 10</p>
<p :class="{'activeClass': idClicked == NP3422 }" id="NP3422" >Portrait head - - - Boston MFA Acc. no. 60.45; Comstock, Μ. B. and C. C. Vermeule 1976, no. 120, ph.</p>
<p :class="{'activeClass': idClicked == NP3423 }" id="NP3423" >Herm, unfinished, findspot unknown NM; Richter, G. M. A. 1965, 1,115 no. 26, fig. 527</p>
<p :class="{'activeClass': idClicked == NP3424 }" id="NP3424" >Unfinished bust, 3rd c. A.C. found at 31 Ermou St. near the intersection with</p>
<p :class="{'activeClass': idClicked == NP3425 }" id="NP3425" >Phokionos St. (Plan 1 beta—8/9) 3rd Ephor. inv. no. 491; Daux, G. 1961, 613, pl. 19; Dontas, G. 1969, 73-74, fig. 2; Trianti, I. 2002, 160-162, figs. 11-13, 16-17; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM510B">510B</a></p>
<p :class="{'activeClass': idClicked == NP3426 }" id="NP3426" >Statuette, late Hellenistic, southwest room of the Poros Bldg. Annex (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM308">308</a>) <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201413" target="_blank">Agora S 1413</a>; this <JBcom>[mingy]</JBcom> statuette <JBcom>[questionably]</JBcom> interpreted as evidence supporting the identification of the Poros Building in the Agora Excavations as Sokrates' Prison, Vanderpool, E. 1980, 21, fig. 12; cf. Desmoterion See also: Demos and Charites, IG II² 4775 </p>
<p :class="{'activeClass': idClicked == NP3427 }" id="NP3427" >SOL INVICTUS: see Mithras, relief dedicated to Helios Mithras Aniketos </p>
<p :class="{'activeClass': idClicked == NP3428 }" id="NP3428" >SOLON: State grave mon. at the City Wall Gate: Ael. VH 8.16; perhaps a cenotaph, Clairmont, C. 1983, 8 with notes 2-3; Kerameikos IX, 11 note 26</p>
<p :class="{'activeClass': idClicked == NP3430 }" id="NP3430" >Solon's laws inscribed on axones, on kyrbeis, and on marble: Stroud, R. S. 1979, passim; SEG 51 (2001) no. 2 <br>On axones, kept first on the Akropolis, then in the prytaneion: Poll. 8.128; Plu. Sol. 25.1; Paus. 1.18.3; Stroud, R. S. 1979, passim especially 12-13, 16-17, 19-20, 22, 33-34, 35, 41-44 <br>On kyrbeis: Plu. Sol. 25.1; Stroud, R. S. 1979, passim especially 19-20, 41-44, 47-60 <br>On marble: ibid., 8, 10, 42, 44</p>
<p :class="{'activeClass': idClicked == NP3434 }" id="NP3434" >Bronze statue in front of the Stoa Poikile: Paus. 1.16.1; Agora III, 216; Richter, G. M. A. 1972, II, 204-205</p>
<p :class="{'activeClass': idClicked == NP3435 }" id="NP3435" >SOPHOKLES: Grave monument with siren or bronze swallow on the road to Dekeleia, 11 stades from the City Wail: Vita Soph. 15; Frazer, J. G. 1913, II, 229</p>
<p :class="{'activeClass': idClicked == NP3437 }" id="NP3437" >House in which Sophokles received Asklepios, probably where he set up an altar to him: EM s.v. Δεξίον; Ferguson, W. S. 1944, 90-91; Kearns, E. 1989, 154-155 s.v. Dexion; Beschi, L. 2002, 25; cf. Asklepios Ask41*; cf. SEG 47 (1997) no. 213</p>
<p :class="{'activeClass': idClicked == NP3438 }" id="NP3438" >Picture in the Stoa Poikile, Sophokles playing the kithara: Vita Soph. 5; Agora ill, 42-43 no. 90; Beschi, L. 1967/68a, 425-426</p>
<p :class="{'activeClass': idClicked == NP3439 }" id="NP3439" >Sanctuary for the Hero Sophokles named Dexion: IG II² 1252 line 16; Aleshire, S. B. 1989, 9-11; eadem, 1991, 238 A 12; SEG 49 (1999) no. 159</p>
<p :class="{'activeClass': idClicked == NP3440 }" id="NP3440" >Statue set up by Iophon: Vita Soph. 11; Richter, G. M. A. 1972, II, 205</p>
<p :class="{'activeClass': idClicked == NP3441 }" id="NP3441" >Bronze statue in the theater: Paus. 1.21.1; Gauer, W. 1968b, 133, 135 See also: Amynos; Asklepios Ask41*; Sarapion Mon.</p>
<p :class="{'activeClass': idClicked == NP3442 }" id="NP3442" >SOSANDRA, statue by Kalamis on the Akr.: Lucian, Im. 4+6, DMeretr. 3.2; LIMC II, 1986, 23 no. 147, s.v. Aphrodite (A. Delivorrias et. al.)</p>
<p :class="{'activeClass': idClicked == NP3443 }" id="NP3443" >SOTER, SOTEIRA: see Artemis Art3*, Art46*-48*; Asklepios Ask5*, Ask7*; Zeus Zeu102*, Zeu103*</p>
<p :class="{'activeClass': idClicked == NP3444 }" id="NP3444" >SOTERES: see Antigonos I and Demetrios Poliorketes</p>
<p :class="{'activeClass': idClicked == NP3445 }" id="NP3445" >SPARTOKOS II AND PAIRISADES I of the Bosporan kingdom, gold wreaths awarded by the people of Athens in 346 B.C. and dedicated by them to Athena Polias with an inscription: IG II² 212 lines 33-39; S. M. Burstein, “I.G. II2 653, Demosthenes and Athenian Relations with Bosporus in the Fourth Century B.C.,” Historia 27 (1978) 428-436; SEG 36 (1986) no. 148; Harris, D. 1995, 104-105; for NM 1471, the document relief of IG II² 212, found in the Peiraeus, see C. L. Lawton, op. cit. (under Arybbas), 120, fig. 5</p>
<p :class="{'activeClass': idClicked == NP3446 }" id="NP3446" >SPARTOKOS III, king of the Bosporan kingdom, bronze statues to be set up in the Agora beside those of his ancestors and another on the Akropolis, awarded by an honorary decree, 284 B.C.: IG II² 653 lines 40-42; S. M. Burstein, op. cit. (Spartokos II), 428-436; Schenkungen hellenistischer Herrscher, Part I, 1995, 74-77; SEG 47 (1997) no. 131; Kotsidu, H. 2000, 93-94</p>
<p :class="{'activeClass': idClicked == NP3447 }" id="NP3447" >SPHENDONAI, place-name: F GR HIST III B, 79-80, Phanodemos, 325 F 4 with suppl. vol. I, 180</p>
<p :class="{'activeClass': idClicked == NP3448 }" id="NP3448" >SPHERE, extremely heavy bronze sphere on the Akropolis which Hieronymus (St Jerome) was barely able to move: Hieronymous in Zachar. 111.12, p. 896; I. Opelt, “Der ‘Hebestein' Jerusalem und eine Hebekugel auf der Akropolis von Athen in der Deutung des Hieronymus von Sach. 12, 1/3,” in Vivarium: Festschrift Theodor Klauser zum 90. Geburtstag (Jahrbuch fur Antike und Christentum, Erganzungsband II, 1984), Munster Westfalen, 288, 293-294</p>
<p :class="{'activeClass': idClicked == NP3449 }" id="NP3449" >SPHINX, found east of the Parthenon - - - Acr. 632; <JBcom>[doubtably]</JBcom> assigned to an acroterion on the Mid-6th c. Temple of Athena (Athena Ath19*), Korres, M. 1997, 234, fig. 7</p>
<p :class="{'activeClass': idClicked == NP3450 }" id="NP3450" >SPHYROS (?), an uninscribed relief of a young man leaning on a herm, with a hammer and two chisels below, Asklepieion NM 1353; <JBcom>[implausibly]</JBcom> identified by Svoronos as Sphyros, son of Machaon, the surgeon hero, Svoronos, J. M. 1908, 272-274, no. 50, pl.46; <JBcom>[correctly]</JBcom> identified as a grave relief, Conze, A. 1893-1922, vol. IV, no. 2021, pl. 439</p>
<p :class="{'activeClass': idClicked == NP3451 }" id="NP3451" >SPLANCHNOPTES, a bronze statue representing a slave of Perikles roasting innards, by Styppax, on the Akropolis (?): Plin. Nat 22.44, 34.81; N. Robertson, «Σπλαγχνοπτης,» ZPE 127 (1999) 175-179; <JBcom>[the Akropolis site though unattested is inherently probable]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3452 }" id="NP3452" >SPOUDAIOS (?) DAIMON, on the Akr.: Paus. 1.24.3; Frazer, J. G. 1913, I, 564; Pritchett, W. K. 1998, 58-59</p>
<p :class="{'activeClass': idClicked == NP3453 }" id="NP3453" >STACHTOTHIKI, called Cinderhill by Gell, a mediaeval place-name still in use for a place around the Dipylon Gate and Pompeion, formerly the site of two mounds of dumped ashes from soap factories: Biris, K. 1971, 101 s.v. Σταχτοθήκη with plan at end</p>
<p :class="{'activeClass': idClicked == NP3454 }" id="NP3454" >STATE BURIAL GROUND: see Demosion Sema</p>
<p :class="{'activeClass': idClicked == NP3455 }" id="NP3455" >STATE FESTIVAL CALENDAR FRIEZE, from an unknown building, mid-2nd-3rd c. A.C., built over the west door of Panagia Gorgoepekoos (Chr. Mon. CM75*), some reliefs obliterated by Christian crosses: <JBcom>[incorrectly]</JBcom> assigned to the 1st c. B.C., H. Gundel, REX A, 1972, col. 623 no. 36 (Zodiacus); <JBcom>[mistakenly]</JBcom> considered Late Hellenistic, LIMC VI, 1992, 482-483 no. 2, 492 under Athens, 498 col. 1, ph., s.v. Menses (D. Parrish); see Panathenaic Ship relief</p>
<p :class="{'activeClass': idClicked == NP3456 }" id="NP3456" >STEPHANEPHOROS: Kearns, E. 1989,198</p>
<p :class="{'activeClass': idClicked == NP3457 }" id="NP3457" >Heroon: Harp, s.v.; F GR HIST III B, 43, Hellanikos 323 a F 9 with suppl. vol. I, 31-32</p>
<p :class="{'activeClass': idClicked == NP3458 }" id="NP3458" >Theater seat? IG II² 5127 (restored reading)</p>
<p :class="{'activeClass': idClicked == NP3459 }" id="NP3459" >STOA ALPHITOPOLIS: Ar. Ec. 686; Agora III, Index s.v. Stoas: Alphitopolis; Agora XIV, 76 note 216, 82 note 3, 172 note 20; Stroud, R. S. 1998, 93 note 19</p>
<p :class="{'activeClass': idClicked == NP3460 }" id="NP3460" >STOA BASILEIOS (Royal Stoa), 5th c. B:C.: "first on the right in the Kerameikos": Paus.1.3.1 + 1.3.2 + 1.14.6; Agora III, 21-25 and Index s.v. Stoas: Basileios; Kuhn, G. 1985, 200-226, figs. 7-26; Camp. J. McK. 1986, 53-55, 57, fig. 5 no. 39, figs. 21, 32-36, 40-41, 55, 66, 71, 75-76, 129, 139, 152-153; T. L. Shear, Jr., “The Persian Destruction of Athens: Evidence from Agora Deposits,” Hesperia 62 (1993) 402, 418, 427-429, fig. 1; <JBcom>[doubtably]</JBcom> supposed to have been renamed the Stoa of the Herms, “N. Robertson,” The Stoa of the Herms,” ZPE 127 (1999) 167-174; <JBcom>[the Stoa was constructed after 480 B.C., probably in the second half of the century]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3461 }" id="NP3461" >Terracotta acroteria groups, Theseus and Skiron, Hemera and Kephalos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%201261" target="_blank">Agora T 1261</a>+; Paus. 1.3.1; <JBcom>[with healthy skepticism]</JBcom> questioning the identification of the terracotta fragments with the acroteria seen by Pausanias on the roof of the Stoa Basileios, R. Nicholls, “Architectural Terracotta Sculpture from the Athenian Agora,” Hesperia 39 (1970) 120-123, C1-C3, pl. 25; Agora XIV, 85 with note 10; Brommer, F. 1982, 17-18, 147; Danner, P. 1989, 22 nos. 140-141</p>
<p :class="{'activeClass': idClicked == NP3462 }" id="NP3462" >State Sacrificial Calendar, 410-404 B.C. - - - IG I³ 236-241 = IG I² 843-845, IG II² 1357, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20251" target="_blank">Agora I 251</a>, I 7471 +; Stroud, R. 1979, 8-10 with bibl. note 25, 11, 14-17</p>
<p :class="{'activeClass': idClicked == NP3463 }" id="NP3463" >Thrones: Agora Guide 1990, 82</p>
<p :class="{'activeClass': idClicked == NP3464 }" id="NP3464" >Two pairs of limestone thrones, reused: Shear Jr., T. L. 1975, 367</p>
<p :class="{'activeClass': idClicked == NP3465 }" id="NP3465" >Marble throne frags. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203874" target="_blank">Agora A 3874-3877</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM3881">3881</a>-3882, 4017, 4025-34 +</p>
<p :class="{'activeClass': idClicked == NP3466 }" id="NP3466" >STOA OF THE ROMAN, alternatively called Stoa of Rhomaios, containing an armory: IG II² 958 line 29; Agora III, 47; Agora XIV, 82 note 3; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM209">209</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM211">211</a></p>
<p :class="{'activeClass': idClicked == NP3467 }" id="NP3467" >STOA POIKILE, also called Peisianakteios Stoa, 475-460 B.C.: Paus. 1.15-16.1, 5.11.6; Agora III, 31-45 and Index s.v. Stoas: Poikile; Di Cesare, R. 2002, 43-47; the site is still uncertain; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM351">351</a>-<a v-on:click.prevent="replaceLink"   href="NNM.html#NNM352">352</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM378">378</a>-379</p>
<p :class="{'activeClass': idClicked == NP3468 }" id="NP3468" >Altar surrounded by a circular wooden enclosure in the stoa: D.L. 7.14; Agora III, no. 64</p>
<p :class="{'activeClass': idClicked == NP3469 }" id="NP3469" >STOICS, CYNICS and EPICUREANS?, names of two, probably three philosophical schools inscribed in the late 4th c. A.C. on three reused architectural blocks: perhaps labels in a library, Agora XXIV, 61-63, pl. 47:a,c; SEG 38 (1988) no. 196; Sironen, E. 1997, 114-115 no. 39; for the date and letter carver, see M. Heil, “Panathenius, Statthalter von Griechenland,” Klio 79 (1997) vol. 2, 469 note 10, 477 note 65</p>
<p :class="{'activeClass': idClicked == NP3470 }" id="NP3470" >Of the Stoics (with a cross neatly outlined between 5th and 6th letters of inscr.) inscribed on a reused door jamb in the church of Hag. Dynamis, Metropoleos St. - - - EM 70</p>
<p :class="{'activeClass': idClicked == NP3471 }" id="NP3471" >Of the Cynics inscribed on a reused lintel, Akr. north slope, Hag. Nikolaos (Seraphim) (Plan 1 epsilon—5, no. 105) - - - IG II² 5184</p>
<p :class="{'activeClass': idClicked == NP3472 }" id="NP3472" >Of the [Epicureians inscribed on a reused door jamb, fragment, Asklepieion - - - 1st Ephor. NK 61; Levenson, E. and M. 1947, 67 no. 14, fig. 1 no. 14</p>
<p :class="{'activeClass': idClicked == NP3473 }" id="NP3473" >STRATEGEION: IG II² 500 lines 39-40; Agora 111, 174-177; probably the large 5th c. B.C. building southwest of the Tholos, Agora XIV, 73, pls. 5-8; Freeden, J. von 1983, 12 with note 55, 174-175</p>
<p :class="{'activeClass': idClicked == NP3474 }" id="NP3474" >Honorary decree for the taxiarchs to be set up in the Strategeion, 281/280 B.C., found in the wall of a Byzantine well east of the Tholos <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20863" target="_blank">Agora I 863</a>; Agora XVI, no. 182, 260-262, commentary on line 30</p>
<p :class="{'activeClass': idClicked == NP3475 }" id="NP3475" >STRATEGOS, theater throne - - - IG II² 5030; Maass, M. 1972, 113-114, pl. VIII; IG II² 5031 on the backrest refers to a curial meeting of the Assembly, Arist. Ath. 43.4 See also: Heroes Hro23*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM317">317</a></p>
<p :class="{'activeClass': idClicked == NP3476 }" id="NP3476" >STRATIOS: see Zeus Zeu104*-107*</p>
<p :class="{'activeClass': idClicked == NP3477 }" id="NP3477" >STRATON, (Marcus Aurelius Straton son of Menestheus) and his son age 2, grave monument <br>1st use: column drum of Hymettian marble <br>2nd use: grave mon. with reliefs of a youth in naiskos and a funeral banquet, 3rd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203205" target="_blank">Agora I 3205</a>, IG II² 6153; Meritt, B. D. 1961, 205-207 no. 1, pls. 33-34; SEG 21 (1965) no. 853; Agora XVII, 52 no. 133 <br>3rd use: altar support, Church of Panagia Vlassarou</p>
<p :class="{'activeClass': idClicked == NP3481 }" id="NP3481" >SUERIS, servant of the priestess Lysimache, statue base, 275-250 B.C., near the temple of Athena on the Akropolis: - - - IG II² 3464; Paus. 1.27.4; Lewis, D. M. 1955, 8 no. 3; SEG 51 (2001) no. 216</p>
<p :class="{'activeClass': idClicked == NP3482 }" id="NP3482" >SULLA = L. Cornelius L.f.P.n. Sulla Felix: Cylindrical statue base, 83 B.C.? - - - IG II² 4103; Mitsos, Μ. T. 1967, 14 no. 2, pl. 10; SEG 24 (1969) no. 214</p>
<p :class="{'activeClass': idClicked == NP3484 }" id="NP3484" >Sulla's catapult balls <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20703" target="_blank">Agora ST 703-708+</a>; Agora XIV, 1972, 71 and pl. 42:c</p>
<p :class="{'activeClass': idClicked == NP3485 }" id="NP3485" >SYMBOLON, name of a place near Andokides' house: Plu. Moralia 580 D; Agora III, no. 682; <JBcom>[the Symbolon is Theseus and Peirithoos' Covenant Place on the Akr. north slope, Paus. 1.18.4]</JBcom>; see Theseus and Peirithoos</p>
<p :class="{'activeClass': idClicked == NP3486 }" id="NP3486" >SYNESIOS: see Kasianos Antiochos</p>
<p :class="{'activeClass': idClicked == NP3487 }" id="NP3487" >SYNGENICON, picture of a large family group in Athens by Athenion: Plin. Nat. 35.134</p>
<p :class="{'activeClass': idClicked == NP3488 }" id="NP3488" >SYNHEDRION, name of bldgs. or places where officials met: Pl. Tht. 173 C-D; Agora III, 126-128; Agora XIV, 72 note 194; Agora XVI, commentaries on no. 175 lines 3-4, no. 181 lines 39-40 and no. 194 line 12</p>
<p :class="{'activeClass': idClicked == NP3489 }" id="NP3489" >SYNHEDRION OF THE SACRED GEROUSIA: Agora 11567+, IG II² 1064; Oliver, J. H. 1941b, 1, 4, 7, 38, 42-43; idem, “The Areopagus and the Whole City Honor M. Ulpius Eubiotus Leurus,” ZPE 38 (1980) 108-109 lines 4 + 29; SEG 30 (1980) no. 82</p>
<p :class="{'activeClass': idClicked == NP3490 }" id="NP3490" >SYNODOS: see Technitai of Dionysos; Zeus Zeu69*</p>
<p :class="{'activeClass': idClicked == NP3491 }" id="NP3491" >TALOS, Daidalos' nephew: see Kalos TAPOSEIRIAS: see Isis Taposeirias </p>
<p :class="{'activeClass': idClicked == NP3492 }" id="NP3492" >TARSIOS: see Apollo Apo79*</p>
<p :class="{'activeClass': idClicked == NP3493 }" id="NP3493" >TAUREAS: (Davies, J. K. 1971, 29), palaestra of Taureas visited by Sokrates, across from the sanct. of Basile: Pl. Chrm. 153 A; Wheeler, J. R. 1887, 45 with note 17; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM176">176</a></p>
<p :class="{'activeClass': idClicked == NP3494 }" id="NP3494" >TAUROBOLION altars found at Ermou and Normanou Sts.(Plan 1 alpha—4): R. Duthoy, The Taurobolium, Leiden, 1969, 1,11-12 no. 6; SEG 38 (1988) no. 189; D. Kinney, “The Iconography of the Ivory Diptych Nicomachorum - Symmachorum,” Jahrbuch fur Antike und Christentum, 37 (1994) 86-95</p>
<p :class="{'activeClass': idClicked == NP3495 }" id="NP3495" >Altar dedicated to Attis and Rhea by Archeleos, kleidouchos of Hera at Argos, 360s A.D. or later - - - NM 1746, IG II² 4841; Sironen, E. 1997, 92-95 no. 28</p>
<p :class="{'activeClass': idClicked == NP3496 }" id="NP3496" >Altar, dedicated by Mousonios, May 27, 387 A.D. NM 1747, IG II² 4842; Sironen, E. 1997, 95-96 no. 29</p>
<p :class="{'activeClass': idClicked == NP3497 }" id="NP3497" >TECHNITAI OF DIONYSOS: Pickard-Cambridge, A. 1968, 279-321 passim, 336; for a valuable work which appeared too late to use, see S. Aneziri, Die Vereine der dionysischen Techniten im Kontext der hellenistischen Gesellschaft (Historia Einzelschriften Heft 163), Stuttgart, 2003</p>
<p :class="{'activeClass': idClicked == NP3498 }" id="NP3498" >Bouleuterion by the gate of the Kerameikos not far from the horsemen: Philostr. VS 2.8.2; Agora III, 21 under no. 2; Aneziri, S. 2000, 268-269; <JBcom>[ the Pompeion may have been the Bouleuterion of the Technitai of Dionysos at one time]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3499 }" id="NP3499" >Personification of the Synodos of Technitai, gilded statue dedicated by the polis of the Delphians in Dionysos' courtyard: Fouilles de Delphes, III, 2, 50, no. 48 lines 41-45 See also: Amphiktyonic decree; Antinoos Choreios; Ariarathes V; Dionysos Dio15*, Dio46*-50* (the precinct of Dionysos Melpomenos owned by the Technitai)</p>
<p :class="{'activeClass': idClicked == NP3500 }" id="NP3500" >TELEIOS: see Zeus Zeu108*-109*</p>
<p :class="{'activeClass': idClicked == NP3501 }" id="NP3501" >TELEKLES, head of the Academy (with Euandros), grave monument, 167 B.C - - - IG II² 12764</p>
<p :class="{'activeClass': idClicked == NP3502 }" id="NP3502" >Reused after 27 B.C. for an altar of Augustus - - - IG II² 3224/5; A. Benjamin and A. E. Raubitschek, “Arae Augusti,” Hesperia 28 (1959) 80 no. 8, pl. 9; SEG 18 (1962) no. 80 (a)</p>
<p :class="{'activeClass': idClicked == NP3503 }" id="NP3503" >TELEPHANES, inscribed epistyle block from a large grave naiskos, 4th c. B.C., northeast of the Dipylon Gate, 12 Dipylou St. (Plan 2 epsilon—3) — IG II² 12778; may be the auletes, Telephanes of Megara, recorded on a choregic tripod base (IG II² 3093), Conze, A. 1893-1922, no. 1487, pl. 306 (drawing); Stupperich, R. 1977, 22-23 note 6; cf. SEG 48 (1998) no. 199; <JBcom>[perhaps a state burial monument]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3504 }" id="NP3504" >TELESPHOROS: S. Dow, “Healing Deities on Pentelikon,” Phoenix 36 (1982) 325-326; LIMC VII, 1994, 870-871 (introduction), 871 nos. 8-10 with ph. (plastic lamps from the Agora Excavations), 876-878 (commentary), s.v. Telesphoros (H. Ruhfel); Metro Excavations 1992-1997, 74-75 no. 47, ph. (H. Harami)</p>
<p :class="{'activeClass': idClicked == NP3505 }" id="NP3505" >Dedication on a base, Akr. South Slope - - - IG II² 4541</p>
<p :class="{'activeClass': idClicked == NP3506 }" id="NP3506" >Bronze statuette, mid-3rd C. A.C., from “a well connected with a small bathing establishment” in the Areopagus-Pnyx valley - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20884" target="_blank">Agora B 884</a>; H. A. Thompson, “Excavations in the Athenian Agora: 1949,” Hesperia 19 (1950) 332-333, pl. 106a; H. Ruhfel, op. cit., 872 no. 22, ph.</p>
<p :class="{'activeClass': idClicked == NP3507 }" id="NP3507" >Yellow marble statuette, early 3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202418" target="_blank">Agora S 2418</a>; H. Rühfel, op. cit., 872 no. 15 See also: Asklepios Ask40*, Ask43*</p>
<p :class="{'activeClass': idClicked == NP3508 }" id="NP3508" >TELESPHOROS, son of Eukarpos, grave relief, ca. 170 A.D. — IG II² 9898; NM 1775; epitaph referring to the war against the Costoboci, Walters, E. J. 1988, 45-46, pl. 32:d</p>
<p :class="{'activeClass': idClicked == NP3509 }" id="NP3509" >TETRAKEPHALOS: see Hermes Hrm30*</p>
<p :class="{'activeClass': idClicked == NP3510 }" id="NP3510" >THEA: see Agathe Thea; Nea Thea Aphrodite: → Drusilla; Nemesis</p>
<p :class="{'activeClass': idClicked == NP3511 }" id="NP3511" >THEA EPEKOOS, dedication on base - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20299" target="_blank">Agora I 299</a>, IG II² 4818A </p>
<p :class="{'activeClass': idClicked == NP3512 }" id="NP3512" >THEA ERGANE: see Athena Ath64*-65*</p>
<p :class="{'activeClass': idClicked == NP3513 }" id="NP3513" >THEAGES, son of Apollonios, painted image in gilded armor, awarded by decree, to be set up in the Gymnasium of Ptolemy - - - IG II² 1070 lines 7-9; Kotsidu, H. 2000, 89-90</p>
<p :class="{'activeClass': idClicked == NP3514 }" id="NP3514" >THEA KOLAINIS: see Artemis Art39*</p>
<p :class="{'activeClass': idClicked == NP3515 }" id="NP3515" >THEANO, theater seat - - - IG II² 5164 </p>
<p :class="{'activeClass': idClicked == NP3516 }" id="NP3516" >THELXINIA: see Hera Thelxinia </p>
<p :class="{'activeClass': idClicked == NP3517 }" id="NP3517" >THEMIS<br>Temple, Akr. South Slope: seen by Pausanias (1.22.1) on his way to the Akropolis after leaving the Asklepieion; temple facing southeast, west of the Asklepieion, 4th c. B.C., Walker, S. 1979a, 247 no. 8, 248, fig. 1</p>
<p :class="{'activeClass': idClicked == NP3519 }" id="NP3519" >Priestess' theater seat IG II² 5109 See also: Athena Ath114*; Chloe Themis; Ge Themis; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM341">341</a></p>
<p :class="{'activeClass': idClicked == NP3520 }" id="NP3520" >THEMISTOKLEAN CITY WALL (Plan 2): IG II² 463; Paus. 1.2.3 and 4, 1.20.6, 1.25.7 and 8; Milchhöfer, A. 1891, 78-81; Maier, F. G. 1959, 15-17, 48-67; idem 1961, passim; Judeich, W. 1931, 124-144, Plan I, Plan IV; Travlos, J. 1971, 158-161, figs. 28, 53, 130, 154, 213, 219, 221-226, 228, 230-231, 379-380, 391, 393, 417, 435, 438; Agora XVI, no. 109, 171-174</p>
<p :class="{'activeClass': idClicked == NP3521 }" id="NP3521" >Plans of the whole circuit: Plan 2; Deltion 38 (1983) B1 Chr., Plan Alpha; Travlos, J. 1988, fig. 29;</p>
<p :class="{'activeClass': idClicked == NP3522 }" id="NP3522" >Plan of the eastern and southeastern section in detail: Deltion 27 (1972) B1 Chr., 46 fig. 16</p>
<p :class="{'activeClass': idClicked == NP3523 }" id="NP3523" >Plan of the southeastern section in detail: Deltion 23 (1968) B1 Chr., fig. 16 Plan of the northern section in detail: Deltion 24 (1969) B1 Chr., 42 fig. 12</p>
<p :class="{'activeClass': idClicked == NP3524 }" id="NP3524" >1* Proteichisma (the outer line of defense): Knigge, U. 1974, 181-191; Knigge, U. 1991, 194 s.v. Proteichisma, figs. 48, 49*, 54, 62-63, 67-69, 165</p>
<p :class="{'activeClass': idClicked == NP3525 }" id="NP3525" >2* Taphros, defensive ditch: IG II² 1035 line 38; Y. Garlan, Recherches de poliorcetique grecque, Paris, 1974, 251-252, fig. 20; Freeden, J. van 1983, 158-159, 163, 177; see Athena Ath14*</p>
<p :class="{'activeClass': idClicked == NP3526 }" id="NP3526" >THEMISTOKLEAN CITY WALL (continued) and GATES, clockwise around the circuit, starting from Ermou St. in the northwest. The Sacred Gate and Dipylon Gate are ancient names; the other gates are named in terms of present-day streets and landmarks. This section occasionally includes references to the proteichisma and taphros</p>
<p :class="{'activeClass': idClicked == NP3527 }" id="NP3527" >3* From Ermou St. to a postern gate (Plan 2 epsilon—3): Knigge, U. 1991, 53-55, figs. 8-51, 163, 165</p>
<p :class="{'activeClass': idClicked == NP3528 }" id="NP3528" >4* Postern Gate: Noack, F. 1907, 142, 495-496 with figs. 3, 5-6 and pls. 10-12, Pforte B; cf. Euktemon's lodging house</p>
<p :class="{'activeClass': idClicked == NP3529 }" id="NP3529" >5* Sacred Gate: Plu. Sull. 14.3; perhaps the gate mentioned by Pausanias 1.2.4; Travlos, J. 1971, 159 (Sacred Gate), fig. 219 Gate III, figs. 223-224, 391, 393; Knigge, U. 1991, 56-67, figs. 1, 48, 53-56, 163-165; see also Eridanos, portcullis gate</p>
<p :class="{'activeClass': idClicked == NP3530 }" id="NP3530" >6* Dipylon Gate: IG II² 463 line 53; SEG 37 (1987) no. 85; Travlos, J. 1971, 159 (Dipylon Gate), fig. 219 Gate IV, figs. 391, 417; Knigge, U. 1991, 68-73, figs. 1, 48, 60-63, 165; Milchhöfer, A. 1891, 79-80; see Dipylon Gate; Kerameikai Gate; Thriasian Gate</p>
<p :class="{'activeClass': idClicked == NP3531 }" id="NP3531" >7* Late Hellenistic Arched Gate closing off the northwest end of the Dipylon courtyard: Gruben, G. 1964, 390; “seems to have been built . . . just before the Roman attack by Sulla in 86 B.C.,” Travlos, J. 1971, 159; excavations carried out by J. Stroszeck in 2003 confirm Travlos' conjecture; see Euegoros; Klegora</p>
<p :class="{'activeClass': idClicked == NP3532 }" id="NP3532" >8* Section of the taphros and of the proteichisma, intersection of Dipylou and Asomaton Sts.: I Tsirigotou-Drakotou, op. cit. (Demosion Sema Sem16*) 87-93, figs. 1-5 The taphros contained large fillings of the 1st c. A.C. and graves of the 1st and 2nd c. A.C. : op. cit., 89, figs. 2,4,5 This section of the proteichisma was constructed with buttresses at the end of the 4th c. B.C., heavily repaired in the 3rd c. A.C. <JBcom>[not a Valerian but a post-Herulian repair]</JBcom>, fortified with a tower in the time of Justinian: op. cit., 89-93, figs. 2-5</p>
<p :class="{'activeClass': idClicked == NP3533 }" id="NP3533" >9* Gate at Leokoriou and Dipylou Sts. (Plan 2 epsilon—3), the Hippades Gate q.v.: Alexandri, O. 1969, 41-45 no. 18, figs. 12, 15-17; <JBcom>[mistakenly]</JBcom> called Eriai Gate, Travlos, J. 1971, 159 (Eriai Gate), fig. 219 Gate V, fig. 318, fig. 417 Gate V</p>
<p :class="{'activeClass': idClicked == NP3534 }" id="NP3534" >10* Proteichisma and evidence for a gate at the northwest corner of Evripidou and Epikourou Sts. (Plan 2 delta—4): Alexandri, O. 1976, 30-32, fig. 2</p>
<p :class="{'activeClass': idClicked == NP3535 }" id="NP3535" >11* Gate near the intersection of Sapphous and Menandrou Sts. (Plan 2 delta—4): Alexandri, O. 1969, 70 no. 53, 42 fig. 12</p>
<p :class="{'activeClass': idClicked == NP3536 }" id="NP3536" >12* Gate east of Aiolou St. and north of Sophokleous St. (Plan 2 delta—5), almost certainly the Acharnian Gate: Travlos, J. 1971, 159, fig. 219 Gate VI; Touchais, G. 1979, 534-536, figs. 28-30</p>
<p :class="{'activeClass': idClicked == NP3537 }" id="NP3537" >13* Gate at 4 Dragatsaniou St. (Plan 2 delta—5): Travlos, J. 1971, 159 (Northeast Gate), fig. 219 Gate VII, fig. 230</p>
<p :class="{'activeClass': idClicked == NP3538 }" id="NP3538" >14* The Wall and proteichisma at Plateia Klafthmonos (Plan 2 epsilon—5): O. Alexandri, «Πλατεία Κλαυθμώνος,» Deltion 29 (1973/74) B1 Chr., 138-142, figs. 28-30, pls. 105-106, plan B nos. 26-27</p>
<p :class="{'activeClass': idClicked == NP3539 }" id="NP3539" >15* Wall, proteichisma, taphros and the conjectured site of a gate in the city block bounded by Mitropoleos, Voulis, Apollonos and Pentelis Sts. (Plan 2 zeta—5): Threpsiades, I. 1960, «Οδός Βουλής και Μητροπολεως,» 22-27 with plan (for “Petrakis” St. read “Pentelis” St.) and pls. 22-23; a gate, identified as the Diochares Gate, “should be in the unexcavated area at the southwest corner of the block at the juncture of Apollonos and Pentelis Sts., as indicated by the break in the line of the moat,” Travlos, J. 1971, 159-160 Gate VIII, fig. 219; the Diochares Gate, Ritchie Jr., C. E. 1989, 253-254; the inscription naming the Diochares Gate, IG II² 2495 lines 6-7, was found on the Akropolis not in the vicinity of this gate, pace Travlos, loc. cit.</p>
<p :class="{'activeClass': idClicked == NP3540 }" id="NP3540" >16* Proteichisma and taphros between Nikis and Skouphou Sts. (Plan 2 zeta—5): Alexandri, O. 1976, «Όδός Νίκης 30,» 37, fig. 4</p>
<p :class="{'activeClass': idClicked == NP3541 }" id="NP3541" >17* Gate west of Lamachou St. in block bounded by Lamachou, Philellinon, Kydathenaion and Nikis Sts. (Plan 2 zeta—5): the position of the as yet unexcavated gate deduced from the line of the road crossing the taphros, Alexandri, O. 1969, 53-55, figs. 21-22, plan 1 no. 27</p>
<p :class="{'activeClass': idClicked == NP3542 }" id="NP3542" >18* Gate north of the Olympieion, west of the Olympieion propylon (Plan 2 eta—6): <JBcom>[mistakenly]</JBcom> identified as the Hippades Gate, Travlos, J. 1971, 160 IX, 289, 402, figs. 219 Gate IX, 222, 380; Alabe, F. 1987, 133, 137; cf. Hippades Gate</p>
<p :class="{'activeClass': idClicked == NP3543 }" id="NP3543" >19* <JBcom>[Imaginary]</JBcom> line of the Themistoklean Wall restored by J. Travlos from the Olympieion south terrace retaining wall (starting at the 10th buttress from the east) all the way to the gate at 8 Iosiph ton Rogon St., <JBcom>[allegedly and non-evidentially]</JBcom> underlying the line of the so-called Valerian Wall: Travlos, J. 1970, 7 fig. 1; Travlos, J. 1971, 160 Gate X, figs. 154, 219, 221, 379, 380, 438; on March 18th, 2003, D. Kyriakou reported in a lecture to the Ελληνική Εταιρεία that excavations by the Greek Archaeological Service south of the Olympieion terrace wall found no trace of the Themistoklean City Wall below the line of the so-called Valerian Wall</p>
<p :class="{'activeClass': idClicked == NP3544 }" id="NP3544" >20* <JBcom>[Imaginary]</JBcom> gate in an <JBcom>[imaginary]</JBcom> line of the Themistoklean City Wall (no. Wal19* above), below the Olympieion south terrace retaining wall, south of the 10th buttress from the east (Plan 2 eta—6): <JBcom>[allegedly]</JBcom> beneath a gate in the so-called Valerian Wall, <JBcom>[arbitrarily]</JBcom> identified as both the Aigeus Gate and the Diomeian Gate (involved in Sokrates' route to Kynosarges), <JBcom>[allegedly]</JBcom> demolished in 86 B.C., Travlos, J. 1970, 6, fig 1 Gate X; Travlos, J. 1971, 112, 160 X, figs. 219, 221, 379 Gate X, 380</p>
<p :class="{'activeClass': idClicked == NP3545 }" id="NP3545" >21* Gate (?) at 8 Iosiph ton Rogon St. (Plan 2 eta—5): identified as both the Itonian Gate and the gate through which the road to Isthmonikos' Bath passed, Travlos, J. 1971, 160 Gate XI, figs. 219, 379, 380, 435</p>
<p :class="{'activeClass': idClicked == NP3546 }" id="NP3546" >22* Gate (?) at Vourvachi St. between Syngrou Blvd. and Iosiph ton Rogon St. (Plan 2 theta—5): restored across line of ancient street, Judeich, W. 1931, 142, Plan I G-7, Itonisches Thor (?)</p>
<p :class="{'activeClass': idClicked == NP3547 }" id="NP3547" >23* Wall and proteichisma at 23 Syngrou Blvd (Plan 2 theta—5): Karagiorga-Stathakopoulou, Th. 1978, 17-18 no. 19, fig. 1 no. 19, pl. 11 gamma</p>
<p :class="{'activeClass': idClicked == NP3548 }" id="NP3548" >24* Wall at 25 Syngrou Blvd. (Plan 2 theta—5): O. Alexandri, «Oδός Συγγρού 25,» Deltion 29 (1973-74) B1 Chr., 97-98, pl. 78 delta—zeta, plan A no. 30</p>
<p :class="{'activeClass': idClicked == NP3549 }" id="NP3549" >25* Gate <JBcom>[convincingly]</JBcom> conjectured at Phalirou St. just southwest of Donta St. (Plan 2 theta—4): given the <JBcom>[utterly confusing]</JBcom> modern name “Halade Gate” in the 1960s, Travlos, J. 1971,160 Gate XII “Halade Gate” 332, figs. 219, 379, 435; Conwell, D. H. 1933, 219-220; <JBcom>[this gate is almost certainly the one through which the Mystai go on their way to the sea (halade) mentioned in IG I³ 84 line 35]</JBcom>; see Halade Gate; Mystai Gate; cf. Themistoklean City Wall Wal28*</p>
<p :class="{'activeClass': idClicked == NP3550 }" id="NP3550" >26* Tower donated by leading citizens in 172/1 B.C., attested by an inscr. found near the City Wall on the road to Phaleron: IG II² 2331; Maier, F. G. 1959, 82-84 no. 17, fig. 24</p>
<p :class="{'activeClass': idClicked == NP3551 }" id="NP3551" >27* Wall, with part of a conglomerate masonry tower, and proteichisma running east-west at the northern corner of Veikou and Misaraliotou Sts. (Plan 2 theta—4): Alexandra, O. 1976, 27-29 with fig. 1 and pl. 32 gamma, 33</p>
<p :class="{'activeClass': idClicked == NP3552 }" id="NP3552" >27** Proteichisma, ring road and taphros, classical pd. at 5-7 Tsami Karatasou St., between Misaraliotou and Parthenonos Sts. (Plan 2 theta—4): E. Lygouri-Tolia, «Όδός Τσάμη Καρατάσου 5—7,» Deltion 44 (1989) B1 Chr. 27, fig. 2, Plan A no. 6</p>
<p :class="{'activeClass': idClicked == NP3553 }" id="NP3553" >27*** Wall with rectangular tower between Parthenonos and Erechtheiou Sts., preserved in the basement of Hotel Zafolia Divani: A. Liangouras, «Όδός Παρθενώνος 19-25,» Deltion 29 (1973/74) 44-47, figs. 7-9, pls. 52-53</p>
<p :class="{'activeClass': idClicked == NP3554 }" id="NP3554" >28* Gate at 25 Erechtheiou St. (Plan 2 theta—4): thought to be the Mystai Gate (q.v.), Stavropoulos, P. D. 1965, fig. 21 no. 22; Travlos, J. 1971, 160 <br>Gate XIII, South Gate, figs. 225-226, 219; put out of use after the mid-4th c. B.C., Brouskari, M. 1980, 14-16, 31, fig. 1, pl. 1; Conwell, D. H. 1993, 221-222; <JBcom>[even nowadays one can still see that the terrain slopes downward more sharply south of 25 Erechtheiou St. on the brow of the hill]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3555 }" id="NP3555" >29* Gate between the Phaleric Wall and the South Long Wall: Judeich, W. 1931, 140, Plan I C 7-8 "Melitisches Tor,” Plan IV; Travlos, J. 1971, fig. 219 (the unlabelled gate almost due west of the peak of Philopappos Hill]</p>
<p :class="{'activeClass': idClicked == NP3556 }" id="NP3556" >30* Gate between the South and North Long Walls: Judeich, W. 1931, 140, Plan I B-7, Plan IV</p>
<p :class="{'activeClass': idClicked == NP3557 }" id="NP3557" >31* Gate on the Hill of The Nymphs northwest slope near the intersection of Akamantos and Aktaiou Sts. (Plan 2 zeta—2): just north of the point where the North Long Wall starts, Judeich, W. 1931, 140, Plan I C-5; Travlos, J. 1971, 121, 159 (Demian Gate), fig. 219 Gate I; probably the Demian Gate q.v.</p>
<p :class="{'activeClass': idClicked == NP3558 }" id="NP3558" >32* Gate at 15 Erysichthonos St. south of Irakleidon St. (Plan 2 epsilon—2): B. Philippaki, «Έρυσίχθονος 15,» Deltion 21 (1966) B1 Chr., 55-57 no. 1, fig. 2, pl. 76; Travlos, J. 1971, 121, 159, fig. 219 Gate II, the Peiraic Gate; Conwell, D. H. 1993, 226-227 with note 25</p>
<p :class="{'activeClass': idClicked == NP3559 }" id="NP3559" >THEMISTOKLEAN CITY WALL IN LATER TIMES</p>
<p :class="{'activeClass': idClicked == NP3560 }" id="NP3560" >33* Hadrianic extension: schol. Aristid. Panath. III p. 201, 32 ff. (Dindorf) cited by Zahrnt, M. 1979, 393-394 with note 3; <JBcom>[the Post-Herulian extension of the Themistoklean City Wall (no. Wal35* below) was often thought to be Hadrianic]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3561 }" id="NP3561" >34* Reconstruction of the Themistoklean circuit, mid-3rd c. A.C., during the reign of Valerian: Zos. I.29; a <JBcom>[Post-Herulian]</JBcom> repair of the City Wall at Dipylou and Leokoriou Sts. using many inscribed gravestones, <JBcom>[mistakenly]</JBcom> assigned to the reign of Valerian., N. Kyparissis, «Άνασκαφή όδού Διπύλου,» Deltion 11 (1927/28) Parart. 56-58, figs. 14-15; Travlos, J. 1971, Index, 587 s.v. Valerian Wall</p>
<p :class="{'activeClass': idClicked == NP3562 }" id="NP3562" >34**<JBcom>[Heavily restored]</JBcom> line of the 3rd c. A.C. City Wall extending south from the Olympieion southern terrace retaining wall as far as the gate at 8 Iosiph ton Rogon St.: assigned to Valerian construction on a new line, I. Threpsiades - I Travlos, «Άνασκαφαι νοτίως τού Όλυμπιείου (1961),» Deltion 17 (1961/2) B1 Chr. 12-13, fig. 1, pl. 8 alpha; <JBcom>[implausibly]</JBcom> assigned to Valerian rebuilding on the old line of the Themistoklean circuit, Travlos, J. 1971, 160 Gate X, figs.154, 219, 221, 379, 380, 438; cf. Themistoklean Wall Wal20* above</p>
<p :class="{'activeClass': idClicked == NP3563 }" id="NP3563" >35* Post-Herulian Extension of the Themistoklean circuit, including much of the present National Gardens, after 267 A.D. (Plan 2 epsilon/eta—6/7): Judeich, W. 1931, Plan I “Hadrianische Stadtmauer; Travlos, J. 1978, fig. 29; the “Wall of Hadrian . . . close to Stadium St.,” the post-Herulian construction excellently described by A. M. Woodward, “Archaeology in Greece,” JHS 47 (1927) 252-253 fig. 4; first perceived as Post-Herulian construction, Thompson, H. A. 1936, 198 with note 2; <JBcom>[the last surviving section of the Post-Herulian Extension, reduced to rubble during the construction of the Metro 1992-1997, was on the northern side of the National Gardens]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3564 }" id="NP3564" >Foundations of a tower on Vas. Sophias Blvd. at Merlin St. opposite the National Gardens (Plan 2 epsilon—7): I. Threpsiades, «24. Λεωφόρος Βασιλίσσις Σοφίας,» AE 1971, Chr. 31 no. 24</p>
<p :class="{'activeClass': idClicked == NP3565 }" id="NP3565" >36* Bastion added to the City Wall in the Kerameikos Excavations just below Ermou St.: Kerameikos IX,1976, fig. 1 opposite the "M" of “Hermes Strasse”; <JBcom>[latest 4th c. A.C. or later]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3566 }" id="NP3566" >37* Towers added to the City Wall in latest 4th-6th c. A.C., usually ascribed to Justinianic restoration: Procop. Aed. 4.2.23; Parsons, A. W. 1943, 250 note 159; Thomson, H. A. and R. L. Scranton 1943, 373-376</p>
<p :class="{'activeClass': idClicked == NP3567 }" id="NP3567" >Tower at 15 Erysichthonos St.: assigned to the reign of Justinian, B. Philippaki, «1. Έρισίχθονος 15,» Deltion 21 (1966) B1 Chr., 57, fig. 1 no. 1, fig. 2</p>
<p :class="{'activeClass': idClicked == NP3568 }" id="NP3568" >Tower at 54 Irakleidon St.: E. Lygouri-Tolia, «Ανασκαφική έρευνα παρά την Πειραική πύλη,» ΑΑΑ 18 (1985) 137-142, figs. 1-4</p>
<p :class="{'activeClass': idClicked == NP3569 }" id="NP3569" >Tower at 28-32 Athan. Diakou St.: Alexandri, O. 1968, «22. Άθαν. Διάκου 28-32,» 53 no. 22, fig. 1 no. 24, figs. 15-16</p>
<p :class="{'activeClass': idClicked == NP3570 }" id="NP3570" >Tower south of the Olympieion precinct: I. Threpsiades - I Travlos, «Άνασκαφαι νοτίως τού Όλυμπιείου (1961),» Deltion 17 (1961/62) B1 Chr., 13 with fig. 1</p>
<p :class="{'activeClass': idClicked == NP3571 }" id="NP3571" >Tower at 6 Koryzi St.: Alexandri, O. 1968, «45. Κορυζή 6,» 67 no. 45, fig. 1 no. 47, figs. 26-27, pl. 34 epsilon See also no. 8* above</p>
<p :class="{'activeClass': idClicked == NP3572 }" id="NP3572" >THEMISTOKLES, son of Neokles: House in Melite, near the sanctuary of Artemis Aristoboule: Plu. Them. 22.2</p>
<p :class="{'activeClass': idClicked == NP3574 }" id="NP3574" >Inscribed plaque set up by Themistokles for a choregic victory with a drama by Phrynichos, 477/6 B.C.: Plu. Them. 5.4; Ghiron-Bastagne, P. 1976, 94-95</p>
<p :class="{'activeClass': idClicked == NP3575 }" id="NP3575" >Picture with a portrait of Themistokles dedicated by his sons in the Parthenon: Paus. 1.1.2; P. Amandry, “Themistocle a Melite,” in Orlandos Charisterion 1965-1968, IV, 277 note 27; <JBcom>[During his tour of the Peiraeus Pausanias mentions this portrait but when he reached the Parthenon which had recently been repaired after a fire he did not see it]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3576 }" id="NP3576" >Statuette (eikonion) in the temple of Artemis Aristoboule: Plu. Them. 22.1; Threpsiades, J. and E. Vanderpool 1964, 35-36; Gauer, W. 1968b, 140</p>
<p :class="{'activeClass': idClicked == NP3577 }" id="NP3577" >Themistokles on a galley at Salamis: bronze imperial coin, Thompson, M. 1982, 166, 167,168, pl. 28 no. 9 See also: Miltiades and Themistokles; Nikanor</p>
<p :class="{'activeClass': idClicked == NP3578 }" id="NP3578" >THEMISTOKLES, son of Poliarchos, grandson of Themistokles, grave on the Sacred Way: Paus. 1.37.1; Davies, J. K. 1971, 218</p>
<p :class="{'activeClass': idClicked == NP3579 }" id="NP3579" >THEODEKTES OF PHASELIS, the tragedian, grave with statue of Homer and other great poets, on the Sacred Way at the Kephisos crossing near the altar of Zeus Meilichios: Plu. Moralia 837 D; Paus. 1. 37.4; Further Greek Epigrams, 1981, 431-2 no. 125; Scholl, A. 1994, 252-254, fig. 9</p>
<p :class="{'activeClass': idClicked == NP3580 }" id="NP3580" >THEODOROS the tragic actor, grave on the Sacred Way before the Kephisos crossing: Paus. 1.37.3</p>
<p :class="{'activeClass': idClicked == NP3581 }" id="NP3581" >THEODOROS, father of Isokrates, statue on the Akr.: Plu. Moralia 839 D</p>
<p :class="{'activeClass': idClicked == NP3582 }" id="NP3582" >THEODOROS OF GADARA, rhetorician, reused statue base, Augustan pd. — <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206051" target="_blank">Agora I 6051</a>; E. Vanderpool, “An Athenian Monument to Theodoros of Gadara,” AJP 80 (1959) 366-369, drawing; SEG 19 (1963) no. 208; Blanck, H. 1969, 15</p>
<p :class="{'activeClass': idClicked == NP3583 }" id="NP3583" >THEODOROS HEROS: see Heroes Hro24*</p>
<p :class="{'activeClass': idClicked == NP3584 }" id="NP3584" >THEODOROS, proconsul of Achaia, epigrams on a round statue base attesting two statues of Theodoros dedicated by Themistokles, one of stone, the other of bronze, approved by the emperor Theodosius, ca. 394 A.D. - - - IG II² 4223; PLRE I, 1971, Theodorus 16, 17, 18; Sironen, E. 1997, 70-72, no. 14 Before 1767 the base was reused as an altar support in the church of Hag. Aikaterini, formerly Haghioi Theodoroi, northeast of the Lysikrates Mon. (Chr.Mon. CM42*, Plan 1 eta—9)</p>
<p :class="{'activeClass': idClicked == NP3585 }" id="NP3585" >THEOI SEBASTOI: see Divi Augusti</p>
<p :class="{'activeClass': idClicked == NP3586 }" id="NP3586" >THEOKLEIDES' throne on the Akropolis IG II² 5169</p>
<p :class="{'activeClass': idClicked == NP3587 }" id="NP3587" >THEOPHRASTOS' GARDEN near or adjoining the Lykeion: D.L. 5.52-54, 55; Lynch, J. P. 1972, 22, 28-29, 98-102, 125-6 note 27, 209-211; Ritchie Jr., C. E. 1989, 250-251, 258, 260; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM507">507</a></p>
<p :class="{'activeClass': idClicked == NP3588 }" id="NP3588" >Peripatos: D.L. 5.52 + 54</p>
<p :class="{'activeClass': idClicked == NP3589 }" id="NP3589" >Houses adjoining the garden: D.L. 5.52</p>
<p :class="{'activeClass': idClicked == NP3590 }" id="NP3590" >Theophrastos' grave in his Garden: D.L. 5.53 See also: Mouseion rebuilt by Theophrastos; Muses</p>
<p :class="{'activeClass': idClicked == NP3591 }" id="NP3591" >THEOS HYPSISTOS: see Zeus Zeu36*-48*</p>
<p :class="{'activeClass': idClicked == NP3592 }" id="NP3592" >THEOXENA, theater seat - - - IG II² 5164</p>
<p :class="{'activeClass': idClicked == NP3593 }" id="NP3593" >THERRIKLES, hero of the phratry of the Therrikleidai, sanctuary probably not far from the southeast corner of Agora Exc.: IG I³ 243 lines 30-31; C. W. Hedrick, Jr., Old and New on the Attic Phratry of the Therrikleidai,” Hesperia 52 (1983) 301 with note 12 = SEG 32 (1982) no. 150; Hedrick, C, W., Jr. 1984, 267-273; cf. Lambert, S. D. 1993, 322-326 T 12; see Apollo Apo57*</p>
<p :class="{'activeClass': idClicked == NP3594 }" id="NP3594" >THERSANDROS AND SIMYLOS, envoys from Kerkyra, state burial mon. on the Sacred Way southeast of the Tritopatreion IG II² 5224; the original stele of 433/432 B.C. perished, the replacement was set up in 375 B.C., Knigge, U. 1972, 591-605, figs. 1, 11-26, 31, 33-34</p>
<p :class="{'activeClass': idClicked == NP3595 }" id="NP3595" >THESAUROS with paintings by Polygnotos: Harp. s.v. Πολυγνώτου; Dinsmoor, W. B. 1932, 317-18 with note 3</p>
<p :class="{'activeClass': idClicked == NP3596 }" id="NP3596" >THESEUS: H. Herter, RE Suppl. XIII, 1973, col. 1046-1238 (Theseus); Kearns, E. 1989, 53, 117-124,168-169; Neils, J. 1994, 922-940, 943-951</p>
<p :class="{'activeClass': idClicked == NP3597 }" id="NP3597" >Sanctuaries of Theseus given to Herakles: see Herakles</p>
<p :class="{'activeClass': idClicked == NP3598 }" id="NP3598" >Sanctuary, the Theseion, next to the Gymnasium of Ptolemy on the Akr. north slope: Paus. 1.17.2 + 1.17.6; Agora 111,113-119, nos. 339-362; E. Vanderpool, “The ‘ Agora' of Pausanias I, 17, 1-2.” Hesperia 43 (1974) 308-310; Koumanoudes, S. N. 1976 <JBcom>[1977]</JBcom>, 194-216; cf. Chr. Mon. CM43*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM212">212</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM330">330</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM345">345</a></p>
<p :class="{'activeClass': idClicked == NP3599 }" id="NP3599" >Theseus' bones brought from Skyros by Kimon in 476/5 B.C. and buried in the Theseion: Paus. 1.17.6, 3.3.7; Develin, R. 1989, 68</p>
<p :class="{'activeClass': idClicked == NP3600 }" id="NP3600" >Priest's theater throne - - - IG II² 5076; Maass, M. 1972, 137, pl. 20</p>
<p :class="{'activeClass': idClicked == NP3601 }" id="NP3601" >Precincts of Theseus (in and outside of Athens): IG II² 1035 line 48; Culley, G. R. 1973, 123, 171-176; Koumanoudes, S. N. 1976 <JBcom>[1977]</JBcom>, 197-198, 201-202, 204-206, 209</p>
<p :class="{'activeClass': idClicked == NP3602 }" id="NP3602" >Temenos of Theseus founded by himself: Plu. Thes. 13.3</p>
<p :class="{'activeClass': idClicked == NP3603 }" id="NP3603" >Theseion at the Long Walls, conjectured: Agora III, no. 359; Culley, G. R. 1973, 123, 171-176</p>
<p :class="{'activeClass': idClicked == NP3604 }" id="NP3604" >Arula, mid-2nd c. B.C., Akropolis IG II² 2865; Guarducci, M. 1967-1978, IV, 201-202</p>
<p :class="{'activeClass': idClicked == NP3605 }" id="NP3605" >THESEUS (continued) Pictures:</p>
<p :class="{'activeClass': idClicked == NP3606 }" id="NP3606" >Picture by Aristolaus, son of Pausias: Plin. Nat. XXXV.137; LIMC III, 1986, 379 no. 49, s.v. Demos (O. Alexandri-Tzahou)</p>
<p :class="{'activeClass': idClicked == NP3607 }" id="NP3607" >Picture by Parrhasios: Plu. Thes. 4, Moralia 346 A; according to Plin. Nat. 35.69, the picture was in the Capitoline</p>
<p :class="{'activeClass': idClicked == NP3608 }" id="NP3608" >Theseus, Demokratia and Demos, picture by Euphranor, in the Stoa of Zeus: Paus. 1.3.3; Palagia, O. 1980, 1-2 no. 7, 2-3 no. 14, 3-4 no. 18, 50-51, 57-60</p>
<p :class="{'activeClass': idClicked == NP3609 }" id="NP3609" >Theseus fetching Minos' seal ring from the sea, picture by Mikon in the Theseion: Paus. 1.17.3; Brommer, F. 1982, 77-83; Neils, J. 1994, 939 See also: Amazonomachia, two pictures, one in the Stoa Poikile, one in the Theseion; Centauromachy, picture in the Theseion; Demos; Dionysos Dio21*; Marathonomachia</p>
<p :class="{'activeClass': idClicked == NP3610 }" id="NP3610" >THESEUS (continued) Sculpture:</p>
<p :class="{'activeClass': idClicked == NP3611 }" id="NP3611" >Statue by Silanion, late 4th c. B.C.: Plu. Thes. 4; F. Brommer, “Theseus-Statuen,” AM 97 (1982) 145-146, 147-148</p>
<p :class="{'activeClass': idClicked == NP3612 }" id="NP3612" >Statue in the Ares precinct: Paus. 1.8.4; F. Brommer, op. cit.,145, 151</p>
<p :class="{'activeClass': idClicked == NP3613 }" id="NP3613" >Theseus lifting the rock at Troizen: Brommer, F. 1982, 1-2, 148</p>
<p :class="{'activeClass': idClicked == NP3614 }" id="NP3614" >Statue, Akr.: Paus. 1.27.8; cf. C. Sourvinou-lnwood, “Theseus Lifting the Rock and a Cup Near the Pithos Painter,” JHS 91 (1971) 94-109; represented on imperial bronze coins, Thompson, M. 1982, 166, 169 with note 23, pl. 28 no. 14; Neils, J. 1994, 925 no. 29, ph. and cf. no. 28, ph., coins of Troezen</p>
<p :class="{'activeClass': idClicked == NP3615 }" id="NP3615" >Theseus lifting the rock, document relief, citizenship decree for Telesias of Troizen, 140/139 B.C., Akr. South Slope near the theater IG II² 971; Brommer. F. 1982, 2, 148, pl. 17A; Lawton, C. L. 1995, 157 no. 187, pl. 96</p>
<p :class="{'activeClass': idClicked == NP3616 }" id="NP3616" >Base and feet of a statue of Theseus, 1st-2nd c. A.C. - - - IG II² 4763; NM 2815; F. Brommer, op. cit., 148, 150-151, pl. 30; SEG 51 (2001) no. 232</p>
<p :class="{'activeClass': idClicked == NP3617 }" id="NP3617" >Theseus and the Marathonian Bull, dedicated by the people of Marathon, Akr.: Paus. 1.27.9-10; Brommer, F. 1982, 33-4; represented on bronze imperial coins, Thompson, M. 1982, 166, 169-170, pl. 28 nos. 15-16; cf. Bulls: Fragments</p>
<p :class="{'activeClass': idClicked == NP3618 }" id="NP3618" >Theseus and the Minotaur<br>Theseus and the Minotaur, group on the Akr.: Paus. 1.24.1; Brommer, F. 1982, 49-55, 57</p>
<p :class="{'activeClass': idClicked == NP3620 }" id="NP3620" >Theseus and the Minotaur, Roman pd., found on the Akr. lower north slope at the so-called Diogeneion site, Kyrrhestou and Erechtheos Sts. (Plan 1 delta—7 no. 76) NM 1664 A; P. Pervanoglu, “Theseus und Minotaur,” AZ 1866, no. 208, April 1866, 160-161, pl. 108 nos. 4-5; E. Vanderpool, “The ‘Agora' of Pausanias I, 17, 1-2.” Hesperia 43 (1974) 309-310; Kaltsas, N. 2002, 96-97 no. 169, ph.</p>
<p :class="{'activeClass': idClicked == NP3621 }" id="NP3621" >Theseus and the Minotaur, three compositions on bronze imperial coins probably harking back to sculpture or painting: Thomson, M. 1982, 166, 167, 168, 170-171, pl. 28 nos. 10, 17-19 See also: Dionysos Dio7*, tripod base, side 3</p>
<p :class="{'activeClass': idClicked == NP3622 }" id="NP3622" >Theseus, Aigeus and Medea (mistakenly identified reliefs on a tripod base), see Dionysos Dio15**</p>
<p :class="{'activeClass': idClicked == NP3623 }" id="NP3623" >Theseus and Amazon: see Harmodios and Aristogeiton, Broomhall Throne</p>
<p :class="{'activeClass': idClicked == NP3624 }" id="NP3624" >Theseus and Ariadne, relief: see Ariadne</p>
<p :class="{'activeClass': idClicked == NP3625 }" id="NP3625" >Theseus and Hadrian: see Hadrian, Arch</p>
<p :class="{'activeClass': idClicked == NP3626 }" id="NP3626" >Theseus and Skiron: see Stoa Basileios, Akroterion</p>
<p :class="{'activeClass': idClicked == NP3627 }" id="NP3627" >THESEUS AND PEIRITHOOS: Krater-shaped hollow in the rock marking the place where Theseus and Peirithoos made their pact: S. OC, 1593; the site (but not the hollow in the rock) seen by Pausanias, 1.18.4, not far from the sanctuary of Sarapis; probably in the area around the Roman Agora, Travlos, J. 1971, 28; see Symbolon; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM473">473</a> See also: Orpheus Monument; Peirithoos</p>
<p :class="{'activeClass': idClicked == NP3629 }" id="NP3629" >THESMOPHORION, THESMOPHOROI, THESMOPHOROS, DEMETER THESMOPHOROS<br>Thesmophorion, precinct: Ar. Th. lines 278, 880; Agora III, no. 224; for summaries of K. Clinton's arguments that the deme of Melite celebrated its local festival of the Thesmophoria in the Eleusinion, see SEG 46 (1996) nos. 20, 152, 160; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM256">256</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM282">282</a></p>
<p :class="{'activeClass': idClicked == NP3631 }" id="NP3631" >Thesmophoroi, dedication with altar-and-snakes relief, 1st/2nd c. A.C., Asklepieion - - - IG II² 4752; SEG 42 (1992) no. 189</p>
<p :class="{'activeClass': idClicked == NP3632 }" id="NP3632" >Thesmophoroi and Hierophant, relief, 2nd c. A.C., in a house north of the Olympieion precinct (Plan 2 eta—6): for the findspot, see Daux, G. 1960, 637, fig. 1, fig. 2 epsilon, pl. 13; Travlos, J. 1971, 289, fig. 383; Clinton, K. 1974, 32-35, no. 23, fig. 3; <JBcom>[since the relief was not found in situ (information about the excavation of that house is not available), it might be worth noticing that the findspot north of the Olympieion is not so far away from Kallirrhoe south of the Olympieion where the Lesser Mysteries were celebrated]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3633 }" id="NP3633" >Thesmophoros, theater seat - - - IG II² 5132 Demeter Thesmophoros, theater seat - - - IG II² 5136 </p>
<p :class="{'activeClass': idClicked == NP3634 }" id="NP3634" >THESMOTHETAI <br>Thesmotheteion where the Thesmothetai met and dined: Arist. Ath. 3.5; Agora 111,177-179; Rhodes, P. J. 1981, 106; conjectural location on the Akr. north slope east of the Eleusinion, Travlos, J. 1971, 2, fig. 5 no. 18; for a public dining room, perhaps the Thesmothetion, located somewhere around the northwest corner of the Agora, see Rotroff, S. I. and J. H. Oakley 1992, 38-40, 59</p>
<p :class="{'activeClass': idClicked == NP3636 }" id="NP3636" >Picture of the Thesmothetai by Protogenes of Kaunos in the Bouleuterion: Paus. 1.3.5; Thompson, H. A. 1937, 214</p>
<p :class="{'activeClass': idClicked == NP3637 }" id="NP3637" >Theater thrones - - - IG II² 5039-5042; Maass, M. 1972, 16, 117-119, pls. 10-11</p>
<p :class="{'activeClass': idClicked == NP3638 }" id="NP3638" >For sixteen votive plaques dedicated by the Thesmothetai at the cave sanctuary of Apollo Hypoakraios (Apollo Apo2*), see Nulton, P. E. 2000, 35 note 80, 57 and the catalogue passim</p>
<p :class="{'activeClass': idClicked == NP3639 }" id="NP3639" >THESPIS, statue base, 2nd c. A.C., in the theater - - - IG II² 4264; Ghiron-Bastagne, P. 1976, 100 fig. 38</p>
<p :class="{'activeClass': idClicked == NP3640 }" id="NP3640" >THOLOS, near the Bouleuterion: Paus. 1.5.1; Agora III, 179-184; Agora XIV, 41-46, figs. 1, 12, 13 and pls. 3, 5-9, 11-13, 28, 33:A; Camp, J. McK. 1986, 231 (Index), figs. 5 no. 46, figs. 51-53, 66, 68, 71, 91, 129, 139, 151-153, 166 and pl. I</p>
<p :class="{'activeClass': idClicked == NP3641 }" id="NP3641" >Dining ware donated to the Tholos in 181/0 B.C. by Queen Laodike, wife of Antiochos III: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205344" target="_blank">Agora I 5344</a> lines 8-9; Thompson, H. A. 1940, 144-147, fig. 105; Schenkungen hellenistischer Herrscher, Part I, 1995, 51-52</p>
<p :class="{'activeClass': idClicked == NP3642 }" id="NP3642" >THORIKIAN ROCK and the wild pear tree, landmarks on Oidipous' route from Kolonos Hippios to the Akropolis North Slope: S. OC 1595; W. Wrede, RE, VI A1,1936, col. 340 lines 37-39 (Thorikos); cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM474">474</a></p>
<p :class="{'activeClass': idClicked == NP3643 }" id="NP3643" >THRASYBOULOS, son of Lykos <br>The first grave seen by Pausanias on the Road to the Academy: Paus. 1.29.3. Just before Pausanias starts to report the Demosion Sema monuments, he mentions four monuments for individuals buried at state expense: Thrasyboulos, Perikles, Chabrias, and Phormion; hypothetical <JBcom>[and O so doubtable]</JBcom> location of Thrasyboulos' grave on Salaminos St. approximately opposite Kerameikos Horoi 2 and 3, Stichel, R. H. W. 1998, 153-154, 156. <JBcom>[Because Cicero and Livy mention the Dipylon Gate as the starting point for the Road to the Academy, scholars assume that Pausanias also started there, it ain't necessarily so. Pausanias does not say that he started at a gate and indeed he does not mention the Dipylon Gate throughout his narrative. He may have started his walk along the Road to the Academy at some distance from the Dipylon Gate.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3645 }" id="NP3645" >Thrasyboulos and his men, decree honouring the heroes of Phyle in the Metroon, 403/2 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%2016" target="_blank">Agora I 16</a> +; A. E. Raubitschek, “The Heroes of Phyle,” Hesperia 10 (1941) 284-295, fig. 1 and 4 unnumbered photos in the text; SEG 32 (1982) no. 36</p>
<p :class="{'activeClass': idClicked == NP3646 }" id="NP3646" >THRASYBOULOS, son of Thrason, statue base, 373/2 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204373" target="_blank">Agora I 4373</a>; E. Schweigert, “Greek Inscriptions,” Hesperia 8 (1939) 3-5 no. 2, ph.</p>
<p :class="{'activeClass': idClicked == NP3647 }" id="NP3647" >THRASYKLES, son of Aristokleides, and his mother, grave mon., late 4th c. B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206017" target="_blank">Agora I 6017</a>; Meritt, B. D. 1961, 278-279 no. 135, pl. 57; Agora XVII, 67 no. 231 <br>1st use: Hymettian marble orthostate block <br>2nd use: grave monument of Thrasykles <br>3rd use: threshold block <br>4th use: curbstone on Eponymon St. until 1947</p>
<p :class="{'activeClass': idClicked == NP3649 }" id="NP3649" >THRASYKLES, son of Thrasyllos, tripods for 2 choregic victories in 271/270 B.C. mounted on the Thrasyllos Monument: Travlos, J. 1971, 562; Townsend, R. F. 1982, 192-193</p>
<p :class="{'activeClass': idClicked == NP3650 }" id="NP3650" >Tripod bases flanking Thrasyllos' tripod - - - IG II² 3083; SEG 47 (1997) no. 204</p>
<p :class="{'activeClass': idClicked == NP3651 }" id="NP3651" >Two statues set on Thrasykles' tripod bases ca. 400 A.D.: J. Travlos, 1971, 562; View of the Akropolis in 1670, Akademisches Kunstmuseum.Bonn; A. H. Borbein, “Die griechische Statue des 4. Jahrhunderts v. Chr.,” Jahrbuch 88 (1973) 58 note 62</p>
<p :class="{'activeClass': idClicked == NP3652 }" id="NP3652" >THRASYLLOS, choregic monument above the theater of Dionysos at the cave of Panagia Spiliaiotissa (Chr. Mon. CM78*): Paus. 1.21.3 (Thrasyllos not mentioned); Travlos, J. 1971, 562-565, fig. 71 no. 142, figs. 704-708; Townsend, R. F. 1982, 181-198, 219-222 (notes 27-61), figs. 105-116; Wilson, P. 2000, 229-234, figs. 17-20</p>
<p :class="{'activeClass': idClicked == NP3653 }" id="NP3653" >Inscribed epistyle recording Thrasyllos* victory in 320/319 B.C. - - - IG II² 3056; SEG 47 (1997) no. 204; awarded for a performance of Timotheus' Niobe, Townsend, R. F. 1982, 197-198</p>
<p :class="{'activeClass': idClicked == NP3654 }" id="NP3654" >Frieze with ivy wreath and olive wreaths - - - NM 3943; Welter, G. 1938, 42-43, figs. 28-31, 36, 39</p>
<p :class="{'activeClass': idClicked == NP3655 }" id="NP3655" >Apollo and Artemis slaying Niobe's children: Paus. 1.21.3; <JBcom>[implausibly]</JBcom> called a wall-painting, Travlos, J. 1971, 562; A. H. Borbein, op. cit under Thrasykles, 58, 59, 198-199; <JBcom>[plausibly]</JBcom> a sculpture group inside the cave, Townsend, R. F. 1982, 195-198</p>
<p :class="{'activeClass': idClicked == NP3656 }" id="NP3656" >Base of Thrasyllos' tripod: Amandry, P. 1976, 53-56; Townsend, R. F. 1982, 191-192</p>
<p :class="{'activeClass': idClicked == NP3657 }" id="NP3657" >Seated statue of Dionysos later set on (?) Thrasyllos' tripod base - - - BM cat. no. 432 (Smith, A. H. 1891, 257-259); E. Pochmarski, Das Bild des Dionysos in der Rundplastik der klassischen Zeit Griechenlands, Wien, 1974, 191-194; the statue was actually placed in back of the base, Townsend, R. F.1982, 192, 220-221 (note 46)</p>
<p :class="{'activeClass': idClicked == NP3658 }" id="NP3658" >THRASYLOS, bath: Alciphr. III.40; Camp, J. McK. 1980, 346-347</p>
<p :class="{'activeClass': idClicked == NP3659 }" id="NP3659" >THRIASIAN GATE, earlier name for the Dipylon Gate: Plu. Per. 30.3; Stroszeck, J. 2003, 74 with notes 97-99; see Themistoklean City Wall Wal6*</p>
<p :class="{'activeClass': idClicked == NP3660 }" id="NP3660" >THUCYDIDES, son of Oloros <br>Grave mon. with epitaph, in Kimon's family plot in Koile not far from the Melitides Gate: Marcellin. Vit. Thuc. 16-17; Paus. 1.23.9; O. Luschat, RE Suppl. XII, 1970, col. 1105-1106 (Thukydides)</p>
<p :class="{'activeClass': idClicked == NP3662 }" id="NP3662" >Statue (?) on the Akropolis (?), conjecture arising from an interpretation of Paus. 1.23.9; K. Deichgraber, RE XXI, 1952, col. 1293 line 58 - col. 1294 line 7 (Polemon); Richter, G. M. A. 1965, I, 148</p>
<p :class="{'activeClass': idClicked == NP3663 }" id="NP3663" >Portrait head - - - NM 984; D. Metzler, Untersuchungen zu den griechischen Portrats des 5 Jhd. v. Chr., Münster, 1966, 67.-68</p>
<p :class="{'activeClass': idClicked == NP3664 }" id="NP3664" >THYECHOOS: Thyechoos, theater throne - - - IG II² 5026; Maass, M. 1972, 105-106 with drawing, pl. 6</p>
<p :class="{'activeClass': idClicked == NP3666 }" id="NP3666" >Thyechous, altar, Erechtheion North Porch: IG I² 474 lines 79, 202 and IG I² 476 lines 219-220; Kron, U. 1976, 44-48; <JBcom>[mistakenly]</JBcom> identified as the altar of Zeus Hypatos, Caskey, L. D. et al. 1927, 490-491; SEG 46 (1996) no. 51</p>
<p :class="{'activeClass': idClicked == NP3667 }" id="NP3667" >TIBERIUS = Tiberius Julius Caesar Augustus: Geagan, D. 1979, 386</p>
<p :class="{'activeClass': idClicked == NP3668 }" id="NP3668" >Statue bases - - - IG II² 3243 (= 3932), 3244-3247, 3265; E. Vanderpool, “Athens Honors the Emperor Tiberius,” Hesperia 28 (1959) 89; SEG 40 (1990) no. 182 See also: Asklepios Ask8**; Attalos II, stoa, donor's mon.; Augustus, altars; Pandaites and Pasikles</p>
<p :class="{'activeClass': idClicked == NP3669 }" id="NP3669" >TIMAGORAS, statue at the altar of Anteros: Suidas s.v.</p>
<p :class="{'activeClass': idClicked == NP3670 }" id="NP3670" >TIMEAS, palaestra: IG II² 956 lines 61-2; Delorme, J. 1960, 147, 261 note 1</p>
<p :class="{'activeClass': idClicked == NP3671 }" id="NP3671" >TIMON, tower near Plato's grave and Kolonos Hippios: Paus.l.30.4; Svoronos, J. N. 1911, 400 with note 2; Billot, M.-F. 1989, 780</p>
<p :class="{'activeClass': idClicked == NP3672 }" id="NP3672" >TIMOSTRATOS, comedy writer, statue base, theater IG II² 4267; Ghiron-Bastagne, P. 1976, 100 fig. 40</p>
<p :class="{'activeClass': idClicked == NP3673 }" id="NP3673" >TIMOTHEOS (I), father of Konon, statue base, 460-450? B.C., Akr. - - - IG I³ 863 = IG I² 651; Davies, J. K. 1971, 507; Harris, D. 1995, 231</p>
<p :class="{'activeClass': idClicked == NP3674 }" id="NP3674" >TIMOTHEOS, son of Konon: Statue granted by the Athenians in 375 B.C.: Aeschin. 3,243; Develin, R. 1989, 243; <JBcom>[perhaps belongs to one of the Konon and Timotheos groups q.v.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3676 }" id="NP3676" >Tyche and Timotheos, picture: Piu. Sull. 6.3 See also: Konon and Timotheos</p>
<p :class="{'activeClass': idClicked == NP3677 }" id="NP3677" >TITAN: see Prometheus</p>
<p :class="{'activeClass': idClicked == NP3678 }" id="NP3678" >TOLMIDES, son of Tolmaios: Tolmides and his seer Theainetos, statue group (andriantes) on the Akropolis near the Temple of Athena: Paus. 1.27.5; cf. Th. 3.20.1; Stupperich, R. 1977, 1.12; <JBcom>[doubtably thought to be part of a four-figure group with Erechtheus fighting Eumolpos q.v. ]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3680 }" id="NP3680" >Tolmides and his men: see Demosion Sema Sem19*</p>
<p :class="{'activeClass': idClicked == NP3681 }" id="NP3681" >TOURKOVOUNI, present name of the hill about 1.5 km. north of Lykabettos (Plan 3 zeta/theta—1/3): probably ancient Anchesmos, Langdon, Μ. K. 1976, 101-102</p>
<p :class="{'activeClass': idClicked == NP3682 }" id="NP3682" >TOXARIS, 6th c. B.C., heroized as Xenos Iatros in 429 B.C., inscribed grave stele with relief of a Scythian holding a bow and a book, not far from the Dipylon, on the left going to the Academy: Lucian, Scyth. 1-2; Pantazopoulos, N. I. 1947/48, 106, 109-111; Pritchett, W. K. 1998, 18</p>
<p :class="{'activeClass': idClicked == NP3683 }" id="NP3683" >TRAGIC MASK, Akr. lower west slope: Schrader, H. 1896, 270</p>
<p :class="{'activeClass': idClicked == NP3684 }" id="NP3684" >TRAGIC MASKS, two votive masks of marble, intersection of Plataion and Agesilaou Sts. northwest of the Kerameikos Exc. (Plan 2 delta—2: Karagiorga-Stathakopoulou, Th. 1978, «Οδός Πλαταιών 4 και Αγησιλάου,» 21 no. 26 at end</p>
<p :class="{'activeClass': idClicked == NP3685 }" id="NP3685" >TRAGOIDIA (rest.) and KOMOIDIA, statues listed in a gymnasium inventory: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora I 1005</a> line 7; Clay, D. 1977, 260, 264</p>
<p :class="{'activeClass': idClicked == NP3686 }" id="NP3686" >TRAJAN = M. Ulpius Traianus, cult centre in the Library of Pantainos: Shear Jr., T. L. 1981, 371 with note 63</p>
<p :class="{'activeClass': idClicked == NP3687 }" id="NP3687" >Dedication, Pantainos Library area <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20380" target="_blank">Agora I 380</a>, IG II² 3284 A; Meritt, B. D. 1934, 74 no. 72 with drawing</p>
<p :class="{'activeClass': idClicked == NP3688 }" id="NP3688" >Statues dedicated by Atticus, father of Herodes Atticus: T. L. Shear, Jr., loc. cit</p>
<p :class="{'activeClass': idClicked == NP3689 }" id="NP3689" >Found in the stoa of the Library of Pantainos, ca. 100 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207353" target="_blank">Agora I 7353</a>; Shear Jr., T. L. 1973a, 175-176 no. 2, pl. 39 h; idem 1973b, 388</p>
<p :class="{'activeClass': idClicked == NP3690 }" id="NP3690" >Found near the Gate of Athena Archegetis: N. M. Verdelis, “Inscriptions de Γ Agora romaine d'Athenes,” BCH 71/72 (1947/48) 42-46</p>
<p :class="{'activeClass': idClicked == NP3691 }" id="NP3691" >Portrait head?, near the Library of Pantainos <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20347" target="_blank">Agora S 347</a>; “not an imperial portrait,” Agora I, no. 17, pl. 12; cf. Agora Guide 1990, 213</p>
<p :class="{'activeClass': idClicked == NP3692 }" id="NP3692" >Trajan with a captive Dacian, Pantainos Library stoa - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202518" target="_blank">Agora S 2518</a>; K. Stemmer, Untersuchungen zur Typologie, Chronologie und Ikonographie der Panzerstatuen, Berlin, DAI, 1978, 42-43 no. Ill 21 a, pl. 25.2-5 See also: Pandaites and Pasikles (reused statue base)</p>
<p :class="{'activeClass': idClicked == NP3693 }" id="NP3693" >TRAPEZAI, money changers' tables in the Agora: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207180" target="_blank">Agora I 7180</a> lines 5-6 + 46; Agora III, 192-193; <JBcom>[mistakenly]</JBcom> equated with the South Stoa I dining tables, Travlos, J. 1971, 534; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM355">355</a></p>
<p :class="{'activeClass': idClicked == NP3694 }" id="NP3694" >TREBELLIUS RUFUS, Q., of Toulouse in Narbonese Gaul, benefactor of the Athenian people, Eponymous Archon between 85 and 95 A.D.; SEG 50 (2000) no. 209; Byrne, S. G. 2003, 453-454 no. 1</p>
<p :class="{'activeClass': idClicked == NP3695 }" id="NP3695" >Statue base <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20849" target="_blank">Agora I 849</a> +; Oliver, J. H. 1941a, 72-77 no. 32, ph.; Agora Guide 1990, 68, 274</p>
<p :class="{'activeClass': idClicked == NP3696 }" id="NP3696" >Statue base, Propylaia - - - IG II² 4193 A; SEG 38 (1988) no. 183</p>
<p :class="{'activeClass': idClicked == NP3697 }" id="NP3697" >Statue base, site of Hag. Dimitrios tou Katiphori (Chr. Mon. CM59*) at the intersection of Kyrrhestou and Erechtheos Sts. (Plan 1 delta—7) - - - IG II² 4193B + EM 2334; D. Peppa-Delmouzou, “IG II² 4193 B (EM 1871) + EM 2334,” Deltion 29 (1973/1974) B1 Chr., 19 no. 7, pl. 26:b; SEG 29 (1979) no. 181</p>
<p :class="{'activeClass': idClicked == NP3698 }" id="NP3698" >TRIGLA, the place where Hekate Triglanthine stood: Ath. VII.325 D; Judeich, W. 1931, 178 note 3</p>
<p :class="{'activeClass': idClicked == NP3699 }" id="NP3699" >TRIGONON, lawcourt: Paus. 1.28.8; Agora XXVIII, Index s.v. Trigonon </p>
<p :class="{'activeClass': idClicked == NP3700 }" id="NP3700" >TRIKEPHALOS: see Hermes Hrm31*</p>
<p :class="{'activeClass': idClicked == NP3701 }" id="NP3701" >TRIODOS LYKEIS, place-name: St. Byz. s.v.</p>
<p :class="{'activeClass': idClicked == NP3702 }" id="NP3702" >TRIPODES, place-name derived from the name of the street, 6-6.50 m. wide, starting at the Prytaneion <JBcom>[on the Akropolis upper north slope]</JBcom>, going clockwise around the Akropolis slopes to the precinct of Dionysos Eleuthereus: Paus. 1.20.1; A. Choremi-Spetsieri, «Η οδός των Τρίποδων και τα χορηγικά μνημεία στην αρχαία Αθήνα» in Coulson et al. 1994, 31-42, figs. 1-10; Κ. N. Kazamiakis, «Η Οδός των Τριπόδων— τεχνικά και κατασκευαστικά στοιχεία,» ibid., 43-44; the ancient street forked at the intersection of Prytaneiou and Tholou Sts., just east of the Old University (Plan I no. 124), both branches leading to the Panathenaic Way, A. Choremi-Spetsieri, op. cit, 35, fig. 2; Travlos, J. 1971, 198, 566, figs. 540 no. 186, 710; Agora XXXI, 1, 11 with note 3, 12 with note 5, fig. 2; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM190B">190B</a>. <JBcom>[The ancient street is only partly coterminous with present-day Tripodon St.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3703 }" id="NP3703" >TRIPODS of bronze enclosing memorable artworks, supported on temples, Street of the Tripods: Paus. 1.20.1, referring to choregic monuments; A. H. Borbein, “Die griechische statue des 4. Jahrhunderts v. Chr.,” Jahrbuch 88 (1973) 55, 194 -198, Anhang II</p>
<p :class="{'activeClass': idClicked == NP3704 }" id="NP3704" >TRIPTOLEMOS: Kearns, E. 1989, 201</p>
<p :class="{'activeClass': idClicked == NP3705 }" id="NP3705" >Temple facing north, early 5th c. B.C., in the forecourt of the Eleusinion: Paus. 1.14.1 + 1.14.4; Agora XXXI, 35-57 and Index, s.v. Temple of Triptolemos</p>
<p :class="{'activeClass': idClicked == NP3706 }" id="NP3706" >Temple statue: Paus. 1.14.1 + 1.14.4; Eschbach, N. 1986, 109-123, especially 117-118; Agora XXXI, 52</p>
<p :class="{'activeClass': idClicked == NP3707 }" id="NP3707" >Mission of Triptolemos relief, early 4th c. B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201013" target="_blank">Agora S 1013</a>; Agora XXXI, 56, 217-218 no. 4, pl. 37</p>
<p :class="{'activeClass': idClicked == NP3708 }" id="NP3708" >Mission of Triptolemos relief - - - Acr. 1348; Despinis, G. 1971, 179 with notes 367-368. See also: Eubouleus</p>
<p :class="{'activeClass': idClicked == NP3709 }" id="NP3709" >TRIREME RELIEF (Lenormant relief) — National Museum inv. no. ?, Acr. 1339 + 2544; Beschi, L. 1969/1970, 117-132, figs. 18-19, 22, 24; J. S. Morrison and J. F. Coates, The Athenian Trireme, Cambridge, 1986, 13, 16, 21, 23, 136, 139-151, 157, 160, 161, 172, 199-200, 234-237 with figs. 11-14, 36, 58</p>
<p :class="{'activeClass': idClicked == NP3710 }" id="NP3710" >TRITE RHUME, place-name or street name: Poll. 9.38; Judeich, W. 1931, 183 note 10</p>
<p :class="{'activeClass': idClicked == NP3711 }" id="NP3711" >TRITOPATERES: F GR HIST III B, suppl. vol. I, 181-2, 551; Jameson, Μ. H. et al. 1993, 108 and Index s.v. Tritopateres; SEG 46 (1996) no. 17</p>
<p :class="{'activeClass': idClicked == NP3712 }" id="NP3712" >Tritopateres of the Euergidai, boundary stone, early 4th c. B.C., north foot of the Areopagus <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205890" target="_blank">Agora I 5890</a>; Meritt, B. D. 1961, 264 no. 80, pl. 50; SEG 21 (1965) no. 650</p>
<p :class="{'activeClass': idClicked == NP3713 }" id="NP3713" >Tritopateres of the Zakyadai, sanct. boundary stone, 4th c. B.C. - - - IG II² 2615; Threatte, L. 1980, 58-59; Lambert, S. D. 1993, 368-369 T 34</p>
<p :class="{'activeClass': idClicked == NP3714 }" id="NP3714" >TRITOPATREION, sanct. in the fork of the Sacred Way and the Graberstrasse northwest of the Sacred Gate: K. Kübler, “Eine archaische Grabanlage vor dem Heiligen Tor,” AA 1973, 178, 182, 189-190 with figs. 2,5,6,12,13, 20-22. The sanctuary has a walled enclosure (abaton) and a triangular unwalled precinct.</p>
<p :class="{'activeClass': idClicked == NP3715 }" id="NP3715" >Sanctuary marker set in the south wall of the enclosure, 500-480 B.C. IG I³ 1067; Kerameikos I 294; Ohly, D. 1965, 328, fig. 30; Ritchie Jr., C. E. 1985, 447-452, pl. 84, fig. 74</p>
<p :class="{'activeClass': idClicked == NP3716 }" id="NP3716" >Three boundary stones, ca. 445-410 B.C. - - - IG I³ 1066 A-C.; A. Brueckner, «Άνασκαφαι Κεραμεικού,» Praktika 1910, 102, 104, pl. Alpha nos. 1, 2, 3: Ritchie Jr., C. E. 1985, 453-470, 659-662, pls. 85-87, figs. 75-77</p>
<p :class="{'activeClass': idClicked == NP3717 }" id="NP3717" >1) Fragment east of the walled sanct. at the fork in the Sacred Way Kerameikos I 297; this stone marks the apex of the triangular unwalled precinct</p>
<p :class="{'activeClass': idClicked == NP3718 }" id="NP3718" >2) Stele at the north corner of the walled sanct. - - - Kerameikos I 296; IG I² 870</p>
<p :class="{'activeClass': idClicked == NP3719 }" id="NP3719" >3) Stele at the south corner of the walled sanct. - - - Kerameikos I 295; IG I² 870; Ohly, D. 1965, 328, figs. 30-31</p>
<p :class="{'activeClass': idClicked == NP3720 }" id="NP3720" >TRITTYS SIGNPOSTS: Ritchie Jr., C. E. 1985, 644-645, 833-854; Traill, J. S. 1986, 93-113, pls. 10-12; SEG 38 (1988) no. 16; Agora XIX, 1991, 14-16, 29-31</p>
<p :class="{'activeClass': idClicked == NP3721 }" id="NP3721" >Six trittys signposts made of poros limestone</p>
<p :class="{'activeClass': idClicked == NP3722 }" id="NP3722" >1) Eleusinioi-Peiraieis?, ca. mid-5th c. B.C., Akr. - - - IG I³ 1126 = IG I² 885; Traill, J. S. 1986, 96, 98-99 no. 5, pl. 11</p>
<p :class="{'activeClass': idClicked == NP3723 }" id="NP3723" >2) Pedieis, after mid-5th c. B.C., Agora Exc. - - - IG I³ 1123; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201191" target="_blank">Agora I 1191</a>; Traill, J. S. 1986, 94, 98, 100 no. 8, pl. 12; Agora XIX, 30 H 39, pl. 4</p>
<p :class="{'activeClass': idClicked == NP3724 }" id="NP3724" >3) Thorikioi-Eleusinioi?, after mid-5th c. B.C., Agora Exc. - - - IG I³ 1122; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202045" target="_blank">Agora I 2045</a>; Traill, J. S. 1986, 94, 96, 99 no. 6, pl. 11; Agora XIX, 31 H42</p>
<p :class="{'activeClass': idClicked == NP3725 }" id="NP3725" >4) Alopekis-Palleneis, ca. 450 B.C., Agora Exc. - - - IG I³ 1125; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202197" target="_blank">Agora I 2197</a>; Traill, J. S. 1986, 94, 96, 101-102 no. 10, pl. 12; Agora XIX, 30 H38</p>
<p :class="{'activeClass': idClicked == NP3726 }" id="NP3726" >5) Diakrioi-Phrearrhioi, mid-5th c. B.C., Agora Exc. IG I³ 1121; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205564" target="_blank">Agora I 5564</a>; Traill, J. S. 1986, 94, 96, 99-100 no. 7; Agora XIX, 30 H 41</p>
<p :class="{'activeClass': idClicked == NP3727 }" id="NP3727" >6) Alopekeis, ca. 450 B.C., Agora Exc., lower northwest slope of the Areopagus IG I³ 1124; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206078" target="_blank">Agora I 6078</a>, Traill, J. S. 1986, 94, 96, 102 no. 11, pl. 12; Agora XIX, 29 H 36</p>
<p :class="{'activeClass': idClicked == NP3728 }" id="NP3728" >Four trittys signposts made of marble</p>
<p :class="{'activeClass': idClicked == NP3729 }" id="NP3729" >7) Kerameis, mid-5th c. B.C., Areopagus west slope - - - IG I³ 1118 = IG I² 883; SEG 37 (1987) no. 41; Traill, J. S. 1986, 93-94, 97-98 no. 3, pl. 11</p>
<p :class="{'activeClass': idClicked == NP3730 }" id="NP3730" >8) Lakiadai, ca. 450 B.C., Pnyx Hill, not far from the bema - - - IG I³ 1120 = IG I² 884; SEG 37 (1987) no. 41; Traill, J. S. 1986, 93-94, 96 no. 1, 112, pl. 10</p>
<p :class="{'activeClass': idClicked == NP3731 }" id="NP3731" >9) Sphettioi, after mid-5th c. B.C., Agora Exc., near the Church of the Holy Apostles - - - IG I³ 1119; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205053" target="_blank">Agora I 5053</a>; Traill, J. S. 1986, 93-94, 98 no. 4, pl. 11; Agora XIX, 30 H40, pl. 4</p>
<p :class="{'activeClass': idClicked == NP3732 }" id="NP3732" >10) Skambonidai, ca. 450 B.C, Agora Exc. - - - IG I³ 1117; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206699" target="_blank">Agora I 6699</a>; Traill, J. S. 1986, 93-94, 96-97 no. 2, pl. 10; Agora XIX, 30 H37</p>
<p :class="{'activeClass': idClicked == NP3733 }" id="NP3733" >TROJAN HORSE with Menestheus, Teukros, and Theseus' sons, Akamas and Demophon, colossal bronze by Strongylion dedicated by Chairedemos on the Akropolis, ca. 415 B.C. - - - IG I³ 895 = IG I² 535; Acr. 13264; Paus. 1.23.8-9; Pollitt, J. J. 1990, 72; <JBcom>[mistakenly]</JBcom> located in the Brauronion <JBcom>[where no suitable bedding exists]</JBcom>, G. P. Stevens, “The Periclean Entrance Court of the Acropolis of Athens,” Hesperia 5 (1936) 460-461, 483, fig. 14; Kron, U. 1976, 151-152; SEG 30 (1980) no. 25; perhaps on the rock bedding north of the processional way (Kavvadias-Kawerau, 1906, plan Alpha no. 10), Μ. E. Caskey, rev. of R. E. Wycherley, The Stones of Athens in AJA 83 (1979) 359</p>
<p :class="{'activeClass': idClicked == NP3734 }" id="NP3734" >TWELVE GODS, Sanctuary of The Twelve <JBcom>[Olympian]</JBcom> Gods on the Panathenaic Way east of the Stoa of Zeus: Agora III, 119-122, nos. 363-378; Gadbery, L. M. 1992, 447-489 with figs. 1-13, pls. 105-111</p>
<p :class="{'activeClass': idClicked == NP3735 }" id="NP3735" >Inscribed altar dedicated by Peisistratos son of Hippias, stuccoed poros limestone, 522/521 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201198" target="_blank">Agora A 1198-99+</a>; Th. 6.54.6-7; Crosby, M. 1949, 82, 93-94, fig. 7, pl. 14 nos. 1-3; Gadbery, L. M. 1992, 448-450, 461-462 note 32, fig. 12, pl. 108: b,d; formerly <JBcom>[mistakenly]</JBcom> merged with the Altar of Pity, cf. Eleos</p>
<p :class="{'activeClass': idClicked == NP3736 }" id="NP3736" >Cylindrical altar or <JBcom>[more probably]</JBcom> base, with reliefs of the Twelve Gods, (Aphrodite, Poseidon, Demeter, Athena, Zeus, Hera, Apollo, Artemis, four missing), Late Hellenistic, near the church of Haghios Philippos at the intersection of Adrianou and Hag. Philippou Sts. (Plan 1 beta—3) - - - NM 1731; Travlos, J. 1971, 458-459, figs. 579-580; A. Peschiow-Bindokat, “Demeter und Persephone in der attischen Kunst des 6. bis 4. Jahrhunderts v. Chr.,” Jahrbuch 87 (1972) 121-2, 152 no. R 34; Gadbery, L M. 1992, 483 note 132; Kaltsas, N. 2002, 255 no. 532, ph.</p>
<p :class="{'activeClass': idClicked == NP3737 }" id="NP3737" >Sanctuary Enclosure Parapet: Gadbery, L. M. 1992, 447-485 passim with figs. 2-11, 13; pls. 105-107, 108:b, 109:b; the Three-Figure Reliefs formerly assigned to the parapet (Agora XIV, 1972, 129-136), disassociated, L. M. Gadbery, op. cit, 450 note 9, 483 note 131, 487; cf. Orpheus Mon.</p>
<p :class="{'activeClass': idClicked == NP3738 }" id="NP3738" >1st parapet sill, constructed 522/521 B.C., reset in the last third of the 5th c. B.C.</p>
<p :class="{'activeClass': idClicked == NP3739 }" id="NP3739" >2nd parapet sill, ca. 350-325 B.C.</p>
<p :class="{'activeClass': idClicked == NP3740 }" id="NP3740" >Dedication by Leagros son of Glaukon on the base for the bronze statue of Leagros, at the west side of the altar enclosure, 470s B.C. IG I³ 951; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201597" target="_blank">Agora I 1597</a>; SEG 42 (1992) no. 46; Gadbery, L. M. 1992, 447-448, 450, 452, 465-466, 473-474, 487 with note 138, figs. 1-4, pls. 105-106, 107:a,109: b</p>
<p :class="{'activeClass': idClicked == NP3741 }" id="NP3741" >Base of a statue dedicated to the Twelve Gods, mid-4th c. B.C., - - - SEG 16 (1959) no. 172; place and time of finding unknown, now in the Roman Agora, S. N. Koumanoudes, «Άνάθημα Δώδεκα Θεοΐς,» Polemo 6 (1957) 26-27, fig. 9</p>
<p :class="{'activeClass': idClicked == NP3742 }" id="NP3742" >Picture by Euphranor in Stoa of Zeus: Paus. 1.3.3; testimonia, Pollitt, J. J. 1990, 167-168; Palagia, O. 1980, 1-2 no. 7, 2 no. 10, 3-4 no. 18, 4 no. 25, 50-51, 54-57</p>
<p :class="{'activeClass': idClicked == NP3743 }" id="NP3743" >Priest's theater throne, 2nd c. A.C. - - - IG II² 5065; Maass, M. 1972, 132, pl. 18</p>
<p :class="{'activeClass': idClicked == NP3744 }" id="NP3744" >Twelve Gods and Agathe Tyche, dedication by Philippos, son of Iasidemos, 400-350 B.C., Athens or Attica - - - IG II² 4564; S. V. Tracy, “IG Il21195 and Agathe Tyche in Attica,” Hesperia 63 (1994) 242 with note 15</p>
<p :class="{'activeClass': idClicked == NP3745 }" id="NP3745" >TYCHAI: see Heroes Hro34*</p>
<p :class="{'activeClass': idClicked == NP3746 }" id="NP3746" >TYCHE: see Agathe Tyche; Timotheus</p>
<p :class="{'activeClass': idClicked == NP3747 }" id="NP3747" >TYCHE OF THE POLIS, temple built by Herodes Atticus, overlooking the Panathenaic Stadium: Philostr. VS 11.18; Gasparri, C. 1974/75, 367-375, figs. 3-6, 12, 14, 65-77</p>
<p :class="{'activeClass': idClicked == NP3748 }" id="NP3748" >Temple statue of ivory: Philostr. VS 11.18; Tobin, J. 1997, 175</p>
<p :class="{'activeClass': idClicked == NP3749 }" id="NP3749" >TYCHON: see Hermes Hrm32*</p>
<p :class="{'activeClass': idClicked == NP3750 }" id="NP3750" >TYR (name formerly restored as Tymnes), son of Skylax, grave mon. base for a kouros by the sculptor Aristokles with a Greek-Carian bilingual inscr., ca. 525-520? B.C. <JBcom>[or later]</JBcom>, City Wall Gate at Erysichthonos St. south of Irakleidon St. (Themistoklean City Wall Wal32*) IG I³ 1344; Kerameikos I 190; SEG 42 (1992) no. 68 and 49 (1999) no. 39</p>
<p :class="{'activeClass': idClicked == NP3751 }" id="NP3751" >ULPIUS APULEIUS EURYKLES, M., of Aezani in Phrygia; statue and portrait herm set up by the Council of the Areopagus, mid-2nd c. A.C.: OGI no. 505 lines 10-12; R. Naumann, Der Zeustempel zu Aizanoi, Berlin, 1979, 36</p>
<p :class="{'activeClass': idClicked == NP3752 }" id="NP3752" >ULPIUS EUBIOTUS (M. Ulpius Eubiotus Leurus) and his sons, M. ULPIUS FL. TISAMENUS and M. ULPIUS PUPIENUS MAXIMUS: Oliver, J. H. 1941b, 1; N. V. Sekunda, “The Kylloi and Eubiotoi of Hypata,” ZPE 118 (1997) 224-226; Byrne, S. G. 2003, 457-459 nos. 2-4</p>
<p :class="{'activeClass': idClicked == NP3753 }" id="NP3753" >Bronze statues in the synhedrion of the Sacred Gerousia and in the Prytaneion, decreed ca. 230 A.D.: IG II² 1064, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201571" target="_blank">Agora I 1571</a>, I 6673; J. H. Oliver, “The Areopagus and the Whole City Honor M. Ulpius Eubiotus Leurus,” ZPE 38 (1980) 107-114; SEG 30 (1980) no. 82, lines 4, 29-30</p>
<p :class="{'activeClass': idClicked == NP3754 }" id="NP3754" >Theater throne - - - IG II² 3700 decreed ca. 230 A.D., IG II² 1064 +; Maas, M. 1972, 20-21, 83, 87, 89, 101-102, pl. 3:b; J. H. Oliver, op. cit, 108 lines 7-8, 110 lines 41-42, 112, 113 A 7-8 and 33-34 See also: Ploutarchos, reused statue base</p>
<p :class="{'activeClass': idClicked == NP3755 }" id="NP3755" >UNKNOWN GOD(S): O. Jessen, RE Suppl. I, 1903, col. 28-30 ("Αγνωστοι Θεοί); P. W. v. d. Horst, “The Altar of the ‘Unknown God' in the Hellenistic and Roman Periods,” in ANRW 11.18.2 (1989), 1427-1456</p>
<p :class="{'activeClass': idClicked == NP3756 }" id="NP3756" >Altars dedicated to anonymous local divinities, set up in the early 6th c. B.C. according to Epimenides' instructions: D.L. 1.110; P. W. v. d. Horst, op. cit., 1430-1431</p>
<p :class="{'activeClass': idClicked == NP3757 }" id="NP3757" >Altar dedicated to unknown daimones: Philostr. VA VI.3; Frazer, J. G. 1913, II, 33-34; P. W. v. d. Horst, op. cit, 1432-1432</p>
<p :class="{'activeClass': idClicked == NP3758 }" id="NP3758" >Altar dedicated to The Unknown God, the Agnostos Theos mentioned by Paul, location not specified: Acts XVII.23; the altar to The Unknown God was in the Parthenon according to Pope Innocent III in 1208: PL vol. 215, cols. 1559-1561, CCLVI; Travlos, J. and A. Frantz 1965, 194 testimonium no. 1; for the Parthenon as the temple of The Unknown God, see Vienna Anonymous 11, L. Ross, ed., Archaologische Aufsatze II (1855) 246-254; F. Mallouchou-Tufano, “The Parthenon from Cyriacus of Ancona to Frederic Boissonas,” in Parthenon 1994, 166, 196-197 note 9</p>
<p :class="{'activeClass': idClicked == NP3759 }" id="NP3759" >Altar dedicated to the gods of Asia, Europe and Libya, to the Unknown and Strange God: Oecumenius on Acts XVII.23 (PG CXVIII.238); L. Ziehen, RE XVIII,1949, col. 718-719 note 1 (Pantheion); cf. Paus. 1.1.4 (altar of Unknown Gods in Phaleron)</p>
<p :class="{'activeClass': idClicked == NP3760 }" id="NP3760" >VALERIAN FORTIFICATIONS, a term applied to a variety of Post-Herulian Walls or Post-Herulian rebuilding: since Zosimus I 29 refers to an overhauling of the Themistoklean circuit in the reign of Valerian, earlier scholars, lacking a chronological fix, naturally assigned to the reign of Valerian what are now known to be Post-Herulian constructions; Travlos, J. 1971, 387, 587, Index s.v. Valerian Wall; see Post-Herulian Wall; Themistoklean City Wall Wal19*-20*, Wal34*; Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM19">19</a> (the Beule Gate), <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM162">162</a> </p>
<p :class="{'activeClass': idClicked == NP3761 }" id="NP3761" >VALETUDO: see Asklepios Ask9*</p>
<p :class="{'activeClass': idClicked == NP3762 }" id="NP3762" >VARIUS CAELIANUS, head of a philosophical school, probably the Academy, honorary monument, ca. 98-117 A.D. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204280" target="_blank">Agora I 4280</a>; J. H. Oliver, “Philosophers and Procurators, Relatives of the Aemilius Juncus of Vita Commodi 4, 11,” Hesperia 36 (1967) 42-44, pl. 17; SEG 28 (1978) no. 220; Byrne, S. G. 2003, 469-470 no. 10</p>
<p :class="{'activeClass': idClicked == NP3763 }" id="NP3763" >VENUS: see Jupiter Optimus Maximus</p>
<p :class="{'activeClass': idClicked == NP3764 }" id="NP3764" >VESPASIAN = T. Flavius Vespasianus: D. J. Geagan, “Imperial Visits to Athens: The Epigraphical Evidence,” in Praktika 8th Congress 1984, 70 lines 1-3, 75</p>
<p :class="{'activeClass': idClicked == NP3765 }" id="NP3765" >Dedication on a curved monument, 69-79 A.D. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204642" target="_blank">Agora I 4642</a>; J. H. Oliver, “Greek and Latin Inscriptions,” Hesperia 10 (1941) 242 no. 41, ph. See also: Augustus, Altars</p>
<p :class="{'activeClass': idClicked == NP3766 }" id="NP3766" >VESTAL VIRGINS, statue bases on the Akropolis: IG II² 3532-3534; SEG 51 (2001) no. 14; see also Hestia of the Romans</p>
<p :class="{'activeClass': idClicked == NP3767 }" id="NP3767" >VICTORIA ROMANA, overlifesize monolithic statue, Augustan pd., southwest corner of the Library of Hadrian forecourt 1st Ephoria BA 395, 1065; probably commemorating Augustus' victory over the Parthians in 20 B.C., A. Choremi-Spetsieri, “Eine uberlebensgrosse Nike-Statue in Athen,” AM 111 (1996) 363-390, figs. 1-3, pls. 73-79, Beilage 1</p>
<p :class="{'activeClass': idClicked == NP3768 }" id="NP3768" >WINDMILL HILL (Windmuhlenberg), 19th century name of a hill on the Ilissos left bank, southwest of Ardettos and the Panathenaic Stadium, north of the First Cemetery: Judeich, W. 1931, 420, Plan I H-l:7; Athens 1839-1900, frontispiece with cat. no. 274; in 1991 remains of a windmill were still visible on Gorgiou St., just above Melet. Piga St. (Plan 2 theta-6)</p>
<p :class="{'activeClass': idClicked == NP3769 }" id="NP3769" >WINDS <br>Dedication to The Winds on a statue base originally for Caligula and his sister, 2nd c. A.C., Akr. near the Belvedere - - - IG II² 3266; <JBcom>[doubtably]</JBcom> called an altar, Roland Hampe, Kult der Winde in Athen und Kreta, Heidelberg, 1967, 13 See also: Andronikos, horologion</p>
<p :class="{'activeClass': idClicked == NP3771 }" id="NP3771" >XANTHIPPOS, Perikles' father, statue on the Akropolis near the statue of Anakreon: Paus. 1.25.1 (twice); Holscher, T. 1975, i87, 191</p>
<p :class="{'activeClass': idClicked == NP3772 }" id="NP3772" >XANTHOS: see Apollo Apo80*</p>
<p :class="{'activeClass': idClicked == NP3773 }" id="NP3773" >XENIOS: see Asklepios Ask38*; Zeus Zeu110*</p>
<p :class="{'activeClass': idClicked == NP3774 }" id="NP3774" >XENOKLES, son of Xeinis <br>Choregic monument on a free standing gate, 307/6 B.C., on the road leading to the theater IG II² 3073; Ghiron-Bastagne, P. 1976, 83-84, figs. 30-32; M. Kyrkou, «Διονυσιακό θέατρο (χορηγικό μνημείο IG II² 3073),» Deltion 38 (1983) B 1 Chr., 10, pl. 15:alpha; the lost name of the agonothetes more plausibly restored as Androkles, brother of Xenokles, SEG 51 (2001) no. 194</p>
<p :class="{'activeClass': idClicked == NP3776 }" id="NP3776" >Choregic monument, 307/6 B.C., above the Odeion of Herodes - - - IG II² 3077; SEG 51 (2001) no. 194</p>
<p :class="{'activeClass': idClicked == NP3777 }" id="NP3777" >XENOKRATES, tragic poet, base of statue set up by the Technitai ca. 190 B.C. - - - IG II² 3211; Pickard-Cambridge, A. 1968, 286; Tracy, S. V. 1990, 71, 78, 79, 263</p>
<p :class="{'activeClass': idClicked == NP3778 }" id="NP3778" >XENOPHON and ARRIAN, double herm — NM 538; J. H. Oliver, “Herm at Atheris with Portraits of Xenophon and Arrian,” AJA 76 (1972) 327-328</p>
<p :class="{'activeClass': idClicked == NP3779 }" id="NP3779" >XENOS IATROS: see Toxaris</p>
<p :class="{'activeClass': idClicked == NP3780 }" id="NP3780" >XERXES' silver-footed stool in the Parthenon: D. 24.129; F. J. Frost, “A Note on Xerxes at Salamis,” Historia 22 (1973) 118-119</p>
<p :class="{'activeClass': idClicked == NP3781 }" id="NP3781" >ZAKOROU DIO(KLEOUS), theater seat - - - IG II² 5158</p>
<p :class="{'activeClass': idClicked == NP3782 }" id="NP3782" >ZARMAROS, Indian gymnosophist from Bargosa, tomb: Str. 15.1.4 + 15.1.73; K. Ziegler, RE IX A2,1967, col. 2329 (Zarmaros)</p>
<p :class="{'activeClass': idClicked == NP3783 }" id="NP3783" >ZENO OF KITION, stoic philosopher <br>State burial monument: on the the Road to the Academy, Paus. 1.29.15; tomb in the Kerameikos to be constructed at state expense accorded by a decree, 262/1 B.C., to be inscribed on two stelai, set up in the Academy and in the Lykeion, D.L. 7.10-12 + 15 + 29; Stupperich, R. 1977, 26 with note 1, 264 with note 1; S. V. Tracy, “Antigonos Gonatas, King of Athens,” in Macedonians in Athens 2003, 57-58</p>
<p :class="{'activeClass': idClicked == NP3785 }" id="NP3785" >Herm frag., 2nd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206010" target="_blank">Agora I 6010</a>; Meritt, B. D. 1948, 42 no. 31, pl. 14</p>
<p :class="{'activeClass': idClicked == NP3786 }" id="NP3786" >Bronze statue accorded by decree: D.L. 7.6; K. Schefold, "Ariston von Chios,” in Eikones, Studien zum griechischen und romischen Bildnis: Hans Jucker zum sechzigsten Geburtstag gewidmet (Antike Kunst, Suppl. 12), Bern, 1980,162-163</p>
<p :class="{'activeClass': idClicked == NP3787 }" id="NP3787" >ZEPHYROS, altar on the Sacred Way: Paus. 1.37.2; Neuser, K. 1982, 191</p>
<p :class="{'activeClass': idClicked == NP3788 }" id="NP3788" >ZEUS: Schwabl, H. 1978, cols.1064-1075</p>
<p :class="{'activeClass': idClicked == NP3789 }" id="NP3789" >1* Two rock-cut boundary markers, on the eastern spur of the Hill of the Nymphs opposite 5 Otryneon St. (Plan 2 zeta—3); SEG 50 (2000) no. 86</p>
<p :class="{'activeClass': idClicked == NP3790 }" id="NP3790" >Boundary marker (for sanctuary) of Zeus, late 6th c. B.C. - - - IG I³ 1055A = IG I² 863; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 90-91; Threatte, L. 1980, 53; Ritchie Jr., C. E. 1985, 163-167 with pl. 30, fig. 26 and 538-542 with pl. 101, suppl. fig. 13; <JBcom>[extremely probably]</JBcom> for Zeus Meilichios, G. V. Lalonde, “IG I³ 1055A and B: Zeus on the Hill of the Nymphs,” AJA 100 (1996) 343; cf. Zeus Zeu56*-60*</p>
<p :class="{'activeClass': idClicked == NP3791 }" id="NP3791" >Boundary marker - - - IG I³ 1055B; disassociated from 1055A, “deme horos referring to the adjacent road (Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM586">586</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM600">600</a>) as the boundary between Melite to the north and Kollytos to the south,” Lalonde, G. V. 2002, 297; perhaps referring to the rock-cut path leading up to the sanctuary from below, K. Lazaridi, «Λοφος Νυμφών,» Deltion 52 (1997) <JBcom>[2002]</JBcom> B1 Chr., 40, pl. 21</p>
<p :class="{'activeClass': idClicked == NP3792 }" id="NP3792" >2* Precinct boundary stone (the inscription massively restored), 4th c. B.C., south of the Stoa of Attalos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20370" target="_blank">Agora I 370</a>; Meritt, B. D. 1946, 175 no. 22, dim photo; Agora III, 1957, no. 37; Ritchie Jr., C. E. 1985, 563-566; the restoration is highly questionable, Agora XIX, 1991, 24-25 H 15</p>
<p :class="{'activeClass': idClicked == NP3793 }" id="NP3793" >3* Garden of Zeus with well: Plin. Nat. 31.3.28</p>
<p :class="{'activeClass': idClicked == NP3794 }" id="NP3794" >4* Bronze statue in the Olympieion precinct: Paus. 1.18.7; see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM548">548</a></p>
<p :class="{'activeClass': idClicked == NP3795 }" id="NP3795" >5* Statue by Leochares on the the Akropolis: Paus. 1.24.4 (mentioned together with a statue of Zeus Polieus)</p>
<p :class="{'activeClass': idClicked == NP3796 }" id="NP3796" >6* Statue in the precinct of Dionysos Melpomenos: Paus. 1.2.5</p>
<p :class="{'activeClass': idClicked == NP3797 }" id="NP3797" >7* Vote of Zeus, Διός ψήφος, place on the Akropolis where Zeus cast his vote for Athena: Hsch. s.v. Διός Θάκοι; Frazer, J. G. 1913, II, 301-302 See also: Athena Ath29*; Hermes Hrm37* (grotto relief); Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM96">96</a> (thunderbolt marks), 109 (alleged copy of Myron's Zeus)</p>
<p :class="{'activeClass': idClicked == NP3798 }" id="NP3798" >8* ZEUS AGORAIOS, altar: Agora III, nos. 379-386; Agora XIV.135 with note 96, 161-162; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM253">253</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM268">268</a></p>
<p :class="{'activeClass': idClicked == NP3799 }" id="NP3799" >9* ZEUS ANCHESMIOS, statue on Anchesmos hill: Paus. 1.32.2; Langdon, Μ. K. 1976, 101-102</p>
<p :class="{'activeClass': idClicked == NP3800 }" id="NP3800" >10* ZEUS ASTRAPAIOS, eschara on the wall between the Pythion and the Olympieion where the Pythaistai kept watch for the lightning sign over Harma: Str. 9.2.11; on the City Wall, E. Meyer, REXXIV, 1963, col. 556 line 67 - col. 558 (Pythion); Wycherley, R. E. 1959, 68-72; Nulton, P. E. 2000, 13, 19-23; cf. Pelargikon; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM203">203</a></p>
<p :class="{'activeClass': idClicked == NP3801 }" id="NP3801" >11* ZEUS AT THE PALLADION <br>Cult: IG II² 2884 line 3; B. D. Meritt, “Greek Inscriptions,” Hesperia 9 (1940) 94-96</p>
<p :class="{'activeClass': idClicked == NP3803 }" id="NP3803" >12* Bouzyges priest's theater throne, 3rd/4th c. A.C. IG II² 5055; possibly the latest theater throne inscription, Maass, M. 1972, 125, pl. 14; Kearns, E. 1989, 152 See also: Palladion; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM164">164</a></p>
<p :class="{'activeClass': idClicked == NP3804 }" id="NP3804" >13* ZEUS BASILEIOS, cult: Poll. 8.122; A. L. Boegehold, “Philokleon's Court,” Hesperia 36 (1967) 116 note 24; Schwabl, H. 1978, cols. 1446-1447</p>
<p :class="{'activeClass': idClicked == NP3805 }" id="NP3805" >ZEUS BOULAIOS: Agora 111,128, nos. 384, 388, 394, 402, 406, 421-423, 431-432</p>
<p :class="{'activeClass': idClicked == NP3806 }" id="NP3806" >14* Xoanon in the Bouleuterion: Paus. 1.3.5; Agora 111, no. 402</p>
<p :class="{'activeClass': idClicked == NP3807 }" id="NP3807" >Zeus Boulaios and Athena Boulaia: Agora III, nos. 394, 422-423</p>
<p :class="{'activeClass': idClicked == NP3808 }" id="NP3808" >15* Sanctuary in the Bouleuterion: Antiphon VI.45; Agora 111, no. 394</p>
<p :class="{'activeClass': idClicked == NP3809 }" id="NP3809" >16* Priest's theater throne,. 150-200 A.D. - - - IG II² 5054; Maass, M. 1972, 56, 124-125, pl. 14</p>
<p :class="{'activeClass': idClicked == NP3810 }" id="NP3810" >17* Zeus Boulaios and Hestia Boulaia, dedication, 55/54 B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205543" target="_blank">Agora I 5543</a> +; Agora III, nos. 431-432; D. J. Geagan, “Greek Inscriptions from the Athenian Agora,” Hesperia 52 (1983) 161-162, pl. 42; SEG 33 (1983) no. 198; see Hestia Bouiaia</p>
<p :class="{'activeClass': idClicked == NP3811 }" id="NP3811" >18* ZEUS E, label (?) on an architectural block, mid-5th c. B.C. IG I³ 1056; <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202483" target="_blank">Agora I 2483</a>; Meritt, B. D. 1952, 374 no. 25, pl. 96; SEG 12 (1955) no. 64; Agora III, no.39; a boundary stone of Zeus Eleutherios, Agora XIX, 8, 23 H7</p>
<p :class="{'activeClass': idClicked == NP3812 }" id="NP3812" >ZEUS ELEUTHERIOS: Agora III, 25-30, nos. 24-46</p>
<p :class="{'activeClass': idClicked == NP3813 }" id="NP3813" >19* Stoa, last third of the 5th c. B.C., Agora west side: Paus. 1.3.3-4, 1.26.2, 10.21.6; Thompson, H. A. 1937, 5-59, figs. 2-35, pls. 1-2; Travlos, J. 1971, 527-533, figs. 29 no. 37, 655-672; Agora Guide 1990, 77-79, 275 no. 77, figs. 4-8, 18-19, 37, 56; cf. Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM324">324</a></p>
<p :class="{'activeClass': idClicked == NP3814 }" id="NP3814" >20* Benches along the inside walls on which Sokrates and Euripides sat together: POxy 2889, Aischines, Miltiades lines 5-7; Kuhn, G. 1985, 296</p>
<p :class="{'activeClass': idClicked == NP3815 }" id="NP3815" >21* Altar east of the Stoa of Zeus Eleutherios: Agora XIV, 96, 97, 103, 161-162, fig. 1, pls. 5-6, 8</p>
<p :class="{'activeClass': idClicked == NP3816 }" id="NP3816" >22* Statue in front of the stoa: Paus. 1.3.2; Agora III, nos. 16, 26-28, 35, 41; also called Zeus Soter, ibid., nos. 28-29, 42; perhaps the standing Zeus on Athenian bronze coins associated with Flamininus' victory over Philip V in 197 B.C., Agora XXVI, 51,54,55, 57, 63 nos. 78-80 with pl. 8, 87, 106 no. 148</p>
<p :class="{'activeClass': idClicked == NP3817 }" id="NP3817" >23* Shield of Kydias, killed fighting the Galatians at Thermopylai in 279 B.C., dedicated to Zeus Eleutherios, removed by Sulla: Paus. 10.21.5-6; Habicht, Chr. 1985, 83-84; the epigram preserved by Pausanias has the earliest mention of the Galatians, F. Chamoux, “1) Dedicace metrique pour le bouclier de l'Athenien Cydias,” REG 101 (1988) 498</p>
<p :class="{'activeClass': idClicked == NP3818 }" id="NP3818" >24* Leokritos' shield dedicated to Zeus Eleutherios by the Athenians with an inscription recording his name and successful accomplishments: Paus. 1.26.2</p>
<p :class="{'activeClass': idClicked == NP3819 }" id="NP3819" >25* ZEUS ENCHORIOS, boundary stone (?), 2nd-3rd c. A.C., Roman Agora - - - 1st Ephor. Rho Alpha 656; SEG 18 (1962) no. 62; Bull. 1961, no. 272; Ritchie Jr., C. E. 1985, 578-581</p>
<p :class="{'activeClass': idClicked == NP3820 }" id="NP3820" >26* ZEUS EPA<JBcom>[KRIOS?]</JBcom>, cult mentioned by an association of orgeones: IG II² 1294 line 3; Ferguson, W. S. 1944, 93-94 no. 11; SEG 50 (2000) no. 162</p>
<p :class="{'activeClass': idClicked == NP3821 }" id="NP3821" >27* ZEUS EPHOROS, arula, found near its rock-cut bedding along the way from the Propylaia to the Parthenon - - - IG II² 4740; K. S. Pittakis, «Σημειώσεις έπι των λιθογραφημάτων,» ΑΕ 1838, 103 no. 47</p>
<p :class="{'activeClass': idClicked == NP3822 }" id="NP3822" >28* ZEUS EXOU (?), sanctuary boundary stone, early 4th - late 2nd c. B.C.  <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205983" target="_blank">Agora I 5983</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 90 no. 37, pl. 22; Ritchie Jr., C. E. 1985, 567-571, fig. 95, pl. 105; G. V. Lalonde, “Agora I 5983: Zeus Exou-. . . Again,” Hesperia 68 (1999) 155-159; SEG 25 (1971) no. 199 and 49 (1999) no. 181</p>
<p :class="{'activeClass': idClicked == NP3823 }" id="NP3823" >29* ZEUS GELEON, cult: IG II² 1072 line 6; Threatte, L. 1980, 398, 408; Hartswick, K. J. 1990, 259</p>
<p :class="{'activeClass': idClicked == NP3824 }" id="NP3824" >30* ZEUS GEORGOS, cult in Athens or Attica: IG II² 1367 line 12; SEG 51 (2001) no. 138</p>
<p :class="{'activeClass': idClicked == NP3825 }" id="NP3825" >31* ZEUS HERAIOS, cult in Athens or Attica, more likely Attica: IG I³ 234 lines 20-21 = IG I² 840 line 20; Schwabl, H. 1978, col. 1068</p>
<p :class="{'activeClass': idClicked == NP3826 }" id="NP3826" >ZEUS HERKEIOS</p>
<p :class="{'activeClass': idClicked == NP3827 }" id="NP3827" >32* Altar on the Akropolis under the olive tree in the Pandroseion: D.H. Din. 3; Caskey, L. D. et al. 1927, 474-475</p>
<p :class="{'activeClass': idClicked == NP3828 }" id="NP3828" >33* Zeus Herkeios, Hermes, Akamas, altar, 3rd c. B.C., found in a late wall near the Dipylon Gate IG II² 4983; Kron, U. 1976, 145, 146; the altar has been <JBcom>[high-handedly]</JBcom> plunked on a base <JBcom>[to which it does not belong]</JBcom> just inside the Dipylon Gate</p>
<p :class="{'activeClass': idClicked == NP3829 }" id="NP3829" >ZEUS HIKESIOS: see Hikesios</p>
<p :class="{'activeClass': idClicked == NP3830 }" id="NP3830" >ZEUS HYPATOS: Schwabl, H. 1978, cols. 1074, 1476</p>
<p :class="{'activeClass': idClicked == NP3831 }" id="NP3831" >34* Altar in front of the Erechtheion: Paus. 1.26.5, 8.2.3; <JBcom>[wildly implausible]</JBcom> conjectural site in the Erechtheion east colonnade jammed up against the east cella wall in an unparalleled manner, Travlos, J. 1971, fig. 281 A; Kron, U. 1976, 44-46; cf. Thyechoos</p>
<p :class="{'activeClass': idClicked == NP3832 }" id="NP3832" >35* Dedication to Zeus Hypatos (?) by Artemidoros and his family, Thracians from Serdica, epigram with fragmentary relief of man and child at the Altar of Eleos, 1st c. A.C. (?), Odeion of Herodes Atticus - - - IG II² 4786; SEG 42 (1992) no. 159; Schnurr, C. 1995a, 133-134</p>
<p :class="{'activeClass': idClicked == NP3833 }" id="NP3833" >ZEUS HYPSISTOS, THEOS HYPSISTOS, HYPSISTOS: S. Mitchell, “The Cult of Theos Hypsistos between Pagans, Jews and Christians,” in Pagan Monotheism in Late Antiquity, P. Athanassiadi and M. Frede eds., Oxford, 1999, 81-148, including the invaluable Appendix, 128-147, listing 293 inscriptions; cf. SEG 49 (1999) no. 2495</p>
<p :class="{'activeClass': idClicked == NP3834 }" id="NP3834" >36* Sanctuary 2nd-3rd c. A.C., with 58 rock-cut niches in the rock-cut scarp south of the Pnyx bema: B. Forsen, “The Sanctuary of Zeus Hypsistos and the Assembly Place on the Pnyx,” Hesperia 62 (1993) 507-521, figs. 1-2, pls. 87-88; SEG 43 (1993) no. 70; <JBcom>[non-evidentially]</JBcom> dated to the end of the 1st c. A. C., B. Forsen, “The Sanctuary of Zeus Hypsistos and Pnyx III,” in Pnyx 1996, 47-55; for an <JBcom>[imaginary]</JBcom> open-air or roofed rock-cut cella, destroyed by <JBcom>[imaginary]</JBcom> quarrying operations ca. 330 B.C., with a <JBcom>[theoretical]</JBcom> cult statue, see Travlos, J. 1971, 466, 569 with fig. 595 (cella at lower right). <JBcom>[Since no epigraphical, literary or archaeological evidence supports the generally accepted founding date in the first century after Christ, the sanctuary may well have been founded in the late 2nd c. A.C. following the plague of 167-172 A.D.]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3835 }" id="NP3835" >37* Altar dedicated to Hypsistos, 21 m. northwest of the bema of the Pnyx Assembly Place: Thompson, H. A. 1936, 155-156, figs. 5-6</p>
<p :class="{'activeClass': idClicked == NP3836 }" id="NP3836" >38* Plaque with breasts, dedicated to Hypsistos IG II² 4783; Kourouniotis, K. and H. A. Thompson 1932, 197, fig. 59</p>
<p :class="{'activeClass': idClicked == NP3837 }" id="NP3837" >39* Plaque with vulva, dedicated to Hypsistos IG II² 4800; Forsen, B. 1996, 69 no. 8.18, fig. 65</p>
<p :class="{'activeClass': idClicked == NP3838 }" id="NP3838" >40* Plaque with breast, dedicated to Zeus Hypsistos IG II² 4802; Forsen, B. 1996, 63 no. 8.6, fig. 56</p>
<p :class="{'activeClass': idClicked == NP3839 }" id="NP3839" >41* Plaque dedicated to Theos Hypsistos - - - IG II² 4807</p>
<p :class="{'activeClass': idClicked == NP3840 }" id="NP3840" >Finds from the Agora Excavations, mainly in the southeast area (none in situ)</p>
<p :class="{'activeClass': idClicked == NP3841 }" id="NP3841" >42* Cylindrical altar dedicated to Hypsistos - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203551" target="_blank">Agora I 3551</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 23 (1954) 256 no. 40, pl. 54;SEG 14 (1957) no. 139</p>
<p :class="{'activeClass': idClicked == NP3842 }" id="NP3842" >43* Cylindrical altar dedicated to Hypsistos, 2nd/3rd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205960" target="_blank">Agora I 5960</a>; Meritt, B. D. 1948, 43 no. 34, pl. 15</p>
<p :class="{'activeClass': idClicked == NP3843 }" id="NP3843" >44* Cylindrical altar dedicated to Hypsistos, 2nd/3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206606" target="_blank">Agora I 6606</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 89-90, pl. 22; SEG 16 (1959) no. 185</p>
<p :class="{'activeClass': idClicked == NP3844 }" id="NP3844" >45* Arula with reliefs of boukrania and wheat sheaves dedicated to Hypsistos, 2nd/3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203794" target="_blank">Agora I 3794</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 29 (1960) 63 no.108, pl. 20; SEG 19 (1963) no. 226</p>
<p :class="{'activeClass': idClicked == NP3845 }" id="NP3845" >46* Dedication to Hypsistos with unexplained relief flanked by unexplained letters, 2nd/3rd c. A.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204294" target="_blank">Agora I 4294</a>; B. D: Meritt, op. cit. (no. 45 above), 63 no. 107, pl. 20; SEG 19 (1963) no. 225</p>
<p :class="{'activeClass': idClicked == NP3846 }" id="NP3846" >47* Eagle on an Ionic capital, relief dedicated to Theos Hypsistos, Akr. South Slope - - - NM 2388 (or NM 3288); IG II² 4782; Svoronos, J. N. 1937, no. 365, pl. 144; cf. SEG 46 (1996) no. 640</p>
<p :class="{'activeClass': idClicked == NP3847 }" id="NP3847" >48* Plaque with footprints, dedicated to Theos Hypsistos, 2nd c. A.C., Roman Agora - - - IG II² 4784; Guarducci, M. 1967-1978, III, 57-58, fig. 28; dated to 1st-2nd c. A.C., B. Forsen, op. cit. (Zeus Zeu36*), 516 no. 18; Forsen, B. 1996, 71-72 no. 8.23, fig. 68; Pritchett, W. K. 1998, 119-121</p>
<p :class="{'activeClass': idClicked == NP3848 }" id="NP3848" >49* ZEUS KARIOS, cult practiced in Isagoras' family: Hdt. 5.66; Kron, U. 1976, 16 note 12; D. M. Lewis, “Cleisthenes and Attica,” Historia 12 (1963) 26</p>
<p :class="{'activeClass': idClicked == NP3849 }" id="NP3849" >ZEUS KATAIBATES</p>
<p :class="{'activeClass': idClicked == NP3850 }" id="NP3850" >50* Abaton sanctuary marker, Akr. South Slope - - - IG II² 4964</p>
<p :class="{'activeClass': idClicked == NP3851 }" id="NP3851" >51* Abaton marker, built into the wall of a Turkish house north of the northeast corner of the Parthenon - - - IG II² 4965; for a strong hint that the abaton of Zeus Kataibates is in the Erechtheion North Porch, see Kron, U. 1976, 44 (enelysia), 45; Ritchie Jr., C. E. 1985, 572-577, pl. 106, fig. 96</p>
<p :class="{'activeClass': idClicked == NP3852 }" id="NP3852" >52* Base or altar, north of the Olympieion - - - IG II² 4998; Tolle-Kastenbein, R. 1994, 135-136 with note 674 <JBcom>[mistakenly]</JBcom> citing IG II² 4965 See also: Zeus Zeu67* (Zeus Morios = Kataibates Zeus); Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM96">96</a></p>
<p :class="{'activeClass': idClicked == NP3853 }" id="NP3853" >ZEUS KATHARSIOS: see Katharsios</p>
<p :class="{'activeClass': idClicked == NP3854 }" id="NP3854" >53* ZEUS KTESIOS, cult: <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204917" target="_blank">Agora I 4917</a> line 9; Agora XV, 4, 149 no. 171 </p>
<p :class="{'activeClass': idClicked == NP3855 }" id="NP3855" >ZEUS LAPHYSTIOS: see Phrixos</p>
<p :class="{'activeClass': idClicked == NP3856 }" id="NP3856" >54* ZEUS MAIMAKTES, cult: Plu. Moralia 458 B-C; Schwabl, H. 1978, col. 1069</p>
<p :class="{'activeClass': idClicked == NP3857 }" id="NP3857" >ZEUS MEILICHIOS, MEILICHIOS: Vikela, E. 1994, 71-74; Jameson, Μ. H. et al., 1993, 81-83 and Index s.v. Zeus Meilichios; cf. SEG 46 (1996) 2358</p>
<p :class="{'activeClass': idClicked == NP3858 }" id="NP3858" >55* Altar at the far side of the Kephisos crossing where Theseus was purified: Paus. 1.37.4; M. Jameson, “The Sacrificial Calendar from Erchia,” BCH 89 (1965) 161, 164</p>
<p :class="{'activeClass': idClicked == NP3859 }" id="NP3859" >Five dedications found in the Agora Excavations: A. E. Raubitschek, “Three Dedications to Zeus Meilichios,” Hesperia 12 (1943) 48-52; the dedications were probably brought into the Agora for reuse from a sanctuary on the eastern spur of the Hill of The Nymphs ( Zeus Zeu1* above)</p>
<p :class="{'activeClass': idClicked == NP3860 }" id="NP3860" >56* Snake relief, ca. 330 B.C., east of the Odeion of Agrippa <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202201" target="_blank">Agora I 2201</a>; A. E. Raubitschek, op. cit, 49-50 no. 9, ph.</p>
<p :class="{'activeClass': idClicked == NP3861 }" id="NP3861" >57* Snake relief, 4th c. B.C., east of the Odeion of Agrippa - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202474" target="_blank">Agora I 2474</a>; Meritt, B. D, 1952, 377-378 no. 33, pl. 97; SEG 12 (1955) no. 167</p>
<p :class="{'activeClass': idClicked == NP3862 }" id="NP3862" >58* Dedication (restored), 4th c. B.C., west of the Odeion of Agrippa - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202778" target="_blank">Agora I 2778</a>; A. E. Raubitschek, op. cit., 51-52 no. 11, ph.</p>
<p :class="{'activeClass': idClicked == NP3863 }" id="NP3863" >59* Relief frag, preserving figures of man and woman, 3rd c. B.C., west of the Odeion of Agrippa <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203868" target="_blank">Agora I 3868</a>; A. E. Raubitschek, op. cit, 51 no. 10, ph.; SEG 21 (1965) no. 790</p>
<p :class="{'activeClass': idClicked == NP3864 }" id="NP3864" >60* Relief frag., 4th c. B.C., west end of the South Stoa - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206599" target="_blank">Agora I 6599</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 32 (1963) 45 no. 58, pl. 2; SEG 21 (1965) no. 781</p>
<p :class="{'activeClass': idClicked == NP3865 }" id="NP3865" >61* Site of the Diasia festival on the Ilissos left bank at Agrai: EM 13163; SEG 21 (1965) no. 541, col. I, lines 38-40; the Diasia associated with the Lesser Mysteries, M. Jameson, op. cit. (no. Zeu55* above)159-162; Schwabl, H. 1978,, 1069-1070</p>
<p :class="{'activeClass': idClicked == NP3866 }" id="NP3866" >62* Dedication, 3rd c. B.C., north of the Hill of the Nymphs - - - IG II² 4677; S. A. Koumanoudes, «Έπιγραφαι Άττικής ανέκδοτοι,» Athenaion 8 (1879) 288</p>
<p :class="{'activeClass': idClicked == NP3867 }" id="NP3867" >63* Snake relief, Akr. South Slope, sanct. of Nymphe - - - 1st Ephor. NA 89-1957; SEG 19 (1963) no. 221; La Rocca, E. 1975, 444-445</p>
<p :class="{'activeClass': idClicked == NP3868 }" id="NP3868" >64* Snake relief, Aristomenes' dedication found at Vatrachonisi near the Ilissos - - - NM 3329; E. Mitropoulou, “Attic Workshops,” AAA 8 (1975) 121-122, fig. 4; Kaltsas, N. 2002, 224 no. 469, ph.</p>
<p :class="{'activeClass': idClicked == NP3869 }" id="NP3869" >65* Snake relief, dedicated to Meilichios, south of the Olympieion precinct wall: P. Lemerle, “Chronique des fouilles 1939,” BCH 63 (1939) 294</p>
<p :class="{'activeClass': idClicked == NP3870 }" id="NP3870" >66* Zeus Meilichios, Ge, Athena, sanctuary marker, 5th c. B.C., Ambelokipi in east Athens - - - IG I³ 1084 = IG I² 866; SEG 19 (1963) no. 30; Threatte, L. 1980, 24, 43 no.3, 194; A. G. Kaloyeropoulou, «Ορος Δίος Παρνησσίου,» HOROS 2 (1984) 114 note 9, fig. 3 See also: Helios and Zeus Meilichios; Herakles Hrk38*; Zeus Zeu1*</p>
<p :class="{'activeClass': idClicked == NP3871 }" id="NP3871" >ZEUS MOIRAGETES: see Moirai</p>
<p :class="{'activeClass': idClicked == NP3872 }" id="NP3872" >67* ZEUS MORIOS, altar of Kataibates Zeus also called Morios at the Academy, beside the sanct. of Athena: schol. S.OC 705; Billot, M.-F. 1989, 736-740</p>
<p :class="{'activeClass': idClicked == NP3873 }" id="NP3873" >ZEUS NAIOS: Schwabl, H. 1978, cols. 1071, 1465 lines 57-58</p>
<p :class="{'activeClass': idClicked == NP3874 }" id="NP3874" >68* Zeus Naios and Dione, inscribed relief frag. 4th c. B.C. Acr. 4887; Walter, O. 1923, 89 no. 183, ph.; probably a document relief perhaps from a decree concerning the oracle at Dodona, Palagia, O. 2002, 171-180, fig. 1</p>
<p :class="{'activeClass': idClicked == NP3875 }" id="NP3875" >69* Zeus Naios and the Synodos, arula, end of the 2nd c. B.C., west of the Parthenon IG II² 4707; O. Palagia, op. cit. 173-174, fig. 9</p>
<p :class="{'activeClass': idClicked == NP3876 }" id="NP3876" >70* ZEUS OLYMPIOS, sanctuary founded by Deukalion (Plan 2 eta—5/6): Paus. 1.18.6-8 + I. 19.1; founded 1528 B.C., Marmor Parium 7, IG XII 5, 444; Travlos, J. 1971, 402-411, figs. 380-381, 521-531; Wycherley, R. E. 1978, 155-164; Willers, D. 1990, 26-53, figs. 9-14, pls. 2-3; Tolle-Kastenbein, R. 1994, 117-120 (testimonia), 174-179 (bibliography)</p>
<p :class="{'activeClass': idClicked == NP3877 }" id="NP3877" >71* First temple, discovered by Penrose, the so-called pre-Peisistratid temple: Welter, G. 1922, 66-67, pl. 8; constructed between ca. 590-560 B.C., Tolle-Kastenbein, R.,1994, 134</p>
<p :class="{'activeClass': idClicked == NP3878 }" id="NP3878" >72* The Peisistratid temple, uncompleted Doric temple of poros limestone built by the architects Antistates, Kallaischros, Antimachides and Porinos, before 510 B.C.: Vitr. Preface to 7.15</p>
<p :class="{'activeClass': idClicked == NP3879 }" id="NP3879" >73* Altar fragments, second half of 6th c. B.C. NM _ ?; Welter, G. 1922, 65-66, fig.1; <JBcom>[where are these fragments, has anyone seen them?]</JBcom></p>
<p :class="{'activeClass': idClicked == NP3880 }" id="NP3880" >74* The third temple (the first marble temple) had three phases: <br> 1) The temple was designed and construction begun in the late classical period; M. Korres, «Ολυμπιείον,» Anthemio 5 (January 1999) 27-29 <br> 2) Temple built to the architrave level by the Roman architect, Decimus Cossutius, for Antiochos IV of Syria before 164 B.C: Vitr. Preface to 7.15; Tolle-Kastenbein, R. 1994, 117-119 (testimonia for the Hellenistic Olympieion); Schenkungen hellenistischer Herrscher, Part I, 1995, 54-56, and Part II, vol. 2, 2000, 268-270 <br> 3) Temple completed under Hadrian by 131/132 A.D.: Paus. 1.18.6, 1.19.1.</p>
<p :class="{'activeClass': idClicked == NP3881 }" id="NP3881" >Three-storey structure on top of the temple architraves, built before 1000 A.D., demolished ca. 1870, perhaps a fortification: Charalambos Bouras, “The So-called Cell of the Athenian Stylite,” in Architectural Studies in Memory of Richard Krautheimer, edd. C. L. Striker et al., Mainz, 1996, 23-26, pls. 10-11</p>
<p :class="{'activeClass': idClicked == NP3882 }" id="NP3882" >75* Gold and ivory temple statue: Paus. 1.18.6; 2.27.2; most likely a copy of Pheidias' temple statue at Olympia as indicated by the inscriptions on the theater thrones Zeu81* and Zeu83* below</p>
<p :class="{'activeClass': idClicked == NP3883 }" id="NP3883" >76* Precinct wall: Paus. 1.18.6 + 7; Stuart, J. and N. Revett 1764, 14-15, 16, 17, 22, 23, Chapter 2 pls. 1-3, Chapter 3 pl. 1</p>
<p :class="{'activeClass': idClicked == NP3884 }" id="NP3884" >77* Propylon toward the eastern end of the north precinct wall: Daux, G. 1960, 637 fig. 1, fig. 2 Alpha, fig. 5</p>
<p :class="{'activeClass': idClicked == NP3885 }" id="NP3885" >78* Exedra toward the western end of the north precinct wall: G. Welter, “Das Olympieion in Athen. II .” AM 48 (1923) 189, pl. 2 no. 4; the exedra was beside a propylon facing Hadrian's arch, the main entrance to the precinct, W. Dörpfeld, “Ausgrabungen: 6. Athen,”, AM 11 (1886) 331-332</p>
<p :class="{'activeClass': idClicked == NP3886 }" id="NP3886" >79* Priest's theater throne - - - IG II² 5025; Maass, M. 1972, 105, pl. VI</p>
<p :class="{'activeClass': idClicked == NP3887 }" id="NP3887" >80* Priests' (theekoloi) theater seat — IG II² 5085; Tolle-Kastenbein, R. 1994, 120 (testimonia nos. 56-57), 159 with note 914.</p>
<p :class="{'activeClass': idClicked == NP3888 }" id="NP3888" >81* Phaidyntes' theater throne, for the cleanser of the cult statue in Athens, 131/2 A.D. - - - IG II² 5072; Maass, M. 1972, 131, 135, pl. 19; Tolle-Kastenbein, R. 1994, 159 with notes 910-914</p>
<p :class="{'activeClass': idClicked == NP3889 }" id="NP3889" >82* Temenos boundary stone (rest.), early 4th c. B.C. - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206373" target="_blank">Agora I 6373</a>; B. D. Meritt, “Greek Inscriptions,” Hesperia 26 (1957) 91 no. 39, pl. 23; SEG 16 (1959) no. 148; attribution to an <JBcom>[alleged]</JBcom> Olympieion on the Akropolis North Slope <JBcom>[rightly]</JBcom> rejected, Tolle-Kastenbein, R. 1994, 142 with note 725; Nulton, P. E. 2000, 28-29</p>
<p :class="{'activeClass': idClicked == NP3890 }" id="NP3890" >83* Phaidyntes' theater throne for the cleaner of the statue of Zeus Olympios at Pisa (Olympia) - - - IG II² 5064; Maass, M. 1972, 131, pl. 17; cf. Paus. 5.14.5 cf. Dionysos Dio7*; Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM197">197</a></p>
<p :class="{'activeClass': idClicked == NP3891 }" id="NP3891" >ZEUS OMBRIOS: A. E. Raubitschek, “Greek Inscriptions: Altars of Zeus Ombrios,” Hesperia 12 (1943) 72-73, nos. 19-21, ph.</p>
<p :class="{'activeClass': idClicked == NP3892 }" id="NP3892" >84* Altar, 2nd c. A.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201797" target="_blank">Agora I 1797</a> + I 4825; Meritt, B. D. 1968, 291, pl. 86; SEG 25 (1971) no. 225</p>
<p :class="{'activeClass': idClicked == NP3893 }" id="NP3893" >85* Altar, ca. 100 A.D., southwest of the Eleusinion - - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204804" target="_blank">Agora I 4804</a>; A. E. Raubitschek, op. cit, 73 no. 21, ph.</p>
<p :class="{'activeClass': idClicked == NP3894 }" id="NP3894" >ZEUS PANHELLENIOS: see Panhellenion</p>
<p :class="{'activeClass': idClicked == NP3895 }" id="NP3895" >86* ZEUS PANKRATES, cult: EM 13330 lines 38-39; Schwabl, H. 1978, cols. 1072, 1469 -1470</p>
<p :class="{'activeClass': idClicked == NP3896 }" id="NP3896" >87* ZEUS PARNESSIOS, boundary stone, 480-460 B.C.?, corner of Argous and Monastiriou Sts. (Plan 2 beta—1) — IG I³ 1057 bis; 3rd Ephor. M. 1539; A. G. Kaloyeropoulou, «Ὅρος Δίος Παρνησσίου,» HOROS 2 (1984) 111-118, figs. 1-2; SEG 34 (1984) no. 39</p>
<p :class="{'activeClass': idClicked == NP3897 }" id="NP3897" >ZEUS PHILIOS</p>
<p :class="{'activeClass': idClicked == NP3898 }" id="NP3898" >88* Dedication on a base for two statues, 400-350 B.C., in the Byzantine wall above the Odeion of Herodes Atticus - - - IG II² 4555; the cult of Zeus Philios was probably in the Asklepieion on the Akropolis South Slope, G. Welter, “Eine Weihung an Zeus Philios,” AM 50 (1925) 165-166</p>
<p :class="{'activeClass': idClicked == NP3899 }" id="NP3899" >89* Dedication, 350-300 B.C., south of the Nike Bastion Acr. 7019 (formerly 1st Ephor. B. Per. no. 261); Daux, G. 1961, 607 fig. 13; SEG 44 (1994) no. 135</p>
<p :class="{'activeClass': idClicked == NP3900 }" id="NP3900" >90* Relief of enthroned Zeus, with eagle, altar, worshipper(s) and sacrificial pig, dedicated by eranistai, 324/3 B.C., Hill of the Nymphs north slope - - - IG II² 2935; F. T. van Straten, “Did the Greeks kneel before their Gods,” BABesch 49 (1974) 164 with note 39, fig. 7; Vikela, E. 1994, 279 (Museumsregister) under EM 8738</p>
<p :class="{'activeClass': idClicked == NP3901 }" id="NP3901" >91* Relief, Zeus Philios (?) Acr. 2401 +; O. Walter, “Kniende Adoranten auf attischen Reliefs,” JOAI 13 (1910) Beibl. 228-235, fig. 141</p>
<p :class="{'activeClass': idClicked == NP3902 }" id="NP3902" >92* Priest's theater throne, late 2nd-early 1st c. B.C. - - - IG II² 5066; Maass, M. 1972, 52-53, 132, pl. 18</p>
<p :class="{'activeClass': idClicked == NP3903 }" id="NP3903" >ZEUS PHRATRIOS AND ATHENA PHRATRIA: Hedrick Jr., C. W. 1984, 504 s.v. Athena Phratria, Zeus Phratrios; C. W. Hedrick, Jr. “Phratry Shrines of Attica and Athens,” Hesperia 60 (1991) 246-247 nos. 14-16 and 256-259</p>
<p :class="{'activeClass': idClicked == NP3904 }" id="NP3904" >93* Altar, 3rd c. B.C, northwest Athens, Vissis and Voreou Sts. off Athinas St. (Plan 2 epsilon-4)- - - <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206709" target="_blank">Agora I 6709</a>; Kyparissis, N. and H. A. Thompson, 1938, 612-619, figs. 1-6; Travlos, J. 1971, 573-575, figs. 219 no. 241, 719-721; C. W. Hedrick, Jr., op. cit, 256-259; SEG 41 (1991) no. 183</p>
<p :class="{'activeClass': idClicked == NP3905 }" id="NP3905" >94* Altar, ca. 350 B.C., found in front of the Stoa of Attalos, <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203706" target="_blank">Agora I 3706</a>; now set up on a base <JBcom>[on which it does not belong]</JBcom> east of a naiskos (Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM364">364</a>) beside the Temple of Apollo Patroos, Agora XIV,140, pl. 68:b; disconnected from the naiskos, linked to a phratry shrine elsewhere, Hedrick Jr., C. W. 1988, 192/193, fig. 6; SEG 38 (1988) no. 194</p>
<p :class="{'activeClass': idClicked == NP3906 }" id="NP3906" >95* Sanctuary boundary stone of Zeus Phratrios and Athena, inscribed on a marble rooftile after breakage, 4th c. B.C., found near the Stoa of Attalos IG II² 4975; Hedrick Jr., C. W. 1984, 324-327; SEG 38 (1988) no. 170 See also: Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM364">364</a></p>
<p :class="{'activeClass': idClicked == NP3907 }" id="NP3907" >96* ZEUS POLIEUS, sanctuary on the Akropolis: IG I³ 326 line 20; Stevens, G. P. 1946a, 12-15, fig. 3 no. 10, figs. 17-18; cf. Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM56">56</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM99">99</a></p>
<p :class="{'activeClass': idClicked == NP3908 }" id="NP3908" >97* Altar: Paus. 1.24.4, 1.28.10; Gill, G. 1991, 28 no. 5</p>
<p :class="{'activeClass': idClicked == NP3909 }" id="NP3909" >98* Base or throne, 4th c. B.C. - - - IG II² 4626; Maass, M. 1972, 104 </p>
<p :class="{'activeClass': idClicked == NP3909b }" id="NP3909b" >99* Statue: Paus. 1.24.4</p>
<p :class="{'activeClass': idClicked == NP3910 }" id="NP3910" >100* Priest's theater throne IG II² 5024; Maass, M. 1972, 52, 104, pl. VI</p>
<p :class="{'activeClass': idClicked == NP3911 }" id="NP3911" >100** ZEUS POLIEUS (continued), abaton, boundary stone, Diamantopoulou St. 1 off Perraibou St. (Plan 2 (theta—6) 3rd Ephor. _ (inv. no., if any, not known); Kathimerini, English edition, June 1, 2001</p>
<p :class="{'activeClass': idClicked == NP3912 }" id="NP3912" >101* ZEUS SABAZIOS, dedication, inscribed bronze hand, 25 B.C.- 50 A.D., Asklepieion — Paris, Bibliotheque National, inv. no. unknown; Maarten J. Vermaseren, Corpus Cultus Iovis Sabazii I, Leiden, 1983, 2-3 no. 5, ph.; date provided by Eugene Lane, December 18, 1986</p>
<p :class="{'activeClass': idClicked == NP3913 }" id="NP3913" >ZEUS SOTER: Agora III, 25, 30 and nos. 24, 27-29, 42-3</p>
<p :class="{'activeClass': idClicked == NP3914 }" id="NP3914" >102* Sanctuary: schol. Ar. PL 1175; Agora III, nos. 24, 43 and cf. nos. 37, 40, 46 Statue: see Zeus Zeu22*</p>
<p :class="{'activeClass': idClicked == NP3915 }" id="NP3915" >103* Zeus Soter and Athena Soteira, priest's theater throne - - - IG II² 5063; Maas, M. 1972, 39, 129-130, pl. 16</p>
<p :class="{'activeClass': idClicked == NP3916 }" id="NP3916" >ZEUS STRATIOS: Schwabl, H. 1978, col. 1073</p>
<p :class="{'activeClass': idClicked == NP3917 }" id="NP3917" >104* Altar base dedicated by two men of Germanikopolis, 1st c. A.C., near the Giants (i.e. Odeion of Agrippa) IG II² 4723; SEG 28 (1978) no. 230</p>
<p :class="{'activeClass': idClicked == NP3918 }" id="NP3918" >105* Dedication with an outline representation of an altar and twining tree trunks, near the Erechtheion IG II² 4739 = 4812; Peek, W. 1942, 56 no. 93, 58 no. 98, pl. 17 no. 2</p>
<p :class="{'activeClass': idClicked == NP3919 }" id="NP3919" >106* Base, northern Athens at the Varvakeion in the city block bounded by Sokratous, Sophokleous, Athinas and Evripidou Sts. (Plan 2 delta—4) IG II² 4785</p>
<p :class="{'activeClass': idClicked == NP3920 }" id="NP3920" >107* Dedication, Acharnon St. (Plan 2 alpha/beta—4) IG II² 4844</p>
<p :class="{'activeClass': idClicked == NP3921 }" id="NP3921" >ZEUS TELEIOS: Schwabl, H. 1978, cols. 1074, 1475 line 42</p>
<p :class="{'activeClass': idClicked == NP3922 }" id="NP3922" >108* Dedication, ca. 200 B.C. <a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20608" target="_blank">Agora I 608</a> +; Meritt, B. D. 1946, 220-221 no. 47, ph.</p>
<p :class="{'activeClass': idClicked == NP3923 }" id="NP3923" >109* Bouzyges priest's theater throne IG II² 5075; Maass, M. 1972, 136-137, pl. 20; Kearns, E. 1989, 152</p>
<p :class="{'activeClass': idClicked == NP3924 }" id="NP3924" >110* ZEUS XENIOS, boundary stone of a sanctuary belonging to the phratry Thymaitis, 5th-4th c. B.C., built into a late wall in the Areopagus-Pnyx Valley IG I³ 1057 = IG I² 886; C. W. Hedrick, Jr., “The Thymaitian Phratry,” Hesperia 57 (1988) 81-85; Lambert, S. D. 1993, 327-329 T 13</p>
<p :class="{'activeClass': idClicked == NP3925 }" id="NP3925" >ZEUS AND ATHENA: see Athena Ath129*, Ath133*</p>
<p :class="{'activeClass': idClicked == NP3926 }" id="NP3926" >111* ZEUS AND HERA, votive relief from the Elgin Collection, Athens ? - - - BM cat. no. 770; (Smith, A. H. 1892, 354) See also: Athena Ath121*, document relief</p>
<p :class="{'activeClass': idClicked == NP3927 }" id="NP3927" >ZEUS AND TYPHON: see Athena Ath19*</p>
<p :class="{'activeClass': idClicked == NP3928 }" id="NP3928" >ZEUS AND OTHERS: see Hermes Hrm37*; Athena Ath129*</p>
<p :class="{'activeClass': idClicked == NP3929 }" id="NP3929" >ZEUS' THUNDERBOLT: see Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM96">96</a></p>
<p :class="{'activeClass': idClicked == NP3930 }" id="NP3930" >ZEUXIPPOS AND BASILEIA, banqueting hero relief, 4th c. B.C. — IG II² 4645; Thonges-Stringaris, R. N. 1965, 49, 75 no. 42; Kearns, E. 1989, 165-166</p>
<p :class="{'activeClass': idClicked == NP3931 }" id="NP3931" >ZOSTER: see Apollo Apo81*</p>
<p :class="{'activeClass': idClicked == NP3932 }" id="NP3932" >ZOTIKOS, EROS, EUKARPOS, ATTIKOS,and EPIKTAS, friends, rock-cut inscription, Akropolis South Slope, east of the Thrasyllos cavern and higher up - - - IG II² 1987; Levensohn, M. and E. 1947, 68, fig. 1 no. 71, pl. 12</p>



</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    JBcom {color:red}
bibl {color:gray}
inscr {color:brown}
.ambiguous {color:red} 
    </style>
    