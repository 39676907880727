<template>

    <div class="part-text">

<h3 :class="{'activeClass': idClicked == I747 }" id="I747">INDEX III ATHENS AFTER A.D. 500</h3>
<ul>
<li :class="{'activeClass': idClicked == I748 }" id="I748">ACCIAJUOLI: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM11">11</a></li> 
<li :class="{'activeClass': idClicked == I749 }" id="I749">ARABIC INSCRIPTION: Islamic Mon., mosque with Kufic Arabic; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM9">9</a></li> 
<li :class="{'activeClass': idClicked == I750 }" id="I750">ARISTOTELIS STUDIUM: Aristotle</li> 
<li :class="{'activeClass': idClicked == I751 }" id="I751">BATHS: Islamic and Ottoman Mon.; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM294">294</a></li> 
<li :class="{'activeClass': idClicked == I752 }" id="I752">BRIDGE above <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2324'>Kallirrhoe</a>: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2242'>Ilissos</a></li> 
<li :class="{'activeClass': idClicked == I753 }" id="I753">BYRON, statuettes owned by: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP259'>Aph16*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP260'>Aph17*</a></li> 
<li :class="{'activeClass': idClicked == I754 }" id="I754">CAVES: see under SPILIA </li> 
<li :class="{'activeClass': idClicked == I755 }" id="I755">CHOISEUL-GOUFFIER, graffito: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a> </li> 
<li :class="{'activeClass': idClicked == I756 }" id="I756">CHRISTIAN ATHENS: see Index II Christian Athens </li> 
<li :class="{'activeClass': idClicked == I757 }" id="I757">CHURCHES: see Index II Christian Athens </li> 
<li :class="{'activeClass': idClicked == I758 }" id="I758">CINDERHILL: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3453'>Stachtothiki</a> </li> 
<li :class="{'activeClass': idClicked == I759 }" id="I759">CISTERNS: see under Reservoirs </li> 
<li :class="{'activeClass': idClicked == I760 }" id="I760">DEMOSTHENES' LANTERN: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2574'>Lysikrates</a> </li> 
<li :class="{'activeClass': idClicked == I761 }" id="I761">DIOGENES' LANTERN: Diogenes </li> 
<li :class="{'activeClass': idClicked == I762 }" id="I762">DIONYSIOS THE AREOPAGITE</li> 
<li :class="{'activeClass': idClicked == I763 }" id="I763">DOORWAYS: Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM14">14</a> (in Mnesiklean Bastion), <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM139">139</a> (Akr. south slope)</li> 
<li :class="{'activeClass': idClicked == I764 }" id="I764">ELGIN: Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1031'>CM10*</a></li> 
<li :class="{'activeClass': idClicked == I765 }" id="I765">ENCLOSURE WALL, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP115'>Akropolis</a> west slope: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM221">221</a> </li> 
<li :class="{'activeClass': idClicked == I766 }" id="I766">FAUVEL, graffito: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == I767 }" id="I767">FORTIFICATIONS: City Wall before 1687; City Wall in 1778; Hypapantis Wall; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2501'>Leon the Metropolitan</a> (11th c. tower); <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2817'>Odysseus Bastion</a>; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3310'>Rizokastron</a>; Serpentze; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3566'>Wal37*</a>; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3880'>Zeu74*</a>(three-storey structure on <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3876'>Olympieion</a> architraves); Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM11">11</a>-12, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM20">20</a>-22, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM25">25</a>-33, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM39">39</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM137">137</a></li> 
<li :class="{'activeClass': idClicked == I768 }" id="I768">FOUCHEROT, graffito: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == I769 }" id="I769">FOUNTAIN HOUSE, SPRING HOUSE: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a> (2)</li> 
<li :class="{'activeClass': idClicked == I770 }" id="I770">FRANKISH MONUMENTS: Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1034'>CM11*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1083'>CM29*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1144'>CM75*</a>; Frankish Pavilion; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3000'>Paul</a>; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3310'>Rizokastron</a> Wall; Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM10">10</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM11">11</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM12">12</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM39">39</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM137">137</a></li> 
<li :class="{'activeClass': idClicked == I771 }" id="I771">GATES: City Wall, 1778, with seven gates; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3310'>Rizokastron</a>; Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM26">26</a>-29 </li> 
<li :class="{'activeClass': idClicked == I772 }" id="I772">GLASS FACTORY: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM393">393</a></li> 
<li :class="{'activeClass': idClicked == I773 }" id="I773">GRAVESTONES: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a>; see also Index II under Graves </li> 
<li :class="{'activeClass': idClicked == I774 }" id="I774">GRAVEYARD: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a> </li> 
<li :class="{'activeClass': idClicked == I775 }" id="I775">HASEKI WALL: City Wall 1778 A.D.</li> 
<li :class="{'activeClass': idClicked == I776 }" id="I776">HOUSE: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM291">291</a></li> 
<li :class="{'activeClass': idClicked == I777 }" id="I777">HYPAPANTI WALL</li> 
<li :class="{'activeClass': idClicked == I778 }" id="I778">INSCRIPTIONS Arabic: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM9">9</a> Greek: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM24">24</a>; Kufic Arabic: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a>; Latin: Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1034'>CM11*</a>; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3000'>Paul</a>; Turkish: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM30">30</a> (<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1628'>Erechtheion</a> architrave block)</li> 
<li :class="{'activeClass': idClicked == I779 }" id="I779">“INVALIDENWOHNUNG”: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM28">28</a></li> 
<li :class="{'activeClass': idClicked == I780 }" id="I780">ISLAMIC and OTTOMAN MONUMENTS</li> 
<li :class="{'activeClass': idClicked == I781 }" id="I781">ITTAR, graffito: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM178">178</a></li> 
<li :class="{'activeClass': idClicked == I782 }" id="I782">KASTRO, place-name</li> 
<li :class="{'activeClass': idClicked == I783 }" id="I783">KATHIMIA, place-name</li> 
<li :class="{'activeClass': idClicked == I784 }" id="I784">KILNS: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM140">140</a> (Akr. south slope, 13th c.), no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM404">404</a> (<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2365'>Kerameikos</a> Exc.)</li> 
<li :class="{'activeClass': idClicked == I785 }" id="I785">LIONDARI TOWER: Hypapantis Wall </li> 
<li :class="{'activeClass': idClicked == I786 }" id="I786">MENDRESES: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a> </li> 
<li :class="{'activeClass': idClicked == I787 }" id="I787">MOSAICS: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM409">409</a></li> 
<li :class="{'activeClass': idClicked == I788 }" id="I788">MOSQUES: Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1049'>CM12*</a> (mosque in the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a>); <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a> (6)</li> 
<li :class="{'activeClass': idClicked == I789 }" id="I789">ODYSSEUS BASTION</li> 
<li :class="{'activeClass': idClicked == I790 }" id="I790">OLIVE PRESS: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP446'>Ares</a> Temple</li> 
<li :class="{'activeClass': idClicked == I791 }" id="I791">OTTOMAN MONUMENTS: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a>; Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM21">21</a>,26-32 </li> 
<li :class="{'activeClass': idClicked == I792 }" id="I792">PLATO'S PAVILION: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP189'>Andronikos Kyrrhestes</a> </li> 
<li :class="{'activeClass': idClicked == I793 }" id="I793">POTTERY WORKS: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM213">213</a> (Byz. period)</li> 
<li :class="{'activeClass': idClicked == I794 }" id="I794">RESERVOIRS: Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM73">73</a>-80, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM156">156</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM206">206</a></li> 
<li :class="{'activeClass': idClicked == I795 }" id="I795">RIZOKASTRON</li> 
<li :class="{'activeClass': idClicked == I796 }" id="I796">SERPENTZE WALL</li> 
<li :class="{'activeClass': idClicked == I797 }" id="I797">SETINES</li> 
<li :class="{'activeClass': idClicked == I798 }" id="I798">SOCRATIS TEMPLUM: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP189'>Andronikos Kyrrhestes</a> </li> 
<li :class="{'activeClass': idClicked == I799 }" id="I799">SPILIA POLEMOU, cave in southwest Athens: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM240">240</a> </li> 
<li :class="{'activeClass': idClicked == I800 }" id="I800">SPILIA TON MOIRON: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2917'>Panathenaic Stadium</a> </li> 
<li :class="{'activeClass': idClicked == I801 }" id="I801">STACHTOTHIKl (Cinder Hill)</li> 
<li :class="{'activeClass': idClicked == I802 }" id="I802">STAIRWAY through the cleft in the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP115'>Akropolis</a> north slope: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM198">198</a> (2)</li> 
<li :class="{'activeClass': idClicked == I803 }" id="I803">TEKES TOU BRAIMI: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP189'>Andronikos Kyrrhestes</a></li> 
<li :class="{'activeClass': idClicked == I804 }" id="I804">THEMISTOKLEAN CITY WALL IN LATER TIMES: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3560'>Wal33*</a>-37*</li> 
<li :class="{'activeClass': idClicked == I805 }" id="I805">TOWERS: Hypapantis Wall, Liondari Tower; <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2501'>Leon the Metropolitan</a>; Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM11">11</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM12">12</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM20">20</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM33">33</a></li> 
<li :class="{'activeClass': idClicked == I806 }" id="I806">TUNNEL BELOW THE NIKE BASTION: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM148">148</a> </li> 
<li :class="{'activeClass': idClicked == I807 }" id="I807">VOIVODALIKI: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2271'>Islamic and Ottoman Monuments</a> </li> 
<li :class="{'activeClass': idClicked == I808 }" id="I808">WATER MILL south of the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP868'>Stoa of Attalos</a>: Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM301">301</a></li> 
</ul>



</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    