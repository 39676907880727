<template>

    <div class="part-text">


<h3 :class="{'activeClass': idClicked == I2983 }" id="I2983"><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> INDEX</h3>
<ul>
<li :class="{'activeClass': idClicked == I2984 }" id="I2984"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 3 (1929)</b></li> 
<li :class="{'activeClass': idClicked == I2985 }" id="I2985">SEG_3 115	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a></li> 
<li :class="{'activeClass': idClicked == I2986 }" id="I2986">SEG_3 116	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1970'>Hrk39*</a></li> 
<li :class="{'activeClass': idClicked == I2987 }" id="I2987">SEG_3 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP160'>Amphiaraos</a></li> 
<li :class="{'activeClass': idClicked == I2988 }" id="I2988">SEG_3 149	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP160'>Amphiaraos</a></li> 
<li :class="{'activeClass': idClicked == I2989 }" id="I2989">SEG_3 226	Heliodoros</li> 
<li :class="{'activeClass': idClicked == I2990 }" id="I2990"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 10 (1949)</b></li> 
<li :class="{'activeClass': idClicked == I2991 }" id="I2991">SEG_10 27	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1671'>Eros</a></li> 
<li :class="{'activeClass': idClicked == I2992 }" id="I2992">SEG_10 324	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1671'>Eros</a></li> 
<li :class="{'activeClass': idClicked == I2993 }" id="I2993">SEG_10 325	Lakedaimonian</li> 
<li :class="{'activeClass': idClicked == I2994 }" id="I2994">SEG_10 359	Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM100">100</a>/101</li> 
<li :class="{'activeClass': idClicked == I2995 }" id="I2995">SEG_10 364	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP814'>Ath109*</a></li> 
<li :class="{'activeClass': idClicked == I2996 }" id="I2996">SEG_10 466	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == I2997 }" id="I2997"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 12 (1955)</b></li> 
<li :class="{'activeClass': idClicked == I2998 }" id="I2998">SEG_12 12:D6	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP993'>Chairion</a></li> 
<li :class="{'activeClass': idClicked == I2999 }" id="I2999">SEG_12 64	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3811'>Zeu18*</a></li> 
<li :class="{'activeClass': idClicked == I3000 }" id="I3000">SEG_12 100	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2824'>Oikia Demosia</a></li> 
<li :class="{'activeClass': idClicked == I3001 }" id="I3001">SEG_12 150	Herod</li> 
<li :class="{'activeClass': idClicked == I3002 }" id="I3002">SEG_12 162	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP160'>Amphiaraos</a></li> 
<li :class="{'activeClass': idClicked == I3003 }" id="I3003">SEG_12 167	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3861'>Zeu57*</a></li> 
<li :class="{'activeClass': idClicked == I3004 }" id="I3004">SEG_12 168	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2026'>Hrm26*</a></li> 
<li :class="{'activeClass': idClicked == I3005 }" id="I3005"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 13 (1956)</b></li> 
<li :class="{'activeClass': idClicked == I3006 }" id="I3006">SEG_13 63	Hordeonius</li> 
<li :class="{'activeClass': idClicked == I3007 }" id="I3007"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 14 (1957)</b></li> 
<li :class="{'activeClass': idClicked == I3008 }" id="I3008">SEG_14 27	Propylon</li> 
<li :class="{'activeClass': idClicked == I3009 }" id="I3009">SEG_14 121	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a></li> 
<li :class="{'activeClass': idClicked == I3010 }" id="I3010">SEG_14 139	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3841'>Zeu42*</a></li> 
<li :class="{'activeClass': idClicked == I3011 }" id="I3011">SEG_14 140	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM122">122</a></li> 
<li :class="{'activeClass': idClicked == I3012 }" id="I3012">SEG_14 295	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2'>Abecedarium</a></li> 
<li :class="{'activeClass': idClicked == I3013 }" id="I3013"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 15 (1958)</b></li> 
<li :class="{'activeClass': idClicked == I3014 }" id="I3014">SEG_15 98	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1603'>Ephebes</a></li> 
<li :class="{'activeClass': idClicked == I3015 }" id="I3015"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 16 (1959)</b></li> 
<li :class="{'activeClass': idClicked == I3016 }" id="I3016">SEG_16 14	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP315'>Apo4*</a></li> 
<li :class="{'activeClass': idClicked == I3017 }" id="I3017">SEG_16 15	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2000'>Hrm2*</a></li> 
<li :class="{'activeClass': idClicked == I3018 }" id="I3018">SEG_16 24	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP754'>Ath64*</a>/65*</li> 
<li :class="{'activeClass': idClicked == I3019 }" id="I3019">SEG_16 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3889'>Zeu82*</a></li> 
<li :class="{'activeClass': idClicked == I3020 }" id="I3020">SEG_16 166	Barbarus</li> 
<li :class="{'activeClass': idClicked == I3021 }" id="I3021">SEG_16 167	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP179'>Anakreon</a></li> 
<li :class="{'activeClass': idClicked == I3022 }" id="I3022">SEG_16 168	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP755'>Ath65*</a></li> 
<li :class="{'activeClass': idClicked == I3023 }" id="I3023">SEG_16 172	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3734'>Twelve Gods</a></li> 
<li :class="{'activeClass': idClicked == I3024 }" id="I3024">SEG_16 176	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2804'>Nym6*</a></li> 
<li :class="{'activeClass': idClicked == I3025 }" id="I3025">SEG_16 181	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3026 }" id="I3026">SEG_16 182	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3027 }" id="I3027">SEG_16 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3028 }" id="I3028">SEG_16 184	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2878'>Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I3029 }" id="I3029">SEG_16 185	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3843'>Zeu44*</a></li> 
<li :class="{'activeClass': idClicked == I3030 }" id="I3030"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 17 (1960)</b></li> 
<li :class="{'activeClass': idClicked == I3031 }" id="I3031">SEG_17 10	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == I3032 }" id="I3032">SEG_17 13	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1931'>Hrk10*</a></li> 
<li :class="{'activeClass': idClicked == I3033 }" id="I3033">SEG_17 63	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP441'>Areopagus</a></li> 
<li :class="{'activeClass': idClicked == I3034 }" id="I3034">SEG_17 68	Attalos II</li> 
<li :class="{'activeClass': idClicked == I3035 }" id="I3035">SEG_17 75	Brutus</li> 
<li :class="{'activeClass': idClicked == I3036 }" id="I3036">SEG_17 80	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2802'>Nym4*</a></li> 
<li :class="{'activeClass': idClicked == I3037 }" id="I3037">SEG_17 81	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2812'>Nym12*</a></li> 
<li :class="{'activeClass': idClicked == I3038 }" id="I3038">SEG_17 88	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2899'>Pan11*</a></li> 
<li :class="{'activeClass': idClicked == I3039 }" id="I3039"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 18 (1962)</b></li> 
<li :class="{'activeClass': idClicked == I3040 }" id="I3040">SEG_18 51	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2469'>Kynosarges</a></li> 
<li :class="{'activeClass': idClicked == I3041 }" id="I3041">SEG_18 62	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3819'>Zeu25*</a></li> 
<li :class="{'activeClass': idClicked == I3042 }" id="I3042"><inscr id='SEG_18 73'>SEG_18 73 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/291061' target='_blank'>PHI</a>] </inscr>-80	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3043 }" id="I3043">SEG_18 80(a)	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3501'>Telekles</a></li> 
<li :class="{'activeClass': idClicked == I3044 }" id="I3044">SEG_18 87	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2325'>Kalliste</a></li> 
<li :class="{'activeClass': idClicked == I3045 }" id="I3045">SEG_18 88	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I3046 }" id="I3046"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 19 (1963)</b></li> 
<li :class="{'activeClass': idClicked == I3047 }" id="I3047">SEG_19 30	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3870'>Zeu66*</a></li> 
<li :class="{'activeClass': idClicked == I3048 }" id="I3048">SEG_19 150	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2069'>Her18*</a></li> 
<li :class="{'activeClass': idClicked == I3049 }" id="I3049">SEG_19 208	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3582'>Theodoros of Gadara</a></li> 
<li :class="{'activeClass': idClicked == I3050 }" id="I3050">SEG_19 221	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3867'>Zeu63*</a></li> 
<li :class="{'activeClass': idClicked == I3051 }" id="I3051">SEG_19 224	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1732'>Euporia</a></li> 
<li :class="{'activeClass': idClicked == I3052 }" id="I3052">SEG_19 225	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3845'>Zeu46*</a></li> 
<li :class="{'activeClass': idClicked == I3053 }" id="I3053">SEG_19 226	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3844'>Zeu45*</a></li> 
<li :class="{'activeClass': idClicked == I3054 }" id="I3054">SEG_19 227	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP374'>Apo51*</a></li> 
<li :class="{'activeClass': idClicked == I3055 }" id="I3055">SEG_19 234	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM165">165</a></li> 
<li :class="{'activeClass': idClicked == I3056 }" id="I3056">SEG_19 235	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a></li> 
<li :class="{'activeClass': idClicked == I3057 }" id="I3057"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 21 (1965)</b></li> 
<li :class="{'activeClass': idClicked == I3058 }" id="I3058">SEG_21 86	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2684'>Moschophoros</a></li> 
<li :class="{'activeClass': idClicked == I3059 }" id="I3059">SEG_21 435	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2175'>Hipparcheion</a></li> 
<li :class="{'activeClass': idClicked == I3060 }" id="I3060">SEG_21 436	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2175'>Hipparcheion</a></li> 
<li :class="{'activeClass': idClicked == I3061 }" id="I3061">SEG_21 525	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2051'>Her2*</a></li> 
<li :class="{'activeClass': idClicked == I3062 }" id="I3062">SEG_21 530	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2101'>Hro10*</a></li> 
<li :class="{'activeClass': idClicked == I3063 }" id="I3063">SEG_21 536	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2611'>Megaloi Theoi</a></li> 
<li :class="{'activeClass': idClicked == I3064 }" id="I3064">SEG_21 541	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3865'>Zeu61*</a></li> 
<li :class="{'activeClass': idClicked == I3065 }" id="I3065">SEG_21 633	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2096'>Hro5*</a></li> 
<li :class="{'activeClass': idClicked == I3066 }" id="I3066">SEG_21 647	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM321">321</a></li> 
<li :class="{'activeClass': idClicked == I3067 }" id="I3067">SEG_21 650	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3711'>Tritopateres</a></li> 
<li :class="{'activeClass': idClicked == I3068 }" id="I3068">SEG_21 651	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP662'>Ath15*</a></li> 
<li :class="{'activeClass': idClicked == I3069 }" id="I3069">SEG_21 663	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2'>Abecedarium</a></li> 
<li :class="{'activeClass': idClicked == I3070 }" id="I3070">SEG_21 672	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP274'>Aph29*</a></li> 
<li :class="{'activeClass': idClicked == I3071 }" id="I3071">SEG_21 675	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1708'>Eueteria</a></li> 
<li :class="{'activeClass': idClicked == I3072 }" id="I3072">SEG_21 680	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2115'>Hro21*</a></li> 
<li :class="{'activeClass': idClicked == I3073 }" id="I3073">SEG_21 704	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3074 }" id="I3074">SEG_21 705/30	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == I3075 }" id="I3075">SEG_21 732/33	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == I3076 }" id="I3076">SEG_21 754	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2093'>Hro2*</a></li> 
<li :class="{'activeClass': idClicked == I3077 }" id="I3077">SEG_21 765	Kornelios</li> 
<li :class="{'activeClass': idClicked == I3078 }" id="I3078">SEG_21 772	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1708'>Eueteria</a></li> 
<li :class="{'activeClass': idClicked == I3079 }" id="I3079">SEG_21 781	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3864'>Zeu60*</a></li> 
<li :class="{'activeClass': idClicked == I3080 }" id="I3080">SEG_21 787	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> Aph3*</li> 
<li :class="{'activeClass': idClicked == I3081 }" id="I3081">SEG_21 790	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3863'>Zeu59*</a></li> 
<li :class="{'activeClass': idClicked == I3082 }" id="I3082">SEG_21 794	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2099'>Hro8*</a></li> 
<li :class="{'activeClass': idClicked == I3083 }" id="I3083">SEG_21 795	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1223'>Demeter and Kore</a></li> 
<li :class="{'activeClass': idClicked == I3084 }" id="I3084">SEG_21 799	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2230'>Hyrkanos</a></li> 
<li :class="{'activeClass': idClicked == I3085 }" id="I3085">SEG_21 802	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1490'>Dio54*</a></li> 
<li :class="{'activeClass': idClicked == I3086 }" id="I3086">SEG_21 812	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP394'>Apo67*</a></li> 
<li :class="{'activeClass': idClicked == I3087 }" id="I3087">SEG_21 814	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP747'>Ath58*</a></li> 
<li :class="{'activeClass': idClicked == I3088 }" id="I3088">SEG_21 815	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP613'>Ask44*</a></li> 
<li :class="{'activeClass': idClicked == I3089 }" id="I3089">SEG_21 853	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3477'>Straton</a></li> 
<li :class="{'activeClass': idClicked == I3090 }" id="I3090"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 22 (1967)</b></li> 
<li :class="{'activeClass': idClicked == I3091 }" id="I3091">SEG_22 65	Silenos</li> 
<li :class="{'activeClass': idClicked == I3092 }" id="I3092">SEG_22 91	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP834'>Ath120*</a></li> 
<li :class="{'activeClass': idClicked == I3093 }" id="I3093">SEG_22 116	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP516'>Art25*</a></li> 
<li :class="{'activeClass': idClicked == I3094 }" id="I3094">SEG_22 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP854'>Ath135*</a></li> 
<li :class="{'activeClass': idClicked == I3095 }" id="I3095">SEG_22 151	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3096 }" id="I3096">SEG_22 152	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2541'>Livia</a></li> 
<li :class="{'activeClass': idClicked == I3097 }" id="I3097">SEG_22 164	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3098 }" id="I3098">SEG_22 166	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2095'>Hro4*</a></li> 
<li :class="{'activeClass': idClicked == I3099 }" id="I3099"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 23 (1968)</b></li> 
<li :class="{'activeClass': idClicked == I3100 }" id="I3100">SEG_23 78	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP109'>Akamas</a></li> 
<li :class="{'activeClass': idClicked == I3101 }" id="I3101">SEG_23 94	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM618">618</a></li> 
<li :class="{'activeClass': idClicked == I3102 }" id="I3102">SEG_23 100	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2001'>Hrm3*</a></li> 
<li :class="{'activeClass': idClicked == I3103 }" id="I3103">SEG_23 107	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP231'>Antoninus Pius</a></li> 
<li :class="{'activeClass': idClicked == I3104 }" id="I3104">SEG_23 108/9	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2596'>Marcus Aurelius</a></li> 
<li :class="{'activeClass': idClicked == I3105 }" id="I3105">SEG_23 110	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1708'>Eueteria</a></li> 
<li :class="{'activeClass': idClicked == I3106 }" id="I3106">SEG_23 128	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I3107 }" id="I3107"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 24 (1969)</b></li> 
<li :class="{'activeClass': idClicked == I3108 }" id="I3108">SEG_24 54	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP102'>Akademia</a></li> 
<li :class="{'activeClass': idClicked == I3109 }" id="I3109">SEG_24 56	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM235">235</a></li> 
<li :class="{'activeClass': idClicked == I3110 }" id="I3110">SEG_24 58	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM560">560</a></li> 
<li :class="{'activeClass': idClicked == I3111 }" id="I3111">SEG_24 157	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2653'>Metronomoi</a></li> 
<li :class="{'activeClass': idClicked == I3112 }" id="I3112">SEG_24 203	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2111'>Hro17*</a></li> 
<li :class="{'activeClass': idClicked == I3113 }" id="I3113">SEG_24 211	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP421'>Apollodoros</a></li> 
<li :class="{'activeClass': idClicked == I3114 }" id="I3114">SEG_24 213	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3115 }" id="I3115">SEG_24 214	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3482'>Sulla</a></li> 
<li :class="{'activeClass': idClicked == I3116 }" id="I3116">SEG_24 225	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2266'>Isis and Anubis</a></li> 
<li :class="{'activeClass': idClicked == I3117 }" id="I3117">SEG_24 226	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I3118 }" id="I3118"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 25 (1971)</b></li> 
<li :class="{'activeClass': idClicked == I3119 }" id="I3119">SEG_25 7	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2116'>Hro22*</a></li> 
<li :class="{'activeClass': idClicked == I3120 }" id="I3120">SEG_25 25	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP407'>Apo77*</a></li> 
<li :class="{'activeClass': idClicked == I3121 }" id="I3121">SEG_25 42	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP62'>Agora of the Skambonidai</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3396'>Skambonidai</a></li> 
<li :class="{'activeClass': idClicked == I3122 }" id="I3122">SEG_25 54	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2792'>Nymphaion</a></li> 
<li :class="{'activeClass': idClicked == I3123 }" id="I3123">SEG_25 56	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem1*</li> 
<li :class="{'activeClass': idClicked == I3124 }" id="I3124">SEG_25 57	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li> 
<li :class="{'activeClass': idClicked == I3125 }" id="I3125">SEG_25 108	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP517'>Art26*</a></li> 
<li :class="{'activeClass': idClicked == I3126 }" id="I3126">SEG_25 127	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2109'>Hro15*</a></li> 
<li :class="{'activeClass': idClicked == I3127 }" id="I3127">SEG_25 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1740'>Eurysakes</a></li> 
<li :class="{'activeClass': idClicked == I3128 }" id="I3128">SEG_25 149	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1258'>Demetrios Poliorketes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == I3129 }" id="I3129">SEG_25 197	Egretes</li> 
<li :class="{'activeClass': idClicked == I3130 }" id="I3130">SEG_25 201	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> Ath108*</li> 
<li :class="{'activeClass': idClicked == I3131 }" id="I3131">SEG_25 207	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1019'>Chremonides</a></li> 
<li :class="{'activeClass': idClicked == I3132 }" id="I3132">SEG_25 211	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2952'>Panhellenion</a></li> 
<li :class="{'activeClass': idClicked == I3133 }" id="I3133">SEG_25 212	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2952'>Panhellenion</a></li> 
<li :class="{'activeClass': idClicked == I3134 }" id="I3134">SEG_25 219	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2097'>Hro6*</a></li> 
<li :class="{'activeClass': idClicked == I3135 }" id="I3135">SEG_25 222	Hegemon</li> 
<li :class="{'activeClass': idClicked == I3136 }" id="I3136">SEG_25 225	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3892'>Zeu84*</a></li> 
<li :class="{'activeClass': idClicked == I3137 }" id="I3137">SEG_25 231	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2671'>Mnemosyne</a></li> 
<li :class="{'activeClass': idClicked == I3138 }" id="I3138">SEG_25 234	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1921'>Hrk2*</a></li> 
<li :class="{'activeClass': idClicked == I3139 }" id="I3139">SEG_25 236	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP994'>Chairippos</a></li> 
<li :class="{'activeClass': idClicked == I3140 }" id="I3140">SEG_25 248	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2376'>Kerameis</a></li> 
<li :class="{'activeClass': idClicked == I3141 }" id="I3141">SEG_25 257	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3088'>Philon</a></li> 
<li :class="{'activeClass': idClicked == I3142 }" id="I3142">SEG_25 317	Jewish Mon.</li> 
<li :class="{'activeClass': idClicked == I3143 }" id="I3143">SEG_25 318	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3284'>Pyloroi</a></li> 
<li :class="{'activeClass': idClicked == I3144 }" id="I3144"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 26 (1976/7)</b></li> 
<li :class="{'activeClass': idClicked == I3145 }" id="I3145">SEG_26 139	Gymnasium inventory</li> 
<li :class="{'activeClass': idClicked == I3146 }" id="I3146">SEG_26 199	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3174'>Pompeion</a></li> 
<li :class="{'activeClass': idClicked == I3147 }" id="I3147">SEG_26 273	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a> milestone</li> 
<li :class="{'activeClass': idClicked == I3148 }" id="I3148"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 27 (1977)</b></li> 
<li :class="{'activeClass': idClicked == I3149 }" id="I3149">SEG_27 11	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1774'>Glaukon</a></li> 
<li :class="{'activeClass': idClicked == I3150 }" id="I3150">SEG_27 12/19	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP400'>Apo73*</a></li> 
<li :class="{'activeClass': idClicked == I3151 }" id="I3151"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 28 (1978)</b></li> 
<li :class="{'activeClass': idClicked == I3152 }" id="I3152">SEG_28 214	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a> III</li> 
<li :class="{'activeClass': idClicked == I3153 }" id="I3153">SEG_28 220	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3762'>Varius Caelianus</a></li> 
<li :class="{'activeClass': idClicked == I3154 }" id="I3154">SEG_28 227	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP494'>Art4*</a></li> 
<li :class="{'activeClass': idClicked == I3155 }" id="I3155">SEG_28 230	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3917'>Zeu104*</a></li> 
<li :class="{'activeClass': idClicked == I3156 }" id="I3156">SEG_28 232	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1977'>Hrk43*</a></li> 
<li :class="{'activeClass': idClicked == I3157 }" id="I3157">SEG_28 237	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1245'>Demeter Kourotrophos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3011'>Peitho</a></li> 
<li :class="{'activeClass': idClicked == I3158 }" id="I3158">SEG_28 238	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2446'>Kourotrophos</a></li> 
<li :class="{'activeClass': idClicked == I3159 }" id="I3159">SEG_28 319	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1266'>Demokrates</a></li> 
<li :class="{'activeClass': idClicked == I3160 }" id="I3160"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 29 (1979)</b></li> 
<li :class="{'activeClass': idClicked == I3161 }" id="I3161">SEG_29 10	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3026'>Periklean Fountain</a> House</li> 
<li :class="{'activeClass': idClicked == I3162 }" id="I3162">SEG_29 25	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP703'>Ath26*</a></li> 
<li :class="{'activeClass': idClicked == I3163 }" id="I3163">SEG_29 62	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2380'>Keramo</a></li> 
<li :class="{'activeClass': idClicked == I3164 }" id="I3164">SEG_29 88	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1553'>Eirene</a></li> 
<li :class="{'activeClass': idClicked == I3165 }" id="I3165">SEG_29 95	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2087'>Herodoros</a></li> 
<li :class="{'activeClass': idClicked == I3166 }" id="I3166">SEG_29 135	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == I3167 }" id="I3167">SEG_29 146	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2098'>Hro7*</a></li> 
<li :class="{'activeClass': idClicked == I3168 }" id="I3168">SEG_29 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2503'>Leos</a></li> 
<li :class="{'activeClass': idClicked == I3169 }" id="I3169">SEG_29 169	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a>, statue base</li> 
<li :class="{'activeClass': idClicked == I3170 }" id="I3170">SEG_29 176	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == I3171 }" id="I3171">SEG_29 180	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2975'>Pantainos</a></li> 
<li :class="{'activeClass': idClicked == I3172 }" id="I3172">SEG_29 181	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3694'>Trebellius Rufus</a></li> 
<li :class="{'activeClass': idClicked == I3173 }" id="I3173">SEG_29 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP818'>Ath113*</a></li> 
<li :class="{'activeClass': idClicked == I3174 }" id="I3174"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 30 (1980)</b></li> 
<li :class="{'activeClass': idClicked == I3175 }" id="I3175">SEG_30 25	Trojan Horse</li> 
<li :class="{'activeClass': idClicked == I3176 }" id="I3176">SEG_30 82	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3489'>Synhedrion of the Sacred Gerousia</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3752'>Ulpius Eubiotus</a></li> 
<li :class="{'activeClass': idClicked == I3177 }" id="I3177">SEG_30 93	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2536'>Lithos</a></li> 
<li :class="{'activeClass': idClicked == I3178 }" id="I3178">SEG_30 124/5	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP471'>Aristeides</a></li> 
<li :class="{'activeClass': idClicked == I3179 }" id="I3179">SEG_30 127	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP400'>Apo73*</a></li> 
<li :class="{'activeClass': idClicked == I3180 }" id="I3180">SEG_30 138	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> + II</li> 
<li :class="{'activeClass': idClicked == I3181 }" id="I3181">SEG_30 140	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2577'>Lysimache</a></li> 
<li :class="{'activeClass': idClicked == I3182 }" id="I3182">SEG_30 191	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem8*</li> 
<li :class="{'activeClass': idClicked == I3183 }" id="I3183"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 31 (1981)</b></li> 
<li :class="{'activeClass': idClicked == I3184 }" id="I3184">SEG_31 27	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM247">247</a></li> 
<li :class="{'activeClass': idClicked == I3185 }" id="I3185">SEG_31 50	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1462'>Dio33*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1463'>Dio34*</a></li> 
<li :class="{'activeClass': idClicked == I3186 }" id="I3186">SEG_31 65	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP835'>Ath121*</a></li> 
<li :class="{'activeClass': idClicked == I3187 }" id="I3187">SEG_31 74	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP826'>Ath116*</a></li> 
<li :class="{'activeClass': idClicked == I3188 }" id="I3188">SEG_31 108	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2762'>Nicanor</a></li> 
<li :class="{'activeClass': idClicked == I3189 }" id="I3189">SEG_31 176	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3406'>Snake</a></li> 
<li :class="{'activeClass': idClicked == I3190 }" id="I3190">SEG_31 238	Heraklios</li> 
<li :class="{'activeClass': idClicked == I3191 }" id="I3191"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 32 (1982)</b></li> 
<li :class="{'activeClass': idClicked == I3192 }" id="I3192">SEG_32 24	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP476'>Aristokrates</a></li> 
<li :class="{'activeClass': idClicked == I3193 }" id="I3193">SEG_32 36	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3643'>Thrasyboulos</a></li> 
<li :class="{'activeClass': idClicked == I3194 }" id="I3194">SEG_32 55	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3693'>Trapezai</a></li> 
<li :class="{'activeClass': idClicked == I3195 }" id="I3195">SEG_32 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == I3196 }" id="I3196">SEG_32 150	Therrikleion</li> 
<li :class="{'activeClass': idClicked == I3197 }" id="I3197">SEG_32 151	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1258'>Demetrios Poliorketes</a></li> 
<li :class="{'activeClass': idClicked == I3198 }" id="I3198">SEG_32 239	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2050'>Her1**</a></li> 
<li :class="{'activeClass': idClicked == I3199 }" id="I3199">SEG_32 250	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP956'>Bryaxis Base</a></li> 
<li :class="{'activeClass': idClicked == I3200 }" id="I3200">SEG_32 259	Rhoimetalkes</li> 
<li :class="{'activeClass': idClicked == I3201 }" id="I3201">SEG_32 263	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP492'>Art2*</a></li> 
<li :class="{'activeClass': idClicked == I3202 }" id="I3202">SEG_32 271	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1471'>Dio39*</a></li> 
<li :class="{'activeClass': idClicked == I3203 }" id="I3203">SEG_32 273	Harmodius and Aristogeiton</li> 
<li :class="{'activeClass': idClicked == I3204 }" id="I3204">SEG_32 274	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM24">24</a></li> 
<li :class="{'activeClass': idClicked == I3205 }" id="I3205">SEG_32 317	Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1075'>CM23*</a></li> 
<li :class="{'activeClass': idClicked == I3206 }" id="I3206">SEG_32 327	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM366">366</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM369">369</a></li> 
<li :class="{'activeClass': idClicked == I3207 }" id="I3207">SEG_32 336	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a></li> 
<li :class="{'activeClass': idClicked == I3208 }" id="I3208">SEG_32 1686	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1776'>Glykon</a></li> 
<li :class="{'activeClass': idClicked == I3209 }" id="I3209"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 33 (1983)</b></li> 
<li :class="{'activeClass': idClicked == I3210 }" id="I3210">SEG_33 25	Epicharinos</li> 
<li :class="{'activeClass': idClicked == I3211 }" id="I3211">SEG_33 33	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</li> 
<li :class="{'activeClass': idClicked == I3212 }" id="I3212">SEG_33 39	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem5*</li> 
<li :class="{'activeClass': idClicked == I3213 }" id="I3213">SEG_33 41	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem24*</li> 
<li :class="{'activeClass': idClicked == I3214 }" id="I3214">SEG_33 42	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem34*</li> 
<li :class="{'activeClass': idClicked == I3215 }" id="I3215">SEG_33 43	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem14*</li> 
<li :class="{'activeClass': idClicked == I3216 }" id="I3216">SEG_33 45	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem16*</li> 
<li :class="{'activeClass': idClicked == I3217 }" id="I3217">SEG_33 46	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == I3218 }" id="I3218">SEG_33 51	Pythagoras</li> 
<li :class="{'activeClass': idClicked == I3219 }" id="I3219">SEG_33 180	Geta</li> 
<li :class="{'activeClass': idClicked == I3220 }" id="I3220">SEG_33 186	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1361'>Dexippos I</a></li> 
<li :class="{'activeClass': idClicked == I3221 }" id="I3221">SEG_33 195	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1396'>Diomedes</a></li> 
<li :class="{'activeClass': idClicked == I3222 }" id="I3222">SEG_33 196	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP568'>Ask10*</a></li> 
<li :class="{'activeClass': idClicked == I3223 }" id="I3223">SEG_33 198	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3810'>Zeu17*</a></li> 
<li :class="{'activeClass': idClicked == I3224 }" id="I3224">SEG_33 207	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM391">391</a></li> 
<li :class="{'activeClass': idClicked == I3225 }" id="I3225">SEG_33 208	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == I3226 }" id="I3226">SEG_33 209	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem21*</li> 
<li :class="{'activeClass': idClicked == I3227 }" id="I3227">SEG_33 244:d-e	Hikesios</li> 
<li :class="{'activeClass': idClicked == I3228 }" id="I3228"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 34 (1984)</b></li> 
<li :class="{'activeClass': idClicked == I3229 }" id="I3229">SEG_34 39	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3896'>Zeu87*</a></li> 
<li :class="{'activeClass': idClicked == I3230 }" id="I3230">SEG_34 44	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a></li> 
<li :class="{'activeClass': idClicked == I3231 }" id="I3231">SEG_34 45	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem13*</li> 
<li :class="{'activeClass': idClicked == I3232 }" id="I3232">SEG_34 118	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3024'>Peplotheke</a></li> 
<li :class="{'activeClass': idClicked == I3233 }" id="I3233">SEG_34 173	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2840'>Olympicum Chronicon</a></li> 
<li :class="{'activeClass': idClicked == I3234 }" id="I3234">SEG_34 177	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a></li> 
<li :class="{'activeClass': idClicked == I3235 }" id="I3235">SEG_34 179	Hipparchs</li> 
<li :class="{'activeClass': idClicked == I3236 }" id="I3236">SEG_34 180	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1530'>Drusilla</a></li> 
<li :class="{'activeClass': idClicked == I3237 }" id="I3237">SEG_34 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3329'>Sabina Augusta</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2609'>Matidia</a></li> 
<li :class="{'activeClass': idClicked == I3238 }" id="I3238">SEG_34 185	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3380'>Septimius Severus</a> and Geta</li> 
<li :class="{'activeClass': idClicked == I3239 }" id="I3239">SEG_34 186	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP980'>Caracalla</a></li> 
<li :class="{'activeClass': idClicked == I3240 }" id="I3240">SEG_34 187	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP980'>Caracalla</a></li> 
<li :class="{'activeClass': idClicked == I3241 }" id="I3241">SEG_34 192	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP413'>Apo83*</a></li> 
<li :class="{'activeClass': idClicked == I3242 }" id="I3242"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 35 (1985)</b></li> 
<li :class="{'activeClass': idClicked == I3243 }" id="I3243">SEG_35 94	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2108'>Hro14*</a></li> 
<li :class="{'activeClass': idClicked == I3244 }" id="I3244">SEG_35 137	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1930'>Hrk9*</a></li> 
<li :class="{'activeClass': idClicked == I3245 }" id="I3245">SEG_35 141	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I3246 }" id="I3246">SEG_35 145	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == I3247 }" id="I3247">SEG_35 151	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3380'>Septimius Severus</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP980'>Caracalla</a></li> 
<li :class="{'activeClass': idClicked == I3248 }" id="I3248">SEG_35 160	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2630'>Menander</a></li> 
<li :class="{'activeClass': idClicked == I3249 }" id="I3249">SEG_35 189	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP188'>Andreas</a></li> 
<li :class="{'activeClass': idClicked == I3250 }" id="I3250">SEG_35 208	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1649'>Eridanos River</a></li> 
<li :class="{'activeClass': idClicked == I3251 }" id="I3251">SEG_35 234	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a> (<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3061'>Pharia</a>)</li> 
<li :class="{'activeClass': idClicked == I3252 }" id="I3252">SEG_35 1767	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2952'>Panhellenion</a></li> 
<li :class="{'activeClass': idClicked == I3253 }" id="I3253"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 36 (1986)</b></li> 
<li :class="{'activeClass': idClicked == I3254 }" id="I3254">SEG_36 38	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM560">560</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM561">561</a></li> 
<li :class="{'activeClass': idClicked == I3255 }" id="I3255">SEG_36 42	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == I3256 }" id="I3256">SEG_36 148	Spartokos II</li> 
<li :class="{'activeClass': idClicked == I3257 }" id="I3257">SEG_36 208	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP998'>Chalkotheke</a></li> 
<li :class="{'activeClass': idClicked == I3258 }" id="I3258">SEG_36 233	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP65'>Agoranomion</a></li> 
<li :class="{'activeClass': idClicked == I3259 }" id="I3259">SEG_36 243	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM113">113</a></li> 
<li :class="{'activeClass': idClicked == I3260 }" id="I3260">SEG_36 244	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2761'>Nero</a></li> 
<li :class="{'activeClass': idClicked == I3261 }" id="I3261">SEG_36 246	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2432'>Konon</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3674'>Timotheos</a></li> 
<li :class="{'activeClass': idClicked == I3262 }" id="I3262">SEG_36 248	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2478'>Laetus</a></li> 
<li :class="{'activeClass': idClicked == I3263 }" id="I3263">SEG_36 276	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == I3264 }" id="I3264"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 37 (1987)</b></li> 
<li :class="{'activeClass': idClicked == I3265 }" id="I3265">SEG_37 23	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1980'>Hrk45*</a></li> 
<li :class="{'activeClass': idClicked == I3266 }" id="I3266">SEG_37 41	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2376'>Kerameis</a> Trittys (2)</li> 
<li :class="{'activeClass': idClicked == I3267 }" id="I3267">SEG_37 49	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33**</li> 
<li :class="{'activeClass': idClicked == I3268 }" id="I3268">SEG_37 85	Hippades Gate <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a>: <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1509'>Dipylon Gate</a></li> 
<li :class="{'activeClass': idClicked == I3269 }" id="I3269">SEG_37 96	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM87">87</a></li> 
<li :class="{'activeClass': idClicked == I3270 }" id="I3270">SEG_37 97	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2307'>Julia Domna</a></li> 
<li :class="{'activeClass': idClicked == I3271 }" id="I3271">SEG_37 143	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2103'>Hro11*</a></li> 
<li :class="{'activeClass': idClicked == I3272 }" id="I3272">SEG_37 148	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1773'>Glaphyra</a></li> 
<li :class="{'activeClass': idClicked == I3273 }" id="I3273">SEG_37 153	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2240'>Iliad</a></li> 
<li :class="{'activeClass': idClicked == I3274 }" id="I3274">SEG_37 155	Aristotle</li> 
<li :class="{'activeClass': idClicked == I3275 }" id="I3275">SEG_37 158	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem8*</li> 
<li :class="{'activeClass': idClicked == I3276 }" id="I3276">SEG_37 161	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2562'>Lykophron</a></li> 
<li :class="{'activeClass': idClicked == I3277 }" id="I3277">SEG_37 165	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1359'>Dexileos</a></li> 
<li :class="{'activeClass': idClicked == I3278 }" id="I3278"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 38 (1988)</b></li> 
<li :class="{'activeClass': idClicked == I3279 }" id="I3279">SEG_38 5	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2745'>Neleus and Basile</a></li> 
<li :class="{'activeClass': idClicked == I3280 }" id="I3280">SEG_38 16	Trittys</li> 
<li :class="{'activeClass': idClicked == I3281 }" id="I3281">SEG_38 18	Hipparchs</li> 
<li :class="{'activeClass': idClicked == I3282 }" id="I3282">SEG_38 19	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM129">129</a></li> 
<li :class="{'activeClass': idClicked == I3283 }" id="I3283">SEG_38 30	Melanopos</li> 
<li :class="{'activeClass': idClicked == I3284 }" id="I3284">SEG_38 112	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a> VII <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2637'>Menodoros</a></li> 
<li :class="{'activeClass': idClicked == I3285 }" id="I3285">SEG_38 113	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP393'>Apo66*</a></li> 
<li :class="{'activeClass': idClicked == I3286 }" id="I3286">SEG_38 116	Ptolemy III</li> 
<li :class="{'activeClass': idClicked == I3287 }" id="I3287">SEG_38 118	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP569'>Ask11*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP599'>Ask33*</a></li> 
<li :class="{'activeClass': idClicked == I3288 }" id="I3288">SEG_38 129	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP498'>Art7*</a></li> 
<li :class="{'activeClass': idClicked == I3289 }" id="I3289">SEG_38 170	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3906'>Zeu95*</a></li> 
<li :class="{'activeClass': idClicked == I3290 }" id="I3290">SEG_38 172	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1932'>Hrk10**</a></li> 
<li :class="{'activeClass': idClicked == I3291 }" id="I3291">SEG_38 176	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2003'>Hrm5*</a></li> 
<li :class="{'activeClass': idClicked == I3292 }" id="I3292">SEG_38 178	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2637'>Menodoros</a></li> 
<li :class="{'activeClass': idClicked == I3293 }" id="I3293">SEG_38 180	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3294 }" id="I3294">SEG_38 181	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3295 }" id="I3295">SEG_38 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3694'>Trebellius Rufus</a>, Q.</li> 
<li :class="{'activeClass': idClicked == I3296 }" id="I3296">SEG_38 184	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2234'>Iamblichos</a></li> 
<li :class="{'activeClass': idClicked == I3297 }" id="I3297">SEG_38 185	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1986'>Herculius</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a>, son of Nestorius <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a>, the sophist</li> 
<li :class="{'activeClass': idClicked == I3298 }" id="I3298">SEG_38 189	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3494'>Taurobolion</a></li> 
<li :class="{'activeClass': idClicked == I3299 }" id="I3299">SEG_38 190	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP919'>Blaute</a> (2)</li> 
<li :class="{'activeClass': idClicked == I3300 }" id="I3300">SEG_38 194	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3905'>Zeu94*</a></li> 
<li :class="{'activeClass': idClicked == I3301 }" id="I3301">SEG_38 195	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP380'>Apo55*</a>, 56</li> 
<li :class="{'activeClass': idClicked == I3302 }" id="I3302">SEG_38 196	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3469'>Stoics</a></li> 
<li :class="{'activeClass': idClicked == I3303 }" id="I3303">SEG_38 197	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1359'>Dexileos</a></li> 
<li :class="{'activeClass': idClicked == I3304 }" id="I3304">SEG_38 259	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1451'>Dio25*</a></li> 
<li :class="{'activeClass': idClicked == I3305 }" id="I3305">SEG_38 261	Eumenes II</li> 
<li :class="{'activeClass': idClicked == I3306 }" id="I3306"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 39 (1989)</b></li> 
<li :class="{'activeClass': idClicked == I3307 }" id="I3307">SEG_39 17	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1567'>Eleusinion</a></li> 
<li :class="{'activeClass': idClicked == I3308 }" id="I3308">SEG_39 30	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM84">84</a></li> 
<li :class="{'activeClass': idClicked == I3309 }" id="I3309">SEG_39 139	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM323">323</a></li> 
<li :class="{'activeClass': idClicked == I3310 }" id="I3310">SEG_39 164	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP570'>Ask12*</a>. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP577'>Ask17*</a></li> 
<li :class="{'activeClass': idClicked == I3311 }" id="I3311">SEG_39 165	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP570'>Ask12*</a></li> 
<li :class="{'activeClass': idClicked == I3312 }" id="I3312">SEG_39 175	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP590'>Ask28*</a></li> 
<li :class="{'activeClass': idClicked == I3313 }" id="I3313">SEG_39 197	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2728'>Muses</a> (2)</li> 
<li :class="{'activeClass': idClicked == I3314 }" id="I3314">SEG_39 203	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP320'>Apo9*</a></li> 
<li :class="{'activeClass': idClicked == I3315 }" id="I3315">SEG_39 211	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1649'>Eridanos</a></li> 
<li :class="{'activeClass': idClicked == I3316 }" id="I3316">SEG_39 212	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP611'>Ask42*</a></li> 
<li :class="{'activeClass': idClicked == I3317 }" id="I3317">SEG_39 213	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a></li> 
<li :class="{'activeClass': idClicked == I3318 }" id="I3318">SEG_39 214	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2975'>Pantainos</a></li> 
<li :class="{'activeClass': idClicked == I3319 }" id="I3319">SEG_39 217	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3059'>Phanes</a></li> 
<li :class="{'activeClass': idClicked == I3320 }" id="I3320">SEG_39 225	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP68'>Agrippa</a></li> 
<li :class="{'activeClass': idClicked == I3321 }" id="I3321">SEG_39 230	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP601'>Ask35*</a></li> 
<li :class="{'activeClass': idClicked == I3322 }" id="I3322">SEG_39 235	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2113'>Hro19*</a></li> 
<li :class="{'activeClass': idClicked == I3323 }" id="I3323">SEG_39 236	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1926'>Hrk7*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1960'>Hrk31*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1961'>Hrk32*</a></li> 
<li :class="{'activeClass': idClicked == I3324 }" id="I3324">SEG_39 245	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP231'>Antoninus Pius</a></li> 
<li :class="{'activeClass': idClicked == I3325 }" id="I3325">SEG_39 249	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP635'>Astynomos</a></li> 
<li :class="{'activeClass': idClicked == I3326 }" id="I3326">SEG_39 293	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM377">377</a></li> 
<li :class="{'activeClass': idClicked == I3327 }" id="I3327">SEG_39 323	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3328 }" id="I3328"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 40 (1990)</b></li> 
<li :class="{'activeClass': idClicked == I3329 }" id="I3329">SEG_40 19	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2987'>Parthenon</a></li> 
<li :class="{'activeClass': idClicked == I3330 }" id="I3330">SEG_40 26	Chariot</li> 
<li :class="{'activeClass': idClicked == I3331 }" id="I3331">SEG_40 37	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem19*</li> 
<li :class="{'activeClass': idClicked == I3332 }" id="I3332">SEG_40 157	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == I3333 }" id="I3333">SEG_40 182	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3667'>Tiberius</a></li> 
<li :class="{'activeClass': idClicked == I3334 }" id="I3334">SEG_40 184	Eudokia</li> 
<li :class="{'activeClass': idClicked == I3335 }" id="I3335">SEG_40 194	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1184'>Claudius Marcellus</a></li> 
<li :class="{'activeClass': idClicked == I3336 }" id="I3336">SEG_40 275	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2689'>Met1*</a></li> 
<li :class="{'activeClass': idClicked == I3337 }" id="I3337"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 41 (1991)</b></li> 
<li :class="{'activeClass': idClicked == I3338 }" id="I3338">SEG_41 10	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3339 }" id="I3339">SEG_41 13	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM612">612</a></li> 
<li :class="{'activeClass': idClicked == I3340 }" id="I3340">SEG_41 82/84	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3341 }" id="I3341">SEG_41 85	Part II no 440</li> 
<li :class="{'activeClass': idClicked == I3342 }" id="I3342">SEG_41 122	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2366'>Kerameikos Boundary Stones</a></li> 
<li :class="{'activeClass': idClicked == I3343 }" id="I3343">SEG_41 134	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP930'>Boule</a></li> 
<li :class="{'activeClass': idClicked == I3344 }" id="I3344">SEG_41 152	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2334'>Karneades</a></li> 
<li :class="{'activeClass': idClicked == I3345 }" id="I3345">SEG_41 166	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2214'>Hygieia</a></li> 
<li :class="{'activeClass': idClicked == I3346 }" id="I3346">SEG_41 171	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3347 }" id="I3347">SEG_41 172	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3348 }" id="I3348">SEG_41 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3904'>Zeu93*</a></li> 
<li :class="{'activeClass': idClicked == I3349 }" id="I3349">SEG_41 247	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3350 }" id="I3350">SEG_41 1839	Jewish Mon.</li> 
<li :class="{'activeClass': idClicked == I3351 }" id="I3351">SEG_41 1862	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2630'>Menander</a></li> 
<li :class="{'activeClass': idClicked == I3352 }" id="I3352"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 42 (1992)</b></li> 
<li :class="{'activeClass': idClicked == I3353 }" id="I3353">SEG_42 12	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2851'>Opisthodomos</a></li> 
<li :class="{'activeClass': idClicked == I3354 }" id="I3354">SEG_42 32	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM412">412</a></li> 
<li :class="{'activeClass': idClicked == I3355 }" id="I3355">SEG_42 33	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1835'>Harmodios and Aristogeiton</a></li> 
<li :class="{'activeClass': idClicked == I3356 }" id="I3356">SEG_42 39	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3239'>Potter Hero</a></li> 
<li :class="{'activeClass': idClicked == I3357 }" id="I3357">SEG_42 40	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP704'>Ath27*</a></li> 
<li :class="{'activeClass': idClicked == I3358 }" id="I3358">SEG_42 42	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a></li> 
<li :class="{'activeClass': idClicked == I3359 }" id="I3359">SEG_42 46	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3734'>Twelve Gods</a></li> 
<li :class="{'activeClass': idClicked == I3360 }" id="I3360">SEG_42 68	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3750'>Tyr</a> _ son of Sky I ax</li> 
<li :class="{'activeClass': idClicked == I3361 }" id="I3361">SEG_42 90	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP553'>Arybbas</a></li> 
<li :class="{'activeClass': idClicked == I3362 }" id="I3362">SEG_42 92	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP639'>Ath2*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP773'>Ath77*</a></li> 
<li :class="{'activeClass': idClicked == I3363 }" id="I3363">SEG_42 101	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == I3364 }" id="I3364">SEG_42 106	Ptomemaios</li> 
<li :class="{'activeClass': idClicked == I3365 }" id="I3365">SEG_42 108	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2879'>Palladia</a></li> 
<li :class="{'activeClass': idClicked == I3366 }" id="I3366">SEG_42 109	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2211'>Hyakinthos</a></li> 
<li :class="{'activeClass': idClicked == I3367 }" id="I3367">SEG_42 111	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a></li> 
<li :class="{'activeClass': idClicked == I3368 }" id="I3368">SEG_42 119	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1566'>Eleusinian Mysteries</a></li> 
<li :class="{'activeClass': idClicked == I3369 }" id="I3369">SEG_42 124	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP900'>Bakcheion</a></li> 
<li :class="{'activeClass': idClicked == I3370 }" id="I3370">SEG_42 128	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2879'>Palladia</a></li> 
<li :class="{'activeClass': idClicked == I3371 }" id="I3371">SEG_42 135	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == I3372 }" id="I3372">SEG_42 138	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == I3373 }" id="I3373">SEG_42 139	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2387'>Kerykes</a></li> 
<li :class="{'activeClass': idClicked == I3374 }" id="I3374">SEG_42 143	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1924'>Hrk5*</a></li> 
<li :class="{'activeClass': idClicked == I3375 }" id="I3375">SEG_42 144	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM441">441</a></li> 
<li :class="{'activeClass': idClicked == I3376 }" id="I3376">SEG_42 157	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a>, etc.</li> 
<li :class="{'activeClass': idClicked == I3377 }" id="I3377">SEG_42 159	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3832'>Zeu35*</a></li> 
<li :class="{'activeClass': idClicked == I3378 }" id="I3378">SEG_42 163	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a></li> 
<li :class="{'activeClass': idClicked == I3379 }" id="I3379">SEG_42 172	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == I3380 }" id="I3380">SEG_42 173	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I3381 }" id="I3381">SEG_42 177	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1749'>Festus</a></li> 
<li :class="{'activeClass': idClicked == I3382 }" id="I3382">SEG_42 180	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2586'>Machaon</a></li> 
<li :class="{'activeClass': idClicked == I3383 }" id="I3383">SEG_42 188	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == I3384 }" id="I3384">SEG_42 189	Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == I3385 }" id="I3385">SEG_42 190	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP532'>Art35*</a></li> 
<li :class="{'activeClass': idClicked == I3386 }" id="I3386">SEG_42 203	Antipatros</li> 
<li :class="{'activeClass': idClicked == I3387 }" id="I3387">SEG_42 206	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3169'>Polydeukion</a></li> 
<li :class="{'activeClass': idClicked == I3388 }" id="I3388">SEG_42 215	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2536'>Lithos</a></li> 
<li :class="{'activeClass': idClicked == I3389 }" id="I3389">SEG_42 219	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3390 }" id="I3390">SEG_42 236	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2469'>Kynosarges</a></li> 
<li :class="{'activeClass': idClicked == I3391 }" id="I3391">SEG_42 237	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3268'>Ptolemies</a></li> 
<li :class="{'activeClass': idClicked == I3392 }" id="I3392">SEG_42 1811	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a> Taposiris</li> 
<li :class="{'activeClass': idClicked == I3393 }" id="I3393"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 43 (1993)</b></li> 
<li :class="{'activeClass': idClicked == I3394 }" id="I3394">SEG_43 24	Heliodoros</li> 
<li :class="{'activeClass': idClicked == I3395 }" id="I3395">SEG_43 70	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3834'>Zeu36*</a></li> 
<li :class="{'activeClass': idClicked == I3396 }" id="I3396">SEG_43 111	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM486">486</a></li> 
<li :class="{'activeClass': idClicked == I3397 }" id="I3397"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 44 (1994)</b></li> 
<li :class="{'activeClass': idClicked == I3398 }" id="I3398">SEG_44 3	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM38">38</a></li> 
<li :class="{'activeClass': idClicked == I3399 }" id="I3399">SEG_44 4	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP780'>Ath84*</a></li> 
<li :class="{'activeClass': idClicked == I3400 }" id="I3400">SEG_44 9	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP359'>Apo40*</a></li> 
<li :class="{'activeClass': idClicked == I3401 }" id="I3401">SEG_44 12	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a> (2)</li> 
<li :class="{'activeClass': idClicked == I3402 }" id="I3402">SEG_44 13	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3147'>Pnyx</a></li> 
<li :class="{'activeClass': idClicked == I3403 }" id="I3403">SEG_44 15	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP770'>Ath74*</a></li> 
<li :class="{'activeClass': idClicked == I3404 }" id="I3404">SEG_44 22	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2738'>Myrrhine</a></li> 
<li :class="{'activeClass': idClicked == I3405 }" id="I3405">SEG_44 41	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2917'>Panathenaic Stadium</a></li> 
<li :class="{'activeClass': idClicked == I3406 }" id="I3406">SEG_44 43	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP830'>Ath117*</a></li> 
<li :class="{'activeClass': idClicked == I3407 }" id="I3407">SEG_44 69	Philokrates</li> 
<li :class="{'activeClass': idClicked == I3408 }" id="I3408">SEG_44 74	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2644'>Meter</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM248">248</a></li> 
<li :class="{'activeClass': idClicked == I3409 }" id="I3409">SEG_44 75	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2492'>Leimon</a></li> 
<li :class="{'activeClass': idClicked == I3410 }" id="I3410">SEG_44 135	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3899'>Zeu89*</a></li> 
<li :class="{'activeClass': idClicked == I3411 }" id="I3411">SEG_44 139/159	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a></li> 
<li :class="{'activeClass': idClicked == I3412 }" id="I3412">SEG_44 162	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP989'>Chabrias</a></li> 
<li :class="{'activeClass': idClicked == I3413 }" id="I3413">SEG_44 165	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2761'>Nero</a></li> 
<li :class="{'activeClass': idClicked == I3414 }" id="I3414">SEG_44 172	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2391'>Keryx Panages</a></li> 
<li :class="{'activeClass': idClicked == I3415 }" id="I3415">SEG_44 180	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem10*</li> 
<li :class="{'activeClass': idClicked == I3416 }" id="I3416">SEG_44 215	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM165">165</a></li> 
<li :class="{'activeClass': idClicked == I3417 }" id="I3417">SEG_44 233	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM345">345</a></li> 
<li :class="{'activeClass': idClicked == I3418 }" id="I3418"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 45 (1995)</b></li> 
<li :class="{'activeClass': idClicked == I3419 }" id="I3419">SEG_45 1	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM85">85</a></li> 
<li :class="{'activeClass': idClicked == I3420 }" id="I3420">SEG_45 65	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1272'>Demos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == I3421 }" id="I3421">SEG_45 100	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2087'>Herodoros</a></li> 
<li :class="{'activeClass': idClicked == I3422 }" id="I3422">SEG_45 101	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3083'>Philippides</a></li> 
<li :class="{'activeClass': idClicked == I3423 }" id="I3423">SEG_45 105	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP297'>Aph45*</a></li> 
<li :class="{'activeClass': idClicked == I3424 }" id="I3424">SEG_45 131	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP462'>Ariarathes V</a></li> 
<li :class="{'activeClass': idClicked == I3425 }" id="I3425">SEG_45 178	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2987'>Parthenon</a></li> 
<li :class="{'activeClass': idClicked == I3426 }" id="I3426">SEG_45 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP327'>Apo13*</a></li> 
<li :class="{'activeClass': idClicked == I3427 }" id="I3427">SEG_45 222	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3213'>Post-Herulian Wall</a></li> 
<li :class="{'activeClass': idClicked == I3428 }" id="I3428">SEG_45 223	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3429 }" id="I3429">SEG_45 1029	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3430 }" id="I3430"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 46 (1996)</b></li> 
<li :class="{'activeClass': idClicked == I3431 }" id="I3431">SEG_46 17	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3711'>Tritopateres</a></li> 
<li :class="{'activeClass': idClicked == I3432 }" id="I3432">SEG_46 20	Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == I3433 }" id="I3433">SEG_46 22	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3263'>Prytanikon</a></li> 
<li :class="{'activeClass': idClicked == I3434 }" id="I3434">SEG_46 51	Thyechous</li> 
<li :class="{'activeClass': idClicked == I3435 }" id="I3435">SEG_46 56	Agora Bound¬ ary Stones</li> 
<li :class="{'activeClass': idClicked == I3436 }" id="I3436">SEG_46 59	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1002'>Chariot and Fetters</a></li> 
<li :class="{'activeClass': idClicked == I3437 }" id="I3437">SEG_46 62	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1527'>Dromos</a> Ptolemy II</li> 
<li :class="{'activeClass': idClicked == I3438 }" id="I3438">SEG_46 71	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP931'>Bouleuterion</a></li> 
<li :class="{'activeClass': idClicked == I3439 }" id="I3439">SEG_46 72	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == I3440 }" id="I3440">SEG_46 75	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM246">246</a></li> 
<li :class="{'activeClass': idClicked == I3441 }" id="I3441">SEG_46 104	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1511'>Dipylon Graves</a></li> 
<li :class="{'activeClass': idClicked == I3442 }" id="I3442">SEG_46 132	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3083'>Philippides</a></li> 
<li :class="{'activeClass': idClicked == I3443 }" id="I3443">SEG_46 152	Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == I3444 }" id="I3444">SEG_46 160	Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == I3445 }" id="I3445">SEG_46 161	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3089'>Philonides</a></li> 
<li :class="{'activeClass': idClicked == I3446 }" id="I3446">SEG_46 167	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2051'>Her2*</a></li> 
<li :class="{'activeClass': idClicked == I3447 }" id="I3447">SEG_46 176	Nikokrates</li> 
<li :class="{'activeClass': idClicked == I3448 }" id="I3448">SEG_46 179	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2934'>Pandaites</a></li> 
<li :class="{'activeClass': idClicked == I3449 }" id="I3449">SEG_46 202	Daidaleion</li> 
<li :class="{'activeClass': idClicked == I3450 }" id="I3450">SEG_46 214	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM493">493</a></li> 
<li :class="{'activeClass': idClicked == I3451 }" id="I3451">SEG_46 245	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == I3452 }" id="I3452">SEG_46 246	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP316'>Apo5*</a></li> 
<li :class="{'activeClass': idClicked == I3453 }" id="I3453">SEG_46 271	Arcadius</li> 
<li :class="{'activeClass': idClicked == I3454 }" id="I3454">SEG_46 272	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3213'>Post-Herulian Wall</a></li> 
<li :class="{'activeClass': idClicked == I3455 }" id="I3455">SEG_46 283	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP906'>Bakchios</a></li> 
<li :class="{'activeClass': idClicked == I3456 }" id="I3456">SEG_46 311	Ptolemy III</li> 
<li :class="{'activeClass': idClicked == I3457 }" id="I3457">SEG_46 368	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == I3458 }" id="I3458">SEG_46 640	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3846'>Zeu47*</a></li> 
<li :class="{'activeClass': idClicked == I3459 }" id="I3459">SEG_46 2269	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3062'>Phayllos</a></li> 
<li :class="{'activeClass': idClicked == I3460 }" id="I3460">SEG_46 2292	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2195'>Homonia</a></li> 
<li :class="{'activeClass': idClicked == I3461 }" id="I3461">SEG_46 2341	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == I3462 }" id="I3462">SEG_46 2358	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3865'>Zeus Meilichios</a></li> 
<li :class="{'activeClass': idClicked == I3463 }" id="I3463">SEG_46 2376	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2028'>Hrm27*</a></li> 
<li :class="{'activeClass': idClicked == I3464 }" id="I3464">SEG_46 2378	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3413'>Sokrates</a></li> 
<li :class="{'activeClass': idClicked == I3465 }" id="I3465">SEG_46 2388	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2507'>Leschai</a></li> 
<li :class="{'activeClass': idClicked == I3466 }" id="I3466"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 47 (1997)</b></li> 
<li :class="{'activeClass': idClicked == I3467 }" id="I3467">SEG_47 29	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP158'>Ammon</a></li> 
<li :class="{'activeClass': idClicked == I3468 }" id="I3468">SEG_47 46	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem1*</li> 
<li :class="{'activeClass': idClicked == I3469 }" id="I3469">SEG_47 56	Athens and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2656'>Miletos</a></li> 
<li :class="{'activeClass': idClicked == I3470 }" id="I3470">SEG_47 65	Drakonʼs Law</li> 
<li :class="{'activeClass': idClicked == I3471 }" id="I3471">SEG_47 72	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3460'>Stoa Basileios</a></li> 
<li :class="{'activeClass': idClicked == I3472 }" id="I3472">SEG_47 86	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM455">455</a></li> 
<li :class="{'activeClass': idClicked == I3473 }" id="I3473">SEG_47 87	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP993'>Chairion</a></li> 
<li :class="{'activeClass': idClicked == I3474 }" id="I3474">SEG_47 90	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP646'>Ath8*</a></li> 
<li :class="{'activeClass': idClicked == I3475 }" id="I3475">SEG_47 131	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3446'>Spartokos III</a></li> 
<li :class="{'activeClass': idClicked == I3476 }" id="I3476">SEG_47 135	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP786'>Ath89*</a></li> 
<li :class="{'activeClass': idClicked == I3477 }" id="I3477">SEG_47 186	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> Dio86*</li> 
<li :class="{'activeClass': idClicked == I3478 }" id="I3478">SEG_47 197	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2004'>Hrm6*</a></li> 
<li :class="{'activeClass': idClicked == I3479 }" id="I3479">SEG_47 204	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3647'>Thrasykles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3652'>Thrasyllos</a></li> 
<li :class="{'activeClass': idClicked == I3480 }" id="I3480">SEG_47 206	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1671'>Eros</a></li> 
<li :class="{'activeClass': idClicked == I3481 }" id="I3481">SEG_47 208	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP98'>Aischraios</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM136">136</a></li> 
<li :class="{'activeClass': idClicked == I3482 }" id="I3482">SEG_47 210	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP33'>Agathos Daimon</a></li> 
<li :class="{'activeClass': idClicked == I3483 }" id="I3483">SEG_47 211	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP250'>Aph7*</a></li> 
<li :class="{'activeClass': idClicked == I3484 }" id="I3484">SEG_47 212	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP742'>Ath55*</a></li> 
<li :class="{'activeClass': idClicked == I3485 }" id="I3485">SEG_47 213	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3435'>Sophokles</a></li> 
<li :class="{'activeClass': idClicked == I3486 }" id="I3486">SEG_47 219	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a>, Gaius Drusus</li> 
<li :class="{'activeClass': idClicked == I3487 }" id="I3487">SEG_47 222	Hadrian, statue base</li> 
<li :class="{'activeClass': idClicked == I3488 }" id="I3488">SEG_47 227bis	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1153'>Chrysippos</a></li> 
<li :class="{'activeClass': idClicked == I3489 }" id="I3489">SEG_47 233	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3312'>Roma and Augustus</a></li> 
<li :class="{'activeClass': idClicked == I3490 }" id="I3490">SEG_47 234	Andronikos</li> 
<li :class="{'activeClass': idClicked == I3491 }" id="I3491">SEG_47 236	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1986'>Herculius</a></li> 
<li :class="{'activeClass': idClicked == I3492 }" id="I3492">SEG_47 258	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3109'>Phrasikleia</a></li> 
<li :class="{'activeClass': idClicked == I3493 }" id="I3493">SEG_47 259	Jewish Mon.</li> 
<li :class="{'activeClass': idClicked == I3494 }" id="I3494">SEG_47 277	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3373'>Semnai</a></li> 
<li :class="{'activeClass': idClicked == I3495 }" id="I3495">SEG_47 518	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1671'>Eros</a></li> 
<li :class="{'activeClass': idClicked == I3496 }" id="I3496">SEG_47 2297	Jewish Mon.</li> 
<li :class="{'activeClass': idClicked == I3497 }" id="I3497"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 48 (1998)</b></li> 
<li :class="{'activeClass': idClicked == I3498 }" id="I3498">SEG_48 12	Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == I3499 }" id="I3499">SEG_48 43	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1868'>Hekatompedon</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM63">63</a></li> 
<li :class="{'activeClass': idClicked == I3500 }" id="I3500">SEG_48 52	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a>, decree</li> 
<li :class="{'activeClass': idClicked == I3501 }" id="I3501">SEG_48 54	Oikia Demosion</li> 
<li :class="{'activeClass': idClicked == I3502 }" id="I3502">SEG_48 69	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP82'>Aiakeion</a></li> 
<li :class="{'activeClass': idClicked == I3503 }" id="I3503">SEG_48 72	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2825'>Oikia Hiera</a></li> 
<li :class="{'activeClass': idClicked == I3504 }" id="I3504">SEG_48 79	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2610'>Medontidai</a></li> 
<li :class="{'activeClass': idClicked == I3505 }" id="I3505">SEG_48 80	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3062'>Phayllos</a></li> 
<li :class="{'activeClass': idClicked == I3506 }" id="I3506">SEG_48 83	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem41*</li> 
<li :class="{'activeClass': idClicked == I3507 }" id="I3507">SEG_48 84	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem35*</li> 
<li :class="{'activeClass': idClicked == I3508 }" id="I3508">SEG_48 89	Dipylon graves</li> 
<li :class="{'activeClass': idClicked == I3509 }" id="I3509">SEG_48 96	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP82'>Aiakeion</a></li> 
<li :class="{'activeClass': idClicked == I3510 }" id="I3510">SEG_48 99	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP785'>Ath88*</a></li> 
<li :class="{'activeClass': idClicked == I3511 }" id="I3511">SEG_48 103	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP639'>Ath2*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP773'>Ath77*</a></li> 
<li :class="{'activeClass': idClicked == I3512 }" id="I3512">SEG_48 104	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2917'>Panathenaic Stadium</a></li> 
<li :class="{'activeClass': idClicked == I3513 }" id="I3513">SEG_48 106	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1743'>Eutaxia</a></li> 
<li :class="{'activeClass': idClicked == I3514 }" id="I3514">SEG_48 116	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM87">87</a></li> 
<li :class="{'activeClass': idClicked == I3515 }" id="I3515">SEG_48 119	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1769'>Gephyraioi</a></li> 
<li :class="{'activeClass': idClicked == I3516 }" id="I3516">SEG_48 130	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP27'>Agathe Thea</a></li> 
<li :class="{'activeClass': idClicked == I3517 }" id="I3517">SEG_48 138	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1248'>Demeter Phrearoos</a></li> 
<li :class="{'activeClass': idClicked == I3518 }" id="I3518">SEG_48 169	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP604'>Ask36**</a></li> 
<li :class="{'activeClass': idClicked == I3519 }" id="I3519">SEG_48 195	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM194">194</a></li> 
<li :class="{'activeClass': idClicked == I3520 }" id="I3520">SEG_48 196	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1445'>Dio20*</a></li> 
<li :class="{'activeClass': idClicked == I3521 }" id="I3521">SEG_48 199	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3503'>Telephanes</a></li> 
<li :class="{'activeClass': idClicked == I3522 }" id="I3522">SEG_48 203	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == I3523 }" id="I3523">SEG_48 234	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1731'>Eupolis</a></li> 
<li :class="{'activeClass': idClicked == I3524 }" id="I3524">SEG_48 236	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == I3525 }" id="I3525">SEG_48 237	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I3526 }" id="I3526">SEG_48 238	Hadrian</li> 
<li :class="{'activeClass': idClicked == I3527 }" id="I3527">SEG_48 248	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP387'>Apo62*</a></li> 
<li :class="{'activeClass': idClicked == I3528 }" id="I3528">SEG_48 249	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP493'>Art3*</a></li> 
<li :class="{'activeClass': idClicked == I3529 }" id="I3529">SEG_48 253	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP505'>Art14*</a></li> 
<li :class="{'activeClass': idClicked == I3530 }" id="I3530">SEG_48 260	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1532'>Eagle</a></li> 
<li :class="{'activeClass': idClicked == I3531 }" id="I3531">SEG_48 261	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1414'>Dio1*</a></li> 
<li :class="{'activeClass': idClicked == I3532 }" id="I3532">SEG_48 272	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2498'>Leokorion</a></li> 
<li :class="{'activeClass': idClicked == I3533 }" id="I3533">SEG_48 275	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP28'>Agathe Tyche</a></li> 
<li :class="{'activeClass': idClicked == I3534 }" id="I3534">SEG_48 284	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem11*</li> 
<li :class="{'activeClass': idClicked == I3535 }" id="I3535">SEG_48 292	Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == I3536 }" id="I3536">SEG_48 362	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM345">345</a></li> 
<li :class="{'activeClass': idClicked == I3537 }" id="I3537">SEG_48 2030	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == I3538 }" id="I3538"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 49 (1999)</b></li> 
<li :class="{'activeClass': idClicked == I3539 }" id="I3539">SEG_49 39	TYR—, son of Skylax</li> 
<li :class="{'activeClass': idClicked == I3540 }" id="I3540">SEG_49 55	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP642'>Ath4*</a></li> 
<li :class="{'activeClass': idClicked == I3541 }" id="I3541">SEG_49 62	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP763'>Ath68*</a></li> 
<li :class="{'activeClass': idClicked == I3542 }" id="I3542">SEG_49 63	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM86">86</a></li> 
<li :class="{'activeClass': idClicked == I3543 }" id="I3543">SEG_49 76	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP906'>Bakchios</a></li> 
<li :class="{'activeClass': idClicked == I3544 }" id="I3544">SEG_49 106	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP554'>Asander</a></li> 
<li :class="{'activeClass': idClicked == I3545 }" id="I3545">SEG_49 109	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM377">377</a></li> 
<li :class="{'activeClass': idClicked == I3546 }" id="I3546">SEG_49 113	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2318'>Kallias</a> of Sphettos</li> 
<li :class="{'activeClass': idClicked == I3547 }" id="I3547">SEG_49 117	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2358'>Kephisodoros</a></li> 
<li :class="{'activeClass': idClicked == I3548 }" id="I3548">SEG_49 119	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3266'>Ptolemaios</a>, son of <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3266'>Ptolemaios</a></li> 
<li :class="{'activeClass': idClicked == I3549 }" id="I3549">SEG_49 130	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP236'>Antonius</a></li> 
<li :class="{'activeClass': idClicked == I3550 }" id="I3550">SEG_49 159	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP169'>Amynos</a> (2) <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3435'>Sophokles</a></li> 
<li :class="{'activeClass': idClicked == I3551 }" id="I3551">SEG_49 160*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2101'>Hro10*</a></li> 
<li :class="{'activeClass': idClicked == I3552 }" id="I3552">SEG_49 162	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP917'>Bendis</a></li> 
<li :class="{'activeClass': idClicked == I3553 }" id="I3553">SEG_49 164	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> 27**</li> 
<li :class="{'activeClass': idClicked == I3554 }" id="I3554">SEG_49 165	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP570'>Ask12*</a></li> 
<li :class="{'activeClass': idClicked == I3555 }" id="I3555">SEG_49 180	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2361'>Kephisos</a></li> 
<li :class="{'activeClass': idClicked == I3556 }" id="I3556">SEG_49 181	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3822'>Zeu28*</a></li> 
<li :class="{'activeClass': idClicked == I3557 }" id="I3557">SEG_49 216	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP634'>Astydamas</a></li> 
<li :class="{'activeClass': idClicked == I3558 }" id="I3558">SEG_49 219	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP572'>Ask13*</a></li> 
<li :class="{'activeClass': idClicked == I3559 }" id="I3559">SEG_49 222	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP292'>Aph40**</a></li> 
<li :class="{'activeClass': idClicked == I3560 }" id="I3560">SEG_49 231	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I3561 }" id="I3561">SEG_49 232	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a></li> 
<li :class="{'activeClass': idClicked == I3562 }" id="I3562">SEG_49 239	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1359'>Dexileos</a></li> 
<li :class="{'activeClass': idClicked == I3563 }" id="I3563">SEG_49 330	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM408B">408B</a></li> 
<li :class="{'activeClass': idClicked == I3564 }" id="I3564">SEG_49 2408	Plateia</li> 
<li :class="{'activeClass': idClicked == I3565 }" id="I3565">SEG_49 2495	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2229'>Hypsistos</a></li> 
<li :class="{'activeClass': idClicked == I3566 }" id="I3566"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 50 (2000)</b></li> 
<li :class="{'activeClass': idClicked == I3567 }" id="I3567">SEG_50 19	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1905'>Hera</a></li> 
<li :class="{'activeClass': idClicked == I3568 }" id="I3568">SEG_50 21	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == I3569 }" id="I3569">SEG_50 32	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2676'>Moirai</a></li> 
<li :class="{'activeClass': idClicked == I3570 }" id="I3570">SEG_50 36	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP769'>Ath73*</a></li> 
<li :class="{'activeClass': idClicked == I3571 }" id="I3571">SEG_50 38	Opisthdomos</li> 
<li :class="{'activeClass': idClicked == I3572 }" id="I3572">SEG_50 47	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a></li> 
<li :class="{'activeClass': idClicked == I3573 }" id="I3573">SEG_50 63	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP843'>Ath127*</a></li> 
<li :class="{'activeClass': idClicked == I3574 }" id="I3574">SEG_50 67	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM8">8</a></li> 
<li :class="{'activeClass': idClicked == I3575 }" id="I3575">SEG_50 68	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2763'>Nike</a>: Golden Nikai</li> 
<li :class="{'activeClass': idClicked == I3576 }" id="I3576">SEG_50 69	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1628'>Erechtheion</a></li> 
<li :class="{'activeClass': idClicked == I3577 }" id="I3577">SEG_50 72	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1835'>Harmodios and Aristogeiton</a></li> 
<li :class="{'activeClass': idClicked == I3578 }" id="I3578">SEG_50 78	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP645'>Ath7*</a></li> 
<li :class="{'activeClass': idClicked == I3579 }" id="I3579">SEG_50 80	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP398'>Apo71*</a></li> 
<li :class="{'activeClass': idClicked == I3580 }" id="I3580">SEG_50 82	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == I3581 }" id="I3581">SEG_50 86	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3789'>Zeu1*</a></li> 
<li :class="{'activeClass': idClicked == I3582 }" id="I3582">SEG_50 87	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2808'>Nym10*</a></li> 
<li :class="{'activeClass': idClicked == I3583 }" id="I3583">SEG_50 96*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP682'>Ath20*</a></li> 
<li :class="{'activeClass': idClicked == I3584 }" id="I3584">SEG_50 100	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == I3585 }" id="I3585">SEG_50 137	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM345">345</a></li> 
<li :class="{'activeClass': idClicked == I3586 }" id="I3586">SEG_50 155	Hadrian, arch</li> 
<li :class="{'activeClass': idClicked == I3587 }" id="I3587">SEG_50 162*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3820'>Zeu26*</a></li> 
<li :class="{'activeClass': idClicked == I3588 }" id="I3588">SEG_50 183	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2365'>Kerameikos</a> boundary stones</li> 
<li :class="{'activeClass': idClicked == I3589 }" id="I3589">SEG_50 190*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP313'>Apo2*</a></li> 
<li :class="{'activeClass': idClicked == I3590 }" id="I3590">SEG_50 193	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2778'>Nikias</a>, son of Nikomedes</li> 
<li :class="{'activeClass': idClicked == I3591 }" id="I3591">SEG_50 209	Trebellius Pufus</li> 
<li :class="{'activeClass': idClicked == I3592 }" id="I3592">SEG_50 211	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == I3593 }" id="I3593">SEG_50 268	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3088'>Philon</a></li> 
<li :class="{'activeClass': idClicked == I3594 }" id="I3594">SEG_50 273	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM245">245</a></li> 
<li :class="{'activeClass': idClicked == I3595 }" id="I3595">SEG_50 1071	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1905'>Hera</a></li> 
<li :class="{'activeClass': idClicked == I3596 }" id="I3596"><b><a v-on:click.prevent="replaceLink"  href='biblio.html#AB512'>SEG</a> 51 (2001)</b></li> 
<li :class="{'activeClass': idClicked == I3597 }" id="I3597">SEG_51 2	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3428'>Solon</a></li> 
<li :class="{'activeClass': idClicked == I3598 }" id="I3598">SEG_51 11	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP917'>Bendis</a></li> 
<li :class="{'activeClass': idClicked == I3599 }" id="I3599">SEG_51 13	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP376'>Apo52**</a></li> 
<li :class="{'activeClass': idClicked == I3600 }" id="I3600">SEG_51 14	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3766'>Vestal Virgins</a></li> 
<li :class="{'activeClass': idClicked == I3601 }" id="I3601">SEG_51 26	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == I3602 }" id="I3602">SEG_51 30	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3015'>Pelargikon</a></li> 
<li :class="{'activeClass': idClicked == I3603 }" id="I3603">SEG_51 35	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1523'>Drakonʼs Laws</a></li> 
<li :class="{'activeClass': idClicked == I3604 }" id="I3604">SEG_51 38	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1567'>Eleusinion</a></li> 
<li :class="{'activeClass': idClicked == I3605 }" id="I3605">SEG_51 39	Athea <a v-on:click.prevent="replaceLink"  href='NPP.html#NP818'>Ath113*</a></li> 
<li :class="{'activeClass': idClicked == I3606 }" id="I3606">SEG_51 40	Bellerophon</li> 
<li :class="{'activeClass': idClicked == I3607 }" id="I3607">SEG_51 43	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP759'>Ath65**</a></li> 
<li :class="{'activeClass': idClicked == I3608 }" id="I3608">SEG_51 44	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3042'>Persian War Epigrams</a></li> 
<li :class="{'activeClass': idClicked == I3609 }" id="I3609">SEG_51 49-50	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem1*</li> 
<li :class="{'activeClass': idClicked == I3610 }" id="I3610">SEG_51 51*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem9*</li> 
<li :class="{'activeClass': idClicked == I3611 }" id="I3611">SEG_51 56-59	Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM412">412</a></li> 
<li :class="{'activeClass': idClicked == I3612 }" id="I3612">SEG_51 81*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP849'>Ath132*</a></li> 
<li :class="{'activeClass': idClicked == I3613 }" id="I3613">SEG_51 93	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP39'>Aglauros</a></li> 
<li :class="{'activeClass': idClicked == I3614 }" id="I3614">SEG_51 98	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a></li> 
<li :class="{'activeClass': idClicked == I3615 }" id="I3615">SEG_51 138	Nephthys <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3199'>Poseidon Chamaizelos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3824'>Zeu30*</a></li> 
<li :class="{'activeClass': idClicked == I3616 }" id="I3616">SEG_51 156	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP813'>Ath107*</a>-108</li> 
<li :class="{'activeClass': idClicked == I3617 }" id="I3617">SEG_51 160	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == I3618 }" id="I3618">SEG_51 168	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP2932'>Panathenaic Way</a></li> 
<li :class="{'activeClass': idClicked == I3619 }" id="I3619">SEG_51 194	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3774'>Xenokles</a></li> 
<li :class="{'activeClass': idClicked == I3620 }" id="I3620">SEG_51 195*	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1436'>Dio14*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1437'>Dio15*</a></li> 
<li :class="{'activeClass': idClicked == I3621 }" id="I3621">SEG_51 204	Sarapion Mon.</li> 
<li :class="{'activeClass': idClicked == I3622 }" id="I3622">SEG_51 216	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3481'>Sueris</a></li> 
<li :class="{'activeClass': idClicked == I3623 }" id="I3623">SEG_51 218	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a> Illyrius</li> 
<li :class="{'activeClass': idClicked == I3624 }" id="I3624">SEG_51 221	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1370'>Dexippos</a></li> 
<li :class="{'activeClass': idClicked == I3625 }" id="I3625">SEG_51 224	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1923'>Hrk4*</a></li> 
<li :class="{'activeClass': idClicked == I3626 }" id="I3626">SEG_51 232	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a></li> 
<li :class="{'activeClass': idClicked == I3627 }" id="I3627">SEG_51 233	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I3628 }" id="I3628">SEG_51 234	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP527'>Art33*</a></li> 
<li :class="{'activeClass': idClicked == I3629 }" id="I3629">SEG_51 235	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP539'>Art42*</a></li> 
<li :class="{'activeClass': idClicked == I3630 }" id="I3630">SEG_51 298	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3245'>Proklos</a></li> 
<li :class="{'activeClass': idClicked == I3631 }" id="I3631">SEG_51 305	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP3093'>Philotera</a></li> 
<li :class="{'activeClass': idClicked == I3632 }" id="I3632">SEG_51 315	Part II no 461</li> 
<li :class="{'activeClass': idClicked == I3633 }" id="I3633">SEG_51 1005	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP432'>Archelaos</a></li> 
</ul>
<ul>
<h4 :class="{'activeClass': idClicked == I3634 }" id="I3634">CORPUS INSCRIPTIONUM LATINARUM</h4> 
<li :class="{'activeClass': idClicked == I3635 }" id="I3635">CIL_III 549 Hadrian</li> 
<li :class="{'activeClass': idClicked == I3636 }" id="I3636">CIL_III 550 Hadrian</li> 
<li :class="{'activeClass': idClicked == I3637 }" id="I3637">CIL_III 552 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == I3638 }" id="I3638">CIL_III 7280 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2313'>Jupiter Optimus Maximus</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3763'>Venus</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2640'>Mercury</a></li> 
<li :class="{'activeClass': idClicked == I3639 }" id="I3639">CIL_III 7281 Hadrian</li> 
<li :class="{'activeClass': idClicked == I3640 }" id="I3640">CIL_III 7282 Hadrian</li> 
<li :class="{'activeClass': idClicked == I3641 }" id="I3641">CIL_III 7283 Hadrian</li> 
<li :class="{'activeClass': idClicked == I3642 }" id="I3642">CIL_III 7285 Hadrian</li> 
</ul>


</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    