import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'  
import SourceBook from '../views/SourceBook.vue' 
import Forward from '../components/parts/Forward.vue' 
import NNM from '../components/parts/NNM.vue' 
import NPP from '../components/parts/NPP.vue' 
import Paus1GR from '../components/parts/Paus1GR.vue' 
import Paus1 from '../components/parts/Paus1.vue'  
import JBMSFull from '../components/parts/JBMSFull.vue' 
import Abbreviations from '../components/parts/Abbreviations.vue' 
import GenIndex from '../components/parts/GenIndex.vue' 
import LateAthensIndex from '../components/parts/LateAthensIndex.vue' 
import FloraFaunaIndex from '../components/parts/FloraFaunaIndex.vue' 
import ForeignIndex from '../components/parts/ForeignIndex.vue' 
import PersonIndex from '../components/parts/PersonIndex.vue' 
import IGIndex from '../components/parts/IGIndex.vue' 
import SEGCILIndex from '../components/parts/SEGCILIndex.vue' 
import AcropolisNNMIndex from '../components/parts/AcropolisNNMIndex.vue' 
import AgoraKerameikosIndex from '../components/parts/AgoraKerameikosIndex.vue' 
import EphoriaIndex from '../components/parts/EphoriaIndex.vue' 
import OtherMuseumsIndex from '../components/parts/OtherMuseumsIndex.vue' 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },  
  {
    path: '/sourcebook',
    name: 'sourcebook',
    component: SourceBook ,
    redirect: to => { 
      return { name: 'Forward' }
    },
    children: [
      { 
        path: 'Forward/:term?',
        name: 'Forward',
        component: Forward
      } ,
      { 
        path: 'NNM/:term?',
        name: 'NNM',
        component: NNM
      } ,
      { 
        path: 'NPP/:term?',
        name: 'NPP',
        component: NPP
      } ,
      { 
        path: 'Paus1GR/:term?',
        name: 'Paus1GR',
        component: Paus1GR
      } , 
      { 
        path: 'Paus1/:term?',
        name: 'Paus1',
        component: Paus1
      } ,  
      { 
        path: 'Abbreviations/:term?',
        name: 'Abbreviations',
        component: Abbreviations
      } ,
      { 
        path: 'GenIndex/:term?',
        name: 'GenIndex',
        component: GenIndex
      } ,
      { 
        path: 'LateAthensIndex/:term?',
        name: 'LateAthensIndex',
        component: LateAthensIndex
      } ,
      { 
        path: 'FloraFaunaIndex/:term?',
        name: 'FloraFaunaIndex',
        component: FloraFaunaIndex
      },
      { 
        path: 'ForeignIndex/:term?',
        name: 'ForeignIndex',
        component: ForeignIndex
      } ,
      { 
        path: 'PersonIndex/:term?',
        name: 'PersonIndex',
        component: PersonIndex
      } ,
      { 
        path: 'IGIndex/:term?',
        name: 'IGIndex',
        component: IGIndex
      } ,
      { 
        path: 'SEGCILIndex/:term?',
        name: 'SEGCILIndex',
        component: SEGCILIndex
      } ,
      { 
        path: 'AcropolisNNMIndex/:term?',
        name: 'AcropolisNNMIndex',
        component: AcropolisNNMIndex
      } ,
      { 
        path: 'AgoraKerameikosIndex/:term?',
        name: 'AgoraKerameikosIndex',
        component: AgoraKerameikosIndex
      } ,
      { 
        path: 'EphoriaIndex/:term?',
        name: 'EphoriaIndex',
        component: EphoriaIndex
      } ,
      { 
        path: 'OtherMuseumsIndex/:term?',
        name: 'OtherMuseumsIndex',
        component: OtherMuseumsIndex
      } ,
      { 
        path: 'JBMSFull/:term?',
        name: 'JBMSFull',
        component: JBMSFull
      } 
            
      
      
    ]
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
