<template>   
<div class="home-content">  

  <div>
      <p id="home-intro-text">
        <h1>Judith Binder, A Sourcebook</h1>
        <p>Judith Binder's lifetime project "The Monuments and Sites of Athens: A Sourcebook", is essentially
           a research tool that collects all ancient written sources and archaeological data on Athenian topography and the history of archaeological research in the city.</p>
      </p>   
  </div>

</div>
</template>

<script> 

export default {
  name: 'Home', 
  setup(){  
  }
}
</script>

<style scoped>
    .home-content{   
      background-image: url('../assets/desk.jpg'); 
      display: block; 
      min-height: 100% ; 
      margin-top: 1px;
      width: 100%; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    } 

    #home-intro-text{
      display: block;
      position:absolute;
      width: 700px;
      box-sizing: border-box;
      text-align: right;
      bottom : 25%;
      right: 60px;
      padding: 20px;
      border-radius: 20px ;
      background-color: rgba(255, 255, 255, 0.8);       
    } 

    h1{
      font-size: 35px;
    }

    @media only screen and (max-width: 850px) {
      #home-intro-text{ 
        width: 570px;  
        bottom : 20%;
        right: 35px; 
      } 
    }

    @media only screen and (max-width: 650px) {
      #home-intro-text{ 
        width: 90%;  
        bottom : 15%;
        right: 15px; 
      } 
    }



</style>
