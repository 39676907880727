<template>

    <div class="part-text">
<h3  :class="{'activeClass': idClicked == I3643 }" id="I3643" >ACROPOLIS MUSEUM INDEX</h3>
<ul>
<li  :class="{'activeClass': idClicked == I3644 }" id="I3644" >AcrM 1 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1936'>Hrk13*</a></li>
<li  :class="{'activeClass': idClicked == I3645 }" id="I3645" >AcrM 2 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1937'>Hrk14*</a></li>
<li  :class="{'activeClass': idClicked == I3646 }" id="I3646" >AcrM 3/4 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3647 }" id="I3647" >AcrM 9 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1939'>Hrk15*</a></li>
<li  :class="{'activeClass': idClicked == I3648 }" id="I3648" >AcrM 13+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3649 }" id="I3649" >AcrM 21 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3650 }" id="I3650" >AcrM 35/37 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3651 }" id="I3651" >AcrM 40+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3652 }" id="I3652" >AcrM 50 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP702'>Ath25*</a></li>
<li  :class="{'activeClass': idClicked == I3653 }" id="I3653" >AcrM 52 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2832'>Olive Tree</a></li>
<li  :class="{'activeClass': idClicked == I3654 }" id="I3654" >AcrM 56 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2867'>Owl</a></li>
<li  :class="{'activeClass': idClicked == I3655 }" id="I3655" >AcrM 63 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3656 }" id="I3656" >AcrM 66+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3657 }" id="I3657" >AcrM 69+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3658 }" id="I3658" >AcrM 122 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2980'>Panthers</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3659 }" id="I3659" >AcrM 136 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP703'>Ath26*</a></li>
<li  :class="{'activeClass': idClicked == I3660 }" id="I3660" >AcrM 140 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP704'>Ath27*</a></li>
<li  :class="{'activeClass': idClicked == I3661 }" id="I3661" >AcrM 340 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2867'>Owl</a></li>
<li  :class="{'activeClass': idClicked == I3662 }" id="I3662" >AcrM 545 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3663 }" id="I3663" >AcrM 551/5 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2980'>Panthers</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3664 }" id="I3664" >AcrM 577 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3665 }" id="I3665" >AcrM 597 Hippalektryon</li>
<li  :class="{'activeClass': idClicked == I3666 }" id="I3666" >AcrM 622 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2010'>Hrm12*</a></li>
<li  :class="{'activeClass': idClicked == I3667 }" id="I3667" >AcrM 624 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2684'>Moschophoros</a></li>
<li  :class="{'activeClass': idClicked == I3668 }" id="I3668" >AcrM 625 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP705'>Ath28*</a></li>
<li  :class="{'activeClass': idClicked == I3669 }" id="I3669" >AcrM 629 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP993'>Chairion</a></li>
<li  :class="{'activeClass': idClicked == I3670 }" id="I3670" >AcrM 631+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3671 }" id="I3671" >AcrM 632 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3449'>Sphinx</a></li>
<li  :class="{'activeClass': idClicked == I3672 }" id="I3672" >AcrM 642 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a></li>
<li  :class="{'activeClass': idClicked == I3673 }" id="I3673" >AcrM 681 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP645'>Ath7*</a></li>
<li  :class="{'activeClass': idClicked == I3674 }" id="I3674" >AcrM 690 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP646'>Ath8*</a></li>
<li  :class="{'activeClass': idClicked == I3675 }" id="I3675" >AcrM 695 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP730'>Ath47*</a></li>
<li  :class="{'activeClass': idClicked == I3676 }" id="I3676" >AcrM 701 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1777'>Gorgon</a></li>
<li  :class="{'activeClass': idClicked == I3677 }" id="I3677" >AcrM 973 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP784'>Ath87*</a></li>
<li  :class="{'activeClass': idClicked == I3678 }" id="I3678" >AcrM 1313 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3131'>Ploutarchos</a></li>
<li  :class="{'activeClass': idClicked == I3679 }" id="I3679" >AcrM 1323 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1972'>Hrk41*</a></li>
<li  :class="{'activeClass': idClicked == I3680 }" id="I3680" >AcrM 1331 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP118'>Alexander the Great</a></li>
<li  :class="{'activeClass': idClicked == I3681 }" id="I3681" >AcrM 1332 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3239'>Potter Hero</a></li>
<li  :class="{'activeClass': idClicked == I3682 }" id="I3682" >AcrM 1337 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP706'>Ath28**</a></li>
<li  :class="{'activeClass': idClicked == I3683 }" id="I3683" >AcrM 1339 Trireme Relief</li>
<li  :class="{'activeClass': idClicked == I3684 }" id="I3684" >AcrM 1340 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3685 }" id="I3685" >AcrM 1342 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3686 }" id="I3686" >AcrM 1343 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3687 }" id="I3687" >AcrM 1345 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2900'>Pan12*</a></li>
<li  :class="{'activeClass': idClicked == I3688 }" id="I3688" >AcrM 1346 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2011'>Hrm13*</a></li>
<li  :class="{'activeClass': idClicked == I3689 }" id="I3689" >AcrM 1347 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2867'>Owl</a></li>
<li  :class="{'activeClass': idClicked == I3690 }" id="I3690" >AcrM 1348 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3704'>Triptolemos</a></li>
<li  :class="{'activeClass': idClicked == I3691 }" id="I3691" >AcrM 1358 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3249'>Prokne</a></li>
<li  :class="{'activeClass': idClicked == I3692 }" id="I3692" >AcrM 1362 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP799'>Ath95*</a></li>
<li  :class="{'activeClass': idClicked == I3693 }" id="I3693" >AcrM 1366 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM122">122</a></li>
<li  :class="{'activeClass': idClicked == I3694 }" id="I3694" >AcrM 1367 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP760'>Ath66*</a></li>
<li  :class="{'activeClass': idClicked == I3695 }" id="I3695" >AcrM 2186 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1608'>Epicurus</a></li>
<li  :class="{'activeClass': idClicked == I3696 }" id="I3696" >AcrM 2285 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3413'>Sokrates</a></li>
<li  :class="{'activeClass': idClicked == I3697 }" id="I3697" >AcrM 2307 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2087'>Herodoros</a></li>
<li  :class="{'activeClass': idClicked == I3698 }" id="I3698" >AcrM 2338 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP707'>Ath29*</a></li>
<li  :class="{'activeClass': idClicked == I3699 }" id="I3699" >AcrM 2344 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP707'>Ath29*</a></li>
<li  :class="{'activeClass': idClicked == I3700 }" id="I3700" >AcrM 2347 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM109">109</a></li>
<li  :class="{'activeClass': idClicked == I3701 }" id="I3701" >AcrM 2360 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1746'>Faustina</a> the Elder</li>
<li  :class="{'activeClass': idClicked == I3702 }" id="I3702" >AcrM 2394 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1692'>Eubouleus</a></li>
<li  :class="{'activeClass': idClicked == I3703 }" id="I3703" >AcrM 2401 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3901'>Zeu91*</a></li>
<li  :class="{'activeClass': idClicked == I3704 }" id="I3704" >AcrM 2426 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP734'>Ath50*</a></li>
<li  :class="{'activeClass': idClicked == I3705 }" id="I3705" >AcrM 2437 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP806'>Ath102*</a></li>
<li  :class="{'activeClass': idClicked == I3706 }" id="I3706" >AcrM 2444 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2832'>Olive Tree</a></li>
<li  :class="{'activeClass': idClicked == I3707 }" id="I3707" >AcrM 2451 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2121'>Hro26*</a></li>
<li  :class="{'activeClass': idClicked == I3708 }" id="I3708" >AcrM 2530 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP558'>Ask2*</a></li>
<li  :class="{'activeClass': idClicked == I3709 }" id="I3709" >AcrM 2544 Trireme</li>
<li  :class="{'activeClass': idClicked == I3710 }" id="I3710" >AcrM 2586 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1963'>Hrk34*</a></li>
<li  :class="{'activeClass': idClicked == I3711 }" id="I3711" >AcrM 2624 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM112">112</a></li>
<li  :class="{'activeClass': idClicked == I3712 }" id="I3712" >AcrM 2727 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM112B">112B</a></li>
<li  :class="{'activeClass': idClicked == I3713 }" id="I3713" >AcrM 2743 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2035'>Hrm33*</a></li>
<li  :class="{'activeClass': idClicked == I3714 }" id="I3714" >AcrM 2799 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2315'>Kairos</a></li>
<li  :class="{'activeClass': idClicked == I3715 }" id="I3715" >AcrM 2803 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP708'>Ath30*</a></li>
<li  :class="{'activeClass': idClicked == I3716 }" id="I3716" >AcrM 2805 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP709'>Ath31*</a></li>
<li  :class="{'activeClass': idClicked == I3717 }" id="I3717" >AcrM 2861 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP247'>Aph4*</a></li>
<li  :class="{'activeClass': idClicked == I3718 }" id="I3718" >AcrM 2881 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2061'>Her10*</a></li>
<li  :class="{'activeClass': idClicked == I3719 }" id="I3719" >AcrM 3001 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP806'>Ath102*</a></li>
<li  :class="{'activeClass': idClicked == I3720 }" id="I3720" >AcrM 3013 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2120'>Hro25*</a></li>
<li  :class="{'activeClass': idClicked == I3721 }" id="I3721" >AcrM 3189+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP716'>Ath37*</a></li>
<li  :class="{'activeClass': idClicked == I3722 }" id="I3722" >AcrM 3209 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP709'>Ath31*</a></li>
<li  :class="{'activeClass': idClicked == I3723 }" id="I3723" >AcrM 3457 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM108">108</a></li>
<li  :class="{'activeClass': idClicked == I3724 }" id="I3724" >AcrM 3541 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP993'>Chairion</a></li>
<li  :class="{'activeClass': idClicked == I3725 }" id="I3725" >AcrM 3647 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2630'>Menander</a></li>
<li  :class="{'activeClass': idClicked == I3726 }" id="I3726" >AcrM 3665/3667+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a></li>
<li  :class="{'activeClass': idClicked == I3727 }" id="I3727" >AcrM 3703 Ship</li>
<li  :class="{'activeClass': idClicked == I3728 }" id="I3728" >AcrM 3722 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP682'>Ath20*</a></li>
<li  :class="{'activeClass': idClicked == I3729 }" id="I3729" >AcrM 3737 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP915'>Bear</a></li>
<li  :class="{'activeClass': idClicked == I3730 }" id="I3730" >AcrM 3747 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM102">102</a></li>
<li  :class="{'activeClass': idClicked == I3731 }" id="I3731" >AcrM 3776 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP646'>Ath8*</a></li>
<li  :class="{'activeClass': idClicked == I3732 }" id="I3732" >AcrM 3799 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM103">103</a></li>
<li  :class="{'activeClass': idClicked == I3733 }" id="I3733" >AcrM 3832-3834 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3734 }" id="I3734" >AcrM 3902/40 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3735 }" id="I3735" >AcrM 3945/88+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP667'>Ath19*</a></li>
<li  :class="{'activeClass': idClicked == I3736 }" id="I3736" >AcrM 4040/42 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM104">104</a></li>
<li  :class="{'activeClass': idClicked == I3737 }" id="I3737" >AcrM 4047 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1404'>Dione</a></li>
<li  :class="{'activeClass': idClicked == I3738 }" id="I3738" >AcrM 4064 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2899'>Pan11*</a></li>
<li  :class="{'activeClass': idClicked == I3739 }" id="I3739" >AcrM 4069 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP33'>Agathos Daimon</a></li>
<li  :class="{'activeClass': idClicked == I3740 }" id="I3740" >AcrM 4303 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP782'>Ath86*</a></li>
<li  :class="{'activeClass': idClicked == I3741 }" id="I3741" >AcrM 4359 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM66">66</a></li>
<li  :class="{'activeClass': idClicked == I3742 }" id="I3742" >AcrM 4402 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM50">50</a></li>
<li  :class="{'activeClass': idClicked == I3743 }" id="I3743" >AcrM 4404 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM52">52</a></li>
<li  :class="{'activeClass': idClicked == I3744 }" id="I3744" >AcrM 4406 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM51">51</a></li>
<li  :class="{'activeClass': idClicked == I3745 }" id="I3745" >AcrM 4487 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3874'>Zeu68*</a></li>
<li  :class="{'activeClass': idClicked == I3746 }" id="I3746" >AcrM 4503 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM48">48</a></li>
<li  :class="{'activeClass': idClicked == I3747 }" id="I3747" >AcrM 4510 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM48">48</a></li>
<li  :class="{'activeClass': idClicked == I3748 }" id="I3748" >AcrM 4550+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3749 }" id="I3749" >AcrM 4603 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2087'>Herodoros</a></li>
<li  :class="{'activeClass': idClicked == I3750 }" id="I3750" >AcrM 4734+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP730'>Ath47*</a></li>
<li  :class="{'activeClass': idClicked == I3751 }" id="I3751" >AcrM 4742 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP800'>Ath96*</a></li>
<li  :class="{'activeClass': idClicked == I3752 }" id="I3752" >AcrM 4887 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3874'>Zeu68*</a></li>
<li  :class="{'activeClass': idClicked == I3753 }" id="I3753" >AcrM 4901 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1964'>Hrk35*</a></li>
<li  :class="{'activeClass': idClicked == I3754 }" id="I3754" >AcrM 4915 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP729'>Ath46*</a></li>
<li  :class="{'activeClass': idClicked == I3755 }" id="I3755" >AcrM 4938 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM67">67</a></li>
<li  :class="{'activeClass': idClicked == I3756 }" id="I3756" >AcrM 5906 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1950'>Hrk23*</a></li>
<li  :class="{'activeClass': idClicked == I3757 }" id="I3757" >AcrM 6117/8 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM104">104</a></li>
<li  :class="{'activeClass': idClicked == I3758 }" id="I3758" >AcrM 6476 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM151">151</a></li>
<li  :class="{'activeClass': idClicked == I3759 }" id="I3759" >AcrM 6506 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP703'>Ath26*</a></li>
<li  :class="{'activeClass': idClicked == I3760 }" id="I3760" >AcrM 6510 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3406'>Snake</a></li>
<li  :class="{'activeClass': idClicked == I3761 }" id="I3761" >AcrM 6656 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP501'>Art10*</a></li>
<li  :class="{'activeClass': idClicked == I3762 }" id="I3762" >AcrM 6978 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP652'>Ath10*</a></li>
<li  :class="{'activeClass': idClicked == I3763 }" id="I3763" >AcrM 7019 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3899'>Zeu89*</a></li>
<li  :class="{'activeClass': idClicked == I3764 }" id="I3764" >AcrM 7021 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM85">85</a></li>
<li  :class="{'activeClass': idClicked == I3765 }" id="I3765" >AcrM 7232 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1964'>Hrk35*</a></li>
<li  :class="{'activeClass': idClicked == I3766 }" id="I3766" >AcrM 7248 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2596'>Marcus Aurelius</a></li>
<li  :class="{'activeClass': idClicked == I3767 }" id="I3767" >AcrM 7275 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3122'>Plato</a></li>
<li  :class="{'activeClass': idClicked == I3768 }" id="I3768" >AcrM 7285 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1692'>Eubouleus</a></li>
<li  :class="{'activeClass': idClicked == I3769 }" id="I3769" >AcrM 7898 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2318'>Kallias</a></li>
<li  :class="{'activeClass': idClicked == I3770 }" id="I3770" >AcrM 7988 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP581'>Ask20*</a></li>
<li  :class="{'activeClass': idClicked == I3771 }" id="I3771" >AcrM 9716 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2138'>Hro43*</a></li>
<li  :class="{'activeClass': idClicked == I3772 }" id="I3772" >AcrM 9945 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM202">202</a></li>
<li  :class="{'activeClass': idClicked == I3773 }" id="I3773" >AcrM 12367 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP504'>Art13*</a></li>
<li  :class="{'activeClass': idClicked == I3774 }" id="I3774" >AcrM 13201 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1377'>Dieitrephes</a> </li>
<li  :class="{'activeClass': idClicked == I3775 }" id="I3775" >AcrM 13204/5 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM86">86</a> </li>
<li  :class="{'activeClass': idClicked == I3776 }" id="I3776" >AcrM 13206 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP650'>Ath9*</a> </li>
<li  :class="{'activeClass': idClicked == I3777 }" id="I3777" >AcrM 13248 Epicharinos </li>
<li  :class="{'activeClass': idClicked == I3778 }" id="I3778" >AcrM 13250 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3239'>Potter Hero</a> </li>
<li  :class="{'activeClass': idClicked == I3779 }" id="I3779" >AcrM 13264 Trojan Horse </li>
<li  :class="{'activeClass': idClicked == I3780 }" id="I3780" >AcrM 13302 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2352'>Kekrops</a> </li>
<li  :class="{'activeClass': idClicked == I3781 }" id="I3781" >AcrM 13371 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP39'>Aglauros</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1874'>Helios</a> Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM188">188</a> </li>
<li  :class="{'activeClass': idClicked == I3782 }" id="I3782" >AcrM 13530+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP150'>Amazonomachia</a> </li>
<li  :class="{'activeClass': idClicked == I3783 }" id="I3783" >AcrM 13601 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP520'>Art28*</a> </li>
<li  :class="{'activeClass': idClicked == I3784 }" id="I3784" >AcrM 15243 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP958'>Bulls</a></li>
</ul>
<h3  :class="{'activeClass': idClicked == I3785 }" id="I3785" >NATIONAL MUSEUM INDEX</h3>
<ul>
<li  :class="{'activeClass': idClicked == I3786 }" id="I3786" >NM 43 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1965'>Hrk36*</a></li>
<li  :class="{'activeClass': idClicked == I3787 }" id="I3787" >NM 45 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP331'>Apo16**</a></li>
<li  :class="{'activeClass': idClicked == I3788 }" id="I3788" >NM 46 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2845'>Omphaloi</a></li>
<li  :class="{'activeClass': idClicked == I3789 }" id="I3789" >NM 47 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP335'>Apo20*</a></li>
<li  :class="{'activeClass': idClicked == I3790 }" id="I3790" >NM 54 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2036'>Hrm34*</a></li>
<li  :class="{'activeClass': idClicked == I3791 }" id="I3791" >NM 96 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2062'>Her11*</a></li>
<li  :class="{'activeClass': idClicked == I3792 }" id="I3792" >NM 127 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP839'>Ath123*</a></li>
<li  :class="{'activeClass': idClicked == I3793 }" id="I3793" >NM 128 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP797'>Ath93*</a></li>
<li  :class="{'activeClass': idClicked == I3794 }" id="I3794" >NM 129 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP798'>Ath94*</a></li>
<li  :class="{'activeClass': idClicked == I3795 }" id="I3795" >NM 177 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM150">150</a></li>
<li  :class="{'activeClass': idClicked == I3796 }" id="I3796" >NM 192 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1511'>Dipylon Graves</a></li>
<li  :class="{'activeClass': idClicked == I3797 }" id="I3797" >NM 227 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP256'>Aph13*</a></li>
<li  :class="{'activeClass': idClicked == I3798 }" id="I3798" >NM 233 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2671'>Mnemosyne</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2728'>Muses</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a></li>
<li  :class="{'activeClass': idClicked == I3799 }" id="I3799" >NM 234 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP789'>Ath91*</a></li>
<li  :class="{'activeClass': idClicked == I3800 }" id="I3800" >NM 245 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1428'>Dio9***</a></li>
<li  :class="{'activeClass': idClicked == I3801 }" id="I3801" >NM 249 Hadrian</li>
<li  :class="{'activeClass': idClicked == I3802 }" id="I3802" >NM 307 (NM inv.<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3598'>Theseion</a>) <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1516'>Domitia Longina</a></li>
<li  :class="{'activeClass': idClicked == I3803 }" id="I3803" >NM ex-311 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2240'>Iliad</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2818'>Odyssey</a></li>
<li  :class="{'activeClass': idClicked == I3804 }" id="I3804" >NM ex-312 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2240'>Iliad</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2818'>Odyssey</a></li>
<li  :class="{'activeClass': idClicked == I3805 }" id="I3805" >NM 327 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1348'>Demosthenes</a></li>
<li  :class="{'activeClass': idClicked == I3806 }" id="I3806" >NM 350 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2553'>Lucius Verus</a></li>
<li  :class="{'activeClass': idClicked == I3807 }" id="I3807" >NM 366 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP118'>Alexander the Great</a></li>
<li  :class="{'activeClass': idClicked == I3808 }" id="I3808" >NM 368 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2652'>Metrodoros</a></li>
<li  :class="{'activeClass': idClicked == I3809 }" id="I3809" >NM 387 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1981'>Hrk46*</a></li>
<li  :class="{'activeClass': idClicked == I3810 }" id="I3810" >NM 514 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2596'>Marcus Aurelius</a></li>
<li  :class="{'activeClass': idClicked == I3811 }" id="I3811" >NM 538 Xenophon and Arrian</li>
<li  :class="{'activeClass': idClicked == I3812 }" id="I3812" >NM 540 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1608'>Epicurus</a></li>
<li  :class="{'activeClass': idClicked == I3813 }" id="I3813" >NM 626 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2192'>Homer</a></li>
<li  :class="{'activeClass': idClicked == I3814 }" id="I3814" >NM 632 Hadrian</li>
<li  :class="{'activeClass': idClicked == I3815 }" id="I3815" >NM 640 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM218">218</a></li>
<li  :class="{'activeClass': idClicked == I3816 }" id="I3816" >NM 683 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2904'>Pan15*</a></li>
<li  :class="{'activeClass': idClicked == I3817 }" id="I3817" >NM 693 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a></li>
<li  :class="{'activeClass': idClicked == I3818 }" id="I3818" >NM 694 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a></li>
<li  :class="{'activeClass': idClicked == I3819 }" id="I3819" >NM 695 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a></li>
<li  :class="{'activeClass': idClicked == I3820 }" id="I3820" >NM 696 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a></li>
<li  :class="{'activeClass': idClicked == I3821 }" id="I3821" >NM 701 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP605'>Ask37*</a></li>
<li  :class="{'activeClass': idClicked == I3822 }" id="I3822" >NM 702 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP605'>Ask37*</a></li>
<li  :class="{'activeClass': idClicked == I3823 }" id="I3823" >NM 754 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem11*</li>
<li  :class="{'activeClass': idClicked == I3824 }" id="I3824" >NM 804 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1511'>Dipylon Graves</a></li>
<li  :class="{'activeClass': idClicked == I3825 }" id="I3825" >NM 831 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3109'>Phrasikleia</a></li>
<li  :class="{'activeClass': idClicked == I3826 }" id="I3826" >NM 984 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3660'>Thucydides</a></li>
<li  :class="{'activeClass': idClicked == I3827 }" id="I3827" >NM 1236 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1012'>Charixenos</a></li>
<li  :class="{'activeClass': idClicked == I3828 }" id="I3828" >NM 1329 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2812'>Nym12*</a></li>
<li  :class="{'activeClass': idClicked == I3829 }" id="I3829" >NM 1332 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP601'>Ask35*</a></li>
<li  :class="{'activeClass': idClicked == I3830 }" id="I3830" >NM 1338 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP580'>Ask19**</a></li>
<li  :class="{'activeClass': idClicked == I3831 }" id="I3831" >NM 1341+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP581'>Ask20*</a></li>
<li  :class="{'activeClass': idClicked == I3832 }" id="I3832" >NM 1343 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP28'>Agathe Tyche</a></li>
<li  :class="{'activeClass': idClicked == I3833 }" id="I3833" >NM 1349 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2044'>Hrm39*</a></li>
<li  :class="{'activeClass': idClicked == I3834 }" id="I3834" >NM 1351 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP582'>Ask21*</a></li>
<li  :class="{'activeClass': idClicked == I3835 }" id="I3835" >NM 1352 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP583'>Ask22*</a></li>
<li  :class="{'activeClass': idClicked == I3836 }" id="I3836" >NM 1353 Sphyrus (?)</li>
<li  :class="{'activeClass': idClicked == I3837 }" id="I3837" >NM 1354 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP584'>Ask23*</a></li>
<li  :class="{'activeClass': idClicked == I3838 }" id="I3838" >NM 1359 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP358'>Apo39*</a></li>
<li  :class="{'activeClass': idClicked == I3839 }" id="I3839" >NM 1364 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP585'>Ask24*</a></li>
<li  :class="{'activeClass': idClicked == I3840 }" id="I3840" >NM 1369 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP764'>Ath69*</a></li>
<li  :class="{'activeClass': idClicked == I3841 }" id="I3841" >NM 1377 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP586'>Ask25*</a></li>
<li  :class="{'activeClass': idClicked == I3842 }" id="I3842" >NM 1389 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP415'>Apo85*</a></li>
<li  :class="{'activeClass': idClicked == I3843 }" id="I3843" >NM 1394 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM527">527</a></li>
<li  :class="{'activeClass': idClicked == I3844 }" id="I3844" >NM 1396 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP160'>Amphiaraos</a></li>
<li  :class="{'activeClass': idClicked == I3845 }" id="I3845" >NM 1398 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP733'>Ath49*</a></li>
<li  :class="{'activeClass': idClicked == I3846 }" id="I3846" >NM 1401 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2118'>Hro24*</a></li>
<li  :class="{'activeClass': idClicked == I3847 }" id="I3847" >NM 1443 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2898'>Pan10*</a></li>
<li  :class="{'activeClass': idClicked == I3848 }" id="I3848" >NM 1451/2 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1677'>Eros and Aphrodite</a></li>
<li  :class="{'activeClass': idClicked == I3849 }" id="I3849" >NM 1452 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1677'>Eros and Aphrodite</a></li>
<li  :class="{'activeClass': idClicked == I3850 }" id="I3850" >NM 1454 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1966'>Hrk37*</a></li>
<li  :class="{'activeClass': idClicked == I3851 }" id="I3851" >NM 1463 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1436'>Dio14*</a></li>
<li  :class="{'activeClass': idClicked == I3852 }" id="I3852" >NM 1464 Part II no 215</li>
<li  :class="{'activeClass': idClicked == I3853 }" id="I3853" >NM 1467 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP835'>Ath121*</a></li>
<li  :class="{'activeClass': idClicked == I3854 }" id="I3854" >NM 1471 Spartokos</li>
<li  :class="{'activeClass': idClicked == I3855 }" id="I3855" >NM 1473 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP930'>Boule</a></li>
<li  :class="{'activeClass': idClicked == I3856 }" id="I3856" >NM 1474 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP834'>Ath120*</a></li>
<li  :class="{'activeClass': idClicked == I3857 }" id="I3857" >NM 1476 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP849'>Ath132*</a></li>
<li  :class="{'activeClass': idClicked == I3858 }" id="I3858" >NM 1481 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP845'>Ath129*</a></li>
<li  :class="{'activeClass': idClicked == I3859 }" id="I3859" >NM 1482 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP830'>Ath117*</a></li>
<li  :class="{'activeClass': idClicked == I3860 }" id="I3860" >NM 1487 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3041'>Persian God</a> or Ruler</li>
<li  :class="{'activeClass': idClicked == I3861 }" id="I3861" >NM 1488 Antipatros</li>
<li  :class="{'activeClass': idClicked == I3862 }" id="I3862" >NM 1495 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP274'>Aph29*</a></li>
<li  :class="{'activeClass': idClicked == I3863 }" id="I3863" >NM 1510 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2127'>Hro32*</a></li>
<li  :class="{'activeClass': idClicked == I3864 }" id="I3864" >NM 1512 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2128'>Hro33*</a></li>
<li  :class="{'activeClass': idClicked == I3865 }" id="I3865" >NM 1517 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2129'>Hro34*</a></li>
<li  :class="{'activeClass': idClicked == I3866 }" id="I3866" >NM 1552 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2713'>Met20*</a></li>
<li  :class="{'activeClass': idClicked == I3867 }" id="I3867" >NM 1612 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP334'>Apo19*</a></li>
<li  :class="{'activeClass': idClicked == I3868 }" id="I3868" >NM 1641 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM218">218</a></li>
<li  :class="{'activeClass': idClicked == I3869 }" id="I3869" >NM 1642 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM218">218</a></li>
<li  :class="{'activeClass': idClicked == I3870 }" id="I3870" >NM 1656 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1425'>Dio8*</a></li>
<li  :class="{'activeClass': idClicked == I3871 }" id="I3871" >NM 1664 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3596'>Theseus</a></li>
<li  :class="{'activeClass': idClicked == I3872 }" id="I3872" >NM 1673 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a></li>
<li  :class="{'activeClass': idClicked == I3873 }" id="I3873" >NM 1682/3 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM217">217</a></li>
<li  :class="{'activeClass': idClicked == I3874 }" id="I3874" >NM 1688 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM216">216</a></li>
<li  :class="{'activeClass': idClicked == I3875 }" id="I3875" >NM 1693 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a></li>
<li  :class="{'activeClass': idClicked == I3876 }" id="I3876" >NM 1727 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1788'>Habryllis</a></li>
<li  :class="{'activeClass': idClicked == I3877 }" id="I3877" >NM 1730 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP341'>Apo24*</a></li>
<li  :class="{'activeClass': idClicked == I3878 }" id="I3878" >NM 1731 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3734'>Twelve Gods</a></li>
<li  :class="{'activeClass': idClicked == I3879 }" id="I3879" >NM 1732 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM334">334</a></li>
<li  :class="{'activeClass': idClicked == I3880 }" id="I3880" >NM 1733 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP956'>Bryaxis Base</a></li>
<li  :class="{'activeClass': idClicked == I3881 }" id="I3881" >NM 1747 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3494'>Taurobolion</a></li>
<li  :class="{'activeClass': idClicked == I3882 }" id="I3882" >NM 1744 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3494'>Taurobolion</a></li>
<li  :class="{'activeClass': idClicked == I3883 }" id="I3883" >NM 1762 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP255'>Aph12*</a></li>
<li  :class="{'activeClass': idClicked == I3884 }" id="I3884" >NM 1775 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3504'>Telesphoros</a></li>
<li  :class="{'activeClass': idClicked == I3885 }" id="I3885" >NM 1778 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1969'>Hrk38*</a></li>
<li  :class="{'activeClass': idClicked == I3886 }" id="I3886" >NM 1795 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP266'>Aph22**</a></li>
<li  :class="{'activeClass': idClicked == I3887 }" id="I3887" >NM 1801 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a></li>
<li  :class="{'activeClass': idClicked == I3888 }" id="I3888" >NM 1966 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP416'>Apo86*</a></li>
<li	 :class="{'activeClass': idClicked == I3889 }" id="I3889" >NM 2006	Julian the 	Apostate</li>
<li  :class="{'activeClass': idClicked == I3890 }" id="I3890" >NM 2144 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3169'>Polydeukion</a></li>
<li  :class="{'activeClass': idClicked == I3891 }" id="I3891" >NM 2147 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP266'>Aph22**</a></li>
<li  :class="{'activeClass': idClicked == I3892 }" id="I3892" >NM 2266 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP727'>Ath45*</a></li>
<li  :class="{'activeClass': idClicked == I3893 }" id="I3893" >NM 2348 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM336">336</a></li>
<li  :class="{'activeClass': idClicked == I3894 }" id="I3894" >NM 2351 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2801'>Nym3*</a></li>
<li  :class="{'activeClass': idClicked == I3895 }" id="I3895" >NM 2388 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3846'>Zeu47*</a></li>
<li  :class="{'activeClass': idClicked == I3896 }" id="I3896" >NM 2407 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP833'>Ath119*</a></li>
<li  :class="{'activeClass': idClicked == I3897 }" id="I3897" >NM 2413 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2130'>Hro35*</a></li>
<li  :class="{'activeClass': idClicked == I3898 }" id="I3898" >NM 2422 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP293'>Aph41*</a></li>
<li  :class="{'activeClass': idClicked == I3899 }" id="I3899" >NM 2439 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2802'>Nym4*</a></li>
<li  :class="{'activeClass': idClicked == I3900 }" id="I3900" >NM 2450 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP587'>Ask26*</a></li>
<li  :class="{'activeClass': idClicked == I3901 }" id="I3901" >NM 2477 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP558'>Ask2*</a></li>
<li  :class="{'activeClass': idClicked == I3902 }" id="I3902" >NM 2490 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP558'>Ask2*</a></li>
<li  :class="{'activeClass': idClicked == I3903 }" id="I3903" >NM 2491 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP558'>Ask2*</a></li>
<li  :class="{'activeClass': idClicked == I3904 }" id="I3904" >NM 2565 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3406'>Snake</a></li>
<li  :class="{'activeClass': idClicked == I3905 }" id="I3905" >NM 2585 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP261'>Aph18*</a></li>
<li  :class="{'activeClass': idClicked == I3906 }" id="I3906" >NM 2599 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM476">476B</a></li>
<li  :class="{'activeClass': idClicked == I3907 }" id="I3907" >NM 2650 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1692'>Eubouleus</a></li>
<li  :class="{'activeClass': idClicked == I3908 }" id="I3908" >NM 2668 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP842'>Ath126*</a></li>
<li  :class="{'activeClass': idClicked == I3909 }" id="I3909" >NM 2744 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem10*</li>
<li  :class="{'activeClass': idClicked == I3910 }" id="I3910" >NM 2811 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM236">236</a></li>
<li  :class="{'activeClass': idClicked == I3911 }" id="I3911" >NM 2815 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3596'>Theseus</a></li>
<li  :class="{'activeClass': idClicked == I3912 }" id="I3912" >NM 2948 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP553'>Arybbas</a></li>
<li  :class="{'activeClass': idClicked == I3913 }" id="I3913" >NM 2958 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1743'>Eutaxia</a></li>
<li  :class="{'activeClass': idClicked == I3914 }" id="I3914" >NM 2973 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM112">112</a></li>
<li  :class="{'activeClass': idClicked == I3915 }" id="I3915" >NM 3004 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP714'>Ath35*</a></li>
<li  :class="{'activeClass': idClicked == I3916 }" id="I3916" >NM 3131 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1468'>Dio36*</a></li>
<li  :class="{'activeClass': idClicked == I3917 }" id="I3917" >NM 3157 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM157">157</a></li>
<li  :class="{'activeClass': idClicked == I3918 }" id="I3918" >NM 3158 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM158">158</a></li>
<li  :class="{'activeClass': idClicked == I3919 }" id="I3919" >NM 3215+ <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a></li>
<li  :class="{'activeClass': idClicked == I3920 }" id="I3920" >NM 3288 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3846'>Zeu47*</a></li>
<li  :class="{'activeClass': idClicked == I3921 }" id="I3921" >NM 3291 Aristotle</li>
<li  :class="{'activeClass': idClicked == I3922 }" id="I3922" >NM 3292 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2630'>Menander</a></li>
<li  :class="{'activeClass': idClicked == I3923 }" id="I3923" >NM 3296 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2553'>Lucius Verus</a></li>
<li  :class="{'activeClass': idClicked == I3924 }" id="I3924" >NM 3329 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3868'>Zeu64*</a></li>
<li  :class="{'activeClass': idClicked == I3925 }" id="I3925" >NM 3373 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1191'>Comic Mask</a></li>
<li  :class="{'activeClass': idClicked == I3926 }" id="I3926" >NM 3397+ Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM335">335</a></li>
<li  :class="{'activeClass': idClicked == I3927 }" id="I3927" >NM 3491 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1970'>Hrk39*</a></li>
<li  :class="{'activeClass': idClicked == I3928 }" id="I3928" >NM 3496-8 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1432'>Dio13*</a></li>
<li  :class="{'activeClass': idClicked == I3929 }" id="I3929" >NM 3526 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2579'>Lysimachides</a></li>
<li  :class="{'activeClass': idClicked == I3930 }" id="I3930" >NM 3527 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2132'>Hro37*</a></li>
<li  :class="{'activeClass': idClicked == I3931 }" id="I3931" >NM 3529 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2800'>Nym2*</a></li>
<li  :class="{'activeClass': idClicked == I3932 }" id="I3932" >NM 3531 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2141'>Hro45*</a></li>
<li  :class="{'activeClass': idClicked == I3933 }" id="I3933" >NM 3538 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2702'>Met11*</a></li>
<li  :class="{'activeClass': idClicked == I3934 }" id="I3934" >NM 3556 Ariarathes</li>
<li  :class="{'activeClass': idClicked == I3935 }" id="I3935" >NM 3563 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP231'>Antoninus Pius</a></li>
<li  :class="{'activeClass': idClicked == I3936 }" id="I3936" >NM 3566 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP527'>Art33*</a></li>
<li  :class="{'activeClass': idClicked == I3937 }" id="I3937" >NM 3567 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP502'>Art11*</a></li>
<li  :class="{'activeClass': idClicked == I3938 }" id="I3938" >NM ex-3573 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP385'>Apo60*</a></li>
<li  :class="{'activeClass': idClicked == I3939 }" id="I3939" >NM 3606 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP968'>Caesar</a>, Gaius</li>
<li  :class="{'activeClass': idClicked == I3940 }" id="I3940" >NM 3665 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP968'>Caesar</a>,Gaius</li>
<li  :class="{'activeClass': idClicked == I3941 }" id="I3941" >NM 3708 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM451">451</a></li>
<li  :class="{'activeClass': idClicked == I3942 }" id="I3942" >NM 3714 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP503'>Art12*</a></li>
<li  :class="{'activeClass': idClicked == I3943 }" id="I3943" >NM 3715 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2133'>Hro38*</a></li>
<li  :class="{'activeClass': idClicked == I3944 }" id="I3944" >NM 3718 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP724'>Ath42*</a></li>
<li  :class="{'activeClass': idClicked == I3945 }" id="I3945" >NM 3726 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2710'>Met17*</a></li>
<li  :class="{'activeClass': idClicked == I3946 }" id="I3946" >NM 3729 Hadrian</li>
<li  :class="{'activeClass': idClicked == I3947 }" id="I3947" >NM 3735 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3122'>Plato</a></li>
<li  :class="{'activeClass': idClicked == I3948 }" id="I3948" >NM 3740 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2553'>Lucius Verus</a></li>
<li  :class="{'activeClass': idClicked == I3949 }" id="I3949" >NM 3748 Ploughman</li>
<li  :class="{'activeClass': idClicked == I3950 }" id="I3950" >NM 3758 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP886'>Augustus</a></li>
<li  :class="{'activeClass': idClicked == I3951 }" id="I3951" >NM 3772 Aristotle</li>
<li  :class="{'activeClass': idClicked == I3952 }" id="I3952" >NM 3775 Orpheus.</li>
<li  :class="{'activeClass': idClicked == I3953 }" id="I3953" >NM 3936 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM214">214</a></li>
<li  :class="{'activeClass': idClicked == I3954 }" id="I3954" >NM 3937 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2135'>Hro40*</a></li>
<li  :class="{'activeClass': idClicked == I3955 }" id="I3955" >NM 3942 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1971'>Hrk40*</a></li>
<li  :class="{'activeClass': idClicked == I3956 }" id="I3956" >NM 3943 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3652'>Thrasyllos</a> Mon.</li>
<li  :class="{'activeClass': idClicked == I3957 }" id="I3957" >NM 3949 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2746'>Nemesis</a></li>
<li  :class="{'activeClass': idClicked == I3958 }" id="I3958" >NM 3951 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3348'>Sarapis</a></li>
<li  :class="{'activeClass': idClicked == I3959 }" id="I3959" >NM 3989 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1216'>Demeter</a></li>
<li  :class="{'activeClass': idClicked == I3960 }" id="I3960" >NM 4464 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM477">477</a></li>
<li  :class="{'activeClass': idClicked == I3961 }" id="I3961" >NM 4478 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3213'>Post-Herulian Wall</a></li>
<li  :class="{'activeClass': idClicked == I3962 }" id="I3962" >NM 4485 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2738'>Myrrhine</a></li>
<li  :class="{'activeClass': idClicked == I3963 }" id="I3963" >NM 4582 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2668'>Mithras</a></li>
<li  :class="{'activeClass': idClicked == I3964 }" id="I3964" >NM 4762 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP254'>Aph11*</a></li>
<li  :class="{'activeClass': idClicked == I3965 }" id="I3965" >NM 4798 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM335">335</a></li>
<li  :class="{'activeClass': idClicked == I3966 }" id="I3966" >NM 5656 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP958'>Bulls</a></li>
<li  :class="{'activeClass': idClicked == I3967 }" id="I3967" >NM 6503 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM111">111</a></li>
<li  :class="{'activeClass': idClicked == I3968 }" id="I3968" >NM 6530/1 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM105">105</a></li>
<li  :class="{'activeClass': idClicked == I3969 }" id="I3969" >NM 6532 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM110">110</a></li>
<li  :class="{'activeClass': idClicked == I3970 }" id="I3970" >NM 6591 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2207'>Horus</a></li>
<li  :class="{'activeClass': idClicked == I3971 }" id="I3971" >NM 6675 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM106">106</a></li>
<li  :class="{'activeClass': idClicked == I3972 }" id="I3972" >NM 6698 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM107">107</a></li>
<li  :class="{'activeClass': idClicked == I3973 }" id="I3973" >NM 6902+ Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM93">93</a></li>
<li  :class="{'activeClass': idClicked == I3974 }" id="I3974" >NM 7038 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP644'>Ath6*</a></li>
<li  :class="{'activeClass': idClicked == I3975 }" id="I3975" >NM 7146 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2891'>Pan3*</a></li>
<li  :class="{'activeClass': idClicked == I3976 }" id="I3976" >NM 7406 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP309'>Aph55*</a></li>
<li  :class="{'activeClass': idClicked == I3977 }" id="I3977" >NM 8052 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM428">428</a></li>
<li  :class="{'activeClass': idClicked == I3978 }" id="I3978" >NM 8054 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM428">428</a></li>
<li  :class="{'activeClass': idClicked == I3979 }" id="I3979" >NM 13050 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1777'>Gorgon</a></li>
<li  :class="{'activeClass': idClicked == I3980 }" id="I3980" >NM 16771/5 Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM516">516</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM519">519</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM522">522</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM524">524</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM525">525</a></li>
<li  :class="{'activeClass': idClicked == I3981 }" id="I3981" >NM 16778 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM517">517</a> </li>
<li  :class="{'activeClass': idClicked == I3982 }" id="I3982" >NM 16780/81 Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM518">518</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM526">526</a></li>
<li  :class="{'activeClass': idClicked == I3983 }" id="I3983" >NM 16786/9 Part II nos. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM515">515</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM520">520</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM521">521</a>, <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM523">523</a> </li>
<li  :class="{'activeClass': idClicked == I3984 }" id="I3984" >NM 16792+ Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM123">123</a></li>
</ul>


    </div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    