import { onMounted, onUnmounted } from 'vue';
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core'; 
import { useRoute } from 'vue-router'

export function usePartAndTerm (){ 
  let idClicked = ref(false); 
  const loadPartAndTerm = () => {

      const route = useRoute();
      let id = false; 
      let mounted = ref(false); 
      let tfscroll = true

      const resizeHandler = ()=>{
        if (window.innerWidth < 960) { 
          tfscroll = false
        } 
        else{
          tfscroll = true
        }
      }

      watchEffect(()=>{
        id = route.params.term;
        idClicked.value = route.params.term; 
        let el = document.getElementById(id); 
        if (mounted && el) {
          el.scrollIntoView(tfscroll, {behavior: "smooth"}); 
          id = false;
        }
      }) 

      onMounted(() => {
        mounted = true;
        let el = document.getElementById(id); 
        if (id && el) { 
          el.scrollIntoView(tfscroll, {behavior: "smooth"}); 
          id = false;
        }
        resizeHandler()
        window.addEventListener("resize", resizeHandler);
      })  
  
      onUnmounted(() => {
        window.removeEventListener("resize", resizeHandler);
      })

  } 

  return {loadPartAndTerm, idClicked}

}
 
 
 