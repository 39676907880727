<template>

    <div class="part-text">
<h4 :class="{'activeClass': idClicked == I4454 }" id="I4454">FIRST EPHORIA EXCAVATIONS INDEX</h4>
<p>A note on the inventories used in recording finds from the excavations of the First Ephoria, a reckless attempt to untangle the intricacies of the First Ephoria inventories with the hope of clearing up at least some of the confusion. <br>Since the excavators had no master grid plan on which to register their sites and were provided with no guidelines for recording finds, each one had to set up her or his own designations and records, sometimes with incomplete knowledge of earlier excavations on the same site. All inventory numbers cited here have been published. <br>The First Ephoria inventory numbers have two prefixes. <br>1st Ephor. prefixes indicating findspots. <br>The Greek letters used in these prefixes are frequently transliterated in foreign publications, as publishers more and more insist on printing transliterations rather than the original Greek. Incorrect transliteration of the Greek letters used in these prefixes is causing confusion and misunderstandings, especially because the Greek rho looks like the Latin P. In the following list, the prefixes are given in transliteration with the original Greek in parentheses. </p>
<ul>
<li :class="{'activeClass': idClicked == I4455 }" id="I4455">ΒΑ (beta alpha) inventory numbers: objects kept in the old storeroom of the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1798'>Library of Hadrian</a> The letters stand for βιβλιοθήκη Αδριανού. The old storeroom is in the southern part of the area, just off Dexippou St. (A new storeroom, built in the 1980s at the north side, houses sculpture, inscriptions and other finds from various sites in Athens; I do not know how they are inventoried.)</li> 
<li :class="{'activeClass': idClicked == I4456 }" id="I4456">B.Per. (B. Περ.) inventory numbers. This prefix probably, but not certainly, refers to the northern section of the <a v-on:click.prevent="replaceLink"  href='JBMS_G.html'>Akropolis South Slope</a> zone. </li> 
<li :class="{'activeClass': idClicked == I4457 }" id="I4457">NA, NAG, NAM, NK (nu alpha, nu alpha gamma, nu alpha mu, nu kappa): objects found on the <a v-on:click.prevent="replaceLink"  href='JBMS_G.html'>Akropolis South Slope</a>. </li> 
<li :class="{'activeClass': idClicked == I4458 }" id="I4458"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> numbers for finds from the sanctuary of <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> on the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2242'>Ilissos</a>. </li> 
<li :class="{'activeClass': idClicked == I4459 }" id="I4459">Pi (Π) inventory numbers for finds from the Plaka area mostly stored at 20 Thrasyllou St.; see Deltion 46 (1991) B1 Chr. 20</li> 
<li :class="{'activeClass': idClicked == I4460 }" id="I4460">Pi Lambda (ΠΛ) inventory numbers for finds from the Plaka stored in the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1798'>Library of Hadrian</a></li> 
<li :class="{'activeClass': idClicked == I4461 }" id="I4461">Rho Alpha (PA) inventory numbers: a special inventory for stone objects in the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a> area which has served as a collecting point for stone finds from all over Athens since 1835. </li> 
<li :class="{'activeClass': idClicked == I4462 }" id="I4462">Stray Finds, a separate inventory which had reached no. 372 before 1966. ( Some of these stray finds were old finds encountered by G. Dontas, Director of the <a v-on:click.prevent="replaceLink"  href='NPP.html#NP115'>Akropolis</a>, as he was cleaning out the <a v-on:click.prevent="replaceLink"  href='NNM.html#NNM19'>Beulé Gate</a> north tower)</li> 
<li :class="{'activeClass': idClicked == I4463 }" id="I4463"><b>First Ephoria inventory numbers</b></li> 
<li :class="{'activeClass': idClicked == I4464 }" id="I4464">BA 3 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2492'>Leimon</a></li> 
<li :class="{'activeClass': idClicked == I4465 }" id="I4465">BA 395 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3767'>Victoria Romana</a></li> 
<li :class="{'activeClass': idClicked == I4466 }" id="I4466">BA 594 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3301'>Pythodoros</a></li> 
<li :class="{'activeClass': idClicked == I4467 }" id="I4467">BA 1065 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3767'>Victoria Romana</a></li> 
<li :class="{'activeClass': idClicked == I4468 }" id="I4468">B.Per. 261 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3899'>Zeu89*</a></li> 
<li :class="{'activeClass': idClicked == I4470 }" id="I4470">ΝΑ 89-1957 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3867'>Zeu63*</a></li> 
<li :class="{'activeClass': idClicked == I4471 }" id="I4471">NAG 90-1957 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == I4472 }" id="I4472">NAM 11-1955 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM168">168</a></li> 
<li :class="{'activeClass': idClicked == I4473 }" id="I4473">NAM 12-1955 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM167">167</a></li> 
<li :class="{'activeClass': idClicked == I4474 }" id="I4474">NAM 40 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == I4475 }" id="I4475">NAM 90-1955 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM166">166</a></li> 
<li :class="{'activeClass': idClicked == I4476 }" id="I4476">NK 31 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM161">161</a> (sundial)</li> 
<li :class="{'activeClass': idClicked == I4477 }" id="I4477">NK 61 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3469'>Stoics</a></li> 
<li :class="{'activeClass': idClicked == I4478 }" id="I4478">NK 105 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2214'>Hygieia</a></li> 
<li :class="{'activeClass': idClicked == I4479 }" id="I4479">NK 201 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1471'>Dio39*</a></li> 
<li :class="{'activeClass': idClicked == I4480 }" id="I4480"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 10 A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4481 }" id="I4481"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 17 A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4482 }" id="I4482"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 18 A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == I4483 }" id="I4483"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 3A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2878'>Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4484 }" id="I4484"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 42 B <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4485 }" id="I4485"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 56 B <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4486 }" id="I4486"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 58 B <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a></li> 
<li :class="{'activeClass': idClicked == I4487 }" id="I4487"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 62A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2962'>Pankrates and Palaimon</a>, leg relief</li> 
<li :class="{'activeClass': idClicked == I4488 }" id="I4488"><a v-on:click.prevent="replaceLink"  href='NPP.html#NP2961'>Pankrates</a> 66A <a v-on:click.prevent="replaceLink"  href='NPP.html#NP118'>Alexander the Great</a></li> 
<li :class="{'activeClass': idClicked == I4489 }" id="I4489">Pi (Plaka) 225 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == I4490 }" id="I4490">Pi Lambda (Plaka) 751 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP604'>Ask36**</a></li> 
<li :class="{'activeClass': idClicked == I4491 }" id="I4491"><b>Rho Alpha inventory numbers</b></li> 
<li :class="{'activeClass': idClicked == I4492 }" id="I4492">117 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2139'>Hro44*</a></li> 
<li :class="{'activeClass': idClicked == I4493 }" id="I4493">636 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2952'>Panhellenion</a></li> 
<li :class="{'activeClass': idClicked == I4494 }" id="I4494">637 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == I4495 }" id="I4495">643 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> and Attalos II of Pergamon</li> 
<li :class="{'activeClass': idClicked == I4496 }" id="I4496">745 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP65'>Agoranomion</a></li> 
<li :class="{'activeClass': idClicked == I4497 }" id="I4497">656 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3819'>Zeu25*</a></li> 
<li :class="{'activeClass': idClicked == I4498 }" id="I4498">765 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == I4499 }" id="I4499">1244 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2143'>Hro47*</a></li> 
<li :class="{'activeClass': idClicked == I4500 }" id="I4500">1322 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM247">247</a></li> 
<li :class="{'activeClass': idClicked == I4501 }" id="I4501">1965 no. 20 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == I4502 }" id="I4502"><b>Stray finds</b></li> 
<li :class="{'activeClass': idClicked == I4503 }" id="I4503">372 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1187'>Claudius Pulcher</a></li>
</ul>
<h4 :class="{'activeClass': idClicked == I4504 }" id="I4504">THIRD EPHORIA EXCAVATION INDEX</h4>
<ul>
<li :class="{'activeClass': idClicked == I4505 }" id="I4505">3rd Ephor. 13 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP231'>Antoninus Pius</a></li> 
<li :class="{'activeClass': idClicked == I4506 }" id="I4506">3rd Ephor. 490 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3122'>Plato</a>; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4507 }" id="I4507">3rd Ephor. 491 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3413'>Sokrates</a>; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4508 }" id="I4508">3rd Ephor. 492 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4509 }" id="I4509">3rd Ephor. 493 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1348'>Demosthenes</a>; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4510 }" id="I4510">3rd Ephor. 494 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP980'>Caracalla</a>; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4511 }" id="I4511">3rd Ephor. 495 Antinoos; Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM510B">510B</a></li> 
<li :class="{'activeClass': idClicked == I4512 }" id="I4512">3rd Ephor. 767 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2051'>Her2*</a></li> 
<li :class="{'activeClass': idClicked == I4513 }" id="I4513">3rd Ephor. 2191 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2562'>Lykophron</a> (I)</li> 
<li :class="{'activeClass': idClicked == I4514 }" id="I4514">3rd Ephor. 2290 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2562'>Lykophron</a> (III)</li> 
<li :class="{'activeClass': idClicked == I4515 }" id="I4515">3rd Ephor. Λ 7066 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem16*</li> 
<li :class="{'activeClass': idClicked == I4516 }" id="I4516">3rd Ephor. M 431 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM560">560</a></li> 
<li :class="{'activeClass': idClicked == I4517 }" id="I4517">3rd Ephor. M 743 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2003'>Hrm5*</a></li> 
<li :class="{'activeClass': idClicked == I4518 }" id="I4518">3rd Ephor. M 887 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1608'>Epicurus</a></li> 
<li :class="{'activeClass': idClicked == I4519 }" id="I4519">3rd Ephor. M 888 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1608'>Epicurus</a></li> 
<li :class="{'activeClass': idClicked == I4520 }" id="I4520">3rd Ephor. M 915 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2145'>Hro48*</a></li> 
<li :class="{'activeClass': idClicked == I4521 }" id="I4521">3rd Ephor. M 1069 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM561">561</a></li> 
<li :class="{'activeClass': idClicked == I4522 }" id="I4522">3rd Ephor. M 1111 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP102'>Akademia</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1853'>Hekademeia</a></li> 
<li :class="{'activeClass': idClicked == I4523 }" id="I4523">3rd Ephor. M 1129 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1930'>Hrk9*</a></li> 
<li :class="{'activeClass': idClicked == I4524 }" id="I4524">3rd Ephor. M 1213 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33**</li> 
<li :class="{'activeClass': idClicked == I4525 }" id="I4525">3rd Ephor. M 1539 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3896'>Zeu87*</a></li> 
<li :class="{'activeClass': idClicked == I4526 }" id="I4526">3rd Ephor. M 2347 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem8*</li> 
<li :class="{'activeClass': idClicked == I4527 }" id="I4527">3rd Ephor. M 3112 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP840'>Ath124*</a></li> 
<li :class="{'activeClass': idClicked == I4528 }" id="I4528">3rd Ephor. M 3118 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2004'>Hrm6*</a></li> 
<li :class="{'activeClass': idClicked == I4529 }" id="I4529">3rd Ephor. M 3258 Persian War Memorials</li> 
<li :class="{'activeClass': idClicked == I4530 }" id="I4530">3rd Ephor. M 4551 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem31*</li> 
<li :class="{'activeClass': idClicked == I4531 }" id="I4531">3rd Ephor. no number <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2134'>Hro39*</a></li> 
<li :class="{'activeClass': idClicked == I4532 }" id="I4532">3rd Ephor. no number <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2136'>Hro41*</a></li> 
</ul>
<h4 :class="{'activeClass': idClicked == I4533 }" id="I4533">METRO EXCAVATIONS (1992-1997) </h4> 
<ul>
<li :class="{'activeClass': idClicked == I4534 }" id="I4534">M 163 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3122'>Plato</a></li> 
<li :class="{'activeClass': idClicked == I4535 }" id="I4535">M 2516 Dioskouros</li> 
<li :class="{'activeClass': idClicked == I4536 }" id="I4536">M 4305 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a></li> 
</ul>

</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    