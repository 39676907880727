import { useRouter } from 'vue-router'   

export function useReplaceLinkClick (){

    const router = useRouter();  
    
    const replaceLink = (e) => {          
      if (!e.target.href.includes(window.location.hostname)) {   
        window.open(e.target.href, '_blank');
      }
      else{ 
        let urlParts = new URL(e.target.href)  
        let pathArray = urlParts.pathname.substring(urlParts.pathname.lastIndexOf('/') + 1)
        let part = pathArray.split('.')[0]
        let termArray = urlParts.hash.split('#')
        let term = termArray[1]  
        router.push({ name: part, force: true ,params: { term } }) 
      }
    }; 

    return {replaceLink}

}