<template > 

<div class="content">
<title>{{currentRoute}}</title> 
      <header style="position:sticky;" >
        <span id="scrollPoint" ></span>
        <Menubar :model="items"  >
          <template #start> 

            <router-link to="/"><img alt="judith-binder-logo" src="@/assets/judith-binder.png"  height="40" ></router-link>
            
          </template>  
        </Menubar>
      </header> 

      <section >
        <router-view/> 
      </section> 


        <footer class="footer" > 
            <div class="card"> 
      <div class="footer-flex">
          <div id="footer-left" >
      <span id="footer-auspices"> designed and hosted by : </span> 
      <a id="footer-dipylon" href="https://dipylon.org/gr/" target="_blank">
        <img  src="@/assets/dipylon.png" alt="Dipylon Society WebPage" height="65" >  
      </a>
      

      <p id="footer-social">
        <a href="https://www.facebook.com/DipylonAthens/" target="_blank" >
          <img src="https://mappingancientathens.org/wp-content/uploads/60X60_Facebook.png" alt="Dipylon Society Facebook" >
        </a>

        <a href="https://twitter.com/DipylonSociety" target="_blank" >
          <img src="https://mappingancientathens.org/wp-content/uploads/60X60_Twitter.png" alt="Dipylon Society Twitter" >
        </a>

        <a href="https://www.instagram.com/dipylon_athens/" target="_blank" >
          <img src="https://mappingancientathens.org/wp-content/uploads/60x60_instagram.png" alt="Dipylon Society Instagram" >
        </a>

        <a href="https://www.linkedin.com/company/74948280/admin/" target="_blank" >
          <img src="https://mappingancientathens.org/wp-content/uploads/60x60_linkedin.png" alt="Dipylon Society LinkedIn" >
        </a>
      </p>
          </div>
<Divider layout="vertical" />
     
          <div id="footer-right" >
          <p> Digital adaptation : <a href="https://topostext.org/who-we-are" target="_blank" > Brady Kiesling </a> </p>     
          <p> created by : Spiros Mousouris </p>     
          
    <p> &copy; 2023 <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/"><img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png" /></a></p>
          </div>
    
      </div>
            </div>

  </footer>  
</div>
 


  <!-- <footer class="footer" > 
        
    <div class="card"> 
      <div class="footer-flex">
        <div id="footer-left" >

          <a href="https://dipylon.org/gr/" target="_blank">
            <img  src="@/assets/dipylon.png" alt="Dipylon Society WebPage" height="60" >  
          </a>
          

          <p id="footer-social">
            <a href="https://www.facebook.com/DipylonAthens/" target="_blank" >
              <img src="https://mappingancientathens.org/wp-content/uploads/60X60_Facebook.png" alt="Dipylon Society Facebook" >
            </a>

            <a href="https://twitter.com/DipylonSociety" target="_blank" >
              <img src="https://mappingancientathens.org/wp-content/uploads/60X60_Twitter.png" alt="Dipylon Society Twitter" >
            </a>

            <a href="https://www.instagram.com/dipylon_athens/" target="_blank" >
              <img src="https://mappingancientathens.org/wp-content/uploads/60x60_instagram.png" alt="Dipylon Society Instagram" >
            </a>

            <a href="https://www.linkedin.com/company/74948280/admin/" target="_blank" >
              <img src="https://mappingancientathens.org/wp-content/uploads/60x60_linkedin.png" alt="Dipylon Society LinkedIn" >
            </a>
          </p>
 
        </div>
        <Divider layout="vertical" />
        <div id="footer-right" >
          <p> under the auspices of : <a href="https://dipylon.org/gr/" target="_blank" > Dipylon Society </a> </p> 
          <p> historical research : <a href="https://topostext.org/who-we-are" target="_blank" > Brady Keisling </a> </p>  
          <p>created by : <a href="https://www.linkedin.com/in/spirosmousouris/" target="_blank" > Spiros Mousouris </a> </p>    
        </div>

      </div>
    </div>
    
    <p> &copy; 2022 </p>

  </footer>  -->
</template>

<script>
import { ref, watch } from 'vue'  
import { useRoute, useRouter } from 'vue-router'  
import { computed } from '@vue/runtime-core'; 
export default {
  setup() {

    const items = ref([
      	{
					label:'Home',
          to:"/"
        },
                {
					label:'About',
          to:"/about"
        },
        {
					label:'SourceBook',
          to:"/sourcebook"
        }


    ]) 
  
    let title = ref('');  
    const route = useRoute();     
    const router = useRouter();   

    const currentRoute = computed(() => {
      let a = useRouter().currentRoute.value.name; 
      a = a + ' - Judith Binder'
      return a;
    })
  
  return { items, title, currentRoute }
  },
}
</script>


<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} 

html, body {
  height: 100%;
  margin: 0;
}

.content {
  min-height: calc(100vh - 97px); 
}

section{
  display: block;
  width: 100%;
  height: calc(100vh - 150px); 
}

.footer {
  height: 95px;
  width: 100%;
  display: block;
  background-color: lightgray;
  text-align: center;
  padding: 5px;
  font-size: 0.9rem;
}

.footer-flex{
  display: flex;
}

.footer .p-divider.p-divider-vertical{
  width: 2px;
  background-color: darkgray;
}

#footer-left{
  flex-basis: 50%;
  text-align: right; 
}

#footer-right{
  flex-basis: 50%;
  text-align: left;
  padding-top: 16px;
}

#footer-right p {
  margin: 2px 0;
  padding: 0;
}

#footer-right a {
  color: black;
}

#footer-right a:hover {
  color: darkgray;
}

#footer-social{
  margin:  -1px 31px 0 0;
}

#footer-social img{
  height: 15px;
  padding: 0 5px;
}

.p-splitter .p-splitter-gutter{
  background: darkgray!important;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: black!important;
}

.part-text{
  text-align: left!important;
  padding: 5px!important;
}

.part-text h1, h2, h3 {
  text-align: center!important;
}

@media only screen and (max-width: 960px){
  section{
    min-height: calc(100vh - 163px); 
  } 
  #footer-auspices{
    display: none;
  }
} 

@media only screen and (min-width: 960px) {
   .p-menubar {
    /* display: -webkit-box;  
    display: flex;  */
    /* display: block!important; */
    height: 55px;
  }

  .p-menubar-start{ 
    float: left; 
  }

  .p-menubar-root-list{ 
    float: right; 
  }

  .p-menubar-root-list ul{ 
        margin: 0 auto!important;
    float: right; 
  }

  .linkLike{
    color: black;
    cursor: pointer;
  }

  .linkLike:hover{
    color: darkgray;
    cursor: pointer;
    background-color: lightgray;
  }
}

@media only screen and (max-width: 550px){

  #footer-dipylon img{ 
      width: 142px;
    height: 43px;
}
 #footer-social {
    margin: -3px 13px 0 0;
}
#footer-social img {
    height: 14px;
    padding: 0 5px;
}

#footer-right p{
  font-size: 10px;
}

#footer-right{
  padding-top: 10px;
}

.footer{
  height: 80px;
}

.content {
    min-height: calc(100vh - 81px);
}

section {
    min-height: calc(100vh - 142px);
}

}  

/* @media only screen and (max-width: 600px){

  .footer{
    height: 260px;
  }

  .footer-flex{
    display: block;
  }  

  .footer .p-divider.p-divider-vertical{
    display: none;
  }

  #footer-left, #footer-right{ 
  text-align: center; 
}

}  */


</style>
