<template>

    <div class="part-text">
 

<h3 :class="{'activeClass': idClicked == I1209 }" id="I1209">INSCRIPTIONES GRAECAE INDEXES </h3>
<h4>IG I³</h4>
<ul>
<li :class="{'activeClass': idClicked == IGI31 }" id="IGI31"><inscr id='IG I³ 1'>IG I³ 1 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM85">85</a></li> 
<li :class="{'activeClass': idClicked == IGI34 }" id="IGI34"><inscr id='IG I³ 4'>IG I³ 4 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1868'>Hekatompedon</a>, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM63">63</a> </li> 
<li :class="{'activeClass': idClicked == IGI37 }" id="IGI37"><inscr id='IG I³ 7'>IG I³ 7 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/7' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2676'>Moirai</a></li> 
<li :class="{'activeClass': idClicked == IGI321 }" id="IGI321"><inscr id='IG I³ 21'>IG I³ 21 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/21' target='_blank'>PHI</a>] </inscr> - Athens and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2656'>Miletos</a></li> 
<li :class="{'activeClass': idClicked == IGI335 }" id="IGI335"><inscr id='IG I³ 35'>IG I³ 35 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/35' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/35' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP769'>Ath73*</a></li> 
<li :class="{'activeClass': idClicked == IGI336 }" id="IGI336"><inscr id='IG I³ 36'>IG I³ 36 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/36' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/36' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP769'>Ath73*</a></li> 
<li :class="{'activeClass': idClicked == IGI345 }" id="IGI345"><inscr id='IG I³ 45'>IG I³ 45 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/45' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM38">38</a></li> 
<li :class="{'activeClass': idClicked == IGI349 }" id="IGI349"><inscr id='IG I³ 49'>IG I³ 49 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/50' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3026'>Periklean Fountain</a> House</li> 
<li :class="{'activeClass': idClicked == IGI352 }" id="IGI352"><inscr id='IG I³ 52'>IG I³ 52 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/53' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/52' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2851'>Opisthodomos</a></li> 
<li :class="{'activeClass': idClicked == IGI364 }" id="IGI364"><inscr id='IG I³ 64'>IG I³ 64 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/65' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP780'>Ath84*</a></li> 
<li :class="{'activeClass': idClicked == IGI371 }" id="IGI371"><inscr id='IG I³ 71'>IG I³ 71 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/72' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/71' target='_blank'>AOI</a>] </inscr> - Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1034'>CM11*</a></li> 
<li :class="{'activeClass': idClicked == IGI378 }" id="IGI378"><inscr id='IG I³ 78'>IG I³ 78 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/79' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3015'>Pelargikon</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGI382 }" id="IGI382"><inscr id='IG I³ 82'>IG I³ 82 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/84' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/82' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1200'>Court of the Archon</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGI384 }" id="IGI384"><inscr id='IG I³ 84'>IG I³ 84 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/86' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/84' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1408'>Dionysion</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1833'>Halade Gate</a>, Isthmonikos, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a> (4) <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2739'>Mystai Gate</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2824'>Oikia Demosia</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3549'>Wal25*</a>, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM559">559</a> (3)</li> 
<li :class="{'activeClass': idClicked == IGI3104 }" id="IGI3104"><inscr id='IG I³ 104'>IG I³ 104 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/106' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/104' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1523'>Drakonʼs Laws</a></li> 
<li :class="{'activeClass': idClicked == IGI3105 }" id="IGI3105"><inscr id='IG I³ 105'>IG I³ 105 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/107' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/105' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP359'>Apo40*</a></li> 
<li :class="{'activeClass': idClicked == IGI3127 }" id="IGI3127"><inscr id='IG I³ 127'>IG I³ 127 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/130' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/127' target='_blank'>AOI</a>] </inscr> - Demosion </li> 
<li :class="{'activeClass': idClicked == IGI3137 }" id="IGI3137"><inscr id='IG I³ 137'>IG I³ 137 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/140' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP407'>Apo77*</a></li> 
<li :class="{'activeClass': idClicked == IGI3231 }" id="IGI3231"><inscr id='IG I³ 231'>IG I³ 231 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/239' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/231' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1567'>Eleusinion</a></li> 
<li :class="{'activeClass': idClicked == IGI3232 }" id="IGI3232"><inscr id='IG I³ 232'>IG I³ 232 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/240' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/232' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1567'>Eleusinion</a> </li> 
<li :class="{'activeClass': idClicked == IGI3234 }" id="IGI3234"><inscr id='IG I³ 234'>IG I³ 234 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/242' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/234' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2116'>Hro22*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3825'>Zeu31*</a> </li> 
<li :class="{'activeClass': idClicked == IGI3239 }" id="IGI3239"><inscr id='IG I³ 239'>IG I³ 239 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/248' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/239' target='_blank'>AOI</a>] </inscr>-241 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3460'>Stoa Basileios</a></li> 
<li :class="{'activeClass': idClicked == IGI3243 }" id="IGI3243"><inscr id='IG I³ 243'>IG I³ 243 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/252' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP642'>Ath4*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3593'>Therrikles</a></li> 
<li :class="{'activeClass': idClicked == IGI3244 }" id="IGI3244"><inscr id='IG I³ 244'>IG I³ 244 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/253' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/244' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP62'>Agora of the Skambonidai</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3396'>Skambonidai</a> </li> 
<li :class="{'activeClass': idClicked == IGI3257 }" id="IGI3257"><inscr id='IG I³ 257'>IG I³ 257 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/267' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1980'>Hrk45*</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM534B">534B</a></li> 
<li :class="{'activeClass': idClicked == IGI3259 }" id="IGI3259"><inscr id='IG I³ 259'>IG I³ 259 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/270' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/259' target='_blank'>AOI</a>] </inscr>-290 - Athenian T.L. </li> 
<li :class="{'activeClass': idClicked == IGI3326 }" id="IGI3326"><inscr id='IG I³ 326'>IG I³ 326 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/337' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3907'>Zeu96*</a></li> 
<li :class="{'activeClass': idClicked == IGI3344 }" id="IGI3344"><inscr id='IG I³ 344'>IG I³ 344 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/355' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM121">121</a></li> 
<li :class="{'activeClass': idClicked == IGI3369 }" id="IGI3369"><inscr id='IG I³ 369'>IG I³ 369 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/381' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/369' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP279'>Aph32*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP744'>Ath57*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> above <a v-on:click.prevent="replaceLink"  href='NPP.html#NP726'>Ath44*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2700'>Met10*</a></li> 
<li :class="{'activeClass': idClicked == IGI3375 }" id="IGI3375"><inscr id='IG I³ 375'>IG I³ 375 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/387' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/375' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP843'>Ath127*</a></li> 
<li :class="{'activeClass': idClicked == IGI3383 }" id="IGI3383"><inscr id='IG I³ 383'>IG I³ 383 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/395' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP271'>Aph26*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP377'>Apo53*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP765'>Ath70*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2242'>Ilissos</a> </li> 
<li :class="{'activeClass': idClicked == IGI3386 }" id="IGI3386"><inscr id='IG I³ 386'>IG I³ 386 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/398' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2164'>Hiera Syke</a> </li> 
<li :class="{'activeClass': idClicked == IGI3405 }" id="IGI3405"><inscr id='IG I³ 405'>IG I³ 405 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/419' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3204'>Poseidon Hippios</a> </li> 
<li :class="{'activeClass': idClicked == IGI3421 }" id="IGI3421"><inscr id='IG I³ 421'>IG I³ 421 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/435' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/421' target='_blank'>AOI</a>] </inscr>-430 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP130'>Alkibiades</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1567'>Eleusinion</a></li> 
<li :class="{'activeClass': idClicked == IGI3435 }" id="IGI3435"><inscr id='IG I³ 435'>IG I³ 435 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/449' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP818'>Ath113*</a> </li> 
<li :class="{'activeClass': idClicked == IGI3436 }" id="IGI3436"><inscr id='IG I³ 436'>IG I³ 436 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/450' target='_blank'>PHI</a>] </inscr>-451 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2987'>Parthenon</a></li> 
<li :class="{'activeClass': idClicked == IGI3436 }" id="IGI3436"><inscr id='IG I³ 436'>IG I³ 436 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/450' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM68">68</a> </li> 
<li :class="{'activeClass': idClicked == IGI3444 }" id="IGI3444"><inscr id='IG I³ 444'>IG I³ 444 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/458' target='_blank'>PHI</a>] </inscr>-447 - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM68">68</a> </li> 
<li :class="{'activeClass': idClicked == IGI3453 }" id="IGI3453"><inscr id='IG I³ 453'>IG I³ 453 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/467' target='_blank'>PHI</a>] </inscr>-460 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP791'>Ath92*</a> </li> 
<li :class="{'activeClass': idClicked == IGI3460 }" id="IGI3460"><inscr id='IG I³ 460'>IG I³ 460 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/474' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/460' target='_blank'>AOI</a>] </inscr> - Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1034'>CM11*</a> </li> 
<li :class="{'activeClass': idClicked == IGI3462 }" id="IGI3462"><inscr id='IG I³ 462'>IG I³ 462 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/476' target='_blank'>PHI</a>] </inscr>-466 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2825'>Oikia Hiera</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM8">8</a> </li> 
<li :class="{'activeClass': idClicked == IGI3467 }" id="IGI3467"><inscr id='IG I³ 467'>IG I³ 467 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/481' target='_blank'>PHI</a>] </inscr>-471 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2763'>Nike</a> </li> 
<li :class="{'activeClass': idClicked == IGI3472 }" id="IGI3472"><inscr id='IG I³ 472'>IG I³ 472 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/486' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a> (2) </li> 
<li :class="{'activeClass': idClicked == IGI3474 }" id="IGI3474"><inscr id='IG I³ 474'>IG I³ 474 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/488' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/474' target='_blank'>AOI</a>] </inscr>-479 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1628'>Erechtheion</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2352'>Kekrops</a></li> 
<li :class="{'activeClass': idClicked == IGI3474 }" id="IGI3474"><inscr id='IG I³ 474'>IG I³ 474 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/488' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/474' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2948'>Pandrosos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3664'>Thyechoos</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM65">65</a></li> 
<li :class="{'activeClass': idClicked == IGI3475 }" id="IGI3475"><inscr id='IG I³ 475'>IG I³ 475 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/489' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2948'>Pandrosos</a> Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM57">57</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM65">65</a></li> 
<li :class="{'activeClass': idClicked == IGI3476 }" id="IGI3476"><inscr id='IG I³ 476'>IG I³ 476 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/490' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/476' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1404'>Dione</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2352'>Kekrops</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3664'>Thyechoos</a></li> 
<li :class="{'activeClass': idClicked == IGI3482 }" id="IGI3482"><inscr id='IG I³ 482'>IG I³ 482 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/497' target='_blank'>PHI</a>] </inscr> - Bellerophon </li> 
<li :class="{'activeClass': idClicked == IGI3501 }" id="IGI3501"><inscr id='IG I³ 501'>IG I³ 501 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/516' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/501' target='_blank'>AOI</a>] </inscr> - Chariot and fetters Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM116">116</a> </li> 
<li :class="{'activeClass': idClicked == IGI3502 }" id="IGI3502"><inscr id='IG I³ 502'>IG I³ 502 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/517' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/502' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1835'>Harmodios and Aristogeiton</a> </li> 
<li :class="{'activeClass': idClicked == IGI3503 }" id="IGI3503">IG I³ 503-504 - Persian War Memorials</li> 
<li :class="{'activeClass': idClicked == IGI3505 }" id="IGI3505"><inscr id='IG I³ 505'>IG I³ 505 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/519' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/505' target='_blank'>AOI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM86">86</a></li> 
<li :class="{'activeClass': idClicked == IGI3506 }" id="IGI3506"><inscr id='IG I³ 506'>IG I³ 506 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/520' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/506' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP763'>Ath68*</a></li> 
<li :class="{'activeClass': idClicked == IGI3511 }" id="IGI3511"><inscr id='IG I³ 511'>IG I³ 511 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/526' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/511' target='_blank'>AOI</a>] </inscr> - Hipparchs' Dedication</li> 
<li :class="{'activeClass': idClicked == IGI3514 }" id="IGI3514"><inscr id='IG I³ 514'>IG I³ 514 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/529' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/514' target='_blank'>AOI</a>] </inscr> - Athens <a v-on:click.prevent="replaceLink"  href='NPP.html#NP768'>Ath72**</a></li> 
<li :class="{'activeClass': idClicked == IGI3521bis }" id="IGI3521bis"><inscr id='IG I³ 521bis'>IG I³ 521bis [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/548' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/521bis' target='_blank'>AOI</a>] </inscr> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a> - </li> 
<li :class="{'activeClass': idClicked == IGI3522 }" id="IGI3522"><inscr id='IG I³ 522'>IG I³ 522 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/549' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/522' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2479'>Lakedaimonian Shield</a></li> 
<li :class="{'activeClass': idClicked == IGI3549bis }" id="IGI3549bis"><inscr id='IG I³ 549bis'>IG I³ 549bis [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/579' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP644'>Ath6*</a></li> 
<li :class="{'activeClass': idClicked == IGI3590 }" id="IGI3590"><inscr id='IG I³ 590'>IG I³ 590 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/666' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP640'>Ath3*</a></li> 
<li :class="{'activeClass': idClicked == IGI3593 }" id="IGI3593"><inscr id='IG I³ 593'>IG I³ 593 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/669' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2684'>Moschophoros</a></li> 
<li :class="{'activeClass': idClicked == IGI3596 }" id="IGI3596"><inscr id='IG I³ 596'>IG I³ 596 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/672' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/596' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP770'>Ath74*</a></li> 
<li :class="{'activeClass': idClicked == IGI3618 }" id="IGI3618"><inscr id='IG I³ 618'>IG I³ 618 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/696' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP993'>Chairion</a></li> 
<li :class="{'activeClass': idClicked == IGI3628 }" id="IGI3628"><inscr id='IG I³ 628'>IG I³ 628 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/708' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP645'>Ath7*</a></li> 
<li :class="{'activeClass': idClicked == IGI3680 }" id="IGI3680"><inscr id='IG I³ 680'>IG I³ 680</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP703'>Ath26*</a></li> 
<li :class="{'activeClass': idClicked == IGI3764 }" id="IGI3764"><inscr id='IG I³ 764'>IG I³ 764</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3239'>Potter Hero</a></li> 
<li :class="{'activeClass': idClicked == IGI3776 }" id="IGI3776"><inscr id='IG I³ 776'>IG I³ 776</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2000'>Hrm2*</a></li> 
<li :class="{'activeClass': idClicked == IGI3784 }" id="IGI3784"><inscr id='IG I³ 784'>IG I³ 784 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/884' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP646'>Ath8*</a></li> 
<li :class="{'activeClass': idClicked == IGI3788 }" id="IGI3788"><inscr id='IG I³ 788'>IG I³ 788</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP704'>Ath27*</a></li> 
<li :class="{'activeClass': idClicked == IGI3823 }" id="IGI3823"><inscr id='IG I³ 823'>IG I³ 823 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/931' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3062'>Phayllos</a></li> 
<li :class="{'activeClass': idClicked == IGI3824 }" id="IGI3824"><inscr id='IG I³ 824'>IG I³ 824 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/932' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP759'>Ath65**</a></li> 
<li :class="{'activeClass': idClicked == IGI3832 }" id="IGI3832"><inscr id='IG I³ 832'>IG I³ 832 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/941' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP244'>Aph1*</a></li> 
<li :class="{'activeClass': idClicked == IGI3835 }" id="IGI3835"><inscr id='IG I³ 835'>IG I³ 835 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/945' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2318'>Kallias</a></li> 
<li :class="{'activeClass': idClicked == IGI3847 }" id="IGI3847"><inscr id='IG I³ 847'>IG I³ 847 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/958' target='_blank'>PHI</a>] </inscr> - Epicharinos</li> 
<li :class="{'activeClass': idClicked == IGI3850 }" id="IGI3850"><inscr id='IG I³ 850'>IG I³ 850 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/961' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP650'>Ath9*</a></li> 
<li :class="{'activeClass': idClicked == IGI3863 }" id="IGI3863"><inscr id='IG I³ 863'>IG I³ 863 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/976' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3674'>Timotheos</a> </li> 
<li :class="{'activeClass': idClicked == IGI3873 }" id="IGI3873"><inscr id='IG I³ 873'>IG I³ 873 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/988' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a> </li> 
<li :class="{'activeClass': idClicked == IGI3876 }" id="IGI3876"><inscr id='IG I³ 876'>IG I³ 876 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/991' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP248'>Aph5*</a></li> 
<li :class="{'activeClass': idClicked == IGI3880 }" id="IGI3880"><inscr id='IG I³ 880'>IG I³ 880 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/995' target='_blank'>PHI</a>] </inscr> - Chariot</li> 
<li :class="{'activeClass': idClicked == IGI3883 }" id="IGI3883"><inscr id='IG I³ 883'>IG I³ 883 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/998' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1377'>Dieitrephes</a></li> 
<li :class="{'activeClass': idClicked == IGI3884 }" id="IGI3884"><inscr id='IG I³ 884'>IG I³ 884 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/999' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3027'>Perikles</a></li> 
<li :class="{'activeClass': idClicked == IGI3885 }" id="IGI3885"><inscr id='IG I³ 885'>IG I³ 885 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1000' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP652'>Ath10*</a></li> 
<li :class="{'activeClass': idClicked == IGI3895 }" id="IGI3895"><inscr id='IG I³ 895'>IG I³ 895 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1012' target='_blank'>PHI</a>] </inscr> - Trojan Horse </li> 
<li :class="{'activeClass': idClicked == IGI3899 }" id="IGI3899"><inscr id='IG I³ 899'>IG I³ 899 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1016' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP733'>Ath49*</a> </li> 
<li :class="{'activeClass': idClicked == IGI3926 }" id="IGI3926"><inscr id='IG I³ 926'>IG I³ 926 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1047' target='_blank'>PHI</a>] </inscr>-932 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP649'>Ath8**</a> </li> 
<li :class="{'activeClass': idClicked == IGI3940 }" id="IGI3940"><inscr id='IG I³ 940'>IG I³ 940 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1061' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM129">129</a> </li> 
<li :class="{'activeClass': idClicked == IGI3948 }" id="IGI3948"><inscr id='IG I³ 948'>IG I³ 948 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1071' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP398'>Apo71*</a></li> 
<li :class="{'activeClass': idClicked == IGI3950 }" id="IGI3950"><inscr id='IG I³ 950'>IG I³ 950 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1073' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP315'>Apo4*</a></li> 
<li :class="{'activeClass': idClicked == IGI3951 }" id="IGI3951"><inscr id='IG I³ 951'>IG I³ 951 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1074' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3734'>Twelve Gods</a> </li> 
<li :class="{'activeClass': idClicked == IGI3955 }" id="IGI3955"><inscr id='IG I³ 955'>IG I³ 955 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1079' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2812'>Nym12*</a></li> 
<li :class="{'activeClass': idClicked == IGI3960 }" id="IGI3960"><inscr id='IG I³ 960'>IG I³ 960 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1084' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3301'>Pythodoros</a> </li> 
<li :class="{'activeClass': idClicked == IGI3964 }" id="IGI3964"><inscr id='IG I³ 964'>IG I³ 964 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1089' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP476'>Aristokrates</a> </li> 
<li :class="{'activeClass': idClicked == IGI31023 }" id="IGI31023"><inscr id='IG I³ 1023'>IG I³ 1023 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1161' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a> </li> 
<li :class="{'activeClass': idClicked == IGI31049 }" id="IGI31049"><inscr id='IG I³ 1049'>IG I³ 1049 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1190' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP766'>Ath71*</a></li> 
<li :class="{'activeClass': idClicked == IGI31050 }" id="IGI31050"><inscr id='IG I³ 1050'>IG I³ 1050 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1191' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP642'>Ath4*</a></li> 
<li :class="{'activeClass': idClicked == IGI31051 }" id="IGI31051"><inscr id='IG I³ 1051'>IG I³ 1051 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1192' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP814'>Ath109*</a></li> 
<li :class="{'activeClass': idClicked == IGI31052 }" id="IGI31052"><inscr id='IG I³ 1052'>IG I³ 1052 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1193' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a></li> 
<li :class="{'activeClass': idClicked == IGI31053 }" id="IGI31053"><inscr id='IG I³ 1053'>IG I³ 1053 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1194' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP410'>Apo80*</a></li> 
<li :class="{'activeClass': idClicked == IGI31054 }" id="IGI31054"><inscr id='IG I³ 1054'>IG I³ 1054 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1195' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1216'>Demeter</a></li> 
<li :class="{'activeClass': idClicked == IGI31055A }" id="IGI31055A"><inscr id='IG I³ 1055A'>IG I³ 1055A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3789'>Zeu1*</a></li> 
<li :class="{'activeClass': idClicked == IGI31055B }" id="IGI31055B"><inscr id='IG I³ 1055B'>IG I³ 1055B</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3789'>Zeu1*</a></li> 
<li :class="{'activeClass': idClicked == IGI31056 }" id="IGI31056"><inscr id='IG I³ 1056'>IG I³ 1056 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1197' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3811'>Zeu18*</a></li> 
<li :class="{'activeClass': idClicked == IGI31057 }" id="IGI31057"><inscr id='IG I³ 1057'>IG I³ 1057 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1198' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3924'>Zeu110*</a></li> 
<li :class="{'activeClass': idClicked == IGI31057bis }" id="IGI31057bis"><inscr id='IG I³ 1057bis'>IG I³ 1057bis</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3896'>Zeu87*</a></li> 
<li :class="{'activeClass': idClicked == IGI31058 }" id="IGI31058"><inscr id='IG I³ 1058'>IG I³ 1058 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1200' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1931'>Hrk10*</a></li> 
<li :class="{'activeClass': idClicked == IGI31059 }" id="IGI31059"><inscr id='IG I³ 1059'>IG I³ 1059 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1201' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1930'>Hrk9*</a></li> 
<li :class="{'activeClass': idClicked == IGI31060 }" id="IGI31060"><inscr id='IG I³ 1060'>IG I³ 1060 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1202' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2443'>Kourotrophion</a></li> 
<li :class="{'activeClass': idClicked == IGI31061 }" id="IGI31061"><inscr id='IG I³ 1061'>IG I³ 1061 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1203' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP15'>Acheloos</a></li> 
<li :class="{'activeClass': idClicked == IGI31062 }" id="IGI31062"><inscr id='IG I³ 1062'>IG I³ 1062 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1204' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2610'>Medontidai</a></li> 
<li :class="{'activeClass': idClicked == IGI31063 }" id="IGI31063"><inscr id='IG I³ 1063'>IG I³ 1063 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1205' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2792'>Nymphaion</a></li> 
<li :class="{'activeClass': idClicked == IGI31064 }" id="IGI31064"><inscr id='IG I³ 1064'>IG I³ 1064 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1206' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == IGI31065 }" id="IGI31065"><inscr id='IG I³ 1065'>IG I³ 1065 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1207' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2808'>Nym10*</a></li> 
<li :class="{'activeClass': idClicked == IGI31066 }" id="IGI31066"><inscr id='IG I³ 1066'>IG I³ 1066 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1208' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3714'>Tritopatreion</a> </li> 
<li :class="{'activeClass': idClicked == IGI31067 }" id="IGI31067"><inscr id='IG I³ 1067'>IG I³ 1067 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1209' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3714'>Tritopatreion</a></li> 
<li :class="{'activeClass': idClicked == IGI31068 }" id="IGI31068"><inscr id='IG I³ 1068'>IG I³ 1068 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1210' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM195">195</a></li> 
<li :class="{'activeClass': idClicked == IGI31069 }" id="IGI31069"><inscr id='IG I³ 1069'>IG I³ 1069 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1211' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM160">160</a></li> 
<li :class="{'activeClass': idClicked == IGI31070 }" id="IGI31070"><inscr id='IG I³ 1070'>IG I³ 1070 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1212' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM161">161</a></li> 
<li :class="{'activeClass': idClicked == IGI31071 }" id="IGI31071"><inscr id='IG I³ 1071'>IG I³ 1071 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1213' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM247">247</a></li> 
<li :class="{'activeClass': idClicked == IGI31071 }" id="IGI31071"><inscr id='IG I³ 1071'>IG I³ 1071 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1213' target='_blank'>PHI</a>] </inscr> - bis Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM561">561</a></li> 
<li :class="{'activeClass': idClicked == IGI31072 }" id="IGI31072"><inscr id='IG I³ 1072'>IG I³ 1072 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1215' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM100">100</a></li> 
<li :class="{'activeClass': idClicked == IGI31073 }" id="IGI31073"><inscr id='IG I³ 1073'>IG I³ 1073 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1216' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM101">101</a></li> 
<li :class="{'activeClass': idClicked == IGI31074 }" id="IGI31074"><inscr id='IG I³ 1074'>IG I³ 1074 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1217' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM319">319</a></li> 
<li :class="{'activeClass': idClicked == IGI31075 }" id="IGI31075"><inscr id='IG I³ 1075'>IG I³ 1075 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1218' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM328">328</a></li> 
<li :class="{'activeClass': idClicked == IGI31076 }" id="IGI31076"><inscr id='IG I³ 1076'>IG I³ 1076 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1219' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM560">560</a></li> 
<li :class="{'activeClass': idClicked == IGI31084 }" id="IGI31084"><inscr id='IG I³ 1084'>IG I³ 1084 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1227' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3870'>Zeu66*</a> </li> 
<li :class="{'activeClass': idClicked == IGI31087 }" id="IGI31087"><inscr id='IG I³ 1087'>IG I³ 1087 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1231' target='_blank'>PHI</a>] </inscr>-1090 - Agora</li> 
<li :class="{'activeClass': idClicked == IGI31091 }" id="IGI31091"><inscr id='IG I³ 1091'>IG I³ 1091 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1235' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP102'>Akademia</a></li> 
<li :class="{'activeClass': idClicked == IGI31092 }" id="IGI31092"><inscr id='IG I³ 1092'>IG I³ 1092 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1236' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3147'>Pnyx</a></li> 
<li :class="{'activeClass': idClicked == IGI31092bis }" id="IGI31092bis"><inscr id='IG I³ 1092bis'>IG I³ 1092bis</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM595">595</a></li> 
<li :class="{'activeClass': idClicked == IGI31093 }" id="IGI31093"><inscr id='IG I³ 1093'>IG I³ 1093 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1238' target='_blank'>PHI</a>] </inscr> - Part II no 615</li> 
<li :class="{'activeClass': idClicked == IGI31094 }" id="IGI31094"><inscr id='IG I³ 1094'>IG I³ 1094 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1239' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM616">616</a></li> 
<li :class="{'activeClass': idClicked == IGI31094bis }" id="IGI31094bis"><inscr id='IG I³ 1094bis'>IG I³ 1094bis [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1240' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM614">614</a></li> 
<li :class="{'activeClass': idClicked == IGI31095 }" id="IGI31095"><inscr id='IG I³ 1095'>IG I³ 1095 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1241' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3332'>Sacred Way</a></li> 
<li :class="{'activeClass': idClicked == IGI31096 }" id="IGI31096"><inscr id='IG I³ 1096'>IG I³ 1096 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1242' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3332'>Sacred Way</a></li> 
<li :class="{'activeClass': idClicked == IGI31097 }" id="IGI31097"><inscr id='IG I³ 1097'>IG I³ 1097 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1243' target='_blank'>PHI</a>] </inscr> - Propylon</li> 
<li :class="{'activeClass': idClicked == IGI31098 }" id="IGI31098"><inscr id='IG I³ 1098'>IG I³ 1098 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1244' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM154">154</a></li> 
<li :class="{'activeClass': idClicked == IGI31099 }" id="IGI31099"><inscr id='IG I³ 1099'>IG I³ 1099 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1245' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM155">155</a></li> 
<li :class="{'activeClass': idClicked == IGI31100 }" id="IGI31100"><inscr id='IG I³ 1100'>IG I³ 1100 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1246' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM494">494</a></li> 
<li :class="{'activeClass': idClicked == IGI31109 }" id="IGI31109"><inscr id='IG I³ 1109'>IG I³ 1109 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1257' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM610">610</a></li> 
<li :class="{'activeClass': idClicked == IGI31110 }" id="IGI31110"><inscr id='IG I³ 1110'>IG I³ 1110 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1258' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM611">611</a></li> 
<li :class="{'activeClass': idClicked == IGI31117 }" id="IGI31117"><inscr id='IG I³ 1117'>IG I³ 1117 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1265' target='_blank'>PHI</a>] </inscr>-1126 - Trittys </li> 
<li :class="{'activeClass': idClicked == IGI31142 }" id="IGI31142"><inscr id='IG I³ 1142'>IG I³ 1142 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1300' target='_blank'>PHI</a>] </inscr> - Persian War Memorials</li> 
<li :class="{'activeClass': idClicked == IGI31147 }" id="IGI31147"><inscr id='IG I³ 1147'>IG I³ 1147 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1305' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/1147' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem13*</li> 
<li :class="{'activeClass': idClicked == IGI31149 }" id="IGI31149"><inscr id='IG I³ 1149'>IG I³ 1149 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1308' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/1149' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == IGI31150 }" id="IGI31150"><inscr id='IG I³ 1150'>IG I³ 1150 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1309' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33** </li> 
<li :class="{'activeClass': idClicked == IGI31154 }" id="IGI31154"><inscr id='IG I³ 1154'>IG I³ 1154 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1313' target='_blank'>PHI</a>] </inscr> - Pythagoras </li> 
<li :class="{'activeClass': idClicked == IGI31158a }" id="IGI31158a"><inscr id='IG I³ 1158a'>IG I³ 1158a</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem36*</li> 
<li :class="{'activeClass': idClicked == IGI31162 }" id="IGI31162"><inscr id='IG I³ 1162'>IG I³ 1162 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1321' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/1162' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem9*</li> 
<li :class="{'activeClass': idClicked == IGI31163 }" id="IGI31163"><inscr id='IG I³ 1163'>IG I³ 1163 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1322' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem16*, Sem19*</li> 
<li :class="{'activeClass': idClicked == IGI31178 }" id="IGI31178"><inscr id='IG I³ 1178'>IG I³ 1178 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1337' target='_blank'>PHI</a>] </inscr> - Silenos </li> 
<li :class="{'activeClass': idClicked == IGI31179 }" id="IGI31179"><inscr id='IG I³ 1179'>IG I³ 1179 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1338' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem24*</li> 
<li :class="{'activeClass': idClicked == IGI31180 }" id="IGI31180"><inscr id='IG I³ 1180'>IG I³ 1180 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1339' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem32*</li> 
<li :class="{'activeClass': idClicked == IGI31181 }" id="IGI31181"><inscr id='IG I³ 1181'>IG I³ 1181 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1340' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == IGI31184 }" id="IGI31184"><inscr id='IG I³ 1184'>IG I³ 1184 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1343' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li> 
<li :class="{'activeClass': idClicked == IGI31186 }" id="IGI31186"><inscr id='IG I³ 1186'>IG I³ 1186 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1345' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem35*</li> 
<li :class="{'activeClass': idClicked == IGI31191 }" id="IGI31191"><inscr id='IG I³ 1191'>IG I³ 1191 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1350' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == IGI31192 }" id="IGI31192"><inscr id='IG I³ 1192'>IG I³ 1192 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1351' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33*</li> 
<li :class="{'activeClass': idClicked == IGI31194 }" id="IGI31194"><inscr id='IG I³ 1194'>IG I³ 1194 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1354' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2380'>Keramo</a></li> 
<li :class="{'activeClass': idClicked == IGI31285 }" id="IGI31285"><inscr id='IG I³ 1285'>IG I³ 1285</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2738'>Myrrhine</a></li> 
<li :class="{'activeClass': idClicked == IGI31288 }" id="IGI31288"><inscr id='IG I³ 1288'>IG I³ 1288</inscr> - Melanopos</li> 
<li :class="{'activeClass': idClicked == IGI31330 }" id="IGI31330"><inscr id='IG I³ 1330'>IG I³ 1330 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1521' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/1330' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2738'>Myrrhine</a></li> 
<li :class="{'activeClass': idClicked == IGI31341bis }" id="IGI31341bis"><inscr id='IG I³ 1341bis'>IG I³ 1341bis</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1991'>Hermaios</a></li> 
<li :class="{'activeClass': idClicked == IGI31344 }" id="IGI31344"><inscr id='IG I³ 1344'>IG I³ 1344</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3750'>Tyr</a> —</li> 
<li :class="{'activeClass': idClicked == IGI31353 }" id="IGI31353"><inscr id='IG I³ 1353'>IG I³ 1353 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1548' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGI3/1353' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3296'>Pythion of Megara</a></li> 
<li :class="{'activeClass': idClicked == IGI31382 }" id="IGI31382"><inscr id='IG I³ 1382'>IG I³ 1382</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1671'>Eros</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGI31385 }" id="IGI31385"><inscr id='IG I³ 1385'>IG I³ 1385</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP931'>Bouleuterion</a></li> 
<li :class="{'activeClass': idClicked == IGI31388 }" id="IGI31388"><inscr id='IG I³ 1388'>IG I³ 1388</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == IGI31389 }" id="IGI31389"><inscr id='IG I³ 1389'>IG I³ 1389</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1463'>Dio34*</a></li> 
<li :class="{'activeClass': idClicked == IGI31390 }" id="IGI31390"><inscr id='IG I³ 1390'>IG I³ 1390</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1462'>Dio33*</a></li> 
<li :class="{'activeClass': idClicked == IGI31392 }" id="IGI31392"><inscr id='IG I³ 1392'>IG I³ 1392</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2387'>Kerykes</a></li> 
<li :class="{'activeClass': idClicked == IGI31403 }" id="IGI31403"><inscr id='IG I³ 1403'>IG I³ 1403</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM246">246</a></li> 
<li :class="{'activeClass': idClicked == IGI31406 }" id="IGI31406"><inscr id='IG I³ 1406'>IG I³ 1406</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == IGI31417bis }" id="IGI31417bis"><inscr id='IG I³ 1417bis'>IG I³ 1417bis</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM391">391</a> </li> 
<li :class="{'activeClass': idClicked == IGI31418 }" id="IGI31418"><inscr id='IG I³ 1418'>IG I³ 1418</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM84">84</a></li> 
</ul> 
<h4>IG I²</h4>
<ul>
<li :class="{'activeClass': idClicked == IGI278 }" id="IGI278"><inscr id='IG I² 78'>IG I² 78</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP407'>Apo77*</a></li> 
<li :class="{'activeClass': idClicked == IGI284 }" id="IGI284"><inscr id='IG I² 84'>IG I² 84</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a></li> 
<li :class="{'activeClass': idClicked == IGI294 }" id="IGI294"><inscr id='IG I² 94'>IG I² 94</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a></li> 
<li :class="{'activeClass': idClicked == IGI2370 }" id="IGI2370">IG I² 370/1	<a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a> </li> 
<li :class="{'activeClass': idClicked == IGI2394 }" id="IGI2394"><inscr id='IG I² 394'>IG I² 394</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1002'>Chariot and Fetters</a>, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM116">116</a></li> 
<li :class="{'activeClass': idClicked == IGI2395 }" id="IGI2395"><inscr id='IG I² 395'>IG I² 395</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP763'>Ath68*</a></li> 
<li :class="{'activeClass': idClicked == IGI2400 }" id="IGI2400"><inscr id='IG I² 400'>IG I² 400 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1756' target='_blank'>PHI</a>] </inscr> - Hipparchs (3)</li> 
<li :class="{'activeClass': idClicked == IGI2467 }" id="IGI2467"><inscr id='IG I² 467'>IG I² 467</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP640'>Ath3*</a></li> 
<li :class="{'activeClass': idClicked == IGI2469 }" id="IGI2469"><inscr id='IG I² 469'>IG I² 469</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2684'>Moschophoros</a></li> 
<li :class="{'activeClass': idClicked == IGI2484 }" id="IGI2484"><inscr id='IG I² 484'>IG I² 484</inscr> - Part II. no. 84</li> 
<li :class="{'activeClass': idClicked == IGI2485 }" id="IGI2485"><inscr id='IG I² 485'>IG I² 485</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP645'>Ath7*</a></li> 
<li :class="{'activeClass': idClicked == IGI2495 }" id="IGI2495"><inscr id='IG I² 495'>IG I² 495</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP704'>Ath27*</a></li> 
<li :class="{'activeClass': idClicked == IGI2507 }" id="IGI2507"><inscr id='IG I² 507'>IG I² 507</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP703'>Ath26*</a></li> 
<li :class="{'activeClass': idClicked == IGI2516 }" id="IGI2516"><inscr id='IG I² 516'>IG I² 516</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP759'>Ath65**</a></li> 
<li :class="{'activeClass': idClicked == IGI2527 }" id="IGI2527"><inscr id='IG I² 527'>IG I² 527</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1377'>Dieitrephes</a></li> 
<li :class="{'activeClass': idClicked == IGI2528 }" id="IGI2528"><inscr id='IG I² 528'>IG I² 528</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3027'>Perikles</a></li> 
<li :class="{'activeClass': idClicked == IGI2529 }" id="IGI2529"><inscr id='IG I² 529'>IG I² 529</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP652'>Ath10*</a></li> 
<li :class="{'activeClass': idClicked == IGI2530 }" id="IGI2530"><inscr id='IG I² 530'>IG I² 530</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP650'>Ath9*</a></li> 
<li :class="{'activeClass': idClicked == IGI2531 }" id="IGI2531"><inscr id='IG I² 531'>IG I² 531</inscr> - Epicharinos</li> 
<li :class="{'activeClass': idClicked == IGI2535 }" id="IGI2535"><inscr id='IG I² 535'>IG I² 535</inscr> - Trojan Horse</li> 
<li :class="{'activeClass': idClicked == IGI2561 }" id="IGI2561"><inscr id='IG I² 561'>IG I² 561 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1760' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP753'>Ath63*</a></li> 
<li :class="{'activeClass': idClicked == IGI2580 }" id="IGI2580"><inscr id='IG I² 580'>IG I² 580</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a> on the Akr. </li> 
<li :class="{'activeClass': idClicked == IGI2607 }" id="IGI2607"><inscr id='IG I² 607'>IG I² 607</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2318'>Kallias</a>, son of <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2190'>Hipponikos</a></li> 
<li :class="{'activeClass': idClicked == IGI2609 }" id="IGI2609"><inscr id='IG I² 609'>IG I² 609</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP646'>Ath8*</a></li> 
<li :class="{'activeClass': idClicked == IGI2631 }" id="IGI2631"><inscr id='IG I² 631'>IG I² 631</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2000'>Hrm2*</a></li> 
<li :class="{'activeClass': idClicked == IGI2651 }" id="IGI2651"><inscr id='IG I² 651'>IG I² 651</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3674'>Timotheos</a></li> 
<li :class="{'activeClass': idClicked == IGI2655 }" id="IGI2655"><inscr id='IG I² 655'>IG I² 655</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3062'>Phayllos</a></li> 
<li :class="{'activeClass': idClicked == IGI2663 }" id="IGI2663"><inscr id='IG I² 663'>IG I² 663</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP993'>Chairion</a></li> 
<li :class="{'activeClass': idClicked == IGI2700 }" id="IGI2700"><inscr id='IG I² 700'>IG I² 700</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP244'>Aph1*</a></li> 
<li :class="{'activeClass': idClicked == IGI2718 }" id="IGI2718"><inscr id='IG I² 718'>IG I² 718</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3239'>Potter Hero</a></li> 
<li :class="{'activeClass': idClicked == IGI2731 }" id="IGI2731"><inscr id='IG I² 731'>IG I² 731</inscr> - Part II. no. 129</li> 
<li :class="{'activeClass': idClicked == IGI2761 }" id="IGI2761"><inscr id='IG I² 761'>IG I² 761</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP398'>Apo71*</a></li> 
<li :class="{'activeClass': idClicked == IGI2762 }" id="IGI2762"><inscr id='IG I² 762'>IG I² 762</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP733'>Ath49*</a></li> 
<li :class="{'activeClass': idClicked == IGI2763 }" id="IGI2763"><inscr id='IG I² 763'>IG I² 763</inscr> - Persian War epigrams </li> 
<li :class="{'activeClass': idClicked == IGI2770A }" id="IGI2770A"><inscr id='IG I² 770A'>IG I² 770A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3301'>Pythodoros</a></li> 
<li :class="{'activeClass': idClicked == IGI2772 }" id="IGI2772"><inscr id='IG I² 772'>IG I² 772</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP476'>Aristokrates</a></li> 
<li :class="{'activeClass': idClicked == IGI2773 }" id="IGI2773"><inscr id='IG I² 773'>IG I² 773</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP15'>Acheloos</a> </li> 
<li :class="{'activeClass': idClicked == IGI2837 }" id="IGI2837"><inscr id='IG I² 837'>IG I² 837</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGI2840 }" id="IGI2840"><inscr id='IG I² 840'>IG I² 840</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2116'>Hro22*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3825'>Zeu31*</a> </li> 
<li :class="{'activeClass': idClicked == IGI2843 }" id="IGI2843"><inscr id='IG I² 843'>IG I² 843</inscr>-845 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3460'>Stoa Basileios</a> </li> 
<li :class="{'activeClass': idClicked == IGI2851 }" id="IGI2851"><inscr id='IG I² 851'>IG I² 851</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem36*</li> 
<li :class="{'activeClass': idClicked == IGI2854 }" id="IGI2854"><inscr id='IG I² 854'>IG I² 854</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2808'>Nym10*</a></li> 
<li :class="{'activeClass': idClicked == IGI2856 }" id="IGI2856"><inscr id='IG I² 856'>IG I² 856</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM100">100</a></li> 
<li :class="{'activeClass': idClicked == IGI2857 }" id="IGI2857"><inscr id='IG I² 857'>IG I² 857</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM101">101</a></li> 
<li :class="{'activeClass': idClicked == IGI2859 }" id="IGI2859"><inscr id='IG I² 859'>IG I² 859</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2443'>Kourotrophion</a></li> 
<li :class="{'activeClass': idClicked == IGI2860 }" id="IGI2860"><inscr id='IG I² 860'>IG I² 860</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM160">160</a></li> 
<li :class="{'activeClass': idClicked == IGI2861 }" id="IGI2861"><inscr id='IG I² 861'>IG I² 861</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM161">161</a></li> 
<li :class="{'activeClass': idClicked == IGI2862 }" id="IGI2862"><inscr id='IG I² 862'>IG I² 862</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM195">195</a></li> 
<li :class="{'activeClass': idClicked == IGI2863 }" id="IGI2863"><inscr id='IG I² 863'>IG I² 863</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3789'>Zeu1*</a></li> 
<li :class="{'activeClass': idClicked == IGI2866 }" id="IGI2866"><inscr id='IG I² 866'>IG I² 866</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3870'>Zeu66*</a></li> 
<li :class="{'activeClass': idClicked == IGI2870 }" id="IGI2870"><inscr id='IG I² 870'>IG I² 870</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3714'>Tritopatreion</a></li> 
<li :class="{'activeClass': idClicked == IGI2871 }" id="IGI2871"><inscr id='IG I² 871'>IG I² 871</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2610'>Medontidai</a></li> 
<li :class="{'activeClass': idClicked == IGI2874 }" id="IGI2874"><inscr id='IG I² 874'>IG I² 874</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM154">154</a></li> 
<li :class="{'activeClass': idClicked == IGI2875 }" id="IGI2875"><inscr id='IG I² 875'>IG I² 875</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM155">155</a></li> 
<li :class="{'activeClass': idClicked == IGI2876 }" id="IGI2876"><inscr id='IG I² 876'>IG I² 876</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM494">494</a></li> 
<li :class="{'activeClass': idClicked == IGI2877 }" id="IGI2877"><inscr id='IG I² 877'>IG I² 877</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM615">615</a></li> 
<li :class="{'activeClass': idClicked == IGI2878 }" id="IGI2878"><inscr id='IG I² 878'>IG I² 878</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM616">616</a></li> 
<li :class="{'activeClass': idClicked == IGI2879 }" id="IGI2879"><inscr id='IG I² 879'>IG I² 879</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1462'>Dio33*</a></li> 
<li :class="{'activeClass': idClicked == IGI2880 }" id="IGI2880"><inscr id='IG I² 880'>IG I² 880</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2387'>Kerykes</a></li> 
<li :class="{'activeClass': idClicked == IGI2881 }" id="IGI2881"><inscr id='IG I² 881'>IG I² 881</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3332'>Sacred Way</a> </li> 
<li :class="{'activeClass': idClicked == IGI2882 }" id="IGI2882"><inscr id='IG I² 882'>IG I² 882</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3147'>Pnyx</a></li> 
<li :class="{'activeClass': idClicked == IGI2883 }" id="IGI2883"><inscr id='IG I² 883'>IG I² 883</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2376'>Kerameis</a> Trittys</li> 
<li :class="{'activeClass': idClicked == IGI2884 }" id="IGI2884"><inscr id='IG I² 884'>IG I² 884</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2482'>Lakiadai</a> Trittys</li> 
<li :class="{'activeClass': idClicked == IGI2885 }" id="IGI2885"><inscr id='IG I² 885'>IG I² 885</inscr> - Trittys</li> 
<li :class="{'activeClass': idClicked == IGI2886 }" id="IGI2886"><inscr id='IG I² 886'>IG I² 886</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3924'>Zeu110*</a></li> 
<li :class="{'activeClass': idClicked == IGI2892 }" id="IGI2892"><inscr id='IG I² 892'>IG I² 892</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM610">610</a></li> 
<li :class="{'activeClass': idClicked == IGI2900 }" id="IGI2900"><inscr id='IG I² 900'>IG I² 900</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1017'>Cholargos</a> </li> 
<li :class="{'activeClass': idClicked == IGI2919 }" id="IGI2919"><inscr id='IG I² 919'>IG I² 919 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/1811' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1511'>Dipylon Graves</a> </li> 
<li :class="{'activeClass': idClicked == IGI2923 }" id="IGI2923"><inscr id='IG I² 923'>IG I² 923</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM246">246</a></li> 
<li :class="{'activeClass': idClicked == IGI2925 }" id="IGI2925"><inscr id='IG I² 925'>IG I² 925</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP667'>Ath19*</a></li> 
<li :class="{'activeClass': idClicked == IGI2928 }" id="IGI2928"><inscr id='IG I² 928'>IG I² 928</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</li> 
<li :class="{'activeClass': idClicked == IGI2929 }" id="IGI2929"><inscr id='IG I² 929'>IG I² 929</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem13*</li> 
<li :class="{'activeClass': idClicked == IGI2931 }" id="IGI2931"><inscr id='IG I² 931/2'>IG I² 931/2</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == IGI2933 }" id="IGI2933"><inscr id='IG I² 933'>IG I² 933</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33**</li> 
<li :class="{'activeClass': idClicked == IGI2942 }" id="IGI2942"><inscr id='IG I² 942'>IG I² 942</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem19*</li> 
<li :class="{'activeClass': idClicked == IGI2943 }" id="IGI2943"><inscr id='IG I² 943'>IG I² 943</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem9*</li> 
<li :class="{'activeClass': idClicked == IGI2944 }" id="IGI2944"><inscr id='IG I² 944'>IG I² 944</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem32*</li> 
<li :class="{'activeClass': idClicked == IGI2945 }" id="IGI2945"><inscr id='IG I² 945'>IG I² 945</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem24*</li> 
<li :class="{'activeClass': idClicked == IGI2946 }" id="IGI2946"><inscr id='IG I² 946'>IG I² 946</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</li> 
<li :class="{'activeClass': idClicked == IGI2949 }" id="IGI2949"><inscr id='IG I² 949'>IG I² 949</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem25*</li> 
<li :class="{'activeClass': idClicked == IGI2951 }" id="IGI2951"><inscr id='IG I² 951/2'>IG I² 951/2</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem33*</li> 
<li :class="{'activeClass': idClicked == IGI2954 }" id="IGI2954"><inscr id='IG I² 954'>IG I² 954</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == IGI2955 }" id="IGI2955"><inscr id='IG I² 955'>IG I² 955</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem35*</li> 
<li :class="{'activeClass': idClicked == IGI2957 }" id="IGI2957"><inscr id='IG I² 957'>IG I² 957</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == IGI2964 }" id="IGI2964"><inscr id='IG I² 964'>IG I² 964</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</li> 
<li :class="{'activeClass': idClicked == IGI2997 }" id="IGI2997"><inscr id='IG I² 997'>IG I² 997</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2380'>Keramo</a></li> 
<li :class="{'activeClass': idClicked == IGI21034 }" id="IGI21034">IG I² 1034 - Pythagoras</li> 
<li :class="{'activeClass': idClicked == IGI21085 }" id="IGI21085"><inscr id='IG I² 1085'>IG I² 1085</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3296'>Pythion of Megara</a></li> 
</ul>
<h4>IG II²</h4>
<ul>
<li :class="{'activeClass': idClicked == IGII297 }" id="IGII297"><inscr id='IG II² 97'>IG II² 97 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2315' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP835'>Ath121*</a></li> 
<li :class="{'activeClass': idClicked == IGII2112 }" id="IGII2112"><inscr id='IG II² 112'>IG II² 112 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2330' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/112' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP845'>Ath129*</a></li> 
<li :class="{'activeClass': idClicked == IGII2133 }" id="IGII2133"><inscr id='IG II² 133'>IG II² 133 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2351' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP834'>Ath120*</a></li> 
<li :class="{'activeClass': idClicked == IGII2171 }" id="IGII2171"><inscr id='IG II² 171'>IG II² 171 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2389' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/171' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP160'>Amphiaraos</a> </li> 
<li :class="{'activeClass': idClicked == IGII2212 }" id="IGII2212"><inscr id='IG II² 212'>IG II² 212 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2430' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/212' target='_blank'>AOI</a>] </inscr> - Spartokos II </li> 
<li :class="{'activeClass': idClicked == IGII2218 }" id="IGII2218"><inscr id='IG II² 218'>IG II² 218 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2436' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/218' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP826'>Ath116*</a></li> 
<li :class="{'activeClass': idClicked == IGII2223 }" id="IGII2223"><inscr id='IG II² 223'>IG II² 223 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2441' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/223' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a> </li> 
<li :class="{'activeClass': idClicked == IGII2226 }" id="IGII2226"><inscr id='IG II² 226'>IG II² 226 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2444' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/226' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP553'>Arybbas</a> </li> 
<li :class="{'activeClass': idClicked == IGII2312 }" id="IGII2312"><inscr id='IG II² 312'>IG II² 312 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2529' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/312' target='_blank'>AOI</a>] </inscr> - Ship</li> 
<li :class="{'activeClass': idClicked == IGII2333 }" id="IGII2333"><inscr id='IG II² 333'>IG II² 333 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2550' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/333' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP28'>Agathe Tyche</a></li> 
<li :class="{'activeClass': idClicked == IGII2334 }" id="IGII2334"><inscr id='IG II² 334'>IG II² 334 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2551' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/334' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP639'>Ath2*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP773'>Ath77*</a> </li> 
<li :class="{'activeClass': idClicked == IGII2349 }" id="IGII2349"><inscr id='IG II² 349'>IG II² 349 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2566' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/349' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP849'>Ath132*</a></li> 
<li :class="{'activeClass': idClicked == IGII2351 }" id="IGII2351"><inscr id='IG II² 351'>IG II² 351 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2568' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/351' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2917'>Panathenaic Stadium</a></li> 
<li :class="{'activeClass': idClicked == IGII2403 }" id="IGII2403"><inscr id='IG II² 403'>IG II² 403 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2621' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/403' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP785'>Ath88*</a></li> 
<li :class="{'activeClass': idClicked == IGII2417 }" id="IGII2417"><inscr id='IG II² 417'>IG II² 417 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2635' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/417' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1743'>Eutaxia</a> </li> 
<li :class="{'activeClass': idClicked == IGII2448 }" id="IGII2448"><inscr id='IG II² 448'>IG II² 448 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2666' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/448' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP830'>Ath117*</a></li> 
<li :class="{'activeClass': idClicked == IGII2450 }" id="IGII2450"><inscr id='IG II² 450'>IG II² 450 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2668' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/450' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP554'>Asander</a> </li> 
<li :class="{'activeClass': idClicked == IGII2463 }" id="IGII2463"><inscr id='IG II² 463'>IG II² 463 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2681' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1371'>Diateichisma</a>, Dipylon above the Gates, Hippades Gate, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a> </li> 
<li :class="{'activeClass': idClicked == IGII2478 }" id="IGII2478"><inscr id='IG II² 478'>IG II² 478 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2696' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1603'>Ephebes</a></li> 
<li :class="{'activeClass': idClicked == IGII2500 }" id="IGII2500"><inscr id='IG II² 500'>IG II² 500 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2718' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3473'>Strategeion</a></li> 
<li :class="{'activeClass': idClicked == IGII2646 }" id="IGII2646"><inscr id='IG II² 646'>IG II² 646 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2865' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/646' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2087'>Herodoros</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGII2648 }" id="IGII2648"><inscr id='IG II² 648'>IG II² 648 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2867' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/648' target='_blank'>AOI</a>] </inscr> - Diodoros of Sinope</li> 
<li :class="{'activeClass': idClicked == IGII2649 }" id="IGII2649"><inscr id='IG II² 649'>IG II² 649 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2868' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/649' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3083'>Philippides</a></li> 
<li :class="{'activeClass': idClicked == IGII2653 }" id="IGII2653"><inscr id='IG II² 653'>IG II² 653 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2872' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/653' target='_blank'>AOI</a>] </inscr> - Pairisades <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3446'>Spartokos III</a> </li> 
<li :class="{'activeClass': idClicked == IGII2654 }" id="IGII2654"><inscr id='IG II² 654'>IG II² 654 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2873' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/654' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP885'>Audoleon</a></li> 
<li :class="{'activeClass': idClicked == IGII2657 }" id="IGII2657"><inscr id='IG II² 657'>IG II² 657 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2876' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/657' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3083'>Philippides</a></li> 
<li :class="{'activeClass': idClicked == IGII2659 }" id="IGII2659"><inscr id='IG II² 659'>IG II² 659 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2878' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/659' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP297'>Aph45*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP299'>Aph46*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP300'>Aph47*</a></li> 
<li :class="{'activeClass': idClicked == IGII2677 }" id="IGII2677"><inscr id='IG II² 677'>IG II² 677 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2896' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/677' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP786'>Ath89*</a></li> 
<li :class="{'activeClass': idClicked == IGII2682 }" id="IGII2682"><inscr id='IG II² 682'>IG II² 682 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/2901' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/682' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == IGII2788 }" id="IGII2788"><inscr id='IG II² 788'>IG II² 788 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3006' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/788' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2325'>Kalliste</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGII2789 }" id="IGII2789"><inscr id='IG II² 789'>IG II² 789 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/789' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2325'>Kalliste</a></li> 
<li :class="{'activeClass': idClicked == IGII2839 }" id="IGII2839"><inscr id='IG II² 839'>IG II² 839 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3058' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/839' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2108'>Hro14*</a></li> 
<li :class="{'activeClass': idClicked == IGII2840 }" id="IGII2840"><inscr id='IG II² 840'>IG II² 840 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3059' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2106'>Hro13*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2109'>Hro15*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2116'>Hro22*</a></li> 
<li :class="{'activeClass': idClicked == IGII2844 }" id="IGII2844"><inscr id='IG II² 844'>IG II² 844 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3063' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/844' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1272'>Demos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1007'>Charites</a>, Eumaridas </li> 
<li :class="{'activeClass': idClicked == IGII2895 }" id="IGII2895"><inscr id='IG II² 895'>IG II² 895 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/895' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2175'>Hipparcheion</a> </li> 
<li :class="{'activeClass': idClicked == IGII2902 }" id="IGII2902"><inscr id='IG II² 902'>IG II² 902 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3122' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/902' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP543'>Art44*</a></li> 
<li :class="{'activeClass': idClicked == IGII2937 }" id="IGII2937"><inscr id='IG II² 937'>IG II² 937</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a> VII <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2637'>Menodoros</a> </li> 
<li :class="{'activeClass': idClicked == IGII2956 }" id="IGII2956"><inscr id='IG II² 956'>IG II² 956 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3176' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3670'>Timeas</a></li> 
<li :class="{'activeClass': idClicked == IGII2958 }" id="IGII2958"><inscr id='IG II² 958'>IG II² 958 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3178' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP206'>Antigenes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3466'>Stoa of the Roman</a> </li> 
<li :class="{'activeClass': idClicked == IGII2971 }" id="IGII2971"><inscr id='IG II² 971'>IG II² 971 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3191' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a></li> 
<li :class="{'activeClass': idClicked == IGII2974 }" id="IGII2974"><inscr id='IG II² 974'>IG II² 974 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3194' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/974' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2071'>Heroa</a></li> 
<li :class="{'activeClass': idClicked == IGII2983 }" id="IGII2983"><inscr id='IG II² 983'>IG II² 983</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3266'>Ptolemaios</a></li> 
<li :class="{'activeClass': idClicked == IGII21006 }" id="IGII21006"><inscr id='IG II² 1006'>IG II² 1006 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3226' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1417'>Dio5*</a></li> 
<li :class="{'activeClass': idClicked == IGII21008 }" id="IGII21008"><inscr id='IG II² 1008'>IG II² 1008 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3228' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP83'>Aias of Salamis</a></li> 
<li :class="{'activeClass': idClicked == IGII21009 }" id="IGII21009"><inscr id='IG II² 1009'>IG II² 1009 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3229' target='_blank'>PHI</a>] </inscr> - Ptolemy III </li> 
<li :class="{'activeClass': idClicked == IGII21011 }" id="IGII21011"><inscr id='IG II² 1011'>IG II² 1011 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3231' target='_blank'>PHI</a>] </inscr> - Diogenes Euergete </li> 
<li :class="{'activeClass': idClicked == IGII21013 }" id="IGII21013"><inscr id='IG II² 1013'>IG II² 1013 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3233' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP459'>Argyrokopeion</a></li> 
<li :class="{'activeClass': idClicked == IGII21029 }" id="IGII21029"><inscr id='IG II² 1029'>IG II² 1029 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3249' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3268'>Ptolemies</a> </li> 
<li :class="{'activeClass': idClicked == IGII21035 }" id="IGII21035"><inscr id='IG II² 1035'>IG II² 1035 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3255' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP189'>Andronikos Kyrrhestes</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP815'>Ath110*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> & II, Eukleia and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1728'>Eunomia</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2210'>Hyakinthides</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a>, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM87">87</a> </li> 
<li :class="{'activeClass': idClicked == IGII21039 }" id="IGII21039"><inscr id='IG II² 1039'>IG II² 1039 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3259' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1849'>Hedylos</a> </li> 
<li :class="{'activeClass': idClicked == IGII21043 }" id="IGII21043"><inscr id='IG II² 1043'>IG II² 1043 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3263' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP236'>Antonius</a> </li> 
<li :class="{'activeClass': idClicked == IGII21046 }" id="IGII21046"><inscr id='IG II² 1046'>IG II² 1046</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP569'>Ask11*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP598'>Ask32*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP599'>Ask33*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP600'>Ask34*</a> </li> 
<li :class="{'activeClass': idClicked == IGII21064 }" id="IGII21064"><inscr id='IG II² 1064'>IG II² 1064</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3488'>Synhedrion</a>, Ulpius Eubiotos </li> 
<li :class="{'activeClass': idClicked == IGII21070 }" id="IGII21070"><inscr id='IG II² 1070'>IG II² 1070 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3289' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3513'>Theages</a> </li> 
<li :class="{'activeClass': idClicked == IGII21072 }" id="IGII21072"><inscr id='IG II² 1072'>IG II² 1072 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3291' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP240'>Antonius Oxylos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP446'>Ares</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1597'>Enyalios</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1598'>Enyo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3823'>Zeu29*</a> </li> 
<li :class="{'activeClass': idClicked == IGII21073 }" id="IGII21073"><inscr id='IG II² 1073'>IG II² 1073 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3292' target='_blank'>PHI</a>] </inscr>-1074 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a> </li> 
<li :class="{'activeClass': idClicked == IGII21076 }" id="IGII21076"><inscr id='IG II² 1076'>IG II² 1076 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3295' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1628'>Erechtheion</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2307'>Julia Domna</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1514'>Divi Augusti</a> </li> 
<li :class="{'activeClass': idClicked == IGII21081 }" id="IGII21081"><inscr id='IG II² 1081'>IG II² 1081 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3300' target='_blank'>PHI</a>] </inscr>-1085 - Fulvius </li> 
<li :class="{'activeClass': idClicked == IGII21096 }" id="IGII21096"><inscr id='IG II² 1096'>IG II² 1096 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3311' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1769'>Gephyraioi</a> </li> 
<li :class="{'activeClass': idClicked == IGII21100 }" id="IGII21100"><inscr id='IG II² 1100'>IG II² 1100 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3315' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a> </li> 
<li :class="{'activeClass': idClicked == IGII21133 }" id="IGII21133"><inscr id='IG II² 1133'>IG II² 1133</inscr> - Amphiktyonic Decree </li> 
<li :class="{'activeClass': idClicked == IGII21144 }" id="IGII21144"><inscr id='IG II² 1144'>IG II² 1144</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2943'>Pandion</a> </li> 
<li :class="{'activeClass': idClicked == IGII21172 }" id="IGII21172"><inscr id='IG II² 1172'>IG II² 1172 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3387' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/1172' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2942'>Pandia</a> </li> 
<li :class="{'activeClass': idClicked == IGII21231 }" id="IGII21231"><inscr id='IG II² 1231'>IG II² 1231</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1566'>Eleusinian Mysteries</a> </li> 
<li :class="{'activeClass': idClicked == IGII21236 }" id="IGII21236"><inscr id='IG II² 1236'>IG II² 1236 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3451' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3089'>Philonides</a> </li> 
<li :class="{'activeClass': idClicked == IGII21247 }" id="IGII21247"><inscr id='IG II² 1247'>IG II² 1247 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3462' target='_blank'>PHI</a>] </inscr> - Diomos </li> 
<li :class="{'activeClass': idClicked == IGII21252 }" id="IGII21252"><inscr id='IG II² 1252'>IG II² 1252 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3467' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP169'>Amynos</a> (2) <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3435'>Sophokles</a></li> 
<li :class="{'activeClass': idClicked == IGII21277 }" id="IGII21277"><inscr id='IG II² 1277'>IG II² 1277 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3491' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM257">257</a></li> 
<li :class="{'activeClass': idClicked == IGII21283 }" id="IGII21283"><inscr id='IG II² 1283'>IG II² 1283 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3497' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/1283' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP917'>Bendis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII21294 }" id="IGII21294"><inscr id='IG II² 1294'>IG II² 1294</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3820'>Zeu26*</a></li> 
<li :class="{'activeClass': idClicked == IGII21298 }" id="IGII21298"><inscr id='IG II² 1298'>IG II² 1298 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3512' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP497'>Art6*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP498'>Art7*</a></li> 
<li :class="{'activeClass': idClicked == IGII21320 }" id="IGII21320"><inscr id='IG II² 1320'>IG II² 1320</inscr> - Poseidippeion</li> 
<li :class="{'activeClass': idClicked == IGII21330 }" id="IGII21330"><inscr id='IG II² 1330'>IG II² 1330 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3546' target='_blank'>PHI</a>] </inscr> - Ariarathes (2) <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1482'>Dio47*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1483'>Dio48*</a></li> 
<li :class="{'activeClass': idClicked == IGII21341 }" id="IGII21341"><inscr id='IG II² 1341'>IG II² 1341</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP419'>Apo88*</a></li> 
<li :class="{'activeClass': idClicked == IGII21343 }" id="IGII21343"><inscr id='IG II² 1343'>IG II² 1343 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3559' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP548'>Art48*</a></li> 
<li :class="{'activeClass': idClicked == IGII21357 }" id="IGII21357"><inscr id='IG II² 1357'>IG II² 1357</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2023'>Hrm23*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3460'>Stoa Basileios</a></li> 
<li :class="{'activeClass': idClicked == IGII21367 }" id="IGII21367"><inscr id='IG II² 1367'>IG II² 1367 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3583' target='_blank'>PHI</a>] </inscr> - Nephthys and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2862'>Osiris</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3199'>Poseidon Chamaizelos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3824'>Zeu30*</a></li> 
<li :class="{'activeClass': idClicked == IGII21368 }" id="IGII21368"><inscr id='IG II² 1368'>IG II² 1368 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3584' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP900'>Bakcheion</a> (4)</li> 
<li :class="{'activeClass': idClicked == IGII21388 }" id="IGII21388"><inscr id='IG II² 1388'>IG II² 1388 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3604' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2879'>Palladia</a></li> 
<li :class="{'activeClass': idClicked == IGII21424A }" id="IGII21424A">IG II² 1424A - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP809'>Ath103*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP998'>Chalkotheke</a></li> 
<li :class="{'activeClass': idClicked == IGII21425 }" id="IGII21425"><inscr id='IG II² 1425'>IG II² 1425 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3642' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP781'>Ath85*</a></li> 
<li :class="{'activeClass': idClicked == IGII21438 }" id="IGII21438"><inscr id='IG II² 1438'>IG II² 1438</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP998'>Chalkotheke</a></li> 
<li :class="{'activeClass': idClicked == IGII21462 }" id="IGII21462"><inscr id='IG II² 1462'>IG II² 1462 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3679' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3024'>Peplotheke</a></li> 
<li :class="{'activeClass': idClicked == IGII21471 }" id="IGII21471"><inscr id='IG II² 1471'>IG II² 1471</inscr> - Nikokrates</li> 
<li :class="{'activeClass': idClicked == IGII21487 }" id="IGII21487"><inscr id='IG II² 1487'>IG II² 1487 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3704' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2253'>Iphikrates</a></li> 
<li :class="{'activeClass': idClicked == IGII21489 }" id="IGII21489"><inscr id='IG II² 1489'>IG II² 1489</inscr> - owl</li> 
<li :class="{'activeClass': idClicked == IGII21492 }" id="IGII21492"><inscr id='IG II² 1492'>IG II² 1492 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3709' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP811'>Ath105*</a></li> 
<li :class="{'activeClass': idClicked == IGII21496 }" id="IGII21496"><inscr id='IG II² 1496'>IG II² 1496 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3713' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP158'>Ammon</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2022'>Hrm22*</a></li> 
<li :class="{'activeClass': idClicked == IGII21498 }" id="IGII21498"><inscr id='IG II² 1498'>IG II² 1498</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2879'>Palladia</a></li> 
<li :class="{'activeClass': idClicked == IGII21514 }" id="IGII21514"><inscr id='IG II² 1514'>IG II² 1514 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3731' target='_blank'>PHI</a>] </inscr>-1531 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP519'>Art27**</a></li> 
<li :class="{'activeClass': idClicked == IGII21533 }" id="IGII21533"><inscr id='IG II² 1533'>IG II² 1533 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3750' target='_blank'>PHI</a>] </inscr> - Polykritos</li> 
<li :class="{'activeClass': idClicked == IGII21534 }" id="IGII21534"><inscr id='IG II² 1534'>IG II² 1534 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3751' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP570'>Ask12*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP577'>Ask17*</a></li> 
<li :class="{'activeClass': idClicked == IGII21590 }" id="IGII21590"><inscr id='IG II² 1590'>IG II² 1590 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3808' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2461'>Kydathenaion</a></li> 
<li :class="{'activeClass': idClicked == IGII21651 }" id="IGII21651"><inscr id='IG II² 1651'>IG II² 1651</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1621'>Epistasion</a></li> 
<li :class="{'activeClass': idClicked == IGII21654 }" id="IGII21654"><inscr id='IG II² 1654'>IG II² 1654 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/1654-fr-a-fr-b' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1628'>Erechtheion</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM57">57</a></li> 
<li :class="{'activeClass': idClicked == IGII21665 }" id="IGII21665"><inscr id='IG II² 1665'>IG II² 1665</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2469'>Kynosarges</a></li> 
<li :class="{'activeClass': idClicked == IGII21672 }" id="IGII21672"><inscr id='IG II² 1672'>IG II² 1672 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3897' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a> Part II nos. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM252">252</a>, <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM462">462</a> </li> 
<li :class="{'activeClass': idClicked == IGII21685 }" id="IGII21685"><inscr id='IG II² 1685'>IG II² 1685 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3911' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP590'>Ask28*</a></li> 
<li :class="{'activeClass': idClicked == IGII21742 }" id="IGII21742"><inscr id='IG II² 1742'>IG II² 1742 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3969' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2503'>Leos</a></li> 
<li :class="{'activeClass': idClicked == IGII21748 }" id="IGII21748"><inscr id='IG II² 1748'>IG II² 1748</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2461'>Kydathenaion</a> </li> 
<li :class="{'activeClass': idClicked == IGII21930 }" id="IGII21930"><inscr id='IG II² 1930'>IG II² 1930</inscr> - Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1029'>CM9*</a></li> 
<li :class="{'activeClass': idClicked == IGII21934 }" id="IGII21934"><inscr id='IG II² 1934'>IG II² 1934</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a> </li> 
<li :class="{'activeClass': idClicked == IGII21945 }" id="IGII21945"><inscr id='IG II² 1945'>IG II² 1945 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4180' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP371'>Apo49*</a></li> 
<li :class="{'activeClass': idClicked == IGII21948 }" id="IGII21948"><inscr id='IG II² 1948'>IG II² 1948</inscr> - Sarapion Mon.</li> 
<li :class="{'activeClass': idClicked == IGII21950 }" id="IGII21950"><inscr id='IG II² 1950'>IG II² 1950</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1715'>Eukolos</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2265'>Isis Taposeirias</a></li> 
<li :class="{'activeClass': idClicked == IGII21960A }" id="IGII21960A"><inscr id='IG II² 1960A'>IG II² 1960A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3174'>Pompeion</a> </li> 
<li :class="{'activeClass': idClicked == IGII21972 }" id="IGII21972"><inscr id='IG II² 1972'>IG II² 1972</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP356'>Apo38*</a></li> 
<li :class="{'activeClass': idClicked == IGII21987 }" id="IGII21987"><inscr id='IG II² 1987'>IG II² 1987</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3932'>Zotikos</a> et al.</li> 
<li :class="{'activeClass': idClicked == IGII22245 }" id="IGII22245"><inscr id='IG II² 2245'>IG II² 2245 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4480' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1389'>Diogenes Euergetes</a></li> 
<li :class="{'activeClass': idClicked == IGII22292 }" id="IGII22292"><inscr id='IG II² 2292'>IG II² 2292</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM18">18</a></li> 
<li :class="{'activeClass': idClicked == IGII22304 }" id="IGII22304"><inscr id='IG II² 2304'>IG II² 2304</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3284'>Pyloroi</a></li> 
<li :class="{'activeClass': idClicked == IGII22319 }" id="IGII22319"><inscr id='IG II² 2319'>IG II² 2319</inscr>-2323 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1468'>Dio36*</a></li> 
<li :class="{'activeClass': idClicked == IGII22325 }" id="IGII22325"><inscr id='IG II² 2325'>IG II² 2325 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4561' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1468'>Dio36*</a></li> 
<li :class="{'activeClass': idClicked == IGII22326 }" id="IGII22326"><inscr id='IG II² 2326'>IG II² 2326</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2840'>Olympicum Chronicon</a></li> 
<li :class="{'activeClass': idClicked == IGII22331 }" id="IGII22331"><inscr id='IG II² 2331'>IG II² 2331</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3520'>Themistoklean City Wall</a></li> 
<li :class="{'activeClass': idClicked == IGII22340 }" id="IGII22340"><inscr id='IG II² 2340'>IG II² 2340</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2387'>Kerykes</a></li> 
<li :class="{'activeClass': idClicked == IGII22343 }" id="IGII22343"><inscr id='IG II² 2343'>IG II² 2343 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4579' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1924'>Hrk5*</a></li> 
<li :class="{'activeClass': idClicked == IGII22362 }" id="IGII22362"><inscr id='IG II² 2362'>IG II² 2362</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP200'>Ankyle</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP913'>Bate</a></li> 
<li :class="{'activeClass': idClicked == IGII22491 }" id="IGII22491"><inscr id='IG II² 2491'>IG II² 2491</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM618">618</a></li> 
<li :class="{'activeClass': idClicked == IGII22495 }" id="IGII22495"><inscr id='IG II² 2495'>IG II² 2495</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP663'>Ath16*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1382'>Diochares</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGII22499 }" id="IGII22499"><inscr id='IG II² 2499'>IG II² 2499 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4740' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1536'>Egretes Heros</a></li> 
<li :class="{'activeClass': idClicked == IGII22501 }" id="IGII22501"><inscr id='IG II² 2501'>IG II² 2501 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/4742' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2228'>Hypodektes</a></li> 
<li :class="{'activeClass': idClicked == IGII22502 }" id="IGII22502"><inscr id='IG II² 2502'>IG II² 2502</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM618">618</a></li> 
<li :class="{'activeClass': idClicked == IGII22507 }" id="IGII22507"><inscr id='IG II² 2507'>IG II² 2507</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM235">235</a></li> 
<li :class="{'activeClass': idClicked == IGII22521 }" id="IGII22521"><inscr id='IG II² 2521'>IG II² 2521</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM493">493</a></li> 
<li :class="{'activeClass': idClicked == IGII22599 }" id="IGII22599"><inscr id='IG II² 2599'>IG II² 2599</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM320">320</a></li> 
<li :class="{'activeClass': idClicked == IGII22603 }" id="IGII22603"><inscr id='IG II² 2603'>IG II² 2603</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2714'>Met21*</a></li> 
<li :class="{'activeClass': idClicked == IGII22607 }" id="IGII22607"><inscr id='IG II² 2607'>IG II² 2607</inscr>-2608 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP643'>Ath5*</a></li> 
<li :class="{'activeClass': idClicked == IGII22613 }" id="IGII22613"><inscr id='IG II² 2613'>IG II² 2613</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2728'>Muses</a></li> 
<li :class="{'activeClass': idClicked == IGII22614 }" id="IGII22614"><inscr id='IG II² 2614'>IG II² 2614</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2728'>Muses</a></li> 
<li :class="{'activeClass': idClicked == IGII22615 }" id="IGII22615"><inscr id='IG II² 2615'>IG II² 2615</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3711'>Tritopateres</a></li> 
<li :class="{'activeClass': idClicked == IGII22617 }" id="IGII22617"><inscr id='IG II² 2617'>IG II² 2617</inscr>-2619 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2366'>Kerameikos Boundary Stones</a></li> 
<li :class="{'activeClass': idClicked == IGII22620 }" id="IGII22620"><inscr id='IG II² 2620'>IG II² 2620</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2507'>Leschai</a></li> 
<li :class="{'activeClass': idClicked == IGII22624 }" id="IGII22624"><inscr id='IG II² 2624'>IG II² 2624</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3332'>Sacred Way</a></li> 
<li :class="{'activeClass': idClicked == IGII22630 }" id="IGII22630"><inscr id='IG II² 2630'>IG II² 2630</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM617">617</a></li> 
<li :class="{'activeClass': idClicked == IGII22639 }" id="IGII22639"><inscr id='IG II² 2639'>IG II² 2639</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3034'>Peripatos</a></li> 
<li :class="{'activeClass': idClicked == IGII22640 }" id="IGII22640"><inscr id='IG II² 2640'>IG II² 2640</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM595">595</a></li> 
<li :class="{'activeClass': idClicked == IGII22729 }" id="IGII22729"><inscr id='IG II² 2729'>IG II² 2729</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP475'>Aristodemos</a></li> 
<li :class="{'activeClass': idClicked == IGII22776 }" id="IGII22776"><inscr id='IG II² 2776'>IG II² 2776 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5028' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP14'>Acharnian Gate</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP102'>Akademia</a>, Keionian Garden, Myrmex</li> 
<li :class="{'activeClass': idClicked == IGII22782 }" id="IGII22782"><inscr id='IG II² 2782'>IG II² 2782</inscr> - Calender</li> 
<li :class="{'activeClass': idClicked == IGII22789 }" id="IGII22789"><inscr id='IG II² 2789'>IG II² 2789 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/2789' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP332'>Apo17*</a></li> 
<li :class="{'activeClass': idClicked == IGII22791 }" id="IGII22791"><inscr id='IG II² 2791'>IG II² 2791 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5043' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/2791' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == IGII22794 }" id="IGII22794"><inscr id='IG II² 2794'>IG II² 2794 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5046' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3060'>Phanostratos</a></li> 
<li :class="{'activeClass': idClicked == IGII22798 }" id="IGII22798"><inscr id='IG II² 2798'>IG II² 2798 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/2798' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP274'>Aph29*</a></li> 
<li :class="{'activeClass': idClicked == IGII22802 }" id="IGII22802"><inscr id='IG II² 2802'>IG II² 2802</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP816'>Ath111*</a></li> 
<li :class="{'activeClass': idClicked == IGII22820 }" id="IGII22820"><inscr id='IG II² 2820'>IG II² 2820 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/2820' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP250'>Aph7*</a></li> 
<li :class="{'activeClass': idClicked == IGII22865 }" id="IGII22865"><inscr id='IG II² 2865'>IG II² 2865 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5122' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a></li> 
<li :class="{'activeClass': idClicked == IGII22875 }" id="IGII22875"><inscr id='IG II² 2875'>IG II² 2875 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5132' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP320'>Apo9*</a></li> 
<li :class="{'activeClass': idClicked == IGII22884 }" id="IGII22884"><inscr id='IG II² 2884'>IG II² 2884</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3802'>Zeu11*</a></li> 
<li :class="{'activeClass': idClicked == IGII22891 }" id="IGII22891"><inscr id='IG II² 2891'>IG II² 2891</inscr>-2930 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP313'>Apo2*</a></li> 
<li :class="{'activeClass': idClicked == IGII22891 }" id="IGII22891"><inscr id='IG II² 2891'>IG II² 2891</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP318'>Apo7*</a></li> 
<li :class="{'activeClass': idClicked == IGII22893 }" id="IGII22893"><inscr id='IG II² 2893'>IG II² 2893 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5150' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP317'>Apo6*</a></li> 
<li :class="{'activeClass': idClicked == IGII22914 }" id="IGII22914"><inscr id='IG II² 2914'>IG II² 2914 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5171' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP316'>Apo5*</a></li> 
<li :class="{'activeClass': idClicked == IGII22931 }" id="IGII22931"><inscr id='IG II² 2931'>IG II² 2931</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1361'>Dexippos I</a></li> 
<li :class="{'activeClass': idClicked == IGII22934 }" id="IGII22934"><inscr id='IG II² 2934'>IG II² 2934</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2811'>Nym11*</a></li> 
<li :class="{'activeClass': idClicked == IGII22935 }" id="IGII22935"><inscr id='IG II² 2935'>IG II² 2935</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3900'>Zeu90*</a></li> 
<li :class="{'activeClass': idClicked == IGII22949 }" id="IGII22949"><inscr id='IG II² 2949'>IG II² 2949 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5207' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1445'>Dio20*</a></li> 
<li :class="{'activeClass': idClicked == IGII22970 }" id="IGII22970"><inscr id='IG II² 2970'>IG II² 2970</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP854'>Ath135*</a></li> 
<li :class="{'activeClass': idClicked == IGII22979 }" id="IGII22979"><inscr id='IG II² 2979'>IG II² 2979</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1489'>Dio53*</a></li> 
<li :class="{'activeClass': idClicked == IGII22988 }" id="IGII22988"><inscr id='IG II² 2988'>IG II² 2988</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2065'>Her14*</a></li> 
<li :class="{'activeClass': idClicked == IGII22990 }" id="IGII22990"><inscr id='IG II² 2990'>IG II² 2990</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2043'>Hrm38*</a></li> 
<li :class="{'activeClass': idClicked == IGII22997 }" id="IGII22997"><inscr id='IG II² 2997'>IG II² 2997</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2001'>Hrm3*</a></li> 
<li :class="{'activeClass': idClicked == IGII23002 }" id="IGII23002"><inscr id='IG II² 3002'>IG II² 3002</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP321'>Apo10*</a></li> 
<li :class="{'activeClass': idClicked == IGII23003 }" id="IGII23003"><inscr id='IG II² 3003'>IG II² 3003</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP409'>Apo79*</a></li> 
<li :class="{'activeClass': idClicked == IGII23012 }" id="IGII23012"><inscr id='IG II² 3012'>IG II² 3012</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1959'>Hrk30*</a></li> 
<li :class="{'activeClass': idClicked == IGII23023 }" id="IGII23023"><inscr id='IG II² 3023'>IG II² 3023</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2019'>Hrm19*</a></li> 
<li :class="{'activeClass': idClicked == IGII23027 }" id="IGII23027"><inscr id='IG II² 3027'>IG II² 3027 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5290' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP471'>Aristeides</a></li> 
<li :class="{'activeClass': idClicked == IGII23042 }" id="IGII23042"><inscr id='IG II² 3042'>IG II² 3042 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5305' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3042' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2574'>Lysikrates</a></li> 
<li :class="{'activeClass': idClicked == IGII23043 }" id="IGII23043"><inscr id='IG II² 3043'>IG II² 3043 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5306' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP98'>Aischraios</a></li> 
<li :class="{'activeClass': idClicked == IGII23055 }" id="IGII23055"><inscr id='IG II² 3055'>IG II² 3055 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5318' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2778'>Nikias</a></li> 
<li :class="{'activeClass': idClicked == IGII23056 }" id="IGII23056"><inscr id='IG II² 3056'>IG II² 3056 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5319' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3652'>Thrasyllos</a> Mon.</li> 
<li :class="{'activeClass': idClicked == IGII23065 }" id="IGII23065"><inscr id='IG II² 3065'>IG II² 3065 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5328' target='_blank'>PHI</a>] </inscr>-3067 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP400'>Apo73*</a></li> 
<li :class="{'activeClass': idClicked == IGII23073 }" id="IGII23073"><inscr id='IG II² 3073'>IG II² 3073 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5336' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3774'>Xenokles</a></li> 
<li :class="{'activeClass': idClicked == IGII23077 }" id="IGII23077"><inscr id='IG II² 3077'>IG II² 3077 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5340' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3774'>Xenokles</a></li> 
<li :class="{'activeClass': idClicked == IGII23079 }" id="IGII23079"><inscr id='IG II² 3079'>IG II² 3079 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5342' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1774'>Glaukon</a></li> 
<li :class="{'activeClass': idClicked == IGII23083 }" id="IGII23083"><inscr id='IG II² 3083'>IG II² 3083 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5346' target='_blank'>PHI</a>] </inscr> - Thrasylllos Mon.</li> 
<li :class="{'activeClass': idClicked == IGII23089 }" id="IGII23089"><inscr id='IG II² 3089'>IG II² 3089</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1437'>Dio15*</a></li> 
<li :class="{'activeClass': idClicked == IGII23093 }" id="IGII23093"><inscr id='IG II² 3093'>IG II² 3093</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3503'>Telephanes</a></li> 
<li :class="{'activeClass': idClicked == IGII23120 }" id="IGII23120"><inscr id='IG II² 3120'>IG II² 3120 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5383' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP594'>Ask31*</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM138">138</a></li> 
<li :class="{'activeClass': idClicked == IGII23123 }" id="IGII23123"><inscr id='IG II² 3123'>IG II² 3123</inscr> - Chariot</li> 
<li :class="{'activeClass': idClicked == IGII23130 }" id="IGII23130"><inscr id='IG II² 3130'>IG II² 3130</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP956'>Bryaxis Base</a></li> 
<li :class="{'activeClass': idClicked == IGII23147 }" id="IGII23147"><inscr id='IG II² 3147'>IG II² 3147</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2637'>Menodoros</a></li> 
<li :class="{'activeClass': idClicked == IGII23156 }" id="IGII23156"><inscr id='IG II² 3156'>IG II² 3156</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3307'>Rhoimetalkes III</a></li> 
<li :class="{'activeClass': idClicked == IGII23167 }" id="IGII23167"><inscr id='IG II² 3167'>IG II² 3167</inscr> - Peisonianos</li> 
<li :class="{'activeClass': idClicked == IGII23168 }" id="IGII23168"><inscr id='IG II² 3168'>IG II² 3168</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM136">136</a></li> 
<li :class="{'activeClass': idClicked == IGII23171 }" id="IGII23171"><inscr id='IG II² 3171'>IG II² 3171</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> and II</li> 
<li :class="{'activeClass': idClicked == IGII23173 }" id="IGII23173"><inscr id='IG II² 3173'>IG II² 3173 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5438' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3173' target='_blank'>AOI</a>] </inscr> - Roma and Augustu</li> 
<li :class="{'activeClass': idClicked == IGII23174 }" id="IGII23174"><inscr id='IG II² 3174'>IG II² 3174 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5439' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP611'>Ask42*</a></li> 
<li :class="{'activeClass': idClicked == IGII23175 }" id="IGII23175"><inscr id='IG II² 3175'>IG II² 3175 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5440' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3175' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a></li> 
<li :class="{'activeClass': idClicked == IGII23177 }" id="IGII23177"><inscr id='IG II² 3177'>IG II² 3177</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2879'>Palladia</a></li> 
<li :class="{'activeClass': idClicked == IGII23179 }" id="IGII23179"><inscr id='IG II² 3179'>IG II² 3179 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5444' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3179' target='_blank'>AOI</a>] </inscr> - Roma and Augustu</li> 
<li :class="{'activeClass': idClicked == IGII23180 }" id="IGII23180"><inscr id='IG II² 3180'>IG II² 3180</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP742'>Ath55*</a></li> 
<li :class="{'activeClass': idClicked == IGII23181 }" id="IGII23181"><inscr id='IG II² 3181'>IG II² 3181 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5446' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP566'>Ask8**</a></li> 
<li :class="{'activeClass': idClicked == IGII23182 }" id="IGII23182"><inscr id='IG II² 3182'>IG II² 3182 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5447' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1469'>Dio37*</a></li> 
<li :class="{'activeClass': idClicked == IGII23183 }" id="IGII23183"><inscr id='IG II² 3183'>IG II² 3183 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5448' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP742'>Ath55*</a></li> 
<li :class="{'activeClass': idClicked == IGII23185 }" id="IGII23185"><inscr id='IG II² 3185'>IG II² 3185 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5450' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3185' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII23193 }" id="IGII23193"><inscr id='IG II² 3193'>IG II² 3193</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP812'>Ath106*</a></li> 
<li :class="{'activeClass': idClicked == IGII23194 }" id="IGII23194"><inscr id='IG II² 3194'>IG II² 3194</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP711'>Ath33*</a></li> 
<li :class="{'activeClass': idClicked == IGII23197 }" id="IGII23197"><inscr id='IG II² 3197'>IG II² 3197</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP587'>Ask26*</a></li> 
<li :class="{'activeClass': idClicked == IGII23198 }" id="IGII23198"><inscr id='IG II² 3198'>IG II² 3198</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1370'>Dexippos</a></li> 
<li :class="{'activeClass': idClicked == IGII23206 }" id="IGII23206"><inscr id='IG II² 3206'>IG II² 3206 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5473' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1649'>Eridanos River</a></li> 
<li :class="{'activeClass': idClicked == IGII23211 }" id="IGII23211"><inscr id='IG II² 3211'>IG II² 3211</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3777'>Xenokrates</a></li> 
<li :class="{'activeClass': idClicked == IGII23222 }" id="IGII23222"><inscr id='IG II² 3222'>IG II² 3222</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a></li> 
<li :class="{'activeClass': idClicked == IGII23224 }" id="IGII23224"><inscr id='IG II² 3224'>IG II² 3224</inscr>-3225 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3501'>Telekles</a></li> 
<li :class="{'activeClass': idClicked == IGII23228 }" id="IGII23228"><inscr id='IG II² 3228'>IG II² 3228 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5495' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII23229 }" id="IGII23229"><inscr id='IG II² 3229'>IG II² 3229 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5497' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII23238 }" id="IGII23238"><inscr id='IG II² 3238'>IG II² 3238 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5506' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2541'>Livia</a></li> 
<li :class="{'activeClass': idClicked == IGII23240 }" id="IGII23240"><inscr id='IG II² 3240'>IG II² 3240 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5508' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2541'>Livia</a></li> 
<li :class="{'activeClass': idClicked == IGII23243 }" id="IGII23243"><inscr id='IG II² 3243'>IG II² 3243</inscr>-3247 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3667'>Tiberius</a></li> 
<li :class="{'activeClass': idClicked == IGII23250 }" id="IGII23250"><inscr id='IG II² 3250'>IG II² 3250 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5518' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a>,Gaius</li> 
<li :class="{'activeClass': idClicked == IGII23251 }" id="IGII23251"><inscr id='IG II² 3251'>IG II² 3251 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5519' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3318'>Roman Agora</a></li> 
<li :class="{'activeClass': idClicked == IGII23252 }" id="IGII23252"><inscr id='IG II² 3252'>IG II² 3252</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP968'>Caesar</a>, L.</li> 
<li :class="{'activeClass': idClicked == IGII23253 }" id="IGII23253"><inscr id='IG II² 3253'>IG II² 3253 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5522' target='_blank'>PHI</a>] </inscr>-3256 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2934'>Pandaites</a></li> 
<li :class="{'activeClass': idClicked == IGII23257 }" id="IGII23257"><inscr id='IG II² 3257'>IG II² 3257</inscr> - Drusus</li> 
<li :class="{'activeClass': idClicked == IGII23260 }" id="IGII23260"><inscr id='IG II² 3260'>IG II² 3260 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5529' target='_blank'>PHI</a>] </inscr> - Hipparchs' Dedication</li> 
<li :class="{'activeClass': idClicked == IGII23262 }" id="IGII23262"><inscr id='IG II² 3262'>IG II² 3262 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5531' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII23265 }" id="IGII23265"><inscr id='IG II² 3265'>IG II² 3265</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3667'>Tiberius</a></li> 
<li :class="{'activeClass': idClicked == IGII23266 }" id="IGII23266"><inscr id='IG II² 3266'>IG II² 3266 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5535' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3769'>Winds</a></li> 
<li :class="{'activeClass': idClicked == IGII23270 }" id="IGII23270"><inscr id='IG II² 3270'>IG II² 3270 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5539' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a></li> 
<li :class="{'activeClass': idClicked == IGII23272 }" id="IGII23272"><inscr id='IG II² 3272'>IG II² 3272 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5541' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM113">113</a></li> 
<li :class="{'activeClass': idClicked == IGII23274 }" id="IGII23274"><inscr id='IG II² 3274'>IG II² 3274 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5543' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a></li> 
<li :class="{'activeClass': idClicked == IGII23277 }" id="IGII23277"><inscr id='IG II² 3277'>IG II² 3277 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5546' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2987'>Parthenon</a>, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM113">113</a></li> 
<li :class="{'activeClass': idClicked == IGII23280 }" id="IGII23280"><inscr id='IG II² 3280'>IG II² 3280</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2642'>Messalina</a></li> 
<li :class="{'activeClass': idClicked == IGII23281 }" id="IGII23281"><inscr id='IG II² 3281'>IG II² 3281 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5550' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII23282 }" id="IGII23282"><inscr id='IG II² 3282'>IG II² 3282 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5551' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII23283 }" id="IGII23283"><inscr id='IG II² 3283'>IG II² 3283</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1517'>Domitian</a></li> 
<li :class="{'activeClass': idClicked == IGII23284 }" id="IGII23284"><inscr id='IG II² 3284'>IG II² 3284 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5553' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2934'>Pandaites</a></li> 
<li :class="{'activeClass': idClicked == IGII23284A }" id="IGII23284A">IG II² 3284A - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3686'>Trajan</a></li> 
<li :class="{'activeClass': idClicked == IGII23286 }" id="IGII23286"><inscr id='IG II² 3286'>IG II² 3286</inscr>-3287 - Hadrian</li> 
<li :class="{'activeClass': idClicked == IGII23289 }" id="IGII23289"><inscr id='IG II² 3289'>IG II² 3289</inscr>-3307 - Hadrian</li> 
<li :class="{'activeClass': idClicked == IGII23323 }" id="IGII23323"><inscr id='IG II² 3323'>IG II² 3323</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1490'>Dio54*</a></li> 
<li :class="{'activeClass': idClicked == IGII23331 }" id="IGII23331"><inscr id='IG II² 3331'>IG II² 3331 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5604' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == IGII23358 }" id="IGII23358"><inscr id='IG II² 3358'>IG II² 3358 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5631' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2195'>Homonia</a></li> 
<li :class="{'activeClass': idClicked == IGII23361 }" id="IGII23361"><inscr id='IG II² 3361'>IG II² 3361 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5634' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == IGII23384 }" id="IGII23384"><inscr id='IG II² 3384'>IG II² 3384</inscr> - Hadrian</li> 
<li :class="{'activeClass': idClicked == IGII23387 }" id="IGII23387"><inscr id='IG II² 3387'>IG II² 3387 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5662' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3329'>Sabina Augusta</a></li> 
<li :class="{'activeClass': idClicked == IGII23388 }" id="IGII23388"><inscr id='IG II² 3388'>IG II² 3388 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5663' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2609'>Matidia</a></li> 
<li :class="{'activeClass': idClicked == IGII23391 }" id="IGII23391"><inscr id='IG II² 3391'>IG II² 3391</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP65'>Agoranomion</a></li> 
<li :class="{'activeClass': idClicked == IGII23403 }" id="IGII23403"><inscr id='IG II² 3403'>IG II² 3403 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5678' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2596'>Marcus Aurelius</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2553'>Lucius Verus</a></li> 
<li :class="{'activeClass': idClicked == IGII23405 }" id="IGII23405"><inscr id='IG II² 3405'>IG II² 3405 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5680' target='_blank'>PHI</a>] </inscr>-3406 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2596'>Marcus Aurelius</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2553'>Lucius Verus</a></li> 
<li :class="{'activeClass': idClicked == IGII23410 }" id="IGII23410"><inscr id='IG II² 3410'>IG II² 3410 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5685' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2596'>Marcus Aurelius</a></li> 
<li :class="{'activeClass': idClicked == IGII23414 }" id="IGII23414"><inscr id='IG II² 3414'>IG II² 3414 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5689' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3380'>Septimius Severus</a> and Geta</li> 
<li :class="{'activeClass': idClicked == IGII23416 }" id="IGII23416"><inscr id='IG II² 3416'>IG II² 3416 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5691' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP980'>Caracalla</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3380'>Septimius Severus</a></li> 
<li :class="{'activeClass': idClicked == IGII23416a }" id="IGII23416a"><inscr id='IG II² 3416a'>IG II² 3416a [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5692' target='_blank'>PHI</a>] </inscr> - Geta</li> 
<li :class="{'activeClass': idClicked == IGII23417 }" id="IGII23417"><inscr id='IG II² 3417'>IG II² 3417 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5693' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3380'>Septimius Severus</a></li> 
<li :class="{'activeClass': idClicked == IGII23425 }" id="IGII23425"><inscr id='IG II² 3425'>IG II² 3425 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5701' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3086'>Philokles</a></li> 
<li :class="{'activeClass': idClicked == IGII23426 }" id="IGII23426"><inscr id='IG II² 3426'>IG II² 3426 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5702' target='_blank'>PHI</a>] </inscr>-3427 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP466'>Ariobarzanes II</a></li> 
<li :class="{'activeClass': idClicked == IGII23428 }" id="IGII23428"><inscr id='IG II² 3428'>IG II² 3428 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5704' target='_blank'>PHI</a>] </inscr> - Ariobarzanes III</li> 
<li :class="{'activeClass': idClicked == IGII23429 }" id="IGII23429"><inscr id='IG II² 3429'>IG II² 3429 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5705' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1210'>Deiotaros</a></li> 
<li :class="{'activeClass': idClicked == IGII23430 }" id="IGII23430"><inscr id='IG II² 3430'>IG II² 3430 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5706' target='_blank'>PHI</a>] </inscr>-3432 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP432'>Archelaos</a></li> 
<li :class="{'activeClass': idClicked == IGII23433 }" id="IGII23433"><inscr id='IG II² 3433'>IG II² 3433 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5709' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3300'>Pythodoris</a></li> 
<li :class="{'activeClass': idClicked == IGII23434 }" id="IGII23434"><inscr id='IG II² 3434'>IG II² 3434 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5710' target='_blank'>PHI</a>] </inscr>-3435 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP432'>Archelaos</a></li> 
<li :class="{'activeClass': idClicked == IGII23437 }" id="IGII23437"><inscr id='IG II² 3437'>IG II² 3437 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5713' target='_blank'>PHI</a>] </inscr>-3438 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1773'>Glaphyra</a></li> 
<li :class="{'activeClass': idClicked == IGII23440 }" id="IGII23440"><inscr id='IG II² 3440'>IG II² 3440 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5715' target='_blank'>PHI</a>] </inscr>-3441 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2072'>Herod the Great</a></li> 
<li :class="{'activeClass': idClicked == IGII23442 }" id="IGII23442"><inscr id='IG II² 3442'>IG II² 3442 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5717' target='_blank'>PHI</a>] </inscr> - Rhaskopouris</li> 
<li :class="{'activeClass': idClicked == IGII23443 }" id="IGII23443"><inscr id='IG II² 3443'>IG II² 3443 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5718' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2440'>Kotys</a></li> 
<li :class="{'activeClass': idClicked == IGII23444 }" id="IGII23444"><inscr id='IG II² 3444'>IG II² 3444 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5719' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a> III</li> 
<li :class="{'activeClass': idClicked == IGII23445 }" id="IGII23445"><inscr id='IG II² 3445'>IG II² 3445 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5720' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3266'>Ptolemaios</a></li> 
<li :class="{'activeClass': idClicked == IGII23447 }" id="IGII23447"><inscr id='IG II² 3447'>IG II² 3447 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5722' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3326'>Romanus Populus</a></li> 
<li :class="{'activeClass': idClicked == IGII23448 }" id="IGII23448"><inscr id='IG II² 3448'>IG II² 3448 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5723' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2480'>Lakedaimonians</a></li> 
<li :class="{'activeClass': idClicked == IGII23449 }" id="IGII23449"><inscr id='IG II² 3449'>IG II² 3449 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5724' target='_blank'>PHI</a>] </inscr> - Julia Berenike</li> 
<li :class="{'activeClass': idClicked == IGII23451 }" id="IGII23451"><inscr id='IG II² 3451'>IG II² 3451 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5728' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3090'>Philopappos</a></li> 
<li :class="{'activeClass': idClicked == IGII23453 }" id="IGII23453"><inscr id='IG II² 3453'>IG II² 3453 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5730' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3453' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2577'>Lysimache</a></li> 
<li :class="{'activeClass': idClicked == IGII23455 }" id="IGII23455"><inscr id='IG II² 3455'>IG II² 3455 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5732' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2577'>Lysimache</a> </li> 
<li :class="{'activeClass': idClicked == IGII23459 }" id="IGII23459"><inscr id='IG II² 3459'>IG II² 3459 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5736' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP39'>Aglauros</a></li> 
<li :class="{'activeClass': idClicked == IGII23464 }" id="IGII23464"><inscr id='IG II² 3464'>IG II² 3464 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5741' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/3464' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3481'>Sueris</a></li> 
<li :class="{'activeClass': idClicked == IGII23472 }" id="IGII23472"><inscr id='IG II² 3472'>IG II² 3472 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5749' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP844'>Ath128*</a></li> 
<li :class="{'activeClass': idClicked == IGII23532 }" id="IGII23532"><inscr id='IG II² 3532'>IG II² 3532</inscr>-3534 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3766'>Vestal Virgins</a></li> 
<li :class="{'activeClass': idClicked == IGII23538 }" id="IGII23538"><inscr id='IG II² 3538'>IG II² 3538 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5818' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a> on the Akr.</li> 
<li :class="{'activeClass': idClicked == IGII23565 }" id="IGII23565"><inscr id='IG II² 3565'>IG II² 3565</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a></li> 
<li :class="{'activeClass': idClicked == IGII23596 }" id="IGII23596"><inscr id='IG II² 3596'>IG II² 3596 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5876' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII23597a }" id="IGII23597a"><inscr id='IG II² 3597a'>IG II² 3597a [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5877' target='_blank'>PHI</a>] </inscr>-c - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII23599 }" id="IGII23599"><inscr id='IG II² 3599'>IG II² 3599</inscr>-3600 - Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII23607 }" id="IGII23607"><inscr id='IG II² 3607'>IG II² 3607</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3304'>Regilla</a></li> 
<li :class="{'activeClass': idClicked == IGII23625 }" id="IGII23625"><inscr id='IG II² 3625'>IG II² 3625</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1361'>Dexippos I</a></li> 
<li :class="{'activeClass': idClicked == IGII23630 }" id="IGII23630"><inscr id='IG II² 3630'>IG II² 3630</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP388'>Apo63*</a></li> 
<li :class="{'activeClass': idClicked == IGII23664 }" id="IGII23664"><inscr id='IG II² 3664'>IG II² 3664</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1757'>Ge</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1883'>Hemeros</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3238'>Pothos</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2031'>Hrm29*</a></li> 
<li :class="{'activeClass': idClicked == IGII23669 }" id="IGII23669"><inscr id='IG II² 3669'>IG II² 3669</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1361'>Dexippos I</a></li> 
<li :class="{'activeClass': idClicked == IGII23670 }" id="IGII23670"><inscr id='IG II² 3670'>IG II² 3670 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5954' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1361'>Dexippos I</a></li> 
<li :class="{'activeClass': idClicked == IGII23683 }" id="IGII23683"><inscr id='IG II² 3683'>IG II² 3683</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> Aph22*</li> 
<li :class="{'activeClass': idClicked == IGII23689 }" id="IGII23689"><inscr id='IG II² 3689'>IG II² 3689</inscr>-3690 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a> Illyrius</li> 
<li :class="{'activeClass': idClicked == IGII23692 }" id="IGII23692"><inscr id='IG II² 3692'>IG II² 3692</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a></li> 
<li :class="{'activeClass': idClicked == IGII23700 }" id="IGII23700"><inscr id='IG II² 3700'>IG II² 3700</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3752'>Ulpius Eubiotus</a></li> 
<li :class="{'activeClass': idClicked == IGII23701 }" id="IGII23701"><inscr id='IG II² 3701'>IG II² 3701</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a></li> 
<li :class="{'activeClass': idClicked == IGII23712 }" id="IGII23712"><inscr id='IG II² 3712'>IG II² 3712 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/5996' target='_blank'>PHI</a>] </inscr> - Kasianos <a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2721'>Mouseion</a></li> 
<li :class="{'activeClass': idClicked == IGII23725 }" id="IGII23725"><inscr id='IG II² 3725'>IG II² 3725</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP349'>Apo32*</a></li> 
<li :class="{'activeClass': idClicked == IGII23744 }" id="IGII23744"><inscr id='IG II² 3744'>IG II² 3744</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1981'>Hrk46*</a></li> 
<li :class="{'activeClass': idClicked == IGII23774 }" id="IGII23774"><inscr id='IG II² 3774'>IG II² 3774 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6058' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2432'>Konon</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3674'>Timotheos</a></li> 
<li :class="{'activeClass': idClicked == IGII23775 }" id="IGII23775"><inscr id='IG II² 3775'>IG II² 3775 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6059' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP634'>Astydamas</a> </li> 
<li :class="{'activeClass': idClicked == IGII23776 }" id="IGII23776"><inscr id='IG II² 3776'>IG II² 3776 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6060' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2565'>Lykourgos</a> II</li> 
<li :class="{'activeClass': idClicked == IGII23777 }" id="IGII23777"><inscr id='IG II² 3777'>IG II² 3777 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6061' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2630'>Menander</a></li> 
<li :class="{'activeClass': idClicked == IGII23778 }" id="IGII23778"><inscr id='IG II² 3778'>IG II² 3778 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6062' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3059'>Phanes</a> of Chios</li> 
<li :class="{'activeClass': idClicked == IGII23779 }" id="IGII23779"><inscr id='IG II² 3779'>IG II² 3779 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6063' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2781'>Nikokles</a></li> 
<li :class="{'activeClass': idClicked == IGII23781 }" id="IGII23781"><inscr id='IG II² 3781'>IG II² 3781 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6065' target='_blank'>PHI</a>] </inscr> - Kameades</li> 
<li :class="{'activeClass': idClicked == IGII23786 }" id="IGII23786"><inscr id='IG II² 3786'>IG II² 3786 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6070' target='_blank'>PHI</a>] </inscr> - Nikanor</li> 
<li :class="{'activeClass': idClicked == IGII23787 }" id="IGII23787"><inscr id='IG II² 3787'>IG II² 3787 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6071' target='_blank'>PHI</a>] </inscr> - Nikanor</li> 
<li :class="{'activeClass': idClicked == IGII23794 }" id="IGII23794"><inscr id='IG II² 3794'>IG II² 3794 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6078' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2668'>Mithras</a></li> 
<li :class="{'activeClass': idClicked == IGII23800 }" id="IGII23800"><inscr id='IG II² 3800'>IG II² 3800 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6084' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3182'>Pompeius Capito</a></li> 
<li :class="{'activeClass': idClicked == IGII23816 }" id="IGII23816"><inscr id='IG II² 3816'>IG II² 3816</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2478'>Laetus</a></li> 
<li :class="{'activeClass': idClicked == IGII23818 }" id="IGII23818"><inscr id='IG II² 3818'>IG II² 3818 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6102' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a></li> 
<li :class="{'activeClass': idClicked == IGII23829a }" id="IGII23829a">IG II² 3829a-e - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2934'>Pandaites</a></li> 
<li :class="{'activeClass': idClicked == IGII23831 }" id="IGII23831"><inscr id='IG II² 3831'>IG II² 3831</inscr>-3832 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP635'>Astynomos</a></li> 
<li :class="{'activeClass': idClicked == IGII23833 }" id="IGII23833"><inscr id='IG II² 3833'>IG II² 3833</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII23845 }" id="IGII23845"><inscr id='IG II² 3845'>IG II² 3845</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1690'>Eteokles</a></li> 
<li :class="{'activeClass': idClicked == IGII23867 }" id="IGII23867"><inscr id='IG II² 3867'>IG II² 3867</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2657'>Miltiades</a></li> 
<li :class="{'activeClass': idClicked == IGII23897 }" id="IGII23897"><inscr id='IG II² 3897'>IG II² 3897 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6179' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a> </li> 
<li :class="{'activeClass': idClicked == IGII23899 }" id="IGII23899"><inscr id='IG II² 3899'>IG II² 3899</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == IGII23932 }" id="IGII23932"><inscr id='IG II² 3932'>IG II² 3932</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3667'>Tiberius</a></li> 
<li :class="{'activeClass': idClicked == IGII23966A }" id="IGII23966A">IG II² 3966A - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1821'>Hadrian Olympios</a></li> 
<li :class="{'activeClass': idClicked == IGII23970 }" id="IGII23970"><inscr id='IG II² 3970'>IG II² 3970 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6255' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP657'>Ath11*</a></li> 
<li :class="{'activeClass': idClicked == IGII24004 }" id="IGII24004"><inscr id='IG II² 4004'>IG II² 4004</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2439'>Kornelios Onomarchos</a></li> 
<li :class="{'activeClass': idClicked == IGII24008 }" id="IGII24008"><inscr id='IG II² 4008'>IG II² 4008</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1370'>Dexippos</a></li> 
<li :class="{'activeClass': idClicked == IGII24048 }" id="IGII24048"><inscr id='IG II² 4048'>IG II² 4048</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == IGII24073 }" id="IGII24073"><inscr id='IG II² 4073'>IG II² 4073</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP857'>Athenais</a></li> 
<li :class="{'activeClass': idClicked == IGII24099 }" id="IGII24099"><inscr id='IG II² 4099'>IG II² 4099</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1196'>Cossutius</a></li> 
<li :class="{'activeClass': idClicked == IGII24100 }" id="IGII24100"><inscr id='IG II² 4100'>IG II² 4100 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6386' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3184'>Pompeius Sextus</a></li> 
<li :class="{'activeClass': idClicked == IGII24101 }" id="IGII24101"><inscr id='IG II² 4101'>IG II² 4101</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3185'>Pompeius Strabo</a></li> 
<li :class="{'activeClass': idClicked == IGII24103 }" id="IGII24103"><inscr id='IG II² 4103'>IG II² 4103</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3482'>Sulla</a></li> 
<li :class="{'activeClass': idClicked == IGII24104 }" id="IGII24104"><inscr id='IG II² 4104'>IG II² 4104</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2557'>Lucullus</a></li> 
<li :class="{'activeClass': idClicked == IGII24107 }" id="IGII24107"><inscr id='IG II² 4107'>IG II² 4107 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6393' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP967'>Caecilius Metellus</a></li> 
<li :class="{'activeClass': idClicked == IGII24109 }" id="IGII24109"><inscr id='IG II² 4109'>IG II² 4109</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1187'>Claudius Pulcher</a></li> 
<li :class="{'activeClass': idClicked == IGII24111 }" id="IGII24111"><inscr id='IG II² 4111'>IG II² 4111</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1184'>Claudius Marcellus</a></li> 
<li :class="{'activeClass': idClicked == IGII24116 }" id="IGII24116"><inscr id='IG II² 4116'>IG II² 4116</inscr> - Artorius</li> 
<li :class="{'activeClass': idClicked == IGII24118 }" id="IGII24118"><inscr id='IG II² 4118'>IG II² 4118</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2515'>Licinius</a></li> 
<li :class="{'activeClass': idClicked == IGII24120 }" id="IGII24120"><inscr id='IG II² 4120'>IG II² 4120</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1195'>Cornelius Scipio</a></li> 
<li :class="{'activeClass': idClicked == IGII24122 }" id="IGII24122"><inscr id='IG II² 4122'>IG II² 4122</inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM114">114</a></li> 
<li :class="{'activeClass': idClicked == IGII24123 }" id="IGII24123"><inscr id='IG II² 4123'>IG II² 4123</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP68'>Agrippa</a></li> 
<li :class="{'activeClass': idClicked == IGII24126 }" id="IGII24126"><inscr id='IG II² 4126'>IG II² 4126</inscr> - Aquilius </li> 
<li :class="{'activeClass': idClicked == IGII24129 }" id="IGII24129"><inscr id='IG II² 4129'>IG II² 4129</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2440'>Kotys</a></li> 
<li :class="{'activeClass': idClicked == IGII24133 }" id="IGII24133"><inscr id='IG II² 4133'>IG II² 4133</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2587'>Maecenas</a></li> 
<li :class="{'activeClass': idClicked == IGII24139 }" id="IGII24139"><inscr id='IG II² 4139'>IG II² 4139</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2548'>Lollius</a></li> 
<li :class="{'activeClass': idClicked == IGII24141 }" id="IGII24141"><inscr id='IG II² 4141'>IG II² 4141</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2546'>Livy</a></li> 
<li :class="{'activeClass': idClicked == IGII24144 }" id="IGII24144"><inscr id='IG II² 4144'>IG II² 4144 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6430' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1521'>Domitius Ahenobarbus</a></li> 
<li :class="{'activeClass': idClicked == IGII24155 }" id="IGII24155"><inscr id='IG II² 4155'>IG II² 4155</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1194'>Cornelius Dolabella</a></li> 
<li :class="{'activeClass': idClicked == IGII24168 }" id="IGII24168"><inscr id='IG II² 4168'>IG II² 4168</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP650'>Ath9*</a></li> 
<li :class="{'activeClass': idClicked == IGII24193 }" id="IGII24193"><inscr id='IG II² 4193'>IG II² 4193 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6479' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3694'>Trebellius Rufus</a></li> 
<li :class="{'activeClass': idClicked == IGII24209 }" id="IGII24209"><inscr id='IG II² 4209'>IG II² 4209 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6496' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> and II</li> 
<li :class="{'activeClass': idClicked == IGII24211 }" id="IGII24211"><inscr id='IG II² 4211'>IG II² 4211 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6498' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2201'>Hordeonius Lollianus</a></li> 
<li :class="{'activeClass': idClicked == IGII24217 }" id="IGII24217"><inscr id='IG II² 4217'>IG II² 4217 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6504' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1749'>Festus</a></li> 
<li :class="{'activeClass': idClicked == IGII24222 }" id="IGII24222"><inscr id='IG II² 4222'>IG II² 4222 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6509' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1749'>Festus</a></li> 
<li :class="{'activeClass': idClicked == IGII24223 }" id="IGII24223"><inscr id='IG II² 4223'>IG II² 4223 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6510' target='_blank'>PHI</a>] </inscr> - Theodoras</li> 
<li :class="{'activeClass': idClicked == IGII24224 }" id="IGII24224"><inscr id='IG II² 4224'>IG II² 4224 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6511' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1986'>Herculius</a></li> 
<li :class="{'activeClass': idClicked == IGII24225 }" id="IGII24225"><inscr id='IG II² 4225'>IG II² 4225 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6512' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP818'>Ath113*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1986'>Herculius</a></li> 
<li :class="{'activeClass': idClicked == IGII24226 }" id="IGII24226"><inscr id='IG II² 4226'>IG II² 4226 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6513' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3243'>Probus</a></li> 
<li :class="{'activeClass': idClicked == IGII24256 }" id="IGII24256"><inscr id='IG II² 4256'>IG II² 4256 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6541' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2630'>Menander</a></li> 
<li :class="{'activeClass': idClicked == IGII24257 }" id="IGII24257"><inscr id='IG II² 4257'>IG II² 4257 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6542' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1396'>Diomedes</a></li> 
<li :class="{'activeClass': idClicked == IGII24258 }" id="IGII24258"><inscr id='IG II² 4258'>IG II² 4258 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6543' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2409'>Kodros</a> (2)</li> 
<li :class="{'activeClass': idClicked == IGII24259 }" id="IGII24259"><inscr id='IG II² 4259'>IG II² 4259 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6544' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2565'>Lykourgos</a> II</li> 
<li :class="{'activeClass': idClicked == IGII24261 }" id="IGII24261"><inscr id='IG II² 4261'>IG II² 4261 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6546' target='_blank'>PHI</a>] </inscr> - Aristotle</li> 
<li :class="{'activeClass': idClicked == IGII24263 }" id="IGII24263"><inscr id='IG II² 4263'>IG II² 4263 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6548' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1348'>Demosthenes</a></li> 
<li :class="{'activeClass': idClicked == IGII24264 }" id="IGII24264"><inscr id='IG II² 4264'>IG II² 4264 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6549' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3639'>Thespis</a></li> 
<li :class="{'activeClass': idClicked == IGII24265 }" id="IGII24265"><inscr id='IG II² 4265'>IG II² 4265 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6550' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP99'>Aischylos</a></li> 
<li :class="{'activeClass': idClicked == IGII24266 }" id="IGII24266"><inscr id='IG II² 4266'>IG II² 4266 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6551' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3070'>Philemon</a></li> 
<li :class="{'activeClass': idClicked == IGII24267 }" id="IGII24267"><inscr id='IG II² 4267'>IG II² 4267 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6552' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3672'>Timostratos</a></li> 
<li :class="{'activeClass': idClicked == IGII24268 }" id="IGII24268"><inscr id='IG II² 4268'>IG II² 4268 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6553' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1409'>Dionysios</a></li> 
<li :class="{'activeClass': idClicked == IGII24272 }" id="IGII24272"><inscr id='IG II² 4272'>IG II² 4272 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6557' target='_blank'>PHI</a>] </inscr> - Phrixos</li> 
<li :class="{'activeClass': idClicked == IGII24298 }" id="IGII24298"><inscr id='IG II² 4298'>IG II² 4298 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6583' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP327'>Apo13*</a></li> 
<li :class="{'activeClass': idClicked == IGII24313 }" id="IGII24313"><inscr id='IG II² 4313'>IG II² 4313 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6598' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2240'>Iliad</a></li> 
<li :class="{'activeClass': idClicked == IGII24318 }" id="IGII24318"><inscr id='IG II² 4318'>IG II² 4318 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6603' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP755'>Ath65*</a></li> 
<li :class="{'activeClass': idClicked == IGII24323A }" id="IGII24323A"><inscr id='IG II² 4323A'>IG II² 4323A [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6609' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP825'>Ath115*</a></li> 
<li :class="{'activeClass': idClicked == IGII24329 }" id="IGII24329"><inscr id='IG II² 4329'>IG II² 4329 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6615' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP752'>Ath62*</a></li> 
<li :class="{'activeClass': idClicked == IGII24334 }" id="IGII24334"><inscr id='IG II² 4334'>IG II² 4334 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6620' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP754'>Ath64*</a></li> 
<li :class="{'activeClass': idClicked == IGII24339b }" id="IGII24339b"><inscr id='IG II² 4339b'>IG II² 4339b [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6628' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP855'>Ath136*</a></li> 
<li :class="{'activeClass': idClicked == IGII24347 }" id="IGII24347"><inscr id='IG II² 4347'>IG II² 4347 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6636' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP657'>Ath11*</a></li> 
<li :class="{'activeClass': idClicked == IGII24350 }" id="IGII24350"><inscr id='IG II² 4350'>IG II² 4350 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6639' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP726'>Ath44*</a></li> 
<li :class="{'activeClass': idClicked == IGII24353 }" id="IGII24353"><inscr id='IG II² 4353'>IG II² 4353 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6642' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2586'>Machaon</a></li> 
<li :class="{'activeClass': idClicked == IGII24356 }" id="IGII24356"><inscr id='IG II² 4356'>IG II² 4356 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6645' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP581'>Ask20*</a></li> 
<li :class="{'activeClass': idClicked == IGII24357 }" id="IGII24357"><inscr id='IG II² 4357'>IG II² 4357 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6646' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP584'>Ask23*</a> </li> 
<li :class="{'activeClass': idClicked == IGII24359 }" id="IGII24359"><inscr id='IG II² 4359'>IG II² 4359 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6648' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP601'>Ask35*</a></li> 
<li :class="{'activeClass': idClicked == IGII24371 }" id="IGII24371"><inscr id='IG II² 4371'>IG II² 4371 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6660' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2802'>Nym4*</a></li> 
<li :class="{'activeClass': idClicked == IGII24387 }" id="IGII24387"><inscr id='IG II² 4387'>IG II² 4387 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6677' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2579'>Lysimachides</a></li> 
<li :class="{'activeClass': idClicked == IGII24388 }" id="IGII24388"><inscr id='IG II² 4388'>IG II² 4388 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6678' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP583'>Ask22*</a> </li> 
<li :class="{'activeClass': idClicked == IGII24416 }" id="IGII24416"><inscr id='IG II² 4416'>IG II² 4416</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP585'>Ask24*</a></li> 
<li :class="{'activeClass': idClicked == IGII24417 }" id="IGII24417"><inscr id='IG II² 4417'>IG II² 4417</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP607'>Ask39*</a></li> 
<li :class="{'activeClass': idClicked == IGII24423 }" id="IGII24423"><inscr id='IG II² 4423'>IG II² 4423</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3406'>Snake</a></li> 
<li :class="{'activeClass': idClicked == IGII24424 }" id="IGII24424"><inscr id='IG II² 4424'>IG II² 4424</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP169'>Amynos</a></li> 
<li :class="{'activeClass': idClicked == IGII24441 }" id="IGII24441"><inscr id='IG II² 4441'>IG II² 4441 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6730' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP160'>Amphiaraos</a></li> 
<li :class="{'activeClass': idClicked == IGII24457 }" id="IGII24457"><inscr id='IG II² 4457'>IG II² 4457</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP169'>Amynos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a></li> 
<li :class="{'activeClass': idClicked == IGII24464 }" id="IGII24464"><inscr id='IG II² 4464'>IG II² 4464</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP588'>Ask27*</a></li> 
<li :class="{'activeClass': idClicked == IGII24467 }" id="IGII24467"><inscr id='IG II² 4467'>IG II² 4467</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP565'>Ask8*</a></li> 
<li :class="{'activeClass': idClicked == IGII24473 }" id="IGII24473"><inscr id='IG II² 4473'>IG II² 4473</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP413'>Apo83*</a></li> 
<li :class="{'activeClass': idClicked == IGII24478 }" id="IGII24478"><inscr id='IG II² 4478'>IG II² 4478</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP567'>Ask9*</a></li> 
<li :class="{'activeClass': idClicked == IGII24482 }" id="IGII24482"><inscr id='IG II² 4482'>IG II² 4482</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2130'>Hro35*</a></li> 
<li :class="{'activeClass': idClicked == IGII24492 }" id="IGII24492"><inscr id='IG II² 4492/93'>IG II² 4492/93</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP606'>Ask38*</a></li> 
<li :class="{'activeClass': idClicked == IGII24509 }" id="IGII24509"><inscr id='IG II² 4509'>IG II² 4509 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6800' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP572'>Ask13*</a></li> 
<li :class="{'activeClass': idClicked == IGII24511 }" id="IGII24511"><inscr id='IG II² 4511'>IG II² 4511 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6802' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP568'>Ask10*</a></li> 
<li :class="{'activeClass': idClicked == IGII24514 }" id="IGII24514"><inscr id='IG II² 4514'>IG II² 4514 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6805' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP573'>Ask14*</a></li> 
<li :class="{'activeClass': idClicked == IGII24516 }" id="IGII24516"><inscr id='IG II² 4516'>IG II² 4516 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6807' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP562'>Ask5*</a></li> 
<li :class="{'activeClass': idClicked == IGII24523 }" id="IGII24523"><inscr id='IG II² 4523'>IG II² 4523 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6816' target='_blank'>PHI</a>] </inscr> - Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII24533 }" id="IGII24533"><inscr id='IG II² 4533'>IG II² 4533 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6827' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP612'>Ask43*</a></li> 
<li :class="{'activeClass': idClicked == IGII24539 }" id="IGII24539"><inscr id='IG II² 4539'>IG II² 4539 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6833' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2214'>Hygieia</a></li> 
<li :class="{'activeClass': idClicked == IGII24541 }" id="IGII24541"><inscr id='IG II² 4541'>IG II² 4541 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6835' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3504'>Telesphoros</a></li> 
<li :class="{'activeClass': idClicked == IGII24545 }" id="IGII24545"><inscr id='IG II² 4545'>IG II² 4545 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6839' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2812'>Nym12*</a></li> 
<li :class="{'activeClass': idClicked == IGII24555 }" id="IGII24555"><inscr id='IG II² 4555'>IG II² 4555 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6849' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3898'>Zeu88*</a></li> 
<li :class="{'activeClass': idClicked == IGII24558 }" id="IGII24558"><inscr id='IG II² 4558'>IG II² 4558 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6852' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP415'>Apo85*</a></li> 
<li :class="{'activeClass': idClicked == IGII24564 }" id="IGII24564"><inscr id='IG II² 4564'>IG II² 4564 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6858' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3734'>Twelve Gods</a></li> 
<li :class="{'activeClass': idClicked == IGII24565 }" id="IGII24565"><inscr id='IG II² 4565'>IG II² 4565 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6859' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2800'>Nym2*</a></li> 
<li :class="{'activeClass': idClicked == IGII24571 }" id="IGII24571"><inscr id='IG II² 4571'>IG II² 4571 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6865' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1925'>Hrk6*</a></li> 
<li :class="{'activeClass': idClicked == IGII24572 }" id="IGII24572"><inscr id='IG II² 4572'>IG II² 4572 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6866' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2020'>Hrm20*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2021'>Hrm21*</a></li> 
<li :class="{'activeClass': idClicked == IGII24592 }" id="IGII24592"><inscr id='IG II² 4592'>IG II² 4592 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6886' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2801'>Nym3*</a></li> 
<li :class="{'activeClass': idClicked == IGII24595 }" id="IGII24595"><inscr id='IG II² 4595'>IG II² 4595 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6890' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2694'>Met5*</a></li> 
<li :class="{'activeClass': idClicked == IGII24596 }" id="IGII24596"><inscr id='IG II² 4596'>IG II² 4596 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6891' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP301'>Aph48*</a></li> 
<li :class="{'activeClass': idClicked == IGII24597 }" id="IGII24597"><inscr id='IG II² 4597'>IG II² 4597 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6892' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1249'>Demeter Propylaia</a></li> 
<li :class="{'activeClass': idClicked == IGII24599 }" id="IGII24599"><inscr id='IG II² 4599'>IG II² 4599 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6894' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2092'>Hro1*</a></li> 
<li :class="{'activeClass': idClicked == IGII24611 }" id="IGII24611"><inscr id='IG II² 4611'>IG II² 4611 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6906' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1960'>Hrk31*</a></li> 
<li :class="{'activeClass': idClicked == IGII24612 }" id="IGII24612"><inscr id='IG II² 4612'>IG II² 4612 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6907' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1926'>Hrk7*</a></li> 
<li :class="{'activeClass': idClicked == IGII24613 }" id="IGII24613"><inscr id='IG II² 4613'>IG II² 4613 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6908' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1961'>Hrk32*</a></li> 
<li :class="{'activeClass': idClicked == IGII24626 }" id="IGII24626"><inscr id='IG II² 4626'>IG II² 4626 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6921' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3909'>Zeu98*</a></li> 
<li :class="{'activeClass': idClicked == IGII24630 }" id="IGII24630"><inscr id='IG II² 4630'>IG II² 4630 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6926' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4630' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP833'>Ath119*</a></li> 
<li :class="{'activeClass': idClicked == IGII24640 }" id="IGII24640"><inscr id='IG II² 4640'>IG II² 4640 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6937' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1223'>Demeter and Kore</a></li> 
<li :class="{'activeClass': idClicked == IGII24642 }" id="IGII24642"><inscr id='IG II² 4642'>IG II² 4642 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6939' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1223'>Demeter and Kore</a></li> 
<li :class="{'activeClass': idClicked == IGII24643 }" id="IGII24643"><inscr id='IG II² 4643'>IG II² 4643 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6940' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1404'>Dione</a></li> 
<li :class="{'activeClass': idClicked == IGII24644 }" id="IGII24644"><inscr id='IG II² 4644'>IG II² 4644 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6941' target='_blank'>PHI</a>] </inscr> - AgatheTyche</li> 
<li :class="{'activeClass': idClicked == IGII24645 }" id="IGII24645"><inscr id='IG II² 4645'>IG II² 4645 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6942' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3930'>Zeuxippos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP909'>Basileia</a></li> 
<li :class="{'activeClass': idClicked == IGII24647 }" id="IGII24647"><inscr id='IG II² 4647'>IG II² 4647 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6944' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2804'>Nym6*</a></li> 
<li :class="{'activeClass': idClicked == IGII24659 }" id="IGII24659"><inscr id='IG II² 4659'>IG II² 4659 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6956' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP544'>Art45*</a></li> 
<li :class="{'activeClass': idClicked == IGII24662 }" id="IGII24662"><inscr id='IG II² 4662'>IG II² 4662 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6959' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1216'>Demeter</a></li> 
<li :class="{'activeClass': idClicked == IGII24668 }" id="IGII24668"><inscr id='IG II² 4668'>IG II² 4668 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6965' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2325'>Kalliste</a></li> 
<li :class="{'activeClass': idClicked == IGII24669 }" id="IGII24669"><inscr id='IG II² 4669'>IG II² 4669 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6966' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == IGII24670 }" id="IGII24670"><inscr id='IG II² 4670'>IG II² 4670 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6967' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2714'>Met21*</a></li> 
<li :class="{'activeClass': idClicked == IGII24672 }" id="IGII24672"><inscr id='IG II² 4672'>IG II² 4672 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6969' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2899'>Pan11*</a></li> 
<li :class="{'activeClass': idClicked == IGII24675 }" id="IGII24675"><inscr id='IG II² 4675'>IG II² 4675 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6972' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1910'>Hera Boulaia</a></li> 
<li :class="{'activeClass': idClicked == IGII24676 }" id="IGII24676"><inscr id='IG II² 4676'>IG II² 4676 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6973' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == IGII24677 }" id="IGII24677"><inscr id='IG II² 4677'>IG II² 4677 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6974' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3866'>Zeu62*</a></li> 
<li :class="{'activeClass': idClicked == IGII24678 }" id="IGII24678"><inscr id='IG II² 4678'>IG II² 4678 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6975' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1874'>Helios</a></li> 
<li :class="{'activeClass': idClicked == IGII24682 }" id="IGII24682"><inscr id='IG II² 4682'>IG II² 4682 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6979' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == IGII24686 }" id="IGII24686"><inscr id='IG II² 4686'>IG II² 4686 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6983' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1850'>Hegemon Archegetes</a></li> 
<li :class="{'activeClass': idClicked == IGII24688 }" id="IGII24688"><inscr id='IG II² 4688'>IG II² 4688 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6986' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP524'>Art31*</a></li> 
<li :class="{'activeClass': idClicked == IGII24689 }" id="IGII24689"><inscr id='IG II² 4689'>IG II² 4689 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6987' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP493'>Art3*</a></li> 
<li :class="{'activeClass': idClicked == IGII24692 }" id="IGII24692"><inscr id='IG II² 4692'>IG II² 4692 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6990' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a></li> 
<li :class="{'activeClass': idClicked == IGII24695 }" id="IGII24695"><inscr id='IG II² 4695'>IG II² 4695 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6993' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP547'>Art47*</a></li> 
<li :class="{'activeClass': idClicked == IGII24700 }" id="IGII24700"><inscr id='IG II² 4700'>IG II² 4700 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/6998' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2230'>Hyrkanos</a></li> 
<li :class="{'activeClass': idClicked == IGII24702 }" id="IGII24702"><inscr id='IG II² 4702'>IG II² 4702 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7000' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2263'>Isis Dikaiosyne</a></li> 
<li :class="{'activeClass': idClicked == IGII24707 }" id="IGII24707"><inscr id='IG II² 4707'>IG II² 4707 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7005' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3875'>Zeu69*</a></li> 
<li :class="{'activeClass': idClicked == IGII24712 }" id="IGII24712"><inscr id='IG II² 4712'>IG II² 4712 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7010' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2118'>Hro24*</a></li> 
<li :class="{'activeClass': idClicked == IGII24719 }" id="IGII24719"><inscr id='IG II² 4719'>IG II² 4719 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7017' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4719' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP339'>Apo22*</a></li> 
<li :class="{'activeClass': idClicked == IGII24723 }" id="IGII24723"><inscr id='IG II² 4723'>IG II² 4723 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7021' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3917'>Zeu104*</a></li> 
<li :class="{'activeClass': idClicked == IGII24725 }" id="IGII24725"><inscr id='IG II² 4725'>IG II² 4725 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7023' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII24726 }" id="IGII24726"><inscr id='IG II² 4726'>IG II² 4726 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7024' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP387'>Apo62*</a></li> 
<li :class="{'activeClass': idClicked == IGII24727 }" id="IGII24727"><inscr id='IG II² 4727'>IG II² 4727 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7025' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP390'>Apo64*</a></li> 
<li :class="{'activeClass': idClicked == IGII24728 }" id="IGII24728"><inscr id='IG II² 4728'>IG II² 4728 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7026' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2006'>Hrm8*</a></li> 
<li :class="{'activeClass': idClicked == IGII24731 }" id="IGII24731"><inscr id='IG II² 4731'>IG II² 4731 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7029' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP536'>Art39*</a></li> 
<li :class="{'activeClass': idClicked == IGII24732 }" id="IGII24732"><inscr id='IG II² 4732'>IG II² 4732 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7030' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == IGII24733 }" id="IGII24733"><inscr id='IG II² 4733'>IG II² 4733 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7031' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a> and the Egyptian Gods</li> 
<li :class="{'activeClass': idClicked == IGII24734 }" id="IGII24734"><inscr id='IG II² 4734'>IG II² 4734 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7032' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2205'>Horme</a></li> 
<li :class="{'activeClass': idClicked == IGII24739 }" id="IGII24739"><inscr id='IG II² 4739'>IG II² 4739 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7037' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3918'>Zeu105*</a></li> 
<li :class="{'activeClass': idClicked == IGII24740 }" id="IGII24740"><inscr id='IG II² 4740'>IG II² 4740 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7038' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3821'>Zeu27*</a></li> 
<li :class="{'activeClass': idClicked == IGII24742 }" id="IGII24742"><inscr id='IG II² 4742'>IG II² 4742 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7040' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> Hrm42*</li> 
<li :class="{'activeClass': idClicked == IGII24747 }" id="IGII24747"><inscr id='IG II² 4747'>IG II² 4747 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7045' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == IGII24750 }" id="IGII24750"><inscr id='IG II² 4750'>IG II² 4750 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7048' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1240'>Demeter Chloe</a></li> 
<li :class="{'activeClass': idClicked == IGII24751 }" id="IGII24751"><inscr id='IG II² 4751'>IG II² 4751 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7049' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3140'>Plouton</a></li> 
<li :class="{'activeClass': idClicked == IGII24752 }" id="IGII24752"><inscr id='IG II² 4752'>IG II² 4752 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7050' target='_blank'>PHI</a>] </inscr> - Thesmophoros</li> 
<li :class="{'activeClass': idClicked == IGII24756 }" id="IGII24756"><inscr id='IG II² 4756/57'>IG II² 4756/57</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2443'>Kourotrophion</a></li> 
<li :class="{'activeClass': idClicked == IGII24758 }" id="IGII24758"><inscr id='IG II² 4758'>IG II² 4758 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7056' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1759'>Ge Karpophoros</a></li> 
<li :class="{'activeClass': idClicked == IGII24761 }" id="IGII24761"><inscr id='IG II² 4761'>IG II² 4761 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7059' target='_blank'>PHI</a>] </inscr> - AgatheTyche</li> 
<li :class="{'activeClass': idClicked == IGII24763 }" id="IGII24763"><inscr id='IG II² 4763'>IG II² 4763 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7061' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a></li> 
<li :class="{'activeClass': idClicked == IGII24771 }" id="IGII24771"><inscr id='IG II² 4771'>IG II² 4771 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7069' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP249'>Aph6*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2257'>Isis</a></li> 
<li :class="{'activeClass': idClicked == IGII24772 }" id="IGII24772"><inscr id='IG II² 4772'>IG II² 4772 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7070' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP603'>Ask36*</a></li> 
<li :class="{'activeClass': idClicked == IGII24773 }" id="IGII24773"><inscr id='IG II² 4773'>IG II² 4773 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7071' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2703'>Met12*</a></li> 
<li :class="{'activeClass': idClicked == IGII24775 }" id="IGII24775"><inscr id='IG II² 4775'>IG II² 4775 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7073' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP855'>Ath136*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1272'>Demos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1007'>Charites</a>, Hadrian, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3413'>Sokrates</a></li> 
<li :class="{'activeClass': idClicked == IGII24777 }" id="IGII24777"><inscr id='IG II² 4777'>IG II² 4777 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7075' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1240'>Demeter Chloe</a></li> 
<li :class="{'activeClass': idClicked == IGII24778 }" id="IGII24778"><inscr id='IG II² 4778'>IG II² 4778 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7076' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2446'>Kourotrophos</a></li> 
<li :class="{'activeClass': idClicked == IGII24782 }" id="IGII24782"><inscr id='IG II² 4782'>IG II² 4782 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7080' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3846'>Zeu47*</a></li> 
<li :class="{'activeClass': idClicked == IGII24783 }" id="IGII24783"><inscr id='IG II² 4783'>IG II² 4783 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7081' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3836'>Zeu38*</a></li> 
<li :class="{'activeClass': idClicked == IGII24784 }" id="IGII24784"><inscr id='IG II² 4784'>IG II² 4784 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7082' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3847'>Zeu48*</a></li> 
<li :class="{'activeClass': idClicked == IGII24785 }" id="IGII24785"><inscr id='IG II² 4785'>IG II² 4785 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7083' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3919'>Zeu106*</a></li> 
<li :class="{'activeClass': idClicked == IGII24786 }" id="IGII24786"><inscr id='IG II² 4786'>IG II² 4786 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7084' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3832'>Zeu35*</a></li> 
<li :class="{'activeClass': idClicked == IGII24789 }" id="IGII24789"><inscr id='IG II² 4789'>IG II² 4789 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7087' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1414'>Dio1*</a></li> 
<li :class="{'activeClass': idClicked == IGII24791 }" id="IGII24791"><inscr id='IG II² 4791'>IG II² 4791 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7089' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP532'>Art35*</a></li> 
<li :class="{'activeClass': idClicked == IGII24796 }" id="IGII24796"><inscr id='IG II² 4796'>IG II² 4796 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7094' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a></li> 
<li :class="{'activeClass': idClicked == IGII24800 }" id="IGII24800"><inscr id='IG II² 4800'>IG II² 4800 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7098' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3837'>Zeu39*</a></li> 
<li :class="{'activeClass': idClicked == IGII24802 }" id="IGII24802"><inscr id='IG II² 4802'>IG II² 4802 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7100' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3838'>Zeu40*</a></li> 
<li :class="{'activeClass': idClicked == IGII24807 }" id="IGII24807"><inscr id='IG II² 4807'>IG II² 4807 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7105' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3839'>Zeu41*</a></li> 
<li :class="{'activeClass': idClicked == IGII24812 }" id="IGII24812"><inscr id='IG II² 4812'>IG II² 4812 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7110' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3918'>Zeu105*</a></li> 
<li :class="{'activeClass': idClicked == IGII24813 }" id="IGII24813"><inscr id='IG II² 4813'>IG II² 4813 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7111' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP353'>Apo36*</a></li> 
<li :class="{'activeClass': idClicked == IGII24815 }" id="IGII24815"><inscr id='IG II² 4815'>IG II² 4815 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7113' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a></li> 
<li :class="{'activeClass': idClicked == IGII24817A }" id="IGII24817A"><inscr id='IG II² 4817A'>IG II² 4817A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == IGII24818 }" id="IGII24818"><inscr id='IG II² 4818'>IG II² 4818 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7117' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2626'>Men</a></li> 
<li :class="{'activeClass': idClicked == IGII24818A }" id="IGII24818A"><inscr id='IG II² 4818A'>IG II² 4818A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3511'>Thea Epekoos</a></li> 
<li :class="{'activeClass': idClicked == IGII24819 }" id="IGII24819"><inscr id='IG II² 4819'>IG II² 4819 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7119' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP33'>Agathos Daimon</a></li> 
<li :class="{'activeClass': idClicked == IGII24820 }" id="IGII24820"><inscr id='IG II² 4820'>IG II² 4820 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7120' target='_blank'>PHI</a>] </inscr> - Chthonic <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1207'>Daimon</a></li> 
<li :class="{'activeClass': idClicked == IGII24841 }" id="IGII24841"><inscr id='IG II² 4841'>IG II² 4841 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7141' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4841' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3494'>Taurobolion</a></li> 
<li :class="{'activeClass': idClicked == IGII24842 }" id="IGII24842"><inscr id='IG II² 4842'>IG II² 4842 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7142' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4842' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3494'>Taurobolion</a></li> 
<li :class="{'activeClass': idClicked == IGII24844 }" id="IGII24844"><inscr id='IG II² 4844'>IG II² 4844 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7144' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3920'>Zeu107*</a></li> 
<li :class="{'activeClass': idClicked == IGII24850 }" id="IGII24850"><inscr id='IG II² 4850'>IG II² 4850 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7150' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP340'>Apo23*</a></li> 
<li :class="{'activeClass': idClicked == IGII24852 }" id="IGII24852"><inscr id='IG II² 4852'>IG II² 4852 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7152' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP391'>Apo65*</a></li> 
<li :class="{'activeClass': idClicked == IGII24854 }" id="IGII24854"><inscr id='IG II² 4854'>IG II² 4854 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7154' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP408'>Apo78*</a></li> 
<li :class="{'activeClass': idClicked == IGII24855 }" id="IGII24855"><inscr id='IG II² 4855'>IG II² 4855 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7155' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2026'>Hrm26*</a></li> 
<li :class="{'activeClass': idClicked == IGII24860 }" id="IGII24860"><inscr id='IG II² 4860'>IG II² 4860 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7160' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP535'>Art38*</a></li> 
<li :class="{'activeClass': idClicked == IGII24865 }" id="IGII24865"><inscr id='IG II² 4865'>IG II² 4865 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7164' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == IGII24870 }" id="IGII24870"><inscr id='IG II² 4870'>IG II² 4870 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7169' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2689'>Met1*</a></li> 
<li :class="{'activeClass': idClicked == IGII24872 }" id="IGII24872"><inscr id='IG II² 4872'>IG II² 4872 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7171' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3348'>Sarapis</a></li> 
<li :class="{'activeClass': idClicked == IGII24873 }" id="IGII24873"><inscr id='IG II² 4873'>IG II² 4873 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7172' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2862'>Osiris</a></li> 
<li :class="{'activeClass': idClicked == IGII24876 }" id="IGII24876"><inscr id='IG II² 4876'>IG II² 4876 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7175' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2908'>Pan18*</a></li> 
<li :class="{'activeClass': idClicked == IGII24886 }" id="IGII24886"><inscr id='IG II² 4886'>IG II² 4886 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7185' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2806'>Nym8*</a></li> 
<li :class="{'activeClass': idClicked == IGII24924 }" id="IGII24924"><inscr id='IG II² 4924'>IG II² 4924 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7223' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2129'>Hro34*</a></li> 
<li :class="{'activeClass': idClicked == IGII24952 }" id="IGII24952"><inscr id='IG II² 4952'>IG II² 4952</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1962'>Hrk33*</a></li> 
<li :class="{'activeClass': idClicked == IGII24960 }" id="IGII24960"><inscr id='IG II² 4960'>IG II² 4960 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7263' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == IGII24961 }" id="IGII24961"><inscr id='IG II² 4961'>IG II² 4961 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7264' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4961' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP560'>Ask3*</a></li> 
<li :class="{'activeClass': idClicked == IGII24963 }" id="IGII24963"><inscr id='IG II² 4963'>IG II² 4963 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7266' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a>,6*</li> 
<li :class="{'activeClass': idClicked == IGII24964 }" id="IGII24964"><inscr id='IG II² 4964'>IG II² 4964 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7267' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3850'>Zeu50*</a></li> 
<li :class="{'activeClass': idClicked == IGII24965 }" id="IGII24965"><inscr id='IG II² 4965'>IG II² 4965 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7268' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3851'>Zeu51*</a>-52*</li> 
<li :class="{'activeClass': idClicked == IGII24969 }" id="IGII24969"><inscr id='IG II² 4969'>IG II² 4969 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7272' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4969' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP394'>Apo67*</a></li> 
<li :class="{'activeClass': idClicked == IGII24973 }" id="IGII24973"><inscr id='IG II² 4973'>IG II² 4973 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7276' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP382'>Apo57*</a></li> 
<li :class="{'activeClass': idClicked == IGII24975 }" id="IGII24975"><inscr id='IG II² 4975'>IG II² 4975 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7278' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3906'>Zeu95*</a></li> 
<li :class="{'activeClass': idClicked == IGII24982 }" id="IGII24982"><inscr id='IG II² 4982'>IG II² 4982 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7302' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP942'>Boutes</a></li> 
<li :class="{'activeClass': idClicked == IGII24983 }" id="IGII24983"><inscr id='IG II² 4983'>IG II² 4983 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7303' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3828'>Zeu33*</a></li> 
<li :class="{'activeClass': idClicked == IGII24984 }" id="IGII24984"><inscr id='IG II² 4984'>IG II² 4984 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7304' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP380'>Apo55*</a></li> 
<li :class="{'activeClass': idClicked == IGII24985 }" id="IGII24985"><inscr id='IG II² 4985'>IG II² 4985 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7305' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2195'>Homonia</a></li> 
<li :class="{'activeClass': idClicked == IGII24986 }" id="IGII24986"><inscr id='IG II² 4986'>IG II² 4986 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7306' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/4986' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1921'>Hrk2*</a></li> 
<li :class="{'activeClass': idClicked == IGII24992 }" id="IGII24992"><inscr id='IG II² 4992'>IG II² 4992 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7312' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP747'>Ath58*</a></li> 
<li :class="{'activeClass': idClicked == IGII24993 }" id="IGII24993"><inscr id='IG II² 4993'>IG II² 4993 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7313' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1874'>Helios</a></li> 
<li :class="{'activeClass': idClicked == IGII24994 }" id="IGII24994"><inscr id='IG II² 4994'>IG II² 4994 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7314' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2037'>Hrm35*</a></li> 
<li :class="{'activeClass': idClicked == IGII24995 }" id="IGII24995"><inscr id='IG II² 4995'>IG II² 4995 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7315' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP341'>Apo24*</a></li> 
<li :class="{'activeClass': idClicked == IGII24997 }" id="IGII24997"><inscr id='IG II² 4997'>IG II² 4997 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7317' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP613'>Ask44*</a></li> 
<li :class="{'activeClass': idClicked == IGII24998 }" id="IGII24998"><inscr id='IG II² 4998'>IG II² 4998 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7318' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3852'>Zeu52*</a></li> 
<li :class="{'activeClass': idClicked == IGII25004 }" id="IGII25004"><inscr id='IG II² 5004'>IG II² 5004 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7309' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2446'>Kourotrophos</a></li> 
<li :class="{'activeClass': idClicked == IGII25005 }" id="IGII25005"><inscr id='IG II² 5005'>IG II² 5005 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7310' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP527'>Art33*</a></li> 
<li :class="{'activeClass': idClicked == IGII25006 }" id="IGII25006"><inscr id='IG II² 5006'>IG II² 5006 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7311' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP395'>Apo68*</a></li> 
<li :class="{'activeClass': idClicked == IGII25010 }" id="IGII25010"><inscr id='IG II² 5010'>IG II² 5010 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7315' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP352'>Apo35*</a></li> 
<li :class="{'activeClass': idClicked == IGII25013 }" id="IGII25013"><inscr id='IG II² 5013'>IG II² 5013 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7318' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP267'>Aph23*</a></li> 
<li :class="{'activeClass': idClicked == IGII25015 }" id="IGII25015"><inscr id='IG II² 5015'>IG II² 5015 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7320' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2714'>Met21*</a></li> 
<li :class="{'activeClass': idClicked == IGII25020 }" id="IGII25020"><inscr id='IG II² 5020'>IG II² 5020 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7325' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1163'>Chthonic Gods</a></li> 
<li :class="{'activeClass': idClicked == IGII25021 }" id="IGII25021"><inscr id='IG II² 5021'>IG II² 5021 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7326' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/5021' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1471'>Dio39*</a></li> 
<li :class="{'activeClass': idClicked == IGII25022 }" id="IGII25022"><inscr id='IG II² 5022'>IG II² 5022 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7327' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1465'>Dio35*</a></li> 
<li :class="{'activeClass': idClicked == IGII25023 }" id="IGII25023"><inscr id='IG II² 5023'>IG II² 5023 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7328' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3299'>Pythochrestos Exegetes</a></li> 
<li :class="{'activeClass': idClicked == IGII25024 }" id="IGII25024"><inscr id='IG II² 5024'>IG II² 5024 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7329' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3910'>Zeu100*</a></li> 
<li :class="{'activeClass': idClicked == IGII25025 }" id="IGII25025"><inscr id='IG II² 5025'>IG II² 5025 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7328' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3886'>Zeu79*</a></li> 
<li :class="{'activeClass': idClicked == IGII25026 }" id="IGII25026"><inscr id='IG II² 5026'>IG II² 5026 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7331' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3664'>Thyechoos</a></li> 
<li :class="{'activeClass': idClicked == IGII25027 }" id="IGII25027"><inscr id='IG II² 5027'>IG II² 5027 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7330' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2777'>Nike Olympia</a></li> 
<li :class="{'activeClass': idClicked == IGII25028 }" id="IGII25028"><inscr id='IG II² 5028'>IG II² 5028 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7333' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1202'>Dadouchos</a></li> 
<li :class="{'activeClass': idClicked == IGII25029 }" id="IGII25029"><inscr id='IG II² 5029'>IG II² 5029 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7334' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP405'>Apo76*</a></li> 
<li :class="{'activeClass': idClicked == IGII25029A }" id="IGII25029A"><inscr id='IG II² 5029A'>IG II² 5029A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1267'>Demokratia</a></li> 
<li :class="{'activeClass': idClicked == IGII25030 }" id="IGII25030"><inscr id='IG II² 5030'>IG II² 5030 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7335' target='_blank'>PHI</a>] </inscr>-5031 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3475'>Strategos</a></li> 
<li :class="{'activeClass': idClicked == IGII25032 }" id="IGII25032"><inscr id='IG II² 5032'>IG II² 5032 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7337' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2390'>Keryx of the Areopagus Council</a></li> 
<li :class="{'activeClass': idClicked == IGII25033 }" id="IGII25033"><inscr id='IG II² 5033'>IG II² 5033 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7338' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2167'>Hieromnemon</a></li> 
<li :class="{'activeClass': idClicked == IGII25034 }" id="IGII25034"><inscr id='IG II² 5034'>IG II² 5034 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7338' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP886'>Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII25035 }" id="IGII25035"><inscr id='IG II² 5035'>IG II² 5035 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7340' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1820'>Hadrian Eleutheraios</a></li> 
<li :class="{'activeClass': idClicked == IGII25036 }" id="IGII25036"><inscr id='IG II² 5036'>IG II² 5036 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7341' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP433'>Archon</a></li> 
<li :class="{'activeClass': idClicked == IGII25037 }" id="IGII25037"><inscr id='IG II² 5037'>IG II² 5037 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7342' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP912'>Basileus</a></li> 
<li :class="{'activeClass': idClicked == IGII25038 }" id="IGII25038"><inscr id='IG II² 5038'>IG II² 5038 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7343' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3160'>Polemarch</a></li> 
<li :class="{'activeClass': idClicked == IGII25039 }" id="IGII25039"><inscr id='IG II² 5039'>IG II² 5039 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7344' target='_blank'>PHI</a>] </inscr>-5042 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3634'>Thesmothetai</a></li> 
<li :class="{'activeClass': idClicked == IGII25043 }" id="IGII25043"><inscr id='IG II² 5043'>IG II² 5043 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7348' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2166'>Hierokeryx</a></li> 
<li :class="{'activeClass': idClicked == IGII25044 }" id="IGII25044"><inscr id='IG II² 5044'>IG II² 5044 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7349' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2231'>Iakchos</a></li> 
<li :class="{'activeClass': idClicked == IGII25045 }" id="IGII25045"><inscr id='IG II² 5045'>IG II² 5045 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7350' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP620'>Ask50*</a></li> 
<li :class="{'activeClass': idClicked == IGII25046 }" id="IGII25046"><inscr id='IG II² 5046'>IG II² 5046 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7351' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3287'>Pyrphoros</a></li> 
<li :class="{'activeClass': idClicked == IGII25047 }" id="IGII25047"><inscr id='IG II² 5047'>IG II² 5047 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7352' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1272'>Demos</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1007'>Charites</a></li> 
<li :class="{'activeClass': idClicked == IGII25048 }" id="IGII25048"><inscr id='IG II² 5048'>IG II² 5048 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7353' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2391'>Keryx Panages</a></li> 
<li :class="{'activeClass': idClicked == IGII25049 }" id="IGII25049"><inscr id='IG II² 5049'>IG II² 5049 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7353' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1729'>Eupatrid Expounder</a></li> 
<li :class="{'activeClass': idClicked == IGII25050 }" id="IGII25050"><inscr id='IG II² 5050'>IG II² 5050 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7355' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1007'>Charites</a></li> 
<li :class="{'activeClass': idClicked == IGII25051 }" id="IGII25051"><inscr id='IG II² 5051'>IG II² 5051 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7356' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3210'>Poseidon Phytalmios</a></li> 
<li :class="{'activeClass': idClicked == IGII25052 }" id="IGII25052"><inscr id='IG II² 5052'>IG II² 5052 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7357' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP346'>Apo29*</a></li> 
<li :class="{'activeClass': idClicked == IGII25053 }" id="IGII25053"><inscr id='IG II² 5053'>IG II² 5053 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7358' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2168'>Hierophant</a></li> 
<li :class="{'activeClass': idClicked == IGII25054 }" id="IGII25054"><inscr id='IG II² 5054'>IG II² 5054 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7359' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3809'>Zeu16*</a></li> 
<li :class="{'activeClass': idClicked == IGII25055 }" id="IGII25055"><inscr id='IG II² 5055'>IG II² 5055 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7360' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3803'>Zeu12*</a></li> 
<li :class="{'activeClass': idClicked == IGII25056 }" id="IGII25056"><inscr id='IG II² 5056'>IG II² 5056 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7361' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1484'>Dio49*</a></li> 
<li :class="{'activeClass': idClicked == IGII25057 }" id="IGII25057"><inscr id='IG II² 5057'>IG II² 5057 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7362' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> 36</li> 
<li :class="{'activeClass': idClicked == IGII25058 }" id="IGII25058"><inscr id='IG II² 5058'>IG II² 5058 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7363' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a> on the Akr.</li> 
<li :class="{'activeClass': idClicked == IGII25059 }" id="IGII25059"><inscr id='IG II² 5059'>IG II² 5059 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7364' target='_blank'>PHI</a>] </inscr> - Eukleia and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1728'>Eunomia</a></li> 
<li :class="{'activeClass': idClicked == IGII25060 }" id="IGII25060"><inscr id='IG II² 5060'>IG II² 5060 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7365' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1485'>Dio50*</a></li> 
<li :class="{'activeClass': idClicked == IGII25061 }" id="IGII25061"><inscr id='IG II² 5061'>IG II² 5061 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7366' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP386'>Apo61*</a></li> 
<li :class="{'activeClass': idClicked == IGII25062 }" id="IGII25062"><inscr id='IG II² 5062'>IG II² 5062 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7367' target='_blank'>PHI</a>] </inscr> - Antinoos</li> 
<li :class="{'activeClass': idClicked == IGII25063 }" id="IGII25063"><inscr id='IG II² 5063'>IG II² 5063 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7368' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP697'>Ath22*</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3915'>Zeu103*</a></li> 
<li :class="{'activeClass': idClicked == IGII25063A }" id="IGII25063A"><inscr id='IG II² 5063A'>IG II² 5063A</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP697'>Ath22*</a></li> 
<li :class="{'activeClass': idClicked == IGII25064 }" id="IGII25064"><inscr id='IG II² 5064'>IG II² 5064 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7369' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3890'>Zeu83*</a></li> 
<li :class="{'activeClass': idClicked == IGII25065 }" id="IGII25065"><inscr id='IG II² 5065'>IG II² 5065 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7370' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3734'>Twelve Gods</a></li> 
<li :class="{'activeClass': idClicked == IGII25066 }" id="IGII25066"><inscr id='IG II² 5066'>IG II² 5066 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7371' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3902'>Zeu92*</a></li> 
<li :class="{'activeClass': idClicked == IGII25067 }" id="IGII25067"><inscr id='IG II² 5067'>IG II² 5067 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7372' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2728'>Muses</a></li> 
<li :class="{'activeClass': idClicked == IGII25068 }" id="IGII25068"><inscr id='IG II² 5068'>IG II² 5068 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7373' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP621'>Ask51*</a></li> 
<li :class="{'activeClass': idClicked == IGII25069 }" id="IGII25069"><inscr id='IG II² 5069'>IG II² 5069 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7374' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1885'>Hephaistos</a></li> 
<li :class="{'activeClass': idClicked == IGII25070 }" id="IGII25070"><inscr id='IG II² 5070'>IG II² 5070 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7375' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == IGII25071 }" id="IGII25071"><inscr id='IG II² 5071'>IG II² 5071 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7376' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a></li> 
<li :class="{'activeClass': idClicked == IGII25072 }" id="IGII25072"><inscr id='IG II² 5072'>IG II² 5072 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7377' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3888'>Zeu81*</a></li> 
<li :class="{'activeClass': idClicked == IGII25073 }" id="IGII25073"><inscr id='IG II² 5073'>IG II² 5073 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7378' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP364'>Apo42*</a></li> 
<li :class="{'activeClass': idClicked == IGII25074 }" id="IGII25074"><inscr id='IG II² 5074'>IG II² 5074 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7379' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1216'>Demeter</a> and <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3067'>Pherephatte</a></li> 
<li :class="{'activeClass': idClicked == IGII25075 }" id="IGII25075"><inscr id='IG II² 5075'>IG II² 5075 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7380' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3923'>Zeu109*</a></li> 
<li :class="{'activeClass': idClicked == IGII25076 }" id="IGII25076"><inscr id='IG II² 5076'>IG II² 5076 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7381' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3596'>Theseus</a></li> 
<li :class="{'activeClass': idClicked == IGII25077 }" id="IGII25077"><inscr id='IG II² 5077'>IG II² 5077 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7382' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2535'>Lithophoros</a></li> 
<li :class="{'activeClass': idClicked == IGII25078 }" id="IGII25078"><inscr id='IG II² 5078'>IG II² 5078 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7383' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1439'>Dio16*</a></li> 
<li :class="{'activeClass': idClicked == IGII25079 }" id="IGII25079"><inscr id='IG II² 5079'>IG II² 5079 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7384' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP345'>Apo28*</a></li> 
<li :class="{'activeClass': idClicked == IGII25080 }" id="IGII25080"><inscr id='IG II² 5080'>IG II² 5080 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7385' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP862'>Attalos I</a> and II <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1389'>Diogenes Euergetes</a></li> 
<li :class="{'activeClass': idClicked == IGII25081 }" id="IGII25081"><inscr id='IG II² 5081'>IG II² 5081 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7386' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP411'>Apo81*</a></li> 
<li :class="{'activeClass': idClicked == IGII25082 }" id="IGII25082"><inscr id='IG II² 5082'>IG II² 5082 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7387' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP420'>Apo89*</a></li> 
<li :class="{'activeClass': idClicked == IGII25085 }" id="IGII25085"><inscr id='IG II² 5085'>IG II² 5085 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7390' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3887'>Zeu80*</a></li> 
<li :class="{'activeClass': idClicked == IGII25087 }" id="IGII25087"><inscr id='IG II² 5087'>IG II² 5087 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7392' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2534'>Lithokopoi</a></li> 
<li :class="{'activeClass': idClicked == IGII25089 }" id="IGII25089"><inscr id='IG II² 5089'>IG II² 5089 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7394' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3070'>Philemon</a></li> 
<li :class="{'activeClass': idClicked == IGII25090 }" id="IGII25090"><inscr id='IG II² 5090'>IG II² 5090 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7395' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII25092 }" id="IGII25092"><inscr id='IG II² 5092'>IG II² 5092 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7397' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2476'>Ladamea</a></li> 
<li :class="{'activeClass': idClicked == IGII25093 }" id="IGII25093"><inscr id='IG II² 5093'>IG II² 5093 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7398' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1874'>Helios</a></li> 
<li :class="{'activeClass': idClicked == IGII25094 }" id="IGII25094"><inscr id='IG II² 5094'>IG II² 5094 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7399' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> Dio2*</li> 
<li :class="{'activeClass': idClicked == IGII25095 }" id="IGII25095"><inscr id='IG II² 5095'>IG II² 5095 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7400' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP228'>Antonia</a> (Minor?)</li> 
<li :class="{'activeClass': idClicked == IGII25096 }" id="IGII25096"><inscr id='IG II² 5096'>IG II² 5096 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7401' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII25097 }" id="IGII25097"><inscr id='IG II² 5097'>IG II² 5097 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7402' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3076'>Philinos</a></li> 
<li :class="{'activeClass': idClicked == IGII25098 }" id="IGII25098"><inscr id='IG II² 5098'>IG II² 5098 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7403' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1016'>Chloe Themis</a></li> 
<li :class="{'activeClass': idClicked == IGII25099 }" id="IGII25099"><inscr id='IG II² 5099'>IG II² 5099 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7404' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1538'>Eileithyia</a></li> 
<li :class="{'activeClass': idClicked == IGII25100 }" id="IGII25100"><inscr id='IG II² 5100'>IG II² 5100 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7405' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2793'>Nymphe</a></li> 
<li :class="{'activeClass': idClicked == IGII25101 }" id="IGII25101"><inscr id='IG II² 5101'>IG II² 5101 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7406' target='_blank'>PHI</a>] </inscr> - Julia - - -</li> 
<li :class="{'activeClass': idClicked == IGII25102 }" id="IGII25102"><inscr id='IG II² 5102'>IG II² 5102 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7407' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII25103 }" id="IGII25103"><inscr id='IG II² 5103'>IG II² 5103 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7408' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP824'>Ath114*</a></li> 
<li :class="{'activeClass': idClicked == IGII25104 }" id="IGII25104"><inscr id='IG II² 5104'>IG II² 5104 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7409' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3082'>Philippe</a> II</li> 
<li :class="{'activeClass': idClicked == IGII25105 }" id="IGII25105"><inscr id='IG II² 5105'>IG II² 5105 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7410' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2612'>Megiste</a></li> 
<li :class="{'activeClass': idClicked == IGII25107 }" id="IGII25107"><inscr id='IG II² 5107'>IG II² 5107 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7412' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2612'>Megiste</a></li> 
<li :class="{'activeClass': idClicked == IGII25109 }" id="IGII25109"><inscr id='IG II² 5109'>IG II² 5109 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7414' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3517'>Themis</a></li> 
<li :class="{'activeClass': idClicked == IGII25111 }" id="IGII25111"><inscr id='IG II² 5111'>IG II² 5111 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7416' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2169'>Hierophantid</a></li> 
<li :class="{'activeClass': idClicked == IGII25113 }" id="IGII25113"><inscr id='IG II² 5113'>IG II² 5113 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7418' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1404'>Dione</a></li> 
<li :class="{'activeClass': idClicked == IGII25114 }" id="IGII25114"><inscr id='IG II² 5114'>IG II² 5114 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7419' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3312'>Roma and Augustus</a></li> 
<li :class="{'activeClass': idClicked == IGII25115 }" id="IGII25115"><inscr id='IG II² 5115'>IG II² 5115 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7420' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP269'>Aph24*</a></li> 
<li :class="{'activeClass': idClicked == IGII25116 }" id="IGII25116"><inscr id='IG II² 5116'>IG II² 5116 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7421' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP539'>Art42*</a></li> 
<li :class="{'activeClass': idClicked == IGII25117 }" id="IGII25117"><inscr id='IG II² 5117'>IG II² 5117 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7422' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1236'>Demeter Achaia</a></li> 
<li :class="{'activeClass': idClicked == IGII25118 }" id="IGII25118"><inscr id='IG II² 5118'>IG II² 5118 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7423' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP744'>Ath57*</a></li> 
<li :class="{'activeClass': idClicked == IGII25119 }" id="IGII25119"><inscr id='IG II² 5119'>IG II² 5119 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7424' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP281'>Aph34*</a></li> 
<li :class="{'activeClass': idClicked == IGII25120 }" id="IGII25120"><inscr id='IG II² 5120'>IG II² 5120 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7425' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP748'>Ath59*</a></li> 
<li :class="{'activeClass': idClicked == IGII25122 }" id="IGII25122"><inscr id='IG II² 5122'>IG II² 5122 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7427' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP128'>Alkia</a></li> 
<li :class="{'activeClass': idClicked == IGII25123 }" id="IGII25123"><inscr id='IG II² 5123'>IG II² 5123 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7428' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2476'>Ladamea</a></li> 
<li :class="{'activeClass': idClicked == IGII25124 }" id="IGII25124"><inscr id='IG II² 5124'>IG II² 5124 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7429' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2487'>Lamidion</a></li> 
<li :class="{'activeClass': idClicked == IGII25125 }" id="IGII25125"><inscr id='IG II² 5125'>IG II² 5125 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7430' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2400'>Kleariste</a></li> 
<li :class="{'activeClass': idClicked == IGII25126 }" id="IGII25126"><inscr id='IG II² 5126'>IG II² 5126 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7431' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> Dio3* after</li> 
<li :class="{'activeClass': idClicked == IGII25126 }" id="IGII25126"><inscr id='IG II² 5126'>IG II² 5126 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7431' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP228'>Antonia</a></li> 
<li :class="{'activeClass': idClicked == IGII25127 }" id="IGII25127"><inscr id='IG II² 5127'>IG II² 5127 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7432' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3456'>Stephanephoros</a></li> 
<li :class="{'activeClass': idClicked == IGII25128 }" id="IGII25128"><inscr id='IG II² 5128'>IG II² 5128 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7433' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2219'>Hymnetriai Philidiou</a></li> 
<li :class="{'activeClass': idClicked == IGII25129 }" id="IGII25129"><inscr id='IG II² 5129'>IG II² 5129 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7434' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1240'>Demeter Chloe</a></li> 
<li :class="{'activeClass': idClicked == IGII25130 }" id="IGII25130"><inscr id='IG II² 5130'>IG II² 5130 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7435' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1765'>Ge Themis</a></li> 
<li :class="{'activeClass': idClicked == IGII25131 }" id="IGII25131"><inscr id='IG II² 5131'>IG II² 5131 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7437' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1245'>Demeter Kourotrophos</a>, <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3011'>Peitho</a></li> 
<li :class="{'activeClass': idClicked == IGII25132 }" id="IGII25132"><inscr id='IG II² 5132'>IG II² 5132 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7452' target='_blank'>PHI</a>] </inscr> - Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == IGII25133 }" id="IGII25133"><inscr id='IG II² 5133'>IG II² 5133 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7453' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2827'>Oinanthe</a></li> 
<li :class="{'activeClass': idClicked == IGII25134 }" id="IGII25134"><inscr id='IG II² 5134'>IG II² 5134 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7454' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2699'>Met9*</a></li> 
<li :class="{'activeClass': idClicked == IGII25136 }" id="IGII25136"><inscr id='IG II² 5136'>IG II² 5136 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7456' target='_blank'>PHI</a>] </inscr> - Thesmophoroi</li> 
<li :class="{'activeClass': idClicked == IGII25137 }" id="IGII25137"><inscr id='IG II² 5137'>IG II² 5137 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7457' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2676'>Moirai</a></li> 
<li :class="{'activeClass': idClicked == IGII25138 }" id="IGII25138"><inscr id='IG II² 5138'>IG II² 5138 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7458' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2612'>Megiste</a></li> 
<li :class="{'activeClass': idClicked == IGII25140 }" id="IGII25140"><inscr id='IG II² 5140'>IG II² 5140 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7460' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP534'>Art37*</a></li> 
<li :class="{'activeClass': idClicked == IGII25143 }" id="IGII25143"><inscr id='IG II² 5143'>IG II² 5143 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7463' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2746'>Nemesis</a></li> 
<li :class="{'activeClass': idClicked == IGII25144 }" id="IGII25144"><inscr id='IG II² 5144'>IG II² 5144 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7464' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a></li> 
<li :class="{'activeClass': idClicked == IGII25145 }" id="IGII25145"><inscr id='IG II² 5145'>IG II² 5145 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7465' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2151'>Hestia</a></li> 
<li :class="{'activeClass': idClicked == IGII25146 }" id="IGII25146"><inscr id='IG II² 5146'>IG II² 5146 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7466' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP508'>Art17*</a></li> 
<li :class="{'activeClass': idClicked == IGII25147 }" id="IGII25147"><inscr id='IG II² 5147'>IG II² 5147 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7467' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP86'>Aidos</a></li> 
<li :class="{'activeClass': idClicked == IGII25148 }" id="IGII25148"><inscr id='IG II² 5148'>IG II² 5148 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7468' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP270'>Aph25*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1905'>Hera</a> Elliminia</li> 
<li :class="{'activeClass': idClicked == IGII25149 }" id="IGII25149"><inscr id='IG II² 5149'>IG II² 5149 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7469' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP302'>Aph49*</a></li> 
<li :class="{'activeClass': idClicked == IGII25150 }" id="IGII25150"><inscr id='IG II² 5150'>IG II² 5150 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7470' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1844'>Hebe</a></li> 
<li :class="{'activeClass': idClicked == IGII25151 }" id="IGII25151"><inscr id='IG II² 5151'>IG II² 5151 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7471' target='_blank'>PHI</a>] </inscr> - Deipnophorai</li> 
<li :class="{'activeClass': idClicked == IGII25152 }" id="IGII25152"><inscr id='IG II² 5152'>IG II² 5152 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7472' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2446'>Kourotrophos</a></li> 
<li :class="{'activeClass': idClicked == IGII25153 }" id="IGII25153"><inscr id='IG II² 5153'>IG II² 5153 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7473' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1216'>Demeter</a> Kourotr.</li> 
<li :class="{'activeClass': idClicked == IGII25154 }" id="IGII25154"><inscr id='IG II² 5154'>IG II² 5154 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7474' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1844'>Hebe</a></li> 
<li :class="{'activeClass': idClicked == IGII25155 }" id="IGII25155"><inscr id='IG II² 5155'>IG II² 5155 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7463' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1248'>Demeter Phrearoos</a></li> 
<li :class="{'activeClass': idClicked == IGII25156 }" id="IGII25156"><inscr id='IG II² 5156'>IG II² 5156 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7476' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2510'>Leto</a></li> 
<li :class="{'activeClass': idClicked == IGII25157 }" id="IGII25157"><inscr id='IG II² 5157'>IG II² 5157 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7477' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3187'>Poseidon</a> on the Akr.</li> 
<li :class="{'activeClass': idClicked == IGII25158 }" id="IGII25158"><inscr id='IG II² 5158'>IG II² 5158 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7478' target='_blank'>PHI</a>] </inscr> - Zakorou, Dio[kleous]</li> 
<li :class="{'activeClass': idClicked == IGII25159 }" id="IGII25159"><inscr id='IG II² 5159'>IG II² 5159 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7479' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3023'>Penteteris</a></li> 
<li :class="{'activeClass': idClicked == IGII25161 }" id="IGII25161"><inscr id='IG II² 5161'>IG II² 5161 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7481' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2541'>Livia</a></li> 
<li :class="{'activeClass': idClicked == IGII25163 }" id="IGII25163"><inscr id='IG II² 5163'>IG II² 5163 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7483' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP698'>Ath23*</a></li> 
<li :class="{'activeClass': idClicked == IGII25164 }" id="IGII25164"><inscr id='IG II² 5164'>IG II² 5164 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7484' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3515'>Theano</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3592'>Theoxena</a></li> 
<li :class="{'activeClass': idClicked == IGII25165 }" id="IGII25165"><inscr id='IG II² 5165'>IG II² 5165 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7485' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM122">122</a></li> 
<li :class="{'activeClass': idClicked == IGII25166 }" id="IGII25166"><inscr id='IG II² 5166'>IG II² 5166 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7486' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP942'>Boutes</a></li> 
<li :class="{'activeClass': idClicked == IGII25167 }" id="IGII25167"><inscr id='IG II² 5167'>IG II² 5167 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7487' target='_blank'>PHI</a>] </inscr> - Harmodios</li> 
<li :class="{'activeClass': idClicked == IGII25168 }" id="IGII25168"><inscr id='IG II² 5168'>IG II² 5168 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7488' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP634'>Astydamas</a> </li> 
<li :class="{'activeClass': idClicked == IGII25169 }" id="IGII25169"><inscr id='IG II² 5169'>IG II² 5169 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7489' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3586'>Theokleides</a></li> 
<li :class="{'activeClass': idClicked == IGII25170 }" id="IGII25170"><inscr id='IG II² 5170'>IG II² 5170 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7490' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3287'>Pyrphoros</a></li> 
<li :class="{'activeClass': idClicked == IGII25176 }" id="IGII25176"><inscr id='IG II² 5176'>IG II² 5176 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7489' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1179'>Claudius</a></li> 
<li :class="{'activeClass': idClicked == IGII25180 }" id="IGII25180"><inscr id='IG II² 5180'>IG II² 5180 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7500' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP564'>Ask7*</a></li> 
<li :class="{'activeClass': idClicked == IGII25181 }" id="IGII25181"><inscr id='IG II² 5181'>IG II² 5181 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7501' target='_blank'>PHI</a>] </inscr>-5182 - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == IGII25183 }" id="IGII25183"><inscr id='IG II² 5183'>IG II² 5183 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7503' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP919'>Blaute</a></li> 
<li :class="{'activeClass': idClicked == IGII25184 }" id="IGII25184"><inscr id='IG II² 5184'>IG II² 5184 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7504' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3469'>Stoics</a></li> 
<li :class="{'activeClass': idClicked == IGII25185 }" id="IGII25185"><inscr id='IG II² 5185'>IG II² 5185 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7505' target='_blank'>PHI</a>] </inscr> - Hadrian</li> 
<li :class="{'activeClass': idClicked == IGII25194 }" id="IGII25194"><inscr id='IG II² 5194'>IG II² 5194 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7514' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM426">426</a></li> 
<li :class="{'activeClass': idClicked == IGII25195 }" id="IGII25195"><inscr id='IG II² 5195'>IG II² 5195 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7515' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2728'>Muses</a></li> 
<li :class="{'activeClass': idClicked == IGII25199 }" id="IGII25199"><inscr id='IG II² 5199'>IG II² 5199 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7519' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3213'>Post-Herulian Wall</a></li> 
<li :class="{'activeClass': idClicked == IGII25201 }" id="IGII25201"><inscr id='IG II² 5201'>IG II² 5201 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7521' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1986'>Herculius</a></li> 
<li :class="{'activeClass': idClicked == IGII25202 }" id="IGII25202"><inscr id='IG II² 5202'>IG II² 5202 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7522' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == IGII25204 }" id="IGII25204"><inscr id='IG II² 5204'>IG II² 5204 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7518' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP625'>Asty</a></li> 
<li :class="{'activeClass': idClicked == IGII25205 }" id="IGII25205"><inscr id='IG II² 5205'>IG II² 5205 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7525' target='_blank'>PHI</a>] </inscr> - Arcadius</li> 
<li :class="{'activeClass': idClicked == IGII25206 }" id="IGII25206"><inscr id='IG II² 5206'>IG II² 5206 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7526' target='_blank'>PHI</a>] </inscr> - Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM24">24</a> Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM27">27</a></li> 
<li :class="{'activeClass': idClicked == IGII25208 }" id="IGII25208"><inscr id='IG II² 5208'>IG II² 5208 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7528' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a></li> 
<li :class="{'activeClass': idClicked == IGII25210 }" id="IGII25210"><inscr id='IG II² 5210'>IG II² 5210 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7530' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP231'>Antoninus Pius</a></li> 
<li :class="{'activeClass': idClicked == IGII25214 }" id="IGII25214"><inscr id='IG II² 5214'>IG II² 5214 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7534' target='_blank'>PHI</a>] </inscr> - Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII25220 }" id="IGII25220"><inscr id='IG II² 5220'>IG II² 5220 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7540' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3393'>Silenos of Rhegion</a></li> 
<li :class="{'activeClass': idClicked == IGII25221 }" id="IGII25221"><inscr id='IG II² 5221'>IG II² 5221 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7541' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/5221' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem10*</li> 
<li :class="{'activeClass': idClicked == IGII25222 }" id="IGII25222"><inscr id='IG II² 5222'>IG II² 5222 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7542' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/5222' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem11*, Sem40*</li> 
<li :class="{'activeClass': idClicked == IGII25224 }" id="IGII25224"><inscr id='IG II² 5224'>IG II² 5224 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7544' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3594'>Thersandros and Simylos</a></li> 
<li :class="{'activeClass': idClicked == IGII25226 }" id="IGII25226"><inscr id='IG II² 5226'>IG II² 5226 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/7546' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem8*</li> 
<li :class="{'activeClass': idClicked == IGII25227a }" id="IGII25227a"><inscr id='IG II² 5227a'>IG II² 5227a</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP994'>Chairippos</a></li> 
<li :class="{'activeClass': idClicked == IGII25813 }" id="IGII25813"><inscr id='IG II² 5813'>IG II² 5813</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2579'>Lysimachides</a></li> 
<li :class="{'activeClass': idClicked == IGII25962 }" id="IGII25962"><inscr id='IG II² 5962'>IG II² 5962</inscr>-5964 - Daidalidai</li> 
<li :class="{'activeClass': idClicked == IGII26041 }" id="IGII26041"><inscr id='IG II² 6041'>IG II² 6041</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3307'>Rhoimetalkes III</a></li> 
<li :class="{'activeClass': idClicked == IGII26043 }" id="IGII26043"><inscr id='IG II² 6043'>IG II² 6043</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2678'>Moirokles</a></li> 
<li :class="{'activeClass': idClicked == IGII26153 }" id="IGII26153"><inscr id='IG II² 6153'>IG II² 6153</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3477'>Straton</a></li> 
<li :class="{'activeClass': idClicked == IGII26217 }" id="IGII26217"><inscr id='IG II² 6217'>IG II² 6217 [<a v-on:click.prevent="replaceLink"  href='https://www.atticinscriptions.com/inscription/IGII2/6217' target='_blank'>AOI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1359'>Dexileos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP906'>Bakchios</a> </li> 
<li :class="{'activeClass': idClicked == IGII26323 }" id="IGII26323"><inscr id='IG II² 6323'>IG II² 6323</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1649'>Eridanos River</a></li> 
<li :class="{'activeClass': idClicked == IGII26398 }" id="IGII26398"><inscr id='IG II² 6398'>IG II² 6398</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1788'>Habryllis</a></li> 
<li :class="{'activeClass': idClicked == IGII26791 }" id="IGII26791"><inscr id='IG II² 6791'>IG II² 6791</inscr> - Herodes <a v-on:click.prevent="replaceLink"  href='NPP.html#NP873'>Atticus</a></li> 
<li :class="{'activeClass': idClicked == IGII27045 }" id="IGII27045"><inscr id='IG II² 7045'>IG II² 7045</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1706'>Euegoros</a></li> 
<li :class="{'activeClass': idClicked == IGII27400 }" id="IGII27400"><inscr id='IG II² 7400'>IG II² 7400</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2184'>Hipparete</a></li> 
<li :class="{'activeClass': idClicked == IGII27967 }" id="IGII27967"><inscr id='IG II² 7967'>IG II² 7967</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1991'>Hermaios</a> </li> 
<li :class="{'activeClass': idClicked == IGII28388 }" id="IGII28388"><inscr id='IG II² 8388'>IG II² 8388</inscr> - Antipatros </li> 
<li :class="{'activeClass': idClicked == IGII29069 }" id="IGII29069"><inscr id='IG II² 9069'>IG II² 9069</inscr> - Klegora</li> 
<li :class="{'activeClass': idClicked == IGII29898 }" id="IGII29898"><inscr id='IG II² 9898'>IG II² 9898</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3504'>Telesphoros</a></li> 
<li :class="{'activeClass': idClicked == IGII210678 }" id="IGII210678"><inscr id='IG II² 10678'>IG II² 10678</inscr> - Jewish Mon.</li> 
<li :class="{'activeClass': idClicked == IGII210813 }" id="IGII210813"><inscr id='IG II² 10813'>IG II² 10813 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/13290' target='_blank'>PHI</a>] </inscr> - Chr. Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1138'>CM71*</a></li> 
<li :class="{'activeClass': idClicked == IGII211030 }" id="IGII211030"><inscr id='IG II² 11030'>IG II² 11030</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1783'>Gymnasium Boundary Stone</a></li> 
<li :class="{'activeClass': idClicked == IGII211120 }" id="IGII211120"><inscr id='IG II² 11120'>IG II² 11120</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1266'>Demokrates</a> </li> 
<li :class="{'activeClass': idClicked == IGII211370 }" id="IGII211370"><inscr id='IG II² 11370'>IG II² 11370</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1700'>Euboulos</a></li> 
<li :class="{'activeClass': idClicked == IGII211606A }" id="IGII211606A"><inscr id='IG II² 11606A'>IG II² 11606A</inscr> - Heraklios 1</li> 
<li :class="{'activeClass': idClicked == IGII21678 }" id="IGII21678"><inscr id='IG II² 1678'>IG II² 1678 [<a v-on:click.prevent="replaceLink"  href='https://epigraphy.packhum.org/text/3904' target='_blank'>PHI</a>] </inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2480'>Lakedaimonians</a> </li> 
<li :class="{'activeClass': idClicked == IGII211952 }" id="IGII211952"><inscr id='IG II² 11952'>IG II² 11952</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2473'>Lachares</a>, sophist</li> 
<li :class="{'activeClass': idClicked == IGII212196 }" id="IGII212196"><inscr id='IG II² 12196'>IG II² 12196</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2738'>Myrrhine</a></li> 
<li :class="{'activeClass': idClicked == IGII212516 }" id="IGII212516"><inscr id='IG II² 12516'>IG II² 12516</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2803'>Nym5*</a></li> 
<li :class="{'activeClass': idClicked == IGII212764 }" id="IGII212764"><inscr id='IG II² 12764'>IG II² 12764</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3501'>Telekles</a></li> 
<li :class="{'activeClass': idClicked == IGII212767A }" id="IGII212767A">IG II² 12767A - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3131'>Ploutarchos</a></li> 
<li :class="{'activeClass': idClicked == IGII212778 }" id="IGII212778"><inscr id='IG II² 12778'>IG II² 12778</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3503'>Telephanes</a></li> 
<li :class="{'activeClass': idClicked == IGII213013 }" id="IGII213013"><inscr id='IG II² 13013'>IG II² 13013</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3109'>Phrasikleia</a></li> 
<li :class="{'activeClass': idClicked == IGII213194 }" id="IGII213194"><inscr id='IG II² 13194'>IG II² 13194</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3169'>Polydeukion</a></li> 
<li :class="{'activeClass': idClicked == IGII213209 }" id="IGII213209"><inscr id='IG II² 13209'>IG II² 13209</inscr> - Kasianos <a v-on:click.prevent="replaceLink"  href='NPP.html#NP216'>Antiochos</a></li> 
<li :class="{'activeClass': idClicked == IGII213211 }" id="IGII213211"><inscr id='IG II² 13211'>IG II² 13211</inscr> - <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1649'>Eridanos River</a></li> 
<li :class="{'activeClass': idClicked == IGII213242 }" id="IGII213242"><inscr id='IG II² 13242'>IG II² 13242</inscr> - Metrobios and others</li> 
</ul> 


</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    