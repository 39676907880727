<template>  
<div class="about-content ">
  <h1>Judith Binder</h1>  
  <div class="card"> 
    <div class="flex">
      <p style="flex-basis: 400px; max-height:200px; position:relative;">
        <img  id="signature" alt="Judith Binder" src="@/assets/judith-binder.jpg"    >
      </p>

      <Divider layout="vertical" />

      <p style="flex-basis: 70%;">
Dr. Judith Perlzweig Binder (1921-2013) settled in Athens in 1952. After a long-term collaboration with the American School of Classical Studies, she remained Senior Associate Member of the School until 2008 when she left for Germany. Binder studied the lamps of the Agora (<i>Lamps of the Roman Period: First to Seventh Centuries after Christ - The Athenian Agora VII</i>, 1961), however she is mostly known for her deep knowledge of Athenian topography.
      </p> 
    </div>
  </div>  



<p class="extra-text">Her lifetime project <i>The Monuments and Sites of Athens: A Sourcebook</i>, which she entrusted to us unpublished when she left Athens, is essentially a research tool that collects all sources (ancient written sources and archaeological data) on Athenian topography and the history of archaeological research in the city.</p> 
<p class="extra-text">Judith Binder draws her information on the rescue excavations in Athens from the <i>Archaiologikon Deltion</i>. In this sense, her entries overlap partly with the project <a href="https://dipylon.org/en/2018/06/12/mapping-the-antiquities-athens/" target="_blank">Mapping Ancient Athens</a>. After completing the latter at the end of 2019, the two projects could be interconnected so that Binder's entries obtain a spatial dimension on the digital map of ancient Athens.</p> 
<p class="extra-text">Following the initiative of our collaborator epigraphist Robert Pitt and with the valuable help of historian/archaeologist Brady Kiesling, now Binder’s work is fully accessible and freely available in its hypertext version.</p>
<p class="extra-text">
  

  Binder's entries are now electronically linked to Pausanias’ <i>Description of Greece</i> (both in the original text and in English). Also, references to other ancient texts or inscriptions 
  in her manuscript are linked to corresponding online resources, such as the Packard Humanities Institute (PHI) Greek Inscriptions. 
  Furthermore, there are links to the relevant pages in <i>Hesperia </i>and <i>The Athenian Agora</i> series, as well as to the geographical 
  locations in the <a href="https://topostext.org/" target="_blank">ToposText</a> app. 
  There are also internal links for easier navigation between Binder’s entries and the bibliography. It should be noted, however, that her bibliography was last updated in 2005. 
</p>
<p class="extra-text extra-text-last extra-text-center">
  <small>
    <p class="extra-text-center">
  <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
    <img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
  </a>
  </p>
<p class="extra-text-center">
  <span xmlns:dct="http://purl.org/dc/terms/" href="http://purl.org/dc/dcmitype/Text" property="dct:title" rel="dct:type">
    The Monuments and Sites of Athens: A Sourcebook
  </span> by  
    Judith Binder 
  is licensed under a 
  <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-NonCommercial 4.0 International License</a>.
</p>
</small>
</p>
 

</div>
</template>

<script> 

export default {
  name: 'About', 
  setup(){  
  }
}
</script>

<style scoped>
    .about-content{ 
      display: block;
      margin-top: 1px;
      width: 100%;
      height: calc(100vh - 161px);
      overflow-y: scroll;
    }


    .card {
        background: var(--surface-card);
        padding: 2rem;
        border-radius: 10px; 
    }

    .card p{
      text-align: left;
    }

    .extra-text{
      padding: 0 32px;
      margin-top: 0;
      text-align: left;
    }

    .extra-text-last{ 
      margin-top: 120px; 
      border-top: 1px solid lightgray;
      padding-top: 20px;
    }

    .extra-text-center{
      width: 90%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    #signature{
      width: 90%;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 750px){
      .flex{
        display: block!important;
      } 
      #signature{
        width: 65%;
      }
    } 
</style>
