<template>   
<span>
<span style="position:sticky;"></span>
<button id="scrollButton" @click="scrollUp" ><i class="pi pi-arrow-up"></i></button> 
  <splitpanes class="default-theme" @resize="smallPanelSize = $event[0].size" :horizontal=splitlayout>
    <pane :size=smallPanelSize>
      <div style="position:sticky;top:0;background-color:white;">

            <div class="flex">
              <span :style="{'flex-basis':textFlexBasis}">
                <vue3-simple-typeahead
                  id="typeahead_id"
                  ref="typeahead_ref"
                  placeholder="Search chapters and terms"
                  :items=baritems
                  :minInputLength="1" 
                  :itemProjection="itemProjectionFunction" 
                  @onBlur="typeheadblur"
                  @selectItem="selectItemEventHandler" 
                  @onInput="typeheadinput"
                > 
                </vue3-simple-typeahead>
              </span>

              <span :style="{'flex-basis':buttonFlexBasis}">
                <button id="fixSizeVertical" @click="panelResizeVertical"><i class="pi pi-chevron-up"></i></button>
              </span> 
            </div>
        
      </div>

      <Panel :toggleable="true" v-for="i in items" :key="i.title" >
        <template  v-slot:header class="chapters-title" > 
          <a v-on:click.prevent="replaceLink" :href="i.href" >{{i.title}}</a>
        </template>
        <ul class="chapters-ul">
          <li v-for="s in i.secondary" :key="s.title">
            <a v-on:click.prevent="replaceLink" :href="s.href">{{s.title}}</a> 
          </li>
        </ul>
      </Panel>
    </pane>
    <pane :size="100 - smallPanelSize">
      <router-view/>  
    </pane>
  </splitpanes>  
 </span>
</template>

<script>  
import { useRoute, useRouter } from 'vue-router'  
import { ref, onMounted, onUnmounted } from 'vue'; 
import {useReplaceLinkClick} from '@/composables/handleClick.js' 

export default {
  name: 'SourceBook',  
  setup(){

    let smallPanelSize = ref(30) 
    let typeahead_ref = ref('') 
    let splitlayout = ref(false)
    let textFlexBasis = ref('100%')
    let buttonFlexBasis = ref('0%')
    
    

    const items = ref([
      {
        href:"Forward.html#F1", title:"FOREWORD",
        secondary:[
          {  href:"Forward.html#F4", title:"INTRODUCTION"} ,
          {  href:"Forward.html#F4", title:"The general scheme "} ,
          {  href:"Forward.html#F62", title:"Documentation "} ,
          {  href:"Forward.html#F99", title:"Some matters of terminology "} ,
          {  href:"Forward.html#F160", title:"Principles of selection"} ,
          {  href:"Forward.html#F166", title:"Coverage of Monuments and sites "} ,
          {  href:"Forward.html#F295", title:"Coverage of Place-names "} ,
          {  href:"Forward.html#F299", title:"Coverage of cults "}  ,
          {  href:"Forward.html#F306", title:"Christian Athens "}  ,
          {  href:"Forward.html#F312", title:"Territorial Limits "} ,
          {  href:"Forward.html#F314", title:"Acknowledgements"} ,
        ]
      },
      {
        href:"NPP.html", title:"MONUMENTS AND SITES SOURCEBOOK : PART I: MONUMENTS, SITES, PLACE-NAMES AND CULTS WITH CLEAR-CUT NAMES IN ALPHABETICAL ORDER",
        secondary:[
          { href:"NPP.html#NP2", title:"ABECEDARIA" } , 
          { href:"NPP.html#NP7", title:"ACADEMY" } , 
          { href:"NPP.html#NP20", title:"ACROPOLIS" } , 
          { href:"NPP.html#NP39", title:"AGLAUROS" } , 
          { href:"NPP.html#NP45", title:"AGORA" } , 
          { href:"NPP.html#NP82", title:"AIAKEION" } , 
          { href:"NPP.html#NP102", title:"AKADEMIA" } , 
          { href:"NPP.html#NP118", title:"ALEXANDER THE GREAT" } , 
          { href:"NPP.html#NP243", title:"APHRODITE" } , 
          { href:"NPP.html#NP311", title:"APOLLO" } , 
          { href:"NPP.html#NP441", title:"AREOPAGUS" } , 
          { href:"NPP.html#NP446", title:"ARES" } , 
          { href:"NPP.html#NP489", title:"ARTEMIS" } , 
          { href:"NPP.html#NP555", title:"ASKLEPARION" } , 
          { href:"NPP.html#NP602", title:"ASKLEPIOS" } , 
          { href:"NPP.html#NP636", title:"ATHENA" } , 
          { href:"NPP.html#NP900", title:"BAKCHEION" } , 
          { href:"NPP.html#NP931", title:"BOULEUTERION" } , 
          { href:"NPP.html#NP967", title:"CAECILIUS METELLUS" } , 
          { href:"NPP.html#NP998", title:"CHALKOTHEKE" } , 
          { href:"NPP.html#NP1020", title:"CHRISTIAN MONUMENTS" } , 
          { href:"NPP.html#NP1166", title:"CITY WALL" } , 
          { href:"NPP.html#NP1202", title:"DADOUCHOS" } , 
          { href:"NPP.html#NP1216", title:"DEMETER" } , 
          { href:"NPP.html#NP1272", title:"DEMOS" } , 
          { href:"NPP.html#NP1287", title:"DEMOSION SEMA" } , 
          { href:"NPP.html#NP1413", title:"DIONYSOS" } , 
          { href:"NPP.html#NP1509", title:"DIPYLON" } , 
          { href:"NPP.html#NP1532", title:"EAGLE" } , 
          { href:"NPP.html#NP1566", title:"ELEUSINIAN" } , 
          { href:"NPP.html#NP1603", title:"EPHEBES" } , 
          { href:"NPP.html#NP1628", title:"ERECHTHEION" } , 
          { href:"NPP.html#NP1671", title:"EROS" } , 
          { href:"NPP.html#NP1718", title:"EUMENES" } , 
          { href:"NPP.html#NP1725", title:"EUMENIDES" } , 
          { href:"NPP.html#NP1746", title:"FAUSTINA" } , 
          { href:"NPP.html#NP1755", title:"GAIEOCHOS" } , 
          { href:"NPP.html#NP1788", title:"HABRYLLIS" } , 
          { href:"NPP.html#NP1790", title:"HADRIAN" } , 
          { href:"NPP.html#NP1835", title:"HARMODIOS" } , 
          { href:"NPP.html#NP1885", title:"HEPHAISTOS" } , 
          { href:"NPP.html#NP1919", title:"HERAKLES" } , 
          { href:"NPP.html#NP1990", title:"HERM" } , 
          { href:"NPP.html#NP2077", title:"HERODES ATTICUS" } , 
          { href:"NPP.html#NP2163", title:"HIERA ODOS" } , 
          { href:"NPP.html#NP2231", title:"IAKCHOS" } , 
          { href:"NPP.html#NP2257", title:"ISIS" } , 
          { href:"NPP.html#NP2298", title:"JASON" } , 
          { href:"NPP.html#NP2299", title:"JEWISH MONUMENTS" } , 
          { href:"NPP.html#NP2314", title:"KAINON" } , 
          { href:"NPP.html#NP2352", title:"KEKROPS" } , 
          { href:"NPP.html#NP2375", title:"KERAMEIKOS" } , 
          { href:"NPP.html#NP2469", title:"KYNOSARGES" } , 
          { href:"NPP.html#NP2473", title:"LACHARES" } , 
          { href:"NPP.html#NP2510", title:"LETO" } , 
          { href:"NPP.html#NP2549", title:"LONG WALLS" } , 
          { href:"NPP.html#NP2574", title:"LYSIKRATES" } , 
          { href:"NPP.html#NP2586", title:"MACHAON" } , 
          { href:"NPP.html#NP2685", title:"MOTHER OF THE GODS" } , 
          { href:"NPP.html#NP2718", title:"MOUSEION" } , 
          { href:"NPP.html#NP2740", title:"NAIADS" } , 
          { href:"NPP.html#NP2746", title:"NEMESIS" } , 
          { href:"NPP.html#NP2763", title:"NIKE" } , 
          { href:"NPP.html#NP2798", title:"NYMPHS" } , 
          { href:"NPP.html#NP2813", title:"ODYSSEUS" } , 
          { href:"NPP.html#NP2867", title:"OWL" } , 
          { href:"NPP.html#NP2874", title:"PAIDEIOS" } , 
          { href:"NPP.html#NP2888", title:"PAN" } , 
          { href:"NPP.html#NP2917", title:"PANATHENAIC STADIUM" } , 
          { href:"NPP.html#NP3007", title:"PEISISTRATOS" } , 
          { href:"NPP.html#NP3027", title:"PERIKLES" } , 
          { href:"NPP.html#NP3063", title:"PHEIDIAS" } , 
          { href:"NPP.html#NP3081", title:"PHILIP V" } , 
          { href:"NPP.html#NP3122", title:"PLATO" } , 
          { href:"NPP.html#NP3147", title:"PNYX" } , 
          { href:"NPP.html#NP3187", title:"POSEIDON" } , 
          { href:"NPP.html#NP3213", title:"POSTHERULIAN WALL" } , 
          { href:"NPP.html#NP3256", title:"PROPYLAIA" } , 
          { href:"NPP.html#NP3303", title:"RAM" } , 
          { href:"NPP.html#NP3318", title:"ROMAN AGORA" } , 
          { href:"NPP.html#NP3327", title:"ROYAL STOA" } , 
          { href:"NPP.html#NP3328", title:"SABAZIOS" } , 
          { href:"NPP.html#NP3330", title:"SACRED GATE" } , 
          { href:"NPP.html#NP3435", title:"SOPHOKLES" } , 
          { href:"NPP.html#NP3467", title:"STOA POIKILE" } , 
          { href:"NPP.html#NP3491", title:"TALOS" } , 
          { href:"NPP.html#NP3520", title:"THEMISTOKLEAN WALL" } , 
          { href:"NPP.html#NP3596", title:"THESEUS" } , 
          { href:"NPP.html#NP3751", title:"ULPIUS" } , 
          { href:"NPP.html#NP3760", title:"VALERIAN WALL" } , 
          { href:"NPP.html#NP3768", title:"WINDMILL HILL" } , 
          { href:"NPP.html#NP3771", title:"XANTHIPPOS" } , 
          { href:"NPP.html#NP3788", title:"ZEUS" }
        ]
      },
      {
        href:"NNM.html#YYY", title:"MONUMENTS AND SITES SOURCEBOOK : PART II MONUMENTS AND SITES WITHOUT CLEAR-CUT NAMES ",
        secondary:[
          { href:"NNM.html#UP2", title: "Akropolis, nos. 1129" } ,  
          { href:"NNM.html#UP152", title: "Akropolis Upper South Slope, nos. 130  161" } ,  
          { href:"NNM.html#UP192", title: "Akropolis Lower South Slope, nos. 162  185" } ,  
          { href:"NNM.html#UP217", title: "Akropolis East Slope, nos. 186  189" } ,  
          { href:"NNM.html#UP223", title: "East and northeast of the Akropolis (eastern part of Plaka), nos. 190  195" } ,  
          { href:"NNM.html#UP232", title: "Akropolis Upper North Slope, nos. 196  206" } ,  
          { href:"NNM.html#UP243", title: "Akropolis Lower North Slope, nos. 207  218" } ,  
          { href:"NNM.html#UP257", title: "Akropolis West Slope, nos. 219  221" } ,  
          { href:"NNM.html#UP261", title: "Areopagus Hill, nos. 222  229" } ,   
          { href:"NNM.html#UP270", title: "AreopagusPnyx Valley along ancient road, nos. 230  239 " } ,  
          { href:"NNM.html#UP281", title: "Pnyx Range, nos. 240  265 " } ,  
          { href:"NNM.html#UP284", title: "Mouseion Hill, nos. 240  249 " } ,  
          { href:"NNM.html#UP292", title: "Pnyx Hill, nos. 250  259 " } ,  
          { href:"NNM.html#UP304", title: "Hill of the Nymphs, nos. 260  265 " } ,  
          { href:"NNM.html#UP311", title: "Northwest Athens, nos. 266  492 " } ,  
          { href:"NNM.html#UP311", title: "Agora Excavations, nos. 266  373 " } ,  
          { href:"NNM.html#UP436", title: "Agora Surroundings, nos. 374  387 " } ,  
          { href:"NNM.html#UP455", title: "Kerameikos Excavations, nos. 388  429 " } ,  
          { href:"NNM.html#UP503", title: "Academy, nos. 430  446 " } ,  
          { href:"NNM.html#UP523", title: "Academy Area, nos. 447  461 " } ,  
          { href:"NNM.html#UP539", title: "Kolonos Hippios Area, nos. 462  474 " } ,  
          { href:"NNM.html#UP553", title: "Northwest Varia: nos. 475  492 " } ,  
          { href:"NNM.html#UP575", title: "Northeast Athens: nos. 493  527 " } ,  
          { href:"NNM.html#UP622", title: "Ilissos Left Bank, nos. 528  540 " } ,  
          { href:"NNM.html#UP638", title: "Ilissos Right Bank, nos. 541  561 " } ,  
          { href:"NNM.html#UP660", title: "Roads and streets, nos. 562  617 " } ,  
          { href:"NNM.html#NNM618", title: "Aqueducts, nos. 618  620" } 
        ]
      },
      { 
        href:"JBMSFull.html#YYY" , title:"MONUMENTS AND SITES HISTORY 1102-1997"   ,   
        secondary:[ 
          { href:"JBMSFull.html#JBMSF5", title:"Areas"},
          { href:"JBMSFull.html#JBMSF7", title:"How to Use"},
          { href:"JBMSFull.html#JBMSF11", title:"Gates"},
          { href:"JBMSFull.html#JBSMF13", title:"Bibliography"},
          { href:"JBMSFull.html#JBMSF15", title:"Clarification"},
          { href:"JBMSFull.html#Y1054", title:"1054"},
          { href:"JBMSFull.html#Y1303", title:"1303"},
          { href:"JBMSFull.html#Y1500", title:"1500"},
          { href:"JBMSFull.html#Y1601", title:"1601"},
          { href:"JBMSFull.html#Y1700", title:"1700"},
          { href:"JBMSFull.html#Y1751", title:"1751"},
          { href:"JBMSFull.html#Y1775", title:"1775"},
          { href:"JBMSFull.html#Y1800", title:"1800"},
          { href:"JBMSFull.html#Y1820", title:"1820"},
          { href:"JBMSFull.html#Y1835", title:"1835"},
          { href:"JBMSFull.html#Y1850", title:"1850"},
          { href:"JBMSFull.html#Y1870", title:"1870"},
          { href:"JBMSFull.html#Y1880", title:"1880"},
          { href:"JBMSFull.html#Y1890", title:"1890"},
          { href:"JBMSFull.html#Y1900", title:"1900"},
          { href:"JBMSFull.html#Y1910", title:"1910"},
          { href:"JBMSFull.html#Y1920", title:"1920"},
          { href:"JBMSFull.html#Y1930", title:"1930"},
          { href:"JBMSFull.html#Y1940", title:"1940"},
          { href:"JBMSFull.html#Y1950", title:"1950"},
          { href:"JBMSFull.html#Y1960", title:"1960"},
          { href:"JBMSFull.html#Y1970", title:"1970"},
          { href:"JBMSFull.html#Y1980", title:"1980"},
          { href:"JBMSFull.html#Y1990", title:"1990"},
          { href:"JBMSFull.html#JBMS3766", title:"Metro Exc."},
          { href:"JBMSFull.html#Y1997", title:"1997"}
        ]
      },
      { 
        href:"Paus1.html#0.1" , title:"Pausanias (English)"   ,   
        secondary:[
          { href:"Paus1.html#0.1" , title:"Pausanias, Description of Greece" }    
        ]
      },
      { 
        href:"Paus1GR.html#1.1.1" , title:"Pausanias (Greek)"   ,   
        secondary:[
          { href:"Paus1GR.html#1.1.1" , title:"Pausanias Book 1" }  
        ]
      },
      { 
        href:"Abbreviations.html#AB1" , title:"ABBREVIATIONS"   ,   
        secondary:[
          { href:"Abbreviations.html#AB1" , title:"General abbreviations" } ,   
          { href:"Abbreviations.html#AB36" , title:"Abbreviations for ancient and mediaeval literary sources " } ,   
          { href:"Abbreviations.html#AB1" , title:"Abbreviations for books, periodicals, MSS" } ,   
          { href:"Abbreviations.html#AB802" , title:"Abbreviations for Excavations, Museums, Storerooms, Collections, Libraries" }    
        ]
      },
      {  
        href:"GenIndex.html#I1", title:"INDEXES",  
        secondary:[
          { href:"GenIndex.html#I1", title:"Index I General Index preceded by a list of headings"},  
          { href:"ChristianIndex.html#I603", title:"Index II Christian Athens"},  
          { href:"LateAthensIndex.html#I747", title:"Index III Athens after 500 A.D (excluding Christian Monuments) 472-474"},  
          { href:"FloraFaunaIndex.html#I809", title:"Index IV Fauna and Flora, Mythological Creatures 475-479"},  
          { href:"ForeignIndex.html#I894", title:"Index V Places outside of Athens, ethnic groups and foreign languages 480-486"},  
          { href:"PersonIndex.html#I1116", title:"Index VI Persons"}
        ]
      },
      {   
        href:"IGIndex.html#I1209", title:"Indexes to epigraphical publications ",
        secondary:[  
          { href:"IGIndex.html#I1209", title:"Inscriptiones Graecae "},  
          { href:"SEGCILIndex.html#I2983", title:"Supplementum Epigraphicum Graecum "},  
          { href:"SEGCILIndex.html#I2983", title:"Corpus Inscriptionum Latinarum"},  
        ]
      },    
      { 
        href:"AcropolisNNMIndex.html#I3643", title:"Indexes to Museums and Storerooms in Athens",  
        secondary:[
          { href:"AcropolisNNMIndex.html#I3643", title:"Acropolis Museum, National Museum"},  
          { href:"AgoraKerameikosIndex.html#I3985", title:"Agora Excavations, American School of Classical Studies"},  
          { href:"AgoraKerameikosIndex.html#I4404", title:"Kerameikos Excavations, German Archaeological Institute"},  
          { href:"EphoriaIndex.html#I4454", title:"First Ephoria Excavations, Greek Archaeological Service"},  
          { href:"EphoriaIndex.html#I4504", title:"Third Ephoria Excavations, Greek Archaeological Service"},  
          { href:"EphoriaIndex.html#I4533", title:"Metro Excavations 1992-1997"},  
          { href:"OtherMuseumsIndex.html#I4537", title:"Benaki Museum"},  
          { href:"OtherMuseumsIndex.html#I4539", title:"Byzantine Museum"},  
          { href:"OtherMuseumsIndex.html#I4548", title:"Kanellopoulos Museum"},  
          { href:"OtherMuseumsIndex.html#I4550", title:"National Historical Museum"}, 
          { href:"OtherMuseumsIndex.html#I4553", title:"Index to Museums outside of Greece"},  
          { href:"StreetIndex.html#I4612", title:"Index to present-day streets, place-names and landmarks"},  
        ]
      }
    ])

    const baritems = ref([ 
        {  href:"Forward.html#F1", title:"FOREWORD" },
        {  href:"Forward.html#F4", title:"INTRODUCTION"} ,
        {  href:"Forward.html#F4", title:"The general scheme "} ,
        {  href:"Forward.html#F62", title:"Documentation "} ,
        {  href:"Forward.html#F99", title:"Some matters of terminology "} ,
        {  href:"Forward.html#F160", title:"Principles of selection"} ,
        {  href:"Forward.html#F166", title:"Coverage of Monuments and sites "} ,
        {  href:"Forward.html#F295", title:"Coverage of Place-names "} ,
        {  href:"Forward.html#F299", title:"Coverage of cults "}  ,
        {  href:"Forward.html#F306", title:"Christian Athens "}  ,
        {  href:"Forward.html#F312", title:"Territorial Limits "} ,
        {  href:"Forward.html#F314", title:"Acknowledgements"}   , 
        { href:"NPP.html", title:"MONUMENTS AND SITES SOURCEBOOK : PART I: MONUMENTS, SITES, PLACE-NAMES AND CULTS WITH CLEAR-CUT NAMES IN ALPHABETICAL ORDER" },  
        { href:"NPP.html#NP2", title:"ABECEDARIA" } , 
        { href:"NPP.html#NP7", title:"ACADEMY" } , 
        { href:"NPP.html#NP20", title:"ACROPOLIS" } , 
        { href:"NPP.html#NP39", title:"AGLAUROS" } , 
        { href:"NPP.html#NP45", title:"AGORA" } , 
        { href:"NPP.html#NP82", title:"AIAKEION" } , 
        { href:"NPP.html#NP102", title:"AKADEMIA" } , 
        { href:"NPP.html#NP118", title:"ALEXANDER THE GREAT" } , 
        { href:"NPP.html#NP243", title:"APHRODITE" } , 
        { href:"NPP.html#NP311", title:"APOLLO" } , 
        { href:"NPP.html#NP441", title:"AREOPAGUS" } , 
        { href:"NPP.html#NP446", title:"ARES" } , 
        { href:"NPP.html#NP489", title:"ARTEMIS" } , 
        { href:"NPP.html#NP555", title:"ASKLEPARION" } , 
        { href:"NPP.html#NP602", title:"ASKLEPIOS" } , 
        { href:"NPP.html#NP636", title:"ATHENA" } , 
        { href:"NPP.html#NP900", title:"BAKCHEION" } , 
        { href:"NPP.html#NP931", title:"BOULEUTERION" } , 
        { href:"NPP.html#NP967", title:"CAECILIUS METELLUS" } , 
        { href:"NPP.html#NP998", title:"CHALKOTHEKE" } , 
        { href:"NPP.html#NP1020", title:"CHRISTIAN MONUMENTS" } , 
        { href:"NPP.html#NP1166", title:"CITY WALL" } , 
        { href:"NPP.html#NP1202", title:"DADOUCHOS" } , 
        { href:"NPP.html#NP1216", title:"DEMETER" } , 
        { href:"NPP.html#NP1272", title:"DEMOS" } , 
        { href:"NPP.html#NP1287", title:"DEMOSION SEMA" } , 
        { href:"NPP.html#NP1413", title:"DIONYSOS" } , 
        { href:"NPP.html#NP1509", title:"DIPYLON" } , 
        { href:"NPP.html#NP1532", title:"EAGLE" } , 
        { href:"NPP.html#NP1566", title:"ELEUSINIAN" } , 
        { href:"NPP.html#NP1603", title:"EPHEBES" } , 
        { href:"NPP.html#NP1628", title:"ERECHTHEION" } , 
        { href:"NPP.html#NP1671", title:"EROS" } , 
        { href:"NPP.html#NP1718", title:"EUMENES" } , 
        { href:"NPP.html#NP1725", title:"EUMENIDES" } , 
        { href:"NPP.html#NP1746", title:"FAUSTINA" } , 
        { href:"NPP.html#NP1755", title:"GAIEOCHOS" } , 
        { href:"NPP.html#NP1788", title:"HABRYLLIS" } , 
        { href:"NPP.html#NP1790", title:"HADRIAN" } , 
        { href:"NPP.html#NP1835", title:"HARMODIOS" } , 
        { href:"NPP.html#NP1885", title:"HEPHAISTOS" } , 
        { href:"NPP.html#NP1919", title:"HERAKLES" } , 
        { href:"NPP.html#NP1990", title:"HERM" } , 
        { href:"NPP.html#NP2077", title:"HERODES ATTICUS" } , 
        { href:"NPP.html#NP2163", title:"HIERA ODOS" } , 
        { href:"NPP.html#NP2231", title:"IAKCHOS" } , 
        { href:"NPP.html#NP2257", title:"ISIS" } , 
        { href:"NPP.html#NP2298", title:"JASON" } , 
        { href:"NPP.html#NP2299", title:"JEWISH MONUMENTS" } , 
        { href:"NPP.html#NP2314", title:"KAINON" } , 
        { href:"NPP.html#NP2352", title:"KEKROPS" } , 
        { href:"NPP.html#NP2375", title:"KERAMEIKOS" } , 
        { href:"NPP.html#NP2469", title:"KYNOSARGES" } , 
        { href:"NPP.html#NP2473", title:"LACHARES" } , 
        { href:"NPP.html#NP2510", title:"LETO" } , 
        { href:"NPP.html#NP2549", title:"LONG WALLS" } , 
        { href:"NPP.html#NP2574", title:"LYSIKRATES" } , 
        { href:"NPP.html#NP2586", title:"MACHAON" } , 
        { href:"NPP.html#NP2685", title:"MOTHER OF THE GODS" } , 
        { href:"NPP.html#NP2718", title:"MOUSEION" } , 
        { href:"NPP.html#NP2740", title:"NAIADS" } , 
        { href:"NPP.html#NP2746", title:"NEMESIS" } , 
        { href:"NPP.html#NP2763", title:"NIKE" } , 
        { href:"NPP.html#NP2798", title:"NYMPHS" } , 
        { href:"NPP.html#NP2813", title:"ODYSSEUS" } , 
        { href:"NPP.html#NP2867", title:"OWL" } , 
        { href:"NPP.html#NP2874", title:"PAIDEIOS" } , 
        { href:"NPP.html#NP2888", title:"PAN" } , 
        { href:"NPP.html#NP2917", title:"PANATHENAIC STADIUM" } , 
        { href:"NPP.html#NP3007", title:"PEISISTRATOS" } , 
        { href:"NPP.html#NP3027", title:"PERIKLES" } , 
        { href:"NPP.html#NP3063", title:"PHEIDIAS" } , 
        { href:"NPP.html#NP3081", title:"PHILIP V" } , 
        { href:"NPP.html#NP3122", title:"PLATO" } , 
        { href:"NPP.html#NP3147", title:"PNYX" } , 
        { href:"NPP.html#NP3187", title:"POSEIDON" } , 
        { href:"NPP.html#NP3213", title:"POSTHERULIAN WALL" } , 
        { href:"NPP.html#NP3256", title:"PROPYLAIA" } , 
        { href:"NPP.html#NP3303", title:"RAM" } , 
        { href:"NPP.html#NP3318", title:"ROMAN AGORA" } , 
        { href:"NPP.html#NP3327", title:"ROYAL STOA" } , 
        { href:"NPP.html#NP3328", title:"SABAZIOS" } , 
        { href:"NPP.html#NP3330", title:"SACRED GATE" } , 
        { href:"NPP.html#NP3435", title:"SOPHOKLES" } , 
        { href:"NPP.html#NP3467", title:"STOA POIKILE" } , 
        { href:"NPP.html#NP3491", title:"TALOS" } , 
        { href:"NPP.html#NP3520", title:"THEMISTOKLEAN WALL" } , 
        { href:"NPP.html#NP3596", title:"THESEUS" } , 
        { href:"NPP.html#NP3751", title:"ULPIUS" } , 
        { href:"NPP.html#NP3760", title:"VALERIAN WALL" } , 
        { href:"NPP.html#NP3768", title:"WINDMILL HILL" } , 
        { href:"NPP.html#NP3771", title:"XANTHIPPOS" } , 
        { href:"NPP.html#NP3788", title:"ZEUS" },
        {href:"NNM.html#YYY", title:"MONUMENTS AND SITES SOURCEBOOK : PART II MONUMENTS AND SITES WITHOUT CLEAR-CUT NAMES "}, 
        { href:"NNM.html#UP2", title: "Akropolis, nos. 1129" } ,  
        { href:"NNM.html#UP152", title: "Akropolis Upper South Slope, nos. 130  161" } ,  
        { href:"NNM.html#UP192", title: "Akropolis Lower South Slope, nos. 162  185" } ,  
        { href:"NNM.html#UP217", title: "Akropolis East Slope, nos. 186  189" } ,  
        { href:"NNM.html#UP223", title: "East and northeast of the Akropolis (eastern part of Plaka), nos. 190  195" } ,  
        { href:"NNM.html#UP232", title: "Akropolis Upper North Slope, nos. 196  206" } ,  
        { href:"NNM.html#UP243", title: "Akropolis Lower North Slope, nos. 207  218" } ,  
        { href:"NNM.html#UP257", title: "Akropolis West Slope, nos. 219  221" } ,  
        { href:"NNM.html#UP261", title: "Areopagus Hill, nos. 222  229" } ,   
        { href:"NNM.html#UP270", title: "AreopagusPnyx Valley along ancient road, nos. 230  239 " } ,  
        { href:"NNM.html#UP281", title: "Pnyx Range, nos. 240  265 " } ,  
        { href:"NNM.html#UP284", title: "Mouseion Hill, nos. 240  249 " } ,  
        { href:"NNM.html#UP292", title: "Pnyx Hill, nos. 250  259 " } ,  
        { href:"NNM.html#UP304", title: "Hill of the Nymphs, nos. 260  265 " } ,  
        { href:"NNM.html#UP311", title: "Northwest Athens, nos. 266  492 " } ,  
        { href:"NNM.html#UP311", title: "Agora Excavations, nos. 266  373 " } ,  
        { href:"NNM.html#UP436", title: "Agora Surroundings, nos. 374  387 " } ,  
        { href:"NNM.html#UP455", title: "Kerameikos Excavations, nos. 388  429 " } ,  
        { href:"NNM.html#UP503", title: "Academy, nos. 430  446 " } ,  
        { href:"NNM.html#UP523", title: "Academy Area, nos. 447  461 " } ,  
        { href:"NNM.html#UP539", title: "Kolonos Hippios Area, nos. 462  474 " } ,  
        { href:"NNM.html#UP553", title: "Northwest Varia: nos. 475  492 " } ,  
        { href:"NNM.html#UP575", title: "Northeast Athens: nos. 493  527 " } ,  
        { href:"NNM.html#UP622", title: "Ilissos Left Bank, nos. 528  540 " } ,  
        { href:"NNM.html#UP638", title: "Ilissos Right Bank, nos. 541  561 " } ,  
        { href:"NNM.html#UP660", title: "Roads and streets, nos. 562  617 " } ,  
        { href:"NNM.html#NNM618", title: "Aqueducts, nos. 618  620" } ,
        {href:"Abbreviations.html#YYY" , title:"ABBREVIATIONS" }  ,    
        { href:"Abbreviations.html#YYY" , title:"General abbreviations" } ,   
        { href:"Abbreviations.html#YYY" , title:"Abbreviations for ancient and mediaeval literary sources " } ,   
        { href:"Abbreviations.html#YYY" , title:"Abbreviations for books, periodicals, MSS" } ,   
        { href:"Abbreviations.html#YYY" , title:"Abbreviations for Excavations, Museums, Storerooms, Collections, Libraries" }  ,
        {href:"GenIndex.html#YYY", title:"INDEXES"},  
        { href:"GenIndex.html#YYY", title:"Index I General Index preceded by a list of headings"},  
        { href:"ChristianIndex.html#YYY", title:"Index II Christian Athens"},  
        { href:"LateAthensIndex.html#YYY", title:"Index III Athens after 500 A.D (excluding Christian Monuments) 472-474"},  
        { href:"FloraFaunaIndex.html#YYY", title:"Index IV Fauna and Flora, Mythological Creatures 475-479"},  
        { href:"ForeignIndex.html#YYY", title:"Index V Places outside of Athens, ethnic groups and foreign languages 480-486"},  
        { href:"PersonIndex.html#YYY", title:"Index VI Persons"},   
        {href:"IGIndex.html#YYY", title:"Indexes to epigraphical publications "}, 
        { href:"IGIndex.html#YYY", title:"Inscriptiones Graecae "},  
        { href:"SEGCILIndex.html#YYY", title:"Supplementum Epigraphicum Graecum "},  
        { href:"SEGCILIndex.html#YYY", title:"Corpus Inscriptionum Latinarum"},  
        {href:"AcropolisNNMIndex.html#YYY", title:"Indexes to Museums and Storerooms in Athens"},   
        { href:"AcropolisNNMIndex.html#YYY", title:"Acropolis Museum, National Museum"},  
        { href:"AgoraKerameikosIndex.html#YYY", title:"Agora Excavations, American School of Classical Studies"},  
        { href:"AgoraKerameikosIndex.html#YYY", title:"Kerameikos Excavations, German Archaeological Institute"},  
        { href:"EphoriaIndex.html#YYY", title:"First Ephoria Excavations, Greek Archaeological Service"},  
        { href:"EphoriaIndex.html#YYY", title:"Third Ephoria Excavations, Greek Archaeological Service"},  
        { href:"EphoriaIndex.html#YYY", title:"Metro Excavations 1992-1997"},  
        { href:"OtherMuseumsIndex.html#YYY", title:"Benaki Museum"},  
        { href:"OtherMuseumsIndex.html#YYY", title:"Byzantine Museum"},  
        { href:"OtherMuseumsIndex.html#YYY", title:"Kanellopoulos Museum"},  
        { href:"OtherMuseumsIndex.html#YYY", title:"National Historical Museum"}, 
        { href:"OtherMuseumsIndex.html#YYY", title:"Index to Museums outside of Greece"},  
        { href:"StreetIndex.html#YYY", title:"Index to present-day streets, place-names and landmarks"} ,
        {href:"JBMSFull.html#YYY" , title:"MONUMENTS AND SITES HISTORY 1102-1997" }  ,   
        { href:"JBMSFull.html#JBMSF5", title:"Areas"},
        { href:"JBMSFull.html#JBMSF7", title:"How to Use"},
        { href:"JBMSFull.html#JBMSF11", title:"Gates"},
        { href:"JBMSFull.html#JBSMF13", title:"Bibliography"},
        { href:"JBMSFull.html#JBMSF15", title:"Clarification"},
        { href:"JBMSFull.html#Y1054", title:"1054"},
        { href:"JBMSFull.html#Y1303", title:"1303"},
        { href:"JBMSFull.html#Y1500", title:"1500"},
        { href:"JBMSFull.html#Y1601", title:"1601"},
        { href:"JBMSFull.html#Y1700", title:"1700"},
        { href:"JBMSFull.html#Y1751", title:"1751"},
        { href:"JBMSFull.html#Y1775", title:"1775"},
        { href:"JBMSFull.html#Y1800", title:"1800"},
        { href:"JBMSFull.html#Y1820", title:"1820"},
        { href:"JBMSFull.html#Y1835", title:"1835"},
        { href:"JBMSFull.html#Y1850", title:"1850"},
        { href:"JBMSFull.html#Y1870", title:"1870"},
        { href:"JBMSFull.html#Y1880", title:"1880"},
        { href:"JBMSFull.html#Y1890", title:"1890"},
        { href:"JBMSFull.html#Y1900", title:"1900"},
        { href:"JBMSFull.html#Y1910", title:"1910"},
        { href:"JBMSFull.html#Y1920", title:"1920"},
        { href:"JBMSFull.html#Y1930", title:"1930"},
        { href:"JBMSFull.html#Y1940", title:"1940"},
        { href:"JBMSFull.html#Y1950", title:"1950"},
        { href:"JBMSFull.html#Y1960", title:"1960"},
        { href:"JBMSFull.html#Y1970", title:"1970"},
        { href:"JBMSFull.html#Y1980", title:"1980"},
        { href:"JBMSFull.html#Y1990", title:"1990"},
        { href:"JBMSFull.html#JBMS3766", title:"Metro Exc."},
        { href:"JBMSFull.html#Y1997", title:"1997"}
    ])

    const route = useRoute();     
    const router = useRouter(); 

    const selectItemEventHandler = (item)=>{    
      let part = item.href.split('.')[0]
      let term = item.href.split('#')[1]
      router.push({ name: part, force: true ,params: { term } }) 
    }

    const resizeHandler = ()=>{
      if (window.innerWidth < 960) { 
        splitlayout.value = true
        smallPanelSize.value=5
        // textFlexBasis.value = '95%'
        // buttonFlexBasis.value = '5%'
      } 
      else{
        splitlayout.value = false
        smallPanelSize.value=30
        // textFlexBasis.value = '100%'
        // buttonFlexBasis.value = '0%'
      }
    }

    const typeheadblur = ()=>{
      typeahead_ref.value.input = ''
      if (window.innerWidth < 960) {
        smallPanelSize.value = 5
      }
    }

    const typeheadinput = ()=>{ 
      if (window.innerWidth < 960) {
        if (typeahead_ref.value.input.length<3) {
          smallPanelSize.value = 52  
        }
        else if (typeahead_ref.value.input.length>2){
          smallPanelSize.value = 20
        }
      }       
    }
        
    const itemProjectionFunction = (item)=>{  
      return item.title
    } 

    const { replaceLink } = useReplaceLinkClick()      

    const panelResizeVertical = ()=>{ 
      if (smallPanelSize.value != 5 ) {
        smallPanelSize.value = 5
      } 
    }

    onMounted(() => { 
      // if (router.currentRoute.value.name == 'sourcebook') {
      //   router.push({ name: 'Forward', force: true }) 
      // } 
      resizeHandler()
      window.addEventListener("resize", resizeHandler);
    }) 

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    })

    
    const scrollUp = ()=>{  
      let el = document.getElementById('scrollPoint');  
      el.scrollIntoView({behavior: "smooth"}); 
    } 

    return{ 
      smallPanelSize, 
      selectItemEventHandler, 
      itemProjectionFunction, 
      items, 
      replaceLink, 
      baritems,
      typeheadblur,
      typeheadinput,
      typeahead_ref, 
      splitlayout,
      panelResizeVertical,
      textFlexBasis,
      buttonFlexBasis,
      scrollUp
    }
    
  } 
}
</script>

<style scoped>

  #fixSizeVertical{
    background-color: rgb(255, 255, 255);
    border: none; 
    padding: 8px 8px 0 0;
    display: none;
    cursor: pointer;
  }
 
  .chapters-ul li{
    text-align: left;
    list-style-type: none;
  } 
  .chapters-ul a{
    color: black;
  }
  .chapters-ul a:hover{
    color: darkgray;
  }
  .chapters-ul a:visited{
    color: gray;
  } 

    .p-panel-header a{
    color: black;
    display: block;
    width: 100%;
    text-align: center;
    text-align: center;
    font-weight: bold;
  }

  .p-panel-header a:hover {
    color: darkgray;
  }

  .p-panel-header a:visited {
    color: gray;
  }

  #scrollButton{
    position: fixed; 
    bottom:20px;
    right: 25px;
    padding: 5px;
    cursor: pointer; 
  }

  @media only screen and (max-width: 960px){
    #fixSizeVertical{
      display: block;
    } 
  } 
</style>

<style>
  #typeahead_id{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    width: 95%;
    padding: 5px;
    font-size: 17px;
    margin: 0 auto;  
  } 

  .simple-typeahead .simple-typeahead-list{
    z-index: 200!important;
  }

  .splitpanes__pane{
    /* display: block; */
    overflow-x: hidden; 
    overflow-y:scroll;  
    background-color: white;
  }

  .part-text a{
    color: black;
    background-color: #e6e6e6;
  }
  .part-text a:hover{
    color: grey;
  }
  .part-text a:visited{
    color: gray;
  }

  .splitpanes.default-theme .splitpanes__splitter{
    background-color: rgb(176, 196, 222);     
  }

  .splitpanes.default-theme .splitpanes__splitter:before, .splitpanes.default-theme .splitpanes__splitter:after{
    background-color: black;
  }

  .default-theme.splitpanes--horizontal>.splitpanes__splitter:before, .default-theme.splitpanes--horizontal>.splitpanes__splitter:after, .default-theme .splitpanes--horizontal>.splitpanes__splitter:before, .default-theme .splitpanes--horizontal>.splitpanes__splitter:after{
    width: 53px;
    height: 2px;
  }
</style>

