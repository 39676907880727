<template>

    <div class="part-text">


<h4 :class="{'activeClass': idClicked == I4537 }" id="I4537">BENAKI MUSEUM</h4>
<ul>
<li :class="{'activeClass': idClicked == I4538 }" id="I4538">Inv. no. 26463, Islamic and Ottoman Mon. watercolor by Dupre</li> 
</ul>
<h4 :class="{'activeClass': idClicked == I4539 }" id="I4539">BYZANTINE MUSEUM</h4> 
<ul>
<li :class="{'activeClass': idClicked == I4540 }" id="I4540">T 95 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1088'>CM33*</a></li> 
<li :class="{'activeClass': idClicked == I4541 }" id="I4541">inv. no. 139 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1079'>CM27*</a></li> 
<li :class="{'activeClass': idClicked == I4542 }" id="I4542">inv. no. 142 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1050'>CM13*</a></li> 
<li :class="{'activeClass': idClicked == I4543 }" id="I4543">inv. nos. 3145-3179 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1021'>CM1*</a></li> 
<li :class="{'activeClass': idClicked == I4544 }" id="I4544">inv. nos. 4143-4173 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1022'>CM2*</a></li> 
<li :class="{'activeClass': idClicked == I4545 }" id="I4545">inv. no. not known <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3000'>Paul</a></li> 
<li :class="{'activeClass': idClicked == I4546 }" id="I4546">inv. no. not known mosaic pavement, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM185">185</a></li> 
<li :class="{'activeClass': idClicked == I4547 }" id="I4547">inv. no. not known mosaic pavement, Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM481">481</a></li> 
</ul>
<h4 :class="{'activeClass': idClicked == I4548 }" id="I4548">KANELLOPOULOS MUSEUM</h4> 
<ul>
<li :class="{'activeClass': idClicked == I4549 }" id="I4549">XA 1469 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2037'>Hrm35*</a></li>
</ul> 
<h4 :class="{'activeClass': idClicked == I4550 }" id="I4550">NATIONAL HISTORICAL MUSEUM</h4> 
<ul>
<li :class="{'activeClass': idClicked == I4551 }" id="I4551">inv. no. 29 City Wall in 1778</li> 
<li :class="{'activeClass': idClicked == I4552 }" id="I4552">inv no. 4431 Christian Mon. <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1104'>CM44*</a></li> 
</ul>
<h4 :class="{'activeClass': idClicked == I4553 }" id="I4553">INDEX TO MUSEUMS OUTSIDE OF GREECE</h4> 
<ul>
<li :class="{'activeClass': idClicked == I4554 }" id="I4554">Amsterdam, Allard Pierson Museum <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem3*</li> 
<li :class="{'activeClass': idClicked == I4555 }" id="I4555">Avignon, Musee Calvert 220 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2804'>Nym6*</a> (Nymphai <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2850'>Ompniai</a>)</li> 
<li :class="{'activeClass': idClicked == I4556 }" id="I4556">Berlin Staatliche Museen zu Berlin</li> 
<li :class="{'activeClass': idClicked == I4557 }" id="I4557">inv. 1722 = A 10 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2867'>Owl</a></li> 
<li :class="{'activeClass': idClicked == I4558 }" id="I4558">inv. 709 = K 87 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2811'>Nym11*</a></li> 
<li :class="{'activeClass': idClicked == I4559 }" id="I4559">inv. 818 = K 98 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2131'>Hro36*</a></li> 
<li :class="{'activeClass': idClicked == I4560 }" id="I4560">inv. 730 = K 103 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1493'>Dioskouroi</a></li> 
<li :class="{'activeClass': idClicked == I4561 }" id="I4561">inv. 881 = K 104 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP805'>Ath101*</a></li> 
<li :class="{'activeClass': idClicked == I4562 }" id="I4562">inv. 808 = K 113 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2146'>Hro49*</a></li> 
<li :class="{'activeClass': idClicked == I4563 }" id="I4563">inv. 1811-1826 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3055'>Phainocharis</a></li> 
<li :class="{'activeClass': idClicked == I4564 }" id="I4564">Boston, Boston MFA 60.45 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3413'>Sokrates</a></li> 
<li :class="{'activeClass': idClicked == I4565 }" id="I4565">Boston MFA 96.696 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1977'>Hrk43*</a> Alexikakos</li> 
<li :class="{'activeClass': idClicked == I4566 }" id="I4566">Boston MFA 99.347 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP68'>Agrippa</a></li> 
<li :class="{'activeClass': idClicked == I4567 }" id="I4567">Boston MFA 1972.78 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1874'>Helios</a></li> 
<li :class="{'activeClass': idClicked == I4568 }" id="I4568">Brockiesby Park, Brocklesby Park no. 28 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1962'>Hrk33*</a></li> 
<li :class="{'activeClass': idClicked == I4569 }" id="I4569">Brockiesby Park no. 101 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP506'>Art15*</a></li> 
<li :class="{'activeClass': idClicked == I4570 }" id="I4570">Brussels, Mus. du Cinq. A 1937 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2763'>Nike</a></li> 
<li :class="{'activeClass': idClicked == I4571 }" id="I4571">Cambridge Fitzwilliam Museum inv. GR 4.1865 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2895'>Pan7*</a></li> 
<li :class="{'activeClass': idClicked == I4572 }" id="I4572">inv. GR 14.1865 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP616'>Ask47*</a></li> 
<li :class="{'activeClass': idClicked == I4573 }" id="I4573">inv. GR 16.1865 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2137'>Hro42*</a></li> 
<li :class="{'activeClass': idClicked == I4574 }" id="I4574">Cleveland, Cleveland Mus. of Art 26.538 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2897'>Pan9*</a></li> 
<li :class="{'activeClass': idClicked == I4575 }" id="I4575">Copenhagen, Danish Nat. Mus. ABb160 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2896'>Pan8*</a></li> 
<li :class="{'activeClass': idClicked == I4576 }" id="I4576">Istanbul, Archaeological Museum M 638 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1420'>Dio7*</a>, tripod base </li> 
<li :class="{'activeClass': idClicked == I4577 }" id="I4577">Kassel SK 44 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP617'>Ask48*</a></li> 
<li :class="{'activeClass': idClicked == I4578 }" id="I4578">Kassel SK 774 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP505'>Art14*</a></li> 
<li :class="{'activeClass': idClicked == I4579 }" id="I4579">Leiden, Rijksmuseum van Oudheden no. RO III 65 Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM460">460</a></li> 
<li :class="{'activeClass': idClicked == I4580 }" id="I4580">London, British Museum</li> 
<li :class="{'activeClass': idClicked == I4581 }" id="I4581">BM cat. no. 302 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP801'>Ath97*</a>, Strangford shield</li> 
<li :class="{'activeClass': idClicked == I4582 }" id="I4582">BM cat. no. 432 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3652'>Thrasyllos</a> Mon., <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a></li> 
<li :class="{'activeClass': idClicked == I4583 }" id="I4583">BM cat. no. 770 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3926'>Zeu111*</a></li> 
<li :class="{'activeClass': idClicked == I4584 }" id="I4584">BM cat. no. 814 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP841'>Ath125*</a></li> 
<li :class="{'activeClass': idClicked == I4585 }" id="I4585">BM cat. no. 1575 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP260'>Aph17*</a></li> 
<li :class="{'activeClass': idClicked == I4586 }" id="I4586">BM cat. no. 1579 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP259'>Aph16*</a></li> 
<li :class="{'activeClass': idClicked == I4587 }" id="I4587">BM cat. no. 2544 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3046'>Phaidros</a>, son of Zoilos</li> 
<li :class="{'activeClass': idClicked == I4588 }" id="I4588">BM GR 1816.6-10.348 Demosion Dema <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1325'>Dem24*</a></li> 
<li :class="{'activeClass': idClicked == I4589 }" id="I4589">BM GR 1816.6-10.193, cat. no. 2154 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1431'>Dio12*</a></li> 
<li :class="{'activeClass': idClicked == I4590 }" id="I4590">BM 1920.6-16.1 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I4591 }" id="I4591">BM 1922.3-17.82 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3312'>Roma and Augustus</a></li> 
<li :class="{'activeClass': idClicked == I4592 }" id="I4592">BM 1971.1-25.1 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I4593 }" id="I4593">Malibu, Getty Museum no. 74.<a v-on:click.prevent="replaceLink"  href='biblio.html#AB1'>AA</a>.12 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1835'>Harmodios and Aristogeiton</a></li> 
<li :class="{'activeClass': idClicked == I4594 }" id="I4594">Munich, Glyptothek 456 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2911'>Pan20*</a></li> 
<li :class="{'activeClass': idClicked == I4595 }" id="I4595">Munich, Graphische Sammlung no. 21360 Islamic and Ottoman Mon., Turkish Spring House</li> 
<li :class="{'activeClass': idClicked == I4596 }" id="I4596">New York, Metropolitan Museum of Art 42.11.35 Lion and bull pediment</li> 
<li :class="{'activeClass': idClicked == I4597 }" id="I4597">Metropolitan Museum of Art 50.11.1 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP725'>Ath43*</a></li> 
<li :class="{'activeClass': idClicked == I4598 }" id="I4598">Oslo, Nasjonalgalleriet Christiana <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2888'>Pan</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2902'>Pan13*</a></li> 
<li :class="{'activeClass': idClicked == I4599 }" id="I4599">Oxford, Ashmolean Museum <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1959'>Hrk30*</a></li> 
<li :class="{'activeClass': idClicked == I4600 }" id="I4600">Padua, Museo Civico di Padova no. 14 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
<li :class="{'activeClass': idClicked == I4601 }" id="I4601">Paris, Bibliotheque National <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3912'>Zeu101*</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP3328'>Sabazios</a></li> 
<li :class="{'activeClass': idClicked == I4602 }" id="I4602">Paris, Louvre MA 831 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP843'>Ath127*</a></li> 
<li :class="{'activeClass': idClicked == I4603 }" id="I4603">Paris, Louvre MA 863 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem13*</li> 
<li :class="{'activeClass': idClicked == I4604 }" id="I4604">Paris, Louvre MA 2701 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP294'>Aph42*</a></li> 
<li :class="{'activeClass': idClicked == I4605 }" id="I4605">Paris, Louvre MNC no. 978 REFERENCE????</li> 
<li :class="{'activeClass': idClicked == I4606 }" id="I4606">Richmond, Virginia Museum of Fine Arts no. 60-5 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP732'>Ath48*</a></li> 
<li :class="{'activeClass': idClicked == I4607 }" id="I4607">Rome, Mus. Barracco <a v-on:click.prevent="replaceLink"  href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP414'>Apo84*</a></li> 
<li :class="{'activeClass': idClicked == I4608 }" id="I4608">Rome, Villa Albani no. 985 <a v-on:click.prevent="replaceLink"  href='NPP.html#NP1287'>Demosion Sema</a> Sem40*</li> 
<li :class="{'activeClass': idClicked == I4609 }" id="I4609">Salisbury, Teffont Manor <a v-on:click.prevent="replaceLink"  href='NPP.html#NP2917'>Panathenaic Stadium</a>, Biel throne</li> 
<li :class="{'activeClass': idClicked == I4610 }" id="I4610">Verona, Museo Archeologico <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP618'>Ask49*</a></li> 
<li :class="{'activeClass': idClicked == I4611 }" id="I4611">Museo Maffeiano <a v-on:click.prevent="replaceLink"  href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"  href='NPP.html#NP558'>Ask2*</a></li> 
</ul>



</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    