<template>

    <div class="part-text">
<h2 :class="{'activeClass': idClicked == I3985 }" id="I3985">AGORA EXCAVATIONS INDEX</h2>
<h4>AGORA ARCHITECTURE</h4>
<p :class="{'activeClass': idClicked == I3986 }" id="I3986"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%2041" target="_blank">Agora_A 41</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP385'>Apo60*</a></p>
<p :class="{'activeClass': idClicked == I3987 }" id="I3987"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%2064" target="_blank">Agora_A 64</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a> </p>
<p :class="{'activeClass': idClicked == I3988 }" id="I3988"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20304" target="_blank">Agora_A 304</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2689'>Met1*</a> </p>
<p :class="{'activeClass': idClicked == I3989 }" id="I3989"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20403" target="_blank">Agora_A 403</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM268">268</a> </p>
<p :class="{'activeClass': idClicked == I3990 }" id="I3990"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20409" target="_blank">Agora_A 409</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1059'>CM17*</a> </p>
<p :class="{'activeClass': idClicked == I3991 }" id="I3991"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20818" target="_blank">Agora_A 818</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a> </p>
<p :class="{'activeClass': idClicked == I3992 }" id="I3992"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20820" target="_blank">Agora_A 820</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM305">305</a> </p>
<p :class="{'activeClass': idClicked == I3993 }" id="I3993"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%20891" target="_blank">Agora_A 891</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1885'>Hephaistos</a> </p>
<p :class="{'activeClass': idClicked == I3994 }" id="I3994"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201133" target="_blank">Agora_A 1133</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM372">372</a></p>
<p :class="{'activeClass': idClicked == I3995 }" id="I3995"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201198" target="_blank">Agora_A 1198-99</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3734'>Twelve Gods</a> </p>
<p :class="{'activeClass': idClicked == I3996 }" id="I3996"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201283" target="_blank">Agora_A 1283</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM372">372</a></p>
<p :class="{'activeClass': idClicked == I3997 }" id="I3997"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201595" target="_blank">Agora_A 1595</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM366">366</a></p>
<p :class="{'activeClass': idClicked == I3998 }" id="I3998"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201651" target="_blank">Agora_A 1651</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a> </p>
<p :class="{'activeClass': idClicked == I3999 }" id="I3999"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201693" target="_blank">Agora_A 1693-1744+</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM352">352</a> </p>
<p :class="{'activeClass': idClicked == I4000 }" id="I4000"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%201869" target="_blank">Agora_A 1869</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM363">363</a></p>
<p :class="{'activeClass': idClicked == I4001 }" id="I4001"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202115" target="_blank">Agora_A 2115</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM307">307</a></p>
<p :class="{'activeClass': idClicked == I4002 }" id="I4002"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202257" target="_blank">Agora_A 2257</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a> </p>
<p :class="{'activeClass': idClicked == I4003 }" id="I4003"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202266" target="_blank">Agora_A 2266</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1054'>CM16*</a> </p>
<p :class="{'activeClass': idClicked == I4004 }" id="I4004"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202277" target="_blank">Agora_A 2277</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a> </p>
<p :class="{'activeClass': idClicked == I4005 }" id="I4005"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202293" target="_blank">Agora_A 2293</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1054'>CM16*</a> </p>
<p :class="{'activeClass': idClicked == I4006 }" id="I4006"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202296" target="_blank">Agora_A 2296</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1777'>Gorgon</a> </p>
<p :class="{'activeClass': idClicked == I4007 }" id="I4007"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202425" target="_blank">Agora_A 2425</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM296">296</a></p>
<p :class="{'activeClass': idClicked == I4008 }" id="I4008"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202507" target="_blank">Agora_A 2507</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM108">108</a> </p>
<p :class="{'activeClass': idClicked == I4009 }" id="I4009"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202891" target="_blank">Agora_A 2891-2892</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM286">286</a> </p>
<p :class="{'activeClass': idClicked == I4010 }" id="I4010"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%202969" target="_blank">Agora_A 2969-2973</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM286">286</a> </p>
<p :class="{'activeClass': idClicked == I4011 }" id="I4011"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203001" target="_blank">Agora_A 3001</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP791'>Ath92*</a></p>
<p :class="{'activeClass': idClicked == I4012 }" id="I4012"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203008" target="_blank">Agora_A 3008</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM369">369</a></p>
<p :class="{'activeClass': idClicked == I4013 }" id="I4013"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203349" target="_blank">Agora_A 3349</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM345">345</a></p>
<p :class="{'activeClass': idClicked == I4014 }" id="I4014"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203373" target="_blank">Agora_A 3373</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP515'>Art24*</a></p>
<p :class="{'activeClass': idClicked == I4015 }" id="I4015"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203534" target="_blank">Agora_A 3534</a>	Agora </p>
<p :class="{'activeClass': idClicked == I4016 }" id="I4016"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203774" target="_blank">Agora_A 3774</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP286'>Aph36*</a></p>
<p :class="{'activeClass': idClicked == I4017 }" id="I4017"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203869" target="_blank">Agora_A 3869</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM292">292</a></p>
<p :class="{'activeClass': idClicked == I4018 }" id="I4018"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203874" target="_blank">Agora_A 3874-3877</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4019 }" id="I4019"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%203881" target="_blank">Agora_A 3881-3882</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4020 }" id="I4020"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204017" target="_blank">Agora_A 4017</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4021 }" id="I4021"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204202" target="_blank">Agora_A 4202</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1084'>CM30*</a></p>
<p :class="{'activeClass': idClicked == I4022 }" id="I4022"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204025" target="_blank">Agora_A 4025-4034</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4023 }" id="I4023"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204202" target="_blank">Agora_A 4202</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1084'>CM30*</a></p>
<p :class="{'activeClass': idClicked == I4024 }" id="I4024"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204492" target="_blank">Agora_A 4492</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM297">297</a></p>
<p :class="{'activeClass': idClicked == I4025 }" id="I4025"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204546" target="_blank">Agora_A 4546</a>	Jewish Mon. </p>
<p :class="{'activeClass': idClicked == I4026 }" id="I4026"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/a%204643" target="_blank">Agora_A 4643</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM365">365</a></p>
<h4 :class="{'activeClass': idClicked == I4027 }" id="I4027">AGORA BRONZES</h4>
<p :class="{'activeClass': idClicked == I4028 }" id="I4028"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%2030" target="_blank">Agora_B 30</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a></p>
<p :class="{'activeClass': idClicked == I4029 }" id="I4029"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20253" target="_blank">Agora_B 253</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1776'>Glykon</a></p>
<p :class="{'activeClass': idClicked == I4030 }" id="I4030"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20262" target="_blank">Agora_B 262</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2479'>Lakedaimonian Shield</a></p>
<p :class="{'activeClass': idClicked == I4031 }" id="I4031"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20884" target="_blank">Agora_B 884</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3504'>Telesphoros</a></p>
<p :class="{'activeClass': idClicked == I4032 }" id="I4032"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%20904" target="_blank">Agora_B 904</a>	Part II 323B</p>
<p :class="{'activeClass': idClicked == I4033 }" id="I4033"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201207" target="_blank">Agora_B 1207</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM330">330</a></p>
<p :class="{'activeClass': idClicked == I4034 }" id="I4034"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201270" target="_blank">Agora_B 1270</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1059'>CM17*</a></p>
<p :class="{'activeClass': idClicked == I4035 }" id="I4035"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201373" target="_blank">Agora_B 1373</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a> </p>
<p :class="{'activeClass': idClicked == I4036 }" id="I4036"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/b%201382" target="_blank">Agora_B 1382-1385</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM338">338</a></p>
<h4 :class="{'activeClass': idClicked == I4037 }" id="I4037">AGORA BONE AND IVORY</h4>
<p :class="{'activeClass': idClicked == I4038 }" id="I4038"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/bi%20236" target="_blank">Agora_BI 236</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP384'>Apo59*</a></p>
<p :class="{'activeClass': idClicked == I4039 }" id="I4039"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/bi%20457" target="_blank">Agora_BI 457</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2070'>Her19*</a></p>
<p :class="{'activeClass': idClicked == I4040 }" id="I4040"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/bi%20511" target="_blank">Agora_BI 511</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM225">225</a></p>
<h4 :class="{'activeClass': idClicked == I4041 }" id="I4041">AGORA INSCRIPTIONS</h4>
<p :class="{'activeClass': idClicked == I4042 }" id="I4042"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%2016" target="_blank">Agora_I 16</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3643'>Thrasyboulos</a></p>
<p :class="{'activeClass': idClicked == I4043 }" id="I4043"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20147" target="_blank"><inscr id='Agora_I 147'>Agora_I 147</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2117'>Hro23*</a></p>
<p :class="{'activeClass': idClicked == I4044 }" id="I4044"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20236" target="_blank">Agora_I 236</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1567'>Eleusinion</a></p>
<p :class="{'activeClass': idClicked == I4045 }" id="I4045"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20251" target="_blank">Agora_I 251</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4046 }" id="I4046"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20298" target="_blank"><inscr id='Agora_I 298'>Agora_I 298 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/232611' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1931'>Hrk10*</a></p>
<p :class="{'activeClass': idClicked == I4047 }" id="I4047"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20299" target="_blank"><inscr id='Agora_I 299'>Agora_I 299</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3511'>Thea Epekoos</a></p>
<p :class="{'activeClass': idClicked == I4048 }" id="I4048"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20356" target="_blank"><inscr id='Agora_I 356'>Agora_I 356</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1991'>Hermaios</a></p>
<p :class="{'activeClass': idClicked == I4049 }" id="I4049"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20370" target="_blank"><inscr id='Agora_I 370'>Agora_I 370</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3792'>Zeu2*</a></p>
<p :class="{'activeClass': idClicked == I4050 }" id="I4050"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20380" target="_blank"><inscr id='Agora_I 380'>Agora_I 380</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3686'>Trajan</a></p>
<p :class="{'activeClass': idClicked == I4051 }" id="I4051"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20382" target="_blank"><inscr id='Agora_I 382'>Agora_I 382</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2637'>Menodoros</a></p>
<p :class="{'activeClass': idClicked == I4052 }" id="I4052"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20406" target="_blank">Agora_I 406</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1031'>CM10*</a></p>
<p :class="{'activeClass': idClicked == I4053 }" id="I4053"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20513" target="_blank">Agora_I 513</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1239'>Demeter Azesia</a> </p>
<p :class="{'activeClass': idClicked == I4054 }" id="I4054"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20555" target="_blank">Agora_I 555</a>	Persian War Memorials </p>
<p :class="{'activeClass': idClicked == I4055 }" id="I4055"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20562" target="_blank"><inscr id='Agora_I 562'>Agora_I 562</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1216'>Demeter</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> </p>
<p :class="{'activeClass': idClicked == I4056 }" id="I4056"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20578" target="_blank"><inscr id='Agora_I 578'>Agora_I 578</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1179'>Claudius</a></p>
<p :class="{'activeClass': idClicked == I4057 }" id="I4057"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20605" target="_blank">Agora_I 605</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2358'>Kephisodoros</a></p>
<p :class="{'activeClass': idClicked == I4058 }" id="I4058"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20608" target="_blank"><inscr id='Agora_I 608'>Agora_I 608</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3922'>Zeu108*</a> </p>
<p :class="{'activeClass': idClicked == I4059 }" id="I4059"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20708" target="_blank"><inscr id='Agora_I 708'>Agora_I 708</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2755'>Neokles</a></p>
<p :class="{'activeClass': idClicked == I4060 }" id="I4060"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20808" target="_blank">Agora_I 808</a>	Geta</p>
<p :class="{'activeClass': idClicked == I4061 }" id="I4061"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20848" target="_blank"><inscr id='Agora_I 848'>Agora_I 848</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2975'>Pantainos</a></p>
<p :class="{'activeClass': idClicked == I4062 }" id="I4062"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20849" target="_blank">Agora_I 849</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3694'>Trebellius Rufus</a></p>
<p :class="{'activeClass': idClicked == I4063 }" id="I4063"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20863" target="_blank"><inscr id='Agora_I 863'>Agora_I 863 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/233032' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVI/182' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3473'>Strategeion</a></p>
<p :class="{'activeClass': idClicked == I4064 }" id="I4064"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20895" target="_blank"><inscr id='Agora_I 895'>Agora_I 895</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem38*</p>
<p :class="{'activeClass': idClicked == I4065 }" id="I4065"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20984" target="_blank">Agora_I 984</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM323">323</a></p>
<p :class="{'activeClass': idClicked == I4066 }" id="I4066"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%20994" target="_blank">Agora_I 994</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP989'>Chabrias</a></p>
<p :class="{'activeClass': idClicked == I4067 }" id="I4067"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201005" target="_blank">Agora_I 1005</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP499'>Art8*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP614'>Ask45*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP615'>Ask46*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP804'>Ath100*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1786'>Gymnasium Sculpture Inventory</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1992'>Hermaphroditos</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2012'>Hrm14*</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2728'>Muses</a>, <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3685'>Tragoidia</a></p>
<p :class="{'activeClass': idClicked == I4068 }" id="I4068"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201008" target="_blank">Agora_I 1008</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem18*</p>
<p :class="{'activeClass': idClicked == I4069 }" id="I4069"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201052" target="_blank"><inscr id='Agora_I 1052'>Agora_I 1052</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1923'>Hrk4*</a></p>
<p :class="{'activeClass': idClicked == I4070 }" id="I4070"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201078" target="_blank">Agora_I 1078</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2098'>Hro7*</a></p>
<p :class="{'activeClass': idClicked == I4071 }" id="I4071"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201191" target="_blank"><inscr id='Agora_I 1191'>Agora_I 1191</inscr></a>	Trittys</p>
<p :class="{'activeClass': idClicked == I4072 }" id="I4072"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201207" target="_blank"><inscr id='Agora_I 1207'>Agora_I 1207</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem36*</p>
<p :class="{'activeClass': idClicked == I4073 }" id="I4073"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201349" target="_blank"><inscr id='Agora_I 1349'>Agora_I 1349</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP546'>Art46*</a></p>
<p :class="{'activeClass': idClicked == I4074 }" id="I4074"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201454" target="_blank">Agora_I 1454</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP410'>Apo80*</a></p>
<p :class="{'activeClass': idClicked == I4075 }" id="I4075"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201559" target="_blank"><inscr id='Agora_I 1559'>Agora_I 1559</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2094'>Hro3*</a></p>
<p :class="{'activeClass': idClicked == I4076 }" id="I4076"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201567" target="_blank"><inscr id='Agora_I 1567'>Agora_I 1567</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3488'>Synhedrion</a></p>
<p :class="{'activeClass': idClicked == I4077 }" id="I4077"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201571" target="_blank">Agora_I 1571</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3752'>Ulpius Eubiotus</a></p>
<p :class="{'activeClass': idClicked == I4078 }" id="I4078"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201597" target="_blank"><inscr id='Agora_I 1597'>Agora_I 1597</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3734'>Twelve Gods</a></p>
<p :class="{'activeClass': idClicked == I4079 }" id="I4079"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201732" target="_blank"><inscr id='Agora_I 1732'>Agora_I 1732</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP751'>Ath61*</a></p>
<p :class="{'activeClass': idClicked == I4080 }" id="I4080"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201740" target="_blank"><inscr id='Agora_I 1740'>Agora_I 1740</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2490'>Leagros the Younger</a></p>
<p :class="{'activeClass': idClicked == I4081 }" id="I4081"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201749" target="_blank">Agora_I 1749</a>	Philokrates, Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM594">594</a>, Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM600">600</a></p>
<p :class="{'activeClass': idClicked == I4082 }" id="I4082"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201787" target="_blank"><inscr id='Agora_I 1787'>Agora_I 1787</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP307'>Aph53*</a></p>
<p :class="{'activeClass': idClicked == I4083 }" id="I4083"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201797" target="_blank"><inscr id='Agora_I 1797'>Agora_I 1797</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3892'>Zeu84*</a></p>
<p :class="{'activeClass': idClicked == I4084 }" id="I4084"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201898" target="_blank"><inscr id='Agora_I 1898'>Agora_I 1898</inscr></a>	Melanopos</p>
<p :class="{'activeClass': idClicked == I4085 }" id="I4085"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201906" target="_blank"><inscr id='Agora_I 1906'>Agora_I 1906 [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVI/161' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2101'>Hro10*</a></p>
<p :class="{'activeClass': idClicked == I4086 }" id="I4086"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%201936" target="_blank"><inscr id='Agora_I 1936'>Agora_I 1936</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2439'>Kornelios Onomarchos</a> </p>
<p :class="{'activeClass': idClicked == I4087 }" id="I4087"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202003" target="_blank"><inscr id='Agora_I 2003'>Agora_I 2003 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/230906' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP33'>Agathos Daimon</a></p>
<p :class="{'activeClass': idClicked == I4088 }" id="I4088"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202006" target="_blank">Agora_I 2006</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem27*</p>
<p :class="{'activeClass': idClicked == I4089 }" id="I4089"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202044" target="_blank">Agora_I 2044</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1769'>Gephyraioi</a></p>
<p :class="{'activeClass': idClicked == I4090 }" id="I4090"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202045" target="_blank"><inscr id='Agora_I 2045'>Agora_I 2045</inscr></a>	Trittys</p>
<p :class="{'activeClass': idClicked == I4091 }" id="I4091"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202061" target="_blank"><inscr id='Agora_I 2061'>Agora_I 2061</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP179'>Anakreon</a></p>
<p :class="{'activeClass': idClicked == I4092 }" id="I4092"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202080" target="_blank">Agora_I 2080</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a></p>
<p :class="{'activeClass': idClicked == I4093 }" id="I4093"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202197" target="_blank"><inscr id='Agora_I 2197'>Agora_I 2197</inscr></a>	Trittys</p>
<p :class="{'activeClass': idClicked == I4094 }" id="I4094"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202201" target="_blank"><inscr id='Agora_I 2201'>Agora_I 2201</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3860'>Zeu56*</a></p>
<p :class="{'activeClass': idClicked == I4095 }" id="I4095"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202221" target="_blank"><inscr id='Agora_I 2221'>Agora_I 2221</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP814'>Ath109*</a></p>
<p :class="{'activeClass': idClicked == I4096 }" id="I4096"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202277" target="_blank"><inscr id='Agora_I 2277'>Agora_I 2277</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem24*</p>
<p :class="{'activeClass': idClicked == I4097 }" id="I4097"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202361" target="_blank"><inscr id='Agora_I 2361'>Agora_I 2361 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/233098' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVI/225' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP517'>Art26*</a></p>
<p :class="{'activeClass': idClicked == I4098 }" id="I4098"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202470" target="_blank">Agora_I 2470</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1567'>Eleusinion</a></p>
<p :class="{'activeClass': idClicked == I4099 }" id="I4099"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202472" target="_blank">Agora_I 2472</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2691'>Met2*</a></p>
<p :class="{'activeClass': idClicked == I4100 }" id="I4100"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202474" target="_blank"><inscr id='Agora_I 2474'>Agora_I 2474</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3861'>Zeu57*</a></p>
<p :class="{'activeClass': idClicked == I4101 }" id="I4101"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202483" target="_blank">Agora_I 2483</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3811'>Zeu18*</a></p>
<p :class="{'activeClass': idClicked == I4102 }" id="I4102"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202526" target="_blank"><inscr id='Agora_I 2526'>Agora_I 2526</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP246'>Aph2*</a></p>
<p :class="{'activeClass': idClicked == I4103 }" id="I4103"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202618" target="_blank"><inscr id='Agora_I 2618'>Agora_I 2618</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM322">322</a></p>
<p :class="{'activeClass': idClicked == I4104 }" id="I4104"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202638" target="_blank"><inscr id='Agora_I 2638'>Agora_I 2638</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2123'>Hro28*</a></p>
<p :class="{'activeClass': idClicked == I4105 }" id="I4105"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202658" target="_blank"><inscr id='Agora_I 2658'>Agora_I 2658</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2072'>Herod the Great</a></p>
<p :class="{'activeClass': idClicked == I4106 }" id="I4106"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202669" target="_blank"><inscr id='Agora_I 2669'>Agora_I 2669</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2696'>Met6*</a></p>
<p :class="{'activeClass': idClicked == I4107 }" id="I4107"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202729" target="_blank"><inscr id='Agora_I 2729'>Agora_I 2729</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2975'>Pantainos</a></p>
<p :class="{'activeClass': idClicked == I4108 }" id="I4108"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202778" target="_blank"><inscr id='Agora_I 2778'>Agora_I 2778</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3862'>Zeu58*</a></p>
<p :class="{'activeClass': idClicked == I4109 }" id="I4109"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202800" target="_blank"><inscr id='Agora_I 2800'>Agora_I 2800</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM319">319</a></p>
<p :class="{'activeClass': idClicked == I4110 }" id="I4110"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202818" target="_blank"><inscr id='Agora_I 2818'>Agora_I 2818</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP968'>Caesar</a></p>
<p :class="{'activeClass': idClicked == I4111 }" id="I4111"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%202843" target="_blank"><inscr id='Agora_I 2843'>Agora_I 2843</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP491'>Art1*</a></p>
<p :class="{'activeClass': idClicked == I4112 }" id="I4112"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203005" target="_blank">Agora_I 3005</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2'>Abecedaria</a></p>
<p :class="{'activeClass': idClicked == I4113 }" id="I4113"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203042" target="_blank"><inscr id='Agora_I 3042'>Agora_I 3042</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP968'>Caesar</a></p>
<p :class="{'activeClass': idClicked == I4114 }" id="I4114"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203068" target="_blank">Agora_I 3068</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2503'>Leos</a></p>
<p :class="{'activeClass': idClicked == I4115 }" id="I4115"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203071" target="_blank"><inscr id='Agora_I 3071'>Agora_I 3071</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP236'>Antonius</a></p>
<p :class="{'activeClass': idClicked == I4116 }" id="I4116"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203181" target="_blank"><inscr id='Agora_I 3181'>Agora_I 3181</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem32*</p>
<p :class="{'activeClass': idClicked == I4117 }" id="I4117"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203205" target="_blank"><inscr id='Agora_I 3205'>Agora_I 3205</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3477'>Straton</a></p>
<p :class="{'activeClass': idClicked == I4118 }" id="I4118"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203226" target="_blank"><inscr id='Agora_I 3226'>Agora_I 3226</inscr></a>	Agora</p>
<p :class="{'activeClass': idClicked == I4119 }" id="I4119"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203244" target="_blank">Agora_I 3244</a>	Agora in <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2417'>Koile</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP738'>Ath53*</a></p>
<p :class="{'activeClass': idClicked == I4120 }" id="I4120"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203328" target="_blank"><inscr id='Agora_I 3328'>Agora_I 3328</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1732'>Euporia</a></p>
<p :class="{'activeClass': idClicked == I4121 }" id="I4121"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203361" target="_blank"><inscr id='Agora_I 3361'>Agora_I 3361</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3332'>Sacred Way</a></p>
<p :class="{'activeClass': idClicked == I4122 }" id="I4122"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203366" target="_blank">Agora_I 3366</a>	Brutus and Cassius</p>
<p :class="{'activeClass': idClicked == I4123 }" id="I4123"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203398" target="_blank"><inscr id='Agora_I 3398'>Agora_I 3398</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP471'>Aristeides</a></p>
<p :class="{'activeClass': idClicked == I4124 }" id="I4124"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203525" target="_blank"><inscr id='Agora_I 3525'>Agora_I 3525</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM320">320</a></p>
<p :class="{'activeClass': idClicked == I4125 }" id="I4125"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203542" target="_blank"><inscr id='Agora_I 3542'>Agora_I 3542</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2234'>Iamblichos</a></p>
<p :class="{'activeClass': idClicked == I4126 }" id="I4126"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203551" target="_blank"><inscr id='Agora_I 3551'>Agora_I 3551</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3841'>Zeu42*</a></p>
<p :class="{'activeClass': idClicked == I4127 }" id="I4127"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203558" target="_blank"><inscr id='Agora_I 3558'>Agora_I 3558</inscr></a>	Eudokia</p>
<p :class="{'activeClass': idClicked == I4128 }" id="I4128"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203624" target="_blank"><inscr id='Agora_I 3624'>Agora_I 3624</inscr></a>	Propylon</p>
<p :class="{'activeClass': idClicked == I4129 }" id="I4129"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203706" target="_blank"><inscr id='Agora_I 3706'>Agora_I 3706</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3905'>Zeu94*</a></p>
<p :class="{'activeClass': idClicked == I4130 }" id="I4130"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203794" target="_blank"><inscr id='Agora_I 3794'>Agora_I 3794</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3844'>Zeu45*</a></p>
<p :class="{'activeClass': idClicked == I4131 }" id="I4131"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203837" target="_blank">Agora_I 3837</a>	Islamic . . . Mon.</p>
<p :class="{'activeClass': idClicked == I4132 }" id="I4132"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203868" target="_blank"><inscr id='Agora_I 3868'>Agora_I 3868</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3863'>Zeu59*</a></p>
<p :class="{'activeClass': idClicked == I4133 }" id="I4133"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%203872" target="_blank"><inscr id='Agora_I 3872'>Agora_I 3872 [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVIII/A1' target='_blank'>AOI</a>] </inscr></a>	Harmodios</p>
<p :class="{'activeClass': idClicked == I4134 }" id="I4134"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204012" target="_blank"><inscr id='Agora_I 4012'>Agora_I 4012</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2541'>Livia</a></p>
<p :class="{'activeClass': idClicked == I4135 }" id="I4135"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204031" target="_blank">Agora_I 4031</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> 33</p>
<p :class="{'activeClass': idClicked == I4136 }" id="I4136"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204039" target="_blank"><inscr id='Agora_I 4039'>Agora_I 4039</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2596'>Marcus Aurelius</a> and L. Verus</p>
<p :class="{'activeClass': idClicked == I4137 }" id="I4137"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204088" target="_blank"><inscr id='Agora_I 4088'>Agora_I 4088</inscr></a>	Chr. Mon. CM27*I </p>
<p :class="{'activeClass': idClicked == I4138 }" id="I4138"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204090A" target="_blank">Agora_I 4090A</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP813'>Ath107*</a></p>
<p :class="{'activeClass': idClicked == I4139 }" id="I4139"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204090B" target="_blank">Agora_I 4090B</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> Ath108*</p>
<p :class="{'activeClass': idClicked == I4140 }" id="I4140"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204147" target="_blank"><inscr id='Agora_I 4147'>Agora_I 4147</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP231'>Antoninus Pius</a></p>
<p :class="{'activeClass': idClicked == I4141 }" id="I4141"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204165" target="_blank"><inscr id='Agora_I 4165'>Agora_I 4165</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a></p>
<p :class="{'activeClass': idClicked == I4142 }" id="I4142"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204168" target="_blank">Agora_I 4168</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> 34</p>
<p :class="{'activeClass': idClicked == I4143 }" id="I4143"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204188" target="_blank"><inscr id='Agora_I 4188'>Agora_I 4188</inscr></a>	Hadrian</p>
<p :class="{'activeClass': idClicked == I4144 }" id="I4144"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204205" target="_blank"><inscr id='Agora_I 4205'>Agora_I 4205</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP374'>Apo51*</a></p>
<p :class="{'activeClass': idClicked == I4145 }" id="I4145"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204226" target="_blank"><inscr id='Agora_I 4226'>Agora_I 4226 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/230872' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2069'>Her18*</a></p>
<p :class="{'activeClass': idClicked == I4146 }" id="I4146"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204256" target="_blank">Agora_I 4256</a>	Persian War Memorials</p>
<p :class="{'activeClass': idClicked == I4147 }" id="I4147"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204268" target="_blank"><inscr id='Agora_I 4268'>Agora_I 4268</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM371">371</a></p>
<p :class="{'activeClass': idClicked == I4148 }" id="I4148"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204280" target="_blank"><inscr id='Agora_I 4280'>Agora_I 4280</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3762'>Varius Caelianus</a></p>
<p :class="{'activeClass': idClicked == I4149 }" id="I4149"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204294" target="_blank"><inscr id='Agora_I 4294'>Agora_I 4294</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3845'>Zeu46*</a></p>
<p :class="{'activeClass': idClicked == I4150 }" id="I4150"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204313" target="_blank"><inscr id='Agora_I 4313'>Agora_I 4313</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1530'>Drusilla</a></p>
<p :class="{'activeClass': idClicked == I4151 }" id="I4151"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204347" target="_blank"><inscr id='Agora_I 4347'>Agora_I 4347</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2207'>Horus</a>  <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2862'>Osiris</a></p>
<p :class="{'activeClass': idClicked == I4152 }" id="I4152"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204348" target="_blank"><inscr id='Agora_I 4348'>Agora_I 4348</inscr></a>	All The Gods</p>
<p :class="{'activeClass': idClicked == I4153 }" id="I4153"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204373" target="_blank"><inscr id='Agora_I 4373'>Agora_I 4373</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3643'>Thrasyboulos</a></p>
<p :class="{'activeClass': idClicked == I4154 }" id="I4154"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204468" target="_blank"><inscr id='Agora_I 4468'>Agora_I 4468</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1932'>Hrk10**</a></p>
<p :class="{'activeClass': idClicked == I4155 }" id="I4155"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204571" target="_blank">Agora_I 4571</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3239'>Potter Hero</a></p>
<p :class="{'activeClass': idClicked == I4156 }" id="I4156"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204572" target="_blank">Agora_I 4572</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP920'>Blaute and Kourotrophos</a></p>
<p :class="{'activeClass': idClicked == I4157 }" id="I4157"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204642" target="_blank"><inscr id='Agora_I 4642'>Agora_I 4642</inscr></a>	Vespasian</p>
<p :class="{'activeClass': idClicked == I4158 }" id="I4158"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204707" target="_blank"><inscr id='Agora_I 4707'>Agora_I 4707</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2095'>Hro4*</a></p>
<p :class="{'activeClass': idClicked == I4159 }" id="I4159"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204721" target="_blank">Agora_I 4721</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1567'>Eleusinion</a></p>
<p :class="{'activeClass': idClicked == I4160 }" id="I4160"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204745" target="_blank"><inscr id='Agora_I 4745'>Agora_I 4745</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3107'>Phosphoroi</a></p>
<p :class="{'activeClass': idClicked == I4161 }" id="I4161"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204773" target="_blank"><inscr id='Agora_I 4773'>Agora_I 4773</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2792'>Nymphaion</a></p>
<p :class="{'activeClass': idClicked == I4162 }" id="I4162"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204790" target="_blank"><inscr id='Agora_I 4790'>Agora_I 4790</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2746'>Nemesis</a></p>
<p :class="{'activeClass': idClicked == I4163 }" id="I4163"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204791" target="_blank"><inscr id='Agora_I 4791'>Agora_I 4791</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2099'>Hro8*</a></p>
<p :class="{'activeClass': idClicked == I4164 }" id="I4164"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204804" target="_blank"><inscr id='Agora_I 4804'>Agora_I 4804</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3893'>Zeu85*</a></p>
<p :class="{'activeClass': idClicked == I4165 }" id="I4165"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204825" target="_blank">Agora_I 4825</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3892'>Zeu84*</a></p>
<p :class="{'activeClass': idClicked == I4166 }" id="I4166"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204853" target="_blank"><inscr id='Agora_I 4853'>Agora_I 4853 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/233293' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1754'>Fulvius Plautianus</a></p>
<p :class="{'activeClass': idClicked == I4167 }" id="I4167"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204869" target="_blank"><inscr id='Agora_I 4869'>Agora_I 4869</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP931'>Bouleuterion</a></p>
<p :class="{'activeClass': idClicked == I4168 }" id="I4168"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204917" target="_blank"><inscr id='Agora_I 4917'>Agora_I 4917 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/231042' target='_blank'>PHI</a>]  [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXV/171' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3854'>Zeu53*</a></p>
<p :class="{'activeClass': idClicked == I4169 }" id="I4169"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204922" target="_blank"><inscr id='Agora_I 4922'>Agora_I 4922</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1178'>Civica Barbarus</a></p>
<p :class="{'activeClass': idClicked == I4170 }" id="I4170"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%204963" target="_blank"><inscr id='Agora_I 4963'>Agora_I 4963</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2932'>Panathenaic Way</a></p>
<p :class="{'activeClass': idClicked == I4171 }" id="I4171"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205016" target="_blank"><inscr id='Agora_I 5016'>Agora_I 5016</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1538'>Eileithyia</a></p>
<p :class="{'activeClass': idClicked == I4172 }" id="I4172"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205047" target="_blank">Agora_I 5047</a>	Septimus Severus and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP980'>Caracalla</a></p>
<p :class="{'activeClass': idClicked == I4173 }" id="I4173"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205043" target="_blank">Agora_I 5043</a>	Trittys</p>
<p :class="{'activeClass': idClicked == I4174 }" id="I4174"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205054" target="_blank"><inscr id='Agora_I 5054'>Agora_I 5054</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP441'>Areopagus</a></p>
<p :class="{'activeClass': idClicked == I4175 }" id="I4175"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205128" target="_blank"><inscr id='Agora_I 5128'>Agora_I 5128</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP248'>Aph5*</a></p>
<p :class="{'activeClass': idClicked == I4176 }" id="I4176"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205165" target="_blank"><inscr id='Agora_I 5165'>Agora_I 5165 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/233185' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a></p>
<p :class="{'activeClass': idClicked == I4177 }" id="I4177"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205212" target="_blank"><inscr id='Agora_I 5212'>Agora_I 5212</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1916'>Herakleidai</a></p>
<p :class="{'activeClass': idClicked == I4178 }" id="I4178"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205231" target="_blank"><inscr id='Agora_I 5231'>Agora_I 5231</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a></p>
<p :class="{'activeClass': idClicked == I4179 }" id="I4179"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205290" target="_blank"><inscr id='Agora_I 5290'>Agora_I 5290</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2325'>Kalliste</a></p>
<p :class="{'activeClass': idClicked == I4180 }" id="I4180"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205344" target="_blank"><inscr id='Agora_I 5344'>Agora_I 5344 [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVI/275' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3640'>Tholos</a></p>
<p :class="{'activeClass': idClicked == I4181 }" id="I4181"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205436" target="_blank"><inscr id='Agora_I 5436'>Agora_I 5436</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1532'>Eagle</a></p>
<p :class="{'activeClass': idClicked == I4182 }" id="I4182"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205476" target="_blank"><inscr id='Agora_I 5476'>Agora_I 5476</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3332'>Sacred Way</a></p>
<p :class="{'activeClass': idClicked == I4183 }" id="I4183"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205485" target="_blank"><inscr id='Agora_I 5485'>Agora_I 5485</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3046'>Phaidros</a></p>
<p :class="{'activeClass': idClicked == I4184 }" id="I4184"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205509" target="_blank"><inscr id='Agora_I 5509'>Agora_I 5509 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/232637' target='_blank'>PHI</a>] </inscr></a>	Daidaleion</p>
<p :class="{'activeClass': idClicked == I4185 }" id="I4185"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205510" target="_blank"><inscr id='Agora_I 5510'>Agora_I 5510</inscr></a>	Agora (2)</p>
<p :class="{'activeClass': idClicked == I4186 }" id="I4186"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205517" target="_blank"><inscr id='Agora_I 5517'>Agora_I 5517</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP315'>Apo4*</a></p>
<p :class="{'activeClass': idClicked == I4187 }" id="I4187"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205529" target="_blank">Agora_I 5529</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2'>Abecedaria</a></p>
<p :class="{'activeClass': idClicked == I4188 }" id="I4188"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205543" target="_blank">Agora_I 5543</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3810'>Zeu17*</a></p>
<p :class="{'activeClass': idClicked == I4189 }" id="I4189"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205564" target="_blank"><inscr id='Agora_I 5564'>Agora_I 5564</inscr></a>	Trittys</p>
<p :class="{'activeClass': idClicked == I4190 }" id="I4190"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205569" target="_blank">Agora_I 5569</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP381'>Apo56*</a></p>
<p :class="{'activeClass': idClicked == I4191 }" id="I4191"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205675" target="_blank">Agora_I 5675</a>	Agora</p>
<p :class="{'activeClass': idClicked == I4192 }" id="I4192"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205679" target="_blank">Agora_I 5679</a>	Amphiktyonic Decree</p>
<p :class="{'activeClass': idClicked == I4193 }" id="I4193"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205738" target="_blank"><inscr id='Agora_I 5738'>Agora_I 5738</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2005'>Hrm7*</a></p>
<p :class="{'activeClass': idClicked == I4194 }" id="I4194"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205770" target="_blank"><inscr id='Agora_I 5770'>Agora_I 5770</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2365'>Kerameikos</a></p>
<p :class="{'activeClass': idClicked == I4195 }" id="I4195"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205784" target="_blank"><inscr id='Agora_I 5784'>Agora_I 5784</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM322">322</a></p>
<p :class="{'activeClass': idClicked == I4196 }" id="I4196"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205868" target="_blank"><inscr id='Agora_I 5868'>Agora_I 5868</inscr></a>	Butterfly</p>
<p :class="{'activeClass': idClicked == I4197 }" id="I4197"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205890" target="_blank"><inscr id='Agora_I 5890'>Agora_I 5890</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3711'>Tritopateres</a></p>
<p :class="{'activeClass': idClicked == I4198 }" id="I4198"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205910" target="_blank"><inscr id='Agora_I 5910'>Agora_I 5910</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2361'>Kephisos</a></p>
<p :class="{'activeClass': idClicked == I4199 }" id="I4199"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205960" target="_blank"><inscr id='Agora_I 5960'>Agora_I 5960</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3842'>Zeu43*</a></p>
<p :class="{'activeClass': idClicked == I4200 }" id="I4200"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205968" target="_blank"><inscr id='Agora_I 5968'>Agora_I 5968</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2110'>Hro16*</a></p>
<p :class="{'activeClass': idClicked == I4201 }" id="I4201"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205975" target="_blank"><inscr id='Agora_I 5975'>Agora_I 5975</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2432'>Konon</a></p>
<p :class="{'activeClass': idClicked == I4202 }" id="I4202"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%205983" target="_blank">Agora_I 5983</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3822'>Zeu28*</a></p>
<p :class="{'activeClass': idClicked == I4203 }" id="I4203"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206010" target="_blank"><inscr id='Agora_I 6010'>Agora_I 6010</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3783'>Zeno of Kition</a></p>
<p :class="{'activeClass': idClicked == I4204 }" id="I4204"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206017" target="_blank"><inscr id='Agora_I 6017'>Agora_I 6017</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3647'>Thrasykles</a></p>
<p :class="{'activeClass': idClicked == I4205 }" id="I4205"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206051" target="_blank"><inscr id='Agora_I 6051'>Agora_I 6051</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3582'>Theodoros of Gadara</a></p>
<p :class="{'activeClass': idClicked == I4206 }" id="I4206"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206078" target="_blank"><inscr id='Agora_I 6078'>Agora_I 6078</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP142'>Alopeke</a> Trittys</p>
<p :class="{'activeClass': idClicked == I4207 }" id="I4207"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206095" target="_blank">Agora_I 6095</a>	Islamic . . . Mon.</p>
<p :class="{'activeClass': idClicked == I4208 }" id="I4208"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206108" target="_blank"><inscr id='Agora_I 6108'>Agora_I 6108</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2611'>Megaloi Theoi</a></p>
<p :class="{'activeClass': idClicked == I4209 }" id="I4209"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206120" target="_blank"><inscr id='Agora_I 6120'>Agora_I 6120</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and II</p>
<p :class="{'activeClass': idClicked == I4210 }" id="I4210"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206135" target="_blank"><inscr id='Agora_I 6135'>Agora_I 6135</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP862'>Attalos I</a> and II</p>
<p :class="{'activeClass': idClicked == I4211 }" id="I4211"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206243" target="_blank"><inscr id='Agora_I 6243'>Agora_I 6243</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP68'>Agrippa</a></p>
<p :class="{'activeClass': idClicked == I4212 }" id="I4212"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206311" target="_blank"><inscr id='Agora_I 6311'>Agora_I 6311</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1216'>Demeter</a></p>
<p :class="{'activeClass': idClicked == I4213 }" id="I4213"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206373" target="_blank">Agora_I 6373</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3889'>Zeu82*</a></p>
<p :class="{'activeClass': idClicked == I4214 }" id="I4214"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206381" target="_blank"><inscr id='Agora_I 6381'>Agora_I 6381</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM321">321</a></p>
<p :class="{'activeClass': idClicked == I4215 }" id="I4215"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206523" target="_blank">Agora_I 6523</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem32*</p>
<p :class="{'activeClass': idClicked == I4216 }" id="I4216"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206524" target="_blank"><inscr id='Agora_I 6524'>Agora_I 6524</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1272'>Demos</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1267'>Demokratia</a></p>
<p :class="{'activeClass': idClicked == I4217 }" id="I4217"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206578" target="_blank"><inscr id='Agora_I 6578'>Agora_I 6578 [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVIII/V603' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2671'>Mnemosyne</a></p>
<p :class="{'activeClass': idClicked == I4218 }" id="I4218"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206595" target="_blank"><inscr id='Agora_I 6595'>Agora_I 6595</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2096'>Hro5*</a></p>
<p :class="{'activeClass': idClicked == I4219 }" id="I4219"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206599" target="_blank"><inscr id='Agora_I 6599'>Agora_I 6599</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3864'>Zeu60*</a></p>
<p :class="{'activeClass': idClicked == I4220 }" id="I4220"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206606" target="_blank"><inscr id='Agora_I 6606'>Agora_I 6606</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3843'>Zeu44*</a></p>
<p :class="{'activeClass': idClicked == I4221 }" id="I4221"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206627" target="_blank"><inscr id='Agora_I 6627'>Agora_I 6627</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3348'>Sarapis</a></p>
<p :class="{'activeClass': idClicked == I4222 }" id="I4222"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206628" target="_blank"><inscr id='Agora_I 6628'>Agora_I 6628</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2240'>Iliad</a></p>
<p :class="{'activeClass': idClicked == I4223 }" id="I4223"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206634" target="_blank">Agora_I 6634</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a>, altar</p>
<p :class="{'activeClass': idClicked == I4224 }" id="I4224"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206656" target="_blank">Agora_I 6656</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP642'>Ath4*</a></p>
<p :class="{'activeClass': idClicked == I4225 }" id="I4225"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206673" target="_blank">Agora_I 6673</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3752'>Ulpius Eubiotus</a></p>
<p :class="{'activeClass': idClicked == I4226 }" id="I4226"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206690" target="_blank">Agora_I 6690</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2148'>Hro50*</a></p>
<p :class="{'activeClass': idClicked == I4227 }" id="I4227"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206699" target="_blank"><inscr id='Agora_I 6699'>Agora_I 6699</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3396'>Skambonidai</a> Trittys</p>
<p :class="{'activeClass': idClicked == I4228 }" id="I4228"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206700" target="_blank"><inscr id='Agora_I 6700'>Agora_I 6700</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM323">323</a></p>
<p :class="{'activeClass': idClicked == I4229 }" id="I4229"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206709" target="_blank"><inscr id='Agora_I 6709'>Agora_I 6709</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3788'>Zeus</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3904'>Zeu93*</a> </p>
<p :class="{'activeClass': idClicked == I4230 }" id="I4230"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206737" target="_blank"><inscr id='Agora_I 6737'>Agora_I 6737</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a></p>
<p :class="{'activeClass': idClicked == I4231 }" id="I4231"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206738" target="_blank"><inscr id='Agora_I 6738'>Agora_I 6738</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2952'>Panhellenion</a></p>
<p :class="{'activeClass': idClicked == I4232 }" id="I4232"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206827" target="_blank"><inscr id='Agora_I 6827'>Agora_I 6827</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1265'>Demokles</a></p>
<p :class="{'activeClass': idClicked == I4233 }" id="I4233"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206835" target="_blank"><inscr id='Agora_I 6835'>Agora_I 6835</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2365'>Kerameikos</a></p>
<p :class="{'activeClass': idClicked == I4234 }" id="I4234"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206876" target="_blank"><inscr id='Agora_I 6876'>Agora_I 6876</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2536'>Lithos</a></p>
<p :class="{'activeClass': idClicked == I4235 }" id="I4235"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206909" target="_blank"><inscr id='Agora_I 6909'>Agora_I 6909</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a></p>
<p :class="{'activeClass': idClicked == I4236 }" id="I4236"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206952" target="_blank"><inscr id='Agora_I 6952'>Agora_I 6952</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> Aph3*</p>
<p :class="{'activeClass': idClicked == I4237 }" id="I4237"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206953" target="_blank"><inscr id='Agora_I 6953'>Agora_I 6953</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem8*</p>
<p :class="{'activeClass': idClicked == I4238 }" id="I4238"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206969" target="_blank">Agora_I 6969</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP516'>Art25*</a></p>
<p :class="{'activeClass': idClicked == I4239 }" id="I4239"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206992" target="_blank"><inscr id='Agora_I 6992'>Agora_I 6992 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/231378' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem38*</p>
<p :class="{'activeClass': idClicked == I4240 }" id="I4240"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206960" target="_blank"><inscr id='Agora_I 6960'>Agora_I 6960</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a></p>
<p :class="{'activeClass': idClicked == I4241 }" id="I4241"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206969" target="_blank">Agora_I 6969</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP516'>Art25*</a></p>
<p :class="{'activeClass': idClicked == I4242 }" id="I4242"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%206992" target="_blank"><inscr id='Agora_I 6992'>Agora_I 6992 [<a v-on:click.prevent="replaceLink"   href='https://epigraphy.packhum.org/text/231378' target='_blank'>PHI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem38*</p>
<p :class="{'activeClass': idClicked == I4243 }" id="I4243"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207002" target="_blank"><inscr id='Agora_I 7002'>Agora_I 7002</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP421'>Apollodoros</a></p>
<p :class="{'activeClass': idClicked == I4244 }" id="I4244"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207006" target="_blank"><inscr id='Agora_I 7006'>Agora_I 7006</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM235">235</a></p>
<p :class="{'activeClass': idClicked == I4245 }" id="I4245"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207009" target="_blank"><inscr id='Agora_I 7009'>Agora_I 7009</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem15*</p>
<p :class="{'activeClass': idClicked == I4246 }" id="I4246"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207012" target="_blank"><inscr id='Agora_I 7012'>Agora_I 7012</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM328">328</a></p>
<p :class="{'activeClass': idClicked == I4247 }" id="I4247"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207015" target="_blank"><inscr id='Agora_I 7015'>Agora_I 7015</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2097'>Hro6*</a></p>
<p :class="{'activeClass': idClicked == I4248 }" id="I4248"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207030" target="_blank"><inscr id='Agora_I 7030'>Agora_I 7030</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2653'>Metronomoi</a></p>
<p :class="{'activeClass': idClicked == I4249 }" id="I4249"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207039" target="_blank">Agora_I 7039</a>	Agora</p>
<p :class="{'activeClass': idClicked == I4250 }" id="I4250"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207047" target="_blank"><inscr id='Agora_I 7047'>Agora_I 7047</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP766'>Ath71*</a></p>
<p :class="{'activeClass': idClicked == I4251 }" id="I4251"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207154" target="_blank"><inscr id='Agora_I 7154'>Agora_I 7154</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2041'>Hrm37*</a></p>
<p :class="{'activeClass': idClicked == I4252 }" id="I4252"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207166" target="_blank"><inscr id='Agora_I 7166'>Agora_I 7166</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM613">613</a></p>
<p :class="{'activeClass': idClicked == I4253 }" id="I4253"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207167" target="_blank"><inscr id='Agora_I 7167'>Agora_I 7167</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM339">339</a></p>
<p :class="{'activeClass': idClicked == I4254 }" id="I4254"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207168" target="_blank">Agora_I 7168</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> Hrm1**</p>
<p :class="{'activeClass': idClicked == I4255 }" id="I4255"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207180" target="_blank">Agora_I 7180</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3693'>Trapezai</a></p>
<p :class="{'activeClass': idClicked == I4256 }" id="I4256"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207265" target="_blank"><inscr id='Agora_I 7265'>Agora_I 7265</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM337">337</a></p>
<p :class="{'activeClass': idClicked == I4257 }" id="I4257"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207295" target="_blank"><inscr id='Agora_I 7295'>Agora_I 7295 [<a v-on:click.prevent="replaceLink"   href='https://www.atticinscriptions.com/inscription/AgoraXVI/255' target='_blank'>AOI</a>] </inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> Archegetis <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2318'>Kallias</a> of Sphettos</p>
<p :class="{'activeClass': idClicked == I4258 }" id="I4258"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207307" target="_blank">Agora_I 7307</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1567'>Eleusinion</a></p>
<p :class="{'activeClass': idClicked == I4259 }" id="I4259"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207349" target="_blank">Agora_I 7349</a>	Plateia</p>
<p :class="{'activeClass': idClicked == I4260 }" id="I4260"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207352" target="_blank">Agora_I 7352</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP476'>Aristokrates</a></p>
<p :class="{'activeClass': idClicked == I4261 }" id="I4261"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207353" target="_blank">Agora_I 7353</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3686'>Trajan</a></p>
<p :class="{'activeClass': idClicked == I4262 }" id="I4262"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207396" target="_blank"><inscr id='Agora_I 7396'>Agora_I 7396</inscr></a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2113'>Hro19*</a></p>
<p :class="{'activeClass': idClicked == I4263 }" id="I4263"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207471" target="_blank">Agora_I 7471</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a></p>
<p :class="{'activeClass': idClicked == I4264 }" id="I4264"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207475" target="_blank">Agora_I 7475</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2098'>Hro7*</a></p>
<p :class="{'activeClass': idClicked == I4265 }" id="I4265"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207505" target="_blank"><inscr id='Agora_I 7505'>Agora_I 7505</inscr></a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM614">614</a></p>
<p :class="{'activeClass': idClicked == I4266 }" id="I4266"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207557" target="_blank">Agora_I 7557</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP82'>Aiakeion</a></p>
<p :class="{'activeClass': idClicked == I4267 }" id="I4267"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/i%207587" target="_blank">Agora_I 7587</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2051'>Her2*</a></p>
<h4 :class="{'activeClass': idClicked == I4268 }" id="I4268">AGORA IRON AND LEAD</h4>
<p :class="{'activeClass': idClicked == I4269 }" id="I4269"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/il%20529" target="_blank">Agora_IL 529</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1059'>CM17*</a></p>
<p :class="{'activeClass': idClicked == I4270 }" id="I4270"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/il%201255" target="_blank">Agora_IL 1255</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM271">271</a></p>
<h4 :class="{'activeClass': idClicked == I4271 }" id="I4271">AGORA LAMPS</h4>
<p :class="{'activeClass': idClicked == I4272 }" id="I4272"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/l%204274" target="_blank">Agora_L 4274</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3348'>Sarapis</a> and <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2257'>Isis</a></p>
<h4 :class="{'activeClass': idClicked == I4273 }" id="I4273">AGORA MISC. CLAY</h4>
<p :class="{'activeClass': idClicked == I4274 }" id="I4274"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/mc%20907" target="_blank">Agora_MC 907</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2'>Abecedaria</a></p>
<h4 :class="{'activeClass': idClicked == I4275 }" id="I4275">AGORA POTTERY</h4>
<p :class="{'activeClass': idClicked == I4276 }" id="I4276"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%206978" target="_blank">Agora_P 6978</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1885'>Hephaistos</a>' Garden </p>
<p :class="{'activeClass': idClicked == I4277 }" id="I4277"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%207001" target="_blank">Agora_P 7001</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM312">312</a></p>
<p :class="{'activeClass': idClicked == I4278 }" id="I4278"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2013581" target="_blank">Agora_P 13581</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1733'>Euripides</a> </p>
<p :class="{'activeClass': idClicked == I4279 }" id="I4279"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2015840" target="_blank">Agora_P 15840</a>	Panath. Festival</p>
<p :class="{'activeClass': idClicked == I4280 }" id="I4280"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2020137" target="_blank">Agora_P 20137</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM309">309</a> </p>
<p :class="{'activeClass': idClicked == I4281 }" id="I4281"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2020858" target="_blank">Agora_P 20858</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM309">309</a> </p>
<p :class="{'activeClass': idClicked == I4282 }" id="I4282"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2022998" target="_blank">Agora_P 22998</a>	Simon the Cobbler</p>
<p :class="{'activeClass': idClicked == I4283 }" id="I4283"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/p%2025133" target="_blank">Agora_P 25133</a>	Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1054'>CM16*</a></p>
<h4 :class="{'activeClass': idClicked == I4284 }" id="I4284">AGORA SCULPTURE</h4>
<p :class="{'activeClass': idClicked == I4285 }" id="I4285"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%2033" target="_blank">Agora_S 33</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2040'>Hrm36*</a></p>
<p :class="{'activeClass': idClicked == I4286 }" id="I4286"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%2037" target="_blank">Agora_S 37</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP253'>Aph10*</a></p>
<p :class="{'activeClass': idClicked == I4287 }" id="I4287"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20166" target="_blank">Agora_S 166</a>	Hadrian</p>
<p :class="{'activeClass': idClicked == I4288 }" id="I4288"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20181" target="_blank">Agora_S 181</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a></p>
<p :class="{'activeClass': idClicked == I4289 }" id="I4289"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20182" target="_blank">Agora_S 182</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM332">332</a></p>
<p :class="{'activeClass': idClicked == I4290 }" id="I4290"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20211" target="_blank">Agora_S 211</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1990'>Herm</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2067'>Her16*</a></p>
<p :class="{'activeClass': idClicked == I4291 }" id="I4291"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20220" target="_blank">Agora_S 220</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP228'>Antonia</a> Minor</p>
<p :class="{'activeClass': idClicked == I4292 }" id="I4292"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20221" target="_blank">Agora_S 221</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3362'>Satyrs</a></p>
<p :class="{'activeClass': idClicked == I4293 }" id="I4293"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20270" target="_blank">Agora_S 270</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2089'>Herodotos</a></p>
<p :class="{'activeClass': idClicked == I4294 }" id="I4294"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20312" target="_blank">Agora_S 312</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a></p>
<p :class="{'activeClass': idClicked == I4295 }" id="I4295"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20333" target="_blank">Agora_S 333</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2257'>Isis</a></p>
<p :class="{'activeClass': idClicked == I4296 }" id="I4296"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20335" target="_blank">Agora_S 335</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP24'>Aelius Caesar</a></p>
<p :class="{'activeClass': idClicked == I4297 }" id="I4297"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20336" target="_blank">Agora_S 336</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1746'>Faustina</a> the Younger</p>
<p :class="{'activeClass': idClicked == I4298 }" id="I4298"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20344" target="_blank">Agora_S 344</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP882'>Attis</a></p>
<p :class="{'activeClass': idClicked == I4299 }" id="I4299"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20346" target="_blank">Agora_S 346</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP263'>Aph20*</a></p>
<p :class="{'activeClass': idClicked == I4300 }" id="I4300"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20347" target="_blank">Agora_S 347</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3686'>Trajan</a></p>
<p :class="{'activeClass': idClicked == I4301 }" id="I4301"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20355" target="_blank">Agora_S 355</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3348'>Sarapis</a></p>
<p :class="{'activeClass': idClicked == I4302 }" id="I4302"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20370" target="_blank">Agora_S 370</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1438'>Dio15**</a></p>
<p :class="{'activeClass': idClicked == I4303 }" id="I4303"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20373" target="_blank">Agora_S 373</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a></p>
<p :class="{'activeClass': idClicked == I4304 }" id="I4304"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20378" target="_blank">Agora_S 378</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP253'>Aph10*</a></p>
<p :class="{'activeClass': idClicked == I4305 }" id="I4305"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20396" target="_blank">Agora_S 396</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2124'>Hro29*</a></p>
<p :class="{'activeClass': idClicked == I4306 }" id="I4306"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20429" target="_blank">Agora_S 429</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM333">333</a></p>
<p :class="{'activeClass': idClicked == I4307 }" id="I4307"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20433" target="_blank">Agora_S 433</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP737'>Ath52**</a></p>
<p :class="{'activeClass': idClicked == I4308 }" id="I4308"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20473" target="_blank">Agora_S 473</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP304'>Aph51*</a></p>
<p :class="{'activeClass': idClicked == I4309 }" id="I4309"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20477" target="_blank">Agora_S 477</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP276'>Aph30*</a></p>
<p :class="{'activeClass': idClicked == I4310 }" id="I4310"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20654" target="_blank">Agora_S 654</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP715'>Ath36*</a></p>
<p :class="{'activeClass': idClicked == I4311 }" id="I4311"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20676" target="_blank">Agora_S 676</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP446'>Ares</a></p>
<p :class="{'activeClass': idClicked == I4312 }" id="I4312"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20713" target="_blank">Agora_S 713</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2125'>Hro30*</a></p>
<p :class="{'activeClass': idClicked == I4313 }" id="I4313"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20726" target="_blank">Agora_S 726</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2626'>Men</a></p>
<p :class="{'activeClass': idClicked == I4314 }" id="I4314"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20731" target="_blank">Agora_S 731</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2697'>Met7*</a></p>
<p :class="{'activeClass': idClicked == I4315 }" id="I4315"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20741" target="_blank">Agora_S 741</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM344">344</a> </p>
<p :class="{'activeClass': idClicked == I4316 }" id="I4316"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20826" target="_blank">Agora_S 826</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1608'>Epicurus</a> </p>
<p :class="{'activeClass': idClicked == I4317 }" id="I4317"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20857" target="_blank">Agora_S 857</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3369'>Selene</a> </p>
<p :class="{'activeClass': idClicked == I4318 }" id="I4318"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20862" target="_blank">Agora_S 862</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1854'>Hekataia</a> </p>
<p :class="{'activeClass': idClicked == I4319 }" id="I4319"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20866" target="_blank">Agora_S 866</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP985'>Centaurs</a> </p>
<p :class="{'activeClass': idClicked == I4320 }" id="I4320"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%20912" target="_blank">Agora_S 912</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP500'>Art9*</a></p>
<p :class="{'activeClass': idClicked == I4321 }" id="I4321"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201013" target="_blank">Agora_S 1013</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3704'>Triptolemos</a> </p>
<p :class="{'activeClass': idClicked == I4322 }" id="I4322"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201055" target="_blank">Agora_S 1055</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2746'>Nemesis</a> </p>
<p :class="{'activeClass': idClicked == I4323 }" id="I4323"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201064" target="_blank">Agora_S 1064</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP716'>Ath37*</a></p>
<p :class="{'activeClass': idClicked == I4324 }" id="I4324"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201086" target="_blank">Agora_S 1086</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2070'>Her19*</a></p>
<p :class="{'activeClass': idClicked == I4325 }" id="I4325"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201158" target="_blank">Agora_S 1158</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP289'>Aph39*</a> </p>
<p :class="{'activeClass': idClicked == I4326 }" id="I4326"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201192" target="_blank">Agora_S 1192</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP306'>Aph52*</a> </p>
<p :class="{'activeClass': idClicked == I4327 }" id="I4327"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201194" target="_blank">Agora_S 1194</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3187'>Poseidon</a> </p>
<p :class="{'activeClass': idClicked == I4328 }" id="I4328"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201197" target="_blank">Agora_S 1197</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2142'>Hro46*</a></p>
<p :class="{'activeClass': idClicked == I4329 }" id="I4329"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201213" target="_blank">Agora_S 1213</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP68'>Agrippa</a> </p>
<p :class="{'activeClass': idClicked == I4330 }" id="I4330"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201214" target="_blank">Agora_S 1214</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP68'>Agrippa</a> </p>
<p :class="{'activeClass': idClicked == I4331 }" id="I4331"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201232" target="_blank">Agora_S 1232</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP719'>Ath38*</a></p>
<p :class="{'activeClass': idClicked == I4332 }" id="I4332"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201234" target="_blank">Agora_S 1234</a>	Hermaphroditus </p>
<p :class="{'activeClass': idClicked == I4333 }" id="I4333"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201241" target="_blank">Agora_S 1241</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1947'>Hrk20*</a></p>
<p :class="{'activeClass': idClicked == I4334 }" id="I4334"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201249" target="_blank">Agora_S 1249</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1971'>Hrk40*</a></p>
<p :class="{'activeClass': idClicked == I4335 }" id="I4335"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201251" target="_blank">Agora_S 1251</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a> </p>
<p :class="{'activeClass': idClicked == I4336 }" id="I4336"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201277" target="_blank">Agora_S 1277</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1854'>Hekataia</a> </p>
<p :class="{'activeClass': idClicked == I4337 }" id="I4337"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201318" target="_blank">Agora_S 1318</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1955'>Hrk27*</a></p>
<p :class="{'activeClass': idClicked == I4338 }" id="I4338"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201320" target="_blank">Agora_S 1320</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP882'>Attis</a> </p>
<p :class="{'activeClass': idClicked == I4339 }" id="I4339"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201342" target="_blank">Agora_S 1342</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1493'>Dioskouroi</a> </p>
<p :class="{'activeClass': idClicked == I4340 }" id="I4340"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201357" target="_blank">Agora_S 1357</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP150'>Amazonomachia</a> </p>
<p :class="{'activeClass': idClicked == I4341 }" id="I4341"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201391" target="_blank">Agora_S 1391</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP68'>Agrippa</a> </p>
<p :class="{'activeClass': idClicked == I4342 }" id="I4342"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201413" target="_blank">Agora_S 1413</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3413'>Sokrates</a> </p>
<p :class="{'activeClass': idClicked == I4343 }" id="I4343"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201429" target="_blank">Agora_S 1429</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a></p>
<p :class="{'activeClass': idClicked == I4344 }" id="I4344"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201507" target="_blank">Agora_S 1507</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2845'>Omphaloi</a> </p>
<p :class="{'activeClass': idClicked == I4345 }" id="I4345"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201539" target="_blank">Agora_S 1539</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM334">334</a></p>
<p :class="{'activeClass': idClicked == I4346 }" id="I4346"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201590" target="_blank">Agora_S 1590</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2917'>Panathenaic Stadium</a> </p>
<p :class="{'activeClass': idClicked == I4347 }" id="I4347"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201630" target="_blank">Agora_S 1630</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2126'>Hro31*</a></p>
<p :class="{'activeClass': idClicked == I4348 }" id="I4348"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201646" target="_blank">Agora_S 1646</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1223'>Demeter and Kore</a></p>
<p :class="{'activeClass': idClicked == I4349 }" id="I4349"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201654" target="_blank">Agora_S 1654</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP252'>Aph9*</a></p>
<p :class="{'activeClass': idClicked == I4350 }" id="I4350"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201655" target="_blank">Agora_S 1655</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP147'>Amazon</a> </p>
<p :class="{'activeClass': idClicked == I4351 }" id="I4351"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201806" target="_blank">Agora_S 1806</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP308'>Aph54*</a> </p>
<p :class="{'activeClass': idClicked == I4352 }" id="I4352"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201881" target="_blank">Agora_S 1881</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP958'>Bulls</a> </p>
<p :class="{'activeClass': idClicked == I4353 }" id="I4353"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201882" target="_blank">Agora_S 1882</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP251'>Aph8*</a></p>
<p :class="{'activeClass': idClicked == I4354 }" id="I4354"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201943" target="_blank">Agora_S 1943</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1854'>Hekataia</a> </p>
<p :class="{'activeClass': idClicked == I4355 }" id="I4355"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201944" target="_blank">Agora_S 1944</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP290'>Aph40*</a> </p>
<p :class="{'activeClass': idClicked == I4356 }" id="I4356"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201972" target="_blank">Agora_S 1972</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2520'>Lions</a> </p>
<p :class="{'activeClass': idClicked == I4357 }" id="I4357"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%201977" target="_blank">Agora_S 1977</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2307'>Julia Domna</a> </p>
<p :class="{'activeClass': idClicked == I4358 }" id="I4358"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202038" target="_blank">Agora_S 2038-2039</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2240'>Iliad</a></p>
<p :class="{'activeClass': idClicked == I4359 }" id="I4359"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202054" target="_blank">Agora_S 2054</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP720'>Ath39*</a></p>
<p :class="{'activeClass': idClicked == I4360 }" id="I4360"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202058" target="_blank">Agora_S 2058</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP330'>Apo16*</a></p>
<p :class="{'activeClass': idClicked == I4361 }" id="I4361"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202070" target="_blank">Agora_S 2070</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM368">368</a></p>
<p :class="{'activeClass': idClicked == I4362 }" id="I4362"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202089" target="_blank">Agora_S 2089</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1692'>Eubouleus</a> </p>
<p :class="{'activeClass': idClicked == I4363 }" id="I4363"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202091" target="_blank">Agora_S 2091</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM335">335</a></p>
<p :class="{'activeClass': idClicked == I4364 }" id="I4364"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202113" target="_blank">Agora_S 2113</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP802'>Ath98*</a></p>
<p :class="{'activeClass': idClicked == I4365 }" id="I4365"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202154" target="_blank">Agora_S 2154</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP311'>Apollo</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP385'>Apo60*</a></p>
<p :class="{'activeClass': idClicked == I4366 }" id="I4366"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202180" target="_blank">Agora_S 2180</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1885'>Hephaistos</a> </p>
<p :class="{'activeClass': idClicked == I4367 }" id="I4367"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202220" target="_blank">Agora_S 2220</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP736'>Ath52*</a></p>
<p :class="{'activeClass': idClicked == I4368 }" id="I4368"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202337" target="_blank">Agora_S 2337</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP721'>Ath40*</a></p>
<p :class="{'activeClass': idClicked == I4369 }" id="I4369"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202353" target="_blank">Agora_S 2353</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2392'>Khnum</a> </p>
<p :class="{'activeClass': idClicked == I4370 }" id="I4370"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202356" target="_blank">Agora_S 2356</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1874'>Helios</a> </p>
<p :class="{'activeClass': idClicked == I4371 }" id="I4371"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202361" target="_blank">Agora_S 2361</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP507'>Art16*</a></p>
<p :class="{'activeClass': idClicked == I4372 }" id="I4372"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202370" target="_blank">Agora_S 2370</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM341">341</a></p>
<p :class="{'activeClass': idClicked == I4373 }" id="I4373"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202418" target="_blank">Agora_S 2418</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3504'>Telesphoros</a> </p>
<p :class="{'activeClass': idClicked == I4374 }" id="I4374"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202432" target="_blank">Agora_S 2432</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2392'>Khnum</a> </p>
<p :class="{'activeClass': idClicked == I4375 }" id="I4375"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202436" target="_blank">Agora_S 2436</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP231'>Antoninus Pius</a></p>
<p :class="{'activeClass': idClicked == I4376 }" id="I4376"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202438" target="_blank">Agora_S 2438</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1954'>Hrk26*</a></p>
<p :class="{'activeClass': idClicked == I4377 }" id="I4377"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202452" target="_blank">Agora_S 2452</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2068'>Her17*</a></p>
<p :class="{'activeClass': idClicked == I4378 }" id="I4378"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202476" target="_blank">Agora_S 2476</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2763'>Nike</a> </p>
<p :class="{'activeClass': idClicked == I4379 }" id="I4379"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202485" target="_blank">Agora_S 2485</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1430'>Dio11*</a> </p>
<p :class="{'activeClass': idClicked == I4380 }" id="I4380"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202495" target="_blank">Agora_S 2495</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP853'>Ath134*</a></p>
<p :class="{'activeClass': idClicked == I4381 }" id="I4381"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202518" target="_blank">Agora_S 2518</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3686'>Trajan</a> </p>
<p :class="{'activeClass': idClicked == I4382 }" id="I4382"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%202525" target="_blank">Agora_S 2525</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM270">270</a></p>
<p :class="{'activeClass': idClicked == I4383 }" id="I4383"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%203344" target="_blank">Agora_S 3344</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP288'>Aph38*</a> </p>
<p :class="{'activeClass': idClicked == I4384 }" id="I4384"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/s%203347" target="_blank">Agora_S 3347</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2048'>Herms</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2066'>Her15*</a></p>
<h4 :class="{'activeClass': idClicked == I4385 }" id="I4385">AGORA STONE OBJECTS</h4>
<p :class="{'activeClass': idClicked == I4386 }" id="I4386"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%2071" target="_blank">Agora_ST 71</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM269">269</a> </p>
<p :class="{'activeClass': idClicked == I4387 }" id="I4387"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%2080" target="_blank">Agora_ST 80</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM307">307</a> </p>
<p :class="{'activeClass': idClicked == I4388 }" id="I4388"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20334" target="_blank">Agora_ST 334</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM294">294</a>bis </p>
<p :class="{'activeClass': idClicked == I4389 }" id="I4389"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20527" target="_blank">Agora_ST 527</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2257'>Isis</a> (<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3061'>Pharia</a>) </p>
<p :class="{'activeClass': idClicked == I4390 }" id="I4390"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20703" target="_blank">Agora_ST 703-708</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3482'>Sulla</a> </p>
<p :class="{'activeClass': idClicked == I4391 }" id="I4391"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/st%20780" target="_blank">Agora_ST 780</a>	Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM362">362</a></p>
<h4 :class="{'activeClass': idClicked == I4392 }" id="I4392">AGORA TERRACOTTAS</h4>
<p :class="{'activeClass': idClicked == I4393 }" id="I4393"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%20175" target="_blank">Agora_T 175</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3406'>Snake</a> </p>
<p :class="{'activeClass': idClicked == I4394 }" id="I4394"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%20915" target="_blank">Agora_T 915</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP2685'>Mother of the Gods</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2698'>Met8*</a></p>
<p :class="{'activeClass': idClicked == I4395 }" id="I4395"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%201261" target="_blank">Agora_T 1261</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP3460'>Stoa Basileios</a> </p>
<p :class="{'activeClass': idClicked == I4396 }" id="I4396"><a v-on:click.prevent="replaceLink"   href="http://agora.ascsa.net/id/agora/object/t%202466" target="_blank">Agora_T 2466</a>	<a v-on:click.prevent="replaceLink"   href='NPP.html#NP1919'>Herakles</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1973'>Hrk41**</a></p>
<h4 :class="{'activeClass': idClicked == I4397 }" id="I4397">AGORA DEPOSITS</h4>
<p :class="{'activeClass': idClicked == I4398 }" id="I4398">Agora_D_7:2 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM325">325</a> 	</p>
<p :class="{'activeClass': idClicked == I4399 }" id="I4399">Agora_H_6:9 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM331">331</a> 	</p>
<p :class="{'activeClass': idClicked == I4400 }" id="I4400">Agora_I_1:3 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP459'>Argyrokopeion</a> 	</p>
<p :class="{'activeClass': idClicked == I4401 }" id="I4401">Agora_J_2:3 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM285">285</a> 	</p>
<p :class="{'activeClass': idClicked == I4402 }" id="I4402">Agora_O_13:4 Chr. Mon. <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1054'>CM16*</a> 	</p>
<p :class="{'activeClass': idClicked == I4403 }" id="I4403">Agora_Q_12:3 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM311">311</a> 	</p>
<h3 :class="{'activeClass': idClicked == I4404 }" id="I4404">KERAMEIKOS EXCAVATIONS</h3> 
<h4>KERAMEIKOS ARCHITECTURE</h4>
<p :class="{'activeClass': idClicked == I4405 }" id="I4405">Kerameikos_A 212 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM425">425</a>	</p>
<p :class="{'activeClass': idClicked == I4406 }" id="I4406">Kerameikos_A 216 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM426">426</a>	</p>
<h4 :class="{'activeClass': idClicked == I4407 }" id="I4407">KERAMEIKOS INSCRIPTIONS </h4>
<p :class="{'activeClass': idClicked == I4408 }" id="I4408">Kerameikos_I 27 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3332'>Sacred Way</a>	</p>
<p :class="{'activeClass': idClicked == I4409 }" id="I4409">Kerameikos_I 39 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2325'>Kalliste</a>	</p>
<p :class="{'activeClass': idClicked == I4410 }" id="I4410">Kerameikos_I 40 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP662'>Ath15*</a>	</p>
<p :class="{'activeClass': idClicked == I4411 }" id="I4411">Kerameikos_I 64 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2115'>Hro21*</a>	</p>
<p :class="{'activeClass': idClicked == I4412 }" id="I4412">Kerameikos_I 66 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1287'>Demosion Sema</a> Sem32*	</p>
<p :class="{'activeClass': idClicked == I4413 }" id="I4413">Kerameikos_I 110 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP643'>Ath5*</a>	</p>
<p :class="{'activeClass': idClicked == I4414 }" id="I4414">Kerameikos_I 190 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3750'>Tyr</a> —	</p>
<p :class="{'activeClass': idClicked == I4415 }" id="I4415">Kerameikos_I 220 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1359'>Dexileos</a>	</p>
<p :class="{'activeClass': idClicked == I4416 }" id="I4416">Kerameikos_I 232-233 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM423">423</a>	</p>
<p :class="{'activeClass': idClicked == I4417 }" id="I4417">Kerameikos_I 234 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM424">424</a>	</p>
<p :class="{'activeClass': idClicked == I4418 }" id="I4418">Kerameikos_I 238 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2366'>Kerameikos Boundary Stones</a>	</p>
<p :class="{'activeClass': idClicked == I4419 }" id="I4419">Kerameikos_I 239 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2366'>Kerameikos Boundary Stones</a>	</p>
<p :class="{'activeClass': idClicked == I4420 }" id="I4420">Kerameikos_I 240 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2366'>Kerameikos Boundary Stones</a>	</p>
<p :class="{'activeClass': idClicked == I4421 }" id="I4421">Kerameikos_I 294 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3714'>Tritopatreion</a>	</p>
<p :class="{'activeClass': idClicked == I4422 }" id="I4422">Kerameikos_I 295 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3714'>Tritopatreion</a>	</p>
<p :class="{'activeClass': idClicked == I4423 }" id="I4423">Kerameikos_I 296 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3714'>Tritopatreion</a>	</p>
<p :class="{'activeClass': idClicked == I4424 }" id="I4424">Kerameikos_I 297 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3714'>Tritopatreion</a>	</p>
<p :class="{'activeClass': idClicked == I4425 }" id="I4425">Kerameikos_I 304 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP489'>Artemis</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP547'>Art47*</a>	</p>
<p :class="{'activeClass': idClicked == I4426 }" id="I4426">Kerameikos_I 322 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2366'>Kerameikos Boundary Stones</a>	</p>
<p :class="{'activeClass': idClicked == I4427 }" id="I4427">Kerameikos_I 328 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2002'>Hrm4*</a>	</p>
<p :class="{'activeClass': idClicked == I4428 }" id="I4428">Kerameikos_I 405 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP636'>Athena</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP662'>Ath15*</a>	</p>
<p :class="{'activeClass': idClicked == I4429 }" id="I4429">Kerameikos_I 444 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2147'>Heroes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2103'>Hro11*</a>	</p>
<p :class="{'activeClass': idClicked == I4430 }" id="I4430">Kerameikos_I 445 Part II no 415	</p>
<p :class="{'activeClass': idClicked == I4431 }" id="I4431">Kerameikos_I 460 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1254'>Demetrios of Phaleron</a>	</p>
<p :class="{'activeClass': idClicked == I4432 }" id="I4432">Kerameikos_I 487 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM388">388</a>	</p>
<p :class="{'activeClass': idClicked == I4433 }" id="I4433">Kerameikos_I 526 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM612">612</a>	</p>
<p :class="{'activeClass': idClicked == I4434 }" id="I4434">Kerameikos_IB 2 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1254'>Demetrios of Phaleron</a>	</p>
<h4 :class="{'activeClass': idClicked == I4435 }" id="I4435">METAL FINDS</h4>
<p :class="{'activeClass': idClicked == I4436 }" id="I4436">Kerameikos_M 374 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP243'>Aphrodite</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP295'>Aph43*</a> 	</p>
<h4 :class="{'activeClass': idClicked == I4437 }" id="I4437">SCULPTURE</h4>
<p :class="{'activeClass': idClicked == I4438 }" id="I4438">Kerameikos_P 1 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1997'>Hermes</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2043'>Hrm38*</a>	</p>
<p :class="{'activeClass': idClicked == I4439 }" id="I4439">Kerameikos_P 674-677 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP150'>Amazonomachia</a>	</p>
<p :class="{'activeClass': idClicked == I4440 }" id="I4440">Kerameikos_P 692 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2579'>Lysimachides</a>	</p>
<p :class="{'activeClass': idClicked == I4441 }" id="I4441">Kerameikos_P 766 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM419">419</a>	</p>
<p :class="{'activeClass': idClicked == I4442 }" id="I4442">Kerameikos_P 950 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM408">408B</a>	</p>
<p :class="{'activeClass': idClicked == I4443 }" id="I4443">Kerameikos_P 928 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2798'>Nymphs</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP2805'>Nym7*</a>	</p>
<p :class="{'activeClass': idClicked == I4444 }" id="I4444">Kerameikos_P 1130 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1359'>Dexileos</a>	</p>
<p :class="{'activeClass': idClicked == I4445 }" id="I4445">Kerameikos_P 1200 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM416">416</a>	</p>
<p :class="{'activeClass': idClicked == I4446 }" id="I4446">Kerameikos_P 1250 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP559'>Asklepios</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP608'>Ask40*</a>	</p>
<p :class="{'activeClass': idClicked == I4447 }" id="I4447">Kerameikos_P 1441 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1854'>Hekataia</a>, Hekate	</p>
<p :class="{'activeClass': idClicked == I4448 }" id="I4448">Kerameikos_P 1488 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1854'>Hekataia</a>, Hekate	</p>
<p :class="{'activeClass': idClicked == I4449 }" id="I4449">Kerameikos_P 1520 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3504'>Telesphoros</a>	</p>
<p :class="{'activeClass': idClicked == I4450 }" id="I4450">Kerameikos_P 1540 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1413'>Dionysos</a> <a v-on:click.prevent="replaceLink"   href='NPP.html#NP1427'>Dio9**</a>	</p>
<p :class="{'activeClass': idClicked == I4451 }" id="I4451">Kerameikos_P 1684 Part II no. <a v-on:click.prevent="replaceLink"   href="NNM.html#NNM420">420</a>	</p>
<h4 :class="{'activeClass': idClicked == I4452 }" id="I4452">WALL PAINTING</h4>
<p :class="{'activeClass': idClicked == I4453 }" id="I4453">Kerameikos_WM 3 <a v-on:click.prevent="replaceLink"   href='NPP.html#NP3174'>Pompeion</a> (II)	</p>
 </div>
</template>

   

        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    