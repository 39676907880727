<template>

    <div class="part-text">
<h3 :class="{'activeClass': idClicked == AB1 }" id="AB1">ABBREVIATIONS</h3>
<h4>GENERAL</h4>
<ul>
<li :class="{'activeClass': idClicked == AB2 }" id="AB2"><b>Acr.</b> = Acropolis, used for Acropolis Museum inventory numbers </li> 
<li :class="{'activeClass': idClicked == AB3 }" id="AB3"><b>Akr.</b> = <a v-on:click.prevent="replaceLink"  href='NPP.html#NP115'>Akropolis</a>, the place name</li> 
<li :class="{'activeClass': idClicked == AB4 }" id="AB4"><b>ASCS</b> = The American School of Classical Studies at Athens</li> 
<li :class="{'activeClass': idClicked == AB5 }" id="AB5"><b>Ave.</b> = Avenue, referring to present-day streets with four or more lanes</li> 
<li :class="{'activeClass': idClicked == AB6 }" id="AB6"><b>Beibl.</b> = Beiblatt, supplement in German publications</li> 
<li :class="{'activeClass': idClicked == AB7 }" id="AB7"><b>Blvd.</b> = Boulevard, referring to present-day streets with a dividing (green) strip down the middle</li> 
<li :class="{'activeClass': idClicked == AB8 }" id="AB8"><b>Chr.</b> = Chronika, the annual reports of excavations conducted by the Greek Archaeological Service, published in Deltion </li> 
<li :class="{'activeClass': idClicked == AB9 }" id="AB9"><b>Chr. Mon.</b> = Christian Monument(s)</li> 
<li :class="{'activeClass': idClicked == AB10 }" id="AB10">d.	died </li> 
<li :class="{'activeClass': idClicked == AB11 }" id="AB11"><b>dd</b> = dedication, dedicated </li> 
<li :class="{'activeClass': idClicked == AB12 }" id="AB12"><b>dr(s).</b> = drawing(s)</li> 
<li :class="{'activeClass': idClicked == AB13 }" id="AB13"><b>Ephor.</b> = Ephoria or Ephorate, referring to departments of the Greek Archaeological Service </li> 
<li :class="{'activeClass': idClicked == AB14 }" id="AB14"><b>esp.</b> = especially </li> 
<li :class="{'activeClass': idClicked == AB15 }" id="AB15"><b>exc.</b> = excavations</li> 
<li :class="{'activeClass': idClicked == AB16 }" id="AB16"><b>First Ephor.</b> = First Archaeological District of Attica, in Greek publications abbreviated as A' ΕΠΚΑ (The First Archaeological District of Prehistoric and Classical Antiquities)</li> 
<li :class="{'activeClass': idClicked == AB17 }" id="AB17"><b>H. or Hag.</b> = Haghia, Haghioi, Haghios, titles of Greek Orthodox saints for whom churches, streets and places are named </li> 
<li :class="{'activeClass': idClicked == AB18 }" id="AB18"><b>inscr.</b> = inscribed, inscription </li> 
<li :class="{'activeClass': idClicked == AB19 }" id="AB19"><b>inv.</b> = inventory, inventory number </li> 
<li :class="{'activeClass': idClicked == AB20 }" id="AB20"><b>mon.</b> = monument(s)</li> 
<li :class="{'activeClass': idClicked == AB21 }" id="AB21"><b>Myc.</b> = Mycenaean </li> 
<li :class="{'activeClass': idClicked == AB22 }" id="AB22"><b>par.</b> = paragraph(s)</li> 
<li :class="{'activeClass': idClicked == AB23 }" id="AB23"><b>parart.</b> = παραρτημα , supplement or Beiblatt in Greek periodicals</li> 
<li :class="{'activeClass': idClicked == AB24 }" id="AB24"><b>ph.</b> = photograph</li> 
<li :class="{'activeClass': idClicked == AB25 }" id="AB25"><b>pd.</b> = period</li> 
<li :class="{'activeClass': idClicked == AB26 }" id="AB26"><b>q.</b> = quarter</li> 
<li :class="{'activeClass': idClicked == AB27 }" id="AB27"><b>rel.</b> = relief</li> 
<li :class="{'activeClass': idClicked == AB28 }" id="AB28"><b>repr.</b> = representation, represented</li> 
<li :class="{'activeClass': idClicked == AB29 }" id="AB29"><b>rest.</b> = restored</li> 
<li :class="{'activeClass': idClicked == AB30 }" id="AB30"><b>sanct.</b> = sanctuary</li> 
<li :class="{'activeClass': idClicked == AB31 }" id="AB31"><b>schol.</b> = scholion</li> 
<li :class="{'activeClass': idClicked == AB32 }" id="AB32"><b>St</b> = Saint</li> 
<li :class="{'activeClass': idClicked == AB33 }" id="AB33"><b>St., Sts.</b> = street(s), referring only to present-day streets, never to ancient streets </li> 
<li :class="{'activeClass': idClicked == AB34 }" id="AB34"><b>suppl.</b> = supplement</li> 
<li :class="{'activeClass': idClicked == AB35 }" id="AB35"><b>Third Ephor.</b> = Third Archaeological District of Attica, in Greek publications abbreviated as Γ' ΕΠΚΑ (The Third Archaeological District of Prehistoric and Classical Antiquities)</li> 
</ul>
<h4 :class="{'activeClass': idClicked == AB36 }" id="AB36">ABBREVIATIONS: ANCIENT AND MIDIAEVAL LITERARY SOURCES</h4>
<ul>
<li :class="{'activeClass': idClicked == AB37 }" id="AB37"><b>A. Eu.</b> = Aeschylus, Eumenides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/14" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB38 }" id="AB38"><b>Ael. NA</b> = Aelian, De natura animalium <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/560" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB39 }" id="AB39"><b>Ael. VH</b> = Aelian, Varia historia <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/220" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB40 }" id="AB40"><b>Ael. Fr.</b> = Aelian, Fragmenta </li> 
<li :class="{'activeClass': idClicked == AB41 }" id="AB41"><b>Aeschin. Ep.</b> = Aischines orator, Epistulae </li> 
<li :class="{'activeClass': idClicked == AB42 }" id="AB42"><b>Alciphr.</b> = Alciphro Epistolographus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/494" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB43 }" id="AB43"><b>And.</b> = Andocides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/55" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB44 }" id="AB44"><b>Anecdota Graeca, 1914</b> = Anecdota Graeca, vol. I, I. Bekker, ed., Berlin </li> 
<li :class="{'activeClass': idClicked == AB45 }" id="AB45"><b>Anon. Argent.</b> = Strasbourg Papyrus Graeca 84: Anonymous Argentinensis </li> 
<li :class="{'activeClass': idClicked == AB46 }" id="AB46"><b>Antig. Mir.</b> = Antigonus Carystius  Mirabilia <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/667" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB47 }" id="AB47"><b>AP</b> = Anthologia Palatina <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/532" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB48 }" id="AB48"><b>APl</b> = Anthologia Planudea <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/748" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB49 }" id="AB49"><b>Apollod.</b> = Apollodorus Mythographicus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/150" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB50 }" id="AB50"><b>Apul. Pl.</b> = Apuleius  De Platone </li> 
<li :class="{'activeClass': idClicked == AB51 }" id="AB51"><b>Ar. Ach.</b> = Aristophanes, Acharnenses <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/57" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB52 }" id="AB52"><b>Ar. Ec.</b> = Aristophanes, Ecclesiazusae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/66" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB53 }" id="AB53"><b>Ar. Lys.</b> = Aristophanes, Lysistrata <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/63" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB54 }" id="AB54"><b>Ar. Pax</b> = Aristophanes, Pax <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/61" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB55 }" id="AB55"><b>Ar. Ra.</b> = Aristophanes, Ranae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/65" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB56 }" id="AB56"><b>Ar. Th.</b> = Aristophanes, Thesmophoriazusae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/64" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB57 }" id="AB57"><b>Ar. V.</b> = Aristophanes, Vespae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/60" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB58 }" id="AB58"><b>Arist. Ath.</b> = Aristotle, Athenaion Politeia <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/99" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB59 }" id="AB59"><b>Arist. Fr.</b> = Aristotle, Fragmenta</li> 
<li :class="{'activeClass': idClicked == AB60 }" id="AB60"><b>Arist. Pepl.</b> = Aristotle, Peplos</li> 
<li :class="{'activeClass': idClicked == AB61 }" id="AB61"><b>Aristid. Or.</b> = Aristides Rhetor, Orationes</li> 
<li :class="{'activeClass': idClicked == AB62 }" id="AB62"><b>Arr.</b> = Arrian <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/150" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB63 }" id="AB63"><b>Ath.</b> = Athenaeus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/218" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB64 }" id="AB64"><b>Call. Dian.</b> = Callimachus, Hymnus in Dianam <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/122" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB65 }" id="AB65"><b>Callistr. Stat.</b> = Callistratus, Statuarum descriptiones</li> 
<li :class="{'activeClass': idClicked == AB66 }" id="AB66"><b>Cic. Att.</b> = Cicero, Epistulae ad Atticum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/136" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB67 }" id="AB67"><b>Cic. N.D.</b> = Cicero, De natura deorum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/137" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB68 }" id="AB68"><b>Cic. Fam.</b> = Cicero, Epistulae ad familiares <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/136" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB69 }" id="AB69"><b>Cic. Fin.</b> = Cicero, De finibus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/615" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB70 }" id="AB70"><b>Cic. Leg.</b> = Cicero, De legibus </li> 
<li :class="{'activeClass': idClicked == AB71 }" id="AB71"><b>Clem. Al. Protr.</b> = Clement of Alexandria, Protrepticus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/215" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB72 }" id="AB72"><b>D.</b> = Demosthenes <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/401" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB73 }" id="AB73"><b>D.C.</b> = Dio Cassius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/219" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB74 }" id="AB74"><b>D. Chr.</b> = Dio Chrysostomus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/197" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB75 }" id="AB75"><b>D.H.</b> = Dionysius Halicarnassensis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/139" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB76 }" id="AB76"><b>Did. in D.</b> = Didymus, in Demosthenem <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/151" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB77 }" id="AB77"><b>Din.</b> = Dinarchus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/115" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB78 }" id="AB78"><b>D.L.</b> = Diogenes Laertius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/221" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB79 }" id="AB79"><b>D.S.</b> = Diodorus Siculus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/134" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB80 }" id="AB80"><b>E. Erech.</b> = Euripides C. Austin, Nova Fragmenta Euripidea, Berlin, <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/1965" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB81 }" id="AB81"><b>E. Hipp.</b> = Euripides, Hippolytus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/36" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB82 }" id="AB82"><b>E. Ion</b> = Euripides, Ion <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/42" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB83 }" id="AB83"><b>EM</b> = Etymologicum Magnum</li> 
<li :class="{'activeClass': idClicked == AB84 }" id="AB84"><b>Eun. VS</b> = Eunapius Vitae Sophistarum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/782" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB85 }" id="AB85"><b>Eust. Iliad</b> = Eustathius, Iliad</li> 
<li :class="{'activeClass': idClicked == AB85a }" id="AB85a"><b>Eust. Od.</b> = Eustathius, Odyssey</li> 
<li :class="{'activeClass': idClicked == AB86 }" id="AB86"><b>FAC</b> = ed. J. M. Edmonds, The Fragments of Attic Comedy, 2 vols., Leiden, 1957-1971</li> 
<li :class="{'activeClass': idClicked == AB87 }" id="AB87"><b>F GR HIST</b> = ed. F. Jacoby, Die Fragmente der griechischen Historiker, Berlin and Leiden, 1923-1958</li> 
<li :class="{'activeClass': idClicked == AB88 }" id="AB88"><b>Gel.</b> = Gellius Noctes Atticae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/208" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB89 }" id="AB89"><b>Harp.</b> = Harpocratio Grammaticus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/537" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB90 }" id="AB90"><b>Hld.</b> = Heliodorus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/228" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB91 }" id="AB91"><b>Hdt.</b> = Herodotus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/22" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB92 }" id="AB92"><b>Him.</b> = Himerius, Himerii declamationes et orationes cum deperditarum fragmentis, A. Colonna recensuit, Rome, <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/1951" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB93 }" id="AB93"><b>Hsch.</b> = Hesychius Lexicographus</li> 
<li :class="{'activeClass': idClicked == AB94 }" id="AB94"><b>Hyp.</b> = Hyperides, Hyperidis orationes sex cum ceterarum fragmentis, ed. Chr. Jensen, Teubner, Stuttgart reprint, 1963 <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/108" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB95 }" id="AB95"><b>Hyp. Dem.</b> = Hyperides, adv. Demosthenem <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/112" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB96 }" id="AB96"><b>Hyp. Fr.</b> = Hyperides, Fragmenta</li> 
<li :class="{'activeClass': idClicked == AB97 }" id="AB97"><b>Hyp. Epit.</b> = Hyperides, Epitaphius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/762" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB98 }" id="AB98"><b>Is.</b> = Isaeus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/620" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB99 }" id="AB99"><b>Josephus AJ</b> = Josephus, Antiquitates Judicae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/526" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB100 }" id="AB100"><b>Josephus BJ</b> = Josephus, Bellum Judaicum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/566" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB101 }" id="AB101"><b>Jul. Or.</b> = Julian Imperator Orationes</li> 
<li :class="{'activeClass': idClicked == AB102 }" id="AB102"><b>Liv.</b> = Livy <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/142" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB103 }" id="AB103"><b>Lucian Anach.</b> = Lucian, Anacharsis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/335" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB104 }" id="AB104"><b>Lucian Dem. Enc.</b> = Lucian, Demosthenis Encomium <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/764" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB105 }" id="AB105"><b>Lucian DMeretr.</b> = Lucian, Dialogi Meretricii <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/352" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB106 }" id="AB106"><b>Lucian Im.</b> = Lucian, Imagines <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/338" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB107 }" id="AB107"><b>Lucian Scyth.</b> = Lucian, Scytha <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/348" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB108 }" id="AB108"><b>Lucian Zeux.</b> = Lucian, Zeuxis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/347" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB109 }" id="AB109"><b>Lycurg. Leocr.</b> = Lycurgus, Against Leocrates <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/113" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB111 }" id="AB111"><b>Lys.</b> = Lysias <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/462" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB112 }" id="AB112"><b>Macr. Sat.</b> = Macrobius Saturnalia</li> 
<li :class="{'activeClass': idClicked == AB113 }" id="AB113"><b>Marcellin. Vit. Thuc.</b> = Marcellinus, Vita Thucydidis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/763" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB114 }" id="AB114"><b>Marin. Procl.</b> = Marinus Vita Procl. <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/238" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB115 }" id="AB115"><b>Marm. Par.</b> = Marmor Parium <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/119" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB116 }" id="AB116"><b>Nep. Att. Cha.</b> = Nepos Atticus, Chabrias <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/509" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB117 }" id="AB117"><b>Od.</b> = Odyssey <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/3" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB118 }" id="AB118"><b>Olymp. Vita Pl.</b> = Olympiodorus Philosophus, Vita Platonis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/530" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB120 }" id="AB120"><b>Olymp. Hist.</b> = Olympiodorus Historicus</li> 
<li :class="{'activeClass': idClicked == AB121 }" id="AB121"><b>Paus.</b> = Pausaniae Graeciae Descriptio, ed. Maria Helena Rocha-Pereira, Teubner, Leipzig, 1973 <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/213" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB122 }" id="AB122"><b>PG</b> = Patrologiae cursus completus. Series Graeca, ed. J. P. Migne, Paris: Gamier, 1857-1866</li> 
<li :class="{'activeClass': idClicked == AB123 }" id="AB123"><b>Pers.</b> = Persius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/671" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB124 }" id="AB124"><b>Phaedr.</b> = Phaedrus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/671" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB125 }" id="AB125"><b>Philostr. Gym.</b> = Philostratus De gymnastica </li> 
<li :class="{'activeClass': idClicked == AB126 }" id="AB126"><b>Philostr. VA</b> = Vitae Apollonii <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/223" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB127 }" id="AB127"><b>Philostr. VS</b> = Vita sophistarum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/224" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB128 }" id="AB128"><b>Phot.</b> = Photius, Lexicon <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/237" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB129 }" id="AB129"><b>Phot. Bibl.</b> = Photius, Bibliotheca </li> 
<li :class="{'activeClass': idClicked == AB130 }" id="AB130"><b>PL</b> = Patrologiae cursus completus. Series Latina, ed. J. P. Migne, Paris: Gamier, 1844-1880 </li> 
<li :class="{'activeClass': idClicked == AB131 }" id="AB131"><b>Pl. Ax.</b> = Plato, Axiochus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/758" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB132 }" id="AB132"><b>Pl. Chrm.</b> = Plato, Charmides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/759" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB133 }" id="AB133"><b>Pl. Grg.</b> = Plato, Gorgias <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/760" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB134 }" id="AB134"><b>Pl. Hp. Ma.</b> = Plato, Hippias Maior <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/544" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB135 }" id="AB135"><b>Pl. Ly.</b> = Plato, Lysis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/743" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB136 }" id="AB136"><b>Pl. Phd.</b> = Plato, Phaedo <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/91" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB137 }" id="AB137"><b>Pl. Phdr.</b> = Plato, Phaedrus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/94" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB138 }" id="AB138"><b>Pl. Prm.</b> = Plato, Parmenides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/761" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB139 }" id="AB139"><b>Pl. Smp.</b> = Plato, Symposium <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/528" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB140 }" id="AB140"><b>Pl. Tht.</b> = Plato, Theaetetus </li> 
<li :class="{'activeClass': idClicked == AB141 }" id="AB141"><b>Plautus Aul.</b> = Plautus, Aularia</li> 
<li :class="{'activeClass': idClicked == AB142 }" id="AB142"><b>Plin. Nat.</b> = Pliny the Elder Historia Naturalis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/153" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB143 }" id="AB143"><b>Plu. Alc.</b> = Plutarch, Life of Alcibiades <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/161" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB144 }" id="AB144"><b>Plu. Alex</b> = Plutarch, Life of Alexander <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/172" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB145 }" id="AB145"><b>Plu. Ant.</b> = Plutarch, Life of Antonius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/180" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB146 }" id="AB146"><b>Plu. Arist.</b> = Plutarch, Life of Aristides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/164" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB147 }" id="AB147"><b>Plu. Cim.</b> = Plutarch, Life of Cimon <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/168" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB148 }" id="AB148"><b>Plu. Demetr.</b> = Plutarch, Life of Demetrius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/177" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB149 }" id="AB149"><b>Plu. Dem.</b> = Plutarch, Life of Demosthenes <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/176" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB150 }" id="AB150"><b>Plu. Moralia</b> = Plutarch, Moralia <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/250" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB151 }" id="AB151"><b>Plu. Nic.</b> = Plutarch, Life of Nicias <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/169" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB152 }" id="AB152"><b>Plu. Pel.</b> = Plutarch, Life of Pelopidas <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/163" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB153 }" id="AB153"><b>Plu. Per.</b> = Plutarch, Life of Pericles <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/160" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB154 }" id="AB154"><b>Plu. Phoc.</b> = Plutarch, Life of Phocion <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/173" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB155 }" id="AB155"><b>Plu. Sol.</b> = Plutarch, Life of Solon <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/158" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB156 }" id="AB156"><b>Plu. Sull.</b> = Plutarch, Life of Sulla <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/182" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB157 }" id="AB157"><b>Plu. Them.</b> = Plutarch, Life of Themistocles <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/159" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB158 }" id="AB158"><b>Plu. Thes.</b> = Plutarch, Life of Theseus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/156" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB159 }" id="AB159"><b>Poll.</b> = Pollux</li> 
<li :class="{'activeClass': idClicked == AB160 }" id="AB160"><b>Porph. Abst.</b> = Porphyrios, De abstinentia </li> 
<li :class="{'activeClass': idClicked == AB161 }" id="AB161"><b>POxy</b> = Oxyrrhyncus Papyri</li> 
<li :class="{'activeClass': idClicked == AB162 }" id="AB162"><b>Procop. Aed.</b> = Procopius Caesariensis De aedificiis <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/239" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB163 }" id="AB163"><b>Rut. Lup.</b> = Rutilius Lupus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/416" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB164 }" id="AB164"><b>Sen. Con.</b> = Seneca Controversiae</li> 
<li :class="{'activeClass': idClicked == AB165 }" id="AB165"><b>Sen. Ep.</b> = Seneca Epistulae <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/736" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB166 }" id="AB166"><b>S. OC</b> = Sophocles Oedipus Coloneus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/26" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB167 }" id="AB167"><b>Spartianus</b> = Spartianus in Scriptores Historiae Augustae</li> 
<li :class="{'activeClass': idClicked == AB168 }" id="AB168"><b>Stat. Th.</b> = Statius Thebais <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/149" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB169 }" id="AB169"><b>St. Byz.</b> = Stephanus Byzantius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/241" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB170 }" id="AB170"><b>Str.</b> = Strabo <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/144" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB171 }" id="AB171"><b>Suid.</b> = Suidas <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/240" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB172 }" id="AB172"><b>Thphr. HP</b> = Theophrastus  Historia plantarum <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/242" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB173 }" id="AB173"><b>Th.</b> = Thucydides <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/52" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB174 }" id="AB174"><b>Tim. Lex.</b> = Timaeus Lexicon Platonicum </li> 
<li :class="{'activeClass': idClicked == AB175 }" id="AB175"><b>Tz. H.</b> = Tzetzes Historiarum variarum chiliades <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/617" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB176 }" id="AB176"><b>V. Max.</b> = Valerius Maximus</li> 
<li :class="{'activeClass': idClicked == AB177 }" id="AB177"><b>Vienna Anonymous</b> = Cod. Theolog. Gr. 252, fol. 39/32; L. Ross, Archaologische Aufsatze, II ( 1855) 246 - 254 </li> 
<li :class="{'activeClass': idClicked == AB178 }" id="AB178"><b>Vita Soph.</b> = Vita Sophoclis, ed. O. Jahn and A. Michaelis, Sophoclis Electra, Bonn, 1872, 1/19</li> 
<li :class="{'activeClass': idClicked == AB179 }" id="AB179"><b>Vitr.</b> = Vitruvius <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/138" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB180 }" id="AB180"><b>X. Eq.</b> = Xenophon De equitandi ratione </li> 
<li :class="{'activeClass': idClicked == AB181 }" id="AB181"><b>X. Eq. Mag.</b> = De equitum magistro <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/784" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB182 }" id="AB182"><b>X. HG</b> = Historia Graeca <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/96" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB183 }" id="AB183"><b>X. Mem.</b> = Memorabilia <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/249" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB184 }" id="AB184"><b>X. Oec.</b> = Oeconomicus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/95" target="_blank"><i>ToposText</i></a></li> 
<li :class="{'activeClass': idClicked == AB185 }" id="AB185"><b>Zen.</b> = Zenobius</li> 
<li :class="{'activeClass': idClicked == AB186 }" id="AB186"><b>Zos.</b> = Zosimus Historicus <a v-on:click.prevent="replaceLink"  href="https://topostext.org/work/740" target="_blank"><i>ToposText</i></a></li> 
</ul>

<h4 :class="{'activeClass': idClicked == AB802 }" id="AB802">ABBEVIATIONS: EXCAVATIONS, INVENTORIES, MUSEUMS, STOREROOMS, COLLECTIONS, LIBRARIES</h4>
<p :class="{'activeClass': idClicked == AB803 }" id="AB803">This section, far from being comprehensive, merely assembles some information that might come in handy. </p> 
<p :class="{'activeClass': idClicked == AB804 }" id="AB804"><b>Acr.</b> = prefix for Acropolis inventory numbers. Note that in this work the abbreviation Acr. is used for Acr. inventory numbers and for the museum, whereas Akr. is used the place-name. The Acropolis inventory was theoretically reserved for objects found on the top of the hill. Many entries in the old Acropolis inventory books contain valuable information about findspots. Other entries do not report the findspot. (It is easier to gain entry to Fort Knox, than to have a look at the old inventory books.)</p> 
<p :class="{'activeClass': idClicked == AB805 }" id="AB805">Many objects found on the Akropolis did not receive Acropolis inventory numbers which were given and still continue to be given to objects found elsewhere. In earlier times objects brought up to the Akropolis for safekeeping from the lower town and even from the Peiraeus were entered into the Acropolis inventory. As late as the 1950s some finds from S. Charitonides' excavations on Plateia Syntagmatos were on show in the Acropolis Museum and stored in an Acropolis storeroom with what kind of inventory numbers I do not know. In the 1980s some finds from excavations on the Akropolis North Slope were entered into the Acropolis inventory, for example a rare Mycenaean terracotta head received the inventory number Acr. 9945 (Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM202">202</a>). In 1878 the Turkish house serving as a museum on the Akropolis was demolished and the antiquities were transferred to the Acropolis Museum and the National Museum. Descriptions of what was stored in the Turkish house include the information that vases found in excavations in the Piraeus were kept there. During the 19th century the Akropolis was a collecting point for finds from as far afield as Aigina. In the early 20th century a decision was made to clear the Akropolis of non-Akropolis finds which were to be transferred to the National Museum and other places. This effort was accompanied by a rough and ready sorting with the results that some finds from various sites remained on the Akropolis and some Akropolis finds were brought to the National Museum. The Akropolis end of this transfer was not documented. Individual notices in the National Museum and Epigraphical Museum inventories and in publications occasionally preserve the information about Akropolis findspots. It is better to take nothing for granted. </p> 
<p :class="{'activeClass': idClicked == AB806 }" id="AB806"><b>Acr. Stray Finds</b> = In 1967 stray finds stored in the Beule Gate Tower were registered in a special inventory. The objects had been brought in from all over Athens and further afield in bygone years. See G. S. Dontas, Deltion 23 (1968) B1 Chr. 17</p> 
<h4 :class="{'activeClass': idClicked == AB807 }" id="AB807">AGORA EXCAVATIONS</h4>
<p>Agora Excavation inventory numbers have two prefixes, the first prefix is Agora, and the second prefix is a letter indicating the class of find as follows:</p> 
<ul>
<li :class="{'activeClass': idClicked == AB808 }" id="AB808"><b>Agora A</b> = architecture; </li> 
<li :class="{'activeClass': idClicked == AB809 }" id="AB809"><b>Agora B</b> = bronzes; </li> 
<li :class="{'activeClass': idClicked == AB810 }" id="AB810"><b>Agora BI</b> = bone and ivory; </li> 
<li :class="{'activeClass': idClicked == AB811 }" id="AB811"><b>Agora I</b> = inscriptions; </li> 
<li :class="{'activeClass': idClicked == AB812 }" id="AB812"><b>Agora IL</b> = iron and lead; </li> 
<li :class="{'activeClass': idClicked == AB813 }" id="AB813"><b>Agora L</b> = lamps; </li> 
<li :class="{'activeClass': idClicked == AB814 }" id="AB814"><b>Agora P</b> = pottery; </li> 
<li :class="{'activeClass': idClicked == AB815 }" id="AB815"><b>Agora S</b> = sculpture; </li> 
<li :class="{'activeClass': idClicked == AB816 }" id="AB816"><b>Agora ST</b> = stone objects; </li> 
<li :class="{'activeClass': idClicked == AB817 }" id="AB817"><b>Agora T</b> = terracotta figurines</li> 
</ul>
<p :class="{'activeClass': idClicked == AB818 }" id="AB818"><b>Agora Deposits</b> = Each deposit is identified by its letter and number coordinates on the Agora grid and the number assigned to the deposit within the grid square. The Agora Excavations conducted by the American School of Classical Studies (ASCS) began in May 1931. Before that time some of the outstanding finds of sculpture from the Agora area were taken to the National Museum and given NM inventory numbers. Major inscriptions were taken to the Epigraphical Museum and inventoried there; other inscriptions were published in the IG and left on the site. A much to be desired list of finds made in the Agora area before 1931 has not yet been compiled from the published notices and the National Museum and Epigraphical Museum inventory books. </p> 
<h4 :class="{'activeClass': idClicked == AB819 }" id="AB819">AKROPOLIS NORTH SLOPE EXCAVATIONS CONDUCTED BY OSCAR BRONEER</h4> 
<p :class="{'activeClass': idClicked == AB820 }" id="AB820">The excavations were carried out in the 1930s under the auspices of the ASCS. Broneer's excavations were not affiliated with the Agora Excavations which did, however, provide storage space for his finds, stored first in the old Agora Excavation House on Asteroskopeion Street which was demolished when the Stoa of Attalos was built. In 1956 when Broneer was requested to sort the finds before they were transferred to the Stoa of Attalos, he came and discarded quite a lot of material, especially prehistoric artefacts including quite a number of grinding stones. Broneer encountered sections of the old 19th century Akropolis dump as he cleared away debris. The pottery sherds that joined Akropolis BF and RF vases and fragments of sculpture that either joined Akropolis sculpture or could be safely associated were taken to the Akropolis or in some cases to the National Museum. The inventory numbers assigned by Broneer have the prefix <b>A.</b> for Akropolis and a second prefix indicating the class of finds as follows:</p> 
<ul>
<li :class="{'activeClass': idClicked == AB821 }" id="AB821"><b>A.B.</b> = bronzes;</li>
<li><b>A.F.</b> = terracotta figurines; </li>
<li><b>A.L.</b> = lamps; </li>
<li><b>A.M.</b> = miscellaneous finds; </li>
<li><b>A.O.</b> = ostraka; </li>
<li><b>A.P.</b> = pottery; </li>
<li><b>A.S.</b> = sculpture. </li>
</ul>
<p>The A.I inventory numbers of inscriptions were assigned later on by Kevin Glowacki to the inscriptions transferred to the Epigraphical Museum </p> 
<h4>Other Institutions</h4>
<ul>
<li :class="{'activeClass': idClicked == AB822 }" id="AB822"><b>ASCS</b> = The American School of Classical Studies at Athens </li> 
<li :class="{'activeClass': idClicked == AB823 }" id="AB823"><b>BA inventory numbers</b> = see below under Ephoria Inventory Numbers </li> 
<li :class="{'activeClass': idClicked == AB824 }" id="AB824"><b>Berlin</b> = Staatliche Museen zu Berlin </li> 
<li :class="{'activeClass': idClicked == AB825 }" id="AB825"><b>BM</b> = British Museum, London Boston, </li> 
<li :class="{'activeClass': idClicked == AB826 }" id="AB826"><b>MFA</b> = Boston Museum of Fine Arts</li> 
<li :class="{'activeClass': idClicked == AB827 }" id="AB827"><b>B. Per.</b> = see below under Ephoria Inventory Numbers</li> 
<li :class="{'activeClass': idClicked == AB828 }" id="AB828"><b>Brussels, Mus. du Cinq.</b> = Brussels, Musees Royaux d'Art et d'Histoire, 10 Parc du Cinquantehaire</li>
<li :class="{'activeClass': idClicked == AB829 }" id="AB829"><b>Byzantine Museum</b> = Byzantine and Christian Museum, Athens. Circa 1890 Byzantine and Frankish architectural reliefs were taken from the Akropolis to the small collection of sculpture in the National Museum. Many of these, including a 13th century relief of St. Paul named in Latin were later transferred to the Byzantine Museum. </li> 
<li :class="{'activeClass': idClicked == AB830 }" id="AB830"><b>Center for Acropolis Studies</b> = Building at 2-4 Makriyanni St. (formerly known as the Old Military Hospital or the Weiler Bldg. or the Gendarmerie) houses extremely informative, absolutely fascinating exhibits presenting the work of the Committee for the Preservation of Acropolis Monuments and the results of research by individual members. The plans and drawings, the models and other exhibits incorporate valuable information about recent unpublished discoveries. In 1999 the building was severely damaged by an earthquake which necessitated closing some of the exhibits. Other exhibits were either removed or concealed when office space was needed in connexion with the construction of the new Acropolis Museum. Thus statements in this book reporting that models, plans and drawings are on show in the Center for Acropolis Studies often no longer hold true. </li> 
<li :class="{'activeClass': idClicked == AB831 }" id="AB831"><b>DAI</b> = Deutsches Archaologisches Institut. See under Inst. Phot, below. </li> 
<li :class="{'activeClass': idClicked == AB832 }" id="AB832"><b>EM</b> = Epigraphical Museum, Athens. In 1888 P. Kavvadias asked G. Lolling to inventory the inscriptions in the National Museum and put them in order and make a selection for display. Although EM inventory numbers are rarely cited in this work, information about findspots of inscriptions in the inventories has been utilized. </li> 
</ul>
<h4 :class="{'activeClass': idClicked == AB833 }" id="AB833">Ephoria inventory numbers</h4>
 
<p :class="{'activeClass': idClicked == AB834 }" id="AB834"><b>1st Ephor.</b> and <b>3rd Ephor.</b> are the prefixes for inventory numbers of finds from the First and Third Ephorias of the Greek Archaeological Service. The Greek Archaeological Service suffers from a chronic shortage of storage space. Finds from their excavations in Athens are in storerooms all over the place which are constantly being overcrowded. As finds from new excavations pour in, new storerooms are constructed ad hoc, old finds are shifted here and there to make room for the flood of new finds. There is no central system for inventorying finds from excavations in Athens and I do not know of any document listing the many different sets of inventories that have been adopted. What follows is an attempt to list some of the Ephoria inventories cited in the reports and publications of the Greek Archaeological Service. 1st Ephor. is the initial prefix for certain finds from the First Archaeological District (Ephoria). The secondary prefixes for the First Ephoria inventory numbers are:</p> 
<ul>
<li :class="{'activeClass': idClicked == AB835 }" id="AB835"><b>BA inventory numbers</b> = for objects in the storerooms of the Library of Hadrian. The letters stand for Βιβλιοθήκη Αδριανού</li> 
<li :class="{'activeClass': idClicked == AB836 }" id="AB836"><b>B. Per. inventory numbers</b> = The letters stand for Northern Area [of the Akropolis South Slope?]</li> 
<li :class="{'activeClass': idClicked == AB837 }" id="AB837"><b>NA, NAG, NK and NAM numbers</b> = finds from the Akropolis South Slope. The N stands for “South”. </li> 
<li :class="{'activeClass': idClicked == AB838 }" id="AB838"><b>Pankrates numbers</b> = finds from the sanctuary of Pankrates on the Ilissos, stored in the mosque in the Roman Agora. </li> 
<li :class="{'activeClass': idClicked == AB839 }" id="AB839"><b>Pi numbers</b> = for finds from the Plaka area stored at 20 Thrasyllou St.; Deltion 46 (1991) B1 Chr. 20. The Greek letter P stands for Plaka and is here transcribed as Pi. </li> 
<li :class="{'activeClass': idClicked == AB840 }" id="AB840"><b>Pi Lambda numbers</b> = finds from the Plaka stored in the Library of Hadrian </li> 
<li :class="{'activeClass': idClicked == AB841 }" id="AB841"><b>Rho Alpha numbers</b> = a special inventory for stone objects in the Roman Agora area, mostly around the Tower of the Winds. Since 1835 this area has served as a collecting point for stone finds from all over Athens. Because the Greek letters RA standing for Roman Agora are turning up in English publications as PA they are called Rho Alpha numbers here in order to avoid [confusion.]</li> 
<li :class="{'activeClass': idClicked == AB842 }" id="AB842"><b>3rd Ephor.</b> = Third Ephoria, the archaeological district of Lower Athens, excepting the various enclaves belonging to the First Ephoria. </li> 
<li :class="{'activeClass': idClicked == AB843 }" id="AB843"><b>Historical Museum</b> = National Historical Museum, Athens</li> 
<li :class="{'activeClass': idClicked == AB844 }" id="AB844"><b>Inst, phot.</b> = photographs in the Photoabteilung of the DAI, Athens. Institute negative numbers, e.g. Inst. phot. Acr. 2007, have, in the past, frequently been cited as museum inventory numbers; this wreaks havoc. </li> 
</ul>
<h4 :class="{'activeClass': idClicked == AB845 }" id="AB845">Kerameikos Excavations</h4>
<p>Conducted by the German Archaeological Institute, these have a standard system for the earlier excavations using two inventory number prefixes: the first prefix is <b>Kerameikos</b> and the 2nd prefix is a letter as follows:</p> 
<ul>
<li :class="{'activeClass': idClicked == AB846 }" id="AB846"><b>A</b> = Architektur; </li>
<li><b>I</b> = Inschrift;</li>
<li><b>M</b> = Metal; </li>
<li><b>P</b> = Plastik; </li>
<li><b>WM</b> = Wall Painting </li> 
</ul>
<p :class="{'activeClass': idClicked == AB847 }" id="AB847">A great number of grave reliefs, sarcophagi and some other objects (coin hoards, jewelry, inscriptions, sculpture) from earlier excavations of the Greek Archaeological Society in the area of what is now the Kerameikos Excavations conducted by the German Archaeological Institute, are now in the National, Epigraphical and Numismatic Museums. The archives of the Kerameikos Excavations have a list of grave monuments removed from the site. </p> 
<p :class="{'activeClass': idClicked == AB848 }" id="AB848"><b>Kerameikos Grave numbers</b> = In the last century many graves excavated in the area of Plateia Koumoundourou, far outside the Kerameikos Excavations, were given Kerameikos Grave inventory numbers. The contents of four of these graves, skeletons and grave goods, are displayed in the anteroom to the National Museum auditorium and labelled as Kerameikos graves. Part II no. <a v-on:click.prevent="replaceLink"  href="NNM.html#NNM476B">476B</a> is one of these graves. </p> 
<p :class="{'activeClass': idClicked == AB849 }" id="AB849"><b>Mosque in the Roman Agora</b>, the Fetichie Tzami (Tzami Starobazarou) <br />The mosque was equipped with shelving to serve as a storeroom for the finds from excavations of the Akropolis South Slope, 1955-1959, e.g. the finds from the sanctuary of The Nymphe were stored there. Finds made elsewhere in Athens, e.g. the sanctuary of Pankrates on the Ilissos, were brought in from time to time. I cannot resist the pleasure of recording a shining example of peaceful coexistence. Whenever an Arabian sheik came to worship in the mosque, the archaeologists vacated the building, attendants came and laid down carpets, the sheik and his retinue came and did their devotions, the carpets were rolled up again and the archaeologists returned. </p> 
<ul>
<li :class="{'activeClass': idClicked == AB850 }" id="AB850"><b>NA</b> = see above under Ephoria Inventory Numbers </li> 
<li :class="{'activeClass': idClicked == AB851 }" id="AB851"><b>NAG</b> = see above under Ephoria Inventory Numbers</li> 
<li :class="{'activeClass': idClicked == AB852 }" id="AB852"><b>NK</b> = see above under Ephoria Inventory Numbers</li> 
<li :class="{'activeClass': idClicked == AB853 }" id="AB853"><b>NM</b> = National Museum, Athens. Before the National Museum was completed in 1879 earlier collections of antiquities of Athens were transferred there. Praktika June 1874 - December 1875, 25-27, is a distressed report about the distressing disorder in the National Museum in 1874-1875. </li> 
<li :class="{'activeClass': idClicked == AB854 }" id="AB854"><b>Pankrates</b> = see above under Ephor. Inventory Numbers </li> 
<li :class="{'activeClass': idClicked == AB855 }" id="AB855"><b>Pi (Plaka)</b> = see above under Ephor. Inventory Numbers </li> 
<li :class="{'activeClass': idClicked == AB856 }" id="AB856"><b>Pi Lambda (Plaka)</b> = see above under Ephor. Inventory Numbers </li> 
<li :class="{'activeClass': idClicked == AB857 }" id="AB857"><b>Rho Alpha (Roman Agora)</b> = see above under Ephor. Inventory Numbers</li> 
</ul>


</div>
</template>
        
<script>

    import { ref } from '@vue/reactivity';
    import { watchEffect } from '@vue/runtime-core'; 
    import {usePartAndTerm} from '../../composables/loadPartAndTerm.js'
    import {useReplaceLinkClick} from '../../composables/handleClick.js' 
    
    export default {
      name: 'Forward',
      setup(props, { emit }){   
        let { loadPartAndTerm, idClicked } = usePartAndTerm()   
        const { replaceLink } = useReplaceLinkClick()    
    
        // let idClicked = ref(loadPartAndTerm())  
    
        loadPartAndTerm()       
        return{ replaceLink, idClicked }
      }
    }
    </script>
    
    <style>
    .activeClass{ 
      background-color: rgb(176, 196, 222, 0.5);     
    }
    </style>
    