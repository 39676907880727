import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import OverlayPanel from 'primevue/overlaypanel';
import Menubar from 'primevue/menubar' 
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext'; 
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import "primeflex/primeflex.css";
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css' 
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; 
import router from './router' 
 
const app = createApp(App);
app.use(PrimeVue);
app.use(SimpleTypeahead);
app.use(router);
app.component("Dialog", Dialog);
app.component("Button", Button);
app.component("OverlayPanel", OverlayPanel);
app.component("Menubar", Menubar); 
app.component("Panel", Panel);
app.component("InputText", InputText);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Splitpanes', Splitpanes);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Pane', Pane);
app.mount("#app");